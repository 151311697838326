import { makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ThemeInput from 'Components/ThemeInput'
import React, { useCallback, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: '100%',
  }, 
  input: {
    
  }
}))

const TypeAhead = ({ defaultValue, filterOptions = [], onChange }) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue)

  const handleChange = useCallback((e, selected, reason) => {

    if (reason === 'clear' && e.type === 'click') {
      selected = defaultValue
    }
    if (selected && (selected.value !== value.value)) {
      onChange(selected)
      setValue(selected)
    }
  }, [defaultValue, value, onChange, setValue])

  return (
    <Autocomplete
      defaultValue={defaultValue}
      options={filterOptions}
      value={value}
      classes={{
        root: classes.autoComplete, 
        input: classes.input, 
      }}
      getOptionLabel={(option) => option.label}
      // onBlur={onBlur}
      onChange={handleChange}
      disableClearable={value.value === false}
      renderInput={(params) => <ThemeInput {...params} />}
    />

  )
}

export default React.memo(TypeAhead)
