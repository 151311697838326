import { makeStyles, Typography } from '@material-ui/core'
import { PropTypes } from 'Core'
import React, { useMemo } from 'react'

const useColors = makeStyles((theme) => ({
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  success: {
    color: theme.palette.success.main
  },
  info: {
    color: theme.palette.info.main
  },
  error: {
    color: theme.palette.error.main
  },
  initial: {
    color: 'initial'
  },
  inherit: {
    color: 'inherit'
  },
  link: {
    color: '#0965df'
  },
  'primary-light': {
    color: theme.palette.primary.light
  },
  'secondary-light': {
    color: theme.palette.secondary.light
  },
  'success-light': {
    color: theme.palette.success.light
  },
  'info-light': {
    color: theme.palette.info.light
  },
  'error-light': {
    color: theme.palette.error.light
  }
}))

const useTypoClasses = makeStyles((theme) => ({
  h3: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.25),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    margin: 0
  },
  h6: {},
  title: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.12px'
  },
  body: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.1px'
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.11px'
  },
  caption: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(0.75),
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.4px'
  }
}))

const useEmphasis = makeStyles((theme) => ({
  high: {
    color: theme.palette.text.primary
  },
  medium: {
    color: theme.palette.text.secondary
  },
  low: {
    color: theme.palette.text.disabled
  },
  none: {}
}))

const useStyles = makeStyles(() => ({
  main: {
    // @ts-ignore
    fontWeight: (props) => props.fontWeight || 'normal',
    overflowWrap: 'anywhere',
    // @ts-ignore
    textAlign: (props) => props.align
  }
}))

/**
 * @typedef { 'info' | 'error' | 'initial' | 'primary' | 'link' | 'secondary' | 'success' | 'info-light' | 'error-light' | 'primary-light' | 'secondary-light' | 'success-light'} Color
 * @typedef {'high' | 'medium' | 'low' | 'none'} Emphasis
 * @typedef { 'left' | 'center' | 'right' | 'none' } TextAlign
 * @typedef { ('title' | 'body' | 'h3' | 'subtitle' | 'caption' | 'h6' | 'body1' | 'body2' | 'h4' | 'h5') & string } Variant
 * @param {{ color?: Color, align?: TextAlign, emphasis?: Emphasis, variant?: Variant, className?: string, children?:React.ReactNode, height?: string, fontWeight?: string }} param0
 * @returns
 */
const CustomTypo = ({
  color = 'initial',
  align = 'left',
  emphasis = 'none',
  variant = 'body',
  className = '',
  children = null,
  height = '16px',
  fontWeight = 'normal',
  ...rest
}) => {
  const typoClasses = useTypoClasses({ height })
  const emphasisClasses = useEmphasis()
  const colorsClasses = useColors()
  const classes = useStyles({ fontWeight, align })

  const Component = useMemo(() => {
    const Elements = {
      h3: (props) => <h3 {...props} />,
      body: (props) => <p {...props} />,
      title: (props) => <p {...props} />,
      caption: (props) => <span {...props} />,
      subtitle: (props) => <span {...props} />,
      h6: (props) => <Typography variant='h6' {...props} />,
      h4: (props) => <Typography variant='h4' {...props} />,
      h5: (props) => <Typography variant='h5' {...props} />,
      body1: (props) => <Typography variant='body1' {...props} />,
      body2: (props) => <Typography variant='body2' {...props} />
    }
    return Elements[variant] || Elements.title
  }, [variant])

  const combinedClasses = () => {
    return `${typoClasses[variant]} ${colorsClasses[color]} ${emphasisClasses[emphasis]} ${classes.main}  ${className}`
  }

  return (
    <Component className={combinedClasses()} {...rest}>
      {children}
    </Component>
  )
}

CustomTypo.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['h3', 'body', 'title', 'subtitle']),
  emphasis: PropTypes.oneOf(['high', 'low', 'medium']),
  color: PropTypes.oneOf([
    'info',
    'error',
    'success',
    'primary',
    'secondary',
    'info-light',
    'error-light',
    'success-light',
    'primary-light',
    'secondary-light',
    'textSecondary',
    'textPrimary',
    'initial',
    'inherit'
  ])
}
export default CustomTypo
