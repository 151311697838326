import useAppView from 'Core/Hooks/useAppView'
import { SummaryHeader, getResourceName, getRsrcIcon, useResourceDetails } from 'features/resources'
import React from 'react'
import { useHistory } from 'react-router'

function ResourceDetailsView({ HeaderActions, children, catalogPage = 'targets' }) {
  const { rsrc } = useResourceDetails()
  const { appView } = useAppView()

  const history = useHistory()

  const DEFAULT_BREADCRUMBS = [
    {
      label: 'Resource Catalog',
      link: `/${appView}/${catalogPage}`
    },
    {
      label: getResourceName(rsrc),
      link: null
    }
  ]

  const getBreadcrumbsElements = () => {
    // @ts-ignore
    const fromHistory = history.location.state?.from || []
    if (!fromHistory.length) return DEFAULT_BREADCRUMBS

    return fromHistory.map((h) => ({
      label: h.name,
      link: h.url
    }))
  }

  if (!rsrc) return null

  return (
    <div>
      <div className='bg-[#F9FBFC] -mx-4 -mt-6 p-4'>
        <SummaryHeader
          Icon={getRsrcIcon(rsrc)}
          rsrcName={getResourceName(rsrc)}
          actions={HeaderActions}
          breadCrumbsItems={getBreadcrumbsElements()}
        />
      </div>
      <div className='mt-10'>{children}</div>
    </div>
  )
}

export { ResourceDetailsView }
