import { useSelector } from 'react-redux'
import useMultiDispatch from './useMultiDispatch'
import { useState } from 'react'
import _ from 'lodash'
import { getCurrentOrg } from 'Utils/Helpers'

// TODO! Create a context for user
export const useUser = () => {
  //@ts-ignore
  const user = useSelector((s) => s.user)
  const [userObject, setUserObject] = useState(user)
  const [groupsRefs, setGroupsRefs] = useState([])
  const [org, setOrg] = useState(getCurrentOrg())
  const isAgentlessMode = user.awsurl !== 'signin.aws.procyon.ai'
  //@ts-ignore
  const { initiallyLoaded, data: users, status } = useSelector((s) => s.userList)
  const { selectDispatch } = useMultiDispatch([])

  // Dispatch if user list is not fetched
  if (!initiallyLoaded && status === 'idle') selectDispatch(['userList'])

  const currentUserObj = _.find(users, { ObjectMeta: { ID: user.ObjectMeta.ID, Kind: 'User' } })

  if (currentUserObj && !_.isEqual(currentUserObj, userObject)) {
    setUserObject(currentUserObj)
    setGroupsRefs(currentUserObj.Spec.Groups.ObjectRef)
  }

  if (user && user.activeOrg !== org) setOrg(user.activeOrg)

  const isOrgAdmin = user ? user.UserOrgs[org] : false

  return {
    user: userObject,
    currentUserIsAdmin: user.role === 'admin',
    groupsRefs,
    org,
    isAuditor: user.isAuditor,
    isAgentlessMode,
    rdpurl: user.rdpurl,
    isOrgAdmin,
    controller: user.controller
  }
}
