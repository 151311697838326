import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { removeUserFromGroup, updateGroup } from 'features/groups'
import { createRef } from 'features/resources'
import { fetchUser } from 'features/users'
import _ from 'lodash'
import React, { useState } from 'react'

function ManageUsersGroups({ onCancel, onSuccess, user }) {
  const [isUpdating, setIsUpdating] = useState(false)
  const { slices, getObjectRef } = useMultiSlice(['groupList'])

  const [selectedEntities, setSelectedEntities] = useState(() =>
    getObjectRef(user.Spec.Groups?.ObjectRef || [])
  )

  const handleDeSelect = (group) => {
    if (group.ObjectMeta.Name === 'everyone') return true
  }

  const handleManageGroups = async () => {
    try {
      setIsUpdating(true)
      const existingGroups = getObjectRef(user.Spec.Groups?.ObjectRef || [])
      const updatedGroups = await handleBulkAddGroup(user, selectedEntities, existingGroups)
      const updatedUser = await fetchUser(user)
      updatedGroups.forEach(updateSliceData)
      updateSliceData(updatedUser)
      enqueueNotification("Successfully updated user's groups.", 'info')
      onSuccess?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <div>
      <div>
        <LoadingFeedback
          loading={isUpdating}
          message={`Updating user's groups.`}
          caption='Please wait..'
        />
        <FullScreenContentModal width='auto'>
          <EntityPicker
            deSelectFn={handleDeSelect}
            title={'Add User to Groups'}
            continueText='Save'
            entities={slices.groupList}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={onCancel}
            onContinue={handleManageGroups}
          />
        </FullScreenContentModal>
      </div>
    </div>
  )
}

const handleBulkAddGroup = async (user, toBeAdded = [], existingGroups = []) => {
  const toUpdateGroups = []

  existingGroups.forEach((group) => {
    // check if the group existed before but not anymore
    const isRemoved = !_.find(toBeAdded, { ObjectMeta: { ID: group.ObjectMeta.ID } })
    if (!isRemoved) return
    // If the group is not present in the to-be added groups but was present before, that means we need to remove that group
    const [, newGroup] = removeUserFromGroup(user, group)
    toUpdateGroups.push(newGroup)
  })

  toBeAdded.forEach((group) => {
    const uRefs = [...group.Spec.Users?.ObjectRef] || []
    // If user is already in group dont update
    if (_.find(uRefs, { RefID: user.ObjectMeta.ID })) return
    uRefs.push(createRef(user))
    const newGroup = structuredClone(group)
    // Update the group's users with new set of users
    newGroup.Spec.Users.ObjectRef = uRefs
    toUpdateGroups.push(newGroup)
  })

  const apiCalls = toUpdateGroups.map(async (g) => await updateGroup(g))
  return await Promise.all(apiCalls)
}

export { ManageUsersGroups }
