import React from 'react'
import { Application } from '../Application'
import { IamRsrc } from '../IamRsrc'
import { Server } from '../Server'

const RsrcRow = ({
  rsrc,
  rsrcAttributesSpec,
  getObjectRef,
  handleRsrcRolesSelect,
  onRsrcAttriputesUpdate
}) => {
  const Kind = rsrc.ObjectMeta.Kind
  const Component = rsrcComponentMap[Kind]

  if (!Component) return null
  return (
    <Component
      rsrc={rsrc}
      rsrcAttributesSpec={rsrcAttributesSpec}
      getObjectRef={getObjectRef}
      handleRsrcRolesSelect={handleRsrcRolesSelect}
      onRsrcAttriputesUpdate={onRsrcAttriputesUpdate}
    />
  )
}

const rsrcComponentMap = {
  Server: Server,
  AwsResource: IamRsrc,
  GcpResource: IamRsrc,
  AzureResource: IamRsrc,
  Application: Application
}

export { RsrcRow }
