import React from 'react'
import { Route } from 'react-router'
import { IAMRoles } from '../views/IamRoles'
import { IAMRoleDetails } from '../views/IAMRolesDetails'
import { CreateIAMRole } from '../views/CreateIamRole'

const IAMRolesRoutes = () => {
  return (
    <>
      <Route
        exact
        path={['/admin/iam-roles', '/admin/iam-roles/:cloudType']}
        component={IAMRoles}
      />
      <Route
        exact
        path={['/admin/iam-roles/:cloudType/create', '/admin/iam-roles/:cloudType/:roleName/edit']}
        component={CreateIAMRole}
      />
      <Route
        exact
        path={['/admin/iam-roles', '/admin/iam-roles/:roleName/overview']}
        component={IAMRoleDetails}
      />
      <Route exact path={['/user/iam-roles', '/user/iam-roles/:cloudType']} component={IAMRoles} />
      <Route
        exact
        path={['/user/iam-roles', '/user/iam-roles/:roleName/overview']}
        component={IAMRoleDetails}
      />
    </>
  )
}

export { IAMRolesRoutes }
