import { DataTablExtended } from 'V2Components'
import { SummaryHeader } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { Button, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { columns, useADDomainControllers } from './ADDomainControllers.utils'
import { DeleteADDCModal } from './components/DeleteADDCModal'
import useAppView from 'Core/Hooks/useAppView'
import { useHistory } from 'react-router'

const useSlices = createDataSelectorHook(['adDomainControllers'])

const ADDomainControllers = () => {
  const history = useHistory()
  const { appView } = useAppView()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedADDC, setSelectedADDC] = useState(null)

  const {
    slices: { adDomainControllers }
  } = useSlices()

  const { rowDataAdapterFn } = useADDomainControllers({
    setShowDeleteModal,
    setSelectedADDC
  })

  return (
    <div>
      <div className='bg-[#F9FBFC] -mx-4 -mt-6 p-4'>
        <SummaryHeader
          rsrcName={'AD Domain Controllers'}
          Icon={({ ...props }) => <TargetIcon type='AD_DOMAIN_CONTROLER' {...props} />}
          actions={
            <>
              <Button
                onClick={() =>
                  history.push(`/${appView}/addomainControllers/${encodeURIComponent('create')}`)
                }
                size='md'
                variant='primary'
              >
                Create
              </Button>
            </>
          }
          breadCrumbsItems={[]}
        />
      </div>
      <div className='pt-2'>
        {adDomainControllers.length !== 0 && (
          <Typography variant='body-regular'>
            {adDomainControllers.length} AD Domain Controllers
          </Typography>
        )}
        <div className='mt-4'>
          <DataTablExtended
            disableRowSelectionOnClick
            data={adDomainControllers}
            getColumns={columns}
            rowDataAdapter={rowDataAdapterFn}
            disableVirtualList
          />
        </div>
      </div>
      {showDeleteModal && (
        <DeleteADDCModal
          addc={selectedADDC}
          onCancel={() => {
            setSelectedADDC(null)
            setShowDeleteModal(false)
          }}
          onSuccess={() => {
            setSelectedADDC(null)
            setShowDeleteModal(false)
          }}
        />
      )}
    </div>
  )
}

export { ADDomainControllers }
