import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useAppDetailsContext, useApplicationAccess } from 'features/applications'
import { getPolicyIssuedToEntities } from 'features/policy'
import { RsrcAccessSummaryView, createRsrcKey } from 'features/resources'
import _ from 'lodash'
import React from 'react'

function AccessSummary() {
  const { app } = useAppDetailsContext()
  const { getApplicationPolicysRef } = useApplicationAccess()
  const { getObjectRef } = useMultiSlice([
    'policyList',
    'userList',
    'groupList',
    'serviceAccounts',
    'githubResources'
  ])

  const policyReducer = (prev, p) => [...prev, ...getPolicyIssuedToEntities(p)]

  // All the policys this app is attached with
  const policys = getObjectRef(getApplicationPolicysRef(app))
  // All the entities who have access to the app via policy
  const entities = getObjectRef(_.uniqBy(policys.reduce(policyReducer, []), createRsrcKey))

  return (
    <div>
      <RsrcAccessSummaryView attachedPolicys={policys} rsrcAccessEntities={entities} />
    </div>
  )
}

export { AccessSummary as AccessSummaryTab }
