import { randomStringGenerator } from './PureHelperFuctions'

const rivers = ['YarqonRiver', 'DravaRiver', 'PedieosRiver', 'AndroscogginRiver', 'SaintCroixRive', 'TarRiver', 'BandamaRiver', 'OrdRiver',
  'AngermanRiver', 'LewesRiver', 'GilaRiver', 'ToneRiver', 'KamchatkaRiver', 'RiverAire', 'SabiRiver', 'KuisebRiver', 'VaalRiver', 'SileruRiver', 'FlyRiver',
  'TowyRiver', 'FuerteRiver', 'CongareeRiver', 'SoloRiver', 'PanjnadRiver', 'GuayasRiver', 'WinooskiRiver', 'GuadianaRiver', 'NottawayRiver', 'CheyenneRiver',
  'BrahmaniRiver', 'SourisRiver', 'ElkRiver', 'CongoRiver', 'SaaleRiver', 'KadunaRiver', 'MahiRiver', 'KinabatanganRiver', 'AngaraRiver', 'MurrumbidgeeRiver',
  'RiverMersey', 'EureRiver', 'LufiraRiver', 'Ruhr', 'Rubicon', 'SouthernBuh', 'SepikRiver', 'LinthRiver', 'WanganuiRiver', 'SanagaRiver', 'OuhamRiver',
  'ShebeliRiver', 'BaniRiver', 'KafueRiver', 'RedRiver', 'WeiRiver', 'PripetRiver', 'NegroRiver', 'BiobioRiver', 'MuresRiver', 'GreatMiamiRiver', 'WabashRiver',
  'NantahalaRiver', 'ArgunRiver', 'DunajecRiver', 'MissouriRiver', 'BullerRiver', 'TaieriRiver', 'ChubutRiver', 'AwashRiver', 'KomatiRiver', 'Acheron', 'RiverRibble',
  'YaquedelNorteRiver', 'TapajosRiver', 'MaitengweRiver', 'YaluRiver', 'RiverWeaver', 'BowRiver', 'StonesRiver', 'VoltaRiver', 'BrahmaputraRiver', 'LahnRiver',
  'MonongahelaRiver', 'SanteeRiver', 'WailuaRiver', 'GrandeRiver', 'QinRiver', 'DeGreyRiver', 'TongueRiver', 'WashitaRiver', 'MatauraRiver', 'SemlikiRiver', 'AddaRiver',
  'OltRiver', 'VahRiver', 'RuvubuRiver', 'TanoRiver', 'SierraLeoneRiver', 'VltavaRiver', 'NeisseRiver', 'OutardesRiver', 'PineiosRiver', 'PiaveRiver', 'CumberlandRiver',
  'DaguaRiver', 'WisconsinRiver', 'NunRiver', 'SeguraRiver', 'SaintClairRiver', 'DoloresRiver', 'SaltRiver', 'JubbaRiver', 'MaputoRiver', 'JinshaRiver', 'BrisbaneRiver',
  'DonRiver', 'SenegalRiver', 'MorghabRiver', 'AubeRiver', 'OrangeRiver', 'AlleghenyRiver', 'ForcadosRiver', 'GangesRiver', 'HeiRiver', 'MahanadiRiver', 'UnzhaRiver',
  'Harirud', 'TumutRiver', 'DalRiver', 'ArkansasRiver', 'SanghaRiver', 'CasamanceRiver', 'YodoRiver', 'BaradaRiver', 'CeyhanRiver', 'MaraisdesCygnesRiver', 'AraguaiaRiver',
  'LuvuaRiver', 'SurmaRiver', 'MitchellRiver', 'EmsRiver', 'CautoRiver', 'CuyuniRiver', 'ChunaRiver', 'ToddRiver', 'BugRiver', 'RiverMedina', 'CanadianRiver', 'KootenayRiver',
  'PeaceRiver', 'ZambeziRiver', 'SewaRiver', 'TarimRiver', 'SnowyRiver', 'TiberRiver', 'HunyaniRiver', 'GanRiver', 'SequatchieRiver', 'RangitikeiRiver', 'YalongRiver', 'BaghmatiRiver',
  'MuldeRiver', 'EvrotasRiver', 'BackRiver', 'McArthurRiver', 'PescaraRiver', 'DonetsRiver', 'PigeonRiver', 'CatatumboRiver', 'FaroRiver', 'NiariRiver', 'BakoyeRiver', 'AllierRiver',
  'LoupRiver', 'NeretvaRiver', 'OwyheeRiver', 'SaintFrancisRiver', 'SubarnarekhaRiver', 'ChaoPhrayaRiver', 'MississippiRiver', 'TallahatchieRiver', 'DadesRiver', 'TaptiRiver',
  'KubanRiver', 'SaintMarysRiver', 'CaneyForkRiver', 'AlbertNile', 'RiverTay', 'HawkesburyRiver', 'KaskaskiaRiver', 'ChaobaiRiver', 'IliRiver', 'PawcatuckRiver', 'YongdingRiver',
  'KumRiver', 'VilyuyRiver', 'EbroRiver', 'UsumacintaRiver', 'SantaRiver', 'LogoneRiver', 'BlueNileRiver', 'SwatRiver', 'RakaiaRiver', 'WaimakaririRiver', 'LualabaRiver',
  'OgooueRiver', 'NarewRiver', 'ConnecticutRiver', 'AcreRiver', 'MiloRiver', 'RiverSuir', 'WiniskRiver', 'FallRiver', 'NeoshoRiver', 'LomamiRiver', 'SchwarzeElsterRiver',
  'DesPlainesRiver', 'SaoFranciscoRiver', 'MahakamRiver', 'UcayaliRiver', 'GumalRiver', 'RiverSlaney', 'TobolRiver', 'KansasRiver', 'RewaRiver', 'BelaitRiver', 'WaingangaRiver',
  'HudsonRiver', 'MerrimackRiver', 'AtratoRiver', 'ManoRiver', 'OrontesRiver', 'MackenzieRiver', 'ThompsonRiver', 'AlabamaRiver', 'GaronneRiver', 'UluaRiver', 'SaintJohnsRiver',
  'MetaRiver', 'GhaggarRiver', 'ForthRiver', 'RoperRiver', 'NeckarRiver', 'PayetteRiver', 'DawsonRiver', 'TagusRiver', 'MinnesotaRiver', 'ParaRiver', 'MonoRiver', 'RedVoltaRiver',
  'PonnaiyarRiver', 'MurchisonRiver', 'RaritanRiver', 'MarkhamRiver', 'SousRiver', 'AbakanRiver', 'HavelRiver', 'DetroitRiver', 'RappahannockRiver', 'LiaoRiver', 'KolymaRiver',
  'NormanRiver', 'LimpopoRiver', 'TekezeRiver', 'SankaraniRiver', 'MoselleRiver', 'IrrawaddyRiver', 'SusquehannaRiver', 'TuscarawasRiver', 'CaroniRiver', 'SaskatchewanRiver',
  'PolochicRiver', 'OrhonRiver', 'ArnoRiver', 'MedicineCreek', 'RiverWharfe', 'YunaRiver', 'YaquiRiver', 'KarnaphuliRiver', 'BanasRiver', 'OkaRiver', 'RiverYare', 'YakimaRiver',
  'SangganRiver', 'RiverTeviot', 'ParaguacuRiver', 'ClarenceRiver', 'TorneRiver', 'DesMoinesRiver', 'BelizeRiver', 'HolstonRiver', 'RockRiver', 'TennesseeRiver', 'LoddonRiver',
  'CedarRiver', 'HumboldtRiver', 'SobatRiver', 'TugelaRiver', 'GomatiRiver', 'PlatteRiver', 'SavannahRiver', 'NapoRiver', 'MamoreRiver', 'SafidRiver', 'GlenelgRiver', 'VardarRiver',
  'TirsoRiver', 'GuaporeRiver', 'GalOya', 'SwanRiver', 'NorthernDvinaRiver', 'YoughioghenyRiver', 'LuapulaRiver', 'AshburtonRiver', 'ClitunnoRiver', 'MotaguaRiver', 'PearlRiver',
  'KouilouRiver', 'FalemeRiver', 'DiyalaRiver', 'BroadRiver', 'KomoeRiver', 'ChambalRiver', 'PeriyarRiver', 'LittleTennesseeRiver', 'JapuraRiver', 'GreatFishRiver', 'NechakoRiver',
  'WatereeRiver', 'YellowstoneRiver', 'SurinameRiver', 'SokotoRiver', 'IJsselRiver', 'Lagen', 'RiverWelland', 'PingRiver', 'TrebbiaRiver', 'SanJoaquinRiver', 'MafouRiver',
  'LundiRiver', 'MazaruniRiver', 'ApureRiver', 'IndigirkaRiver', 'NestosRiver', 'TicinoRiver', 'BoeufRiver', 'SouthPlatteRiver', 'ParanaRiver', 'DelawareRiver', 'HeartRiver',
  'XinRiver', 'YongsanRiver', 'NmaiHka', 'OrneRiver', 'CocoRiver', 'KwandoRiver', 'MaranonRiver', 'BigSiouxRiver', 'BigWoodRiver', 'YalobushaRiver', 'GashRiver', 'GreatKeiRiver',
  'FenRiver', 'DamodarRiver', 'SaguenayRiver', 'StrumaRiver', 'VistulaRiver', 'ChattahoocheeRiver', 'SheyenneRiver', 'SpokaneRiver', 'DoubsRiver', 'QuAppelleRiver',
  'MoreauRiver', 'BetwaRiver', 'JhelumRiver', 'JordanRiver', 'MenderesRiver', 'ElbeRiver', 'SweetwaterRiver', 'WaiauRiver', 'MuRiver', 'CharenteRiver', 'SaarRiver', 'ThelonRiver',
  'LungwebunguRiver', 'KizilRiver', 'RiverMourne', 'WalhondingRiver', 'TinkissoRiver', 'ThjorsRiver', 'SaoLourencoRiver', 'SungariRiver', 'SolimoesRiver', 'GreatScarciesRiver',
  'BurdekinRiver', 'SavaRiver', 'SartheRiver', 'TuckasegeeRiver', 'GongolaRiver', 'LuzniceRiver', 'SanJuanRiver', 'ColoradoRiver', 'BrancoRiver', 'RiverEttrick',
  'AttawapiskatRiver', 'IguacuRiver', 'CapibaribeRiver', 'OiseRiver', 'RiverLiffey', 'HuallagaRiver', 'ShemankerRiver', 'TaedongRiver', 'MindanaoRiver', 'TippecanoeRiver',
  'ParaguayRiver', 'PechoraRiver', 'NaraCanal', 'PeeDeeRiver', 'UruguayRiver', 'SmokyHillRiver', 'EscravosRiver', 'SureRiver', 'FuldaRiver', 'RiverClyde', 'PortneufRiver',
  'SonRiver', 'VychegdaRiver', 'LiriRiver', 'ChoctawhatcheeRiver', 'RuhuhuRiver', 'MalheurRiver', 'CimarronRiver', 'DoceRiver', 'AlligatorRivers', 'YserRiver', 'ChianaRiver',
  'RupertRiver', 'SaladoRiver', 'VictoriaRiver', 'RanceRiver', 'LippeRiver', 'InnokoRiver', 'JingRiver', 'CagayanRiver', 'RiverTrent', 'InnRiver', 'NemanRiver', 'WaikatoRiver',
  'NossobRiver', 'AmuDarya', 'IsarRiver', 'AssiniboineRiver', 'CaniapiscauRiver', 'BloodRiver', 'RajangRiver', 'LotRiver', 'BesosRiver', 'KarunRiver', 'DiamantinaRiver',
  'BalsasRiver', 'CurrentRiver', 'PassaicRiver', 'AlpenrheinRiver', 'DrinaRiver', 'RedDeerRiver', 'ClarionRiver', 'OhioRiver', 'PenobscotRiver', 'SalmonRiver', 'GundlakammaRiver',
  'ThaRiver', 'MaineRiver', 'LiardRiver', 'FrenchBroadRiver', 'YampaRiver', 'UmniatiRiver', 'HanRiver', 'YarmukRiver', 'RiverFinn', 'CuyahogaRiver', 'SaltForkArkansasRiver',
  'HousatonicRiver', 'KlamathRiver', 'SunRiver', 'KaveriRiver', 'YeniseyRiver', 'LachlanRiver', 'SpreeRiver', 'WerraRiver', 'ChulymRiver', 'LoireRiver', 'CaucaRiver',
  'OumelRbiaRiver', 'SelengaRiver', 'KanawhaRiver', 'LaramieRiver', 'SolomonRiver', 'Glomma', 'IalomitaRiver', 'MoscowRiver', 'RiverWye', 'CarsonRiver', 'MohakaRiver', 'SommeRiver',
  'WartaRiver', 'KazanRiver', 'KentuckyRiver', 'MekongRiver', 'AldanRiver', 'FitzroyRiver', 'DordogneRiver', 'GeneseeRiver', 'MedicineLodgeRiver', 'MoulouyaRiver',
  'VictoriaNile', 'KosiRiver', 'DniesterRiver', 'SlaveRiver', 'UralRiver', 'VolturnoRiver', 'SaintJosephRiver', 'BafingRiver', 'MeghnaRiver', 'BeiRiver', 'HuaiRiver', 'BhimaRiver',
  'CuanzaRiver', 'HunterRiver', 'RhoneRiver', 'RiverExe', 'CapeFearRiver', 'RiverBann', 'ColdwaterRiver', 'RiverDee', 'BighornRiver', 'KiamichiRiver', 'OuachitaRiver', 'SabineRiver',
  'ChelifRiver', 'SaludaRiver', 'NileRiver', 'RiverOuse', 'CrossRiver', 'KuraRiver', 'AliakmonRiver', 'SiretRiver', 'GoascoranRiver', 'MeramecRiver', 'MolopoRiver', 'VolgaRiver',
  'PeelRiver', 'SciotoRiver', 'HuttRiver', 'PatiaRiver', 'ArasRiver', 'BhagirathiRiver', 'CamuRiver', 'PorcupineRiver', 'LenaRiver', 'KhwaeNoiRiver', 'AlpheusRiver',
  'YaquedelSurRiver', 'MadeiraRiver', 'PonnaniRiver', 'MokauRiver', 'AraucaRiver', 'RegnitzRiver', 'PowderRiver', 'JaguaribeRiver', 'TumenRiver', 'RiverSevern', 'WadiSeybouse',
  'TallapoosaRiver', 'OyapockRiver', 'CavallaRiver', 'BahrAlGhazal', 'OtiRiver', 'DarlingRiver', 'AdourRiver', 'MetauroRiver', 'ManawatuRiver', 'EssequiboRiver', 'RufijiRiver',
  'PenneruRiver', 'NottelyRiver', 'SacramentoRiver', 'RangitataRiver', 'RukiRiver', 'RichmondRiver', 'NorthPlatteRiver', 'ParnaibaRiver', 'LukugaRiver', 'YangonRiver', 'MainRiver',
  'GatineauRiver', 'PecosRiver', 'SaintJohnRiver', 'SardaRiver', 'OttawaRiver', 'LaGrandeRiver', 'TetonRiver', 'EuphratesRiver', 'ChindwinRiver', 'BosnaRiver', 'RiverErne',
  'HumberRiver', 'SutlejRiver', 'MunRiver', 'JequitinhonhaRiver', 'BiaRiver', 'RupnarayanRiver', 'BattleRiver', 'SevierRiver', 'SaigonRiver', 'PutumayoRiver', 'AmurRiver',
  'LjusnanRiver', 'PellyRiver', 'ShoshoneRiver', 'CleElumRiver', 'IowaRiver', 'LuniRiver', 'MahaweliGanga', 'MarneRiver', 'DramsRiver', 'FraserRiver', 'Ankobra', 'GordonRiver',
  'WaccamawRiver', 'UrubambaRiver', 'ParuRiver', 'ApurimacRiver', 'ShireRiver', 'IndusRiver', 'MamberamoRiver', 'LempaRiver', 'NazasRiver', 'LermaRiver', 'WairauRiver', 'FishRiver',
  'LajaRiver', 'PotomacRiver', 'CourantyneRiver', 'WadiMajardah', 'OuemeRiver', 'LoirRiver', 'OsageRiver', 'YuanRiver', 'RhineRiver', 'RoanokeRiver', 'JariRiver', 'TananaRiver',
  'RiodeAcari', 'LlobregatRiver', 'MooseRiver', 'WinnipegRiver', 'HugliRiver', 'ObRiver', 'DalyRiver', 'DimbovitaRiver', 'TimisRiver', 'DixRiver', 'MobileRiver', 'TrombetasRiver',
  'BomuRiver', 'FarahRiver', 'TerekRiver', 'ShoalhavenRiver', 'MondegoRiver', 'KatsinaAlaRiver', 'MinRiver', 'RiverWaveney', 'SonoraRiver', 'SassandraRiver', 'VolkhovRiver',
  'RiverSwale', 'BermejoRiver', 'GuainiaRiver', 'GuiRiver', 'ByarezinaRiver', 'WeserRiver', 'LuanRiver', 'BLitaniRiver', 'KennebecRiver', 'NolichuckyRiver', 'OkavangoRiver',
  'TennsiftRiver', 'OldmanRiver', 'JacuiRiver', 'TarnRiver', 'IbarRiver', 'FlatheadRiver', 'SevernRiver', 'MadisonRiver', 'SpoonRiver', 'StillwaterRiver', 'KageraRiver', 'KottoRiver',
  'AtbaraRiver', 'WesternDvinaRiver', 'CoppermineRiver', 'CluthaRiver', 'CatawbaRiver', 'HelmandRiver', 'BelayaRiver', 'RiverAvon', 'RiverClwyd', 'BoiseRiver', 'IrtyshRiver',
  'TweedRiver', 'KasaiRiver', 'DhaleswariRiver', 'AbitibiRiver', 'LickingRiver', 'RiverLagan', 'ColumbiaRiver', 'SalineRiver', 'ArinosRiver', 'PanucoRiver', 'KapuasRiver',
  'CaRiver', 'IskurRiver', 'VienneRiver', 'RiverLune', 'AgusanRiver', 'HerbertRiver', 'AareRiver', 'OwensRiver', 'WillametteRiver', 'SkeenaRiver', 'AmazonRiver', 'TaquariRiver',
  'DinderRiver', 'OmoRiver', 'SittangRiver', 'RiverBoyne', 'PetitcodiacRiver', 'RokelRiver', 'JialingRiver', 'DiyalaRiver', 'KaministiquiaRiver', 'DraaRiver', 'PatucaRiver',
  'OcoeeRiver', 'ParanapanemaRiver', 'YamunaRiver', 'JavariRiver', 'GrandRiver', 'OtterCreek', 'WouriRiver', 'CaledonRiver', 'SharavatiRiver', 'PoRiver', 'BrandywineCreek',
  'YonneRiver', 'ArdaRiver', 'RiverDon', 'ChagresRiver', 'CombaheeRiver', 'JuruaRiver', 'NyongRiver', 'MaricoRiver', 'YangtzeRiver', 'JiuRiver', 'GeorgeRiver', 'HongshuiRiver',
  'AlKalbRiver', 'AksuRiver', 'RichelieuRiver', 'RioGrande', 'IllinoisRiver', 'AtchafalayaRiver', 'TombigbeeRiver', 'PraRiver', 'BaShattAlArab', 'AragonRiver', 'RiverTweed',
  'AguanRiver', 'FuchunRiver', 'GhagharaRiver', 'StikineRiver', 'BotetiRiver', 'LuangwaRiver', 'BeasRiver', 'PriceRiver', 'JuruenaRiver', 'TieteRiver', 'ShinanoRiver', 'OuRiver',
  'RiverThames', 'ObeyRiver', 'RepublicanRiver', 'NigerRiver', 'KomaduguYobeRiver', 'PurariRiver', 'CaroniRiver', 'PahangRiver', 'JucarRiver', 'CannonballRiver', 'CharlesRiver',
  'JamesRiver', 'JeffersonRiver', 'SaintPaulRiver', 'MadhumatiRiver', 'TunguskaRiver', 'RiverBarrow', 'GuadalquivirRiver', 'HernadRiver', 'SaintMauriceRiver', 'MaliRiver',
  'VitimRiver', 'HayesRiver', 'LoaRiver', 'GallatinRiver', 'OrinocoRiver', 'TanaRiver', 'PusurRiver', 'NarynRiver', 'NanRiver', 'ScheldeRiver', 'RahadRiver', 'QishonRiver',
  'HiwasseeRiver', 'MuskingumRiver', 'DongNaiRiver', 'IndreRiver', 'SaintCroixRiver', 'YazooRiver', 'TocantinsRiver', 'BoumbeIIRiver', 'DanRiver', 'PasigRiver', 'EastmainRiver',
  'ThamesRiver', 'YarkandRiver', 'FlumendosaRiver', 'GreenRiver', 'CuiabaRiver', 'GambiaRiver', 'RiverLea', 'MusselshellRiver', 'PilcomayoRiver', 'CuneneRiver', 'GandakRiver',
  'VaigaiRiver', 'OhreRiver', 'RiverForth', 'MagdalenaRiver', 'MurrayRiver', 'RiverEden', 'RiverTees', 'ChicagoRiver', 'XinguRiver', 'BerbiceRiver', 'RaviRiver', 'ArgesRiver',
  'AthabascaRiver', 'MariasRiver', 'TensasRiver', 'MuratRiver', 'JizeraRiver', 'PapaloapanRiver', 'SnakeRiver', 'RuziziRiver', 'CooperCreek', 'MeuseRiver', 'SeineRiver',
  'HenrysFork', 'IshikariRiver', 'NiagaraRiver', 'TuiraRiver', 'KissimmeeRiver', 'FinkeRiver', 'XiangRiver', 'MulliganRiver', 'CheoahRiver', 'AbunaRiver', 'Casiquiare',
  'RamuRiver', 'NenRiver', 'XiRiversystem', 'RiverTamar', 'SchuylkillRiver', 'VerdigrisRiver', 'DwangwaRiver', 'SebouRiver', 'PalarRiver', 'EiderRiver', 'ManicouaganRiver',
  'BarronRiver', 'BurnettRiver', 'ArveRiver', 'KankakeeRiver', 'PelotasRiver', 'UbangiRiver', 'OderRiver', 'SomesRiver', 'RuoRiver', 'AnieneRiver', 'DesnaRiver', 'ChenabRiver',
  'IshimRiver', 'OlenyokRiver', 'KhaburRiver', 'MalagarasiRiver', 'CampaspeRiver', 'RiverShannon', 'SeekonkRiver', 'YukonRiver', 'DeseadoRiver', 'ShashiRiver', 'UssuriRiver',
  'GascoyneRiver', 'LittleMissouriRiver', 'WindRiver', 'ApaRiver', 'AinRiver', 'NevaRiver', 'RiverTummel', 'BenueRiver', 'SankuruRiver', 'DigulRiver', 'FarmingtonRiver',
  'ChambeshiRiver', 'SaoneRiver', 'AlsterRiver', 'NiobraraRiver', 'NelsonRiver', 'BrazosRiver', 'ClinchRiver', 'DanubeRiver', 'MoravaRiver', 'DnieperRiver', 'RiverWensum',
  'AlbanyRiver', 'KoyukukRiver', 'MadredeDiosRiver', 'BahrAlZaraf', 'PanjRiver', 'FlindersRiver', 'HuonRiver', 'BigSandyRiver', 'IsereRiver', 'SnoqualmieRiver', 'ItapicuruRiver',
  'LuleRiver', 'RiverYarrow', 'JiulongRiver', 'NarmadaRiver', 'RiverDerwent', 'GrijalvaRiver', 'NeuseRiver', 'DjaRiver', 'ChariRiver', 'BonnyRiver', 'WilmotRiver', 'MilkRiver',
  'LulongaRiver', 'MaRiver', 'KokemaenRiver', 'RiverWitham', 'RiverWear', 'BonanzaCreek', 'MaroniRiver', 'SuraRiver', 'ZeravshanRiver', 'GoulburnRiver', 'VerdonRiver',
  'MahanandaRiver', 'KondaRiver', 'AguaricoRiver', 'CoosaRiver', 'SkunkRiver', 'SyrDarya', 'GreatStour', 'PowellRiver', 'YuRiver', 'NaktongRiver', 'DouroRiver', 'NewRiver',
  'ParanaibaRiver', 'HuangHe', 'AconcaguaRiver', 'RuvumaRiver', 'ChuRiver', 'KemiRiver', 'SalweenRiver', 'Durance', 'AdigeRiver', 'SomesRiver', 'TelesPiresRiver', 'KabulRiver',
  'PrutRiver', 'ChurchillRiver', 'TugalooRiver', 'PanganiRiver', 'KollidamRiver', 'AdelaideRiver', 'CherRiver', 'MayenneRiver', 'PurusRiver', 'TiszaRiver', 'ConchosRiver',
  'PadmaRiver', 'ShilkaRiver', 'MaritsaRiver', 'MaumeeRiver', 'WaitakiRiver', 'GuaviareRiver', 'ShyokRiver', 'PyshmaRiver', 'PiemanRiver', 'RiverMedway', 'ClarkFork',
  'KoulountouRiver', 'TistaRiver', 'GasconadeRiver', 'AframRiver', 'MuresRiver', 'SamaraRiver', 'ChixoyRiver', 'DjouaRiver', 'AchelousRiver', 'SuwanneeRiver', 'DemeraraRiver',
  'NorthCanadianRiver', 'StrawberryRiver', 'KabompoRiver', 'PampangaRiver', 'LesseRiver', 'AguedaRiver', 'MohawkRiver', 'BeniRiver', 'MusiRiver', 'YarraRiver', 'RiverNen',
  'RiverSpey', 'ArtiboniteRiver']
const convertToHex = (str) => {
  let hex = ''
  for (let i = 0; i < str.length; i++) {
    hex += '' + str.charCodeAt(i).toString(16)
  }
  return hex
}
const getRiverName = () => {
  return rivers[Math.floor(Math.random() * rivers.length)]
}
export const getFriendlyName = () => {
  return `${getRiverName()}-${convertToHex(randomStringGenerator(6))}`
}
