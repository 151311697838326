import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { isChildAccount, useCloudTypeRouteProvider } from 'features/clouds'
import { TargetsSliceNames } from 'features/targets'
import { useEffect } from 'react'

export function useClouds() {
  const { cloudType } = useCloudTypeRouteProvider()

  const { slices, selectDispatch } = useMultiSlice(['accountList'])

  const applyFilter = (a) => a.Spec.Type === cloudType && !isChildAccount(a)

  const accounts = slices.accountList.filter(applyFilter)

  useEffect(() => {
    selectDispatch(['accountList', ...TargetsSliceNames])
  }, [])

  return { accounts, cloudType }
}
