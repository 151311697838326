import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { AlertModal, Button } from 'procyon-ui'
import React, { useState } from 'react'
import { FullScreenModal, LoadingFeedback } from 'V2Components'

/**
 *
 * @param {{
 * onActionClick:(() => void),
 * showModal: boolean,
 * onCancel:(() => void),
 * loadingMessage: string,
 * alertMessage: any,
 * actionButtonText: string,
 * actionButtonVariant: import('procyon-ui').ButtonProps['variant']
 * Content?: React.ReactNode
 * disableAction?: boolean,
 * icon?: any,
 * }} param0
 * @returns
 */
function FullScreenAlertModal({
  onActionClick,
  showModal,
  onCancel,
  loadingMessage,
  alertMessage,
  actionButtonText,
  actionButtonVariant,
  disableAction,
  Content,
  icon
}) {
  const [loading, setLoading] = useState(false)

  const handleActionButtonClick = async () => {
    setLoading(true)
    await onActionClick?.()
    setLoading(false)
  }

  return (
    <div>
      <LoadingFeedback loading={loading} caption='Please wait...' message={loadingMessage} />
      {showModal && (
        <FullScreenModal showModal>
          <div className='flex justify-center items-center h-[100%] glass-morph'>
            <AlertModal
              Content={Content}
              description=''
              icon={icon || faExclamationCircle}
              title={alertMessage}
            >
              <>
                <Button onClick={onCancel} variant='gray'>
                  Cancel
                </Button>
                <Button
                  disabled={disableAction}
                  onClick={handleActionButtonClick}
                  variant={actionButtonVariant}
                >
                  {actionButtonText}
                </Button>
              </>
            </AlertModal>
          </div>
        </FullScreenModal>
      )}
    </div>
  )
}

export { FullScreenAlertModal }
