import React from 'react'
import { Route, Switch } from 'react-router'
import { Integrations } from '../views'
import ManageIntegratonsWrapper from '../views/ManageIntegration/ManageIntegration'

const IntegrationsRoutes = () => {
  return ( 
    <Switch>
      <Route exact path={'/admin/integrations'}>
        <Integrations/>
      </Route>
      <Route exact path={'/admin/integrations/:integrationName/:kind'}>
        <ManageIntegratonsWrapper/>
      </Route>
    </Switch>
  )
}

export { IntegrationsRoutes }
