import { SearchInput } from 'V2Components'
import { AdvancedSearch } from 'V2Components'
import { useResourcesViewContext } from 'features/resources'
import { SelectDropDown, ToggleCloudControl } from 'procyon-ui'
import React from 'react'

const ResourceFilterHeader = ({
  cloudOptions,
  dropDownItems,
  disableAccountControls,
  activeCloudType,
  setActiveCloudType,
  selectedDropdownValue,
  setSelectedDropdownValue,
  HeaderFilter = null,
  searchKey,
  setSearchKey,
  suggestions = [],
  defaultSuggestions = [],
  filterSaveKey,
  AdvancedSearchComponent = null
}) => {
  const { filterOptions } = useResourcesViewContext()

  return (
    <div className='flex justify-between items-center'>
      {!filterOptions.hideCloudOptions && (
        <div className='flex gap-4'>
          {!filterOptions.hideCloudToggle && (
            <ToggleCloudControl
              options={cloudOptions}
              disabled={disableAccountControls}
              value={activeCloudType}
              ariaLabel='cloud-select'
              onChange={(_, values) => {
                if (values) {
                  setActiveCloudType(values)
                  setSelectedDropdownValue('')
                }
              }}
              multiSelect={false}
            />
          )}

          {!filterOptions.hideDropdown && activeCloudType !== 'PRIVATE' && (
            <SelectDropDown
              sx={{ minWidth: '300px' }}
              showHelpText={false}
              // @ts-ignore
              onChange={(e) => setSelectedDropdownValue(e.target.value)}
              disabled={disableAccountControls}
              menuItems={[
                {
                  label: 'All',
                  selected: false,
                  value: '',
                  disabled: false
                },
                ...dropDownItems
              ]}
              value={selectedDropdownValue}
            />
          )}
          {HeaderFilter}
        </div>
      )}
      <div className='flex gap-2 items-center'>
        {AdvancedSearchComponent && AdvancedSearchComponent}
        <AdvancedSearch
          filterSaveKey={filterSaveKey}
          defaultSuggestions={defaultSuggestions}
          suggestions={suggestions}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
      </div>
    </div>
  )
}

export { ResourceFilterHeader }
