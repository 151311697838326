import { DEVELOPMENT_CONFIG } from './development'
import { PRODUCTION_CONFIG } from './production'
let SITE_CONFIG = {}
switch (process.env.REACT_APP_STAGE) {
  case 'development':
    SITE_CONFIG = DEVELOPMENT_CONFIG
    break
  case 'production':
    SITE_CONFIG = PRODUCTION_CONFIG
    break
  default:
    SITE_CONFIG = DEVELOPMENT_CONFIG
}
SITE_CONFIG.Namespace = 'default'
SITE_CONFIG.publicUrl = '/ui'
SITE_CONFIG.credentialColors = ['#f4b85c', '#428ff5', '#f56a5b', '#8fa825', '#cef52a', '#7989a6']
SITE_CONFIG.rowHeight = '3rem'
SITE_CONFIG.newtableHoverActionHeigt = '2.7rem'
export default SITE_CONFIG
