import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { Button, GenericResourceRow, IconButton, Label } from 'procyon-ui'
import React from 'react'

const IamRsrc = ({ rsrc, rsrcAttributesSpec, getObjectRef, handleRsrcRolesSelect }) => {
  const Icon = getRsrcIcon(rsrc)
  const iamRoles = getObjectRef(rsrcAttributesSpec[createRsrcKey(rsrc)]?.IamRoles) || []

  return (
    <div className='w-[100%] mb-2' key={createRsrcKey(rsrc)}>
      <GenericResourceRow
        className='w-[100%]'
        accountName=''
        children1={
          <div className='flex gap-2 flex-wrap'>
            {iamRoles.map((role) => {
              return (
                <Label variant='grayBlue' key={createRsrcKey(role)} text={getResourceName(role)} />
              )
            })}
          </div>
        }
        children2={
          iamRoles.length > 0 ? (
            <IconButton
              icon={faPen}
              variant='grayBlue'
              onClick={() => handleRsrcRolesSelect(rsrc)}
            />
          ) : (
            <Button
              className='!w-[150px]'
              onClick={() => handleRsrcRolesSelect(rsrc)}
              variant='grayBlue'
            >
              Select Roles
            </Button>
          )
        }
        region=''
        caption={getIAMResourceDisplayType(rsrc)}
        resourceIcon={<Icon className='rounded' />}
        title={getResourceName(rsrc)}
      />
    </div>
  )
}

export { IamRsrc }
