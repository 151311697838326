// @ts-nocheck
import DebugForm from 'Components/DebugForm'
import Loader from 'Components/Loader'
import { initDump } from 'Core/DebugUtils'
import { FeatureFlagProvider } from 'Core/FeatureFlagContext'
import BootProvider from 'Core/Hooks/useBoot/BootProvider'
import { AuthenticatedRoute } from 'features/layout'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Routes } from 'routes'
import { debug } from 'Utils/DebugHelper'

const SignupComplete = React.lazy(() => import('./Views/Signup/Components/SignupComplete'))
const Signup = React.lazy(() => import('./Views/Signup'))
const Register = React.lazy(() => import('./Views/Register'))
const TenantLogin = React.lazy(() => import('features/TenantLogin'))
const TenantSignup = React.lazy(() => import('features/TenantSignup'))

window.debug = debug

initDump()

const App = () => {
  const isAuthenticated = useSelector((state) => state.user.loggedIn)

  return (
    <BootProvider>
      <Switch>
        <Route path='/signupverify' component={SignupComplete} />
        <Route path='/signup' component={Signup} />
        <Route path='/register' component={Register} />
        <Route path='/tenant-login' component={TenantLogin} />
        <Route path='/tenant-signup' component={TenantSignup} />
        <FeatureFlagProvider Fallback={<Loader />}>
          <AuthenticatedRoute isAuthenticated={isAuthenticated} path='/' component={Routes} />
        </FeatureFlagProvider>
      </Switch>
      <DebugForm />
    </BootProvider>
  )
}

export default App
