import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { RelationAccessGraph } from 'V2Components'
import {
  BUNDLE_REQ_TYPE,
  getBundleAttachedIAMActionsResources,
  getBundleAttachedPrincipals,
  getBundleIAMActions,
  getBundleResourcesRefs
} from 'features/bundles/utils'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { useSalesForceUtils } from 'features/salesForce'
import { TargetsSliceNames, getK8RsrcsPropertiesFromPrincipal } from 'features/targets'
import React, { useEffect } from 'react'

function BundleAccessGraph({ bundle }) {
  const { getObjectRef, dispatchThunks } = useMultiSlice([
    'awsResources',
    'gcpResources',
    'azureResources',
    'iamActions',
    'salesForcePermissionList',
    ...TargetsSliceNames
  ])

  const { getCRMEntityApplication } = useSalesForceUtils()

  useEffect(() => {
    dispatchThunks()
  }, [])

  const getResources = () => {
    const rsrcObjs = []
    const refs = getBundleResourcesRefs(bundle)

    refs.forEach((ref) => {

      if (ref.RefKind === 'CRMEntity') {
        const sfApp = getCRMEntityApplication(ref)
        if (sfApp) rsrcObjs.push(sfApp)
        console.log('[bundle](sf):', sfApp)
        return
      }
      const rsrc = getObjectRef(ref)
      if (rsrc) rsrcObjs.push(rsrc)
    })

    return rsrcObjs
  }

  const getSalesforceCRMNode = () => {
    // for SF the resource refs are the CRMEntity ie the permission
    const refs = getBundleResourcesRefs(bundle)
    if (refs.some((e) => e.RefKind !== 'CRMEntity')) return []
    return getObjectRef(refs)
  }

  console.log('[bundle](rsrc)=>>:', getResources())
  const getPreDataNodes = () => {
    const principalsMap = getBundleAttachedPrincipals(bundle)
    const data = []

    for (const rsrcKey in principalsMap) {
      if (!rsrcKey.includes('KubeNamespace')) continue
      const rsrc = getObjectRef(reverseRsrcKey(rsrcKey))
      const principalStr = principalsMap[rsrcKey]
      const items = getK8RsrcsPropertiesFromPrincipal(principalStr)

      items.forEach((e) => {
        data.push({
          label: `${e.name} (${getResourceName(rsrc)})`,
          id: e.id,
          key: e.key,
          description: e.type,
          type: e.iconType
        })
      })
    }

    // same pattern as getNodes()
    return [[], [], data]
  }

  const getNodes = () => {
    const rsrcs = getResources()
    const principalsMap = getBundleAttachedPrincipals(bundle)
    const iamActions = getObjectRef(getBundleIAMActions(bundle))
    const sfCrmNodes = getSalesforceCRMNode()

    return [
      [bundle],
      iamActions,
      sfCrmNodes,
      rsrcs.filter((r) => {
        if (r.ObjectMeta.Kind === 'KubeNamespace' && principalsMap[createRsrcKey(r)]) return false
        return true
      })
    ]
  }

  const getNodesRelationObject = () => {
    const relationMap = {}
    const bundleKey = createRsrcKey(bundle)

    const preDataNodeObjects = getPreDataNodes()

    preDataNodeObjects?.forEach((nodes) => {
      nodes.forEach((node) => {
        relationMap[bundleKey] = relationMap[bundleKey] || []
        relationMap[bundleKey].push(node.key)
      })
    })

    if (!bundle.ReqType || bundle.ReqType === BUNDLE_REQ_TYPE.IAMAction) {
      //bundle to iam actions

      //bundle iam actions
      const iamActions = getBundleIAMActions(bundle).map(createRsrcKey)
      iamActions.forEach((k) => {
        relationMap[bundleKey] = relationMap[bundleKey] || []
        relationMap[bundleKey].push(k)
      })

      const rsrcIamActionsMap = getBundleAttachedIAMActionsResources(bundle)

      for (const rsrcKey in rsrcIamActionsMap) {
        const actionsKey = rsrcIamActionsMap[rsrcKey].map(createRsrcKey)

        actionsKey.forEach((k) => {
          relationMap[k] = relationMap[k] || []
          relationMap[k].push(rsrcKey)
        })
      }
    }

    if (bundle.ReqType === BUNDLE_REQ_TYPE.Target) {
      //Bundle to resources directly

      const rsrcs = getBundleResourcesRefs(bundle)

      rsrcs.forEach((ref) => {
        const k = createRsrcKey(ref)
        //bundle to rsrc key
        relationMap[bundleKey] = relationMap[bundleKey] || []
        relationMap[bundleKey].push(k)
      })
    }

    if (bundle.ReqType === BUNDLE_REQ_TYPE.Salesforce) {
      //Bundle to CRM Entity
      const crms = getBundleResourcesRefs(bundle)
      relationMap[bundleKey] = [createRsrcKey(crms[0])]
      const sfApp = getCRMEntityApplication(crms[0])
      relationMap[createRsrcKey(crms[0])] = sfApp ? [createRsrcKey(sfApp)] : []
    }

    return relationMap
  }

  const getNodesAttributes = () => {
    const map = {}
    const principalsMap = getBundleAttachedPrincipals(bundle)
    for (const rsrcKey in principalsMap) {
      const principal = principalsMap[rsrcKey]
      if (!rsrcKey.includes('KubeNamespace')) {
        map[rsrcKey] = {
          description: `Principal: (${principal})`
        }
      }
    }
    return map
  }

  return (
    <div>
      <RelationAccessGraph
        nodeAttributesObject={getNodesAttributes()}
        preDataNodeObjects={getPreDataNodes()}
        nodeObjects={getNodes()}
        relationObject={getNodesRelationObject()}
      />
    </div>
  )
}

export { BundleAccessGraph }
