import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  adminTargets: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  userTargets: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  userResources: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  adminResources: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  userApplications: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  adminApplications: {
    data: null,
    status: 'idle',
    disableCloudSync: false
  },
  workloadPolicy: {
    data: {
      CartQueues: {
        CartQueue: []
      }
    },
    status: 'idle',
    disableCloudSync: true
  }
}
/**
 *
 * @typedef { 'loading'|'loaded' } Status
 */
const cartSlice = createSlice({
  name: 'carts',
  initialState,
  reducers: {
    /**
     *
     * @param {{ type: string, payload: { key:import('features/resources').key, data: any }}} action
     */
    updateCart(state, action) {
      const { key, data } = action.payload
      state[key].data = data
      return state
    },
    /**
     * @param {{ type: string, payload: { key:import('features/resources').key, data: any }}} action
     */
    addToCart(state, action) {
      const { key, data } = action.payload
      if (state[key]?.data) {
        state[key].data.CartQueues.CartQueue.push(data)
        return state
      }
      return state
    },
    /**
     *
     * @param {{ type: string, payload: { key:import('features/resources').key, data: any }}} action
     */
    updateCartQueue(state, action) {
      const { key, data } = action.payload
      const queue = state[key].data?.CartQueues.CartQueue || []
      const index = _.findIndex(queue, { Resource: data.Resource })
      if (index !== -1) {
        queue[index] = { ...queue[index], ...data }
        state[key].data.CartQueues.CartQueue = queue
      }
      return state
    },
    /**
     *
     * @param {{ type: string, payload: { key:import('features/resources').key}}} action
     */
    clearCart(state, action) {
      if (state[action.payload.key]?.data) state[action.payload.key].data.CartQueues.CartQueue = []
      return state
    },
    /**
     *
     * @param {{ type: string,payload:{  status:Status, key: import('features/resources').key } }} action
     */
    updateCartStatus(state, action) {
      const { status, key } = action.payload
      if (state[key]) state[key].status = status
      return state
    },
    /**
     *
     * @param {{ type: string,payload:{  resource:any, key: import('features/resources').key } }} action
     */
    removeResourceFromCart(state, action) {
      const { resource, key } = action.payload
      if (state[key].data) {
        const queue = state[key].data.CartQueues.CartQueue || []
        const newQueue = queue.filter((e) => !_.isEqual(e.Resource, resource))
        state[key].data.CartQueues.CartQueue = newQueue
      }
      return state
    }
  }
})

export const { updateCart, addToCart, updateCartQueue, clearCart, updateCartStatus, removeResourceFromCart } =
  cartSlice.actions
export default cartSlice.reducer
