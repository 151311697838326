import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LabelContent } from 'V2Components'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { isCustomIAMRole, useIAMRoleAccess } from 'features/iamRoles'
import { ResourcesSummaryView, getResourceName, reverseRsrcKey } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'

const OverviewTab = () => {
  const iamrole = useDataDetailsContext()
  const { getObjectRef } = useObjectRef(IAMResourcesSliceNames)
  const { getIAMRoleAttachedRsrcs } = useIAMRoleAccess()

  const getResources = () => {
    return getObjectRef(getIAMRoleAttachedRsrcs(iamrole).map(reverseRsrcKey))
  }

  return (
    <div>
      <Typography variant='h4-regular'>Role Information</Typography>
      <div className='flex gap-4'>
        <div className='w-1/2'>
          <LabelContent title='Role Name' content={getResourceName(iamrole)} />
          <LabelContent title='Type' content={!isCustomIAMRole(iamrole) ? 'Pre-Defined' : 'Custom'} />
          <LabelContent title='Description' content={iamrole.Spec.Description} />
          <LabelContent
            title='Actions'
            content={iamrole.Spec.Actions.Elems.map((e) => (
              <Typography key={e} className='!text-[#8D94A1]' variant='body-regular'>
                {e}
              </Typography>
            ))}
          />
        </div>
        <div className='w-1/2'>
          <ResourcesSummaryView resourceAttibutesObject={{}} resources={getResources()} />
        </div>
      </div>
    </div>
  )
}

export { OverviewTab }
