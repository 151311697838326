import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { cn } from 'Utils/Helpers'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { LabelContent } from 'V2Components'
import {
  AppBackendURLKey,
  AppFrontendURLKey,
  AppHTTPEntryPathKey,
  AppMeshClusterKey,
  AppSSOURLKey,
  AppServiceProviderMetadatKey,
  getApplicationType,
  useAppDetailsContext
} from 'features/applications'
import {
  ManageOwnersView,
  getResourceName,
  getResourcePolicyTags,
  getRsrcIcon
} from 'features/resources'
import { reduxApiClient } from 'infra'

import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import moment from 'moment'
import { InformationRow, Label, RiskIndicator, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
const useSlices = createDataSelectorHook([
  'githubAccount',
  'githubResources',
  'salesForceAccountList'
])

function OverviewTab() {
  const { app } = useAppDetailsContext()
  const { user, rdpurl, isAgentlessMode } = useUser()
  const { appView } = useAppView()
  const appType = getApplicationType(app)
  const { getObjectRef } = useMultiSlice(['accountList'])
  const Icon = getRsrcIcon(app)
  const account = getObjectRef(app?.Spec.Account)
  const AccountIcon = getAccountIcon(account?.Spec.Type)
  const [repoCount, setRepoCount] = useState(Number)
  const [sFedittion, setSFedittion] = useState('')
  const { slices } = useSlices()

  useEffect(() => {
    if (app.Spec.AppType === 'github') {
      RepoCount()
    }
    if (app.Spec.AppType === 'salesforce') {
      getSfAccountEdition(app)
    }
  }, [])

  const getSfAccountEdition = async (app) => {
    try {
      const accountList = await reduxApiClient('salesforce-account').getAll()
      const filterCurrentAccount = await accountList?.find(
        (account) => account.Application.RefID === app.ObjectMeta.ID
      )
      setSFedittion(filterCurrentAccount?.Spec.Edition)
    } catch (e) {
      console.log(e)
    }
  }

  const RepoCount = async () => {
    const currentAccount = _.find(slices.githubAccount, {
      Application: { RefID: app?.ObjectMeta?.ID }
    })
    const currentUserWithWriteAccess = currentAccount?.ObjectMeta?.WrOwners?.ObjectRef?.find(
      (member) => member?.RefID === user?.ObjectMeta?.ID
    )

    let filterRepoCard = []

    if (appView === 'user') {
      const currentUserAccess = await currentAccount?.Spec?.Members?.Members?.find(
        (member) => member?.memberRef?.RefID === user?.ObjectMeta?.ID
      )

      if (
        (currentUserAccess?.ScimProvisonedIdentity || currentUserWithWriteAccess) &&
        slices.githubResources
      ) {
        filterRepoCard = slices.githubResources.filter(
          (obj) =>
            obj.Spec.Type === 'repository' &&
            obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID
        )
      }
    } else if (slices.githubResources) {
      filterRepoCard = slices.githubResources.filter(
        (obj) =>
          obj.Spec.Type === 'repository' &&
          obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID
      )
    }
    setRepoCount(filterRepoCard.length)
  }

  const getFnUrlWithAgentLessCheck = () => {
    let url = _.get(app, AppFrontendURLKey)
    if (isAgentlessMode) {
      return url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
    } else {
      return url
    }
  }

  const policyTags = Object.values(getResourcePolicyTags(app))

  return (
    <div className='flex gap-8'>
      {/* Hide the border in user view, because the other div which shows owners won't be shown */}
      <div
        className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8', {
          'border-none': appView === 'user'
        })}
      >
        <Typography variant='h4-regular'>Resource Information</Typography>
        <div className='mt-8'>
          {getApplicationType(app) === 'GITHUB'}{' '}
          {
            <InformationRow
              title='Status'
              titleContainerStyle={{
                width: '228px'
              }}
            >
              {app.Spec.AppType === 'salesforce' ? (
                <div>
                  {/* <Typography variant='body-regular'>
                    {app.Spec.SalesforceAppConfig?.Status?.Error}
                  </Typography> */}
                  <RiskIndicator
                    variant={
                      app.Spec.SalesforceAppConfig?.Status?.Status === 'Fail' ? 'offline' : 'online'
                    }
                  />
                </div>
              ) : (
                <RiskIndicator variant='online' />
              )}
            </InformationRow>
          }
          <LabelContent title='Application Name' content={getResourceName(app)} />
          <LabelContent
            title='Application Type'
            content={
              <Label
                //@ts-ignore
                text={
                  <div className='flex items-center'>
                    {Icon && <Icon />}
                    <strong>{appType}</strong>
                  </div>
                }
              />
            }
          />
          {account && (
            <LabelContent
              title='Account'
              content={
                <div className='flex gap-2 items-center'>
                  {AccountIcon && <AccountIcon />}
                  {getResourceName(account)}
                </div>
              }
            />
          )}
          {getApplicationType(app) === 'HTTP' && (
            <>
              <LabelContent
                title='Frontend Name'
                content={<Label className='!normal-case' text={getFnUrlWithAgentLessCheck()} />}
              />
              <LabelContent
                title='Frontend URL path'
                content={_.get(app, AppHTTPEntryPathKey, '/')}
              />
              <LabelContent
                title='Backend Name'
                content={<Label className='!normal-case' text={_.get(app, AppBackendURLKey)} />}
              />
              <LabelContent
                title='Mesh Cluster'
                content={<Label className='!normal-case' text={_.get(app, AppMeshClusterKey)} />}
              />
            </>
          )}
          {getApplicationType(app) !== 'SALESFORCE' && (
            <LabelContent
              showEmptyLabel
              title='Tags'
              content={
                policyTags.length > 0 && (
                  <div className='flex gap-2 items-center'>
                    {policyTags.map((e) => (
                      <Label text={e} />
                    ))}
                  </div>
                )
              }
            />
          )}
          {getApplicationType(app) === 'SALESFORCE' && (
            <LabelContent title={'Edition'} content={sFedittion} />
          )}
          {getApplicationType(app) === 'AWS' && (
            <>
              <LabelContent
                title='AWS Account ID'
                content={_.get(app, 'Spec.AwsFederationConfig.AwsAccountID')}
              />
              <LabelContent
                title='AWS IDProvider ARN'
                content={_.get(app, 'Spec.AwsFederationConfig.AwsIdproviderARN')}
              />
            </>
          )}
          {getApplicationType(app) === 'DROPBOX' && (
            <>
              <LabelContent title='Service Provider ACS URL' content={_.get(app, AppSSOURLKey)} />
              <LabelContent
                title='Service Provider Metadata'
                content={
                  <div className='p-2 rounded bg-gray-100'>
                    {_.get(app, AppServiceProviderMetadatKey)}
                  </div>
                }
              />
            </>
          )}
          {getApplicationType(app) === 'GITHUB' && (
            <>
              <LabelContent
                title='SSO Url'
                content={_.get(app, AppSSOURLKey).replace(/saml$/, 'sso')}
              />
              <LabelContent title='Application ID' content={_.get(app, 'ObjectMeta.ID')} />
              <LabelContent title='Service Type' content={'SSO'} />
              <LabelContent title='Repo Count' content={repoCount} />
            </>
          )}
          <LabelContent
            title='Created On'
            content={moment(app?.ObjectMeta.CreatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
          <LabelContent
            title='Last Updated On'
            content={moment(app?.ObjectMeta.UpdatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
        </div>
      </div>
      {/* 
          Hide owners on user view
      */}
      {appView === 'admin' && appType !== 'GITHUB' && (
        <div className='w-1/2'>
          <ManageOwnersView rsrc={app} />
        </div>
      )}
    </div>
  )
}

export { OverviewTab }
