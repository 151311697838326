import React from 'react'
import { Route } from 'react-router'
import { AdminTargetDetails, AdminTargets, UserTargetDetails, UserTargets } from '../views'

const TargetsRoutes = () => {
  return (
    <>
      <Route exact path={['/admin/targets', '/admin/targets/:cloudType']} component={AdminTargets} />
      <Route exact path='/admin/targets/:kind/:targetName' component={AdminTargetDetails} />
      <Route exact path={['/user/targets', '/user/targets/:cloudType']} component={UserTargets} />
      <Route exact path='/user/targets/:kind/:targetName' component={UserTargetDetails} />
    </>
  )
}

export { TargetsRoutes }
