import { LOGIN_USER, LOGOUT_USER, USER_ORG_UPDATE } from "../../actions"

const initState = {
  loggedIn: false
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const userObject = action.payload
      localStorage.setItem('user', JSON.stringify(userObject))
      return {
        ...userObject, loggedIn: true
      } }
    case LOGOUT_USER:
      localStorage.removeItem('user')
      return {
        loggedIn: false
      }
    case USER_ORG_UPDATE:
      sessionStorage.setItem('org', action.payload.activeOrg)
      return { ...state, activeOrg: action.payload.activeOrg }
    default:
      return state
  }
}

export default userReducer
