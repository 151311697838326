import { _ } from 'Core'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { updateSliceMap } from 'infra/redux/sliceHandlers'
import { LoadingFeedback } from 'V2Components'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import React, { createContext, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { fetchApprovalRequest } from '../api'
import { getApprovalRequestResourcesRefs } from '../utils'
import { createFakeServiceAccountObject } from './utils'

const initialContext = {
  approvalRequest: null,
  paaPolicy: null,
  resources: [],
  requestForUsersGroups: [],
  requestor: null,
  createdPolicy: null
}
/**
 * @type {import('types').SliceNames[]}
 */
const _rsrcsSlices = [
  'awsResources',
  'gcpResources',
  'serverList',
  'appRolesList',
  'paaPolicyList',
  'azureResources',
  'kubeNamespaces',
  'serviceAccounts',
  'githubResources',
  'databases',
  'applicationList',
  'kafkas',
  'kubeClusters',
  'rdpServers',
  'bundles'
]
const useSlices = createDataSelectorHook(['approvalReqList', 'paaPolicyList', 'policyList'])

export const ApprovalsDetailsContext = createContext(initialContext)

export const ApprovalsDetailsProvider = ({ children }) => {
  // @ts-ignore
  const { requestName: param } = useParams()
  const requestName = decodeURIComponent(param)
  const isFetchStarted = useRef(false)
  const [approvalRequest, setApprovalRequest] = useState(null)
  const [paaPolicy, setPaaPolicy] = useState(null)
  const [createdPolicy, setCreatedPolicy] = useState(null)
  const [bundle, setBundle] = useState(null)
  const { getObjectRef } = useObjectRef(_rsrcsSlices)
  const { slices } = useSlices()

  const resources = (() => {
    const list = []
    if (!approvalRequest) return []
    if (approvalRequest.Type === 'ServiceAccount') {
      if (approvalRequest.Status === 'Approved') {
        const sa = getObjectRef(approvalRequest.ServiceAccount)
        if (sa) return [sa]
      }
      return [createFakeServiceAccountObject(approvalRequest)]
    } else {
      const resourcesRefs = getApprovalRequestResourcesRefs(approvalRequest)
      resourcesRefs.forEach((ref) => {
        const refObj = getObjectRef(ref)
        if (refObj) list.push(refObj)
      })
    }
    return list
  })()

  const requestForUsersGroups = (() => {
    const list = []
    if (!approvalRequest) return []
    const usersGroupsRefs = _.get(approvalRequest, 'Spec.RequestedFor.ObjectRef', [])
    usersGroupsRefs.forEach((ref) => {
      const refObj = getObjectRefFlat(ref)
      if (refObj) list.push(refObj)
    })
    return list
  })()

  const requestor = (() => {
    if (!approvalRequest) return null
    const userObj = getObjectRefFlat(approvalRequest.Requestor)
    if (userObj) return userObj
    return null
  })()

  const fetchAndUpdateApprovalReq = async () => {
    const data = await fetchApprovalRequest(requestName)
    updateSliceMap(data)
  }

  if (slices.approvalReqList) {
    const req = _.find(slices.approvalReqList, {
      ObjectMeta: { Name: requestName }
    })
    if (req && req !== approvalRequest) setApprovalRequest(req)
  }

  if (slices.policyList && !createdPolicy && approvalRequest) {
    const policy = _.find(slices.policyList, {
      ObjectMeta: { Name: approvalRequest.ObjectMeta.Name }
    })
    if (policy) setCreatedPolicy(policy)
  }

  /**
   * Look for auto approve policy, if it exists set paa Policy
   */
  if (!paaPolicy && approvalRequest && slices.paaPolicyList.length) {
    const policy = _.find(slices.paaPolicyList, {
      ObjectMeta: { Name: approvalRequest.ObjectMeta.Name }
    })
    if (policy) setPaaPolicy(policy)
  }

  if (!bundle && approvalRequest && approvalRequest.BundleRef.RefID !== '0') {
    const bundle = getObjectRef(approvalRequest.BundleRef)
    if (bundle) setBundle(bundle)
  }

  if (!isFetchStarted.current && !approvalRequest) {
    isFetchStarted.current = true
    fetchAndUpdateApprovalReq()
  }

  return (
    <ApprovalsDetailsContext.Provider
      value={{
        approvalRequest,
        resources,
        requestForUsersGroups,
        requestor,
        createdPolicy,
        paaPolicy,
        bundle
      }}
    >
      {approvalRequest && children}
      {!approvalRequest && (
        <LoadingFeedback
          shrinked
          loading
          message='Fetching Access Request'
          caption='Please wait..'
          shadow={false}
        />
      )}
    </ApprovalsDetailsContext.Provider>
  )
}
