import { FilterBarCategory, FilterBarItem } from 'procyon-ui'
import React from 'react'

const CheckboxFilter = ({ activeFilters = [], onActiveFilterChange }) => {
  const filterItems = [
    {
      label: 'Application Filter',
      filters: [
        {
          label: 'Snowflake',
          value: 'snowflake',
          checked: activeFilters.includes('snowflake')
        },
        {
          label: 'Databricks',
          value: 'databricks',
          checked: activeFilters.includes('databricks')
        }
      ]
    }
  ]

  const toggleResourceTypeFilter = (filter) => {
    let newFilters = []
    if (activeFilters.includes(filter)) newFilters = activeFilters.filter((e) => e !== filter)
    else newFilters = [filter, ...activeFilters]
    onActiveFilterChange && onActiveFilterChange(newFilters)
  }

  return (
    <div>
      {filterItems.map((e) => (
        <>
          <FilterBarCategory key={e.label} name={e.label}>
            {e.filters.map((filter) => (
              <FilterBarItem
                key={filter.value}
                amount={filter.amount?.toString()}
                name={filter.label}
                checked={activeFilters.includes(filter.value)}
                onClick={() => toggleResourceTypeFilter(filter.value)}
              />
            ))}
          </FilterBarCategory>
        </>
      ))}
    </div>
  )
}

export { CheckboxFilter }
