import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { FullScreenContentModal } from 'V2Components'
import {
  ApplicationDetailsProvider,
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  isReadOnlyApp,
  useAppDetailsContext,
  useApplicationAccess
} from 'features/applications'
import {
  AccessCartProvider,
  createRef,
  createRsrcKey,
  useAccessCartProvider
} from 'features/resources'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { AddApplication } from '../AddApplication'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { ApiProvider } from 'Core'
import { useUser } from 'Core/Hooks/useUser'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { reduxApiClient } from 'infra'
import { CreatePermissionBundleModal } from './components/CreatePermissionBundleModal'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

function ApplicationDetails({}) {
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const history = useHistory()
  const { appView } = useAppView()
  const { currentUserIsAdmin } = useUser()
  const { addItemsToCart, isResourceInCart, isCartEmpty, cartItems } = useAccessCartProvider()
  const { app, isAllowedAccess } = useAppDetailsContext()
  const handleEditClick = () => setShowEditAppModal(true)
  const handleDeleteClick = () => setShowDeleteAppModal(true)
  const {
    slices,
    selectDispatch,
    getObjectRef: getRsrcsRef
  } = useMultiSlice([
    'githubAccount',
    'githubResources',
    'applicationList',
    'salesForceAccountList',
    'salesForcePermissionList'
  ])
  const [nonHttp, setNonHttp] = useState(['Dropbox', 'github'])
  //Reaonly Apps cannot be signed in
  const signInAllowed = !isReadOnlyApp(app) && (currentUserIsAdmin || isAllowedAccess)
  const canGoBack = useCanGoBack()
  const [showCreateBundleModal, setShowCreateBundleModal] = useState(false)
  const [showScanning, setShowScanning] = useState(false)
  const pollingInterval = useRef(null)
  const { getApplicationRsrcsAccessMap } = useApplicationAccess()
  const accessObject = getApplicationRsrcsAccessMap()

  useEffect(() => {
    selectDispatch(['applicationList'])
  }, [])

  useEffect(() => {
    return () => {
      clearInterval(pollingInterval.current)
    }
  }, [])

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const handleAccessClick = () => {
    if (app.Spec.AppType === 'http') {
      return addItemsToCart({
        resourceRef: createRef(app),
        principal: '',
        roles: []
      })
    }
    //@ts-ignore
    if (isResourceInCart(createRef(app)) || !isCartEmpty) return
    addItemsToCart({
      resourceRef: createRef(app),
      principal: '',
      roles: []
    })
    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: true
      })
    } else {
      handleViewsChange({
        accessRequestSubmissionModal: true
      })
    }
  }

  const githubSync = async () => {
    const getAccount = await new ApiProvider('github-account').getAll()
    const filteredAccount = getAccount?.data?.GithubAccounts.find(
      (Obj) => Obj.ObjectMeta.Name === app.ObjectMeta.Name
    )
    filteredAccount.Sync = true
    await new ApiProvider('github-account').setInstance(filteredAccount).put()
    selectDispatch(['githubResources'])
    enqueueNotification('Sync updated', 'info')
  }

  const getDisableState = () => {
    //  check if http same rsrc is already exist in cart
    const existingCartItemsappType = []
    if (!isCartEmpty && app.Spec.AppType === 'http') {
      cartItems?.forEach(({ Resource }) => {
        let rsrc = null
        rsrc = getRsrcsRef(Resource)
        if (!rsrc) return
        const appType = getIAMResourceDisplayType(rsrc)
        existingCartItemsappType.push(appType)
      })

      if (!existingCartItemsappType.some((type) => nonHttp.includes(type))) {
        if (!isResourceInCart(createRef(app)) && app.Spec.AppType === 'http') {
          return false
        }
      } else {
        return true
      }
    }

    if (!isCartEmpty || (appView === 'user' && isAllowedAccess)) {
      return true
    }
  }

  const salesForceSync = async () => {
    setShowScanning(true)
    const currentAccount = await reduxApiClient('salesforce-account').getAll()
    const filteredAccount = currentAccount?.find(
      (obj) => obj.Application.RefID === app.ObjectMeta.ID
    )

    if (filteredAccount?.Status?.Status) {
      filteredAccount.Status.Status = {
        Status: 'started',
        Error: ''
      }

      await reduxApiClient('salesforce-account').update(filteredAccount)

      const checkSyncStatus = async () => {
        const currentAccount = await reduxApiClient('salesforce-account').getAll()
        const filteredAccount = currentAccount?.find(
          (obj) => obj.Application.RefID === app.ObjectMeta.ID
        )
        if (filteredAccount.Status.Status.Status === 'success') {
          clearInterval(pollingInterval.current)
          enqueueNotification('Sync updated', 'info')
          reduxApiClient('salesforce-users').getAll()
          setShowScanning(false)
        }
      }

      pollingInterval.current = setInterval(checkSyncStatus, 10000)
    } else {
      setShowScanning(false)
    }
  }

  const handleSync = () => {
    switch (app.Spec.AppType) {
      case 'github':
        return githubSync()
      case 'salesforce':
        return salesForceSync()
    }
  }

  const getSignInStatus = () => {
    if (app.Spec.AppType === 'salesforce' && appView === 'user') {
      const crmEntityRef = accessObject && Object.keys(accessObject)[0]?.split('+')
      if (crmEntityRef) {
        const getCrmObject = slices?.salesForcePermissionList?.find(
          (crm) => crm.ObjectMeta.ID === crmEntityRef[1]
        )
        const filterSfAccount = slices?.salesForceAccountList?.find(
          (sfaccount) => sfaccount.ObjectMeta.ID === getCrmObject?.SalesforceAccount?.RefID
        )
        const sfApp = slices?.applicationList?.find(
          (apps) => apps.ObjectMeta.ID === filterSfAccount?.Application?.RefID
        )
        if (createRsrcKey(sfApp) === createRsrcKey(app)) {
          return true
        }
      }
    } else {
      return signInAllowed
    }
  }

  return (
    <div>
      <Header
        onAccessClick={handleAccessClick}
        disableAccessBtn={getDisableState()} //disable when cart is not empty and disable if it is userview and the app is already allowed access
        onDelete={handleDeleteClick}
        onEdit={handleEditClick}
        showSignIn={getSignInStatus()}
        app={app}
        onSync={handleSync}
        isSyncDisabled={false}
        setShowCreateBundleModal={setShowCreateBundleModal}
        showScanning={showScanning}
      />
      <div className='mt-6'>
        <Tabs />
      </div>
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            if (canGoBack) history.goBack()
            else history.push(`/${appView}/applications`)
          }}
          onCancel={() => setShowDeleteAppModal(false)}
          app={app}
        />
      )}
      {showEditAppModal && (
        <FullScreenContentModal>
          <AddApplication
            type=''
            onComplete={() => setShowEditAppModal(false)}
            app={app}
            onClose={() => setShowEditAppModal(false)}
          />
        </FullScreenContentModal>
      )}

      {showCreateBundleModal && (
        <CreatePermissionBundleModal
          setShowCreateBundleModal={setShowCreateBundleModal}
          showCreateBundleModal={showCreateBundleModal}
        />
      )}

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
    </div>
  )
}

const WrappedApplicationDetails = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <ApplicationDetailsProvider>
      <AccessCartProvider key={cartKey} cartKey={cartKey}>
        <ApplicationDetails />
      </AccessCartProvider>
    </ApplicationDetailsProvider>
  )
}

export { WrappedApplicationDetails as ApplicationDetails }
