import { Button, Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useChannelBrowser } from '../../../providers/hooks'
import { SelectChannel } from '../../Modal'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { createRsrcKey } from 'features/resources'
import { getFriendlyName } from 'Utils/FriendlyName'
import { useUser } from 'Core/Hooks/useUser'
import { createDataSelectorHook } from 'infra/redux'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { reduxApiClient } from 'infra'
const useSlices = createDataSelectorHook([
  'msTeamsChannels',
  'msTeams',
  'notificationSubscriptions'
])

const MSTeam = () => {
  const { integration } = useChannelBrowser()
  const { slices } = useSlices()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationType, setNotificationType] = useState('')
  const { isAgentlessMode } = useUser()

  const getPrivateNS = () => {
    return slices.notificationSubscriptions.find((e) => {
      const { SubscriptionFor, Subscriber } = e.Spec
      if (
        SubscriptionFor.RefID === '0' &&
        SubscriptionFor.RefKind === 'Server' &&
        Subscriber.RefKind === 'MsTeamsChannel'
      )
        return true
    })
  }

  /**
   * check is private webhook channel is aready exist
   */

  const webookPrivateChannel = () => {
    const foundSubscription = slices.notificationSubscriptions.find((e) => {
      const { Subscriber } = e.Spec
      if (
        integration?.Spec?.WebhookUrl !== '' &&
        Subscriber.RefKind === 'MsTeamsChannel' &&
        Subscriber.RefID === integration?.ObjectMeta?.ID
      ) {
        return true
      } else {
        return false
      }
    })
    return foundSubscription
  }

  const getPrivateServerChannel = () => {
    const privtNotificationChannel = getPrivateNS()
    if (privtNotificationChannel) {
      const { Subscriber } = privtNotificationChannel.Spec
      let subsriberObj = slices.msTeamsChannels.find((e) => e.ObjectMeta.ID === Subscriber.RefID)
      return subsriberObj
    }
    return
  }

  const getApplicationTb = () => {
    return slices.notificationSubscriptions.find((e) => {
      const { SubscriptionFor } = e.Spec
      if (
        SubscriptionFor.RefID === '0' &&
        SubscriptionFor.RefKind === 'Account' &&
        e.Spec.Subscriber.RefKind === 'MsTeamsChannel'
      )
        return true
    })
  }

  const getApplicationTable = () => {
    const ApplicationTable = getApplicationTb()
    if (ApplicationTable) {
      const { Subscriber } = ApplicationTable.Spec
      let subsriberObj = slices.msTeamsChannels.find((e) => e.ObjectMeta.ID === Subscriber.RefID)
      return subsriberObj
    }
    return
  }

  useEffect(() => {
    getPrivateServerChannel()
    getApplicationTable()
  }, [updateSliceData])

  const privateServerChannel = getPrivateServerChannel()
  const ApplicationTaskTable = getApplicationTable()

  const getApplicationNotificationIcon = () => {
    return ApplicationTaskTable && Object.keys(ApplicationTaskTable).length > 1 ? (
      <TargetIcon type='TEAMS' />
    ) : (
      ''
    )
  }

  const getApplicationNotificationVariant = () => {
    return ApplicationTaskTable && Object.keys(ApplicationTaskTable).length > 1
      ? 'grayBlue'
      : 'gray'
  }

  const getApplicationChannelName = () => {
    if (ApplicationTaskTable) {
      return (
        <div>
          <strong>{ApplicationTaskTable.Spec.ChannelName}</strong>
          <p>Team : {ApplicationTaskTable.Spec.Description}</p>
        </div>
      )
    } else {
      return <em>No Channel Selected</em>
    }
  }

  const getPrivateNotificationIcon = () => {
    return privateServerChannel && Object.keys(privateServerChannel).length > 1 ? (
      <TargetIcon type='TEAMS' />
    ) : (
      ''
    )
  }

  const getPrivateNotificationVariant = () => {
    return privateServerChannel && Object.keys(privateServerChannel).length > 1
      ? 'grayBlue'
      : 'gray'
  }

  const getPrivateChannelName = () => {
    if (privateServerChannel) {
      return <strong>{privateServerChannel.Spec.ChannelName}</strong>
    } else {
      return <em>No channel connected yet</em>
    }
  }

  const handleModalClick = (type) => {
    setNotificationType(type)
    setShowModal(!showModal)
  }

  const onConfirmChannelChange = (selectedChannel, notificationType) => {
    if (notificationType === 'getway') {
      applicationNotificationChannel(selectedChannel)
    } else {
      privateNotificationChannel(selectedChannel)
    }
  }

  const applicationNotificationChannel = async (selectedChannels) => {
    setShowModal(false)
    try {
      setLoading(true)
      if (!Object.keys(selectedChannels).length) {
        // If no channels are selected then, delete any NS if exists
        const privateNS = getApplicationTable()
        if (privateNS) {
          const response = await reduxApiClient('notificationsubscriptions').delete(privateNS)
          updateSliceData(response)
        }
        return
      }

      const selectedChannelRef = {
        RefKind: 'MsTeamsChannel',
        RefID: selectedChannels.ObjectMeta.ID
      }
      const channelKey = createRsrcKey(selectedChannelRef)
      const privateNS = getApplicationTb()

      if (channelKey === createRsrcKey(ApplicationTaskTable)) return setLoading(false)

      if (privateNS) {
        // Delete if PRIVATE notification channel exists
        await reduxApiClient('notificationsubscriptions').delete(privateNS)
      }

      const newNS = {
        ObjectMeta: {
          Name: `PRIVATE_${getFriendlyName()}`
        },
        Spec: {
          SubscriptionFor: {
            RefID: 0,
            RefKind: 'Account'
          },
          Subscriber: selectedChannelRef
        }
      }
      // Create NS
      const response = await reduxApiClient('notificationsubscriptions').create(newNS)
      enqueueNotification('Application Task Table changed successfully!', 'info')
      setLoading(false)
      updateSliceData(response)
    } catch (error) {
      enqueueNotification('Failed to change Notification Channel!', 'error')
    }
  }

  const privateNotificationChannel = async (selectedChannels) => {
    try {
      setLoading(true)
      if (!Object.keys(selectedChannels).length && integration.Spec?.WebhookUrl === '') {
        // If no channels are selected then, delete any NS if exists
        const privateNS = getPrivateNS()
        if (privateNS) {
          const response = await reduxApiClient('notificationsubscriptions').delete(privateNS)
          updateSliceData(response)
          setShowModal(false)
        }
        return
      }

      let selectedChannelRef = {
        RefKind: 'MsTeamsChannel',
        RefID: selectedChannels?.ObjectMeta?.ID
      }

      const channelKey = createRsrcKey(selectedChannelRef)
      const privateNS = getPrivateNS()

      if (channelKey === createRsrcKey(privateServerChannel)) return setLoading(false)

      if (privateNS) {
        // Delete if PRIVATE notification channel exists
        const response = await reduxApiClient('notificationsubscriptions').delete(privateNS)
        updateSliceData(response)
      }

      const newNS = {
        ObjectMeta: {
          Name: `PRIVATE_${getFriendlyName()}`
        },
        Spec: {
          SubscriptionFor: {
            RefID: 0,
            RefKind: 'Server'
          },
          Subscriber: selectedChannelRef
        }
      }
      // Create NS
      const response = await reduxApiClient('notificationsubscriptions').create(newNS)
      enqueueNotification('Private Server Notification Channel changed successfully!', 'info')
      setShowModal(false)
      setLoading(false)
      updateSliceData(response)
    } catch (error) {
      enqueueNotification('Failed to change Notification Channel!', 'error')
    }
  }

  return (
    <div className='mt-8'>
      {!isAgentlessMode && (
        <div className='border-b-2'>
          <Typography variant='h4-medium'>Gateway Notification Channel</Typography>
          <div className='flex justify-between items-center my-5'>
            <Label
              size='lg'
              icon={getApplicationNotificationIcon()}
              onClick={function noRefCheck() {}}
              text={getApplicationChannelName()}
              variant={getApplicationNotificationVariant()}
              style={{
                padding: '15px'
              }}
            />
            <Button size='md' variant='gray' onClick={() => handleModalClick('getway')}>
              Select Channel
            </Button>
          </div>
        </div>
      )}

      <div className='border-b-2 mt-10'>
        <Typography variant='h4-medium'>Private Server Notification Channel</Typography>
        <div className='flex justify-between items-center my-5'>
          <Label
            size='lg'
            icon={getPrivateNotificationIcon()}
            onClick={function noRefCheck() {}}
            text={getPrivateChannelName()}
            variant={getPrivateNotificationVariant()}
            style={{
              padding: '15px 10px'
            }}
          />
          <Button size='md' variant='gray' onClick={() => handleModalClick('private')}>
            Select Channel
          </Button>
        </div>
      </div>

      <SelectChannel
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirmChannelChange={onConfirmChannelChange}
        notificationType={notificationType}
      />
    </div>
  )
}

export { MSTeam }
