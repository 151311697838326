// @ts-nocheck
import { Card, CircularProgress, makeStyles, Box } from '@material-ui/core'
import FilterDrawer from 'Components/FilterDrawer'
import MountOn from 'Components/MountOn'
import ThemeTableHeader from 'Components/ThemeTableHeader'
import MUIDataTable from 'mui-datatables'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { objectIncludes } from 'Utils/Helpers'
import CustomFooter from './CustomFooter'
import DataTiles from './DataTiles'
import TableBulkActions from 'Components/TableBulkActions'
import Header from '../Header'
import SITE_CONFIG from 'Core/Config'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    border: '1px solid #cfcfcf',
    borderBottom: 'none',
    backgroundColor: 'inherit'
  },
  flatTable: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    // minHeight: '100%',
    '& > .MuiToolbar-root': {
      // @ts-ignore
      position: 'absolute',
      marginTop: (props) => (props.hideTimeFilter ? '-10.5rem' : '-14rem'),
      width: (props) => props.tableHeaderWidth,
      padding: theme.spacing(0, 0, 0, 0) + '!important',
      display: (props) => !props.showControls && 'none'
    },
    '& .MUIDataTableHeadCell-toolButton-153': {
      padding: theme.spacing(0.3, 0.5),
      '&:hover': { backgroundColor: 'transparent' }
    },
    '& > .MUIDataTable-responsiveBase-250': {
      overflow: 'hidden'
    },
    '& tr': {
      height: (props) => props.rowHeight || SITE_CONFIG.rowHeight,
      border: '1px solid #e0e0e0',
      '& td': {
        '&:first-child': {
          // padding: collapsableRow => collapsableRow ? theme.spacing(0, 0) : theme.spacing(0, 1)
          // padding: theme.spacing(0, 1)
        },
        borderBottom: 'unset',
        // @ts-ignore
        padding: (props) => (props.collapsableRow ? theme.spacing(0, 0) : theme.spacing(0.125, 1, 0, 1))
        // padding: theme.spacing(0, 0)
      },
      '& th': {
        '&:first-child': {
          padding: theme.spacing(0, 1)
        },
        backgroundColor: '#F9FBFC',
        borderBottom: 'unset',
        padding: theme.spacing(0, 1),
      },

      '&:hover' : {
        backgroundColor:'#F9FBFC'
      }
    },
    '& thead ': {
      border: '1px solid #e0e0e0',
      '& th': {},
      '& tr': {}
    },
    '& .MuiPaper-root:first-child': {
      display: 'none'
    }
  },
  loaderContainer: {
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #cfcfcf'
  }
}))
const defaultComponents = {}
const defaultNumberOfRowsOptions = [5, 10, 15, 50, 100]
const gloalDefaultFilters = []
const defaultExternalFilters = []
const defaultcontrolCheckbox = []
const defaultColumns = []
const defaultSelectedRows = []
const defaultRowsPerPage = 10
const defaultInitialTime = { start: null, end: null }
const NewTable = ({
  components = defaultComponents,
  openGridSettings,
  viewModes = false,
  setViewMode,
  TileComponent,
  onClick,
  viewMode,
  selectedRows = defaultSelectedRows,
  setSelectedRows,
  noMarginTop = false,
  showDownloadCsv = false,
  showFilters = false,
  searchKey,
  isLoading,
  data,
  columns = defaultColumns,
  options,
  title = 'Records',
  BulkActions,
  numberOfRowsOptions = defaultNumberOfRowsOptions,
  hideHeader = true,
  hideFooter = true,
  showFilterHeader = false,
  customRowRender = false,
  renderMap = false,
  setSearchKey = false,
  applyFiltersOnChange = false,
  filterTitle = false,
  collapsableRow = false,
  defaultFilters = gloalDefaultFilters,
  showControls = false,
  draggableColumns = false,
  tableEmptyPlaceholder = 'No Records Available',
  hideTimeFilter = false,
  searchBarWidth,
  controlCheckbox = defaultcontrolCheckbox,
  initialTime = defaultInitialTime,
  onTimeChange = null,
  onApplyClick = null,
  externalFilters = defaultExternalFilters,
  actionLabel = '',
  actionToolTip,
  isActionDisabled = false,
  headerFlexDirection,
  disabledActionToolTip = '',
  onActionClick,
  ActionIcon,
  selectToolbarPlacement = 'above',
  tableHeaderWidth = '97%',
  HeaderRightFilterComponent,
  noCustomColumnsForRow = false,
  onPagination = false,
  controlledSearch = true,
  TopRightFilterComponent,
  LeftFilterComponent = null
}) => {
  const classes = useStyles({ collapsableRow, showControls, hideTimeFilter, tableHeaderWidth })
  const [autoSearchKey, setAutoSearchKey] = useState(searchKey || '')
  const [open, setOpen] = useState(false)
  const [currentFilters, setFilters] = useState(false)
  const [pageInfo, setPageInfo] = useState({ rowsPerPage: options.rowsPerPage || defaultRowsPerPage })
  const [filteredData, setFilteredData] = useState([])
  /** Column toggle and arrangement functionalities for custom rows */
  const [columnsForCustomRow, setColumnsForCustomRow] = useState(columns)
  const [customRowOrder, setCustomRowOrder] = useState(columns.map((_, i) => i))
  useEffect(() => {
    typeof onPagination === 'function' && onPagination(pageInfo)
  }, [onPagination, pageInfo])
  const toggleFilterDrawer = useCallback(() => {
    setOpen((previous) => !previous)
  }, [])

  const handleRowSelectionChange = useCallback(
    (data) => {
      let newSelectedRows = []
      if (data.length === 1) {
        if (selectedRows.includes(data[0].dataIndex)) {
          newSelectedRows = selectedRows.filter((e) => e !== data[0].dataIndex)
        } else {
          newSelectedRows = [...selectedRows, data[0].dataIndex]
        }
      } else {
        if (data.length !== selectedRows.length) {
          newSelectedRows = data.map((e) => e.dataIndex)
        }
      }
      setSelectedRows && setSelectedRows(newSelectedRows)
    },
    [selectedRows, setSelectedRows]
  )

  /**
   * This functions returns all the columns which is currently being displayed on the table.
   * @param {object[]} columns
   * @returns {object[]} Returns all the columns which is currently being displayed on the table
   */
  const displayedColumns = useCallback(
    (columns) => {
      return columns.filter((item) => {
        if (typeof item.options === 'undefined') return true
        if (typeof item.options.display === 'undefined') return true
        return item.options.display
      })
    },
    [columns]
  )

  const customSearch = useCallback(
    (searchText, row) => {
      const filteredColumns = displayedColumns(columns)
      const nameList = filteredColumns.map((item) => item.name)
      const rowNamedObject = {}
      // row is an array whose value is associated to the column by it's index
      // Hence, we need to create a named object for column's name field with row's value
      nameList.forEach((element, index) => {
        rowNamedObject[element] = row[index]
      })
      // Look for searchText on the currently displayed row properties
      return objectIncludes(searchText, rowNamedObject)
    },
    [displayedColumns, objectIncludes]
  )

  const toggleCompleteSelection = useCallback(() => {
    if (data.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(
        Array(data.length)
          .fill(1)
          .map((e, i) => i)
      )
    }
  }, [data, selectedRows])

  const customFooterCreator = useCallback(
    (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      if (!pageInfo || pageInfo.count !== count || pageInfo.page !== page || pageInfo.rowsPerPage !== rowsPerPage) {
        setPageInfo({ count, page, rowsPerPage })
      }
      if (hideFooter) {
        return <></>
      }
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={(rows) => changeRowsPerPage(rows)}
          changePage={(page) => changePage(page)}
          numberOfRowsOptions={numberOfRowsOptions}
        />
      )
    },
    [pageInfo, setPageInfo, numberOfRowsOptions]
  )

  const filterCategories = useMemo(() => {
    return columns
      .filter((e) => e.options?.filter)
      .map((e) => ({
        UiVariant: e.options.UiVariant,
        filterOptions: e.options.filterOptions,
        Name: e.name,
        Label: e.label,
        filterType: e.options.filterType,
        styles: e.options.styles || {}
      }))
  }, [columns])

  /**
   * updates columns for customrows
   * @param {string} columnName Name of the column that is changed
   * @param {string} action - Add|Remove
   */
  const handleColumnsChange = useCallback(
    (columnName, action) => {
      const tempColumns = [...columnsForCustomRow]
      const target = tempColumns.find((e) => e.name === columnName)
      if (!target.options) target.options = {}
      target.options.display = action === 'add'
      setColumnsForCustomRow(tempColumns)
    },
    [columnsForCustomRow, setColumnsForCustomRow]
  )

  /**
   * Tracks column rearangement for custom rows
   * @param {Array} newOrder new order after the rearrangement
   * @param {number} columnIndex column which was dragged
   * @param {number} newPosition position where the dragged column was released
   */
  const handleColumnReorder = useCallback(
    (newOrder, columnIndex, newPosition) => {
      // update customRowColumn in new order to stay in sync with column toggle functionality
      const tempColumns = newOrder.map((i) => {
        // using the column[i] as newOrder is relative to the initial state, not the last state
        const columnName = columns[i].name
        // using columnsForCustomRow as it has the column toggle functionality data
        const column = columnsForCustomRow.find((e) => e.name === columnName)
        return column
      })
      setCustomRowOrder(newOrder)
      setColumnsForCustomRow(tempColumns)
    },
    [columns, columnsForCustomRow, setCustomRowOrder, setColumnsForCustomRow]
  )

  const onCsvDownload = useCallback(() => {}, [])

  const handleSearchKeyChange = useCallback(
    (key) => {
      if (controlledSearch && setSearchKey) setSearchKey(key)
      else setAutoSearchKey(key)
    },
    [controlledSearch]
  )

  useEffect(() => {
    setPageInfo((state) => ({ ...state, count: filteredData.length }))
  }, [filteredData.length])
  const muiDataOptions = useMemo(
    () => ({
      ...options,
      fixedHeader: true,
      selectToolbarPlacement: selectToolbarPlacement,
      [customRowRender && 'onViewColumnsChange']: handleColumnsChange,
      draggableColumns: { enabled: draggableColumns },
      onColumnOrderChange: handleColumnReorder,
      [customRowRender ? 'customRowRender' : '_']: customRowRender
        ? (data, dataIndex, rowIndex) =>
            customRowRender(
              filteredData,
              data,
              dataIndex,
              rowIndex,
              noCustomColumnsForRow ? columns : columnsForCustomRow,
              customRowOrder
            )
        : false,
      customFooter: customFooterCreator,
      searchText: autoSearchKey,
      onRowSelectionChange: handleRowSelectionChange,
      rowsSelected: selectedRows,
      tableBodyHeight: '100%',
      rowsPerPage: pageInfo.rowsPerPage || defaultRowsPerPage,
      customSearch,
      textLabels: {
        body: {
          noMatch: filteredData.length ? 'Sorry, no matching records found' : <em>{tableEmptyPlaceholder}</em>
        }
      }
    }),
    [
      options,
      customRowRender,
      handleColumnsChange,
      columnsForCustomRow,
      customRowOrder,
      draggableColumns,
      handleColumnReorder,
      customFooterCreator,
      autoSearchKey,
      handleRowSelectionChange,
      selectedRows,
      pageInfo,
      customSearch,
      filteredData,
      tableEmptyPlaceholder,
      selectToolbarPlacement,
      columns,
      noCustomColumnsForRow
    ]
  )
  useEffect(() => {
    if (showFilterHeader === false) {
      setFilteredData(data)
    }
  }, [showFilterHeader, data])

  useEffect(() => {
    if (controlledSearch) setAutoSearchKey(searchKey)
  }, [searchKey, controlledSearch])

  useEffect(() => {
    if (!controlledSearch && setSearchKey) setSearchKey(autoSearchKey)
  }, [autoSearchKey, controlledSearch, setSearchKey])

  return (
    <Box>
      <MountOn on={!hideHeader}>
        <ThemeTableHeader
          actionLabel={actionLabel}
          actionToolTip={actionToolTip}
          disabledActionToolTip={disabledActionToolTip}
          onActionClick={onActionClick}
          isActionDisabled={isActionDisabled}
          direction={headerFlexDirection}
          ActionIcon={ActionIcon}
          openGridSettings={openGridSettings}
          viewMode={viewMode}
          viewModes={viewModes}
          setViewMode={setViewMode}
          showFilters={showFilters}
          noMarginTop={noMarginTop}
          onCsvDownload={onCsvDownload}
          showDownloadCsv={showDownloadCsv}
          onClick={toggleFilterDrawer}
          data={data}
          title={title}
          pageInfo={pageInfo}
        />
      </MountOn>
      <MountOn on={showFilterHeader}>
        <Box className={classes.headerWrapper}>
          <Header
            LeftFilterComponent={LeftFilterComponent}
            externalFilters={externalFilters}
            onApplyClick={onApplyClick}
            initialTime={initialTime}
            onTimeChange={onTimeChange}
            controlCheckbox={controlCheckbox}
            searchBarWidth={searchBarWidth}
            hideTimeFilter={hideTimeFilter}
            setFilteredData={setFilteredData}
            applyFiltersOnChange={applyFiltersOnChange}
            sourceData={data}
            defaultFilters={defaultFilters}
            onChange={applyFiltersOnChange}
            filterTitle={filterTitle}
            filterCategories={filterCategories}
            searchKey={autoSearchKey}
            setSearchKey={handleSearchKeyChange}
            RightFilterComponent={HeaderRightFilterComponent}
            TopRightFilterComponent={TopRightFilterComponent}
          />
        </Box>
      </MountOn>
      <Card elevation={0} className={!viewMode || viewMode === 'List' ? classes.root : classes.tiles}>
        <MountOn on={isLoading}>
          <div className={classes.loaderContainer}>
            <CircularProgress size='1.5rem' color='primary' />
          </div>
        </MountOn>
        <MountOn on={BulkActions}>
          <TableBulkActions
            selectedRows={selectedRows}
            tableToolbarVisible={showControls}
            checked={data.length === selectedRows.length}
            filteredData={[...filteredData]}
            columns={columns}
            toggleCompleteSelection={toggleCompleteSelection}
            BulkActions={BulkActions}
            title={title}
            searchKey={autoSearchKey}
            Length={selectedRows.length}
          />
        </MountOn>
        <MountOn on={!isLoading && (!viewMode || viewMode === 'List')}>
          <MUIDataTable
            // @ts-ignore
            className={classes.flatTable}
            title={title}
            responsiveScroll
            data={filteredData}
            columns={customRowRender && !noCustomColumnsForRow ? columnsForCustomRow : columns}
            components={components}
            options={muiDataOptions}
          />
        </MountOn>
        <MountOn on={!isLoading && viewMode === 'Tile' && TileComponent}>
          <DataTiles TileComponent={TileComponent} onClick={onClick} searchKey={autoSearchKey} data={data} />
        </MountOn>
        {!isLoading && viewMode === 'Map' && renderMap && renderMap(filteredData)}
        <FilterDrawer
          filterCategories={filterCategories}
          title={title}
          currentFilters={currentFilters}
          setFilters={setFilters}
          open={open}
          toggleFilterDrawer={toggleFilterDrawer}
        />
      </Card>
    </Box>
  )
}

export default React.memo(NewTable)

NewTable.defaultProps = {
  hideTitle: false,
  hideFooter: false
}
