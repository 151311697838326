import { useRef } from 'react'
import { useParams } from 'react-router'

/**
 * The hook is should be used to "sync the route paramter with state value" nothing more nothing less.
 * The hook doesn;t change any routes hence, it is the sole duty of the calle  to handle route change
 * @param {{
 *  key: string,
 *  possibleValues: string[],
 *  defaultValue: string,
 *  controlValue: string,
 *  onControlValueChange: ((newValue:string) => void),
 *  syncUsingParamValue?: boolean //Use route param value to update
 * }} param0
 * @returns
 */
export const useRouteParams = ({
  key,
  possibleValues = [],
  defaultValue,
  controlValue,
  onControlValueChange,
  syncUsingParamValue
}) => {
  const paramValue = useParams()[key]

  /**
   * This flag is used to prevent infinite loop of setting the route param
   */
  const isSet = useRef(false)

  /**
   * If the control value is falsy, then, prioritize the route param
   */
  if (!controlValue) {
    let newVal = ''

    /**
     * If the route param exists in possible values, then change the control value to that value
     * else, set control value to default value
     */
    if (possibleValues.includes(paramValue)) newVal = paramValue
    else newVal = defaultValue

    return onControlValueChange(newVal)
  }
  /**
   * If control value and route param are different, and the param value is in possible values then,
   * call the on change handler. The reason to call the change handler is to tell the calle to change the route now.
   * If route gets changed, then we will have the correct routeParam and hence the sync is complete
   */

  if (!isSet.current && controlValue !== paramValue && possibleValues.includes(paramValue)) {
    isSet.current = true
    /**
     * If `syncUsingParamValue` is true use the paramvalue to update the control value.
     * Else, use the control value change to update
     */
    return onControlValueChange(syncUsingParamValue ? paramValue : controlValue)
  }

  /**
   * Reset the flag
   */
  if (controlValue === paramValue) {
    isSet.current = false
  }
}
