/**
 * The function starts network polling for the specified object resource, until `checkFn` returns true or `stopPollingFn` is called.
 * @param {any} apiFn The object/string to poll
 * @param {((response:import('axios').AxiosResponse) => boolean)} testFn The function which will be called with response and must return true or false
 * @param {((response:import('axios').AxiosResponse) => any)} doFn
 * @returns {(() => void)} Returns a function which can be called to stop polling
 */
export const startPolling = (apiFn, testFn, doFn) => {
  let polling = true

  ;(async function () {
    while (polling) {
      try {
        let result
        /**
         * * We check for polling to be true on each line because, by the time the instructions are run the polling might have been 
         * * set to `false`.
         */
        if (polling) result = await apiFn()
        if (polling && testFn(result)) {
          stopPolling()
          doFn(result)
        }
      } catch (error) {}
    }
  })()

  function stopPolling() {
    if (polling) {
      console.log('[NP](): Stop polling!')
      polling = false
    }
  }

  return stopPolling
}
