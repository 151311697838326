import moment from 'moment'
import { prepareUserDevicesList, filterUserCredentials, getUserRole, getAdminGroups, getUserInfo } from 'Utils/Helpers'
import SlackIcon from 'Components/CustomIcons/SlackIcon'
import JIRAIcon from 'Components/CustomIcons/JIRAIcon'
import { Security, Folder, Storage, FeedbackOutlined } from '@material-ui/icons'
import DnsIcon from '@material-ui/icons/Dns'
import AppRoleIcon from 'Components/CustomIcons/AppRoleIcon'
import { Theme } from 'Core'

import { debug } from 'Utils/DebugHelper'
import { status } from 'infra/redux/reducers'
import { APPROVED_STATUS } from 'Utils/Constants/ApprovalRequest'
import _ from 'lodash'
import AmazonIcon from 'Components/CustomIcons/AmazonIcon'
import GoogleIcon from 'Components/CustomIcons/GoogleIcon'
import AzureIcon from 'Components/CustomIcons/AzureIcon'
import DatabaseIcon from 'Components/CustomIcons/Database'
import ServiceNow from 'Components/CustomIcons/ServiceNow'
// TODO this hould be handled on server

const _source_ = 'SCIM'

export const getAccountImage = (type, color = false) => {
  const accountType = typeof type === 'string' ? type.toLowerCase() : ''
  switch (accountType) {
    case 'aws':
      return color ? 'img/color/aws.svg' : 'img/greyscale/aws.svg'
    case 'gcp':
      return color ? 'img/color/gcp.svg' : 'img/greyscale/gcp.svg'
    case 'azure':
      return color ? 'img/color/azure.svg' : 'img/greyscale/azure.svg'
    default:
      return color ? 'img/color/aws.svg' : 'img/amazon-transparent.png'
  }
}
export const getAccountIcon = (type) => {
  const accountType = typeof type === 'string' ? type.toLowerCase() : ''
  switch (accountType) {
    case 'aws':
      return AmazonIcon
    case 'gcp':
      return GoogleIcon
    case 'azure':
      return AzureIcon
    default:
      return Security
  }
}
export const formatTime = (timestamp) => {
  if (timestamp && timestamp.includes('T')) {
    const date = timestamp.split('T')[0]
    const time = timestamp.split('T')[1].split('Z')[0]
    const formattedTimeStamp = `${date} ${time} +0000 UTC`
    return formattedTimeStamp
  }
  return ''
}

export const prepareSessionReplays = (sessionReplay) => sessionReplay.map(e => {
  const date = moment(e?.time).format('ll')
  const time = moment(e?.time).format('HH:mm:ss')
  const formattedTime = `${date} (${time})`
  return ({
    ...e,
    formattedTime
  })
})
/**
 * Prepares list for user list table
 */
export const prepareUserList = (userList, DEVICE_LIST, CREDENTIAL_LIST, groupList) => {
  const preparedUserList = userList.map((user, i) => {
    const devices = prepareUserDevicesList(DEVICE_LIST, user.ObjectMeta.Name).length
    // Ids of all the group this user belongs to
    const groupIds = user.Spec.Groups.ObjectRef
      .filter((group) => group.RefKind === 'Group')
      .map((group) => group.RefID)
    const groupsCount = groupIds.length
    // Preparing group names string
    let groupNames = ''
    if (groupList) {
      groupNames = groupList
        .filter((group) => groupIds.includes(group.ObjectMeta.ID))
        .map((group) => group.ObjectMeta.Name)
        .join(', ')
    }
    const loggedUser = getUserInfo()
    //  Count of all the credentials this user belongs to
    const credentials = filterUserCredentials(CREDENTIAL_LIST, user, groupIds).length
    const userName = user.ObjectMeta.Name
    return {
      Name: userName,
      NameEmail: {
        FullName: user.Spec.FullName || user.ObjectMeta.Name,
        sort: user.Spec.FullName || user.ObjectMeta.Name,
        EmailID: user.Spec.EmailID,
        loggedUser: userName === loggedUser.ObjectMeta.Name
      },
      FullName: user.Spec.FullName || user.ObjectMeta.Name,
      EmailID: user.Spec.EmailID,
      ID: user.ObjectMeta.ID,
      credentials,
      source: user.Status.Source && user.Status.Source === 'SCIM' ? 'SCIM' : 'Procyon',
      groups: {
        sort: groupsCount,
        groupName: groupNames
      },
      groupsCount,
      groupName: groupNames,
      devices,
      activity: {
        recentActivity: '1 Device Added',
        activityType: (i + 1) % 2 === 0 ? 'warning' : 'info',
        activityTime: '2 days ago (5/12/2021 4:35 PM)'
      },
      recentActivity: '1 Device Added',
      activityType: (i + 1) % 2 === 0 ? 'warning' : 'info',
      activityTime: '2 days ago (5/12/2021 4:35 PM)',
      Status: _.get(user, 'Spec.Active', false) ? 'Active' : 'Inactive'
    }
  })
  return preparedUserList
}
/**
   * Prepares list for devices list table
   */
export const prepareDevicesList = (DEVICE_LIST, deviceInfo = {}, IsApproved = true) => {
  const preparedDeviceList = []
  DEVICE_LIST.forEach((device) => {
    console.log('IsApproved', IsApproved, device.IsApproved)
    if (device.IsApproved !== IsApproved) return
    const deviceName = getDeviceName(device)
    const tableRow = {
      UserName: device.UserName,
      approved: { status: device.IsApproved ? 'success' : 'error', title: device.IsApproved ? 'Approved' : 'Not Approved' },
      DeviceName: {
        active: device.ObjectMeta.Name === deviceInfo.deviceid,
        name: deviceName,
        sort: deviceName
      },
      OperatingSystem: device.Attributes.OperatingSystem,
      Model: device.Attributes.Model,
      CreatedAt: device.ObjectMeta.CreatedAt,
      ID: device.ObjectMeta.Name
    }
    preparedDeviceList.push(tableRow)
  })
  return preparedDeviceList
}
/**
   * Prepares list for groups list table
   */
export const prepareGroupsList = (groupList, userList, CREDENTIAL_LIST) => {
  const preparedGroupList = []
  console.log('preparing groupList')
  groupList.forEach((group) => {
    const ID = group.ObjectMeta.ID
    const groupUsers = userList.filter(user => {
      const userGroups = user.Spec.Groups.ObjectRef
      const isMember = userGroups.some(group => group.RefID === ID && group.RefKind === 'Group')
      return isMember
    })
    const userCount = groupUsers.length
    const CredentialsCount = CREDENTIAL_LIST.filter(credential => {
      const issuedTo = credential?.Spec?.IssuedTo
      return issuedTo.RefKind === 'Group' && issuedTo.RefID === ID
    }).length
    preparedGroupList.push({
      ID,
      Name: group.ObjectMeta.Name,
      Description: group.Spec.Description,
      Users: userCount,
      source: group.Status.Source && group.Status.Source === 'SCIM' ? 'SCIM' : 'Procyon',
      Credentials: CredentialsCount,
      Status: 'Active'
    })
  })
  return preparedGroupList
}
/**
   * Prepares list for server groups list table
   */
export const prepareServerGroupsList = (groupList) => {
  const preparedServerGroupList = groupList.map((group) => {
    return {
      Name: group.ObjectMeta.Name,
      Description: group.Spec.Description,
      GroupName: group.ObjectMeta.GroupName,
      ID: group.ObjectMeta.ID
    }
  })
  return preparedServerGroupList
}
/**
   * Prepares list for idps list table
   */
export const prepareIdpsList = (IDP_LIST) => {
  const preparedIdpList = IDP_LIST.map((idp) => {
    const image = getIDPImage(idp.ObjectMeta.Name, idp.ObjectMeta.Kind)
    return {
      Name: idp.ObjectMeta.Name,
      Kind: idp.ObjectMeta.Kind,
      heading: idp.ObjectMeta.Name,
      ProviderType: idp.Spec.ProviderType,
      idp,
      subHeading: '',
      ID: idp.ObjectMeta.ID,
      ...idp.Spec,
      ...idp.Status,
      image
    }
  })
  return preparedIdpList
}
/**
   * Prepares list for credenial list table
   */
export const getCredentialsAssignments = (Name, credentialInstanceList) => {
  const assignments = {
    groupsCount: 0,
    usersCount: 0,
    users: [],
    groups: []
  }
  credentialInstanceList.forEach(instance => {
    if (instance.Spec.CredentialType === Name) {
      if (instance.Spec.IssuedTo.RefKind === 'User') {
        assignments.users.push(instance)
      } else if (instance.Spec.IssuedTo.RefKind === 'Group') {
        assignments.groups.push(instance)
      }
    }
  })
  assignments.usersCount = assignments.users.length
  assignments.groupsCount = assignments.groups.length
  return assignments
}
export const prepareCredentialTypes = (credentialTypesList, credentialInstanceList) => {
  const processedList = credentialTypesList.map(credentialType => {
    const { Name, ID } = credentialType.ObjectMeta
    const assignments = getCredentialsAssignments(Name, credentialInstanceList)
    return {
      ID: ID,
      Name: Name,
      attributesCount: Object.keys(credentialType.Spec.Attributes.Map).length,
      groupCount: assignments.groupsCount,
      userCount: assignments.usersCount,
      Description: credentialType.Spec.Description,
      Theme: credentialType.Spec.Theme

    }
  })
  return processedList
}
/**
   * Prepares list for userAccounts list table
   */
export const prepareUserAccountsList = (USER_ACCOUNT_LIST) => {
  const preparedIdpList = USER_ACCOUNT_LIST.map((userAccount) => {
    return {
      heading: userAccount.ObjectMeta.Name,
      subHeading: userAccount.Spec.type,
      ID: userAccount.ObjectMeta.ID,
      ...userAccount.Spec,
      ...userAccount.Status
    }
  })
  return preparedIdpList
}

/**
   * Prepares list for approval request list table
   */
const getStatusType = (status) => {
  switch (status) {
    case APPROVED_STATUS.Pending:
      return 'warning'
    case APPROVED_STATUS.Approved:
      return 'info'
    case APPROVED_STATUS.Cancelled:
      return 'danger'
    case APPROVED_STATUS.Rejected:
      return 'danger'
    default:
      return 'warning'
  }
}
export const getRoleAccountID = (role, APPLICATION_LIST) => {
  let AccountID = null
  if (role && APPLICATION_LIST && APPLICATION_LIST.length > 0) {
    const applicationId = role?.Spec?.Application.RefID
    const application = APPLICATION_LIST.find(
      (e) => e.ObjectMeta.ID === applicationId
    )
    if (application) {
      AccountID = application?.Spec?.AwsFederationConfig?.AwsAccountID
    }
  }
  return AccountID
}

/**
 * Gives account object of the provided role
 * @param {Array<object>} ACCOUNT_LIST List of all accounts
 * @param {{Spec:{Account:{RefID:string}}}} role role object
 * @returns {object} account object of the role
 */
export const getAppRoleAccount = (ACCOUNT_LIST, role) => {
  /** Reference ID of role's account */
  const roleAccountId = role?.Spec?.Account?.RefID
  if (roleAccountId) {
    /** Find account whose ObjectMeta.ID matches with roleAccountId */
    const account = ACCOUNT_LIST.find((e) => e.ObjectMeta.ID === roleAccountId)
    /** account object of the role */
    return account
  }
  return false
}

export const getTargetAccount = (ACCOUNT_LIST, target) => {
  let account = {}
  if (ACCOUNT_LIST && ACCOUNT_LIST.length && target) {
    account = ACCOUNT_LIST?.find((e) => {
      return e.ObjectMeta.ID === target?.Spec?.Account?.RefID
    })
  }
  return account
}

export const getResourceList = (type, resourcesKindMap) => {
  return resourcesKindMap[type] || []
}
/**
 * @deprecated This has been depreacted, use the one from features/resources
 * @param {'Role' | 'Server' | 'AppRole' | 'Project' | 'Database'} type
 * @returns
 */
export const getResourceIcon = (type) => {
  switch (type) {
    case 'Role':
      return ({ className }) => <img className={className} src='img/iam-role.png' />
    case 'AppRole':
      return AppRoleIcon
    case 'Server':
      return DnsIcon
    case 'Project':
      return Folder
    case 'GcpResource':
      return Folder
    case 'Database':
      return DatabaseIcon
    default:
      return FeedbackOutlined
  }
}
/**
 * Gives account object of provided resource
 * @param {'Server' | 'Role' | 'AppRole'} type
 * @param {Array} ACCOUNT_LIST List of all accounts
 * @param {Object} resource server or role object
 * @returns {object} Account object
 */
export const getResourceAccount = (type, ACCOUNT_LIST, resource, awsResources) => {
  switch (type) {
    case 'Role':
      return getAppRoleAccount(ACCOUNT_LIST, resource)
    case 'AppRole':
      return getAppRoleAccount(ACCOUNT_LIST, resource)
    case 'Server':
      return getTargetAccount(ACCOUNT_LIST, resource)
    case 'Project':
      return getProjectAccount(resource, ACCOUNT_LIST)
    case 'GcpResource':
      return getGcpResourceAccount(resource, ACCOUNT_LIST)
    case 'AwsResource':
      return getGcpResourceAccount(resource, ACCOUNT_LIST)
    case 'KubeNamespace':
      return getKubeNamespaceAccount(resource, awsResources, ACCOUNT_LIST)
    case 'AzureResource':
      return getGcpResourceAccount(resource, ACCOUNT_LIST)
    case 'Application':
      return getTargetAccount(ACCOUNT_LIST, resource)
    case 'Database':
      return getTargetAccount(ACCOUNT_LIST, resource)
  }
}

/**
 * Prepares data for user app approval requets datatable
 * @param {object} approvalRequests request object from db
 * @param {{ObjectMeta:{ID:string},Spec:{Groups}}} user
 * @param {Array} appRoles
 * @param {Array} userList
 * @param {Array} groupList
 * @param {Array} serverList
 * @param {Array} iamActions
 *  @returns {Array<ApprovalReqTableData>}  format expected by the data table
 */
export const prepareApprovalReqList = (approvalRequests, user, appRoles, userList, groupList, serverList, iamActions, applications, databases, kubeNamespaces, awsResources, gcpResources, serviceAccounts) => {
  const preparedList = []
  approvalRequests.forEach(request => {
    const resourcesRefs = request?.Spec?.Resources?.Resource || []
    const isIAMActionBased = resourcesRefs.some(resource => ['AwsIAM', 'GcpIAM', 'AzureIAM'].includes(resource.Action))
    const isCancellable = request?.Status !== APPROVED_STATUS.Cancelled &&
     resourcesRefs.every(resource => (resource.Approved === APPROVED_STATUS.New))
    let pendingOnUserView = resourcesRefs.some(resource => (resource.Approved === APPROVED_STATUS.New))
    if (request.Type === 'ServiceAccount') {
      pendingOnUserView = request.Status === APPROVED_STATUS.Pending
    }
    const requestedByRef = request.Requestor
    const requestedFor = request.Spec.RequestedFor.ObjectRef
    /** Is this request made for/by this user */
    const isRequetor = isUserRequestor(user, requestedByRef, requestedFor)
    const requestedForRef = request?.Spec?.RequestedFor?.ObjectRef || []
    /** Adds user's/group's name and email to the requested for list items */
    const dereferencedRequestedFor = requestedForRef.map(entity => getDisplayData(entity, groupList, userList, [], serviceAccounts))
    /** Gets name and email of the user/group who made the request */
    const requestedBy = getDisplayData(requestedByRef, groupList, userList)
    const hasExpired = (new Date(request.Spec.NotAfter).getTime() - new Date().getTime() < 0)
    /** Object containing count of how many resources are Approved, Rejected or New */
    const approvalStats = { ...getApprovalStats(resourcesRefs), Status: request.Status, hasExpired }
    if (request.Type === 'ServiceAccount') {
      if(request.Status === APPROVED_STATUS.Pending) {
        approvalStats.pending = 1
      }
      if(request.Status === APPROVED_STATUS.Approved) {
        approvalStats.approved = 1
      }
      if(request.Status === APPROVED_STATUS.Rejected) {
        approvalStats.rejected = 1
      }
      if(request.Status === APPROVED_STATUS.Cancelled) {
        approvalStats.count = 1
      }
    }
    if (isRequetor) {
      /** Get name of each resource from its reference */
      const resourcesWithName = resourcesRefs.map(resourceRef =>
        resourcesWithNameAdapter(resourceRef, appRoles, serverList, iamActions, applications, databases, kubeNamespaces, awsResources, gcpResources))
      console.log('resources-bug, resourcesWithName', resourcesWithName)
      const tableRow = approvalRequestsTableAdapter(false, request, resourcesWithName, approvalStats, dereferencedRequestedFor, null, requestedBy, isCancellable, hasExpired)
      preparedList.push({ ...tableRow, Source: request, resourceName: request.ObjectMeta.Name, isIAMActionBased, pendingOnUserView })
    }
  })
  return preparedList
}
/**
 * Gives database object of the referenced resource
 * @param {{RefKind:'AppRole'|'Server',RefID:string}} resourceRef referenced resource object
 * @param {Array} APP_ROLE_LIST
 * @param {Array} SERVER_LIST
 * @param {Array} iamActions
 * @returns {object} database object of the referenced resource
 */
export const dereferenceResource = (resourceRef, resourcesKindMap = {}) => {
  try {
    /** find source list for current resourcetype */
    const sourceList = getResourceList(resourceRef.RefKind, resourcesKindMap)
    const resourceDBObject = sourceList.find(e => e.ObjectMeta.ID === resourceRef.RefID)
    if (resourceDBObject) {
      return resourceDBObject
    } else {
      return false
    }
  } catch (error) {
    console.log('error in dereferenceResources', error)
    return false
  }
}
/**
 * Gives approval stats of resources under a request
 * @param {Array<{Approved:'Approved'|'New'|'Rejected'}>} resources
 * @returns
 */
export const getApprovalStats = (resources) => {
  const stats = { pending: 0, approved: 0, rejected: 0, count: 0 }
  resources.forEach(resourceItem => {
    stats.count++
    if (resourceItem.Approved === 'New') stats.pending++
    if (resourceItem.Approved === 'Approved') stats.approved++
    if (resourceItem.Approved === 'Rejected') stats.rejected++
  })
  return stats
}
/**
 * Adds resource name to the resource reference object
 * @param {{Target:{RefID:string,RefKind:'AppRole'|'Server'}}} resourceRef reference to request's resource
 * @param {Array} appRoles list of all app roles
 * @param {Array} serverList list of all servers
 * @param {Array} iamActions list of all iamActions
 * @returns {{Target:{RefID:string,RefKind:string},name?:string, services:object}}
 */
const resourcesWithNameAdapter = (resourceRef, appRoles, serverList, iamActions, applications, databases, kubeNamespaces = [], awsResources = [], gcpResources = []) => {
  const services = resourceRef.Services.ObjectRef.reduce((prev, current) => {
    if (_.get(current, 'RefObject.ObjectMeta.ID')) {
      let name = _.get(current, 'RefObject.Spec.Name')
      if (current.RefObject.ObjectMeta.Kind === 'KubeNamespace') {
        name = current.RefObject.Spec.DisplayName
      }
      prev.push({
        name,
        ID: _.get(current, 'RefObject.ObjectMeta.ID'),
        type: _.get(current, 'RefObject.Spec.Type')
      })
    }
    return prev
  }, [])
  const refTarget = resourceRef?.Target
  const dereferencedResource = dereferenceResource(refTarget, {
    AppRole: appRoles,
    Server: serverList,
    Application: applications,
    Database: databases,
    IamAction: iamActions
  })
  console.log('iamActions dereferencedResource', dereferencedResource)
  if (dereferencedResource) {
    let name = getResourceName(dereferencedResource)
    if (refTarget.RefKind === 'Server') name = getServerName(dereferencedResource, true)
    if (refTarget.RefKind === 'AppRole') name = dereferencedResource.Spec.RoleName
    return { ...resourceRef, name, services }
  } else {
    return { ...resourceRef, services }
  }
}
export const getServiceAccountNameFromRequest = (request) => {
  try {
    console.log('getServiceAccountNameFromRequest request', request)
    if (request.Spec.SvcAccSpec.Type === 'AWS') return request.Spec.SvcAccSpec.AwsSpec.UserName
    if (request.Spec.SvcAccSpec.Type === 'GCP') return request.Spec.SvcAccSpec.GcpSpec.Name
    if (request.Spec.SvcAccSpec.Type === 'AZURE') return request.Spec.SvcAccSpec.AzureSpec.DisplayName
  } catch (e) {
    console.error('con not get service account name from request', e)
    return ''
  }
}
export const getServiceAcountParentRefFromRequest = (request, projectList) => {
  try {
    console.log('getServiceAccountNameFromRequest request', request)
    if (request.Spec.SvcAccSpec.Type === 'AWS') return request.Spec.SvcAccSpec.AwsSpec.Account
    if (request.Spec.SvcAccSpec.Type === 'GCP') return request.Spec.SvcAccSpec.GcpSpec.Project
    if (request.Spec.SvcAccSpec.Type === 'AZURE') return request.Spec.SvcAccSpec.AzureSpec.Account
  } catch (e) {
    console.error('con not get service account name from request', e)
    return ''
  }
}
/**
 * @typedef {Object} ApprovalReqTableData
 * @property { boolean } [isPending] Is request pending on current user's end
 * @property { boolean } [isCancellable] can this reuqest be cancelled
 * @property { {name:string,email?:string} } user name and email of the user/group who made the request
 * @property { Array } resources Resources list with name of each item dereferenced
 * @property { string } Name Name of request
 * @property { string } requestID ID of the request
 * @property { {display:String, sort:Number} } Date formatted date string o request's creation time
 * @property { {count:number,approved:number,pending:number,rejected:number} } Status stats count of all the requets
 * @property { object } collapsable data for collapsable segment of row
 */
/**
 * Transforms data in the format expected by the data table
 * @param {boolean} forAdminApp
 * @param {object} request
 * @param {object} resourcesWithName
 * @param {object} approvalStats
 * @param {object} dereferencedRequestedFor
 * @param {boolean} [isPending]
 * @param {boolean} [isCancellable] can this reuqest be cancelled
 * @param {{name:string,email?:string}} [requestedBy] name and email of the user who made the request
 * @returns {ApprovalReqTableData}  format expected by the data table
 */
const approvalRequestsTableAdapter = (forAdminApp, request, resourcesWithName, approvalStats, dereferencedRequestedFor, isPending, requestedBy, isCancellable, hasExpired) => {
  const tableRow = {
    user: requestedBy,
    resources: resourcesWithName,
    Name: {
      // reqest?.GivenName is specifcally for IAMActions which has GivenName field
      display: request?.GivenName || request?.ObjectMeta?.Name,
      Name: request?.ObjectMeta?.Name // For redirecting purposes
    },
    requestID: request?.ObjectMeta?.ID,
    Date: {
      display: moment(request?.ObjectMeta?.CreatedAt)
        .format('MM/DD/YY hh:mmA'),
      sort: moment(request?.ObjectMeta?.CreatedAt).toDate().getTime()
    },
    Status: approvalStats,
    collapsable: {
      resourcesWithName: resourcesWithName,
      requestedFor: dereferencedRequestedFor,
      From: moment(request?.Spec?.NotBefore).format('MM/DD/YY hh:mmA'),
      To: moment(request?.Spec?.NotAfter).format('MM/DD/YY hh:mmA'),
      requestIsCancelled: request?.Status === 'Cancelled',
      hasExpired,
      type: request.Type,
      status: request.Status
    },
    hasExpired
  }
  if (request.Type === 'ServiceAccount') tableRow.collapsable.ServiceAccountName = getServiceAccountNameFromRequest(request)
  if (forAdminApp) {
    tableRow.isPending = isPending
  } else {
    tableRow.collapsable.requestedBy = requestedBy
    tableRow.isCancellable = isCancellable
  }
  return tableRow
}
/**
 * Prepares data for admin app approval requets datatable
 * @param {object} approvalRequests request object from db
 * @param {Array} appRoles
 * @param {Array} userList
 * @param {{ObjectMeta:{ID:string},Spec:{Groups}}} currentUser
 * @param {Array} groupList
 * @param {Array} serverList
 * @param {Array} iamActions
 *  @returns {Array<ApprovalReqTableData>}  format expected by the data table
 */
export const prepareAdminApprovalReqList = (
  approvalRequests,
  appRoles,
  userList,
  currentUser,
  groupList,
  serverList,
  iamActions,
  applications,
  databases,
  kubeNamespaces,
  awsResources
) => {
  const preparedList = []
  approvalRequests.forEach((request) => {
    const hasExpired = (new Date(request.Spec.NotAfter).getTime() - new Date().getTime() < 0)
    const resourcesRefs = request?.Spec?.Resources?.Resource || []
    /** Can the current user Approve/Reject this request */
    const isOwner = isUserRequestOwner(currentUser, resourcesRefs)
    /** Only populate request if its not cancalled and current user can Approve/Reject it */
    if (request.Status !== 'Cancelled' && isOwner) {
      /** Is the request pending on current user's end */
      const isPending = isRequestPendingOnUsersEnd(currentUser, resourcesRefs, request)
      const requestedForRef = request?.Spec?.RequestedFor?.ObjectRef || []
      /** Adds user's/group's name and email to the requested for list items */
      const dereferencedRequestedFor = requestedForRef.map(entity => getDisplayData(entity, groupList, userList))
      /** Object containing count of how many resources are Approved, Rejected or New */
      const approvalStats = getApprovalStats(resourcesRefs)
      /** Get name of each resource from its reference */
      const resourcesWithName = resourcesRefs.map(resourceRef =>
        resourcesWithNameAdapter(resourceRef, appRoles, serverList, iamActions, applications, databases, kubeNamespaces, awsResources))
      /** Gets name and email of the user/group who made the request */
      const requestedBy = getDisplayData(request.Requestor, groupList, userList)
      requestedBy.sort = requestedBy.name
      /** Transforms data in the format expected by the data table */
      const tableRow = approvalRequestsTableAdapter(
        true,
        request,
        resourcesWithName,
        { ...approvalStats, hasExpired },
        dereferencedRequestedFor,
        isPending,
        requestedBy,
        false,
        hasExpired
      )
      /** Adds data row to table */
      preparedList.push({ ...tableRow, resourceName: request.ObjectMeta.Name, Type: request.Type })
    }
  })
  return preparedList
}
export const cancelationModalInfo = (
  request,
  APP_ROLE_LIST,
  userList,
  APPLICATION_LIST,
  ACCOUNT_LIST
) => {
  let modalData = {}
  const role = APP_ROLE_LIST.find(
    (e) => e.ObjectMeta.Name === request.Spec.Resource.Name
  )
  let account = {}
  if (role && ACCOUNT_LIST && ACCOUNT_LIST.length > 0) {
    const applicationId = role?.Spec?.Application.RefID
    const application = APPLICATION_LIST.find(
      (e) => e.ObjectMeta.ID === applicationId
    )
    if (application && application.Spec) {
      account = ACCOUNT_LIST.find(
        (e) =>
          e.AccountID === application?.Spec?.AwsFederationConfig?.AwsAccountID
      )
    }
  }
  const user =
    userList.find(
      (e) => e.ObjectMeta.Name === request?.Spec?.Requestor?.RequestorName
    ) || {}
  modalData = {
    resourceType: request.Spec.Resource.Type,
    account: {
      Name: account?.ObjectMeta?.Name,
      ID: account?.AccountID,
      type: 'amazon'
    },
    user: {
      Name: request?.Spec?.Requestor?.RequestorName || 'N/A',
      Role: getUserRole(user)
    },
    resource: {
      title: request?.Spec?.Resource?.Name,
      subTitle: request?.Spec?.Resource?.Type
    },
    Name: request?.ObjectMeta?.Name,
    requestID: request?.ObjectMeta?.ID,
    Date: moment(request?.ObjectMeta?.CreatedAt)
      .format('MM/DD/YY hh:mm'),
    status: {
      type: getStatusType(request?.Status?.Status),
      text: request?.Status?.Status
    },
    collapsable: {
      title: request.Spec.Resource.Name,
      subTitle:
        role && role.Spec ? role?.Spec?.Attributes?.Map?.['aws-role-arn'] : '',
      accessTime: {
        From: moment(request?.Spec?.StartTime).format('MM/DD/YY hh:mm'),
        To: moment(request?.Spec?.EndTime).format('MM/DD/YY hh:mm')
      },
      description: request?.Spec?.Requestor?.RequestorComments,
      tags: [{ value: 'nflxtag-scp-exempt-all', type: 'primary' }]
    }
  }

  return modalData
}
/**
   * Prepares list for Servers list table
   */
export const prepareServersList = (SERVER_LIST) => {
  const preparedIdpList = SERVER_LIST.map((server) => {
    return {
      'server-name': server.ObjectMeta.Name || 'Server Error',
      'vpc-id': server.VpcId,
      region: server.Region,
      'private-ip': server.PrivateIP,
      'created-at': server.ObjectMeta.CreatedAt,
      'last-update': server.ObjectMeta.UpdatedAt,
      managed: server.State !== 'unmanaged'
    }
  })
  return preparedIdpList
}
/**
   * Prepares list for Recordings list table
   */
export const prepareRecordingsList = (RECORDING_LIST) => {
  const preparedRecordingsList = RECORDING_LIST.map((recording) => {
    return {
      Server: recording.Server,
      Users: recording.Users,
      Duration: recording.Duration,
      Created: recording.Created,
      'Session ID': recording['Session ID']
    }
  })
  return preparedRecordingsList
}
/** Gives back random string of desired length  */
export const randomStringGenerator = (length, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}
/**
 *! @deprecated Do not use, use helper `getUserDisplayName` from `features/users`
 * Gives Full name if full name is Present
 * Else gives firstname +' '+ lastname
**/
export const getDisplayName = (user, useObjectMeta = false) => {
  if (user && user.Spec) {
    const { FirstName, LastName, FullName } = user.Spec
    if (FullName) {
      return FullName
    } else if (typeof FirstName === 'string' && typeof LastName === 'string') {
      return `${FirstName.trim()} ${LastName.trim()}`
    } else if (useObjectMeta && user.ObjectMeta) {
      return user.ObjectMeta.Name
    } else {
      return 'N/A'
    }
  }
  return 'N/A'
}

const getAccountsInfoArray = (singleAccount) => {
  if (singleAccount) {
    return [
      { label: 'Managed', value: 0, type: 'plainText' },
      { label: 'Online', value: 0, type: 'plainText' },
      { label: 'Offline', value: 0, type: 'chip' }
    ]
  } else {
    return [
      { label: 'Managed', value: 0, type: 'plainText' },
      { label: 'Offline', value: 0, type: 'chip' }
    ]
  }
}

const getAccountSummaryCardFooter = (total, current) => {
  return ({
    total,
    current,
    Icon: ({ className }) => <img src='img/greyscale/user.svg' className={className} />
  })
}

export const getAccountSummary = (selectedAccount, serverList, appRolesList, userList, projects, exclude = { target: false, appRole: false, project: false }) => {
  /** Prepare array for the summary cards */
  const data = []
  const errorIndex = selectedAccount ? 2 : 1
  if (!exclude.project) {
    let count = 0; let secondaryCount = 0
    projects?.forEach((project) => {
      if (selectedAccount ? project?.Spec?.Account?.RefID === selectedAccount : true) {
        count++
        if (project.Status.Status.State === 'managed') secondaryCount++
      }
    })
    /** Project card object */
    const projectData = {
      name: 'Projects',
      count: count, // total
      secondaryCount: secondaryCount, // managed
      // @ts-ignore
      Icon: ({ className }) => <Folder className={className} />,
      color: Theme.palette.success.light,
      infoArray: [
        { label: 'Managed', value: secondaryCount, type: 'plainText' }
      ]
    }
    data.push(projectData)
  }

  /** App Role card object */
  if (!exclude.appRole) {
    let count = 0; let secondaryCount = 0
    appRolesList.forEach((role) => {
      if (selectedAccount ? role?.Spec?.Account?.RefID === selectedAccount : true) {
        count++
        if (role?.Spec?.State === 'managed') secondaryCount++
      }
    })
    const roleData = {
      name: 'IAM Roles',
      count: count,
      secondaryCount: secondaryCount,
      // @ts-ignore
      Icon: ({ className }) => <img src='img/icons/iam-role.png' style={{ marginTop: '3px' }} className={className} />,
      color: Theme.palette.info.light,
      infoArray: [
        { label: 'Managed', value: secondaryCount, type: 'plainText' }
      ]
    }
    data.push(roleData)
  }

  if (!exclude.target) {
    /** Targets card object */
    const targetData = {
      name: 'Targets',
      count: 0, // total
      secondaryCount: 0, // managed
      // @ts-ignore
      Icon: ({ className }) => <Storage className={className} />,
      color: Theme.palette.warning.light,
      infoArray: getAccountsInfoArray(selectedAccount),
      footer: getAccountSummaryCardFooter(userList.length, userList.length)
    }
    /** prepare offline/online count for servers */
    serverList?.forEach(target => {
      if (selectedAccount ? target?.Spec?.Account?.RefID === selectedAccount : true) {
        targetData.count++
        if (target.Spec.State === 'managed') {
          targetData.secondaryCount++
          targetData.infoArray[0].value++
          // increament offline count
          if (target.Status.Error) { targetData.infoArray[errorIndex].value++ }
          // increament online count
          if (selectedAccount) {
            if (target.Status.State === '1') { targetData.infoArray[1].value++ }
          }
        }
      }
    })
    data.push(targetData)
  }

  return data
}

export const getProjectSummary = (project, serverList, appRolesList, exclude = { target: false, appRole: false }) => {
  /** Prepare array for the summary cards */
  const data = []

  /** App Role card object */
  if (!exclude.appRole) {
    let count = 0; let secondaryCount = 0
    appRolesList.forEach((role) => {
      const parent = role?.Spec?.GcpSpec?.Parent
      if (project ? parent?.RefID === project && parent.RefKind === 'Project' : true) {
        count++
        if (role?.Spec?.State === 'managed') secondaryCount++
      }
    })
    const roleData = {
      name: 'IAM Roles',
      count: count,
      secondaryCount: secondaryCount,
      // @ts-ignore
      Icon: ({ className }) => <img src='img/icons/iam-role.png' style={{ marginTop: '3px' }} className={className} />,
      color: Theme.palette.info.light
    }
    data.push(roleData)
  }

  if (!exclude.target) {
    /** Targets card object */
    let count = 0; let secondaryCount = 0
    serverList.forEach((server) => {
      if (project ? server?.Spec?.Project?.RefID === project : true) {
        count++
        if (server?.Spec?.State === 'managed') secondaryCount++
      }
    })
    const targetData = {
      name: 'Targets',
      count: count, // total
      secondaryCount: secondaryCount, // managed
      // @ts-ignore
      Icon: ({ className }) => <Storage className={className} />,
      color: Theme.palette.warning.light
    }
    data.push(targetData)
  }

  return data
}

export const getCredentialTypeFooter = (credentialType) => ([
  {
    icon: 'icon--user',
    text: credentialType ? credentialType.userCount : '0'
  },
  {
    icon: 'icon--group',
    text: credentialType ? credentialType.groupCount : '0'
  }
])
export const getCredentialInstaneFooter = (credentialType) => ([
  {
    icon: 'icon--first',
    text: credentialType?.targetCount || '0'
  },
  {
    icon: 'icon--second',
    text: credentialType?.iamCount || '0'
  },
  {
    icon: 'icon--third',
    text: credentialType?.dataSourcesCount || '0'
  }
])

export const getIDPImage = (idpName, kind) => {
  const providersImages = {
    Okta: 'img/okta.png',
    'Azure AD': 'img/azure.png',
    GCP: 'img/google.png',
    'Ping Identity': 'img/ping-id.png'
  }
  if (kind === 'GoogleIDProvider') return providersImages.GCP
  const imageSrc = (idpName in providersImages) ? providersImages[idpName] : providersImages.Okta
  return imageSrc
}

/**
 * Gives list of write owners for a resource
 * @param {{ObjectMeta:{WrOwners:{ObjectRef:Array<{RefID:string,RefKind:'User'|'Group' | 'SlackChannel'}>}, RdOwners: {ObjectRef:Array<{RefID:string,RefKind:'User'|'Group' | 'SlackChannel'}>}}}} resource
 * @param {Array<{ObjectMeta:{ID:string}}>} possibleOwners list of all groups or users
 * @param {'User'|'Group' | 'SlackChannel'} RefKind provided list is of users or groups
 * @returns {Array<{ObjectMeta:{ID:string}}>} owners
 */
export const listOwners = (resource, possibleOwners, RefKind, ownerType) => {
  const owners = []
  // @ts-ignore
  if (resource && resource.ObjectMeta && possibleOwners.length > 0) {
    const OwnerRefIDs = ownerType === 'read' ? resource.ObjectMeta.RdOwners.ObjectRef : resource.ObjectMeta.WrOwners.ObjectRef
    const ownerRefs = getUniqueRefs(OwnerRefIDs)
    ownerRefs.forEach((ownerRef) => {
      if (ownerRef.RefKind === RefKind) {
        const adminUser = possibleOwners.find(user => ownerRef.RefID === user.ObjectMeta.ID)
        if (adminUser) owners.push(adminUser)
      }
    })
  }
  return owners
}

export const getAge = (date) => {
  const offset = new Date().getTimezoneOffset()
  return moment
    .utc(date, 'YYYY-MM-DD HH:mm:ss')
    .utcOffset(offset).fromNow()
}
/**
 * @deprecated This util has been moved to features/targets
 **/
export const getServerName = (server, includeVpcRegion = false, withInstanceId = true) => {
  if (!server) return ['']
  if (server.Spec.Project && server.Spec.Project.RefID !== '0') return [server.Spec.Name]
  if (server?.ObjectMeta?.Kind !== 'Server') return [server.ObjectMeta.Name]
  const tagName = server?.Spec?.Tags?.Map?.Name
  const name = tagName === 'aws:cloudformation:stack-name' ? 'ProcyonProxy' : tagName
  const separater = name ? '/' : ''
  const firstName = `${name || ''}${(withInstanceId || !name) ? separater + server?.Spec?.InstanceId : ''}`
  if (!includeVpcRegion) {
    return [firstName]
  } else {
    const lastName = `${server?.Spec?.VpcId}/${server?.Spec?.Region}`
    return [firstName, lastName]
  }
}
/**
 * @param {{ObjectMeta:{},Spec:{Name:string,Tags:{Map:{Name?:string}}}}} resource
 * @return {string}
 */
export const getAccountResourceName = (resource) => {
  const tagName = resource?.Spec?.Tags?.Map?.Name
  return `${tagName ? tagName + '/ ' : ''}${resource.Spec.Name}`
}
export const getPublicDNS = (server) => {
  const DNSs = server?.Spec?.DNSNames?.Elems
  if (DNSs) {
    return DNSs?.find(e => e?.includes('amazonaws'))
  }
  return false
}
/**
 * Determines if a user is an onwer of a resource by evaluating
 * 1. if user is in the owners list.
 * 2. if user belong to a group which is in the owners list
 * @param {object} user user object
 * @param {Array<{RefID:string,RefKind:string}>} owners list of owners of a resource
 * @returns {Boolean} isOwner
 */
export const isUserResourceApprover = (user, owners) => {
  try {
    const userGroupList = user.Spec.Groups.ObjectRef.map(e => e.RefID)
    const userID = user.ObjectMeta.ID
    const isOwner = owners.some(e => (e.RefKind === 'User' && e.RefID === userID) || (e.RefKind === 'Group' && userGroupList.includes(e.RefID)))
    return isOwner
  } catch (error) {
    console.log('error in isUserResourceApprover', error)
    return false
  }
}

/**
 * Determines If request pending on current users end.
 * @param {object} user user object
 * @param {Array<{Approved:string,Approvers:{ObjectRef:Array<{RefID:string,RefKind:string}>}}>} resources all resources from the request
 * @param {{ObjectMeta:object, Type:string, Status:string}} resources all resources from the request
 * @returns {boolean} isPending - is request pending on current users end.
 */
export const isRequestPendingOnUsersEnd = (user, resources, request) => {
  /**
   * The logic to check if a service account request is pending
   */
  if (request.Type === 'ServiceAccount') return request.Status === APPROVED_STATUS.Pending
  const isPending = resources.some(resourceItem => {
    /**
     * A resource is considered pending on an user's end,
     * If both of these conditions are true
     * 1. They are ne of the approvers of the resource
     * 2. The value of the Approved property of the resource is 'New'
     */
    const approvers = resourceItem.Approvers.ObjectRef
    return resourceItem.Approved === 'New' && isUserResourceApprover(user, approvers)
  })
  return isPending
}
/**
 * Determines If a user is an onwer of a request by evaluating,
 * If user is owner of atleast one resource.
 * @param {object} user user object
 * @param {Array<{Approvers:{ObjectRef:Array<{RefID:string,RefKind:string}>}}>} resources all resources from the request
 * @returns {boolean} isOwner - user owner of this request
 */
export const isUserRequestOwner = (user, resources) => {
  try {
    /** Check if user is owner of atleast one resource from the requests */
    const isOwner = resources.some(resource => isUserResourceApprover(user, resource.Approvers.ObjectRef))
    return isOwner
  } catch (error) {
    console.log('error in isUserRequestOwner', error, resources, user)
    return false
  }
}

/**
 * Gives display information for user or group refs
 * @param {{RefKind:string,RefID:string}} entityRef reference to user or group object
 * @param {Array} groupList list of all the groups
 * @param {Array} userList list of all the users
 * @returns {{name:string,email?:string,userCount?:number, Kind: string, ID: string|boolean}} name and email of the user/group ref { name, email }
 */
export const getDisplayData = (entityRef, groupList, userList, slackChannels, serviceAccounts, jiraProjects) => {
  try {
    if (entityRef) {
      const Kind = entityRef.RefKind
      /** Find the User/Group/Channel Object from userList/groupList */
      let entity = null
      switch (entityRef.RefKind) {
        case 'SlackChannel':
          entity = slackChannels.find(e => e.ObjectMeta.ID === entityRef.RefID)
          break
        case 'User':
          entity = userList.find(e => e.ObjectMeta.ID === entityRef.RefID)
          break
        case 'Group':
          entity = groupList.find(e => e.ObjectMeta.ID === entityRef.RefID)
          break
        case 'ServiceAccount':
          entity = serviceAccounts.find(e => e.ObjectMeta.ID === entityRef.RefID)
          break
        case 'JiraProject':
          entity = jiraProjects.find(e => e.ObjectMeta.ID === entityRef.RefID)
          break
        default:
          break
      }

      if (entity) {
        switch (Kind) {
          case 'User':
            return { name: getDisplayName(entity, true), email: entity.Spec.EmailID, Kind, ID: entity.ObjectMeta.ID }
          case 'Group':
            return { name: entity.ObjectMeta.Name, userCount: entity?.Spec?.Users?.ObjectRef?.length, Kind, ID: entity.ObjectMeta.ID }
          case 'SlackChannel':
            return { name: entity.Spec.ChannelName, Kind, ID: entity.ObjectMeta.ID }
          case 'ServiceAccount':
            return { name: getServiceAccountName(entity), Kind, ID: entity.ObjectMeta.ID }
          case 'JiraProject':
            return { name: entity.Spec.ProjectName, Kind, ID: entity.ObjectMeta.ID }
        }
      }
    }
  } catch (error) {
  }
  /** Return default name and email, If the entity is not found in group/user list */
  return { name: 'N/A', email: '', Kind: 'N/A', ID: false }
}

/**
 * Decides to show the request current user only if,
 * 1. User made the request.
 * 2. Request was made for this user.
 * @param {object} user user object
 * @param {{RefID:string,RefKind:string}} requestedBy Reference to all users/groups for whome the request is made
 * @param {Array<{RefID:string,RefKind:string}>} requestedFor Reference to all users/groups for whome the request is made
 * @returns {boolean} isRequestedFor - request is by/for of this user
 */
export const isUserRequestor = (user, requestedBy, requestedFor) => {
  let userGroupList = []
  if (user?.Spec?.Groups?.ObjectRef) userGroupList = user.Spec.Groups.ObjectRef.map(e => e.RefID)
  const userID = user.ObjectMeta.ID
  /**
   * User can be requestor if
   * 1. Request is made by this user
   * 2. Request is made by a group to which this user belongs to
   */
  if ((requestedBy.RefKind === 'User' && requestedBy.RefID === userID) || (requestedBy.RefKind === 'Group' && userGroupList.includes(requestedBy.RefID))) {
    return true
  } else {
    /**
     * Request is condidered to be for a user if
     * 1. user is in the list of requestedFor
     * 2.  user is in a group that is in the list of requestedFor
     */
    const isRequestedFor = requestedFor.some(e => (e.RefKind === 'User' && e.RefID === userID) || (e.RefKind === 'Group' && userGroupList.includes(e.RefID)))
    return isRequestedFor
  }
}

/**
 * Redirects to assume role url
 * 1. Opens new tab if app container is 'browser'.
 * 2. Opens Popup window if container is 'agent'.
 * @param {string} url assume role url
 * @param {number} [height] height of popup window
 * @param {number} [width] widht of popup window
 */
export const openURLExternally = (url, height = screen.height, width = screen.width) => {
  const isContainerAgent = debug.isContainerAgent()
  console.log('isContainerAgent', isContainerAgent)
  console.log('url', url)
  if (isContainerAgent) {
    window.open(url, new Date().getTime().toString(),
      `popup,menubar=1,toolbar=1,titlebar=1,top=0,left=0,height=${height},width=${width}`)
  } else {
    window.open(url, '_blank')
  }
}
/**
 * @param {Array<{ObjectMeta:{ID:string}}>} groupList
 * @returns {{RefID?:string,RefKind?:string,name?:string,userCount?:number}|boolean}
 * */
const getAdminGroupForOwnerList = (groupList) => {
  const adminGroups = getAdminGroups(groupList)
  if (adminGroups.length > 0) {
    const adminGroup = adminGroups[0]
    const adminGroupForList = {
      RefKind: 'Group',
      RefID: adminGroup.ObjectMeta.ID,
      name: adminGroup.ObjectMeta.Name,
      userCount: adminGroup?.Spec?.Users?.ObjectRef?.length
    }
    return adminGroupForList
  }
  return false
}

/**
 *
 * @param {'loading'|'idle'} userLoading
 * @param {'loading'|'idle'} groupLoading
 * @param {{ObjectMeta:{WrOwners:{ObjectRef:Array<{RefID:string,RefKind:'User'|'Group'}>}}, RdOwners:{ObjectRef:Array<{RefID:string,RefKind:'User'|'Group'}>}}} resource
 * @param {Array<{ObjectMeta:{ID:string}}>} groupList
 * @param {Array<{ObjectMeta:{ID:string}}>} userList
 * @param {Boolean} onlyUsers dont include groups in owner list if this field is true
 * @returns {Array<{RefID?:string,RefKind?:'User'|'Group',name?:string,userCount?:number}>|boolean}
 */
export const getOwnerList = (userLoading, groupLoading, channelsLoading, resource, groupList, userList, onlyUsers = false, exclude = [], slackChannels = [], ownerType, jiraProjectListLoading, jiraProjectList) => {
  try {
    if (
      (!exclude.includes('User') && userLoading === status.LOADING) ||
      (!exclude.includes('Group') && groupLoading === status.LOADING) ||
      (!exclude.includes('SlackChannel') && channelsLoading === status.LOADING) ||
      (!exclude.includes('JiraProject') && jiraProjectListLoading === status.LOADING)
    ) {
      return false
    } else {
      const ownerFilterFn = (owner) =>
        !exclude.includes(owner.RefKind) &&
      (owner.RefKind === 'User' || owner.RefKind === 'SlackChannel' || owner.RefKind === 'JiraProject' || (!onlyUsers && owner.RefKind === 'Group'))
      const OwnerRefIDs = ownerType === 'write' ? resource.ObjectMeta.WrOwners.ObjectRef : resource.ObjectMeta.RdOwners.ObjectRef
      const ownerRefs = getUniqueRefs(OwnerRefIDs).filter(ownerFilterFn)
      console.log('owners', OwnerRefIDs, ownerRefs, jiraProjectList)
      const list = []
      ownerRefs.forEach((ref) => {
        const { name, userCount, email, ID } = getDisplayData(ref, groupList, userList, slackChannels, [], jiraProjectList)
        if (ID) list.push({ ...ref, name, userCount, email })
      })

      if (!onlyUsers) {
        /** Admin group is always WrOwner */
        const adminGroup = getAdminGroupForOwnerList(groupList)
        list.push(adminGroup)
      }

      return list
    }
  } catch (error) {
    console.error('error in listing resource owners ', error)
    return false
  }
}

/**
 * Gived recursively parsed policy JSON
 * @param {string} policyJSON JSON stringyfied policy json where the nested propertyPolicyVersion.Document is double stringyfied
 * @returns {Object}  parsed policy JSON
 */
export const parsePolicyJSONDocument = (policyJSON) => {
  try {
    const policy = JSON.parse(policyJSON)
    const document = policy.PolicyVersion.Document
    const parsedDocument = JSON.parse(document)
    policy.PolicyVersion.Document = parsedDocument
    return policy
  } catch (error) {
    console.error('error in parsePolicyJSONDocument', error)
    return policyJSON
  }
}

const getFormattedChartDate = (date, index) => {
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD YYYY')
}
export const compareDatesFn = (a, b) => {
  const aDate = moment(a?.date)
  const bDate = moment(b?.date)
  if (!a?.date || !b?.date) return 0
  if (aDate.diff(bDate) > 0) return 1
  else if (aDate.diff(bDate) < 0) return -1
  return 0
}
/**
 * Individual entry in elastic search logs
 * @typedef {{_source:{"@timestamp":string}}} hit
 */

/**
 * groups logs by date and any other specified property
 * @param {Array<hit>} data
 * @param {string} chartKey
 * @param {((hit:object)=>any)} [getUserFromHit]
 * @returns
 */
export const groupByDate = (data, chartKey = 'approle', getUserFromHit) => {
  try {
    const map = {}// {date:[hits]}
    data.forEach(hit => {
      const groupOn = getUserFromHit ? getUserFromHit(hit) : false
      const timestamp = hit._source?.['@timestamp']
      const date = timestamp.split('T')[0]
      if (date in map) {
        if (groupOn) {
          if (!map[date].groupBy.includes(groupOn)) {
            map[date][chartKey]++
            map[date].groupBy.push(groupOn)
          }
        } else {
          map[date][chartKey]++
        }
      } else {
        map[date] = { date: date, [chartKey]: 1, groupBy: [groupOn] }
      }
    })
    const dataValues = Object.values(map)
    dataValues.sort(compareDatesFn)
    return dataValues.map((e, index) => ({ ...e, formattedDate: getFormattedChartDate(e.date, index) }))
  } catch (error) {
    console.error('error in groupByDate', error)
    return []
  }
}
/**
 * groups logs by userName and any other specified property
 * @param {Array<hit>} data
 * @param {string} chartKey
 * @param {((hit:object)=>any)} [getUserFromHit]
 * @returns
 */
export const groupByUser = (data, chartKey = 'approle', getUserFromHit) => {
  try {
    const map = {}// {date:[hits]}
    data.forEach(hit => {
      const groupOn = getUserFromHit ? getUserFromHit(hit) : false
      if (groupOn in map) {
        map[groupOn][chartKey]++
      } else {
        map[groupOn] = { [chartKey]: 1, userName: groupOn, type: chartKey }
      }
    })
    const activeUsers = Object.values(map)
    return activeUsers
  } catch (error) {
    console.error('error in groupByUser', error)
    return []
  }
}

const getResorceDisplayName = (type, resource, refName) => {
  const getResourceName = () => type === 'Server' ? getServerName(resource) : resource.Spec.RoleName
  const displayName = resource ? getResourceName() : refName
  return displayName
}

export const getResourceInfoUIData = (resorceDisplayName, type, account) => {
  try {
    const data = [
      {
        Icon: getResourceIcon(type),
        text: resorceDisplayName,
        textColor: 'primary'
      },
      {
        Icon: ({ className }) => <img className={className} src={getAccountImage(account?.Spec?.Type, true)} />,
        text: account?.ObjectMeta?.Name || 'N/A',
        textColor: 'default'
      }
    ]
    return data
  } catch (error) {
    console.error('error in getResourceInfoUIData', error)
    return []
  }
}
/**
 * Gives average formatted upto N decimal points
 * @param {number} total
 * @param {number} count
 * @param {number} tillDecimalPoint
 * @returns {number}
 */
export const getAvg = (total, count, tillDecimalPoint = 1) => {
  try {
    const avg = count / total
    const formattedAvg = parseFloat(avg.toFixed(tillDecimalPoint))
    return formattedAvg
  } catch (error) {
    console.error('error in calculating avg', error)
    return 0
  }
}
/**
 * @typedef {{ count: number, users: Array<string>, type:string, resourceInfo:Array, dailyAverage:number, resourceName:string}} resourceActivity
 */
/**
 * groups logs by anyGiven Key
 * @param {Array<hit>} data
 * @param {'Role'|'Server' } type
 * @param {((hit:object)=>any)} getResourceNameFromHit
 * @param {((hit:object)=>any)} getUserFromHit
 * @param {Array<{ObjectMeta:{Name:string}, Spec:{Account:{RefID:string},RoleName:string}}>} resourceList
 * @param {Array<{ObjectMeta:{Name:string}}>} accountList
 * @param {number} numberOfDays
 * @returns {Array<resourceActivity>}
 */
export const resourceActivityAdapter = (data, type, getResourceNameFromHit, getUserFromHit, resourceList, accountList, numberOfDays = 1) => {
  try {
    /**
     * @type {{[resourceName:string]:resourceActivity}}
     */
    const map = {}
    data.forEach(hit => {
      const resourceName = getResourceNameFromHit ? getResourceNameFromHit(hit) : false
      const resource = resourceList.find(e => e.ObjectMeta.Name === resourceName)
      const resorceDisplayName = getResorceDisplayName(type, resource, resourceName)
      const user = getUserFromHit ? getUserFromHit(hit) : false
      const account = (type === 'Server' ? getTargetAccount(accountList, resource) : getAppRoleAccount(accountList, resource)) || {}

      if (resourceName in map) {
        map[resourceName].count++
        if (!(map[resourceName].users.includes(user))) map[resourceName].users.push(user)
      } else {
        const resourceInfo = getResourceInfoUIData(resorceDisplayName, type, account)
        map[resourceName] = { count: 1, users: [user], type, resourceInfo, dailyAverage: 0, resourceName: resorceDisplayName }
      }

      map[resourceName].dailyAverage = getAvg(numberOfDays, map[resourceName].users.length, 2)
    })
    const resourceGroups = Object.values(map)
    return resourceGroups
  } catch (error) {
    console.error('error in groupByUser', error)
    return []
  }
}
/**
 *
 * @typedef {{ name: string, color: string, label: string }} activityDistributionItem
 * @typedef { { [resouceType:string]: activityDistributionItem}} activityDistribution
 */
/**
 *
 * @param {Object} data
 * @param {activityDistribution} template
 * @param {'appRole'|'servers'} type
 * @returns {(activityDistributionItem & {value:number}) | {}}
 */
const addActivityDistribution = (data, template = {}, type) => {
  try {
    if (type in template) {
      return { ...template[type], value: data[type] }
    }
    return { name: type, value: data[type], color: 'grey', label: type }
  } catch (error) {
    console.error('error in addActivityDistribution', error)
    return {}
  }
}

/**
 * Mixes results of two log sources to give objects with total session count
 * @param {Array} data
 * @param {Array<{ObjectMeta:{Name:string}}>} userList
 * @param {string} groupOn
 * @param { activityDistribution} template
 * @returns { Array<{ name:string, index:number, sessions:number, activityDistribution: activityDistribution }>}
 */
export const mixSessionCounts = (data, userList = [], groupOn = 'userName', template = {}) => {
  try {
    const mixMap = {}
    data.forEach((item) => {
      const hitUser = item[groupOn]
      if (mixMap[hitUser]) {
        mixMap[hitUser] = { ...mixMap[hitUser], ...item }
        mixMap[hitUser].sessions += parseInt(item[item.type])
        mixMap[hitUser].activityDistribution.push(addActivityDistribution(item, template, item.type))
        delete mixMap[hitUser].type
      } else {
        const user = userList.find(e => e.ObjectMeta.Name === hitUser)
        mixMap[hitUser] = {
          ...item,
          sessions: parseInt(item[item.type]),
          name: getDisplayName(user, true),
          activityDistribution: [addActivityDistribution(item, template, item.type)]
        }
      }
    })
    const activeUsers = Object.values(mixMap)
    activeUsers.sort((a, b) => b.sessions - a.sessions)
    const activeUsersWithIndex = activeUsers.map((e, i) => ({ ...e, index: i + 1 }))
    return activeUsersWithIndex
  } catch (error) {
    console.error('error in mixSessionCounts', error)
    return []
  }
}
export const populateResourcesAccessSubjects = (subjects = [], users = [], groups = [], ignoreBaseActions = false) => {
  const populatedData = []
  // add items whose actions needs to be ignored
  const baseActions = []
  let filteredSubjects = [...subjects]
  if (!ignoreBaseActions) {
    filteredSubjects = subjects.filter(item => {
      // get actions which are not in `baseActions`
      const mainActions = item.Action?.filter(action => !baseActions.includes(action?.toLowerCase()))
      // if the subject has action other than what's in `baseActions` return true
      if (!mainActions?.length) return false
      return true
    })
  }
  filteredSubjects.forEach((subject) => {
    const { Subject, Action } = subject
    if (Subject?.RefKind === 'User') {
      const user = users.find(item => item.ObjectMeta.ID === Subject?.RefID)
      if (user) populatedData.push({ ...user, Action })
    } else if (Subject?.RefKind === 'Group') {
      const group = groups.find(item => item.ObjectMeta.ID === Subject?.RefID)
      if (group) populatedData.push({ ...group, Action })
    }
  })
  return populatedData
}
export const getEventInformation = ({ message, account, resource, type, device = {} }) => {
  try {
    const accountName = account ? account.ObjectMeta.Name : 'N/A'
    const accountId = account ? account.Spec.AccountID : 'N/A'
    if (type === 'Server') {
      const name = resource ? getServerName(resource, false) : 'N/A'
      if (typeof message === 'string' && message.includes('User logged in to SSH server')) {
        return `Start SSH session to target ${name} in account - ${accountName}/ ${accountId}`
      }
      return `End SSH session to target ${name} in account - ${accountName}/ ${accountId}`
    } else if (type === 'AppRole') {
      const name = resource?.ObjectMeta?.Name || 'N/A'
      return `Assumed role ${name} in account - ${accountName}/ ${accountId}`
    } else if (type === 'OnBoard') {
      return `${getDeviceName(device)} Onboard by Token.`
    } else if (type === 'connected') {
      return `${getDeviceName(device)} Device connected to proxy.`
    }
  } catch (error) {
    console.error('error in getEventInformation', error)
    return ''
  }
}

/**
 * @typedef {{user: Object, message:String, account: Object, resource: Object, device: Object, time:String, project:Object, type:String}} logTableAdapterProps
 * @param {logTableAdapterProps} props
 * @returns
 */
export const activityLogTableAdapter = ({ user, message, account, resource, device, time, type, project }) => {
  try {
    const avaibaleAccount = (typeof account === 'object' && Object.keys(account).length > 0) ? account : undefined
    let resourceName = ''
    if (type === 'OnBoard') {
      resourceName = getDeviceName(device)
    } else if (type === 'Server') {
      // @ts-ignore
      resourceName = getServerName(resource)
    } else if (type === 'connected') {
      resourceName = getDeviceName(device)
    } else {
      resourceName = resource?.Spec?.RoleName || 'N/A'
    }

    return {
      time,
      user: getDisplayName(user),
      deviceName: device?.Attributes?.DeviceName,
      type,
      Resource: resourceName,
      eventInformation: getEventInformation({ message, account: avaibaleAccount, resource, type, device }),
      collapsable: { resource: resource, user, device, time, message, account: avaibaleAccount, project }
    }
  } catch (error) {
    console.error('error in activityLogTableAdapter', error)
    return { collapsable: {} }
  }
}
/**
 *
 * @param {*} hit
 * @param {*} userList
 * @param {*} deviceList
 * @param {*} serverList
 * @param {*} accountList
 * @param {*} projects
 * @param {Array.<'user' | 'device' | 'account' | 'resource' | 'project'>} exclude The items to exclude for optimizations
 * @returns {{time?:String, message?:String, user?:Object, device?:Object, account?:Object,project:Object, resource?: Object}}
 */
export const sshLogObjectAdapter = (hit, userList, deviceList, serverList, accountList, projects, exclude = []) => {
  try {
    const time = hit?._source?.['@timestamp']
    const message = hit?._source?.Message
    const data = hit?._source?.Data
    const { ServerName, User: userName, Device: deviceName } = data
    const server = !exclude.includes('resource') && serverList.find(e => e.ObjectMeta.Name === ServerName)
    const user = !exclude.includes('user') && userList.find(e => e.ObjectMeta.Name === userName)
    const device = !exclude.includes('device') && deviceList.find(e => e.ObjectMeta.Name === deviceName)
    const account = !exclude.includes('account') && getTargetAccount(accountList, server)
    const project = !exclude.includes('project') && projects && projects.find(e => e.ObjectMeta.ID === server.Spec.Project.RefID)
    return { time, message, user, device, account, resource: server, project }
  } catch (error) {
    console.error('error in sshLogAdapter', error, hit)
    return {}
  }
}

/**
 *
 * @param {*} hit
 * @param {*} userList
 * @param {*} deviceList
 * @param {*} serverList
 * @param {*} accountList
 * @param {Array.<'user' | 'device' | 'account' | 'resource'>} exclude The items to exclude for optimizations
 * @returns
 */
export const sshLogAdapter = (hit, userList, deviceList, serverList, accountList, projects, exclude = []) => {
  try {
    const sshLogObject = sshLogObjectAdapter(hit, userList, deviceList, serverList, accountList, projects, exclude = [])
    // @ts-ignore
    const tableRow = activityLogTableAdapter({ ...sshLogObject, type: 'Server' })
    return tableRow
  } catch (error) {
    console.error('error in sshLogAdapter', error, hit)
    return { collapsable: {} }
  }
}

/**
 *
 * @param {*} hit
 * @param {*} userList
 * @param {*} deviceList
 * @param {*} appRoleList
 * @param {*} accountList
 * @param {*} projects
 * @param {Array.<'user' | 'device' | 'account' | 'resource' | 'project'}>} exclude
 */
export const assumeRoleLogObjectAdapter = (hit, userList, deviceList, appRoleList, accountList, projects, exclude = []) => {
  try {
    const data = hit._source
    const { Resources, User: { Name: userName }, Device: { Name: deviceName } } = data
    // const roleref = Resources.find(e => e.Kind === 'AppRole')
    const roleref = Resources && Resources.length > 0 ? Resources[0] : null
    const appRole = !exclude.includes('resource') && appRoleList.find(e => e.ObjectMeta.Name === roleref?.Name)
    const user = !exclude.includes('user') && userList.find(e => e.ObjectMeta.Name === userName)
    const device = !exclude.includes('device') && deviceList.find(e => e.ObjectMeta.Name === deviceName)
    const time = hit._source['@timestamp']
    const account = !exclude.includes('account') && getAppRoleAccount(accountList, appRole)
    const project = !exclude.includes('project') && projects && projects.find(e => e.ObjectMeta.ID === appRole?.Spec?.GcpSpec?.Parent?.RefID && appRole?.Spec?.GcpSpec?.Parent?.RefKind === 'Project')
    const message = hit._source.Message
    return { user, device, time, account, message, resource: appRole, project }
  } catch (error) {
    console.error('error in assumeRoleLogAdapter', error)
    return {}
  }
}

export const eventLogDeviceOnboardedAdapter = (hit, userList, deviceList, exclude = []) => {
  
  try {
    const data = hit._source
    const { User: { Name: userName }, Device: { Name: deviceName } } = data
    const user = !exclude.includes('user') && userList.find(e => e.ObjectMeta.Name === userName)
    const device = !exclude.includes('device') && deviceList.find(e => e.ObjectMeta.Name === deviceName)
    const time = hit._source['@timestamp']
    const message = hit._source.Message
    return activityLogTableAdapter({ user, device, time, account: false, message, resource: { ObjectMeta: { Name: getDeviceName(device) } }, type: 'OnBoard' })
  } catch (error) {
    console.error('error in assumeRoleLogAdapter', error)
    return {}
  }
}

export const proxyEventLogDeviceOnboardedAdapter = (hit, userList, deviceList, exclude = []) => {
  try {
    const data = hit._source.Data
    const { User: { Name: userName }, Device: { Name: deviceName } } = data
    const user = !exclude.includes('user') && userList.find(e => e.ObjectMeta.Name === userName)
    const device = !exclude.includes('device') && deviceList.find(e => e.ObjectMeta.Name === deviceName)
    const time = hit._source['@timestamp']
    const message = hit._source.Message
    return activityLogTableAdapter({ user, device, time, account: false, message, resource: { ObjectMeta: { Name: getDeviceName(device) } }, type: 'connected' })
  } catch (error) {
    console.error('error in assumeRoleLogAdapter', error)
    return {}
  }
}

export const assumeRoleLogAdapter = (hit, userList, deviceList, appRoleList, accountList, projects) => {
  try {
    const assumeRoleLogObject = assumeRoleLogObjectAdapter(hit, userList, deviceList, appRoleList, accountList, projects, [])
    // @ts-ignore
    const tableRow = activityLogTableAdapter({
      ...assumeRoleLogObject,
      type: 'AppRole'
    })
    return tableRow
  } catch (error) {
    console.error('error in assumeRoleLogAdapter', error)
    return { collapsable: {} }
  }
}

export const getDeviceName = (device) => {
  try {
    return device?.Attributes?.DeviceName || device.ObjectMeta.Name
  } catch (error) {
    console.error('error in getting device name', error)
  }
}

/**
 * Creates payload for onboarding link post request
 * @param {{ObjectMeta:{Name:String,Namespace:String,Tenant:String}}} user
 * @returns {{ObjectMeta:{Namespace:String,Tenant:String},UserID:String}}
 */
export const constructMagicLinkPayload = (user) => {
  try {
    const payload = {
      ObjectMeta: {
        Tenant: user.ObjectMeta.Tenant,
        Namespace: user.ObjectMeta.Namespace
      },
      UserID: user.ObjectMeta.Name
    }
    return payload
  } catch (error) {
    console.error('Error in creating magic link payload', error)
  }
}

/**
 * Extracts list of unique regions and vpcs from all the servers
 * @param {Array<{Spec:{Region:string,VpcId:string}}>} serverList
 * @returns {{regions:Array<string>,vpcs:Array<string>}}
 */
export const getRegionVpcFromServers = (serverList) => {
  console.log('serverList1s', serverList)
  const regions = []
  const vpcs = []
  serverList.forEach((server) => {
    const region = server.Spec.Region
    const vpc = server.Spec.VpcId
    /** Omit duplicate entries */
    if (!regions.includes(region)) regions.push(region)
    if (!vpcs.includes(vpc)) vpcs.push(vpc)
  })
  return { regions, vpcs }
}

export const mixMapFn = (data = [], summationFn = obj => obj) => {
  const mixMap = {}
  data.forEach((item) => {
    // item.date = item.date
    if (mixMap[item.date]) {
      mixMap[item.date] = { ...mixMap[item.date], ...item }
    } else {
      mixMap[item.date] = item
    }
    summationFn(mixMap[item.date])
  })
  return Object.values(mixMap)
}
export const getUserFromHit = (hit) => {
  if (hit._index === 'proxy-audit-log') return hit._source.Data.User
  if (hit._index === 'event-log') return hit._source.User.Name
}

/**
 * Gives and array of object with unique entries bassed on a key
 * @param {Array<{RefID:string,RefKind:string}>} refs
 * @returns {Array<{RefID:string,RefKind:string}>}
 */
export const getUniqueRefs = (refs) => {
  try {
    const unique = [...new Map(refs.map((item) => [item.RefID + item.RefKind, item])).values()]
    return unique
  } catch (error) {
    console.log('error in getUniqueRefs', error)
    return []
  }
}
/**
 *
 * @param {'asc'|'dsc'} order
 * @returns {Function}
 */
export const sortCompareHOF = (order, key = 'sort') => {
  return (obj1, obj2) => {
    if (!obj1.data) return 1
    if (typeof obj1.data[key] === 'string') {
      return obj1.data[key].localeCompare(obj2.data[key]) * (order === 'asc' ? 1 : -1)
    } else if (typeof obj1.data[key] === 'number') {
      return (obj1.data[key] - obj2.data[key]) * (order === 'asc' ? 1 : -1)
    }
  }
}

/**
 * Gives duration between two dates
 * @param {Date|String} startTime
 * @param {Date|String} endTime
 * @returns {String} Duration
 */
export const timeSummary = (startTime, endTime) => {
  try {
    const startMoment = moment(startTime)
    const endMoment = moment(endTime)
    if (startMoment.isValid() && endMoment.isValid()) {
      return endMoment.from(startMoment).split('in')[1]
    }
  } catch (error) {
    console.log('error in TimeSummary', error)
  }
  return 'N/A'
}

export const getResourceAccountType = (resource) => {
  let projectID = null
  if (!resource || (resource?.Spec?.Project?.RefID === '0')) return
  if (resource.ObjectMeta.Kind === 'AppRole') {
    projectID = ['Project', 'Account'].includes(resource.Spec.GcpSpec?.Parent?.RefKind) &&
    resource.Spec.GcpSpec?.Parent?.RefID
  } else if (resource.ObjectMeta.Kind === 'Server') {
    projectID = resource.Spec?.Project?.RefID
  }
  return projectID ? 'GCP' : 'AWS'
}

export const getProjectAccount = (project, accounts = []) => {
  const accountId = project?.Spec?.Account?.RefID
  if (!accountId) return
  return accounts.find((account) => account?.ObjectMeta.ID === accountId)
}
export const getGcpResourceAccount = (project, accounts = []) => {
  const accountId = project?.Spec?.Account?.RefID
  if (!accountId) return
  return accounts.find((account) => account?.ObjectMeta.ID === accountId)
}
export const getKubeNamespaceAccount = (namespace, awsResources = [], accounts = []) => {
  const awsResource = awsResources.find(e => e.ObjectMeta.ID === namespace.Spec.Cluster.RefID)
  const accountId = awsResource?.Spec?.Account?.RefID
  if (!accountId) return
  return accounts.find((account) => account?.ObjectMeta.ID === accountId)
}
/**
 * @deprecated Use this helper from features/resources
 * @param {*} resource
 * @returns
 */
export const isResourceManaged = (resource) => {
  if (_.get(resource, 'ObjectMeta.Kind') === 'Project') { return _.get(resource, 'Status.Status.State', 'unmanaged') === 'managed' }
  return _.get(resource, 'Spec.State', 'unmanaged') === 'managed'
}

export const getApplicationHostName = (application) => {
  const FrontendName = _.get(application, 'Spec.HttpAppConfig.FrontEndName.Elems', [])[0]
  const SAMLACSURL = _.get(application, 'Spec.SamlServiceProvider.ServiceProviderACSURL')
  return FrontendName || SAMLACSURL
}

export const getProcyonDnsName = (resource) => {
  return _.get(resource, 'Spec.DNSNames.Elems', []).find(e => e?.includes('servers.procyon.ai'))
}

export const openURLSafely = (url = '') => {
  if (url.startsWith('http://') || url.startsWith('https:/')) return openURLExternally(url)
  openURLExternally('https://' + url)
}
export const getSafeURL = (url = '') => {
  if (url.startsWith('http://') || url.startsWith('https:/')) return url
  return 'http://' + url
}
/**
 * @deprecated Use the export from `features/resources`
 * Gives Display name for resource
 * @param {{ObjectMeta:{Name:string,Kind:string}, Spec:{RoleName:string,Name:string}, GivenName?: string}} resource
 * @param {boolean} includeVpcNameForServer
 */
export const getResourceName = (resource, includeVpcNameForServer = false) => {
  switch (resource.ObjectMeta.Kind) {
    case 'AppRole':
      return resource.GivenName || resource.Spec.RoleName
    case 'Project':
      return resource.Spec.DisplayName || resource.Spec.Name
    case 'GcpResource':
      return resource.Spec.DisplayName || resource.Spec.Name
    case 'AwsResource':
      return resource.Spec.DisplayName || resource.Spec.Name
    case 'KubeNamespace':
      return resource.Spec.DisplayName || resource.Spec.Name
    case 'AzureResource':
      return resource.Spec.DisplayName || resource.Spec.Name
    case 'IamAction':
      return getIamActionName(resource)
    case 'Server':
      return getServerName(resource, includeVpcNameForServer)
    case 'Database':
      return resource.Spec.CloudType === 'AZURE' ? resource.Spec.DisplayName : resource.Spec.Name
  }
  return resource.ObjectMeta.Name
}

const getBase64DecodedString = (base64EncodedString) => {
  try {
    return Buffer.from(base64EncodedString, 'base64').toString('utf-8')
  } catch (error) {
    console.error('error in getBase64DecodedString', error)
    return base64EncodedString
  }
}
export const getIntegrationIcon = (kind) => {
  const icons = {
    SlackIntegration: SlackIcon,
    JiraIntegration: JIRAIcon,
    ServiceNowIntegration: ServiceNow 
  }
  return icons[kind] || SlackIcon
}

export const hasApprovalRequestExpired = (request) => (new Date(request?.Spec?.NotAfter).getTime() - new Date().getTime() < 0)

export const safeJSONParse = (jsonString, fallback = {}) => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    return fallback
  }
}

export const getServiceAccountName = (serviceAccount) => {
  let name
  try {
    if (serviceAccount.Spec.Type === 'AWS') {
      name = serviceAccount.Status.AwsStatus.UserName
    }
    if (serviceAccount.Spec.Type === 'GCP') {
      name = serviceAccount.Spec.GcpSpec.Name
    }
    if (serviceAccount.Spec.Type === 'AZURE') {
      name = serviceAccount.Spec.AzureSpec.DisplayName
    }
    return name
  } catch (error) {
    return serviceAccount.ObjectMeta.Name
  }
}
export const getIamActionName = (iamAction) => {
  try {
    const type = iamAction.Spec.CloudType
    if (type === 'AZURE') return iamAction.Spec.RoleName || iamAction.ObjectMeta.Name
    return iamAction.ObjectMeta.Name
  } catch (error) {
    console.error('error in getIamActionName', error)
    return 'N/A'
  }
}
/**
 *
 * @param {*} r
 * @returns @deprecated This is depreacated, use the function from `/features/resources`
 */
export const createRsrcKey = (r) => {
  if (!r) return '-'
  return `${_.get(r, 'ObjectMeta.Kind')}+${_.get(r, 'ObjectMeta.ID')}`
}


/**
 * 
 * @param {Object.<any, number>} counts 
 * @returns {string}
 */
export const getCountSummaryString = (counts) => {
  let str = ''
  const countStr = []
  
  for (const key in counts) {
    const count = counts[key]
    countStr.push(`${count} ${key}`)
  }

  str = `${countStr.slice(0, countStr.length > 1 ? countStr.length - 1 : 1).join(', ')} ${
    countStr.length >= 2 ? 'and ' + countStr[countStr.length - 1] : ''
  }`

  return str
}

/**
 * Convert the string into an space string by adding space in front of every caps character
 * 
 * Eg,
 *
 * ACROText -> ACRO Text
 * UserNameTest -> User Name Test
 * 
 * @param {string} string 
 */
export const addSpaces = (string) => {
  string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return string
}

export const isValidEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const getServiceAccountDisplayName = (sa) => {
  const KeyMap = {
    GCP: 'Status.GcpStatus.Email',
    AWS: 'Status.AwsStatus.UserName',
    AZURE: 'Status.AzureStatus.DisplayName'
  }

  const value = _.get(sa, KeyMap[sa.Spec.Type], '' )
  return value || sa.ObjectMeta.Name
}