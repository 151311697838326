import { AccountSelectionItem, TargetIcon } from 'procyon-ui'
import React from 'react'

const WebhookChannelSelection = ({
  isWebhookChannelSelected,
  setIsWebhookChannelSelected,
  kind
}) => {
  return (
    <>
      <AccountSelectionItem
        assigned={isWebhookChannelSelected}
        accountName={'Webhook Url Channel'}
        selected={isWebhookChannelSelected}
        icon={<TargetIcon type={kind} />}
        onClickAddButton={() => setIsWebhookChannelSelected(true)}
        width='100%'
        key={1}
      />
    </>
  )
}

export { WebhookChannelSelection }
