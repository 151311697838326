import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityInfoBar, FullScreenAlertModal } from 'V2Components'
import { deleteGroup, getUserRefsFromGroup } from 'features/groups'
import { createRsrcKey, getResourceName } from 'features/resources'
import { PickListContainer, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function BulkDeleteGroupModal({ groups = [], onCancel, onUpdate, onSuccess }) {
  const [deleteConfirmText, setDeleteConfirmText] = useState('')

  const handleGroupRemoveClick = (group) => {
    onUpdate(groups.filter((g) => createRsrcKey(g) !== createRsrcKey(group)))
  }

  const handleBulkDeleteClick = async () => {
    try {
      const apiCalls = groups.map(async (u) => await deleteGroup(u))
      const deletedGroups = await Promise.all(apiCalls)
      deletedGroups.forEach(deleteSliceData)
      enqueueNotification(`Successfully Deleted ${groups.length} groups!`, 'info')
      onSuccess?.()
    } catch (error) {
      enqueueNotification('Unable to delete groups', 'error')
    }
  }

  useHTMLBodyScrollbar(true)

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`You are about to delete the following ${groups.length} groups`}
        loadingMessage={`Deleting all ${groups.length} groups.`}
        onActionClick={handleBulkDeleteClick}
        onCancel={onCancel}
        disableAction={deleteConfirmText !== 'delete' || !groups.length}
        Content={
          <div>
            <PickListContainer
              style={{
                height: '400px',
								width: '100%',
								backgroundColor: 'white'
              }}
            >
              {groups.map((u) => (
                <EntityInfoBar
                  assigned
                  caption={`${getUserRefsFromGroup(u).length} Users`}
                  style={{ width: '100%' }}
                  key={createRsrcKey(u)}
                  kind='Group'
                  name={getResourceName(u)}
                  onClickRemoveButton={() => handleGroupRemoveClick(u)}
                />
              ))}
            </PickListContainer>
            <div className='mt-4'>
              <Typography className='my-2' variant='buttonLabel-regular'>
                The groups will be <span className='text-red-500 mx-2'>permanently</span> deleted
                and cannot be recovered.
              </Typography>
              <TextInput
                value={deleteConfirmText}
                onChange={(e) => setDeleteConfirmText(e.target.value)}
                sx={{ width: '100%' }}
                label={`Enter 'delete' to confirm`}
              />
            </div>
          </div>
        }
        showModal
      />
    </div>
  )
}

export { BulkDeleteGroupModal }
