import { createAccount, isValidAccountCredentials, updateAccount } from "features/clouds"

export function useAZUREForm() {
  const checkAZUREAccountCredentials = async ({ tenantID, appID, appKey }) => {
    const payload = {
      ObjectMeta: {
        Name: 'azure-test'
      },
      Spec: {
        Automate: false,
        AzureSpec: {
          TenantID: tenantID.trim(),
          ProcyonAppID: appID.trim(),
          ProcyonAppKey: appKey.trim()
        },
        Description: '',
        Discovery: true,
        RemoteProxy: false,
        AccountType: 'AZURE'
      }
    }
    return  await isValidAccountCredentials(payload)
  }

  const handleAZUREAccountCreate = async ({ tenantID, appID, appKey, name, description, org }) => {
    const payload = {
      ObjectMeta: {
        Name: name.trim(),
        Namespace: org
      },
      Spec: {
        Automate: false,
        AzureSpec: {
          TenantID: tenantID.trim(),
          ProcyonAppID: appID.trim(),
          ProcyonAppKey: appKey.trim()
        },
        Description: description.trim(),
        Discovery: true,
        RemoteProxy: false,
        Type: 'AZURE'
      }
    }
    return await createAccount(payload)
  }

  const handleAZUREAccountUpdate = async ({ tenantID, appID, appKey, description, account }) => {
    const acc = structuredClone(account)
    const azureSpec = {
      TenantID: tenantID.trim(),
      ProcyonAppID: appID.trim(),
      ProcyonAppKey: appKey.trim()
    }
    acc.Spec.Description = description.trim()
    acc.Spec.AzureSpec = azureSpec

    return await updateAccount(acc)
  }

  return { checkAZUREAccountCredentials, handleAZUREAccountCreate, handleAZUREAccountUpdate }
}