import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { deleteRepository } from 'features/github'
import React from 'react'

function DeleteTeamModal({ app, cleanUpFn, onCancel = null, name }) {
  const handleDeleteApplication = async () => {
    const deletedApp = await deleteRepository(app)
    deleteSliceData(deletedApp)
    enqueueNotification('Team Deleted Successfully!', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`Are you sure you want to delete the team ${name} ?`}
        loadingMessage='Deleting Team'
        onActionClick={handleDeleteApplication}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeleteTeamModal }
