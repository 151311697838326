
import CONSTANTS from 'Utils/Constants'
import { errorHandler, getUserInfo } from '../Utils/Helpers'
import { getRequest } from './NetworkRequest'

export class accessGrantApis {
  /** fetch all credentials List */

  static async getShhAccessList () {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = getRequest(apiKey, `authzactioncheck/${org}/Server+${CONSTANTS.approvalRequest.Server.default}`, tenant)
      return response
    } catch (error) {
      errorHandler(error, 'appuserprofiles', 'approlesApis.js')
    }
  }

  static async getRoleAccessList () {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = getRequest(apiKey, `authzactioncheck/${org}/AppRole+${CONSTANTS.approvalRequest.Role.default}`, tenant)
      return response
    } catch (error) {
      errorHandler(error, 'appuserprofiles', 'approlesApis.js')
    }
  }
}
