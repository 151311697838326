import { faHammer, faWarning } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Label, Tooltip, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { LoadingFeedback } from 'V2Components'

const FooterComponent = ({
  activeStep,
  setActiveStep,
  totalSteps,
  onSubmit,
  indexedErrors,
  isEditMode
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const outOfFocusErrors = indexedErrors.find((e) => e?.length)
  // show other steps errors only on last summary page with the fix button
  const errorMessage = activeStep === totalSteps - 1 ? outOfFocusErrors : indexedErrors[activeStep]
  const erroredStep = indexedErrors.findIndex((e) => e?.length)
  const showFixErrorBtn = erroredStep !== activeStep
  const isLastStep = activeStep === totalSteps - 1

  const handleFixErrorClick = () => {
    setActiveStep(erroredStep)
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      await onSubmit()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='flex justify-end gap-4 items-center mt-4'>
      {errorMessage && (
        <Label
          text={
            <div className='flex gap-2 items-center'>
              <Typography
                className='!text-yellow-500 flex gap-2 items-center'
                variant='caption-regular'
              >
                <FontAwesomeIcon icon={faWarning} />
                {errorMessage}
              </Typography>
              {showFixErrorBtn && (
                <Tooltip title='Go and Fix' arrow placement='top'>
                  <button
                    onClick={handleFixErrorClick}
                    className='rounded px-[6px] py-1 text-xs hover:bg-yellow-500 bg-yellow-400 text-white'
                  >
                    <FontAwesomeIcon icon={faHammer} />
                  </button>
                </Tooltip>
              )}
            </div>
          }
          variant='warning'
        />
      )}
      {activeStep > 0 && (
        <Button onClick={() => setActiveStep((s) => s - 1)} variant='grayBlue'>
          Previous
        </Button>
      )}
      {isLastStep && (
        <Button disabled={!!errorMessage} onClick={handleSubmit} variant='primary'>
          {isEditMode ? 'Update' : 'Create'}
        </Button>
      )}
      {!isLastStep && (
        <Button
          disabled={!!errorMessage}
          onClick={() => setActiveStep((s) => s + 1)}
          variant='primary'
        >
          Next
        </Button>
      )}
      <LoadingFeedback
        loading={isLoading}
        message={`${isEditMode ? 'Updating' : 'Creating'} Proxy Controller`}
        caption='Please wait..'
      />
    </div>
  )
}

export { FooterComponent }
