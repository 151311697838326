// @ts-nocheck
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Modal,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import classNames from 'classnames'
import React from 'react'

const useStyle = makeStyles(theme => ({

  Close: {
    justifySelf: 'flex-end',
    marginLeft: 'auto',
    marginRight: '10px'
  },
  title: {
    marginLeft: '10px',
    fontSize: theme.typography.h6.fontSize
  },
  card: {
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    height: props => props.height,
    width: props => props.width,
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    padding: props => props.headerPadding || theme.spacing(2, 2, 0, 2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    borderBottom: props => props.headerBorder && `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: (props) => props.background === 'white' ? theme.palette.background.paper : theme.palette.background.default
    // backgroundColor: theme.palette.background.paper
  },
  cardContent: {
    flexGrow: 1,
    // minHeight: '200px',
    overflowY: 'auto',
    maxHeight: props => props.noMaxHeight ? 'unset' : 'calc( 100% - 60px )',
    padding: '0px!important',
    display: 'flex',
    flexDirection: 'column'
  },
  close: {
    marginLeft: 'auto'
  }
}))
const FlexModal = ({
  children,
  Title,
  width = '40.5rem',
  height = '32.5rem',
  isOpen,
  handleClose,
  Icon,
  background,
  headerPadding = false,
  headerBorder = false,
  titleBoxProps = {},
  showModalHeader = true,
  showCloseButton = false,
  noMaxHeight = false
}) => {
  const classes = useStyle({ background, headerBorder, headerPadding, noMaxHeight, height, width, showModalHeader })
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      BackdropComponent={Backdrop}
      onBackdropClick={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500
      }}
    >
      <Card className={classes.card}>
        {showModalHeader && (

          <div className={classes.cardHeader}>
            {Icon && <Icon />}
            <Typography variant='h5' {...titleBoxProps}>{Title}</Typography>
            {
                showCloseButton && <IconButton className={classes.close} onClick={handleClose}><Close /></IconButton>
              }
          </div>
        )}
        <CardContent className={classes.cardContent}>{children || <></>}</CardContent>
      </Card>
    </Modal>
  )
}

export default FlexModal
