import React from 'react'
import { Route } from 'react-router'
import { Settings } from '../views/Settings'

const SettingsRoute = () => {
  return (
    <>
      {/* @ts-ignore*/}
      <Route
        exact
        path={['/admin/settings', '/admin/settings/:tab', '/admin/settings/:tabName']}
        component={Settings}
      />
    </>
  )
}

export { SettingsRoute }
