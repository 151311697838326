import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.625, 1.5, 0.5, 0.75),
    borderRadius: theme.spacing(0.25),
    boxShadow: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.13)',
    border: `solid 1px ${theme.palette.grey['200']}`,
    backgroundColor: theme.palette.grey['50'],
    wordBreak: 'break-all'
  }
}))
const LinkBox = ({ children, ...rest }) => {
  const classes = useStyle()
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  )
}

export default LinkBox
