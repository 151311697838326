import useAppView from 'Core/Hooks/useAppView'
import { useRouteParams } from 'Core/Hooks/useRouteParams'
import { useCloudTypes } from 'features/clouds'
import { AccessCartProvider, useAccessCartProvider } from 'features/resources'
import { CreateRequestFlow, PRINCIPAL_OBJECT_KINDS, TargetsView } from 'features/targets'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

const QUEUE_KEY = 'userTargets'

function UserTargets() {
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const [activeCloudType, setActiveCloudType] = useState('')

  const [views, setViews] = useState({
    principalModal: false,
    accessRequestSubmissionModal: false,
    userGroupSelectionModal: false
  })

  const history = useHistory()
  const { appView } = useAppView()
  const { cloudTypesWP } = useCloudTypes()

  const { addItemsToCart } = useAccessCartProvider()

  const handleResourceAccessClick = (rsrc) => {
    const rsrcKind = rsrc.ObjectMeta.Kind
    if (rsrcKind === 'AppRole' || rsrcKind === 'Kafka') {
      addItemsToCart({
        principal: '',
        resourceRef: {
          RefKind: rsrcKind,
          RefID: rsrc.ObjectMeta.ID
        },
        roles: []
      })
      return
    }

    if (rsrc.ObjectMeta.Kind === 'KubeCluster' || rsrc.ObjectMeta.Kind === 'KubeNamespace') {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, kubeAppRoleSelector: true }))
    }

    if (PRINCIPAL_OBJECT_KINDS.includes(rsrcKind)) {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, principalModal: true }))
      return
    }
  }

  useRouteParams({
    key: 'cloudType',
    defaultValue: cloudTypesWP[0]?.toLowerCase() || 'aws',
    controlValue: activeCloudType.toLowerCase(),
    possibleValues: cloudTypesWP.map((e) => e.toLowerCase()),
    onControlValueChange(value) {
      setActiveCloudType(value.toUpperCase())
      history.push(`/${appView}/targets/${value}`)
    }
  })

  return (
    <div>
      <TargetsView
        activeCloudType={activeCloudType}
        setActiveCloudType={setActiveCloudType}
        forPolicy={false}
        onAccessClick={handleResourceAccessClick}
      />
      <CreateRequestFlow
        selectedRsrc={selectedRsrc}
        setSelectedRsrc={setSelectedRsrc}
        setViews={setViews}
        views={views}
      />
    </div>
  )
}

const WrappedUserTargets = () => (
  <AccessCartProvider cartKey={QUEUE_KEY}>
    <UserTargets />
  </AccessCartProvider>
)

export { WrappedUserTargets as UserTargets }
