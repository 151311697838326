import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { InfoTabsModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { useUserRsrcAccess } from 'features/users'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { DevicesTab } from './components/DevicesTab'
import { GroupsTab } from './components/GroupsTab'
import { ResourcesTab } from './components/ResourcesTab'
import { getUserInfo } from 'Utils/Helpers'
import { useUser } from 'Core/Hooks/useUser'

function UserDetailsModal({ user, onClose, tab }) {
  const [activeIndex, setActiveIndex] = useState(TabIndexMap[tab])

  const history = useHistory()

  const { getUserAccessIAMRsrcs, getUserAccessApps, getUserAccessTargets } = useUserRsrcAccess()

  const handleViewProfileClick = () => history.push(`/admin/users/${user.ObjectMeta.Name}`)

  const apps = getUserAccessApps(user)
  const targets = getUserAccessTargets(user)
  const iamRsrcs = getUserAccessIAMRsrcs(user)
  const {isAgentlessMode} = useUser()

  useHTMLBodyScrollbar(true)

  return (
    <>
      <InfoTabsModal
        activeIndex={activeIndex}
        caption={user.Spec.EmailID}
        onClose={onClose}
        onPrimaryButtonClick={handleViewProfileClick}
        primaryButtonLabel='View Full Profile'
        setActiveIndex={setActiveIndex}
        title={getResourceName(user)}
        tabs={[
          {
            label: 'Groups',
            tabContent: <GroupsTab user={user} />
          },
          {
            label: 'Devices',
            tabContent: <DevicesTab user={user} />
          },
          {
            label: 'Targets',
            tabContent: <ResourcesTab rsrcsRefs={targets} fallbackMessage='No targets to show.' />
          },
          {
            label: 'Resources',
            tabContent: (
              <ResourcesTab rsrcsRefs={iamRsrcs} fallbackMessage='No resources to show.' />
            )
          },
          !isAgentlessMode &&
          {
            label: 'Applications',
            tabContent: <ResourcesTab rsrcsRefs={apps} fallbackMessage='No applications to show.' />
          }
        ].filter(Boolean)}
      />
    </>
  )
}

const TabIndexMap = {
  GROUPS: 0,
  DEVICES: 1,
  TARGETS: 2,
  RSRCS: 3,
  APPS: 4
}

export { UserDetailsModal }
