import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getObjectFromRedux } from '../utils'
/**
 * Selects all the data from the slices and returns an object
 * @returns
 */
const select = (sliceNames, state) => {
  const selectObject = {}
  sliceNames.forEach((sliceName) => {
    const { data, map } = state[sliceName]
    selectObject[sliceName] = { data, map }
  })
  return selectObject
}
/**
 * 
 * @param {import('types').SliceNames[]} sliceNames 
 * @returns 
 */
export const useObjectRef = (sliceNames) => {
  const state = useSelector((s) => select(sliceNames, s))

  const getObjectRef = useCallback((refs) => getObjectFromRedux(refs), [state])

  return { getObjectRef }
}
