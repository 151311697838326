import { createRef } from "features/resources"

export const getUserRefsFromGroup = (g) => g.Spec.Users?.ObjectRef || []

export const getGroupSource = (g) => g.Status?.Source || 'Procyon'

/**
 * Remove `user` from `group` 
 * @param {*} user The user who needs to be removed from `group`
 * @param {*} group The group to remove from the `user`
 * @returns {[object, object]} New  group's and user's object with updated references
 */
export const removeUserFromGroup = (user, group) => {
  const gRef = createRef(group)
  const uRef = createRef(user)

  const gUserRefs = group.Spec.Users.ObjectRef || []
  const uGroupRefs = user.Spec.Groups.ObjectRef || []

  const newUser = structuredClone(user)
  const newGroup = structuredClone(group)
  // Remove the group from the user's groups references
  newUser.Spec.Groups.ObjectRef = uGroupRefs.filter((g) => g.RefID !== gRef.RefID)
  // Remove the user from the group's users refereces
  newGroup.Spec.Users.ObjectRef = gUserRefs.filter((u) => u.RefID !== uRef.RefID)

  return [newUser, newGroup]
}