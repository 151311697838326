import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal, PaginationView, SearchInput } from 'V2Components'
import {
  ErrorModal,
  deletePolicy,
  getPolicyDisplayName,
  getPolicyError,
  getPolicyIssuedToEntities,
  getPolicyResourcesRefs
} from 'features/policy'
import { createRsrcKey } from 'features/resources'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import _ from 'lodash'
import moment from 'moment'
import { PolicyCard, SimpleDropDown, ToggleViewControl, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AccessSummaryModal } from './Components/AccessSummaryModal'
import { ListTable } from './Components/ListTable'
import { tableDataAdapter } from './Components/ListTable/utils'
import { createDataSelectorHook } from 'infra/redux'
import { reduxApiClient } from 'infra'

const sortKeys = {
  CreatedDate: 'ObjectMeta.CreatedAt',
  IssuedDate: 'NotBefore'
}

const useSlices = createDataSelectorHook(['policyList'])

function PolicyList() {
  const [contentViewMode, setContentViewMode] = useState('grid') //grid ||table
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [sortBy, setSortedBy] = useState(sortKeys.CreatedDate)
  const [searchKey, setSearchKey] = useState('')
  const [showAccessSummaryModal, setShowAccessSummaryModal] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [showDeletePolicyModal, setShowDeletePolicyModal] = useState(false)
  const { slices } = useSlices()

  const history = useHistory()
  const { appView } = useAppView()
  const { selectDispatch } = useMultiSlice(['policyList'])

  useEffect(() => {
    initialCall()
  }, [])

  const initialCall = async () => {
    await reduxApiClient('pacpolicys').getAll({})
  }

  const getPolicyCardData = (policy) => {
    const createdOn = moment(policy.ObjectMeta.CreatedAt).format('MMMM Do YYYY')
    const errors = getPolicyError(policy)
    return {
      name: getPolicyDisplayName(policy),
      caption: (
        <>
          Created On {createdOn} by <strong className='ml-1'>{policy.Creator}</strong>
        </>
      ),
      endsIn: moment(policy.NotAfter).fromNow(),
      identitiesAmount: getPolicyIssuedToEntities(policy).length,
      resourcesAmount: getPolicyResourcesRefs(policy).length,
      errored: !!errors
    }
  }

  const filteredPolicys = (() => {
    const list = []
    slices.policyList.forEach((policy) => {
      const searchString = JSON.stringify([
        getPolicyDisplayName(policy),
        policy.ObjectMeta.Name
      ]).toLowerCase()
      if (searchKey && !searchString.includes(searchKey)) return
      list.push(policy)
    })
    return list
  })()
  const sortedPolicys = (() => {
    return filteredPolicys.sort((a, b) => {
      const aSortKey = moment(_.get(a, sortBy)).toDate().getTime()
      const bSortKey = moment(_.get(b, sortBy)).toDate().getTime()
      return aSortKey - bSortKey
    })
  })()
  const handleResourceListModalView = (policy) => {
    setSelectedPolicy(policy)
    setShowAccessSummaryModal(true)
  }

  const tableData = (() => {
    return tableDataAdapter({
      policys: sortedPolicys,
      handleResourcesClick: handleResourceListModalView
    })
  })()

  const handlePolicyDelete = async () => {
    try {
      await deletePolicy(selectedPolicy)
      deleteSliceData(selectedPolicy)
      enqueueNotification('Policy has been deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification('Unknown error encountered!')
    } finally {
      setShowDeletePolicyModal(false)
      setSelectedPolicy(null)
    }
  }

  useEffect(() => {
    selectDispatch([
      'policyList',
      'awsResources',
      'gcpResources',
      'userList',
      'groupList',
      'azureResources',
      'appRolesList',
      'serverList',
      'serviceAccounts',
      'kubeNamespaces',
      'applicationList',
      'githubResources',
      'kubeClusters',
      'rdpServers',
      'salesForceAccountList'
    ])
  }, [])

  return (
    <div>
      <div className='flex justify-between items-center'>
        <Typography variant='h2'>Policies</Typography>
        <div className='flex gap-8'>
          <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
          <ToggleViewControl
            ariaLabel='view control'
            onChange={(_, value) => setContentViewMode(value)}
            options={['grid', 'table']}
            value={[contentViewMode]}
          />
        </div>
      </div>
      <div className='mt-9'>
        <div className='flex justify-between items-center'>
          <Typography variant='body-regular'>{sortedPolicys.length} Total Policies</Typography>
          <SimpleDropDown
            menuItems={[
              {
                label: 'Sort: Created Date',
                selected: sortBy === sortKeys.CreatedDate,
                value: sortKeys.CreatedDate
              },
              {
                label: 'Sort: Issued Date',
                value: sortKeys.IssuedDate,
                selected: sortBy === sortKeys.IssuedDate
              }
            ]}
            onChange={(e) => {
              setSortedBy(e.target.value)
            }}
            value={sortBy}
          />
        </div>
        <div className='mt-4'>
          {contentViewMode === 'grid' && (
            <PaginationView
              itemsCount={12}
              data={sortedPolicys}
              WrapperComponent={({ children }) => (
                <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 mt-4 gap-8'>
                  {children}
                </div>
              )}
              renderFunction={(policy) => {
                const cardData = getPolicyCardData(policy)
                return (
                  <PolicyCard
                    hasAlert={cardData.errored}
                    onClickAlert={() => {
                      setSelectedPolicy(policy)
                      setShowErrorModal(true)
                    }}
                    //@ts-ignore
                    caption={cardData.caption}
                    isAdmin={appView === 'admin'}
                    onClickMoreInfo={() =>
                      history.push(
                        `/${appView}/policys/${encodeURIComponent(policy.ObjectMeta.Name)}`
                      )
                    }
                    key={createRsrcKey(policy)}
                    width='100%'
                    expirationTime={`Ends ${cardData.endsIn}`}
                    identitiesAmount={cardData.identitiesAmount}
                    menuItems={[
                      {
                        submenu: [
                          {
                            action: () => {
                              setSelectedPolicy(policy)
                              setShowDeletePolicyModal(true)
                            },
                            title: (
                              <Typography
                                className='!text-[#E02A47]'
                                variant='body-regular'
                                color='#E02A47'
                              >
                                Delete
                              </Typography>
                            )
                          }
                        ],
                        title: <FontAwesomeIcon icon={faEllipsisVertical} />
                      }
                    ]}
                    onClickIdentities={() => handleResourceListModalView(policy)}
                    onClickResources={() => handleResourceListModalView(policy)}
                    policyName={cardData.name}
                    resourcesAmount={cardData.resourcesAmount}
                  />
                )
              }}
            />
          )}
          {contentViewMode === 'table' && <ListTable data={tableData} />}
        </div>
      </div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='grayRed'
        alertMessage='Are you sure you want to delete this policy?'
        loadingMessage='Deleting Policy'
        onActionClick={handlePolicyDelete}
        onCancel={() => setShowDeletePolicyModal(false)}
        showModal={showDeletePolicyModal && selectedPolicy}
      />
      {showErrorModal && selectedPolicy && (
        <ErrorModal
          message={getPolicyError(selectedPolicy)}
          onClose={() => {
            setSelectedPolicy(null)
            setShowErrorModal(false)
          }}
          onViewDetailsClick={() =>
            history.push(
              `/${appView}/policys/${encodeURIComponent(selectedPolicy.ObjectMeta.Name)}`
            )
          }
          showModal
          title='Policy Error'
        />
      )}
      {selectedPolicy && (
        <AccessSummaryModal
          policy={selectedPolicy}
          onClose={() => setShowAccessSummaryModal(false)}
          showModal={showAccessSummaryModal}
        />
      )}
    </div>
  )
}

export { PolicyList }
