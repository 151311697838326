import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

/**
 *
 * @param {{
 * message?: string
 * buttons: {
 * 		label: string,
 * 		variant:import('procyon-ui').ButtonProps['variant'],
 * 		onClick:(() => void)
 * 	}[]
 * }} param0
 * @returns
 */
function FloatingActions({ message, buttons = [] }) {
  return (
    <div className='fixed bottom-4 right-5 p-2 px-4 rounded-md bg-white flex justify-between z-[1000] border-2 border-[#e5b20d]'>
      <div className='flex gap-3 items-center !text-[#545B71]'>
        <FontAwesomeIcon size='lg' icon={faUsers} />
        <Typography variant='body-semiBold' className=' mr-[130px]'>
          {message}
        </Typography>
      </div>
      <div className='flex gap-2'>
        {buttons.map((e) => (
          <Button variant={e.variant} onClick={e.onClick}>
            {e.label}
          </Button>
        ))}
      </div>
    </div>
  )
}

export { FloatingActions }
