import { Box, Grid, makeStyles } from '@material-ui/core'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import StyledBox from 'Components/StyledBox'
import React from 'react'
import AccountActivityGraph from 'Views/Accounts/AccountActivityGraph'
import ActivityTabs from './ActivityTabs'
const useStyle = makeStyles((theme) => ({
  left: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderRight: 'unset',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  right: {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    padding: 0
  }
}))
const Resources = () => {
  console.log('re-renders Dashboard Resources')
  const classes = useStyle()
  return (
    <Box mt={2}>
      <Box mb={1}>
        <CustomTypo variant='h4'>Resources</CustomTypo>
      </Box>
      <Grid spacing={0} container>
        <Grid xs={8} item>
          <AccountActivityGraph loadGraph className={classes.left} mt={0} title='Resource Activity' />
        </Grid>
        <Grid xs={4} item>
          <StyledBox globalClassName='applicationCard' className={classes.right} height='100%'>
            <ActivityTabs />
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(Resources)
