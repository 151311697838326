export const steps = () => [
  {
    title: 'Welcome to procyon.',
    intro: 'Hello World! 👋'
  },
  {
    element: document.querySelector('#intro-js-alerts'),
    intro: 'See all your <b>Alerts</b> at one place !'
  },
  {
    element: document.querySelector('#intro-js-tasks'),
    intro: 'See all your <b>Tasks</b> at one place !'
  },
  {
    element: document.querySelector('#intro-js-accounts'),
    intro: 'See all your <b>Accounts</b> stats !'
  }
]
