import { faUser } from '@fortawesome/pro-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getGroupSource, getUserRefsFromGroup } from 'features/groups'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import moment from 'moment'
import { Button, MenuButton } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export function useGroupList({}) {
  const [searchKey, setSearchKey] = useState('')
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selectedRowIDs, setSelectedRowIDs] = useState([])
  const [bulkSelectedGroups, setBulkSelectedGroups] = useState([])
  const history = useHistory()

  const { slices, getObjectRef, dispatchThunks } = useMultiSlice(['userList', 'groupList'])

  const handleRowCellClick = (name) => {
    history.push(`/admin/groups/${name}`)
  }
  // prettier-ignore
  const [views, setViews] = useState({
    create          : false,
    edit            : false,
    delete          : false,
    bulkDelete      : false,
    usersModal      : false,
    bulkAddUsers    : false,
  })

  /**
   * @param {Partial<typeof views>} vs views
   */
  const handleViewsChange = (vs) => {
    setViews((s) => ({ ...s, ...vs }))
  }

  const handleBulkDeleteClick = () => {
    const groupRefs = selectedRowIDs.map(reverseRsrcKey)
    // Remove admin groups from deleting
    const groups = getObjectRef(groupRefs).filter(
      (g) => g.ObjectMeta.Name !== 'admin' && g.ObjectMeta.Name !== 'everyone'
    )
    setBulkSelectedGroups(groups)
    handleViewsChange({ bulkDelete: true })
  }

  const handleAddUsersToGroup = (group) => {
    setBulkSelectedGroups([group])
    handleViewsChange({ bulkAddUsers: true })
  }

  const handleBulkAddToUser = () => {
    const groupRefs = selectedRowIDs.map(reverseRsrcKey)
    setBulkSelectedGroups(getObjectRef(groupRefs))
    handleViewsChange({ bulkAddUsers: true })
  }
  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction
  })

  const filteredGroups = applySearchQuery(slices.groupList)

  const getDotMenuTableRowItems = (group) => {
    const isGroupDeletable =
      group.ObjectMeta.Name !== 'admin' && group.ObjectMeta.Name !== 'everyone'
    const actions = [
      {
        title: 'Add Users',
        action: () => handleAddUsersToGroup(group)
      },
      {
        title: 'Edit',
        action: () => {
          setSelectedGroup(group)
          handleViewsChange({ edit: true })
        }
      }
    ]
    if (isGroupDeletable) {
      actions.push({
        // @ts-ignore
        title: <span className='text-red-500'>Delete</span>,
        action: () => {
          setSelectedGroup(group)
          handleViewsChange({ delete: true })
        }
      })
    }

    return actions
  }

  const rowDataAdapterFn = (group) => {
    const users = getObjectRef(getUserRefsFromGroup(group))
    const data = {
      key: createRsrcKey(group),
      name: getResourceName(group),
      description: group.Spec.Description,
      users: {
        count: users.length,
        onClick() {
          setSelectedGroup(group)
          handleViewsChange({ usersModal: true })
        }
      },
      source: getGroupSource(group),
      createdAt: moment(group.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A'),
      more: getDotMenuTableRowItems(group),
      goToDetailsPage: () => handleRowCellClick(group.ObjectMeta.Name)
    }
    return data
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return {
    bulkSelectedGroups,
    searchKey,
    setSearchKey,
    selectedRowIDs,
    setSelectedRowIDs,
    handleBulkAddToUser,
    handleBulkDeleteClick,
    setBulkSelectedGroups,
    selectedGroup,
    views,
    handleViewsChange,
    filteredGroups,
    rowDataAdapterFn
  }
}

// =========================================// TABLE META //======================================//

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const responsiveCloumns = () => [
  {
    dataIndex: 'name',
    title: 'Name',
    align: 'left',
    fixed: 'left',
    width: 250,
    sorter: (v1, v2) => v1.name.localeCompare(v2.name),
    onCell(data) {
      return {
        onClick: data.goToDetailsPage
      }
    }
  },
  {
    dataIndex: 'description',
    title: 'Description',
    width: 400,
    align: 'left',
    sorter: (v1, v2) => v1.description.localeCompare(v2.description)
  },
  {
    dataIndex: 'users',
    title: 'Users',
    width: 200,
    sorter: (v1, v2) => v1.users.count - v2.users.count,
    render(value) {
      return (
        <Button onClick={value.onClick} variant='grayBlue' icon={faUser}>
          {value.count}
        </Button>
      )
    }
  },
  {
    dataIndex: 'createdAt',
    title: 'Created On',
    align: 'left',
    width: 200
  },
  {
    dataIndex: 'source',
    title: 'Source',
    align: 'left',
    sorter: (v1, v2) => v1.source.localeCompare(v2.source),
    width: 200
  },
  {
    dataIndex: 'more',
    title: ' ',
    width: 50,
    fixed: 'right',
    render(value) {
      return <MenuButton menuItems={value} />
    }
  }
]

function defaultQueryFunction(group, queryKey) {
  const { Description } = group.Spec
  const Name = getResourceName(group)
  return `${Description}${Name}`.toLowerCase().includes(queryKey)
}
