import { LoadingModal } from 'procyon-ui'
import React from 'react'
import { createPortal } from 'react-dom'
import { cn } from 'Utils/Helpers'

function LoadingFeedback({ caption = '', message = '', loading, shrinked = false, shadow = true }) {
  if (!loading) return null
  return (
    <>
      {createPortal(
        <div
          className={cn({
            'fixed bg-[#00000033] z-[9999] flex justify-center items-center right-0 bottom-0 glass-morph !border-r-0': true,
            'w-[calc(100vw-72px)]': shrinked,
            'w-[100vw]': !shrinked,
            'h-[calc(100vh-67px)]': shrinked,
            'h-[100vh]': !shrinked
          })}
        >
          <div className={cn({ 'animate-fadeIn': loading })}>
            <LoadingModal shadow={shadow} caption={caption} message={message} />
          </div>
        </div>,
        document.getElementById('loading-feedback')
      )}
    </>
  )
}

export { LoadingFeedback }
