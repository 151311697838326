import React from 'react'
import { Route } from 'react-router'
import { SessionLogs } from '../views/SessionLogs'

const SessionRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/session-logs', '/admin/session-logs/:sessionKey']}>
        <SessionLogs />
      </Route>

      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/session-logs', '/user/session-logs/:sessionKey']}>
        <SessionLogs />
      </Route>
    </>
  )
}

export { SessionRoutes }
