import { ChannelRow } from 'procyon-ui'
import { useChannelBrowser } from '../../../providers/hooks'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

const SlackChannels = ({
  getCloudsAccountCount,
  handleManageAccount,
  handleUnlinkAllAccount,
  getTotalLinkedAccount,
  getIntegrationType
}) => {
  const { integration } = useChannelBrowser()
  return (
    <ChannelRow
      key={999} // Ensure each element has a unique key
      awsButtonNumber={getCloudsAccountCount(integration, 'AWS').length}
      azureButtonNumber={getCloudsAccountCount(integration, 'AZURE').length}
      channelName={'Webhook url channel'}
      dropdownMenuItems={[
        {
          submenu: [
            {
              action: () => handleManageAccount(integration),
              title: 'Manage'
            },
            {
              action: () => handleUnlinkAllAccount(integration),
              title: 'Unlink All'
            }
          ],
          title: <FontAwesomeIcon icon={faEllipsisVertical} />
        }
      ]}
      googleButtonNumber={getCloudsAccountCount(integration, 'GCP').length}
      onClickAwsButton={function noRefCheck() {}}
      onClickAzureButton={function noRefCheck() {}}
      onClickGoogleButton={function noRefCheck() {}}
      onClickRepositories={function noRefCheck() {}}
      onClickUserGroups={function noRefCheck() {}}
      showAwsButton
      showAzureButton
      showDropdownButton
      showGoogleButton
      totalAccounts={`Total ${getTotalLinkedAccount(integration).length}`}
      type={getIntegrationType()}
    />
  )
}

export { SlackChannels }
