import { Button, Typography } from 'procyon-ui'
import React from 'react'

function Header({
  title,
  caption,
  onClose,
  onPrimaryButtonClick,
  primaryButtonLabel = 'View Details'
}) {

  return (
    <div className='flex justify-between items-center'>
      <div>
        <Typography className='!font-semibold' variant='h4-regular'>
          {title.substring(0, 40)}
          {title.length > 40 && '...'}
        </Typography>
        <Typography variant='caption-regular' className='mt-2 break-all'>
          {caption.slice(0, 62)}{caption.length > 63 ? '...' : ''}
        </Typography>
      </div>
      <div className='flex gap-4'>
        <Button onClick={onPrimaryButtonClick} variant='grayBlue'>
          {primaryButtonLabel}
        </Button>
        <Button onClick={onClose} variant='gray'>
          Close
        </Button>
      </div>
    </div>
  )
}

export { Header }
