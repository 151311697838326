import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

export const RedirectSlackSuccess = () => {
  const { integrationName } = useParams()
  return <Redirect to={`/admin/integrations/${integrationName}/SlackIntegration`} />
}

export const RedirectJiraSuccess = () => {
  const { integrationName } = useParams()
  return <Redirect to={`/admin/integrations/${integrationName}/JiraIntegration`} />
}

export const RedirectServiceNowSuccess = () => {
  const { integrationName } = useParams()
  return <Redirect to={`/admin/integrations/${integrationName}/ServiceNowIntegration`} />
}

export const RedirectMSTeamSuccess = () => {
  const { integrationName } = useParams()
  return <Redirect to={`/admin/integrations/${integrationName}/MsTeamsIntegration`} />
}
