import { Box, Chip, makeStyles, useTheme } from '@material-ui/core'
import { Computer, DevicesOther, PhoneIphone, Phonelink, TabletMac, Watch } from '@material-ui/icons'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import TabsSegment from 'Components/TabsSegment'
import Segment from 'Components/TabsSegment/Segment'
import { API } from 'Core'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import useQuery from 'Core/Hooks/useQuery'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deviceListSlice } from 'infra/redux/reducers'
import { sshLogObjectAdapter } from 'Utils/PureHelperFuctions'
import Alerts from './components/Alerts'
import MostActive from './components/MostActive'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.primary.white,
    position: 'relative'
  },
  alertChip: {
    height: theme.spacing(1.25),
    width: theme.spacing(2),
    backgroundColor: 'rgba(255, 152, 0, 0.1)',
    marginLeft: theme.spacing(0.288)
  },
  fallbackText: {
    color: theme.palette.text.disabled,
    fontSize: theme.spacing(1.2)
  }
}))

const VARIANT_MAPS = ['info', 'success', 'warning', 'primary_light', 'light']

const DevicesSegment = () => {
  const classes = useStyles()
  const [activeDevices, setActiveDevices] = useState(0)
  const [loading, setLoading] = useState({
    os: true,
    type: true,
    maker: true
  })
  const theme = useTheme()
  const { query } = useQuery({})
  const { data: devices, initiallyLoaded } = useMemoSelector('deviceList')
  const dispatch = useDispatch()
  const COLORS_MAP = useMemo(() => {
    return [
      theme.palette.info.light,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.grey[200]
    ]
  }, [theme])

  /**
   *
   * @param {((item: object) => string) | string} extractKey The extractor function or string which will resolve the key
   * @param {object[]} items The items to group
   * @returns object
   */
  const groupItemsWithKey = (extractKey, items = []) => {
    const sourceObject = {}
    items.forEach((item) => {
      let key = ''
      if (typeof extractKey === 'function') {
        key = extractKey(item)
      } else if (typeof extractKey === 'string') {
        key = item[extractKey]
      }
      if (!key) return
      if (!sourceObject[key]) sourceObject[key] = [item]
      else sourceObject[key] = [...sourceObject[key], item]
    })
    return sourceObject
  }

  /**
   *
   * @param {{others: object[], count: number, topFive: object[]}} segmentedData
   * @param {object[]} dataBeforeOthers
   * @param {((group: object) => string)} titleExtract
   */
  const createSegmentData = (segmentedData, titleExtract, dataBeforeOthers = [], ignoreOthers = false) => {
    let data = []
    const { topFive, count, others } = segmentedData
    topFive.forEach((group, index) => {
      let key = ''
      if (typeof titleExtract === 'function') {
        key = titleExtract(group[0])
      }
      data.push({
        title: key,
        text: `${Math.round(100 / ((count - others.length) / group.length))}%`,
        variant: VARIANT_MAPS[index],
        count
      })
    })
    if (dataBeforeOthers.length) {
      data = [...data, ...dataBeforeOthers]
    }
    if (others.length && !ignoreOthers) {
      data.push({
        title: 'Others',
        text: `${Math.round(100 / (count / others.length))}%`,
        variant: VARIANT_MAPS[4],
        count
      })
    } else if (!ignoreOthers) {
      data.push({
        title: 'Others',
        text: '0%',
        variant: VARIANT_MAPS[4],
        count
      })
    }
    return data
  }

  /**
   *
   * @param {object[]} data Array of segment data
   * @returns {Array.<{ value: number, color: string, title: string }>}
   */
  const createSegmentChartData = (data = []) => {
    return data.map((item, index) => {
      return {
        value: +item.text.split('%')[0],
        color: COLORS_MAP[index],
        title: item.title || 'N/A'
      }
    })
  }

  /**
   *
   * @param {object[]} groupedData
   * @returns {{ count: number, topFive: object[], others: object[] }}
   */
  const groupDataIntoSegment = (groupedData = [], mergeData = false) => {
    let count = 0
    const topFive = []
    const others = []
    groupedData.sort((a, b) => {
      return b.length - a.length
    })
    groupedData.forEach((item, index) => {
      count += item.length
      if (index <= 3 || mergeData) topFive[index] = item
      else others.push(item)
    })
    count += others.length
    return {
      count,
      topFive,
      others
    }
  }

  const getDeviceType = (device = {}) => {
    const MAP = {
      Mobile: ['android', 'ios'],
      Desktop: ['windows', 'mac', 'darwin'],
      Tablet: [],
      SmartWatch: []
    }
    let type = ''
    for (const deviceType of Object.keys(MAP)) {
      if (type) break
      MAP[deviceType].every((osName) => {
        if (device?.Attributes?.OperatingSystem.toLowerCase().includes(osName)) {
          type = deviceType
          return false
        }
        return true
      })
    }
    return type
  }

  /**
   *
   * @param {object[]} devices
   * @param {((group: object) => string)} extractKey
   */
  const createSegment = (devices, extractKey) => {
    const groupedData = Object.values(groupItemsWithKey(extractKey, devices))
    const segmentedData = groupDataIntoSegment(groupedData)
    const segmentedDataMerged = groupDataIntoSegment(groupedData, true)
    const data = createSegmentData(segmentedData, extractKey, [])
    const tableData = createSegmentData(segmentedDataMerged, extractKey, [], true)
    const chartData = createSegmentChartData(data)
    return [data, tableData, chartData]
  }

  const [osData, osTableData, osChartData] = React.useMemo(() => {
    const segment = createSegment(devices, (device) => device.Attributes?.OperatingSystem)
    devices.length && setLoading((state) => ({ ...state, os: false }))
    return segment
  }, [devices])

  const [makerData, makerTableData, makerChartData] = useMemo(() => {
    const segment = createSegment(devices, (device) => device?.Attributes?.Maker)
    devices.length && setLoading((state) => ({ ...state, maker: false }))
    return segment
  }, [devices])

  const [typeData, typeTableData, typeChartData] = useMemo(() => {
    const groupedData = Object.values(groupItemsWithKey(getDeviceType, devices))
    const segmentedData = groupDataIntoSegment(groupedData)
    const segmentedDataMerged = groupDataIntoSegment(groupedData, true)
    const tableData = createSegmentData(segmentedDataMerged, getDeviceType, [], true)
    const DEFAULT_VALUES = [
      {
        title: 'Mobile',
        text: '0%',
        variant: VARIANT_MAPS[0],
        count: segmentedData.count
      },
      {
        title: 'Desktop',
        text: '0%',
        variant: VARIANT_MAPS[1]
      },
      {
        title: 'Smartwatch',
        text: '0%',
        variant: VARIANT_MAPS[2]
      },
      {
        title: 'Tablet',
        text: '0%',
        variant: VARIANT_MAPS[3]
      },
      {
        title: 'Others',
        text: '0%',
        variant: VARIANT_MAPS[4]
      }
    ]
    const TYPE_ICONS_MAP = {
      Mobile: PhoneIphone,
      Desktop: Computer,
      Tablet: TabletMac,
      Smartwatch: Watch,
      Others: DevicesOther
    }
    const data = createSegmentData(segmentedData, (group) => getDeviceType(group))
    const _append = []
    let coercedData = []
    DEFAULT_VALUES.forEach((item, index) => {
      const existing = data.find((elem) => elem.title === item.title)
      if (!existing) _append.push({ ...item, Icon: TYPE_ICONS_MAP[item.title], variant: VARIANT_MAPS[index] })
      else coercedData.push({ ...existing, Icon: TYPE_ICONS_MAP[item.title], variant: VARIANT_MAPS[index] })
    })
    coercedData = [...coercedData, ..._append]
    const chartData = createSegmentChartData(data)
    devices.length && setLoading((state) => ({ ...state, type: false }))
    return [coercedData, tableData, chartData]
  }, [devices])

  const Fallback = useCallback(() => {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' width='100%' height='100%'>
        <CustomTypo className={classes.fallbackText} variant='h6'>NO DEVICES AVAILABLE</CustomTypo>
      </Box>
    )
  }, [])

  useEffect(() => {
    if (initiallyLoaded) {
      setLoading({
        os: false,
        maker: false,
        type: false
      })
    }
  }, [initiallyLoaded])

  const tabList = [
    {
      Tab: (
        <Segment
          loading={loading.type}
          tableTitleHeader='Type'
          modalOptions={{
            modalTitle: 'Most Active Devices by Type',
            searchPlaceholder: 'Search Type',
            expandButtonLabel: 'Expand Device Type'
          }}
          chartData={typeChartData}
          Icon={DevicesOther}
          data={typeData}
          modalTableData={typeTableData}
          showFallback={initiallyLoaded && devices.length === 0}
          fallback={Fallback()}
        />
      ),
      name: 'Type',
      ID: '1001'
    },
    {
      Tab: (
        <Segment
          loading={loading.maker}
          tableTitleHeader='Maker'
          modalOptions={{
            modalTitle: 'Most Active Devices by Maker',
            searchPlaceholder: 'Search Maker',
            expandButtonLabel: 'Expand Device Maker'
          }}
          chartData={makerChartData}
          Icon={Phonelink}
          data={makerData}
          modalTableData={makerTableData}
          showFallback={initiallyLoaded && devices.length === 0}
          fallback={Fallback()}
        />
      ),
      name: 'Maker',
      ID: '1002'
    },
    {
      Tab: (
        <Segment
          loading={loading.os}
          tableTitleHeader='OS'
          modalOptions={{
            modalTitle: 'Most Active Devices by OS',
            searchPlaceholder: 'Search OS',
            expandButtonLabel: 'Expand Device OS'
          }}
          chartData={osChartData}
          modalTableData={osTableData}
          Icon={Phonelink}
          data={osData}
          showFallback={initiallyLoaded && devices.length === 0}
          fallback={Fallback()}
        />
      ),
      name: 'OS',
      ID: '1003'
    },
    {
      Tab: <MostActive />,
      name: 'Most Active',
      ID: '1004'
    },
    {
      Tab: <Alerts />,
      name: (
        <Box px={1}>
          ALERTS
          <Chip className={classes.alertChip} label='1' />
        </Box>
      ),
      ID: '1005'
    }
  ]

  useEffect(() => {
    dispatch(deviceListSlice.thunk())
  }, [])

  useEffect(() => {
    Promise.all([API.getEventLogs(query.roleQuery), API.getProxyAuditLogs(query.sshQuery)])
      .then((values) => {
        const devicesMap = {}
        const rolesHits = values[0].data.hits.hits
        const serverHits = values[1].data.hits.hits
        serverHits.forEach((hit) => {
          const data = sshLogObjectAdapter(hit, [], devices, [], [], ['account', 'resource', 'user'])
          if (data.device) {
            if (!devicesMap[data.device?.Attributes?.DeviceName]) {
              devicesMap[data.device?.Attributes?.DeviceName] = {}
            }
          }
        })
        setActiveDevices(Object.values(devicesMap).length)
        console.log('[roles-hit]():', rolesHits)
      })
      .catch(console.log)
  }, [query, devices])

  return (
    <Box className={classes.root}>
      <TabsSegment
        compact
        tabMinWidth='3.625rem'
        title='Devices'
        current={activeDevices}
        total={devices.length}
        TabList={tabList}
      />
    </Box>
  )
}

export default DevicesSegment
