import { SummaryHeader, getResourceName } from 'features/resources'
import { Button, IconButton } from 'procyon-ui'
import React, { useState } from 'react'
import { WorkloadAccessTokenModal } from '../WorkloadAccessTokensModal'
import { DeleteWorkloadModal, isWorkloadErrored } from 'features/workloads'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import useAppView from 'Core/Hooks/useAppView'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { useHistory } from 'react-router'
import { WorkloadErrorModal } from '../WorkloadErrorModal'

const Header = ({ workload }) => {
  const [showTokensModal, setShowTokensModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const error = isWorkloadErrored(workload)
  const history = useHistory()
  const [showDeleteWorkload, setShowDeleteWorkload] = useState(false)
  const { appView } = useAppView()

  const canGoBackFn = useCanGoBack(`/${appView}/workloads`)

  return (
    <div>
      <SummaryHeader
        actions={
          <div className='flex gap-4'>
            {error && (
              <IconButton
                icon={faTriangleExclamation}
                onClick={() => setShowErrorModal(true)}
                variant='grayRed'
              />
            )}
            <Button
              onClick={() => history.push(`/admin/workloads/${workload.ObjectMeta.Name}/edit`)}
              size='md'
              variant='grayBlue'
            >
              Edit
            </Button>
            <Button onClick={() => setShowTokensModal(true)} size='md' variant='grayBlue'>
              View Access Tokens
            </Button>
            <Button onClick={() => setShowDeleteWorkload(true)} size='md' variant='grayRed'>
              Delete
            </Button>
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Workloads',
            link: '/admin/workloads'
          },
          {
            label: getResourceName(workload)
          }
        ]}
        rsrcName={getResourceName(workload)}
      />
      {showTokensModal && (
        <WorkloadAccessTokenModal workload={workload} onClose={() => setShowTokensModal(false)} />
      )}
      {showDeleteWorkload && (
        <DeleteWorkloadModal
          workload={workload}
          onCancel={() => setShowDeleteWorkload(false)}
          onSuccess={() => {
            typeof canGoBackFn === 'function' && canGoBackFn()
          }}
        />
      )}
      {showErrorModal && (
        <WorkloadErrorModal workload={workload} onClose={() => setShowErrorModal(false)} />
      )}
    </div>
  )
}

export { Header }
