import { API } from 'Core'
import { populateActionObjects } from 'Utils/ActionEnpointsHelper'
import { populateAuthZ } from 'Utils/ActionZActionCheckHelper'
import { sliceGenerator } from './sliceGenerator'

export const userListSlice = sliceGenerator({
  sliceName: 'userList',
  endpoint: 'users',
  initialState: []
})
export const proxiesSlice = sliceGenerator({
  sliceName: 'proxyList',
  endpoint: 'proxies',
  initialState: []
})
export const groupListSlice = sliceGenerator({
  sliceName: 'groupList',
  endpoint: 'groups',
  initialState: []
})
export const serviceAccountsSlice = sliceGenerator({
  sliceName: 'serviceAccounts',
  endpoint: 'serviceaccounts',
  initialState: []
})
export const projectsSlice = sliceGenerator({
  sliceName: 'projects',
  endpoint: 'projects',
  initialState: []
})
export const idpListSlice = sliceGenerator({
  sliceName: 'idpList',
  endpoint: 'idproviders',
  initialState: []
})
export const policySlice = sliceGenerator({
  sliceName: 'policyList',
  endpoint: 'pacpolicys',
  initialState: []
})
export const paaPolicySlice = sliceGenerator({
  sliceName: 'paaPolicyList',
  endpoint: 'paapolicys',
  initialState: []
})
export const deviceListSlice = sliceGenerator({
  sliceName: 'deviceList',
  endpoint: 'devices',
  initialState: []
})
export const serverListSlice = sliceGenerator({
  sliceName: 'serverList',
  endpoint: 'servers',
  initialState: []
})
export const vpcListSlice = sliceGenerator({
  sliceName: 'vpcList',
  endpoint: 'vpcs',
  initialState: []
})
export const accountListSlice = sliceGenerator({
  sliceName: 'accountList',
  endpoint: 'accounts',
  initialState: []
})
export const iamActionsSlice = sliceGenerator({
  sliceName: 'iamActions',
  endpoint: 'iamactions',
  initialState: []
})
export const gcpResources = sliceGenerator({
  sliceName: 'gcpResources',
  endpoint: 'gcpresources',
  initialState: []
})
export const azureResourcesSlice = sliceGenerator({
  sliceName: 'azureResources',
  endpoint: 'azureresources',
  initialState: []
})
export const appRolesListSlice = sliceGenerator({
  sliceName: 'appRolesList',
  endpoint: 'approles',
  initialState: []
})
export const medusaNodesSlice = sliceGenerator({
  sliceName: 'medusaNodes',
  endpoint: 'medusanodes',
  initialState: []
})
export const jiraProject = sliceGenerator({
  sliceName: 'jiraProjectList',
  endpoint: 'jiraprojects',
  initialState: []
})
export const recordingListSlice = sliceGenerator({
  sliceName: 'recordingList',
  endpoint: 'recordings',
  initialState: []
})
export const awsResourcesSlice = sliceGenerator({
  sliceName: 'awsResources',
  endpoint: 'awsresources',
  initialState: []
})
export const slackChannel = sliceGenerator({
  sliceName: 'slackChannelList',
  endpoint: 'slackchannels',
  initialState: []
})
export const integrationSlice = sliceGenerator({
  sliceName: 'integrationsList',
  endpoint: 'integrations',
  initialState: []
})
export const approvalReqListSlice = sliceGenerator({
  sliceName: 'approvalReqList',
  endpoint: 'approvalreqs',
  initialState: []
})
export const applicationListSlice = sliceGenerator({
  sliceName: 'applicationList',
  endpoint: 'applications',
  initialState: []
})
export const googleIdpListSlice = sliceGenerator({
  sliceName: 'googleIdpList',
  endpoint: 'googleidproviders',
  initialState: []
})
export const userAccountsListSlice = sliceGenerator({
  sliceName: 'userAccountsList',
  endpoint: 'useraccounts',
  initialState: []
})
export const jiraIntegrations = sliceGenerator({
  sliceName: 'jiraIntegrationsList',
  endpoint: 'jiraintegrations',
  initialState: []
})
export const slackIntegrations = sliceGenerator({
  sliceName: 'slackIntegrationList',
  endpoint: 'slackintegrations',
  initialState: []
})
export const credentialsListSlice = sliceGenerator({
  sliceName: 'credentialsList',
  endpoint: 'credentials',
  initialState: []
})
export const serverGroupListSlice = sliceGenerator({
  sliceName: 'serverGroupList',
  endpoint: 'servergroups',
  initialState: []
})

export const credentialTypeSlice = sliceGenerator({
  sliceName: 'credentialTypesList',
  endpoint: null,
  initialState: [],
  apiFn: API.getCredentialTypesList
})
export const credentialInstanceSlice = sliceGenerator({
  sliceName: 'credentialInstanceList',
  endpoint: null,
  initialState: [],
  apiFn: API.getCredentialInstanceList
})
export const resourceActionsSlice = sliceGenerator({
  sliceName: 'resourceActions',
  endpoint: null,
  initialState: {},
  apiFn: populateActionObjects
})
export const resourceKindGrantsSlice = sliceGenerator({
  sliceName: 'resourceKindGrants',
  endpoint: null,
  initialState: {},
  apiFn: populateAuthZ
})
export const userpreferencesSlice = sliceGenerator({
  sliceName: 'userPreferences',
  endpoint: null,
  initialState: [],
  apiFn: API.fetchUserPreference
})
export const tenantprofilesSlice = sliceGenerator({
  sliceName: 'tenantprofiles',
  endpoint: 'tenantprofiles',
  initialState: {},
  apiFn: API.getTenantprofiles
})

export const databaseSlice = sliceGenerator({
  sliceName: 'databases',
  endpoint: 'databases',
  initialState: []
})
export const kubeNamespacesSlice = sliceGenerator({
  sliceName: 'kubeNamespaces',
  endpoint: 'kubenamespaces',
  initialState: []
})
export const githubresourcesSlice = sliceGenerator({
  sliceName: 'githubResources',
  endpoint: 'githubresources',
  initialState: []
})

export const githubAccountSlice = sliceGenerator({
  sliceName: 'githubAccount',
  endpoint: 'github-account',
  initialState: []
})

export const orgsSlice = sliceGenerator({
  sliceName: 'orgs',
  endpoint: 'orgs',
  initialState: []
})

export const tagPolicySlice = sliceGenerator({
  sliceName: 'tagPolicys',
  endpoint: 'tagpolicys',
  initialState: []
})

export const notificationSubscriptionSlice = sliceGenerator({
  sliceName: 'notificationSubscriptions',
  endpoint: 'notificationsubscriptions',
  initialState: []
})

export const workloadsSlice = sliceGenerator({
  sliceName: 'workloads',
  endpoint: 'workloadidentitys',
  initialState: []
})

export const approversSlice = sliceGenerator({
  sliceName: 'approvers',
  endpoint: 'approvers',
  initialState: []
})

export const rdpServersSlice = sliceGenerator({
  sliceName: 'rdpServers',
  endpoint: 'rdpservers',
  initialState: []
})

export const kafkas = sliceGenerator({
  sliceName: 'kafkas',
  endpoint: 'kafkas',
  initialState: []
})

export const servicenowintegrations = sliceGenerator({
  sliceName: 'servicenowintegrations',
  endpoint: 'servicenowintegrations',
  initialState: []
})

export const servicenowtables = sliceGenerator({
  sliceName: 'servicenowtables',
  endpoint: 'servicenowtables',
  initialState: []
})

export const kubeClusters = sliceGenerator({
  sliceName: 'kubeClusters',
  endpoint: 'kubeclusters',
  initialState: []
})

export const adDomainControllers = sliceGenerator({
  sliceName: 'adDomainControllers',
  endpoint: 'addomaincontrollers',
  initialState: []
})

export const adPasswords = sliceGenerator({
  sliceName: 'adPasswords',
  endpoint: 'adpasswords',
  initialState: []
})

export const bundles = sliceGenerator({
  sliceName: 'bundles',
  endpoint: 'reqbundles',
  initialState: []
})

export const visibilitys = sliceGenerator({
  sliceName: 'visibilitys',
  endpoint: 'visibilitys',
  initialState: []
})

export const rdppasswords = sliceGenerator({
  sliceName: 'rdppasswords',
  endpoint: 'rdppasswords',
  initialState: []
})

export const salesForceUsersList = sliceGenerator({
  sliceName: 'salesForceUsersList',
  endpoint: 'salesforce-users',
  initialState: []
})

export const salesForcePermissionList = sliceGenerator({
  sliceName: 'salesForcePermissionList',
  endpoint: 'crm-entities',
  initialState: []
})

export const salesForceAccountList = sliceGenerator({
  sliceName: 'salesForceAccountList',
  endpoint: 'salesforce-account',
  initialState: []
})

export const salesForceUserPermissionBinding = sliceGenerator({
  sliceName: 'salesForceUserPermissionBinding',
  endpoint: 'salesforce-user-perm-binding',
  initialState: []
})

export const snowFlakeResources = sliceGenerator({
  sliceName: 'snowFlakeResources',
  endpoint: 'snowflakeresources',
  initialState: []
})

export const snowFlakeRoles = sliceGenerator({
  sliceName: 'snowFlakeRoles',
  endpoint: 'snowflakeroles',
  initialState: []
})

export const snowFlakeAccounts = sliceGenerator({
  sliceName: 'snowFlakeAccounts',
  endpoint: 'snowflakeaccounts',
  initialState: []
})

export const snowFlakeUsers = sliceGenerator({
  sliceName: 'snowFlakeUsers',
  endpoint: 'snowflakeusers',
  initialState: []
})

export const msTeamsIntegrations = sliceGenerator({
  sliceName: 'msTeamsIntegrations',
  endpoint: 'msteamsintegrations',
  initialState: []
})

export const msTeamsChannels = sliceGenerator({
  sliceName: 'msTeamsChannels',
  endpoint: 'msteamschannels',
  initialState: []
})

export const msTeams = sliceGenerator({
  sliceName: 'msTeams',
  endpoint: 'msteams',
  initialState: []
})

export const dataBricksAccounts = sliceGenerator({
  sliceName: 'dataBricksAccounts',
  endpoint: 'databricksaccounts',
  initialState: []
})

export const dataBricksWorkSpaces = sliceGenerator({
  sliceName: 'dataBricksWorkSpaces',
  endpoint: 'databricksworkspaces',
  initialState: []
})

export const dataBricksIdentitys = sliceGenerator({
  sliceName: 'dataBricksIdentitys',
  endpoint: 'databricksidentitys',
  initialState: []
})

export const dataBricksResources = sliceGenerator({
  sliceName: 'dataBricksResources',
  endpoint: 'databricksresources',
  initialState: []
})

export const iamUsers = sliceGenerator({
  sliceName: 'iamUsers',
  endpoint: 'iamusers',
  initialState: []
})

export const analyzeIdentity = sliceGenerator({
  sliceName: 'analyzeIdentity',
  endpoint: 'analyze-identity',
  initialState: []
})

export const iamGroups = sliceGenerator({
  sliceName: 'iamGroups',
  endpoint: 'iamgroups',
  initialState: []
})

export const iamRoles = sliceGenerator({
  sliceName: 'iamRoles',
  endpoint: 'iamroles',
  initialState: []
})

export const iamFederatedUsers = sliceGenerator({
  sliceName: 'iamFederatedUsers',
  endpoint: 'iamfederatedusers',
  initialState: []
})

export const serverControllers = sliceGenerator({
  sliceName: 'serverControllers',
  endpoint: 'servercontrollers',
  initialState: []
})

export const proxyControllers = sliceGenerator({
  sliceName: 'proxyControllers',
  endpoint: 'proxycontrollers',
  initialState: []
})