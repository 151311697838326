import React from 'react'
const { createContext, useContext } = require('react')

const ApplicationEditContext = createContext({
  isEditMode: false,
  app: undefined
})

const ApplicationEditProvider = ({ children, app }) => {
  return (
    <ApplicationEditContext.Provider value={{ isEditMode: !!app, app }}>{children}</ApplicationEditContext.Provider>
  )
}

export const useApplicationEditContext = () => useContext(ApplicationEditContext)

export { ApplicationEditProvider }
