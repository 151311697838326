import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import {
  ReRequestApprovalRequestModal,
  isApprovalRequestCancelled,
  isApprovalRequestCreatedByUser,
  isApprovalRequestEditable
} from 'features/approvals'
import React, { useCallback, useEffect, useState } from 'react'
import { ApprovalRequestManager } from '../../../../../components/ApprovalRequestManager'
import { ResourcesListModal } from '../../ResourcesListModal'
import { Table } from '../../Table'
import { useTabs } from '../Tabs.utils'
import { ViewColumnsMap, historyTableColumns } from '../table.utils'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'

const useSlices = createDataSelectorHook(['approvalReqList'], { disableReduxFetch: true })

function HistoryTab({ searchKey }) {
  const [selectedApprovalRequest, setSelectedApprovalRequest] = useState(null)
  const [showRsrcsListModal, setShowRsrcsListModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReRequestModal, setShowReRequestModal] = useState(false)

  const { appView } = useAppView()
  const { user } = useUser()
  const { getCommonTableRowData } = useTabs()
  const { slices } = useSlices()

  const expiredOrRejectedReqs = slices.approvalReqList.filter((e) => {
    return ['Expired', 'Cancelled', 'Closed', 'Rejected'].includes(e.Status)
  })

  const getColumns = () => {
    const visibleColumns = ViewColumnsMap[appView].history
    //@ts-ignore
    return historyTableColumns().filter((e) => visibleColumns.includes(e.dataIndex))
  }

  const getStatusLabel = (request) => {
    if (!request) return
    const labelMap = {
      Cancelled: 'Cancelled',
      Rejected: 'Rejected',
      Closed: 'Closed'
    }
    return labelMap[request.Status] || 'Expired'
  }

  const getUserViewRowOptions = (request) => {
    const moreOptions = []
    // If the access request was not created by current user, show admin write actions
    if (!isApprovalRequestCreatedByUser(user, request)) return []

    if (isApprovalRequestCancelled(request)) {
      moreOptions.push({
        title: 'Re-Request',
        twColor: '!text-blue-500',
        action() {
          setSelectedApprovalRequest(request)
          setShowReRequestModal(true)
        }
      })
    }

    if (isApprovalRequestEditable(request)) {
      moreOptions.push({
        title: 'Edit',
        twColor: '!text-blue-500',
        action() {
          setSelectedApprovalRequest(request)
        }
      })
    }

    return moreOptions
  }

  const rowDataAdapterFn = useCallback((dataItem) => {
    const data = getCommonTableRowData(dataItem)

    data.resources.onClick = () => {
      setSelectedApprovalRequest(dataItem)
      setShowRsrcsListModal(true)
    }

    const searchData = {
      ...data,
      approvedOn: dataItem.ObjectMeta.UpdatedAt,
      status: getStatusLabel(dataItem)
    }

    return {
      ...searchData,
      moreOptions: appView === 'admin' ? [] : getUserViewRowOptions(dataItem)
    }
  }, [])

  useEffect(() => {
    reduxApiClient('approvalreqs').getByDataParams({ Status: 'Expired' })
    reduxApiClient('approvalreqs').getByDataParams({ Status: 'Cancelled' })
    reduxApiClient('approvalreqs').getByDataParams({ Status: 'Closed' })
    reduxApiClient('approvalreqs').getByDataParams({ Status: 'Rejected' })
  }, [])

  return (
    <div>
      <Table
        data={expiredOrRejectedReqs}
        getColumns={getColumns}
        rowDataAdapterFn={rowDataAdapterFn}
        searchKey={searchKey}
      />
      <ApprovalRequestManager
        approvalRequest={selectedApprovalRequest}
        onModalsViewChange={({ showDelete }) => setShowDeleteModal(showDelete)}
        views={{
          showClose: false,
          showDeleteAutoApprove: false,
          showDelete: showDeleteModal,
          showApprove: false,
          showReject: false
        }}
      />
      {selectedApprovalRequest && (
        <ResourcesListModal
          approvalReq={selectedApprovalRequest}
          onClose={() => setShowRsrcsListModal(false)}
          showModal={showRsrcsListModal}
        />
      )}
      {selectedApprovalRequest && showReRequestModal && (
        <ReRequestApprovalRequestModal
          approvalReq={selectedApprovalRequest}
          onCancel={() => setShowReRequestModal(false)}
          onSuccess={() => setShowReRequestModal(false)}
        />
      )}
    </div>
  )
}

export { HistoryTab }
