import { createRsrcKey } from 'features/resources'
import React from 'react'
import { AccountActionInput } from '../AccountActionInput'

export const AZUREAccountSelector = ({
  accounts,
  selectedAccounts,
  accountScopeURLMap,
  setSelectedAccounts,
  setAccountScopeURLMap,
  showOnlySelected = false,
  readonlyAccounts = []
}) => {
  const handleAddAccount = (accountKey) => {
    setSelectedAccounts((s) => [accountKey, ...s])
  }

  const handleRemoveAccount = (accountKey) => {
    setSelectedAccounts((s) => s.filter((e) => e !== accountKey))
  }

  const handleScopeURLChange = (accountKey, url) => {
    setAccountScopeURLMap((s) => ({ ...s, [accountKey]: url }))
  }

  const list = showOnlySelected
    ? accounts.filter((e) => selectedAccounts.includes(createRsrcKey(e)))
    : accounts

  return (
    <>
      {list.map((account) => {
        return (
          <AccountActionInput
            disabledInput={readonlyAccounts.includes(createRsrcKey(account))}
            isSelected={selectedAccounts.includes(createRsrcKey(account))}
            key={createRsrcKey(account)}
            account={account}
            placeholder='https://graph.microsoft.com'
            autoCompleteOptions={['https://graph.microsoft.com', 'https://management.azure.com']}
            onAddButtonClick={handleAddAccount}
            onRemoveButtonClick={handleRemoveAccount}
            onInputChange={handleScopeURLChange}
            value={accountScopeURLMap[createRsrcKey(account)]}
          />
        )
      })}
    </>
  )
}
