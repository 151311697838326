import { getPolicyResourcesRefs, isPolicyIssuedToUser } from 'features/policy'
import { createRef, createRsrcKey } from 'features/resources'
import { useTagsRsrcAccess } from 'features/tags'
import { isAdminUser } from 'features/users'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { isReadOnlyApp } from '../utils'

const useSlices = createDataSelectorHook([
  'policyList',
  'applicationList',
  'groupList',
  'githubResources',
  'salesForceUsersList',
  'salesForcePermissionList',
  'salesForceAccountList',
  'snowFlakeAccounts',
  'snowFlakeRoles',
  'snowFlakeUsers'
])

function useApplicationAccess() {
  const { slices } = useSlices()
  const { getRsrcTagPolicies } = useTagsRsrcAccess()

  /**
   * Function returns an object whose key is the application and value is an array of policy refs
   * {
   *    'Application+1234': [{ RefKind: 'PacPolicy', RefID: '122222' }]
   * }
   * @returns {Object<any, import('types').ObjectRef[]>}
   */
  const getApplicationRsrcsAccessMap = () => {
    const mapObj = {}
    slices.policyList.forEach((p) => {
      /**
       * We don't check if the policy is issued to logged in user because,
       * Admins will always have access to the applications and,
       * non-admin users will never get policies which are not issued to them!
       * Hence, the issued to check doesn;t do anything for us.
       */
      const rsrcRefs = getPolicyResourcesRefs(p)
      rsrcRefs.forEach((ref) => {
        const appKey = createRsrcKey(ref)
        // If the policy is for application, then push the policy ref
        if (
          ref.RefKind === 'Application' ||
          ref.RefKind === 'CRMEntity' ||
          ref.RefKind === 'SnowflakeRole'
        ) {
          const policiesRef = mapObj[appKey] || []
          mapObj[appKey] = [createRef(p), ...policiesRef]
        }
      })
    })
    return mapObj
  }

  /**
   * Get all the policys's attached to this application
   * @param {*} app The app whose policy you want to retrieve
   * @returns {import('types').ObjectRef[]}
   */
  const getApplicationPolicysRef = (app) => {
    const key = createRsrcKey(app)
    const tagBasedPolicyRefs = getRsrcTagPolicies(app).map(createRef)
    const accessObject = getApplicationRsrcsAccessMap()[key] || []
    const combined = [...accessObject, ...tagBasedPolicyRefs]

    return combined
  }

  const getAllAppsMapObj = () => {
    const mapObj = {}
    slices.applicationList.forEach((e) => {
      if (isReadOnlyApp(e)) return
      mapObj[createRsrcKey(e)] = []
    })
    return mapObj
  }

  /**
   * Function returns an  object whose key is the application and value is an array of policy refs.
   *
   * Only policys which are issued to `user` are included
   *
   * ```
   *    'Application+1234': [{ RefKind: 'PacPolicy', RefID: '122222' }]
   * ```
   * @returns {Object<any, import('types').ObjectRef[]>}
   */
  const getUserApplicationAccessMap = (user) => {
    const mapObj = {}
    if (!user) return {}

    const isAdmin = (() => {
      const adminGroupRef = createRef(_.find(slices.groupList, { ObjectMeta: { Name: 'admin' } }))
      return isAdminUser(user, adminGroupRef)
    })()

    /**
     * Admin users have access to all apps by default
     */
    if (isAdmin) return getAllAppsMapObj()

    slices.policyList.forEach((p) => {
      if (!isPolicyIssuedToUser(p, user)) return
      const rsrcRefs = getPolicyResourcesRefs(p)
      rsrcRefs.forEach((ref) => {
        const appKey = createRsrcKey(ref)
        // If the policy is for application, then push the policy ref
        if (ref.RefKind === 'Application') {
          const policiesRef = mapObj[appKey] || []
          mapObj[appKey] = [createRef(p), ...policiesRef]
        }
      })
    })
    return mapObj
  }

  return { getApplicationRsrcsAccessMap, getApplicationPolicysRef, getUserApplicationAccessMap }
}

export { useApplicationAccess }
