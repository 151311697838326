import { FullScreenModal } from 'V2Components'
import { DeletionModal } from 'procyon-ui'
import React from 'react'

const DeleteIntegrationModal = ({integrationName ,showDeleteModal, setShowDeleteModal, handleDeleteIntegrationClick}) => {
    return (
        <FullScreenModal showModal={showDeleteModal} >
          <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
            <DeletionModal
                style={{
                    width: '650px'
                }}
                message="This action cannot be undone."
                onClickCancel={() => setShowDeleteModal(false)}
                onClickDelete={() => handleDeleteIntegrationClick()}
                title={`Are you sure you want to delete integration ${integrationName?.ObjectMeta.Name} ?`}
                />
            </div>    
        </FullScreenModal>
    )
}

export {DeleteIntegrationModal}