import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { PolicysView, getPolicyIssuedToEntities } from 'features/policy'
import { EntitiesView, createRsrcKey } from 'features/resources'
import React, { useState } from 'react'

const PolicyEntitiesSummaryView = ({ policys = [] }) => {
  const { getObjectRef } = useMultiSlice(['userList', 'serviceAccounts', 'groupList'])
  const [selectedPolicy, setSelectedPolicy] = useState('')

  const getPolicysToEntitiesMap = () => {
    const map = {}
    policys.forEach((policy) => {
      map[createRsrcKey(policy)] = getObjectRef(getPolicyIssuedToEntities(policy))
    })

    return map
  }

  const getEntities = () => {
    const map = getPolicysToEntitiesMap()
    return map[selectedPolicy] || []
  }

  if (policys.length && !selectedPolicy) setSelectedPolicy(createRsrcKey(policys[0]))

  return (
    <div className='flex gap-4'>
      <div className='w-1/2'>
        <PolicysView
          policys={policys}
          onPolicyClick={setSelectedPolicy}
          activePolicyKey={selectedPolicy}
        />
      </div>
      <div className='w-1/2'>
        <EntitiesView entities={getEntities()} />
      </div>
    </div>
  )
}

export { PolicyEntitiesSummaryView }
