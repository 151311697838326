import {
  faBracketsCurly,
  faClose,
  faPause,
  faPauseCircle,
  faPlay,
  faRepeat
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from 'Utils/Helpers'
import { FullScreenContentModal, JSONPrettyView } from 'V2Components'
import Ansi from 'ansi-to-react'
import { convertTimeToMilliseconds, parseLogSessionData } from 'features/sessions'
import { Button, LoadingSpinner, Tooltip, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const CommandsRecordTab = ({ log }) => {
  const [slowedOutput, setSlowedOutput] = useState('')
  const [paused, setPaused] = useState(false)
  const [commandsBody, setCommandsBody] = useState({})
  const [showCommandsBody, setShowCommandsBody] = useState(false)

  const [isVideoComplete, setIsVideoComplete] = useState(false)
  const [indexes, setIndexes] = useState({
    sessionData: 0,
    timedData: 0
  })
  const sessionData = parseLogSessionData(log)
  if (!sessionData) return null

  const getCommands = () => {
    const commands = []

    sessionData.forEach((d) => {
      try {
        if (!d.Data) return
        if (d.Type === 'SSH') {
          commands.push({ cmd: cleanStringArray(d.Data.split('\r')), body: '' })
        } else if (d.Type === 'DB') {
          if (d.QueryType.toLowerCase() === 'query') {
            commands.push({ cmd: cleanStringArray(d.Data.split('\n')), body: '' })
          }
        } else if (d.Type === 'K8') {
          try {
            commands.push({ cmd: d.Data.Command, body: JSON.parse(d.Data.Body) })
          } catch (error) {
            commands.push({ cmd: d.Data.Command, body: d.Data.Body })
          }
        }
      } catch (error) {}
    })

    return commands
  }

  /**
   *
   * @returns {'Input' | 'Output'}
   */
  const getLogDirection = () => {
    // @ts-ignore
    if (sessionData.length) return sessionData[0].Direction || 'Input'
    return 'Input'
  }

  const renderInputDirection = () => {
    const cmds = getCommands()
    return (
      <>
        {cmds.length === 0 && <strong className='text-gray-200'>No Session Data</strong>}
        {cmds.map(({ cmd, body }) => {
          return (
            <Typography
              className={cn({
                '!text-gray-200': true,
                'flex gap-2 items-center': body
              })}
              variant='body-regular'
            >
              {log.ResoureceType === 'SSH' && (
                <>
                  <strong>{getPrincipal()}</strong>
                  <span className='text-green-500 font-bold mr-1'> $</span>
                </>
              )}
              {cmd}
              {body && (
                <Tooltip placement='right' arrow title='Click To View Command Body'>
                  <Button
                    onClick={() => {
                      setCommandsBody(body)
                      setShowCommandsBody(true)
                    }}
                    variant='success'
                    className='bg-green-700 !p-1 !h-[20px] rounded'
                  >
                    <FontAwesomeIcon size='sm' icon={faBracketsCurly} />
                  </Button>
                </Tooltip>
              )}
            </Typography>
          )
        })}
      </>
    )
  }

  const renderOutputDirection = () => {
    return slowedOutput
      .split('\n')
      .reverse()
      .map((e) => (
        <div className=''>
          <Ansi>{removeTerminalTitle(e)}</Ansi>
        </div>
      ))
  }

  const getPrincipal = () => {
    if (sessionData.length && log.ResoureceType === 'SSH') return sessionData[0].Principal
  }

  const direction = getLogDirection()

  /**
   * UseEffect is used for animating because of sideeffects
   * It is possible without useEffect but would need more cleanup than necessary.
   * Hence, here it is okay to use useEffect.
   */
  useEffect(() => {
    const fn = async () => {
      let isIndexMutated = false

      if (direction !== 'Output') return
      const session = sessionData[indexes.sessionData]
      const timedData = session.TimedData?.[indexes.timedData]
      if (!timedData) return
      let duration = 0
      // If it is the first timed data, use the current duration
      if (indexes.timedData === 0) {
        duration = convertTimeToMilliseconds(timedData.Duration)
      } else {
        /**
         * The duration in timedData.Duration is relative to the start if the session.
         * Hence, to calculate to the time taken to show data on screen is,
         * the time difference between previous and the current command.
         */
        duration =
          convertTimeToMilliseconds(timedData.Duration) -
          convertTimeToMilliseconds(session.TimedData[indexes.timedData - 1].Duration)
      }

      await sleep(duration)
      /**
       * The command must be paused after the delay, else
       * the pause will be delayed by one await
       */
      if (paused) return

      setSlowedOutput((s) => {
        /**
            The ANSI escape sequence '\b\u001b[K' is a combination of two control characters:
            \b (Backspace): This control character represents a backward movement of the cursor by one position, 
            effectively moving it one space to the left. 
            It doesn't delete characters but moves the cursor position backward. 
            It's often used in combination with other characters or escape sequences to control cursor movement or overwrite existing text.
            \u001b[K (ANSI Erase Line): This part of the sequence is an ANSI escape sequence that clears or erases the line from the current cursor position to the end of the line. 
            The \u001b is the escape character, and [K is the ANSI control code for erasing the line.
            When combined, '\b\u001b[K' can be used to clear the characters to the left of the current cursor position, effectively erasing them. 
           */
        if (timedData.Data === '\b\u001b[K') return s.slice(0, -1)
        return s + timedData.Data
      })

      // Increase the sessionData index by 1
      if (indexes.sessionData < sessionData.length - 1) {
        setIndexes((s) => ({ ...s, sessionData: 1 + s.sessionData }))
        isIndexMutated = true
      }

      // Increate the timed data index by 1
      if (indexes.timedData < session.TimedData.length - 1) {
        setIndexes((s) => ({ ...s, timedData: s.timedData + 1 }))
        isIndexMutated = true
      }
      if (!isIndexMutated) setIsVideoComplete(true)
    }

    fn()
  }, [paused, indexes])

  return (
    <div className='w-[100%]'>
      <div className='bg-black w-[100%] h-[434px]  mr-3 rounded-md text-white p-2 flex overflow-auto flex-col-reverse gap-1'>
        {direction === 'Output' && (
          <div className='absolute top-5 right-2'>
            {!paused && !isVideoComplete && <LoadingSpinner color='white' size={16} />}
            {paused && !isVideoComplete && <FontAwesomeIcon icon={faPauseCircle} />}
          </div>
        )}
        {direction === 'Input' && renderInputDirection()}
        {direction === 'Output' && renderOutputDirection()}
      </div>
      {direction === 'Output' && (
        <div className='mt-4 flex justify-end p-2 gap-2'>
          {isVideoComplete && (
            <Button
              width='80px'
              onClick={() => {
                setIndexes({ sessionData: 0, timedData: 0 })
                setSlowedOutput('')
                setPaused(false)
                setIsVideoComplete(false)
              }}
              variant='grayBlue'
              icon={faRepeat}
            >
              Replay
            </Button>
          )}

          {!isVideoComplete && paused && (
            <Button
              width='80px'
              onClick={() => setPaused((s) => !s)}
              variant='grayBlue'
              icon={faPlay}
            >
              Play
            </Button>
          )}
          {!isVideoComplete && !paused && (
            <Button width='80px' onClick={() => setPaused((s) => !s)} variant='gray' icon={faPause}>
              Pause
            </Button>
          )}
        </div>
      )}
      {showCommandsBody && (
        <FullScreenContentModal>
          <div className='max-h-[800px]'>
            <div className='flex justify-between mb-4'>
              <Typography variant='h2'>Command Body</Typography>
              <Button onClick={() => setShowCommandsBody(false)} variant='gray' icon={faClose}>
                Close
              </Button>
            </div>
            {/*@ts-ignore */}
            <JSONPretty theme={jsonTheme} data={{ ...commandsBody, t: { ...commandsBody } }} />
          </div>
        </FullScreenContentModal>
      )}
    </div>
  )
}

const UNWANTED_ESCAPE_SEQUENCES = [/\x1B\]0;[^\x07]*\x07/g, /\[\?\d+h/g, /\[\?\d+l/g]

function removeTerminalTitle(text) {
  // Define a regular expression to match the ANSI escape sequence that sets the terminal title.
  const ansiEscapeRegex = /\x1b\[[0-9;]*[A-Za-z]/g

  const cleanText = UNWANTED_ESCAPE_SEQUENCES.reduce((prev, regex) => {
    return prev.replace(regex, '')
  }, text)

  // Preserve color codes and backspace (\x08)
  const cleanedText = cleanText.replace(ansiEscapeRegex, (match) => {
    if (match === '\x1b[0m') {
      // Preserve reset color code
      return match
    } else if (match.match(/\x1b\[[0-9;]*m/)) {
      // Preserve other color codes
      return match
    } else if (match === '\x08') {
      // Preserve backspace character
      return match
    } else {
      return '' // Remove other escape sequences
    }
  })
  // Use the replace method to remove the matched ANSI escape sequences.

  return cleanedText
}

const cleanStringArray = (arr = []) => {
  const newArr = []
  arr.forEach((a) => {
    const trim = a.trim()
    if (trim) newArr.push(trim)
  })
  return newArr
}

const jsonTheme = {
  main: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;padding:1rem;margin:-1.25rem;margin-top:1rem;max-height:600px;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;',
  error: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;',
  key: 'color:#f92672;',
  string: 'color:#fd971f;',
  value: 'color:#a6e22e;',
  boolean: 'color:#ac81fe;'
}
