import React, { createContext, useContext } from 'react'

/**
 * @type {FilterOptions}
 */
const FILTER_OPTIONS = {
  hideCloudToggle: false,
  hideDropdown: false,
  hideCloudOptions: false,
  hideAccessStatusFilters: false,
  hideFilterCount: false
}

const ResourcesViewContext = createContext({
  dropDownItems: [],
  filterOptions: FILTER_OPTIONS,
  cloudType: 'AWS'
})

/**
 * @typedef {{
 * hideCloudToggle?: boolean,
 * hideDropdown?: boolean
 * hideCloudOptions?: boolean,
 * hideAccessStatusFilters?: boolean
 * hideFilterCount?: boolean
 * }} FilterOptions
 *
 * @param {{
 * children: any,
 * dropDownItems: {label:string, value: string }[]
 * filterOptions?:FilterOptions
 * cloudType?: 'AWS' | 'GCP' | 'AZURE' | 'PRIVATE'
 * }} param0
 * @returns
 */
const ResourcesViewProvider = ({ children, dropDownItems, filterOptions = FILTER_OPTIONS, cloudType }) => {
  const safeOptions = { ...FILTER_OPTIONS, ...filterOptions }

  return (
    //@ts-ignore
    <ResourcesViewContext.Provider value={{ dropDownItems, filterOptions: safeOptions, cloudType }}>
      {children}
    </ResourcesViewContext.Provider>
  )
}

export const useResourcesViewContext = () => useContext(ResourcesViewContext)

export { ResourcesViewProvider }
