import { TabGroup } from 'procyon-ui'
import React from 'react'
import { OverviewTab } from './OverviewTab'
import { AccessGraphTab } from './AccessGraphTab'
import { AccessSummaryTab } from './AccessSummaryTab'
import { ActivityLogsTab } from './ActivityLogsTab'

function Tabs() {
  return (
    <div className="pt-4">
      <TabGroup
        onChangeTab={function noRefCheck() {}}
        tabs={[
          {
            label: 'Overview',
            tabContent: <OverviewTab />
          },
          {
            label: 'Access Graph',
            tabContent: <AccessGraphTab />
          },
          {
            label: 'Access Summary',
            tabContent: <AccessSummaryTab />
          },
          {
            label: 'Activity Logs',
            tabContent: <ActivityLogsTab />
          }
        ]}
      />
    </div>
  )
}

export { Tabs }
