import { enqueueNotification } from 'Utils/Helpers'
/** * ============================== Required constants goes in here ================================= */

export const KUBENAMESPACE_TARGET_ICON_TYPE = {
  PRIVATE: 'KUBE_NAMESPACES',
  AWS: 'AWS_KUBENAMESPACE',
  GCP: 'GCP_KUBENAMESPACE',
  AZURE: 'AZURE_KUBENAMESPACE'
}

/** ================================================================================================= */

export const getIamActionName = (iamAction) => {
  try {
    const type = iamAction.Spec.CloudType
    if (type === 'AZURE') return iamAction.Spec.RoleName || iamAction.ObjectMeta.Name
    return iamAction.ObjectMeta.Name
  } catch (error) {
    console.error('error in getIamActionName', error)
    return 'N/A'
  }
}

/**
 * Returns Kubenamespace name with cluster if it exists
 * @param {any} kubeNamespace
 * @returns
 */
export const getKubeNamespaceName = (kubeNamespace) => {
  return kubeNamespace.Spec.DisplayName || kubeNamespace.Spec.Name
}

/**
 * Get tags from resource object
 * @param {object} rsrc resource
 * @returns {Object.<any, string>} Object with tags
 */

export const getAwsResourceTags = (rsrc) => {
  return rsrc.ObjectMeta.CTags?.Map || {}
}

export const getRsrcTags = getAwsResourceTags

/**
 * Get the display type of iam resource
 * @param {*} rsrc
 * @returns {string} Display Type
 */
export const getIAMResourceDisplayType = (rsrc) => {
  if (rsrc.ObjectMeta.Kind === 'AwsResource') return rsrc.Spec.Type.replaceAll('_', ' ')
  if (rsrc.ObjectMeta.Kind === 'KubeCluster' || rsrc.ObjectMeta.Kind === 'KubeNamespace')
    return rsrc.Spec.DisplayName
  if (rsrc.ObjectMeta.Kind === 'Application') return rsrc.Spec.AppType
  if (rsrc.ObjectMeta.Kind === 'CRMEntity') return rsrc.CRM
  return rsrc.Spec.DisplayType || ''
}

/**
 * Returns true for cloudType which has iam actions map link
 * @param {'AWS' |'GCP'|'AZURE'} cloudType
 * @returns {boolean}
 */
export const isCloudTypeSupportedIAMAction = (cloudType) => {
  return ['GCP'].includes(cloudType)
}

export const copyProfileName = (ProfileName) => {
  try {
    if (!ProfileName)
      return enqueueNotification('Unable to find ProfileName for this role.', 'error')
    navigator.clipboard.writeText(ProfileName)
    enqueueNotification('Profile Name  copied : ' + ProfileName, 'info')
  } catch (error) {
    console.error(error)
  }
}

export * from './constants'
