import {
  faCircleMinus,
  faDatabase,
  faProjectDiagram,
  faTable
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useCloudActions from 'Core/Hooks/useCloudActions'
import { FloatingDrawer, SearchInput } from 'V2Components'
import { useAppDetailsContext } from 'features/databricks'
import { useAccessCartProvider } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { Button, IamRolesCard, Label, TreeItem, TreeView, Typography } from 'procyon-ui'
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import useAppView from 'Core/Hooks/useAppView'
import { getUserInfo } from 'Utils/Helpers'
const useSlices = createDataSelectorHook([
  'dataBricksAccounts',
  'dataBricksResources',
  'dataBricksWorkSpaces',
  'dataBricksIdentitys'
])
const ResourcesTab = () => {
  const { slices } = useSlices()
  const { app } = useAppDetailsContext()
  const { appView } = useAppView()
  const user = getUserInfo()
  const { dataBricksCloudActions } = useCloudActions()
  const [showGrantModal, setShowGrantModal] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [selectedPermission, setSelectedPermission] = useState([])
  const [permissionList, setPermissionList] = useState([])
  const [selectedRsrc, setSelectedRsrc] = useState({})
  const [showResources, setShowResources] = useState(appView === 'admin' ? true : false)
  const { getResourceFromCart, addItemsToCart, updateCartQueueItem } = useAccessCartProvider()

  useMemo(() => {
    const userIndentity = _.find(slices?.dataBricksIdentitys, {
      ObjectMeta: { ID: app.Status.Users.ObjectRef[0]?.RefID }
    })

    if (appView === 'user' && userIndentity?.Spec?.Email === user.Spec.EmailID) {
      setShowResources(true)
    }
  }, [slices])

  const filteredResources = slices.dataBricksResources.filter(
    (rsrc) =>
      rsrc?.Spec?.Account?.RefID === app?.Account?.RefID &&
      rsrc?.Spec?.Workspace?.RefID === app?.ObjectMeta?.ID
  )

  const catalogObject = filteredResources.filter((rsrc) => rsrc.Spec.Type === 'CATALOG')
  const schemaObject = filteredResources.filter((rsrc) => rsrc.Spec.Type === 'SCHEMA')
  const tableObject = filteredResources.filter((rsrc) => rsrc.Spec.Type === 'TABLE')

  const catalogList = (rsrc) => {
    return rsrc.map((item) => (
      <TreeItem
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Catalog'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Permission'
        labelText={item.Spec.DisplayName}
        nodeId={`catalog+${item.ObjectMeta.ID}`}
      >
        {renderSchema(item)}
      </TreeItem>
    ))
  }

  const renderSchema = (rsrc) => {
    const filterParentObj = schemaObject.filter(
      (item) => item?.Spec?.Parent?.RefID === rsrc.ObjectMeta.ID
    )
    return filterParentObj.map((item) => (
      <TreeItem
        labelIcon={<FontAwesomeIcon icon={faProjectDiagram} />}
        labelText={item.Spec.DisplayName}
        nodeId={`schema+${item.ObjectMeta.ID}`}
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Schema'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Permission'
      >
        {renderTable(item)}
      </TreeItem>
    ))
  }

  const renderTable = (rsrc) => {
    const filterParentObj = tableObject.filter(
      (item) => item?.Spec?.Parent?.RefID === rsrc.ObjectMeta.ID
    )

    return filterParentObj.map((item) => (
      <TreeItem
        labelIcon={<FontAwesomeIcon icon={faTable} />}
        labelText={item.Spec.DisplayName}
        nodeId={`table+${item.ObjectMeta.ID}`}
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Table'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Permission'
      />
    ))
  }

  const isResourceInQueue = getResourceFromCart({
    RefKind: selectedRsrc?.RefKind,
    RefID: selectedRsrc?.RefID
  })

  const handleGrantPermission = (item) => {
    const actionList = dataBricksCloudActions.find((action) => action.Service === item.Spec.Type)
    setPermissionList(actionList?.Actions?.Elems)
    setShowGrantModal(true)
    setSelectedRsrc(item)
  }

  const handleRemoveRoleClick = (permission) =>
    setSelectedPermission((s) => s.filter((e) => e !== permission))

  const handleAddToQueue = () => {
    const { ID, Kind } = selectedRsrc?.ObjectMeta

    // If resource is already in queue, then update the resource item
    // Else, create a new resource item
    if (isResourceInQueue) {
      updateCartQueueItem({
        resourceRef: {
          RefID: ID,
          RefKind: Kind
        },
        permission: selectedPermission
      })
    } else {
      addItemsToCart({
        resourceRef: {
          RefID: ID,
          RefKind: Kind
        },
        principal: '',
        permission: selectedPermission
      })
    }
    setSelectedPermission([])
    setShowGrantModal(false)
  }

  const handleRoleActionClick = (permission) => {
    setSelectedPermission((s) => {
      if (s.find((r) => r === permission)) return s.filter((e) => e !== permission)
      return [...s, permission]
    })
  }

  return (
    <>
      {app && showResources && (
        <TreeView
          defaultExpanded={['0']}
          height={'100%'}
          maxWidth={800}
          onNodeFocus={function noRefCheck() {}}
          onNodeSelect={function noRefCheck() {}}
          onNodeToggle={function noRefCheck() {}}
          sx={{
            flexGrow: 1,
            overflowY: 'auto'
          }}
        >
          <TreeItem
            labelIcon={<FontAwesomeIcon icon={faDatabase} />}
            labelText='CATALOG'
            nodeId='2'
          >
            {catalogList(catalogObject)}
          </TreeItem>
        </TreeView>
      )}

      <FloatingDrawer
        onOverlayClick={() => setShowGrantModal(!showGrantModal)}
        showDrawer={showGrantModal}
      >
        <div className='mx-4 relative pb-20'>
          <Typography variant='body-regular'>Select Permissions</Typography>
          <div className='flex justify-between my-4'>
            <SearchInput
              searchKey={searchKey}
              sx={{ width: '272px' }}
              className={''}
              onChange={setSearchKey}
            />
          </div>
          <div className='flex gap-2 mb-4 flex-wrap'>
            {selectedPermission.map((e) => (
              <Label
                text={e}
                iconButton={faCircleMinus}
                variant='grayBlue'
                onClick={() => handleRemoveRoleClick(e)}
              />
            ))}
          </div>
          <div>
            {permissionList &&
              permissionList?.map((e) => (
                <IamRolesCard
                  className='mb-2'
                  style={{
                    height: '60px',
                    minHeight: '53px important!',
                    alignItems: 'center'
                  }}
                  key={e}
                  showOpenButton={false}
                  showMoreButton={false}
                  description={e.description}
                  selected={e.selected}
                  assigned={e.selected}
                  onClickActionButton={() => handleRoleActionClick(e)}
                  title={e}
                ></IamRolesCard>
              ))}
          </div>
          <div className='fixed bottom-0 flex !bg-white w-[671px] justify-end  gap-2 right-0 z-[501] border-t p-4'>
            <Button onClick={() => setShowGrantModal(false)} variant='gray'>
              Cancel
            </Button>
            <Button
              disabled={!selectedPermission.length}
              onClick={handleAddToQueue}
              variant='primary'
            >
              Add To Request Queue
            </Button>
          </div>
        </div>
      </FloatingDrawer>
    </>
  )
}

export { ResourcesTab }
