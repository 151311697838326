import {
  createRef,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getRsrcIcon
} from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { Label, SummaryResourceCard } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { getPolicyAttachedIAMActions } from 'features/policy/utils'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'
import { SimpleIAMRolesSelectionDrawer } from 'features/iamResources'

const IAMAction = ({ iamActionsRsrcMap, setIamActionsRsrcMap }) => {
  const [selectedRsrcRef, setSelectedRsrcRef] = useState(null)
  const [showIAMRolesSelectDrawer, setShowIAMRolesSelectDrawer] = useState(false)
  const [initialIamRoles, setInitialIamRoles] = useState([])

  const { getObjectRef } = useObjectRef([
    'iamActions',
    'awsResources',
    'gcpResources',
    'azureResources',
    'accountList',
    ...TargetsSliceNames
  ])

  const { policy, selectedResources, removeSelectedResource } = useEditPolicyModulesContext()

  const iamResourcesResolver = (rsrc) => {
    const rsrcKey = createRsrcKey(rsrc)
    const account = getObjectRef(getRsrcAccountRef(rsrc))
    const data = {
      key: rsrcKey,
      rsrcName: getResourceName(rsrc),
      Icon: getRsrcIcon(rsrc),
      attributes: [],
      accountName: getResourceName(account),
      rsrcRef: createRef(rsrc)
    }
    const actions = getObjectRef(iamActionsRsrcMap[rsrcKey])
    actions?.forEach((act) => {
      data.attributes.push({
        title: getResourceName(act),
        action: act
      })
    })

    return data
  }

  const handleIAMActionsSave = (rsrc, iamActions) => {
    setIamActionsRsrcMap((s) => {
      return { ...s, [createRsrcKey(rsrc)]: iamActions }
    })
  }

  const handleEditRsrc = (item) => {
    setSelectedRsrcRef(item)
    const temp = iamActionsRsrcMap[createRsrcKey(item)] || []
    const selectedIamActions = getObjectRef(temp)

    const iamactionsItems = selectedIamActions.map((action) => ({
      description: action.Spec.Description,
      title: getResourceName(action),
      actions: action.Spec.Actions.Elems,
      RefID: action.ObjectMeta.ID
    }))

    setInitialIamRoles(iamactionsItems)
    setShowIAMRolesSelectDrawer(true)
  }

  const getRsrcItems = () => {
    const items = []
    selectedResources.forEach((ref) => {
      const rsrc = getObjectRef(ref)
      if (!rsrc) return
      const data = iamResourcesResolver(rsrc)
      items.push(data)
    })

    return items.map(({ key, rsrcRef, accountName, rsrcName, attributes = [], Icon }) => {
      return (
        <div className='mb-4'>
          <SummaryResourceCard
            key={key}
            isEditButtonEnabled={rsrcRef.RefKind !== 'AppRole'}
            accountName={accountName}
            isDeleteButtonEnabled
            resourceIcon={<Icon />}
            resourceName={rsrcName}
            resourceType=''
            region=''
            onClickEditButton={() => handleEditRsrc(rsrcRef)}
            onClickDeleteButton={() => removeSelectedResource(rsrcRef)}
          >
            {attributes.map((r) => (
              <Label key={r.title} text={r.title} variant='grayBlue' />
            ))}
          </SummaryResourceCard>
        </div>
      )
    })
  }

  useEffect(() => {
    const iamRefs = getPolicyAttachedIAMActions(policy)
    setIamActionsRsrcMap(iamRefs)
  }, [])

  return (
    <div>
      {getRsrcItems()}
      {showIAMRolesSelectDrawer && (
        <SimpleIAMRolesSelectionDrawer
          onCloseDrawer={() => setShowIAMRolesSelectDrawer(false)}
          onCreateIAMRoleClick={() => {}}
          onSaveClick={handleIAMActionsSave}
          selectedRsrc={selectedRsrcRef}
          showDrawer
          initialIamRoles={initialIamRoles}
        />
      )}
    </div>
  )
}

export { IAMAction }
