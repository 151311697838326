import axios from 'axios'
import { getServerControllerTags } from 'features/serverControllers'
import { createDataSelectorHook } from 'infra/redux'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

/**
 * @type {{
 *  controllerName?: string,
 *  selectedNodeIDs?: string[],
 *  selectorType?: 'Nodes' | 'NodeTypes' | 'Labels',
 *  label?: string,
 *  ctag?: string,
 *  nodeTypes?: any[],
 *  groups?: string[]
 *  groupsMap?: any
 *  sudoers?: string,
 *  disableAnonymousLogin?: boolean
 *  disableOSLogin?: boolean,
 *  isEditMode?:boolean
 *  targetProxyImages?: {
 *    'procyon-proxy': string
 *    'guacamole-client': string
 *    'guacamole-server': string
 * }
 * targetProxyImagesOptions?: {
 *    'procyon-proxy': string[]
 *    'guacamole-client': string[]
 *    'guacamole-server': string[]
 * }
 * rdpEnabled?: boolean
 * proxyCtrl?: any
 * }}
 */
const INITIAL = {
  controllerName: '',
  selectedNodeIDs: [],
  selectorType: 'Nodes',
  label: 'proxygrp',
  ctag: '',
  nodeTypes: [],
  groups: [],
  sudoers: '',
  groupsMap: {},
  targetProxyImages: {
    'procyon-proxy': 'public.ecr.aws/v2s2h8i9/procyon-proxy:latest',
    'guacamole-client': 'public.ecr.aws/v2s2h8i9/procyon-guacamole-client:latest', //RDP Enabled
    'guacamole-server': 'public.ecr.aws/v2s2h8i9/procyon-guacamole-server:latest' //RDP Enabled
  },
  rdpEnabled: false,
  disableAnonymousLogin: true,
  disableOSLogin: false,
  isEditMode: false,
  proxyCtrl: null,
  targetProxyImagesOptions: {
    'procyon-proxy': ['latest'],
    'guacamole-client': ['latest'], //RDP Enabled
    'guacamole-server': ['latest'] //RDP Enabled
  }
}

const ProxyControllerWizardContext = createContext({
  ...INITIAL,
  /**
   *
   * @param {{
   *  controllerName?: string,
   *  selectedNodeIDs?: string[],
   *  selectorType?: 'Nodes' | 'NodeTypes' | 'Labels',
   *  label?: string,
   *  ctag?: string,
   *  nodeTypes?: any[],
   *  groups?: string[]
   *  groupsMap?: any
   *  sudoers?: string,
   * proxyCtrl?: any
   * targetProxyImages?: {
   *    'procyon-proxy': string
   *    'guacamole-client': string
   *    'guacamole-server': string
   * }
   * targetProxyImagesOptions?: {
   *    'procyon-proxy': string[]
   *    'guacamole-client': string[]
   *    'guacamole-server': string[]
   * }
   * disableAnonymousLogin?:boolean
   * disableOSLogin?: boolean
   * rdpEnabled?: boolean
   * }} config
   */
  updateConfig(config) {}
})
const useSlices = createDataSelectorHook(['userList'])

export const ProxyControllerWizardProvider = ({ children, proxyCtrl = null }) => {
  const [config, setConfig] = useState(INITIAL)

  const isConfigLoaded = useRef(false)

  const { slices } = useSlices()

  if (proxyCtrl && !isConfigLoaded.current && slices.userList.length) {
    isConfigLoaded.current = true
    //@ts-ignore
    setConfig(getProxyCtrlConfig(proxyCtrl, slices.userList))
  }

  const updateConfig = (uptdConfig) => {
    setConfig((s) => ({ ...s, ...uptdConfig }))
  }

  const populateProxyImagesOptions = async () => {
    try {
      const response = await axios.get(
        'https://procyon-gcp-roles.s3.us-west-2.amazonaws.com/proxy-tag.json'
      )
      updateConfig({ targetProxyImagesOptions: response.data })
    } catch (error) {}
  }

  useEffect(() => {
    populateProxyImagesOptions()
  }, [])

  return (
    <ProxyControllerWizardContext.Provider
      //@ts-ignore
      value={{
        ...config,
        updateConfig,
        isEditMode: !!proxyCtrl,
        proxyCtrl
      }}
    >
      {children}
    </ProxyControllerWizardContext.Provider>
  )
}

const getProxyCtrlConfig = (proxyCtrl) => {
  if (!proxyCtrl) return INITIAL
  const controllerName = proxyCtrl.ObjectMeta.Name

  const tagsMap = getServerControllerTags(proxyCtrl)
  const label = tagsMap.labels.join()

  const selectedNodeIDs = proxyCtrl.Spec.MedusaNodes.Elems
  const nodeTypes = proxyCtrl.Spec.NodeTypes.Elems

  const targetProxyImages = proxyCtrl.Spec.TargetImageInfo.Map
  const rdpEnabled = Object.keys(targetProxyImages).length > 1
  let selectorType = ''

  if (selectedNodeIDs.length) selectorType = 'Nodes'
  else if (label.length) selectorType = 'Labels'
  else selectorType = 'NodeTypes'

  return {
    controllerName,
    selectorType,
    label,
    selectedNodeIDs,
    nodeTypes,
    targetProxyImages: { ...INITIAL.targetProxyImages, ...targetProxyImages },
    rdpEnabled
  }
}

export const useProxyControllerWizardContext = () => useContext(ProxyControllerWizardContext)
