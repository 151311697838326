import { UserDetailsProvider } from 'features/users'
import React from 'react'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'

function UserDetails() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <Tabs />
    </div>
  )
}

const WrappedUserDetails = () => (
  <UserDetailsProvider>
    <UserDetails />
  </UserDetailsProvider>
)

export { WrappedUserDetails as UserDetails }
