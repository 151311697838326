import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import { APPROVED_STATUS } from '../utils'
import _ from 'lodash'
import { useEffect } from 'react'
import useAppView from 'Core/Hooks/useAppView'

const useSlices = createDataSelectorHook(['approvalReqList'], { disableReduxFetch: false })
export const useApprovalPolling = () => {
  const { slices } = useSlices()
  const { appView } = useAppView()

  const startApprovalPolling = () => {
    const pendingReqNames = slices.approvalReqList.reduce((prev, req) => {
      if (req.Status === APPROVED_STATUS.Pending && req.Spec.Resources.Resource.length) {
        prev.push(req.ObjectMeta.Name)
      }
      return prev
    }, [])

    for (const name of pendingReqNames) {
      reduxApiClient('pacpolicys').startRsrcObjectPolling(
        name,
        (obj) => {
          if (obj.Type === 'IAMAction') {
            const assumeRole = _.get(obj, 'Spec.ActionMap.AssumeRole')
            return !!assumeRole
          }
          return !!obj
        },
        `pac/${name}`
      )
      reduxApiClient('approvalreqs').startRsrcObjectPolling(
        name,
        (obj) => {
          return obj.Status !== APPROVED_STATUS.Pending
        },
        `approval/${name}`
      )
    }
  }

  useEffect(() => {
    if (appView === 'user') startApprovalPolling()
  }, [slices.approvalReqList])

  return { startApprovalPolling }
}
