import { faClose } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { getApprovalRequestResourcesRefs } from 'features/approvals'
import { getKubeNamespaceName } from 'features/iamResources'
import { getPolicyDisplayName } from 'features/policy'
import { getResourceName, getRsrcIcon } from 'features/resources'
import moment from 'moment'
import { Button, ResourceDropDownItem, TabGroup, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'
import { getServiceAccountNameFromRequest } from 'Utils/PureHelperFuctions'
import { FullScreenModal } from 'V2Components'

function ResourcesListModal({ showModal, onClose, approvalReq }) {
  const history = useHistory()

  const { appView } = useAppView()

  const getDataItems = () => {
    const resourcesRefs = getApprovalRequestResourcesRefs(approvalReq)
    const rsrcsObjects = getObjectRefFlat(resourcesRefs)
    if (approvalReq.Type === 'ServiceAccount') {
      return [
        {
          Icon: getRsrcIcon({ ObjectMeta: { Kind: 'ServiceAccount' } }),
          title: getServiceAccountNameFromRequest(approvalReq),
          caption: 'ServiceAccount'
        }
      ]
    }
    return rsrcsObjects.map((r) => {
      const Icon = getRsrcIcon(r)
      switch (r.ObjectMeta.Kind) {
        case 'AwsResource':
          return {
            Icon,
            title: getResourceName(r),
            caption: r.Spec.DisplayType
          }
        case 'KubeNamespace':
          return {
            Icon,
            title: getKubeNamespaceName(r),
            caption: r.ObjectMeta.DisplayType
          }
        case 'AzureResource':
          return {
            Icon,
            title: getResourceName(r),
            caption: r.Spec.DisplayType
          }
        case 'GcpResource':
          return {
            Icon,
            title: r.Spec.DisplayName,
            caption: r.Spec.DisplayType
          }
        default:
          return {
            Icon,
            title: getResourceName(r)
          }
      }
    })
  }

  const requestorUser = getObjectRefFlat(approvalReq.Requestor)

  return (
    <FullScreenModal showModal={showModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='!text-lg'>
              {getPolicyDisplayName(approvalReq)}
            </Typography>
            <div className='flex gap-4'>
              <Button
                onClick={() => {
                  history.push(`/${appView}/access-requests/${approvalReq.ObjectMeta.Name}`)
                }}
                variant='grayBlue'
              >
                View Details
              </Button>
              <Button onClick={onClose} variant='gray' icon={faClose} iconPosition='end'>
                Close
              </Button>
            </div>
          </div>
          <Typography variant='caption-regular'>
            {requestorUser && (
              <>
                Requested by <strong>{getResourceName(requestorUser)}</strong> on
                <strong>
                  {moment(approvalReq.ObjectMeta.CreatedAt).format(' MMMM Do YYYY, h:mm A')}
                </strong>
              </>
            )}
          </Typography>
          <div className='mt-6'>
            <TabGroup
              tabs={[
                {
                  label: `Resources (${getDataItems().length})`,
                  tabContent: (
                    <>
                      {getDataItems().map(({ Icon, caption, title }) => (
                        <ResourceDropDownItem
                          key={title + caption}
                          icon={<Icon className='rounded-md' />}
                          onClick={function noRefCheck() {}}
                          resourceName={title}
                          resourceType={caption}
                        />
                      ))}
                    </>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { ResourcesListModal }
