import { makeStyles } from '@material-ui/core'
import { Security } from '@material-ui/icons'
import { AlertLogs } from 'Components/AlertLogs'
import React from 'react'
import { chipsItems, logItems } from './metaData'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const SecurityAlerts = ({ onLogClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} id='intro-js-alerts'>
      <AlertLogs Title='Security Alerts' Icon={Security} chips={chipsItems} logs={logItems} onLogClick={onLogClick} />
    </div>
  )
}

export default SecurityAlerts
