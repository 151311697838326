const { createContext } = require('react')
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { LoadingFeedback } from 'V2Components'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getPolicyError, isPolicyIssuedToUser, isWorkloadIdentityPolicy } from '../utils'
import equal from 'fast-deep-equal'

const PolicyDetailsContext = createContext({
  policy: undefined,
  isIssuedToLoggedInUser: false,
  error: null
})

const getPolicyFromList = (list, name) => _.find(list, { ObjectMeta: { Name: name } })

export const PolicyDetailsProvider = ({ children }) => {
  const { slices, isFetched, selectDispatch } = useMultiSlice(['policyList'])
  //@ts-ignore
  const { policyName } = useParams()
  const { user } = useUser()
  const [policy, setPolicy] = useState(
    getPolicyFromList(slices.policyList, decodeURIComponent(policyName))
  )
  const [error, setError] = useState(getPolicyError(policy))

  const p = getPolicyFromList(slices.policyList, decodeURIComponent(policyName))

  if (isFetched(['policyList']) && !equal(policy, p)) {
    if (p) setPolicy(p)
  }

  if (policy && getPolicyError(policy) && !error) setError(getPolicyError(policy))

  useEffect(() => {
    selectDispatch(['policyList'])
  }, [])

  // For workload issued policy the result will be true
  const isIssuedToLoggedInUser =
    isWorkloadIdentityPolicy(policy) || isPolicyIssuedToUser(policy, user)

  return (
    <PolicyDetailsContext.Provider value={{ policy, isIssuedToLoggedInUser, error }}>
      {policy && children}
      {!policy && <LoadingFeedback shrinked loading message='Fetching Policy' />}
    </PolicyDetailsContext.Provider>
  )
}

export const usePolicyDetailsContext = () => useContext(PolicyDetailsContext)
