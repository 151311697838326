
export const FILL_SELECTED_USER = 'FILL_SELECTED_USER'
export const FLUSH_SELECTED_USER = 'FLUSH_SELECTED_USER'

export const FILL_SELECTED_ACCOUNT = 'FILL_SELECTED_ACCOUNT'
export const FLUSH_SELECTED_ACCOUNT = 'FLUSH_SELECTED_ACCOUNT'
export const FILL_SELECTED_IDP = 'FILL_SELECTED_IDP'
export const FLUSH_SELECTED_IDP = 'FLUSH_SELECTED_IDP'
export const FILL_SELECTED_DEVICE = 'FILL_SELECTED_DEVICE'
export const FLUSH_SELECTED_DEVICE = 'FLUSH_SELECTED_DEVICE'

export const FILL_FAVORITE_IAM_LIST = 'FILL_FAVORITE_IAM_LIST'
export const FLUSH_FAVORITE_IAM_LIST = 'FLUSH_FAVORITE_IAM_LIST'

export const FILL_SESSION_REPLAYS = 'FILL_SESSION_REPLAYS'
export const FILL_FAVORITE_SERVER_LIST = 'FILL_FAVORITE_SERVER_LIST'
export const FLUSH_SESSION_REPLAYS = 'FLUSH_SESSION_REPLAYS'

const initState = {

  SELECTED_IDP: {},
  SELECTED_DEVICE: {},
  FAVORITE_IAM_LIST: [],
  FAVORITE_SERVER_LIST: [],
  SESSION_REPLAYS: []
}
const dataStoreReducer = (state = initState, action) => {
  switch (action.type) {
    case FILL_FAVORITE_IAM_LIST: {
      console.log('action.payload: ', action.payload)
      const FAVORITE_IAM_LIST = action.payload || []
      return { ...state, FAVORITE_IAM_LIST }
    }
    case FILL_FAVORITE_SERVER_LIST: {
      console.log('action.payload: ', action.payload)
      const FAVORITE_SERVER_LIST = action.payload || []
      return { ...state, FAVORITE_SERVER_LIST }
    }
    case FLUSH_FAVORITE_IAM_LIST:
      return {
        ...state, FAVORITE_IAM_LIST: []
      }

    case FILL_SELECTED_USER: {
      const SELECTED_USER = action.payload || []
      return { ...state, SELECTED_USER }
    }
    case FLUSH_SELECTED_USER:
      return {
        ...state, SELECTED_USER: {}
      }

    case FILL_SELECTED_IDP: {
      const SELECTED_IDP = action.payload || []
      return { ...state, SELECTED_IDP }
    }
    case FLUSH_SELECTED_IDP:
      return {
        ...state, SELECTED_IDP: {}
      }
    case FILL_SELECTED_DEVICE: {
      const SELECTED_DEVICE = action.payload || []
      return { ...state, SELECTED_DEVICE }
    }
    case FLUSH_SELECTED_DEVICE:
      return {
        ...state, SELECTED_DEVICE: {}
      }

    case FILL_SESSION_REPLAYS: {
      const SESSION_REPLAYS = action.payload || []
      return { ...state, SESSION_REPLAYS }
    }
    case FLUSH_SESSION_REPLAYS:
      return {
        ...state, SESSION_REPLAYS: {}
      }
    default:
      return state
  }
}

export default dataStoreReducer
