import { EntityInfoBar, LabelContent, SearchInput } from 'V2Components'
import { getResourceName } from 'features/resources'
import { Button, Label, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useOverviewTab } from './OverviewTab.utils'
import { ManageGroupUsers, getGroupSource } from 'features/groups'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

function OverviewTab() {
  const [searchKey, setSearchKey] = useState('')
  const [showManageGroupUsers, setShowManageGroupUsers] = useState(false)
  const { users, group } = useOverviewTab({ searchKey })

  return (
    <div className='mt-6'>
      <div>
        <div className='flex gap-4'>
          <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
            <Typography variant='h4-regular'>Group Information</Typography>
            <div className='mt-12'>
              <LabelContent title='Name' content={getResourceName(group)} />
              <LabelContent title='Description' content={group.Spec.Description} />
              <LabelContent
                title='Created On'
                content={moment(group.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')}
              />
              <LabelContent title='Source' content={<Label text={getGroupSource(group)} />} />
            </div>
          </div>
          <div className='w-1/2'>
            <div>
              <div className='flex justify-between items-center'>
                <Typography variant='h4-regular'>Users</Typography>
                <div className='flex gap-4'>
                  <SearchInput
                    sx={{ width: '303px' }}
                    searchKey={searchKey}
                    onChange={setSearchKey}
                  />
                  <Button
                    icon={faPen}
                    onClick={() => setShowManageGroupUsers(true)}
                    size='md'
                    variant='grayBlue'
                  >
                    Manage
                  </Button>
                </div>
              </div>
              <div>
                <Typography className='my-4' variant='body-regular'>
                  {users.length} Users
                </Typography>
                {users.map((u) => (
                  <EntityInfoBar
                    endButton={false}
                    kind='User'
                    name={getResourceName(u)}
                    caption={u.Spec.EmailID}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showManageGroupUsers && (
        <ManageGroupUsers
          group={group}
          onCancel={() => setShowManageGroupUsers(false)}
          onSuccess={() => setShowManageGroupUsers(false)}
        />
      )}
    </div>
  )
}

export { OverviewTab }
