/**
 * Note to developers:
 * Any functionality which is shared in multiple pages should be put here.
 */
import { ApiProvider, PropTypes } from 'Core'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

const defaultEntityFromResponse = (response) => response?.data

export const DetailsContext = createContext(null)

const DetailsContextProvider = ({ pathParameterName, storeName, endpoint, getEntityFromResponse = defaultEntityFromResponse, onlyRedux, thunk, children }) => {
  // @ts-ignore
  const { [pathParameterName]: param } = useParams()
  const name = decodeURIComponent(param)
  const dispatch = useDispatch()
  /** Used to prevent interference of an update in redux store with an ongoing fetch */
  const transactionLock = useRef(false)

  /** Redux store to which the entity belongs */
  const { data: reduxStore, status } = useMemoSelector(storeName)
  const [fetching, setFetching] = useState(true)
  const [managing, setManaging] = useState(false)
  const api = useRef(new ApiProvider(endpoint))
  const [entity, setEntity] = useState(false)
  const [error, setError] = useState('')
  useEffect(() => {
    if (status === 'loading' && !entity) {
      setFetching(true)
    }
  }, [entity, status])
  /**
   * Handles error in fetching, finding or updating the entity
   */
  const onError = useCallback((error) => {
    const stringifiedError = JSON.stringify(error)
    setError(stringifiedError)
    setFetching(false)
    setManaging(false)
    console.error(`error in fetching from ${endpoint}:`, error)
    return stringifiedError
  }, [entity])

  /**
   * Updates the entity state
   */
  const updateEntity = useCallback((tempEntity) => {
    setEntity(tempEntity)
    setError('')
    setFetching(false)
    setManaging(false)
    transactionLock.current = false
    return tempEntity
  }, [])

  /**
   * Toggles Spec.State property between the values 'unmanaged' | 'managed'
   */
  const handleManageSwitch = useCallback(async () => {
    try {
      setManaging(true)
      if (entity) {
        api.current.setInstance(entity)
        let response
        // @ts-ignore
        if (entity.ObjectMeta.Kind === 'Project') {
          // @ts-ignore
          const State = entity?.Status?.Status?.State === 'managed' ? 'unmanaged' : 'managed'
          response = await api.current.updateInstance({ Status: { Status: { State } } }).put()
        } else {
          // @ts-ignore
          const State = entity?.Spec?.State === 'managed' ? 'unmanaged' : 'managed'
          response = await api.current.updateInstance({ Spec: { State } }).put()
        }
        const tempEntity = getEntityFromResponse(response)
        return updateEntity(tempEntity)
      }
    } catch (error) {
      enqueueNotification('Operation Failed', 'error')
      onError(error)
    }
  }, [entity])

  /**
   * Used just for initial fetch
   * Speeds up first render time
   */
  const findInRedux = useCallback(() => {
    try {
      setFetching(true)
      const tempEntity = reduxStore.find(e => e?.ObjectMeta?.Name === name)
      if (tempEntity) {
        /**
         * Redux store is not updated when entity is updated
         * When user revists the page, redux store might be in an older state
         * Hence, we listen for any change in store and update the entity if required
         */
        if ((!_.isEqual(tempEntity, entity))) {
          updateEntity(tempEntity)
        }
        setFetching(false)
        /** Either the enity was successfully update or it does not needs to be updated */
        return true
      }
    } catch (error) {
      onError(error)
    }
    setFetching(false)
    /** Entity not found in Redux, Should fetch from server */
    return false
  }, [name, reduxStore, entity])

  /**
   * Fetches DB object from server
   */
  const fetch = useCallback(async () => {
    try {
      if (onlyRedux) return dispatch(thunk())
      /** Lock the transaction */
      transactionLock.current = true
      setFetching(true)
      const searchParam = { ObjectMeta: { Name: name } }
      const response = await api.current.setInstance(searchParam).get()
      const tempEntity = getEntityFromResponse(response)
      return updateEntity(tempEntity)
    } catch (error) {
      enqueueNotification('Can not find ' + name, 'error')
      return onError(error)
    }
  }, [name, onlyRedux, findInRedux])

  useEffect(() => {
    console.log('name', name)
    /** Check if transaction is locked */
    setFetching(true)
    if (!transactionLock.current) {
      /**
       * For initial page load
       * Check if entity is in redux store
       */
      if (!findInRedux()) {
        /**
         * If entity is not found in redux store
         * Fetch it from Server
         */
        if (!onlyRedux) fetch()
      }
    }
  }, [name, reduxStore, onlyRedux])

  useEffect(() => {
    try {
      dispatch(thunk())
    } catch (error) {
      console.error('error in calling thunk', error)
    }
  }, [dispatch])
  const contextProvides = { entity, error, fetching, managing, updateEntity, handleManageSwitch, fetch }

  return (
    <DetailsContext.Provider value={contextProvides}>
      {children}
    </DetailsContext.Provider>
  )
}

DetailsContextProvider.propTypes = {
  children: PropTypes.element,
  pathParameterName: PropTypes.string,
  storeName: PropTypes.string,
  endpoint: PropTypes.string,
  thunk: PropTypes.func,
  getEntityFromResponse: PropTypes.func
}

export { DetailsContextProvider }
