import moment from 'moment'

export const simpleSearchFilter = (data, key, match) => {
  const lowerCasesMatch = match ? match.toLowerCase() : ''
  return data.filter(e => {
    const value = e[key] ? e[key].toLowerCase() : ''
    return value.toLowerCase().includes(lowerCasesMatch)
  })
}

export const singleSelectFilter = (data, key, match) => {
  if (match && match !== '-') {
    return data.filter(e => match === e[key])
  }
  return data
}

export const multiSelectFilter = (data, key, match) => {
  if (match && match !== '-' && match.length > 0) {
    return data.filter(e => match.includes(e[key]))
  }
  return data
}
export const timeRangeFilter = (data, key, match) => {
  if (match && match.startDate && match.endDate) {
    const startDate = moment(match.startDate)
    const endDate = moment(match.endDate).add(1, 'day')
    return data.filter(e => {
      const date = moment.utc(e[key], 'YYYY-MM-DD HH:mm:ss')
      return date.isBetween(startDate, endDate)
    })
  }
  return data
}

export const tillDateFilter = (data, key, match) => {
  if (match && match !== '-') {
    const [count, unit] = match.split(' ')
    const startDate = moment().subtract(count, unit)
    const endDate = moment().add(1, 'day')
    return data.filter(e => {
      const date = moment.utc(e[key], 'YYYY-MM-DD HH:mm:ss')
      return date.isBetween(startDate, endDate)
    })
  }
  return data
}

export const applyTypeBasedFilter = (data, key, match, type) => {
  switch (type) {
    case 'Input':
      return simpleSearchFilter(data, key, match)
    case 'Dropdown':
      return singleSelectFilter(data, key, match)
    case 'MultiSelect':
      return multiSelectFilter(data, key, match)
    case 'TimeRange':
      return timeRangeFilter(data, key, match)
    case 'TillDate':
      return tillDateFilter(data, key, match)
    default:
      return simpleSearchFilter(data, key, match)
  }
}
