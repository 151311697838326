import useAppView from 'Core/Hooks/useAppView'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { getCurrentOrg } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'
import { fetchSessionLogs, parseLogSessionData } from 'features/sessions'
import _ from 'lodash'
import moment from 'moment'
import { LoadingModal, Typography } from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Filters } from './components/Filters'
import { LogInfoModal } from './components/LogInfoModal'
import { SessionLogsTable } from './components/SessionLogsTable'

const SessionLogs = () => {
  const [selectedLog, setSelectedLog] = useState(null)
  const [searchhKey, setSearchhKey] = useState('')
  const [sessionLogs, setSessionLogs] = useState([])

  const [filters, setFilters] = useState({
    StartTime: moment().add(-24, 'hours').format('YYYY-MM-DD'),
    EndTime: moment().format('YYYY-MM-DD'),
    ResoureceType: 'ALL_RESOURCES',
    QueryType: 'ALL_AUDITED',
    UserFilter: []
  })

  //@ts-ignore
  const search = useURLQuery()
  const history = useHistory()
  const { appView } = useAppView()
  //@ts-ignore
  const { sessionKey: sessionID } = useParams()
  const { selectDispatch } = useMultiDispatch([])

  const sessionTime = search.get('sessionTime')
  const resourceType = search.get('resourceType')
  // bollean value is true if the url params exists to fetch a specific log
  const isSingleLogFetchingMode = !!(sessionID && sessionTime && resourceType)

  //fetching status for url provided session log
  const [fetchingSelectedLog, setFetchingSelectedLog] = useState(isSingleLogFetchingMode)
  // if we need to fetch a single log, we disable fetching all logs by default
  const [isFetching, setIsFetching] = useState(!isSingleLogFetchingMode)

  /**
   *
   * @param {Partial<typeof filters>} v
   */
  const handleFilterChange = (v = {}) => {
    setFilters((s) => ({ ...s, ...v }))
  }
  const initiallyFetched = useRef(false)

  /**
   *
   */
  const getAndPopulateSessionLogs = async (filterData) => {
    setIsFetching(true)
    try {
      const data = []
      const AUDITS = FilterMaps[filterData.QueryType]
      const RSRCS = FilterMaps[filterData.ResoureceType]
      for (let i = 0; i < AUDITS.length; i++) {
        const auditType = AUDITS[i]
        for (let j = 0; j < RSRCS.length; j++) {
          const rsrcType = RSRCS[j]
          const logs = await fetchSessionLogs({
            ...filterData,
            ResoureceType: rsrcType,
            QueryType: auditType,
            UserFilter: { Elems: filterData.UserFilter || [] }
          })
          data.push(...logs.SessionResponses)
        }
      }

      const orgFilteredData = data.filter((log) => {
        const parsed = parseLogSessionData(log)
        //Check if the logs are from current org
        const logIsOrg = parsed.some((p) => {
          return p && p.Org === getCurrentOrg()
        })
        return logIsOrg
      })

      setSessionLogs(orgFilteredData)
    } catch (error) {}
    setIsFetching(false)
    return
  }

  // Dont fetch all logs if single log needs to be fetched
  if (!initiallyFetched.current && !isSingleLogFetchingMode) {
    //Fetch initial logs
    getAndPopulateSessionLogs({
      StartTime: moment().add(-24, 'hours'),
      EndTime: moment(),
      ResoureceType: 'ALL_RESOURCES',
      QueryType: 'ALL_AUDITED'
    })
    initiallyFetched.current = true
  }

  const refreshLogs = () => {
    getAndPopulateSessionLogs({
      ...filters,
      StartTime: new Date(filters.StartTime),
      EndTime:
        filters.EndTime === moment().format('YYYY-MM-DD') ? new Date() : new Date(filters.EndTime)
    })
  }

  /**
   * Fetch specific log with data from url
   * @returns
   */
  // https://localhost:3001/ui/admin/session-logs/6eecbc39fe1f03cda4ead57534e36a339126ddacdf21dd13ecdaaa6fc4ddcd14?resourceType=SSH&sessionTime=2024-01-19T10:37:01.722553687Z
  const getLogFromRouteParam = async () => {
    if (!sessionTime || !resourceType || !sessionID) return
    setFetchingSelectedLog(true)
    const logs = await fetchSessionLogs({
      StartTime: new Date(sessionTime),
      //@ts-ignore
      ResoureceType: resourceType,
      QueryType: 'All',
      UserFilter: { Elems: [sessionID] }
    })
    const s = logs.SessionResponses[0]
    setFetchingSelectedLog(false)
    return s
  }

  const getSelectedLog = async () => {
    if (!sessionID) return
    const sessionTime = search.get('sessionTime')
    const resourceType = search.get('resourceType')
    if (sessionTime && resourceType) return await getLogFromRouteParam()
    return _.find(sessionLogs, { SessionKey: sessionID })
  }

  const handleAuditSuccess = () => refreshLogs()

  useHTMLBodyScrollbar(selectedLog)

  useEffect(() => {
    const fn = async () => {
      const l = await getSelectedLog()
      if (l) setSelectedLog(l)
    }
    fn()
  }, [sessionLogs, sessionID])

  useEffect(() => {
    selectDispatch(['deviceList', 'serverList', 'databases', 'kubeClusters'])
  }, [])

  return (
    <div>
      <div className='mb-4 flex justify-between items-center'>
        <Typography className='mb-4' variant='h2'>
          Session Logs
        </Typography>
      </div>
      <Filters
        filters={filters}
        onFilterChange={handleFilterChange}
        onApplyClick={getAndPopulateSessionLogs}
        searchhKey={searchhKey}
        setSearchhKey={setSearchhKey}
      />
      {!isFetching && (
        <div className='mt-6'>
          <SessionLogsTable
            searchKey={searchhKey}
            onLogClick={(log) => {
              setSelectedLog(log)
            }}
            sessions={sessionLogs}
          />
        </div>
      )}
      {isFetching && (
        <div className='h-[400px] flex justify-center items-center'>
          <LoadingModal message='Fetching Logs' shadow={false} />
        </div>
      )}
      {fetchingSelectedLog && (
        <LoadingFeedback
          shrinked
          loading
          message={` Fetching Log of ${moment(sessionTime).format('MMMM Do YYYY, h:mm A')} `}
          caption='Please wait'
          shadow={false}
        />
      )}
      {selectedLog && (
        <LogInfoModal
          onAuditSuccess={() => {
            handleAuditSuccess()
            history.push(`/${appView}/session-logs`)
          }}
          onCancel={() => {
            setSelectedLog(null)
            history.push(`/${appView}/session-logs`)
          }}
          log={selectedLog}
        />
      )}
    </div>
  )
}

const FilterMaps = {
  ALL_AUDITED: ['All'],
  Audited: ['Audited'],
  NonAudited: ['NonAudited'],
  ALL_RESOURCES: ['SSH', 'DB', 'K8', 'ECS', 'RDP'],
  SSH: ['SSH'],
  DB: ['DB'],
  K8: ['K8'],
  ECS: ['ECS'],
  RDP: ['RDP']
}

export { SessionLogs }
