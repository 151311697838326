import { Box, Button, makeStyles } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
  footer: {
    borderTopColor: theme.palette.divider,
    borderTopWidth: '1px',
    borderTopStyle: 'solid'
  }
}))
const Footer = ({ closeModal }) => {
  const classes = useStyle()
  return (
    <Box height='5.125rem' className={classes.footer} display='flex' justifyContent='flex-end' pt={1} px={1.5} pb={1.5}>
      <Button variant='contained' color='primary' onClick={closeModal}>Ok</Button>
    </Box>
  )
}

export default Footer
