import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getFriendlyName } from 'Utils/FriendlyName'
import { LoadingFeedback, SearchInput } from 'V2Components'
import { addUserToAdminGroup, createUser, createUserPreference } from 'features/users'
import { Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { AddUsersQueue } from './components/AddUsersQueue/AddUsersQueue'
import { Header } from './components/Header'
import { UserInfoForm } from './components/UserInfoForm'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { useHistory } from 'react-router'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'

function AddUsers() {
  const [isCreating, setIsCreating] = useState(false)
  const [userCreateMessage, setUserCreateMessage] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [toAddUsers, setToAddUsers] = useState([])
  const [editUser, setEditUser] = useState({ id: '', fName: '', lName: '', email: '', isAdmin: false })

  const { slices } = useMultiSlice(['userList', 'groupList'])
  const canGoBack = useCanGoBack()
  const history = useHistory()

  const goBack = () => {
    if (canGoBack) history.goBack()
    else history.push('/admin/users')
  }

  const handleUserAddToQueue = ({ fName, lName, email, isAdmin }) => {
    if (editUser.id) {
      //* Check if edit  was in progress
      setToAddUsers((s) =>
        s.map((e) => {
          if (e.id === editUser.id) {
            return {
              ...e,
              fName,
              lName,
              email,
              isAdmin
            }
          }
          return e
        })
      )
    } else {
      setToAddUsers((s) => [
        {
          id: getFriendlyName(),
          fName,
          lName,
          email,
          isAdmin
        },
        ...s
      ])
    }
    //* Reset the edit user data
    setEditUser({ id: '', fName: '', lName: '', email: '', isAdmin: false })
  }

  const handleUsersCreate = async () => {
    try {
      setIsCreating(true)
      /**
       * STEP1: Create users
       */
      setUserCreateMessage('Please wait..')
      const createUsersApiCalls = toAddUsers.map(
        async ({ fName, lName, email }) => await handleUserCreate({ firstName: fName, emailID: email, lastName: lName })
      )
      const createdUsers = await Promise.all(createUsersApiCalls)
      /**
       * Push the created users to slice
       */
      createdUsers.forEach(pushToSlice)
      /**
       * STEP 2: Of all the created users, create a user preference object for each of them
       */
      setUserCreateMessage('Creating user preferences..')
      const preferencesApiCalls = createdUsers.map(async (user) => await createUserPreference(user))
      await Promise.all(preferencesApiCalls)
      /**
       * STEP 3: Create admin users which is, add admin group to user's Groups
       *
       * Make a list of to be admin users
       */

      const toBeAdminUsers = toAddUsers.reduce((prev, e) => {
        if (e.isAdmin) return [...prev, e.email]
        return prev
      }, [])
      setUserCreateMessage('Adding users to admin group..')
      /**
       * Get the tobe admin users object
       */
      const adminUsers = createdUsers.reduce((prev, u) => {
        if (toBeAdminUsers.includes(u.ObjectMeta.Name)) return [...prev, u]
        return prev
      }, [])
      /**
       * Get the admin group - for ID
       */
      const adminGroup = _.find(slices.groupList, { ObjectMeta: { Name: 'admin' } })
      const adminApiCalls = adminUsers.map(async (user) => await addUserToAdminGroup(adminGroup, user))
      /**
       * Make all to be admin users -> admins
       */
      const updatedAdminUsers = await Promise.all(adminApiCalls)
      /**
       * Update the user's slice data to include admin group
       */
      updatedAdminUsers.forEach(updateSliceData)
      enqueueNotification('Users have been created successfully!', 'info')
      goBack()
    } catch (error) {
    } finally {
      setIsCreating(false)
    }
  }

  const handleUserDelete = (user) => {
    setToAddUsers((s) => s.filter((e) => e.id !== user.id))
    //* If the user who was deleted, is the one getting edited, then remove from editUser
    if (editUser.id === user.id) setEditUser({ ...editUser, id: '' })
  }

  const handleUserEdit = (user) => setEditUser(user)

  const handleUserIsAdminChange = (user, isAdmin) => {
    setToAddUsers((s) =>
      s.map((e) => {
        if (e.id === user.id) return { ...user, isAdmin }
        return e
      })
    )
  }

  /**
   * Get the emails of in-queue users and already registered users
   * @returns {Object.<string, 'queue'  | 'registered'>} A object with keys of lowercase email of registered and in queue users
   */
  const getTakenEmailsObject = () => {
    const obj = {}
    toAddUsers.forEach((e) => {
      obj[e.email.toLowerCase()] = 'queue'
    })
    slices.userList.forEach((e) => {
      obj[e.Spec.EmailID.toLowerCase()] = 'registered'
    })
    return { ...obj }
  }

  useHTMLBodyScrollbar(true)

  // Boolean for when the form is in edit mode
  const isEditMode = !!editUser.id

  return (
    <div className="bg-white">
      <Header onComplete={handleUsersCreate} disableAction={!toAddUsers.length || isEditMode} onCancel={goBack} />
      <div className="flex mt-6 gap-4">
        <div className="w-1/2 border-r pr-4 border-[#D8DDE4]">
          <Typography variant="h4-regular">User Information</Typography>
          <div className="mb-12">
            <UserInfoForm
              isAdminUser={editUser.isAdmin}
              isEditMode={isEditMode}
              firstName={editUser.fName}
              lastName={editUser.lName}
              emailID={editUser.email}
              key={editUser.id}
              onSubmit={handleUserAddToQueue}
              alreadyTakenEmailsObj={getTakenEmailsObject()}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex justify-between items-center">
            <Typography variant="h4-regular">Users Queue</Typography>
            <SearchInput sx={{ width: '275px' }} searchKey={searchKey} onChange={setSearchKey} />
          </div>
          <div className="overflow-auto">
            <AddUsersQueue
              searchKey={searchKey}
              onIsAdminChange={handleUserIsAdminChange}
              editingUserID={editUser.id}
              users={toAddUsers}
              onDeleteClick={handleUserDelete}
              onEditClick={handleUserEdit}
            />
          </div>
        </div>
      </div>
      <LoadingFeedback loading={isCreating} message="Creating Users" caption={userCreateMessage} />
    </div>
  )
}

const handleUserCreate = async ({ firstName, lastName, emailID }) => {
  const data = {
    ObjectMeta: {
      Name: emailID
    },
    Spec: {
      FullName: `${firstName} ${lastName}`,
      FirstName: firstName,
      LastName: lastName,
      EmailID: emailID,
      Active: true
    }
  }
  return await createUser(data)
}

export { AddUsers }
