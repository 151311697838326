import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useGroupDetailsContext, useGroupRsrcAccess } from 'features/groups'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { getPolicyAttachedIAMActions, getPolicyResourcesRefs } from 'features/policy'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { useEffect } from 'react'

export function useAccessSummaryTab() {
  const { group } = useGroupDetailsContext()
  const { getGroupIssuedPolicys } = useGroupRsrcAccess()

  const policys = getGroupIssuedPolicys(group)

  const { getObjectRef, dispatchThunks } = useMultiSlice([
    'policyList',
    'iamActions',
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames
  ])

  const policyRsrcRefMap = policys.reduce((map, policy) => {
    map[createRsrcKey(policy)] = getPolicyResourcesRefs(policy)
    return map
  }, {})

  /**
   * Get all iam actions associated to the resource
   * 
   ```
   {  
      'AwsResource+123': ['IAMAction+123', ...]
    }
   ```
   * @returns {object}
   */
  const getRsrcIAMActions = () => {
    return policys.reduce((prev, pac) => {
      const iamActionsMap = getPolicyAttachedIAMActions(pac)
      for (const key in iamActionsMap) {
        const actions = prev[key] || []
        actions.push(...iamActionsMap[key].map(createRsrcKey))
        prev[key] = [...new Set(actions)]
      }
      return prev
    }, {})
  }

  /**
   * Get attributes object for the resources
   * @returns {object}
   */
  const getAttributesObject = () => {
    const iamActionsMap = getRsrcIAMActions()
    const map = {}

    for (const key in iamActionsMap) {
      const actionsRef = iamActionsMap[key].map(reverseRsrcKey)
      map[key] = getObjectRef(actionsRef).map(getResourceName)
    }

    return map
  }

  const rsrcs = Object.values(policyRsrcRefMap).reduce((prev, rsrcRefs) => {
    return [...prev, ...getObjectRef(rsrcRefs)]
  }, [])

  useEffect(() => {
    dispatchThunks()
  }, [])

  return { attributesObject: getAttributesObject(), rsrcs, policys }

}
