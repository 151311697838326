import { ApiProvider } from 'Core'
import { useEffect, useState } from 'react'
import convert from 'xml-js'

export const useSAMLServers = () => {
  const [samlMetaData, setSamlMetaData] = useState({})
  const [ssoURI, setSsoURI] = useState('')
  const [issuerURI, setIssuerURI] = useState('')
  const [metadataURI, setMetadataURI] = useState('')
  const [domain, setDomain] = useState('')
  const [samlCertficate, setSamlCertficate] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fetchMetaData = async () => {
    try {
      setIsLoading(true)
      const response = await new ApiProvider('samlservers')
        .setInstance({ ObjectMeta: { Name: 'saml' } })
        .get()
      const result = convert.xml2js(response.data.SamlMetadata)
      const ssoData = result.elements[0].elements[0].elements.find(
        (e) => e.name === 'SingleSignOnService'
      )
      if (ssoData) setSsoURI(ssoData.attributes.Location)
      setSamlMetaData(result)
      setIssuerURI(`https://${response.data.Issuer}/metadata`)
      setMetadataURI(`https://${response.data.Issuer}/metadata`)
      setDomain(response.data.Issuer)
      let parsedCert = response.data.IssuerCertificate.replace('-----BEGIN CERTIFICATE-----', '')
      parsedCert = parsedCert.replace('-----END CERTIFICATE-----', '')
      setSamlCertficate(parsedCert.replace(/\s+/g, ''))
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchMetaData()
  }, [])

  return { samlMetaData, ssoURI, issuerURI, metadataURI, domain, isLoading, samlCertficate }
}
