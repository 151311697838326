import { faHourglassEnd } from '@fortawesome/pro-solid-svg-icons'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import {
  IANA_TIMEZONES,
  StartEndTimePicker,
  convertTZ,
  getSystemTimeZone
} from 'features/resources'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import moment from 'moment'
import { Button, Label, SelectDropDown, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const ExtendPolicyExpiry = ({ policy, onCancel, onSuccess }) => {
  const [startDate, setStartDate] = useState(policy.NotBefore)
  const [endDate, setEndDate] = useState(policy.NotAfter)
  const [timeZone, setTimeZone] = useState(getSystemTimeZone())

  const [isLoading, setIsLoading] = useState(false)

  const tzMomentDate = moment(convertTZ(endDate, timeZone))

  const isEndDateLessThanStartDate = moment(startDate).diff(endDate) > 0

  const getErrors = () => {
    if (isEndDateLessThanStartDate) return 'End date cannot be less than policy start date.'
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      const clone = structuredClone(policy)
      clone.NotAfter = tzMomentDate.utc()
      await reduxApiClient('pacpolicys').update(clone)
      enqueueNotification('Policy expiry date has been successfully updated.', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Error updating policy.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const errors = getErrors()

  useHTMLBodyScrollbar(true)

  return (
    <FullScreenContentModal>
      <div>
        <div className='flex justify-between items-center mb-4'>
          <Typography variant='h4-regular'>Extend Policy Expiry</Typography>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
        </div>
        <div className='-mb-4'>
          <Typography className='mb-2' variant='body-regular'>
            Select Timezone
          </Typography>
          <SelectDropDown
            menuItems={IANA_TIMEZONES.map((tz) => ({ label: tz, value: tz }))}
            onChange={(e) => setTimeZone(e.target.value)}
            value={timeZone}
          />
        </div>
        <div>
          <StartEndTimePicker
            hideEndDateLabel
            hideStartDatePicker
            sD={endDate} //start date is end date so that the picker will "add/extend" time for enddate
            eD={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          <div className='border border-[#d8dde4] my-4 inline-block pl-2 rounded-md'>
            <Typography className='flex gap-2 items-center' variant='body-regular'>
              New Expiry
              <Label
                iconButton={faHourglassEnd}
                text={tzMomentDate.format('MMMM Do YYYY, h:mm A')}
              />
            </Typography>
          </div>
          <Typography variant='body-regular'>
            {!isEndDateLessThanStartDate ? (
              <>
                The policy will expire, <strong>{tzMomentDate.fromNow()}</strong> from now.
              </>
            ) : (
              <>
                The end date is less than the start date hence, policy{' '}
                <strong>will be deleted.</strong>
              </>
            )}
          </Typography>
          <div className='flex gap-2 justify-end items-center mt-4'>
            {!_.isEmpty(errors) && <Label variant='warning' text={errors} />}
            <Button onClick={handleSave} disabled={!!errors} variant='primary'>
              Save
            </Button>
          </div>
        </div>
        <LoadingFeedback loading={isLoading} caption='Please wait' message='Updating Policy' />
      </div>
    </FullScreenContentModal>
  )
}

export { ExtendPolicyExpiry }
