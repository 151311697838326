import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { useUser } from 'Core/Hooks/useUser'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { BUNDLE_REQ_TYPE } from 'features/bundles/utils'
import { createRef, getResourceName, getRsrcIcon } from 'features/resources'
import { StartEndTimePicker } from 'features/resources'
import { reduxApiClient } from 'infra'
import { Button, Label, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const BundleRequestInfoModal = ({ bundle, onCancel, onSuccess }) => {
  const Icon = getRsrcIcon(bundle)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [comments, setComments] = useState('')
  const [error, setError] = useState({
    startDate: '',
    endDate: ''
  })

  const { user } = useUser()
  const getError = () => {
    if (error.endDate) return error.endDate
    if (error.startDate) return error.startDate
    return ''
  }

  const convertPolicySpecToApprovalSpec = async () => {
    try {
      setIsLoading(true)
      const reqType = bundle.ReqType || 'IAMAction'

      const objectMeta = {
        Name: `${bundle.GivenName}_${getResourceName(user)}_${getFriendlyName()}`
      }

      const newSpec = {
        Resources: { Resource: [] },
        Justification: comments,
        NotAfter: endDate,
        NotBefore: startDate,
        RequestedFor: {
          ObjectRef: [createRef(user)]
        }
      }

      const meta = {
        Status: 'New',
        GivenName: bundle.GivenName,
        BundleRef: createRef(bundle),
        Type: reqType
      }
      if (reqType === 'IAMAction') {
        newSpec.Resources.Resource = getIamRsrcsApprovalSpecFromBundle(bundle)
      } else if (reqType === 'Target') {
        newSpec.Resources.Resource = getTargetApprovalSpecFromBundle(bundle)
      } else if (reqType === BUNDLE_REQ_TYPE.Salesforce) {
        newSpec.Resources.Resource = getSalesforceApprovalSpecFromBundle(bundle)
      }

      const approvalReq = {
        ObjectMeta: objectMeta,
        Spec: newSpec,
        ...meta
      }
      await reduxApiClient('approvalreqs').create(approvalReq)
      enqueueNotification('Bundle access request created successfully!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Error creating bundle request.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    await convertPolicySpecToApprovalSpec()
  }

  useHTMLBodyScrollbar(true)
  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center'>
        <Typography className='flex gap-4 items-center' variant='h4-regular'>
          <Icon />
          {getResourceName(bundle)}
        </Typography>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
      </div>
      <div className='mt-8'>
        <StartEndTimePicker
          sD={startDate}
          eD={endDate}
          onError={setError}
          onStartDateChange={(d) => setStartDate(d)}
          onEndDateChange={(d) => setEndDate(d)}
        />
        <div className='mt-4'>
          <Typography variant='body-regular'>Comments</Typography>
          <TextInput
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            multiline
            rows={3}
            sx={{ width: '100%' }}
          />
        </div>
        <div className='flex justify-end mt-4 gap-2'>
          {getError() && <Label variant='warning' text={getError()} />}
          <Button onClick={handleSubmit} disabled={!!getError()} variant='primary'>
            Submit
          </Button>
        </div>
      </div>
      <LoadingFeedback
        loading={isLoading}
        message='Creating Approval Request for the Bundle'
        caption='Please wait..'
      />
    </FullScreenContentModal>
  )
}

const getSalesforceApprovalSpecFromBundle = (bundle) => {
  const resources = []

  for (const action in bundle.Spec.ActionMap) {
    const rules = bundle.Spec.ActionMap[action].PolicyRule
    rules.forEach((rule) => {
      resources.push({
        Target: rule.ObjectRef
      })
    })
  }

  return resources
}

const getTargetApprovalSpecFromBundle = (bundle) => {
  const resources = []

  for (const action in bundle.Spec.ActionMap) {
    const rules = bundle.Spec.ActionMap[action].PolicyRule
    rules.forEach((rule) => {
      const Principal = rule.Principal
      const Services = rule.Services
      resources.push({
        Target: rule.ObjectRef,
        Action: action,
        Principal: Principal,
        Services
      })
    })
  }

  return resources
}

const getIamRsrcsApprovalSpecFromBundle = (bundle) => {
  const resources = []
  const { AwsIAM, GcpIAM, AzureIAM } = bundle.Spec.ActionMap

  if (AwsIAM) {
    AwsIAM.PolicyRule.forEach((rule) => {
      resources.push({
        Target: rule.ObjectRef,
        Action: 'AwsIAM',
        Services: rule.Services
      })
    })
  }

  if (GcpIAM) {
    GcpIAM.PolicyRule.forEach((rule) => {
      resources.push({
        Target: rule.ObjectRef,
        Action: 'GcpIAM',
        Services: rule.Services
      })
    })
  }

  if (AzureIAM) {
    AzureIAM.PolicyRule.forEach((rule) => {
      resources.push({
        Target: rule.ObjectRef,
        Action: 'AzureIAM',
        Services: rule.Services
      })
    })
  }

  return resources
}

export { BundleRequestInfoModal }
