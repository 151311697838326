import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useIAMRoleAccess } from 'features/iamRoles'
import { PolicyEntitiesSummaryView } from 'features/iamRoles/'
import { reverseRsrcKey } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'

const AccessSummary = () => {
  const iamrole = useDataDetailsContext()
  const { getIAMRoleAttachedPolicys } = useIAMRoleAccess()
  const { getObjectRef } = useObjectRef(['policyList'])

  const getPolicys = () => {
    const policys = getIAMRoleAttachedPolicys(iamrole)
    return getObjectRef(policys.map(reverseRsrcKey))
  }

  const policys = getPolicys()

  return (
    <div>
      {policys.length ? (
        <PolicyEntitiesSummaryView policys={policys} />
      ) : (
        <Typography variant='body-regular'>IAM Role is not used for access.</Typography>
      )}
    </div>
  )
}

export { AccessSummary }
