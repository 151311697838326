import {
  BUNDLE_TYPE_ICON_TYPE,
  BundleRequestInfoModal,
  useBundlesUserAccess
} from 'features/bundles'
import { getResourceName, getRsrcIcon } from 'features/resources'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BundlesGridView } from '../../BundlesGridView'
import { Typography } from 'procyon-ui'
import useAppView from 'Core/Hooks/useAppView'
import { AdvancedSearch } from 'V2Components'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'

const UnGrantedBundles = () => {
  const [showBundleRequestModal, setShowBundleRequestModal] = useState(false)
  const [selectedBundle, setSelectedBundle] = useState(null)

  const [searchKey, setSearchKey] = useState('')

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const { name } = row

      const all = `${name}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  //@ts-ignore
  const { bundleType } = useParams()
  const history = useHistory()

  const { appView } = useAppView()

  const {
    bundleAccessMap: { ungranted }
  } = useBundlesUserAccess(bundleType.toUpperCase())

  const getBundlesData = () => {
    const data = []

    ungranted.forEach((bundle) => {
      data.push({
        name: getResourceName(bundle),
        Icon: getRsrcIcon(bundle),
        status: 'ungranted',
        iconType: BUNDLE_TYPE_ICON_TYPE[bundle.Type],
        onRequestClick() {
          setShowBundleRequestModal(true)
          setSelectedBundle(bundle)
        },
        onMoreInfoClick() {
          history.push(`/${appView}/bundles/${bundleType.toLowerCase()}/${bundle.ObjectMeta.Name}`)
        }
      })
    })

    return applySearchQuery(data)
  }

  const data = getBundlesData()

  return (
    <div>
      {ungranted.length === 0 && (
        <Typography variant='body-regular'>No un-granted bundles.</Typography>
      )}
      {ungranted.length > 0 && (
        <>
          <AdvancedSearch
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            filterSaveKey='bundles-ungranted'
          />
          <BundlesGridView data={data} />
        </>
      )}
      {showBundleRequestModal && selectedBundle && (
        <BundleRequestInfoModal
          bundle={selectedBundle}
          onCancel={() => setShowBundleRequestModal(false)}
          onSuccess={() => setShowBundleRequestModal(false)}
        />
      )}
    </div>
  )
}

export { UnGrantedBundles }
