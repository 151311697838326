import { faClose, faPen } from '@fortawesome/pro-solid-svg-icons'
import { getApplicationType } from 'features/applications'
import { createRsrcKey, getResourceName, getRsrcAccountRef, getRsrcIcon } from 'features/resources'
import { GenericResourceRow, IconButton, Label, TextInput } from 'procyon-ui'
import React, { useState } from 'react'

const Application = ({ rsrc: app, getObjectRef, rsrcAttributesSpec, onRsrcAttriputesUpdate }) => {
  const Icon = getRsrcIcon(app)

  return (
    <div className='w-[100%] mb-2' key={createRsrcKey(app)}>
      <GenericResourceRow
        caption={getApplicationType(app)}
        accountName=''
        className='w-[100%]'
        children1={<div className='flex gap-2 w-[100%] justify-end items-center'></div>}
        children2={<div className='flex items-center justify-center'></div>}
        region=''
        resourceIcon={<Icon className='rounded' />}
        title={getResourceName(app)}
      />
    </div>
  )
}

export { Application }
