import moment from 'moment'

export const consturctTillDateRange = (value) => {
  const [amount, unit] = value.split(' ')
  const lt = moment().format()
  const gte = moment().subtract(amount, unit).format()
  const range = { range: { '@timestamp': { gte, lt } } }
  return range
}
export const consturctDateRange = (startTime, endTime) => {
  console.log('consturctDateRange end start', endTime,
    startTime)
  const lt = moment(endTime).format()
  const gte = moment(startTime).format()
  console.log('consturctDateRange lt gte', lt,
    gte)
  const range = { range: { '@timestamp': { gte, lt } } }
  return range
}
/**
 * Generates query object for elastic search
 * @param {string | boolean} tillDate till which date data should be fetched
 * @param {Array<Object>} musts must conditions for elastic search query
 * @param {{start:Date,end:Date}|false} timeRange
 * @returns {Object} elastic search query
 */
export const constructQuery = (tillDate = false, musts = [], timeRange = false) => {
  const query = {
    size: 10000,
    query: {
      bool: {
        must: [
          ...musts
        ]
      }
    }
  }
  if (tillDate && tillDate !== '-') {
    query.query.bool.must.push(consturctTillDateRange(tillDate))
  }
  if (timeRange) {
    query.query.bool.must.push(consturctDateRange(timeRange.start, timeRange.end))
  }
  return query
}

/**
 * Generates query object for elastic search
 * @param {string | boolean} tillDate till which date data should be fetched
 * @param {{start:Date,end:Date}|false} timeRange
 * @returns {Object} elastic search query
 */

export const constructTimeAndDateRange = (tillDate = false, timeRange = false) => {
  const dateTimeArray = []

  if(tillDate && tillDate !== '-') {
    dateTimeArray.push(consturctTillDateRange(tillDate))
   }

  if(timeRange) {
    dateTimeArray.push(consturctDateRange(timeRange.start, timeRange.end))
  }

  return dateTimeArray
}
