import { faServer } from '@fortawesome/free-solid-svg-icons'
import { faHelmetSafety } from '@fortawesome/pro-light-svg-icons'
import {
  faCodePullRequestClosed,
  faDatabase,
  faFileCertificate,
  faKey,
  faLaptopMobile,
  faLayerGroup,
  faWavePulse
} from '@fortawesome/pro-regular-svg-icons'
import { faBrowser, faCubes, faGrid, faSignalStrong } from '@fortawesome/pro-solid-svg-icons'

export const getUserViewRoutes = ({ history, showSessionLogs }) => {
  const views = [
    {
      itemType: 'section',
      label: 'directory'
    },
    {
      icon: faCubes,
      label: 'Bundles',
      variant: 'primary',
      onClick() {
        history.push('/user/bundles')
      },
      _meta: {
        itemActiveRoutes: '/user/bundles'
      }
    },
    {
      icon: faKey,
      label: 'Service Accounts',
      variant: 'primary',
      onClick() {
        history.push('/user/service-accounts/aws')
      },
      _meta: {
        itemActiveRoutes: '/user/service-accounts/aws'
      }
    },
    {
      itemType: 'section',
      label: 'cloud'
    },
    {
      icon: faLayerGroup,
      label: 'Targets',
      variant: 'primary',
      onClick() {
        history.push('/user/targets')
      },
      _meta: {
        itemActiveRoutes: '/user/targets'
      }
    },
    {
      icon: faBrowser,
      label: 'Resources',
      variant: 'primary',
      onClick() {
        history.push('/user/resources')
      },
      _meta: {
        itemActiveRoutes: '/user/resources'
      }
    },
    {
      icon: faGrid,
      label: 'applications',
      variant: 'primary',
      onClick() {
        history.push('/user/applications')
      },
      _meta: {
        itemActiveRoutes: '/user/applications'
      }
    },
    {
      icon: faDatabase,
      label: 'Data Cloud',
      variant: 'primary',
      onClick() {
        history.push('/user/datacloud')
      },
      _meta: {
        itemActiveRoutes: '/user/datacloud'
      }
    },
    {
      itemType: 'section',
      label: 'Access Management'
    },
    {
      icon: faHelmetSafety,
      label: 'IAM Roles',
      variant: 'primary',
      onClick() {
        history.push('/user/iam-roles/aws')
      },
      _meta: {
        itemActiveRoutes: '/user/iam-roles'
      }
    },
    {
      icon: faCodePullRequestClosed,
      label: 'Access Requests',
      variant: 'primary',
      onClick() {
        history.push('/user/access-requests')
      },
      _meta: {
        itemActiveRoutes: '/user/access-requests'
      }
    },

    {
      icon: faFileCertificate,
      label: 'Policies',
      variant: 'primary',
      onClick() {
        history.push('/user/policys')
      },
      _meta: {
        itemActiveRoutes: '/user/policys'
      }
    },
    {
      itemType: 'section',
      label: 'devices'
    },
    {
      icon: faLaptopMobile,
      label: 'devices',
      variant: 'primary',
      onClick() {
        history.push('/user/devices')
      },
      _meta: {
        itemActiveRoutes: '/user/devices'
      }
    },
    {
      icon: faServer,
      label: 'AD Domain Controllers',
      variant: 'primary',
      onClick() {
        history.push('/user/addomaincontrollers')
      },
      _meta: {
        itemActiveRoutes: '/user/addomaincontrollers'
      }
    },
    {
      itemType: 'section',
      label: 'Activity'
    },
    {
      icon: faWavePulse,
      label: 'activity logs',
      variant: 'primary',
      onClick() {
        history.push('/user/activity-logs')
      },
      _meta: {
        itemActiveRoutes: '/user/activity-logs'
      }
    }
  ]

  if (showSessionLogs) {
    views.push({
      icon: faSignalStrong,
      label: 'Session Logs',
      variant: 'primary',
      onClick() {
        history.push('/user/session-logs')
      },
      _meta: {
        itemActiveRoutes: '/user/session-logs'
      }
    })
  }

  return views
}
