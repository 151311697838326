import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { DataTable, RiskIndicator, TargetIcon } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'

const useslices = createDataSelectorHook(['iamFederatedUsers'])
const FederatedUserTable = ({ searchQuery }) => {
  const { slices } = useslices()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [tableRows, setTableRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0
  })

  useEffect(() => {
    if (!slices || slices.iamFederatedUsers.length === 0) {
      setIsLoading(true)
    }

    if (slices?.iamFederatedUsers?.length > 0 && tableRows.length === 0) {
      const rows = slices.iamFederatedUsers
        .slice()
        .sort((a, b) => {
          const dateA = moment(a.ObjectMeta.CreatedAt)
          const dateB = moment(b.ObjectMeta.CreatedAt)
          if (dateA.isBefore(dateB)) return 1
          if (dateA.isAfter(dateB)) return -1
        })
        .map((obj, index) => {
          let transformedObj = {
            id: index + 1,
            platform: 'AWS',
            name: obj.Spec.UserName,
            lastused: '',
            riskResources: obj.Spec.RiskScore,
            accountRef: obj.ObjectMeta.ID
          }
          return transformedObj
        })

      setTableRows(rows)
      setIsLoading(false)
    }
  }, [slices])

  const handleGraphRedirection = (accountRef) => {
    history.push(`/admin/idaanalyzer/idagraph/IamFederatedUser/${encodeURIComponent(accountRef)}`)
  }

  const getRiskLevel = (riskValue) => {
    if (riskValue >= 0 && riskValue <= 3.9) {
      return 'low'
    } else if (riskValue >= 4 && riskValue <= 6.9) {
      return 'medium'
    } else if (riskValue >= 7 && riskValue <= 8.9) {
      return 'high'
    } else if (riskValue >= 9 && riskValue <= 10) {
      return 'critical'
    } else {
      return 'none'
    }
  }

  const filteredRows = tableRows.filter((row) => {
    const search = searchQuery?.toLowerCase()
    if (search) {
      return row.name.toLowerCase().includes(search)
    } else {
      return tableRows
    }
  })

  return (
    <DataTable
      autoHeight={true}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          renderCell: (param) =>
            isLoading ? (
              <ShimmerTableRow />
            ) : (
              <>
                <span className='mr-2'>
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <p className='font-medium'>{param.value}</p>
              </>
            ),
          width: 500
        },
        {
          field: 'platforms',
          headerName: 'Platforms',
          renderCell: (param) =>
            isLoading ? <ShimmerTableRow /> : <TargetIcon width='30px' type='AWS' />,
          width: 350
        },
        {
          field: 'riskResources',
          headerName: 'Risk Resources',
          renderCell: (params) =>
            isLoading ? (
              <ShimmerTableRow />
            ) : (
              <RiskIndicator variant={getRiskLevel(params.value)} />
            ),
          width: 359
        },
        {
          field: 'graph',
          headerName: 'Graph',
          renderCell: (param) =>
            isLoading ? (
              <ShimmerTableRow />
            ) : (
              <div className='bg-white shadow-md rounded-full w-[35px] h-[35px] flex items-center justify-center'>
                <img
                  onClick={() => handleGraphRedirection(param.row.accountRef)}
                  src='img/icons/organization-chart.png'
                  alt=''
                  className='w-5 cursor-pointer'
                />
              </div>
            ),
          width: 70
        }
      ]}
      rows={
        isLoading
          ? Array(1).fill({ id: '', name: '', createdate: '', platforms: '', riskResources: '' })
          : filteredRows
      }
    />
  )
}

export { FederatedUserTable }
