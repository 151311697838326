import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { randomStringGenerator } from 'Utils/PureHelperFuctions'

import * as am4charts from '@amcharts/amcharts4/charts'
import { am4core, PropTypes, Theme } from 'Core'
import { makeStyles } from '@material-ui/core'
const useStyle = makeStyles(theme => ({
  theChart: {
    border: '10px solid red'
    // transform: 'translate(-10px, 25px)'

  },
  cursor: {
    // border: '10px solid red',
    // transform: 'translate(-10px, 25px)'
  },
  baseGrid: {
    width: '20px',
    transform: 'scaleX(1.2)'
  },
  mainGrid: {
    '& path': {
      fill: 'red',
      transform: 'scaleX(1.2)'
    }
  }
}))
const BarChart = ({ borderRadius = 2, data, seriesMeta, xAxis, yAxis, zoomAxis, chartStyles, chartClassName }) => {
  const classes = useStyle()
  /** Each chart must have a div unique id */
  const divId = useRef(randomStringGenerator(16))
  const chart = useRef(null)
  /** Create category axis */
  const createXAxis = (chart) => {
    const axis = new am4charts.CategoryAxis()
    const categoryAxis = chart.xAxes.push(axis)
    categoryAxis.dataFields.category = xAxis.name
    /** Grid styles */
    categoryAxis.renderer.grid.template.strokeWidth = 0
    const label = categoryAxis.renderer.labels.template
    label.wrap = true
    label.maxWidth = 70
    // categoryAxis.renderer.minGridDistance = 100
    /** Label Styles */
    categoryAxis.renderer.labels.template.strokeWidth = '0.1'
    categoryAxis.renderer.labels.template.fontSize = '11px'
    // categoryAxis.renderer.labels.template.dx = 30
  }

  /** Create value axis  */
  const createYAxis = (chart) => {
    const axis = new am4charts.ValueAxis()
    // axis.renderer.baseGrid.w
    const valueAxis = chart.yAxes.push(axis)
    valueAxis.title.text = yAxis.label
    /** Label Styles */
    valueAxis.renderer.labels.template.fill = Theme.palette.text.disabled
    valueAxis.renderer.labels.template.fontSize = '12px'
    valueAxis.renderer.labels.template.contentAlign = 'right'
    // valueAxis.renderer.labels.template.dx = -10
    valueAxis.renderer.grid.template.dx = -38
    valueAxis.renderer.baseGrid.dx = -38
    valueAxis.renderer.labels.template.dy = -8
    /** Grid styles */
    axis.renderer.gridContainer.element.addClass(classes.mainGrid)
    axis.renderer.baseGrid.element.addClass(classes.baseGrid)

    valueAxis.renderer.grid.template.stroke = Theme.palette.divider
    valueAxis.renderer.baseGrid.stroke = Theme.palette.divider
  }

  /** Pushes new series with bullets to chart */
  const createSeries = (chart, meta) => {
    /** Plot the series only if its active */
    if (meta.active) {
      // Setup Chart Lines
      const columns = new am4charts.ColumnSeries()
      const series = chart.series.push(columns)

      series.dataFields.valueY = meta.name
      series.dataFields.categoryX = xAxis.name
      // columns.columns.template.dx = 40
      series.name = meta.label
      series.stroke = meta.color
      series.fill = meta.color
      /** Apply border radius to bars */
      if (borderRadius) {
        series.columns.template.column.adapter.add('cornerRadiusTopRight', () => borderRadius)
        series.columns.template.column.adapter.add('cornerRadiusTopLeft', () => borderRadius)
        series.columns.template.column.adapter.add('cornerRadiusBottomRight', () => borderRadius)
        series.columns.template.column.adapter.add('cornerRadiusBottomLeft', () => borderRadius)
      }
      series.columns.template.width = am4core.percent(60)

      series.tooltipText = meta.hoverText
      // columns.tooltip.dx = -10
    }
  }

  /** Setup Chart Cursor */
  const setuChartCursor = (chart) => {
    const cursor = new am4charts.XYCursor()
    // cursor.dx = 25
    // cursor.ex = -100
    cursor.element.addClass(classes.cursor)
    // cursor.dy = -20
    chart.cursor = cursor
    chart.cursor.behavior = zoomAxis
  }

  /**
   * Initate chart on data change
   * Handle creation of axises and serises
  **/
  const initChart = () => {
    console.log('re-renders barchart')
    /** create chart instance */
    const chartObject = am4core.create(divId.current, am4charts.XYChart)
    chartObject.element.addClass(classes.theChart)
    chart.current = chartObject

    /** Add data to chart */
    chart.current.data = data
    /** setup chart.current Axises */
    createXAxis(chart.current)
    createYAxis(chart.current)

    /** Generate lines & bullets for each series */
    seriesMeta.forEach(meta => createSeries(chart.current, meta))

    setuChartCursor(chart.current)
  }
  /** reset chart on data change */
  useLayoutEffect(() => {
    initChart()
    return () => {
      if (chart.current) { chart.current.dispose() }
    }
  }, [data, seriesMeta, xAxis, yAxis])
  return (
    <div className={chartClassName} style={chartStyles} id={divId.current} />
  )
}
/**  Reusable type for meta and axises props */
const axisType = { name: PropTypes.string, label: PropTypes.string }
const metaType = {
  ...axisType,
  hoverText: PropTypes.string,
  color: PropTypes.string
}
/** Setup propTypes */
BarChart.propTypes = {
  xAxis: PropTypes.shape(axisType),
  yAxis: PropTypes.shape(axisType),
  data: PropTypes.array,
  zoomAxis: PropTypes.string,
  seriesMeta: PropTypes.arrayOf(PropTypes.shape(metaType)),
  chartClassName: PropTypes.string,
  chartStyles: PropTypes.object
}
BarChart.defaultProps = {
  zoomAxis: 'zoomX'
}
export default React.memo(BarChart)
