import { faRadar, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { openURLSafely } from 'Utils/PureHelperFuctions'
import { getApplicationSignInURL, isReadOnlyApp } from 'features/applications'
import { ErrorModal } from 'features/policy'
import {
  SummaryHeader,
  getResourceName,
  getRsrcIcon,
  useAccessCartProvider
} from 'features/resources'
import { Button, IconButton, Label } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function Header({
  app,
  showSignIn,
  onEdit,
  onDelete,
  onAccessClick,
  disableAccessBtn = false,
  onSync,
  isSyncDisabled,
  setShowCreateBundleModal,
  showScanning
}) {
  const { appView } = useAppView()
  const { rdpurl, isAgentlessMode } = useUser()
  const history = useHistory()
  const { clearCartItems } = useAccessCartProvider()
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleBreadcrumbClick = (link) => {
    clearCartItems()
    if (link) {
      history.push(link)
    }
  }

  const onErrorClick = () => {
    setShowErrorModal(true)
  }

  const handleSignInClick = () => {
    let url = getApplicationSignInURL(app)
    console.log(url)
    if (isAgentlessMode) {
      url = url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
      url && openURLSafely(url)
    } else {
      url && openURLSafely(url)
    }
  }

  // hide request button if github and user view
  const getIsReadOnly = (app) => {
    if (
      (app?.Spec?.AppType === 'github' && appView === 'user') ||
      app.Spec.AppType === 'salesforce'
    ) {
      return false
    } else {
      return !isReadOnlyApp(app)
    }
  }


  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon(app)}
        rsrcName={getResourceName(app)}
        actions={
          <div className='flex gap-4'>
            {app.Spec.AppType === 'salesforce' &&
              app.Spec.SalesforceAppConfig.Status.Status == 'Fail' && (
                <IconButton
                  icon={faTriangleExclamation}
                  onClick={onErrorClick}
                  size='sm'
                  variant='grayRed'
                />
              )}
            {app.Spec.AppType === 'salesforce' && appView === 'admin' && (
              <Button size='md' variant='grayBlue' onClick={setShowCreateBundleModal}>
                Create Permission Group
              </Button>
            )}

            {showScanning && (
              <Label iconButton={faRadar} variant='success' size='lg' text='Sync In Progress' />
            )}

            {(app.Spec.AppType === 'github' ||
              (app.Spec.AppType === 'salesforce' && !showScanning)) &&
              appView === 'admin' && (
                <Button size='md' variant='grayBlue' onClick={onSync} disabled={isSyncDisabled}>
                  Sync
                </Button>
              )}
            {getIsReadOnly(app) && (
              <Button
                disabled={disableAccessBtn}
                onClick={onAccessClick}
                size='md'
                variant='grayBlue'
              >
                {appView === 'admin' ? 'Create Policy' : 'Request'}
              </Button>
            )}
            {showSignIn && (
              <Button onClick={handleSignInClick} size='md' variant='grayBlue'>
                Sign In
              </Button>
            )}
            {!isReadOnlyApp(app) && appView === 'admin' && (
              <Button onClick={onEdit} size='md' variant='gray'>
                Edit
              </Button>
            )}
            {!isReadOnlyApp(app) && appView === 'admin' && (
              <Button onClick={onDelete} size='md' variant='grayRed'>
                Delete
              </Button>
            )}
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Resource Catalog',
            link: `/${appView}/applications`,
            onClick: () => handleBreadcrumbClick(`/${appView}/applications`)
          },
          {
            label: getResourceName(app),
            link: null,
            onClick: () => handleBreadcrumbClick(null)
          }
        ]}
      />
      {showErrorModal && (
        <ErrorModal
          message={app.Spec?.SalesforceAppConfig?.Status.Error}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Error'
        />
      )}
    </div>
  )
}

export { Header }
