import { SimpleDropDown } from 'procyon-ui'
import React from 'react'

function AccountsSortOptions({ activeOption, onActiveOptionChange, options = [] }) {
  const getOptions = () => {
    const opts = [
      {
        label: 'Show: All',
        value: 'all'
      }
    ]

    options.forEach((o) => {
      opts.push({
        label: `Show: ${o}`,
        value: o
      })
    })

    return opts
  }

  return (
    <SimpleDropDown
      menuItems={getOptions()}
      onChange={(e) => onActiveOptionChange(e.target.value)}
      value={activeOption}
    />
  )
}

export { AccountsSortOptions }
