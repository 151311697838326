import { getActivityLog, getElastic } from 'API_LAYER/NetworkRequest'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserInfo } from 'Utils/Helpers'
import { constructQuery, constructTimeAndDateRange } from 'Utils/LogsHelpers'
import { useHistory } from 'react-router-dom';



const IndexMaps = {
  'event-log': {
    MessageMatchPhrases: ['Device onboarded by token', 'User assumed role'],
    UserNameMatchKey: 'User.Name',
    TenantMatchKey: 'User.Tenant',
    DeviceMatchKey: 'Device.Name',
    ResourceMatchKey: 'Resources.Name'
  },
  'proxy-audit-log': {
    MessageMatchPhrases: ['SSH', 'Received HTTP request'],
    UserNameMatchKey: 'Data.User',
    TenantMatchKey: 'Data.Tenant',
    DeviceMatchKey: 'Data.Device',
    ResourceMatchKey: 'Data.ServerName'
  },
  'proxy-event-log': {
    MessageMatchPhrases: ['Device connected to proxy'],
    UserNameMatchKey: 'Data.User.Name',
    TenantMatchKey: 'Data.User.Tenant',
    DeviceMatchKey: 'Data.Device.Name',
    ResourceMatchKey: null // Resource doesnt exist for these logs
  },
  'audit-log': {
    MessageMatchPhrases: ['HTTP '],
    UserNameMatchKey: 'User.Name',
    TenantMatchKey: 'User.Tenant',
    DeviceMatchKey: 'Device.Name',
    ResourceMatchKey: null // Resource doesnt exist for these logs
  }
}

function getMessageClausesMap () {
  const _clauses = {}
  for (const key in IndexMaps) {
    const element = IndexMaps[key]
    const phrases = []
    element.MessageMatchPhrases.forEach((message) => {
      phrases.push({
        match_phrase: {
          Message: message
        }
      })
    })
    _clauses[key] = phrases
  }
  return _clauses
}

const IndexMessageClausesMap = getMessageClausesMap()

const getKeyIndexMap = (matchKey = '', value) => {
  const _map = {}
  if (typeof value === 'undefined') return _map
  for (const key in IndexMaps) {
    const tenantShouldClauses = []
    const element = IndexMaps[key]
    if (element[matchKey]) {
      tenantShouldClauses.push({
        match_phrase: {
          [element[matchKey]]: value
        }
      })
    }
    _map[key] = tenantShouldClauses
  }
  return _map
}

const getTenantIndexMap = (tenant) => getKeyIndexMap('TenantMatchKey', tenant)
const getUserNameIndexMap = (UserName) => getKeyIndexMap('UserNameMatchKey', UserName)
const getDeviceIndexMap = (deviceName) => getKeyIndexMap('DeviceMatchKey', deviceName)
const getResourcesIndexMap = (resourceValue) => getKeyIndexMap('ResourceMatchKey', resourceValue)

/**
 * @type { { userName?: string; deviceIdentifier?: string; defaultTimeRange?:{ start: any, end: any } | null; resourceIdentifier?: string; indexNotFoundExceptionHandling?: boolean;} }
 */
const InitialOptions = {
  userName: null,
  deviceIdentifier: null,
  defaultTimeRange: { start: moment().subtract(1, 'week').toDate(), end: moment().toDate() },
  resourceIdentifier: null,
  indexNotFoundExceptionHandling: false
}

/**
 * @typedef { 'event-log' | 'proxy-event-log' | 'proxy-audit-log' | 'audit-log' } QueryIndexes
 * @param {QueryIndexes[] | string[]} indexes
 * @returns
 */
const useMultiQuery = (indexes = ['event-log', 'proxy-event-log', 'proxy-audit-log', 'audit-log'], options = InitialOptions) => {
  const [_indexes, _setIndexes] = useState(() => {
    indexes.forEach((item) => {
      if (!['event-log', 'proxy-event-log', 'proxy-audit-log', 'audit-log'].includes(item)) {
        throw new Error(`The _index ${item} does not exist on IndexMaps`)
      }
    })
    return indexes
  })
  const [queryTillTime, setQueryTillTime] = useState('-')
  const [loading, setLoading] = useState(null)
  const prevHitsRef = useRef({})
  const abortControllerRef = useRef(new AbortController());
  const history = useHistory();


  const createAbortController = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous fetch (if any)
    }
    abortControllerRef.current = new AbortController(); // Create a new AbortController
    return abortControllerRef.current.signal; // Get the new AbortSignal
  };

  const [safeOptions, setSafeOptions] = useState(() => {
    return { ...InitialOptions, ...options }
  })

  const [queryTimeRange, setQueryTimeRange] = useState(safeOptions.defaultTimeRange)
  const [hits, setHits] = useState(() => {
    const _object = {}
    indexes.forEach((_index) => (_object[_index] = []))
    return _object
  })
  // @ts-ignore
  const tenant = useSelector((state) => state.user.tenant)
  const nameSpace = useSelector((state) => state.user.org)

  // const createCancellationToken = () => new Promise((resolve) => setCancellation(resolve))

  const IndexMatchClauses = useMemo(() => {
    let finalClauses = []

    const messageShouldClauses = { bool: { should: [] } }
    const tenantShouldClauses = { bool: { should: [] } }
    const userNameShouldClauses = { bool: { should: [] } }
    const deviceShouldClauses = { bool: { should: [] } }
    const resourcesShouldClauses = { bool: { should: [] } }

    const IndexTenantMap = getTenantIndexMap(tenant || '')
    const IndexUserNameMap = getUserNameIndexMap(safeOptions.userName)
    const IndexDeviceNameMap = getDeviceIndexMap(safeOptions.deviceIdentifier)
    const IndexResourcesMap = getResourcesIndexMap(safeOptions.resourceIdentifier)
    
    _indexes.forEach((_index) => {
      messageShouldClauses.bool.should = [...messageShouldClauses.bool.should, ...IndexMessageClausesMap[_index]]
      tenantShouldClauses.bool.should = [...tenantShouldClauses.bool.should, ...IndexTenantMap[_index]]
      userNameShouldClauses.bool.should = [...userNameShouldClauses.bool.should, ...IndexUserNameMap[_index]]
      deviceShouldClauses.bool.should = [...deviceShouldClauses.bool.should, ...IndexDeviceNameMap[_index]]
      resourcesShouldClauses.bool.should = [...resourcesShouldClauses.bool.should, ...IndexResourcesMap[_index]]
    })

    if (safeOptions.userName) finalClauses = [...finalClauses, userNameShouldClauses]
    if (safeOptions.deviceIdentifier) finalClauses = [...finalClauses, deviceShouldClauses]
    if (safeOptions.resourceIdentifier) finalClauses = [...finalClauses, resourcesShouldClauses]
    return [tenantShouldClauses, messageShouldClauses, ...finalClauses]
  }, [_indexes, safeOptions.userName, safeOptions.deviceIdentifier, safeOptions.resourceIdentifier, tenant])

  const fullQuery = useMemo(() => {
    return constructQuery(queryTillTime, IndexMatchClauses, queryTimeRange)
  }, [IndexMatchClauses, queryTimeRange, queryTillTime])

  const fullDateTimeQuery = useMemo(() => {
    return constructTimeAndDateRange(queryTillTime, queryTimeRange)
  }, [queryTillTime, queryTimeRange])

  const onTimeFilterChange = useCallback((start, end) => {
    const tempTimeRange = { start, end }
    setQueryTimeRange(tempTimeRange)
  }, [])

  const onTillTimeChange = useCallback((time) => {
    setQueryTillTime(time)
  }, [])

  const filterHitsIntoIndexes = (hits = []) => {
    const _object = {}
    hits.forEach((hit) => {
      if (_.isArray(_object[hit._index])) {
        _object[hit._index].push(hit)
      } else {
        _object[hit._index] = [hit]
      }
    })
    // For safety
    _indexes.forEach((_index) => {
      if (!_object[_index]) _object[_index] = []
    })
    return _object
  }

  /**
   * @type {((INDEXES:QueryIndexes[] | string[]) => void)}
   */
  const setIndexes = useCallback((INDEXES) => {
    INDEXES.forEach((_index) => {
      if (!IndexMaps[_index]) throw new Error(`The _index ${_index} does not exist on IndexMaps`)
    })
    if (!_.isEqual(INDEXES, _indexes)) _setIndexes(INDEXES)
  }, [_indexes])

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getQueryLogs = useCallback(async () => {
    setLoading(true);

    const abortSignal = createAbortController();

    let from = 0;
    let allHits = [];
    const batchSize = 500;
    let totalRecords;
    let displayedHits = [] || {};

    try{
      while(true) {
        const response = await fetchDataWithCancellation(abortSignal, from , batchSize)
        const decodeResponse = response?.data ? JSON.parse(atob(response?.data?.Response)) : '';
        const hits = decodeResponse?.hits?.hits || [];
        totalRecords = decodeResponse?.hits?.total?.value
  
        allHits = [...allHits, ...hits];
        displayedHits = filterHitsIntoIndexes(allHits); // Update displayed hits with allHits
        
      // Update displayed hits in the UI
      if(!_.isEqual(prevHitsRef.current, displayedHits)){
        setHits((state) => {
          prevHitsRef.current = displayedHits;
          return displayedHits;
        });
       }
        
        if(allHits.length < totalRecords) {
            from += batchSize;
        }else {
          setLoading(false)
          break
        }

        await delay(3000);
        setLoading(false);
      }
    }catch(error) {
      console.log(error)
    }
  
  }, [fullQuery, fullDateTimeQuery, safeOptions.indexNotFoundExceptionHandling, safeOptions]);
  

  const fetchDataWithCancellation = async (abortSignal, from, batchSize) => {
    const user = getUserInfo();
    const { apiKey } = user;
    const currentUrl = window.location.pathname.toLowerCase();
    const userName = currentUrl.includes('user') ? user.Spec.EmailID : safeOptions.userName || '';
    let startTime = '';
    let endTime = '';
    for (const dateRange of fullDateTimeQuery) {
      if (dateRange.range && dateRange.range["@timestamp"]) {
        const { gte, lt } = dateRange.range["@timestamp"];
        startTime = gte;
        endTime = lt;
      }
    }

    try{
      const dataObj = {
        "ObjectMeta": {
          "Tenant": tenant,
          "Namespace": nameSpace
        },
        "Indices": {
          "Elems": _indexes
        },
        "Filters": {
          "UserName": userName,
          "DeviceName": safeOptions.deviceIdentifier || '',
          "ResourceName": safeOptions.resourceIdentifier || '',
        },
        "Size": batchSize,
        "StartTime": startTime,
        "EndTime": endTime,
        "From": from
      };

      
  
      const response = await getActivityLog(apiKey, tenant, 'elasticlog', dataObj);
      if (response?.error) {
        if (
          response?.error?.response?.data?.error?.type === 'index_not_found_exception' &&
          safeOptions.indexNotFoundExceptionHandling
        ) {
          const _index = response?.error?.response?.data?.error?.index;
          _setIndexes(state => state.filter((index) => index !== _index));
        } else {
          // Handle other errors here
          return null; // or handle the error in another way
        }
      }

      if(abortSignal && abortSignal.aborted) {
        throw new Error('fetch was cancelled')
      }
  
     return response
    }catch(error){
      if(abortSignal && abortSignal.aborted)
      throw error
    }
  }

  useEffect(() => {
    // When safeOptions change, abort the ongoing fetch (if any)
    abortControllerRef.current.abort();

    // Start a new fetch with updated safeOptions
    getQueryLogs();
  }, [safeOptions, getQueryLogs]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      abortControllerRef.current.abort();
    });

    return () => {
      unlisten()
    }
  }, [history])

  const setOptions = useCallback((options = InitialOptions) => {
    const _options = { ...safeOptions, ...options }
    if (!_.isEqual(_options, safeOptions)) setSafeOptions(_options)
  }, [safeOptions])

  useEffect(() => {
    if (!_.isEqual(indexes, _indexes)) _setIndexes(indexes)
  }, [indexes])

  useEffect(() => {
    if (!_.isEqual({ ...InitialOptions, ...options }, safeOptions)) setSafeOptions({ ...InitialOptions, ...options })
  }, [options])

  return {
    IndexMatchClauses,
    setIndexes,
    getQueryLogs,
    hits,
    fullQuery,
    fullDateTimeQuery,
    onTimeFilterChange,
    onTillTimeChange,
    queryTimeRange,
    loading,
    setOptions
  }
}

export default useMultiQuery
