import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { deleteIAMRole } from 'features/iamRoles'
import { getResourceName } from 'features/resources'
import React from 'react'

const IAMRoleDeleteModal = ({ iamrole, onSuccess, onCancel }) => {
  const groupName = getResourceName(iamrole)

  const handleDelete = async () => {
    try {
      await deleteIAMRole(iamrole)
			enqueueNotification('IAM Role deleted successfully!', 'info')
			deleteSliceData(iamrole)
      onSuccess?.()
      return iamrole
    } catch (error) {
      enqueueNotification(`Failed to delete iamrole: ${groupName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={iamrole} onCancel={onCancel} onDelete={handleDelete} />
}

export { IAMRoleDeleteModal }
