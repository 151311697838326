import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import * as thunks from 'infra/redux/reducers'
import store from 'infra/redux/store'

const MUST_ARGUMENT_SLICES = ['resourceKindGrants']

const SliceThunks = {}
const SliceNames = []

// This is used to override the slice.name with custom name
const EXCEPTIONS_MAP = {
  credentialTypesList: 'credentialTypeList'
}

/**
 * Parse all thunks and slices names.
 */
for (const property in thunks) {
  const { name: _name, thunk } = thunks[property]
  const name = EXCEPTIONS_MAP[_name] || _name
  SliceNames.push(name)
  SliceThunks[name] = thunk
}

const InitialOptions = {
  skipIdleCheck: false
}
const PAGINATED_SLICE_NAMES = ['awsResources', 'gcpResources', 'azureResources']
const FETCH_HISTORY_MAP = {}

/**
 * @typedef {{ skipWhenLoaded?: boolean, payload?: any }} ThunkOptions
 * @param {import('types').SliceNames[]} sliceNames
 * @returns
 */
function useMultiDispatch(sliceNames, options = InitialOptions) {
  const dispatch = useDispatch()
  const safeOptions = useMemo(() => ({ ...InitialOptions, ...options }), [options])

  const dispatchEvery = (_sliceNames = [], thunkOptions = {}) => {
    const state = store.getState()
    // MUST CHECK for sliceNames which needs argument
    const sliceNames = _sliceNames.filter(
      (name) => !(MUST_ARGUMENT_SLICES.includes(name) && _.isEmpty(thunkOptions.payload))
    )
    sliceNames.forEach((sliceName) => {
      if (thunkOptions.skipWhenLoaded && state[sliceName].initiallyLoaded) return
      if (PAGINATED_SLICE_NAMES.includes(sliceName)) return
      if (safeOptions.skipIdleCheck) {
        SliceThunks[sliceName] && dispatch(SliceThunks[sliceName](thunkOptions))
      } else if (!FETCH_HISTORY_MAP[sliceName]) {
        FETCH_HISTORY_MAP[sliceName] = true
        /**
         * Dispatch action only if API is IDLE
         */
        SliceThunks[sliceName] && dispatch(SliceThunks[sliceName](thunkOptions))
      }
    })
  }

  /**
   * @type {((thunkOptions?:ThunkOptions) => void)}
   */
  const dispatchThunks = useCallback(
    (thunkOptions) => dispatchEvery(sliceNames, thunkOptions),
    [sliceNames]
  )
  /**
   * @type {((sliceNames: import('types').SliceNames[], thunkOptions?: ThunkOptions) => void)}
   */
  const selectDispatch = useCallback(
    (sliceNames, thunkOptions) => dispatchEvery(sliceNames, thunkOptions),
    []
  )

  return { dispatchThunks, selectDispatch, dispatch }
}

export default useMultiDispatch
