import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { LabelContent } from 'V2Components'
import { Typography } from 'procyon-ui'
import useMultiSlice from 'Core/Hooks/useMultiSlice'

const JiraConfiguration = ({ integration }) => {
  const { slices, selectDispatch } = useMultiSlice(['jiraIntegrationsList'])

  const generateTransitionRow = () => {
    const transitionsMap = integration?.Spec?.Transitions?.Map || {}

    return Object.entries(transitionsMap)
      .filter(([key, value]) => value.trim() !== '')
      .map(([key, value], index) => {
        const keyName = key
          .split(':')
          .filter((part) => part !== 'undefined')
          .join(' - ')
        const steps = value
          .split(', ')
          .filter((step) => step !== 'undefined')
          .join(', ')
        return <LabelContent key={index} title={keyName} content={steps} />
      })
  }

  useEffect(() => {
    selectDispatch(['jiraIntegrationsList'])
  }, [])

  return (
    <div className='mt-8 w-[100%]'>
      {slices.jiraIntegrationsList && integration &&
      <>
        <LabelContent title={'Jira issue Ttpe'} content={integration?.Spec?.JiraIssueType} />

        {Object.entries(integration?.Spec?.AttributeMapping?.Map).map(
          ([fieldName, resourceValue]) => (
            <LabelContent key={fieldName} title={fieldName} content={resourceValue} />
          )
        )}

        <div className='mt-5'>
          <Typography variant='h3'>Status Mapping</Typography>

          {Object.entries(integration?.Spec?.StatusMapping?.Map).map(([fieldName, value]) => (
            <LabelContent title={fieldName} content={value} />
          ))}
        </div>
        <div className='mt-5'>
          <Typography variant='h3'>Transitions</Typography>
          {generateTransitionRow()}
        </div>
      </>
      }
    </div>
  )
}

export { JiraConfiguration }
