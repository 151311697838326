// @ts-nocheck
import StyledBox from 'Components/StyledBox'
import { _ } from 'Core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Footer from './Components/Footer'
import Header from './Components/Header'
import * as FiltrationFunctions from 'Components/FilterHeader/FilterationFunctions'
import { Box } from '@material-ui/core'
import ChartLoader from './ChartLoader'

/** @typedef {{'Input'?:Function,'Dropdown'?:Function,'MultiSelect'?:Function,'TimeRange'?:Function,'TillDate'?:Function}} externalFilterHandlers */
/**
 * @param {{externalFilterHandlers:externalFilterHandlers}} param0
 */
const ChartContainer = ({
  Graph,
  dataSets = {},
  xAxis,
  chartStyles = {},
  seriesMeta = [],
  yAxis,
  SeriesFilterComponent,
  DatasetComponent,
  title,
  InfoSegment,
  defaultFilters = [],
  headerHorizontalPadding,
  graphLeftOffset,
  barsBorderRadius,
  graphTopOffset,
  className = '',
  height,
  parent = '',
  datasetFilterPosition = 'bottom',
  titleClass,
  headerVerticalAlignment,
  loading,
  externalFilterHandlers
}) => {
  console.log('re-renders chartContainer by - ', parent)
  /**
   * Manage dataset selection
  **/
  const dataSetKeys = useMemo(() => Object.keys(dataSets), [dataSets])
  const [selectedDataSet, setSelectedDataSet] = useState(dataSetKeys[0])

  /**
   * Manage showing and hiding serieses
  **/
  const [filteredSeriesMeta, setFilteredSeriesMeta] = useState(seriesMeta.map(e => ({ ...e, active: true })))

  // Handles click on series filers
  const changeFilter = useCallback((filterName) => {
    const tempSeriesMeta = _.cloneDeep(filteredSeriesMeta)
    // Find and toggle the actve value of clicked series filter
    tempSeriesMeta.forEach(e => {
      if (e.name === filterName) e.active = !e.active
    })
    setFilteredSeriesMeta(tempSeriesMeta)
  }, [setFilteredSeriesMeta, filteredSeriesMeta])

  /**
   *  Manage Filters functionality
  **/
  const [filters, setFilters] = useState([...defaultFilters])

  /**
   * Apply all filters when
   * 1. Source data prop changes.
   * 2. One of the filter dropdowns change
   * 3. Data set is selected
  **/
  const applyFilters = (filters, currentDataset) => {
    let tempData = [...currentDataset]
    filters.forEach(filter => {
      tempData = FiltrationFunctions.applyTypeBasedFilter(tempData, filter.name, filter.value, filter.filterType)
    })
    return tempData
  }
  /** Apply default filters on component load. */
  const [filteredData, setFilteredData] = useState(applyFilters(defaultFilters, dataSets[selectedDataSet]))

  /** Handle filter dropdown change */
  const onChange = (value, name, filterType) => {
    const tempFilters = [...filters]
    const tagetFilter = tempFilters.find(e => e.name === name)
    if (tagetFilter) {
      tagetFilter.value = value
    } else {
      tempFilters.push({ value, name, filterType })
    }
    setFilters([...tempFilters])
    if (name in externalFilterHandlers) {
      externalFilterHandlers[name](value)
    } else {
      const currentDataset = dataSets[selectedDataSet]
      const tempData = applyFilters([...tempFilters], currentDataset)
      setFilteredData(tempData)
    }
  }

  /** Handle data set change */
  const handleDataSetChange = (dataSetName) => {
    setSelectedDataSet(dataSetName)
    const newData = dataSets[dataSetName]
    /** Apply all filters on new dataset */
    const newFilteredData = applyFilters(filters, newData)
    setFilteredData(newFilteredData)
  }
  useEffect(() => {
    /** @type {string} eg: Sessions | Active Users  */
    const tempSelectedDataSet = dataSetKeys[0]
    setSelectedDataSet(tempSelectedDataSet)
    setFilteredData(applyFilters(filters, dataSets[tempSelectedDataSet]))
  }, [dataSets])
  return (
    <StyledBox globalClassName='chartWrapper ' className={className} pt={1} px={1.5} pb={0} height='100%'>
      <Header
        headerVerticalAlignment={headerVerticalAlignment}
        showDataSet={datasetFilterPosition === 'top'}
        selectedDataSet={selectedDataSet}
        handleDataSetChange={handleDataSetChange}
        dataSetKeys={dataSetKeys}
        DatasetComponent={DatasetComponent}
        px={headerHorizontalPadding} InfoSegment={InfoSegment} titleClass={titleClass} title={title} filters={filters} onChange={onChange}
      />
      <Box position='relative' style={{ [graphTopOffset ? 'marginTop' : '-']: graphTopOffset, [graphLeftOffset ? 'marginLeft' : '-']: graphLeftOffset }}>
        {loading && <ChartLoader loading={loading} />}
        {Graph &&
          <Graph
            borderRadius={barsBorderRadius}
            chartStyles={chartStyles}
            seriesMeta={filteredSeriesMeta}
            xAxis={xAxis}
            yAxis={yAxis}
            data={filteredData}
          />}
      </Box>
      <Footer
        SeriesFilterComponent={SeriesFilterComponent}
        currentFilters={filteredSeriesMeta}
        changeFilter={changeFilter}
        selectedDataSet={selectedDataSet}
        showDataSet={datasetFilterPosition === 'bottom'}
        handleDataSetChange={handleDataSetChange}
        dataSetKeys={dataSetKeys}
        DatasetComponent={DatasetComponent}
      />
    </StyledBox>
  )
}

export default React.memo(ChartContainer)
