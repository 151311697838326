import { LabelContent } from 'V2Components'
import { getAwsResourceTags } from 'features/iamResources'
import { getResourcePolicyTags } from 'features/resources/utils'
import { Label, Typography } from 'procyon-ui'
import React from 'react'

const TagsAndLabelsView = ({ rsrc, hideCloudTags = false }) => {
  if (!rsrc) return null

  const getItems = (tagObj = {}) => {
    const items = []

    for (const labelName in tagObj) {
      const labelValue = tagObj[labelName]
      items.push({
        label: labelName,
        value: labelValue
      })
    }

    return items
  }

  const getPolicyTagItems = () => {
    const tags = getResourcePolicyTags(rsrc)

    return getItems(tags)
  }

  const getAwsTagItems = () => {
    const awsTags = getAwsResourceTags(rsrc)
    return getItems(awsTags)
  }

  const policyTagItems = getPolicyTagItems()
  const awsTagsItems = getAwsTagItems()

  return (
    <div className='w-1/2'>
      {!hideCloudTags && (
        <div className='mb-6'>
          <Typography variant='h4-regular'>Cloud Tags</Typography>
          {!awsTagsItems.length && (
            <Typography className='italic mt-4' variant='body-regular'>
              No tags for this resource.
            </Typography>
          )}
          {awsTagsItems.map((e) => (
            <LabelContent
              showEmptyLabel
              title={e.label}
              content={
                e.value ? (
                  <Typography
                    className='!break-all bg-gray-200 inline-block p-2 py-1 rounded'
                    variant='body-regular'
                  >
                    {e.value}
                  </Typography>
                ) : (
                  ''
                )
              }
            />
          ))}
        </div>
      )}
      <div>
        <Typography variant='h4-regular'>Procyon Tags</Typography>
        {!policyTagItems.length && (
          <Typography className='italic mt-4' variant='body-regular'>
            No Procyon tags attached to this resource.
          </Typography>
        )}
        {policyTagItems.map((e) => (
          <LabelContent
            showEmptyLabel
            title={e.label}
            content={e.value ? <Label text={e.value} /> : ''}
          />
        ))}
      </div>
    </div>
  )
}

export { TagsAndLabelsView }
