import { Box, makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
  root: {
    flexGrow: props => props.panelFullHeight && 1
  }
})
const TabPanel = (props) => {
  const { children, value, index, panelFullHeight, ...other } = props
  const classes = useStyle({ panelFullHeight })

  return (
    <div
      className={classes.root}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default TabPanel
