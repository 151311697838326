import { Button, Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useDebugValue, useState } from 'react'
import { useChannelBrowser } from '../../../providers/hooks'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SelectChannel } from '../../Modal'
import { ApiProvider } from 'Core'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { createRsrcKey } from 'features/resources'
import { getFriendlyName } from 'Utils/FriendlyName'
import { reduxApiClient } from 'infra'
import { LoadingFeedback } from 'V2Components'

const SlackNotificationChannel = () => {
  const { linkedChannels, refresh, integration } = useChannelBrowser()
  const { slices, getObjectRef } = useMultiSlice(['notificationSubscriptions', 'slackChannelList'])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationType, setNotificationType] = useState('')

  const getMedusaNodeSlackNotificationChannel = () => {
    const medusaNodeSlackNotificationChannelRefID =
      integration?.SlackIntegrationSettings?.SlackIntegrationSettingsMap
        ?.medusaNodeSlackNotificationChannelRef?.RefID

    // webhook
    if (integration?.Spec?.WebhookUrl !== '' && medusaNodeSlackNotificationChannelRefID) return true
    if (!medusaNodeSlackNotificationChannelRefID) return false

    const medusaNodeSlackNotificationChannel = linkedChannels.find(
      (e) => e.ObjectMeta.ID === medusaNodeSlackNotificationChannelRefID
    )
    if (!medusaNodeSlackNotificationChannel) return false
    return medusaNodeSlackNotificationChannel
  }

  const getPrivateNS = () => {
    return slices.notificationSubscriptions.find((e) => {
      const { SubscriptionFor, Subscriber } = e.Spec
      if (
        SubscriptionFor.RefID === '0' &&
        SubscriptionFor.RefKind === 'Server' &&
        Subscriber.RefKind === 'SlackChannel'
      )
        return true
    })
  }

  /**
   * check is private webhook channel is aready exist
   */

  const webookPrivateChannel = () => {
    const foundSubscription = slices.notificationSubscriptions.find((e) => {
      const { Subscriber } = e.Spec
      if (
        integration?.Spec?.WebhookUrl !== '' &&
        Subscriber.RefKind === 'SlackIntegration' &&
        Subscriber.RefID === integration?.ObjectMeta?.ID
      ) {
        return true
      } else {
        return false
      }
    })

    return foundSubscription
  }

  const getPrivateServerChannel = () => {
    const privtNotificationChannel = getPrivateNS()
    if (privtNotificationChannel) {
      const { Subscriber } = privtNotificationChannel.Spec
      console.log(
        '[channel](subs):',
        Subscriber,
        slices.slackChannelList.find((e) => e.ObjectMeta.ID === Subscriber.RefID)
      )
      return getObjectRef(Subscriber)
    }
    return
  }

  const medusaNodeChannel = getMedusaNodeSlackNotificationChannel()
  const privateServerChannel = getPrivateServerChannel()

  const getGetwayNotificationIcon = () => {
    return (medusaNodeChannel && Object.keys(medusaNodeChannel).length > 1) ||
      integration?.Spec?.WebhookUrl !== '' ? (
      <TargetIcon type='SLACK' />
    ) : (
      ''
    )
  }

  const getGetWayNotificationVariant = () => {
    return medusaNodeChannel && Object.keys(medusaNodeChannel).length > 1 ? 'grayBlue' : 'gray'
  }

  const getGetwayChannelName = () => {
    if (medusaNodeChannel && integration?.Spec?.WebhookUrl === '') {
      return <strong>{medusaNodeChannel?.Spec?.ChannelName}</strong>
    } else if (medusaNodeChannel && integration?.Spec?.WebhookUrl !== '') {
      return <strong>Webhook Channel</strong>
    } else {
      return <em>No channel connected yet</em>
    }
  }

  const getPrivateNotificationIcon = () => {
    return (privateServerChannel && Object.keys(privateServerChannel).length > 1) ||
      integration?.Spec?.WebhookUrl !== '' ? (
      <TargetIcon type='SLACK' />
    ) : (
      ''
    )
  }

  const getPrivateNotificationVariant = () => {
    return privateServerChannel && Object.keys(privateServerChannel).length > 1
      ? 'grayBlue'
      : 'gray'
  }

  const getPrivateChannelName = () => {
    if (webookPrivateChannel() && integration?.Spec?.WebhookUrl !== '') {
      return <strong>Webhook Channel</strong>
    } else if (privateServerChannel) {
      return <strong>{privateServerChannel.Spec.ChannelName}</strong>
    } else {
      return <em>No channel connected yet</em>
    }
  }

  const handleModalClick = (type) => {
    setNotificationType(type)
    setShowModal(!showModal)
  }

  const onConfirmChannelChange = (selectedChannel, notificationType) => {
    if (notificationType === 'getway') {
      getwayNotificationChannel(selectedChannel)
    } else {
      privateNotificationChannel(selectedChannel)
    }
  }

  const getwayNotificationChannel = async (selectedChannel) => {
    try {
      setLoading(true)
      const payload = _.cloneDeep(integration)
      if (selectedChannel && Object.keys(selectedChannel)?.length > 1) {
        payload.SlackIntegrationSettings.SlackIntegrationSettingsMap.medusaNodeSlackNotificationChannelRef =
          {
            RefID: selectedChannel.ObjectMeta.ID,
            RefKind: 'SlackChannel'
          }
      } else {
        payload.SlackIntegrationSettings.SlackIntegrationSettingsMap.medusaNodeSlackNotificationChannelRef =
          {}
      }

      // for webhook
      if (integration?.Spec?.WebhookUrl !== '') {
        payload.SlackIntegrationSettings.SlackIntegrationSettingsMap.medusaNodeSlackNotificationChannelRef =
          {
            RefID: integration?.ObjectMeta?.ID,
            RefKind: 'SlackIntegration'
          }
      }

      await reduxApiClient('slackintegrations').update(payload)
      enqueueNotification('Gateway Notification channel updated successfully.', 'info')
    } catch (error) {
      enqueueNotification('Operation Failed', 'error')
    } finally {
      setLoading(false)
      setShowModal(false)
    }
  }

  const privateNotificationChannel = async (selectedChannels) => {
    try {
      setLoading(true)
      if (!Object.keys(selectedChannels).length && integration.Spec?.WebhookUrl === '') {
        // If no channels are selected then, delete any NS if exists
        const privateNS = getPrivateNS()
        if (privateNS) {
          await new ApiProvider('notificationsubscriptions').setInstance(privateNS).delete()
          refresh()
          setShowModal(false)
        }
        return
      }

      let selectedChannelRef = {}

      if (integration?.Spec?.WebhookUrl === '') {
        selectedChannelRef = {
          RefKind: 'SlackChannel',
          RefID: selectedChannels.ObjectMeta.ID
        }
      } else {
        selectedChannelRef = {
          RefKind: 'SlackIntegration',
          RefID: integration?.ObjectMeta?.ID
        }
      }

      const channelKey = createRsrcKey(selectedChannelRef)
      const privateNS = getPrivateNS()

      if (channelKey === createRsrcKey(privateServerChannel)) return setLoading(false)

      if (privateNS) {
        // Delete if PRIVATE notification channel exists
        await reduxApiClient('notificationsubscriptions').delete(privateNS)
      }

      const newNS = {
        ObjectMeta: {
          Name: `PRIVATE_${getFriendlyName()}`
        },
        Spec: {
          SubscriptionFor: {
            RefID: 0,
            RefKind: 'Server'
          },
          Subscriber: selectedChannelRef
        }
      }
      // Create NS
      await reduxApiClient('notificationsubscriptions').create(newNS)
      enqueueNotification('Private Server Notification Channel changed successfully!', 'info')
      setShowModal(false)
      setLoading(false)
      refresh()
    } catch (error) {
      enqueueNotification('Failed to change Notification Channel!', 'error')
      refresh()
    }
  }

  return (
    <div className='mt-8'>
      <div className='border-b-2'>
        <Typography variant='h4-medium'>GateWay Notification Channel</Typography>
        <div className='flex justify-between items-center my-5'>
          <Label
            size='lg'
            icon={getGetwayNotificationIcon()}
            onClick={function noRefCheck() {}}
            text={getGetwayChannelName()}
            variant={getGetWayNotificationVariant()}
            style={{
              padding: '15px'
            }}
          />

          <Button size='md' variant='gray' onClick={() => handleModalClick('getway')}>
            Select
          </Button>
        </div>
      </div>

      <div className='border-b-2 mt-10'>
        <Typography variant='h4-medium'>Private Server Notification Channel</Typography>
        <div className='flex justify-between items-center my-5'>
          <Label
            size='lg'
            icon={getPrivateNotificationIcon()}
            onClick={function noRefCheck() {}}
            text={getPrivateChannelName()}
            variant={getPrivateNotificationVariant()}
            style={{
              padding: '15px'
            }}
          />

          <Button size='md' variant='gray' onClick={() => handleModalClick('private')}>
            Select
          </Button>
        </div>
      </div>

      <SelectChannel
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirmChannelChange={onConfirmChannelChange}
        notificationType={notificationType}
      />
      <LoadingFeedback
        loading={loading}
        message='Updating Slack Notification Channel'
        caption='Please wait..'
      />
    </div>
  )
}

export { SlackNotificationChannel }
