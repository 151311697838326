import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const useStyles = makeStyles(styles)

const StyledBox = (props) => {
  const classes = useStyles()

  const DynamicComponent = typeof props.component === 'object' ? props.component : Box

  let componentClassName = props.className

  if (props.globalClassName.length > 0) {
    const globalClasses = props.globalClassName.split(' ')

    const useStyleClasses = globalClasses.map(globalClass => classes[globalClass] || false)

    componentClassName += ` ${useStyleClasses.join(' ')}`
  }

  const componentProps = {
    ...props,
    className: componentClassName
  }

  delete componentProps.globalClassName

  return (
    <DynamicComponent {...componentProps} />
  )
}

StyledBox.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  globalClassName: PropTypes.string
}

StyledBox.defaultProps = {
  globalClassName: '',
  component: Box
}

export default StyledBox
