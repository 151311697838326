import { ApiProvider } from 'Core'
import { createRef, getResourceName } from 'features/resources'

/**
 *
 * @param {import("../types").SessionQueryType} sessionQuery
 */
export const fetchSessionLogs = async (sessionQuery) => {
  const payload = {
    ObjectMeta: {
      Name: '1337'
    },
    ReqType: 'Query',
    SessionQuery: sessionQuery
  }

  const response = await new ApiProvider('sessionmgmt').setInstance(payload).post()
  return response.data
}

export const markAsAuditLog = async (sessionKeys = [], auditor) => {
  const payload = {
    ObjectMeta: {
      Name: '1337'
    },
    ReqType: 'Audit',
    SessionAudit: {
      Sessions: {
        Elems: sessionKeys
      },
      AuditorName: getResourceName(auditor),
      Auditor: createRef(auditor)
    }
  }
  const response = await new ApiProvider('sessionmgmt').setInstance(payload).post()
  return response.data
}
