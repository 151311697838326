import { useMultiOrg } from 'Core/Hooks/useMultiOrg'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createOrganization } from 'features/settings'
import { Button, Label, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const AddOrganizationModal = ({ onCancel, onSuccess }) => {
  const [name, setName] = useState('')
  const { orgs } = useMultiOrg()
  const [isLoading, setIsLoading] = useState(false)

  const getErrors = () => {
    if (orgs.find((v) => v.toLowerCase() === name.trim().toLowerCase()))
      return 'Organization with that name already exists.'
    if (!name) return 'Name is required.'
    if (!name.match('^[a-z]+$')) return 'Name can only have lowercase letters.'
    return ''
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const payload = {
        ObjectMeta: {
          Kind: 'Org',
          Name: name
        }
      }
      const response = await createOrganization(payload)
      pushToSlice(response)
      setIsLoading(false)
      enqueueNotification('Organization successfully created!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Error creating organization.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FullScreenContentModal>
      <LoadingFeedback
        loading={isLoading}
        caption='Please wait..'
        message='Creating organization'
      />
      <Typography className='mb-4' variant='h4-regular'>
        Add Organization
      </Typography>
      <div>
        <TextInput
          onChange={(e) => setName(e.target.value)}
          sx={{ width: '100%' }}
          label='Organization Name'
        />
      </div>
      <div className='flex justify-end gap-4 mt-4'>
        {getErrors() && <Label variant='warning' text={getErrors()} />}
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!name || !!getErrors()} variant='primary'>
          Submit
        </Button>
      </div>
    </FullScreenContentModal>
  )
}

export { AddOrganizationModal }
