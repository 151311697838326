import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { ApiProvider } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { hasApprovalRequestExpired, openURLSafely } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal, FullScreenModal } from 'V2Components'
import { getApplicationSignInURL, useAppDetailsContext } from 'features/applications'
import { isApprovalRequestCreatedByUser } from 'features/approvals'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  Headers,
  gitLicense
} from 'features/github'
import { AccessCartProvider, createRef, useAccessCartProvider } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import {
  Button,
  GithubUserRow,
  Label,
  RadioInput,
  RepositoryCard,
  TabGroup,
  TeamsRow,
  TextArea,
  TextInput
} from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '650px'
    }
  }
}

const useSlices = createDataSelectorHook([
  'githubAccount',
  'githubResources',
  'userList',
  'approvalReqList'
])

const Respositories = () => {
  const history = useHistory()
  const { user } = useUser()
  const { app } = useAppDetailsContext()
  const [searchKey, setSearchKey] = useState('')
  const { appView } = useAppView()
  const [githubResources, setGithubResources] = useState([])
  const [showGroupsModal, setShowGroupsModal] = useState(false)
  const [showAddRepoModal, setShowAddRepoModal] = useState(false)
  const [currentAccountObj, setCurrentAccountObj] = useState({})
  const [githubTeam, setGithubTeam] = useState([])
  const [repoListData, setRepoListData] = useState([])
  const [membersList, setMembersList] = useState([])
  const [selectedRepoObjRef, setSelectedRepoObjRef] = useState({})
  const { addItemsToCart, isResourceInCart, isCartEmpty } = useAccessCartProvider()
  const [showSignIn, setShowSignIn] = useState(true)
  const { selectDispatch } = useMultiSlice(['githubResources'])
  const [repoState, setRepoState] = useState({
    repoName: '',
    repoDescription: '',
    license: '',
    radioInputValue: ''
  })

  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const { slices } = useSlices()
  const getErrors = () => {
    if (!repoState.repoName) return 'Repository Name must not be empry'
    if (!repoState.radioInputValue) return 'Repository type must public or private'
  }

  useEffect(() => {
    initGithubResources()
  }, [])

  const handleAddApplicationClick = () => setShowAddRepoModal(!showAddRepoModal)

  const initGithubResources = async () => {
    const currentAccount = slices.githubAccount.find(
      (account) => account.Application.RefID === app?.ObjectMeta?.ID
    )

    let filterRepo = []
    let currentUserAccess
    let currentUserWithWriteAccess

    if (currentAccount) {
      currentUserAccess = currentAccount.Spec.Members.Members.find(
        (member) => member.memberRef.RefID === user?.ObjectMeta?.ID
      )
      currentUserWithWriteAccess = currentAccount.ObjectMeta.WrOwners.ObjectRef.find(
        (member) => member.RefID === user?.ObjectMeta.ID
      )

      if (
        appView === 'admin' ||
        currentUserAccess?.ScimProvisonedIdentity ||
        currentUserWithWriteAccess
      ) {
        filterRepo = slices.githubResources.filter(
          (obj) =>
            obj.Spec.Type === 'repository' &&
            obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID
        )
      }

      setShowSignIn(!(currentUserAccess?.ScimProvisonedIdentity || currentUserWithWriteAccess))
    }
    setGithubResources(filterRepo)
    setRepoListData(filterRepo)
    setCurrentAccountObj(currentAccount)
  }

  useEffect(() => {
    handleSearchKeyUpdate()
  }, [searchKey])

  const handleSearchKeyUpdate = () => {
    if (!isInitialLoad) {
      if (searchKey !== '') {
        const matchingObject = githubResources.filter((item) => {
          const fullName = item.Spec.RepositorySpec.FullName.toLowerCase()
          const name = item.Spec.RepositorySpec.Name.toLowerCase()
          const keyword = searchKey.toLowerCase()
          return fullName.includes(keyword) || name.includes(keyword)
        })
        setRepoListData(matchingObject)
      } else {
        setRepoListData(githubResources)
      }
    } else {
      setIsInitialLoad(false)
    }
  }

  const handleMoreInfoClick = (app) => {
    history.push(`/${appView}/applications/repo/${encodeURIComponent(app.ObjectMeta.Name)}`)
  }

  const handleGroups = (app) => {
    setSelectedRepoObjRef(app)
    setRepoState((prevState) => ({
      ...prevState,
      repoName: app.Spec.RepositorySpec.Name
    }))
    const refIds = app.Spec?.Members?.Members.map((member) => member?.memberRef?.RefID)
    const matchedUsers = slices.userList.filter((obj) => refIds?.includes(obj?.ObjectMeta?.ID))
    const matchedTeams = slices.githubResources.filter((obj) =>
      refIds?.includes(obj?.ObjectMeta?.ID)
    )
    setMembersList(matchedUsers)
    setGithubTeam(matchedTeams)
    setShowGroupsModal(true)
  }

  const handleModalClose = () => {
    setRepoState((prevState) => ({
      ...prevState,
      license: '',
      repoName: '',
      repoDescription: '',
      radioInputValue: ''
    }))
    setShowGroupsModal(false)
    setShowAddRepoModal(false)
  }

  const handleInputChange = (label, value) => {
    setRepoState((prevState) => ({
      ...prevState,
      [label]: value
    }))
  }

  const handleAddRepo = async () => {
    const { repoName, repoDescription, license, radioInputValue } = repoState
    setShowAddRepoModal(false)
    let dataObj = {
      ObjectMeta: {
        Name: repoName.replace(/ /g, '_'),
        Namespace: 'default',
        Tenant: currentAccountObj?.ObjectMeta?.Tenant
      },
      Spec: {
        Type: 'repository',
        RepositorySpec: {
          Name: repoName.replace(/ /g, '_'),
          FullName: repoName.replace(/ /g, '_'),
          Description: repoDescription,
          License: license,
          IsPrivate: radioInputValue === 'public' ? false : true,
          DefaultBranch: 'master'
        },
        ManagedBy: 'procyon',
        Account: {
          RefKind: currentAccountObj?.ObjectMeta?.Kind,
          RefID: currentAccountObj?.ObjectMeta?.ID
        }
      },
      Status: {
        Status: 'pending'
      }
    }

    try {
      await new ApiProvider('githubresources').setInstance(dataObj).post()
      selectDispatch(['githubResources'])
      enqueueNotification('Repository added successfull!')
      setRepoState((prevState) => ({
        ...prevState,
        license: '',
        repoName: '',
        repoDescription: '',
        radioInputValue: ''
      }))
    } catch (error) {
      console.log()
    }
  }

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const handleAccessClick = (app) => {
    //@ts-ignore
    if (isResourceInCart(createRef(app)) || !isCartEmpty) return
    addItemsToCart({
      resourceRef: createRef(app),
      principal: '',
      roles: []
    })
    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: true
      })
    } else {
      handleViewsChange({
        accessRequestSubmissionModal: true
      })
    }
  }

  const handleSignInClick = () => openURLSafely(getApplicationSignInURL(app))

  const getMember = (members) => {
    const refIds = members.map((member) => member?.memberRef?.RefID)
    const matchedUsers = slices.userList.filter((obj) => refIds?.includes(obj?.ObjectMeta?.ID))
    return matchedUsers.length
  }

  const getTeams = (members) => {
    const refIds = members.map((member) => member?.memberRef?.RefID)
    const matchedTeams = slices.githubResources.filter((obj) =>
      refIds?.includes(obj?.ObjectMeta?.ID)
    )
    return matchedTeams.length
  }

  const getAppAccessState = (app) => {
    const reversed = structuredClone(slices?.approvalReqList)?.reverse()
    const approvalList = []
    const expiredOrRejected = []
    reversed?.forEach((e) => {
      if (appView === 'user' && !isApprovalRequestCreatedByUser(user, e)) return
      if (hasApprovalRequestExpired(e)) {
        expiredOrRejected.push(e)
      } else {
        approvalList.push(e)
      }
    })

    for (const object of approvalList) {
      if (object?.Spec?.Resources?.Resource) {
        const resourceArray = object?.Spec?.Resources?.Resource
        for (const resource of resourceArray) {
          if (app?.ObjectMeta.ID === resource?.Target?.RefID) {
            return object.Status
          }
        }
      }
    }
  }

  const formError = getErrors()
  return (
    <>
      <Headers
        handleAddApplicationClick={handleAddApplicationClick}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        showToggle={true}
        showAddButton={true}
        buttonLabel={'Add New Repository'}
        title={'total repositories'}
        count={repoListData.length}
      />

      <div className='flex my-[20px] mx-0 pb-[60px] flex-wrap'>
        {repoListData?.map((item) => (
          <RepositoryCard
            githubAccountName={item.Spec.RepositorySpec.FullName?.split('/')[0]}
            groupsAmount={getTeams(item?.Spec?.Members?.Members)}
            identitiesAmount={getMember(item?.Spec?.Members?.Members)}
            isAdmin={appView === 'admin' ? true : false}
            isRequested={getAppAccessState(item) === 'Pending' ? true : false}
            menuItems={
              appView === 'admin' && showSignIn
                ? [
                    {
                      submenu: [
                        {
                          action: () => handleSignInClick(),
                          title: 'Sign in'
                        }
                      ],
                      title: <FontAwesomeIcon icon={faEllipsisVertical} />
                    }
                  ]
                : [] // Empty array if appView is not 'admin'
            }
            onClickGroups={() => handleGroups(item)}
            onClickIdentities={() => handleGroups(item)}
            onClickMoreInfo={() => {
              handleMoreInfoClick(item)
            }}
            onClickRequest={() => handleAccessClick(item)}
            showRequestButton={
              getAppAccessState(item) !== 'Pending' && getAppAccessState(item) === 'Approved'
                ? false
                : true
            }
            showSignInButton={
              getAppAccessState(item) === 'Approved' && appView === 'user' ? true : false
            }
            onClickSignIn={() => handleSignInClick()}
            repositoryName={item.Spec.RepositorySpec.Name}
            style={{
              marginRight: '20px',
              marginBottom: '20px'
            }}
          />
        ))}
      </div>

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={setViews} isRepo={false} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}

      {showGroupsModal && (
        <FullScreenContentModal>
          <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
            <div className='header'>
              <p>{repoState.repoName}</p>
              <span className='flex gap-4'>
                <Button
                  onClick={() => handleMoreInfoClick(selectedRepoObjRef)}
                  size='sm'
                  variant='grayBlue'
                >
                  {' '}
                  Manage Access
                </Button>
                <Button onClick={handleModalClose} size='sm' variant='gray' className='close-btn'>
                  Close X
                </Button>
              </span>
            </div>

            <TabGroup
              onChangeTab={function noRefCheck() {}}
              tabContentHeight='auto'
              tabs={[
                {
                  label: 'Teams',
                  tabContent: (
                    <>
                      {githubTeam &&
                        githubTeam.map((item) => (
                          <TeamsRow
                            caption={item.Spec.TeamSpec.Description}
                            onClickEdit={() => {}}
                            onClickRepositoryButton={function noRefCheck() {}}
                            onClickUsers={function noRefCheck() {}}
                            repositoryCount={1}
                            title={item.Spec.TeamSpec.Name}
                            usersCount={item.Spec.Members.Members.length}
                            width='100%'
                            showEditButton={false}
                            showRepositoryButton={false}
                            showUsersButton={false}
                          />
                        ))}
                    </>
                  )
                },
                {
                  label: 'Users',
                  tabContent: (
                    <>
                      {membersList.map((item) => (
                        <GithubUserRow
                          dropdownMenuItems={[
                            {
                              submenu: [
                                {
                                  title: 'Delete'
                                }
                              ],
                              title: <FontAwesomeIcon icon={faEllipsisVertical} />
                            }
                          ]}
                          onClickRepositories={function noRefCheck() {}}
                          onClickUserGroups={function noRefCheck() {}}
                          repositoryCount={1}
                          userEmail={item?.Spec?.EmailID}
                          showControlButtons={false}
                          userName={item?.Spec?.FullName}
                        />
                      ))}
                    </>
                  )
                }
              ]}
            />
          </div>
        </FullScreenContentModal>
      )}

      <FullScreenModal showModal={showAddRepoModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <div className='w-[626px] rounded-lg p-8 bg-white'>
            <div className='flex justify-between items-center mb-[30px]'>
              <p className='text-[20px] m-0'>Add Github Repository</p>
              <span
                className='py-[10px] px-[30px] bg-[#f9fbfc] rounded-[7px] text-base text-[#545B71] cursor-pointer'
                onClick={handleModalClose}
              >
                X Close
              </span>
            </div>

            <p className='text-[20px] mb-6 text-[#545B71] text-sm'>
              Add new repository in Github Account
            </p>
            <div className=''>
              <TextInput
                label='Repository Name'
                onChange={(e) => handleInputChange('repoName', e.target.value)}
                onClickEndIcon={function noRefCheck() {}}
                placeholder='Name'
                fullWidth
                sx={{ width: '100%', marginBottom: '20px' }}
                value={repoState.repoName}
              />

              <TextArea
                label='Description (optional)'
                rows={5}
                cols={73}
                onChange={(e) => handleInputChange('repoDescription', e.target.value)}
                placeholder='Enter a text here'
                value={repoState.repoDescription}
              />
            </div>

            <div className='flex items-center my-[30px]'>
              <span className='py-0 px-30 w-[300px]'>
                <RadioInput
                  label='Public'
                  name='type'
                  onChange={(e) => handleInputChange('radioInputValue', e.target.value)}
                  value='public'
                />
                <p className='mt-[10px] mb-[30px] text-sm text-[#8D94A1]'>
                  Anyone on the internet can see this repository. You choose who can commit.
                </p>
              </span>

              <span className='py-0 px-30 w-[300px]'>
                <RadioInput
                  label='Private'
                  name='type'
                  onChange={(e) => handleInputChange('radioInputValue', e.target.value)}
                  value='private'
                />
                <p className='mt-[10px] mb-[30px] text-sm text-[#8D94A1]'>
                  You choose who can see and commit tho this repository.
                </p>
              </span>
            </div>

            <div className=''>
              <label id='select-label'>Choose a License</label>
              <Select
                displayEmpty
                style={{
                  width: '100%',
                  height: '40px',
                  marginTop: '10px',
                  background: '#F9FBFC',
                  outline: 0
                }}
                onChange={(e) => handleInputChange('license', e.target.value)}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
                labelId='demo-simple-select-label'
                id='select-label'
                value={repoState.license}
              >
                <MenuItem value='' disabled>
                  Select License
                </MenuItem>
                {Object.entries(gitLicense).map(([name, code]) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {formError && <Label variant='warning' text={formError} />}
            <div className='mt-10 mb-5 flex justify-end gap-4'>
              <Button variant='gray' onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={!!formError}
                className='bg-[#3267D6] text-[#fff]'
                onClick={handleAddRepo}
              >
                Save
              </Button>
            </div>
            <div className='error'>
              <p>{''}</p>
            </div>
          </div>
        </div>
      </FullScreenModal>
    </>
  )
}

const WrappedApplicationsList = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <AccessCartProvider cartKey={cartKey}>
      <Respositories />
    </AccessCartProvider>
  )
}

export { WrappedApplicationsList as Respositories }
