import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { isPolicyIssuedToGroup } from 'features/policy'
import { useEffect } from 'react'

export function useGroupRsrcAccess() {
  const { slices, dispatchThunks } = useMultiSlice(['policyList'])

  /**
   * Get all the policies which are issued to this `group`
   * @param {any} group 
   * @returns {any[]} Policys which are issued to the following group
   */
  const getGroupIssuedPolicys = (group) => {
    return slices.policyList.filter((e) => isPolicyIssuedToGroup(e, group))
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return { getGroupIssuedPolicys }
}
