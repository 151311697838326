import React from 'react'
import SegmentRow from './SegmentRow'

const Segments = ({ segmentRows = [] }) => {
  if (!segmentRows) return null
  return segmentRows.map(({ Icon, text, title, variant, disableBorder }, index) => (
    <SegmentRow Icon={Icon} key={index} text={text} title={title} variant={variant} disableBorder={disableBorder} />
  ))
}

export default Segments
