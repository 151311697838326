import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { AutocompleteInput, IconButton, TextInput, Typography } from 'procyon-ui'
import React from 'react'

const AccountActionInput = ({
  account,
  placeholder = '',
  onInputChange = null,
  onAddButtonClick = null,
  isSelected = false,
  onRemoveButtonClick = null,
  value = '',
  autoCompleteOptions =[],
  readonly = false,
  policyMode = false,
  onPolicyButtonClick = null,
  disabledInput = false
}) => {
  const Icon = getRsrcIcon(account)

  return (
    <div className='flex gap-4 items-start border-b p-3 mb-3'>
      {Icon && <Icon />}
      <div className='w-[100%]'>
        <Typography variant='body-regular'>{getResourceName(account)}</Typography>
        <div className='flex gap-4 items-center mt-2'>
          <TextInput
            disabled={disabledInput}
            value={value}
            onChange={(e) => onInputChange?.(createRsrcKey(account), e.target.value)}
            fullWidth
            placeholder={placeholder}
            sx={{ width: '100%' }}
          />
          {!readonly && (
            <IconButton
              variant={isSelected ? 'gray' : 'grayBlue'}
              icon={isSelected ? faMinusCircle : faPlusCircle}
              onClick={
                isSelected
                  ? () => onRemoveButtonClick(createRsrcKey(account))
                  : () => onAddButtonClick(createRsrcKey(account))
              }
            />
          )}
          {policyMode && onPolicyButtonClick && (
            <IconButton variant={'grayBlue'} icon={faPlusCircle} onClick={onPolicyButtonClick} />
          )}
        </div>
      </div>
    </div>
  )
}

export { AccountActionInput }
