import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal } from 'V2Components'
import { getApprovalRequestResourcesRefs, useApprovalPolling } from 'features/approvals'
import { useCloudTypes } from 'features/clouds'
import {
  IAMResourcesCheckboxFilters,
  NamespacePrompt,
  getIAMResourceDisplayType,
  useIAMRsrcAccess
} from 'features/iamResources'
import {
  ResourcesView,
  createRef,
  createRsrcKey,
  getIAMBookmarksCount,
  resourceAdapterMap,
  useAccessCartProvider,
  useBookmarks
} from 'features/resources'
import _ from 'lodash'
import { ResourceCard } from 'procyon-ui'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

const DEFAULT_RSRC_TYPE_FILTERS = ['granted', 'ungranted', 'bookmarked']

/**
 * @typedef {{ forPolicy:boolean, onAccessClick:((rsrc) => void), showMoreInfoButton:boolean, onMoreInfoClick?:((r:any) =>  void)  }} CardProps
 * @param {{ activeCloudType:string, setActiveCloudType:((string) => void),
 * cardProps:  CardProps,
 *  rsrcs?: any[]
 * hideCloudTypeSelect?: boolean,
 * }} param0
 * @returns
 */
function IAMResourceView({ cardProps, activeCloudType, setActiveCloudType, rsrcs = [] }) {
  const { appView } = useAppView()
  const history = useHistory()
  const { user } = useUser()
  const [namespacePrompt, setNamespacePrompt] = useState(false)
  const [activeCheckboxFilters, setActiveCheckboxFilters] = useState(DEFAULT_RSRC_TYPE_FILTERS)
  // This holds the account id and Type of the resource which was added to queue
  // For filtering out other resources which don't belong to this account
  // const resourceQueueAccount = resourcesQueue[0]?.Account || { RefID: '', Type: '' }
  const [cartResourceAccount, setCartResourceAccount] = useState({
    RefID: '',
    RefKind: '',
    Type: ''
  })
  const { isBookmarked, toggleBookmark } = useBookmarks()
  const { cartItems, isResourceInCart, cartKey } = useAccessCartProvider()

  const { slices, getObjectRef } = useMultiSlice([
    'awsResources',
    'gcpResources',
    'azureResources',
    'approvalReqList',
    'accountList'
  ])
  const { cloudTypes } = useCloudTypes()
  const { selectDispatch } = useMultiDispatch([])
  const { getPolicySignInURLS } = useIAMRsrcAccess()

  const signInUrlsMap = getPolicySignInURLS(user)
  /**
   * If there are items in cart, then, set the cart resource account, which in turn disables account change from headers
   */
  if (cartItems.length && slices.accountList.length && !cartResourceAccount.RefID) {
    const rsrc = getObjectRef(cartItems[0].Resource)
    if (rsrc) {
      const account = getObjectRef(rsrc.Spec.Account)
      //@ts-ignore
      if (account) setCartResourceAccount({ ...createRef(account), Type: account.Spec.Type })
    }
  } else {
    if (cartResourceAccount.RefID && !cartItems.length) {
      setCartResourceAccount({
        RefID: '',
        RefKind: '',
        Type: ''
      })
    }
  }

  const filteredResources = (() => {
    const list = []
    const ecsSubType = ['AWS ECS Task', 'AWS ECS Service']
    rsrcs.forEach((rsrc) => {
      const key = createRsrcKey(rsrc)
      const rsrcType = getIAMResourceDisplayType(rsrc)
      /**
       * The follwing checks are for resources which needs to be displayed depending on active
       * filters.
       * Any resource which fulfills the filter is displayed.
       *
       * Core Logic: If no resource type is selected, then display all the resources after applying access status filters
       * If a resource type is selected, then display just the resources which belongs to that type after applying access status filters
       *
       */

      // exclude ECS subtype ECS_TASK ECS_SERVICE

      const exludedECSSubType = ecsSubType.some((type) => type === rsrcType)

      if (exludedECSSubType) return

      const rsrcTypeSelected = activeCheckboxFilters.filter(
        (e) => !DEFAULT_RSRC_TYPE_FILTERS.includes(e)
      ).length
      // Check if the rsrc type filter is checked, or if no rsrc type filters are selected, then only, show the rsrc after applying core status filters
      if (activeCheckboxFilters.includes(rsrcType) || !rsrcTypeSelected) {
        if (activeCheckboxFilters.includes('ungranted') && !signInUrlsMap[key]) list.push(rsrc)
        else if (activeCheckboxFilters.includes('bookmarked') && isBookmarked(key)) list.push(rsrc)
        else if (activeCheckboxFilters.includes('granted') && signInUrlsMap[key]) list.push(rsrc)
      }
      /***************************************************************************************/
    })
    return list
  })()

  const grantedCount = Object.keys(signInUrlsMap).filter(
    (key) => signInUrlsMap[key].accountType === activeCloudType
  ).length

  const bookmarkCount = getIAMBookmarksCount({
    list: rsrcs,
    isBookmarked,
    accountType: activeCloudType
  })

  const copyProfileName = (ProfileName) => {
    try {
      if (!ProfileName)
        return enqueueNotification('Unable to find ProfileName for this role.', 'error')
      navigator.clipboard.writeText(ProfileName)
      enqueueNotification('Profile Name  copied : ' + ProfileName, 'info')
    } catch (error) {
      console.error(error)
    }
  }

  const getDotMenuItems = ({ data, rsrc, accessState }) => {
    const rsrcKey = createRsrcKey(data.ref)
    const Kind = rsrc.ObjectMeta.Kind
    const items = [
      {
        action: () => handleResourceRequestClick(data),
        title: appView === 'admin' ? 'Create Policy' : 'Request'
      }
    ]

    if ((Kind === 'AwsResource' || Kind === 'KubeNamespace') && accessState === 'signIn') {
      const urlsMap = signInUrlsMap[rsrcKey].policys
      urlsMap.forEach(({ AppRoleID, name }) => {
        const approle = getObjectRef({ RefKind: 'AppRole', RefID: AppRoleID })
        if (approle) {
          items.push({
            action: () => copyProfileName(approle.ObjectMeta.Name),
            title: `${name} - Copy Profile Name`
          })
        }
      })
    }
    return items
  }

  const getPolicySignInUrlsSubMenuItems = (key) => {
    if (!signInUrlsMap[key]) return []
    return signInUrlsMap[key].policys.map((e) => ({
      action: () => openURLExternally(e.url),
      title: e.name
    }))
  }

  const getSingleSignInFunction = ({ rsrc, accessState }) => {
    const { Kind } = rsrc.ObjectMeta
    if (['GcpResource', 'AzureResource'].includes(Kind) && accessState === 'signIn') {
      return () => openURLExternally(signInUrlsMap[createRsrcKey(rsrc)].policys[0].url)
    }
  }

  const getDropdownItems = ({ rsrc }) => {
    const rsrcKey = createRsrcKey(rsrc)
    const { Kind } = rsrc.ObjectMeta

    if (['GcpResource', 'AzureResource'].includes(Kind)) {
      return null
    }

    return [
      {
        action: null,
        submenu: getPolicySignInUrlsSubMenuItems(rsrcKey),
        title: 'Sign In'
      }
    ]
  }

  /**
   * Get List of AwsResource and GcpResource refs which have pending approvals
   */
  const pendingRsrcRefs = useMemo(() => {
    const pendingRsrcs = []
    slices.approvalReqList.forEach((e) => {
      const resourcesRefs = getApprovalRequestResourcesRefs(e)
      if (e.Status === 'Pending') pendingRsrcs.push(...resourcesRefs)
    })
    return pendingRsrcs
  }, [slices.approvalReqList])

  const handleResourceRequestClick = (e) => {
    cardProps.onAccessClick && cardProps.onAccessClick(e)
  }

  const getRsrcAccessState = useCallback(
    (rsrcRef) => {
      let accessState = 'access'
      const rsrcKey = createRsrcKey(rsrcRef)
      /** Checking the approval state of the object */
      if (_.find(pendingRsrcRefs, rsrcRef)) accessState = 'waiting'
      else if (isResourceInCart(rsrcRef)) accessState = 'requested'
      else if (signInUrlsMap[rsrcKey]) accessState = 'signIn'
      return accessState
    },
    [signInUrlsMap, isResourceInCart, pendingRsrcRefs]
  )

  const renderCardComponent = useCallback(
    (rsrc) => {
      const account = getObjectRef(rsrc.Spec.Account)
      const data = resourceAdapterMap[rsrc.ObjectMeta.Kind]({
        rsrc,
        account
      })
      const accessState = getRsrcAccessState(data.ref)
      const rsrcKey = createRsrcKey(data.ref)

      return (
        <ResourceCard
          showMoreInfoButton={cardProps.showMoreInfoButton}
          showSignInButton={signInUrlsMap[rsrcKey]}
          key={rsrcKey}
          forPolicy={cardProps.forPolicy}
          onClickSingleSignIn={getSingleSignInFunction({ rsrc, accessState })}
          onClickRequest={() => handleResourceRequestClick(data)}
          onClickPolicy={() => handleResourceRequestClick(data)}
          onClickBookmark={() => toggleBookmark(rsrcKey)}
          onClickMoreInfo={() => {
            if (cardProps.onMoreInfoClick) cardProps.onMoreInfoClick(rsrc)
            else
              history.push(
                `/${appView}/resources/${data.RefKind.toLowerCase()}/${rsrc.ObjectMeta.Name}`
              )
          }}
          region={data.region}
          resourceName={data.resourceName}
          resourceType={data.resourceType}
          accountName={data.accountName}
          variant='primary'
          bookmarked={isBookmarked(rsrcKey)}
          resourceIcon={<data.Icon style={{ borderRadius: '4px' }} width='34px' height='34px' />}
          width='100%'
          // @ts-ignore
          accessState={accessState}
          dotMenuItems={[
            {
              icon: faEllipsisVertical,
              submenu: getDotMenuItems({ data, rsrc, accessState }),
              title: ''
            }
          ]}
          dropDownItems={getDropdownItems({ rsrc })}
        />
      )
    },
    [getDotMenuItems, getDropdownItems, getObjectRef, isBookmarked, getRsrcAccessState]
  )

  /** ========================================================================================================== */

  useEffect(() => {
    selectDispatch([
      'accountList',
      'awsResources',
      'azureResources',
      'gcpResources',
      'policyList',
      'userList',
      'groupList',
      'serviceAccounts',
      'workloads'
    ])
    selectDispatch(['iamActions'])
  }, [])

  useApprovalPolling()

  const getUngranterResourceCount = () => {
    const accountTypeResourceMap = {
      AWS: slices.awsResources,
      GCP: slices.gcpResources,
      AZURE: slices.azureResources
    }
    const resources = accountTypeResourceMap[activeCloudType] || []
    return resources.length - grantedCount
  }

  return (
    <div>
      <ResourcesView
        filterSaveKey={cartKey}
        cloudOptions={cloudTypes}
        activeCloudType={activeCloudType}
        setActiveCloudType={(val) => {
          setActiveCloudType(val)
          setActiveCheckboxFilters(DEFAULT_RSRC_TYPE_FILTERS)
        }}
        disableAccountControls={cartItems.length}
        accountID={createRsrcKey(cartResourceAccount)}
        accountType={cartResourceAccount.Type}
        renderCardComponent={renderCardComponent}
        FilterComponent={({}) => (
          <IAMResourcesCheckboxFilters
            rsrcs={rsrcs}
            activeCloudType={activeCloudType}
            unGrantedCount={getUngranterResourceCount()}
            grantedCount={grantedCount}
            activeFilters={activeCheckboxFilters}
            onActiveFilterChange={setActiveCheckboxFilters}
            bookmarkCount={bookmarkCount}
          />
        )}
        resources={filteredResources}
      />
      {namespacePrompt && (
        <FullScreenContentModal>
          <NamespacePrompt cloudType={namespacePrompt} onClose={() => setNamespacePrompt(false)} />
        </FullScreenContentModal>
      )}
    </div>
  )
}

export { IAMResourceView }
