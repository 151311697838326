import React, { useState } from 'react'
import { Button, Typography, Divider, Step, StepLabel, StepContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignInAlt,
  faUserShield,
  faMobileAlt,
  faKey,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

const steps = [
  {
    title: 'Sign in to the AWS Management Console',
    description:
      'Go to AWS Management Console and sign in with your account credentials as either a Root user or an IAM user.',
    icon: faSignInAlt
  },
  {
    title: 'Navigate to the IAM Console',
    description:
      'In the AWS Management Console, search for IAM in the top search bar. Select IAM to open the Identity and Access Management console.',
    icon: faUserShield
  },
  {
    title: 'Access MFA Settings',
    description:
      'In the IAM Dashboard, click on Users to view the list of IAM users. Select the user you want to enable MFA for, or go to Security credentials if setting up for the root account.',
    icon: faKey
  },
  {
    title: 'Enable MFA',
    description:
      'Scroll to Multi-Factor Authentication (MFA) and click Manage or Assign MFA device. Select Virtual MFA device if using an authenticator app.',
    icon: faMobileAlt
  },
  {
    title: 'Set Up MFA Using an Authenticator App',
    description:
      'Open your preferred authenticator app, scan the QR code, and enter two consecutive MFA codes from the app to confirm.',
    icon: faMobileAlt
  },
  {
    title: 'Confirm and Complete MFA Setup',
    description:
      'Click Assign MFA (or Activate for root account) to complete the setup. Test by logging out and signing back in.',
    icon: faCheckCircle
  }
]

const MFARecommendation = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div style={{ margin: 'auto' }}>
      <p className='text-base font-[500]'>Enable MFA on Your AWS Account</p>
      <Divider style={{ marginBottom: '20px' }} />

      {steps.map((step, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Step active={activeStep === index} expanded={activeStep === index}>
            <StepLabel>
              <FontAwesomeIcon icon={step.icon} style={{ marginRight: '10px' }} />
              <Typography variant='subtitle2' component='span'>
                {step.title}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <StepContent>
                <p className='mb-3 text-[14px] font-[500] text-[#6e6e6e]'>{step.description}</p>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    style={{ marginRight: '10px' }}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  {index > 0 && <Button onClick={handleBack}>Back</Button>}
                </div>
              </StepContent>
            )}
          </Step>
        </div>
      ))}
    </div>
  )
}

export { MFARecommendation }
