import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fastEqual } from 'Core'
import { KindToEndpointMap, apiClient } from 'infra/api'
import _ from 'lodash'
import { applyDataMiddleWares } from '../middlewares'
export const status = {
  IDLE: 'idle',
  LOADING: 'loading',
  SILENT_LOADING: 'silentLoading',
  ERRORED: 'errored'
}
export const LOADING_STATUS = status
/**
 * @typedef {{
 * initialState: any,
 * sliceName:import('types').SliceNames,
 * reducers?: object,
 * apiFn?: (() => any) | null,
 * endpoint: import('types').Endpoints
 * }} SliceGeneratorParamType
 * @type SliceGeneratorParamType
 */
const sliceGeneratorParamShape = {
  initialState: [],
  apiFn: null,
  //@ts-ignore
  sliceName: '',
  endpoint: null,
  reducers: {}
}

export const createRsrcKey = (rsrc) => {
  if (!rsrc) return null
  if (rsrc.RefKind) return `${rsrc.RefKind}+${rsrc.RefID}`
  if (!rsrc.ObjectMeta?.Kind) return null
  return `${rsrc.ObjectMeta.Kind}+${rsrc.ObjectMeta.ID}`
}

export const sliceGenerator = (options = sliceGeneratorParamShape) => {
  const opts = { ...sliceGeneratorParamShape, ...options }

  const thunk = createAsyncThunk(
    opts.sliceName,
    // @ts-ignore
    async (data = { payload: false, flags: {} }) => {
      // @ts-ignore
      if (opts.apiFn) return await opts.apiFn(data.payload)
      // @ts-ignore
      const r = await apiClient(opts.endpoint).getAll()
      return r
    }
  )

  const paginatedFetchThunk = createAsyncThunk(
    `${opts.sliceName}/PAGINATED`,
    //@ts-ignore
    async ({ startIndex = 0, endIndex = 10 }) => {
      //@ts-ignore
      const data = await apiClient(opts.endpoint).getAllByOffset(startIndex, endIndex)
      return { data, endIndex, startIndex }
    }
  )

  /**
   * Thunk for fetching an object and storing them in map
   */

  const fetchAsyncObjectThunk = createAsyncThunk(`OBJECT_FETCH_${opts.sliceName}`, async (data) => {
    //@ts-ignore
    const { RefKind, RefID } = data
    const endpoint = KindToEndpointMap[RefKind]
    if (!endpoint) return
    return await apiClient(endpoint).getByID(RefID)
  })

  const updateSliceMap = createAction(`UPDATE_SLICE_MAP/${opts.sliceName}`)
  //Actin dispatched when updating the whole slice map, this takes care of removed object cleanup
  const updateAllSliceMap = createAction(`UPDATE_SLICE_MAP/ALL/${opts.sliceName}`)

  const createObject = createAction(`CREATE/${opts.sliceName}`)
  const updateObject = createAction(`UPDATE/${opts.sliceName}`)
  const deleteObject = createAction(`DELETE/${opts.sliceName}`)

  const slice = createSlice({
    name: opts.sliceName,
    initialState: {
      status: status.IDLE,
      data: opts.initialState, //! THIS HAS BEEN DEPRECATED
      updating: [],
      initiallyLoaded: false, //! THIS HAS BEEN DEPRECATED
      map: {}, // Object with resource key and resource
      fetchingMapKeys: {}, // Object which holds currently being fetched objects,
      notFoundMapKeys: {},
      pageLastIndex: 0,
      lastPageReached: false
    },
    reducers: opts.reducers,
    extraReducers: (builder) => {
      builder.addCase(thunk.pending, (state, action) => {
        const { arg } = action.meta
        // @ts-ignore
        if (!arg?.flags?.skipLoader) {
          state.status = status.LOADING
        } else {
          state.status = status.SILENT_LOADING
        }
        return state
      })
      builder.addCase(thunk.fulfilled, (state, action) => {
        if (!action.payload || action?.payload?.error) {
          state.status = status.ERRORED
        } else {
          const data = applyDataMiddleWares({ sliceName: opts.sliceName, data: action.payload })
          if (_.isArray(data)) {
            // Add/Update new data
            data.map((r) => {
              const k = createRsrcKey(r)
              // Get the resource
              const o = state.map[k] || {}
              if (!fastEqual(r, o)) state.map[k] = r
            })
            //Delete removed data
            const dataKeys = data.map(createRsrcKey)
            for (const key in state.map) {
              // If the data doesnt have the key, then remove from map because most likely the object got delete
              if (!dataKeys.includes(key)) delete state.map[key]
            }
          }
          state.status = status.IDLE
        }
        state.initiallyLoaded = true
        return state
      })
      builder.addCase(paginatedFetchThunk.fulfilled, (state, action) => {
        if (!action.payload || action?.payload?.error) {
          state.status = status.ERRORED
        } else {
          const data = applyDataMiddleWares({
            sliceName: opts.sliceName,
            data: action.payload.data
          })
          if (_.isArray(data)) {
            // Add/Update new data
            data.map((r) => {
              const k = createRsrcKey(r)
              // Get the resource
              const o = state.map[k] || {}
              if (!fastEqual(r, o)) state.map[k] = r
            })
          }
          state.pageLastIndex = action.payload.endIndex
          state.status = status.IDLE
        }
        state.initiallyLoaded = true
        return state
      })
      builder.addCase(thunk.rejected, (state, action) => {
        state.status = status.ERRORED
        state.initiallyLoaded = true
        return state
      })
      builder.addCase(fetchAsyncObjectThunk.pending, (state, action) => {
        const k = createRsrcKey(action?.meta?.arg)
        state.fetchingMapKeys[k] = true
        return state
      })
      builder.addCase(fetchAsyncObjectThunk.fulfilled, (state, action) => {
        const k = createRsrcKey(action.payload)
        if (action.payload === undefined) {
          // Payload is undefined only when server didnt return any data that is, the object didnt exist in database
          // hence push to not found
          state.notFoundMapKeys[createRsrcKey(action.meta.arg)] = true
        }

        if (!action.payload || !k) return state

        const o = state.map[k] || {}

        if (!fastEqual(action.payload, o)) state.map[k] = action.payload

        state.fetchingMapKeys[k] = false
        return state
      })
      /** Object Map Action builders */
      builder.addCase(updateSliceMap, (state, action) => {
        if (typeof action.payload === 'undefined') return state
        const data = applyDataMiddleWares({
          sliceName: opts.sliceName,
          data: _.isArray(action.payload) ? action.payload : [action.payload]
        })

        data.forEach((elem) => {
          const k = createRsrcKey(elem)
          // Get the resource
          const o = state.map[k] || {}
          if (!fastEqual(elem, o)) state.map[k] = elem
        })

        return state
      })
      builder.addCase(updateAllSliceMap, (state, action) => {
        if (typeof action.payload === 'undefined') return state
        const data = applyDataMiddleWares({
          sliceName: opts.sliceName,
          data: _.isArray(action.payload) ? action.payload : [action.payload]
        })

        data.forEach((elem) => {
          const k = createRsrcKey(elem)
          // Get the resource
          const o = state.map[k] || {}
          if (!fastEqual(elem, o)) state.map[k] = elem
        })

        //Delete removed data
        const dataKeys = data.map(createRsrcKey)
        for (const key in state.map) {
          // If the data doesnt have the key, then remove from map because most likely the object got delete
          if (!dataKeys.includes(key)) delete state.map[key]
        }
        return state
      })
      /** Object Action builders */
      builder.addCase(createObject, (state, action) => {
        const k = createRsrcKey(action.payload)
        state.map[k] = action.payload
        return state
      })
      builder.addCase(updateObject, (state, action) => {
        if (typeof action.payload === 'object') {
          //If the state is an array then, use filter for array to update
          const k = createRsrcKey(action.payload)
          state.map[k] = action.payload
        }
        return state
      })
      builder.addCase(deleteObject, (state, action) => {
        delete state.map[createRsrcKey(action.payload)]
        return state
      })
    }
  })

  return {
    thunk,
    paginatedFetchThunk,
    fetchAsyncObjectThunk,
    reducer: slice.reducer,
    name: opts.sliceName,
    endpoint: opts.endpoint
  }
}
