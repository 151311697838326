import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useApprovalPolling } from 'features/approvals'
import {
  AccessCartProvider,
  ResourceDetailsProvider,
  ResourceDetailsView,
  createRef,
  isResourceManaged,
  useAccessCartProvider,
  useResourceDetails
} from 'features/resources'
import {
  CreateRequestFlow,
  HeaderActions,
  MULTI_POLICY_TARGET_KINDS,
  PRINCIPAL_OBJECT_KINDS,
  TargetDetails,
  isDynamicAppRole,
  isPrivateServer
} from 'features/targets'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const QUEUE_KEY = 'userTargets'

function UserTargetDetails() {
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const { rsrc } = useResourceDetails()
  const { addItemsToCart, isResourceInCart, getResourceFromCart, updateCartQueueItem } =
    useAccessCartProvider()
  const { slices, dispatchThunks } = useMultiSlice(['policyList', 'approvalReqList', 'tenantprofiles'])
  /** Checking the approval state of the object */
  //@ts-ignore
  const user = useSelector((state) => state.user)
  const [views, setViews] = useState({
    principalModal: false,
    accessRequestSubmissionModal: false,
    userGroupSelectionModal: false
  })
  /**
   *
   * @returns A list of policy which are attached to this resource
   */
  const getRsrcAttachedPolicy = () => {
    const pList = []
    if (!rsrc) return []

    const rsrcRef = { RefKind: rsrc.ObjectMeta.Kind, RefID: rsrc.ObjectMeta.ID }
    slices.policyList.forEach((p) => {
      let { AssumeRole, SSH, DBAccess, RDPServer, RDPAccess } = p.Spec.ActionMap

      AssumeRole = AssumeRole?.PolicyRule || []
      SSH = SSH?.PolicyRule || []
      DBAccess = DBAccess?.PolicyRule || []
      RDPServer = RDPServer?.PolicyRule || []
      RDPAccess = RDPAccess?.PolicyRule || []

      const rules = [...AssumeRole, ...SSH, ...DBAccess, ...RDPServer, ...RDPAccess]
      if (_.find(rules, { ObjectRef: rsrcRef })) pList.push(p)
    })
    return pList
  }

  const getApprovedRequestList = () => {
    const rsrcRef = { RefKind: rsrc.ObjectMeta.Kind, RefID: rsrc.ObjectMeta.ID }
    const approvalReqList = []
    slices.approvalReqList.some((req) => {
      const resourcesObj = req.Spec.Resources.Resource
      approvalReqList.push(...resourcesObj)
    })

    const accessStatus = _.find(approvalReqList, { Target: rsrcRef })
    if (accessStatus?.Approved === 'Approved') return true
    if (accessStatus?.Approved === 'Pending' || accessStatus?.Approved === 'Expired') return false
  }

  const attachedPolicys = getRsrcAttachedPolicy()
  const approvalReq = getApprovedRequestList()

  const handleResourceAccessClick = (principal, data) => {
    const rsrcKind = data?.ObjectMeta ? data?.ObjectMeta.Kind : rsrc.ObjectMeta.Kind
    const resource = data ? data : rsrc
    // in case of kube pods, deployment policy creation

    if (principal) {
      if (data.ObjectMeta.Kind === 'KubeNamespace') {
        // Kube is already present
        if (isResourceInCart(createRef(data))) {
          const cartData = getResourceFromCart(createRef(data))
          const Principal = cartData?.Principal || ''
          //If the kube rsrc like pods is already present then ignore
          if (Principal.includes(principal)) return
          // Update the principal with new k8 rsrc
          updateCartQueueItem({
            principal: `${cartData.Principal ? cartData.Principal + ',' : ''}${principal}`,
            resourceRef: createRef(data)
          })
        } else {
          //Add new k8 rsrcs to queue
          addItemsToCart({
            principal,
            resourceRef: createRef(data),
            roles: []
          })
        }
      }
      return
    }

    if (rsrcKind === 'AppRole' || rsrcKind === 'Kafka') {
      addItemsToCart({
        principal: '',
        resourceRef: {
          RefKind: rsrcKind,
          RefID: rsrc.ObjectMeta.ID
        },
        roles: []
      })
      return
    }

    if (rsrc.ObjectMeta.Kind === 'KubeCluster' || rsrc.ObjectMeta.Kind === 'KubeNamespace') {
      setSelectedRsrc(resource)
      setViews((s) => ({ ...s, kubeAppRoleSelector: true }))
    }

    if (PRINCIPAL_OBJECT_KINDS.includes(rsrcKind)) {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, principalModal: true }))
    }
  }

  useEffect(() => {
    dispatchThunks()
  }, [])
  useApprovalPolling()
  if (!rsrc) return null

  const hideAccessButton = () => {
    // false if the rsrc can have multiple policy
    if (MULTI_POLICY_TARGET_KINDS.includes(rsrc.ObjectMeta.Kind)) return false
    if (rsrc.ObjectMeta.Kind === 'Kafka') return approvalReq
    return user.role === 'admin' || isDynamicAppRole(rsrc) || attachedPolicys.length >= 1
  }

  return (
    <div>
      <ResourceDetailsView
        HeaderActions={
          <HeaderActions
            showManageButton={!isPrivateServer(rsrc)}
            hideAccessButton={hideAccessButton()}
            disableAccessButton={isResourceInCart(createRef(rsrc))}
            onAccessClick={handleResourceAccessClick}
            rsrc={rsrc}
            showSignIn={
              isResourceManaged(rsrc) &&
              rsrc.ObjectMeta.Kind === 'AppRole' &&
              (attachedPolicys.length > 0 || user.role === 'admin')
            }
            showPrincipleSignIn={
              isResourceManaged(rsrc) &&
              rsrc.ObjectMeta.Kind === 'RDPServer' &&
              (attachedPolicys.length > 0 || user.role === 'admin')
            }
          />
        }
      >
        <TargetDetails tabs={[]} onAccessClick={handleResourceAccessClick} />
      </ResourceDetailsView>
      <CreateRequestFlow
        selectedRsrc={selectedRsrc}
        setSelectedRsrc={setSelectedRsrc}
        setViews={setViews}
        views={views}
      />
    </div>
  )
}

const WrappedUserDetails = () => {
  const { slices, dispatchThunks, selectDispatch } = useMultiSlice([
    'appRolesList',
    'databases',
    'serverList',
    'kafkas',
    'rdpServers',
    'kubeClusters',
    'kubeNamespaces'
  ])
  const rsrcList = useMemo(
    () => [
      ...slices.appRolesList,
      ...slices.databases,
      ...slices.serverList,
      ...slices.kafkas,
      ...slices.rdpServers,
      ...slices.kubeClusters,
      ...slices.kubeNamespaces
    ],
    [slices]
  )

  useEffect(() => {
    dispatchThunks()
    selectDispatch(['serviceAccounts'])
  }, [])

  return (
    <ResourceDetailsProvider rsrcList={rsrcList} rsrcKindKey='kind' rsrcNameKey='targetName'>
      <AccessCartProvider cartKey={QUEUE_KEY}>
        <UserTargetDetails />
      </AccessCartProvider>
    </ResourceDetailsProvider>
  )
}

export { WrappedUserDetails as UserTargetDetails }
