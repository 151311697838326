import { TreeView } from 'procyon-ui'
import React from 'react'

/**
 *
  [root,
   [[
     child-10,
       [child-11,
           [child-100]],
       [child-12]
   ],
   [
     child-20,
       [child-21]
   ]
  ]]
  */

const TreeViewExtended = ({
  renderNodeFn,
  getChildrenNodes,
  rootNode,
  rootChildrens,
  defaultExpandedNodeIds = [],
  selectedNodeKey,
  onNodeClick = null,
  renderRawUI = false
}) => {
  function renderTreeUI(nodes) {
    const nodeKey = nodes[0]
    const childrens = nodes[1]
    const childs = []
    if (childrens.length) {
      for (let i = 0; i < childrens.length; i++) {
        const childNode = childrens[i]
        childs.push(renderTreeUI(childNode))
      }
    }

    return renderNodeFn(nodeKey, childs)
  }

  function createTree(tree, childrenKeys) {
    // The root node is the account
    // The second level of nodes, they are the direct childrens of account
    tree[1] = childrenKeys.map((k) => [k, []])
    const children = tree[1]
    for (let i = 0; i < children.length; i++) {
      const node = children[i]
      const nodeKey = node[0]
      // We use the map object to maintain the parent-children relations, this removes computation complexity
      // Find the childrens of the node
      const chKeys = getChildrenNodes(nodeKey)
      if (chKeys.length) {
        /**
         * Call createTree, to create a new child node with it's own children.
         * This is a recursive call and goes on till all the tree is created.
         *
         * replace the specific node's children with the newly created tree
         *
         * tree[1]        =   children
         * tree[1][i]     =   the children whose tree is created
         * tree[1][i][1]  =   the children of the children
         */
        tree[1][i][1] = createTree([nodeKey, []], chKeys)[1]
      }
    }
    return tree
  }

  const getDefaultExpandedNodes = (tree) => {
    // Only one level of nodes to be expanded
    const keys = []
    const children = tree[1]
    keys.push(tree[0])
    children.forEach((n) => keys.push(n[0]))
    return keys
  }

  const createdTree = createTree(rootNode, rootChildrens)
  const ui = renderTreeUI(structuredClone(createdTree))

  if (renderRawUI) return ui

  return (
    <TreeView
      selected={selectedNodeKey}
      defaultExpanded={
        defaultExpandedNodeIds.length
          ? defaultExpandedNodeIds
          : getDefaultExpandedNodes(createdTree)
      }
      onNodeFocus={function noRefCheck() {}}
      onNodeSelect={(_, nodeId) => onNodeClick && onNodeClick?.(nodeId)}
      onNodeToggle={function noRefCheck() {}}
      sx={{
        flexGrow: 1,
        overflowY: 'auto'
      }}
    >
      {ui}
    </TreeView>
  )
}

export { TreeViewExtended }
