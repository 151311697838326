import { DataTablExtended, LoadingFeedback } from 'V2Components'
import { SummaryHeader } from 'features/resources'
import { Button, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { columns, useADDomainControllers } from './ADDomainControllers.utils'
import { apiClient } from 'infra/api'
import useMultiSlice from 'Core/Hooks/useMultiSlice'

const ADDomainControllersUser = () => {
  const [adPassword, setAdPassword] = useState([])
  const { slices } = useMultiSlice(['adPasswords'])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getRowData()
  }, [slices.adPasswords])

  const getRowData = async () => {
    try {
      const response = await apiClient('adpasswords').getAll()
      setAdPassword(response || [])
    } catch (error) {
      console.error('Error fetching data:', error)
      setAdPassword([])
    }
  }

  const onSaveSuccess = async () => {
    await getRowData()
    setIsLoading(false)
  }

  const { rowDataAdapterFn } = useADDomainControllers({ onSaveSuccess, setIsLoading })

  return (
    <div>
      <div className='bg-[#F9FBFC] -mx-4 -mt-6 p-4'>
        <SummaryHeader
          rsrcName={'AD Domain Controllers'}
          Icon={({ ...props }) => <TargetIcon type='AD_DOMAIN_CONTROLER' {...props} />}
          actions={<></>}
        />
      </div>
      <div className='pt-2'>
        {adPassword?.length !== 0 && (
          <Typography variant='body-regular'>{adPassword?.length} AD Domain Controllers</Typography>
        )}
        <div className='mt-4'>
          <DataTablExtended
            disableRowSelectionOnClick
            data={adPassword}
            getColumns={columns}
            rowDataAdapter={rowDataAdapterFn}
            disableVirtualList
          />
        </div>
      </div>
      <LoadingFeedback caption='Please wait..' loading={isLoading} message='Updating The Data!' />
    </div>
  )
}

export { ADDomainControllersUser }
