import { Pagination } from 'V2Components'
import { DataTable } from 'procyon-ui'
import React, { useCallback, useState } from 'react'

/**
 *@typedef {{
 * handleTableCellClick?: any,
 * getColumns: (() => import("@mui/x-data-grid").GridColDef[])
 * data: any[]
 * rowDataAdapter:(() => any)
 * initialState?:any
 * disableVirtualList?: boolean
 * }} ExtraProps
 * @param {ExtraProps & Omit<import('procyon-ui').DataTableProps, 'rows'|'columns'>} param0
 * @returns
 */
const DataTablExtended = ({
  handleTableCellClick,
  getColumns,
  data,
  rowDataAdapter,
  initialState,
  disableVirtualList,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1)
  const [paginationIndex, setPaginationIndex] = useState({
    start: 0,
    end: PER_PAGE_COUNT
  })

  const getPaginatedData = useCallback(() => {
    if (disableVirtualList) return data
    return data.slice(paginationIndex.start, paginationIndex.end)
  }, [data, paginationIndex, disableVirtualList])

  const getRows = useCallback(() => {
    return getPaginatedData().map(rowDataAdapter)
  }, [rowDataAdapter, getPaginatedData])

  return (
    <div>
      <DataTable
        width='100%'
        autoHeight
        onCellClick={handleTableCellClick}
        columns={getColumns()}
        rows={getRows()}
        initialState={initialState}
        hideFooter={!disableVirtualList}
        {...props}
      />
      {!disableVirtualList && (
        <Pagination
          page={activePage}
          onPageChange={setActivePage}
          count={data.length}
          onIndexChange={setPaginationIndex}
          itemsPerPage={PER_PAGE_COUNT}
        />
      )}
    </div>
  )
}

const PER_PAGE_COUNT = 10

export { DataTablExtended }
