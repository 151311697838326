import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { DeviceInfoBar } from 'V2Components'
import { getUserOwnedDevices } from 'features/users'
import { Typography } from 'procyon-ui'
import React from 'react'

function DevicesTab({ user }) {
  const { slices } = useMultiSlice(['deviceList'])

  const devices = getUserOwnedDevices(slices.deviceList, user)

  return (
    <div className='h-[500px] overflow-y-auto'>
      {!devices.length && <Typography variant='body-regular'>No devices to show.</Typography>}
      {devices.map((d) => (
        <DeviceInfoBar device={d} />
      ))}
    </div>
  )
}

export { DevicesTab }
