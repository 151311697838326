import { InfoRounded, Settings, Warning, Input, PermIdentity, Build, Assignment, Storage } from '@material-ui/icons'

export const chipsItems = [
  {
    Icon: Input,
    label: '2',
    color: 'default',
    variant: 'warning'
  },
  {
    Icon: PermIdentity,
    label: '2',
    color: 'default',
    variant: 'warning'
  },
  {
    Icon: Build,
    label: '2',
    color: 'default',
    variant: 'primary'
  },
  {
    Icon: Assignment,
    label: '2',
    color: 'default',
    variant: 'primary'
  },
  {
    Icon: Storage,
    label: '2',
    color: 'default',
    variant: 'secondary'
  }
]

export const logItems = [
  {
    Icon: Input,
    Text: 'Chewaka has requested access to Server 1. Go to Request Approvals to approve or reject access.',
    variant: 'warning',
    metaData: { ID: 1001 }
  },
  {
    Icon: PermIdentity,
    Text: 'Alert link alert description.',
    variant: 'warning',
    metaData: { ID: 1002 }
  },

  {
    Icon: Build,
    Text: 'Alert link alert description.',
    variant: 'primary',
    metaData: { ID: 1002 }
  },
  {
    Icon: Assignment,
    Text: 'Alert link alert description.',
    variant: 'primary',
    metaData: { ID: 1002 }
  },
  {
    Icon: Storage,
    Text: 'Alert link alert description.',
    variant: 'secondary',
    metaData: { ID: 1002 },
    disableBorder: true
  }
]
