import { makeStyles } from '@material-ui/core'
import { NavLink } from 'Core'
import { FeatureFlagContext } from 'Core/FeatureFlagContext'
import useAppView from 'Core/Hooks/useAppView'
import React, { useContext, useEffect } from 'react'
const useStyle = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})
function NotFound() {
  const classes = useStyle()
  const { appView } = useAppView()

  return (
    <div className={classes.root}>
      <h3>The Route You Are Looking For Is Not Found.</h3>
      <NavLink to={`/${appView}`}>Go back to Home</NavLink>
    </div>
  )
}

export { NotFound }
