import { ApiProvider } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { KindToEndpointMap } from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import { LabelContent, LoadingFeedback } from 'V2Components'
import { Button, SelectDropDown } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const MeshClusterLabelContent = ({ rsrc }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [dbMeshCluster, setDbMeshCluster] = useState((rsrc && rsrc.Spec.MeshCluster) || '')
  const { slices, dispatchThunks } = useMultiSlice(['medusaNodes'])
  const endpoint = KindToEndpointMap[rsrc.ObjectMeta.Kind]
  /**
   *
   */
  const handleSpecUpdate = async () => {
    try {
      setIsSaving(true)
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.MeshCluster = dbMeshCluster
      await new ApiProvider(endpoint).setInstance(dataObj).put()
      enqueueNotification(`Mesh Cluster saved successfully!`, 'info')
    } catch (error) {
    } finally {
      setIsSaving(false)
    }
  }

  const handleRunCheck = async () => {
    try {
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.MeshCluster = ''
      dataObj.Spec.MeshClusterNodes.ObjectRef = []
      await new ApiProvider(endpoint).setInstance(dataObj).put()
      enqueueNotification(`Run checked successfully!`, 'info')
    } catch (error) {
    } finally {
    }
  }

  const getPrivateProxyClusterNames = () => {
    if (!rsrc) return []
    const clusterIDs = [dbMeshCluster]
    const MeshClusterNodes = rsrc.Spec.MeshClusterNodes.ObjectRef
    const existingSelectedClusterId =
      rsrc?.Spec?.MeshCluster !== ''
        ? slices.medusaNodes.find((node) => node.ClusterID === rsrc.Spec.MeshCluster)
        : {}

    const existingSelectedClusterRef = {
      RefKind: existingSelectedClusterId?.ObjectMeta?.Kind,
      RefID: existingSelectedClusterId?.ObjectMeta?.ID
    }

    const combinedClusterNodes = [...MeshClusterNodes]
    combinedClusterNodes.push(existingSelectedClusterRef)
    combinedClusterNodes?.forEach((element) => {
      const matchedNodeID = slices.medusaNodes.filter(
        (meta) => element.RefID === meta.ObjectMeta.ID
      )
      if (matchedNodeID.length > 0) {
        // Extract ClusterID values from matchedNodeID
        const clusterIDsFromMatchedNodes = matchedNodeID.map((node) => node)

        // Push the ClusterID values into clusterIDs
        clusterIDs.push(...clusterIDsFromMatchedNodes)
      }
    })

    const filteredArray = clusterIDs.filter((item) => typeof item === 'object')
    const updatedUniqueArray = []
    const uniqueNode = []
    filteredArray.map((item) => {
      if (uniqueNode.includes(item.ObjectMeta.ID)) {
        return
      } else {
        uniqueNode.push(item.ObjectMeta.ID)
        updatedUniqueArray.push(item)
      }
    })

    return [...updatedUniqueArray]
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <>
      <LabelContent
        title='Cluster Name'
        content={
          <div className='flex justify-between items-center gap-4'>
            <SelectDropDown
              showHelpText={false}
              className='!min-w-[200px]'
              menuItems={[
                ...getPrivateProxyClusterNames().map((e) => ({
                  label: e.ClusterID,
                  value: e.ClusterID
                }))
              ]}
              onChange={(e) => e.target.value && setDbMeshCluster(e.target.value)}
              value={dbMeshCluster}
            />

            <div className='flex gap-3'>
              <Button variant='primary' onClick={() => handleRunCheck()}>
                Run Check
              </Button>

              <Button onClick={() => handleSpecUpdate()} variant='primary'>
                Save
              </Button>
            </div>
          </div>
        }
      />
      <LoadingFeedback
        loading={isSaving}
        caption='Please wait...'
        message='Updating Resource Mesh Cluster'
      />
    </>
  )
}

export { MeshClusterLabelContent }
