/**
 * When a policy is created, for actions AwsIAM, GcpIAM and AzureIAM, a new action is added in the
 * policy object - AssumeRoe. This object holds the approle which gets created.
 *
 * While, the original policy data is move to the following action keys.
 */
export const IAMActionActionMapKeys = {
  OldAwsIAM: true,
  AwsIAM: true,
  AssumeRole: true,
  GcpIAM: true,
  AzureIAM: true,
  GithubAccess: true
}
