import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShimmerRow } from 'Utils/ShimmerEffect'
import { ChannelRow, TargetIcon, TreeItem, TreeView } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useChannelBrowser } from '../../../providers/hooks'

const MsWebhookChannels = ({
  handleManageAccount,
  handleUnlinkAllAccount,
  getCloudsAccountCount,
  getTotalLinkedAccount
}) => {
  const [showShimmer, setShowShimmer] = useState(true)
  const { integration } = useChannelBrowser()
  useEffect(() => {
    setTimeout(() => {
      setShowShimmer(false)
    }, 1000)
  }, [])

  return (
    <div>
      {showShimmer ? (
        <div className='flex flex-col w-100% '>
          <ShimmerRow />
        </div>
      ) : (
        <ChannelRow
          key={999}
          awsButtonNumber={getCloudsAccountCount(integration, 'AWS').length}
          azureButtonNumber={getCloudsAccountCount(integration, 'AZURE').length}
          channelName={'Webhook url channel'}
          dropdownMenuItems={[
            {
              submenu: [
                {
                  action: () => handleManageAccount(integration),
                  title: 'Manage'
                },
                {
                  action: () => handleUnlinkAllAccount(integration),
                  title: 'Unlink All'
                }
              ],
              title: <FontAwesomeIcon icon={faEllipsisVertical} />
            }
          ]}
          googleButtonNumber={getCloudsAccountCount(integration, 'GCP').length}
          onClickAwsButton={function noRefCheck() {}}
          onClickAzureButton={function noRefCheck() {}}
          onClickGoogleButton={function noRefCheck() {}}
          onClickRepositories={function noRefCheck() {}}
          onClickUserGroups={function noRefCheck() {}}
          showAwsButton
          showAzureButton
          showDropdownButton
          showGoogleButton
          totalAccounts={`Total ${getTotalLinkedAccount(integration).length}`}
          type={'TEAMS_CHANNEL'}
        />
      )}
    </div>
  )
}

export { MsWebhookChannels }
