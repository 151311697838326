import { Box } from '@material-ui/core'
import { Storage } from '@material-ui/icons'
import ChartContainer from 'Components/Charts/ChartContainer'
import DatasetSelector from 'Components/Charts/ChartContainer/Components/DatasetSelector'
import SeriesFilter from 'Components/Charts/ChartContainer/Components/SeriesFilter'
import MultiLineChart from 'Components/Charts/MultiLineChart'
import { Theme, _ } from 'Core'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiQueryGraph from 'Core/Hooks/useMultiQueryGraph'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import React, { useMemo, useRef } from 'react'

const AccountActivityGraph = ({ title, mt = 2, className, loadGraph = false, user = false, accountName = false }) => {
  const queryIndexes = useMemoOnce(['event-log', 'proxy-audit-log'])
  const { slices: { appRolesList, serverList, accountList } } = useMultiSlice(['appRolesList', 'serverList', 'accountList'])
  const { loading, onTillTimeChange, graphData } = useMultiQueryGraph(queryIndexes, {
    accountName,
    accountList,
    serverList,
    appRolesList,
    userName: user,
    defaultTimeRange: false
  })
  const seriesMeta = useRef([
    {
      name: 'event-log',
      label: 'IAM Role',
      hoverText: 'Activity on {name} in {categoryX}: {valueY}',
      color: Theme.palette.info.light,
      Icon: ({ className }) => <img className={className} src='img/icons/iam-role.png' />
    },
    {
      name: 'proxy-audit-log',
      label: 'Targets',
      hoverText: 'Activity on {name} in {categoryX}: {valueY}',
      color: Theme.palette.warning.main,
      Icon: ({ className }) => <Storage className={className} />
    }
  ])

  /** Axis configurations for the chart */
  const xAxis = useRef({ name: 'formattedDate', label: 'Month' })
  const yAxis = useRef({ name: 'Resource Type', label: '' })

  /** Custom Styles for chart */
  const chartStyles = { height: '17rem', width: '100%' }
  /** Create header dropdowns for chart container */

  const filters = useRef([
    {
      name: 'date',
      value: '-',
      filterType: 'TillDate',
      filterOptions: [
        { label: 'Last Month', name: '1 months' },
        { label: 'Last Quarter', name: '3 months' },
        { label: 'Last Year', name: '12 months' }
      ]
    }
  ])

  const externalFilterHandlers = useMemo(
    () => ({
      date: onTillTimeChange
    }),
    [onTillTimeChange]
  )

  return (
    <Box mt={mt}>
      <ChartContainer
        loading={loading || !loadGraph}
        externalFilterHandlers={externalFilterHandlers}
        parent='Account Actiity'
        height='100%'
        title={title}
        Graph={MultiLineChart}
        chartStyles={chartStyles}
        seriesMeta={seriesMeta.current}
        xAxis={xAxis.current}
        yAxis={yAxis.current}
        className={className}
        dataSets={graphData}
        defaultFilters={filters.current}
        DatasetComponent={!user ? DatasetSelector : null}
        SeriesFilterComponent={({ changeFilter, filters }) => (
          <SeriesFilter position='right' filters={filters} changeFilter={changeFilter} />
        )}
      />
    </Box>
  )
}

export default React.memo(AccountActivityGraph)
