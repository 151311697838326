import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { cn } from 'Utils/Helpers'
import { isChildAccount, isOrgAccount, useCloudAccounts } from 'features/clouds'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { ActionBar, TargetIcon } from 'procyon-ui'
import React from 'react'

const AWSAccountsSelector = ({ accounts = [], selectedAccounts, setSelectedAccounts }) => {
  const { getObjectRef } = useMultiSlice(['accountList'])
  const { uiGetAccountTargetIconType, getChildAccounts } = useCloudAccounts()

  const createAccountHierarch = (accs = []) => {
    const map = {
      orphans: []
    }
    accs.forEach((a) => {
      if (isOrgAccount(a)) {
        const children = getChildAccounts(a, accounts)
        const refs = children.map(createRsrcKey)
        map[createRsrcKey(a)] = refs
        return
      }

      if (isChildAccount(a)) {
        const parentRef = a.Spec.ParentAccount
        const parentKey = createRsrcKey(parentRef)
        if (!map[parentKey] && !accounts.find((a) => a.ObjectMeta.ID === parentRef.RefID))
          map.orphans.push(createRsrcKey(a))
        return
      }
      //Normal account
      map.orphans.push(createRsrcKey(a))
    })

    return map
  }

  const renderMap = (map = {}) => {
    const flatElems = []
    const orphans = map.orphans || []
    const orgAccountsRefs = Object.keys(map).filter((k) => k !== 'orphans')

    orgAccountsRefs.forEach((ref) => {
      const children = map[ref]
      flatElems.push({
        key: ref,
        type: 'ORG',
        account: getObjectRef(reverseRsrcKey(ref))
      })
      flatElems.push(
        ...children.map((c) => ({
          key: c,
          type: 'CHILD',
          account: getObjectRef(reverseRsrcKey(c))
        }))
      )
    })

    flatElems.push(
      ...orphans.map((o) => ({
        key: o,
        type: 'NORMAL',
        account: getObjectRef(reverseRsrcKey(o))
      }))
    )

    return flatElems
  }

  const mainMap = createAccountHierarch(accounts)
  const accountsHierarched = renderMap(mainMap)

  return (
    <>
      {accountsHierarched.map((acc, i) => {
        const style =
          acc.type === 'CHILD' ? { width: '100%', paddingLeft: '28px' } : { width: '100%' }
        return (
          <div
            className={cn({
              'border-t': i > 0 && acc.type !== 'CHILD',
              'border-b': i === accountsHierarched.length - 1
            })}
          >
            <ActionBar
              showActionButton={acc.type !== 'ORG'}
              assigned={selectedAccounts.includes(acc.key)}
              onClickRemoveButton={() => setSelectedAccounts((s) => s.filter((e) => e !== acc.key))}
              onClickAddButton={() => setSelectedAccounts((s) => [acc.key, ...s])}
              key={acc.key}
              style={style}
              // @ts-ignore
              leftIcon={<TargetIcon type={uiGetAccountTargetIconType(acc.account)} />}
              name={getResourceName(acc.account)}
            />
          </div>
        )
      })}
    </>
  )
}

export { AWSAccountsSelector }
