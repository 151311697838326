import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { ActionBar, Button, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const useSlices = createDataSelectorHook(['adDomainControllers'])

const RDPADDCSetupModal = ({ rdpServer, onCancel, onSuccess }) => {
  const addcKey = createRsrcKey(_.get(rdpServer, 'Spec.ADDomainController', ''))
  const [selectedADDCKey, setSelectedADDCKey] = useState(addcKey || '')
  const [isLoading, setIsLoading] = useState(false)
  const {
    slices: { adDomainControllers }
  } = useSlices()

  const handleSubmitClick = async () => {
    try {
      setIsLoading(true)
      const cloned = structuredClone(rdpServer)
      const newADDCRef = reverseRsrcKey(selectedADDCKey)
      cloned.Spec.ADDomainController = newADDCRef
      await reduxApiClient('rdpservers').update(cloned)
      enqueueNotification('Successfully updated ADDC for RDP Server.', 'info')
      onSuccess?.()
    } catch (error) {
      enqueueNotification('Error trying to update ADDC', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const getItems = () => {
    return adDomainControllers.map((addc) => {
      return {
        name: getResourceName(addc),
        key: createRsrcKey(addc),
        o: addc,
        caption: addc.Spec.DefaultFor.Elems.length
          ? `Default For: ${addc.Spec.DefaultFor.Elems.join()}`
          : ''
      }
    })
  }

  useHTMLBodyScrollbar(true)

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>
          Setup AD Domain Controller for <strong>{getResourceName(rdpServer)}</strong>
        </Typography>
      </div>
      <div className='mt-4 -ml-4'>
        {getItems().map((e) => (
          <ActionBar
            selected={e.key === selectedADDCKey}
            assigned={e.key === selectedADDCKey}
            key={e.key}
            caption={e.caption}
            leftIcon={<TargetIcon height='28px' type='AD_DOMAIN_CONTROLER' width='28px' />}
            name={e.name}
            showActionButton
            onClickAddButton={() => setSelectedADDCKey(e.key)}
            onClickRemoveButton={() => setSelectedADDCKey('')}
          />
        ))}
      </div>
      <div className='mt-4 flex justify-end gap-4'>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
        <Button
          onClick={handleSubmitClick}
          disabled={addcKey === selectedADDCKey}
          variant='primary'
        >
          Save
        </Button>
      </div>
      <LoadingFeedback
        loading={isLoading}
        caption='Please wait..'
        message='Changing the AD Domain Controller'
      />
    </FullScreenContentModal>
  )
}

export { RDPADDCSetupModal }
