import React from 'react'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import { Bundles } from '../views/Bundles'
import { BundleTypeInfo } from '../views/BundleTypeInfo'
import { BundleDetails } from '../views/BundleDetails'

const BundlesRoutes = () => {
  return (
    <>
      <Route exact path={['/user/bundles']} component={Bundles} />
      <Route exact path={['/user/bundles/:bundleType']} component={BundleTypeInfo} />
      <Route exact path={['/user/bundles/:bundleType/:bundleName']} component={BundleDetails} />
      <Route exact path={['/admin/bundles']} component={Bundles} />
      <Route exact path={['/admin/bundles/:bundleType']} component={BundleTypeInfo} />
      <Route exact path={['/admin/bundles/:bundleType/:bundleName']} component={BundleDetails} />
    </>
  )
}

export { BundlesRoutes }
