import {
  EntityInfoBar,
  EntityPicker,
  FullScreenContentModal,
  entityDataAdapters
} from 'V2Components'
import { useTagsCreatePolicyContext } from 'features/tags'
import { createDataSelectorHook } from 'infra/redux'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const useSlices = createDataSelectorHook(['userList', 'groupList', 'serviceAccounts'])

const UsersTab = () => {
  const [showEntitiesModal, setShowEntitiesModal] = useState(false)
  const { entities, setEntities, account } = useTagsCreatePolicyContext()

  const { slices } = useSlices()

  const type = account ? account.Spec.Type : ''

  const serviceAccountFilter = (serviceAccount) => {
    const loading =
      !serviceAccount?.Status?.GcpStatus?.Email &&
      !serviceAccount?.Status?.AzureStatus?.DisplayName &&
      !serviceAccount?.Status?.AwsStatus?.UserName
    const hasError = serviceAccount?.Status?.Status?.Status === '0'
    return !loading && !hasError
  }

  const getEntities = () => {
    const list = [...slices.userList, ...slices.groupList]
    list.push(
      ...slices.serviceAccounts.filter((e) => e.Spec.Type === type && serviceAccountFilter(e))
    )

    return list
  }

  const entitiesList = getEntities()

  const getEntitiesData = () => {
    return entities.map((e) => entityDataAdapters[e.ObjectMeta.Kind]?.(e))
  }

  return (
    <div>
      <div className='flex justify-between items-center mb-4'>
        <Typography variant='h4-regular'>Entities</Typography>
        <Button onClick={() => setShowEntitiesModal(true)} variant='grayBlue'>
          Manage Entities
        </Button>
      </div>
      <div>
        {entities.length === 0 && (
          <Typography variant='body-regular'>No Entities Selected</Typography>
        )}
        {getEntitiesData().map((data) => {
          return (
            <EntityInfoBar
              endButton={false}
              caption={data.caption}
              kind={data.ref.RefKind}
              name={data.name}
            />
          )
        })}
      </div>
      {showEntitiesModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title={`Select Entities in this Policy`}
            errorMessage={entities.length < 1 && 'Select At least one entity.'}
            continueText='Save'
            entities={entitiesList}
            selectedEntities={entities}
            setSelectedEntities={setEntities}
            onCancel={() => {
              setShowEntitiesModal(false)
            }}
            onContinue={() => setShowEntitiesModal(false)}
          />
        </FullScreenContentModal>
      )}
    </div>
  )
}

export { UsersTab }
