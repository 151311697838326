import { faSignalBars } from '@fortawesome/pro-solid-svg-icons'
import { getAWSResourceIcon } from 'Components/CustomIcons/AWSResources'
import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'
import { getGCPResourceIcon } from 'Components/CustomIcons/GCPResources'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { LabelContent } from 'V2Components'
import { OverviewTab, getResourceName, useResourceDetails } from 'features/resources'

import _ from 'lodash'
import { Label, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const AwsResourceOverview = ({ rsrc, account }) => {
  if (!rsrc) return null

  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))
  const TypeIcon = getAWSResourceIcon(rsrc)

  return (
    <>
      <LabelContent
        title='Status'
        content={<Label text='Online' variant='grayBlue' iconButton={faSignalBars} />}
      />
      <LabelContent title='Region' content={rsrc.Spec.Region} />
      <LabelContent
        title='Type'
        content={
          <div className='flex items-center'>
            <TypeIcon className='mr-2 rounded-md' />
            <Typography variant='body-semiBold'>{rsrc.Spec.Type.replaceAll('_', ' ')}</Typography>
          </div>
        }
      />
      <LabelContent title='ARN' content={rsrc.Spec.Arn} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
    </>
  )
}

const GcpResourceOverview = ({ rsrc, account }) => {
  if (!rsrc) return null

  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))
  const TypeIcon = getGCPResourceIcon(rsrc)

  return (
    <>
      <LabelContent
        title='Status'
        content={<Label text='Online' variant='grayBlue' iconButton={faSignalBars} />}
      />
      <LabelContent title='Name' content={rsrc.Spec.DisplayName} />
      <LabelContent title='ID' content={rsrc.Spec.Id} />
      <LabelContent
        title='Type'
        content={
          <div className='flex items-center'>
            <TypeIcon className='mr-2 rounded-md' />
            <Typography variant='body-semiBold'>
              {rsrc.Spec.DisplayType.replaceAll('_', ' ')}
            </Typography>
          </div>
        }
      />
      <LabelContent title='Region' content={rsrc.Spec.Region || '-'} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
    </>
  )
}

const AzureResourceOverview = ({ rsrc, account }) => {
  if (!rsrc) return null

  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))
  const TypeIcon = getAZUREResourceIcon(rsrc)

  return (
    <>
      <LabelContent
        title='Status'
        content={<Label text='Online' variant='grayBlue' iconButton={faSignalBars} />}
      />
      <LabelContent title='Name' content={rsrc.Spec.DisplayName} />
      <LabelContent
        title='Type'
        content={
          <div className='flex items-center'>
            <TypeIcon className='mr-2 rounded-md' />
            <Typography variant='body-semiBold'>{rsrc.Spec.DisplayType}</Typography>
          </div>
        }
      />
      <LabelContent title='Region' content={rsrc.Spec.Region} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
    </>
  )
}

const KubeNamespaceOverview = ({ rsrc, account }) => {
  const { getObjectRef } = useMultiSlice(['awsResources', 'gcpResources', 'kubeClusters'])
  const [cluster, setCluster] = useState(null)

  if (!rsrc) return null

  if (!cluster && rsrc.Spec.Cluster.RefKind) {
    const refObj = getObjectRef(rsrc.Spec.Cluster)
    if (refObj) setCluster(refObj)
  }

  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))

  return (
    <>
      <LabelContent
        title='Status'
        content={<Label text='Online' variant='grayBlue' iconButton={faSignalBars} />}
      />
      <LabelContent title='Name' content={rsrc.Spec.Name} />

      <LabelContent
        title='Cluster'
        content={cluster && <Label text={cluster?.Spec.Name} variant='grayBlue' />}
      />
      <LabelContent title='Region' content={rsrc.Spec.Region} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
    </>
  )
}

const InfoMap = {
  AwsResource: AwsResourceOverview,
  GcpResource: GcpResourceOverview,
  AzureResource: AzureResourceOverview,
  KubeNamespace: KubeNamespaceOverview
}

function Overview() {
  const { rsrc, account } = useResourceDetails()

  const Component = InfoMap[rsrc.ObjectMeta.Kind]

  return (
    <OverviewTab
      ResourceInfoComponent={Component ? <Component rsrc={rsrc} account={account} /> : null}
    />
  )
}

export { Overview }
