//prettier-ignore
export const MapKindToSliceName = {
  ADDomainController: 'adDomainControllers',
  Account         : 'accountList',
  AppRole         : 'appRolesList',
  Application     : 'applicationList',
  ApprovalReq     : 'approvalReqList',
  AwsResource     : 'awsResources',
  AzureResource   : 'azureResources',
  Database        : 'databases',
  GcpResource     : 'gcpResources',
  GithubResource  : 'githubResources',
  GithubAccount   : 'githubAccount',
  Group           : 'groupList',
  IamAction       : 'iamActions',
  KubeNamespace   : 'kubeNamespaces',
  Kafka           : 'kafkas',
  Org             : 'orgs',
  PacPolicy       : 'policyList',
  PaaPolicy       : 'paaPolicyList',
  Project         : 'projects',
  ProxyController : 'proxycontrollers',
  Server          : 'serverList',
  ServerController: 'serverControllers',
  ServiceAccount  : 'serviceAccounts',
  ServiceNowTable : 'servicenowtables',
  TagPolicy       : 'tagPolicys',
  TenantProfile   : 'tenantprofiles',
  RDPServer       : 'rdpServers',
  User            : 'userList',
  SlackChannel    : 'slackChannelList',
  JiraProject     : 'jiraProjectList',
  Approvers       : 'approvers',
  MedusaNode      : 'medusaNodes',
  WorkloadIdentity: 'workloads',
  KubeCluster     : 'kubeClusters',
  NotificationSubscription: 'notificationsubscriptions',
  ReqBundle: 'bundles',
  rdppasswords: 'rdppasswords',
  //!TODO Remove these non-kind keys
  SalesForceUsersList: 'salesForceUsersList',
  SalesForcePermissionList: 'salesForcePermissionList',
  SalesForceAccountList: 'salesForceAccountList',
  SalesForceUserPermissionBinding: 'salesForceUserPermissionBinding',
  SalesforceUser: 'salesForceUsersList',
  CRMEntity: 'salesForcePermissionList',
  SnowflakeRole: 'snowFlakeRoles',
  SalesforceAccount: 'salesForceAccountList',
  DatabricksResource: 'dataBricksResources',
}

export const MapSliceNameToKind = {
  "adDomainControllers": "ADDomainController",
  "accountList": "Account",
  "appRolesList": "AppRole",
  "applicationList": "Application",
  "approvalReqList": "ApprovalReq",
  "awsResources": "AwsResource",
  "azureResources": "AzureResource",
  "databases": "Database",
  "gcpResources": "GcpResource",
  "githubResources": "GithubResource",
  "githubAccount": "GithubAccount",
  "groupList": "Group",
  "iamActions": "IamAction",
  "kubeNamespaces": "KubeNamespace",
  "kafkas": "Kafka",
  "orgs": "Org",
  "policyList": "PacPolicy",
  "paaPolicyList": "PaaPolicy",
  "proxyControllers": "ProxyController",
  "projects": "Project",
  "serverList": "Server",
  "serverControllers": "ServerController",
  "serviceAccounts": "ServiceAccount",
  "servicenowtables": "ServiceNowTable",
  "tagPolicys": "TagPolicy",
  "tenantprofiles": "TenantProfile",
  "rdpServers": "RDPServer",
  "userList": "User",
  "slackChannelList": "SlackChannel",
  "jiraProjectList": "JiraProject",
  "approvers": "Approvers",
  "medusaNodes": "MedusaNode",
  "workloads": "WorkloadIdentity",
  "kubeClusters": "KubeCluster",
  "notificationsubscriptions": "NotificationSubscription",
  "bundles": "ReqBundle",
  "rdppasswords": "rdppasswords",
  "salesForceUsersList": "SalesforceUser",
  "salesForcePermissionList": "CRMEntity",
  "salesForceAccountList": "SalesforceAccount",
  "salesForceUserPermissionBinding": "SalesForceUserPermissionBinding",
  "snowFlakeRoles": "SnowflakeRole",
  "dataBricksResources": "DatabricksResource"
}
