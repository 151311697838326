import superSearch from '@codewell/super-search'
import { Box, Typography } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import { StatusMark } from 'Components/StatusMark'
import GeneralItem from 'Components/TableRowCompoents/GeneralItem'
import moment from 'moment'
import React from 'react'
import { sortCompareHOF } from 'Utils/PureHelperFuctions'

export const options = (onView) => ({
  filterType: 'none',
  rowsPerPage: 10,
  download: false,
  print: false,
  filter: false,
  search: false,
  viewColumns: false,
  pagination: true,
  onRowClick: onView,
  rowsPerPageOptions: [5, 10, 15, 100],
  customSearch: (searchText, row) => superSearch(searchText.toLowerCase(), { ...row }).numberOfMatches > 0,
  customToolbar: ({ displayData }) => null,
  customToolbarSelect: () => null,
  selectableRowsHideCheckboxes: true
})

export const columns = ({ classes }) => [
  {
    name: 'DeviceName',
    label: 'Device Name',
    options: {
      display: true,
      sort: true,
      sortCompare: sortCompareHOF,
      customBodyRender: (value, tableMeta) => {
        return (
          <Box>
            <GeneralItem
              fontSize='body2'
              noMarginTop
              data={value.name}
              Component={value.active && <span className={classes.you}>You</span>}
            />
          </Box>
        )
      }
    }
  },

  {
    name: 'UserName',
    label: 'User',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return <GeneralItem fontSize='body2' noMarginTop data={value} />
      }
    }
  },
  {
    name: 'approved',
    label: 'Approved',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <StatusMark status={value?.status} title={value?.title} justifyContent='center' />
      }
    }
  },
  {
    name: 'OperatingSystem',
    label: 'OS',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return <GeneralItem fontSize='body2' noMarginTop data={value} />
      }
    }
  },
  {
    name: 'Model',
    label: 'Model',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return <GeneralItem fontSize='body2' noMarginTop data={value} />
      }
    }
  },
  {
    name: 'CreatedAt',
    label: 'Onboarded At',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return (
          <Box display='flex' alignItems='center'>
            <AccessTime className={classes.timerIcon} />
            <Typography className={classes.date} variant='body2'>
              {moment(value).format('YYYY/MM/DD  HH:mm:ss A')}
            </Typography>
          </Box>
        )
      }
    }
  },
  {
    name: 'ID',
    label: 'ID',
    options: {
      display: false,
      filter: false
    }
  }
]
