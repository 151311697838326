import { getResourceName, getRsrcIcon } from 'features/resources'
import {
  getServerControllerGroupsMap,
  getServerControllerTags
} from 'features/serverControllers/utils'
import { useObjectRef } from 'infra/redux'
import { Label, TextInput, Typography } from 'procyon-ui'
import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'
import { FullScreenContentModal, LabelContent } from 'V2Components'
import { CreateServerControllerWizard } from '../CreateServerControllerWizard'
import { Header } from './components/Header'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router'

const ServerControllerDetails = () => {
  const srvCtrl = useDataDetailsContext()
  const { getObjectRef } = useObjectRef(['accountList'])

  const accounts = getObjectRef(srvCtrl.Spec.Accounts.ObjectRef)

  const { labels, ctags } = getServerControllerTags(srvCtrl)

  const users = srvCtrl.Spec.Users.Elems
  const groups = srvCtrl.Spec.Groups.Elems
  const groupsMap = getServerControllerGroupsMap(srvCtrl)
  const sudoers = srvCtrl.Spec.Sudoers.Elems

  const history = useHistory()

  return (
    <div>
      <Header />
      <div className='flex gap-4'>
        <div className='w-1/2 pr-2 border-r'>
          <Typography className='mt-4' variant='h4-regular'>
            Information
          </Typography>
          <LabelContent
            title='Accounts'
            fullWidth
            content={
              <div className='flex gap-1 flex-wrap'>
                {!accounts.length && <Label text='None' />}
                {accounts.map((acc) => {
                  const Icon = getRsrcIcon(acc)
                  return <Label icon={<Icon />} text={getResourceName(acc)} />
                })}
              </div>
            }
          />
          <LabelContent
            fullWidth
            title='Users'
            content={
              <div className='flex gap-1 flex-wrap'>
                <Typography variant='body-regular'>{users.join()}</Typography>
              </div>
            }
          />
          <LabelContent
            fullWidth
            title='Sudoer Policies'
            content={
              <div className='flex w-[100%] gap-1 flex-wrap'>
                <TextInput
                  readOnly
                  value={sudoers.reduce((prev, curr) => {
                    return prev + curr + '\n'
                  }, '')}
                  sx={{ width: '100%' }}
                  multiline
                  rows={5}
                />
              </div>
            }
          />
          <LabelContent
            title='Labels'
            fullWidth
            content={
              <div className='flex gap-1 flex-wrap'>
                {!labels.length && <Label text='None' />}
                <Typography variant='body-regular'>{labels.join()}</Typography>
              </div>
            }
          />
          <LabelContent
            title='CTags'
            fullWidth
            content={
              <div className='flex gap-1 flex-wrap'>
                {!ctags.length && <Label text='None' />}
                <Typography variant='body-regular'>{ctags.join()}</Typography>
              </div>
            }
          />
          <LabelContent
            title='Anonymous Login'
            fullWidth
            content={
              srvCtrl.Spec.DisableAnonymousLogin ? (
                <Label text='Disabled' />
              ) : (
                <Label variant='success' text='Enabled' />
              )
            }
          />
          <LabelContent
            title='OS Login'
            fullWidth
            content={
              srvCtrl.Spec.DisableOsLogin ? (
                <Label text='Disabled' />
              ) : (
                <Label variant='success' text='Enabled' />
              )
            }
          />
        </div>
        <div className='w-1/2'>
          <Typography className='mt-4' variant='h4-regular'>
            Groups
          </Typography>
          {groups.map((grpName) => {
            const users = groupsMap[grpName] || []
            return (
              <LabelContent
                fullWidth
                title={grpName}
                content={
                  <div className='flex gap-1 flex-wrap'>
                    <Typography variant='body-regular'>{users.join()}</Typography>
                  </div>
                }
              />
            )
          })}
        </div>
      </div>
      {/* @ts-ignore */}
      <Route exact path={[`/admin/server-controllers/:name/edit`]}>
        <FullScreenContentModal>
          <CreateServerControllerWizard
            srvCtrl={srvCtrl}
            onCancel={() => history.goBack()}
          />
        </FullScreenContentModal>
      </Route>
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider routeKey='name' sliceName='serverControllers'>
    <ServerControllerDetails />
  </DataDetailsProvider>
)

export { Wrapped as ServerControllerDetails }
