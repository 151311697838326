import { Select, MenuItem, makeStyles } from '@material-ui/core'

import { Pagination } from '@material-ui/lab'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    '& > span': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  select: {
    height: '30px',
    margin: '0px 10px',
    width: '90px',
    textAlign: 'center',
    fontSize: theme.typography.fontSize
  },
  menuitem: {
    fontSize: theme.typography.fontSize

  }
}))
const PageinationInfo = ({ page, rowsPerPage, count }) => {
  const lowerBar = (page + 1) * rowsPerPage - rowsPerPage
  const upperBar =
    lowerBar + rowsPerPage > count ? count : lowerBar + rowsPerPage
  return (
    <span>
      {lowerBar}-{upperBar} of {count}
    </span>
  )
}
const CustomFooter = ({
  count,
  page,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  numberOfRowsOptions
}) => {
  const classes = useStyle()
  return (
    count > 0 && (
      <div className={classes.root}>
        {/* Pagination Meta Controls and Information */}
        <span>
          <label>Rows per page</label>
          <Select
            value={rowsPerPage}
            className={classes.select}
            onChange={(event) => {
              changeRowsPerPage(event.target.value)
              changePage(0)
            }}
            variant='outlined'
          >
            {numberOfRowsOptions &&
              numberOfRowsOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
          <PageinationInfo
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
          />
        </span>
        {/* Pagination Controls */}
        <Pagination
          page={page + 1}
          onChange={(event, value) => {
            console.log('page change', value - 1)
            changePage(value - 1)
          }}
          count={Math.ceil(count / rowsPerPage)}
        />
      </div>
    )
  )
}

export default CustomFooter
