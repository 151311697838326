import { RelationAccessGraph } from 'V2Components'
import React from 'react'
import { useAccessGraphTab } from './AccessGraphTab.utils'
import { Typography } from 'procyon-ui'
import { useUser } from 'Core/Hooks/useUser'

function AccessGraphTab() {
  const {isAgentlessMode} = useUser()
  const { getNodes, getNodesRelationObject, isEmpty } = useAccessGraphTab()
  
  // filter out the resource type application if user in agentless mode
  const getAllNodes = getNodes()
  const filteredNodes = !isEmpty && getAllNodes
  .map(innerArray => innerArray.filter(item => item.ObjectMeta.Kind !== "Application"))
  .filter(innerArray => innerArray.length > 0);
  const updatedNodes = isAgentlessMode ? filteredNodes : getAllNodes


  if (isEmpty) {
    return <Typography variant='body-regular'>User has access to 0 resources.</Typography>
  }

  return (
    <div>
      <RelationAccessGraph nodeObjects={updatedNodes} relationObject={getNodesRelationObject()} />
    </div>
  )
}

export { AccessGraphTab }
