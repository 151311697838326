import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { deleteServer } from 'features/targets'
import React from 'react'

function DeletePrivateServerModal({ server, onCancel, onSuccess }) {

  const handleDeleteServer = async () => {
    const deletedServer = await deleteServer(server)
    deleteSliceData(deletedServer)
		enqueueNotification('Private Server Deleted Successfully!', 'info')
		onSuccess?.()
  }

  const handleCancel = () => {
    onCancel?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage='Are you sure you want to delete this private server?'
        loadingMessage='Deleting Private Server'
        onActionClick={handleDeleteServer}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeletePrivateServerModal }
