import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getUserRefsFromGroup, useGroupDetailsContext } from 'features/groups'
import { getResourceName } from 'features/resources'

export function useOverviewTab({ searchKey }) {
  const { group } = useGroupDetailsContext()
  const { getObjectRef } = useMultiSlice(['userList'])

  const users = getObjectRef(getUserRefsFromGroup(group))

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction
  })

  return { users: applySearchQuery(users), group }
}

function defaultQueryFunction(user, queryKey) {
  const { EmailID } = user.Spec
  const Name = getResourceName(user)
  return `${EmailID}${Name}`.toLowerCase().includes(queryKey)
}
