import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { GCP_FOLDER_DISPLAY_TYPE, GCP_PROJECT_DISPLAY_TYPE } from '../utils'
import { createRsrcKey } from 'features/resources'
import _ from 'lodash'

export function useGCPCloud() {
  const { slices } = useMultiSlice(['gcpResources', 'projects', 'kubeNamespaces'])

  const getGCPProjects = () => {
    return slices.gcpResources.filter((e) => e.Spec.DisplayType === GCP_PROJECT_DISPLAY_TYPE)
  }

  const getGCPFolders = () => {
    return slices.gcpResources.filter((e) => e.Spec.DisplayType === GCP_FOLDER_DISPLAY_TYPE)
  }

  const getGCPAccountResources = (gcpAccount) => {
    const accountKey = createRsrcKey(gcpAccount)

    const projects = getGCPProjects()
    const folders = getGCPFolders()

    const rsrcs = [...projects, ...folders]

    return rsrcs.filter((r) => createRsrcKey(r.Spec.Account) === accountKey).map(createRsrcKey)
  }

  const getGCPResourceChildren = (gcpRsrc) => {
    const children = []
    const rsrcKey = createRsrcKey(gcpRsrc)
    slices.gcpResources.forEach((r) => {
      const parentKey = createRsrcKey(r.Spec.Parent)
      // Check if `gcpRrsrc` is the parent of the gcp resource
      if (rsrcKey === parentKey) children.push(r)
    })
    return children
  }

  /**
   * Returns the GcpResource equivalent of the Project kind
   */
  const getGCPProject = (project) => {
    return _.find(slices.gcpResources, { Spec: { Id: project?.Spec.ProjectId } })
  }

  const getClusterKubeNameSpaces = (cluster) => {
    const cKey = createRsrcKey(cluster)
    const kubes = []
    slices.kubeNamespaces.forEach((kube) => {
      const clusterKey = createRsrcKey(kube.Spec.Cluster)
      if (cKey === clusterKey) kubes.push(kube)
    })
    return kubes
  }

  return {
    getGCPFolders,
    getGCPProjects,
    getGCPResourceChildren,
    getGCPAccountResources,
    getGCPProject,
    getClusterKubeNameSpaces
  }
}
