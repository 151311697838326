import {
  addSpaces,
  getCountSummaryString,
  getServiceAccountDisplayName
} from 'Utils/PureHelperFuctions'
import { EntityInfoBar, SearchInput } from 'V2Components'
import { getResourceName } from 'features/resources/utils'
import { SimpleDropDown, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const EntitiesView = ({ entities }) => {
  const [userGroupSearchKey, setUserGroupSearchKey] = useState('')
  const [userGroupsSortFilter, setUserGroupSortFilter] = useState('all')

  const getEntitiesTypes = () => {
    return Array.from(new Set(entities.map((e) => e.ObjectMeta.Kind)))
  }

  const getFilteredEntities = () => {
    const list = []
    entities.forEach((e) => {
      const searchBody = [e.ObjectMeta.ID, getResourceName(e), e.ObjectMeta.Name]
      // Filter based on search key
      if (
        userGroupSearchKey &&
        !JSON.stringify(searchBody).toLowerCase().includes(userGroupSearchKey)
      )
        return
      // Filter based on sort view, either User or Groups
      if (userGroupsSortFilter !== 'all' && e.ObjectMeta.Kind !== userGroupsSortFilter) return
      // All filters passed, push to list
      list.push(e)
    })
    return list
  }

  const getCountString = () => {
    const o = getFilteredEntities().reduce((prev, e) => {
      const k = addSpaces(e.ObjectMeta.Kind + 's')
      let count = prev[k] || 0
      count += 1
      prev[k] = count
      return prev
    }, {})
    return getCountSummaryString(o)
  }

  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>Entities</Typography>
        <SearchInput
          searchKey={userGroupSearchKey}
          placeholder='Search Entities'
          sx={{ width: '275px' }}
          onChange={setUserGroupSearchKey}
        />
      </div>
      <div className='flex mt-5 mb-6 justify-between items-center'>
        <Typography className='!text-[#545B71]' variant='body-regular'>
          {getCountString()}
        </Typography>
        <SimpleDropDown
          menuItems={[
            {
              label: 'Show: All',
              value: 'all'
            },
            ...getEntitiesTypes().map((e) => ({
              label: `Show: ${e}`,
              value: e
            }))
          ]}
          onChange={(e) => setUserGroupSortFilter(e.target.value)}
          value={userGroupsSortFilter}
        />
      </div>
      <div>
        {getFilteredEntities().map((e, i) => (
          <div key={i} className='border-b border-[#EEF4F7] mb-1'>
            <EntityInfoBar
              caption={
                e.ObjectMeta.Kind === 'ServiceAccount'
                  ? getServiceAccountDisplayName(e)
                  : e.ObjectMeta.Name
              }
              name={getResourceName(e)}
              kind={e.ObjectMeta.Kind}
              endButton={false}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export { EntitiesView }
