import { useDashboardDataProvider } from 'features/dashboard'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'

export const useTopResources = () => {
  const { heatMap, startDays } = useDashboardDataProvider()

  const getData = () => {
    const sortedUsers = Object.values(heatMap.resources).sort((a, b) => b.count - a.count)

    return sortedUsers.map((data, i) => {
      return {
        rsrcName: {
          name: getResourceName(data.rsrc),
          index: i + 1,
          Icon: getRsrcIcon(data.rsrc)
        },
        sessions: data.count
      }
    })
  }

  return { rowData: getData(), startDays }
}

/**
 *
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const columns = () => [
  {
    dataIndex: 'rsrcName',
    title: 'Name',
    align: 'left',
    width: 80,
    render(value) {
      const Icon = value.Icon
      return (
        <div className='flex items-center justify-start gap-4'>
          <div className='!h-6 my-1 w-6 flex items-center justify-center !text-[#ADBEDE] rounded-sm bg-[#F1F4FB]'>
            {value.index}
          </div>
          <Icon className='rounded' />
          <Typography className='' variant='body-regular'>
            {value.name}
          </Typography>
        </div>
      )
    }
  },

  {
    dataIndex: 'sessions',
    title: 'Sessions',
    align: 'left',
    width: 20
  }
]
