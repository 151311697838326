import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { getResourceName } from 'features/resources'
import { DeleteServerControllerModal } from 'features/serverControllers'
import { Button } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { SummaryHeader } from 'V2Components'

const Header = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const canGoBackFn = useCanGoBack('/admin/server-controllers')
  const srvCtrl = useDataDetailsContext()
  const history = useHistory()

  return (
    <div>
      <SummaryHeader
        actions={
          <>
            <Button
              onClick={() =>
                history.push(`/admin/server-controllers/${srvCtrl.ObjectMeta.Name}/edit`)
              }
              size='md'
              variant='grayBlue'
            >
              Edit
            </Button>
            <Button onClick={() => setShowDeleteModal(true)} size='md' variant='red'>
              Delete
            </Button>
          </>
        }
        breadCrumbsItems={[
          {
            label: 'Server Controllers',
            link: '/admin/server-controllers'
          },
          {
            label: getResourceName(srvCtrl)
          }
        ]}
        rsrcName={getResourceName(srvCtrl)}
      />
      {showDeleteModal && (
        <DeleteServerControllerModal
          serverController={srvCtrl}
          onCancel={() => {
            setShowDeleteModal(false)
          }}
          cleanUpFn={() => {
            setShowDeleteModal(false)
            canGoBackFn()
          }}
        />
      )}
    </div>
  )
}

export { Header }
