import React from "react";
import { Button, IconButton, Label, Typography } from "procyon-ui";
import { faClose, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { enqueueNotification } from "Utils/Helpers";

const styles = {
    body: { marginTop: "8px" },
    codeblock:{
        marginTop: "32px",
        marginBottom: "32px",
        border: "1px solid #eee",
        padding: "8px",
        borderRadius: "8px",
    }, 
    code:{ marginRight: "4px" },
}
export const NamespacePrompt = ({ cloudType, onClose }) => {
  const cloudCommandMap = {
    AWS: "aws eks update-kubeconfig --region <region> --name <cluster-name> --profile <profile-name>",
    GCP: "gcloud container clusters get-credentials <cluster-name> --zone <region> --project <project-id>",
    Azure:
      "az aks get-credentials --resource-group <resource-group-name> --name <cluster-name>",
  }

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(cloudCommandMap[cloudType])
      enqueueNotification('Command copied', 'info')
    } catch (error) {
      console.error('error in handleCopy: ', error)
    }
  }
  return (
    <div >
      <div className="flex justify-between items-center">
        <Typography variant="h4-medium">Connect to Namespace</Typography>
        <div className="flex items-center gap-4">
          <Button
            onClick={onClose}
            variant="gray"
            icon={faClose}
            iconPosition="end"
          >
            CLOSE
          </Button>
        </div>
      </div>
      <div style={styles.body}>
        <hr />
        <div style={{ marginTop: "32px" }}>
          {["GCP", "AZURE"].includes(cloudType) && (
            <Typography variant="h4-regular">
              Login to the CLI using your derived ID
            </Typography>
          )}
        </div>
      </div>
      <div
        style={styles.codeblock}
        className="flex justify-between items-center"
      >
        <div style={styles.code}>
          <Typography variant="breadcrumbLink">
            {cloudCommandMap[cloudType]}
          </Typography>
        </div>
        <IconButton onClick={handleCopy} variant="gray" icon={faCopy} />
      </div>
    </div>
  );
};


