import { ApiProvider } from 'Core'
import { reduxApiClient } from 'infra'

/**
 * API to check if the account has valid credentials
 * @param {any} payload
 * @returns
 */
export const cloudAccountCheck = async (payload) => {
  const response = await new ApiProvider('accountcheck').setInstance(payload).post()
  return response.data
}

export const createAccount = async (account) => {
  const data = await reduxApiClient('accounts').create(account)
  return data
}

export const getSSHCA = async () => {
  const response = await new ApiProvider('sshca').getAll()
  return response.data.SshCAs[0]
}

export const updateAccount = async (account) => {
  return await reduxApiClient('accounts').update(account)
}

export const deleteAccount = async (account) => {
  await new ApiProvider('accounts').setInstance(account).delete()
  return account
}
