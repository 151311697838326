import React, { useRef, useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import _ from 'lodash'
import { Button, IconButton, Label, TextInput } from 'procyon-ui'
import { createDataSelectorHook } from 'infra/redux'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { JSONPrettyView, LoadingFeedback } from 'V2Components'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { reduxApiClient } from 'infra'
import {
  AppAuthorizedUserKey,
  AppClientIdKey,
  AppInstanceUrlKey,
  AppNameKey,
  AppPrivateKey
} from 'features/applications/utils'
import { faPaste, faUpload } from '@fortawesome/free-solid-svg-icons'

function SalesForceApplicationForm({ onCancel, onComplete }) {
  const { isEditMode, app } = useApplicationEditContext()
  const [isLoading, setIsLoading] = useState(false)
  const [instanceURL, setInstanceURL] = useState(_.get(app, AppInstanceUrlKey, ''))
  const [clientID, setClientID] = useState(_.get(app, AppClientIdKey))
  const [privateKey, setPrivateKey] = useState(_.get(app, AppPrivateKey, ''))
  const [applicationName, setApplicationName] = useState(_.get(app, AppNameKey, ''))
  const [authorizedUser, setAuthorizedUser] = useState(_.get(app, AppAuthorizedUserKey, ''))
  const useSlices = createDataSelectorHook(['applicationList'])
  const { slices } = useSlices()
  const inputFileRef = useRef(null)

  const handleButtonClick = () => {
    inputFileRef.current.click()
  }

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: applicationName } })) {
      return 'Application name is already taken!'
    }
    if (applicationName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!instanceURL) return 'Instance URL must not be empty.'
    if (!privateKey) return 'Private key must not be empty'
    if (!authorizedUser) return 'Authorized user must not be empty'
    if (!clientID) return 'Client ID must not be empty'
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    let updatedPrivateKey = privateKey
    if (!privateKey.startsWith('"')) {
      updatedPrivateKey = privateKey
    }

    if (!privateKey.endsWith('"')) {
      updatedPrivateKey = privateKey
    }

    try {
      const data = { applicationName, instanceURL, clientID, updatedPrivateKey, authorizedUser }
      if (isEditMode) {
        const app = await handleSFApplicationUpdate(data)
        updateSliceData(app)
      } else {
        const app = await handleSFApplicationCreate(data)
        updateSliceData(app)
      }
      enqueueNotification(
        `Successfully ${isEditMode ? 'Updated' : 'Created'} SalesForce Application!`,
        'info'
      )
      onComplete?.()
    } catch (error) {
      enqueueNotification(
        `Failed to ${isEditMode ? 'Update' : 'Create'} SalesForce Application!`,
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleSFApplicationCreate = async ({
    applicationName,
    instanceURL,
    clientID,
    updatedPrivateKey,
    authorizedUser
  }) => {
    const data = {
      ObjectMeta: {
        Name: applicationName
      },
      Spec: {
        AppType: 'salesforce',
        SalesforceAppConfig: {
          InstanceURL: instanceURL,
          ClientID: clientID,
          PrivateKey: updatedPrivateKey,
          AuthorizedUser: authorizedUser
        }
      }
    }
    return await reduxApiClient('applications').create(data)
  }

  const handleSFApplicationUpdate = async (data) => {
    const payload = structuredClone(app)
    _.set(payload, AppInstanceUrlKey, data.instanceURL)
    _.set(payload, AppClientIdKey, data.clientID)
    _.set(payload, AppPrivateKey, data.updatedPrivateKey)
    _.set(payload, AppAuthorizedUserKey, data.authorizedUser)
    return await reduxApiClient('applications').update(payload)
  }

  const safeCreds = (() => {
    if (!privateKey) return {}
    try {
      return JSON.parse(`"${privateKey}"`)
    } catch (error) {
      return { error: 'Invalid JSON:' + privateKey }
    }
  })()

  const updatedPrivateKey = (value) => {
    const filterValue = value.replaceAll('\\n', '\n')
    setPrivateKey(filterValue)
  }

  const handlePaste = async () => {
    try {
      const clipText = await navigator.clipboard.readText()
      updatedPrivateKey(clipText)
    } catch (error) {
      enqueueNotification('Failed to paste from clipboard!', error)
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const fileContent = e.target.result
        setPrivateKey(fileContent)
        inputFileRef.current.value = ''
      }
      reader.readAsText(file)
    }
  }

  const formErrors = getErrors()
  return (
    <div>
      <div className='grid gap-2'>
        <TextInput
          disabled={isEditMode}
          value={applicationName}
          onChange={(e) => setApplicationName(e.target.value)}
          sx={{ width: '100%' }}
          label='Application Name *'
        />

        <TextInput
          value={instanceURL}
          onChange={(e) => setInstanceURL(e.target.value)}
          sx={{ width: '100%' }}
          label='Instance URL *'
        />

        <TextInput
          value={clientID}
          onChange={(e) => setClientID(e.target.value)}
          sx={{ width: '100%' }}
          label='Client ID *'
        />

        <div className='relative'>
          <TextInput
            value={privateKey}
            onChange={(e) => updatedPrivateKey(e.target.value)}
            sx={{ width: '100%', paddingY: '10px', marginBottom: '10px' }}
            label='Private Key *'
          />

          <div className='absolute top-[-5px] right-2 flex gap-2'>
            <div className='file-upload'>
              <IconButton
                title='Upload'
                icon={faUpload}
                variant='gray'
                onClick={handleButtonClick}
              />
              <input
                type='file'
                accept='.key'
                ref={inputFileRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </div>
            <IconButton title='Paste' onClick={handlePaste} icon={faPaste} variant='gray' />
          </div>
        </div>

        {/* <div className='h-[100px] overflow-scroll'>
          <JSONPrettyView data={safeCreds} />
        </div> */}

        <TextInput
          value={authorizedUser}
          onChange={(e) => setAuthorizedUser(e.target.value)}
          sx={{ width: '100%' }}
          label='Authorized User *'
        />
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback
        caption='Please wait..'
        loading={isLoading}
        message='Creating Salesforce Application'
      />
    </div>
  )
}

export { SalesForceApplicationForm }
