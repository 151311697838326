import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { useReduxFetch } from '../hooks'
import { useState } from 'react'
import equal from 'fast-deep-equal'

/**
 * Function which returns a selector function for data of `sliceNames`
 * @param {import('types').SliceNames[]} sliceNames
 * @returns
 */
export const dataSelectorFunction = (sliceNames) => {
  return createSelector(
    sliceNames.map((e) => (s) => s[e].map),
    (...s) => {
      const map = {}
      sliceNames.forEach((sName, index) => {
        const sliceMap = s[index]
        map[sName] = Object.values(sliceMap)
      })
      return map
    }
  )
}

/**
 * The functions returns a hook function that will have the extracted data and slices
 * @param {*} sliceNames
 * @returns Hook function
 */
const createHookFunction = (sliceNames, disableReduxFetch) => {
  return () => {
    const slices = useSelector(dataSelectorFunction(sliceNames))
    const [activeSlices, setActiveSlices] = useState(slices)

    if (!equal(slices, activeSlices)) setActiveSlices(slices)

    useReduxFetch(sliceNames, { disableFetch: disableReduxFetch })

    return { slices: activeSlices, sliceNames }
  }
}

/**
 * STORES Reference to the slector functions which gets created
 */
const SELECTOR_FUNCTIONS_MAP = {}

/**
 *
 * * NOTE: Use this function outside of React Components
 * A memoized data selector factory function, which takes `sliceNames` as input
 * and returns a hook function which can be used inside components to get the `sliceNames` data
 * @typedef {{ [k in import('types').SliceNames]: any[] }} SliceObject
 * @param {import('types').SliceNames[]} sliceNames
 * @param {{
 *  disableReduxFetch: boolean
 * }=} options
 * @returns { (() => {
 *  slices: SliceObject
 *  sliceNames: import('types').SliceNames[]
 * }) }
 */
export function createDataSelectorHook(sliceNames = [], options = { disableReduxFetch: false }) {
  const sorted = [...sliceNames].sort()
  // create key for the input
  const sliceKey = sorted.join('-')

  //Create hook fn
  const hookFn = createHookFunction(sliceNames, options.disableReduxFetch)
  //Store the reference of the function in MAP for memoization
  SELECTOR_FUNCTIONS_MAP[sliceKey] = hookFn
  //@ts-ignore
  return SELECTOR_FUNCTIONS_MAP[sliceKey]
}
