export const admin = {
  general: true,
  showDatabaseDetailsPage: true,
  showAccoutsPage: true,
  showAccoutsBrowserPage: true,
  showUsersAccoutsPage: false,
  showUsersIamRolesPage: false,
  showUsersDataSourcePage: false,
  showUsersServersPage: false,
  showApprovalRequestPage: true,
  showApprovalRequestDetailsPage: true,
  showServersPage: true,

  showCredentialsPage: false,

  showPolicyPage: true,

  showDashboardPage: true,
  showGettingStartedPage: true,

  showUsersPage: true,
  showUserInformationPage: true,
  showUserDetailsPage: true,

  showGroupDetailsPage: true,
  showDevicesPage: true,

  showIdpDetailsPage: true,
  showIdpsPage: true,

  showDeviceDetailsPage: true,

  showAccessManagementGroupLink: true,
  showResourcesGroupLink: true,
  showApplicationsPage: true,
  showProjectsPage: true,
  showTeamGroupLink: true,
  showDashBoardGroupLink: true,
  showReportsGroupLink: true,
  showRequestGroupLink: false,

  showFavoritesPage: false,

  showApprovalPage: false,

  showLogsPage: true,

  showRecordingPage: false,

  showWebSshPage: true,
  showSettingsPage: true,
  showIntegrationsPage: true,
  showGlobalResourcesPage: true,
  showUsersDevicesPage: false,
  showUserFavourites: false,
  showIamActions: true,
  showTargets: true,
  showServiceAccountsPage: true
}
