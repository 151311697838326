import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { createPacPolicy } from 'features/policy'
import React from 'react'
import { TargetsResourceManager } from '../TargetsResourceManager'
import { K8_PRINCIPAL_KEY, convertTZ, createRsrcKey } from 'features/resources'
import moment from 'moment'

function CreatePolicyFlow({ views, setViews, selectedRsrc, setSelectedRsrc }) {
  const { selectDispatch } = useMultiDispatch([])

  const handlePolicyCreateSubmit = async (data) => {
    const policy = await handlePolicyCreate(data)
    pushToSlice(policy)
    setTimeout(() => {
      selectDispatch(['policyList'])
    }, 500)
  }

  return (
    <TargetsResourceManager
      setSelectedRsrc={setSelectedRsrc}
      showRequestForUserGroupsSummary
      mainTitle='Policy'
      userGroupSelectionPrompt
      onRequestSubmission={handlePolicyCreateSubmit}
      views={views}
      onViewsChange={setViews}
      selectedRsrc={selectedRsrc}
    />
  )
}

async function handlePolicyCreate({ spec, resources, entities, resourcesMetaObj }) {
  const data = {
    ObjectMeta: {
      Kind: 'PacPolicy',
      Name: getFriendlyName(),
      RdOwners: {
        ObjectRef: entities
      }
    },
    Spec: {
      Description: spec.comments,
      ActionMap: {}
    },
    IssuedTo: {
      ObjectRef: entities
    },
    NotBefore: moment(convertTZ(spec.startDate, spec.timeZone)).utc(),
    NotAfter: moment(convertTZ(spec.endDate, spec.timeZone)).utc(),
    GivenName: spec.name,
    Type: 'Target',
    Creator: ''
  }

  resources.forEach((rsrc) => {
    const { Principal, Roles } = resourcesMetaObj[createRsrcKey(rsrc)] || {}
    if (rsrc.RefKind === 'Server') {
      const SSHRule = data.Spec.ActionMap.SSH || { PolicyRule: [] }
      SSHRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Server',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      data.Spec.ActionMap.SSH = SSHRule
    } else if (rsrc.RefKind === 'AppRole') {
      const AssumeRole = data.Spec.ActionMap.AssumeRole || { PolicyRule: [] }
      AssumeRole.PolicyRule.push({
        ObjectRef: {
          RefKind: 'AppRole',
          RefID: rsrc.RefID
        }
      })
      data.Spec.ActionMap.AssumeRole = AssumeRole
    } else if (rsrc.RefKind === 'Database') {
      const DBAccess = data.Spec.ActionMap.DBAccess || { PolicyRule: [] }
      DBAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Database',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      data.Spec.ActionMap.DBAccess = DBAccess
    } else if (rsrc.RefKind === 'RDPServer') {
      const RDPAccessRule = data.Spec.ActionMap.RDPAccess || { PolicyRule: [] }
      RDPAccessRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'RDPServer',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      data.Spec.ActionMap.RDPAccess = RDPAccessRule
    } else if (rsrc.RefKind === 'Kafka') {
      const KafkaAccess = data.Spec.ActionMap.KafkaAccess || { PolicyRule: [] }
      KafkaAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Kafka',
          RefID: rsrc.RefID
        }
      })
      data.Spec.ActionMap.KafkaAccess = KafkaAccess
    } else if (rsrc.RefKind === 'KubeCluster') {
      const KubeAccess = data.Spec.ActionMap.KubeAccess || { PolicyRule: [] }
      KubeAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'KubeCluster',
          RefID: rsrc.RefID
        },
        Services: {
          ObjectRef: Roles
        }
      })
      data.Spec.ActionMap.KubeAccess = KubeAccess
    } else if (rsrc.RefKind === 'KubeNamespace') {
      const KubeAccess = data.Spec.ActionMap.KubeAccess || { PolicyRule: [] }
      KubeAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'KubeNamespace',
          RefID: rsrc.RefID
        },
        Services: {
          ObjectRef: Roles
        },
        Principal: Principal?.replace(K8_PRINCIPAL_KEY, '')
      })
      data.Spec.ActionMap.KubeAccess = KubeAccess
    }
  })
  const policy = await createPacPolicy(data)
  enqueueNotification('Policy successfully created!', 'info')
  return policy
}

export { CreatePolicyFlow }
