import { LabelContent } from 'V2Components'
import { Label, TextInput } from 'procyon-ui'
import React from 'react'

function EditableLabelContent({
  errorMessage = '',
  editMode,
  value,
  title,
  content,
  inputName = '',
  onChange,
  showEmptyLabel = false
}) {
  return (
    <div>
      <LabelContent
        title={title}
        showEmptyLabel={showEmptyLabel}
        content={
          editMode ? (
            <div className='flex gap-2 items-center'>
              <TextInput sx={{ width: '280px' }} value={value} name={inputName} onChange={onChange} />
              {errorMessage && <Label variant='warning' text={errorMessage} />}
            </div>
          ) : (
            content
          )
        }
      />
    </div>
  )
}

export { EditableLabelContent }
