import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { Spinner } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const useStyle = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: '0px',
    left: '0px',
    zIndex: 9999999
  },
  spinner: {
    color: theme.palette.common.white
  },
  text: {
    marginLeft: theme.spacing(1)
  },
  svgCont: {
    backgroundColor: 'white',
    padding: theme.spacing(2, 4),
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  loadingText: {
    marginTop: theme.spacing(),
    fontWeight: 'bold',
    fontSize: theme.spacing(1.5),
    width: theme.spacing(5),
    textAlign: 'center'
  },
  glass: {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255, 255, 255, 0.3)'
  }
}))

const Loader = ({ loading }) => {
  const classes = useStyle()
  const [dots, setDots] = useState('.')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((e) => (e.length === 3 ? '.' : e + '.'))
    }, 500)
    return () => clearInterval(intervalId)
  }, [])

  useHTMLBodyScrollbar(loading)
  if (!loading) return null

  return (
    <>
      <div className={classNames(classes.loaderContainer, classes.glass)}>
        <div className={classes.svgCont}>
          <Spinner />
          <div className={classes.loadingText}>Loading{dots}</div>
        </div>
      </div>
    </>
  )
}

export default Loader
