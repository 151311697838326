import { Box } from '@material-ui/core'
import { People } from '@material-ui/icons'
import BarChart from 'Components/Charts/BarChart'
import ChartContainer from 'Components/Charts/ChartContainer'
import SeriesFilter from 'Components/Charts/ChartContainer/Components/SeriesFilter'
import { Theme } from 'Core'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiQueryGraph from 'Core/Hooks/useMultiQueryGraph'
import React, { useMemo } from 'react'
import { compareDatesFn, mixMapFn } from 'Utils/PureHelperFuctions'

const ActiveUsersGraph = () => {
  const seriesMeta = [
    {
      name: 'activeUsers',
      label: 'Active Users',
      hoverText: '{name}: [bold]{valueY} at {categoryX}[/]',
      color: Theme.palette.info.main,
      Icon: ({ className }) => <People className={className} />
    }
  ]

  /** Axis configurations for the chart */
  const xAxis = { name: 'formattedDate', label: 'Month' }
  const yAxis = { name: 'Resource Type', label: '' }

  const queryOptions = useMemoOnce({
    defaultTimeRange: null,
    overrideChartKey: { 'event-log': 'activeUsers', 'proxy-audit-log': 'activeUsers' }
  })

  const { loading, onTillTimeChange, graphData } = useMultiQueryGraph(['event-log', 'proxy-audit-log'], queryOptions)

  /** Combine all datasets by name */
  const chartData = useMemo(() => {
    const summationFn = (obj) => {
      if (obj['proxy-audit-log']) obj.activeUsers = (obj.activeUsers || 0) + obj['proxy-audit-log']
      if (obj['event-log']) obj.activeUsers = (obj.activeUsers || 0) + obj['event-log']
    }
    const mixedActiveUsers = mixMapFn(graphData['Active User'], summationFn)
    mixedActiveUsers.sort(compareDatesFn) // [{date,servers:1,appRole:1}]
    return { 'Active User': mixedActiveUsers }
  }, [graphData])

  /** Custom Styles for chart */
  const chartStyles = { height: '19rem', width: '100%' }
  /** Create header dropdowns for chart container */

  const filters = [
    {
      name: 'date',
      value: '1 months',
      filterType: 'TillDate',
      filterOptions: [
        { label: 'Last Month', name: '1 months' },
        { label: 'Last Quarter', name: '3 months' },
        { label: 'Last Year', name: '12 months' }
      ]
    }
  ]
  const externalFilterHandlers = useMemo(
    () => ({
      date: onTillTimeChange
    }),
    [onTillTimeChange]
  )
  console.log('chart', graphData)
  return (
    <Box height='100%'>
      <ChartContainer
        loading={loading}
        externalFilterHandlers={externalFilterHandlers}
        parent='Active user'
        barsBorderRadius={2}
        graphLeftOffset='-22px'
        graphTopOffset='1.813rem'
        headerHorizontalPadding={0}
        title='Active Users Count'
        Graph={BarChart}
        chartStyles={chartStyles}
        seriesMeta={seriesMeta}
        xAxis={xAxis}
        yAxis={yAxis}
        dataSets={chartData}
        defaultFilters={filters}
        SeriesFilterComponent={({ changeFilter, filters }) => (
          <SeriesFilter position='right' disabled filters={filters} pb={1} changeFilter={changeFilter} />
        )}
      />
    </Box>
  )
}

export default ActiveUsersGraph
