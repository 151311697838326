import React, { useEffect } from 'react'
import { Box, Chip, makeStyles } from '@material-ui/core'
import TabsSegment from 'Components/TabsSegment'

import Locations from './components/Locations'
import MostActive from './components/MostActive'
import AlertsLogs from './components/AlertsLogs'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px 0 0 8px',
    backgroundColor: theme.palette.primary.white,
    position: 'relative'
  },
  alertChip: {
    height: theme.spacing(1.25),
    width: theme.spacing(2),
    backgroundColor: 'rgba(255, 152, 0, 0.1)',
    marginLeft: theme.spacing(0.288)
  }

}))

const UsersSegment = ({ userCount }) => {

  const classes = useStyles()
  const tabList = [
    {
      Tab: <MostActive />,
      name: 'Most Active',
      ID: '1001'
    },
    {
      Tab: <Locations />,
      name: 'Locations',
      ID: '1002'
    }
  ]

  return (
    <Box className={classes.root}>
      <TabsSegment compact tabMinWidth='7rem' title='Users' current={userCount} total={userCount} TabList={tabList} />
    </Box>
  )
}

export default UsersSegment
