import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

/**
 *
 * @param {string} storeName
 * @param {(oldData:any,newData:any)=>boolean} [equalityCheck]
 * @returns
 */
const useMemoSelector = (storeName, equalityCheck) => {
  const data = useSelector(store => {
    const map = store[storeName].map || {}
    return Object.values(map)
  })
  const status = useSelector(store => store[storeName].status)
  const initiallyLoaded = useSelector(store => store[storeName].initiallyLoaded)
  const currentStateRef = useRef(data)
  const [state, setState] = useState(data)
  useEffect(() => {
    let isEqual
    if (typeof equalityCheck === 'function') {
      isEqual = equalityCheck(currentStateRef.current, data)
    } else {
      isEqual = _.isEqual(currentStateRef.current, data)
    }
    if (!isEqual) {
      setState(data)
      currentStateRef.current = data
    }
  }, [data])
  return (
    { data: state, status, initiallyLoaded }
  )
}

export default useMemoSelector
