import useMemoSelector from 'Core/Hooks/useMemoSelector'
import {
  accountListSlice,
  appRolesListSlice,
  approvalReqListSlice,
  databaseSlice,
  iamActionsSlice,
  serverListSlice,
  userListSlice
} from 'infra/redux/reducers'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Container from './Components/Container'

const Dashboard = () => {
  const dispatch = useDispatch()

  const { data: userList } = useMemoSelector('userList')
  const { data: appRolesList } = useMemoSelector('appRolesList')
  const { data: serverList } = useMemoSelector('serverList')
  const { data: accountList } = useMemoSelector('accountList')
  const { data: databases } = useMemoSelector('databases')

  const onInit = () => {
    dispatch(serverListSlice.thunk())
    dispatch(accountListSlice.thunk())
    dispatch(userListSlice.thunk())
    dispatch(appRolesListSlice.thunk())
    dispatch(approvalReqListSlice.thunk())
    dispatch(iamActionsSlice.thunk())
    dispatch(databaseSlice.thunk())
  }

  const handleLogClick = useCallback((meta) => {}, [])

  useEffect(onInit, [])

  return (
    <Container
      handleLogClick={handleLogClick}
      databases={databases}
      userList={userList}
      accountList={accountList}
      serverList={serverList}
      appRolesList={appRolesList}
    />
  )
}

export default React.memo(Dashboard)
