import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SearchInput } from 'V2Components'
import { useApprovalDetails } from 'features/approvals'
import { IAMResourceKindsMap, getIAMResourceDisplayType } from 'features/iamResources'
import {
  getK8NamespaceRsrcsFromPrincipal,
  getKubeType,
  usePolicyDetailsContext
} from 'features/policy'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { getTargetsPolicyRules } from 'features/targets'
import _ from 'lodash'
import { Label, SimpleDropDown, SummaryResourceCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useMemo, useState } from 'react'

function ResourcesSummaryView({ resources, resourceAttibutesObject }) {
  const { getObjectRef: getRsrcsRef } = useMultiSlice([
    'accountList',
    'kubeClusters',
    'kubeNamespaces',
    'projects'
  ])
  const { getObjectRef } = useMultiSlice(['accountList'])
  const [resourcesSearchKey, setResourcesSearchKey] = useState('')
  const [resourcesTypeSortFilter, setResourcesTypeSortFilter] = useState('all')
  const isIAMResource = (rsrc) => IAMResourceKindsMap[rsrc.ObjectMeta.Kind]
  const { approvalRequest } = useApprovalDetails()
  const { policy } = usePolicyDetailsContext()

  const getKubeAttributes = (roles = [], kubeCluster) => {
    if (kubeCluster?.ObjectMeta.Kind !== 'KubeCluster') return []
    const attrs = []
    const ids = roles.map((e) => e.RefID)
    // Get the approle name and id from the kubecluster
    for (const [name, approleID] of Object.entries(kubeCluster.Spec.IAMRoles.Map)) {
      // If the approle is selected in resource queue, push the approle name
      if (ids.includes(approleID) && !attrs.includes(name)) attrs.push(name)
    }
    return attrs
  }

  const getAttributes = (rsrc) => {
    if (rsrc.ObjectMeta.Kind === 'CRMEntity') {
      return [rsrc.Type]
    }

    if (rsrc.ObjectMeta.Kind === 'SnowflakeRole') {
      return ['Role']
    }

    return resourceAttibutesObject[createRsrcKey(rsrc)] || []
  }

  const getK8RsrcsData = (rsrc, Principal, Roles) => {
    const data = []
    const account = getObjectRef(rsrc.Spec.Account)
    const cluster = getRsrcsRef(rsrc.Spec.Cluster)

    if (Principal) {
      const k8Rsrcs = getK8NamespaceRsrcsFromPrincipal(Principal)

      k8Rsrcs?.forEach(({ name, type }, i) => {
        const rsrcAttributes = []
        cluster && rsrcAttributes.push(...getKubeAttributes(Roles, cluster))
        data.push({
          accountName: _.get(account, 'ObjectMeta.Name', ''),
          region: '',
          resourceName: `${type}:${name}`,
          resourceType: getIAMResourceDisplayType(rsrc),
          attributes: rsrcAttributes,
          Icon: ({ ...props }) => <TargetIcon type={getKubeType(type)} {...props} />,
          disableEdit: false,
          principal: '',
          ref: rsrc
        })
      })
    } else {
      const rsrcAttributes = []
      cluster && rsrcAttributes.push(...getKubeAttributes(Roles || [], cluster))
      data.push({
        accountName: _.get(account, 'ObjectMeta.Name', ''),
        region: rsrc.Spec.Region || '',
        resourceName: getResourceName(rsrc),
        resourceType: getIAMResourceDisplayType(rsrc),
        attributes: rsrcAttributes,
        Icon: getRsrcIcon(rsrc),
        disableEdit: false,
        principal: Principal,
        // rsrc,
        ref: rsrc
      })
    }
    return data
  }

  const getRsrcAccount = (rsrc) => {
    // check if the rsrcs is SA obj which is created by us.
    if (rsrc.ObjectMeta.Kind === 'ServiceAccount' && rsrc.ObjectMeta.Name === rsrc.ObjectMeta.ID) {
      const type = rsrc.Spec.Type
      let accountRef = rsrc.Spec[`${_.capitalize(type)}Spec`].Account
      // check if it is GCP type because gcp sa request does not have account so, we need to get it from project itself
      if (type === 'GCP') {
        const prj = getObjectRef(rsrc.Spec.GcpSpec.Project)
        // get account ref
        if (prj) accountRef = prj.Spec.Account
      }
      return getObjectRef(accountRef)
    }

    return getObjectRef(rsrc?.Spec?.Account)
  }

  const rsrcsSummary = useMemo(() => {
    const list = []
    resources?.forEach((rsrc) => {
      let Principal = []
      let Roles = []
      const account = getRsrcAccount(rsrc)
      // get kube principal
      if (rsrc.ObjectMeta.Kind === 'KubeNamespace') {
        if (!policy || approvalRequest) {
          const rsrcRule = approvalRequest?.Spec?.Resources?.Resource.find(
            (e) => createRsrcKey(e.Target) === createRsrcKey(rsrc)
          )
          Principal = rsrcRule?.Principal
          Roles = rsrcRule?.Services.ObjectRef
        } else {
          const { KubeAccess } = getTargetsPolicyRules(policy)
          const rsrcRule = KubeAccess.find(
            (e) => createRsrcKey(e.ObjectRef) === createRsrcKey(rsrc)
          )
          Principal = rsrcRule.Principal
          Roles = rsrcRule.Services.ObjectRef
        }
        list.push(...getK8RsrcsData(rsrc, Principal, Roles))
        return
      }

      const listData = {
        accountName: _.get(account, 'ObjectMeta.Name', ''),
        region: rsrc?.Spec?.Region || '',
        resourceName: getResourceName(rsrc),
        resourceType: getIAMResourceDisplayType(rsrc),
        attributes: getAttributes(rsrc),
        key: createRsrcKey(rsrc),
        Icon: getRsrcIcon(rsrc)
      }

      // Filter rsrc by searchKey
      if (
        resourcesSearchKey &&
        !JSON.stringify(listData).toLowerCase().includes(resourcesSearchKey)
      )
        return
      // Filter rsrc by the selected type filter dropdown
      if (resourcesTypeSortFilter !== 'all') {
        // IAM Resources needs to be filtered by the Type
        if (isIAMResource(rsrc)) {
          const type = getIAMResourceDisplayType(rsrc)
          if (resourcesTypeSortFilter !== type) return
        } else {
          // The rsrc is Server, Database or AppRole
          if (resourcesTypeSortFilter !== rsrc.ObjectMeta.Kind) return
        }
      }

      list.push(listData)
    })
    // Function to remove duplicates based on a property value
    const removeDuplicatesByProperty = (arr, prop) => {
      const seen = new Set()
      return arr.filter((obj) => {
        const val = obj[prop]
        if (seen.has(val)) {
          return false // Duplicate found, filter it out
        }
        seen.add(val)
        return true // Unique, keep it
      })
    }

    // Call the removeDuplicatesByProperty function with your array and the property to check
    const uniqueData = removeDuplicatesByProperty(list, 'resourceName')

    return uniqueData
  }, [
    resources,
    getObjectRef,
    resourceAttibutesObject,
    resourcesSearchKey,
    resourcesTypeSortFilter
  ])

  const getResourcesTypes = () => {
    return Array.from(
      new Set(
        resources.map((e) => {
          // IAM rsrcs needs to be filtered using DisplayType(GcpResource) or Type (AwsResource)
          if (isIAMResource(e)) return getIAMResourceDisplayType(e)
          // Rsrc is database, approle or server
          return e.ObjectMeta.Kind
        })
      )
    )
  }

  return (
    <div>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>Resources</Typography>
        <SearchInput
          searchKey={resourcesSearchKey}
          placeholder='Search Resources'
          sx={{ width: '275px' }}
          onChange={setResourcesSearchKey}
        />
      </div>
      <div className='flex justify-between items-center'>
        <Typography className='!text-[#545B71] mt-6 mb-7' variant='body-regular'>
          {rsrcsSummary.length} Resources
        </Typography>
        <div className='flex justify-between items-center gap-4'>
          <SimpleDropDown
            menuItems={[
              {
                label: 'Show: All Resources',
                selected: true,
                value: 'all'
              },
              ...getResourcesTypes().map((e) => ({
                label: 'Show: ' + e.replaceAll('_', ' '),
                selected: true,
                value: e
              }))
            ]}
            onChange={(e) => setResourcesTypeSortFilter(e.target.value)}
            value={resourcesTypeSortFilter}
          />
        </div>
      </div>

      <div>
        {rsrcsSummary.map((e) => (
          <div key={e.key} className='mb-4'>
            <SummaryResourceCard
              style={{
                alignItems: 'center'
              }}
              isEditButtonEnabled
              accountName={e.accountName}
              isDeleteButtonEnabled
              region={e.region}
              resourceIcon={<e.Icon className='rounded' />}
              resourceName={e.resourceName}
              resourceType={e.resourceType}
              actionButtons={false}
            >
              <React.Fragment key='.0'>
                {e.attributes.map((r) => (
                  <Label key={r} text={r} variant='grayBlue' />
                ))}
              </React.Fragment>
            </SummaryResourceCard>
          </div>
        ))}
      </div>
    </div>
  )
}

export { ResourcesSummaryView }
