import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { SearchInput } from 'V2Components'
import {
  Button,
  Label,
  PickListContainer,
  PickListHeading,
  Typography,
  UserGroupBar
} from 'procyon-ui'
import React, { useState } from 'react'
import { entityDataAdapters, getFilterDropdownItems } from './utils'
import _ from 'lodash'
import { createRsrcKey } from 'features/resources'

function EntityPicker({
  title,
  entities,
  selectedEntities,
  setSelectedEntities,
  errorMessage = '',
  InfoComponent = null,
  onCancel,
  onContinue,
  continueText = 'Continue',
  deSelectFn = null,
  preventDeselect = false
}) {
  const [searchKey, setSearchKey] = useState('')

  const [unSelectedEntitiesFilter, setUnSelectedEntitiesFilter] = useState('')
  const [selectedEntitiesFilter, setSelectedEntitiesFilter] = useState('')

  const applyFilter = ({ data, searchKey, Kind, filterKind }) => {
    if (!searchKey && !filterKind) return true
    if (searchKey) return JSON.stringify(data).toLowerCase().includes(searchKey)
    if (filterKind && filterKind === Kind) return true
    return false
  }

  const getResource = (rsrc) => {
    const { Kind } = rsrc.ObjectMeta
    if (entityDataAdapters[Kind]) return entityDataAdapters[Kind](rsrc)
  }

  const getSelectedEntitiesItems = () => {
    return selectedEntities.reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: selectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind,
        searchKey
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  const getUnSelectedEntities = () => {
    return entities.filter((e) => {
      const { Kind, ID } = e.ObjectMeta
      return !_.find(selectedEntities, { ObjectMeta: { Kind, ID } })
    })
  }

  const getUnSelectedEntitiesItems = () => {
    return getUnSelectedEntities().reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: unSelectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind,
        searchKey
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  /** ====================================== HANDLERS GO HERE ====================================== */

  const handleSelectEntity = (entity) => {
    // Allow only one selected entity
    setSelectedEntities([entity])
  }

  const handleDeSelectEntity = (entity) => {
    setSelectedEntities([])
  }

  /** ============================================================================================== */

  return (
    <div className='bg-white h-auto rounded-md w-[887px]'>
      <div className='grid grid-cols-2 gap-8'>
        <div>
          <Typography variant='h2'>{title}</Typography>
          {InfoComponent && <div className='mt-2'>{InfoComponent}</div>}
        </div>
        <SearchInput
          searchKey={searchKey}
          onChange={setSearchKey}
          sx={{ width: '100%' }}
          className='ml-auto'
        />
      </div>

      <div className='grid grid-cols-2 gap-8 mt-7'>
        <div>
          <PickListHeading
            dropDownValue={unSelectedEntitiesFilter}
            onChange={(e) => setUnSelectedEntitiesFilter(e.target.value)}
            dropDownItems={getFilterDropdownItems(getUnSelectedEntities())}
            title={`Existing (${getUnSelectedEntitiesItems().length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%',
              backgroundColor: 'white',
              marginTop: '-2px'
            }}
          >
            <>
              {getUnSelectedEntitiesItems().map((e) => (
                <UserGroupBar
                  key={e.key}
                  onClickAddButton={() => handleSelectEntity(e.refObject)}
                  caption={e.caption}
                  name={e.name}
                  onClickRemoveButton={function noRefCheck() {}}
                  style={{
                    width: '100%'
                  }}
                  type={e.type}
                />
              ))}
            </>
          </PickListContainer>
        </div>
        <div>
          <PickListHeading
            onChange={(e) => setSelectedEntitiesFilter(e.target.value)}
            dropDownValue={selectedEntitiesFilter}
            dropDownItems={getFilterDropdownItems(selectedEntities)}
            title={`Added (${getSelectedEntitiesItems().length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%',
              marginTop: '-2px'
            }}
          >
            <>
              {getSelectedEntitiesItems().map((e) => (
                <UserGroupBar
                  endButton={
                    typeof deSelectFn === 'function' ? !deSelectFn(e.refObject) : !preventDeselect
                  }
                  key={e.key}
                  selected
                  assigned
                  caption={e.caption}
                  name={e.name}
                  onClickRemoveButton={() => handleDeSelectEntity(e.refObject)}
                  style={{
                    width: '100%'
                  }}
                  type={e.type}
                />
              ))}
            </>
          </PickListContainer>
        </div>
      </div>
      <div className='flex justify-end gap-4 mt-6'>
        {errorMessage && (
          <Label
            iconButton={faTriangleExclamation}
            style={{ width: '800px' }}
            text={errorMessage}
            variant='warning'
          />
        )}
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
        <Button disabled={!!errorMessage} onClick={onContinue} variant='primary'>
          {continueText}
        </Button>
      </div>
    </div>
  )
}

export { EntityPicker }
