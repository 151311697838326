import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import _ from 'lodash'
import {
  getApprovalRequestIAMActions,
  isServiceAccountRequest,
  useApprovalDetails
} from 'features/approvals'
import {
  getAWSResourcePolicyAppRole,
  getPolicyError,
  isPolicyIssuedToUser,
  usePolicyDetailsContext
} from 'features/policy'
import { createRsrcKey } from 'features/resources'
import React, { useEffect } from 'react'
import { AccessGraph } from 'V2Components'
import { GraphResourceNodeActionsFunctionMap } from 'V2Components'
import { PRINCIPAL_OBJECT_KINDS, getRDPServerAccessLinksMap } from 'features/targets'

function AccessGraphTab() {
  const { requestForUsersGroups, resources, createdPolicy, approvalRequest } = useApprovalDetails()
  const { getObjectRef, slices, selectDispatch } = useMultiSlice([
    'iamActions',
    'appRolesList',
    'githubAccount',
    'applicationList',
    'rdpServers',
    'kubeClusters',
    'kubeNamespaces',
    'kafkas'
  ])
  const { user } = useUser()
  useEffect(() => {
    selectDispatch(['applicationList', 'githubAccount', 'kubeClusters'])
  })

  const { policy, isIssuedToLoggedInUser } = usePolicyDetailsContext()

  const getPrincipalOnResources = () => {
    const map = {}
    const actionKeys = Object.keys(createdPolicy?.Spec?.ActionMap || {})
    actionKeys.forEach((actionKey) => {
      const rules = createdPolicy?.Spec?.ActionMap?.[actionKey]?.PolicyRule || []
      rules.forEach((rule) => {
        if (rule?.ObjectRef?.RefKind && PRINCIPAL_OBJECT_KINDS.includes(rule.ObjectRef.RefKind)) {
          const rsrcKey = createRsrcKey(rule.ObjectRef)
          map[rsrcKey] = rule.Principal
        }
      })
    })

    return map
  }

  const getAppRoleNodes = () => {
    if (isServiceAccountRequest(approvalRequest)) return {}
    const iamActionsRefsObj = getApprovalRequestIAMActions(approvalRequest)
    // This will populate all the iam actions refs with the actual object
    const iamActions = Object.keys(iamActionsRefsObj).reduce((prev, current) => {
      const list = []
      const actionsRefs = iamActionsRefsObj[current]
      actionsRefs.forEach((e) => {
        const action = getObjectRef(e)
        if (action) list.push(action)
      })
      if (prev[current]) prev[current] = [...list, ...prev[current]]
      else prev[current] = list
      return prev
    }, {})
    return iamActions
  }

  const getGithubApplicationSaml = (refID) => {
    const githubAccountId = _.find(slices?.githubAccount, { ObjectMeta: { ID: refID } })
    const githubApplicationIdWithSSOUrl = _.find(slices?.applicationList, {
      ObjectMeta: { ID: githubAccountId?.Application?.RefID }
    })
    return githubApplicationIdWithSSOUrl
  }

  const createNodesActionsObject = () => {
    const actionsObj = {}
    resources.forEach((r) => {
      const Kind = r.ObjectMeta.Kind
      const key = createRsrcKey(r)
      const actionsFn = GraphResourceNodeActionsFunctionMap[Kind]
      if (!actionsFn) return
      if (!actionsObj[key]) {
        const appRoleRef = getAWSResourcePolicyAppRole(createdPolicy)
        const approle = appRoleRef && getObjectRef(appRoleRef)
        const errorInPolicy = getPolicyError(createdPolicy)
        // application SSO link for github
        const appSSOLinkApp = getGithubApplicationSaml(r?.Spec?.Account?.RefID)
        // rdpserver signin url
        // const rdpSingInUrl = getPrincipalSignInUrl(getPrincipalOnResources(), r)
        const principal = getPrincipalOnResources()[createRsrcKey(r)]
        actionsObj[key] = actionsFn({
          rsrc: r,
          isIssuedToLoggedInUser: isPolicyIssuedToUser(createdPolicy, user),
          approle,
          errorInPolicy,
          appSSOLinkApp,
          rdpSingInUrl: {
            [principal]: getRDPServerAccessLinksMap(r)[principal] || ''
          },
          kubeCluster: getObjectRef(r?.Spec?.Cluster),
          policy: createdPolicy,
          user
        })
      }
    })
    return actionsObj
  }

  return (
    <div>
      <AccessGraph
        actionsObj={createNodesActionsObject()}
        resourceToRolesMapObject={getAppRoleNodes()}
        entities={requestForUsersGroups}
        policy={createdPolicy}
        resources={resources}
      />
    </div>
  )
}

export { AccessGraphTab }
