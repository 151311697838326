import { Help, NewReleases } from '@material-ui/icons'
import React from 'react'
import _ from 'lodash'
import EBSVolume from './EBSVolume'
import EC2Instance from './EC2Instance'
import ECR from './ECR'
import ECS from './ECS'
import EKS from './EKS'
import ElasticIP from './ElasticIP'
import ImageBuilder from './ImageBuilder'
import KMS from './KMS'
import Lambda from './Lambda'
import MSK from './MSK'
import RDS from './RDS'
import S3 from './S3'
import SecretsManager from './SecretsManager'
import SecurityGroup from './SecurityGroup'
import VPC from './VPC'
import { AllResource } from './AllResource'
import { TargetIcon } from 'procyon-ui'

export const AWSDisplayTypeToIconTypeMap = {
  All_Resource: 'All_Resource',
  EC2: 'AWS_EC2_Instance',
  AWS_EC2_EBS_Volume: 'AWS_EC2_EBS_Volume',
  AWS_EC2_Elastic_IP: 'AWS_EC2_Elastic_IP',
  AWS_EC2_Image: 'AWS_EC2_Image',
  AWS_EC2_Instance: 'AWS_EC2_Instance',
  AWS_EC2_Key_Pair: 'AWS_EC2_Key_Pair',
  AWS_EC2_Network_Interface: 'AWS_EC2_Network_Interface',
  AWS_EC2_Security_Group: 'AWS_EC2_Security_Group',
  AWS_KMS: 'AWS_KMS',
  AWS_S3: 'AWS_S3',
  AWS_VPC: 'AWS_VPC',
  AWS_EKS: 'AWS_EKS',
  AWS_ECR: 'AWS_ECR',
  AWS_ECS: 'AWS_ECS',
  AWS_ECS_Container: 'AWS_ECS_Container',
  AWS_ECS_Task: 'AWS_ECS_TASK',
  AWS_ECS_Service: 'AWS_ECS_SERVICE',
  AWS_LAMBDA: 'AWS_LAMBDA',
  AWS_EC2_Load_Balancer: 'AWS_EC2_Load_Balancer',
  AWS_EC2_Target_Group: 'AWS_EC2_Target_Group',
  AWS_EC2_EBS_Snapshot: 'AWS_EC2_EBS_Snapshot',
  AWS_EC2_Placement_Group: 'AWS_EC2_Placement_Group',
  AWS_RDS_Cluster: 'AWS_RDS_Cluster',
  AWS_RDS_DB: 'AWS_RDS_DB',
  AWS_SM: 'AWS_SM',
  SecretsManager: 'AWS_SM',
  AWS_MSK: 'AWS_MSK',
  AWS_KubeNamespace: 'AWS_KubeNamespace',
  AWS_DYNAMO_DB: 'AWS_DYNAMODB',
  AWS_CloudFormation_Stack: 'AWS_CLOUDFORMATION',
  AWS_CloudFront_Distribution: 'AWS_CLOUDFRONT',
  AWS_Redshift_Cluster: 'AWS_REDSHIFT',
  AWS_IOT: 'AWS_IOT',
  AWS_SNS_Topic: 'AWS_SNS',
  AWS_Amplify_App: 'AWS_AMPLIFY',
  AWS_API_Gateway: 'AWS_API_GATEWAY',
  AWS_Kinesis_Data_Stream: 'AWS_KINESIS',
  AWS_Cloudwatch_Dashboard: 'AWS_CLOUDWATCH',
  AWS_Cloudwatch_Metric_Stream: 'AWS_CLOUDWATCH',
  AWS_Glue_Workflow: 'AWS_GLUE',
  AWS_Glue_Job: 'AWS_GLUE',
  AWS_Cognito_Identity_Pool: 'AWS_COGNITO',
  AWS_Cognito_User_Pool: 'AWS_COGNITO',
  AWS_Athena_Data_Catalog: 'AWS_ATHENA',
  AWS_GuardDuty_Detector: 'AWS_GUARD_DUTY',
  AWS_Chime_App_Instance: 'AWS_CHIME',
  AWS_Route53_Hosted_Zone: 'AWS_ROUTE53',
  AWS_Lightsail_Instance: 'AWS_LIGHTSAIL',
  AWS_Lightsail_Container: 'AWS_LIGHTSAIL',
  AWS_Lightsail_Database: 'AWS_LIGHTSAIL'
}

const DisplayTypeToIconMap = {
  All_Resource: AllResource,
  AWS_EC2_EBS_Volume: EBSVolume,
  AWS_EC2_Elastic_IP: ElasticIP,
  AWS_EC2_Image: ImageBuilder,
  AWS_EC2_Instance: EC2Instance,
  AWS_EC2_Key_Pair: EC2Instance,
  AWS_EC2_Network_Interface: EC2Instance,
  AWS_EC2_Security_Group: SecurityGroup,
  AWS_KMS: KMS,
  AWS_S3: S3,
  AWS_VPC: VPC,
  AWS_EKS: EKS,
  AWS_ECR: ECR,
  AWS_ECS: ECS,
  AWS_ECS_Container: ECS,
  AWS_LAMBDA: Lambda,
  AWS_EC2_Load_Balancer: EC2Instance,
  AWS_EC2_Target_Group: EC2Instance,
  AWS_EC2_EBS_Snapshot: EC2Instance,
  AWS_EC2_Placement_Group: EC2Instance,
  AWS_RDS_Cluster: RDS,
  AWS_RDS_DB: RDS,
  AWS_SM: SecretsManager,
  SecretsManager: SecretsManager,
  AWS_MSK: MSK,
  AWS_KubeNamespace: EKS
}

/**
 *
 * @param {string|{ Spec:{ Type: string } }} rsrc
 */
export const getAWSResourceIcon = (rsrc, matchPattern = false) => {
  let displayType = ''
  if (typeof rsrc === 'object') displayType = _.get(rsrc, 'Spec.Type', '')
  if (typeof rsrc === 'string') displayType = rsrc

  if (matchPattern && !DisplayTypeToIconMap[displayType] && typeof rsrc === 'string') {
    const displayTypeKeys = Object.keys(DisplayTypeToIconMap)
    const firstMatch = displayTypeKeys.find((e) => e.toLowerCase().includes(rsrc))
    if (firstMatch) displayType = firstMatch
  }

  return ({ ...props }) => {
    return (
      <TargetIcon type={AWSDisplayTypeToIconTypeMap[displayType] || 'AWS_APPLICATION'} {...props} />
    )
  }
}
