import React from 'react'
import MultiSelectFilter from './FilterComponents/MultipleSelectFilter'
import SimpleSearchFilter from './FilterComponents/SimpleSearchFilter'
import TypeAhead from './FilterComponents/TypeAhead'
import NewThemeSingleSelectFilter from './FilterComponents/NewThemeSingleSelectFilter'

const ChooseFilter = ({ filter }) => {
  const handleChange = (value) => {
    if (typeof filter.onChange === 'function') filter.onChange(value)
    else console.error('error in Filters: onChange is not a function', filter)
  }
  switch (filter.filterType) {
    case 'Input':
       return <SimpleSearchFilter label={filter.Label} name={filter.Name} onChange={handleChange} />
    case 'Dropdown':
      return <NewThemeSingleSelectFilter defaultValue={filter.defaultValue} filterOptions={filter.filterOptions} onChange={handleChange} />
    case 'TypeAhead':
      return <TypeAhead defaultValue={filter.defaultValue} filterOptions={filter.filterOptions} onChange={handleChange} />
    case 'MultiSelect':
      return <MultiSelectFilter filterOptions={filter.filterOptions} label={filter.Label} name={filter.Name} onChange={handleChange} />
    default:
      return <SimpleSearchFilter label={filter.Label} name={filter.Name} onChange={handleChange} />
  }
}

export default ChooseFilter
