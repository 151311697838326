import React, { useRef } from 'react'

const FileUpload = ({ onFileRead, Component }) => {
  const fileInputRef = useRef(null)

  const handleFileUploadClick = () => {
    if (fileInputRef.current === null) return
    fileInputRef.current.click()
  }

  const handleFileRead = async (file) => {
    if (!file) return
    const text = await file.text()
    onFileRead(text)
    // File value must be cleared, because if same file is re-selected then, onChange event listner is not fired
    fileInputRef.current.value = ''
  }

  return (
    <div>
      <input
        ref={fileInputRef}
        onChange={(e) => handleFileRead(e.target.files[0])}
        hidden
        type='file'
        accept='application/JSON'
      />
      {Component && <Component onClick={handleFileUploadClick} />}
    </div>
  )
}

export { FileUpload }
