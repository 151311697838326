import VanillaTabs from 'Components/VanillaTabs'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiQuery from 'Core/Hooks/useMultiQuery'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getUserFromHit, resourceActivityAdapter } from 'Utils/PureHelperFuctions'
import ActivityTable from './ActivityTable'
import useAppView from 'Core/Hooks/useAppView'

const ActivityTabs = () => {
  const [DataSet, setDataSet] = useState([])
  const indexes = useMemoOnce(['event-log', 'proxy-audit-log'])
  const _slices = useMemoOnce(['accountList', 'serverList', 'appRolesList'])
  const { slices, initiallyLoaded } = useMultiSlice(_slices)
  const addIndex = useCallback((e, i) => ({ ...e, index: i + 1 }), [])
  const leastActive = useMemo(() => [...DataSet].sort((a, b) => a.count - b.count).map(addIndex), [DataSet, addIndex])
  const mostActive = useMemo(() => [...DataSet].sort((a, b) => b.count - a.count).map(addIndex), [DataSet, addIndex])
  const queryOptions = useMemoOnce({
    defaultTimeRange: { start: moment().subtract(30, 'days').toDate(), end: moment().toDate() }
  })
  const { hits, getQueryLogs, loading } = useMultiQuery(indexes, queryOptions)
  const showLoader = loading || !initiallyLoaded
  const getResourceNameFromHit = useCallback((hit) => {
    if (hit._index === 'proxy-audit-log') {
      return hit._source.Data.ServerName || hit._source.Data.Server
    }
    if (hit._index === 'event-log') {
      const {
        _source: { Resources }
      } = hit
      if (Resources && Array.isArray(Resources)) {
        const roleref = Resources && Resources.length > 0 ? Resources[0] : null
        if (roleref) return roleref.Name
      }
    }
    /** Resource name not found in hit */
    return false
  }, [])

  useEffect(() => {
    if (initiallyLoaded) {
      const rolesHits = hits['event-log']
      const serverHits = hits['proxy-audit-log']
      const groupServerActivity = resourceActivityAdapter(
        serverHits,
        'Server',
        getResourceNameFromHit,
        getUserFromHit,
        slices.serverList,
        slices.accountList,
        30
      )
      const groupdedRoleActivity = resourceActivityAdapter(
        rolesHits,
        'Role',
        getResourceNameFromHit,
        getUserFromHit,
        slices.appRolesList,
        slices.accountList,
        30
      )
      const resourceActivity = [...groupServerActivity, ...groupdedRoleActivity]
      setDataSet(resourceActivity)
    }
  }, [hits, initiallyLoaded])

  const TabList = useMemo(
    () => [
      { name: 'Most Active', Tab: <ActivityTable loading={showLoader} data={mostActive} /> },
      { name: 'Least Active', Tab: <ActivityTable loading={showLoader} data={leastActive} /> }
    ],
    [showLoader, mostActive, leastActive]
  )

  useEffect(() => {
    getQueryLogs()
  }, [getQueryLogs])

  return <VanillaTabs tabMinWidth='7rem' compact TabList={TabList} />
}

export default React.memo(ActivityTabs)
