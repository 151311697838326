import React from 'react'
import { Slack } from './Slack'
import { Jira } from './Jira'
import { ServiceNow } from './ServiceNow'
import { MSTeam } from './MSTeam'

const IntegrationsModal = ({
  showIntegrationModal,
  setShowIntegrationModal,
  onInputChange,
  selectedType,
  isDisabled,
  onContinue,
  isSelfHosted,
  setIsSelfHosted,
  message,
  handlePaste
}) => {
  if (!showIntegrationModal) return null
  switch (selectedType) {
    case 'slack':
      return (
        <Slack
          onContinue={onContinue}
          onInputChange={onInputChange}
          onCancel={() => setShowIntegrationModal(false)}
          message={message}
          handlePaste={handlePaste}
        />
      )
    case 'jira':
      return (
        <Jira
          isSelfHosted={isSelfHosted}
          setIsSelfHosted={setIsSelfHosted}
          onContinue={onContinue}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          showIntegrationModal={showIntegrationModal}
          setShowIntegrationModal={setShowIntegrationModal}
          message={message}
        />
      )
    case 'servicenow':
      return (
        <ServiceNow
          onContinue={onContinue}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          showIntegrationModal={showIntegrationModal}
          setShowIntegrationModal={setShowIntegrationModal}
          message={message}
        />
      )
    case 'msteam':
      return (
        <MSTeam
          isSelfHosted={isSelfHosted}
          setIsSelfHosted={setIsSelfHosted}
          onContinue={onContinue}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          onCancel={() => setShowIntegrationModal(false)}
          message={message}
          handlePaste={handlePaste}
        />
      )
    default:
      return null // or handle default case as needed
  }
}

export { IntegrationsModal }
