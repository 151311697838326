import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const ECR = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 40 40' {...props}>
      <svg className='w-6 h-6' height={40} width={40} xmlns='http://www.w3.org/2000/svg' {...props}>
        <defs>
          <linearGradient x1='0%' y1='100%' x2='100%' y2='0%' id='a'>
            <stop stopColor='#C8511B' offset='0%' />
            <stop stopColor='#F90' offset='100%' />
          </linearGradient>
        </defs>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h40v40H0z' fill='url(#a)' />
          <path
            d='M19.404 19.006a.791.791 0 0 0-.404.686v13.183L9 27.19V14.408l11.253-6.396 9.967 4.846-10.816 6.148zm12.051-6.163a.788.788 0 0 0-.424-.688L20.639 7.102a.807.807 0 0 0-.789.002L8.404 13.61a.789.789 0 0 0-.404.686v13.007a.79.79 0 0 0 .402.685l10.392 5.907c.124.07.263.105.402.105a.793.793 0 0 0 .804-.79V19.803l11.058-6.285a.78.78 0 0 0 .397-.676zM32 27.534l-9.039 5.29v-4.962l4.994-3 .033-.231c.009-.06.012-.06.012-.34v-5.547l4-2.274v11.064zm.598-12.083a.816.816 0 0 0-.804 0l-4.472 2.542a.65.65 0 0 0-.322.552v5.738l-4.643 2.787a.79.79 0 0 0-.396.683v5.41c0 .286.15.542.401.685.123.07.262.106.4.106a.84.84 0 0 0 .411-.109l9.422-5.514a.79.79 0 0 0 .405-.687V16.135a.781.781 0 0 0-.402-.684z'
            fill='#FFF'
          />
        </g>
      </svg>
    </SvgIcon>
  )
})

export default ECR
