import {
  faPlus,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import { getResourceName } from 'features/resources'
import { useServerControllerWizardContext } from 'features/serverControllers'
import { getUserServerName } from 'features/users'
import { IconButton, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React from 'react'

const SelectGroupsStep = () => {
  const { users, updateConfig, groups, groupsMap,  } = useServerControllerWizardContext()

  const handleAddNewGroup = () => {
    updateConfig({ groups: ['', ...groups] })
  }

  const handleGroupNameChange = (prevName, newName) => {
    const grpIndex = groups.indexOf(prevName)
    if (grpIndex === -1) throw new Error('Group Name sync error!')
    const newGroups = [...groups]
    newGroups[grpIndex] = newName

    const newGrpMap = { ...groupsMap }
    // moving the old group map to new group name key
    newGrpMap[newName] = groupsMap[prevName]
    //! must delete the old else, weird bugs
    delete newGrpMap[prevName]

    updateConfig({ groups: newGroups, groupsMap: newGrpMap })
  }

  const handleGroupRemove = (name) => {
    updateConfig({ groups: groups.filter((e) => e !== name) })
  }

  const handleGroupUserChange = (grpName, users) => {
    updateConfig({ groupsMap: { ...groupsMap, [grpName]: users.filter((e) => e) } })
  }

  const hasEmptyGroupElement = groups.includes('')

  if (!groups.length) handleAddNewGroup()

  // if (typeof groups.find((e) => e.trim() === '') === 'undefined') handleAddNewGroup()

  return (
    <div>
      <div className='flex justify-between items-center mb-2'>
        <div>
          <Typography variant='h4-regular'>Create Server Groups</Typography>
          <Typography variant='caption-regular'>
            Create a server group and map them with the users.
          </Typography>
        </div>
        <IconButton
          disabled={hasEmptyGroupElement}
          onClick={() => !hasEmptyGroupElement && handleAddNewGroup()}
          variant='primary'
          icon={faPlus}
        />
      </div>
      <div className='max-h-[400px] min-h-[200px] overflow-auto'>
        {groups.map((grpName, i) => {
          return (
            <div
              key={i}
              className='bg-[#fafafa] rounded p-2 flex gap-2 mb-2 items-center justify-between'
            >
              <div className='flex gap-2 w-[100%] items-end overflow-auto'>
                <TextInput
                  placeholder='Group Name'
                  sx={{ minWidth: '150px' }}
                  value={grpName}
                  onChange={(e) => handleGroupNameChange(grpName, e.target.value)}
                />
                <SelectDropDown
                  sx={{ minWidth: '250px' }}
                  multiple
                  menuItems={[
                    //@ts-ignore
                    { label: <em>Select Users</em>, value: '' },
                    ...users.map((usr) => {
                      const usrServerName = getUserServerName(usr)

                      return {
                        label: `${getResourceName(usr)} ${usrServerName && `(${usrServerName})`}`,
                        value: `${usr.ObjectMeta.Name}+${usrServerName}`
                      }
                    })
                  ]}
                  onChange={(e) => handleGroupUserChange(grpName, e.target.value)}
                  value={groupsMap[grpName]?.length ? groupsMap[grpName] : ['']}
                />
              </div>
              <IconButton
                onClick={() => handleGroupRemove(grpName)}
                variant='grayRed'
                icon={faTrash}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { SelectGroupsStep }
