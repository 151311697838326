import { getRoleLogQuery, getServerLogQuery } from 'Components/LogsContext'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { constructQuery } from 'Utils/LogsHelpers'

const useQuery = (
  {
    defaultUser = false,
    defaultDevice = { deviceID: false, deviceName: false },
    defaultTimeRange = { start: moment().subtract(1, 'month').toDate(), end: moment().toDate() },
    server = false,
    role = false
  }
) => {
  const [queryUser, setQueryUser] = useState(defaultUser)
  const [queryDevice, setQueryDevice] = useState(defaultDevice)
  const [queryTimeRange, setQueryTimeRange] = useState(defaultTimeRange)
  const [queryTillTime, setQueryTillTime] = useState('-')

  const [sshQuery, setSSHQuery] = useState(constructQuery(false, getServerLogQuery(server, queryUser, queryDevice.deviceName), defaultTimeRange))
  const [roleQuery, setRoleQuery] = useState(constructQuery(false, getRoleLogQuery(role, queryUser, queryDevice.deviceID), defaultTimeRange))

  const setQueries = useCallback((tillTime, server, role, user, timeRange, device) => {
    console.log('setQueries', defaultDevice)
    const tempSshQuery = constructQuery(tillTime, getServerLogQuery(server, user, device.deviceName), timeRange)
    const tempRoleQuery = constructQuery(tillTime, getRoleLogQuery(role, user, device.deviceID), timeRange)
    setSSHQuery(tempSshQuery)
    setRoleQuery(tempRoleQuery)
  }, [setSSHQuery, setRoleQuery])

  const onTimeFilterChange = useCallback((start, end) => {
    console.log('useQuery onTimeFilterChange', start, end)
    const tempTimeRange = { start, end }
    setQueryTimeRange(tempTimeRange)
    setQueries(false, server, role, queryUser, tempTimeRange, queryDevice)
  }, [queryUser, queryDevice])

  const onUserFilterChange = useCallback((user) => {
    console.log('useQuery onUserFilterChange', user)
    setQueryUser(user)
    setQueries(queryTillTime, server, role, user, queryTimeRange, queryDevice)
  }, [queryTimeRange, queryTillTime, queryDevice])

  const onDeviceFilterChange = useCallback((device) => {
    console.log('useQuery onUserFilterChange', device)
    setQueryDevice(device)
    setQueries(queryTillTime, server, role, queryUser, queryTimeRange, device)
  }, [queryTimeRange, queryTillTime, queryUser])

  const onTillTimeChange = useCallback((time) => {
    console.log('useQuery onTillTimeChange', time)
    setQueryTillTime(time)
    setQueries(time, server, role, queryUser, false, queryDevice)
  }, [queryUser, queryDevice])

  const query = useMemo(() => ({ sshQuery, roleQuery }), [sshQuery, roleQuery])

  return (
    {
      query,
      queryTimeRange,
      onTimeFilterChange,
      onUserFilterChange,
      onTillTimeChange,
      onDeviceFilterChange
    }
  )
}

export default useQuery
