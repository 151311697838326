import { faPen } from '@fortawesome/pro-solid-svg-icons'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityInfoBar } from 'V2Components'
import { ManageGroupUsers } from 'features/groups'
import { createRsrcKey, getResourceName } from 'features/resources'
import _ from 'lodash'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const ManageAdmins = () => {
  const { slices, getObjectRef } = useMultiSlice(['userList', 'groupList'])
  const [showManageAdmins, setShowManageAdmins] = useState(false)

  const adminGroup = _.find(slices.groupList, { ObjectMeta: { Name: 'admin' } })
  const adminUsers = getObjectRef(adminGroup?.Spec.Users?.ObjectRef || [])

  useHTMLBodyScrollbar(showManageAdmins)

  return (
    <div className='mt-8'>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>Manage Admins</Typography>
        <Button onClick={() => setShowManageAdmins(true)} variant='grayBlue' icon={faPen}>
          Manage
        </Button>
      </div>
      {adminGroup && (
        <div className='mt-4'>
          <Typography variant='body-regular'>
            {adminGroup.Spec.Users.ObjectRef?.length} Admins
          </Typography>
          <div className='mt-4'>
            {adminUsers.map((user) => {
              return (
                <EntityInfoBar
                  endButton={false}
                  kind='User'
                  name={getResourceName(user)}
                  key={createRsrcKey(user)}
                  caption={user.Spec.EmailID}
                />
              )
            })}
          </div>
        </div>
      )}
      {showManageAdmins && (
        <ManageGroupUsers
          group={adminGroup}
          onCancel={() => setShowManageAdmins(false)}
          onSuccess={() => setShowManageAdmins(false)}
        />
      )}
    </div>
  )
}

export { ManageAdmins }
