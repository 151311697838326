import { createRsrcKey, reverseRsrcKey } from 'features/resources'

export const BUNDLE_TYPES = {
  AWS: 'AWS',
  GCP: 'GCP',
  AZURE: 'AZURE',
  GITHUB_APP: 'GITHUB_APP',
  HTTP_APP: 'HTTP_APP',
  PRIVATE_SERVER: 'PRIVATE_SERVER',
  SALESFORCE_APP: 'SALESFORCE_APP'
}

export const BUNDLE_TYPE_ICON_TYPE = {
  AWS: 'BUNDLE_AWS',
  GCP: 'BUNDLE_GCP',
  AZURE: 'BUNDLE_AZURE',
  HTTP_APP: 'REST_API',
  PRIVATE_SERVER: 'SERVER_PRIVATE',
  SALESFORCE_APP: 'SALESFORCE_BUNDLE'
}

export const BUNDLE_REQ_TYPE = {
  IAMAction: 'IAMAction',
  Target: 'Target',
  Salesforce: 'Salesforce'
}

export const IAMActionKeys = ['AwsIAM', 'GcpIAM', 'AzureIAM']

/**
 * Get all the resources in the bundle
 *
 * @param {*} bundle
 * @returns {import('types').ObjectRef[]}
 */
export const getBundleResourcesRefs = (bundle) => {
  const resourcesKeys = []
  const { ActionMap } = bundle.Spec

  if (bundle.ReqType === BUNDLE_REQ_TYPE.IAMAction || !bundle.ReqType) {
    for (const actionKey in ActionMap) {
      const rules = ActionMap[actionKey].PolicyRule
      rules.forEach((rule) => {
        //IAM Action resources
        resourcesKeys.push(...rule.Services.ObjectRef.map(createRsrcKey))
      })
    }
  } else {
    for (const actionKey in ActionMap) {
      const rules = ActionMap[actionKey].PolicyRule
      rules.forEach((rule) => {
        resourcesKeys.push(createRsrcKey(rule.ObjectRef))
      })
    }
  }

  const uniqKeys = [...new Set(resourcesKeys)]

  return uniqKeys.map(reverseRsrcKey)
}

/**
 * Function to get the iam actions attached to a rsrc in a bundle.
 * @param {*} bundle
 * @returns { { [k:any]: import("types").GenricObjectRef<'IAMAction'>[] } }
 */
export const getBundleAttachedIAMActionsResources = (bundle) => {
  const map = {}

  const { ActionMap } = bundle.Spec
  for (const actionKey in ActionMap) {
    const rules = ActionMap[actionKey].PolicyRule
    if (IAMActionKeys.includes(actionKey)) {
      //IAM Action resources
      rules.forEach((rule) => {
        const rsrcKeys = rule.Services.ObjectRef.map(createRsrcKey)
        rsrcKeys.forEach((rsrcKey) => {
          const iamActions = map[rsrcKey] || []
          iamActions.push(rule.ObjectRef)
          map[rsrcKey] = iamActions
        })
      })
    }
  }

  return map
}

/**
 * Returns iam actions for this bundle
 * @param {*} bundle
 * @returns {import("types").GenricObjectRef<'IAMAction'>[]}
 */
export const getBundleIAMActions = (bundle) => {
  const actions = []

  const { ActionMap } = bundle.Spec

  if (bundle.ReqType !== '' && bundle.ReqType !== BUNDLE_REQ_TYPE.IAMAction) return []
  for (const actionKey in ActionMap) {
    const rules = ActionMap[actionKey].PolicyRule
    if (IAMActionKeys.includes(actionKey)) {
      //IAM Action resources
      rules.forEach((rule) => {
        actions.push(createRsrcKey(rule.ObjectRef))
      })
    }
  }

  const uniq = [...new Set(actions)]

  //@ts-ignore
  return uniq.map(reverseRsrcKey)
}

/**
 * Get the resource map with key and value as principal used.
 * @param {*} bundle
 * @returns The map of the rsrc key and it's principal
 */
export const getBundleAttachedPrincipals = (bundle) => {
  const principalsMap = {}
  const { ActionMap } = bundle.Spec

  for (const actionKey in ActionMap) {
    const rules = ActionMap[actionKey].PolicyRule
    rules.forEach((rule) => {
      if (rule.Principal) {
        principalsMap[createRsrcKey(rule.ObjectRef)] = rule.Principal
      }
    })
  }

  return principalsMap
}
