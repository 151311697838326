import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityInfoBar } from 'V2Components'
import { createRsrcKey, getResourceName } from 'features/resources'
import { getUserGroupsRefs } from 'features/users'
import { Typography } from 'procyon-ui'
import React from 'react'

function GroupsTab({ user }) {
  const { getObjectRef } = useMultiSlice(['groupList'])

  const groups = (() => getObjectRef(getUserGroupsRefs(user)))()

  return (
    <div className='h-[500px] overflow-y-auto'>
      <Typography variant='body-regular' className='mb-6'>
        {groups.length} Groups
      </Typography>
      {groups.map((g) => (
        <EntityInfoBar
          endButton={false}
          key={createRsrcKey(g)}
          kind='Group'
          caption={`${g.Spec.Users?.ObjectRef?.length} Users`}
          name={getResourceName(g)}
        />
      ))}
    </div>
  )
}

export { GroupsTab }
