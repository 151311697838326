import { CheckboxFilters } from 'V2Components'
import { useResourcesViewContext } from 'features/resources'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { getIAMResourceDisplayType } from '../utils'

function IAMResourcesCheckboxFilters({
  activeFilters,
  onActiveFilterChange,
  grantedCount = 0,
  unGrantedCount = 0,
  bookmarkCount = 0,
  activeCloudType = 'AWS',
  rsrcs = []
}) {
  const { filterOptions } = useResourcesViewContext()

  /**
   * exclude aws ecs subtype checkbox 1 ecs target, 2 ecs service
   *
   */

  const excludeAWSEcsSubType = ['AWS ECS Task', 'AWS ECS Service']

  /**
   * Find and increase the amount of the filter with Type and returns the filters
   * @param {*} filters
   * @param {*} Type
   * @returns
   */
  const incrementFilterAmount = (filters, Type) => {
    const index = _.findIndex(filters, (e) => e.value === Type)
    const filter = filters[index]
    const amount = filterOptions.hideFilterCount ? null : filter.amount + 1
    filters[index] = { ...filter, amount }
    return filters
  }

  const checkboxFilterItems = useMemo(() => {
    const items = [
      filterOptions.hideAccessStatusFilters
        ? { filters: [] }
        : {
            label: 'Access Status',
            filters: [
              {
                label: 'Granted',
                amount: grantedCount,
                value: 'granted',
                checked: true
              },
              {
                label: 'Ungranted',
                amount: unGrantedCount,
                value: 'ungranted',
                checked: false
              },
              {
                label: 'Bookmarked',
                amount: bookmarkCount,
                value: 'bookmarked',
                checked: true
              }
            ]
          },
      {
        label: 'Resource Types',
        filters: []
      }
    ]

    rsrcs.forEach((rsrc) => {
      const Type = getIAMResourceDisplayType(rsrc)
      // exluding aws ecs subtype
      const isAWSECSSubTypeIncluded = excludeAWSEcsSubType.some((type) => type === Type)
      if (isAWSECSSubTypeIncluded) return
      if (!_.find(items[1].filters, { value: Type })) {
        items[1].filters.push({
          label: Type,
          value: Type,
          amount: 1,
          checked: activeFilters.includes(Type)
        })
      } else items[1].filters = incrementFilterAmount(items[1].filters, Type)
    })

    //

    return items
  }, [
    rsrcs,
    activeFilters,
    grantedCount,
    unGrantedCount,
    bookmarkCount,
    activeCloudType,
    filterOptions.hideAccessStatusFilters
  ])

  return (
    <CheckboxFilters
      filterItems={checkboxFilterItems}
      activeFilters={activeFilters}
      onActiveFilterChange={onActiveFilterChange}
    />
  )
}

export { IAMResourcesCheckboxFilters }
