
import { Box, makeStyles, MenuItem, Select } from '@material-ui/core'
import { CustomTypography } from 'Components/CustomTypography'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import _ from 'lodash'
import React from 'react'
import ErrorBox from '../ErrorBox/ErrorBox'
const defaultLabelExtractor = (option) => option
const defaultValueExtractor = (option) => option

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },

  select: {
    height: props => theme.spacing(props.height)
  }
}
))
const ThemeDropDown = ({ disabledHelp, defaultValue, hidelabel = false, labelMarginBottom = 0.5, mb = 0, height = 2.3, options = [], touched, errored, label, showLabel, labelExtractor = defaultLabelExtractor, valueExtractor = defaultValueExtractor, ...rest }) => {
  const classes = useStyles({ height: height })
  const fieldId = _.uniqueId('field-')
  return (
    <>
      {!hidelabel &&
        <CustomTypography.Body>
          {label}
          {rest.disabled &&
            <CustomTypo variant='caption'>{` | ${disabledHelp} `}</CustomTypo>}
        </CustomTypography.Body>}
      <Box mt={labelMarginBottom} mb={mb}>
        <Select
          labelId={fieldId}
          variant='outlined'
          fullWidth
          defaultValue={defaultValue}
          {...rest}
          className={classes.select}
        >
          {options.map((option) => (
            <MenuItem key={valueExtractor(option)} value={valueExtractor(option)}>
              {labelExtractor(option)}
            </MenuItem>
          ))}
        </Select>

        <ErrorBox
          error={errored}
          touched={touched}
        />
      </Box>
    </>
  )
}

export default ThemeDropDown

ThemeDropDown.propTypes = {
  // touched: PropTypes.bool
}

ThemeDropDown.defaultProps = {
  showLabel: false,
  touched: false
}
