import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

const LowerCaseKindMap = {
  approle: 'AppRole',
  database: 'Database',
  server: 'Server',
  awsresource: 'AwsResource',
  gcpresource: 'GcpResource',
  azureresource: 'AzureResource',
  kubenamespace: 'KubeNamespace',
  rdpserver: 'RDPServer',
  kafka: 'Kafka',
  kubecluster: 'KubeCluster'
}

export const ResourceDetailsContext = React.createContext({
  rsrc: undefined,
  account: undefined
})

function ResourceDetailsProvider({ children, rsrcNameKey, rsrcKindKey, rsrcList }) {
  const [rsrc, setRsrc] = useState()
  const [account, setAccount] = useState()

  const { slices, getObjectRef, selectDispatch } = useMultiSlice(['accountList'])

  const rsrcName = useParams()[rsrcNameKey]
  const rsrcKind = LowerCaseKindMap[useParams()[rsrcKindKey]]

  if (rsrc && !account && slices.accountList.length) {
    //@ts-ignore
    const account = getObjectRef(rsrc.Spec.Account)
    if (account) setAccount(account)
  }

  if (rsrcList?.length && rsrcKind) {
    const r = _.find(rsrcList, {
      ObjectMeta: { Name: decodeURIComponent(rsrcName), Kind: rsrcKind }
    })
    if (r && !_.isEqual(rsrc, r)) {
      setRsrc(r)
    }
  }

  useEffect(() => {
    selectDispatch(['accountList'])
  }, [])

  return (
    <ResourceDetailsContext.Provider value={{ rsrc, account }}>
      {rsrc && children}
      {!rsrc && (
        <LoadingFeedback shrinked message='Fetching Resource' caption='Please wait' loading />
      )}
    </ResourceDetailsContext.Provider>
  )
}

export { ResourceDetailsProvider }
export const useResourceDetails = () => useContext(ResourceDetailsContext)
