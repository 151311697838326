import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { DeviceInfoBar } from 'V2Components'
import { createRsrcKey } from 'features/resources'
import { useUserDetailsContext } from 'features/users'
import { Typography } from 'procyon-ui'
import React from 'react'
import { UserInfo } from './components/UserInfo'
import { UserGroups } from './components/UserGroups'

function OverviewTab() {
  const { slices } = useMultiSlice(['deviceList'])
  const { user } = useUserDetailsContext()

  const devicesOwned = (() => {
    return slices.deviceList.filter((d) => d.UserName === user.ObjectMeta.Name)
  })()

  return (
    <div>
      <div className='flex gap-4'>
        <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
          <UserInfo user={user} />
          <div className='mt-6'>
            <Typography variant='body-semiBold'>Devices</Typography>
            <div>
              {devicesOwned.map((d) => (
                <DeviceInfoBar device={d} key={createRsrcKey(d)} />
              ))}
            </div>
          </div>
        </div>
        <div className='w-1/2'>
          <UserGroups user={user} />
        </div>
      </div>
    </div>
  )
}

export { OverviewTab }
