import { Storage } from '@material-ui/icons'
import { Theme } from 'Core'

export const mostActiveSampleData = [
  // {
  //   index: 1,
  //   activeUser: 102,
  //   location: 'San Francisco, CA. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 160, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 40, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 2,
  //   activeUser: 89,
  //   location: 'Houston, TX. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 70, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 50, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 3,
  //   activeUser: 102,
  //   location: 'Mumbai, Maharashtra. India',
  //   activityDistribution: [
  //     { name: 'Targets', value: 80, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 20, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 4,
  //   activeUser: 102,
  //   location: 'San Francisco, CA. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 85, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 75, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 5,
  //   activeUser: 43,
  //   location: 'Kasan, Rr. Russia',
  //   activityDistribution: [
  //     { name: 'Targets', value: 70, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 50, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 6,
  //   activeUser: 102,
  //   location: 'San Francisco, CA. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 160, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 40, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 7,
  //   activeUser: 89,
  //   location: 'Houston, TX. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 70, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 50, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 8,
  //   activeUser: 102,
  //   location: 'Mumbai, Maharashtra. India',
  //   activityDistribution: [
  //     { name: 'Targets', value: 80, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 20, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 9,
  //   activeUser: 102,
  //   location: 'San Francisco, CA. USA',
  //   activityDistribution: [
  //     { name: 'Targets', value: 85, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 75, color: Theme.palette.info.light }]
  // },
  // {
  //   index: 10,
  //   activeUser: 43,
  //   location: 'Kasan, Rr. Russia',
  //   activityDistribution: [
  //     { name: 'Targets', value: 70, color: Theme.palette.warning.light },
  //     { name: 'IAM Role', value: 50, color: Theme.palette.info.light }]
  // }

]
