import { faGrid, faLaptopMobile, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { faBrowser, faLayerGroup } from '@fortawesome/pro-solid-svg-icons'
import { createRsrcKey, getResourceName } from 'features/resources'
import { getUserDisplayName, getUserGroupsRefs, getUserSource, getUserStatus } from 'features/users'
import { Button, Label, MenuButton, Typography } from 'procyon-ui'
import React from 'react'

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const responsiveColumns = () => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
      fixed: 'left',
      width: 200,
      sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
      onCell(data) {
        return {
          onClick: data.goToUserDetails
        }
      },
      render(value) {
        return (
          <div className='flex cursor-pointer'>
            {value.name && (
              <Typography variant='body-regular'>{value.name.substring(0, 30)}</Typography>
            )}
            {!value.name && (
              <Label
                // @ts-ignore
                text={<em>Empty</em>}
                variant='gray'
              />
            )}
            {value.isLoggedIn && <strong className='text-[#3267D6] ml-1'>(You)</strong>}
          </div>
        )
      }
    },
    {
      dataIndex: 'email',
      title: 'Email',
      align: 'left',
      width: 200,
      sorter: (v1, v2) => v1.email.localeCompare(v2.email)
    },
    {
      dataIndex: 'groups',
      title: 'Groups',
      width: 100,
      sorter: (v1, v2) => v1.groups.count - v2.groups.count,
      render(value) {
        return (
          <Button onClick={value.onClick} variant='gray' icon={faUsers}>
            {value.count || 0}
          </Button>
        )
      }
    },
    {
      dataIndex: 'devices',
      title: 'Devices',
      width: 100,
      sorter: (v1, v2) => v1.devices.count - v2.devices.count,
      render(value) {
        return (
          <Button onClick={value.onClick} variant='gray' icon={faLaptopMobile}>
            {value.count || 0}
          </Button>
        )
      }
    },
    {
      dataIndex: 'rsrcAccess',
      title: 'Resource Access',
      width: 250,
      sorter: (v1, v2) => v1.rsrcAccess.count.total - v2.rsrcAccess.count.total,
      render(value) {
        // * @param {'GROUPS'|'DEVICES'|'RSRCS'|'APPS'|'TARGETS'} tab
        const onClick = (tab) => value.onClick?.(tab)
        return (
          <div className='flex gap-4'>
            <Button onClick={() => onClick('TARGETS')} variant='gray' icon={faLayerGroup}>
              {value.count.targetsCount || 0}
            </Button>
            <Button onClick={() => onClick('RSRCS')} variant='gray' icon={faBrowser}>
              {value.count.rsrcsCount || 0}
            </Button>
            <Button onClick={() => onClick('APPS')} variant='gray' icon={faGrid}>
              {value.count.appsCount || 0}
            </Button>
          </div>
        )
      }
    },
    {
      dataIndex: 'source',
      title: 'Source',
      width: 100,
      sorter: (v1, v2) => v1.source.localeCompare(v2.source)
    },
    {
      dataIndex: 'type',
      title: 'Type',
      width: 100,
      sorter: (v1, v2) => v1.type.localeCompare(v2.type)
    },
    {
      dataIndex: 'status',
      title: 'Status',
      sorter: (v1, v2) => v1.status.localeCompare(v2.status),
      width: 100,
      render(value) {
        return (
          <Label
            width='80px'
            variant={value === 'Active' ? 'success' : 'gray'}
            text={<div className='!text-center w-[100%]'>{value}</div>}
          />
        )
      }
    },
    {
      dataIndex: 'details',
      title: '',
      width: 150,
      fixed: 'right',
      render(value) {
        return (
          <Button onClick={value.onClick} variant='grayBlue'>
            View Details
          </Button>
        )
      }
    },
    {
      dataIndex: 'more',
      title: ' ',
      fixed: 'right',
      width: '50px',
      render(value) {
        return (
          <MenuButton
            menuItems={[
              {
                title: 'View Profile',
                action: value.onViewProfileClick
              },
              {
                action: value.onDeleteClick,
                //@ts-ignore
                title: <span className='text-red-500'>Delete</span>
              }
            ]}
          />
        )
      }
    }
  ]
}

/**
 * @type {() => import("@mui/x-data-grid").GridColDef[]}
 * @returns
 */
export const columns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 225,
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell({ value }) {
      return (
        <div className='flex cursor-pointer'>
          {value.name && (
            <Typography variant='body-regular'>{value.name.substring(0, 30)}</Typography>
          )}
          {!value.name && (
            <Label
              // @ts-ignore
              text={<em>Empty</em>}
              variant='gray'
            />
          )}
          {value.isLoggedIn && <strong className='text-[#3267D6] ml-1'>(You)</strong>}
        </div>
      )
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 270
  },
  {
    field: 'groups',
    headerName: 'Groups',
    width: 120,
    sortComparator: (v1, v2) => v1.count - v2.count,
    renderCell({ value }) {
      return (
        <Button onClick={value.onClick} variant='gray' icon={faUsers}>
          {value.count || 0}
        </Button>
      )
    }
  },
  {
    field: 'devices',
    headerName: 'Devices',
    width: 120,
    sortComparator: (v1, v2) => v1.count - v2.count,
    renderCell({ value }) {
      return (
        <Button onClick={value.onClick} variant='gray' icon={faLaptopMobile}>
          {value.count || 0}
        </Button>
      )
    }
  },
  {
    field: 'rsrcAccess',
    headerName: 'Resource Access',
    sortComparator: (v1, v2) => v1.count.total - v2.count.total,
    width: 300,
    renderCell({ value }) {
      // * @param {'GROUPS'|'DEVICES'|'RSRCS'|'APPS'|'TARGETS'} tab
      const onClick = (tab) => value.onClick?.(tab)
      return (
        <div className='flex gap-4'>
          <Button onClick={() => onClick('TARGETS')} variant='gray' icon={faLayerGroup}>
            {value.count.targetsCount || 0}
          </Button>
          <Button onClick={() => onClick('RSRCS')} variant='gray' icon={faBrowser}>
            {value.count.rsrcsCount || 0}
          </Button>
          <Button onClick={() => onClick('APPS')} variant='gray' icon={faGrid}>
            {value.count.appsCount || 0}
          </Button>
        </div>
      )
    }
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 100
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    sortComparator: (v1, v2) => v1.localeCompare(v2),
    renderCell({ value }) {
      return (
        <Label
          width='80px'
          variant={value === 'Active' ? 'success' : 'gray'}
          text={<div className='!text-center w-[100%]'>{value}</div>}
        />
      )
    }
  },
  {
    field: 'details',
    headerName: ' ',
    width: 150,
    sortable: false,
    renderCell({ value }) {
      return (
        <Button onClick={value.onClick} variant='grayBlue'>
          View Details
        </Button>
      )
    }
  },
  {
    field: 'more',
    headerName: ' ',
    sortable: false,
    width: 100,
    renderCell({ value }) {
      return (
        <MenuButton
          menuItems={[
            {
              title: 'View Profile',
              action: value.onViewProfileClick
            },
            {
              action: value.onDeleteClick,
              //@ts-ignore
              title: <span className='text-red-500'>Delete</span>
            }
          ]}
        />
      )
    }
  }
]

export const tableRowsAdapter = ({
  users = [],
  loggedUserKey = '',
  checkIsAdmin,
  onDetailsClick,
  getUserDeviceCount,
  getUserRsrcsCount,
  onViewProfileClick,
  onDeleteClick
}) => {
  return users.map((u) => {
    return {
      id: createRsrcKey(u),
      objectName: u.ObjectMeta.Name,
      name: {
        // For SCIM users, naming fields might not be there and if it's not there we will show `Empty` label
        name: getUserSource(u) === 'SCIM' ? getUserDisplayName(u, false) : getResourceName(u),
        isLoggedIn: loggedUserKey === createRsrcKey(u)
      },
      email: u.Spec.EmailID || u.ObjectMeta.Name,
      groups: {
        count: getUserGroupsRefs(u).length,
        onClick: () => onDetailsClick?.(u, 'GROUPS')
      },
      devices: {
        count: getUserDeviceCount?.(u),
        onClick: () => onDetailsClick?.(u, 'DEVICES')
      },
      rsrcAccess: {
        count: getUserRsrcsCount?.(u),
        onClick: (tab) => onDetailsClick?.(u, tab)
      },
      source: getUserSource(u),
      status: getUserStatus(u),
      type: checkIsAdmin(u) ? 'Admin' : 'User',
      more: {
        onDeleteClick: () => onDeleteClick?.(u),
        onViewProfileClick: () => onViewProfileClick?.(u)
      },
      details: {
        onClick: () => onViewProfileClick?.(u)
      }
    }
  })
}
