import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { FullScreenModal, LoadingFeedback } from 'V2Components'
import { deleteAutoApprovePolicy } from 'features/approvals'
import { AlertModal, Button } from 'procyon-ui'
import React, { useState } from 'react'

function DeleteAutoApprovePolicyModal({ paaPolicy, showModal, onCancel, afterDelete = null }) {
  const [loading, setLoading] = useState(false)

  const handlePolicyDelete = async () => {
    setLoading(true)
    await deleteAutoApprovePolicy(paaPolicy)
    deleteSliceData(paaPolicy)
    setLoading(false)
    afterDelete && afterDelete()
  }

  return (
    <div>
      <LoadingFeedback loading={loading} caption='Please wait...' message={'Deleting Auto Approve Policy'} />
      <FullScreenModal showModal={showModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <AlertModal description='' icon={faExclamationCircle} title={`Are you sure you want to delete this policy?`}>
            <React.Fragment key='.0'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button onClick={handlePolicyDelete} variant='grayRed'>
                Delete
              </Button>
            </React.Fragment>
          </AlertModal>
        </div>
      </FullScreenModal>
    </div>
  )
}

export { DeleteAutoApprovePolicyModal }
