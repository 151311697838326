import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const options = (extraOptions = {}) => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: false,
  rowsPerPageOptions: [5, 10, 15, 100],
  ...extraOptions
})

export const columns = (props) => [
  {
    name: 'index',
    label: ' ',
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { paddingRight: '1.563rem', width: '0px', paddingLeft: '0.2rem' } }),
      customBodyRender: (value) => <Typography variant='body2'>{value}</Typography>
    }
  },
  {
    name: 'title',
    label: props.tableTitleHeader,
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { paddingLeft: '0px' } }),
      setCellHeaderProps: () => ({ className: props.classes.titleHeader }),
      customBodyRender: (value) => <Typography variant='body2'>{value}</Typography>
    }
  },
  {
    name: 'text',
    label: '% Activity',
    options: {
      filter: true,
      sort: true,
      setCellHeaderProps: () => ({ className: props.classes.activity }),
      customBodyRender: (value) => {
        return (
          <Box pr={0.5} textAlign='right'>
            <Typography variant='body2'>{value}</Typography>
          </Box>
        )
      }
    }
  }
]
