import { Box } from '@material-ui/core'
import React from 'react'
import ListItem from './ListItem'
const ListData = ({ listData }) => {
  return (
    <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
      {listData && listData.map(data =>
        <Box maxWidth='50%' flex='50%' paddingRight='10px'>
          <ListItem
            key={data.title}
            title={data.title}
            current={data.current}
            total={data.total}
            error={data.error}
            Icon={data.Icon}
          />
        </Box>
      )}
    </Box>
  )
}

export default ListData
