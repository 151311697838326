import { Button, Typography, Grid, makeStyles, Box, Tooltip } from '@material-ui/core'

import { FilterList } from '@material-ui/icons'
import PageHeaderActions from 'Components/PageHeader/PageHeaderActions'
import ThemeButton from 'Components/ThemeButton'

import React, { useCallback } from 'react'
const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.2, 0, 0.5, 0),
    marginBottom: (props) => (props.actionLabel ? theme.spacing(0.75) : theme.spacing(0.5)),
    marginTop: (props) => (props.noMarginTop ? '0px' : theme.spacing(1.2))
  },
  Icon: {
    marginLeft: theme.spacing(1 / 2)
  },
  FilterButton: {
    fontSize: theme.typography.body1.fontSize,
    '& .MuiButton-label': {
      textTransform: 'capitalize'
    },
    fontWeight: 'normal'
  },
  pageInfo: {
    color: theme.palette.text.secondary
  }
}))
const ThemeTableHeader = ({
  openGridSettings,
  viewMode,
  viewModes,
  setViewMode,
  showFilters,
  noMarginTop = false,
  showDownloadCsv,
  data,
  title,
  onClick,
  pageInfo,
  actionLabel,
  isActionDisabled,
  actionToolTip,
  disabledActionToolTip,
  onActionClick,
  ActionIcon,
  direction = 'row',
  onCsvDownload = () => { }
}) => {
  const classes = useStyle({ noMarginTop, actionLabel })

  const GetPageInfo = () => {
    const count = pageInfo?.count
    const page = pageInfo?.page
    const rowsPerPage = pageInfo?.rowsPerPage
    const getRowsCount = () => {
      const LastRowOfPreviousPage = rowsPerPage * page
      const firstRowOnPage = LastRowOfPreviousPage + 1
      if (count <= rowsPerPage || page === undefined) {
        /**
         * When there is only one page
         * Special case as per UX design
         **/
        return count
      } else {
        // More than one page data is present
        if (page === Math.floor(count / rowsPerPage)) {
          // Its the last page
          return firstRowOnPage + '-' + count
        } else {
          // Any page between first and last pages
          return firstRowOnPage + '-' + (LastRowOfPreviousPage + rowsPerPage)
        }
      }
    }
    return (
      <Box
        width='100%'
        display='flex'
        alignItems='center'
        justifyContent={direction === 'row-reverse' && 'flex-end'}
        pr={direction === 'row-reverse' && 1}
        marginBottom={(actionLabel || viewModes || showFilters) ? 0 : 0.75}
      >
        <Typography variant='body1' className={classes.pageInfo}>
          Showing {`${pageInfo && getRowsCount()} of ${data.length} ${title}`}
        </Typography>
      </Box>
    )
  }
  const handleActionClick = useCallback(() => {
    if (typeof onActionClick === 'function') onActionClick()
  }, [onActionClick])
  return (
    <Grid
      className={classes.root}
      container
      direction={direction}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid xs={4} item>
        <GetPageInfo />
      </Grid>
      <Grid xs={6} item container direction='row' justifyContent={direction === 'row' && 'flex-end'} alignItems='center'>
        {
          viewModes && <PageHeaderActions openGridSettings={openGridSettings} viewMode={viewMode} viewModes={viewModes} setViewMode={setViewMode} />
        }
        {
          showFilters &&
            <Button onClick={onClick} color='primary' className={classes.FilterButton}>Manage Filters <FilterList className={classes.Icon} /></Button>
        }
        {typeof onActionClick === 'function' &&
          <Tooltip disableHoverListener={(!isActionDisabled) && !actionToolTip} title={isActionDisabled ? disabledActionToolTip : actionToolTip}>
            <span>
              <ThemeButton disabled={isActionDisabled} onClick={handleActionClick}>{ActionIcon && <ActionIcon />}{actionLabel}</ThemeButton>
            </span>
          </Tooltip>}
      </Grid>
    </Grid>
  )
}

export default ThemeTableHeader
