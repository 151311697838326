import { Button, Label } from 'procyon-ui'
import React from 'react'

const FooterComponent = ({
  onSubmit,
  indexedErrors = [],
  activeStep, //from wizard
  setActiveStep, //from wizard
  totalSteps //from wizard
}) => {
  const getError = () => {
    if (activeStep === totalSteps - 1) {
      return indexedErrors.find((m) => !!m)
    }
    return indexedErrors[activeStep]
  }
console.log('[pac](error):', indexedErrors, activeStep)
  const stepError = getError()
  return (
    <div className='flex justify-end gap-2'>
      {stepError && <Label variant='warning' text={stepError} />}
      {activeStep > 0 && (
        <Button onClick={() => setActiveStep((a) => a - 1)} variant='gray'>
          Previous
        </Button>
      )}
      <Button
        disabled={!!stepError}
        onClick={() => {
          if (activeStep < totalSteps - 1) setActiveStep((s) => s + 1)
          else onSubmit()
        }}
        variant='primary'
      >
        {activeStep === totalSteps - 1 ? 'Submit' : 'Continue'}
      </Button>
    </div>
  )
}

export { FooterComponent }
