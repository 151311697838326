import { getResourceName } from 'features/resources'
import { AccountSelectionItem, TargetIcon } from 'procyon-ui'
import React from 'react'

const ServiceNowChannelSelection = ({
  filteredChannel,
  selectedItemIndex,
  handleChannelSelection
}) => {
  return (
    <>
      {filteredChannel &&
        filteredChannel.map((item, index) => (
          <AccountSelectionItem
            assigned={index === selectedItemIndex}
            accountName={getResourceName(item)}
            selected={index === selectedItemIndex}
            icon={<TargetIcon type='SERVICENOW' />}
            onClickAddButton={() => handleChannelSelection(item, index)}
            width='100%'
            key={index}
          />
        ))}
    </>
  )
}

export { ServiceNowChannelSelection }
