import { faBrowser, faCubes, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getApprovalIconAndVariant } from 'features/approvals'
import { dateSortComparator, getEntitiyFAIcon } from 'features/resources'
import moment from 'moment'
import { Button, Label, MenuButton, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

const ViewDetailsButton = ({ url = '' }) => {
  const history = useHistory()
  return (
    <Button onClick={() => history.push(url)} variant='grayBlue'>
      View Details
    </Button>
  )
}

const renderRequestedFor = (value) => {
  if (!value?.length) return <Label text='Not Found' variant='gray' />
  return (
    <div className='flex gap-2'>
      {value.map((e, i) => {
        const icon = getEntitiyFAIcon(e.RefKind)
        return (
          <div key={i} className='flex items-center'>
            <FontAwesomeIcon icon={icon} />
            <p className='ml-2'>{e.name?.substring(0, 30)}</p>
          </div>
        )
      })}
    </div>
  )
}

const renderRequestedBy = (value) => {
  if (!value) return <Label text='Not Found' variant='gray' />
  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={faUser} />
      <p className='ml-2'>{value?.substring(0, 30)}</p>
    </div>
  )
}

const renderRowOptions = (value) => {
  if (!value || !value.length) return
  return (
    <MenuButton
      menuItems={value.map(({ title, action, twColor }) => ({
        action,
        title: (
          <Typography variant='body-regular' className={twColor}>
            {title}
          </Typography>
        )
      }))}
    />
  )
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const pendingTableColumns = () => {
  return [
    {
      dataIndex: 'name',
      onCell(data) {
        return {
          onClick: data.goToDetails
        }
      },
      title: 'Name',
      width: 250,
      fixed: 'left',
      sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
      render(value) {
        const { Icon, name } = value
        return (
          <Typography className='flex gap-1 items-center' variant='body-regular'>
            {Icon && <Icon />}
            {name}
          </Typography>
        )
      }
    },
    {
      dataIndex: 'requestedBy',
      title: 'Requested By',
      sorter: (v1, v2) => v1.requestedBy.localeCompare(v2.requestedBy),
      width: 250,
      render: renderRequestedBy
    },
    {
      dataIndex: 'requestedFor',
      title: 'Requested for',
      width: 350,
      render: renderRequestedFor
    },
    {
      dataIndex: 'resources',
      title: 'Resources',
      width: 130,
      sorter: (v1, v2) => v2.resources.count - v1.resources.count,
      render(value) {
        return (
          <Button onClick={value.onClick} icon={faBrowser} variant='grayBlue'>
            {value.count}
          </Button>
        )
      }
    },
    {
      dataIndex: 'submittedOn',
      title: 'Submitted On',
      width: 250,
      sorter: (v1, v2) => {
        if (!v1.submittedOn) return -1
        if (!v2.submittedOn) return 1
        return new Date(v1.submittedOn).getTime() - new Date(v2.submittedOn).getTime()
      },
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'expiresOn',
      title: 'Expire On',
      width: 220,
      sorter: (v1, v2) => {
        if (!v1.expiresOn) return -1
        if (!v2.expiresOn) return 1
        return new Date(v1).getTime() - new Date(v2).getTime()
      },
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'details',
      title: '',
      width: 150,
      fixed: 'right',
      render(value) {
        return <ViewDetailsButton url={value} />
      }
    },
    {
      dataIndex: 'moreOptions',
      title: '',
      width: 50,
      fixed: 'right',
      render: renderRowOptions
    }
  ]
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const autoApproveTableColumns = () => {
  return [
    {
      dataIndex: 'name',
      onCell(data) {
        return {
          onClick: data.goToDetails
        }
      },
      title: 'Name',
      width: 250,
      fixed: 'left',
      sorter: (v1, v2) => v1.name.localeCompare(v2.name)
    },
    {
      dataIndex: 'requestedFor',
      title: 'Requested for',
      width: 350,
      render: renderRequestedFor
    },
    {
      dataIndex: 'resources',
      title: 'Resources',
      width: 430,
      sorter: (v1, v2) => v2.resources.count - v1.resources.count,
      render(value) {
        return (
          <Button onClick={value.onClick} icon={faBrowser} variant='grayBlue'>
            {value.count}
          </Button>
        )
      }
    },
    {
      dataIndex: 'autoApproveUntil',
      title: 'Auto Approved Until',
      width: 320,
      sorter: (v1, v2) => dateSortComparator(v1.autoApproveUntil, v2.autoApproveUntil),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'details',
      title: '',
      width: 130,
      render(value) {
        return <ViewDetailsButton url={value} />
      }
    },
    {
      dataIndex: 'moreOptions',
      title: '',
      width: 50,
      render(value) {
        return (
          <MenuButton
            menuItems={[
              {
                action: value.onDeleteClick,
                // @ts-ignore
                title: (
                  <Typography variant='body-regular' className='!text-red-500'>
                    Delete
                  </Typography>
                )
              }
            ]}
          />
        )
      }
    }
  ]
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const approvedTableColumns = () => {
  return [
    {
      dataIndex: 'name',
      onCell(data) {
        return {
          onClick: data.goToDetails
        }
      },
      title: 'Name',
      width: 200,
      fixed: 'left',
      sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
      render(value) {
        const { Icon, name } = value
        return (
          <Typography className='flex gap-1 items-center' variant='body-regular'>
            {Icon && <Icon />}
            {name}
          </Typography>
        )
      }
    },
    {
      dataIndex: 'requestedBy',
      title: 'Requested By',
      width: 250,
      align: 'left',
      sorter: (v1, v2) => v1.requestedBy.localeCompare(v2.requestedBy),
      render: renderRequestedBy
    },
    {
      dataIndex: 'requestedFor',
      title: 'Requested for',
      width: 250,
      render: renderRequestedFor
    },
    {
      dataIndex: 'resources',
      title: 'Resources',
      width: 130,
      sorter: (v1, v2) => v2.resources.count - v1.resources.count,
      render(value) {
        return (
          <Button onClick={value.onClick} icon={faBrowser} variant='grayBlue'>
            {value.count}
          </Button>
        )
      }
    },
    {
      dataIndex: 'submittedOn',
      title: 'Submitted On',
      width: 220,
      sorter: (v1, v2) => dateSortComparator(v1.submittedOn, v2.submittedOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'approvedOn',
      title: 'Approved On',
      width: 220,
      sorter: (v1, v2) => dateSortComparator(v1.approvedOn, v2.approvedOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'expiresOn',
      title: 'Expire On',
      width: 220,
      sorter: (v1, v2) => dateSortComparator(v1.expiresOn, v2.expiresOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'details',
      title: '',
      width: 130,
      fixed: 'right',
      render(value) {
        return <ViewDetailsButton url={value} />
      }
    },
    {
      dataIndex: 'moreOptions',
      title: '',
      width: 50,
      fixed: 'right',
      render: renderRowOptions
    }
  ]
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const historyTableColumns = () => {
  return [
    {
      dataIndex: 'name',
      onCell(data) {
        return {
          onClick: data.goToDetails
        }
      },
      title: 'Name',
      width: 200,
      fixed: 'left',
      sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
      render(value) {
        const { Icon, name } = value
        return (
          <Typography className='flex gap-1 items-center' variant='body-regular'>
            {Icon && <Icon />}
            {name}
          </Typography>
        )
      }
    },
    {
      dataIndex: 'requestedBy',
      title: 'Requested By',
      width: 200,
      render: renderRequestedBy,
      sorter: (v1, v2) => v1.requestedBy.localeCompare(v2.requestedBy)
    },
    {
      dataIndex: 'requestedFor',
      title: 'Requested for',
      width: 200,
      render: renderRequestedFor
    },
    {
      dataIndex: 'resources',
      title: 'Resources',
      width: 100,
      sorter: (v1, v2) => v2.resources.count - v1.resources.count,
      render(value) {
        return (
          <Button onClick={value.onClick} icon={faBrowser} variant='grayBlue'>
            {value.count}
          </Button>
        )
      }
    },
    {
      dataIndex: 'submittedOn',
      title: 'Submitted On',
      width: 200,
      sorter: (v1, v2) => dateSortComparator(v1.submittedOn, v2.submittedOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'approvedOn',
      title: 'Approved On',
      width: 200,
      sorter: (v1, v2) => dateSortComparator(v1.approvedOn, v2.approvedOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'expiresOn',
      title: 'Expire On',
      width: 200,
      sorter: (v1, v2) => dateSortComparator(v1.expiresOn, v2.expiresOn),
      render(value) {
        if (!value) return '-'
        return moment(value).format('MMMM Do YYYY, h:mm A')
      }
    },
    {
      dataIndex: 'status',
      title: 'Status',
      width: 110,
      sorter(v1, v2) {
        return v1.status.toLowerCase().localeCompare(v2.status.toLowerCase())
      },
      render(value) {
        const { icon, variant } = getApprovalIconAndVariant(value)
        return <Label className='!w-24' iconButton={icon} variant={variant} text={value} />
      }
    },
    {
      dataIndex: 'details',
      title: '',
      width: 130,
      fixed: 'right',
      render(value) {
        return <ViewDetailsButton url={value} />
      }
    }
    // {
    //   dataIndex: 'moreOptions',
    //   title: '',
    //   width: 50,
    //   fixed: 'right',
    //   render: renderRowOptions
    // }
  ]
}

/**
 * Specific tab's visible columns on admin view
 * value is the row's `field`
 */
const adminTableColumns = {
  pending: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'expiresOn',
    'details',
    'moreOptions'
  ],
  approved: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'approvedOn',
    'expiresOn',
    'details',
    'moreOptions'
  ],
  history: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'expiresOn',
    'status',
    'details',
    'moreOptions'
  ],
  autoApprove: ['name', 'requestedFor', 'resources', 'autoApproveUntil', 'details', 'moreOptions']
}
/**
 * Specific tab's visible columns on user view
 * value is the row's `field`
 */
const userTableColumns = {
  pending: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'expiresOn',
    'details',
    'moreOptions'
  ],
  approved: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'approvedOn',
    'expiresOn',
    'details',
    'moreOptions'
  ],
  history: [
    'name',
    'requestedBy',
    'requestedFor',
    'resources',
    'submittedOn',
    'expiresOn',
    'status',
    'details',
    'moreOptions'
  ],
  autoApprove: []
}

/**
 * Map of `view` with column's
 */
export const ViewColumnsMap = {
  admin: adminTableColumns,
  user: userTableColumns
}

/**
 *
 * @returns {import("procyon-ui").DataTableProps['initialState']}
 */
export const getTableInitialState = () => ({
  sorting: {
    // sortModel: [{ dataIndex: 'submittedOn', sort: 'desc' }]
  },
  pagination: {
    paginationModel: {
      pageSize: 10
    }
  }
})
