import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { RelationAccessGraph } from 'V2Components'
import { IAMResourcesSliceNames } from 'features/iamResources'
import {
  getPolicyAttachedIAMActions,
  getPolicyResourcesRefs,
  isPolicyIssuedToEntity
} from 'features/policy'
import { createRef, createRsrcKey, getRsrcAccountRef, reverseRsrcKey } from 'features/resources'
import _ from 'lodash'
import { Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useEffect } from 'react'

const AccessGraphTab = () => {
  const workload = useDataDetailsContext()
  const { slices, getObjectRef, dispatchThunks } = useMultiSlice([
    'policyList',
    'accountList',
    'iamActions',
    ...IAMResourcesSliceNames
  ])

  const issuedPolicys = slices.policyList.filter((p) => isPolicyIssuedToEntity(p, workload))

  const allResources = () => {
    const policyToRsrcsMap = {}

    issuedPolicys.forEach((policy) => {
      const rsrcs = getPolicyResourcesRefs(policy)
      const iamActions = getPolicyAttachedIAMActions(policy)

      policyToRsrcsMap[createRsrcKey(policy)] = {
        rsrcs,
        iamActions
      }
    })

    return policyToRsrcsMap
  }

  const policyToRsrcsMap = allResources()

  const getIAMActionsNodes = () => {
    const nodes = []

    for (const policyKey in policyToRsrcsMap) {
      const iamActions = policyToRsrcsMap[policyKey].iamActions
      for (const rsrcKey in iamActions) {
        const iamActionsRefs = iamActions[rsrcKey]
        nodes.push(...iamActionsRefs.map(createRsrcKey))
      }
    }

    return getObjectRef([...new Set(nodes)].map(reverseRsrcKey))
  }

  const getResourcesNodes = () => {
    const nodes = []

    for (const policyKey in policyToRsrcsMap) {
      const rsrcs = policyToRsrcsMap[policyKey].rsrcs
      nodes.push(...rsrcs.map(createRsrcKey))
    }

    return getObjectRef([...new Set(nodes)].map(reverseRsrcKey))
  }

  const createRsrcNodeKey = (rsrc, rowIndex) => {
    // row index 4 is for resources
    if (rowIndex !== 4) return null
    return `R+${createRsrcKey(rsrc)}`
  }

  const getAccountToPolicyMap = () => {
    const map = {}

    issuedPolicys.forEach((p) => {
      const rsrcs = getPolicyResourcesRefs(p)
      if (!rsrcs.length) return
      const rsrcObj = getObjectRef(rsrcs[0])
      if (!rsrcObj) return
      let account = null
      if (rsrcObj.ObjectMeta.Kind === 'GcpResource') {
        if (rsrcObj.Spec.DisplayType === 'Project') account = rsrcObj
        else account = getObjectRef(rsrcObj.Spec.Parent)
      } else account = getObjectRef(getRsrcAccountRef(rsrcObj))
      if (!account) return
      const accountKey = createRsrcKey(account)
      const pList = map[accountKey] || []
      pList.push(createRef(p))
      map[accountKey] = pList
    })

    return map
  }

  const accountToPolicyMap = getAccountToPolicyMap()

  const getAccountNodes = () => {
    return getObjectRef(Object.keys(accountToPolicyMap).map(reverseRsrcKey))
  }

  const getGraphRelationsObject = () => {
    const map = {}

    //workload to accounts
    map[createRsrcKey(workload)] = Object.keys(accountToPolicyMap)

    //accounts to policy
    for (const accountKey in accountToPolicyMap) {
      map[accountKey] = accountToPolicyMap[accountKey].map(createRsrcKey)
    }

    // Policy to iam actions

    for (const policyKey in policyToRsrcsMap) {
      const iamActions = policyToRsrcsMap[policyKey].iamActions
      map[policyKey] = _.flatten(Object.values(iamActions)).map(createRsrcKey)
      for (const rsrcKey in iamActions) {
        const iamActionsRefs = iamActions[rsrcKey].map(createRsrcKey)
        iamActionsRefs.forEach((iamRef) => {
          const arrows = map[iamRef] || []
          //unique key
          arrows.push(`R+${rsrcKey}`)
          map[iamRef] = [...new Set(arrows)]
        })
      }
    }

    return map
  }
  useEffect(() => {
    dispatchThunks()
  }, [])

  if (!issuedPolicys.length)
    return (
      <Typography variant='body-regular'>
        Workload Identity doesn't have any resource access.
      </Typography>
    )

  return (
    <div>
      <RelationAccessGraph
        getKey={createRsrcNodeKey}
        relationObject={getGraphRelationsObject()}
        nodeObjects={[
          [workload],
          getAccountNodes(),
          issuedPolicys,
          getIAMActionsNodes(),
          getResourcesNodes()
        ]}
      />
    </div>
  )
}

export { AccessGraphTab }
