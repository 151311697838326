import { useBoot } from 'Core/Hooks/useBoot'
import Notifier from 'Utils/Notifier'
import { LayoutProvider } from 'features/layout'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import AdminRoutes from './admin'
import UserRoutes from './user'

import useAppView from 'Core/Hooks/useAppView'
import { NotFound } from 'features/layout'
import { usePaginatedReduxFetch } from 'infra/redux'

function Routes() {
  const { bootComplete } = useBoot()
  //@ts-ignore
  const user = useSelector((state) => state.user)
  const isAuthenticated = user?.loggedIn
  const { appView } = useAppView()

  const renderRoutes =
    appView === 'admin' ? (
      <Route path='/admin/'>
        <AdminRoutes />
      </Route>
    ) : (
      <Route path='/user/'>
        <UserRoutes />
      </Route>
    )

  usePaginatedReduxFetch({ sliceNames: ['awsResources', 'gcpResources', 'azureResources'] })

  return (
    // Setting Redux Provider in context
    // @ts-ignore
    <SnackbarProvider maxSnack={1}>
      {/* Rbac Feature Flags Management */}
      <Notifier />
      <Switch>
        {isAuthenticated && (
          <Route path='/*'>
            <LayoutProvider>
              {bootComplete && (
                <Switch>
                  {renderRoutes}
                  {/* Common Routes */}
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              )}
            </LayoutProvider>
          </Route>
        )}
      </Switch>
    </SnackbarProvider>
  )
}

export { Routes }
