import localforage from 'localforage'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const USER_ORG_UPDATE = 'USER_ORG_UPDATE'

export const loginUser = (payload) => {

  //Code responsible for clearing the local forage db cache when logged in user has changed
  try {
    const localUser = JSON.parse(localStorage.getItem('user'))
    if (payload.ObjectMeta.ID !== localUser.ObjectMeta.ID) {
      localforage.setItem('persist:root', '').then(() => window.location.reload())
    }
  } catch (error) {}

  return {
    type: LOGIN_USER,
    payload
  }
}

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  }
}

export const updateUserOrg = (org) => {
  return {
    type: USER_ORG_UPDATE,
    payload: {
      activeOrg: org
    }
  }
}
