import { useProxyControllerWizardContext } from 'features/proxyControllers'
import { getResourceName, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { Label, Typography } from 'procyon-ui'
import React from 'react'
import { LabelContent } from 'V2Components'

const SummaryStep = () => {
  const {
    selectedNodeIDs,
    controllerName,
    selectorType,
    label,
    nodeTypes,
    targetProxyImages,
    rdpEnabled
  } = useProxyControllerWizardContext()

  const { getObjectRef } = useObjectRef(['medusaNodes'])

  return (
    <div>
      <LabelContent
        fullWidth
        title='Controller Name'
        content={<Typography variant='body-regular'>{controllerName}</Typography>}
      />
      {selectorType === 'Nodes' && (
        <LabelContent
          title='Nodes'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              {selectedNodeIDs.map((node) => {
                return <Label text={node} />
              })}
            </div>
          }
        />
      )}
      {selectorType === 'NodeTypes' && (
        <LabelContent
          title='Node Types'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              {nodeTypes.map((n) => (
                <Label key={n} variant='grayBlue' text={n} />
              ))}
            </div>
          }
        />
      )}
      {selectorType === 'Labels' && (
        <LabelContent
          title='Labels'
          fullWidth
          content={
            <div className='flex gap-1 flex-wrap'>
              <Typography variant='body-regular'>{label}</Typography>
            </div>
          }
        />
      )}
      <LabelContent
        title='Procyon Proxy Image'
        fullWidth
        content={<Label variant='grayBlue' text={targetProxyImages['procyon-proxy']} />}
      />
      {rdpEnabled && (
        <>
          <LabelContent
            titleBreakAll={false}
            title='Guacamole Client Image'
            fullWidth
            content={<Label variant='grayBlue' text={targetProxyImages['guacamole-client']} />}
          />
          <LabelContent
            titleBreakAll={false}
            title='Guacamole Server Image'
            fullWidth
            content={<Label variant='grayBlue' text={targetProxyImages['guacamole-server']} />}
          />
        </>
      )}
    </div>
  )
}

export { SummaryStep }
