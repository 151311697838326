import { ApiProvider } from 'Core'
import { getUserInfo } from 'Utils/Helpers'
import { getDisplayName } from 'Utils/PureHelperFuctions'

export const createPacPolicy = async (data) => {
  const user = getUserInfo()
  const policyApi = new ApiProvider('pacpolicys')
  // Populate creator
  data.Creator = getDisplayName(user)
  const response = await policyApi.setInstance(data).post()
  return response.data
}

export const deletePolicy = async (data) => {
  const policyApi = new ApiProvider('pacpolicys')
  await policyApi.setInstance(data).delete()
  return data
}
