import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useRouteParams } from 'Core/Hooks/useRouteParams'
import { CardsFilterGridView } from 'V2Components'
import { Button, RolesCard } from 'procyon-ui'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { IAMRoleSummaryModal } from './components/IAMRoleSummaryModal'
import { useIamRoles } from './useIamRoles.utils'

const IAMRoles = () => {
  const [selectedIAMRole, setSelectedIAMRole] = useState(null)
  const [iamRoleModalIndex, setIamRoleModalIndex] = useState(0)
  const [showIAMRoleModal, setShowIAMRoleModal] = useState(false)
  const [activeFilters, setActiveFilters] = useState(['Default', 'Custom'])
  const [searchKey, setSearchKey] = useState('')
  const [activeCloudType, setActiveCloudType] = useState('AWS')

  const history = useHistory()
  const { appView } = useAppView()
  const { getCardData, iamActions } = useIamRoles({
    activeCloudType,
    searchKey,
    activeFilters
  })

  const renderCardFn = useCallback((iamRole) => {
    const {
      description,
      custom,
      name,
      key,
      actionsCount,
      iconType,
      policysCount,
      rsrcsCount,
      moreInfoRoute,
      role
    } = getCardData(iamRole)
    return (
      <RolesCard
        key={key}
        title={name}
        description={description}
        actionsCount={actionsCount}
        policiesCount={policysCount}
        resourcesCount={rsrcsCount}
        iconType={iconType}
        labelText={custom ? 'Custom' : 'Pre-Defined'}
        labelVariant={custom ? 'grayBlue' : 'gray'}
        onClickMoreInfoButton={() => {
          history.push(moreInfoRoute)
        }}
        onClickResourcesButton={() => {
          setSelectedIAMRole(role)
          setIamRoleModalIndex(0)
          setShowIAMRoleModal(true)
        }}
        onClickPoliciesButton={() => {
          setSelectedIAMRole(role)
          setIamRoleModalIndex(2)
          setShowIAMRoleModal(true)
        }}
        onClickActionsButton={() => {
          setSelectedIAMRole(role)
          setIamRoleModalIndex(1)
          setShowIAMRoleModal(true)
        }}
        showLabel
      />
    )
  }, [getCardData])

  useRouteParams({
    key: 'cloudType',
    defaultValue: 'aws',
    controlValue: activeCloudType.toLowerCase(),
    possibleValues: ['aws', 'gcp', 'azure'],
    onControlValueChange(value) {
      setActiveCloudType(value.toUpperCase())
      history.push(`/${appView}/iam-roles/${value}`)
    }
  })

  return (
    <div>
      <CardsFilterGridView
        filters={[
          {
            label: 'Role Type',
            filters: [
              {
                label: 'Default',
                value: 'Default'
              },
              {
                label: 'Custom',
                value: 'Custom'
              }
            ]
          }
        ]}
        activeCloudType={activeCloudType}
        onCloudTypeChange={setActiveCloudType}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        ActionButtons={
          appView === 'admin' && (
            <Button
              onClick={() => {
                history.push(`/${appView}/iam-roles/${activeCloudType.toLowerCase()}/create`)
              }}
              size='md'
              variant='primary'
              icon={faPlus}
            >
              Custom Role
            </Button>
          )
        }
        data={iamActions}
        renderCardFn={renderCardFn}
      />
      {showIAMRoleModal && selectedIAMRole && (
        <IAMRoleSummaryModal
          tabIndex={iamRoleModalIndex}
          iamrole={selectedIAMRole}
          onClose={() => {
            setShowIAMRoleModal(false)
            setSelectedIAMRole(null)
          }}
        />
      )}
    </div>
  )
}

export { IAMRoles }
