import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { PropTypes } from 'Core'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  body: {
    height: props => props.height || '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.1px'
  }
}))
const Body = ({ color = 'initial', className = '', children, height = '16px', ...rest }) => {
  const internalClasses = useStyle({ height })
  return (
    <Typography color={color} className={classNames(internalClasses.body, className)} {...rest}>{children}</Typography>
  )
}
Body.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'textPrimary', 'textSecondary', 'error', 'initial', 'inherit'])
}
export default Body
