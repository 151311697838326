import { Box, Grid, makeStyles } from '@material-ui/core'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import StyledBox from 'Components/StyledBox'
import React, { useEffect } from 'react'
import ActiveUserContextProvider from './ActiveUserContext/Provider'
import MapsSegment from './MapsSegment'
import UsersSegment from './UserSegment'
const useStyle = makeStyles(theme => ({
  root: {
    padding: 0
  }
}))
const UsersAndMaps = ({ userCount }) => {
  useEffect(() => {
    console.log('RenderTracker ran useffect of User and Map Segmets')
  }, [])
  const classes = useStyle()

  return (
    <Box>
      <CustomTypo variant='h4'>Users & Devices</CustomTypo>
      <StyledBox mt={1} mb={1.5} globalClassName='applicationCard' className={classes.root} height='100%' px={0} pt={0}>
        <ActiveUserContextProvider>
          <Grid spacing={0} container>
            <Grid xs={6} item>
              <UsersSegment userCount={userCount} />
            </Grid>
            <Grid xs={6} item>
              <MapsSegment />
            </Grid>
          </Grid>
        </ActiveUserContextProvider>
      </StyledBox>
    </Box>
  )
}

export default React.memo(UsersAndMaps)
