const { REQUEST_STATUS } = require('./ApprovalRequest')

const CONSTANTS = {
  approvalRequest: REQUEST_STATUS,
  /** Groups which are too important and special to be changed or harmed */
  sacredGroups: ['admin', 'everyone'],
  WHITELISTED_CLOUD_SERVICES: [
    'Amazon EC2',
    'Amazon Elastic Container Registry',
    'Amazon Elastic Kubernetes Service',
    'Amazon Elastic Container Service',
    'AWS Lambda',
    'Amazon S3',
    'Amazon RDS',
    'AWS Key Management Service',
    'AWS Secrets Manager',
    'AWS CloudFormation',
    'Amazon CloudFront',
    'Amazon CloudWatch',
    'Amazon Redshift',
    'Amazon Lightsail',
    'AWS IoT',
    'Amazon SNS',
    'Amazon DynamoDB',
    'AWS Glue',
    'Amazon Cognito User Pools',
    'Amazon Cognito Identity',
    'Amazon Athena',
    'Amazon GuardDuty',
    'AWS Amplify',
    'Amazon Chime',
    'Amazon API Gateway',
    'Amazon Route 53',
    'Amazon Kinesis',
    'Amazon QuickSight'
  ],
  policyTypes: {
    IAMAction: 'IAMAction',
    Target: 'Target',
    App: 'App'
  },
  ActionTypes: {
    GCPIAM: 'GcpIAM',
    AWSIAM: 'AwsIAM',
    AZUREIAM: 'AzureIAM'
  },
  IAMCloudTypes: {
    AWS: 'AWS',
    GCP: 'GCP',
    AZURE: 'AZURE'
  }
}
export const SupportedActivityLogsResourceType = ['AppRole', 'Server']
export default CONSTANTS

export const KindToEndpointMap = {
  Account: 'accounts',
  AppRole: 'approles',
  Server: 'servers',
  Database: 'databases',
  AwsResource: 'awsresources',
  GcpResource: 'gcpresources',
  AzureResource: 'azureresources',
  KubeNamespace: 'kubenamespaces',
  Application: 'applications',
  RDPServer: 'rdpservers',
  Kafka: 'kafkas',
  WorkloadIdentity: 'workloadidentitys',
  User: 'users',
  Group: 'groups',
  KubeCluster: 'kubeclusters'
}
