import { axios } from 'Core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import lodash from 'lodash'

const DefautOptions = {
  maxStatusHistory: 3,
  method: 'GET',
  initialFill: 'FAILURE',
  headers: {
    Authorization: ''
  }
}

export const POLLING_STATUS = { SUCCESS: 'SUCCESS', FAILURE: 'FAILURE', INITIAL: 'INITIAL' }

const useNetworkPolling = (url = '', duration = 1500, options = DefautOptions) => {
  const pollId = useRef(null)
  const requestStatus = useRef([])
  const [start, setStart] = useState(false)
  const [isReachable, setIsReachable] = useState(true)

  const toUseOptions = useMemo(() => {
    return { ...DefautOptions, ...options }
  }, [options, url, pollId])

  const isNetworkReachable = () => {
    return (
      requestStatus.current.filter((status) => status === POLLING_STATUS.FAILURE).length !==
      toUseOptions.maxStatusHistory
    )
  }

  const setRequestStatus = (fn) => {
    requestStatus.current = fn && fn(requestStatus.current)
    setIsReachable(isNetworkReachable())
  }

  const updateStatus = (status) => {
    setRequestStatus((state) => {
      if (state.length === toUseOptions.maxStatusHistory) {
        const newArr = [].concat(state)
        newArr.shift()
        newArr.push(status)
        if (lodash.isEqual(newArr, state)) {
          return state
        }
        return newArr
      } else {
        const newArr = [].concat(state)
        newArr.push(status)
        return newArr
      }
    })
  }

  const stopPolling = useCallback(() => {
    clearTimeout(pollId.current)
  }, [pollId.current])

  const startPolling = useCallback(() => {
    setStart(true)
  }, [])

  const sendRequest = async (cb) => {
    try {
      // @ts-ignore
      await axios({ url, headers: toUseOptions.headers })
      updateStatus(POLLING_STATUS.SUCCESS)
    } catch (error) {
      updateStatus(POLLING_STATUS.FAILURE)
    }
    cb && cb()
  }

  const beginPolling = () => {
    if (pollId.current) {
      stopPolling()
    }
    const id = setTimeout(() => {
      sendRequest(beginPolling)
    }, duration)
    pollId.current = id
  }

  useEffect(() => {
    if (!start) return
    beginPolling()
    return () => stopPolling()
  }, [start])

  const _ReturnObject = useMemo(() => {
    return { stopPolling, isReachable, startPolling }
  }, [stopPolling, isReachable, startPolling])

  return _ReturnObject
}

export default useNetworkPolling
