import { Grid } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'

export const options = (extraOptions = {}) => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: false,
  rowsPerPageOptions: [5, 10, 15, 100],
  ...extraOptions
})

export const columns = ({ classes }) => [
  {
    name: 'InfoIcon',
    label: 'Info',
    options: {
      filter: false,
      sort: true,
      setCellProps: () => ({ style: { paddingLeft: '0.375rem', paddingRight: '0px' } }),
      customBodyRender: (InfoIcon) => <InfoIcon fontSize='small' />
    }
  },
  {
    name: 'MainIcon',
    label: 'Main',
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { paddingLeft: '0.563rem', paddingRight: '0px' } }),
      customBodyRender: (MainIcon) => <MainIcon className={classes.mainIcon} fontSize='small' />
    }
  },
  {
    name: 'Text',
    label: 'Text',
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { paddingLeft: '1rem', paddingRight: '0px' } }),
      customBodyRender: (Text) => (
        <Grid justifyContent='space-between' container>
          <Grid xs={10} item>
            <CustomTypo variant='body2'>{Text}</CustomTypo>
          </Grid>
          <Grid item>
            <ChevronRight fontSize='small' className={classes.chevronRight} />
          </Grid>
        </Grid>
      )
    }
  }
]
