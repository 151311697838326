import { SummaryHeader } from 'features/resources'
import { Button } from 'procyon-ui'
import React from 'react'

function Header({ onCancel, onComplete, disableAction = false }) {

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 py-4 px-4 pt-4'>
      <SummaryHeader
        rsrcName='Add New User(s)'
        actions={
          <div className='flex gap-4'>
            <Button onClick={onCancel} size='md' variant='grayBlue'>
              Cancel
            </Button>
            <Button disabled={disableAction} onClick={onComplete} size='md' variant='primary'>
              Complete
            </Button>
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Resource Catalog',
            onClick: onCancel
          },
          {
            label: 'Add User(s)',
            link: null
          }
        ]}
      />
    </div>
  )
}

export { Header }

