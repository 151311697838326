import React, { useMemo } from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import superSearch from '@codewell/super-search'

const useStyle = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}))
const DataTiles = ({ data, searchKey, onClick, TileComponent, hideStatus }) => {
  const searchedData = useMemo(() => {
    return data.filter(item => superSearch(searchKey.toLowerCase(), { ...item }).numberOfMatches > 0)
  }, [data, searchKey])
  const classes = useStyle()
  return (
    <Grid container direction='row' className={classes.root} spacing={1}>
      {searchedData && searchedData.map((item, i) => <TileComponent hideStatus={hideStatus} onClick={onClick} item={item} key={item.ID} />)}
    </Grid>
  )
}

export default React.memo(DataTiles)
