import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
  progressBackground: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    color: theme.palette.grey[200]
  },
  progress: {
    color: props => props.color
  }
}))
const ProgressIndicator = ({ texts, count, Icon, currentCount, color, size = '6rem', thickness = 5, InfoComponent, iconSize }) => {
  const classes = useStyle({ color })
  const percentage = (currentCount / count) * 100
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress size={size} thickness={thickness} className={classes.progressBackground} variant='determinate' value={100} />
      <CircularProgress className={classes.progress} size={size} thickness={thickness} variant='determinate' value={percentage} />
      {InfoComponent && <InfoComponent iconSize={iconSize} Icon={Icon} texts={texts} color={color} />}
    </Box>
  )
}

export default ProgressIndicator
