import { useCloudTypes } from 'features/clouds'
import { ToggleCloudControl } from 'procyon-ui'
import React from 'react'
import { CheckboxFilters, PaginationView, SearchInput } from '../'

const CardsFilterGridView = ({
  filters,
  activeCloudType,
  onCloudTypeChange,
  searchKey,
  setSearchKey,
  activeFilters,
  setActiveFilters,
  ActionButtons,
  data,
  renderCardFn
}) => {
  const { cloudTypes } = useCloudTypes()

  return (
    <div className='flex'>
      <div className='w-[336px] bg-[#F9FBFC] pl-8 pt-7 -ml-4 -mt-4 min-h-[800px]'>
        <div className='-ml-8 -mt-6'>
          <CheckboxFilters
            title='IAM Roles'
            filterItems={filters}
            activeFilters={activeFilters}
            onActiveFilterChange={setActiveFilters}
          />
        </div>
      </div>
      <div className='w-[100%] -mt-4 pt-7 pl-8'>
        <div className='flex justify-between items-center'>
          <ToggleCloudControl
            ariaLabel='cloud-select'
            onChange={(_e, v) => onCloudTypeChange?.(v)}
            options={cloudTypes}
            value={activeCloudType}
          />
          <div className='flex gap-4 items-center'>
            <SearchInput searchKey={searchKey} onChange={setSearchKey} />
            {ActionButtons}
          </div>
        </div>
        <div className='my-6'>
          <PaginationView
            data={data}
            renderFunction={renderCardFn}
            WrapperComponent={({ children }) => (
              <div className='grid grid-cols-1 md:grid-cols-2 xxl:grid-cols-3 gap-8 my-4'>
                {children}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export { CardsFilterGridView }
