import { SearchInput } from 'V2Components'
import moment from 'moment'
import { SelectDateTime, SelectDropDown, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

/**
 *
 * @typedef {{
 * 	filters:any
 * }} CustomFilterProps
 *
 * @param {{
 * 	CustomFilters?:any
 * 	hideSearch?:boolean
 * 	initialStartDate?: any
 * 	initialEndDate?: any
 * onFilterChange:((props:{ startDate:any, endDate:any, searchKey:string }) => void)
 * }} param0
 * @returns
 */
const DateTimeComposedFilter = ({
  CustomFilters,
  hideSearch,
  onFilterChange,
  initialEndDate,
  initialStartDate
}) => {
  const [startDateTime, setStartDateTime] = useState(
    initialStartDate || moment().subtract(7, 'days')
  )
  const [endDateTime, setEndDateTime] = useState(initialEndDate || moment())

  const [searchKey, setSearchKey] = useState('')

  useEffect(() => {
    onFilterChange?.({ startDate: startDateTime, endDate: endDateTime, searchKey })
  }, [searchKey, startDateTime, endDateTime])

  return (
    <div className='mt-3 rounded border p-4 border-[#D8DDE4] flex items-center justify-between'>
      <div className='flex items-center gap-4'>
        <Typography variant='body-regular'>From</Typography>
        <SelectDateTime
          value={startDateTime.toDate()}
          onChange={(v) => setStartDateTime(moment(v.toISOString()))}
        />
        <Typography variant='body-regular'>To</Typography>
        <SelectDateTime
          value={endDateTime.toDate()}
          onChange={(v) => setEndDateTime(moment(v.toISOString()))}
        />
        {CustomFilters && CustomFilters}
      </div>
      {!hideSearch && <SearchInput searchKey={searchKey} onChange={setSearchKey} />}
    </div>
  )
}

export { DateTimeComposedFilter }
