import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Modal
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'
const getHeaderPadding = (headerPadding, theme) => {
  if (headerPadding && Object.keys(headerPadding).length > 0) {
    return theme.spacing(
      headerPadding.pt || 1.25,
      headerPadding.pr || 1.25,
      headerPadding.pb || 0,
      headerPadding.pl || 1.25
    )
  } else return theme.spacing(1.25, 1.25, 0, 1.25)
}
const useStyle = makeStyles(theme => ({
  modalWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardHeader: {
    // @ts-ignore
    padding: props => getHeaderPadding(props.headerPadding, theme),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    // @ts-ignore
    borderBottom: props => props.headerBorder && '2px solid #e9e9e9',
    // @ts-ignore
    backgroundColor: (props) => props.background === 'white' ? theme.palette.background.paper : theme.palette.background.default
    // backgroundColor: theme.palette.background.paper
  },
  Close: {
    justifySelf: 'flex-end',
    marginLeft: 'auto',
    marginRight: '10px'
  },
  title: {
    fontSize: theme.typography.h6.fontSize
  },
  cardContent: {
    // minHeight: '200px',
    overflowY: 'auto',
    maxHeight: props => props.noMaxHeight ? 'unset' : 'calc( 100% - 60px )',
    padding: props => props.bodyPadding ? props.bodyPadding + '!important' : '0px!important'
  },
  close: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontSize: props => props.closeLabel && '14px',
    marginTop: props => props.closeLabel && theme.spacing(0.125)
  },
  closeIcon: {

    fontSize: props => props.closeLabel && theme.spacing(1.25),
    marginRight: props => props.closeLabel && theme.spacing(0.275),
    width: props => props.closeLabel && theme.spacing(1.25)
  }
}))
const ActionModal = ({
  children,
  Title,
  width = '40.5rem',
  height = '32.5rem',
  isOpen,
  handleClose,
  Icon,
  background,
  paddingBottom,
  titleBoxProps = {},
  showModalHeader = true,
  showCloseButton = false,
  noMaxHeight = false,
  headerBorder = false,
  headerPadding = { pt: 1.25, pr: 1.25, pb: 0, pl: 1.25 },
  bodyPadding = false,
  closeLabel = '',
  customRender = null
}) => {
  const classes = useStyle({ bodyPadding, closeLabel, headerBorder, background, paddingBottom, noMaxHeight, headerPadding })
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      BackdropComponent={Backdrop}
      onBackdropClick={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.modalWrapper}>
        <Card style={{ height, width }}>
          {showModalHeader && (

            <div className={classes.cardHeader}>
              {Icon && <Icon />}
              <Box className={classes.title} {...titleBoxProps}>{Title}</Box>
              <Box ml='auto' display='flex' alignItems='center'>
                {customRender}
                {
                  showCloseButton && <Button className={classes.close} onClick={handleClose}><Close className={classes.closeIcon} />{closeLabel && <CustomTypo>{closeLabel}</CustomTypo>}</Button>
                }
              </Box>
            </div>
          )}
          <CardContent className={classes.cardContent}>{children || <></>}</CardContent>
        </Card>
      </div>
    </Modal>
  )
}

export default ActionModal
