import { faBuilding, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Button, ElementRow, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { AddOrganizationModal } from './components/AddOrganizationModal'
import { DeleteOrgAlertModal } from './components/DeleteOrgAlertModal'
import { useMultiOrg } from 'Core/Hooks/useMultiOrg'

function Organizations() {
  const [showAddOrgModal, setShowAddOrgModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [selectedOrg, setSelectedOrg] = useState(null)

  const { activeOrg } = useMultiOrg()

  const { slices } = useMultiSlice(['orgs'])

  const handleDeleteClick = (org) => {
    setSelectedOrg(org)
    setShowDeleteModal(true)
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography className='mb-8' variant='h4-regular'>
          Organizations
        </Typography>
        <Button onClick={() => setShowAddOrgModal(true)} variant='gray'>
          Add New
        </Button>
      </div>
      <div className='max-h-[500px] overflow-auto'>
        {slices.orgs.map((e) => {
          const name = getResourceName(e)
          const isDefault = name === 'default'
          const isActive = name === activeOrg
          return (
            <ElementRow
              key={createRsrcKey(e)}
              showButton={!isDefault}
              buttonIcon={faTrash}
              icon={<FontAwesomeIcon color='#A6ABB6' icon={faBuilding} size='lg' />}
              onClickButton={() => handleDeleteClick(e)}
              title={
                <span>
                  {isDefault && <strong className='text-blue-500'>default</strong>}
                  {!isDefault && (isActive ? <strong>{name}</strong> : name)}
                </span>
              }
            />
          )
        })}
      </div>
      {showAddOrgModal && (
        <AddOrganizationModal
          onCancel={() => setShowAddOrgModal(false)}
          onSuccess={() => setShowAddOrgModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteOrgAlertModal
          org={selectedOrg}
          onCancel={() => setShowDeleteModal(false)}
          onSuccess={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  )
}

export { Organizations }
