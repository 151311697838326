import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const AzureIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 32 32' {...props}>
      <g fill='none' fill-rule='evenodd'>
        <g fill='#0089D6'>
          <g>
            <g>
              <g>
                <path
                  d='M12.123 10.804L18.585 18.627 6.277 20.8 26.4 20.8 15.446 1.428zM14.461 0L6.708 6.706 0 18.503 5.969 18.503 14.461 0z'
                  transform='translate(-809.000000, -262.000000) translate(294.000000, 181.000000) translate(515.000000, 81.000000) translate(3.200000, 3.200000)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
})

export default AzureIcon
