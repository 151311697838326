import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { SearchInput } from 'V2Components'
import { AccountsSortOptions, useCloudDetailsProvider, useGCPCloud } from 'features/clouds'
import { getResourceName, reverseRsrcKey } from 'features/resources'
import { CloudCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function ProjectsAndFoldersTab({}) {
  const [searchKey, setSearchKey] = useState('')
  const [activeOption, setActiveOption] = useState('all')
  const history = useHistory()
  const { cloud: account } = useCloudDetailsProvider()

  const { getGCPAccountResources, getGCPResourceChildren } = useGCPCloud()
  const { getObjectRef } = useMultiSlice(['gcpResources', 'accountList'])

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const resources = getObjectRef(getGCPAccountResources(account).map(reverseRsrcKey))
  const searchApplied = applySearchQuery(resources)
  const filtered = (() => {
    const list =
      activeOption === 'all'
        ? searchApplied
        : searchApplied.filter((e) => activeOption === e.Spec.DisplayType)
    return list
  })()

  const getOptions = () => {
    const options = []
    const proj = searchApplied.find((e) => e.Spec.DisplayType === 'Project')
    const folder = searchApplied.find((e) => e.Spec.DisplayType === 'Folder')
    if (proj) options.push('Project')
    if (folder) options.push('Folder')
    return options
  }

  const handleMoreInfoClick = (rsrc) => {
    history.push(`/admin/resources/gcpresource/${encodeURIComponent(rsrc.ObjectMeta.Name)}`, {
      from: [
        {
          name: 'Clouds',
          url: `/admin/clouds/gcp`
        },
        {
          name: getResourceName(account),
          url: `/admin/clouds/gcp/${getResourceName(account)}/child-accounts`
        },
        {
          name: getResourceName(rsrc),
          url: ''
        }
      ]
    })
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='body-regular'>{filtered.length} Resources</Typography>
        <div className='flex gap-4'>
          <SearchInput searchKey={searchKey} onChange={setSearchKey} />
          <AccountsSortOptions
            options={getOptions()}
            activeOption={activeOption}
            onActiveOptionChange={setActiveOption}
          />
        </div>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {filtered.map((resource) => {
          const acc = getObjectRef(resource.Spec.Account)
          const parentRsrc = getObjectRef(resource.Spec.Parent)
          const childRsrcs = getGCPResourceChildren(resource)
          return (
            <CloudCard
              width='100%'
              // @ts-ignore
              caption={`${acc && getResourceName(acc)}${
                parentRsrc ? ' / ' + getResourceName(parentRsrc) : ''
              }`}
              icon={
                <TargetIcon
                  height='100%'
                  type={DisplayTypeToIconTypeMap[resource.Spec.DisplayType]}
                  width='100%'
                />
              }
              menuItems={[]}
              name={getResourceName(resource)}
              onClickMoreInfo={() => handleMoreInfoClick(resource)}
              resourcesAmount={childRsrcs.length}
              onClickResources={function noRefCheck() {}}
            />
          )
        })}
      </div>
    </div>
  )
}

const DisplayTypeToIconTypeMap = {
  Project: 'GCP_GOOGLE_PROJECT',
  Folder: 'GCP_GOOGLE_FOLDER'
}

export { ProjectsAndFoldersTab }
