import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons'
import { ApiProvider } from 'Core'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { useUser } from 'Core/Hooks/useUser'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenModal } from 'V2Components'
import { useAppDetailsContext } from 'features/applications'
import { AccessRequestSubmission, createRef, useAccessCartProvider } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { SnackBar } from 'procyon-ui'
import React, { useState } from 'react'
import _ from 'lodash'
/**
 * @typedef { { showEntitySlectionModal:boolean, accessRequestSubmissionModal: boolean } } ModalViews
 *
 * @param {{
 * views: ModalViews,
 * setViews: ((views:ModalViews) => void),
 * }} param0
 * @returns
 */
const useSlices = createDataSelectorHook(['salesForceUsersList'])

function CreateApplicationRequestFlow({ views, setViews }) {
  const { app } = useAppDetailsContext()
  const { user } = useUser()
  const { cartItems, clearCartItems } = useAccessCartProvider()
  const [requestName, setRequestName] = useState('')
  const [comments, setComments] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dateTimeError, setDateTimeError] = useState('')
  const { slices } = useSlices()
  const handleDismissClick = () => {
    clearCartItems()
    setRequestName('')
    setComments('')
    setStartDate(null)
    setEndDate(null)
    setDateTimeError('')
  }

  const handleViewsChange = (v) => {
    setViews({ ...views, ...v })
  }

  const handleRequestSubmit = async (data) => {
    try {
      const request = await handleAccessRequestCreate(data)
      pushToSlice(request)
      handleViewsChange({ accessRequestSubmissionModal: false })
      clearCartItems()
      enqueueNotification('Access Request created successfully!', 'info')
    } catch (error) {
      enqueueNotification('Unable to create access request!', 'error')
    }
  }

  const onSubmissionCancel = () => {
    handleViewsChange({ accessRequestSubmissionModal: false })
  }

  return (
    <div>
      {views.accessRequestSubmissionModal && (
        <FullScreenModal showModal>
          <AccessRequestSubmission
            onAddMoreResourcesClick={() =>
              handleViewsChange({ accessRequestSubmissionModal: false })
            }
            loadingMessage={`Creating Application's Access Request`}
            requestForUserGroupsRefs={[createRef(user)]}
            showRequestForUserGroupsSummary={false}
            mainTitle='Access Request'
            onSubmit={handleRequestSubmit}
            onCancel={onSubmissionCancel}
            requestName={requestName}
            setRequestName={setRequestName}
            comments={comments}
            setComments={setComments}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateTimeError={dateTimeError}
            setDateTimeError={setDateTimeError}
          />
        </FullScreenModal>
      )}

      {cartItems?.length > 0 && (
        <div className='fixed bottom-4 right-9'>
          <SnackBar
            showDropDownButton
            onClickContinueButton={() => {
              handleViewsChange({ accessRequestSubmissionModal: true })
            }}
            leftIcon={faRightToBracket}
            menuItems={[
              {
                label: 'Dismiss',
                onClick: handleDismissClick,
                value: 'first-item'
              }
            ]}
            textMessage={`${cartItems?.length} Applications on Queue`}
            variant='primary'
          />
        </div>
      )}
    </div>
  )
}

async function handleAccessRequestCreate({
  requestForUserGroupsRefs,
  selectedRsrcs,
  requestName,
  comments,
  startDate,
  endDate
}) {
  const data = {
    ObjectMeta: {
      Name: getFriendlyName()
    },
    Spec: {
      NotBefore: startDate,
      NotAfter: endDate,
      RequestedFor: {
        ObjectRef: requestForUserGroupsRefs
      },
      Justification: comments,
      Resources: {
        Resource: []
      }
    },
    GivenName: requestName,
    Status: 'New',
    Type: 'Snowflake'
  }

  selectedRsrcs.forEach(({ Resource }) => {
    data.Spec.Resources.Resource.push({
      Target: Resource,
      Action: 'Snowflake',
      Principal: '',
      Services: {
        ObjectRef: []
      }
    })
  })

  const response = await new ApiProvider('approvalreqs').setInstance(data).post()
  return response.data
}

export { CreateApplicationRequestFlow }
