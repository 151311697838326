import { useRef, useState } from 'react'
import { deleteApiKeys, getAPIKeys, createApiKeys } from '../api'
import { useUser } from 'Core/Hooks/useUser'
import moment from 'moment'

const API_KEY_STATUS = {
  REGEN: 'REGEN',
  IDLE: 'IDLE',
  LOADING: 'LOADING'
}

/**
 *
 * @returns {{
 * apiKeys,
 * status:'REGEN' | 'IDLE'| 'LOADING'
 * regenerateApiSecret
 * tenantApiKeyObj
 * updateApiKeysObj
 * isApiKeyExpired: boolean
 * }}
 */
export const useAPIKeys = () => {
  const [tenantApiKeyObj, setTenantApiKeyObj] = useState(null)
  const [apiKeys, setApiKeys] = useState({
    ApiKey: '',
    ApiSecret: '',
    CntrlEP: '',
    NotAfter: ''
  })
  const [status, setStatus] = useState(API_KEY_STATUS.IDLE) //API_KEY_STATUS
  const { user } = useUser()
  const Tenant = user?.ObjectMeta.Tenant || ''
  const isFetched = useRef(false)

  const isApiKeyExpired = apiKeys.NotAfter && moment().diff(apiKeys.NotAfter) > 0

  const loadApiKeys = async () => {
    try {
      setStatus(API_KEY_STATUS.LOADING)
      const data = await getAPIKeys(Tenant)
      const { ApiKey, ApiSecret, CntrlEP, NotAfter } = data.Spec
      setTenantApiKeyObj(data)
      setApiKeys({ ApiKey, ApiSecret, CntrlEP, NotAfter })
      setStatus(API_KEY_STATUS.IDLE)
    } catch (error) {}
  }

  const regenerateApiSecret = async () => {
    try {
      setStatus(API_KEY_STATUS.REGEN)
      //1 Delete the api key first
      await deleteApiKeys(Tenant)
      // Create another api key
      const data = await createApiKeys(Tenant)
      const { ApiKey, ApiSecret, CntrlEP, NotAfter } = data.Spec
      setApiKeys({ ApiKey, ApiSecret, CntrlEP, NotAfter })
      setTenantApiKeyObj(data)
    } catch (error) {
      if (error.message.includes('500')) {
        const data = await createApiKeys(Tenant)
        const { ApiKey, ApiSecret, CntrlEP, NotAfter } = data.Spec
        setApiKeys({ ApiKey, ApiSecret, CntrlEP, NotAfter })
        setTenantApiKeyObj(data)
      }
    } finally {
      setStatus(API_KEY_STATUS.IDLE)
    }
  }

  if (!isFetched.current) {
    isFetched.current = true
    loadApiKeys()
  }

  const updateApiKeysObj = (data) => {
    const { ApiKey, ApiSecret, CntrlEP, NotAfter } = data.Spec
    setApiKeys({ ApiKey, ApiSecret, CntrlEP, NotAfter })
    setTenantApiKeyObj(data)
  }

  return {
    apiKeys,
    //@ts-ignore
    status,
    regenerateApiSecret,
    tenantApiKeyObj,
    updateApiKeysObj,
    isApiKeyExpired
  }
}
