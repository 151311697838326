import { faDownload } from '@fortawesome/pro-duotone-svg-icons'
import { parseLogSessionData } from 'features/sessions/utils'
import { Button } from 'procyon-ui'
import React from 'react'
import ReactPlayer from 'react-player/file'

const RDPRecordingTab = ({ log }) => {
  const sessionData = parseLogSessionData(log)

  if (!sessionData) return null
  const firstData = sessionData[0]
  const vidURL = firstData?.DataURL || ''

  return (
    <div className='relative'>
      {/* @ts-ignore */}
      <ReactPlayer
        style={{ backgroundColor: 'black', borderRadius: '4px', padding: '4px' }}
        controls
        height='100%'
        width='100%'
        url={vidURL}
      />

      <a className='inline-block' target='_blank' href={firstData?.DataURL || ''}>
        <Button className='mt-6' variant='grayBlue' icon={faDownload}>
          Download Session Recording
        </Button>
      </a>
    </div>
  )
}

export { RDPRecordingTab }
