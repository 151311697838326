import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { useMemo } from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'linear-gradient(white, #f2f2f2) !important',
    border: '1px solid #cfcfcf',
    fontWeight: 500,
    '&:hover': {
      background: 'linear-gradient(#f2f2f2, #ffffff) !important'
    },
    textTransform: 'capitalize'
  },
  primary: {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.main}) !important`,
    color: 'white',
    border: '1px solid ' + theme.palette.primary.main,
    '&:hover': {
      backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.main}) !important`
    }
  },
  error: {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.error.main}, ${theme.palette.error.main}) !important`,
    color: 'white',
    border: '1px solid ' + theme.palette.error.main,
    '&:hover': {
      backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.error.main}, ${theme.palette.error.main}) !important`
    }
  },
  success: {
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.success.main}, ${theme.palette.success.main}) !important`,
    color: 'white',
    border: '1px solid ' + theme.palette.success.main,
    '&:hover': {
      backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.18) 39%, rgba(255, 255, 255, 0) 68%), linear-gradient(to bottom, ${theme.palette.success.main}, ${theme.palette.success.main}) !important`
    }
  },
  spinner: {
    color: 'inherit'
  }
}))

/**
 * @typedef {'primary' | 'secondary' | 'error' | 'success'} theme
 * @typedef {import('@material-ui/core').ButtonProps & { theme: theme, loading?: boolean }} props
 * @param {props} props
 * @returns {React.ReactElement}
 */
function ThemeButton (props) {
  const classes = useStyles()
  const theme = useMemo(() => {
    if (props.disabled) return 'secondary'
    return props.theme
  }, [props.disabled, props.theme])

  return (
    <Button
      startIcon={props.loading ? <CircularProgress size={18} className={classes.spinner} /> : props.startIcon}
      {...props}
      className={classNames(classes.button, props.className, { [classes[theme]]: true })}
      color='default'
      disableElevation
    >
      {props.children}
    </Button>
  )
}

export default ThemeButton
