import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LabelContent } from 'V2Components'
import { getResourceName } from 'features/resources'
import { Button, Label, Typography } from 'procyon-ui'
import React, { useEffect } from 'react'

const ApproverInfo = ({ tag, onDelete }) => {
  const {getObjectRef} = useMultiSlice(['accountList', 'userList', 'groupList'])
  const kinds = Object.keys(tag?.KindMap?.Map)

  const accountData = getObjectRef(tag?.SearchRef)
  const userData = getObjectRef(tag?.Approvers?.ObjectRef)
  const tags = tag?.SearchString?.split(':')

  return (
    <div className='px-4'>
      <div className='flex justify-between'>
        <Typography variant='h4-regular'>{getResourceName(tag)}</Typography>
        <span className='flex gap-4'>
          <Button onClick={onDelete} variant='grayRed'>Delete</Button>
        </span>
      </div>
      <div>
        <LabelContent
          title='Resources'
          content={
            <div className='flex gap-2'>
              {kinds.map((k) => (
                <Label key={k} text={k} />
              ))}
            </div>
          }
        />

        {tag?.SearchType === 'account' && 
        <LabelContent 
          title='Account'
          content={
            <div className='flex gap-2'>
              <Label key={accountData?.Spec.Type} text={accountData?.ObjectMeta.Name} />
            </div>
          }
        /> }

        { tag?.SearchType !== 'account' &&
        <LabelContent 
        title='Tags'
        content={
          <div className='flex gap-2'>
            {tags.map((tag, index) => 
              <Label key={index} text={tag} />
              )}
            
          </div>
        }
      />
        }

       <LabelContent
          title='Approvers'
          content = {
            <div className='flex gap-2 flex-wrap'>
                {userData?.map((item) => (
                    <Label key={item?.ObjectMeta?.Name} text={item?.ObjectMeta?.Name} />
                ))}
            </div>
          }
        />
          
      </div>
    </div>
  )
}

export { ApproverInfo }
