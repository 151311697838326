import { API, ApiProvider } from 'Core'
import { debug } from './DebugHelper'
import { getCurrentOrg } from './Helpers'

/**
 *
 * @param {{ObjectMeta:{Name:string}}} user
 * @returns {string} preference name
 */
export const getAppViewPreferenceName = (user) => user.ObjectMeta.Name

/**
 * Tells the role of a given user
 * Fetches group lista  & check if user belongs to admin Group
 * @param {Object} user
 * @returns {Promise<Object>} | adds role as a key to the user
 */
const fetchUserRole = async (user) => {
  try {
    user.role = 'user'
    const groupList = await API.getGroupList(user)
    const adminGroup = groupList.find((group) => group.ObjectMeta.Name === 'admin')
    const adminGroupId = adminGroup ? adminGroup.ObjectMeta.ID : false
    if (user && user.Spec && adminGroupId) {
      const userGroupList = user.Spec.Groups.ObjectRef
      // Check wheter user is part of the admin group
      const isAdmin =
        userGroupList &&
        userGroupList.some((group) => group.RefID === adminGroupId && group.RefKind === 'Group')
      user.role = isAdmin ? 'admin' : 'user'
    }
  } catch (error) {
    console.error('error in fetchUserRole', error)
    user.role = 'user'
  }
  return user
}
/**
 * @typedef {{ObjectMeta:{Name},Spec:{Attributes:{Map:{LastViewForApp:string}}}}} Preference
 */
/**
 *
 * @param {Array<Preference>} UserPreferences
 * @param {{ObjectMeta:{Name:string}}} user
 * @returns {Preference|false}
 */
export const extractAppViewPreferences = (UserPreferences, user) => {
  if (UserPreferences && Array.isArray(UserPreferences) && user) {
    const preferenceName = getAppViewPreferenceName(user)
    const AppViewPreferences = UserPreferences.find((e) => e.ObjectMeta.Name === preferenceName)
    if (AppViewPreferences) return AppViewPreferences
  }
  return false
}

/**
 * Tells the last view mode accessed by the user
 * @param {Array} UserPreferences
 * @param {{ ObjectMeta:{Name:string}, role:'admin'|'user' }} user
 * @returns
 */
export const getLastViewForApp = (UserPreferences, user = null) => {
  const AppViewPreferences = extractAppViewPreferences(UserPreferences, user)
  if (AppViewPreferences) {
    const LastViewForApp = AppViewPreferences.Spec?.Attributes?.Map?.LastViewForApp
    if (user?.role === 'admin') return LastViewForApp || 'admin'
    else return 'user'
  }

  return user?.role === 'admin' ? 'admin' : 'user'
}

/**
 * Creates user preference for specified user
 * @param {{ObjectMeta:{Name:String, ID: String}}} user
 * @returns {Promise}
 */
export const createUserPreference = async (user) => {
  const preferenceName = getAppViewPreferenceName(user)
  const preference = {
    ObjectMeta: {
      Name: preferenceName,
      RdOwners: { ObjectRef: [{ RefKind: 'User', RefID: user.ObjectMeta.ID }] },
      WrOwners: { ObjectRef: [{ RefKind: 'User', RefID: user.ObjectMeta.ID }] }
    },
    Spec: { Attributes: { Map: { LastViewForApp: 'user' } } }
  }
  return await new ApiProvider('userpreferences').setInstance(preference).post()
}

// TODO Remove this function | populateUserMeta
/**
 * Sets name, tenant and org as top level keys to user object
 * @param {Object} user  | currently logged in user
 * @returns  | currently logged in user
 */
export const populateUserMeta = (user) => {
  const userMeta = {
    name: user.ObjectMeta.Name,
    tenant: user.ObjectMeta.Tenant,
    org: user.ObjectMeta.Namespace
  }
  return { ...user, ...userMeta }
}

/**
 * Add api key to user object from environment variables
 * @param {Object} user | currently logged in user
 * @returns | currently logged in user with api key fro environment
 */
const populateBypassValues = (user) => {
  const apiKey = debug.getApiToken()
  if (apiKey) {
    user.apiKey = apiKey
  }
  return user
}

/**
 * Adds necessary fields to the user Object | API key from environment, role and preferences
 * @param {Object} response | currently logged in user
 * @returns | currently logged in user with apiKey, role and preference
 */
export const prepareUserObject = (response = {}) => {
  let user = {
    DeviceInfo: {},
    UserOrgs: {},
    activeOrg: 'default',
    awsurl: '',
    rdpurl: '',
    CloudTypes: {}
  }

  try {
    const userData = response.data
    const userInfoHeader = JSON.parse(response.headers['x-auth-token'])

    user = populateUserMeta(userData)
    user = populateBypassValues(user)
    user.activeOrg = getCurrentOrg()
    user.UserOrgs = userInfoHeader.userorgs
    user.isAuditor = userInfoHeader.isauditor
    user.awsurl = userInfoHeader.awsurl || ''
    user.rdpurl = userInfoHeader.rdpurl
    user.DeviceInfo = { deviceid: userInfoHeader.deviceid }
    user.controller = userInfoHeader.controller

    /**
     * Initialise the CloudTypes
     */
    if (userInfoHeader?.cloudtypes) {
      const userCloudTypes = {}
      Object.keys(userInfoHeader.cloudtypes).forEach((cloudType) => {
        userCloudTypes[cloudType.toUpperCase()] = userInfoHeader.cloudtypes[cloudType]
      })
      user.CloudTypes = userCloudTypes
    }

    if (userInfoHeader && userInfoHeader.isadmin) {
      user.role = 'admin'
    } else {
      if (userInfoHeader && userInfoHeader.userorgs[getCurrentOrg()]) user.role = 'admin'
      else user.role = 'user'
    }
    // user.role = 'user'
  } catch (error) {}
  return user
}
