import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { isPolicyIssuedToUser } from 'features/policy'
import { createRef } from 'features/resources'

export const usePrivateKubeAccess = () => {
  const { slices } = useMultiSlice(['policyList'])
  const { user } = useUser()
  const getKubeAccessMap = () => {
    /**
     * Get all the targets which the `user` has access to.
     *
     * Sample:
     * ```
     *    {
     *      'Server+1234': true
     *      'AppRole+6670': true
     *    }
     * ```
     */
    const mapObj = {}
    slices.policyList.forEach((policy) => {
      if (!isPolicyIssuedToUser(policy, user)) return
      const KubeAccess = policy.Spec.ActionMap.KubeAccess?.PolicyRule || []
      KubeAccess.forEach((rule) => {
        const key = `${rule.ObjectRef.RefKind}+${rule.ObjectRef.RefID}`
        // Don't add any iam actions - iam actions carry iam rsrcs
        if (rule.ObjectRef.RefKind === 'IamAction') return
        const policyRefs = mapObj[key] || []
        policyRefs.push(createRef(policy))
        mapObj[key] = policyRefs
      })
    })
    return mapObj
  }

  return { getKubeAccessMap }
}
