/**
 * Resource kinds which have access links for signin in their Status.AccessLink
 */
export const ResourceKindWithAccessLink = {
  GcpResource: true,
  AzureResource: true
}

/**
 * A map of valid iam resource, KubeNamespace is not a valid iam resource
 */
export const IAMResourceKindsMap = {
  AwsResource   :   true,
  GcpResource   :   true,
  AzureResource :   true
}

/**
 * @type {import('types').SliceNames[]}
 */
export const IAMResourcesSliceNames = ['azureResources', 'gcpResources', 'awsResources', 'kubeNamespaces']