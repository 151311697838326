import { Box, useTheme } from '@material-ui/core'
import SeriesFilter from 'Components/Charts/ChartContainer/Components/SeriesFilter'
import SearchField from 'Components/SearchField'
import React from 'react'

const Header = ({ searchKey, setSearchKey, seriesMeta, searchPlaceholder }) => {
  const theme = useTheme()
  return (
    <Box display='flex' alignItems='center' justifyContent='space-betwen'>
      {searchKey !== null &&
        (<SearchField
          placeHolder={searchPlaceholder}
          background={theme.palette.grey[100]}
          elavation={0}
          height='2.5rem'
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          showIcon={false}
        />)}
      {seriesMeta && <SeriesFilter disabled pb={0} position='right' filters={seriesMeta} />}
    </Box>
  )
}

export default Header
