import useAppView from 'Core/Hooks/useAppView'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { FullScreenModal, LoadingFeedback } from 'V2Components'
import {
  getApprovalRequestResourcesRefs,
  getDateWithOptions
} from 'features/approvals'
import { ApprovalModal } from 'procyon-ui'
import React, { useMemo, useState } from 'react'
import { approveApprovalRequest, createAutoApprovePolicy } from '../../../api'

const endDateOptions = [
  {
    label: 'Hours',
    value: 'hours'
  },
  {
    label: 'Days',
    value: 'days'
  },
  {
    label: 'Weeks',
    value: 'weeks'
  },
  {
    label: 'Months',
    value: 'months'
  }
]

function ApproveRequestModal({
  showApprovalModal,
  handleCancel,
  approvalRequest,
  afterApproval = null
}) {
  const [comments, setComments] = useState('')
  const [autoApprove, setAutoApprove] = useState(false)
  const [loading, setLoading] = useState(false)
  const [endDateOption, setEndDateOption] = useState('hours')
  const [selectedEndDateTime, setSelectedEndDateTime] = useState('1-hours')
  const endDatesOptionsObject = useMemo(() => getDateWithOptions(new Date()), [])

  const { appView } = useAppView()

  const rsrcRefs = getApprovalRequestResourcesRefs(approvalRequest)

  const handleApproveClick = async () => {
    setLoading(true)
    const data = await approveApprovalRequest(approvalRequest, comments)
    updateSliceData(data)
    if (autoApprove) {
      const notAfter = endDatesOptionsObject[endDateOption][selectedEndDateTime.split('-')[0]]
      const paa = await createAutoApprovePolicy(approvalRequest, notAfter)
      pushToSlice(paa)
    }
    afterApproval && afterApproval(autoApprove)
    setLoading(false)
  }

  // Disable auto approve if there are more than one resource in the approval request
  if (autoApprove && rsrcRefs.length > 1) setAutoApprove(false)

  const handleEndDateOptionChange = (value) => {
    setEndDateOption(value)
    setSelectedEndDateTime(`1-${value}`)
  }

  const showAutoApproveOption = (() => {
    // Hide the auto approve in user view
    if (appView === 'user') return false
    return rsrcRefs.length === 1
  })()

  return (
    <div>
      <LoadingFeedback loading={loading} caption='Please wait...' message={'Approving Request'} />
      <FullScreenModal showModal={showApprovalModal && !loading}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <ApprovalModal
            allowsAutoApproval={showAutoApproveOption}
            autoApprove={autoApprove}
            onAutoApproveChange={setAutoApprove}
            endDateOptions={endDateOptions}
            endDateValue={endDateOption}
            onEndDateChange={handleEndDateOptionChange}
            onTimeChange={(e) => setSelectedEndDateTime(e)}
            onClickApprove={handleApproveClick}
            onClickCancel={handleCancel}
            onCommentsChange={setComments}
            comments={comments}
            timeOptions={Object.keys(endDatesOptionsObject[endDateOption] || {})?.map((e) => ({
              label: `${e} ${endDateOption[0].toUpperCase() + endDateOption.substring(1)}`,
              value: `${e}-${endDateOption}`
            }))}
            timeValue={selectedEndDateTime}
            title={`Are you sure you want to approve Request ${approvalRequest.GivenName}?`}
          />
        </div>
      </FullScreenModal>
    </div>
  )
}

export { ApproveRequestModal }
