import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { SearchInput } from 'V2Components'
import { Button, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

const Header = ({ searchKey, setSearchKey }) => {
  const history = useHistory()
  const { appView } = useAppView()

  const handleAddWorkload = () => history.push(`/${appView}/workloads/create`)

  return (
    <div className='flex justify-between items-center'>
      <Typography variant='h2'>Workloads</Typography>
      <div className='flex gap-4 items-center'>
        <SearchInput searchKey={searchKey} onChange={setSearchKey} />
        <Button icon={faPlus} onClick={handleAddWorkload} size='md' variant='primary'>
          Add New
        </Button>
      </div>
    </div>
  )
}

export { Header }
