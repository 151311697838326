import { Typography } from 'procyon-ui'
import React from 'react'

function PaginationCountText({ page, itemsToShow, totalItems, title = '' }) {
  return (
    <Typography variant='body-regular'>
      {(page - 1) * itemsToShow + 1} - {page * itemsToShow > totalItems ? totalItems : page * itemsToShow} of{' '}
      {totalItems} Total {title}
    </Typography>
  )
}

export { PaginationCountText }
