import React from 'react'
// @ts-ignore
import { makeStyles } from '@material-ui/core'

import VanillaTabs from 'Components/VanillaTabs'
import ChipCountHeader from 'Components/ChipCountHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    // @ts-ignore
    borderTopLeftRadius: props => props.borderRadius.topLeft,
    // @ts-ignore
    borderTopRightRadius: props => props.borderRadius.topRight,
    // @ts-ignore
    borderBottomLeftRadius: props => props.borderRadius.bottomLeft,
    // @ts-ignore
    borderBottomRightRadius: props => props.borderRadius.bottomRight,
    height: '100%'
  },
  tabs: {
    width: '100%'
  },
  wrapper: {
    padding: theme.spacing(1, 1.5, 0)
  },
  alertChip: {
    padding: theme.spacing(0),
    height: theme.spacing(3),
    backgroundColor: 'rgba(255, 152, 0, 0.1)',
    marginLeft: theme.spacing()
  }
}))

const TabsSegment = ({ title, TabList = [], current = 0, total = 0, borderRadius, compact = false, tabMinWidth = '10rem' }) => {
  const classes = useStyles({ borderRadius })

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <ChipCountHeader title={title} current={current} total={total} />
      </div>
      <div className={classes.tabs}>
        <VanillaTabs compact={compact} tabsMarginBottom='0' tabMinWidth={tabMinWidth} isActionDisabled TabList={TabList} />
      </div>
    </div>
  )
}
TabsSegment.defaultProps = {
  borderRadius: { topLeft: 1, topRight: 1.5, bottomLeft: 1.5, bottomRight: 1 }
}
export default TabsSegment
