import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useCloudTypes = () => {
  //@ts-ignore
  const ct = useSelector((s) => s.user.CloudTypes)

  // With Private, WP
  const cloudTypesWP = useMemo(() => {
    return Object.keys(ct).filter((e) => ct[e])
  }, [ct])

  const cloudTypes = useMemo(() => {
    return cloudTypesWP.filter((e) => e !== 'PRIVATE')
  }, [ct])

  return { cloudTypes, cloudTypesWP }
}
