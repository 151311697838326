import useAppView from 'Core/Hooks/useAppView'
import { SummaryHeader } from 'V2Components'
import {
  BundleAccessGraph,
  BundleRequestInfoModal,
  DeleteBundleModal,
  useBundlesUserAccess
} from 'features/bundles'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Button } from 'procyon-ui'
import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'

const BundleDetails = () => {
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [showBundleRequestModal, setShowBundleRequestModal] = useState(false)
  const bundle = useDataDetailsContext()
  //@ts-ignore
  const { bundleType } = useParams()

  const { getBundleAccessState } = useBundlesUserAccess()

  const bundleAccessState = getBundleAccessState(bundle)

  const { appView } = useAppView()
  const history = useHistory()

  return (
    <div>
      <SummaryHeader
        breadCrumbsItems={[
          {
            label: 'Bundles',
            link: `/${appView}/bundles`
          },
          {
            label: bundleType.toUpperCase(),
            link: `/${appView}/bundles/${bundleType.toLowerCase()}`
          },
          {
            label: getResourceName(bundle)
          }
        ]}
        actions={
          <>
            {appView === 'admin' && (
              <Button
                onClick={() => {
                  setshowDeleteModal(true)
                }}
                variant='grayRed'
              >
                Delete
              </Button>
            )}
            {appView === 'user' && bundleAccessState === 'ungranted' && (
              <Button
                onClick={() => {
                  setShowBundleRequestModal(true)
                }}
                variant='grayBlue'
              >
                Request
              </Button>
            )}
          </>
        }
        Icon={getRsrcIcon(bundle)}
        rsrcName={getResourceName(bundle)}
      />
      <div className='mt-4'>
        <BundleAccessGraph bundle={bundle} />
      </div>
      {showDeleteModal && (
        <DeleteBundleModal
          onSuccess={() => history.push(`/${appView}/bundles/${bundleType.toLowerCase()}`)}
          bundle={bundle}
          onCancel={() => setshowDeleteModal(false)}
        />
      )}
      {showBundleRequestModal && bundle && bundleAccessState === 'ungranted' && (
        <BundleRequestInfoModal
          bundle={bundle}
          onCancel={() => setShowBundleRequestModal(false)}
          onSuccess={() => setShowBundleRequestModal(false)}
        />
      )}
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider routeKey='bundleName' sliceName='bundles' loadingMessage='Fetching Bundle'>
    <BundleDetails />
  </DataDetailsProvider>
)

export { Wrapped as BundleDetails }
