import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRef, getRsrcIcon } from 'features/resources'
import { ResourceKinds, createApproverPolicy, customTagKeys } from 'features/settings'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { Button, CloudIcon, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { SelectEntity } from '../SelectEntity'

const ApproverPolicyCreateModal = ({ onCancel, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPolicyType, setSelectedPolicyType] = useState('account-type')
  const { slices, selectDispatch, getObjectRef } = useMultiSlice([
    'accountList',
    'userList',
    'groupList',
    'tagPolicys'
  ])
  const [selectedAccountRef, setSelectedAccountRef] = useState({})
  const [selectedAccountValue, setSelectedAccountValue] = useState('0')
  const [selectedResourceValue, setSelectedResourceValue] = useState([])
  const [showEntityModal, SetShowEntityModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [keyTag, setKeyTag] = useState('')
  const [valueTag, setValueTag] = useState('')
  const [policyName, setPolicyName] = useState('')
  const [selectedEntity, setSelectedEntity] = useState([])
  const [selectedEntitiesList, setSelectedEntitiesList] = useState([])
  const user = getUserInfo()

  useEffect(() => {
    selectDispatch(['accountList', 'userList', 'groupList'])
  }, [])

  const userAndGroupEntity = () => {
    return [...slices.userList, ...slices.groupList]
  }

  console.log('[tag](ke):', keyTag, valueTag)

  const getErrors = () => {
    try {
      if (policyName == '')
        return 'Approver Name is required and special characters are not allowed.'

      if (selectedPolicyType === 'cloudtag-type' || selectedPolicyType === 'policytag-type') {
        if (!keyTag) return 'Tag key is required.'
        if (!valueTag) return 'Tag value is required.'
      }

      if (selectedPolicyType === 'account-type' && Object.keys(selectedAccountRef).length === 0)
        return 'Please Select any one Account'
      if (selectedResourceValue.length === 0) return 'Please Select any one Resources'
      if (selectedEntity.length === 0) return 'Please Select Approver'
    } catch (error) {
      return 'error'
    }
  }

  const errors = getErrors()

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const payload = {
        ObjectMeta: {
          Name: policyName,
          NameSpace: user.org,
          Tenant: user.tenant
        },

        SearchType:
          selectedPolicyType === '1'
            ? 'account'
            : selectedPolicyType === '2'
            ? 'cloudtag'
            : 'poltag',
        SearchString: keyTag + ':' + valueTag,
        SearchRef: selectedAccountRef,
        KindMap: getResourcesMapObject(),
        Approvers: { ObjectRef: selectedEntity }
      }

      if (selectedPolicyType === '1') {
        delete payload.SearchString
      } else {
        delete payload.SearchRef
      }

      console.log('[tag](ty):', payload)
      return
      const data = await createApproverPolicy(payload)
      selectDispatch(['approvers'])
      pushToSlice(data)
      enqueueNotification('Tag created successfully!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Failed to create tag!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDropdownChange = (label, value) => {
    if (label === 'type') {
      setSelectedPolicyType(value)
    } else {
      if (label === 'resources') {
        setSelectedResourceValue(value)
      }
    }
  }

  const handleAccountDropdownChange = (selectedValue) => {
    const selectedIndex = parseInt(selectedValue, 10)
    if (!isNaN(selectedIndex) && selectedIndex > 0) {
      const selectedAccount = slices.accountList[selectedIndex - 1]

      if (selectedAccount) {
        setSelectedAccountRef(createRef(selectedAccount))
      }
    } else {
      setSelectedAccountRef(null)
    }
    setSelectedAccountValue(selectedValue)
  }

  const accountDropdownOptions = [
    {
      label: 'Please Select',
      value: '0',
      cloudType: ''
    },
    ...(slices?.accountList?.map((account, index) => ({
      label: (
        <>
          <CloudIcon
            type={account.Spec.Type?.toLowerCase()}
            style={{ marginRight: '10px', display: 'inline' }}
          />{' '}
          {account.ObjectMeta.Name}
        </>
      ),
      value: (index + 1).toString(), // Adjust the value to start from 1
      cloudType: account.Spec.Type
    })) || [])
  ]

  //Sort the menu items by cloud type in ascending order
  accountDropdownOptions.sort((a, b) => a.cloudType.localeCompare(b.cloudType))

  const getResourcesMapObject = () => {
    const updatedObject = {}
    for (const item of selectedResourceValue) {
      updatedObject[item] = 'true'
    }
    const finalUpdatedObject = {
      Map: updatedObject
    }
    return finalUpdatedObject
  }

  const handleEntityModalContinue = async () => {
    SetShowEntityModal(false)
    const ref_info_array = selectedEntities.map(createRef)
    const selectedEntitesList = getObjectRef(ref_info_array)
    setSelectedEntity(ref_info_array)
    setSelectedEntitiesList(selectedEntitesList)
  }

  const getTagPolicyValueOptions = (tagKey = '') => {
    const tagValuesMap = {}

    slices.tagPolicys.forEach((tagPolicy) => {
      const value = tagPolicy.Tags.Map[tagKey]
      if (value) tagValuesMap[value] = true
    })

    const tagValues = Object.keys(tagValuesMap)

    return tagValues.map((val) => ({ label: val, value: val }))
  }

  const getFilterResources = () => {
    let filteredList = ResourceKinds.filter(
      (item) => item.kind !== 'MedusaNode' && item.kind !== 'Project'
    )
    let filteredListWithAccount
    if (selectedPolicyType === 'account-type') {
      filteredListWithAccount = filteredList.filter((item) => item.kind !== 'Application')
      return filteredListWithAccount.map((e) => ({ label: e.label, value: e.kind }))
    } else {
      return filteredList.map((e) => ({ label: e.label, value: e.kind }))
    }
  }

  const handleNameInput = (value) => {
    // Use a regular expression to replace any character that is not a letter or number with an empty string
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
    // Update the form state with the sanitized value
    setPolicyName(sanitizedValue)
  }

  return (
    <>
      <FullScreenContentModal>
        <div>
          <Typography className='mb-4' variant='h4-regular'>
            Create Policy
          </Typography>

          <TextInput
            value={policyName}
            onChange={(e) => handleNameInput(e.target.value)}
            sx={{ width: '100%' }}
            label='Approver Policy Name'
          />

          <div className='flex gap-4 items-center my-2'>
            <SelectDropDown
              label='Select Policy Type'
              menuItems={[
                {
                  label: 'Account',
                  value: 'account-type'
                },
                {
                  label: 'CloudTag',
                  value: 'cloudtag-type'
                },
                {
                  label: 'PolicyTag',
                  value: 'policytag-type'
                }
              ]}
              onChange={(e) => {
                handleDropdownChange('type', e.target.value)
                setKeyTag('')
                setValueTag('')
              }}
              value={selectedPolicyType}
              style={{
                width: '150px'
              }}
            />

            {selectedPolicyType === 'account-type' && (
              <SelectDropDown
                label='Select Account'
                //@ts-ignore
                menuItems={accountDropdownOptions}
                onChange={(e) => handleAccountDropdownChange(e.target.value)}
                value={selectedAccountValue}
                style={{
                  width: '300px'
                }}
              />
            )}
          </div>

          {selectedPolicyType === 'cloudtag-type' && (
            <div className='flex gap-4 mt-6'>
              <TextInput
                onChange={(e) => setKeyTag(e.target.value)}
                sx={{ width: '150px' }}
                label='Enter Key'
              />
              <TextInput
                onChange={(e) => setValueTag(e.target.value)}
                sx={{ width: '150px' }}
                label='Enter Value'
              />
            </div>
          )}

          {selectedPolicyType === 'policytag-type' && (
            <div className='flex gap-4 mt-6 items-center'>
              <div>
                <Typography variant='body-regular' className='mb-1'>
                  Select Key
                </Typography>
                <SelectDropDown
                  sx={{ width: '150px' }}
                  menuItems={customTagKeys.map((e) => ({ label: e.label, value: e.kind }))}
                  onChange={(e) => {
                    setKeyTag(e.target.value)
                    setValueTag('')
                  }}
                  value={keyTag}
                />
              </div>
              <div>
                <Typography variant='body-regular' className='mb-1'>
                  Please Select Value
                </Typography>
                <SelectDropDown
                  sx={{ width: '150px' }}
                  menuItems={getTagPolicyValueOptions(keyTag)}
                  onChange={(e) => setValueTag(e.target.value)}
                  value={valueTag}
                />
              </div>
            </div>
          )}

          <SelectDropDown
            label='Please Select Resources Type'
            placeholder='Select Resources Type'
            menuItems={getFilterResources()}
            onChange={(e) => handleDropdownChange('resources', e.target.value)}
            value={selectedResourceValue}
            multiple={true}
            style={{
              width: '100%',
              margin: '20px 0 20px 0'
            }}
          />

          <div className='flex gap-2 items-center mt-5 flex-wrap'>
            <Button
              icon={faUsers}
              iconColor='#A6ABB6'
              iconPosition='end'
              onClick={() => SetShowEntityModal(true)}
              size='sm'
              variant='grayBlue'
            >
              Add Approver
            </Button>

            {selectedEntitiesList?.map((user) => {
              const Icon = getRsrcIcon(user)
              return (
                <Label key={user.ObjectMeta.Name} text={user.ObjectMeta.Name} icon={<Icon />} />
              )
            })}
          </div>

          <div className='flex justify-between items-center gap-4 mt-6'>
            {errors ? <Label variant='warning' text={errors} /> : <div></div>}
            <div className='flex gap-4'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={!!errors} variant='primary'>
                Create
              </Button>
            </div>
          </div>
          <LoadingFeedback message='Creating Tag' loading={isLoading} caption='Please wait..' />
        </div>
      </FullScreenContentModal>

      {showEntityModal && (
        <FullScreenContentModal width='auto'>
          <SelectEntity
            showPermission={''}
            title={`Select Approver`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={userAndGroupEntity()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              SetShowEntityModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}
    </>
  )
}

export { ApproverPolicyCreateModal }
