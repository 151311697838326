import { CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import ThemeButton from 'Components/ThemeButton'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  root: {
    margin: (props) => props.margin,
    padding: '8px 22px',
    height: '42px'
    // marginBottom: '10px', //margin should nod be hard coded in ui-components
  },
  Icon: {
    marginRight: '10px',
    color: theme.palette.background.paper
  },
  dangerLight: {
    backgroundColor: theme.palette.chip.error,
    color: theme.palette.text.secondary,
    '& svg': {
      color: theme.palette.error.main
    },
    '&:hover': {
      background: theme.palette.error.main
    }
  },
  label: {}
}))
const ActionButton = ({
  loading = false,
  margin,
  variant = 'default',
  size = 'medium',
  onClick,
  label,
  Icon,
  disabled = false,
  color = 'primary',
  disableElevation = false,
  className = '',
  fullWidth = false,
  theme = 'primary'
}) => {
  const classes = useStyle({ color, margin })
  return (
    <ThemeButton
      fullWidth={fullWidth}
      disableElevation={disableElevation || disabled}
      disabled={disabled}
      // @ts-ignore
      size={size}
      style={{ height: size === 'small' ? '30px' : '42px' }}
      theme={theme}
      variant='contained'
      onClick={onClick}
      startIcon={loading && <CircularProgress size='16px' color='primary.white' />}
      className={classNames(classes.root, className, classes[variant])}
    >
      {Icon && <Icon className={classes.Icon} />} <span className={color === 'primary' && classes.label}>{label}</span>
    </ThemeButton>
  )
}

export default ActionButton
