import { FloatingActions } from 'V2Components'
import { CreateGroupModal, DeleteGroupModal, ManageGroupUsers } from 'features/groups'
import { ResponsiveTable } from 'infra/ui'
import React from 'react'
import { useHistory } from 'react-router'
import { responsiveCloumns, useGroupList } from './GroupList.utils'
import { BulkAddToUser } from './components/BulkAddToUser'
import { BulkDeleteGroupModal } from './components/BulkDeleteGroupModal'
import { GroupsUsersModal } from './components/GroupsUsersModal'
import { Header } from './components/Header'

function GroupList() {
  const {
    bulkSelectedGroups,
    searchKey,
    setSearchKey,
    selectedRowIDs,
    setSelectedRowIDs,
    handleBulkAddToUser,
    handleBulkDeleteClick,
    setBulkSelectedGroups,
    views,
    handleViewsChange,
    selectedGroup,
    filteredGroups,
    rowDataAdapterFn
  } = useGroupList({})
console.log('[table](sele):', selectedRowIDs)
  return (
    <div>
      <Header
        onAddClick={() => handleViewsChange({ create: true })}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
      <div className='mt-8'>
        <ResponsiveTable
          rowSelection={{
            selectedRowKeys: selectedRowIDs,
            type: 'checkbox',
            onChange(selectedRowIDs) {
              setSelectedRowIDs(selectedRowIDs)
            }
          }}
          columns={responsiveCloumns()}
          data={filteredGroups.map(rowDataAdapterFn)}
          scrollX='1200px'
        />
      </div>
      {views.create && (
        <CreateGroupModal
          onCancel={() => handleViewsChange({ create: false })}
          onComplete={() => handleViewsChange({ create: false })}
        />
      )}
      {selectedRowIDs.length > 0 && (
        <FloatingActions
          message={`${selectedRowIDs.length} Groups Selected`}
          buttons={[
            {
              label: 'Add Users',
              onClick: handleBulkAddToUser,
              variant: 'grayBlue'
            },
            {
              label: 'Delete',
              onClick: handleBulkDeleteClick,
              variant: 'grayRed'
            }
          ]}
        />
      )}
      {views.bulkDelete && (
        <BulkDeleteGroupModal
          onCancel={() => handleViewsChange({ bulkDelete: false })}
          onSuccess={() => handleViewsChange({ bulkDelete: false })}
          onUpdate={setBulkSelectedGroups}
          groups={bulkSelectedGroups}
        />
      )}
      {views.bulkAddUsers && bulkSelectedGroups.length > 1 && (
        <BulkAddToUser
          groups={bulkSelectedGroups}
          onCancel={() => handleViewsChange({ bulkAddUsers: false })}
          onSuccess={() => handleViewsChange({ bulkAddUsers: false })}
        />
      )}
      {views.bulkAddUsers && bulkSelectedGroups.length === 1 && (
        <ManageGroupUsers
          group={bulkSelectedGroups[0]}
          onCancel={() => handleViewsChange({ bulkAddUsers: false })}
          onSuccess={() => handleViewsChange({ bulkAddUsers: false })}
        />
      )}
      {views.edit && (
        <CreateGroupModal
          onCancel={() => handleViewsChange({ edit: false })}
          onComplete={() => handleViewsChange({ edit: false })}
          group={selectedGroup}
        />
      )}
      {views.delete && (
        <DeleteGroupModal
          group={selectedGroup}
          onCancel={() => handleViewsChange({ delete: false })}
          onSuccess={() => handleViewsChange({ delete: false })}
        />
      )}
      {views.usersModal && (
        <GroupsUsersModal
          group={selectedGroup}
          onClose={() => handleViewsChange({ usersModal: false })}
        />
      )}
    </div>
  )
}

export { GroupList }
