
import ThemeInput from 'Components/ThemeInput'
import React from 'react'

const SimpleSearchFilter = ({ onChange, name, label, filterType }) => {
  return (
    <ThemeInput
      variant='outlined'
      fullWidth
      color='primary'
      label={label}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => onChange(e.target.value, name, filterType)}
    />
  )
}

export default SimpleSearchFilter
