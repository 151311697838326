import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { FullScreenAlertModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function DeleteConfirmationModal({ item, onCancel, onDelete, onSuccess = null, onError = null }) {
  const name = getResourceName(item)
  const [deleteConfirmText, setDeleteConfirmText] = useState('')

  const handleDelete = async () => {
    try {
      const d = await onDelete()
      deleteSliceData(d)
      onSuccess?.()
    } catch (error) {
      onError?.()
    }
  }

  return (
    <FullScreenAlertModal
      actionButtonText='Delete'
      actionButtonVariant='danger'
      loadingMessage={`Deleting ${addSpaces(item.ObjectMeta.Kind)}`}
      showModal
      onActionClick={handleDelete}
      onCancel={onCancel}
      disableAction={deleteConfirmText.toLowerCase() !== 'delete'}
      Content={
        <div className='w-[100%]'>
          <Typography className='mb-3' variant='body-regular'>
            Enter 'delete' to confirm.
          </Typography>
          <TextInput
            onPaste={(e) => e.preventDefault()}
            value={deleteConfirmText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            sx={{ width: '100%' }}
            placeholder='delete'
          />
        </div>
      }
      alertMessage={
        <>
          Are you sure you want to delete {addSpaces(item.ObjectMeta.Kind).toLowerCase()}
          <strong className='mx-1' title={name}>
            {name.substring(0, 21)}
            {name.length > 21 && '...'}
          </strong>
          ?
        </>
      }
    />
  )
}

export { DeleteConfirmationModal }
