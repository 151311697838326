import { enqueueNotification, errorHandler, getUserInfo } from '../Utils/Helpers'
import { deleteRequest, getRequest, postRequest, putRequest } from './NetworkRequest'

export class credentialApis {
  /** fetch all credentials List */
  static async getCredentialsList () {
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = await getRequest(apiKey, 'credentials', tenant)
      console.log('getcredentialInstanceList response: ', response)
      return response && response.data ? response.data.Credentials : []
    } catch (error) {
      errorHandler(error, 'getCredentialsList', 'credentialApis.js')
    }
  }

  static async updateCredentials (data) {
    console.log('updateCredentials: ', data)
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await putRequest(apiKey, `credentials/${org}`, data.ObjectMeta.Name, tenant, data, 'api')
      console.log('response: ', response)
      return response
    } catch (error) {
      errorHandler(error, 'updateCredentials', 'SERVER.js')
    }
  }

  static async createCredentials (data) {
    console.log('createCredentials: ', data)
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = await postRequest(apiKey, 'credentials', tenant, data)
      console.log('response: ', response)
      return response
    } catch (error) {
      errorHandler(error, 'createCredentials', 'SERVER.js')
    }
  }

  static async deleteCredential (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await deleteRequest(apiKey, `credentials/${org}`, data.ObjectMeta.Name, tenant, 'api')
      enqueueNotification('Credential Deleted', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'updateCredential', 'SERVER.js')
    }
  }
}
