import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
/**
 * Returns `true` if it is possible in the browser to go back
 * You can check location.key if you have a location key that means you routed in-app.
 * But if you don't that means you come from outside of the app or you just open the app in a new tab etc
 * @returns {any} If it is possible to go back
 */
export const useCanGoBack = (fallbackURL) => {
  const canGoBack = useLocation().key !== 'default'
  const history = useHistory()

  if (typeof fallbackURL === 'string') {
    return () => {
      if (canGoBack) history.goBack()
      history.push(fallbackURL)
    }
  }

  return canGoBack
}
