import React from 'react'
import ReactJson from 'react-json-view'

const JsonViewer = ({ recommendedResponse }) => {
  return (
    <div className='bg-gray-100 p-4 rounded'>
      <ReactJson
        src={recommendedResponse}
        theme='monokai'
        collapsed={2}
        enableClipboard={true}
        displayDataTypes={false}
      />
    </div>
  )
}

export { JsonViewer }
