import React from 'react'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'
import { ProxyControllers } from '../views/ProxyControllers'
import { ProxyControllerDetails } from '../views/ProxyControllerDetails'

const ProxyControllersRoutes = () => {
  return (
    <>
      <Route
        exact
        path={[
          '/admin/proxy-controllers',
          '/admin/proxy-controllers/create',
          '/admin/proxy-controllers/:name',
          '/admin/proxy-controllers/:name/edit'
        ]}
      >
        <Switch>
          {/* ========================= Admin Routes =================================*/}
          <Route exact path={['/admin/proxy-controllers', '/admin/proxy-controllers/create']}>
            <ProxyControllers />
          </Route>
          <Route
            exact
            path={['/admin/proxy-controllers/:name', '/admin/proxy-controllers/:name/edit']}
          >
            <ProxyControllerDetails />
          </Route>
        </Switch>
      </Route>
    </>
  )
}

export { ProxyControllersRoutes }
