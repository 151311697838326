import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CustomTypography } from 'Components/CustomTypography'
import MountOn from 'Components/MountOn'
import ThemeButton from 'Components/ThemeButton'
import lodash from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { ITEMS } from './meta'
import './styles.css'

const useStyles = makeStyles((theme) => ({
  tabIcon: {
    marginRight: theme.spacing(0.5)
  },
  adjustHeight: {
    transform: `translateY(${theme.spacing(0.125)}px)`
  },
  selectedTab: {
    /* Border bottom needs to be same as the background color of the parent */
    borderBottom: '1px solid #FAFAFA !important',
    color: theme.palette.primary.main + ' !important'
  },
  tab: {
    height: theme.spacing(2.4),
    display: 'inline-block',
    borderRadius: '5px 5px 0 0',
    border: '1px solid #cfcfcf',
    borderBottom: 'none',
    position: 'relative',
    bottom: '-2px',
    listStyle: 'none',
    padding: theme.spacing(0.5, 0.75, 0.5),
    cursor: 'pointer',
    marginRight: theme.spacing(0.125),
    color: theme.palette.text.primary,
    userSelect: 'none'
  }
}))

/**
 * @typedef {{ Component: React.ReactElement }} Panel
 * @typedef {{ path:string[] | string }} Route
 * @typedef {(tab:CustomTab, index: number) => void} TabSelectCallback
 * @typedef {{ label: string, theme: 'primary' | 'secondary' | 'error', handleClick: (() => void) }} ACTION
 * @typedef {{ key: string,route?: Route,Tab:{ Icon?: import('@material-ui/icons').SvgIconComponent, Title:any, adjustHeight ?:Boolean}, TabPanel: Panel}} CustomTab
 * @param {{externalClasses:{headerClass:string,tabClass:string},tabItems: CustomTab[],controlled?:boolean ,onTabChange?: TabSelectCallback, selectedIndex?:number, defaultIndex?: number, disableRouteMatch?: boolean, actionButton?: ACTION, disableRouteCorrection?: boolean  }} param0
 * @returns
 */
const defaultClasses = { headerClass: '', tabClass: '' }
const ReactTabs = ({
  tabItems = ITEMS,
  controlled,
  onTabChange,
  selectedIndex,
  defaultIndex = 0,
  disableRouteMatch = true,
  actionButton,
  disableRouteCorrection = false,
  externalClasses = defaultClasses,
  disableTabs = false
}) => {
  // @ts-ignore
  const { subPage } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(() => {
    if (disableRouteMatch) {
      return defaultIndex || 0
    }
    return defaultIndex
  })
  /**
   * @type {string[]} Array of routes to match the `subPage`
   */
  const routesArray = useMemo(() => {
    const _map = []
    tabItems.forEach((item) => {
      const route = item?.route
      if (!route || !route.path) {
        _map.push([])
      } else if (lodash.isArray(route.path)) {
        _map.push(route.path)
      } else if (lodash.isString(route.path)) {
        _map.push([route.path])
      }
    })
    return _map
  }, [tabItems])

  const [tabList, panelist] = useMemo(() => {
    const tabs = []
    const panels = []
    tabItems.forEach((item, index) => {
      const { Icon, Title, adjustHeight } = item?.Tab
      tabs.push(
        <Tab disabled={disableTabs} key={item.key} className={classNames(classes.tab, externalClasses.tabClass)}>
          <Box display='flex' alignItems='center' justifyContent='center'>
            {Icon && <Icon className={classNames({ [classes.tabIcon]: true, [classes.adjustHeight]: adjustHeight })} />}
            <CustomTypography.Body>{Title}</CustomTypography.Body>
          </Box>
        </Tab>
      )
      panels.push(<TabPanel key={item.key}>{item.TabPanel.Component}</TabPanel>)
    })
    return [tabs, panels]
  }, [tabItems, activeIndex, disableTabs])

  const handleTabChangeClick = useCallback(
    (index, lastIndex) => {
      /* Prevent render when same tab is clicked */
      if (lastIndex === index) return
      if (lodash.isFunction(onTabChange)) onTabChange(tabItems[index], index)
      if (controlled) return
      if (disableRouteMatch) {
        setActiveIndex(index)
        return
      }
      /* In case route is not passed for this tab but route match is enabled, goto this tab but dont change the route */
      if (!routesArray[index] || !routesArray[index][0]) {
        return setActiveIndex(index)
      }
      /* The previous Tab has no route passed, since the route would remain same hence, we need to forcefully go to the selected route */
      if (!routesArray[lastIndex] || !routesArray[lastIndex][0]) {
        setActiveIndex(index)
      }
      const newRoute = routesArray[index][0]
      history.push(newRoute)
    },
    [disableRouteMatch, onTabChange, routesArray]
  )

  useEffect(() => {
    if (disableRouteMatch || controlled) return
    let matchTabIndex = null
    routesArray.every((paths, index) => {
      if (paths.includes(subPage)) {
        matchTabIndex = index
        return false
      }
      return true
    })
    /* If the tabs doesnt match the subPage, fallback to default route */
    if (matchTabIndex === null) {
      matchTabIndex = defaultIndex
      /**
       * Change the tab to default index tab
       */
      !disableRouteCorrection && console.log(`React Tabs: ROUTE CORRECTED TO: [${routesArray[defaultIndex][0]}]`)
      !disableRouteCorrection && history.push(routesArray[defaultIndex][0])
    }
    setActiveIndex(matchTabIndex)
  }, [subPage, routesArray, disableRouteMatch, defaultIndex])

  useEffect(() => {
    if (typeof selectedIndex === 'number' && controlled) setActiveIndex(selectedIndex)
  }, [selectedIndex, controlled])

  return (
    <Tabs onSelect={handleTabChangeClick} selectedIndex={activeIndex} selectedTabClassName={classes.selectedTab}>
      <TabList className={classNames(externalClasses.headerClass, 'react-tabs__tab-list')}>
        <Box>{tabList}</Box>
        <MountOn on={!!actionButton}>
          {actionButton && (
            <Box display='inline-block' pr={1}>
              <ThemeButton onClick={actionButton.handleClick} size='small' theme={actionButton.theme}>
                {actionButton.label}
              </ThemeButton>
            </Box>
          )}
        </MountOn>
      </TabList>
      {panelist}
    </Tabs>
  )
}

export default ReactTabs
