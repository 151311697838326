import { errorHandler, getUserInfo } from '../Utils/Helpers'
import { getRequest } from './NetworkRequest'

export default class scimserverApis {
  static async scimserverGetCredentials () {
    try {
      const user = getUserInfo()
      const { apiKey, tenant, org } = user
      const response = await getRequest(apiKey, `scimservers/${org}/scim`, tenant)
      return response
    } catch (error) {
      errorHandler(error, 'scimserverGetCredentials', 'scimserverApis.js')
    }
  }
}
