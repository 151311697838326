import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { useApprovalPolling } from 'features/approvals'
import { useCloudTypes } from 'features/clouds'
import {
  ResourcesView,
  ResourcesViewProvider,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getTargetBookmarksCount,
  isResourceManaged,
  resourceAdapterMap,
  reverseRsrcKey,
  useAccessCartProvider,
  useBookmarks
} from 'features/resources'
import {
  DeletePrivateServerModal,
  getAppRoleSignInURL,
  getRDPServerAccessLinksMap,
  isDynamicAppRole,
  isPrivateServer,
  usePrivateKubeAccess,
  useRDPAccess,
  useTargetAccess
} from 'features/targets'
import { SelectDropDown, TargetCard } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TargetsFilters } from '../TargetsFilters'
import { K8ContextModal } from './components'
import { useTagsRsrcAccess } from 'features/tags'

const DEFAULT_FILTERS = ['granted', 'ungranted', 'bookmarked', 'managed', 'unmanaged']

/**
 * @param {{forPolicy?:boolean, onAccessClick?:((resource) => void), activeCloudType: 'AWS' | 'GCP' | 'AZURE' | 'PRIVATE', setActiveCloudType:((string) => void) }} param0
 * @returns
 */
function TargetsView({ onAccessClick, forPolicy, activeCloudType, setActiveCloudType }) {
  const [server, setServer] = useState(null)
  const [showDeletePrivateServerModal, setShowDeletePrivateServerModal] = useState(false)
  const [selectedTag, setSelectedTag] = useState('')
  const { getKubeAccessMap } = usePrivateKubeAccess()
  const [showK8SingInModal, setShowK8SingInModal] = useState(false)
  const [kube8SignInUrl, setK8SignInUrl] = useState([])

  const [activeCheckboxFilters, setActiveCheckboxFilters] = useState(DEFAULT_FILTERS)
  // This holds the account id and Type of the resource which was added to queue
  // For filtering out other resources which don't belong to this account
  // const resourceQueueAccount = resourcesQueue[0]?.Account || { RefID: '', Type: '' }
  const [cartResourceAccount, setCartResourceAccount] = useState({
    RefID: '',
    Type: '',
    RefKind: ''
  })
  const { startApprovalPolling } = useApprovalPolling()
  const { currentUserIsAdmin, user } = useUser()
  const { cloudTypesWP } = useCloudTypes()
  const { viewMode } = useAppView()
  const history = useHistory()
  const { slices, selectDispatch, getObjectRef } = useMultiSlice([
    'approvalReqList',
    'policyList',
    'paaPolicyList',
    'serverList',
    'appRolesList',
    'databases',
    'accountList',
    'rdpServers',
    'kafkas',
    'kubeClusters',
    'serverControllers'
  ])
  const { cartItems, isResourceInCart } = useAccessCartProvider()

  const { getAccessGrantedTargetsFromPolicy } = useTargetAccess()
  const { getUserRDPAccessLinks } = useRDPAccess()
  const { getRsrcTagPolicies } = useTagsRsrcAccess()

  const accessGrantedTargets = getAccessGrantedTargetsFromPolicy()
  const { isBookmarked, toggleBookmark } = useBookmarks()

  if (cartItems.length && slices.accountList.length && !cartResourceAccount.RefID) {
    const rsrc = getObjectRef(cartItems[0].Resource)
    if (rsrc) {
      const account = getObjectRef(rsrc.Spec.Account)
      if (account) {
        setCartResourceAccount({
          RefID: account.ObjectMeta.ID,
          RefKind: account.ObjectMeta.Kind,
          Type: account.Spec.Type
        })
      }
    }
  } else {
    if (cartResourceAccount.RefID && !cartItems.length) {
      setCartResourceAccount({
        RefID: '',
        RefKind: '',
        Type: ''
      })
    }
  }

  const getFilteredResources = () => {
    const rsrcs = []

    /** ********************************* Filters based on Resource Types ********************/

    const targetType = activeCheckboxFilters.some((e) =>
      ['Server', 'Database', 'AppRole', 'RDPServer', 'Kafka', 'KubeCluster'].includes(e)
    )

    // If no target types filter is selected, then show all resources
    if (!targetType) {
      rsrcs.push(
        ...slices.appRolesList,
        ...slices.serverList,
        ...slices.databases,
        ...slices.rdpServers,
        ...slices.kafkas,
        ...slices.kubeClusters
      )
    } else {
      if (activeCheckboxFilters.includes('Server')) rsrcs.push(...slices.serverList)
      if (activeCheckboxFilters.includes('AppRole')) rsrcs.push(...slices.appRolesList)
      if (activeCheckboxFilters.includes('Database')) rsrcs.push(...slices.databases)
      if (activeCheckboxFilters.includes('RDPServer')) rsrcs.push(...slices.rdpServers)
      if (activeCheckboxFilters.includes('Kafka')) rsrcs.push(...slices.kafkas)
      if (activeCheckboxFilters.includes('KubeCluster')) rsrcs.push(...slices.kubeClusters)
    }
    /** ********************************* Filters based on Managed/Unmanaged ********************/

    const managedFilteredRsrcs = (() => {
      const isManagedFilterActive = activeCheckboxFilters.some((e) =>
        ['managed', 'unmanaged'].includes(e)
      )
      if (!isManagedFilterActive) return rsrcs
      return rsrcs.filter((rsrc) => {
        if (viewMode === 'user') return isResourceManaged(rsrc)
        if (activeCheckboxFilters.includes('managed') && isResourceManaged(rsrc)) return true
        if (activeCheckboxFilters.includes('unmanaged') && !isResourceManaged(rsrc)) return true
        return false
      })
    })()

    /** ***************** Filters based on Granted/Ungranted/Bookmarked *********************/

    const accessStatusFiltered = (() => {
      const isAccessFilterActive = activeCheckboxFilters.some((e) =>
        ['granted', 'ungranted', 'bookmarked'].includes(e)
      )
      if (!isAccessFilterActive) return managedFilteredRsrcs
      return managedFilteredRsrcs.filter((rsrc) => {
        const rsrcKey = createRsrcKey(rsrc)
        if (activeCheckboxFilters.includes('bookmarked') && isBookmarked(rsrcKey)) return true
        /**
         * If admin is viewing the targets, make all targets seem as granted
         */
        if (currentUserIsAdmin && isResourceManaged(rsrc)) {
          if (activeCheckboxFilters.includes('granted')) return true
          return
        }
        /** Non-admin user is viewing hence do the correct checks  */
        if (activeCheckboxFilters.includes('granted') && accessGrantedTargets[rsrcKey]) return true
        if (activeCheckboxFilters.includes('ungranted') && !accessGrantedTargets[rsrcKey])
          return true
      })
    })()
    /****************************************************************************************/

    /** *****************  Filter Private Servers by tags *********************/
    return accessStatusFiltered.filter((r) => {
      // Skip the check if private server tab is not selected or rsrc is not private server or selectedTag == All
      if (activeCloudType !== 'PRIVATE' || !isPrivateServer(r) || !selectedTag) return true
      const { proxygrp } = r.ObjectMeta.Labels.Map
      const spliteTags = proxygrp?.split(',')
      const tagMatch = spliteTags?.some((tag) => tag.trim() === selectedTag.trim())
      // selectedTag === '-' check if "Without Tags" option is selected and return true if the server is without servergrp tag
      if (selectedTag === '-' && !tagMatch) return true
      // if tag is same as selected tag return true
      if (tagMatch) return true
    })
  }

  const filteredTargets = getFilteredResources()

  const isResourceApprovalPending = (rsrc) => {
    let isPending = false
    slices.approvalReqList.some((req) => {
      if (req.Status !== 'Pending') return
      const resources = req.Spec.Resources.Resource
      resources.forEach((r) => {
        const targetKey = createRsrcKey(r.Target)
        const Kinds = ['AppRole', 'Server', 'Database', 'Kafka', 'RDPServer', 'KubeCluster']
        // Only targets approval request should be used
        if (Kinds.includes(r.Target.RefKind))
          if (targetKey === createRsrcKey(rsrc)) isPending = true
        return isPending
      })
      return isPending
    })
    return isPending
  }

  const getResourceData = (rsrc) => {
    const rsrcKind = rsrc.ObjectMeta.Kind
    const account = getObjectRef(rsrc.Spec.Account)
    const fn = resourceAdapterMap[rsrcKind]
    return fn?.({ rsrc, account })
  }

  const getServiceType = (rsrc) => {
    switch (rsrc.ObjectMeta.Kind) {
      case 'AppRole':
        if (isDynamicAppRole(rsrc)) return 'APPROLE_DYNAMIC'
        return 'APPROLE'
      case 'Server':
        if (isPrivateServer(rsrc)) return 'SERVER_PRIVATE'
        else return 'SERVER'
      case 'Database':
        return 'DATABASE'
      case 'RDPServer':
        return 'RDP_SERVER'
      case 'Kafka':
        return 'APACHE_KAFKA'
      case 'KubeCluster':
        return 'KUBE_CLUSTERS'
      default:
        return 'APPROLE'
    }
  }

  const getRDPServerSignInUrl = (rsrc) => {
    const accessLinksMap = getRDPServerAccessLinksMap(rsrc)
    if (viewMode === 'admin') return accessLinksMap
    const userAccessLinksMap = getUserRDPAccessLinks(user, rsrc)
    console.log(userAccessLinksMap)
    return userAccessLinksMap
  }

  const getCardDotMenuItems = ({ rsrc, accessState }) => {
    const dotSubMenuItems = []
    const isManaged = isResourceManaged(rsrc)
    const rsrcKind = rsrc.ObjectMeta.Kind
    if (isManaged && !isDynamicAppRole(rsrc)) {
      if (['KubeCluster', 'KubeNamespace'].includes(rsrcKind)) {
        dotSubMenuItems.push({
          action: () => onAccessClick && onAccessClick(rsrc),
          title: forPolicy ? 'Create Policy' : 'Request'
        })
      } else if (
        accessState !== 'signIn' ||
        forPolicy ||
        rsrcKind === 'Server' ||
        rsrcKind === 'Database' ||
        rsrcKind === 'RDPServer' ||
        rsrcKind === 'Kafka'
      ) {
        dotSubMenuItems.push({
          action: () => onAccessClick && onAccessClick(rsrc),
          title: forPolicy ? 'Create Policy' : 'Request'
        })
      }
    }

    /**
     * Provide delete for private servers on admin view
     */
    if (isPrivateServer(rsrc) && viewMode === 'admin') {
      dotSubMenuItems.push({
        action: () => {
          setServer(rsrc)
          setShowDeletePrivateServerModal(true)
        },
        title: 'Delete'
      })
    }

    const copyProfileName = (ProfileName) => {
      try {
        navigator.clipboard.writeText(ProfileName)
        enqueueNotification('Profile Name  copied : ' + ProfileName, 'info')
      } catch (error) {
        console.error(error)
      }
    }

    if (accessState === 'signIn' && rsrcKind === 'AppRole') {
      if (getAppRoleSignInURL(rsrc)) {
        dotSubMenuItems.push({
          action: () => openURLExternally(getAppRoleSignInURL(rsrc)),
          title: 'Sign In'
        })
      }
      dotSubMenuItems.push({
        action: () => copyProfileName(rsrc.ObjectMeta.Name),
        title: 'Copy Profile Name'
      })
    }

    if (rsrcKind === 'RDPServer' && isManaged) {
      const menuList = getRDPServerSignInUrl(rsrc)
      Object.entries(menuList).forEach(([key, value]) => {
        dotSubMenuItems.push({
          action: () => openURLExternally(value),
          title: `Sign In - ${key.toLowerCase()}`
        })
      })
    }

    if (rsrcKind === 'KubeCluster') {
      const urls = []
      const kubeCluster = rsrc
      const rsrcPolicys = getKubeAccessMap()[createRsrcKey(rsrc)] || []
      const policys = getObjectRef(rsrcPolicys)

      // add default copy ctx for cluster level in admin view
      if (viewMode === 'admin' && isManaged) {
        urls.push({
          resouceName: getResourceName(rsrc),
          policyName: 'Default',
          command: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon`,
          action() {
            navigator.clipboard.writeText(`${kubeCluster.Spec.IAMRes}:procyon:`)
            enqueueNotification(`Context Name  copied`, 'info')
          }
        })
        dotSubMenuItems.push({
          action: () => handleK8signInClick(urls),
          title: 'Copy Ctx'
        })
      }

      policys?.forEach((policy, index) => {
        urls.push({
          resouceName: getResourceName(rsrc),
          policyName: getResourceName(policy),
          command: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon:${policy.ObjectMeta.Name}`,
          action() {
            navigator.clipboard.writeText(
              `${kubeCluster.Spec.IAMRes}:procyon:` + getResourceName(policy)
            )
            enqueueNotification(`Context Name  copied : ${getResourceName(policy)}`, 'info')
          }
        })

        if (viewMode === 'user') {
          dotSubMenuItems.push({
            action: () => handleK8signInClick(urls),
            title: 'Copy Ctx'
          })
        }
      })
    }
    return dotSubMenuItems
  }

  const handleK8signInClick = (data) => {
    setK8SignInUrl(data)
    setShowK8SingInModal(true)
  }

  const renderCardComponent = (rsrc) => {
    const data = getResourceData(rsrc)
    const rsrcKey = createRsrcKey(rsrc)
    const isManaged = isResourceManaged(rsrc)
    // all pac policys matching the tag for rsrc
    const rsrcTagPolicys = getRsrcTagPolicies(rsrc)
    /** Checking the approval state of the object */
    let accessState = 'access'
    if (isResourceApprovalPending(rsrc)) accessState = 'waiting'
    if (isResourceInCart({ RefKind: data.RefKind, RefID: data.RefID })) accessState = 'requested'
    if (
      isManaged &&
      (accessGrantedTargets[rsrcKey] || currentUserIsAdmin || rsrcTagPolicys.length)
    ) {
      accessState = 'signIn'
    }

    // check for RDPServer Policy created
    if (rsrc.ObjectMeta.Kind === 'RDPServer' && isManaged) {
      const menuList = getRDPServerSignInUrl(rsrc)
      if (Object.entries(menuList).length !== 0) accessState = 'signIn'
    }

    const getMenu = () => {
      if (rsrc.ObjectMeta.Kind === 'Kafka' && !currentUserIsAdmin && accessState === 'signIn')
        return []

      return [
        {
          icon: faEllipsisVertical,
          submenu: getCardDotMenuItems({ rsrc, accessState }),
          title: ''
        }
      ]
    }
    return (
      <TargetCard
        serviceType={getServiceType(rsrc)}
        isGranted={accessState === 'signIn'}
        onClickRequest={() => onAccessClick && onAccessClick(rsrc)}
        onClickPolicy={() => onAccessClick && onAccessClick(rsrc)}
        onClickMoreInfo={() =>
          history.push(`/${viewMode}/targets/${data.RefKind.toLowerCase()}/${rsrc.ObjectMeta.Name}`)
        }
        key={rsrcKey}
        forPolicy={forPolicy}
        onClickAccess={() => onAccessClick && onAccessClick(rsrc)}
        onClickBookmark={() => toggleBookmark(rsrcKey)}
        region={data.region}
        resourceName={data.resourceName}
        resourceType={data.resourceType}
        accountName={data.accountName}
        variant='primary'
        bookmarked={isBookmarked(rsrcKey)}
        showPolicyButton={isManaged}
        width='100%'
        // @ts-ignore
        accessState={accessState}
        dotMenuItems={getMenu()}
      />
    )
  }

  const getTagsDropdownOptions = () => {
    const options = new Set()
    slices.serverList.forEach((s) => {
      if (isPrivateServer(s)) {
        const tags = s.ObjectMeta?.Labels?.Map
        if (tags.hasOwnProperty('proxygrp')) {
          const { proxygrp, ...rest } = tags
          // Split the comma-separated values
          const values = proxygrp.split(',')
          // Add unique values to the set
          values.forEach((value) => {
            options.add(value.trim())
          })
        }
      }
    })
    const uniqOptions = [...new Set(options)]
    return uniqOptions.map((e) => ({ label: e, value: e }))
  }

  const getGrantedCount = () => {
    const filteredResources = filteredTargets.filter((e) => {
      const account = getObjectRef(e.Spec.Account)
      const accountType = account?.Spec?.Type || 'PRIVATE'
      return activeCloudType === accountType
    })

    const bookmarked = getTargetBookmarksCount({
      list: filteredResources,
      isBookmarked
    })
    let granted = 0
    if (currentUserIsAdmin) granted = filteredResources.filter(isResourceManaged).length
    else {
      granted = filteredResources.reduce((prev, rsrc) => {
        const rsrcKey = `${rsrc.ObjectMeta.Kind}+${rsrc.ObjectMeta.ID}`
        const rsrcTagPolicys = getRsrcTagPolicies(rsrc)
        //both normal policys and tag based pac policys check
        if (accessGrantedTargets[rsrcKey] || rsrcTagPolicys.length) return prev + 1
        return prev
      }, 0)
    }
    const ungranted = filteredResources.length - granted
    const managed = filteredResources.filter(isResourceManaged).length
    const unmanaged = filteredResources.length - managed
    return { bookmarked, granted, ungranted, managed, unmanaged }
  }

  const counts = getGrantedCount()

  /**
   * Returns accounts for the resource which are there
   * @returns {{label: string, value: string}[]}
   */
  const getDropDownItems = () => {
    const list = []
    const items = []
    filteredTargets.forEach((r) => {
      const k = createRsrcKey(getRsrcAccountRef(r))
      if (!list.includes(k)) {
        list.push(k)
        const account = getObjectRef(reverseRsrcKey(k))
        if (account && account.Spec.Type === activeCloudType) {
          items.push({
            label: getResourceName(account),
            value: k
          })
        }
      }
    })

    return items
  }

  useEffect(() => {
    if (viewMode === 'user') startApprovalPolling()
  }, [slices.approvalReqList])

  useEffect(() => {
    selectDispatch([
      'serverList',
      'appRolesList',
      'databases',
      'approvalReqList',
      'policyList',
      'serviceAccounts',
      'rdpServers',
      'kubeClusters',
      'kafkas',
      'tenantprofiles',
      'serverControllers'
    ])
  }, [])

  return (
    <div>
      <ResourcesViewProvider cloudType={activeCloudType} dropDownItems={getDropDownItems()}>
        <ResourcesView
          render4Cards
          resources={filteredTargets}
          cloudOptions={cloudTypesWP}
          activeCloudType={activeCloudType}
          setActiveCloudType={setActiveCloudType}
          accountID={createRsrcKey(cartResourceAccount)}
          accountType={cartResourceAccount.Type}
          disableAccountControls={cartItems.length}
          renderCardComponent={renderCardComponent}
          FilterComponent={() => (
            <TargetsFilters
              showManagedFilters={activeCloudType !== 'PRIVATE' && viewMode === 'admin'}
              bookmarkCount={counts.bookmarked}
              grantedCount={counts.granted}
              unGrantedCount={counts.ungranted}
              managedCount={counts.managed}
              unmanagedCount={counts.unmanaged}
              activeFilters={activeCheckboxFilters}
              onActiveFilterChange={setActiveCheckboxFilters}
            />
          )}
          HeaderFilter={
            <>
              {activeCloudType === 'PRIVATE' && (
                <SelectDropDown
                  showHelpText={false}
                  className='ml-6'
                  width='307px'
                  onChange={(e) => setSelectedTag(e.target.value)}
                  menuItems={[
                    {
                      label: 'All',
                      value: ''
                    },
                    {
                      label: <em>Without Tags</em>,
                      value: '-'
                    },
                    ...getTagsDropdownOptions()
                  ]}
                  value={selectedTag}
                />
              )}
            </>
          }
        />
      </ResourcesViewProvider>
      {showDeletePrivateServerModal && server && (
        <DeletePrivateServerModal
          server={server}
          onCancel={() => setShowDeletePrivateServerModal(false)}
          onSuccess={() => {
            setServer(null)
            setShowDeletePrivateServerModal(false)
          }}
        />
      )}

      {showK8SingInModal && (
        <K8ContextModal rsrc={kube8SignInUrl} onClose={() => setShowK8SingInModal(false)} />
      )}
    </div>
  )
}

export { TargetsView }
