import { UserGroupBar } from 'procyon-ui'
import React from 'react'

const KindToTypeMap = {
  User: 'USER',
  Group: 'USERGROUPS',
  ServiceAccount: 'SERVICEACCOUNT',
  PacPolicy: 'POLICY',
  WorkloadIdentity: 'WORKLOAD'
}

/**
 *
 * @param {{ kind: 'User' | 'Group' | 'ServiceAccount' | 'PacPolicy' } & Omit<import('procyon-ui').UserGroupBarProps, 'type'> } props
 * @returns
 */
function EntityInfoBar(props) {
  //@ts-ignore
  return <UserGroupBar {...props} type={KindToTypeMap[props.kind]} />
}

export { EntityInfoBar }
