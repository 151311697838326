import axios from 'axios'
import localforage from 'localforage'
import { useEffect, useState } from 'react'
import { createDataSelectorHook } from 'infra/redux/index.js'
import equal from 'fast-deep-equal'
import { enqueueNotification } from 'Utils/Helpers.js'
import { isCloudTypeSupportedIAMAction } from '../utils'

let IAM_ROLES_MAP_TEMP = {}
const useSlices = createDataSelectorHook(['iamActions'])

export const useIAMRolesJSONMap = () => {
  const [iamrolesMap, setIamrolesMap] = useState(IAM_ROLES_MAP_TEMP)

  const { slices } = useSlices()

  if (!Object.keys(IAM_ROLES_MAP_TEMP).length && iamrolesMap) {
    IAM_ROLES_MAP_TEMP = { ...iamrolesMap }
  }

  const fetchJson = async () => {
    let json = null
    try {
      const localCache = await localforage.getItem('iamrolesmap')
      json = JSON.parse(localCache)

      if (json) setIamrolesMap(json)

      const response = await axios.get(
        'https://procyon-gcp-roles.s3.us-west-2.amazonaws.com/gcpRoleMap.json'
      )

      if (!equal(response.data, localCache)) {
        await localforage.setItem('iamrolesmap]', JSON.stringify(response.data))
        setIamrolesMap(response.data)
      }
    } catch (error) {
      enqueueNotification('There was an error fetching IAM Roles map.', 'error')
    }
  }

  const getSupportedIAMActions = (rsrcType, cloudType) => {
    if (!isCloudTypeSupportedIAMAction(cloudType)) return slices.iamActions
    const actions = iamrolesMap[rsrcType]

    if (!actions) return slices.iamActions
    const { user } = actions
    if (!user) return slices.iamActions

    const iamActions = slices.iamActions.filter((action) => {
      const { RoleName } = action.Spec
      if (user.includes(RoleName)) return true
    })

    return iamActions
  }

  useEffect(() => {
    fetchJson()
  }, [])

  return { iamrolesMap, getSupportedIAMActions }
}
