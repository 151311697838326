import React, { useEffect, useRef, useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import _ from 'lodash'
import { Button, IconButton, Label, LabelCheckbox, TextInput } from 'procyon-ui'
import { createDataSelectorHook } from 'infra/redux'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { reduxApiClient } from 'infra'
import {
  AppAccountID,
  AppNameKey,
  AppPrivateKey,
  AppSAML,
  AppSignOnType,
  AppUserName
} from 'features/applications/utils'
import { faPaste, faUpload } from '@fortawesome/free-solid-svg-icons'

function SnowFlakeApplicationForm({ onCancel, onComplete }) {
  const { isEditMode, app } = useApplicationEditContext()
  const [isLoading, setIsLoading] = useState(false)
  const [accountId, setAccountId] = useState(_.get(app, AppAccountID, ''))
  const [userName, setUserName] = useState(_.get(app, AppUserName, ''))
  const [privateKey, setPrivateKey] = useState(_.get(app, AppPrivateKey, ''))
  const [applicationName, setApplicationName] = useState(_.get(app, AppNameKey, ''))
  const useSlices = createDataSelectorHook(['applicationList'])
  const [isChecked, setIsChecked] = useState(_.get(app, AppSAML, false))
  const [enableSSO, setEnableSSO] = useState(_.get(app, AppSAML, false))
  const [signOnType, setSignOnType] = useState(_.get(app, AppSignOnType, ''))
  const { slices } = useSlices()
  const inputFileRef = useRef(null)

  const handleButtonClick = () => {
    inputFileRef.current.click()
  }

  useEffect(() => {
    setEnableSSO(isChecked ? true : false)
    setSignOnType(isChecked ? 'saml' : '')
  }, [isChecked])

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: applicationName } })) {
      return 'Application name is already taken!'
    }
    if (applicationName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!accountId) return 'Account ID must not be empty.'
    if (!privateKey) return 'Private key must not be empty'
    if (!userName) return 'User Name must not be empty'
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    let updatedPrivateKey = privateKey
    if (!privateKey.startsWith('"')) {
      updatedPrivateKey = privateKey
    }

    if (!privateKey.endsWith('"')) {
      updatedPrivateKey = privateKey
    }

    try {
      const data = {
        applicationName,
        accountId,
        userName,
        updatedPrivateKey,
        enableSSO,
        signOnType
      }
      if (isEditMode) {
        const app = await handleSFApplicationUpdate(data)
        updateSliceData(app)
      } else {
        const app = await handleSFApplicationCreate(data)
        reduxApiClient('applications').getAll()
        updateSliceData(app)
      }
      enqueueNotification(
        `Successfully ${isEditMode ? 'Updated' : 'Created'} SnowFlake Application!`,
        'info'
      )
      onComplete?.()
    } catch (error) {
      enqueueNotification(
        `Failed to ${isEditMode ? 'Update' : 'Create'} SnowFlake Application!`,
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleSFApplicationCreate = async ({
    applicationName,
    accountId,
    userName,
    updatedPrivateKey
  }) => {
    const data = {
      ObjectMeta: {
        Name: applicationName
      },
      Spec: {
        AppType: 'snowflake',
        SignOnType: isChecked ? 'saml' : '',
        SnowflakeAppConfig: {
          AccountID: accountId,
          UserName: userName,
          PrivateKey: updatedPrivateKey,
          EnableSSO: isChecked
        }
      }
    }
    return await reduxApiClient('applications').create(data)
  }

  const handleSFApplicationUpdate = async (data) => {
    const payload = structuredClone(app)
    _.set(payload, AppAccountID, data.accountId)
    _.set(payload, AppUserName, data.userName)
    _.set(payload, AppPrivateKey, data.updatedPrivateKey)
    _.set(payload, AppSAML, data.enableSSO)
    _.set(payload, AppSignOnType, data.signOnType)
    return await reduxApiClient('applications').update(payload)
  }

  const updatedPrivateKey = (value) => {
    const filterValue = value.replaceAll('\\n', '\n')
    setPrivateKey(filterValue)
  }

  const handlePaste = async () => {
    try {
      const clipText = await navigator.clipboard.readText()
      console.log(clipText)
      updatedPrivateKey(clipText)
    } catch (error) {
      enqueueNotification('Failed to paste from clipboard!', error)
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const fileContent = e.target.result
        setPrivateKey(fileContent)
        inputFileRef.current.value = ''
      }
      reader.readAsText(file)
    }
  }

  const formErrors = getErrors()
  return (
    <div>
      <div className='grid gap-2'>
        <TextInput
          placeholder='Application Name'
          disabled={isEditMode}
          value={applicationName}
          onChange={(e) => setApplicationName(e.target.value)}
          sx={{ width: '100%' }}
          label='Application Name *'
        />

        <TextInput
          value={userName}
          placeholder='Login Name'
          onChange={(e) => setUserName(e.target.value)}
          sx={{ width: '100%' }}
          label='User Name *'
        />

        <TextInput
          value={accountId}
          placeholder='Org Name - Account Name'
          onChange={(e) => setAccountId(e.target.value)}
          sx={{ width: '100%' }}
          label='Account ID *'
        />

        <div className='relative'>
          <TextInput
            value={privateKey}
            onChange={(e) => updatedPrivateKey(e.target.value)}
            sx={{ width: '100%', paddingY: '10px', marginBottom: '10px' }}
            label='Private Key *'
            type={isEditMode ? 'password' : 'text'}
          />

          <div className='absolute top-[-5px] right-2 flex gap-2'>
            <div className='file-upload'>
              <IconButton
                title='Upload'
                icon={faUpload}
                variant='gray'
                onClick={handleButtonClick}
              />
              <input
                type='file'
                accept='.p8,.pem'
                ref={inputFileRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </div>
            <IconButton title='Paste' onClick={handlePaste} icon={faPaste} variant='gray' />
          </div>
        </div>

        <div>
          <LabelCheckbox
            checked={isChecked}
            name='Enable SSO'
            onClick={() => setIsChecked(!isChecked)}
            size='medium'
          />
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback
        caption='Please wait..'
        loading={isLoading}
        message='Creating SnowFlake Application'
      />
    </div>
  )
}

export { SnowFlakeApplicationForm }
