import React, { useCallback, useEffect, useState } from 'react'
import { AddTeam, Headers, deleteUser } from 'features/github'
import useAppView from 'Core/Hooks/useAppView'
import { cn } from 'Utils/Helpers'
import { GithubUserRow, TargetIcon, TeamsRow } from 'procyon-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiProvider } from 'Core'
import * as Styles from '../../../styles/styles'
import { useHistory, useParams } from 'react-router'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { FullScreenContentModal } from 'V2Components'
import _ from 'lodash'
import { DeleteUserModal } from 'features/github'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { reduxApiClient } from 'infra'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

const TeamsandGroups = ({ showAddButton, isRepo }) => {
  const history = useHistory()
  const { slices, selectDispatch } = useMultiSlice([
    'githubResources',
    'userList',
    'githubAccount',
    'groupList'
  ])
  const [searchKey, setSearchKey] = useState('')
  const { appView } = useAppView()
  const [githubTeam, setGithubTeam] = useState([])
  const [teamCount, setTeamCount] = useState(0)
  const [usersList, setUsersList] = useState([])
  const [showAddTeamModal, setShowAddTeamModal] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [teamName, setTeamName] = useState('')
  const [teamDescription, setTeamDescription] = useState('')
  const [error, setError] = useState('')
  const [selectedEntities, setSelectedEntities] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [membersList, setMembersList] = useState([])
  const [currentAccountObj, setCurrentAccountObj] = useState({})
  const [updatedGitAccountData, setUpdatedGitAccountData] = useState({})
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [updateMemberListState, setUpdateMemberListState] = useState(false)
  const [teamListData, setTeamListData] = useState([])
  const [userListData, setUserListData] = useState([])
  const [initialUsersList, setInitialUsersList] = useState([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [teamSearchKey, setTeamSearchKey] = useState('')
  const params = useParams()
  const canGoBack = useCanGoBack()

  useEffect(() => {
    selectDispatch(['githubResources', 'userList', 'githubAccount'])
    initGithubResources()
  }, [])

  const handleAddApplicationClick = (value) => {
    if (value === 'user') {
      setShowAddUserModal(true)
    } else {
      setShowAddTeamModal(true)
    }
  }

  useEffect(() => {
    handleSearchKeyUpdate()
  }, [searchKey])

  useEffect(() => {
    handleTeamSearchKeyUpdate()
  }, [teamSearchKey])

  const initGithubResources = async () => {
    let matchedUsers = []
    let currentAccount = {}
    if (isRepo) {
      const currentAccountRepo = _.find(slices.githubResources, {
        ObjectMeta: { Name: params.appName }
      })
      currentAccount = _.find(slices.githubAccount, {
        ObjectMeta: { ID: currentAccountRepo?.Spec?.Account?.RefID }
      })
      const refIds = currentAccount?.Spec?.Members?.Members.map((member) => member.memberRef.RefID)
      matchedUsers = slices?.userList?.filter((obj) => refIds?.includes(obj?.ObjectMeta?.ID))
      setInitialUsersList(currentAccountRepo?.Spec?.Members?.Members)
      generateUserRowComponent(currentAccountRepo?.Spec?.Members?.Members)
      setUsersList(matchedUsers)
    } else {
      currentAccount = _.find(slices.githubAccount, { ObjectMeta: { Name: params.appName } })
      setInitialUsersList(currentAccount?.Spec?.Members?.Members)
      generateUserRowComponent(currentAccount?.Spec?.Members?.Members)
      setUsersList(slices.userList)
    }
    setCurrentAccountObj(currentAccount)
    let filterTeam = slices.githubResources?.filter(
      (obj) => obj.Spec.Type === 'team' && obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID
    )
    setTeamCount(filterTeam?.length)
    setGithubTeam(filterTeam)
    setTeamListData(filterTeam)
    setIsLoading(false)
  }

  const handleTeamEdit = (name) => {
    history.push(`/${appView}/team-details/${encodeURIComponent(name)}`)
  }

  const handleModalClose = () => {
    setShowAddTeamModal(false)
    setShowAddUserModal(false)
  }

  const handleSearchKeyUpdate = () => {
    if (!isInitialLoad) {
      if (searchKey !== '') {
        const matchingObject = membersList.filter((item) => {
          const fullName = item.Spec.FullName.toLowerCase()
          const name = item.Spec.FirstName.toLowerCase()
          const keyword = searchKey.toLowerCase()
          return fullName.includes(keyword) || name.includes(keyword)
        })
        generateUserRowComponent(matchingObject)
        setUserListData(matchingObject)
      } else {
        setUserListData(initialUsersList)
        generateUserRowComponent(initialUsersList)
      }
    } else {
      setIsInitialLoad(false)
    }
  }

  const handleTeamSearchKeyUpdate = () => {
    if (!isInitialLoad) {
      if (teamSearchKey !== '') {
        const matchingObject = githubTeam.filter((item) => {
          const name = item.Spec.TeamSpec.Name.toLowerCase()
          const keyword = teamSearchKey.toLowerCase()
          return name.includes(keyword)
        })
        setTeamListData(matchingObject)
      } else {
        setTeamListData(githubTeam)
      }
    } else {
      setIsInitialLoad(false)
    }
  }

  const handleCreateTeam = async () => {
    let dataObj = {
      ObjectMeta: {
        Name: teamName.replace(/ /g, '_'),
        Namespace: 'default',
        Tenant: currentAccountObj?.ObjectMeta.Tenant
      },
      Spec: {
        Type: 'team',
        TeamSpec: {
          Name: teamName.replace(/ /g, '_'),
          Slug: teamName.replace(/ /g, '_'),
          defaultBranch: 'master',
          Description: teamDescription
        },
        ManagedBy: 'procyon',
        Account: {
          RefKind: currentAccountObj?.ObjectMeta?.Kind,
          RefID: currentAccountObj?.ObjectMeta?.ID
        }
      },
      Status: {
        Status: 'pending'
      }
    }

    try {
      let response = await reduxApiClient('githubresources').create(dataObj)
      setShowAddTeamModal(false)
      handleTeamEdit(response?.ObjectMeta?.Name)
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = (label, value) => {
    if (label === 'name') {
      setTeamName(value)
    } else {
      setTeamDescription(value)
    }
  }

  const handleEntityModalContinue = async () => {
    setShowAddUserModal(false)
    const meta_info_array = selectedEntities.map((obj) => ({
      ID: obj.ObjectMeta.ID,
      Kind: obj.ObjectMeta.Kind
    }))

    const newMemberArray = meta_info_array.map((item) => ({
      memberRef: { RefKind: item.Kind, RefID: item.ID },
      ManagedBy: 'procyon',
      Status: 'pending'
    }))

    const newMembersObject = {
      ...(currentAccountObj.Spec.Members || {}), // Include existing properties if they exist
      Members: Array.isArray(currentAccountObj.Spec.Members?.Members)
        ? [...currentAccountObj.Spec.Members.Members, ...newMemberArray]
        : newMemberArray
    }

    // Update the githubAccountData object
    const updatedGithubAccountData = {
      ...currentAccountObj,
      Spec: {
        ...currentAccountObj.Spec,
        Members: newMembersObject
      },
      Sync: true
    }

    try {
      let response = await new ApiProvider('github-account')
        .setInstance(updatedGithubAccountData)
        .put()
      // start polling git account till sync status in false
      await gitAccountPolling()
      generateUserRowComponent(response.data.Spec.Members.Members)
      setSelectedEntities([])
    } catch (error) {
      console.log(error, 'add member error')
    }
  }

  const generateUserRowComponent = (data) => {
    const matchingObjects = []
    if (slices.userList && slices.userList.length > 1) {
      for (const item of data) {
        const matchingObject = slices.userList.find(
          (obj) =>
            (obj.ObjectMeta.ID === item?.memberRef?.RefID && item?.Status !== 'delete') ||
            item?.ObjectMeta?.ID
        )
        if (matchingObject) {
          const newObj = {
            ...matchingObject,
            Status: item?.ScimProvisonedIdentity !== '' ? 'Member' : 'Pending'
          }
          matchingObjects.push(newObj)
        }
      }
    }
    setMembersList(matchingObjects)
    setUserListData(matchingObjects)
    setUpdateMemberListState(true)
  }

  const gitAccountPolling = async () => {
    let count = 0

    const intervalID = setInterval(() => {
      selectDispatch(['githubAccount'])
      count++

      if (count >= 3) {
        clearInterval(intervalID)
      }
    }, 2000)
  }

  const handleDeleteUser = async (app) => {
    const modifiedJSON = JSON.parse(JSON.stringify(currentAccountObj)) // Create a deep copy

    // Loop through the Members array and update the status
    if (modifiedJSON.Spec && modifiedJSON.Spec.Members && modifiedJSON.Spec.Members.Members) {
      modifiedJSON.Spec.Members.Members.forEach((member) => {
        if (member.memberRef && member.memberRef.RefID === app.ObjectMeta.ID) {
          member.Status = 'delete' // Change the status to "delete"
        }
      })
    }

    // Update the githubAccountData object
    const updatedGithubAccountData = {
      ...modifiedJSON,
      Sync: true
    }

    setUpdatedGitAccountData(updatedGithubAccountData)
    setShowDeleteUserModal(true)
  }

  return (
    <div className='flex gap-8'>
      {/* Hide the border in user view, because the other div which shows owners won't be shown */}
      <div
        className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8', {
          'border-none': appView === 'user'
        })}
      >
        <Headers
          handleAddApplicationClick={() => handleAddApplicationClick('team')}
          searchKey={teamSearchKey}
          setSearchKey={setTeamSearchKey}
          showToggle={false}
          showAddButton={isRepo ? false : true}
          buttonLabel={'Create Team'}
          title={'Teams'}
          count={teamCount}
        />

        <div className='team-row' style={{ marginTop: '20px' }}>
          {teamListData?.map((item) => (
            <TeamsRow
              caption={item.Spec.TeamSpec.Description}
              onClickEdit={() => handleTeamEdit(item.Spec.TeamSpec.Name)}
              onClickRepositoryButton={function noRefCheck() {}}
              onClickUsers={function noRefCheck() {}}
              repositoryCount={0}
              showRepositoryButton={false}
              title={item.Spec.TeamSpec.Name}
              usersCount={item.Spec.Members.Members.length}
              width='100%'
            />
          ))}
        </div>
      </div>
      {/* 
             Hide owners on user view
         */}
      {appView === 'admin' && (
        <div className='w-1/2'>
          <Headers
            handleAddApplicationClick={() => handleAddApplicationClick('user')}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            showToggle={false}
            showAddButton={isRepo ? false : true}
            buttonLabel={'Add User'}
            title={''}
            count={''}
          />

          <div className='user-row' style={{ marginTop: '20px', marginBottom: '30px' }}>
            {userListData ? (
              userListData.map((item) => (
                <GithubUserRow
                  dropdownMenuItems={[
                    {
                      submenu: [
                        {
                          action: () => handleDeleteUser(item),
                          title: 'Delete'
                        }
                      ],
                      title: <FontAwesomeIcon icon={faEllipsisVertical} />
                    }
                  ]}
                  onClickRepositories={function noRefCheck() {}}
                  onClickUserGroups={function noRefCheck() {}}
                  showControlButtons={!isRepo}
                  userEmail={item?.Spec?.EmailID}
                  userName={item?.Spec?.FullName}
                  permissions={[item?.Status]}
                />
              ))
            ) : (
              <Styles.ActivePolicyHolder>
                <div className='icon-wrapper'>
                  <TargetIcon type='USER' />
                </div>
                <p>No Users in this {isRepo ? 'repository' : 'account'}</p>
              </Styles.ActivePolicyHolder>
            )}
          </div>
        </div>
      )}

      {showAddTeamModal && (
        <FullScreenContentModal>
          <Styles.TeamModal>
            <div className='header'>
              <p>Add New Team to Github Account</p>
              <span className='close-btn' onClick={handleModalClose}>
                X Close
              </span>
            </div>

            <p>
              Teams are a great way for groups of people to communicate and work on code together.
            </p>
            <div className='form-wrapper'>
              <span>
                <label htmlFor='name'>Name</label>
                <input
                  type='text'
                  id='name'
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </span>

              <span>
                <label htmlFor='description'>Team Description</label>
                <textarea
                  rows={5}
                  cols={10}
                  id='description'
                  onChange={(e) => handleInputChange('description', e.target.value)}
                />
              </span>
            </div>
            <div className='button-wrapper'>
              <span onClick={handleModalClose}>Cancel</span>
              <span className='save-btn' onClick={handleCreateTeam}>
                Save
              </span>
            </div>
            <div className='error'>
              <p>{error}</p>
            </div>
          </Styles.TeamModal>
        </FullScreenContentModal>
      )}

      {showAddUserModal && (
        <FullScreenContentModal width='auto'>
          <AddTeam
            title={`Add users to Github Account`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={usersList}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              setShowAddUserModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}

      {/* delete user modal */}

      {showDeleteUserModal && (
        <DeleteUserModal
          cleanUpFn={() => {
            if (canGoBack) setShowDeleteUserModal(false)
            else setShowDeleteUserModal(false)
          }}
          onCancel={() => setShowDeleteUserModal(false)}
          app={updatedGitAccountData}
          name={''}
        />
      )}
    </div>
  )
}

export { TeamsandGroups }
