import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { openURLSafely } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal, PaginationView } from 'V2Components'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  getApplicationSignInURL,
  getApplicationType,
  isReadOnlyApp,
  useApplicationAccess
} from 'features/applications'
import {
  AccessCartProvider,
  createRef,
  createRsrcKey,
  getResourceName,
  getResourcePolicyTags,
  useAccessCartProvider
} from 'features/resources'
import { ApplicationCard, TargetIcon } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { Header } from './Components'
import { useHistory } from 'react-router'
import { useUser } from 'Core/Hooks/useUser'
import { getIAMResourceDisplayType } from 'features/iamResources'
import _ from 'lodash'

import { CheckboxFilter } from './Components/CheckboxFilter'
import { AddCloudStore } from '../AddCloudStore'
import { createDataSelectorHook } from 'infra/redux'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

const appTypes = ['snowflake', 'databricks']

const useSlices = createDataSelectorHook([
  'applicationList',
  'accountList',
  'snowFlakeAccounts',
  'snowFlakeRoles'
])

function CloudStoreList() {
  const { slices } = useSlices()
  const { rdpurl, isAgentlessMode } = useUser()
  const [searchKey, setSearchKey] = useState('')
  const [selectedTag, setSelectedTag] = useState('')
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)
  const [showAddApplication, setShowAddApplication] = useState(false)
  const { getObjectRef: getRsrcsRef } = useMultiSlice(['serviceAccounts', 'applicationList'])
  const [activeCheckboxFilters, setActiveCheckboxFilters] = useState([])
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const history = useHistory()

  const { getApplicationRsrcsAccessMap } = useApplicationAccess()
  const { appView } = useAppView()
  const accessObject = getApplicationRsrcsAccessMap()

  const { isResourceInCart, isCartEmpty, cartItems } = useAccessCartProvider()

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const getTagsDropdownOptions = () => {
    const options = new Set()

    slices.applicationList.forEach((s) => {
      const tags = getResourcePolicyTags(s)
      if (tags.hasOwnProperty('appgrp')) {
        const { appgrp, ...rest } = tags
        // Split the comma-separated values
        const values = appgrp.split(',')
        // Add unique values to the set
        values.forEach((value) => {
          options.add(value.trim())
        })
      }
    })

    return [...options].map((value) => ({ label: value, value: value }))
  }

  const handleAccessClick = (app) => {
    if (app.Spec.AppType === 'snowflake') {
      return history.push(
        `/${appView}/datacloud/${encodeURIComponent(app.ObjectMeta.Name)}/${encodeURIComponent(
          'roles'
        )}`
      )
    } else {
      return history.push(
        `/${appView}/datacloud/workspace/${encodeURIComponent(
          app.ObjectMeta.Name
        )}/${encodeURIComponent('workspaces')}`
      )
    }
  }

  const getAppAccessState = (app) => {
    let accessState = 'access'
    if (accessObject[createRsrcKey(app)] || appView === 'admin') accessState = 'signIn'
    //@ts-ignore
    if (isResourceInCart(createRef(app))) accessState = 'requested'

    if (appView === 'user') {
      const snowflakeRole = accessObject && Object.keys(accessObject).map((obj) => obj.split('+'))

      if (snowflakeRole) {
        snowflakeRole.find((role) => {
          const roleObj = slices.snowFlakeRoles.find((snRole) => snRole.ObjectMeta.ID === role[1])

          const snAccount =
            roleObj &&
            slices.snowFlakeAccounts.find(
              (snAccount) => snAccount.ObjectMeta.ID === roleObj?.Spec?.SnowflakeAccount?.RefID
            )
          const snApp =
            snAccount &&
            slices.applicationList.find(
              (apps) => apps.ObjectMeta.ID === snAccount?.Application?.RefID
            )

          if (createRsrcKey(snApp) === createRsrcKey(app)) {
            accessState = 'signIn'
          }
        })
      }
    }

    return accessState
  }

  const getDotMenuItems = ({ app, isAppGranted }) => {
    const items = []
    const existingCartItemsappType = []
    if (isCartEmpty && app.Spec.AppType !== 'http') {
      if (appView === 'admin' && app.Spec.AppType !== 'github') {
        items.push({
          title: 'Create Policy',
          action: () => handleAccessClick(app)
        })
      }

      if (appView === 'user' && !isAppGranted) {
        items.push({
          title: 'Create Request',
          action: () => handleAccessClick(app)
        })
      }
    } else {
      cartItems.forEach(({ Resource }) => {
        let rsrc = null
        rsrc = getRsrcsRef(Resource)
        if (!rsrc) return
        const appType = getIAMResourceDisplayType(rsrc)
        existingCartItemsappType.push(appType)
      })
    }

    if (appView == 'admin') {
      items.push(
        {
          title: 'Edit',
          action: () => {
            setSelectedApplication(app)
            setShowEditAppModal(true)
          }
        },
        {
          title: <span className='text-red-600'>Delete</span>,
          action: () => {
            setSelectedApplication(app)
            setShowDeleteAppModal(true)
          }
        }
      )
    }

    const isCreatePolicyExist = items.some(
      (item) => item.title === 'Create Policy' && appView === 'admin'
    )

    if (!isCreatePolicyExist && app.Spec.AppType !== 'github') {
      items.push({
        title: 'Sign In',
        action: () => {
          getSingInUrlWithAgentlessCheck(app)
        }
      })
    }

    return items
  }

  const isSearchMatch = (app) => {
    if (searchKey) {
      const data = `${getResourceName(app)}${getApplicationType(app)}`
      if (data.toLowerCase().includes(searchKey)) return true
      else return false
    }
    return true
  }

  const getFilteredApps = () => {
    let filteredList = slices.applicationList.filter((app) => appTypes.includes(app?.Spec?.AppType))

    if (activeCheckboxFilters.length !== 0) {
      let matchedFilter = filteredList.filter((app) =>
        activeCheckboxFilters.includes(app.Spec.AppType)
      )
      return matchedFilter.filter(isSearchMatch)
    }

    return filteredList.filter((app) => {
      if (!isSearchMatch(app)) return false
      // Filter by tag
      if (selectedTag) {
        const { appgrp } = getResourcePolicyTags(app)
        const spliteTags = appgrp?.split(',')
        const tagMatch = spliteTags?.some((tag) => tag.trim() === selectedTag.trim())
        if (tagMatch === '-') return
        if (!tagMatch) return
      }
      return true
    })
  }

  const getIcon = (app) => {
    return <TargetIcon type={app.Spec.AppType.toUpperCase()} />
  }

  const renderApplicationCardFunction = (app) => {
    const serviceType = getApplicationType(app)
    const data = {
      name: getResourceName(app),
      accessState: getAppAccessState(app),
      serviceType,
      resourceType: serviceType,
      key: createRsrcKey(app),
      isReadOnly: isReadOnlyApp(app),
      dotMenuItems: getDotMenuItems({ app, isAppGranted: getAppAccessState(app) === 'signIn' }),
      onAccessClick: () => handleAccessClick(app),
      app
    }

    //@ts-ignore
    return (
      <ApplicationCard
        onClickSignIn={() => getSingInUrlWithAgentlessCheck(data.app)}
        onClickMoreInfo={() => handleMoreInfoClick(data.app)}
        onClickPolicy={data.onAccessClick}
        onClickRequest={data.onAccessClick}
        forPolicy={appView === 'admin'}
        isReadOnly={data.isReadOnly}
        width='100%'
        key={data.key}
        //@ts-ignore
        accessState={data.accessState}
        dotMenuItems={
          data.dotMenuItems.length
            ? [
                {
                  icon: faEllipsisVertical,
                  submenu: data.dotMenuItems,
                  title: ''
                }
              ]
            : []
        }
        resourceName={data.name}
        resourceType={data.resourceType}
        //@ts-ignore
        serviceType={ServiceTypeIconMap[data.serviceType]}
        customIcon={getIcon(data.app)}
      />
    )
  }

  const handleMoreInfoClick = (app) => {
    if (app.Spec.AppType === 'databricks') {
      history.push(`/${appView}/datacloud/workspace/${encodeURIComponent(app.ObjectMeta.Name)}`)
    } else {
      history.push(`/${appView}/datacloud/${encodeURIComponent(app.ObjectMeta.Name)}`)
    }
  }

  const handleAddApplicationClick = () => setShowAddApplication(true)

  const getSingInUrlWithAgentlessCheck = (app) => {
    let url = getApplicationSignInURL(app)

    if (isAgentlessMode) {
      url = url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
      url && openURLSafely(url)
    } else {
      url && openURLSafely(url)
    }
  }

  return (
    <div className='flex justify-between h-[100vh]'>
      <div className='w-[20%] bg-[#f9fbfc] pl-5'>
        <CheckboxFilter
          activeFilters={activeCheckboxFilters}
          onActiveFilterChange={setActiveCheckboxFilters}
        />
      </div>
      <div className='w-[73%]'>
        <Header
          onAddApplicationClick={handleAddApplicationClick}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          tags={getTagsDropdownOptions()}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />

        <PaginationView
          itemsCount={40}
          data={getFilteredApps()}
          renderFunction={renderApplicationCardFunction}
          WrapperComponent={({ children }) => (
            <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid gap-8 mt-4'>
              {children}
            </div>
          )}
        />
      </div>

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            setSelectedApplication(null)
            setShowDeleteAppModal(false)
          }}
          app={selectedApplication}
        />
      )}

      {showAddApplication && (
        <FullScreenContentModal>
          <AddCloudStore
            type=''
            onComplete={() => setShowAddApplication(false)}
            onClose={() => setShowAddApplication(false)}
          />
        </FullScreenContentModal>
      )}

      {showEditAppModal && (
        <FullScreenContentModal>
          <AddCloudStore
            type=''
            onComplete={() => setShowEditAppModal(false)}
            app={selectedApplication}
            onClose={() => setShowEditAppModal(false)}
          />
        </FullScreenContentModal>
      )}
    </div>
  )
}

const WrappedApplicationsList = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <AccessCartProvider cartKey={cartKey}>
      <CloudStoreList />
    </AccessCartProvider>
  )
}

const ServiceTypeIconMap = {
  SNOWFLAKE: 'snowflake',
  DATABRICKS: 'databricks'
}

export { WrappedApplicationsList as CloudStoreList }
