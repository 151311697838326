import { SLICENAME_TO_ENDPOINT_MAP, reduxApiClient } from 'infra/dataFetching'

/**
 * * useSliceFetch is a hook responsible for auto-population of data in redux
 * @param {import("types").SliceNames[]} sliceNames
 * @param {{
 *  disableFetch: boolean,
 *  apiOptions?: import('infra/api').GetAllApiOptions
 * }=} options
 */
export const useReduxFetch = (sliceNames, options) => {
  if (options && options.disableFetch) return
  sliceNames.forEach((sliceName) => {
    const endpoint = SLICENAME_TO_ENDPOINT_MAP[sliceName]
    const isFetched = FETCHED_SLICENAMES_MAP[sliceName]
    //Check if the slice has been fetched before
    if (!isFetched && !['awsResources', 'gcpResources', 'azureResources'].includes(sliceName)) {
      // Create a redux api client for this
      const rexClient = reduxApiClient(endpoint)
      // Get all data and populate redux
      rexClient.getAll(options?.apiOptions)
      // Update the maps
      API_CLIENT_REF_MAP[sliceName] = rexClient
      // Update fetched for once called slices
      FETCHED_SLICENAMES_MAP[sliceName] = true
    }
  })
}

const API_CLIENT_REF_MAP = {}

const FETCHED_SLICENAMES_MAP = {}
