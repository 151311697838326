import { faPen } from '@fortawesome/pro-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityInfoBar, SearchInput } from 'V2Components'
import { getResourceName } from 'features/resources'
import { ManageUsersGroups, getUserGroups } from 'features/users'
import _ from 'lodash'
import { Button, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

function UserGroups({ user }) {
  const [showManageGroupsModal, setShowManageGroupsModal] = useState(false)
  const [searchKey, setSearchKey] = useState('')

  const { dispatchThunks, slices } = useMultiSlice(['groupList'])

  const userGroups = (() => {
    const groups = getUserGroups(user, slices.groupList)
    return groups.filter((g) => {
      if (searchKey) return getResourceName(g).toLowerCase().includes(searchKey)
      else return true
    })
  })()

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>Groups</Typography>
        <div className='flex gap-4'>
          <SearchInput sx={{ width: '275px' }} searchKey={searchKey} onChange={setSearchKey} />
          <Button
            onClick={() => setShowManageGroupsModal(true)}
            icon={faPen}
            size='md'
            variant='grayBlue'
          >
            Manage Groups
          </Button>
        </div>
      </div>
      <div>
        <Typography className='my-4' variant='body-regular'>
          {userGroups.length} Groups
        </Typography>
        {userGroups.map((g) => (
          <EntityInfoBar
            endButton={false}
            kind='Group'
            name={getResourceName(g)}
            caption={`${g.Spec.Users.ObjectRef.length} Users`}
          />
        ))}
      </div>
      {showManageGroupsModal && (
        <ManageUsersGroups
          user={user}
          onCancel={() => setShowManageGroupsModal(false)}
          onSuccess={() => setShowManageGroupsModal(false)}
        />
      )}
    </div>
  )
}

export { UserGroups }
