import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { deleteWorkload } from 'features/workloads'
import React from 'react'

function DeleteWorkloadModal({ workload, onCancel, onSuccess }) {
  const workloadName = getResourceName(workload)

  const handleDelete = async () => {
    try {
      await deleteWorkload(workload)
      deleteSliceData(workload)
      onSuccess?.()
      enqueueNotification('Workload deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete workload: ${workloadName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={workload} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteWorkloadModal }
