import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createTagPolicy } from 'features/settings'
import { Button, IconButton, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { INITIAL_FORM, ResourceKinds, customTagKeys } from 'features/settings'


// Resource kinds which need their own custom key value tag
const CUSTOM_RSRCS_KINDS = []

const TagPolicyCreateModal = ({ onCancel, onSuccess }) => {
  const [form, setForm] = useState(INITIAL_FORM)

  const [selectedResourceKinds, setSelectedResourceKinds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [customTags, setCustomTags] = useState([])

  const handleAddCustomTagClick = () => {
    setCustomTags((s) => [
      {
        id: Math.floor(Math.random() * 100000),
        key: '',
        value: ''
      },
      ...s
    ])
  }

  const handleCustomTagDeleteClick = (id) => {
    setCustomTags((s) => s.filter((e) => e.id !== id))
  }

  const handleCustomTagKeyValueUpdate = (id, key = '', value = '') => {
    setCustomTags((s) =>
      s.map((e) => {
        if (e.id === id) return { id, key, value }
        return e
      })
    )
  }

  /**
   * @param {Partial<typeof INITIAL_FORM>} data
   */
  const handleFormUpdate = (data) => {
    setForm((s) => ({ ...s, ...data }))
  }

  const getErrors = () => {
    try {
      new RegExp(form.regex)
      if (!form.name) return 'Tag Name is required and Special characters are not allowed'
      if (!form.regex) return 'RegEx pattern is required.'
      if (customTags.some((e) => !e.key.trim() || !e.value.trim()))
        return 'Key and Value is required'
      return ''
    } catch (error) {
      return 'Invalid RegEx pattern.'
    }
  }

  const errors = getErrors()

  const disableButton = () => {
    if (errors) return true
    if (!customTags.length && !form.serverGroup && !form.gatewayGroup && !form.appGroup) return true
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const payload = {
        ObjectMeta: {
          Name: form.name
        },
        RegExPattern: form.regex,
        KindMap: {
          Map: {}
        },
        Tags: {
          Map: {}
        }
      }

      selectedResourceKinds.forEach((kind) => {
        payload.KindMap.Map[kind] = 'true'
      })

      ResourceKinds.forEach(({ label, kind }) => {
        if (selectedResourceKinds.includes(kind)) {
          const fieldValue = form[kind.toLowerCase() + 'Group'];
          // Check if the form field is not empty
          if (fieldValue) {
            // Add the entry to the payload.Tags.Map object
            payload.Tags.Map[kind.toLowerCase() + 'grp'] = fieldValue;
          }
        }
      });

      //Custom tags

      const customTagsObject = customTags.reduce((prev, curr) => {
        return { ...prev, [curr.key]: curr.value }
      }, {})

      payload.Tags.Map = { ...payload.Tags.Map, ...customTagsObject }

      const data = await createTagPolicy(payload)
      pushToSlice(data)

      enqueueNotification('Tag created successfully!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Failed to create tag!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleResourcesSelect = (rsrcKinds = []) => {
    const customTagKinds = rsrcKinds.filter((e) => {
      if (CUSTOM_RSRCS_KINDS.includes(e)) return true
    })

    const newCustomTags = customTagKinds.reduce((prev, kind) => {
      prev.push({ id: kind })
      return prev
    }, [])

    setCustomTags((s) => [...newCustomTags, ...s.filter((e) => !CUSTOM_RSRCS_KINDS.includes(e.id))])
    setSelectedResourceKinds(rsrcKinds)
  }

  const handleNameInput = (data) => {
    const { name } = data;
    // Use a regular expression to replace any character that is not a letter or number with an empty string
    const sanitizedValue = name.replace(/[^a-zA-Z0-9]/g, '');
    // Update the form state with the sanitized value
    setForm((prevForm) => ({
      ...prevForm,
      name: sanitizedValue
    }));
  };

  return (
    <FullScreenContentModal>
      <div className='overflow-scroll min-h-[400px] max-h-[600px] relative'>
        <Typography className='mb-6' variant='h4-regular'>
          Create Tag
        </Typography>
        <TextInput
          value={form.name}
          onChange={(e) => handleNameInput({ name: e.target.value })}
          sx={{ width: '100%', marginTop: '24px' }}
          label='Tag Policy Name'
        />
        <TextInput
          value={form.regex}
          onChange={(e) => handleFormUpdate({ regex: e.target.value })}
          sx={{ width: '100%', marginTop: '24px' }}
          label='RegEx Pattern'
        />
        <div className='flex justify-between items-center mt-6'>
          <Typography variant='body-regular'>Resource Types</Typography>
          <SelectDropDown
            sx={{ minWidth: '200px' }}
            multiple
            menuItems={ResourceKinds.map((e) => ({ label: e.label, value: e.kind }))}
            onChange={(e) => handleResourcesSelect(e.target.value)}
            value={selectedResourceKinds}
          />
        </div>
        
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <Typography variant='body-regular'>Custom Tags</Typography>
            <Button onClick={handleAddCustomTagClick} variant='gray' icon={faPlus}>
              Add
            </Button>
          </div>
          <div>
            {customTags.map((e) => {
              const { id, key, value } = e
              return (
                <div key={id} className='flex gap-4 mt-4 items-center'>
                  <SelectDropDown
                    sx={{ minWidth: '150px' }}
                    menuItems={customTagKeys.map((e) => ({ label: e.label, value: e.kind }))}
                    onChange={(e) => handleCustomTagKeyValueUpdate(id, e.target.value, value)}
                    value={key}
                  />
                  <TextInput
                    value={value}
                    onChange={(e) => handleCustomTagKeyValueUpdate(id, key, e.target.value)}
                    placeholder='Value'
                    sx={{ width: '65%' }}
                  />
                  {!CUSTOM_RSRCS_KINDS.includes(id) && (
                    <IconButton
                      onClick={() => handleCustomTagDeleteClick(id)}
                      variant='gray'
                      icon={faTrash}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <div className='flex justify-between items-center gap-4 mt-6'>
          {errors ? <Label variant='warning' text={errors} /> : <div></div>}
          <div className='flex gap-4'>
            <Button onClick={onCancel} variant='gray'>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={disableButton()} variant='primary'>
              Create
            </Button>
          </div>
        </div>
        <LoadingFeedback message='Creating Tag' loading={isLoading} caption='Please wait..' />
      </div>
    </FullScreenContentModal>
  )
}

export { TagPolicyCreateModal }
