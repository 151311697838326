import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { isApprovalRequestCreatedByUser } from 'features/approvals/utils'
import React, { useEffect, useState } from 'react'
import { ApprovalRequestManager } from '../../../../../components/ApprovalRequestManager'
import { ResourcesListModal } from '../../ResourcesListModal'
import { Table } from '../../Table'
import { useTabs } from '../Tabs.utils'
import { ViewColumnsMap, approvedTableColumns } from '../table.utils'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
const useSlices = createDataSelectorHook(['approvalReqList'], { disableReduxFetch: true })

function ApprovedTab({ searchKey }) {
  const [selectedApprovalRequest, setSelectedApprovalRequest] = useState(null)
  const [showRsrcsListModal, setShowRsrcsListModal] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(false)
  const { slices } = useSlices()
  const { getCommonTableRowData } = useTabs()
  const { appView } = useAppView()
  const { user } = useUser()

  const approved = slices.approvalReqList.filter((e) => e.Status === 'Approved')
  const getColumns = () => {
    const visibleColumns = ViewColumnsMap[appView].approved
    //@ts-ignore
    return approvedTableColumns().filter((e) => visibleColumns.includes(e.dataIndex))
  }

  const getAdminViewRowOptions = (request, noDelete) => {
    if (noDelete) return []
    return [
      {
        title: 'Close',
        twColor: '!text-red-500',
        action() {
          setSelectedApprovalRequest(request)
          setShowCloseModal(true)
        }
      }
    ]
  }

  const getUserViewRowOptions = (request) => {
    // If request is not created by user, then, show all admin options
    if (!isApprovalRequestCreatedByUser(user, request)) return getAdminViewRowOptions(request, true)
    return []
  }

  const rowDataAdapterFn = (request) => {
    const data = getCommonTableRowData(request)

    data.resources.onClick = () => {
      setSelectedApprovalRequest(request)
      setShowRsrcsListModal(true)
    }

    return {
      ...data,
      approvedOn: request.ObjectMeta.UpdatedAt,
      moreOptions:
        appView === 'admin' ? getAdminViewRowOptions(request) : getUserViewRowOptions(request)
    }
  }

  useEffect(() => {
    reduxApiClient('approvalreqs').getByDataParams({ Status: 'Approved' })
  }, [])

  return (
    <div>
      <Table
        searchKey={searchKey}
        getColumns={getColumns}
        data={approved}
        rowDataAdapterFn={rowDataAdapterFn}
      />
      <ApprovalRequestManager
        approvalRequest={selectedApprovalRequest}
        onModalsViewChange={({ showDelete }) => setShowCloseModal(showDelete)}
        views={{
          showDeleteAutoApprove: false,
          showClose: showCloseModal,
          showDelete: false,
          showApprove: false,
          showReject: false
        }}
      />
      {selectedApprovalRequest && (
        <ResourcesListModal
          approvalReq={selectedApprovalRequest}
          onClose={() => setShowRsrcsListModal(false)}
          showModal={showRsrcsListModal}
        />
      )}
    </div>
  )
}

export { ApprovedTab }
