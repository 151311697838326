import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, FullScreenModal } from 'V2Components'
import { createPacPolicy } from 'features/policy'
import {AccessRequestSubmission, AddTeam, useAppDetailsContext} from 'features/github'
import {useAccessCartProvider } from 'features/resources'
import { createRef } from 'features/github'
import { SnackBar } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import _, { set } from 'lodash'
import { useParams } from 'react-router'

/**
 * @typedef { { showEntitySlectionModal:boolean, accessRequestSubmissionModal: boolean } } Views
 *
 * @param {{
 * views: Views,
 * setViews: ((views:Views) => void),
 * isRepo: boolean
 * }} param0
 * @returns
 */
function CreateApplicationPolicyFlow({ views, setViews , isRepo}) {
  const { cartItems, clearCartItems } = useAccessCartProvider()
  const [selectedEntities, setSelectedEntities] = useState([])
  const [requestName, setRequestName] = useState('')
  const [comments, setComments] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dateTimeError, setDateTimeError] = useState('')
  const { slices, selectDispatch } = useMultiSlice(['userList', 'githubResources', 'githubAccount'])
  const [usersList, setUsersList] = useState([])
  const appName = decodeURIComponent(useParams().appName)
  const [currentAccountObj, setCurrentAccountObj] = useState({})


  useEffect(() => {
    selectDispatch(['userList', 'githubResources', 'githubAccount'])
    getCurrentAccountUserList()
  }, [])

  const getCurrentAccountUserList = () => {
    let currentAccount = {}
    if(isRepo) {
      const currentAccountRepo = _.find(slices.githubResources, {ObjectMeta: {Name: appName}})
      currentAccount = _.find(slices.githubAccount, {ObjectMeta: {ID: currentAccountRepo?.Spec?.Account?.RefID}})
    }else {
      currentAccount = _.find(slices.githubAccount, {ObjectMeta: {Name: appName}})  
    }

    setCurrentAccountObj({
      "RefKind": currentAccount?.ObjectMeta?.Kind,
      "RefID": currentAccount?.ObjectMeta?.ID
    })
    
    const filterRepo = slices.githubResources?.filter(obj => obj.Spec.Type === "repository" && obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID)
    let filterTeam = slices.githubResources?.filter(obj => obj.Spec.Type === "team" && obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID)
    const allMembersArray = []
    filterRepo.forEach(item => {
      const memebersArray = item.Spec.Members.Members;
      allMembersArray.push(...memebersArray)
    })
    
    const refIds = currentAccount?.Spec?.Members?.Members.map(member => member.memberRef.RefID);
    const matchedUsers = slices.userList.filter(obj => refIds?.includes(obj.ObjectMeta.ID));
    setUsersList([...matchedUsers, ...filterTeam])
  }


  const handleDismissClick = () => {
    clearCartItems()
    setRequestName('')
    setComments('')
    setStartDate(null)
    setEndDate(null)
    setDateTimeError('')
  }

  const handleViewsChange = (v) => {
    setViews({ ...views, ...v })
  }

  const handleEntityModalContinue = () => {
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: true })
  }

  const handleRsrcEditClick = () => {}

  const handleRequestSubmit = async (data) => {
    const policy = await handlePolicyCreate(data)
    pushToSlice(policy)
    setSelectedEntities([])
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: false })
    clearCartItems()
  }

  const afterSubmit = () => {}

  const onSubmissionCancel = () => {
    setSelectedEntities([])
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: false })
  }

  return (
    <div>
      {views.accessRequestSubmissionModal && (
        <FullScreenModal showModal>
          <AccessRequestSubmission
            onAddMoreResourcesClick={() => handleViewsChange({ accessRequestSubmissionModal: false })}
            onRsrcEditClick={handleRsrcEditClick}
            loadingMessage={`Creating Application's Policy`}
            showRequestForUserGroupsSummary={true}
            requestForUserGroupsRefs={selectedEntities.map(createRef)}
            mainTitle="Policy"
            onSubmit={handleRequestSubmit}
            afterSubmit={afterSubmit}
            onCancel={onSubmissionCancel}
            onManageUserGroupsClick={() =>
              handleViewsChange({ showEntitySlectionModal: true, accessRequestSubmissionModal: false })
            }
            requestName={requestName}
            setRequestName={setRequestName}
            comments={comments}
            setComments={setComments}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateTimeError={dateTimeError}
            setDateTimeError={setDateTimeError}
            currentAccountObj={currentAccountObj}
          />
        </FullScreenModal>
      )}

      {views.showEntitySlectionModal && (
        <FullScreenContentModal width="auto">
          <AddTeam
             title="Users and Teams in this Policy"
             errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
             entities={usersList}
             selectedEntities={selectedEntities}
             setSelectedEntities={setSelectedEntities}
             onCancel={() => {
               handleViewsChange({ showEntitySlectionModal: false })
             }}
             onContinue={handleEntityModalContinue}
             showPermission={true}
          
          />
          
        </FullScreenContentModal>
      )}

      {cartItems?.length > 0 && (
        <div className="fixed bottom-4 right-9">
          <SnackBar
            showDropDownButton
            onClickContinueButton={() => {
              handleViewsChange({ showEntitySlectionModal: true })
            }}
            leftIcon={faRightToBracket}
            menuItems={[
              {
                label: 'Dismiss',
                onClick: handleDismissClick,
                value: 'first-item'
              }
            ]}
            textMessage={`${cartItems?.length} Applications on Queue`}
            variant="primary"
          />
        </div>
      )}
    </div>
  )
}

async function handlePolicyCreate({ requestForUserGroupsRefs, selectedRsrcs, requestName, comments, startDate, endDate, currentAccountObj }) {
  const data = {
    ObjectMeta: {
      Kind: 'PacPolicy',
      Name: getFriendlyName(),
      RdOwners: {
        ObjectRef: requestForUserGroupsRefs
      }
    },
    Spec: {
      Description: comments,
      ActionMap: {
        GithubAccess: {
          PolicyRule: []
        }
      }
    },
    IssuedTo: {
      ObjectRef: requestForUserGroupsRefs
    },
    NotBefore: startDate,
    NotAfter: endDate,
    GivenName: requestName,
    Type: 'GithubResources',
    Creator: ''
  }

  const permissonMap = {
    "read" : "pull",
    "write" : "push",
    "admin" : "admin",
    "owner" : "maintain"
  }

  requestForUserGroupsRefs.map(item => {
    selectedRsrcs.forEach(({ Resource }) => {
      const {permission, ...outPutObj} = Resource
      data.Spec.ActionMap.GithubAccess.PolicyRule.push({
        Principal: permissonMap[item?.permission],
        GcpRoleTarget: null,
        Services: {
            "ObjectRef": [outPutObj]
        }
      })
    })
  })


// append the new object to the first PolicyRule in the array
if (data.Spec.ActionMap && data.Spec.ActionMap.GithubAccess) {
    const policyRuleArray = data.Spec.ActionMap.GithubAccess.PolicyRule;
    if (Array.isArray(policyRuleArray) && policyRuleArray.length > 0) {
        policyRuleArray[0].ObjectRef = currentAccountObj ;
    }
}
  const policy = await createPacPolicy(data)
  enqueueNotification('Policy successfully created!', 'info')
  return policy
}

export { CreateApplicationPolicyFlow }
