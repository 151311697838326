import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SearchInput } from 'V2Components'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { ActionBar, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { AccountActionInput } from '../../../AddWorkload/components/AccountActionInput'

const WhitelistedOwnerKinds = {
  Account: true,
  Project: true
}

function ManageWorkloadAccounts({ workload }) {
  const [ownerSearchKey, setOwnerSearchKey] = useState('')
  const { getObjectRef } = useMultiSlice(['accountList', 'projects'])
  const history = useHistory()

  const handleWorkloadAccountClick = (key) => {
    history.push(`/admin/workloads/${workload.ObjectMeta.Name}/create-policy?account=${key}`)
  }

  const getRsrcOwnersModalItems = () => {
    const items = []
    const accountsRefs = workload.Spec.Accounts.ObjectRef
    accountsRefs.forEach((e) => {
      const account = getObjectRef(e)
      if (!account) return
      const { Kind, ID } = account.ObjectMeta
      const { Type } = account.Spec

      let azureScopeURL = ''

      if (Type === 'AZURE') azureScopeURL = workload.Spec.AzureScope.AzureScopeMap[ID]

      if (WhitelistedOwnerKinds[Kind]) {
        const Icon = getRsrcIcon(account)
        const data = {
          name: getResourceName(account),
          kind: Kind,
          key: createRsrcKey(account),
          Icon: Kind === 'Project' ? <TargetIcon type='GCP_GOOGLE_PROJECT' /> : <Icon />,
          azureScopeURL,
          account,
          ref: {
            RefKind: Kind,
            RefID: ID
          }
        }
        const searchData = [data.caption, data.name, data.key]
        if (ownerSearchKey) {
          JSON.stringify(searchData).toLowerCase().includes(ownerSearchKey) && items.push(data)
        } else items.push(data)
      }
    })
    return items
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography className='text-[#222945]' variant='h4-regular'>
          Accounts
        </Typography>
        <div className='flex gap-4 items-center'>
          <SearchInput
            className='w-[275px]'
            placeholder='Search'
            searchKey={ownerSearchKey}
            onChange={setOwnerSearchKey}
          />
        </div>
      </div>
      <div className='mt-4'>
        {!getRsrcOwnersModalItems().length && (
          <Typography variant='body-regular'>
            The workload identity has not accounts or projects.
          </Typography>
        )}

        {getRsrcOwnersModalItems().map((e) => {
          if (e.azureScopeURL) {
            return (
              <AccountActionInput
                policyMode
                onPolicyButtonClick={() => handleWorkloadAccountClick(createRsrcKey(e.ref))}
                readonly
                account={e.account}
                value={e.azureScopeURL}
              />
            )
          }

          return (
            <div className='border-[#D8DDE4] border-b'>
              <ActionBar
                leftIcon={e.Icon}
                name={e.name}
                showActionButton
                onClickAddButton={() => handleWorkloadAccountClick(createRsrcKey(e.ref))}
                onClickRemoveButton={function noRefCheck() {}}
                style={{
                  width: '100%'
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { ManageWorkloadAccounts }
