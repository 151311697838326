import React from 'react'
import _ from 'lodash'
import { Database } from './components/Database'
import { RDPServer } from './components/RDPServer'
import { ServerResource } from './components/Server'
import { KafkaResource } from './components/Kafka'
import { AppRoleResource } from './components/AppRole'
import { OverviewTab, useResourceDetails } from 'features/resources'
import { KubeCluster } from './components/KubeCluster'
import { KubeNamespace } from './components/KubeNamespace'

const InfoMap = {
  AppRole: AppRoleResource,
  Server: ServerResource,
  Database: Database,
  RDPServer: RDPServer,
  Kafka: KafkaResource,
  KubeCluster: KubeCluster,
  KubeNamespace: KubeNamespace
}

function Overview() {
  const { rsrc, account } = useResourceDetails()

  if (!rsrc) return null

  const Component = InfoMap[rsrc.ObjectMeta.Kind]

  return (
    <OverviewTab ResourceInfoComponent={Component && <Component rsrc={rsrc} account={account} />} />
  )
}

export { Overview }
