
import { errorHandler, getUserInfo } from '../Utils/Helpers'
import {getActivityLog, getElastic } from './NetworkRequest'

export default class activityLogsApis {
  static async getWebProxyActivityLogs () {
    try {
      const user = getUserInfo()
      const { apiKey, tenant } = user
      const response = await getElastic(apiKey, 'web-proxy-audit-log/_search/?q=signin.aws.procyon.ai', 'auditlogs')
      return response
    } catch (error) {
      errorHandler(error, 'getWebProxyActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getSshActivityLogs (data) {
    try {
      const user = getUserInfo()
      const { apiKey, tenant } = user
      const response = await getElastic(apiKey, 'ssh-audit-log/_search', 'auditlogs')
      return response
    } catch (error) {
      errorHandler(error, 'getSshActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getEventLogs (query = {}) {
    try {
      
      const user = getUserInfo()
      let endTime = ''
      let startTime = '' 
      const timeRange = query?.query?.bool?.must?.find(item => item.range && item.range["@timestamp"]);

      if(timeRange) {
        const {gte, lt} = timeRange.range['@timestamp']
        startTime = gte;
        endTime = lt;
      }

      const { apiKey, tenant, org, name } = user
      const payloadData = {
        "ObjectMeta": {
          "Tenant": tenant,
          "Namespace": org
        },
        "Indices": {
          "Elems": ['event-log']
        },
        "Filters": {
          "UserName": name,
          "DeviceName": '',
          "ResourceName": '',
        },
        "Size": 1000,
        "StartTime": startTime,
        "EndTime": endTime,
        "From": 0
      }

      const response = await getActivityLog(apiKey, tenant, 'elasticlog', payloadData)
      const decodeResponse = response?.data ? JSON.parse(atob(response?.data?.Response)) : '';
      return decodeResponse
    } catch (error) {
      errorHandler(error, 'getSshActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getProxyAuditLogs (query = {}) {
    try {
     
      const user = getUserInfo()
      let endTime = ''
      let startTime = '' 
      const timeRange = query?.query?.bool?.must?.find(item => item.range && item.range["@timestamp"]);

      if(timeRange) {
        const {gte, lt} = timeRange.range['@timestamp']
        startTime = gte;
        endTime = lt;
      }

      const { apiKey, tenant, org, name } = user
      const payloadData = {
        "ObjectMeta": {
          "Tenant": tenant,
          "Namespace": org
        },
        "Indices": {
          "Elems": ['proxy-audit-log']
        },
        "Filters": {
          "UserName": name,
          "DeviceName": '',
          "ResourceName": '',
        },
        "Size": 1000,
        "StartTime": startTime,
        "EndTime": endTime,
        "From": 0
      }

      const response = await getActivityLog(apiKey, tenant, 'elasticlog', payloadData)
      const decodeResponse = response?.data ? JSON.parse(atob(response?.data?.Response)) : '';
      console.log('proxy audit log response data', decodeResponse)
      return decodeResponse;
    } catch (error) {
      errorHandler(error, 'getProxyAuditLogs', 'activityLogsApis.js')
    }
  }
}
