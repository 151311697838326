import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { cn } from 'Utils/Helpers'
import { Headers } from '../../Headers'
import { FullScreenContentModal, LabelContent, LoadingFeedback } from 'V2Components'
import { AddTeam, DeleteTeamModal } from 'features/github'
import _ from 'lodash'
import { GithubUserRow, TargetIcon, Typography } from 'procyon-ui'

import React, { useEffect, useState } from 'react'
import { MainHeader } from '../../MainHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useParams } from 'react-router'
import { ApiProvider } from 'Core'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { AccessCartProvider } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

function TeamDetails() {
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false)
  const [showAddUserModal, setAddUserModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const teamName = decodeURIComponent(useParams().teamName)
  const { selectDispatch } = useMultiSlice(['githubResources', 'userList'])
  const [updateState, setUpdateState] = useState(false)
  const [membersList, setMembersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const canGoBack = useCanGoBack()

  const useSlices = createDataSelectorHook(['githubResources', 'userList'])
  const { slices } = useSlices()
  const [currentTeamObject, setCurrentTeamObject] = useState(() => {
    const teamObj = _.find(slices.githubResources, { Spec: { TeamSpec: { Name: teamName } } })
    return teamObj || {}
  })

  const teamObj = _.find(slices.githubResources, { Spec: { TeamSpec: { Name: teamName } } })
  if (teamObj && teamObj !== currentTeamObject) setCurrentTeamObject(teamObj)

  useEffect(() => {
    selectDispatch(['githubResources', 'userList'])
    getTeamDetails()
  }, [])

  let getTeamDetails = async () => {
    let timeoutId
    if (Object.keys(currentTeamObject).length > 0) {
      generateMembersRow(currentTeamObject?.Spec?.Members?.Members)
      clearTimeout(timeoutId)
    } else {
      timeoutId = setTimeout(() => {
        selectDispatch(['githubResources'])
        getTeamDetails()
      }, 3000)
    }
  }

  const generateMembersRow = async (data) => {
    const matchingObjects = []
    for (const item of data) {
      const matchingObject = slices.userList.find(
        (obj) => obj.ObjectMeta.ID === item.memberRef.RefID
      )
      matchingObjects.push(matchingObject)
    }
    setMembersList(matchingObjects)
    setUpdateState(true)
    setIsLoading(false)
  }

  const handleEditClick = () => setShowEditAppModal(true)
  const handleDeleteClick = () => setShowDeleteTeamModal(true)

  const handleAddMember = () => {
    setAddUserModal(true)
  }

  const handleEntityModalContinue = async () => {
    setAddUserModal(false)
    const meta_info_array = selectedEntities.map((obj) => ({
      ID: obj.ObjectMeta.ID,
      Kind: obj.ObjectMeta.Kind
    }))

    const newMemberArray = meta_info_array.map((item) => ({
      memberRef: { RefKind: item.Kind, RefID: item.ID },
      ManagedBy: 'procyon',
      Status: 'pending'
    }))

    const updatedMembersArray = [...currentTeamObject.Spec.Members.Members, ...newMemberArray]
    try {
      let response = await new ApiProvider('githubresources')
        .setInstance({
          ...currentTeamObject,
          Spec: {
            ...currentTeamObject.Spec,
            Members: {
              ...currentTeamObject.Spec.Members,
              Members: updatedMembersArray
            }
          }
        })
        .put()
      console.log(response.data.Spec.Members.Members, 'add new member success')
      generateMembersRow(response.data.Spec.Members.Members)
    } catch (error) {
      console.log('add new member error')
    }
  }

  return (
    <>
      {Object.keys(currentTeamObject).length > 1 ? (
        <>
          <MainHeader
            onAccessClick={''}
            disableAccessBtn={true} //disable when cart is not empty and disable if it is userview and the app is already allowed access
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
            showSignIn={''}
            showPolicy={false}
            app={currentTeamObject}
          />

          <div className='flex gap-8' style={{ marginTop: '20px' }}>
            {/* Hide the border in user view, because the other div which shows owners won't be shown */}
            <div className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8')}>
              <Typography variant='h4-regular'>Team Information</Typography>
              <div className='mt-8'>
                <LabelContent title='Name' content={currentTeamObject?.Spec?.TeamSpec?.Name} />
                <LabelContent
                  title='Description'
                  content={currentTeamObject?.Spec?.TeamSpec?.Description}
                />
                <LabelContent
                  title='Members'
                  content={
                    <span
                      style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                    >
                      <TargetIcon type='USERGROUPS' />
                      <div style={{ marginLeft: '10px' }}>{membersList.length}</div>
                    </span>
                  }
                />
                <LabelContent
                  title='Repositories'
                  content={
                    <span>
                      <span
                        style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                      >
                        <TargetIcon type='GITHUB_REPOSITORIES_MONO' />
                        <div style={{ marginLeft: '10px' }}>{'0'}</div>
                      </span>
                    </span>
                  }
                />
              </div>
            </div>
            {/* 
          Hide owners on user view
      */}

            <div className='w-1/2'>
              <div>
                <Headers
                  showAddButton={true}
                  buttonLabel='Add Member'
                  handleAddApplicationClick={handleAddMember}
                />
                <Typography variant='h4-regular'>Members</Typography>

                <div style={{ marginTop: '20px' }}>
                  {/* {setMembersList && JSON.stringify(membersList)} */}
                  {membersList &&
                    updateState &&
                    membersList.map((item, index) => (
                      <GithubUserRow
                        key={index}
                        dropdownMenuItems={[
                          {
                            submenu: [
                              {
                                title: 'Delete'
                              }
                            ],
                            title: <FontAwesomeIcon icon={faEllipsisVertical} />
                          }
                        ]}
                        onClickRepositories={function noRefCheck() {}}
                        onClickUserGroups={function noRefCheck() {}}
                        userEmail={item?.Spec?.EmailID}
                        showControlButtons={false}
                        userName={item?.Spec?.FullName}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        'Loading.....'
      )}

      {showAddUserModal && (
        <FullScreenContentModal width='auto'>
          <AddTeam
            title={`Add users to ${currentTeamObject?.Spec?.TeamSpec?.Name}`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={slices.userList}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            showPermission
            onCancel={() => {
              setAddUserModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}

      {showDeleteTeamModal && (
        <DeleteTeamModal
          cleanUpFn={() => {
            if (canGoBack) history.goBack()
            else history.goBack()
          }}
          onCancel={() => setShowDeleteTeamModal(false)}
          app={currentTeamObject}
          name={teamName}
        />
      )}
      <LoadingFeedback loading={isLoading} caption='Please wait..' />
    </>
  )
}

const WrappedTeamDetails = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <AccessCartProvider cartKey={cartKey}>
      <TeamDetails />
    </AccessCartProvider>
  )
}

export { WrappedTeamDetails as TeamDetails }
