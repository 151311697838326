import { useDashboardDataProvider } from 'features/dashboard'
import { getResourceName } from 'features/resources'
import { TopDevicesCard } from 'procyon-ui'
import React from 'react'

const TopDevices = () => {
  const { heatMap, startDays } = useDashboardDataProvider()

  const getData = () => {
    const sortedDevices = Object.values(heatMap.devices)
    sortedDevices.sort((a, b) => {
      return b.count - a.count //b-a because to make it descending order
    })

    const top5 = sortedDevices.slice(0, 5)
    const totalCount = top5.reduce((prev, curr) => prev + curr.count, 0)

    return top5.map((data, i) => {
      return {
        color: colors[i],
        name: getResourceName(data.device),
        sessions: data.count,
        total: `${Math.floor((data.count / totalCount) * 100)}%`,
        owner: getResourceName(data.owner)
      }
    })
  }

  const data = getData()

  return (
    <div>
      <TopDevicesCard
        style={{ height: 'auto', width: '100%' }}
        className='!h-auto'
        caption={`by resource type last ${startDays} days`}
        items={data}
        title='Top Devices'
        width='600px'
      />
    </div>
  )
}

const colors = ['#658BD3', '#94BD91', '#E4B761', '#61e48a', '#A6ABB6']

export { TopDevices }
