import { TabGroup } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { OverviewTab } from './OverviewTab'
import { WorkSpaces } from './WorkSpaces'
import { createRsrcKey } from 'features/resources'
import { useAppDetailsContext } from 'features/snowflake'
import { useParams } from 'react-router'

function Tabs() {
  const { app } = useAppDetailsContext()
  const { tabName } = useParams()
  const [activeTab, setActiveTab] = useState(tabName)

  const TAB_INDEX_MAP = {
    overview: 0,
    workspaces: 1
  }

  const TAB_NAME_MAP = {
    0: 'overview',
    1: 'workspaces'
  }

  const tabs = useMemo(() => {
    const list = [
      {
        label: 'Overview',
        tabContent: <OverviewTab />
      },
      {
        label: 'WorkSpaces',
        tabContent: <WorkSpaces />
      }
    ]
    return list
  }, [])

  useEffect(() => {
    if (tabName) {
      setActiveTab(tabName)
    }
  }, [tabName])

  return (
    <div>
      <TabGroup
        key={createRsrcKey(app)}
        controllable={tabName === 'workspaces'}
        activeIndex={tabName === 'workspaces' ? TAB_INDEX_MAP[activeTab] : 0}
        onChangeTab={(_, v) => {
          setActiveTab(TAB_NAME_MAP[v])
        }}
        tabs={tabs}
      />
    </div>
  )
}

export { Tabs }
