import styled from 'styled-components'

export const TeamModal = styled.div`   

     .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        p {
            font-size:20px;
            margin: 0;
        }

        .close-btn {
            padding: 10px 30px;
            background: #f9fbfc;
            border-radius: 7px;
            font-size:16px;
            color:#545B71;
            cursor: pointer;
        }
     }

     p {
        color:#545B71;
        font-size:14px;
        margin-bottom:24px;
     }

    .form-wrapper {
        span {
            width:100%;
            margin-bottom:20px;
            display:block;
        }

        label {
            display:block;
            color:#545B71;
            font-size:14px;
        }

        input {
                width: 100%;
                border: 1px solid #D8DDE4;
                line-height: 40px;
                border-radius: 5px;
                margin-top: 10px;
                background: #F9FBFC;
                padding: 0px 10px;
        }

        textarea {
            width:100%;
            border: 1px solid #D8DDE4;
            line-height: 30px;
            border-radius: 5px;
            margin-top: 10px;
            background: #F9FBFC;
            padding: 0px 10px;
        }
    }

    .button-wrapper {
        margin-top: 40px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        span {
            padding: 13px 40px;
            background: #f9fbfc;
            border-radius: 7px;
            font-size:16px;
            color:#545B71;
            margin-left: 20px;
            cursor: pointer;
            }
        }

        .save-btn {
            background : #3267D6 !important;
            color:#fff !important;
        }
    }

    .add-repo-radio-wrapper {
        display: flex;
        align-items: center;

        .radio-wrapper {
            padding: 0 30px;
            width: 300px;

            p {
                margin:10px 0px 0px 30px;
                font-size: 13px;
                color:#8D94A1;
            }
        }
        
    }

    .add-repo-dropdown-wrapper {
        label {
            margin-top: 30px;
            color: #545B71;
            font-size: 14px;
            display: block;
        }

        li {
            font-size: 14px;
        }
    }
`

export const ActivePolicyHolder = styled.div`
        background: #F9FBFC;
        height: 230px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        width: 100%;

        .icon-wrapper {
            display: flex;
            justify-content: center;
        }

        p {
            color: #545B71;
            font-size:14px;
            margin-top:20px;
            text-align:center;
            padding: 0 30%;
        }
`