import { useCallback, useEffect, useMemo } from 'react'
import { compareDatesFn, getAppRoleAccount, getTargetAccount, getUserFromHit, groupByDate, groupByUser, mixMapFn } from 'Utils/PureHelperFuctions'
import useMultiQuery from './useMultiQuery'
import useAppView from './useAppView'

/**
 * @typedef { 'event-log' | 'proxy-event-log' | 'proxy-audit-log' } QueryIndexes
 * @param {QueryIndexes[] | string[]} indexes
 * @param { {
 *   overrideChartKey?: {};
 *   groupBy?: 'user' | 'date';
 *   userName?: string;
 *   deviceIdentifier?: string;
 *   defaultTimeRange?:{ start: any, end: any } | null;
 *   resourceIdentifier?: string;
 *   indexNotFoundExceptionHandling?: boolean,
 *   accountName?: string;
 *   appRolesList?:[];
 *   serverList?:[];
 *   accountList?:[]
 * } } options
 * @returns
 */
export default function useMultiQueryGraph (indexes, options) {
  const { getQueryLogs, loading, hits, onTillTimeChange } = useMultiQuery(indexes, options)
  const appView = useAppView()
  const transformDataForChart = useCallback(
    (data, chartKey) => {
      const fn = options.groupBy === 'user' ? groupByUser : groupByDate
      const datasets = {
        Sessions: fn(data, chartKey)
      }
      datasets['Active User'] = fn(data, chartKey, getUserFromHit)
      return datasets
    },
    [options.groupBy]
  )

  const chartData = useMemo(() => {
    const object = {}
    for (const index in hits) {
      if (Object.hasOwnProperty.call(hits, index)) {
        let element = hits[index]
        if (options.accountName) {
          let filteredHits = []
          if (options.accountList?.length > 0) {
            if (index === 'event-log' && options.appRolesList?.length > 0) {
              filteredHits = element.filter(hit => {
                const Resources = hit._source.Resources
                const roleref = Resources && Resources.length > 0 ? Resources[0] : null
                const appRole = options.appRolesList?.find(e => e.ObjectMeta.Name === roleref?.Name)
                const account = getAppRoleAccount(options.accountList, appRole)
                return account?.ObjectMeta?.Name === options.accountName
              })
            }
            if (index === 'proxy-audit-log' && options.serverList?.length > 0) {
              filteredHits = element.filter(hit => {
                const ServerName = hit._source.Data.ServerName
                const server = options.serverList?.find(e => e.ObjectMeta.Name === ServerName)
                const account = getTargetAccount(options.accountList, server)
                return account?.ObjectMeta?.Name === options.accountName
              })
            }
          }
          element = filteredHits
        }

        if (options.overrideChartKey && options.overrideChartKey[index]) {
          object[index] = transformDataForChart(element, options.overrideChartKey[index])
        } else object[index] = transformDataForChart(element, index)
      }
    }
    return object
  }, [hits, options.accountName, options.appRolesList, options.serverList, options.accountList])

  const graphData = useMemo(() => {
    const sessions = []
    const activeUser = []

    for (const index in chartData) {
      if (Object.hasOwnProperty.call(chartData, index)) {
        const data = chartData[index]
        if (data.Sessions && Array.isArray(data.Sessions)) {
          sessions.push(...data.Sessions)
        }
        if (data['Active User'] && Array.isArray(data['Active User'])) {
          activeUser.push(...data['Active User'])
        }

        sessions.sort(compareDatesFn)
        activeUser.sort(compareDatesFn)
      }
    }
    return { Sessions: mixMapFn(sessions), 'Active User': mixMapFn(activeUser) }
  }, [chartData])

  useEffect(() => {
    getQueryLogs()
  }, [getQueryLogs])

  return { loading, onTillTimeChange, graphData }
}
