import { TabGroup } from 'procyon-ui'
import React, { useEffect } from 'react'
import { AccessGraphTab } from './AccessGraphTab'
import { AccessSummary } from './AccessSummary'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'

function Tabs() {
  const { selectDispatch } = useMultiDispatch([])

  useEffect(() => {
    selectDispatch([
      'userList',
      'groupList',
      'serviceAccounts',
      'awsResources',
      'gcpResources',
      'azureResources',
      'appRolesList',
      'serverList',
      'databases',
      'kubeNamespaces',
      'githubResources',
      'githubAccount',
      'workloads',
      'rdpServers',
      'kafkas',
      'salesForceUsersList',
      'salesForcePermissionList'
    ])
    selectDispatch(['iamActions'], { skipWhenLoaded: true })
  }, [])

  return (
    <div className='mt-4 mx-4'>
      <TabGroup
        onChangeTab={function noRefCheck() {}}
        tabs={[
          {
            label: 'Access Graph',
            tabContent: <AccessGraphTab />
          },
          {
            label: 'Access Summary',
            tabContent: <AccessSummary />
          }
        ]}
      />
    </div>
  )
}

export { Tabs }
