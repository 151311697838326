export const createFakeServiceAccountObject = (appReq) => {
  const { GivenName } = appReq
  const Spec = appReq.Spec.SvcAccSpec

  return {
    ObjectMeta: {
      Name: GivenName,
      Kind: 'ServiceAccount',
      ID: GivenName
    },
    Spec: Spec,
    Status: {
      Status: {}
    }
  }
}
