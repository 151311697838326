import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

function DeleteServerControllerModal({ serverController, cleanUpFn, onCancel = null }) {
  const handleDeleteServerController = async () => {
    await reduxApiClient('servercontrollers').delete(serverController)
    enqueueNotification('Server Controller Deleted Successfully!', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`Are you sure you want to delete ${getResourceName(
          serverController
        )} server controller?`}
        loadingMessage='Deleting Server Controller'
        onActionClick={handleDeleteServerController}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeleteServerControllerModal }
