import { InfoRounded, Settings, Warning } from '@material-ui/icons'

export const chipsItems = [
  {
    Icon: Warning,
    label: '0',
    color: 'default',
    variant: 'error'
  },
  {
    Icon: InfoRounded,
    label: '0',
    color: 'default',
    variant: 'warning'
  },
  {
    Icon: Settings,
    label: '0',
    color: 'default',
    variant: 'success'
  }
]

export const logItems = [
  // {
  //   Icon: Warning,
  //   Text: '12 servers offline on AWS Account 1. Probable region service outage on AWS us-west-1.',
  //   variant: 'error',
  //   metaData: { ID: 1001 }
  // },
  // {
  //   Icon: Warning,
  //   Text: 'Data Source 1 from GCP Account 1 is offline.',
  //   variant: 'error',
  //   metaData: { ID: 1002 }
  // },
  // {
  //   Icon: InfoRounded,
  //   Text: 'Kylo Ren is showing abnormal activity.',
  //   variant: 'warning',
  //   metaData: { ID: 1003 }
  // },
  // {
  //   Icon: InfoRounded,
  //   Text: 'Luke Skywalker’s MacBook Pro trust score decreased from 93% to 62%.',
  //   variant: 'warning',
  //   metaData: { ID: 1004 }
  // },
  // {
  //   Icon: Settings,
  //   Text: 'Settings Alert alert description.',
  //   variant: 'success',
  //   metaData: { ID: 1005 }
  // },
  // {
  //   Icon: Settings,
  //   Text: 'Settings Alert alert description.',
  //   variant: 'success',
  //   disableBorder: true,
  //   metaData: { ID: 1006 }
  // }
]
