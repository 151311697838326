export default (theme) => ({
  borderBox: { // Adds a grey border to the div with rounder corners
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey[300]
  },
  applicationCard: { // Ususal card style in application
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1)
  },
  'no-padding-card': { // Ususal card style in application
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white
  },
  chartWrapper: { // Ususal card style in application
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white
  },
  pointerOnHover: { // On hover of the box, show a cursor
    '&:hover': {
      cursor: 'pointer'
    }
  },
  pointerOnRowHover: { // On hover of the datable row inside a box, show a cursor
    '& .MuiTableRow-root:hover': {
      cursor: 'pointer'
    }
  },
  labelBox: {
    border: `1px solid ${theme.palette.inside}`,
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(0.375, 0.5, 0.275, 0.5)
  }
})
