import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { updateGroup } from 'features/groups'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { Typography } from 'procyon-ui'
import React, { useState } from 'react'

function BulkAddToUser({ onCancel, onSuccess, groups = [] }) {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const { slices } = useMultiSlice(['userList'])

  const isSingleGroup = groups.length === 1

  const handleBulkAddToGroupSubmit = async () => {
    try {
      setIsUpdating(true)
      const updatedUsers = await handleBulkUserAdd({ users: selectedEntities, groups })
      updatedUsers.forEach(updateSliceData)
      enqueueNotification(
        `User${isSingleGroup ? '' : 's'} has been successfully added to groups!`,
        'info'
      )
      onSuccess?.()
    } catch (error) {
      enqueueNotification(`Error adding user${isSingleGroup ? '' : 's'} to groups!`, 'error')
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <div>
      <LoadingFeedback
        loading={isUpdating}
        message={`Adding group${isSingleGroup ? '' : 's'} to users.`}
        caption='Please wait..'
      />
      <FullScreenContentModal width='auto'>
        <EntityPicker
          title={
            isSingleGroup ? `Add Users to ${getResourceName(groups[0])}` : 'Add Users to Groups'
          }
          continueText='Save'
          InfoComponent={
            <Typography variant='caption-regular'>
              {isSingleGroup
                ? 'Group will be added to these users.'
                : 'Groups will be added to these users. Exisiting groups will be unaffected.'}
            </Typography>
          }
          entities={slices.userList}
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
          onCancel={onCancel}
          onContinue={handleBulkAddToGroupSubmit}
        />
      </FullScreenContentModal>
    </div>
  )
}

const handleBulkUserAdd = async ({ users = [], groups = [] }) => {
  const newGroups = []

  groups.forEach((g) => {
    const existingUsersRefs = g.Spec.Users?.ObjectRef || []
    // Create a map of existing users
    const usersMap = existingUsersRefs.reduce(
      (prev, ref) => ({ ...prev, [createRsrcKey(ref)]: true }),
      {}
    )
    // Create a list of new to-be added users
    const newUserRefs = users.filter((g) => !usersMap[createRsrcKey(g)]).map(createRef)
    // If there are no new groups to be added then, skip this group. --> saves unnecessary api call
    if (!newUserRefs.length) return
    const newGroup = structuredClone(g)
    // Combine existing  users and to-be added users
    newGroup.Spec.Users.ObjectRef = [...existingUsersRefs, ...newUserRefs]
    newGroups.push(newGroup)
  })

  const apiCalls = newGroups.map(async (g) => await updateGroup(g))
  return await Promise.all(apiCalls)
}

export { BulkAddToUser }
