import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'

import { columns, options } from './TableMeta'
import { infoTableData as data } from './MetaData'

const useStyles = makeStyles((theme) => ({
  root: {},
  mainIcon: {
    fill: theme.palette.text.disabled
  },
  brandIcon: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25)
  },
  serverText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.25)
  }
}))

const InfoTable = ({ tableData = [] }) => {
  const classes = useStyles()
  const tableOptions = { hideHeader: true }
  const columnProps = { classes }

  return (
    <Box>
      <AnalyticTable
        rowHeight='2.75rem'
        headHeight='2.5rem'
        headerJustify='flex-end'
        vAlign='top'
        searchString=''
        columns={columns(columnProps)}
        options={options(tableOptions)}
        data={tableData}
      />
    </Box>
  )
}

export default InfoTable
