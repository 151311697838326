import React, { useState } from 'react'
import { Button, Typography, Divider, Step, StepLabel, StepContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignInAlt,
  faUserShield,
  faKey,
  faTrash,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

const steps = [
  {
    title: 'Sign in to the AWS Management Console',
    description:
      'Log in to the AWS Management Console using an account with administrative permissions.',
    icon: faSignInAlt
  },
  {
    title: 'Navigate to the IAM Console',
    description:
      "In the AWS Management Console, search for 'IAM' in the top search bar and select it to access the Identity and Access Management console.",
    icon: faUserShield
  },
  {
    title: 'Locate the User with Unused Access Key',
    description:
      "In the IAM Dashboard, select 'Users' from the sidebar to see all IAM users. Choose the user account for which you want to review access keys.",
    icon: faKey
  },
  {
    title: 'Identify Unused Access Keys',
    description:
      "In the selected user's Summary page, scroll down to 'Security Credentials'. Check the 'Last Used' column next to each access key. Identify keys that haven’t been used recently or are marked as 'Never Used'.",
    icon: faCheckCircle
  },
  {
    title: 'Delete Unused Access Keys',
    description:
      "For each unused access key, click on 'Make Inactive' first to disable it temporarily. Then, confirm by clicking 'Delete' to remove it permanently.",
    icon: faTrash
  }
]

const UnusedAccessKeyRecommendation = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <p className='text-base font-[500]'>Remove Unused IAM User Access Keys</p>
      <Divider style={{ marginBottom: '20px' }} />

      {steps.map((step, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Step active={activeStep === index} expanded={activeStep === index}>
            <StepLabel>
              <FontAwesomeIcon icon={step.icon} style={{ marginRight: '10px' }} />
              <Typography variant='subtitle2' component='span'>
                {step.title}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <StepContent>
                <p className='mb-3 text-[14px] font-[500] text-[#6e6e6e]'>{step.description}</p>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    style={{ marginRight: '10px' }}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  {index > 0 && <Button onClick={handleBack}>Back</Button>}
                </div>
              </StepContent>
            )}
          </Step>
        </div>
      ))}
    </div>
  )
}

export { UnusedAccessKeyRecommendation }
