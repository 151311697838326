import { ApiProvider } from 'Core'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { KindToEndpointMap } from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityInfoBar, FullScreenAlertModal } from 'V2Components'
import { createRsrcKey, getResourceName } from 'features/resources'
import { PickListContainer, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function BulkDeleteSelectionModal({ items = [], onCancel, onSuccess, type = '' }) {
  const [deleteConfirmText, setDeleteConfirmText] = useState('')
  const [visibleItems, setVisibleItems] = useState(items)

  const handleItemRemoveClick = (item) => {
    setVisibleItems((s) => s.filter((e) => createRsrcKey(e) !== createRsrcKey(item)))
  }

  const handleBulkDeleteClick = async () => {
    try {
      const apiCalls = visibleItems.map(async (u) => {
        await new ApiProvider(KindToEndpointMap[u.ObjectMeta.Kind]).setInstance(u).delete()
        return u
      })
      const deletedItems = await Promise.all(apiCalls)
      deletedItems.forEach(deleteSliceData)
      enqueueNotification(`Successfully Deleted (${visibleItems.length}) ${type}s!`, 'info')
      onSuccess?.()
    } catch (error) {
      enqueueNotification(`Unable to delete ${type}s`, 'error')
    }
  }

  useHTMLBodyScrollbar(true)

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`You are about to delete the following (${visibleItems.length}) ${type}s`}
        loadingMessage={`Deleting all (${visibleItems.length}) ${type}s.`}
        onActionClick={handleBulkDeleteClick}
        onCancel={onCancel}
        disableAction={deleteConfirmText !== 'delete'}
        Content={
          <div>
            <PickListContainer
              style={{
                height: '400px',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              {visibleItems.map((item) => (
                <EntityInfoBar
                  assigned
                  caption={item.Spec.Description || item.ObjectMeta.Name}
                  style={{ width: '100%' }}
                  key={createRsrcKey(item)}
                  kind={item.ObjectMeta.Kind}
                  name={getResourceName(item)}
                  onClickRemoveButton={() => handleItemRemoveClick(item)}
                />
              ))}
            </PickListContainer>
            <div className='mt-4'>
              <Typography className='my-2' variant='buttonLabel-regular'>
                The {type}s will be <span className='text-red-500 mx-2'>permanently</span> deleted
                and cannot be recovered.
              </Typography>
              <TextInput
                value={deleteConfirmText}
                onChange={(e) => setDeleteConfirmText(e.target.value)}
                sx={{ width: '100%' }}
                label={`Enter 'delete' to confirm`}
              />
            </div>
          </div>
        }
        showModal
      />
    </div>
  )
}

export { BulkDeleteSelectionModal }
