import { useMultiOrg } from 'Core/Hooks/useMultiOrg'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { deleteOrganization } from 'features/settings'
import React from 'react'

const DeleteOrgAlertModal = ({ org, onSuccess, onCancel }) => {
  const name = getResourceName(org)
  const { changeOrganization, activeOrg } = useMultiOrg()

  const handleDelete = async () => {
    try {
      await deleteOrganization(org)
      deleteSliceData(org)
      onSuccess?.()
      enqueueNotification('Org deleted successfully!', 'info')
      // If the deleted org is the active or, then reset to default org and reload
      if (activeOrg === org.ObjectMeta.Name) {
        changeOrganization('default')
        location.reload()
      }
    } catch (error) {
      enqueueNotification(`Failed to delete org: ${name}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={org} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteOrgAlertModal }
