const { useLocation } = require('react-router')
import React from 'react'

/**
 * React hook for managing url query parameters(URLParams)
 */
export function useURLQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
