import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteIntegrationModal } from 'features/integrations'
import { getIntegrationIcon } from 'features/integrations/utils'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { IntegrationCard } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Endpoints } from '../../../../../const'
import { Header } from '../../Header'
import { ShimmerCard } from 'Utils/ShimmerEffect'

const useSlices = createDataSelectorHook([
  'slackIntegrationList',
  'jiraIntegrationsList',
  'servicenowintegrations',
  'msTeamsIntegrations'
])

const Active = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedType, setSelectedType] = useState('allcategory')
  const [integration, setIntegration] = useState({})
  const [showShrimmer, SetShowShrimmer] = useState(true)
  const history = useHistory()
  const { slices } = useSlices()
  const combinedIntegrationList = [
    ...slices.slackIntegrationList,
    ...slices.jiraIntegrationsList,
    ...slices.servicenowintegrations,
    ...slices.msTeamsIntegrations
  ]

  useEffect(() => {
    const loadData = async () => {
      await reduxApiClient('slackintegrations').getAll({})
      SetShowShrimmer(false)
    }
    loadData()
    filterIntegrationCards()
  }, [slices])

  const handleManageIntegrationClick = (item) => {
    history.push(`/admin/integrations/${item.ObjectMeta.Name + '/' + item.ObjectMeta.Kind}`)
  }

  const getSubtitle = (item) => {
    const kindName = getIntegrationIcon(item.ObjectMeta.Kind)
    switch (kindName) {
      case 'SLACK':
        return kindName + ' / ' + item.Spec.WorkspaceName
      case 'JIRA':
        return kindName + ' / ' + item.Spec.SiteName
      case 'SERVICENOW':
        return kindName
      case 'MSTEAM':
        return kindName
      default:
        return null
    }
  }

  const handleDeleteModal = (item) => {
    setShowDeleteModal(!showDeleteModal)
    setIntegration(item)
  }

  const handleDeleteIntegrationClick = async () => {
    setShowDeleteModal(false)
    try {
      await reduxApiClient(Endpoints[integration?.ObjectMeta?.Kind]).delete(integration)
      deleteSliceData()
      enqueueNotification(`${integration.ObjectMeta.Name} integration removed successfully`, 'info')
    } catch (error) {
      enqueueNotification('Operation failed', 'error')
      console.log(error, 'integration delete error')
    }
  }

  const handleAuthorize = (integration) => {
    window.location.href = integration.Status.AuthURL
  }

  const filterIntegrationCards = () => {
    return combinedIntegrationList.filter((item) => {
      const channelKind = item?.ObjectMeta?.Kind
      const channelName = item?.ObjectMeta?.Name

      if (selectedType === 'allcategory' || selectedType === channelKind) {
        if (channelName && channelName.toLowerCase().includes(searchTerm.toLowerCase())) {
          return true
        }
      }
      return false
    })
  }

  const isPublicApp = (data) => {
    // check for attributs map appstore
    if (data && data.Spec && data.Spec.Attributes && data.Spec.Attributes.Map) {
      return data.Spec.Attributes.Map.source === 'AppStore'
    }
  }

  const getAuthState = (data) => {
    if (isPublicApp(data)) return false
    if (data.ObjectMeta.Kind === 'MsTeamsIntegration' && data.Spec.webhookUrl !== '') return false
    if (
      data.Spec.WebhookUrl === '' &&
      (data.ObjectMeta.Kind === 'MsTeamsIntegration' || data.ObjectMeta.Kind === 'SlackIntegration')
    )
      return true
  }

  const getManageIntegrationState = (data) => {
    if (isPublicApp(data)) {
      return false
    }

    if (data?.Status?.Status === 'Success') {
      return true
    }

    if (data.ObjectMeta.Kind === 'MsTeamsIntegration' && data.Spec.WebhookUrl !== '') {
      return true
    }
  }

  return (
    <>
      <Header
        count={filterIntegrationCards().length}
        setSearchTerm={setSearchTerm}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />
      <div className='flex items-center gap-6 flex-wrap'>
        {showShrimmer && (
          <>
            <ShimmerCard width={'500px'} />
            <ShimmerCard width={'500px'} />
            <ShimmerCard width={'500px'} />
          </>
        )}
        {!showShrimmer &&
          filterIntegrationCards().map((item) => (
            <IntegrationCard
              iconType={getIntegrationIcon(item.ObjectMeta.Kind)}
              onClickAuthorizeButton={() => handleAuthorize(item)}
              onClickManageIntegrationButton={() => handleManageIntegrationClick(item)}
              onClickRemoveButton={() => handleDeleteModal(item)}
              showAuthorizeButton={getAuthState(item)}
              showRemoveButton
              showManageIntegrationButton={getManageIntegrationState(item)}
              subtitle={getSubtitle(item)}
              title={item.ObjectMeta.Name}
              width='500px'
              labelIcon={faWarning}
              labelText='Unauthorized'
              labelVariant='warning'
              showLabel={getAuthState(item)}
              showPublicTag={isPublicApp(item)}
            />
          ))}

        {showDeleteModal && (
          <DeleteIntegrationModal
            integrationName={integration}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            handleDeleteIntegrationClick={handleDeleteIntegrationClick}
          />
        )}
      </div>
    </>
  )
}

export { Active }
