import { Apple } from '@material-ui/icons'
import WindowsIcon from 'Components/CustomIcons/Windows'
import { useMemo, useRef, useState } from 'react'

const useAgent = () => {
  const APP_LISTING = useRef([
    {
      name: 'Procyon Authenticator App',
      osRequirement: 'MacOS.10.14 or Above',
      osName: 'MacOS',
      category: 'Desktop App',
      Icon: Apple,
      link: 'https://procyonagent.s3.us-west-1.amazonaws.com/ProcyonAuthAppInstallation.dmg'
    },
    {
      name: 'Procyon Authenticator App',
      osRequirement: 'Windows 7 or Above',
      osName: 'Windows',
      category: 'Desktop App',
      Icon: WindowsIcon,
      link: 'https://procyonagent.s3.us-west-1.amazonaws.com/ProcyonAuthAppInstallation.exe'
    }
  ])

  const APP_LISTING_AGENTLESS = useRef([
    {
      name: 'Procyon AgentLess CLI',
      osRequirement: 'MacOS.10.14 or Above',
      osName: 'MacOS',
      category: 'Desktop App',
      Icon: Apple,
      link: 'https://procyon-agentless.s3.ap-south-1.amazonaws.com/ProcyonAgentLessCLI.pkg'
    },
    {
      name: 'Procyon AgentLess CLI',
      osRequirement: 'Windows 7 or Above',
      osName: 'Windows',
      category: 'Desktop App',
      Icon: WindowsIcon,
      link: 'https://procyon-agentless.s3.ap-south-1.amazonaws.com/Procyon+Agentless+CLI+Setup.exe'
    }
  ])

  const [activeDeviceOS, setActiveDeviceOS] = useState(() => {
    let Name = 'MacOS'
    if (navigator.userAgent.indexOf('Win') !== -1) Name = 'Windows'
    if (navigator.userAgent.indexOf('Mac') !== -1) Name = 'MacOS'
    return Name
  })

  const [activeAgentLessDeviceOS, setActiveAgentLEssDeviceOS] = useState(() => {
    let Name = 'MacOS'
    if (navigator.userAgent.indexOf('Win') !== -1) Name = 'Windows'
    if (navigator.userAgent.indexOf('Mac') !== -1) Name = 'MacOS'
    return Name
  })

  const activeApp = useMemo(() => {
    const _activeApp = APP_LISTING.current.find((app) => app.osName === activeDeviceOS)
    return _activeApp || APP_LISTING.current[0]
  }, [activeDeviceOS])

  const activeAgentLessApp = useMemo(() => {
    const _activeAgentLessApp = APP_LISTING_AGENTLESS.current.find(
      (app) => app.osName === activeAgentLessDeviceOS
    )
    return _activeAgentLessApp || APP_LISTING_AGENTLESS.current[0]
  }, [activeAgentLessDeviceOS])
  return {
    activeApp,
    activeAgentLessApp,
    activeDeviceOS,
    activeAgentLessDeviceOS,
    setActiveAgentLEssDeviceOS,
    setActiveDeviceOS,
    APP_LISTING: APP_LISTING.current,
    APP_LISTING_AGENTLESS: APP_LISTING_AGENTLESS.current
  }
}

export default useAgent
