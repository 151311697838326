import { faDatabase, faHelmetSafety, faServer } from '@fortawesome/pro-solid-svg-icons'
import { EditableLabelContent, LabelContent } from 'V2Components'
import {
  isOrgAccount,
  useCloudAccounts,
  useCloudDetailsProvider,
  useCloudsResources
} from 'features/clouds'
import { getResourceName } from 'features/resources'
import { Label, LabelCheckbox, TargetIcon } from 'procyon-ui'
import React, { useState } from 'react'
import { AccountNotificationChannels } from '../../../AccountNotificationChannels'
import { SSHPublicKeyLabelContent } from '../SSHPublickeyLabelContent'

const AWSAccount = ({}) => {
  const { cloud: account } = useCloudDetailsProvider()

  const { getAccountTargets } = useCloudsResources()
  const { getChildAccounts } = useCloudAccounts()

  const isOrg = isOrgAccount(account)
  const childAccounts = getChildAccounts(account)

  const targets = getAccountTargets(account)
  const namespace = account.ObjectMeta.Namespace

  console.log(targets, '===== targets')
  return (
    <div className='w-[100%]'>
      <LabelContent content={getResourceName(account)} title='Name' />

      <LabelContent title='Description' content={account.Spec.Description} showEmptyLabel />
      <LabelContent
        content={<Label text={namespace} variant={namespace === 'default' ? 'grayBlue' : 'gray'} />}
        title='Org'
      />
      <LabelContent
        title='Notification Channels'
        content={
          <div className='flex gap-2 flex-wrap'>
            <AccountNotificationChannels
              account={account}
              Fallback={<Label text='No Channels' />}
            />
          </div>
        }
      />
      {account.Spec.AwsSpec.AssumeRoleARN && (
        <>
          <LabelContent
            title='Assume Role ARN'
            content={account.Spec.AwsSpec.AssumeRoleARN}
            showEmptyLabel
          />
          <LabelContent title='External ID' content={account.Spec.AwsSpec.ExternalID} />
        </>
      )}
      {account.Spec.AwsSpec.OuID && (
        <LabelContent content={account.Spec.AwsSpec.OuID} title='OU ID' />
      )}
      <LabelContent content={account.Spec.AccountID} title='Account ID' />
      {isOrg && (
        <LabelContent
          content={
            <Label icon={<TargetIcon type='AWS_CHILD' />} text={childAccounts?.length || 0} />
          }
          title='Child Accounts'
        />
      )}
      {!isOrg && (
        <>
          <LabelContent
            content={<Label iconButton={faHelmetSafety} text={targets.AppRole?.length || 0} />}
            title='AppRole'
          />
          <LabelContent
            content={<Label iconButton={faServer} text={targets.Server?.length || 0} />}
            title='Server'
          />
          <LabelContent
            content={<Label iconButton={faDatabase} text={targets.Database?.length || 0} />}
            title='Database'
          />
          <LabelContent
            content={
              <Label
                icon={<TargetIcon className='rounded' type='KUBE_CLUSTERS' />}
                text={targets.KubeCluster?.length || 0}
              />
            }
            title='Kubenetes Clusters'
          />
          <LabelContent
            content={
              <Label
                icon={<TargetIcon className='rounded' type='RDP_SERVER' />}
                text={targets.RDPServer?.length || 0}
              />
            }
            title='RDP Server'
          />
        </>
      )}
      <SSHPublicKeyLabelContent />
    </div>
  )
}

export { AWSAccount }
