import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { useCloudAccounts } from 'features/clouds'
import { isChildAccount, isOrgAccount } from 'features/clouds/utils'
import { getResourceName } from 'features/resources'
import { useClouds } from '../../Clouds.utils'

export const useAWSAccounts = ({ searchKey, sortOption }) => {
  const { accounts } = useClouds()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const SortFiltersFunctionsMap = {
    all: () => true,
    ['Child Accounts'](account) {
      return account.Spec.ParentAccount.RefID !== '0'
    },
    ['Normal Accounts'](account) {
      return !isOrgAccount(account) && account.Spec.ParentAccount.RefID === '0'
    },
    ['Org Accounts']: isOrgAccount
  }

  const getSortOptions = () => {
    const opts = []
    applySearchQuery(accounts).forEach((acc) => {
      let option = ''
      if (isChildAccount(acc)) option = SORT_OPTIONS.CHILD
      else if (isOrgAccount(acc)) option = SORT_OPTIONS.ORG
      else option = SORT_OPTIONS.NORMAL

      if (!opts.includes(option)) opts.push(option)
    })
    return opts
  }

  const getFilteredAccounts = () => {
    const sortFn = SortFiltersFunctionsMap[sortOption]
    const list = sortFn ? accounts.filter(sortFn) : accounts
    return applySearchQuery(list)
  }

  const filteredAccounts = getFilteredAccounts()

  return { sortOptions: getSortOptions(), filteredAccounts }
}

const SORT_OPTIONS = {
  CHILD: 'Child Accounts',
  ORG: 'Org Accounts',
  NORMAL: 'Normal Accounts'
}
