import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { createRsrcKey, getResourceName, getResourceType, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { ResourceDropDownItem, Typography } from 'procyon-ui'
import React from 'react'
// TODO Make this a standalone stateless component
function ResourcesTab({ rsrcsRefs, fallbackMessage = 'No resources to show.' }) {
  const { getObjectRef } = useMultiSlice([...IAMResourcesSliceNames, ...TargetsSliceNames, 'applicationList'])

  const rsrcItems = (() => {
    const allRsrcRefs = rsrcsRefs.map(reverseRsrcKey)
    return getObjectRef(allRsrcRefs).map((e) => ({
      Icon: getRsrcIcon(e),
      name: getResourceName(e),
      type: getResourceType(e),
      key: createRsrcKey(e)
    }))
  })()

  return (
    <div className='h-[500px] overflow-y-auto'>
      {!rsrcItems.length && <Typography variant='body-regular'>{fallbackMessage}</Typography>}
      {rsrcItems.map((e) => (
        <ResourceDropDownItem key={e.key} icon={<e.Icon />} resourceName={e.name} resourceType={e.type} />
      ))}
    </div>
  )
}

export { ResourcesTab }
