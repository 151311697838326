import React from 'react'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import { Dashboard } from '../views/Dashboard'

const DashboardRoutes = () => {
  return (
    <>
      <Route exact path={['/admin/dashboard']} component={Dashboard} />
    </>
  )
}

export { DashboardRoutes }
