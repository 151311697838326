import React from 'react'
import { Route } from 'react-router'
import { ApplicationDetails, Applications } from '../views'
import { RepositoriesDetails } from 'features/github'

const ApplicationRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/applications']}>
        <Applications />
      </Route>
      <Route exact path={['/admin/applications/:appName', '/admin/applications/:appName/:tabName']}>
        <ApplicationDetails />
      </Route>
      <Route exact path={['/admin/applications/repo/:appName']}>
        <RepositoriesDetails />
      </Route>
      {/* <Route exact path={['admin/applications/team-details/:teamName']}>
         <TeamDetails /> 
      </Route> */}

      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/applications']}>
        <Applications />
      </Route>
      <Route exact path={['/user/applications/:appName', '/user/applications/:appName/:tabName']}>
        <ApplicationDetails />
      </Route>
    </>
  )
}

export { ApplicationRoutes }
