export const Endpoints = {
  JiraIntegration: 'jiraintegrations',
  SlackIntegration: 'slackintegrations',
  ServiceNowIntegration: 'servicenowintegrations',
  MsTeamsIntegration: 'msteamsintegrations'
}

export const IntegrationListEndpoints = {
  JiraIntegration: 'jiraIntegrationsList',
  SlackIntegration: 'slackIntegrationList',
  ServiceNowIntegration: 'servicenowintegrations',
  MsTeamsIntegration: 'msteamsintegrations'
}

export const AllIntegrationData = [
  {
    name: 'Slack',
    type: 'slack',
    icon: 'SLACK',
    category: 'Collaboration',
    caption:
      'Slack is a collaboration platform that streamlines team communication, offering channels, messaging, and integrations to enhance productivity and collaboration in workplaces.'
  },
  {
    name: 'Jira',
    type: 'jira',
    icon: 'JIRA',
    category: 'Task Management',
    caption:
      'JIRA is a popular project management and issue tracking tool developed by Atlassian, allowing users to organize, track, and manage tasks and projects efficiently.'
  },
  {
    name: 'ServiceNow',
    type: 'servicenow',
    icon: 'SERVICENOW',
    category: 'Task Management',
    caption:
      'ServiceNow is a cloud-based platform that provides IT service management (ITSM) and business process automation, streamlining workflows and improving efficiency across organizations.'
  },
  {
    name: 'Microsoft Team',
    type: 'msteam',
    icon: 'TEAMS',
    category: 'Collaboration',
    caption:
      'Microsoft Teams is a collaboration platform that integrates chat, video meetings, file storage, and application integration. It enables team communication and collaboration through a unified interface, facilitating seamless remote and in-office work.'
  }
]
