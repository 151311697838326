
import { Box, Button, makeStyles } from '@material-ui/core'
import { Fullscreen } from '@material-ui/icons'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import React from 'react'
import { mostActiveSampleData as data } from './SampleData'
import { options, columns } from './TableMeta'
const useStyle = makeStyles(theme => ({
  name: {
    '& > span': { justifyContent: 'flex-start !important' }
  }
}))
const AlertsLog = () => {
  const classes = useStyle()
  const headerClassNames = { name: classes.name }
  const handleClick = () => {
    // TODO handle row click here
  }
  return (
    <Box px={1} display='flex' flexDirection='column' justifyContent='space-between'>

      <AnalyticTable padding={{ top: 0.75 }} rowHeight='4rem' headerJustify='flex-end' vAlign='top' columns={columns(headerClassNames, handleClick)} options={options()} data={data} />
      <Box pt={0.725} pb={0.312} width='100%' display='flex' justifyContent='space-between'>
        <Button color='primary' startIcon={<Fullscreen />}>Expand Alerts</Button>
      </Box>
    </Box>
  )
}

export default AlertsLog
