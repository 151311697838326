import {
  faHourglassEmpty,
  faBadgeCheck,
  faServer,
  faDatabase,
  faSquareInfo
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getDeviceName } from 'Utils/PureHelperFuctions'
import { dateSortComparator, getResourceName } from 'features/resources'
import { ResourceTypeToKindMap, parseLogSessionData } from 'features/sessions'
import _ from 'lodash'
import moment from 'moment'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

export const useSessionLogsTable = ({ sessions, searchKey }) => {
  const { slices, getObjectRef } = useMultiSlice([
    'deviceList',
    'serverList',
    'databases',
    'kubeClusters',
    'awsResources'
  ])

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction(row, queryKey) {
      const { device, userName, status, resourceType, resourceName } = row
      const searchString = Object.values({
        device,
        userName,
        status,
        resourceType,
        resourceName,
        rsrc: ResourceTypeToKindMap[resourceType]
      }).join('')
      return searchString.toLowerCase().includes(queryKey)
    }
  })

  const getRows = () => {
    const rows = []
    sessions.map((sess) => {
      try {
        const data = parseLogSessionData(sess)
        if (!data.length) throw new Error('No session data')
        const device = _.find(slices.deviceList, { ObjectMeta: { Name: data[0].Device } })
        const ref = data[0].Ref || {}
        const rsrc = ref ? getObjectRef({ ...ref, RefID: ref.RefID?.toString() }) : null
        const rowData = {
          id: sess.SessionKey,
          auditTime: data[0].AuditTime,
          userName: data[0].User,
          device: getDeviceName(device) || data[0].Device,
          status: sess.Audited,
          resourceType: sess.ResoureceType,
          resourceName: rsrc ? getResourceName(rsrc) : ''
        }
        rows.push(rowData)
      } catch (error) {
        console.error('[error](failed parsing session):', sess)
      }
    })

    return applySearchQuery(rows)
  }

  // latest one first
  return { rows: getRows().sort((v1, v2) => v2.auditTime.localeCompare(v1.auditTime)) }
}

/**
 *
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const columns = () => [
  {
    dataIndex: 'auditTime',
    title: 'Log Time',
    width: 250,
    sorter: (v1, v2) => dateSortComparator(v1.auditTime, v2.auditTime),
    render(value) {
      return (
        <div className='flex gap-2 items-center'>
          <FontAwesomeIcon color='#3267D6' icon={faSquareInfo} />
          <Typography className='!text-[#3267D6]' variant='body-regular'>
            {moment(value).format('MMMM Do YYYY, h:mm A')}
          </Typography>
        </div>
      )
    }
  },
  {
    dataIndex: 'userName',
    title: 'User Name',
    align: 'left',
    sorter: (v1, v2) => v1.userName.localeCompare(v2.userName),
    width: 250
  },
  {
    dataIndex: 'device',
    title: 'Device',
    align: 'left',
    sorter: (v1, v2) => v1.device.localeCompare(v2.device),
    width: 250
  },
  {
    dataIndex: 'resourceType',
    title: 'Resource Type',
    width: 120,
    sorter: (v1, v2) => v1.resourceType.localeCompare(v2.resourceType),
    render(value) {
      switch (value) {
        case 'SSH':
          return <Label width='90px' variant='success' iconButton={faServer} text='Server' />
        case 'DB':
          return <Label width='90px' variant='grayBlue' iconButton={faDatabase} text='Database' />
        case 'K8':
          return (
            <Label
              width='90px'
              variant='grayBlue'
              icon={<TargetIcon type='KUBE_CLUSTERS' />}
              text='K8'
            />
          )
        case 'ECS':
          return (
            <Label
              width='90px'
              variant='grayBlue'
              icon={<TargetIcon className='rounded' type='AWS_ECS' />}
              text='ECS'
            />
          )
        case 'RDP':
          return (
            <Label
              width='90px'
              variant='grayBlue'
              icon={<TargetIcon className='rounded' type='RDP_SERVER' />}
              text='RDP'
            />
          )
        default:
          return <Label text={value} iconButton={faHourglassEmpty} variant='warning' />
      }
    }
  },
  {
    dataIndex: 'resourceName',
    title: 'Resource',
    align: 'left',
    sorter: (v1, v2) => v1.resourceName.localeCompare(v2.resourceName),
    width: 300,
    render(value) {
      if (!value) return <Label text='Not Found' />
      return <Typography variant='body-regular'>{value}</Typography>
    }
  },
  {
    dataIndex: 'status',
    title: 'Status',
    width: 250,
    sorter: (v1, v2) => +v2.status - +v1.status,
    render(value) {
      return value ? (
        <Label variant='success' iconButton={faBadgeCheck} text='Audited' />
      ) : (
        <Label text='Non Audited' iconButton={faHourglassEmpty} variant='warning' />
      )
    }
  }
]
