import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRef, createRsrcKey, getRsrcAccountRef } from 'features/resources'
import { TargetsSliceNames } from 'features/targets'

export function useCloudsResources() {
  const { slices } = useMultiSlice([...TargetsSliceNames])

  const getAccountTargets = (accountRef) => {
    const map = { _meta: { count: 0 } }
    const rsrcs = [
      ...slices.appRolesList,
      ...slices.serverList,
      ...slices.databases,
      ...slices.kubeNamespaces,
      ...slices.kubeClusters,
      ...slices.rdpServers
    ]
    const accKey = createRsrcKey(accountRef)
    rsrcs.forEach((rsrc) => {
      const rsrcRef = createRef(rsrc)
      const rsrcAccKey = createRsrcKey(getRsrcAccountRef(rsrc))
      //* Check if the target resource belong to the account
      if (rsrcAccKey !== accKey) return
      const refs = map[rsrcRef.RefKind] || []
      // Add target ref
      refs.push(rsrcRef)
      // add new refs in map object
      map[rsrcRef.RefKind] = refs
      // update count
      map._meta.count += 1
    })

    return map
  }

  return { getAccountTargets }
}
