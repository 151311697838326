import { userApis } from './userApis'
import { credentialApis } from './credentialApis'
import serverApis from './serverApis'
import authApis from './authApis'
import { appRoleApis } from './appRoleApis'
import scimserverApis from './scimserverApis'
import activityLogsApis from './activityLogsApis'
import { credentialTypeApis } from './credentialTypeApis'
import { credentialInstanceApis } from './credentialInstanceApis'
import { accessGrantApis } from './accessGrantApis'
import { projectApis } from './projectApis'
import { TenantProfilesApi } from './tenantprofilesApis'
/**
 * Authentication APIS
 */
export const checkAuth = authApis.checkAuth

/**
 * USERS TAB APIS
 */
export const fetchUserPreference = userApis.fetchUserPreference
export const setUserPreferrences = userApis.setUserPreferrences

export const getGroupList = userApis.getGroupList
export const deleteGroup = userApis.deleteGroup

export const getIdpList = userApis.getIdpList
export const updateIdp = userApis.updateIdp
export const deleteIdp = userApis.deleteIdp

export const updateDevice = userApis.updateDevice
export const deleteDevice = userApis.deleteDevice
export const getRoleBasedPermissions = userApis.getRoleBasedPermissions

/**
 * CREDENTIALS APIS
 **/
export const getCredentialsList = credentialApis.getCredentialsList
export const createCredentials = credentialApis.createCredentials
export const updateCredentials = credentialApis.updateCredentials
export const deleteCredential = credentialApis.deleteCredential
/**
 * CREDENTIALS TYPE APIS
 **/
export const getCredentialTypesList = credentialTypeApis.getCredentialTypesList
export const updateCredentialType = credentialTypeApis.updateCredentialType
export const createCredentialType = credentialTypeApis.createCredentialType
export const deleteCredentialType = credentialTypeApis.deleteCredentialType
/**
 * CREDENTIALS TYPE APIS
 **/
export const getCredentialInstanceList = credentialInstanceApis.getCredentialInstanceList
export const updatecredentialInstance = credentialInstanceApis.updatecredentialInstance
export const createcredentialInstance = credentialInstanceApis.createcredentialInstance
export const deletecredentialInstance = credentialInstanceApis.deletecredentialInstance

/**
 * SERVER APIS
 */
export const setFavoriteServerList = serverApis.setFavoriteServerList
export const getFavoriteServerList = serverApis.getFavoriteServerList

/**
 * App Role Apis APIS
 */
export const getShhAccessList = accessGrantApis.getShhAccessList
export const getRoleAccessList = accessGrantApis.getRoleAccessList
export const setFavoriteAppRolesList = appRoleApis.setFavoriteAppRolesList
export const getFavoriteAppRolesList = appRoleApis.getFavoriteAppRolesList

/** Scimservers Apis */
export const scimserverGetCredentials = scimserverApis.scimserverGetCredentials
/** Activity Logs Apis */
export const getWebProxyActivityLogs = activityLogsApis.getWebProxyActivityLogs
export const getSshActivityLogs = activityLogsApis.getSshActivityLogs
export const getEventLogs = activityLogsApis.getEventLogs
export const getProxyAuditLogs = activityLogsApis.getProxyAuditLogs

export const getFavouriteProjects = projectApis.getFavouriteProjects
export const setFavouriteProject = projectApis.setFavouriteProject

export const getTenantprofiles = TenantProfilesApi.getTenantprofiles
