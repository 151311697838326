import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { cancelApprovalRequest } from 'features/approvals'
import { Typography } from 'procyon-ui'
import React from 'react'

export const CancelApprovalRequestModal = ({ approvalReq, onCancel, onSuccess }) => {
  const givenName = approvalReq.GivenName

  const handleCancel = async () => {
    const req = await cancelApprovalRequest(approvalReq)
    updateSliceData(req)
    enqueueNotification('Access Request cancelled successfully!', 'info')
    onSuccess()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Yes, Cancel Request'
        actionButtonVariant='danger'
        loadingMessage={`Canceling request ${givenName}`}
        showModal
        onActionClick={handleCancel}
        onCancel={onCancel}
        Content={
          <div className='mt-4'>
            <Typography className='mb-3 !block' variant='body-regular'>
              Once <span className='text-red-500 mx-[1px]'>canceled</span> admin won't be able to
              approve. But, can be <strong>re-requested</strong> to revert back.
            </Typography>
          </div>
        }
        alertMessage={
          <>
            Are you sure you want to cancel request
            <strong className='mx-1' title={givenName}>
              {givenName}
            </strong>
            ?
          </>
        }
      />
    </div>
  )
}
