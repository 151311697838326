import { TabGroup } from 'procyon-ui'
import React, { useMemo, useState, useEffect } from 'react'
import { OverviewTab } from './OverviewTab'
import { AccessGraphTab } from './AccessGraphTab'
import { AccessSummaryTab } from './AccessSummaryTab'
import { ActivePolicies, Respositories, TeamsandGroups } from 'features/github'
import { ReadOnlyAppTypes, getApplicationType, useAppDetailsContext } from 'features/applications'
import useAppView from 'Core/Hooks/useAppView'
import { ActivityLogsTab } from './ActivityLogsTab'
import { SFUsers, SFPermission } from 'features/salesForce'
import { useParams } from 'react-router'
import { createRsrcKey } from 'infra/redux/reducers'
import { TagsAndLabelsView } from 'features/resources'

function Tabs() {
  const { app } = useAppDetailsContext()
  const appType = getApplicationType(app)
  const { appView } = useAppView()
  const { tabName } = useParams()
  const [activeTab, setActiveTab] = useState(tabName)

  const TAB_INDEX_MAP = {
    overview: 0,
    permissions: 1,
    users: 2,
    'access-summary': 3,
    'access-graph': 4,
    'activity-logs': 5,
    repositories: 6,
    'teams-users': 7,
    'active-policies': 8
  }

  const TAB_NAME_MAP = {
    0: 'overview',
    1: 'permissions',
    2: 'users',
    3: 'access-summary',
    4: 'access-graph',
    5: 'activity-logs',
    6: 'repositories',
    7: 'teams-users',
    8: 'active-policies'
  }

  const tabs = useMemo(() => {
    const list = [
      {
        label: 'Overview',
        tabContent: <OverviewTab />
      }
    ]

    if (!ReadOnlyAppTypes[appType]) {
      if (appType === 'SALESFORCE') {
        if (appView === 'admin') {
          list.push(
            {
              label: 'Permissions',
              tabContent: <SFPermission />
            },
            {
              label: 'Users',
              tabContent: <SFUsers />
            }
          )
        } else {
          list.push({
            label: 'Permissions',
            tabContent: <SFPermission />
          })
        }
      } else if (appType !== 'GITHUB') {
        list.push(
          { label: 'Access Graph', tabContent: <AccessGraphTab /> },
          { label: 'Access Summary', tabContent: <AccessSummaryTab /> },
          { label: 'Activity Logs', tabContent: <ActivityLogsTab /> }
        )
      } else if (appView === 'admin') {
        list.push(
          { label: 'Repositories', tabContent: <Respositories /> },
          { label: 'Teams & Users', tabContent: <TeamsandGroups /> },
          { label: 'Active Policies', tabContent: <ActivePolicies /> }
        )
      } else {
        list.push({ label: 'Repositories', tabContent: <Respositories /> })
      }
    }

    return [
      ...list,
      {
        label: 'Tags and Labels',
        tabContent: <TagsAndLabelsView rsrc={app} hideCloudTags />
      }
    ]
  }, [appType, appView])

  useEffect(() => {
    if (tabName) {
      setActiveTab(tabName)
    }
  }, [tabName])

  return (
    <div>
      <TabGroup
        key={createRsrcKey(app)}
        controllable={tabName === 'permissions'}
        activeIndex={tabName === 'permissions' ? TAB_INDEX_MAP[activeTab] : 0}
        onChangeTab={(_, v) => {
          setActiveTab(TAB_NAME_MAP[v])
        }}
        tabs={tabs}
      />
    </div>
  )
}

export { Tabs }
