import { Checkbox, makeStyles, Paper } from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'
import MountOn from 'Components/MountOn'
import React, { useCallback } from 'react'
import TableActionButton from './TableActionButton'
import { useLayoutContext } from 'features/layout'

const getMarginTop = (newDesign) => {
  if (newDesign) {
    return '1px'
  } else return 0
}
const useStyles = makeStyles((theme) => ({
  root: {
    padding: (props) => (props.newDesign ? 0 : theme.spacing(0.2)),
    paddingLeft: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: (props) => (props.isSideMenuOpen ? '75%' : '91%'),
    [theme.breakpoints.up('xl')]: {
      width: (props) => (props.isSideMenuOpen ? '80%' : '91%')
    },
    marginLeft: (props) => (props.newDesign ? '1.5%' : '0'),
    marginTop: (props) => getMarginTop(props.newDesign),
    background: '#f9f9f9',
    borderRadius: '0px',
    border: 'none',
    position: 'absolute',
    zIndex: 999
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '8px'
    }
  }
}))

const TableBulkActions = ({
  filteredData,
  checked,
  selectedRows,
  toggleCompleteSelection,
  BulkActions,
  Length,
  title,
  newDesign = true
}) => {
  const { isSideMenuOpen } = useLayoutContext()
  const classes = useStyles({ newDesign, isSideMenuOpen })

  const handleBulkClick = useCallback(
    (onClick, selectedRows) => {
      onClick && onClick(selectedRows, filteredData)
    },
    [filteredData]
  )

  return (
    <MountOn on={selectedRows.length}>
      <Paper variant='outlined' className={classes.root}>
        <span className={classes.info}>
          <Checkbox
            indeterminateIcon={<RemoveCircle color='primary' />}
            indeterminate={!checked}
            color='primary'
            checked={checked}
            onClick={toggleCompleteSelection}
          />
          {Length} {title} Selected
        </span>
        <span>
          {BulkActions &&
            BulkActions.map((action) => {
              const { variant, Icon, label, onClick, loading } = action
              return (
                <TableActionButton
                  newDesign={newDesign}
                  loading={loading}
                  key={label}
                  dataCy={'bulk ' + label}
                  variant={variant}
                  Icon={Icon}
                  label={label}
                  onClick={() => handleBulkClick(onClick, selectedRows)}
                />
              )
            })}
        </span>
      </Paper>
    </MountOn>
  )
}

export default TableBulkActions
