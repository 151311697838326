import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { SearchInput } from 'V2Components'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

function Header({ searchKey, onSearchKeyChange, onAddUsersClick }) {
  return (
    <div className='flex justify-between items-center'>
      <Typography variant='h2'>Users</Typography>
      <div className='flex gap-4 mt-3'>
        <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={onSearchKeyChange} />
        <Button onClick={onAddUsersClick}  size='md' variant='primary' icon={faPlus}>
          Add New
        </Button>
      </div>
    </div>
  )
}

export { Header }
