import { useDashboardDataProvider } from 'features/dashboard'
import { getResourceName } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'

export const useTopUsersTable = () => {
  const { heatMap, startDays } = useDashboardDataProvider()

  const getData = () => {
    const sortedUsers = Object.values(heatMap.users).sort((a, b) => b.count - a.count)

    return sortedUsers.map((data, i) => {
      return {
        userName: {
          name: getResourceName(data.user),
          index: i + 1
        },
        email: data.user.Spec.EmailID,
        sessions: data.count
      }
    })
  }

  return { rowData: getData(), startDays }
}

/**
 *
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const columns = () => [
  {
    dataIndex: 'userName',
    title: 'Name',
    align: 'left',
    width: 80,
    render(value) {
      return (
        <div className='flex items-center justify-start gap-4'>
          <div className='!h-6 my-1 w-6 flex items-center justify-center !text-[#ADBEDE] rounded-sm bg-[#F1F4FB]'>
            {value.index}
          </div>
          <Typography className='' variant='body-regular'>
            {value.name}
          </Typography>
        </div>
      )
    }
  },
  {
    dataIndex: 'email',
    title: 'Email',
    align: 'left',
    width: 80
  },
  {
    dataIndex: 'sessions',
    title: 'Sessions',
    align: 'left',
    width: 20
  }
]
