import React from 'react'
import { Box, Typography } from '@material-ui/core'
const Count = ({ count, align = 'flex-end', variant = 'subtitle2' }) => {
  return (
    <Box height='100%' width='100%' display='flex' alignItems='flex-start' justifyContent={align}>
      <Typography variant={variant}>{count}</Typography>
    </Box>
  )
}

export default Count
