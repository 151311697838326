import useMultiSlice from 'Core/Hooks/useMultiSlice'
import _ from 'lodash'

export const usePrivateKubeNamespaces = () => {
  const { slices, getObjectRef } = useMultiSlice(['kubeNamespaces', 'kubeClusters'])

  const getKubeNamespaceAppRolesMap = (kube) => {
    const cluster = getObjectRef(kube.Spec.Cluster)
    console.log('[kube](cluster):', cluster)
    if (!cluster) return {}
    return _.get(cluster, 'Spec.IAMRoles.Map')
  }

  const privateKubeNamespaces = slices.kubeNamespaces.filter((e) => e.Spec.Type === 'PRIVATE')

  return { privateKubeNamespaces, getKubeNamespaceAppRolesMap }
}
