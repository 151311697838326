import { faClose, faCopy, faRefresh, faSave, faTimer } from '@fortawesome/pro-solid-svg-icons'
import { useAPIKeys } from 'features/settings'
import moment from 'moment'
import { Button, IconButton, TextInput, Tooltip, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { FullScreenAlertModal, LabelContent } from 'V2Components'
import { ExtendExpiryModal } from '../ExtendExpiryModal'

const ApiKeys = () => {
  const [showRegenApiSecretModal, setShowRegenApiSecretModal] = useState(false)
  const [deleteConfirmText, setDeleteConfirmText] = useState('')
  const [showExtendExpiryModal, setShowExtendExpiryModal] = useState(false)
  const [showExtendExpiryInput, setShowExtendExpiryInput] = useState(false)
  const [keyExpiryInDaysInput, setKeyExpiryInDaysInput] = useState('0')

  const {
    apiKeys,
    regenerateApiSecret,
    status,
    tenantApiKeyObj,
    updateApiKeysObj,
    isApiKeyExpired
  } = useAPIKeys()

  const handleCopyKey = (keyName) => {
    const k = apiKeys[keyName] || ''
    navigator.clipboard.writeText(k)
    enqueueNotification(`${addSpaces(keyName)} Copied to clipboard!`, 'info')
  }

  const handleRegenClick = async () => {
    try {
      await regenerateApiSecret()
      enqueueNotification('A new API Secret has been successfully generated.', 'info')
    } catch (error) {
      enqueueNotification('Error regenerating API Secret.', 'error')
    } finally {
      setShowRegenApiSecretModal(false)
      setDeleteConfirmText('')
    }
  }

  const isLoaded = status === 'IDLE'

  return (
    <div>
      <LabelContent
        content={
          isLoaded ? (
            <div className='flex justify-between'>
              <Typography variant='body-regular'>{apiKeys.ApiKey}</Typography>
              <IconButton onClick={() => handleCopyKey('ApiKey')} icon={faCopy} variant='clear' />
            </div>
          ) : (
            `${status}..`
          )
        }
        title='API Key'
      />
      <LabelContent
        content={
          isLoaded ? (
            <div className='flex justify-between'>
              <Typography variant='body-regular'>
                *********************************************************************************
              </Typography>
              <IconButton
                onClick={() => handleCopyKey('ApiSecret')}
                icon={faCopy}
                variant='clear'
              />
            </div>
          ) : (
            `${status}..`
          )
        }
        title='API Secret'
      />
      <LabelContent
        fullWidth
        content={
          isLoaded ? (
            <div className='flex justify-between items-center'>
              {isApiKeyExpired ? (
                <div className='flex gap-2'>
                  <Typography
                    className={cn('flex items-center gap-2', {
                      '!text-[#e02a47]': isApiKeyExpired
                    })}
                    variant='body-regular'
                  >
                    {moment(apiKeys.NotAfter).format('MMMM Do YYYY, h:mm A')}
                  </Typography>
                  <span className='p-[2px] rounded-full px-3 bg-[#e02a47] text-white'>Expired</span>
                </div>
              ) : (
                <Typography className={'flex items-center gap-2'} variant='body-regular'>
                  {moment(apiKeys.NotAfter).format('MMMM Do YYYY, h:mm A')}
                  {moment(apiKeys.NotAfter).diff(new Date(), 'days') <= 90 && (
                    <span className='p-[2px] rounded-full px-3 bg-[#FFDE21] text-black'>
                      Expiring in {moment(apiKeys.NotAfter).diff(new Date(), 'days')} Days
                    </span>
                  )}
                </Typography>
              )}
              {!showExtendExpiryInput && (
                <Button
                  onClick={() => setShowExtendExpiryInput(true)}
                  icon={faTimer}
                  className='ml-6'
                  variant={isApiKeyExpired ? 'primary' : 'gray'}
                >
                  Extend Expiry
                </Button>
              )}
              {showExtendExpiryInput && (
                <div className='ml-4 flex gap-2'>
                  <Tooltip
                    arrow
                    title={`Expires on ${moment()
                      .add(keyExpiryInDaysInput, 'days')
                      .format('MMMM Do YYYY, h:mm A')}`}
                  >
                    <TextInput
                      sx={{ width: '100px' }}
                      value={keyExpiryInDaysInput}
                      onChange={(e) => setKeyExpiryInDaysInput(e.target.value)}
                      placeholder='Extend in days'
                      type='number'
                    />
                  </Tooltip>
                  <IconButton
                    icon={faClose}
                    onClick={() => setShowExtendExpiryInput(false)}
                    variant='gray'
                  />
                  <IconButton
                    disabled={!keyExpiryInDaysInput || Number(keyExpiryInDaysInput) <= 0}
                    icon={faSave}
                    onClick={() => setShowExtendExpiryModal(true)}
                    variant='grayBlue'
                  />
                </div>
              )}
            </div>
          ) : (
            `${status}..`
          )
        }
        title='Expires'
      />
      <LabelContent content={isLoaded ? apiKeys.CntrlEP : `${status}..`} title='Endpoint' />

      <LabelContent
        title=''
        content={
          <div className='flex gap-2'>
            <Button
              onClick={() => setShowRegenApiSecretModal(true)}
              variant='gray'
              icon={faRefresh}
            >
              Regenerate API Secret
            </Button>
            {/*  //!TODO Removed temporary */}
            {/* <Button onClick={() => setShowRDPConfigModal(true)} icon={faGear} variant='gray'>
          Configure RDP Storage
        </Button> */}
          </div>
        }
      />

      <FullScreenAlertModal
        actionButtonText='Regen'
        actionButtonVariant='danger'
        alertMessage={`Regenerate API Secret`}
        loadingMessage={`Regenerating API Secret`}
        onActionClick={handleRegenClick}
        onCancel={() => {
          setShowRegenApiSecretModal(false)
          setDeleteConfirmText('')
        }}
        disableAction={deleteConfirmText !== 'regen'}
        Content={
          <div>
            <div className='mt-4'>
              <Typography className='my-2' variant='buttonLabel-regular'>
                The current API Secret will be permanently
                <span className='text-red-500 mx-1'>deleted</span>
                and a new one will be created.
              </Typography>
              <TextInput
                value={deleteConfirmText}
                onChange={(e) => setDeleteConfirmText(e.target.value)}
                sx={{ width: '100%' }}
                label={`Enter 'regen' to confirm`}
              />
            </div>
          </div>
        }
        showModal={showRegenApiSecretModal}
      />
      {showExtendExpiryModal && (
        <ExtendExpiryModal
          onCancel={() => setShowExtendExpiryModal(false)}
          onSuccess={(data) => {
            setShowExtendExpiryModal(false)
            updateApiKeysObj(data)
          }}
          newTimeDuration={keyExpiryInDaysInput}
          tenantApiKeyObj={tenantApiKeyObj}
        />
      )}
    </div>
  )
}

export { ApiKeys }
