import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { SummaryHeader } from 'V2Components'
import { getResourceName } from 'features/resources'
import { Button, Label } from 'procyon-ui'
import React from 'react'

const Header = ({ onSubmit, error = '', isEditMode, workload }) => {
  const { appView } = useAppView()

  const can = useCanGoBack(
    isEditMode ? `/${appView}/workloads/${workload?.ObjectMeta.Name}` : `/${appView}/workloads`
  )

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-6 p-4'>
      <SummaryHeader
        rsrcName={isEditMode ? 'Update Workload Identity' : 'Create Workload Identity'}
        actions={
          <>
            {error && <Label text={error} size='md' variant='warning' />}
            <Button onClick={() => typeof can === 'function' && can()} size='md' variant='gray'>
              Cancel
            </Button>
            <Button disabled={!!error} onClick={onSubmit} size='md' variant='primary'>
              {isEditMode ? 'Update' : 'Create'}
            </Button>
          </>
        }
        breadCrumbsItems={
          isEditMode
            ? [
                {
                  label: 'Workloads',
                  link: `/${appView}/workloads`
                },
                {
                  label: getResourceName(workload) || 'Workload Identity',
                  link: workload ? `/${appView}/workloads/${workload?.ObjectMeta.Name}` : null
                },
                {
                  label: 'Edit'
                }
              ]
            : [
                {
                  label: 'Workloads',
                  link: `/${appView}/workloads`
                },
                {
                  label: 'Create Workload Identity'
                }
              ]
        }
      />
    </div>
  )
}

export { Header }
