import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getDisplayName } from 'Utils/PureHelperFuctions'
import useAppView from 'Core/Hooks/useAppView'
import { FeatureFlagContext } from 'Core/FeatureFlagContext'
import Loader from 'Components/Loader'
import { useHistory } from 'react-router'
import { enqueueNotification, getCurrentOrg } from 'Utils/Helpers'
import { AppHeader } from 'procyon-ui'
import { useUser } from 'Core/Hooks/useUser'
import { useMultiOrg } from 'Core/Hooks/useMultiOrg'
import { LoadingFeedback } from 'V2Components'
import axios from 'axios'
import { NotificationBar } from '../NotificationBar'
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
  root: {},
  appHeaderWrapper: {
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 1500,
    borderBottom: '1px solid #D0D9EA'
  },
  logo: {
    '& img': {
      width: '125px'
    }
  }
}))

function Header({}) {
  const classes = useStyles()
  const history = useHistory()
  const { viewMode } = useAppView()

  // @ts-ignore
  const user = useSelector((state) => state.user)
  const [orgChanging, setOrgChanging] = useState(false)
  const [appViewChanging, setAppViewChanging] = useState(false)
  const { updateContext: asyncChangeAppView } = useContext(FeatureFlagContext)
  const { orgs, changeOrganization } = useMultiOrg()
  const nextViewMode = viewMode === 'admin' ? 'user' : 'admin'
  const [showNotificationBar, setShowNotificationBar] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')

  const picNameQuery = getDisplayName(user).replaceAll(' ', '+')
  const userPic = `https://ui-avatars.com/api/?background=000000&color=ffffff&name=${picNameQuery}`
  const { isAgentlessMode } = useUser()
  const activeOrg = getCurrentOrg()
  const copyID = (ID) => {
    try {
      navigator.clipboard.writeText(ID)
      enqueueNotification('Derived ID Copied!', 'info')
    } catch (error) {}
  }

  // to handle the notification bar

  // useEffect(() => {
  //   fetch('https://procyon-release-notes.s3.us-west-2.amazonaws.com/notificationBar.json')
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error(`HTTP error! Status: ${res.status}`)
  //       }
  //       return res.json()
  //     })
  //     .then((data) => {
  //       setNotificationMessage(data.notificationMessage)
  //       const getCookiesState = Cookies.get('shownotification')
  //       if (!getCookiesState) {
  //         setShowNotificationBar(true)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error)
  //     })
  // }, [])

  const switchViewMode = async () => {
    setAppViewChanging(true)
    await asyncChangeAppView(nextViewMode)
    setAppViewChanging(false)
  }

  const logoutUser = async () => {
    try {
      const tenantName = localStorage.getItem('tenant-name')
      const hostName = window.location.host
      const updatedHost = hostName.replace('console.', '')

      const logoutURL = `https://${
        tenantName ? tenantName : user.tenant
      }.login.${updatedHost}/logout`

      const response = await axios.get(logoutURL, {
        withCredentials: true
      })

      if (response && response.data) {
        window.location.href = response.data
      } else {
        console.error('Logout failed. Empty or invalid response.')
      }
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  const handleOrgSaveClick = (org) => {
    if (org === activeOrg) return
    setOrgChanging(true)
    changeOrganization(org)
    setTimeout(() => {
      setOrgChanging(false)
      //@ts-ignore
      window.location = '/ui'
    }, 1500)
  }

  const getOrgMenuItems = () => orgs.map((e) => ({ title: e, action: () => handleOrgSaveClick(e) }))

  const getHeaderMenuItems = () => {
    const items = []
    if (user.role === 'admin') {
      if (viewMode === 'admin')
        items.push({
          action: () => history.push('/admin/settings'),
          title: 'Settings'
        })
      items.push({
        action: switchViewMode,
        title: `Switch to ${nextViewMode}`
      })
    }

    if (viewMode === 'user') {
      if (user?.Spec?.GCPDerivedID) {
        items.push({
          action: () => copyID(user?.Spec?.GCPDerivedID),
          title: `Copy GCP Derived ID`
        })
      }
      if (user?.Spec?.AzureDerivedID) {
        items.push({
          action: () => copyID(user?.Spec?.AzureDerivedID),
          title: `Copy Azure Derived ID`
        })
      }
    }

    if (isAgentlessMode) {
      items.push({
        action: () => logoutUser(),
        title: 'Logout'
      })
    }

    return items
  }

  const handleCloseNotification = () => {
    Cookies.set('shownotification', 'false')
    setShowNotificationBar(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.appHeaderWrapper}>
        {/* {notificationMessage.length > 0 && showNotificationBar && viewMode === 'admin' && (
          <NotificationBar
            message={notificationMessage}
            handleCloseNotification={handleCloseNotification}
          />
        )} */}

        <AppHeader
          legacy={false}
          showNotificationBell={false}
          dropDownMenuItems={[
            {
              isSectionLabel: true,
              title: 'Organization',
              style: {
                color: 'black'
              }
            },
            {
              style: {
                fontWeight: 'bold'
              },
              submenu: getOrgMenuItems(),
              title: activeOrg
            },
            ...getHeaderMenuItems()
          ]}
          userImageUrl={userPic}
          imgUrl='img/full-logo.svg'
          className={classes.logo}
          onClickNotificationButton={function noRefCheck() {}}
          userName={getDisplayName(user)}
          // @ts-ignore
          userRole={
            <span>
              {viewMode}
              <strong className='text-blue-800'>@{activeOrg}</strong>:{user && user.tenant}
            </span>
          }
        />
      </div>
      <LoadingFeedback
        loading={orgChanging}
        caption='Please wait'
        message='Changing Organization'
      />
      <Loader loading={appViewChanging} />
    </div>
  )
}

export { Header }
