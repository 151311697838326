import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback, Wizard } from 'V2Components'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import { useObjectRef } from 'infra/redux'
import React, { useEffect, useState } from 'react'
import { ConfigureResources } from './components/ConfigureResources'
import { FooterComponent } from './components/FooterComponent'
import { SelectResources } from './components/SelectResources'
import {
  EditPolicyModulesContextProvider,
  useEditPolicyModulesContext
} from './EditPolicyModulesProvider'
import {
  getK8NamespaceRsrcsFromPrincipal,
  getPolicyResourcesRefs,
  getRsrcPolicyRule,
  isPolicyKubenamespaceRsrcs
} from 'features/policy/utils'
import { getTargetsPolicyRules } from 'features/targets'

const EditPolicyResources = ({ onCancel, onSuccess }) => {
  const [iamActionsRsrcMap, setIamActionsRsrcMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { getObjectRef } = useObjectRef([
    'awsResources',
    'gcpResources',
    'azureResources',
    'appRolesList',
    'serverList',
    'databases'
  ])

  const { policy, selectedResources, setSelectedResources, attributesMap, setAttributesMap } =
    useEditPolicyModulesContext()

  const handleSubmitClick = async () => {
    try {
      setIsLoading(true)
      const rsrcs = getObjectRef(selectedResources)
      if (policy.Type === 'IAMAction') {
        await handlePolicyUpdate({
          resources: rsrcs,
          rolesMap: iamActionsRsrcMap,
          orgPolicy: policy
        })
      } else if (policy.Type == 'Target') {
        await handleTargetPolicyUpdate({
          policy,
          resources: selectedResources,
          attributesMap: attributesMap
        })
      }
      onSuccess()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const getErrors = () => {
    const indexedErrors = []

    if (isPolicyKubenamespaceRsrcs(policy)) {
      const isNotEmpty = selectedResources.some((ref) => {
        const rsrcKey = createRsrcKey(ref)
        const { rule } = getRsrcPolicyRule(ref, policy)
        if (rule) {
          if (!rule.Principal) return true
          if (rule.Principal && attributesMap[rsrcKey]?.K8Rsrcs.length) return true
        }
      })
      if (!isNotEmpty) indexedErrors[0] = 'Select at least one resource.'
    } else {
      if (!selectedResources.length) indexedErrors[0] = 'Select at least one resource.'
    }

    if (policy.Type === 'IAMAction') {
      const hasNoIAMRoles = selectedResources.some((e) => {
        const key = createRsrcKey(e)
        if (!iamActionsRsrcMap[key] || !iamActionsRsrcMap[key].length) {
          return true
        }
      })
      if (hasNoIAMRoles) {
        indexedErrors[1] = 'Resource must have Iam Roles attached.'
      }
    }

    if (policy.Type === 'Target') {
      const rsrcHasNoPrincipal = selectedResources.find((ref) => {
        const key = createRsrcKey(ref)
        if (
          ['AppRole', 'KubeCluster', 'Application', 'KubeNamespace', 'Kafka'].includes(ref.RefKind)
        )
          return false
        return !attributesMap[key]
      })

      const k8HasNoAppRoleSelected = selectedResources.find((ref) => {
        const key = createRsrcKey(ref)
        if (!['KubeCluster', 'KubeNamespace'].includes(ref.RefKind)) return false
        return !attributesMap[key]
      })

      if (rsrcHasNoPrincipal) {
        const rsrc = getObjectRef(rsrcHasNoPrincipal)
        indexedErrors[1] = `Please enter principal for ${getResourceName(rsrc)}.`
      } else if (k8HasNoAppRoleSelected) {
        const rsrc = getObjectRef(k8HasNoAppRoleSelected)
        indexedErrors[1] = `Please select role for ${getResourceName(rsrc)}.`
      }
    }

    return indexedErrors
  }

  useEffect(() => {
    const selectedResourcesRefs = getPolicyResourcesRefs(policy)
    setSelectedResources(selectedResourcesRefs)
    //K8 Policy Resources are populated here
    /**
     * Since K8 Resources like pods etc are not normal objects hence, we store them as attributes in the `attributesMap`
     * And use them for all our purposes.
     *
     * The attributesMap pattern is as follows:
     *
     * {
     *    [KNS KEY]:{
     *        K8Rsrcs: [ 'POD+<PODNAME>', ...], // All the selected K8 Rsrcs like pods which are or will be in policy.
     *        RoleRef: ObjectRef //  The selected approle for the K8 Rsrcs
     *    }
     * }
     *
     * *The same pattern is followed for KubeCluster as well as KNS(standalone request without pods..)
     */
    const { KubeAccess } = getTargetsPolicyRules(policy)
    if (KubeAccess?.length) {
      /**
       * If the policy is for k8 resource, we need to use the Principal to get the selected K8 Rsrcs.
       */
      if (isPolicyKubenamespaceRsrcs(policy)) {
        /**
         * Since THIS policy can have both K8 Resources and KubeCluster at the same time so, we need to handle
         * them seprately
         */
        const kubeRules = KubeAccess.filter((e) => e.ObjectRef.RefKind === 'KubeNamespace')
        const kubeClusterRules = KubeAccess.filter((e) => e.ObjectRef.RefKind === 'KubeCluster')

        const map = {}

        kubeRules.forEach((kubeRule) => {
          const k8Attributes = {
            K8Rsrcs: [], //Array of keys of k8rsrcs: ['POD+<PODNAME>', "DEPLOYMENTS+<NAME>"], etc
            RoleRef: {} //Ref of the selected kube role
          }

          const rsrcs = getK8NamespaceRsrcsFromPrincipal(kubeRule.Principal)
          rsrcs.map((e) => {
            k8Attributes.K8Rsrcs.push(`${e.type}+${e.name}`)
          })
          k8Attributes.RoleRef = kubeRule.Services.ObjectRef[0]

          //* KNS Map update
          map[createRsrcKey(kubeRule.ObjectRef)] = k8Attributes
        })

        kubeClusterRules.forEach((clusterRule) => {
          //* K8 CLuster map update if it exists
          map[createRsrcKey(clusterRule.ObjectRef)] = {
            K8Rsrcs: [],
            RoleRef: clusterRule.Services.ObjectRef[0]
          }
        })

        setAttributesMap(map)
      } else {
        // This block handles the standalone policy without k8 resources
        const map = {}
        KubeAccess.forEach((rule) => {
          map[createRsrcKey(rule.ObjectRef)] = {
            K8Rsrcs: [], // No resources here as it should be
            RoleRef: rule.Services.ObjectRef[0]
          }
        })
        setAttributesMap(map)
      }
    }
  }, [])

  return (
    <FullScreenContentModal>
      <div>
        <LoadingFeedback
          loading={isLoading}
          caption='Please wait...'
          message='Updating policy resources'
        />
        <Wizard
          FooterComponent={(props) => (
            <FooterComponent indexedErrors={getErrors()} onSubmit={handleSubmitClick} {...props} />
          )}
          header={
            <div>
              Edit Policy, <strong>{getResourceName(policy)}</strong>
            </div>
          }
          onCancel={onCancel}
          steps={[
            {
              label: 'Select Resources',
              content: (
                <div className='h-[55vh] overflow-auto relative'>
                  <SelectResources />
                </div>
              )
            },
            {
              label: 'Configure Resources',
              content: (
                <div className='h-[55vh] overflow-auto'>
                  <ConfigureResources
                    attributesMap={attributesMap}
                    setAttributesMap={setAttributesMap}
                    iamActionsRsrcMap={iamActionsRsrcMap}
                    setIamActionsRsrcMap={setIamActionsRsrcMap}
                  />
                </div>
              )
            }
          ]}
        />
      </div>
    </FullScreenContentModal>
  )
}

const handlePolicyUpdate = async ({ resources, rolesMap, orgPolicy }) => {
  const actionMap = {}

  /** Create the ActionMap object */
  resources.forEach((rsrc) => {
    const Roles = rolesMap[createRsrcKey(rsrc)] || []
    // ActionMap key will be AwsIAM or GcpIAM for AwsResource or GcpResource
    const KubeNampeSpaceActionMap = {
      AWS: 'AwsIAM',
      GCP: 'GcpIAM',
      AZURE: 'AzureIAM'
    }
    const resourceTypeToIAMMap = {
      AwsResource: 'AwsIAM',
      GcpResource: 'GcpIAM',
      AzureResource: 'AzureIAM',
      KubeNamespace: KubeNampeSpaceActionMap[rsrc?.Spec?.Type]
    }
    const actionMapKey = resourceTypeToIAMMap[rsrc.ObjectMeta.Kind]
    if (!actionMap[actionMapKey]) {
      actionMap[actionMapKey] = {
        PolicyRule: []
      }
    }
    Roles.forEach((r) => {
      actionMap[actionMapKey].PolicyRule.push({
        ObjectRef: {
          RefID: r.RefID,
          RefKind: 'IamAction'
        },
        Principal: '',
        GcpRoleTarget: null,
        Services: {
          ObjectRef: [createRef(rsrc)]
        }
      })
    })
  })
  const clone = structuredClone(orgPolicy)
  clone.OrgSpec.ActionMap = actionMap
  clone.Dirty.Spec = true

  const policy = await reduxApiClient('pacpolicys').update(clone)
  enqueueNotification('Policy has been updated successfully!', 'info')
  return policy
}

const handleTargetPolicyUpdate = async ({ resources, attributesMap, policy }) => {
  const actionMap = {}

  resources.forEach((rsrc) => {
    const attributes = attributesMap[createRsrcKey(rsrc)] || {}
    if (rsrc.RefKind === 'Server') {
      const SSHRule = actionMap.SSH || { PolicyRule: [] }
      SSHRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Server',
          RefID: rsrc.RefID
        },
        Principal: attributes
      })
      actionMap.SSH = SSHRule
    } else if (rsrc.RefKind === 'AppRole') {
      const AssumeRole = actionMap.AssumeRole || { PolicyRule: [] }
      AssumeRole.PolicyRule.push({
        ObjectRef: {
          RefKind: 'AppRole',
          RefID: rsrc.RefID
        }
      })
      actionMap.AssumeRole = AssumeRole
    } else if (rsrc.RefKind === 'Database') {
      const DBAccess = actionMap.DBAccess || { PolicyRule: [] }
      DBAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Database',
          RefID: rsrc.RefID
        },
        Principal: attributes
      })
      actionMap.DBAccess = DBAccess
    } else if (rsrc.RefKind === 'RDPServer') {
      const RDPAccessRule = actionMap.RDPAccess || { PolicyRule: [] }
      RDPAccessRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'RDPServer',
          RefID: rsrc.RefID
        },
        Principal: attributes
      })
      actionMap.RDPAccess = RDPAccessRule
    } else if (rsrc.RefKind === 'Kafka') {
      const KafkaAccess = actionMap.KafkaAccess || { PolicyRule: [] }
      KafkaAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Kafka',
          RefID: rsrc.RefID
        }
      })
      actionMap.KafkaAccess = KafkaAccess
    } else if (rsrc.RefKind === 'KubeCluster' || rsrc.RefKind === 'KubeNamespace') {
      const KubeAccess = actionMap.KubeAccess || { PolicyRule: [] }
      const { K8Rsrcs, RoleRef } = attributes

      const principalRsrcs =
        K8Rsrcs &&
        K8Rsrcs.map((e) => {
          const [type, name] = e.split('+')
          return `${type.toLowerCase()}:${name}`
        }).join()

      KubeAccess.PolicyRule.push({
        ObjectRef: rsrc,
        Principal: principalRsrcs || '',
        Services: {
          ObjectRef: [RoleRef]
        }
      })

      actionMap.KubeAccess = KubeAccess
    } else if (rsrc.RefKind === 'Application') {
      const HTTP = actionMap.HTTP || { PolicyRule: [] }
      HTTP.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Application',
          RefID: rsrc.RefID
        }
      })
      actionMap.HTTP = HTTP
    }
  })

  const clone = structuredClone(policy)
  clone.Spec.ActionMap = actionMap
  await reduxApiClient('pacpolicys').update(clone)
  enqueueNotification('Policy has been updated successfully!', 'info')
}

const WrappedEditPolicyResources = ({ policy, onCancel, onSuccess, ...props }) => (
  <EditPolicyModulesContextProvider policy={policy}>
    <EditPolicyResources onCancel={onCancel} onSuccess={onSuccess} {...props} />
  </EditPolicyModulesContextProvider>
)

export { WrappedEditPolicyResources as EditPolicyResources }
