import { faRadar, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { openURLSafely } from 'Utils/PureHelperFuctions'
import { getApplicationSignInURL, isReadOnlyApp } from 'features/applications'
import { ErrorModal } from 'features/policy'
import {
  SummaryHeader,
  getResourceName,
  getRsrcIcon,
  useAccessCartProvider
} from 'features/resources'
import { Button, IconButton, Label } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function Header({ app, showSignIn, showScanning, onSync, isSyncDisabled, parentAppName }) {
  const { appView } = useAppView()
  const { rdpurl, isAgentlessMode } = useUser()
  const history = useHistory()
  const { clearCartItems } = useAccessCartProvider()
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleBreadcrumbClick = (link) => {
    clearCartItems()
    if (link) {
      history.push(link)
    }
  }

  const onErrorClick = () => {
    setShowErrorModal(true)
  }

  const handleSignInClick = () => {
    let url = getApplicationSignInURL(app)
    if (isAgentlessMode) {
      url = url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
      url && openURLSafely(url)
    } else {
      url && openURLSafely(url)
    }
  }

  const getErrorStatus = (app) => {
    return app?.Status?.Status?.Status === 'Fail' ? true : false
  }

  const getErrorMessage = () => {
    return app?.Status?.Status?.Error
  }
  const showErrorIcon = getErrorStatus(app)

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon(app)}
        rsrcName={getResourceName(app)}
        actions={
          <div className='flex gap-4'>
            {showErrorIcon && (
              <IconButton
                icon={faTriangleExclamation}
                onClick={onErrorClick}
                size='sm'
                variant='grayRed'
              />
            )}

            {showScanning && (
              <Label iconButton={faRadar} variant='success' size='lg' text='Sync In Progress' />
            )}

            {!showScanning && appView === 'admin' && (
              <Button size='md' variant='grayBlue' onClick={onSync} disabled={isSyncDisabled}>
                Sync
              </Button>
            )}
            {/* 
            {showSignIn && (
              <Button onClick={handleSignInClick} size='md' variant='grayBlue'>
                Sign In
              </Button>
            )} */}
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Workspace Catalog',
            link: `/${appView}/datacloud/workspace/${parentAppName}`,
            onClick: () => handleBreadcrumbClick(`/${appView}/datacloud/workspace/${parentAppName}`)
          },
          {
            label: getResourceName(app),
            link: null,
            onClick: () => handleBreadcrumbClick(null)
          }
        ]}
      />
      {showErrorModal && (
        <ErrorModal
          message={getErrorMessage()}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Error'
        />
      )}
    </div>
  )
}

export { Header }
