import { TabGroup } from 'procyon-ui'
import React, { useMemo, useState, useEffect } from 'react'
import { OverviewTab } from './OverviewTab'
import useAppView from 'Core/Hooks/useAppView'
import { UsersTab } from './UsersTab'
import { ResourcesTab } from './ResourcesTab'
import { useParams } from 'react-router'
import { createRsrcKey } from 'features/resources'
import { useAppDetailsContext } from 'features/snowflake'

function Tabs() {
  const { app } = useAppDetailsContext()
  const { appView } = useAppView()
  const { tabName } = useParams()
  const [activeTab, setActiveTab] = useState(tabName)

  const TAB_INDEX_MAP = {
    overview: 0,
    roles: 1,
    users: 2
  }

  const TAB_NAME_MAP = {
    0: 'overview',
    1: 'roles',
    2: 'users'
  }

  const tabs = useMemo(() => {
    const list = [
      {
        label: 'Overview',
        tabContent: <OverviewTab />
      },
      {
        label: 'Resources',
        tabContent: <ResourcesTab />
      }
    ]

    if (appView === 'admin') {
      list.push({
        label: 'Users',
        tabContent: <UsersTab />
      })
    }
    return list
  }, [])

  useEffect(() => {
    if (tabName) {
      setActiveTab(tabName)
    }
  }, [tabName])

  return (
    <div>
      <TabGroup
        key={createRsrcKey(app)}
        controllable={tabName === 'roles'}
        activeIndex={tabName === 'roles' ? TAB_INDEX_MAP[activeTab] : 0}
        onChangeTab={(_, v) => {
          setActiveTab(TAB_NAME_MAP[v])
        }}
        tabs={tabs}
      />
    </div>
  )
}

export { Tabs }
