import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { ResourcesTreeView, isAccountResource, useCloudDetailsProvider } from 'features/clouds'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { TargetIcon } from 'procyon-ui'
import React, { useEffect } from 'react'

const WHITELISTED_DISPLAY_TYPES = [
  'Resource Group',
  'Subscription',
  'Virtual Machine',
  'MySQL Flexible Server',
  'SQL Server Database',
  'SQL Server',
  'PostgreSQL Server'
]

const DisplayTypeToIconMap = {
  'Resource Group': 'AZURE_RESOURCE_GROUP_MONO',
  Subscription: 'AZURE_SUBSCRIPTION_TYPE',
  'Virtual Machine': 'AZURE_VM_TYPE',
  'MySQL Flexible Server': 'AZURE_SQL_SERVER_DATABASE_TYPE',
  'SQL Server Database': 'AZURE_SQL_SERVER_DATABASE_TYPE',
  'SQL Server': 'AZURE_SQL_SERVER_TYPE',
  'PostgreSQL Server': 'AZURE_SQL_SERVER_TYPE'
}

const RESOURCES_DISPLAY_TYPES = ['SQL Server Database', 'Virtual Machine']

/**
 * This config map handles the collection/grouping of various display types into one.
 *
 * Eg, All SQL Instances, of same parent will be grouped inside a collection name Databases, etc.
 * Only change this config, when adding more groupings.
 */
const RESOURCES_COLLECTION_MAP_CONFIG = {
  DisplayType: {
    'SQL Server Database': {
      label: 'Databases'
    },
    'MySQL Flexible Server': {
      label: 'Databases'
    },
    'SQL Server': {
      label: 'Databases'
    },
    'PostgreSQL Server': {
      label: 'Databases'
    },
    'Virtual Machine': {
      label: 'Servers'
    }
  },
  more: {
    iconType: {
      // Icon type per label
      Servers: 'SERVER',
      Databases: 'DATABASE'
    }
  }
}

const AZUREResources = () => {
  const { cloud: account } = useCloudDetailsProvider()
  const { slices, getObjectRef, dispatchThunks } = useMultiSlice(['azureResources', 'accountList'])

  const rsrcs = slices.azureResources.filter((g) => {
    return isAccountResource(account, g) && WHITELISTED_DISPLAY_TYPES.includes(g.Spec.DisplayType)
  })

  const getNodeIcon = (rsrc) => {
    let iconType = ''
    if (typeof rsrc === 'string')
      return <TargetIcon type={RESOURCES_COLLECTION_MAP_CONFIG.more.iconType[rsrc.split('+')[0]]} />
    if (rsrc.ObjectMeta.Kind === 'Account') iconType = 'AZURE_APPLICATION'
    else iconType = DisplayTypeToIconMap[rsrc.Spec.DisplayType]

    // @ts-ignore
    return <TargetIcon type={iconType} />
  }

  const getCustomNodeCollection = (rsrc) => {
    const displayType = getIAMResourceDisplayType(rsrc)
    return RESOURCES_COLLECTION_MAP_CONFIG.DisplayType[displayType]
  }

  const disableNodeClick = (rsrc) => {
    if (RESOURCES_DISPLAY_TYPES.includes(rsrc.Spec.DisplayType)) return true
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <ResourcesTreeView
      rsrcs={rsrcs}
      getObjectRef={getObjectRef}
      disableNodeClick={disableNodeClick}
      getNodeIcon={getNodeIcon}
      getCustomNodeCollection={getCustomNodeCollection}
    />
  )
}

export { AZUREResources }
