import { ChannelRow } from 'procyon-ui'
import { useChannelBrowser } from '../../../providers/hooks'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

const ServiceNowChannels = ({
  getCloudsAccountCount,
  handleManageAccount,
  handleUnlinkAllAccount,
  getTotalLinkedAccount,
  getIntegrationType,
  filteredChannel
}) => {
  return (
    filteredChannel &&
    filteredChannel
      .slice()
      .sort((a, b) => getTotalLinkedAccount(b).length - getTotalLinkedAccount(a).length) // Sort by total accounts length in descending order
      .map((item) => (
        <ChannelRow
          key={item.id} // Ensure each element has a unique key
          awsButtonNumber={getCloudsAccountCount(item, 'AWS').length}
          azureButtonNumber={getCloudsAccountCount(item, 'AZURE').length}
          channelName={item.Spec.ChannelName || item.Spec.Name || item.Spec.ProjectName}
          dropdownMenuItems={[
            {
              submenu: [
                {
                  action: () => handleManageAccount(item),
                  title: 'Manage'
                },
                {
                  action: () => handleUnlinkAllAccount(item),
                  title: 'Unlink All'
                }
              ],
              title: <FontAwesomeIcon icon={faEllipsisVertical} />
            }
          ]}
          googleButtonNumber={getCloudsAccountCount(item, 'GCP').length}
          onClickAwsButton={function noRefCheck() {}}
          onClickAzureButton={function noRefCheck() {}}
          onClickGoogleButton={function noRefCheck() {}}
          onClickRepositories={function noRefCheck() {}}
          onClickUserGroups={function noRefCheck() {}}
          showAwsButton
          showAzureButton
          showDropdownButton
          showGoogleButton
          totalAccounts={`Total ${getTotalLinkedAccount(item).length}`}
          type={getIntegrationType()}
        />
      ))
  )
}

export { ServiceNowChannels }
