import { enqueueNotification, errorHandler, getUserInfo } from '../Utils/Helpers'
import { deleteRequest, getRequest, postRequest, putRequest } from './NetworkRequest'

export class credentialTypeApis {
  /** fetch all credentials List */
  static async getCredentialTypesList () {
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = await getRequest(apiKey, 'credentialtypes', tenant)
      console.log('getCredentialTypesList response: ', response)
      return response && response.data ? response.data.CredentialTypes : []
    } catch (error) {
      errorHandler(error, 'getCredentialTypessList', 'credentialTypeApis.js')
    }
  }

  static async updateCredentialType (data) {
    console.log('updateCredentials: ', data)
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await putRequest(apiKey, `credentialtypes/${org}`, data.ObjectMeta.Name, tenant, data, 'api')
      console.log('response: ', response)
      return response
    } catch (error) {
      errorHandler(error, 'updateCredentialTypes', 'credentialTypeApis.js')
    }
  }

  static async createCredentialType (data) {
    console.log('createCredentials: ', data)
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = await postRequest(apiKey, 'credentialtypes', tenant, data)
      console.log('response: ', response)
      enqueueNotification('Credential Created Successfuly', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'createCredentialTypes', 'credentialTypeApis.js')
    }
  }

  static async deleteCredentialType (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await deleteRequest(apiKey, `credentialtypes/${org}`, data.ObjectMeta.Name, tenant, 'api')
      enqueueNotification('Credential Deleted', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'updateCredentialType', 'credentialTypeApis.js')
    }
  }
}
