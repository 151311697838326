import React from 'react'
import { Computer, Group, Menu } from '@material-ui/icons'
import { Box } from '@material-ui/core'

export const ITEMS = [
  {
    key: 'thisIsAKey1',
    Tab: {
      Icon: Menu,
      Title: 'Overview'
    },
    TabPanel: {
      Component: (
        <Box p={1}>
          <h2>Any content 1</h2>
        </Box>
      )
    }
  },
  {
    key: 'thisIsAKey2',
    Tab: {
      Icon: Group,
      Title: 'User and Groups'
    },
    TabPanel: {
      Component: (
        <Box p={1}>
          <h2>Any content 2</h2>
        </Box>
      )
    },
    route: {
      path: 'user-groups'
    }
  },
  {
    key: 'thisIsAKey3',
    Tab: {
      Icon: Computer,
      Title: 'Activity Log'
    },
    TabPanel: {
      Component: (
        <Box p={1}>
          <h2>Any content 3</h2>
        </Box>
      )
    },
    route: {
      path: ['activity-log', 'log']
    }
  }
]
