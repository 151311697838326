import { useServerControllerWizardContext } from 'features/serverControllers'
import { TextInput, Typography } from 'procyon-ui'
import React from 'react'

const SudoersPolicys = () => {
  const { updateConfig, sudoers } = useServerControllerWizardContext()
  console.log('[ctrl](split):', sudoers.split(/\r\n|\r|\n/))
  return (
    <div>
      <Typography className='mb-2' variant='buttonLabel-regular'>
        Enter Sudoers Policies
      </Typography>
      <TextInput
        value={sudoers}
        onChange={(e) => updateConfig({ sudoers: e.target.value })}
        sx={{ width: '100%' }}
        multiline
        rows={5}
      />
      <Typography className='mt-1' variant='caption-regular'>
        {/* Policy should be one per line. */}
      </Typography>
    </div>
  )
}

export { SudoersPolicys }
