import { createRsrcKey, useResourceDetails } from 'features/resources'
import { TabGroup } from 'procyon-ui'
import React from 'react'
import { Overview } from './Components/Overview'

function IAMResourceDetails({ tabs = [] }) {
  const { rsrc } = useResourceDetails()
  return (
    <TabGroup
      key={createRsrcKey(rsrc)}
      tabs={[
        {
          label: 'Overview',
          tabContent: <Overview />
        },
        ...tabs
      ]}
    />
  )
}
const memoized = React.memo(IAMResourceDetails)

export { memoized as IAMResourceDetails }
