import { admin } from './permissions/admin'
import { user } from './permissions/user'
export default class FeatureFlags {
  constructor () {
    /** Current state of feature flags */
    this.flags = {
      general: true,

      // Acounts Permissions
      showAccoutsPage: true,
      showAccoutsBrowserPage: true,
      showUsersAccoutsPage: true,
      showUsersIamRolesPage: true,
      showUsersDataSourcePage: true,
      showUsersServersPage: true,
      showIntegrationsPage: true,
      // Servers Permissions
      showServersPage: true,

      // Credentials Permissions
      showCredentialsPage: true,

      // Dashboard Permissions
      showDashboardPage: true,

      // Getting started Page permission
      showGettingStartedPage: true,

      // User Permissions
      showUsersPage: true,
      showUserInformationPage: true,
      showUserDetailsPage: true,

      // Group Permissions
      showGroupDetailsPage: true,
      showDevicesPage: true,

      // Idp Permissions
      showIdpDetailsPage: true,
      showIdpsPage: true,

      // Device Permissions
      showDeviceDetailsPage: true,

      showApprovalRequestPage: true,
      showApprovalRequestDetailsPage: true,

      // Sidebar Group Titles Permissions
      showAccessManagementGroupLink: true,
      showResourcesGroupLink: true,
      showApplicationsPage: true,
      showProjectsPage: true,
      showTeamGroupLink: true,
      showDashBoardGroupLink: true,
      showReportsGroupLink: true,
      showRequestGroupLink: true,

      // Approval Permissions
      showApprovalPage: true,

      // Logs Permissions
      showLogsPage: true,

      showRecordingPage: true,

      // Web SSH Permissions
      showWebSshPage: true,
      showSettingsPage: true,
      showGlobalResourcesPage: true,
      showIamActions: true,
      showTargets: true,
      showServiceAccountsPage: true
    }
  }

  /**
   * Get Current State
   */
  getFlags () {
    return this.flags
  }

  /**
   * Get Latest state after making an update
   */
  updatePermissionsFor (view = {}) {
    try {
      const update = view === 'admin' ? admin : user
      this.flags = { ...this.flags, ...update }
      return this.flags
    } catch (error) {
      console.log('error: ', error)
    }
  }
}
