import { Box, CircularProgress, makeStyles, Tooltip } from '@material-ui/core'
import { Cancel, CheckCircle, Warning } from '@material-ui/icons'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
    fontSize: theme.spacing(1)
  },
  warning: {
    color: theme.palette.warning.main,
    fontSize: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1)
  }
}))
export const StatusMark = ({ status, title, justifyContent = 'start' }) => {
  const classes = useStyles()
  return (
    <Tooltip title={title || status} placement='top'>
      <Box display='flex' alignItems='center' height='100%' justifyContent={justifyContent}>
        {status === 'error' && <Cancel className={classes.error} />}
        {status === 'success' && <CheckCircle className={classes.success} />}
        {status === 'warning' && <Warning className={classes.warning} />}
        {status === 'pending' && <CircularProgress size={24} color='primary' />}
      </Box>
    </Tooltip>
  )
}
