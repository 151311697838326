import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'
import ThemeButton from './ThemeButton'

/**
 * @typedef {'primary' | 'secondary' | 'error'} theme
 * @typedef {import('@material-ui/core').ButtonProps & { theme: theme ,loading:Boolean,label:String}} props
 * @param {props} props
 * @returns {React.ReactElement}
 */
const ThemeLoaderButton = ({ onClick, loading, label, Icon, ...rest }) => {
  return (
    <ThemeButton size='large' onClick={onClick} {...rest}>
      <Box display='flex' alignItems='center'>
        {((!loading) && Icon) && Icon}
        {loading && <Box mr={1} display='flex' alignItems='center'><CircularProgress size={24} /></Box>}
        {label}
      </Box>
    </ThemeButton>
  )
}

export default ThemeLoaderButton
