import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

function DeleteProxyControllerModal({ proxyController, cleanUpFn, onCancel = null }) {
  const handleDeleteProxyController = async () => {
    await reduxApiClient('proxycontrollers').delete(proxyController)
    enqueueNotification('Proxy Controller Deleted Successfully!', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`Are you sure you want to delete ${getResourceName(
          proxyController
        )} proxy controller?`}
        loadingMessage='Deleting Proxy Controller'
        onActionClick={handleDeleteProxyController}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeleteProxyControllerModal }
