import { ApiProvider } from 'Core'
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import React from 'react'
import { getResourceName } from 'features/resources'

export const RescanContext = createContext(null)

export const RescanProvider = ({ account, children }) => {
  /**
   * @type { { current: { ObjectMeta: {}, Spec: { Attributes: { Map: { [AccountName:String]: String  }}} } } }
   */
  // @ts-ignore
  const scanlist = useRef({})
  const scannedAccountName = useRef('')
  const [scanning, setScanning] = useState(false)

  const createScanList = async () => {
    try {
      const scanList = { ObjectMeta: { Name: 'scanlist' }, Spec: { Attributes: { Map: {} } } }
      const newList = await new ApiProvider('userpreferences').setInstance(scanList).post()
      return newList
    } catch (error) {}
  }
  const getScanList = async () => {
    try {
      const response = await new ApiProvider('userpreferences')
        .setInstance({ ObjectMeta: { Name: 'scanlist' } })
        .get()
      scanlist.current = response.data
    } catch (error) {
      scanlist.current = await createScanList()
    }
  }

  const addToScanlist = async () => {
    try {
      const newAccounts = {
        ...scanlist.current.Spec.Attributes.Map,
        [account.ObjectMeta.Name]: account.Status.CrawlerEndedAt
      }
      const scanList = {
        ObjectMeta: { ...scanlist.current.ObjectMeta },
        Spec: { Attributes: { Map: newAccounts } }
      }
      const response = await new ApiProvider('userpreferences').setInstance(scanList).put()
      scanlist.current = response.data
    } catch (error) {
      console.error('error in scanlist ->addToScanlist:', error)
    }
  }

  const deleteFromScanList = async () => {
    try {
      const newAccounts = { ...scanlist.current.Spec.Attributes.Map }
      delete newAccounts[account.ObjectMeta.Name]
      const scanList = {
        ObjectMeta: { ...scanlist.current.ObjectMeta },
        Spec: { Attributes: { Map: newAccounts } }
      }
      const response = await new ApiProvider('userpreferences').setInstance(scanList).put()
      scanlist.current = response.data
    } catch (error) {
      console.error('something went wrong while cleaning the scan list')
    }
  }

  const getTimeStamp = () => {
    try {
      const name = account.ObjectMeta.Name
      return scanlist.current.Spec.Attributes.Map[name]
    } catch (error) {
      console.error('could not get timestamp', error)
      return null
    }
  }

  const onAccountChange = async () => {
    try {
      await getScanList()
      const lastTimeStamp = getTimeStamp()
      if (account && (lastTimeStamp !== account.Status.CrawlerEndedAt || !lastTimeStamp)) {
        setScanning(false)
        await deleteFromScanList()
      } else {
        setScanning(true)
      }
    } catch (error) {
      console.error('error in onAccountChange', error)
    }
  }

  const triggerRescan = async () => {
    try {
      setScanning(true)
      await new ApiProvider('accounts').setInstance(account).put()
      addToScanlist()
      enqueueNotification('Account Rescan started!', 'info')
    } catch (error) {
      console.error('error in rescanning account', error)
      enqueueNotification('Operation Failed', 'error')
    }
  }

  // Scan on account change
  if (scannedAccountName.current !== getResourceName(account)) {
    scannedAccountName.current = getResourceName(account)
    onAccountChange()
  }

  if (!account.Status.CrawlerEndedAt && !scanning) setScanning(true)

  return (
    <RescanContext.Provider value={{ triggerRescan, account, scanning }}>
      {children}
    </RescanContext.Provider>
  )
}

export const useRescan = () => useContext(RescanContext)
