import { configureStore } from '@reduxjs/toolkit'
import localforage from 'localforage'
import { persistReducer, persistStore } from 'redux-persist'
import rootReducer from './reducers'
import createTransformCompress from './utils/reduxPersistCompressor'

/**
 * @type {import('types').SliceNames[]}
 */
const WHITELIST_SLICENAMES = [
  //@ts-ignore
  'user',
  'userpreferences',
  'accountList',
  'userList',
  'groupList',
  'iamActions',
  'dashboard'
]

// Set-up lz-string compression
const compressor = createTransformCompress({
  whitelist: WHITELIST_SLICENAMES
})

// Redux persist config
const persistConfig = {
  key: 'root',
  whitelist: WHITELIST_SLICENAMES,
  storage: localforage,
  transforms: [compressor]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
})

export const persistor = persistStore(store)
export default store
