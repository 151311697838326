import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import FilterItem from './FilterItem'
const useStyle = makeStyles(theme => ({
  left: {

  },
  right: {
    marginLeft: 'auto'
  }
}))
const SeriesFilter = ({ pb, filters = [], changeFilter, position, disabled }) => {
  const classes = useStyle()
  return (
    <Box pb={pb} className={classNames({ [classes.left]: position === 'left', [classes.right]: position === 'right' })} justifySelf='flex-end' display='flex' alignItems='center'>
      {filters.map((filter) => (
        <FilterItem
          disabled={disabled}
          key={filter.name}
          changeFilter={changeFilter}
          chacked={filter.active}
          name={filter.name}
          label={filter.label}
          color={filter.color}
          Icon={filter.Icon}
        />))}
    </Box>
  )
}

export default SeriesFilter
