import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { Button, DropDownButton, Label, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { ApplicationEditProvider } from './providers/ApplicationEditProvider'
import {
  DropboxApplicationForm,
  HTTPSApplicationForm,
  SAMLApplicationForm,
  GithubApplicationForm,
  SalesForceApplicationForm
} from './components/Forms'

const APPLICATION_TYPES = ['HTTP', 'DROPBOX', 'GITHUB', 'SALESFORCE']

/**
 *
 * @param {{
 *  onClose:(() => void)
 *  onComplete:(() => void)
 * type:string
 *  app?:any  // will be used pre-fill data with the provided application for edit
 * }} param0
 */
function AddApplication({ onClose, onComplete, type, app }) {
  const [applicationType, setApplicationType] = useState(type)
  const isEditMode = !!app

  if (app) {
    // SAML not handled
    const type = app.Spec.AppType.toUpperCase() || 'HTTP'
    if (type !== applicationType) setApplicationType(type)
  }

  const getDropdownMenuItems = () => [
    {
      submenu: APPLICATION_TYPES.map((e) => ({
        title: e,
        action: () => setApplicationType(e)
      })),
      title: applicationType
    }
  ]

  return (
    <div>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-medium'>
          {isEditMode ? (
            <strong>{app.ObjectMeta.Name}</strong>
          ) : (
            `Add ${applicationType} Application`
          )}
        </Typography>
        <div className='flex items-center gap-4'>
          {isEditMode ? (
            <Label text={applicationType} variant='grayBlue' />
          ) : (
            <DropDownButton menuItems={getDropdownMenuItems()} size='sm' variant='primary' />
          )}
          <Button onClick={onClose} variant='gray' icon={faClose} iconPosition='end'>
            CLOSE
          </Button>
        </div>
      </div>
      <ApplicationEditProvider app={app}>
        <div className='mt-6'>
          {applicationType === 'SAML' && (
            <SAMLApplicationForm onComplete={onComplete} onCancel={onClose} />
          )}
          {applicationType === 'HTTP' && (
            <HTTPSApplicationForm onComplete={onComplete} onCancel={onClose} />
          )}
          {applicationType === 'DROPBOX' && (
            <DropboxApplicationForm onComplete={onComplete} onCancel={onClose} />
          )}
          {applicationType === 'GITHUB' && (
            <GithubApplicationForm onComplete={onComplete} onCancel={onClose} />
          )}
          {applicationType === 'SALESFORCE' && (
            <SalesForceApplicationForm onComplete={onComplete} onCancel={onClose} />
          )}
        </div>
      </ApplicationEditProvider>
    </div>
  )
}

export { AddApplication }
