const { createContext, useState } = require('react')
import { createRsrcKey } from 'features/resources'
import React, { useContext } from 'react'

const EditPolicyModulesContext = createContext({
  /**
   * @type {import("types").ObjectRef[]}
   */
  selectedResources: [],
  /**
   *
   * @type {((e) => void)}
   */
  setSelectedResources(rsrcs) {},
  policy: null,
  /**
   * @type {((ref:import('types').ObjectRef) => void)}
   */
  removeSelectedResource() {},
  attributesMap: {},
  setAttributesMap(map = {}) {}
})

export const EditPolicyModulesContextProvider = ({ children, policy }) => {
  const [selectedResources, setSelectedResources] = useState([])
  const [attributesMap, setAttributesMap] = useState({})

  const removeSelectedResource = (ref) => {
    setSelectedResources((s) => s.filter((e) => createRsrcKey(e) !== createRsrcKey(ref)))
  }

  return (
    <EditPolicyModulesContext.Provider
      value={{
        selectedResources,
        setSelectedResources,
        policy,
        removeSelectedResource,
        attributesMap,
        setAttributesMap
      }}
    >
      {children}
    </EditPolicyModulesContext.Provider>
  )
}

export const useEditPolicyModulesContext = () => useContext(EditPolicyModulesContext)
