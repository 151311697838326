import React, { useEffect, useState } from 'react'
import {
  Headers,
  getGithubAllUsers,
  getGithubResources,
  useAppDetailsContext
} from 'features/github'
import useAppView from 'Core/Hooks/useAppView'
import { cn } from 'Utils/Helpers'
import { GithubUserRow, IconButton, PolicyRow, TargetIcon } from 'procyon-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiProvider } from 'Core'
import moment from 'moment'
import * as Styles from '../../styles/styles'
import { useHistory, useParams } from 'react-router'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import _ from 'lodash'
import { DeletePolicyModal } from './DeletePolicyModal'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { createDataSelectorHook } from 'infra/redux'
import { faEclipse } from '@fortawesome/pro-regular-svg-icons'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'

const ActivePolicies = ({ showAddButton, isRepo }) => {
  const [searchKey, setSearchKey] = useState('')
  const { appView } = useAppView()
  const [showAddApplication, setShowAddApplication] = useState(false)
  const [policyList, setPolicyList] = useState([])
  const [policyUsersList, setPolicyUsersList] = useState([])
  const { selectDispatch } = useMultiSlice([
    'githubResources',
    'policyList',
    'userList',
    'githubAccount'
  ])
  const [showPolicyUserRow, setShowPolicyUserRow] = useState(false)
  const [showDeletePolicyModal, setShowDeletePolicyModal] = useState(false)
  const [currentPolicy, setCurrentPolicy] = useState({})
  const [repoLength, setRepoLength] = useState(0)
  const [filteredPolicyList, setFilteredPolicyList] = useState([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const params = useParams()
  const canGoBack = useCanGoBack()
  const history = useHistory()

  const permissionMap = {
    pull: 'Read',
    push: 'Write',
    admin: 'Admin',
    maintain: 'Maintainer'
  }

  const handleAddApplicationClick = () => setShowAddApplication(true)

  const useSlices = createDataSelectorHook([
    'githubAccount',
    'policyList',
    'userList',
    'githubResources'
  ])
  const { slices } = useSlices()

  useEffect(() => {
    selectDispatch(['githubAccount', 'policyList', 'userList', 'githubResources'])
    initGithubResources()
  }, [])

  const initGithubResources = async () => {
    const matchedPolicyObjects = []
    let currentAccountRepo = {}

    let currentAccount = {}
    if (isRepo) {
      currentAccountRepo = _.find(slices.githubResources, { ObjectMeta: { Name: params.appName } })
      currentAccount = _.find(slices.githubAccount, {
        ObjectMeta: { ID: currentAccountRepo?.Spec?.Account?.RefID }
      })
    } else {
      currentAccount = _.find(slices.githubAccount, { ObjectMeta: { Name: params.appName } })
    }

    slices.policyList.forEach((policy) => {
      if (policy.Spec.ActionMap.HTTP && !isRepo) {
        const httpRules = policy.Spec.ActionMap.HTTP.PolicyRule
        if (httpRules.some((rule) => rule.ObjectRef.RefID === currentAccount.Application.RefID)) {
          matchedPolicyObjects.push(policy)
        }
      }

      if (policy.Spec.ActionMap.GithubAccess && isRepo) {
        const githubAccessRules = policy.Spec.ActionMap.GithubAccess.PolicyRule
        if (
          githubAccessRules.some(
            (rule) =>
              rule.ObjectRef.RefID === currentAccount?.ObjectMeta?.ID &&
              rule.Services.ObjectRef[0].RefID === currentAccountRepo?.ObjectMeta?.ID
          )
        ) {
          matchedPolicyObjects.push(policy)
        }
      }
    })

    const allRefIds = []
    matchedPolicyObjects.forEach((dataObj) => {
      // Extract the RefID values for the current object
      const refIds = []
      if (
        dataObj &&
        dataObj.Spec &&
        dataObj.Spec.ActionMap &&
        dataObj.Spec.ActionMap.GithubAccess &&
        dataObj.Spec.ActionMap.GithubAccess.PolicyRule
      ) {
        dataObj.Spec.ActionMap.GithubAccess.PolicyRule.forEach((rule) => {
          if (rule.Services && rule.Services.ObjectRef) {
            rule.Services.ObjectRef.forEach((service) => {
              if (service.RefID) {
                refIds.push(service.RefID)
              }
            })
          }
        })
      }

      // Push the filtered RefID values for the current object to the allRefIds array
      allRefIds.push(refIds)
    })

    setPolicyList(matchedPolicyObjects)
    setFilteredPolicyList(matchedPolicyObjects)
    setRepoLength(allRefIds.length)
  }

  useEffect(() => {
    handleSearchKeyUpdate()
  }, [searchKey])

  const handleSearchKeyUpdate = () => {
    if (!isInitialLoad) {
      if (searchKey !== '') {
        const matchingObject = policyList.filter((policy) => {
          return policy?.GivenName?.includes(searchKey)
        })
        setFilteredPolicyList(matchingObject)
      } else {
        setFilteredPolicyList(policyList)
      }
    } else {
      setIsInitialLoad(false)
    }
  }

  const getRemainingDays = (date) => {
    const givenDate = moment(date)
    const currentDate = moment()
    const remainingDays = givenDate.diff(currentDate, 'days')
    return `Ends in ${remainingDays} days`
  }

  const getUserList = (app) => {
    const issuedToRefs = app?.IssuedTo.ObjectRef
    const policyRules = isRepo
      ? app?.Spec?.ActionMap?.GithubAccess?.PolicyRule
      : app?.Spec?.ActionMap?.HTTP?.PolicyRule
    const matchingObjectMetaIds = []

    issuedToRefs.forEach((element) => {
      if (element.RefKind !== 'GithubResource') {
        for (const user of slices.userList) {
          const userRefID = user.ObjectMeta.ID
          const matchingRef = issuedToRefs.find((ref) => ref.RefID === userRefID)
          if (matchingRef) {
            matchingObjectMetaIds.push(user)
          }
        }
      } else {
        for (const team of slices.githubResources) {
          const teamRefID = team.ObjectMeta.ID
          const matchingRef = issuedToRefs.find((ref) => ref.RefID === teamRefID)
          if (matchingRef) {
            matchingObjectMetaIds.push(team)
          }
        }
      }
    })

    if (matchingObjectMetaIds.length !== policyRules.length) {
      console.log('Arrays are of different lengths')
    } else {
      const UserListWithPermissionKey = matchingObjectMetaIds.map((item, index) => {
        return { ...item, permission: policyRules[index].Principal }
      })
      setPolicyUsersList(UserListWithPermissionKey)
    }
    setShowPolicyUserRow(true)
  }

  const deletePolicy = async (app) => {
    setCurrentPolicy(app)
    setShowDeletePolicyModal(true)
  }

  return (
    <div className='flex gap-8'>
      {/* Hide the border in user view, because the other div which shows owners won't be shown */}
      <div
        className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8', {
          'border-none': appView === 'user'
        })}
      >
        <Headers
          handleAddApplicationClick={handleAddApplicationClick}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          showToggle={false}
          title={'Policies'}
          count={filteredPolicyList?.length}
          showAddButton={false}
          buttonLabel={''}
        />

        <div className='user-row' style={{ marginTop: '20px', paddingBottom: '35px' }}>
          {filteredPolicyList.map((item) => (
            <PolicyRow
              dropdownMenuItems={[
                {
                  submenu: [
                    {
                      action: () => deletePolicy(item),
                      title: 'Delete'
                    }
                  ],
                  title: <FontAwesomeIcon icon={faEllipsisVertical} />
                }
              ]}
              expirationTime={getRemainingDays(item?.NotAfter)}
              onClickRepositoryButton={() => {}}
              policyName={item?.GivenName}
              repositoryCount={repoLength}
              width='100%'
              onClick={() => getUserList(item)}
            />
          ))}
        </div>
      </div>

      {/* 
             Hide owners on user view
         */}
      {appView === 'admin' && (
        <div className='w-1/2'>
          <div className='user-row' style={{ marginTop: '20px' }}>
            {!showPolicyUserRow ? (
              <Styles.ActivePolicyHolder>
                <div className='icon-wrapper'>
                  <TargetIcon type='POLICY' />
                </div>
                <p>Select Policy to display users & teams with access</p>
              </Styles.ActivePolicyHolder>
            ) : (
              policyUsersList &&
              policyUsersList.map((item) => (
                <GithubUserRow
                  dropdownMenuItems={[
                    {
                      title: <FontAwesomeIcon icon={faEllipsisVertical} />
                    }
                  ]}
                  showControlButtons={false}
                  onClickRepositories={function noRefCheck() {}}
                  onClickUserGroups={function noRefCheck() {}}
                  repositoryCount={0}
                  userName={item.Spec.EmailID || item.Spec.TeamSpec.Name}
                  userEmail={item.Spec.FullName || `${item.Spec.Members?.Members?.length} User`}
                  permissions={[permissionMap[item?.permission] || 'Member']}
                />
              ))
            )}
          </div>
        </div>
      )}

      {/* delete policy modal */}

      {showDeletePolicyModal && (
        <DeletePolicyModal
          cleanUpFn={() => {
            if (canGoBack) setShowDeletePolicyModal(false)
            else setShowDeletePolicyModal(false)
          }}
          onCancel={() => setShowDeletePolicyModal(false)}
          app={currentPolicy}
          name={''}
        />
      )}
    </div>
  )
}

export { ActivePolicies }
