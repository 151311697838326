import { Button, Collapse, Grid, makeStyles } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React, { useState } from 'react'
import AlertLogItem from './AlertLogItem'
import Chips from './Chips'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.primary.white
  },
  mainIcon: {
    fill: theme.palette.grey[400]
  },
  title: {
    fontWeight: 500
  },
  summaryContainer: {
    paddingTop: theme.spacing(2)
  },
  collapse: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  actionButton: {
    fontWeight: 500,
    fontSize: '0.813rem'
  }
}))

const AlertLogs = ({
  Title,
  Icon = null,
  chips = [],
  logs = [],
  toggleButtonText = { open: 'Show All', close: 'Hide' },
  contentHeight = '15rem',
  onLogClick
}) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const handleItemClick = (data) => onLogClick?.(data)

  const renderLogItems = () => {
    return logs.map(({ Icon, metaData, Text, color, disableBorder, variant }, index) => (
      <AlertLogItem
        onClick={handleItemClick}
        key={index}
        metaData={metaData}
        Icon={Icon}
        Text={Text}
        color={color}
        disableBorder={disableBorder}
        variant={variant}
      />
    ))
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid spacing={1} alignItems='center' container>
          {Icon && (
            <Grid item>
              <Icon fontSize='medium' className={classes.mainIcon} />
            </Grid>
          )}
          <Grid item>
            <CustomTypo className={classes.title} variant='h3'>
              {Title}
            </CustomTypo>
          </Grid>
        </Grid>
        <Grid className={classes.summaryContainer} justifyContent='space-between' alignItems='center' container>
          <Grid item>
            <Chips chips={chips} />
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              onClick={() => setShow((state) => !state)}
              color='primary'
              size='small'
              endIcon={show ? <ArrowDropUp /> : <ArrowDropDown />}
            >
              {show ? toggleButtonText.close : toggleButtonText.open}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={show}>
        <div style={{ height: contentHeight }} className={classes.collapse}>
          {renderLogItems()}
        </div>
      </Collapse>
    </div>
  )
}

export { AlertLogs }
