import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { cn } from 'Utils/Helpers'
import { SearchInput } from 'V2Components'
import {
  createRsrcKey,
  isResourceManaged,
  resourceAdapterMap,
  useAccessCartProvider
} from 'features/resources'
import { K8ContextCommandsModal, usePrivateKubeAccess, useTargetAccess } from 'features/targets'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { ResourceRow, SimpleDropDown, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { KubeClusterTreeView } from './Components/KubeClusterTreeView'

const useSlices = createDataSelectorHook([
  'kubeNamespaces',
  'approvalReqList',
  'policyList',
  'kubeClusters'
])

const KubernetResources = ({ rsrc, onAccessClick }) => {
  const { viewMode } = useAppView()
  const history = useHistory()
  const { slices, sliceNames } = useSlices()
  const { getObjectRef } = useObjectRef(sliceNames)
  const { isResourceInCart } = useAccessCartProvider()
  const { getAccessGrantedTargetsFromPolicy } = useTargetAccess()
  const accessGrantedTargets = getAccessGrantedTargetsFromPolicy()
  const { currentUserIsAdmin } = useUser()
  const [selectedNodeRsrc, setSelectedNodeRsrc] = useState(null)
  const [searchKey, setSearchKey] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [showSingInModal, setShowSingInModal] = useState(false)
  const [principalName, setPrincipalName] = useState(null)

  const { getKubeAccessMap } = usePrivateKubeAccess()

  const getResourceData = (rsrc) => {
    const rsrcKind = rsrc.ObjectMeta.Kind
    const account = getObjectRef(rsrc.Spec.Account)
    const fn = resourceAdapterMap[rsrcKind]
    return fn?.({ rsrc, account })
  }

  const isResourceApprovalPending = (rsrc) => {
    let isPending = false
    slices.approvalReqList.some((req) => {
      if (req.Status !== 'Pending') return
      const resources = req.Spec.Resources.Resource
      resources.forEach((r) => {
        const targetKey = createRsrcKey(r.Target)
        const Kinds = ['KubeCluster', 'KubeNamespace']
        // Only targets approval request should be used
        if (Kinds.includes(r.Target.RefKind))
          if (targetKey === createRsrcKey(rsrc)) isPending = true
        return isPending
      })
      return isPending
    })
    return isPending
  }

  const getRsrcAccessState = () => {
    const data = getResourceData(rsrc)
    const rsrcKey = createRsrcKey(rsrc)
    const isManaged = isResourceManaged(rsrc)
    /** Checking the approval state of the object */
    let accessState = 'access'
    if (isResourceApprovalPending(rsrc)) accessState = 'waiting'
    if (isResourceInCart({ RefKind: data.RefKind, RefID: data.RefID })) accessState = 'requested'
    if (isManaged && (accessGrantedTargets[rsrcKey] || currentUserIsAdmin)) accessState = 'signIn'
    return accessState
  }

  const accessState = getRsrcAccessState()

  const handleRedirect = (item) => {
    history.push(
      `/${viewMode}/targets/${item?.ObjectMeta?.Kind?.toLowerCase()}/${item.ObjectMeta.Name}`
    )
  }

  const handleRequest = (item, data) => {
    const principalName = item
    onAccessClick(principalName, data)
  }

  const getTotalResourceCount = () => {
    let totalCount = 0
    if (!selectedNodeRsrc) return 0
    const { Pods, StatefulSets, Deployments, KubeServices } = selectedNodeRsrc.Spec
    totalCount +=
      Pods.Pods.length +
      StatefulSets.StatefulSets.length +
      Deployments.Deployments.length +
      KubeServices.KubeServices.length
    return totalCount
  }

  const getPodType = (type, name) => {
    if (type === 'KUBE_POD') {
      return `pod:${name}`
    }
    if (type === 'KUBE_STATEFULLSET') {
      return `sts:${name}`
    }
    if (type === 'KUBE_DEPLOYMENT') {
      return `deploy:${name}`
    }
    if (type === 'KUBE_SERVICES') {
      return `svc:${name}`
    }
  }

  const SortMenuItems = [
    {
      label: 'All Resources',
      value: 'all'
    },
    {
      label: 'Pods',
      value: 'pods'
    },
    {
      label: 'StatefulSets',
      value: 'statefulsets'
    },
    {
      label: 'Deployments',
      value: 'deployments'
    },
    {
      label: 'Services',
      value: 'services'
    }
  ]

  const getFilteredResources = () => {
    if (!selectedNodeRsrc) return []
    const { Pods, StatefulSets, Deployments, KubeServices } = selectedNodeRsrc?.Spec
    let filteredResources = []

    if (filterType === 'all' || filterType === 'pods') {
      const filteredPods = Pods.Pods.filter((pod) =>
        pod.Name.toLowerCase().includes(searchKey.toLowerCase())
      )
      filteredResources = [
        ...filteredResources,
        ...filteredPods.map((pod) => ({ ...pod, type: 'KUBE_POD', caption: 'Pod' }))
      ]
    }

    if (filterType === 'all' || filterType === 'statefulsets') {
      const filteredStatefulSets = StatefulSets.StatefulSets.filter((statefulSet) =>
        statefulSet.Name.toLowerCase().includes(searchKey.toLowerCase())
      )
      filteredResources = [
        ...filteredResources,
        ...filteredStatefulSets.map((statefulSet) => ({
          ...statefulSet,
          type: 'KUBE_STATEFULLSET',
          caption: 'Stateful Set'
        }))
      ]
    }

    if (filterType === 'all' || filterType === 'deployments') {
      const filteredDeployments = Deployments.Deployments.filter((deployment) =>
        deployment.Name.toLowerCase().includes(searchKey.toLowerCase())
      )
      filteredResources = [
        ...filteredResources,
        ...filteredDeployments.map((deployment) => ({
          ...deployment,
          type: 'KUBE_DEPLOYMENT',
          caption: 'Deployment'
        }))
      ]
    }

    if (filterType === 'all' || filterType === 'services') {
      const filteredServices = KubeServices.KubeServices.filter((services) =>
        services.Name.toLowerCase().includes(searchKey.toLowerCase())
      )

      filteredResources = [
        ...filteredResources,
        ...filteredServices.map((services) => ({
          ...services,
          type: 'KUBE_SERVICES',
          caption: 'Services'
        }))
      ]
    }
    return filteredResources
  }

  const generateResourcesRow = () => {
    return getFilteredResources().map((item) => (
      <ResourceRow
        caption={item.caption}
        dropdownMenuItems={[
          {
            icon: faEllipsisVertical,
            //@ts-ignore
            submenu: getDotmenu(getPodType(item.type, item.Name), selectedNodeRsrc),
            title: ''
          }
        ]}
        iconType={item.type}
        isGranted={
          accessState === 'signIn' ||
          getDotmenu(getPodType(item.type, item.Name), selectedNodeRsrc).length > 1
        }
        isNew
        onClickMoreInfoButton={() => handleRedirect(selectedNodeRsrc)}
        onClickRequestButton={() =>
          handleRequest(getPodType(item.type, item.Name), selectedNodeRsrc)
        }
        showMoreInfoButton
        showRequestButton={
          accessState === 'access' &&
          getDotmenu(getPodType(item.type, item.Name), selectedNodeRsrc).length === 0
        }
        title={item.Name}
        width='100%'
      />
    ))
  }

  const getDotmenu = (principalName, rsrc) => {
    const urls = [
      {
        action: () => handleRequest(principalName, selectedNodeRsrc),
        title: `${viewMode === 'admin' ? 'Create Policy' : 'Request'}`
      }
    ]

    const rsrcPolicys = getKubeAccessMap()[createRsrcKey(rsrc)] || []
    const policys = getObjectRef(rsrcPolicys)

    policys.forEach((item, index) => {
      const principalKey = item.Spec.ActionMap.KubeAccess.PolicyRule[0].Principal.split(',')
      principalKey?.map((item) => {
        if (item === principalName && urls.length === 1) {
          // Check if principal keys match
          urls.push({
            action: () => handleSingInModal(principalName),
            title: 'Copy Ctx'
          })
        }
      })
    })

    return urls
  }

  const handleSingInModal = (principal) => {
    setPrincipalName(principal)
    setShowSingInModal(true)
  }

  useEffect(() => {
    if (!selectedNodeRsrc && slices.kubeNamespaces.length) {
      const filterClusterRefId = slices.kubeNamespaces.filter(
        (item) => item.Spec && item.Spec.Cluster && item.Spec.Cluster.RefID === rsrc.ObjectMeta.ID
      )
      setSelectedNodeRsrc(filterClusterRefId[0])
    }
  }, [slices.kubeNamespaces])

  return (
    <div className='h-[100%]'>
      <div className='flex gap-4'>
        <div className={cn('border-r w-1/2 border-[#D8DDE4]')}>
          <Typography variant='h3' className='my-5'>
            Select Resource
          </Typography>
          <KubeClusterTreeView
            onNodeClick={(kubeNamespace) => {
              setSelectedNodeRsrc(kubeNamespace)
            }}
            accessState={accessState}
            kubeCluster={rsrc}
          />
        </div>

        <div className='w-1/2'>
          <div className='border-b'>
            {selectedNodeRsrc && (
              <ResourceRow
                caption={selectedNodeRsrc.Spec.DisplayName}
                dropdownMenuItems={[
                  {
                    icon: faEllipsisVertical,
                    //@ts-ignore
                    submenu: getDotmenu('', selectedNodeRsrc),
                    title: ''
                  }
                ]}
                iconType='KUBE_NAMESPACES'
                isGranted={accessState === 'signIn' || getDotmenu('', selectedNodeRsrc).length > 1}
                isNew
                onClickMoreInfoButton={() => handleRedirect(selectedNodeRsrc)}
                onClickRequestButton={() => handleRequest('', selectedNodeRsrc)}
                showMoreInfoButton
                showRequestButton={
                  accessState === 'access' && getDotmenu('', selectedNodeRsrc).length === 0
                }
                title={selectedNodeRsrc.Spec.Name}
                width='100%'
              />
            )}
          </div>
          {selectedNodeRsrc && (
            <>
              <div className='flex justify-between my-5'>
                <Typography variant='h3' className='pl-5'>
                  Resources
                </Typography>
                <SearchInput className='w-[300px]' searchKey={searchKey} onChange={setSearchKey} />
              </div>
              <div className='flex justify-between'>
                <Typography variant='body-regular' className='pl-5'>
                  {getTotalResourceCount() + ' Resources'}
                </Typography>
                <span className='flex'>
                  <Typography variant='breadcrumbLink' className='pr-1'>
                    Show :
                  </Typography>
                  <SimpleDropDown
                    menuItems={SortMenuItems}
                    onChange={(e) => setFilterType(e.target.value)}
                    value={filterType}
                  />
                </span>
              </div>
            </>
          )}
          <div className=''>{selectedNodeRsrc && generateResourcesRow()}</div>
        </div>
      </div>
      {showSingInModal && (
        <K8ContextCommandsModal
          principalName={principalName}
          onClose={() => setShowSingInModal(false)}
          rsrc={selectedNodeRsrc}
        />
      )}
    </div>
  )
}

export { KubernetResources }
