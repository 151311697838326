import { ApiProvider } from 'Core'
import { createRef } from 'features/resources'

export const deleteUser = async (user) => {
  await new ApiProvider('users').setInstance(user).delete()
  return user
}

export const createUser = async (user) => {
  const response = await new ApiProvider('users').setInstance(user).post()
  return response.data
}

export const updateUser = async (user) => {
  const response = await new ApiProvider('users').setInstance(user).put()
  return response.data
}

export const fetchUser = async (user) => {
  const response = await new ApiProvider('users').setInstance(user).get()
  return response.data
}

/**
 * Add the user as RdOwner of the same user object
 * !Only used when user is created for the very first time.
 * * Remove once BE manages the rd owners update
 * @param {*} user
 * @returns user
 */
export const addUserSelfRdOwner = async (user) => {
  const defaultRdOwners = user?.ObjectMeta?.RdOwners?.ObjectRef || []
  const newRdOwners = {
    ObjectRef: [...defaultRdOwners, createRef(user)]
  }
  const newUser = structuredClone(user)
  newUser.ObjectMeta.RdOwners = newRdOwners
  const response = await new ApiProvider('users').setInstance(newUser).put()
  return response.data
}

/**
 * Creates user preference for specified user
 * @param {{ObjectMeta:{Name:String, ID: String}}} user
 * @returns {Promise}
 */
export const createUserPreference = async (user) => {
  const preference = {
    ObjectMeta: {
      Name: user.ObjectMeta.Name,
      RdOwners: { ObjectRef: [{ RefKind: 'User', RefID: user.ObjectMeta.ID }] },
      WrOwners: { ObjectRef: [{ RefKind: 'User', RefID: user.ObjectMeta.ID }] }
    },
    Spec: { Attributes: { Map: { LastViewForApp: 'user' } } }
  }
  return await new ApiProvider('userpreferences').setInstance(preference).post()
}

/**
 * Add user to admin group making the `user` admin
 * @param {object} adminGroup Admin group object
 * @param {object} user The user who needs to be added into admin group
 * @returns Updated user
 */
export const addUserToAdminGroup = async (adminGroup, user) => {
  const newUser = structuredClone(user)
  const newGroups = newUser.Spec.Groups?.ObjectRef || []
  newGroups.push(createRef(adminGroup))
  const response = await new ApiProvider('users').setInstance(newUser).put()
  return response.data
}

/**
 * Change the `Active` status of user
 * @param {object} user 
 * @param {boolean} isActive 
 */
export const changeUserStatus = async (user, isActive) => {
  const newUser = structuredClone(user)
  newUser.Spec.Active = isActive
  return await updateUser(newUser)
}

/**
 * Create onboarding link for `user`
 * @param {{ObjectMeta:{Name:String,Namespace:String,Tenant:String}}} user
 * @returns {Promise<{ OnboardTokenLink: string; } | null>}
 */
export const getUserOnboardingLink = async (user) => {
  try {
    const payload = {
      ObjectMeta: {
        Tenant: user.ObjectMeta.Tenant,
        Namespace: user.ObjectMeta.Namespace
      },
      UserID: user.ObjectMeta.Name
    }
    const response = await new ApiProvider('onboardtokens').setInstance(payload).post()
    return response.data
  } catch (error) {
    return null
  }
}