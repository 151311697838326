import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const VPC = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 40 40' {...props}>
      <svg className='w-6 h-6' height='40' width='40' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient x1='0%' y1='100%' x2='100%' y2='0%' id='Arch_Amazon-Virtual-Private-Cloud_32_svg__a'>
            <stop stop-color='#4D27A8' offset='0%' />
            <stop stop-color='#A166FF' offset='100%' />
          </linearGradient>
        </defs>
        <g fill='none' fill-rule='evenodd'>
          <path d='M0 0h40v40H0z' fill='url(#Arch_Amazon-Virtual-Private-Cloud_32_svg__a)' />
          <path
            d='M30.971 21.03l-2.944-1.263v10.489c2.546-.352 2.932-2.968 2.948-3.087l-.004-6.14zm-3.944 9.24V19.76l-3.035 1.273v6.2c.012.055.445 2.768 3.035 3.037zm.306-11.722a.5.5 0 01.391 0l3.944 1.693a.5.5 0 01.303.459v6.534c-.183 1.447-1.357 4.063-4.527 4.063-3.075 0-4.254-2.612-4.448-3.994l-.004-6.603c0-.201.121-.383.306-.461l4.035-1.691zm5.65.637l-5.461-2.406-5.53 2.327v7.128c-.001.042-.054 2.868 1.657 4.623.917.94 2.194 1.419 3.795 1.419 1.602 0 2.887-.48 3.819-1.422 1.735-1.758 1.72-4.585 1.72-4.613v-7.056zM31.98 31.55c-1.127 1.144-2.654 1.725-4.536 1.725-1.884 0-3.404-.581-4.517-1.727-2.005-2.063-1.939-5.197-1.935-5.33v-7.445a.5.5 0 01.305-.461l6.035-2.54a.5.5 0 01.396.004l5.956 2.625c.182.08.299.259.299.457v7.375c.002.125.027 3.255-2.003 5.317zm-20.988-7.314h8v1h-8c-2.811 0-4.811-2.016-4.979-5.016A3.745 3.745 0 016 19.84c0-2.508 1.318-4.222 3.817-4.978a8.352 8.352 0 01-.032-.647c0-2.916 1.577-5.445 4.017-6.445 3.298-1.348 7.042-.895 9.106 1.104a8.232 8.232 0 011.76 2.485c.609-.451 1.4-.663 2.349-.614 1.78.09 3.149 1.713 3.433 4.007 1.498.124 2.485.883 3.01 2.308l-.937.346c-.428-1.157-1.208-1.673-2.531-1.673a.5.5 0 01-.5-.47c-.138-2.338-1.406-3.463-2.526-3.52-.948-.05-1.645.22-2.079.792a.506.506 0 01-.473.193.503.503 0 01-.395-.323 7.378 7.378 0 00-1.807-2.814c-1.788-1.73-5.09-2.1-8.031-.895-2.063.845-3.396 3.01-3.396 5.519 0 .273.035.696.066.959a.498.498 0 01-.376.544C8.169 16.29 7 17.678 7 19.842c0 .1-.001.2.009.302.141 2.495 1.703 4.093 3.983 4.093z'
            fill='#FFF'
          />
        </g>
      </svg>
    </SvgIcon>
  )
})

export default VPC
