import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import _ from 'lodash'
import React, { createContext, useContext, useState } from 'react'
import { useParams } from 'react-router'

const UserDetailsContext = createContext({
  user: null
})

const findUser = (list, userName) => {
  const u = _.find(list, { ObjectMeta: { Name: userName } })
  return u
}

export const UserDetailsProvider = ({ children }) => {
  const { slices, initiallyLoaded, selectDispatch } = useMultiSlice(['userList'])
  //@ts-ignore
  const { userName } = useParams()

  const [user, setUser] = useState(() => {
    return findUser(slices.userList, userName)
  })

  if (!initiallyLoaded) selectDispatch(['userList'])
  else if (initiallyLoaded) {
    const u = findUser(slices.userList, userName)
    if (u && u !== user) setUser(u)
  }

  return (
    <UserDetailsContext.Provider value={{ user }}>
      {!user && <LoadingFeedback shadow={false} shrinked loading message='Fetching User' />}
      {user && children}
    </UserDetailsContext.Provider>
  )
}

export const useUserDetailsContext = () => useContext(UserDetailsContext)
