import { Box, makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
  distribution: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(115%)'
    }
  }
}))
const Distribution = ({ name, value, color, sum }) => {
  const percent = (value / sum) * 100
  const classes = useStyle()
  return (
    <Tooltip title={`${name} ${percent.toFixed(2)}%`}>
      <Box
        style={{
          backgroundColor: color,
          width: `${percent}%`
        }}
        key={name}
        className={classes.distribution}
      />
    </Tooltip>
  )
}

export default Distribution
