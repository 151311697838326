import { faCheck, faPen } from '@fortawesome/pro-solid-svg-icons'
import { SimpleIAMRolesSelectionDrawer } from 'features/iamResources'
import { createRef, createRsrcKey } from 'features/resources'
import { useTagsCreatePolicyContext } from 'features/tags'
import { useObjectRef } from 'infra/redux'
import { Button, IconButton, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { RsrcRow } from './components/RsrcRow'

const ResourcesTab = () => {
  const [showIamRolesDrawer, setShowIamRolesDrawer] = useState(false)
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const [showPrincipalInput, setShowPrincipalInput] = useState(false)

  const { resources, rsrcAttributesSpec, updateRsrcAttributes } = useTagsCreatePolicyContext()

  const { getObjectRef } = useObjectRef(['iamActions', 'accountList'])

  const handleRsrcRolesSelect = (rsrc) => {
    setSelectedRsrc(rsrc)
    setShowIamRolesDrawer(true)
  }

  const handleRsrcIAMRolesSaveClick = (rsrcRef, iamRolesRefs) => {
    updateRsrcAttributes(createRsrcKey(rsrcRef), { IamRoles: iamRolesRefs })
  }

  const hasServerRsrc = resources.find((e) => e.ObjectMeta.Kind === 'Server')
  const Principal = rsrcAttributesSpec.Server?.Principal

  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <Typography className='mt-4' variant='h4-regular'>
          Resources
        </Typography>
        <div>
          {hasServerRsrc && (
            <div className='flex items-center justify-center gap-4'>
              {showPrincipalInput && (
                <TextInput
                  placeholder='Enter Principal'
                  sx={{ width: '200px' }}
                  value={Principal}
                  onChange={(e) => updateRsrcAttributes('Server', { Principal: e.target.value })}
                />
              )}
              {showPrincipalInput && (
                <div className='flex gap-2 items-center justify-center'>
                  <IconButton
                    onClick={() => setShowPrincipalInput(false)}
                    icon={faCheck}
                    variant='gray'
                  />
                </div>
              )}
              {!showPrincipalInput && (
                <Button icon={faPen} onClick={() => setShowPrincipalInput(true)} variant='grayBlue'>
                  Edit Server Principal
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='mt-6'>
        {resources.map((rsrc) => {
          return (
            <RsrcRow
              onRsrcAttriputesUpdate={updateRsrcAttributes}
              rsrc={rsrc}
              rsrcAttributesSpec={rsrcAttributesSpec}
              getObjectRef={getObjectRef}
              handleRsrcRolesSelect={handleRsrcRolesSelect}
            />
          )
        })}
      </div>
      {selectedRsrc && (
        <SimpleIAMRolesSelectionDrawer
          showDrawer={showIamRolesDrawer}
          onCloseDrawer={() => {
            setShowIamRolesDrawer(false)
            setSelectedRsrc(null)
          }}
          onSaveClick={handleRsrcIAMRolesSaveClick}
          selectedRsrc={createRef(selectedRsrc)}
          initialIamRoles={rsrcAttributesSpec[createRsrcKey(selectedRsrc)]?.IamRoles || []}
        />
      )}
    </div>
  )
}

export { ResourcesTab }
