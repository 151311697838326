import { ApiProvider } from "Core"

export const createGroup = async (data) => {
  const response = await new ApiProvider('groups').setInstance(data).post()
  return response.data
}

export const deleteGroup = async (g) => {
  await new ApiProvider('groups').setInstance(g).delete()
  return g
}

export const updateGroup = async (g) => {
  const response = await new ApiProvider('groups').setInstance(g).put()
  return response.data
}

export const fetchGroup = async (group) => {
  const response = await new ApiProvider('groups').setInstance(group).get()
  return response.data
}
