import { getApplicationType } from 'features/applications'
import { getRsrcTags } from 'features/iamResources'
import { getResourcePolicyTags } from 'features/resources'
import { getIAMResourceDisplayType } from 'features/iamResources'

export const TAG_DELIMITER = '!'

//ptag=ctags
export const ptagFilterFn = ({ tagsMap, queryKey }) => {
  const split = queryKey.split(TAG_DELIMITER)

  // Check if the tagName is provided as in: tag:Engine
  if (split.length > 1) {
    const tagName = split[0].trim()
    const tagValue = tagsMap[tagName] || ''
    const valueSplits = tagValue.split(',')
    return valueSplits.includes(split[1].trim())
    // return tagsMap[tagName]?.includes(split[1].trim())
  }
  const tagsValues = Object.keys(tagsMap)
  return tagsValues.join('').toLowerCase().includes(queryKey.toLowerCase())
}

//tag=cloudtags=label
export const tagFilterFn = (rsrcTags, queryKey) => {
  const split = queryKey.split(TAG_DELIMITER)
  const tagName = split[0]?.trim()

  // Since tag name is not present, so return true for only rsrc which have tag in object
  if (!tagName) return Object.keys(rsrcTags).length > 0

  const tagValue = split.slice(1).join(TAG_DELIMITER).trim()
  const rsrcTagValue =
    typeof rsrcTags[tagName] === 'string' ? rsrcTags[tagName] : JSON.stringify(rsrcTags[tagName])
  // Check if the tagName and tagValue is provided as in: tag:Engine then
  // check for match
  if (tagName && tagValue) return rsrcTagValue === tagValue

  // only tag name is provided then,

  return !!rsrcTags[tagName]
}

/**
 * Map of resource kind with respective query resolver function.
 *
 * Format:
 *
 * ```
 *  [x: Kind]: {
 *     [x: Query Tag Resolver Function] : (rsrc, queryKey) => boolean (true if match, else false)
 *  }
 *
 * ```
 * @type {import('Core/Hooks/useSearchQuery').QueryMapType}
 *
 */
export const ResourceQueryFilterMap = {
  AwsResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagFilterFn(rsrcTags, queryKey)
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  },
  GcpResource: {
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  },
  AzureResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagFilterFn(rsrcTags, queryKey)
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  },
  Server: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagFilterFn(rsrcTags, queryKey)
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    }
  },
  Application: {
    ptag(app, queryKey) {
      //other app types are not supported yet
      if (getApplicationType(app) !== 'HTTP') return false
      const policyTags = getResourcePolicyTags(app)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    }
  }
}

const tagsSuggesstionsFn = ({ tagsObj, queryKey, tagKey = 'ptag' }) => {
  const split = queryKey.split(TAG_DELIMITER)
  if (split.length > 1) {
    const tagName = split[1]
    const tagValue = tagsObj[tagName]
    console.log('[tag](value):', tagValue)
    if (tagValue) {
      const suggestions = []
      const tagValueSplits = tagValue.split(',')
      for (const value of tagValueSplits) {
        suggestions.push({
          title: `${tagName}${TAG_DELIMITER}${value}`,
          template: `[${tagKey}${TAG_DELIMITER}${tagName}${TAG_DELIMITER}${value}] `
        })
      }

      return suggestions
    }
  }

  const items = []
  for (const key in tagsObj) {
    // If the rsrc tag has no value or value is empty return
    if (tagsObj[key].trim().length) {
      items.push({
        title: `${key}`,
        template: `[${tagKey}${TAG_DELIMITER}${key}${TAG_DELIMITER}]`
      })
    }
  }
  return items
}

/**
 * The suggestions map for the tags which is supported.
 * @type {import('Core/Hooks/useSearchQuery').SuggestionMapType}
 */
export const ResourceTagSuggesstionsMap = {
  AwsResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: rsrcTags,
        queryKey,
        tagKey: 'tag'
      })
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${TAG_DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  },
  GcpResource: {
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)

      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${TAG_DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  },
  AzureResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: rsrcTags,
        queryKey,
        tagKey: 'tag'
      })
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)

      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${TAG_DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  },
  Server: {
    tag(rsrc, queryKey) {
      const rsrcTags = getRsrcTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: rsrcTags,
        queryKey,
        tagKey: 'tag'
      })
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    }
  },
  Application: {
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    }
  }
}

// =================================== // ===================================// ===================================//

export const DefaultSearchSuggesstionsMap = {
  AwsResource: [
    {
      title: 'tag: Search By Cloud Tags',
      template: `[tag${TAG_DELIMITER}$]`,
      caption: 'Eg: tag!engine!postgres'
    },
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${TAG_DELIMITER}$]`,
      caption: `Eg: ptag!appgroup!abc`
    },
    {
      title: `type: Search by Type`,
      template: `[type${TAG_DELIMITER}$]`,
      caption: `Eg: type!ec2`
    }
  ],
  GcpResource: [
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${TAG_DELIMITER}$]`,
      caption: 'Eg: ptag!appgroup!abc'
    },
    {
      title: 'type: Search by Type',
      template: `[type${TAG_DELIMITER}$]`,
      caption: 'Eg: type!instance'
    }
  ],
  AzureResource: [
    {
      title: 'tag: Search By Cloud Tags',
      template: `[tag${TAG_DELIMITER}$]`,
      caption: 'Eg: tag!engine!postgres'
    },
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${TAG_DELIMITER}$]`,
      caption: 'Eg: ptag!appgroup!abc'
    },
    {
      title: 'type: Search by Type',
      template: `[type${TAG_DELIMITER}$]`,
      caption: 'Eg: type!keyvault'
    }
  ],
  Server: [
    {
      title: 'tag: Search By Cloud Tags',
      template: `[tag${TAG_DELIMITER}$]`,
      caption: 'Eg: tag!engine!postgres'
    },
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${TAG_DELIMITER}$]`,
      caption: `Eg: ptag!appgroup!abc`
    }
  ],
  Application: [
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${TAG_DELIMITER}$]`,
      caption: `Eg: ptag!appgroup!abc`
    }
  ]
}

export const getTagsDefaultSuggestions = (rsrcs) => {
  const defSuggestions = []

  const pushedTemplates = {}

  rsrcs.forEach((r) => {
    const options = DefaultSearchSuggesstionsMap[r.ObjectMeta.Kind] || []
    options.forEach((opt) => {
      // Do not push duplicate templates
      if (pushedTemplates[opt.template]) return
      defSuggestions.push(opt)
      pushedTemplates[opt.template] = true
    })
  })

  return defSuggestions
}
