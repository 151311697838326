import { createRef, getRsrcIcon } from 'features/resources'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { TargetIcon, TreeItem, TreeView } from 'procyon-ui'
import React from 'react'

const useSlices = createDataSelectorHook(['awsResources'])

const ResourceListTreeView = ({ ecsRsrc, onNodeClick, accessState }) => {
  const { slices, sliceNames } = useSlices()
  const { getObjectRef } = useObjectRef(sliceNames)

  const handleNodeClick = ({ ref }) => {
    if (!ref) return
    onNodeClick?.(getObjectRef(ref))
  }

  return (
    <TreeView
      height={'100%'}
      maxWidth={`100%`}
      onNodeFocus={() => {}}
      onNodeSelect={(_, nodeId) => {}}
      onNodeToggle={() => {}}
      sx={{
        flexGrow: 1,
        overflowY: 'auto'
      }}
    >
      {ecsRsrc?.map((item, index) => (
        <TreeItem
          key={index}
          onClick={() =>
            handleNodeClick({
              ref: createRef(item)
            })
          }
          isGranted={accessState[index] === 'signIn'} // Check the access state for each resource
          labelIcon={<TargetIcon type={item.Spec.Type.toUpperCase()} />}
          labelText={item.Spec.Name}
          nodeId={index}
          caption={item.Spec.Type}
        />
      ))}
    </TreeView>
  )
}

export { ResourceListTreeView }
