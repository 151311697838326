import { Box, Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core'
import MountOn from 'Components/MountOn'
import React, { useEffect } from 'react'
import SearchBar from '../SearchBar'
import TimeFilter from '../TimeFilter'
import ExternalFilters from './ExternalFilters'
import * as FiltrationFunctions from './FilterationFunctions'
import { SearchInput } from 'V2Components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1, 0.5, 1),
    backgroundColor: '#F9FBFC'
  },
  advancedFilterLabel: {
    fontWeight: 500
  },
  filterText: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.25)
  },
  expandIcon: {
    marginLeft: theme.spacing(0.25),
    fontSize: theme.spacing(0.875)
  },
  checkbox: {
    marginLeft: theme.spacing(3.125),
    marginRight: 0
  }
}))

function Header ({
  searchKey,
  sourceData = [],
  applyFiltersOnChange,
  setSearchKey,
  filters = [],
  setFilteredData,
  externalFilters = [],
  hideTimeFilter,
  searchBarWidth = 37,
  controlCheckbox = [],
  initialTime = { start: null, end: null },
  onTimeChange = null,
  onApplyClick = null,
  RightFilterComponent = null,
  LeftFilterComponent=null,
  TopRightFilterComponent = null
}) {
  const classes = useStyles()

  const applyFilters = () => {
    let tempData = [...sourceData]
    filters.forEach((filter) => {
      tempData = FiltrationFunctions.applyTypeBasedFilter(tempData, filter.name, filter.value, filter.filterType)
    })
    setFilteredData && setFilteredData(tempData)
  }

  useEffect(() => {
    applyFilters()
  }, [sourceData])

  useEffect(() => {
    if (applyFiltersOnChange) {
      applyFilters()
    }
  }, [filters])

  return (
    <Box className={classes.root}>
      <MountOn on={!hideTimeFilter}>
        <Box mb={0.5} display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <TimeFilter initialTime={initialTime} onTimeChange={onTimeChange} onApplyClick={onApplyClick} />
          <div>{typeof TopRightFilterComponent === 'function' && TopRightFilterComponent()}</div>
        </Box>
      </MountOn>
      <Grid justifyContent='space-between' container>
        <Grid className='flex gap-4 items-center' item>
          <SearchInput searchKey={searchKey} onChange={setSearchKey} />
          {LeftFilterComponent && <div>{LeftFilterComponent}</div>}
        </Grid>
        {externalFilters.length > 0 && (
          <Grid xs={1.5} item>
            <ExternalFilters externalFilters={externalFilters} />
          </Grid>
        )}
        {controlCheckbox.length > 0 && (
          <Grid item>
            <Box display='flex' justifyContent='space-between'>
              {controlCheckbox.map((item, index) => (
                <FormControlLabel
                  key={index}
                  className={classes.checkbox}
                  control={
                    <Checkbox
                      checked={item.checked}
                      onClick={(e) => {
                        item.onClick && item.onClick(item)
                      }}
                      color={item.color || 'primary'}
                    />
                  }
                  label={item.label || 'No Label'}
                />
              ))}
            </Box>
          </Grid>
        )}
        {RightFilterComponent && <Grid item>{RightFilterComponent}</Grid>}
      </Grid>
    </Box>
  )
}

export default Header
