import { faServicestack } from '@fortawesome/free-brands-svg-icons'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'Core/Hooks/useUser'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import moment from 'moment'
import { Button, DataTable, IamRolesCard, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { enqueueNotification } from 'Utils/Helpers'
import { ShimmerTableRow } from 'Utils/ShimmerEffect'
import { FloatingDrawer, FullScreenModal } from 'V2Components'
import { ConsoleAccessRecommendation, MFARecommendation } from './Components'
import { UnusedAccessKeyRecommendation } from './Components/UnusedAccessKeyRecommendation'
import { UnusedIamPasswordRecommendation } from './Components/UnusedIamPasswordRecommendation'
import { JsonViewer } from './Components/ReactJson'
import JSONPretty from 'react-json-pretty'

const useSlices = createDataSelectorHook(['iamUsers', 'iamRoles'])

export default function RemediationDetails() {
  const { kind, id } = useParams()
  const { slices } = useSlices()
  const { user } = useUser()
  const history = useHistory()
  const [remedyData, setRemedyData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showSidear, setShowSidear] = useState(false)
  const [expandedRows, setExpandedRows] = useState({})
  const [recommondedResponse, setRecommondedResponse] = useState([])
  const [showRecommendedModal, setShowRecommendedModal] = useState(false)
  const [loadingStates, setLoadingStates] = useState({})
  const [recommendedResponseData, setRecommendedResponseData] = useState(null)
  const [selectedActionsList, setSelectedActionsList] = useState([])
  const [dataType, setDataType] = useState(null)
  const [showStepsRecommendation, setShowStepsRecommendation] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0
  })

  useEffect(() => {
    if (kind === 'IamUser') {
      if (slices?.iamUsers?.length > 0) {
        const userObj = _.find(slices.iamUsers, { ObjectMeta: { ID: id } })
        if (userObj) {
          setIsLoading(false)
          setRemedyData(userObj?.Spec?.AwsUserSpec)
        }
      } else {
        setIsLoading(true)
      }
    }

    if (kind === 'IamRole') {
      if (slices?.iamRoles.length > 0) {
        const roleObj = _.find(slices.iamRoles, { ObjectMeta: { ID: id } })
        if (roleObj) {
          setIsLoading(false)
          setRemedyData(roleObj?.Spec?.AwsRoleSpec)
        }
      } else {
        setIsLoading(true)
      }
    }
  }, [kind, id, slices?.iamUsers, slices?.iamRoles])

  const handleAllActionsClick = (actions) => {
    setShowSidear(true)
    setSelectedActionsList(actions)
  }

  const getIamUserEmail = (arn) => {
    const spliteEmail = arn?.split('user/')[1]
    return spliteEmail
  }

  const rectifyUnusedRole = async (data, findingID) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org
      },
      Spec: {
        Type: 'IAM_RECTIFICATION',
        RectificationSpec: {
          Type: 'UnusedIAMRole',
          FindingID: findingID,
          IamIdentity: {
            RefID: id,
            RefKind: kind
          }
        }
      }
    }

    try {
      await reduxApiClient('analyze-identity').create(payload)
      enqueueNotification('Removed Unused Role Successfully!', 'info')
      await reduxApiClient('iamroles').getAll({})
      history.push('/admin/idaanalyzer')
    } catch (e) {
      console.log(e)
    }
  }

  const getRecommendation = async (data, findingID) => {
    setDataType(data)

    if (data?.Type === 'UnusedIAMUserPassword') {
      setDataType('UnusedIAMUserPassword')
    }

    setLoadingStates((prev) => ({ ...prev, [findingID]: true }))
    let hasUnusedIAMUserAccessKey
    if (data === 'AccessKeys') {
      console.log(findingID)
      hasUnusedIAMUserAccessKey = findingID.AccessKeys.some((accessKey) =>
        Object.values(accessKey.AnalyzerFindings.AnalyzerFindingMap).some(
          (finding) => finding.Type === 'UnusedIAMUserAccessKey'
        )
      )
    }

    if (hasUnusedIAMUserAccessKey) {
      setDataType('UnusedIAMUserAccessKey')
    }

    if (
      data === 'MFAEnabled' ||
      data === 'ConsoleAccess' ||
      hasUnusedIAMUserAccessKey ||
      data?.Type === 'UnusedIAMUserPassword'
    ) {
      setLoadingStates((prev) => ({ ...prev, [findingID]: false }))
      return setShowStepsRecommendation(true)
    }

    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org
      },
      Spec: {
        Type: 'GET_RECOMMENDATION',
        RecommendationSpec: {
          FindingType: data.Type,
          FindingID: findingID,
          AnalyzeArn: data.AnalyzerArn,
          Region: data.Region,
          IamIdentity: {
            RefID: id,
            RefKind: kind
          }
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      setRecommondedResponse(
        response?.Spec?.RecommendationSpec?.RecommendationResponses?.RecommendationResponse
      )
      setRecommendedResponseData(response.Spec.RecommendationSpec)
      setShowRecommendedModal(true)
    } catch (error) {
      console.error('Error fetching recommendation:', error)
    } finally {
      setLoadingStates((prev) => ({ ...prev, [findingID]: false }))
    }
  }

  const filteredRows = (data) => {
    if (!data) return []
    let parsedDetails = []
    try {
      parsedDetails = JSON.parse(data)
    } catch (error) {
      console.error('Error parsing Details:', error)
    }
    return parsedDetails.map((obj, index) => {
      const { ServiceNamespace, Actions, LastAccessed } = obj.Value || {}
      return {
        id: index + 1,
        services: ServiceNamespace || 'Unknown Service',
        unusedactions: Actions ? (
          <span
            className='underline text-[#5757f8] cursor-pointer'
            onClick={() => handleAllActionsClick(Actions)}
          >
            All Actions
          </span>
        ) : (
          'No actions available'
        ),
        lastused: LastAccessed ? moment(LastAccessed).format('MM-DD-YY HH:mm:ss') : 'Never Used'
      }
    })
  }

  const toggleDetails = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId]
    }))
  }

  const generateMFAAndAccessKey = (key, value) => {
    return (
      <div className='bg-white p-6 shadow-md rounded-lg mb-4'>
        <div className='flex justify-between'>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Type :</span>
            <span className='text-[16px] text-[#727171] font-medium ml-3'>{key}</span>
          </div>
          <div className='flex items-center w-2/4'>
            <span className='text-[16px] text-gray-500'>Resource Type :</span>
            <span className='text-[16px] text-[#727171] font-medium ml-3'>{kind}</span>
          </div>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Recommended Action :</span>
            <Button
              style={{ width: '120px', marginLeft: '12px' }}
              variant='warning'
              disabled={loadingStates[key]}
              onClick={() => getRecommendation(key, value)}
            >
              {loadingStates[key] ? 'Fetching...' : 'Actions'}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const generateFindingRows = (data) => {
    return Object.entries(data.AnalyzerFindingMap).map(([findingId, finding], index) => {
      const columns = [
        {
          field: 'services',
          headerName: 'Serivices',
          renderCell: (param) =>
            isLoading ? (
              <ShimmerTableRow />
            ) : (
              <>
                <span className='mr-2'>
                  <FontAwesomeIcon icon={faServicestack} />
                </span>
                {param.value}
              </>
            ),
          width: 300
        },
        {
          field: 'unusedactions',
          headerName: 'Unused Actions',
          renderCell: (param) =>
            isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
          width: 300
        },
        {
          field: 'lastused',
          headerName: 'Last Used',
          renderCell: (param) =>
            isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
          width: 400
        }
      ]

      const rows = filteredRows(finding.Details)

      return (
        <div key={findingId} className='bg-white p-6 shadow-md rounded-lg mb-4'>
          <div className='flex justify-between'>
            <div className='flex items-center w-1/4'>
              <span className='text-[16px] text-gray-500'>Type :</span>
              <span className='text-[16px] text-[#727171] font-medium ml-3'>{finding.Type}</span>
            </div>
            <div className='flex items-center w-1/4'>
              <span className='text-[16px] text-gray-500'>Resource Type :</span>
              <span className='text-[16px] text-[#727171] font-medium ml-3'>{kind}</span>
            </div>
            <div className='flex items-center  w-1/4'>
              <span className='text-[16px] text-gray-500'>Resource Arn :</span>
              <span className='text-[16px] text-[#727171] font-medium ml-3'>
                {getIamUserEmail(finding.ResourceArn)}
              </span>
            </div>

            {finding.Type !== 'UnusedIAMRole' && (
              <div className='flex items-center w-1/4'>
                <span className='text-[16px] text-gray-500'>Recommended Action :</span>
                <Button
                  style={{ width: '120px', marginLeft: '12px' }}
                  variant='warning'
                  disabled={loadingStates[findingId]}
                  onClick={() => getRecommendation(finding, findingId)}
                >
                  {loadingStates[findingId] ? 'Fetching...' : 'Actions'}
                </Button>
              </div>
            )}

            {finding.Type === 'UnusedIAMRole' && (
              <div className='flex items-center w-1/4'>
                <span className='text-[16px] text-gray-500'>Recommended Action :</span>
                <Button
                  style={{ width: '120px', marginLeft: '12px' }}
                  variant='primary'
                  disabled={loadingStates[findingId]}
                  onClick={() => rectifyUnusedRole(finding, findingId)}
                >
                  {'Fix Now'}
                </Button>
              </div>
            )}
          </div>
          <div className='flex mt-5'>
            <div className='w-full'>
              <span
                className='flex text-[16px] cursor-pointer text-gray-500 items-center underline'
                onClick={() => toggleDetails(findingId)}
              >
                Details
                <span className='ml-2 mt-1'>
                  <FontAwesomeIcon icon={expandedRows[findingId] ? faAngleUp : faAngleDown} />
                </span>
              </span>

              {expandedRows[findingId] && (
                <div className='mt-4'>
                  <DataTable
                    autoHeight={true}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    columns={columns}
                    rows={rows}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const handleRecitifacation = async () => {
    let payload
    if (kind === 'IamUser') {
      payload = {
        ObjectMeta: {
          Tenant: user.tenant,
          Namespace: user.org
        },
        Spec: {
          Type: 'IAM_RECTIFICATION',
          RectificationSpec: {
            Type: recommendedResponseData?.FindingType,
            FindingID: recommendedResponseData?.FindingID,
            IamIdentity: {
              RefID: recommendedResponseData?.IamIdentity?.RefID,
              RefKind: recommendedResponseData?.IamIdentity?.RefKind
            },
            RecommendationResponses: recommendedResponseData.RecommendationResponses
          }
        }
      }
    }

    if (kind === 'IamRole') {
      payload = {
        ObjectMeta: {
          Tenant: user.tenant,
          Namespace: user.org
        },
        Spec: {
          Type: 'IAM_RECTIFICATION',
          RectificationSpec: {
            Type: 'UnusedIAMRole',
            FindingID: recommendedResponseData?.FindingID,
            IamIdentity: {
              RefID: id,
              RefKind: kind
            }
          }
        }
      }
    }

    await reduxApiClient('analyze-identity').create(payload)
    setShowRecommendedModal(false)
    if (kind === 'IamUser') {
      await reduxApiClient('iamusers').getAll({})
    }
    await reduxApiClient('iamroles').getAll({})

    enqueueNotification('Recommended fixes applied successfully!', 'info')
    history.push(`/admin/idaanalyzer`)
  }

  const generateAccessKeysRow = (key, data) => {
    const columns = [
      {
        field: 'accesskeyid',
        headerName: 'Access Key ID',
        renderCell: (param) =>
          isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
        width: 300
      },
      {
        field: 'lastused',
        headerName: 'Last Used',
        renderCell: (param) =>
          isLoading ? <ShimmerTableRow /> : <p className='font-medium'>{param.value}</p>,
        width: 400
      }
    ]

    const rows = (data.AccessKeys || []).map((accessKey, index) => {
      let lastUsedDate = 'No Details'
      let findingType = 'N/A'
      let resourceArn = accessKey.AnalyzerFindings?.AnalyzerFindingMap
        ? Object.values(accessKey.AnalyzerFindings.AnalyzerFindingMap)[0]?.ResourceArn
        : 'N/A'

      if (accessKey.AnalyzerFindings?.AnalyzerFindingMap) {
        const findingDetails = Object.values(accessKey.AnalyzerFindings.AnalyzerFindingMap)
          .map((finding) => {
            try {
              const detailsArray = JSON.parse(finding.Details || '[]')
              findingType = finding.Type
              return detailsArray.map((detail) => detail.Value.LastAccessed)
            } catch (error) {
              console.error('Error parsing Details:', error)
              return []
            }
          })
          .flat()
          .filter(Boolean)

        if (findingDetails.length) {
          const mostRecentDate = findingDetails.reduce((latest, date) =>
            new Date(date) > new Date(latest) ? date : latest
          )
          lastUsedDate = moment(mostRecentDate).format('MM-DD-YY HH:mm:ss')
        }
      }

      return {
        id: index,
        accesskeyid: accessKey.AccessKeyId || 'Unknown ID',
        lastused: lastUsedDate,
        type: findingType,
        resourceArn
      }
    })

    const firstAccessKeyId = data.AccessKeys?.[0]?.AccessKeyId

    return (
      <div className='bg-white p-6 shadow-md rounded-lg mb-4'>
        <div className='flex justify-between'>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Type :</span>
            <span className='text-[16px] text-[#727171] font-medium ml-3'>
              {rows[0]?.type || 'N/A'}
            </span>
          </div>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Resource Type :</span>
            <span className='text-[16px] text-[#727171] font-medium ml-3'>{kind}</span>
          </div>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Resource Arn :</span>
            <span className='text-[16px] text-[#727171] font-medium ml-3'>
              {getIamUserEmail(rows[0]?.resourceArn)}
            </span>
          </div>
          <div className='flex items-center w-1/4'>
            <span className='text-[16px] text-gray-500'>Recommended Action :</span>
            <Button
              style={{ width: '120px', marginLeft: '12px' }}
              variant='warning'
              disabled={loadingStates[firstAccessKeyId]}
              onClick={() => getRecommendation(key, data)}
            >
              {loadingStates[firstAccessKeyId] ? 'Fetching...' : 'Actions'}
            </Button>
          </div>
        </div>
        {firstAccessKeyId && (
          <div className='flex mt-5'>
            <div className='w-full'>
              <span
                className='flex text-[16px] cursor-pointer text-gray-500 items-center underline'
                onClick={() => toggleDetails(firstAccessKeyId)}
              >
                Details
                <span className='ml-2 mt-1'>
                  <FontAwesomeIcon
                    icon={expandedRows[firstAccessKeyId] ? faAngleUp : faAngleDown}
                  />
                </span>
              </span>

              {expandedRows[firstAccessKeyId] && (
                <div className='mt-4'>
                  <DataTable
                    autoHeight={true}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    columns={columns}
                    rows={rows}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderFindings = (data) => {
    const [key, value] = data

    if (
      key !== 'AccessKeys' &&
      key !== 'AnalyzerFindings' &&
      key !== 'MFAEnabled' &&
      key !== 'ConsoleAccess'
    )
      return null

    if (key === 'AccessKeys') {
      const hasNonEmptyAnalyzerFinding = value.AccessKeys.some(
        (accessKey) => Object.keys(accessKey.AnalyzerFindings.AnalyzerFindingMap).length > 0
      )

      if (hasNonEmptyAnalyzerFinding) {
        return generateAccessKeysRow(key, value)
      } else {
        console.log(
          'All AnalyzerFindingMap objects are empty; generateAccessKeysRow will not be called.'
        )
      }
    }

    if (key === 'AnalyzerFindings') {
      return generateFindingRows(value)
    }

    if ((key === 'MFAEnabled' && value === false) || (key === 'ConsoleAccess' && value === true)) {
      return generateMFAAndAccessKey(key, value)
    }
  }

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : remedyData ? (
        <>
          <Typography variant='h3' className='text-gray-800 font-semibold'>
            Findings
          </Typography>
          {Object.entries(remedyData).map((item) => renderFindings(item))}
          <FloatingDrawer onOverlayClick={() => setShowSidear(!showSidear)} showDrawer={showSidear}>
            <div className='mx-4 relative pb-20'>
              <Typography variant='h3'>Actions List</Typography>
              <div className='mt-3'>
                {selectedActionsList &&
                  selectedActionsList.map((e) => (
                    <IamRolesCard
                      className='mb-2'
                      style={{ height: '60px', minHeight: '53px important!', alignItems: 'center' }}
                      buttonType='none'
                      key={e.Action}
                      showOpenButton={false}
                      showMoreButton={false}
                      description={''}
                      onClickActionButton={() => {}}
                      title={e.Action}
                    />
                  ))}
              </div>
              <div className='fixed bottom-0 flex !bg-white w-[671px] justify-end gap-2 right-0 z-[501] border-t p-4'>
                <Button onClick={() => setShowSidear(false)} variant='gray'>
                  Cancel
                </Button>
              </div>
            </div>
          </FloatingDrawer>
          <FullScreenModal showModal={showRecommendedModal}>
            <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
              <div className='w-[626px] rounded-lg p-8 bg-white'>
                <div className='flex justify-between items-center mb-[0px]'>
                  <Typography variant='h3' className='text-[20px] m-0'>
                    Recomemended Actions
                  </Typography>
                </div>

                <div className='overflow-scroll h-[500px]'>
                  <JsonViewer recommendedResponse={recommondedResponse} />
                </div>
                <div className='flex mt-5 flex-row-reverse gap-5'>
                  {recommondedResponse.length > 0 && (
                    <Button variant='primary' size='md' onClick={() => handleRecitifacation()}>
                      Fix Now
                    </Button>
                  )}
                  <Button variant='gray' size='md' onClick={() => setShowRecommendedModal(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </FullScreenModal>

          <FullScreenModal showModal={showStepsRecommendation}>
            <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
              <div className='w-[626px] rounded-lg p-8 bg-white'>
                <div className='flex justify-between items-center mb-[30px]'>
                  <p className='text-[20px] m-0 font-[500]'>Recomemended Actions</p>
                </div>

                <div className='overflow-scroll'>
                  {dataType === 'MFAEnabled' ? (
                    <MFARecommendation />
                  ) : dataType === 'UnusedIAMUserAccessKey' ? (
                    <UnusedAccessKeyRecommendation />
                  ) : dataType === 'UnusedIAMUserPassword' ? (
                    <UnusedIamPasswordRecommendation />
                  ) : (
                    <ConsoleAccessRecommendation />
                  )}
                </div>
                <div className='flex mt-5 flex-row-reverse gap-5'>
                  <Button
                    variant='gray'
                    size='md'
                    onClick={() => setShowStepsRecommendation(false)}
                  >
                    Okay
                  </Button>
                </div>
              </div>
            </div>
          </FullScreenModal>
        </>
      ) : (
        'No Data found'
      )}
    </div>
  )
}

export { RemediationDetails }
