import { FormControl, Select, InputLabel, Drawer, Button, IconButton, Typography, makeStyles } from '@material-ui/core'

import { Close } from '@material-ui/icons'
import React from 'react'

import MultipleSelect from './MultiSelect'

const useStyle = makeStyles((theme) => ({
  root: {
    width: '404px',
    padding: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  },
  fullList: {
    width: 'auto'
  },
  title: {
    fontSize: '34px'
  },
  subTitle: {
    fontSize: theme.typography.body1.fontSize
  },
  clearButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px'

  },
  clearBtn: {
    fontSize: theme.typography.body1.fontSize

  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  select: {
    width: '100%'
  }
}))

const FilterDrawer = ({
  currentFilters,
  setFilters,
  filterCategories,
  open,
  title,
  toggleFilterDrawer
}) => {
  const classes = useStyle()

  return (
    <Drawer anchor='right' open={open} onClose={toggleFilterDrawer}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.title} variant='body1'>{title} Filters</Typography>
          <IconButton onClick={toggleFilterDrawer}>
            <Close />
          </IconButton>
        </div>
        <Typography className={classes.subTitle}>Select filter criteria to narrow down user results. Then select Apply Fiters.</Typography>
        <div className={classes.clearButton}>
          <Button color='primary' className={classes.clearBtn}><Close /> Clear All Filters</Button>
        </div>
        <div>
          {filterCategories && filterCategories.map(category => (
            <FormControl key={category.name} className={classes.formControl}>
              <InputLabel>{category.Label}</InputLabel>
              {/* <Select className={classes.select} /> */}
              <MultipleSelect />
            </FormControl>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

export default FilterDrawer
