import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, FullScreenModal } from 'V2Components'
import { useAppDetailsContext } from 'features/applications'
import { BundleCreateModal } from 'features/bundles'
import { createPacPolicy } from 'features/policy'
import { AccessRequestSubmission, createRef, useAccessCartProvider } from 'features/resources'
import { getObject, reduxApiClient } from 'infra'
import { clearCart } from 'infra/redux/reducers/slices/cartSlice'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import _ from 'lodash'
import { SnackBar } from 'procyon-ui'
import React, { useState } from 'react'

/**
 * @typedef { { showEntitySlectionModal:boolean, accessRequestSubmissionModal: boolean } } Views
 *
 * @param {{
 * views: Views,
 * setViews: ((views:Views) => void),
 * }} param0
 * @returns
 */
function CreateApplicationPolicyFlow({ views, setViews }) {
  const { app } = useAppDetailsContext()
  const { cartItems, clearCartItems } = useAccessCartProvider()
  const [selectedEntities, setSelectedEntities] = useState([])
  const [showCreateBundleModal, setShowCreateBundleModal] = useState(false)
  const [requestName, setRequestName] = useState('')
  const [comments, setComments] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dateTimeError, setDateTimeError] = useState('')
  const { slices } = useMultiSlice([
    'userList',
    'groupList',
    'policyList',
    'githubAccount',
    'githubResources',
    'salesForceAccountList',
    'salesForceUsersList',
    'salesForcePermissionList',
    'salesForceUserPermissionBinding'
  ])

  const getEntities = () => {
    if (app?.Spec?.AppType === 'github') {
      const currentAccount = _.find(slices.githubAccount, {
        ObjectMeta: { Name: app?.ObjectMeta?.Name }
      })
      let filterTeam = slices.githubResources?.filter(
        (obj) =>
          obj.Spec.Type === 'team' && obj.Spec.Account.RefID === currentAccount?.ObjectMeta?.ID
      )
      return [...slices.userList, ...filterTeam]
    } else if (app?.Spec?.AppType === 'salesforce') {
      const filterCurrentAccount = slices.salesForceAccountList?.find(
        (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
      )
      let filterCurrentAccountUsers = slices.salesForceUsersList?.filter(
        (user) => user?.SalesforceAccount?.RefID === filterCurrentAccount?.ObjectMeta?.ID
      )

      let filterCurrentPermissionSet = slices.salesForceUserPermissionBinding?.filter(
        (user) => user?.SalesforceAccount?.RefID === filterCurrentAccount?.ObjectMeta?.ID
      )

      // get permission license of cart items if permission type is profile
      const permission = _.find(slices.salesForcePermissionList, {
        ObjectMeta: { ID: cartItems[0].Resource.RefID }
      })
      if (permission?.Type === 'PROFILE') {
        const matcheduser = []
        filterCurrentAccountUsers.forEach((userPermission) => {
          const userPermissionRefId = userPermission.Permission.RefID
          const crmentity = slices.salesForcePermissionList.filter(
            (crmentity) => crmentity.ObjectMeta.ID === userPermissionRefId
          )

          if (
            permission?.License?.RefID === crmentity[0]?.License?.RefID &&
            permission?.ObjectMeta.ID !== crmentity[0]?.ObjectMeta.ID
          ) {
            matcheduser.push(userPermission)
          }
        })

        filterCurrentAccountUsers = matcheduser
      }

      // if permission type is set then filter the user
      if (permission?.Type === 'PERMISSION_SET') {
        const matcheduser = []

        filterCurrentAccountUsers.forEach((userPermission) => {
          const permissionSetLicense = []
          const filterPermissionSet = filterCurrentPermissionSet.filter((permissionSet) => {
            return permissionSet.User.RefID === userPermission?.ObjectMeta?.ID
          })

          filterPermissionSet.forEach((item) => {
            permissionSetLicense.push(item.Permission.RefID)
          })

          const userPermissionRefId = userPermission.Permission.RefID
          const crmentity = slices.salesForcePermissionList.filter(
            (crmentity) => crmentity.ObjectMeta.ID === userPermissionRefId
          )

          if (
            permission?.License?.RefID === crmentity[0]?.License?.RefID &&
            permission?.ObjectMeta?.ID !== crmentity[0]?.ObjectMeta.ID &&
            !permissionSetLicense.includes(permission?.ObjectMeta.ID)
          ) {
            matcheduser.push(userPermission)
          }

          if (
            permission?.License?.RefKind === '' &&
            !permissionSetLicense.includes(permission?.ObjectMeta.ID)
          ) {
            matcheduser.push(userPermission)
          }
        })
        filterCurrentAccountUsers = matcheduser
      }

      const filteredUsers = slices.userList.filter((user) => {
        const userEmail = user.Spec.EmailID
        return filterCurrentAccountUsers.some((emailObj) => emailObj.UserName === userEmail)
      })
      return [...filteredUsers]
    } else {
      return [...slices.userList, ...slices.groupList]
    }
  }

  const handleDismissClick = () => {
    clearCartItems()
    setSelectedEntities([])
    setRequestName('')
    setComments('')
    setStartDate(null)
    setEndDate(null)
    setDateTimeError('')
  }

  const handleViewsChange = (v) => {
    setViews({ ...views, ...v })
  }

  const handleEntityModalContinue = () => {
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: true })
  }

  const handleRsrcEditClick = () => {}

  const handleRequestSubmit = async (data) => {
    const policy = await handlePolicyCreate(data)
    pushToSlice(policy)
    setSelectedEntities([])
    clearCart()
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: false })
    clearCartItems()
  }

  const afterSubmit = () => {}

  const onSubmissionCancel = () => {
    handleViewsChange({ showEntitySlectionModal: false, accessRequestSubmissionModal: false })
  }

  return (
    <div>
      {views.accessRequestSubmissionModal && (
        <FullScreenModal showModal>
          <AccessRequestSubmission
            onAddMoreResourcesClick={() =>
              handleViewsChange({ accessRequestSubmissionModal: false })
            }
            onRsrcEditClick={handleRsrcEditClick}
            loadingMessage={`Creating Application's Policy`}
            showRequestForUserGroupsSummary={true}
            requestForUserGroupsRefs={selectedEntities.map(createRef)}
            mainTitle='Policy'
            onSubmit={handleRequestSubmit}
            afterSubmit={afterSubmit}
            onCancel={onSubmissionCancel}
            onManageUserGroupsClick={() =>
              handleViewsChange({
                showEntitySlectionModal: true,
                accessRequestSubmissionModal: false
              })
            }
            requestName={requestName}
            setRequestName={setRequestName}
            comments={comments}
            setComments={setComments}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateTimeError={dateTimeError}
            setDateTimeError={setDateTimeError}
          />
        </FullScreenModal>
      )}

      {views.showEntitySlectionModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title={`Select Users and ${
              app?.Spec?.AppType === 'github' ? 'Team' : 'Group'
            } in this Policy`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={getEntities()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              handleViewsChange({ showEntitySlectionModal: false })
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}

      {cartItems?.length > 0 && (
        <div className='fixed bottom-4 right-9'>
          <SnackBar
            showDropDownButton
            onClickContinueButton={() => {
              handleViewsChange({ showEntitySlectionModal: true })
            }}
            leftIcon={faRightToBracket}
            menuItems={[
              {
                label: 'Create Bundle',
                onClick() {
                  setShowCreateBundleModal(true)
                }
              },
              {
                label: 'Dismiss',
                onClick: handleDismissClick,
                value: 'first-item'
              }
            ]}
            textMessage={`${cartItems?.length} Applications on Queue`}
            variant='primary'
          />
        </div>
      )}

      {showCreateBundleModal && (
        <BundleCreateModal
          resoucesWithRoles={cartItems}
          onCancel={() => setShowCreateBundleModal(false)}
          onSuccess={() => setShowCreateBundleModal(false)}
          reqType={
            cartItems.some((e) => e.Resource.RefKind === 'CRMEntity') ? 'Salesforce' : 'Target'
          }
        />
      )}
    </div>
  )
}

async function handlePolicyCreate({
  requestForUserGroupsRefs,
  selectedRsrcs,
  requestName,
  comments,
  startDate,
  endDate
}) {
  const data = {
    ObjectMeta: {
      Kind: 'PacPolicy',
      Name: getFriendlyName(),
      RdOwners: {
        ObjectRef: requestForUserGroupsRefs
      }
    },
    Spec: {
      Description: comments,
      ActionMap: {}
    },
    IssuedTo: {
      ObjectRef: requestForUserGroupsRefs
    },
    NotBefore: startDate,
    NotAfter: endDate,
    GivenName: requestName,
    Type: selectedRsrcs[0]?.Resource?.RefKind === 'CRMEntity' ? 'Salesforce' : 'Target',
    Creator: ''
  }

  // check if selected rsrc refkind is crmentity
  if (selectedRsrcs[0]?.Resource?.RefKind === 'CRMEntity') {
    data.Spec.ActionMap.Salesforce = { PolicyRule: [] } // Initialize SALESFORCE

    selectedRsrcs.forEach(({ Resource }) => {
      data.Spec.ActionMap.Salesforce.PolicyRule.push({
        ObjectRef: Resource
      })
    })
  } else {
    data.Spec.ActionMap.HTTP = { PolicyRule: [] } // Initialize HTTP
    selectedRsrcs.forEach(({ Resource }) => {
      data.Spec.ActionMap.HTTP.PolicyRule.push({
        ObjectRef: Resource
      })
    })
  }

  const policy = await createPacPolicy(data)
  enqueueNotification('Policy successfully created!', 'info')
  return policy
}

export { CreateApplicationPolicyFlow }

function selectDispatch(arg0) {
  throw new Error('Function not implemented.')
}
