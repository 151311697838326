import { Box, Radio, Typography } from '@material-ui/core'
import React from 'react'

const SelectorItem = ({ value }) => {
  const radioId = `radio-${value}`
  return (
    <Box display='flex' alignItems='center'>
      <Radio color='primary' name='dataset' id={radioId} title={value} value={value} />
      <Typography component='label' variant='body1' htmlFor={radioId}>{value}</Typography>
    </Box>

  )
}

export default SelectorItem
