import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { reRequestApprovalRequest } from 'features/approvals'
import { Typography } from 'procyon-ui'
import React from 'react'

export const ReRequestApprovalRequestModal = ({ approvalReq, onCancel, onSuccess }) => {
  const givenName = approvalReq.GivenName

  const handleCancel = async () => {
    const req = await reRequestApprovalRequest(approvalReq)
    updateSliceData(req)
    enqueueNotification('Access Request requested successfully!', 'info')
    onSuccess()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Re-Request'
        actionButtonVariant='primary'
        loadingMessage={`Re-Requesting request ${givenName}`}
        showModal
        onActionClick={handleCancel}
        onCancel={onCancel}
        Content={
          <div className='mt-4'>
            <Typography className='mb-3 !block' variant='body-regular'>
              Once <span className='text-red-500 mx-[1px]'>re-requested</span> admin will be able
              approve.
            </Typography>
          </div>
        }
        alertMessage={
          <>
            Are you sure you want to re-request
            <strong className='mx-1' title={givenName}>
              {givenName}
            </strong>
            ?
          </>
        }
      />
    </div>
  )
}
