import { AdvancedSearch, SearchInput } from 'V2Components'
import { SelectDropDown, ToggleViewControl } from 'procyon-ui'
import React from 'react'

function Header({
  searchKey,
  setSearchKey,
  selectedTag,
  setSelectedTag,
  tags = [],
  suggestions,
  defaultSuggestions,
  TagComponent = null
}) {
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className=''>
          <SelectDropDown
            showHelpText={false}
            width='307px'
            onChange={(e) => setSelectedTag(e.target.value)}
            menuItems={[
              {
                label: 'All',
                value: ''
              },
              {
                label: <em>Without Tags</em>,
                value: '-'
              },
              ...tags
            ]}
            value={selectedTag}
          />
        </div>
        <div className='flex gap-6'>
          {TagComponent && TagComponent}
          <AdvancedSearch
            filterSaveKey={'application-search'}
            defaultSuggestions={defaultSuggestions}
            suggestions={suggestions}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
          />
          <ToggleViewControl
            ariaLabel='view control'
            options={['grid', 'table']}
            value={['grid']}
          />
        </div>
      </div>
    </div>
  )
}

export { Header }
