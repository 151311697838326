import { makeStyles } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: (props) => props.noMarginTop ? 'center' : 'flex-start',
    justifyContent: 'flex-start',
    height: '40px',
    width: '100%',
    paddingLeft: '5px',
    cursor: 'pointer',
    fontSize: props => theme.typography[props.fontSize].fontSize
  }
}))
const GeneralItem = ({ data, noMarginTop, fontSize = 'body1', Component = null }) => {
  const classes = useStyle({ noMarginTop, fontSize })
  return (
    <div title={data} className={classes.root}>
      {data}
      {Component && Component}
    </div>
  )
}

export default GeneralItem
