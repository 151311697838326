import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import React from 'react'
import { IAMAction } from './components/IAMAction'
import { Target } from './components/Target'
import { getPolicyType } from 'features/policy/utils'
import { useEditPolicyModulesContext } from '../../EditPolicyModulesProvider'

const ConfigureResources = ({
  iamActionsRsrcMap,
  setIamActionsRsrcMap,
  attributesMap,
  setAttributesMap
}) => {
  const { policy } = useEditPolicyModulesContext()

  useHTMLBodyScrollbar(true)
  const policyType = getPolicyType(policy)

  return (
    <div>
      {policyType === 'IAMAction' && (
        <IAMAction
          iamActionsRsrcMap={iamActionsRsrcMap}
          setIamActionsRsrcMap={setIamActionsRsrcMap}
        />
      )}
      {policyType === 'Target' && <Target />}
    </div>
  )
}

export { ConfigureResources }
