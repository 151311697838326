import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { getApplicationSignInURL } from 'features/applications'
import { APPROVED_STATUS } from 'features/approvals'
import { getRsrcAccessLink } from 'features/clouds'
import { isPolicyIssuedToUser } from 'features/policy'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { getAppRoleSignInURL, getTargetsPolicyRules, useRDPAccess } from 'features/targets'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { useEffect, useMemo } from 'react'

const useSlices = createDataSelectorHook(['bundles', 'policyList', 'appRolesList'])
//Manual fetch pending reqs
const useApprovals = createDataSelectorHook(['approvalReqList'], { disableReduxFetch: true })

export const useBundlesUserAccess = (type = '') => {
  const { slices } = useSlices()
  const {
    slices: { approvalReqList }
  } = useApprovals()
  const { user } = useUser()
  const { getObjectRef } = useObjectRef([
    'appRolesList',
    'awsResources',
    'gcpResources',
    'azureResources',
    'appRolesList',
    'serverList',
    'databases',
    'rdpServers',
    'kafkas',
    'kubeClusters',
    'kubeNamespaces'
  ])

  const { getUserRDPAccessLinks } = useRDPAccess()

  const getBundlePolicy = (bundleRef) => {
    const policy = slices.policyList.find((pac) => {
      if (!isPolicyIssuedToUser(pac, user)) return
      return createRsrcKey(bundleRef) === createRsrcKey(pac.BundleRef)
    })
    return policy
  }

  const getBundleAccessState = (bundle) => {
    //Bundle granted policy check
    const bundleSignInMap = getBundleSignInData(bundle)
    const isGranted = Object.keys(bundleSignInMap).length > 0
    if (isGranted) return 'granted'
    // Bundle approval req check for pending - in progress
    const accReq = approvalReqList.find((req) => {
      if (createRsrcKey(bundle) !== createRsrcKey(req.BundleRef)) return
      if (req && req.Status === APPROVED_STATUS.Pending) return true
    })

    if (accReq) return 'inProgress'

    // Ungranted bundles
    return 'ungranted'
  }

  const getBundleAccessMap = (type) => {
    const map = {
      granted: [],
      ungranted: [],
      inProgress: []
    }

    slices.bundles.forEach((bundle) => {
      if (type && bundle.Type !== type) return
      //Bundle granted policy check
      const accessState = getBundleAccessState(bundle)
      map[accessState].push(bundle)
    })

    return map
  }

  /**
   * Get the access map of the bundle
   * @param {*} bundleRef 
   * @returns An object map whose key is the resource for the bundle attached policy and value is :
   * {
        url,
        policyRef: createRef(policy),
        resourceRef: createRef(createdAppRole),
        profileName: createdAppRole.ObjectMeta.Name // not availabel for azure and gcp rsrcs
      }
   */
  const getBundleSignInData = (bundleRef) => {
    const policy = getBundlePolicy(bundleRef)
    const urlsMap = {}
    if (!policy) return {}

    if (policy.Type === 'Target') {
      const { AssumeRole, DBAccess, KafkaAccess, RDPAccess, SSH, KubeAccess } =
        getTargetsPolicyRules(policy)
      const HTTP = policy.Spec.ActionMap.HTTP?.PolicyRule || []
      const temp = { AssumeRole, DBAccess, KafkaAccess, RDPAccess, SSH, HTTP }

      for (const actionKey in temp) {
        const rules = temp[actionKey]

        rules.forEach((rule) => {
          const rsrc = getObjectRef(rule.ObjectRef)
          const key = createRsrcKey(rule.ObjectRef)
          if (!rsrc) return
          const rsrcKind = rsrc.ObjectMeta.Kind
          let signInURL = ''
          const signInItems = []

          if (rsrcKind === 'AppRole') signInURL = getAppRoleSignInURL(rsrc)
          else if (rsrcKind === 'Application') signInURL = getApplicationSignInURL(rsrc)

          if (rsrcKind === 'RDPServer') {
            const userPolicy = getUserRDPAccessLinks(user, rsrc)
            for (const userName in userPolicy) {
              const signInURL = userPolicy[userName]
              signInItems.push({
                name: userName,
                value: signInURL
              })
            }
          }

          urlsMap[key] = urlsMap[key] || []
          urlsMap[key].push({
            url: signInURL,
            policyRef: createRef(policy),
            policyName: getResourceName(policy),
            resourceRef: createRef(rsrc),
            resourceName: getResourceName(rsrc),
            signInItems,
            profileName: rsrcKind === 'AppRole' ? rsrc.ObjectMeta.Name : ''
          })
        })
      }

      //KubeAccess rules
      KubeAccess.forEach((rule) => {
        const rsrc = getObjectRef(rule.ObjectRef)
        const key = createRsrcKey(rule.ObjectRef)

        if (!rsrc) return
        const kubeCluster =
          rsrc.ObjectMeta.Kind === 'KubeCluster' ? rsrc : getObjectRef(rsrc.Spec.Cluster)
        if (!kubeCluster) return
        urlsMap[key] = urlsMap[key] || []
        urlsMap[key].push({
          url: 'PLACEHOLDER',
          policyRef: createRef(policy),
          policyName: getResourceName(policy),
          resourceName: getResourceName(rsrc),
          signInItems: [],
          resourceRef: createRef(rsrc),
          profileName: '',
          k8DefaultCmd: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon`,
          k8PolicyCmd: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon:${policy.ObjectMeta.Name}`
        })
      })

      return urlsMap
    }
    // For IAM Action type
    policy.Spec.ActionMap.AssumeRole?.PolicyRule.forEach((e) => {
      /**
       * Services are the AwsResources/GcpResources included in the policy
       * */
      const servicesRef = e.Services.ObjectRef
      // Target can be either AppRole for AwsResources or IamAction for GcpResources/Azure Resources
      const targetRef = e.ObjectRef
      if (targetRef.RefKind === 'IamAction') {
        /**
         * This policy is for GcpResource/Azure Resource
         * */
        const serviceRsrcs = getObjectRef(servicesRef)
        serviceRsrcs.map((r) => {
          const key = createRsrcKey(r)
          let url = getRsrcAccessLink(r)
          // Don't add access link with same policy name
          urlsMap[key] = urlsMap[key] || []
          urlsMap[key].push({
            url,
            policyRef: createRef(policy),
            resourceRef: createRef(r),
            signInItems: []
          })
        })
      } else {
        /**
         * This policy is for AwsResource, because targetRef.RefKind is AppRole
         */
        const createdAppRole = getObjectRef(targetRef)
        if (!createdAppRole) return
        servicesRef.forEach((ref) => {
          // Services are the actual aws resources
          const key = createRsrcKey(ref)
          const url = getAppRoleSignInURL(createdAppRole)
          urlsMap[key] = urlsMap[key] || []
          urlsMap[key].push({
            url,
            policyRef: createRef(policy),
            resourceRef: createRef(createdAppRole),
            profileName: createdAppRole.ObjectMeta.Name,
            signInItems: []
          })
        })
      }
    })
    return urlsMap
  }

  const bundleAccessMap = getBundleAccessMap(type)

  const [grantCount, inProgressCount, ungrantedCount] = [
    bundleAccessMap.granted.length,
    bundleAccessMap.inProgress.length,
    bundleAccessMap.ungranted.length
  ]
  const countsMap = useMemo(() => {
    return { grantCount, inProgressCount, ungrantedCount }
  }, [grantCount, inProgressCount, ungrantedCount])

  useEffect(() => {
    const fn = async () => {
      await reduxApiClient('approvalreqs').getByDataParams({ Status: APPROVED_STATUS.Pending })
    }
    fn()
  }, [])

  return { bundleAccessMap, countsMap, getBundleAccessMap, getBundleSignInData, getBundlePolicy, getBundleAccessState }
}
