import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getAutoApprovePolicyResourcesRefs } from 'features/approvals'
import { getResourceName } from 'features/resources'
import _ from 'lodash'
import { ResponsiveTable } from 'procyon-ui'
import React, { useMemo, useState } from 'react'
import { ApprovalRequestManager } from '../../../../../components/ApprovalRequestManager'
import { ResourcesListModal } from '../../ResourcesListModal'
import { ViewColumnsMap, autoApproveTableColumns } from '../table.utils'

import useAppView from 'Core/Hooks/useAppView'

const _slices = [
  'approvalReqList',
  'userList',
  'groupList',
  'awsResources',
  'azureResources',
  'gcpResources',
  'serverList',
  'appRolesList',
  'paaPolicyList',
  'kubeNamespaces',
  'serviceAccounts'
]

function AutoApproveTab({ searchKey = '' }) {
  // @ts-ignore
  const { slices, getObjectRef } = useMultiSlice(_slices)
  const [selectedPAAPolicy, setSelectedPAAPolicy] = useState(null)
  const [selectedApprovalRequest, setSelectedApprovalRequest] = useState(null)
  const [showRsrcsListModal, setShowRsrcsListModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { appView } = useAppView()

  const getColumns = () => {
    const visibleColumns = ViewColumnsMap[appView].autoApprove
    //@ts-ignore
    return autoApproveTableColumns().filter((e) => visibleColumns.includes(e.dataIndex))
  }

  const rows = useMemo(() => {
    const list = []
    slices.paaPolicyList.forEach((r, i) => {
      const requestedFor = getObjectRef(r.Spec.IssuedTo)
      const resourcesRefs = getAutoApprovePolicyResourcesRefs(r)
      const approvalRequest = _.find(slices.approvalReqList, {
        ObjectMeta: { Name: r.ObjectMeta.Name }
      })
      // Search key pattern will be applied in this data of the table
      const data = {
        id: i,
        name: r.GivenName || r.ObjectMeta.Name,
        requestedFor: requestedFor
          ? [
              {
                RefKind: requestedFor?.ObjectMeta.Kind || '',
                name: getResourceName(requestedFor)
              }
            ]
          : [],
        resources: {
          count: resourcesRefs.length,
          onClick() {
            setSelectedApprovalRequest(approvalRequest)
            setShowRsrcsListModal(true)
          }
        },
        autoApproveUntil: r?.NotAfter,
        details: `/admin/access-requests/${r.ObjectMeta.Name}`
      }

      // Return if search pattern doesn't macth
      if (searchKey && !JSON.stringify(Object.values(data)).toLowerCase().includes(searchKey))
        return

      list.push({
        ...data,
        moreOptions: {
          async onDeleteClick() {
            setSelectedPAAPolicy(r)
            setShowDeleteModal(true)
          }
        }
      })
    })
    return list
  }, [slices, searchKey])

  return (
    <div>
      <ResponsiveTable data={rows} columns={getColumns()} />

      {selectedApprovalRequest && (
        <ResourcesListModal
          approvalReq={selectedApprovalRequest}
          onClose={() => setShowRsrcsListModal(false)}
          showModal={showRsrcsListModal}
        />
      )}

      <ApprovalRequestManager
        paaPolicy={selectedPAAPolicy}
        onModalsViewChange={({ showDeleteAutoApprove }) =>
          setShowDeleteModal(showDeleteAutoApprove)
        }
        views={{
          showDeleteAutoApprove: showDeleteModal,
          showClose: false,
          showDelete: false,
          showApprove: false,
          showReject: false
        }}
      />
    </div>
  )
}

export { AutoApproveTab }
