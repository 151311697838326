import { Box, Fab, makeStyles, Tooltip } from '@material-ui/core'
import { Apps, List, LocationOn, Settings } from '@material-ui/icons'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  viewMode: {
    marginRight: '10px',
    height: '40px',
    width: '40px'
  },
  setting: {
    height: '48px',
    width: '48px',
    color: theme.palette.primary.light
  },
  fab: {
    color: theme.palette.primary.light
  }
}))
const PageHeaderActions = ({ viewModes, viewMode, setViewMode, openGridSettings }) => {
  const classes = useStyles()
  return (
    <>
      {viewMode && (
        <>
          {viewModes.includes('List') && (
            <Tooltip title='List View'>
              <Box className={classes.viewMode}>
                <Fab
                  onClick={() => setViewMode('List')}
                  classes={{ colorInherit: classes.fab }}
                  color={viewMode === 'List' ? 'primary' : 'inherit'}
                  size='small'
                >
                  <List />
                </Fab>
              </Box>
            </Tooltip>
          )}
          {viewModes.includes('Tile') && (
            <Tooltip title='Tile View'>
              <Box className={classes.viewMode}>
                <Fab
                  onClick={() => setViewMode('Tile')}
                  classes={{ colorInherit: classes.fab }}
                  color={viewMode === 'Tile' ? 'primary' : 'inherit'}
                  size='small'
                >
                  <Apps />
                </Fab>
              </Box>
            </Tooltip>
          )}
          {viewModes.includes('Map') && (
            <Tooltip title='Map View'>
              <Box className={classes.viewMode}>
                <Fab
                  onClick={() => setViewMode('Map')}
                  classes={{ colorInherit: classes.fab }}
                  color={viewMode === 'Map' ? 'primary' : 'inherit'}
                  size='small'
                >
                  <LocationOn />
                </Fab>
              </Box>
            </Tooltip>
          )}
          {/* {typeof openGridSettings === 'function' && (!viewMode || viewMode === 'List') &&
              <Box onClick={openGridSettings} pr={1}>
                <Fab className={classes.setting} size='small'>
                  <Settings />
                </Fab>
              </Box>} */}
        </>
      )}
    </>
  )
}

export default PageHeaderActions
