import { useResourceAccess } from 'Core/Hooks/useResourceAccess'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { FullScreenModal } from 'V2Components'
import {
  getApprovalRequestIAMActions,
  getApprovalRequestResourcesRefs,
  updateApprovalRequest
} from 'features/approvals'
import { AccessCartProvider, AccessRequestSubmissionV2, getResourceName } from 'features/resources'
import React, { useRef, useState } from 'react'

function EditApprovalRequest({ approvalReq, onCancel, onSuccess }) {
  const [dateTimeError, setDateTimeError] = useState('')
  const isSliceUpdated = useRef(false)

  const { updateRsrcMeta, updateResources, updateEntities, updateSpec } = useResourceAccess()

  const createEntitiesResourceMetaObj = (approvalReq) => {
    const obj = {}
    const iamActionsObj = getApprovalRequestIAMActions(approvalReq)

    for (const rsrcKey in iamActionsObj) {
      obj[rsrcKey] = { Roles: iamActionsObj[rsrcKey] }
    }

    return obj
  }

  const updateResourcesAccessSlice = () => {
    updateSpec({
      startDate: approvalReq.Spec.NotBefore,
      name: approvalReq.GivenName,
      endDate: approvalReq.Spec.NotAfter,
      comments: approvalReq.Spec.Justification
    })

    const resources = getApprovalRequestResourcesRefs(approvalReq)
    const metaObj = createEntitiesResourceMetaObj(approvalReq)

    updateResources(resources)
    updateEntities([])
    updateRsrcMeta(metaObj)
  }

  if (!isSliceUpdated.current) {
    updateResourcesAccessSlice()
    isSliceUpdated.current = true
  }

  const handleSubmit = async (data) => {
    const req = await handleApprovalRequestEdit(approvalReq, { spec: data.spec })
    updateSliceData(req)
    onSuccess()
  }

  return (
    <FullScreenModal showModal>
      <div className='h-[100%] bg-white'>
        <AccessRequestSubmissionV2
          mode='EDIT'
          showAddMoreResourcesBtn={false}
          onAddMoreResourcesClick={() => {}}
          onRsrcEditClick={() => {}}
          loadingMessage={`Updating Access Request`}
          showRequestForUserGroupsSummary={false}
          mainTitle={'Request'}
          headerName={getResourceName(approvalReq)}
          onSubmit={handleSubmit}
          afterSubmit={() => {}}
          onCancel={onCancel}
          onManageUserGroupsClick={() => {}}
          dateTimeError={dateTimeError}
          setDateTimeError={setDateTimeError}
        />
      </div>
    </FullScreenModal>
  )
}

const handleApprovalRequestEdit = async (req, { spec }) => {
  const newReq = structuredClone(req)

  newReq.Spec.NotBefore = spec.startDate
  newReq.Spec.NotAfter = spec.endDate

  return await updateApprovalRequest(newReq)
}

const Wrapped = (props) => (
  //DOESNT MATTER remove this once AccessRequestSubmission IS Refactored to not use the removeFromCart fn
  <AccessCartProvider cartKey='userTargets'>
    <EditApprovalRequest {...props} />
  </AccessCartProvider>
)

export { Wrapped as EditApprovalRequest }
