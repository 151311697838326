import useAppView from 'Core/Hooks/useAppView'
import { cn } from 'Utils/Helpers'
import { ManageOwnersView, useResourceDetails } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'

function OverviewTab({ ResourceInfoComponent }) {
  const { appView } = useAppView()
  const { rsrc } = useResourceDetails()

  return (
    <div className='flex flex-row gap-4 pr-2'>
      <div
        className={cn({ 'border-r ': appView === 'admin' }, 'w-2/4 grid-col-4 border-[#D8DDE4]')}
      >
        <Typography className='text-[#222945]' variant='h4-regular'>
          Resource Information
        </Typography>
        <div className='mt-12 pr-8'>{ResourceInfoComponent}</div>
      </div>
      {appView === 'admin' && (
        <div className='w-1/2'>
          <ManageOwnersView rsrc={rsrc} />
        </div>
      )}
    </div>
  )
}

export { OverviewTab }
