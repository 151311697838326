import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useAppDetailsContext } from 'features/applications'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import { IconButton, Label, LoadingModal, TabGroup, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
const useSlices = createDataSelectorHook([
  'salesForceUsersList',
  'salesForcePermissionList',
  'salesForceAccountList',
  'salesForceUserPermissionBinding'
])

const SFUsers = () => {
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [filterPermissionList, setFilteredPermissionList] = useState([])
  const [filterSetPermissionList, setFilteredSetPermissionList] = useState([])
  const [profilePermission, setProfilePermission] = useState([])
  const [profileExpiry, setProfileExpiry] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [userClicked, setUserClicked] = useState(false)
  const [currentSelectedUser, setCurrentSelectedUser] = useState('')

  useEffect(() => {
    reduxApiClient('salesforce-users').getAll()
    reduxApiClient('crm-entities').getAll()
    reduxApiClient('salesforce-user-perm-binding').getAll()
    setIsLoading(true)
    getFilteredUsersList()
  }, [slices, userClicked])

  const getFilteredUsersList = () => {
    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
    )
    const filterCurrentAccountUsers = slices.salesForceUsersList?.filter(
      (user) => user?.SalesforceAccount?.RefID === filterCurrentAccount?.ObjectMeta?.ID
    )

    setUserList(filterCurrentAccountUsers)
    setIsLoading(false)
  }

  const getExistingPermission = (data) => {
    const { Permission, Assignee } = data
    let filterAssigneePermission
    let [_, time] = ''

    const filterUserPermission = slices.salesForcePermissionList.find(
      (permission) => permission?.ObjectMeta?.ID === Permission?.RefID
    )

    if (Assignee?.ExpirationDate !== '') {
      filterAssigneePermission = slices.salesForcePermissionList.find(
        (permission) => permission?.ObjectMeta?.ID === Assignee?.Permission?.RefID
      )

      const utcTime = new Date(Assignee?.ExpirationDate)
      const convertedTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
      ;[_, time] = convertedTime.split(', ')
    }

    return (
      <div className='gird gap-2'>
        <Label
          style={{ marginBottom: '5px' }}
          variant='grayBlue'
          text={`Default : ${filterUserPermission?.EntityName}`}
        />
      </div>
    )
  }

  const renderUserListAndPermissionLists = async (data) => {
    setUserClicked(true)
    await getExistingSetAndGroupPermissions(data)
  }

  const getExistingSetAndGroupPermissions = async (data) => {
    const { ObjectMeta } = data
    const groupPermissionList = []
    const setPermissionList = []
    const profilePermissionList = []

    // current account
    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
    )

    // get profile permission
    if (data?.Assignee?.ExpirationDate !== '') {
      const permissionList = slices.salesForcePermissionList.find(
        (permission) =>
          permission?.ObjectMeta?.ID === data?.Assignee?.Permission?.RefID &&
          permission.SalesforceAccount.RefID === filterCurrentAccount.ObjectMeta.ID
      )
      profilePermissionList.push(permissionList)
      setProfileExpiry(data?.Assignee?.ExpirationDate)
    }

    const filterGroupPermission = slices.salesForceUserPermissionBinding.filter(
      (set) =>
        set.Type === 'PERMISSION_SET_GROUP' &&
        set.User.RefID === ObjectMeta.ID &&
        set.SalesforceAccount.RefID === filterCurrentAccount.ObjectMeta.ID
    )

    const filterSetPermission = slices.salesForceUserPermissionBinding.filter(
      (set) =>
        set.Type === 'PERMISSION_SET' &&
        set.User.RefID === ObjectMeta.ID &&
        set.SalesforceAccount.RefID === filterCurrentAccount.ObjectMeta.ID
    )

    filterGroupPermission.forEach((PerGroupList) => {
      const permissionRefID = PerGroupList.Permission.RefID
      const permissionObject = slices.salesForcePermissionList.find(
        (permission) => permission.ObjectMeta.ID === permissionRefID
      )
      groupPermissionList.push(permissionObject)
    })

    filterSetPermission.forEach((PerGroupList) => {
      const permissionRefID = PerGroupList.Permission.RefID
      const permissionObject = slices.salesForcePermissionList.find(
        (permission) => permission.ObjectMeta.ID === permissionRefID
      )
      setPermissionList.push(permissionObject)
    })
    setCurrentSelectedUser(data.UserName)
    setFilteredSetPermissionList(setPermissionList)
    setFilteredPermissionList(groupPermissionList)
    setProfilePermission(profilePermissionList)
  }

  const getProfileExpiry = () => {
    const utcTime = new Date(profileExpiry)
    const convertedTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
    const [date, time] = convertedTime.split(', ')

    if (time) {
      return <Label text={`Expire : ${date} : ${time}`} variant='danger' />
    }
    return ''
  }

  const getExpiry = (data) => {
    const getReversePermissionBind = slices.salesForceUserPermissionBinding.find(
      (item) => item.Permission.RefID === data.ObjectMeta.ID
    )

    const utcTime = new Date(getReversePermissionBind?.ExpirationDate)
    const convertedTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
    const [date, time] = convertedTime.split(', ')

    if (time) {
      return <Label text={`Expire : ${date} : ${time}`} variant='danger' />
    }
    return ''
  }

  return (
    <>
      <div className='flex'>
        <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
          {userList &&
            userList.map((user) => (
              <div className='my-5'>
                <div
                  className='flex justify-between items-center border p-2 cursor-pointer'
                  onClick={() => renderUserListAndPermissionLists(user)}
                >
                  <div className='flex gap-2'>
                    <TargetIcon type={'USER'} />
                    <span className='' style={{ width: '250px', wordBreak: 'break-word' }}>
                      <Typography variant='body-regular'>{user.UserName}</Typography>
                    </span>
                  </div>

                  <div className='flex gap-4'>{getExistingPermission(user)}</div>
                </div>
              </div>
            ))}
        </div>
        <div className='w-1/2 px-8'>
          <Typography style={{ textAlign: 'left', marginBottom: '40px' }} variant='h3'>
            {`Existing Group and set Permissions `}
            {currentSelectedUser !== '' && `for - ${currentSelectedUser}`}
          </Typography>

          <TabGroup
            tabs={[
              {
                label: 'Profile',
                tabContent: (
                  <>
                    {profilePermission.map((permission) => (
                      <div className='flex gap-1 my-4 justify-between border-b border[#D8DDE4] pb-2'>
                        <Typography
                          variant='body-semiBold'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {permission?.EntityName}
                        </Typography>
                        {getProfileExpiry()}
                      </div>
                    ))}
                  </>
                )
              },
              {
                label: 'Group',
                tabContent: (
                  <>
                    {filterPermissionList.map((permission) => (
                      <div className='flex gap-1 my-4 justify-between border-b border[#D8DDE4] pb-2'>
                        <Typography
                          variant='body-semiBold'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {permission?.EntityName}
                        </Typography>
                        {getExpiry(permission)}
                      </div>
                    ))}
                  </>
                )
              },
              {
                label: 'Set',
                tabContent: (
                  <>
                    {filterSetPermissionList.map((permission) => (
                      <div className='flex gap-1 my-4 justify-between border-b border[#D8DDE4] pb-2'>
                        <Typography
                          variant='body-semiBold'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {permission?.EntityName}
                        </Typography>
                        {getExpiry(permission)}
                      </div>
                    ))}
                  </>
                )
              }
            ]}
          />
        </div>
      </div>

      {isLoading && (
        <div className='h-[180px] pt-4 w-[100%] flex justify-center items-center'>
          <LoadingModal
            caption='Please wait a few seconds.'
            message='Loading SalesForce Users...'
          />
        </div>
      )}
    </>
  )
}

export { SFUsers }
