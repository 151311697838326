import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { getResourceImage } from 'Utils/Helpers'
const useStyle = makeStyles((theme) => ({

  resourceIdentifier: {
    width: theme.spacing(1.5),
    fontSize: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.125)
  }
}))
const ResourceIdentifier = ({ name, type, backURL }) => {
  const classes = useStyle()
  const getResourceIcon = useCallback(() => {
    const image = getResourceImage(type, classes.resourceIdentifier)
    if (typeof image === 'string') {
      return <img className={classes.resourceIdentifier} src={image} />
    } else {
      return image
    }
  }, [type])
  return (
    <Box display='flex' alignItems='center' mt={0.5}>
      {getResourceIcon()}
      <Typography variant='h6'>{name}</Typography>
    </Box>
  )
}

export default ResourceIdentifier
