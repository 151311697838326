import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import React from 'react'

const DeleteADDCModal = ({ addc, onCancel, onSuccess }) => {
  const rsrcName = getResourceName(addc)

  const handleDelete = async () => {
    try {
      await reduxApiClient('addomaincontrollers').delete(addc)
      onSuccess?.()
      enqueueNotification('AD Domain Controller deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete AD Domain Controller: ${rsrcName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={addc} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteADDCModal }
