import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from 'Utils/Helpers'
import { Typography } from 'procyon-ui'
import React from 'react'

const IconInfoRow = ({ faIcon = null, isActive, title, subtitle, onClick, className = '', Icon = null }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'border-[#ADBEDE] border rounded p-3 px-4 flex items-center gap-4 cursor-pointer',
        {
          'bg-[#F6FBFF]': isActive
        },
        className
      )}
    >
      {faIcon && <FontAwesomeIcon size='lg' color='#545B71' icon={faIcon} />}
      {Icon && Icon}
      <div className='flex justify-between flex-col'>
        <Typography variant='h4-regular'>{title}</Typography>
        <Typography className='mt-2' variant='body-regular'>
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

export { IconInfoRow }
