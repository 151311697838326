// @ts-nocheck
import { Box, CircularProgress, Typography } from '@material-ui/core'
import ActionModal from 'Components/ActionModal/ActionModal'
import { CustomTypography } from 'Components/CustomTypography'
import QrCodeView from 'Components/QrCode'
import { ApiProvider } from 'Core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { constructMagicLinkPayload, getDisplayName } from 'Utils/PureHelperFuctions'
import Footer from './Footer'
import LinkBox from './LinkBox'

const MagicLinkModal = ({ selectedUser, open, handleClose }) => {
  const [link, setLink] = useState('')
  const [loading, setLoading] = useState(false)

  const createMagicLink = useCallback(async () => {
    try {
      setLoading(true)
      if (selectedUser && selectedUser.ObjectMeta) {
        const payload = constructMagicLinkPayload(selectedUser)
        const response = await new ApiProvider('onboardtokens').setInstance(payload).post()
        console.log('response in createMagicLink', response)
        if (response.data && response.data.OnboardTokenLink) {
          setLink(response.data.OnboardTokenLink)
          enqueueNotification('Magic link generated', 'info')
        } else {
          enqueueNotification('Operation failed', 'error')
        }
      }
    } catch (error) {
      console.error('error in createMagicLink', error)
      enqueueNotification('Operation failed', 'error')
    } finally {
      setLoading(false)
    }
  }, [selectedUser])

  const cleanUp = useCallback(() => {
    setLink('')
    setLoading(false)
  }, [])

  useEffect(() => {
    if (open) {
      createMagicLink()
    }
    return cleanUp
  }, [open])
  const userName = useMemo(() => getDisplayName(selectedUser, true), [selectedUser])

  return (
    <ActionModal
      headerBorder
      height='auto'
      width='40.5rem'
      Icon={false}
      showCloseButton
      headerPadding={{ pb: 0.1, pt: 0.2, pl: 1.5 }}
      Title='Onboarding link'
      bodyPadding='1.5rem 1.5rem 1.5rem 1.5rem  '
      isOpen={open}
      closeLabel='CLOSE'
      handleClose={handleClose}
    >
      {loading && <Box display='flex' justifyContent='center'><CircularProgress size={24} /></Box>}
      {!loading && (
        <Box display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
          <QrCodeView text={link} />
          <Typography variant='caption' color='primary'>
            Scan to get onboarded
          </Typography>
        </Box>
      )}
      {!loading &&
        <LinkBox>
          <CustomTypography.Body height='unset'>{link}</CustomTypography.Body>
        </LinkBox>}
      <Footer link={link} userName={userName} />
    </ActionModal>
  )
}

export default MagicLinkModal
