import { Box, makeStyles } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'
const useStyle = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.disabled,
    width: theme.spacing(1.25),
    fontSize: theme.spacing(1.25)
  },
  error: {
    visibility: props => props.error ? 'visible' : 'hidden',
    marginLeft: theme.spacing(0.75),
    fontSize: theme.spacing(1.125),
    transform: 'translateY(3px)'
  },
  root: {
    borderBottomWidth: '1px',
    borderColor: theme.palette.divider,
    borderBottomStyle: 'solid'
  }
}))
const ListItem = ({ Icon, total, current, title, error, index }) => {
  const classes = useStyle({ error })
  return (
    <Box pb={0.375} pt={0.625} className={classes.root} display='flex' justifyContent='space-between'>
      {/* Left Segment */}
      <Box display='flex' alignItems='center'>
        {Icon && <Icon className={classes.icon} />}
        <Box ml={1}>
          <CustomTypo variant='body2' color='primary'>{title}</CustomTypo>
        </Box>
      </Box>
      {/* Right Segment */}
      <Box display='flex' alignItems='baseLine'>
        {/* Ratio */}
        <Box display='flex' alignItems='baseLine'>
          <CustomTypo variant='h5'>{current}</CustomTypo>
          <CustomTypo variant='caption' emphasis='medium'>&nbsp; /{total}</CustomTypo>
          <Warning className={classes.error} color='error' />
        </Box>
        {/* Error Mark */}
        {/* </Box> */}
      </Box>
    </Box>
  )
}

export default ListItem
