import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey } from 'features/resources'
import { isChildAccount, isOrgAccount } from '../utils'

export const useCloudAccounts = () => {
  const { slices, getObjectRef } = useMultiSlice(['accountList'])

  const getParentAccount = (account) => {
    const parentRef = account.Spec.ParentAccount
    const obj = getObjectRef(parentRef)
    return obj || null
  }

  const getChildAccounts = (account, list) => {
    const key = createRsrcKey(account)
    const l = list ? list : slices.accountList
    return l.filter((a) => createRsrcKey(a.Spec.ParentAccount) === key)
  }

  const uiGetAccountTargetIconType = (account) => {
    if (account.Spec.Type === 'AWS') {
      let type = ''
      type = 'AWS_APPLICATION'
      if (isOrgAccount(account)) type = 'AWS_ORG'
      else if (isChildAccount(account)) type = 'AWS_CHILD'
      return type
    }

    if (account.Spec.Type === 'GCP') return 'GOOGLE_APPLICATION'

    return 'AZURE_APPLICATION'
  }

  return { getParentAccount, uiGetAccountTargetIconType, getChildAccounts }
}
