import lodash from 'lodash'
import { useCallback, useRef } from 'react'
/**
 * The hook is used to create a delayed callback for handling user inputs better and performant.
 * 
 * This function takes a `value` which is desired to change, `delay` is the delay to wait for change before calling `callback`.
 * The `updateSeed` is used to update the `seed` value which in turn triggers the delayed call to `callback`. If the `seed` gets changed between this delay,
 * the `callback`'s call gets cancelled and new timeout is set and this continues until there's no change between `delay.`
 * @param {{ delay: number, callback:((value:any) => void) }} props
 */
const useTimeoutCall = ({ delay = 500, callback }) => {
  const seed = useRef(null)
  const timeoutId = useRef(null)

  const updateSeed = useCallback((value) => {
    if (!lodash.isEqual(seed.current, value)) {
      seed.current = lodash.cloneDeep(value)
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      timeoutId.current = setTimeout(() => {
        typeof callback === 'function' && callback(seed.current)
      }, delay)
    }
  }, [])

  return { updateSeed }
}

export default useTimeoutCall
