import React from 'react'
import { Organizations } from './components/Organizations'
import { TenantInformation } from './components/TenantInformation'
import { ManageAdmins } from './components/ManageAdmins'

function Profile() {
  return (
    <div className=' flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        <TenantInformation />
      </div>
      <div className='w-1/2'>
        <div>
          <Organizations />
        </div>
        <ManageAdmins />
      </div>
    </div>
  )
}

export { Profile }
