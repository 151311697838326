import { faJira, faSlack } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Label } from 'procyon-ui'
import React from 'react'
import ServiceNow from 'Components/CustomIcons/ServiceNow'

const AccountNotificationChannels = ({ account, showChannelsCount = 15, Fallback = null }) => {
  const { getObjectRef, slices } = useMultiSlice([
    'slackChannelList',
    'jiraProjectList',
    'servicenowtables',
    'notificationSubscriptions'
  ])

  const getChannels = () => {
    const accountKey = createRsrcKey(account)
    const list = []
    slices.notificationSubscriptions.map((item) => {
      const subscriptionKey = createRsrcKey(item.Spec.SubscriptionFor)
      if (subscriptionKey === accountKey) {
        list.push(item.Spec.Subscriber)
      }
    })
    return getObjectRef(list)
  }

  const channels = getChannels()
  const first20Channels = channels.slice(0, showChannelsCount - 1)

  return (
    <>
      {channels.length === 0 && Fallback}
      {first20Channels.map((c) => {
        const { icon, color, CustomIcon } = ChannelKindIconMap[c.ObjectMeta.Kind]
        return (
          <Label
            key={createRsrcKey(c)}
            icon={CustomIcon ? <CustomIcon /> : <FontAwesomeIcon color={color} icon={icon} />}
            text={getResourceName(c)}
          />
        )
      })}

      {channels.length > showChannelsCount && (
        <span className='flex bg-gray-400 rounded-full justify-center items-center p-2 text-white'>
          {channels.length - showChannelsCount}+
        </span>
      )}
    </>
  )
}
const NotfiChannelKind = {
  SlackChannel: true,
  JiraProject: true,
  ServiceNowTable: true
}

const ChannelKindIconMap = {
  SlackChannel: {
    icon: faSlack,
    color: ''
  },
  JiraProject: {
    icon: faJira,
    color: '#2684FF'
  },
  ServiceNowTable: {
    CustomIcon: ServiceNow
  }
}

export { AccountNotificationChannels }
