import { updateSliceData } from 'infra/redux/sliceHandlers'
import { FullScreenAlertModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'
import { closeApprovalRequest } from '../../../api'

function CloseRequestModal({ approvalRequest, onCancel, afterClose = null }) {
  const givenName = getResourceName(approvalRequest)

  const handleApprovalRequestClose = async () => {
    const req = await closeApprovalRequest(approvalRequest)
    updateSliceData(req)
    afterClose && afterClose()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Yes, Close Request'
        actionButtonVariant='danger'
        loadingMessage={`Closing request ${givenName}`}
        showModal
        onActionClick={handleApprovalRequestClose}
        onCancel={onCancel}
        Content={
          <div className='mt-4'>
            <Typography className='mb-3 !block' variant='body-regular'>
              Once <span className='text-red-500 mx-[1px]'>closed</span> the resources access provided by
              this request will be lost.
            </Typography>
          </div>
        }
        alertMessage={
          <>
            Are you sure you want to close request
            <strong className='mx-1' title={givenName}>
              {givenName}
            </strong>
            ?
          </>
        }
      />
    </div>
  )
}

export { CloseRequestModal }
