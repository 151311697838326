import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getApprovalRequestIAMActions, useApprovalDetails } from 'features/approvals'
import { AccessSummaryTab } from 'features/policy'
import { createRsrcKey, getResourceName } from 'features/resources'
import {
  PRINCIPAL_OBJECT_KINDS,
  getK8ClusterAppRoleWithID,
  getTargetsApprovalReqRules
} from 'features/targets'
import React, { useCallback } from 'react'
const _slices = ['iamActions', 'kubeClusters', 'kubeNamespaces']

function RequestSummaryTab() {
  const { resources, requestForUsersGroups, approvalRequest } = useApprovalDetails()
  // @ts-ignore
  const { getObjectRef } = useMultiSlice(_slices)

  const getPrincipalOnResources = () => {
    const map = {}
    const actionKeys = Object.keys(approvalRequest.Spec.Resources)
    actionKeys.forEach((actionKey) => {
      const rules = approvalRequest.Spec.Resources[actionKey]
      rules.forEach((rule) => {
        if (PRINCIPAL_OBJECT_KINDS.includes(rule.Target.RefKind)) {
          map[createRsrcKey(rule.Target)] = [`Principal: ${rule.Principal}`]
        }
      })
    })
    return map
  }

  const getK8AppRoleAttributes = () => {
    const map = {}
    const { KubeAccess } = getTargetsApprovalReqRules(approvalRequest)

    KubeAccess.forEach((rule) => {
      let cluster = null

      if (rule.Target.RefKind === 'KubeCluster') cluster = getObjectRef(rule.Target)
      if (rule.Target.RefKind === 'KubeNamespace') {
        // Get the cluster
        const namespace = getObjectRef(rule.Target)
        if (!namespace) return
        cluster = getObjectRef(namespace.Spec.Cluster)
      }

      if (!cluster) return
      const attrs = map[createRsrcKey(rule.ObjectRef)] || []
      const services = rule.Services.ObjectRef
      // Get approle id map
      const approleIDMap = getK8ClusterAppRoleWithID(cluster)
      services.forEach((s) => {
        // Push approle name
        if (approleIDMap[s.RefID]) attrs.push(approleIDMap[s.RefID])
      })
      map[createRsrcKey(rule.Target)] = attrs
    })
    return map
  }

  const getResourcesAttributes = useCallback(() => {
    const rsrcAttributesObj = {}
    if (approvalRequest.Type === 'ServiceAccount') return rsrcAttributesObj
    const rsrcAttachedIAMActions = getApprovalRequestIAMActions(approvalRequest)
    for (const rsrcKey in rsrcAttachedIAMActions) {
      const actionRefs = rsrcAttachedIAMActions[rsrcKey]
      const actionsObjects = getObjectRef(actionRefs)
      actionsObjects.forEach((action) => {
        const attrs = rsrcAttributesObj[rsrcKey] || []
        attrs.push(getResourceName(action))
        rsrcAttributesObj[rsrcKey] = attrs
      })
    }

    const PrincipalMap = getPrincipalOnResources()
    const k8Attributes = getK8AppRoleAttributes()
    return { ...rsrcAttributesObj, ...PrincipalMap, ...k8Attributes }
  }, [approvalRequest])

  return (
    <div>
      <AccessSummaryTab
        resourceAttibutesObject={getResourcesAttributes()}
        resources={resources}
        entities={requestForUsersGroups}
      />
    </div>
  )
}

export { RequestSummaryTab }
