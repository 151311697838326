import { Box } from '@material-ui/core'
import ChooseFilter from 'Components/FilterHeader/chooseFilter'
import React from 'react'

const ExternalFilters = ({ externalFilters }) => {
  return (
    <Box display='flex' alignItems='center'>
      {externalFilters.map((filter) => 
        <Box key={filter.Label} ml={1}>
          <ChooseFilter filter={filter} />
        </Box>)}
    </Box>
  )
}

export default ExternalFilters
