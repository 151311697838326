import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SearchInput } from 'V2Components'
import { KindToQueryIndexMap, findActivityLogById, useMultiQuery } from 'features/activityLogs'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import _ from 'lodash'
import moment from 'moment'
import { ResponsiveTable, SelectDateTime, SelectDropDown, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { ActivityLogInfoModal } from '../AcitivityLogInfoModal'
import { columns, useActivityLogs } from './useActivityLogs.utils'

const ActivityLogs = ({ userName = '', rsrc = null }) => {
  const [searchKey, setSearchKey] = useState('')
  const [showActivityLogInfoModal, setShowActivityLogInfoModal] = useState(false)
  const [selectedActivityLog, setSelectedActivityLog] = useState(null)
  const [startDateTime, setStartDateTime] = useState(moment().subtract(7, 'days'))
  const [endDateTime, setEndDateTime] = useState(moment())
  const [selectedUser, setSelectedUser] = useState(null)
  const [resourceKindFilter, setResourceKindFilter] = useState('all')
  const [queryIndexes, setQueryIndexes] = useState([
    'event-log',
    'audit-log',
    'proxy-event-log',
    'proxy-audit-log'
  ])

  const { slices, dispatchThunks, getObjectRef } = useMultiSlice(['userList'])
  const { appView } = useAppView()
  const { getQueryLogs, hits, fetching } = useMultiQuery({
    indexes:
      rsrc && KindToQueryIndexMap[rsrc.ObjectMeta.Kind]
        ? KindToQueryIndexMap[rsrc.ObjectMeta.Kind]
        : queryIndexes,
    endDateTime: endDateTime.toDate(),
    startDateTime: startDateTime.toDate(),
    userName: userName ? userName : selectedUser && selectedUser.ObjectMeta.Name
  })

  const { getRowData } = useActivityLogs({ hits, searchKey })

  /**
   * The resource based filter on the logs are done at row data level
   * @returns {any[]}
   */
  const getFilteredRowData = () => {
    const tableData = getRowData()

    if (!rsrc) {
      //Filter based on Kind
      return tableData.filter((r) => {
        const { resourceKind } = r
        if (resourceKindFilter !== 'all' && resourceKind !== resourceKindFilter) return false
        return true
      })
    }

    const rsrcKey = createRsrcKey(rsrc)
    const rsrcName = rsrc.ObjectMeta.Name

    return tableData.filter((r) => {
      const { resources } = r
      if (!resources.length) return
      const rs = resources[0]
      if (!rs) return
      // If the rsrc key of the rsrc matches with the resource of the log then, show row
      // or, if the name on the log matches the name of the resource then show the row
      if (rs.key === rsrcKey || rs.Name === rsrcName) return true
    })
  }

  const getUsersMenuItems = () => {
    let users = _.sortBy(
      slices.userList.map((e) => ({
        label: getResourceName(e),
        value: createRsrcKey(e)
      })),
      ['label']
    )
    return [{ label: 'All Users', value: 'all' }, ...users]
  }

  const handleResourceKindFilterChange = (e) => {
    setResourceKindFilter(e.target.value)
    if (e.target.value === 'all') return setQueryIndexes(KindToQueryIndexMap._ALL)
    setQueryIndexes(KindToQueryIndexMap[e.target.value])
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  useEffect(() => {
    getQueryLogs()
  }, [selectedUser, queryIndexes, startDateTime, endDateTime])

  const tableData = getFilteredRowData()

  return (
    <div>
      <Typography variant='h2'>Activity Logs</Typography>
      <div className='mt-3 rounded border p-4 border-[#D8DDE4] flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <Typography variant='body-regular'>From</Typography>
          <SelectDateTime
            value={startDateTime.toDate()}
            onChange={(v) => setStartDateTime(moment(v.toISOString()))}
          />

          <Typography variant='body-regular'>To</Typography>
          <SelectDateTime
            value={endDateTime.toDate()}
            onChange={(v) => setEndDateTime(moment(v.toISOString()))}
          />
          {/* { if rsrc is passed, that means only the logs of that rsrc are show hence, we hide the filters } */}
          {!rsrc && (
            <>
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={[
                  {
                    label: 'All Resources',
                    selected: true,
                    value: 'all'
                  },
                  {
                    label: 'Applications',
                    value: 'Application'
                  },
                  {
                    label: 'AppRoles',
                    value: 'AppRole'
                  },
                  {
                    label: 'Servers',
                    value: 'Server'
                  }
                ]}
                onChange={handleResourceKindFilterChange}
                value={resourceKindFilter}
              />

              <SelectDropDown
                multiple
                sx={{ width: '200px' }}
                menuItems={[
                  {
                    label: 'Audit Logs',
                    value: 'audit-log'
                  },
                  {
                    label: 'Event Logs',
                    value: 'event-log'
                  },
                  {
                    label: 'Proxy Audit Logs',
                    value: 'proxy-audit-log'
                  },
                  {
                    label: 'Proxy Event Logs',
                    value: 'proxy-event-log'
                  }
                ]}
                onChange={(e) => {
                  setQueryIndexes(e.target.value)
                }}
                //@ts-ignore
                value={queryIndexes}
              />
            </>
          )}

          {appView === 'admin' && !userName && (
            <SelectDropDown
              sx={{ width: '200px' }}
              menuItems={getUsersMenuItems()}
              onChange={(e) => setSelectedUser(getObjectRef(reverseRsrcKey(e.target.value)))}
              value={selectedUser ? createRsrcKey(selectedUser) : 'all'}
            />
          )}
        </div>
        <SearchInput searchKey={searchKey} onChange={setSearchKey} />
      </div>
      <div className='mt-8'>
        <ResponsiveTable
          scrollX='1200px'
          loading={fetching}
          onRow={(data) => {
            return {
              onClick() {
                const hit = findActivityLogById(hits, data.id)
                if (!hit) return
                setSelectedActivityLog(hit)
                setShowActivityLogInfoModal(true)
              }
            }
          }}
          columns={columns()}
          data={tableData}
        />
      </div>
      {showActivityLogInfoModal && selectedActivityLog && (
        <ActivityLogInfoModal
          onClose={() => {
            setSelectedActivityLog(null)
            setShowActivityLogInfoModal(false)
          }}
          hit={selectedActivityLog}
        />
      )}
      {/* {fetching && <LoadingFeedback loading message='Fetching Logs' />} */}
    </div>
  )
}

export { ActivityLogs }
