import { Box, Dialog, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  errorModal: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3)
  }
}))

const NetworkError = ({ Message, Icon, open = false }) => {
  const classes = useStyles()
  const [dots, setDots] = useState('.')
  useEffect(() => {
    const id = setInterval(() => {
      setDots((s) => {
        if (s.length === 3) return '.'
        else return s + '.'
      })
    }, 1000)
    return () => clearInterval(id)
  }, [])

  return (
    <Dialog maxWidth='md' fullWidth open={open}>
      <Box className={classes.errorModal}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {Icon}
          <Typography>Reconnecting{dots}</Typography>
        </Box>
        <Box display='flex' justifyContent='center'>
          {Message}
        </Box>
      </Box>
    </Dialog>
  )
}

export default NetworkError
