import introJs from 'intro.js'
import { useCallback, useEffect, useRef } from 'react'
import 'intro.js/introjs.css'
import { makeStyles } from '@material-ui/core'
import { debug } from 'Utils/DebugHelper'
import { steps } from './introSteps'
const useStyles = makeStyles((theme) => ({
  tooltipClass: {
    margin: '10px 0px',
    '& .introjs-arrow': {
      border: 'none',
      height: '100px',
      width: '100px',
      background: "url('img/hand.png')",
      backgroundSize: 'cover'
    },
    '& .introjs-arrow.top': {
      top: '-80px!important'
    },
    '& .introjs-arrow.bottom': {
      bottom: '-60px!important',
      left: '30px!important',
      transform: 'rotate(260deg)'
    }
  }
}))
const useIntro = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const startIntro = useCallback(() => {
    if (debug.shoudShowIntro()) {
      ref.current = setTimeout(() => {
        introJs().setOptions({
          steps: steps(),
          tooltipClass: classes.tooltipClass
        }).start()
        debug.markIntroDone()
      }, 2000)
    }
  }, [])

  const cleanup = useCallback(() => {
    if (ref.current) clearTimeout(ref.current)
  }, [])

  useEffect(() => {
    startIntro()
    return cleanup
  }, [])
}

export default useIntro
