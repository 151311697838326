import { _ } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useApprovalDetails } from 'features/approvals'
import { getResourceName } from 'features/resources'
import moment from 'moment'
import { CommentBox, Typography } from 'procyon-ui'
import React, { useEffect, useMemo } from 'react'

function CommentsTab () {
  const { approvalRequest } = useApprovalDetails()
  const { selectDispatch, initiallyLoaded, getObjectRef } = useMultiSlice(['userList'])

  const comments = useMemo(() => {
    const list = []
    if (!approvalRequest || !initiallyLoaded) return []
    const requestorComments = _.get(approvalRequest, 'Spec.Justification')
    const approverComments = _.get(approvalRequest, 'Spec.ApproverComments')
    const user = getObjectRef(approvalRequest.Requestor)
    const approver = getObjectRef(approvalRequest.StatusUpdatedBy)
    if (requestorComments) {
      // Comments by the user who created the request
      if (user) {
        list.push({
          comment: requestorComments,
          user: getResourceName(user),
          date: moment(approvalRequest.ObjectMeta.CreatedAt).format('MMM DD, YYYY hh:mm A')
        })
      }
    }

    if (approverComments) {
      // Comments by the admin upon approval or rejection
      if (user) {
        list.push({
          comment: approverComments,
          user: getResourceName(approver) || 'Not Found',
          date: moment(approvalRequest.ObjectMeta.UpdatedAt).format('MMM DD, YYYY hh:mm A')
        })
      }
    }
    return list
  }, [approvalRequest, initiallyLoaded])

  useEffect(() => {
    selectDispatch(['userList'])
  }, [])

  return (
    <div>
      {!comments.length && <Typography variant='body-regular'>No comments</Typography>}
      {comments.map((e, i) => (
        <div className='mb-4' key={i}>
          <CommentBox
            comment={e.comment}
            style={{
              width: '656px'
            }}
            timestamp={e.date}
            title={e.user}
          />
        </div>
      ))}
    </div>
  )
}

export { CommentsTab }
