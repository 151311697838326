import React, { useEffect } from 'react'
import TabPanel from './TabPanel'

import { Card, Divider, makeStyles, Tab, Tabs, useTheme } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import ActionButton from 'Components/ActionButton'

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({

  tab: {
    // @ts-ignore
    minWidth: (props) => props.tabMinWidth,

    padding: props => props.compact ? theme.spacing(1, 0, 0.5, 0) : '16px',
    '& > span': {
      ...theme.typography.button,
      fontWeight: theme.typography.fontWeightMedium,
      // @ts-ignore
      fontSize: props => props.compact ? theme.spacing(0.813) : theme.typography.fontSize
    }
  },
  activeTab: {
    '& > span': {
      color: theme.palette.primary.main
    }
  },
  divider: {
    backgroundColor: theme.palette.divider,
    height: props => props.compact ? '0.5px' : '2px',
    transform: props => props.compact ? 'translateY(-0.5px)' : 'translateY(-2px)',
    zIndex: 1,
    // @ts-ignore
    width: (props) => props.fullWidth ? '100%' : 'auto'
  },
  indicator: {
    zIndex: 2,
    backgroundColor: theme.palette.primary.main
  },
  root: {
    // @ts-ignore
    marginBottom: props => theme.spacing(props.tabsMarginBottom),
    // @ts-ignore
    backgroundColor: (props) => props.color === 'default' ? theme.palette.background.default : theme.palette.background.light
  },
  tabRoot: {
    padding: (props) => props.fullWidth ? '0px' : theme.spacing(0, 2)
  },
  action: { marginLeft: 'auto' }
}))

export default function VanillaTabs ({ compact, panelFullHeight, isActionDisabled, onActionClick, actionText, TabList, color, fullWidth, tabsMarginBottom = 1, tabMinWidth = '160px' }) {
  const classes = useStyles({ color, fullWidth, compact, tabsMarginBottom, tabMinWidth })
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  return (
    <>
      <div
        className={classes.root}
      >

        <Tabs
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          aria-label='full width tabs example'
          classes={{
            root: classes.tabRoot,
            indicator: classes.indicator
          }}
        >
          {TabList &&
            TabList.length > 0 &&
            TabList.map((tab, index) => (
              <Tab classes={{ selected: classes.activeTab }} onClick={() => setValue(index)} className={classes.tab} label={tab.name} key={index} {...a11yProps(index)} />
            ))}
          {onActionClick && <ActionButton disabled={isActionDisabled} className={classes.action} onClick={onActionClick} label={actionText} />}
        </Tabs>
        <Divider className={classes.divider} />
      </div>

      {TabList &&
        TabList.length > 0 &&
        TabList.map((Tab, index) => (
          <TabPanel panelFullHeight={panelFullHeight} value={value} key={index} index={index} dir={theme.direction}>
            {Tab.Tab}
          </TabPanel>
        ))}

    </>
  )
}
