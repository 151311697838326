import { faCopy, faEye, faInfo } from '@fortawesome/free-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { reduxApiClient } from 'infra'
import { apiClient } from 'infra/api'
import { Button, IconButton, Label, PasswordInput, TargetIcon, TextArea, Tooltip } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

export const useADDomainControllers = ({ onSaveSuccess, setIsLoading }) => {
  const [adPassword, setAdPassword] = useState([])
  const { slices, selectDispatch } = useMultiSlice(['adPasswords'])

  useEffect(() => {
    getRowData()
  }, [slices.adPasswords])

  const getRowData = async () => {
    try {
      const response = await apiClient('adpasswords').getAll()
      setAdPassword((prevAdPassword) => {
        const isSame = JSON.stringify(prevAdPassword) === JSON.stringify(response)
        if (isSame) {
          console.log('No changes detected in AD Password data')
        } else {
          console.log('AD Password data updated')
        }
        return response || []
      })
    } catch (error) {
      console.error('Error fetching data:', error)
      setAdPassword([])
    }
  }

  const isPasswordValidCheck = async (currentRowData, data) => {
    const { name, username, password } = data
    if (password.includes('*')) {
      const response = await apiClient('adpasswords').getAll({})
      const getExistingPassword = response.find((user) => user.UserName === username)
      return getExistingPassword?.Password
    } else {
      return password
    }
  }

  const handleOnSave = async (currentRowData, data) => {
    setIsLoading(true)
    const { Tenant, Namespace } = currentRowData.ObjectMeta
    const { name, username, password } = data
    const isPasswordValid = await isPasswordValidCheck(currentRowData, data)

    if (username && password) {
      const payload = {
        ObjectMeta: {
          Tenant: Tenant,
          Namespace: Namespace,
          Name: name
        },
        UserName: username,
        Password: isPasswordValid
      }
      try {
        const response = await apiClient('adpasswords').update(payload)
        onSaveSuccess()
        enqueueNotification('Username and Password Updated Successfully!', 'info')
      } catch (error) {
        console.error('Error updating password:', error)
      }
    }
  }

  const handleCopyCmd = async (data) => {
    try {
      const response = await reduxApiClient('adpasswords').getAll()
      const filteredData = response.find((user) => user.ObjectMeta.Name === data.name)
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(filteredData?.Password)
        enqueueNotification('Password Copied Successfully!', 'info')
      }
    } catch (e) {
      console.error('Error copying password:', e)
    }
  }

  const rowDataAdapterFn = (addc) => {
    return {
      id: Math.random(),
      name: addc.ObjectMeta.Name,
      username: addc.UserName !== '' ? addc.UserName : 'Enter User@domain Name',
      password: addc.Password !== '' ? '************' : 'Enter Password',
      rotatePasswordEnabled: addc.RotatePasswords,
      defaultFor: addc?.DefaultFor?.Elems,
      actions: {
        onSave(data) {
          handleOnSave(addc, data)
        },
        onCopyPassword: (data) => {
          handleCopyCmd(data)
        }
      }
    }
  }

  const tableData = adPassword?.length > 0 && adPassword?.map(rowDataAdapterFn)

  return { tableData, rowDataAdapterFn }
}

/**
 *
 * @returns {import("procyon-ui").DataTableProps['columns']}
 */
export const columns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 250,
    editable: true
  },
  {
    field: 'password',
    headerName: 'Password',
    width: 300,
    editable: true,
    renderCell({ row }) {
      return (
        <div className='flex relative w-[300px] h-[50px] px-3'>
          <div className='flex'>{row.password}</div>
          {row.password !== 'Enter Password' && (
            <div className='flex absolute right-0 top-2'>
              <IconButton
                onClick={() => row.actions.onCopyPassword(row)}
                variant='gray'
                icon={faCopy}
              />
              <Tooltip arrow placement='top-start' title='Copy Principal Password'>
                <IconButton
                  onMouseOver={() => {}}
                  variant='clear'
                  icon={faInfo}
                  style={{
                    width: '5px',
                    height: '15px'
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      )
    }
  },
  {
    field: 'rotatePasswordEnabled',
    headerName: 'Rotate Password Enabled',
    width: 300
  },
  {
    field: 'defaultFor',
    headerName: 'Default For',
    width: 300,
    renderCell({ value }) {
      return (
        <div className='flex gap-2'>
          {value.map((e) => (
            <Label key={e} icon={<TargetIcon className='w-[40px] h-[40px]' type={e} />} />
          ))}
          {!value.length && <Label variant='gray' text='None' />}
        </div>
      )
    }
  },
  {
    field: 'actions',
    headerName: 'Action',
    width: 200,
    sortable: false,
    renderCell({ row }) {
      return (
        <div className='flex gap-2 items-center'>
          <Button
            onClick={() => row.actions.onSave(row)}
            disabled={row.password === 'Enter Password'}
            variant='primary'
          >
            Save
          </Button>
        </div>
      )
    }
  }
]
