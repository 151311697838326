import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { SearchInput, TreeViewExtended } from 'V2Components'
import { useCloudDetailsProvider } from 'features/clouds'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { IamRolesCard, TreeItem, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const isResourceNodeKey = (key) => {
  const keyElements = key.split('+')
  return keyElements.length === 3
}

const ResourcesTreeView = ({
  rsrcs,
  getObjectRef,
  getNodeIcon,
  disableNodeClick,
  getCustomNodeCollection = null,
  customRenderFunction = null,
  simpleMode = false
}) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const { cloud: account } = useCloudDetailsProvider()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      const displayType = getIAMResourceDisplayType(a)
      return `${rName}${displayType}`.toLowerCase().includes(queryKey)
    }
  })

  const getTopLevelResources = () => {
    return rsrcs.filter((r) => r.Spec.Parent.RefKind === '' && r.Spec.Parent.RefID === '0')
  }

  /**
   * The function loops into every resource and maintains a map which holds resource key and it's respective children.
   * @returns Map Object with parent and childrens
   */

  const createParentToChildrenMap = () => {
    const map = {}
    const accountKey = createRsrcKey(account)
    rsrcs.forEach((rsrc) => {
      const key = createRsrcKey(rsrc)
      const parentKey = createRsrcKey(rsrc.Spec.Parent)
      /**
       * Instead of trying to find the childrens of each resource,
       * we update the parent with the current resource .
       *
       * In short, every resource has a parent which gets updated with the current resource as children.
       */
      if (parentKey === null) {
        const parentChildrens = map[accountKey] || []
        parentChildrens.push(key)
        map[accountKey] = parentChildrens
        return
      }

      /**
       * Custom node collection is used for grouping multiple resources into one common collection.
       * Eg, SQL Instance disply type resources, can be grouped into Database easily.
       * Look at: src/features/clouds/views/AccountDetails/components/ResourcesTab/components/GCPResources/GCPResources.jsx
       */
      if (getCustomNodeCollection && getCustomNodeCollection(rsrc)) {
        const collection = getCustomNodeCollection(rsrc)
        const pKey = `${collection.label}+${parentKey}`
        const rsrcChildrens = map[pKey] || []
        const parentChildrens = map[parentKey] || []
        if (!parentChildrens.includes(pKey)) parentChildrens.push(pKey)
        rsrcChildrens.push(key)
        map[pKey] = rsrcChildrens
        map[parentKey] = parentChildrens
      } else {
        const parentChildrens = map[parentKey] || []
        parentChildrens.push(key)
        map[parentKey] = parentChildrens
      }
    })

    return map
  }

  const parentChildrenMap = createParentToChildrenMap()

  const getSelectedNodeResources = () => {
    if (!selectedNodeKey) return []
    const serverChildrens = parentChildrenMap[`Servers+${selectedNodeKey}`] || []
    const databaseChildrens = parentChildrenMap[`Databases+${selectedNodeKey}`] || []
    const children = parentChildrenMap[selectedNodeKey] || []
    return getObjectRef([...children, ...serverChildrens, ...databaseChildrens].map(reverseRsrcKey))
  }

  const getChildrenNodes = (nodeKey) => parentChildrenMap[nodeKey] || []

  const handleNodeClick = (nodeKey) => {
    let key = nodeKey
    if (isResourceNodeKey(nodeKey)) return setSelectedNodeKey(nodeKey)
    const nodeRsrc = getObjectRef(reverseRsrcKey(key))
    // if (nodeRsrc.ObjectMeta.Kind !== 'GcpResource') return
    if (disableNodeClick(nodeRsrc)) return
    setSelectedNodeKey(key)
  }

  const renderNodeFn = (nodeKey, children) => {
    const keyElements = nodeKey.split('+')
    const isResourceNode = keyElements.length === 3

    if (isResourceNode) {
      if (children.length)
        return (
          <TreeItem
            // @ts-ignore
            labelIcon={getNodeIcon(keyElements[0])}
            labelText={keyElements[0]}
            nodeId={nodeKey}
          >
            {children}
          </TreeItem>
        )
    }

    const rsrc = getObjectRef(reverseRsrcKey(nodeKey))

    if (!rsrc) return null

    if (children.length)
      return (
        <TreeItem
          // @ts-ignore
          labelIcon={getNodeIcon(rsrc)}
          labelText={getResourceName(rsrc)}
          nodeId={nodeKey}
        >
          {children}
        </TreeItem>
      )

    return (
      <TreeItem
        // @ts-ignore
        labelIcon={getNodeIcon(rsrc)}
        labelText={getResourceName(rsrc)}
        nodeId={nodeKey}
      />
    )
  }

  const topLevelRsrcs = getTopLevelResources()
  const selectedNodeChildrenRsrcs = getSelectedNodeResources()
  const selectedNodeRsrc = getObjectRef(reverseRsrcKey(selectedNodeKey))

  if (account && !selectedNodeKey) {
    setSelectedNodeKey(createRsrcKey(account))
  }

  const searchAppliedRsrcs = applySearchQuery(selectedNodeChildrenRsrcs)

  if (simpleMode)
    return (
      <TreeViewExtended
        selectedNodeKey={selectedNodeKey}
        rootNode={[createRsrcKey(account), []]}
        rootChildrens={topLevelRsrcs.map(createRsrcKey)}
        getChildrenNodes={getChildrenNodes}
        renderNodeFn={customRenderFunction || renderNodeFn}
        onNodeClick={handleNodeClick}
      />
    )

  return (
    <div className='flex gap-4'>
      <div className='w-1/2'>
        <TreeViewExtended
          selectedNodeKey={selectedNodeKey}
          rootNode={[createRsrcKey(account), []]}
          rootChildrens={topLevelRsrcs.map(createRsrcKey)}
          getChildrenNodes={getChildrenNodes}
          renderNodeFn={customRenderFunction || renderNodeFn}
          onNodeClick={handleNodeClick}
        />
      </div>

      <div className='w-1/2'>
        <div className='flex justify-between items-center'>
          <Typography variant='body-regular'>{searchAppliedRsrcs.length} Resources</Typography>
          <SearchInput searchKey={searchKey} onChange={setSearchKey} />
        </div>
        <div className='flex gap-4 flex-col mt-4'>
          {!searchAppliedRsrcs.length && selectedNodeKey && (
            <Typography variant='body-regular'>
              No resources in the {getIAMResourceDisplayType(selectedNodeRsrc)},
              <strong className='ml-1'>{selectedNodeRsrc.Spec.DisplayName}</strong>
            </Typography>
          )}
          {searchAppliedRsrcs.map((rsrc) => {
            return (
              <IamRolesCard
                style={{ width: '100%' }}
                expandable={false}
                buttonType='none'
                description={getIAMResourceDisplayType(rsrc)}
                icon={getNodeIcon(rsrc)}
                title={getResourceName(rsrc)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export { ResourcesTreeView }
