import { ErrorLabelContent, getResourceName, getRsrcIcon } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'
import { LabelContent } from 'V2Components'

const AppRoleResource = ({ rsrc, account }) => {
  const AccountIcon = getRsrcIcon(account)

  if (!rsrc) return null

  return (
    <>
      <ErrorLabelContent rsrc={rsrc} />
      <LabelContent title='Name' content={rsrc.Spec.RoleName} />
      <LabelContent title='ARN' content={rsrc.Spec.Attributes.Map['aws-role-arn']} />

      <LabelContent
        title='Account'
        content={
          <div className='flex gap-2 items-center'>
            {AccountIcon && <AccountIcon className='mr-2' />}
            <Typography variant='body-regular'>{getResourceName(account)}</Typography>
          </div>
        }
      />
    </>
  )
}

export { AppRoleResource }
