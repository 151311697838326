// @ts-nocheck
export const defaultDebugFlags = {
  errorNotification: true,
  verboseErrorNotification: false,
  fallbackToMock: true,
  apiToken: false,
  IDPToken: '',
  container: false,
  fullstory: true,
  intro: true,
  introDone: true
}

class Debug {
  constructor (defaultDebugFlags) {
    console.log('ran debug helper')
    this.debugFlagsKeys = 'debugFlags'
    this.defaultDebugFlags = defaultDebugFlags
    this.getAllFlags()
  }

  /**
   * Gets the debug flags from localStorage.
   * If debug flags are not present in localStorage,
   * sets defaultDebugFlags as debug flags in localStorage
   * @returns debug flags from localStorage as Object
  **/
  getAllFlags () {
    const debugFlags = localStorage.getItem(this.debugFlagsKeys)
    if (debugFlags) {
      return JSON.parse(debugFlags)
    } else {
      localStorage.setItem(this.debugFlagsKeys, JSON.stringify(this.defaultDebugFlags))
      return this.defaultDebugFlags
    }
  }

  /**
   * Gets the value of specified flag's from localStorage
   * @param {string} flag flag whose value is being queried
   * @returns Value of specified flag's from localStorage
  **/
  getDebugFlag (flag) {
    const flags = this.getAllFlags()
    if (flag in flags) {
      return flags[flag]
    } else {
      console.error('no such debug flag was found', flag)
      return false
    }
  }

  /**
   * Sets the specified flag's value in localStorage
   * @param {string} flag flag whose value needs to be set
   * @param {boolean} value new value for the specified flag
   * @returns latest state of debugFlags
  **/
  setDebugFlag (flag, value) {
    this.getAllFlags()
    const flags = this.getAllFlags()
    flags[flag] = value
    localStorage.setItem(this.debugFlagsKeys, JSON.stringify(flags))
    return flags
  }

  /**
   * ErrorNotification Methods
  **/

  /**
   * turns off error notification on api failure
   * @returns latest state of debugFlags
  **/
  turnErrorNotificationOff () {
    return this.setDebugFlag('errorNotification', false)
  }

  /**
   * turns on error notification on api failure
   * @returns latest state of debugFlags
  **/
  turnErrorNotificationOn () {
    return this.setDebugFlag('errorNotification', true)
  }

  /**
   * Tells if  Api Error Notification should be shown on api failure
   * @returns {boolean} should Error Notification be shown on api failure
  **/
  shouldNotifyError () {
    return this.getDebugFlag('errorNotification')
  }

  /**
   * turns off verboseerror notification on api failure
   * @returns latest state of debugFlags
  **/
  turnVerboseErrorNotificationOff () {
    return this.setDebugFlag('verboseErrorNotification', false)
  }

  /**
     * turns onverbose error notification on api failure
     * @returns latest state of debugFlags
    **/
  turnVerboseErrorNotificationOn () {
    return this.setDebugFlag('verboseErrorNotification', true)
  }

  /**
     * Tells if verbose Api Error Notification should be shown on api failure
     * @returns {boolean} should Error Notification be shown on api failure
    **/
  verboseErrorNotification () {
    return this.getDebugFlag('verboseErrorNotification')
  }

  /**
   * FallbackToMock Methods
  **/

  /**
   * turns off mock fallback on api failure
   * @returns latest state of debugFlags
  **/
  turnFallbackToMockOff () {
    return this.setDebugFlag('fallbackToMock', false)
  }

  /**
   * turns on mock fallback on api failure
   * @returns latest state of debugFlags
  **/
  turnFallbackToMockOn () {
    return this.setDebugFlag('fallbackToMock', true)
  }

  /**
   * turns off fullstory tracking
   *
  **/
  turnFullstoryOff () {
    this.setDebugFlag('fallbackToMock', false)
    window.location.reload()
  }

  /**
    * Turns on fullstory tracking
    *
   **/
  turnFullstoryOn () {
    this.setDebugFlag('fullstory', true)
    window.location.reload()
  }

  /**
    * @returns latest state of debugFlags
   **/
  getFullstoryStatus () {
    return this.getDebugFlag('fullstory', true)
  }

  /**
   * Turns off mock fallback on api failure
   * @returns {boolean} should Mock api be called on api failure
   */
  shouldFallbackToMock () {
    return this.getDebugFlag('fallbackToMock')
  }

  /**
   * Sets new api token in localStorage for bypassing authentication
   * @param {string} token new api token to be set
   * @param {boolean} noReload should the app reload after updating the apiToken
   */
  setApiToken (token, reloadOnUpdate = true) {
    this.setDebugFlag('apiToken', token)
    if (reloadOnUpdate) {
      window.location.reload()
    }
  }

  /**
   * Fetches latest value of apiToken from localStorage
   * @returns latest value of apiToken from localStorage
   */
  getApiToken () {
    return this.getDebugFlag('apiToken')
  }

  /**
   * Sets apiToken to false in localStorage.
   * no token will be sent in authorization
   * headers after calling this method
   * @param {boolean} reloadOnUpdate should the app reload after updating the apiToken
   */
  clearApiToken (reloadOnUpdate = true) {
    this.setDebugFlag('apiToken', false)
    if (reloadOnUpdate) {
      window.location.reload()
    }
  }

  /**
   * Sets container to false in localStorage.
   * @param {'browser' | 'agent'} container is the app loaded in browser or agent container
   */
  setContainer (container = 'browser') {
    this.setDebugFlag('container', container)
  }

  /**
   * Gives container to false in localStorage.
   * @return {'browser' | 'agent'}
   */
  getContainer () {
    return this.getDebugFlag('container')
  }

  /**
   * Tells whether the current container agent
   * @return {Boolean} Is the current container agent
   */
  isContainerAgent () {
    return this.getDebugFlag('container') === 'agent'
  }

  /**
   * Sets all required flags for demo environment
   * @returns latest state of debugFlags
   */
  getDemoReady () {
    this.turnFallbackToMockOff()
    this.turnErrorNotificationOff()
    return this.getAllFlags()
  }

  /**
   * Sets new api token in localStorage for bypassing authentication
   * @param {string} token new api token to be set
   * @param {boolean} noReload should the app reload after updating the apiToken
   */
  turnIntrojsOn (reloadOnUpdate = true) {
    this.setDebugFlag('intro', true)
    this.setDebugFlag('introDone', false)
    if (reloadOnUpdate) {
      window.location.reload()
    }
  }

  turnIntrojsOff (reloadOnUpdate = true) {
    this.setDebugFlag('intro', false)
    this.setDebugFlag('introDone', false)
    if (reloadOnUpdate) {
      window.location.reload()
    }
  }

  markIntroDone () {
    this.setDebugFlag('introDone', true)
  }

  shoudShowIntro () {
    return this.getDebugFlag('intro') && !this.getDebugFlag('introDone')
  }
}

export const debug = new Debug(defaultDebugFlags)
