import React from 'react'
import { Route } from 'react-router'
import { CloudStore, CloudStoreDetails } from '../views'
import { WorkSpacedetails, WorkSpacesList } from 'features/databricks'

const DataStoreRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/datacloud']}>
        <CloudStore />
      </Route>
      <Route exact path={['/admin/datacloud/:appName', '/admin/datacloud/:appName/:tabName']}>
        <CloudStoreDetails />
      </Route>
      {/* Databricks route */}
      <Route exact path={['/admin/datacloud/workspace/:appName']}>
        <WorkSpacesList />
      </Route>
      <Route exact path={['/admin/datacloud/workspacedetails/:workspaceName']}>
        <WorkSpacedetails />
      </Route>

      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/datacloud']}>
        <CloudStore />
      </Route>
      <Route exact path={['/user/datacloud/:appName', '/user/datacloud/:appName/:tabName']}>
        <CloudStoreDetails />
      </Route>
      <Route
        exact
        path={['/user/datacloud/workspace/:appName', '/user/datacloud/workspace/:appName/:tabName']}
      >
        <WorkSpacesList />
      </Route>
      <Route exact path={['/user/datacloud/workspacedetails/:workspaceName']}>
        <WorkSpacedetails />
      </Route>
    </>
  )
}

export { DataStoreRoutes }
