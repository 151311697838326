import { getCurrentOrg } from 'Utils/Helpers'
import { updateUserOrg } from 'infra/redux/actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useMultiOrg() {
  const activeOrg = getCurrentOrg()

  const orgs = useSelector((s) => {
    try {
      //@ts-ignore
      const u = s.user.UserOrgs
      return Object.keys(u)
    } catch (error) {
      return []
    }
  })

  const adminOrgs = useSelector((s) => {
    try {
      const l = []
      //@ts-ignore
      for (const orgName in s.user.UserOrgs) {
        //@ts-ignore
        if (s.user.UserOrgs[orgName]) l.push(orgName)
      }
      return l
    } catch (error) {
      return []
    }
  })

  const dispatch = useDispatch()

  /**
   * Change the current organization to `org`
   * @param {string} org
   * @returns
   */
  const changeOrganization = useCallback((org) => dispatch(updateUserOrg(org)), [])

  return { orgs, changeOrganization, activeOrg, adminOrgs }
}
