import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { cn, enqueueNotification, getCurrentOrg } from 'Utils/Helpers'
import { LoadingFeedback, PrefixSuffixInput } from 'V2Components'
import {
  AppBackendURLKey,
  AppFrontendURLKey,
  AppHTTPEntryPathKey,
  AppMeshClusterKey,
  AppNameKey,
  HIDDEN_APPLICATION_FRONTEND_URL_PORTS,
  createApplication,
  getFrontendNameFromURL,
  updateApplication
} from 'features/applications'
import _ from 'lodash'
import { Button, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import { useUser } from 'Core/Hooks/useUser'
import { useHistory } from 'react-router'

function HTTPSApplicationForm({ onCancel, onComplete }) {
  const history = useHistory()
  const { isEditMode, app } = useApplicationEditContext()
  const { rdpurl, isAgentlessMode } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const [appName, setAppName] = useState(_.get(app, AppNameKey, ''))
  const [beURL, setBeURL] = useState(_.get(app, AppBackendURLKey, ''))
  const [fnURL, setFnURL] = useState(getFrontendNameFromURL(_.get(app, AppFrontendURLKey, '')))
  const [entryPath, setEntryPath] = useState(_.get(app, AppHTTPEntryPathKey, '/'))

  const [clusterID, setClusterID] = useState(_.get(app, AppMeshClusterKey, ''))

  const { slices } = useMultiSlice(['medusaNodes', 'applicationList'])

  const getMeshClusterIds = () => {
    const ids = []
    if (app) ids.push(_.get(app, AppMeshClusterKey))
    ids.push(...slices.medusaNodes.map((node) => node.ClusterID))
    return [...new Set(ids)]
  }

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: appName } })) {
      return 'Application name is already taken!'
    }
    if (appName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!fnURL) return 'Frontend URL must not be empty.'
    if (!beURL) return 'Backend URL must not be empty.'
    if (!clusterID) return 'Please select the Mesh Cluster.'
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const data = {
      appName,
      beURL,
      fnURL: `${fnURL}.${getCurrentOrg()}.applications.procyon.ai`,
      clusterID,
      app,
      entryPath
    }

    try {
      let appResp
      if (isEditMode) {
        appResp = await handleHTTPSApplicationUpdate(data)
        updateSliceData(app)
      } else {
        appResp = await handleHTTPSApplicationCreate(data)
        pushToSlice(app)
      }
      enqueueNotification(
        `Successfully ${isEditMode ? 'Updated' : 'Created'} HTTPS Application!`,
        'info'
      )
      onComplete?.()
      window.location.reload()
      // history.push(`/admin/applications/${encodeURIComponent(appResp.ObjectMeta.Name)}`) 
    } catch (error) {
      enqueueNotification(
        `Failed to ${isEditMode ? 'Update' : 'Create'} HTTPS Application!`,
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onNameInputChange = (value) => {
    if(value.endsWith('.')){
      value = value.slice(0, -1)
    }
    setAppName(value)
  }

  const formErrors = getErrors()
  const port = location.port
  // Apply port suffix if the current port is not in the listed ports
  const applyPortSuffix = port && !HIDDEN_APPLICATION_FRONTEND_URL_PORTS.includes(port)
  let suffix = isAgentlessMode
    ? `-${getCurrentOrg()}.applications.${rdpurl}`
    : `.${getCurrentOrg()}.applications.procyon.ai${applyPortSuffix ? `:${port}` : ''}`

  return (
    <div>
      <Typography variant='body-regular'>
        Easily integrate web applications by providing the following information.
      </Typography>
      <div className='mt-4'>
        <TextInput
          disabled={isEditMode}
          value={appName}
          onChange={(e) => onNameInputChange(e.target.value)}
          sx={{ width: '100%' }}
          label='Application Name'
        />
        <div className='mt-4'>
          <Typography className='mb-1' variant='body-regular'>
            Frontend URL
          </Typography>
          <PrefixSuffixInput
            prefix='https://'
            suffix={suffix}
            value={fnURL}
            onTextChange={setFnURL}
          />
        </div>
        <div className='mt-4'>
          <Typography className='mb-1' variant='body-regular'>
          Frontend URL Path (optional)
          </Typography>
          <PrefixSuffixInput
            value={entryPath}
            onTextChange={setEntryPath}
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={beURL}
            onChange={(e) => setBeURL(e.target.value)}
            sx={{ width: '100%' }}
            label='Backend URL'
          />
        </div>
        <div className='mt-4'>
          <Typography className='mb-1' variant='body-regular'>
            Mesh Cluster
          </Typography>
          <SelectDropDown
            showHelpText={false}
            sx={{ width: '100%' }}
            menuItems={getMeshClusterIds().map((e) => ({
              label: e,
              value: e
            }))}
            onChange={(e) => setClusterID(e.target.value)}
            value={clusterID}
          />
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback
        caption='Please wait..'
        loading={isLoading}
        message={`${isEditMode ? 'Updating ' + appName : 'Creating HTTPS Application'}`}
      />
    </div>
  )
}

const handleHTTPSApplicationCreate = async ({ appName, fnURL, beURL, clusterID, entryPath }) => {
  const port = location.port
  // Apply port suffix if the current port is not in the listed ports
  const applyPortSuffix = port && !HIDDEN_APPLICATION_FRONTEND_URL_PORTS.includes(port)
  const data = {
    ObjectMeta: {
      Name: appName
    },
    Spec: {
      AppType: 'http',
      HttpAppConfig: {
        FrontEndName: {
          Elems: [applyPortSuffix ? `${fnURL}:${port}` : fnURL]
        },
        Backends: {
          Elems: [beURL]
        },
        MeshCluster: clusterID,
        EntryPath: entryPath
      }
    }
  }

  return await createApplication(data)
}

const handleHTTPSApplicationUpdate = async ({ app, fnURL, beURL, clusterID, entryPath }) => {
  const port = location.port
  // Apply port suffix if the current port is not in the listed ports
  const applyPortSuffix = port && !HIDDEN_APPLICATION_FRONTEND_URL_PORTS.includes(port)

  const data = structuredClone(app)
  // apply port suffix if necessary
  _.set(data, AppFrontendURLKey, applyPortSuffix ? `${fnURL}:${port}` : fnURL)
  _.set(data, AppBackendURLKey, beURL)
  _.set(data, AppMeshClusterKey, clusterID)
  _.set(data, AppHTTPEntryPathKey, entryPath || '/')

  return await updateApplication(data)
}

export { HTTPSApplicationForm }
