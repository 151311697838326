import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles(theme => ({

  icon: {
    // @ts-ignore
    width: props => theme.spacing(props.iconSize),
    color: theme.palette.text.disabled
  }
}))
const ProgressInfo = ({ Icon, texts, color, iconSize }) => {
  const classes = useStyle({ iconSize })
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      top={0}
      left={0}
      bottom={0}
      right={0}
      position='absolute'
    >
      {Icon && <Icon color={color} className={classes.icon} />}
      {
            texts && texts.map(text => <Typography key={text.text} variant={text.variant} style={{ color: text.color }}>{text.text}</Typography>)
        }

    </Box>
  )
}

export default ProgressInfo
