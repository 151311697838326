import useMultiSlice from 'Core/Hooks/useMultiSlice'
import _ from 'lodash'
import { useEffect } from 'react'
import { getDataNamesFromESLog, isApplicationLog } from '../utils'
import { createRsrcKey, getResourceName } from 'features/resources'
import { getApplicationHostName } from 'features/applications'
import { MapKindToSliceName } from "infra/redux/utils/constants"

export const useActivityLogParser = () => {
  const { slices, dispatchThunks } = useMultiSlice([
    'deviceList',
    'userList',
    'appRolesList',
    'serverList',
    'applicationList'
  ])

  /**
   *
   * @param {any[]} hits
   * @returns {{
   * _id: string
   * _index: string
   * device?: object
   * user?: object
   * resource?: object
   * tenantName: string
   * eventTime: Date
   * message: string
   * deviceName: string
   * resourceKind: import('types').ObjectMetaKinds
   * resources: { Kind: import('types').ObjectMetaKinds, Name: string, key: string | null, rsrc: object | null }[]
   * userName: string
   * }[]}
   */
  const getParsedLogData = (hits) => {
    const data = []
    hits.forEach((hit) => {
      const dataNames = getDataNamesFromESLog(hit)
      let rsrcs = []
      const { _index, _source, _id } = hit
      const { deviceName, resourceKind, resources, tenantName, userName } = dataNames
      const device = _.find(slices.deviceList, { ObjectMeta: { Name: deviceName } })
      const user = _.find(slices.userList, { ObjectMeta: { Name: userName } })
      const isAppLog = isApplicationLog(hit)

      if (isAppLog) {
        const { Data } = _source
        const app = slices.applicationList.find((e) => getApplicationHostName(e) === Data.Host)
        if (app) {
          rsrcs = [
            {
              Name: getResourceName(app),
              Kind: 'Application',
              key: createRsrcKey(app),
              rsrc: app
            }
          ]
        } else {
          rsrcs = [
            {
              Name: Data.Host,
              Kind: 'Application',
              key: null,
              rsrc: null
            }
          ]
        }
      }
      if (typeof resources === 'string') {
        const obj = _.find(slices[MapKindToSliceName[resourceKind]] || [], {
          ObjectMeta: { Name: resources }
        })
        if (obj)
          rsrcs = [
            {
              Name: getResourceName(obj),
              Kind: 'Server',
              key: createRsrcKey(obj),
              rsrc: obj
            }
          ]
      } else if (typeof resources !== 'string' && resources) {
        rsrcs = resources.map((rsrc) => {
          const Kind = rsrc.Kind
          const Name = rsrc.Name
          const obj = _.find(slices[MapKindToSliceName[Kind]] || [], { ObjectMeta: { Name } })
          //Format the resource
          if (obj)
            return {
              Name,
              Kind,
              key: createRsrcKey(obj),
              rsrc: obj
            }
          return {
            Name,
            Kind,
            key: null,
            rsrc: null
          }
        })
      }

      data.push({
        _id,
        _index,
        device,
        user,
        //formtted resources
        resources: rsrcs,
        tenantName,
        eventTime: _source['@timestamp'],
        message: isAppLog
          ? `${user ? getResourceName(user) : userName} opened application (${rsrcs[0].Name})`
          : _source.Message,
        deviceName,
        resourceKind,
        userName
      })
    })

    return data
  }

  useEffect(() => {
    dispatchThunks({ skipWhenLoaded: true })
  }, [])

  return { getParsedLogData }
}
