import { useUser } from 'Core/Hooks/useUser'
import { FullScreenContentModal } from 'V2Components'
import {
  Button,
  Label,
  LoadingSpinner,
  SwitchBox,
  TargetIcon,
  TextInput,
  Typography
} from 'procyon-ui'
import React from 'react'

const MSTeam = ({
  setIsSelfHosted,
  isSelfHosted,
  onInputChange,
  onContinue,
  message,
  isDisabled,
  handlePaste,
  onCancel
}) => {
  const { controller } = useUser()

  const handleBotRedirection = () => {
    if (controller === 'staging.proxyon.cloud' || controller === 'sumans.proxyon.cloud') {
      window.open('https://procyon-stage-assets.s3.amazonaws.com/msteams-bot-stage.zip', '_blank')
    } else {
      window.open('', '_blank')
    }
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center'>
        <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
          <TargetIcon
            width={'48px'}
            height={'48px'}
            type={'TEAMS'}
            className='rounded'
            style={{
              borderRadius: '12px',
              marginRight: '15px'
            }}
          />
          Setup Microsoft Team Integration
        </Typography>
        <div>
          <Button onClick={() => handleBotRedirection()}>Integrate BOT</Button>
        </div>
      </div>
      {!message ? (
        <>
          <div className='p-4 mt-2 rounded text-black max-h-[60vh] w-[100%]'>
            <TextInput
              label='Integration Name'
              onChange={(e) => onInputChange(e.target.value, 'name')}
              placeholder='Please enter name'
              style={{
                width: '550px'
              }}
            />

            <div className='flex justify-between my-3 items-center'>
              <span>Enable Webhook</span>
              <SwitchBox
                checked={isSelfHosted}
                onChange={() => setIsSelfHosted(!isSelfHosted)}
                variant='primary'
                width='60px'
                style={{
                  background: 'none'
                }}
              />
            </div>

            {isSelfHosted && (
              <TextInput
                label='Webhook Url'
                onChange={(e) => onInputChange(e.target.value, 'webhookurl')}
                placeholder='URL'
                style={{
                  width: '550px'
                }}
              />
            )}
          </div>

          <div className='flex justify-end mt-4 gap-4'>
            <Button size='md' onClick={onCancel} variant='gray'>
              Cancel
            </Button>
            <Button size='md' onClick={onContinue} variant='primary' disabled={isDisabled}>
              Continue
            </Button>
          </div>
        </>
      ) : (
        <div className='flex items-center bg-[#f2f4fb] p-5 mt-5'>
          <div className='mt-2'>
            <LoadingSpinner size={20} thickness={4} />
          </div>
          <Label text={message} variant='grayBlue' />
        </div>
      )}
    </FullScreenContentModal>
  )
}

export { MSTeam }
