import { faPen } from '@fortawesome/free-solid-svg-icons'
import { ApiProvider } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { KindToEndpointMap } from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityInfoBar, EntityPicker, FullScreenContentModal, LoadingFeedback, SearchInput } from 'V2Components'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const WhitelistedOwnerKinds = {
  User: true,
  Group: true
}

function ManageOwnersView({ rsrc }) {
  const [ownerSearchKey, setOwnerSearchKey] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [userGroupSelectionModal, setUserGroupSelectionModal] = useState(false)
  const { getObjectRef, slices } = useMultiSlice(['userList', 'groupList'])

  const getOwners = () => {
    const list = []
    if (!rsrc) return []

    rsrc.ObjectMeta.WrOwners.ObjectRef.forEach((e) => {
      if (WhitelistedOwnerKinds[e.RefKind]) {
        const obj = getObjectRef(e)
        if (obj) list.push(obj)
      }
    })

    return list
  }

  const getRsrcOwnersModalItems = () => {
    const items = []
    const wrOwners = rsrc.ObjectMeta.WrOwners.ObjectRef
    wrOwners.forEach((e) => {
      const obj = getObjectRef(e)
      if (!obj) return
      const { Kind, ID, Name } = obj.ObjectMeta
      if (WhitelistedOwnerKinds[Kind]) {
        const data = {
          caption: Name,
          name: getResourceName(obj),
          kind: Kind,
          key: createRsrcKey(obj),
          ref: {
            RefKind: Kind,
            RefID: ID
          }
        }
        const searchData = [data.caption, data.name, data.key]
        if (ownerSearchKey) {
          JSON.stringify(searchData).toLowerCase().includes(ownerSearchKey) && items.push(data)
        } else items.push(data)
      }
    })
    return items
  }

  const getEntities = () => {
    return [...slices.userList, ...slices.groupList]
  }

  const handleWrOwnersUpdate = async () => {
    try {
      setIsLoading(true)
      const newRsrcObject = structuredClone(rsrc)
      const preservedRefs = newRsrcObject.ObjectMeta.WrOwners.ObjectRef.filter((e) => !WhitelistedOwnerKinds[e.RefKind])
      newRsrcObject.ObjectMeta.WrOwners.ObjectRef = [...preservedRefs, ...selectedEntities.map(createRef)]
      const response = await new ApiProvider(KindToEndpointMap[rsrc.ObjectMeta.Kind]).setInstance(newRsrcObject).put()
      updateSliceData(response.data)
      enqueueNotification('Owners Updated Successfully!', 'info')
    } catch (error) {
    } finally {
      setIsLoading(false)
      setUserGroupSelectionModal(false)
    }
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography className='text-[#222945]' variant='h4-regular'>
          Owners
        </Typography>
        <div className='flex gap-4 items-center'>
          <SearchInput
            className='w-[275px]'
            placeholder='Search'
            searchKey={ownerSearchKey}
            onChange={setOwnerSearchKey}
          />
          <Button
            size='md'
            onClick={() => {
              setUserGroupSelectionModal(true)
              setSelectedEntities(getOwners())
            }}
            variant='grayBlue'
            icon={faPen}
          >
            Edit
          </Button>
        </div>
      </div>
      <div className='mt-4'>
        {!getRsrcOwnersModalItems().length && (
          <Typography variant='body-regular'>The resource has no owners.</Typography>
        )}
        {getRsrcOwnersModalItems().map((e) => (
          <div className='border-[#D8DDE4] border-b'>
            <EntityInfoBar
              endButton={false}
              key={e.key}
              caption={e.caption}
              name={e.name}
              onClick={function noRefCheck() {}}
              onClickAddButton={function noRefCheck() {}}
              onClickRemoveButton={function noRefCheck() {}}
              kind={e.kind}
            />
          </div>
        ))}
      </div>

      {userGroupSelectionModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title='Select Owners'
            entities={getEntities()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onContinue={handleWrOwnersUpdate}
            onCancel={() => setUserGroupSelectionModal(false)}
          />
        </FullScreenContentModal>
      )}
      <LoadingFeedback loading={isLoading} caption='Please wait..' message='Updating Owners' />
    </div>
  )
}

export { ManageOwnersView }
