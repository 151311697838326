import { RejectionModal } from 'procyon-ui'
import React, { useState } from 'react'
import { FullScreenModal, LoadingFeedback } from 'V2Components'
import { rejectApprovalRequest } from '../../../api'
import { updateSliceData } from 'infra/redux/sliceHandlers'

function RejectRequestModal({ approvalRequest, showModal, onCancel, afterRejection = null }) {
  const [comments, setComments] = useState('')
  const [loading, setLoading] = useState(false)

  const handleApprovalRequestReject = async () => {
    setLoading(true)
    const data = await rejectApprovalRequest(approvalRequest, comments)
    updateSliceData(data)
    setLoading(false)
    afterRejection && afterRejection()
  }

  return (
    <div>
      <LoadingFeedback loading={loading} caption='Please wait...' message={'Rejecting Approval Request'} />
      <FullScreenModal showModal={showModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <RejectionModal
            onClickReject={handleApprovalRequestReject}
            onClickCancel={onCancel}
            onChangeComments={(e) => setComments(e.target.value)}
            title={`Are you sure you want to reject Request ${approvalRequest.GivenName}?`}
          />
        </div>
      </FullScreenModal>
    </div>
  )
}

export { RejectRequestModal }
