import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { SummaryHeader } from 'V2Components'
import { isAccountResource, useGCPCloud } from 'features/clouds'
import { CreatePolicyFlow, IAMResourceView } from 'features/iamResources'
import {
  AccessCartProvider,
  ResourcesViewProvider,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  reverseRsrcKey,
  useAccessCartProvider
} from 'features/resources'
import _ from 'lodash'
import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const CART_KEY = 'workloadPolicy'

function WorkloadResourcesView() {
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const [activeCloudType, setActiveCloudType] = useState('')
  const [views, setViews] = useState({
    showAccessRequestSubmission: false,
    showCreateIAMRole: false,
    showIAMRolesSelection: false,
    showUserGroupSelection: false
  })
  const history = useHistory()
  const { slices, getObjectRef, dispatchThunks } = useMultiSlice([
    'awsResources',
    'gcpResources',
    'azureResources',
    'accountList',
    'projects'
  ])

  const workloadAccountKey = useURLQuery().get('account')

  const workload = useDataDetailsContext()
  const { getGCPResourceChildren, getGCPProject, getClusterKubeNameSpaces } = useGCPCloud()
  const { clearCartItems } = useAccessCartProvider()

  const accountKey = workloadAccountKey?.split(' ').join('+')
  const account = getObjectRef(reverseRsrcKey(accountKey))

  const getResources = () => {
    const list = []
    const listMap = {
      GCP: slices.gcpResources,
      AWS: slices.awsResources,
      AZURE: slices.azureResources
    }

    const KubeClusterDisplayType = {
      AWS: 'EKS',
      GCP: 'GKE Cluster',
      AZURE: 'Managed Cluster'
    }

    if (!workloadAccountKey) return []
    if (!account) return []

    if (account && account.ObjectMeta.Kind === 'Project') {
      const gcpProject = getGCPProject(account)
      const gkeNamespaces = []
      if (gcpProject) {
        const children = getGCPResourceChildren(gcpProject)
        list.push(gcpProject, ...children)
        // Get all GKE Clusters inside the gcpProject
        const gkeClusters = children.filter(
          (e) => e.Spec.DisplayType === KubeClusterDisplayType.GCP
        )
        gkeClusters.forEach((cluster) => {
          // Get all kubenamespaces inside each cluster
          const ks = getClusterKubeNameSpaces(cluster)
          // push to array
          gkeNamespaces.push(...ks)
        })
      }
      // Show all kubes which belong to this project on the resources list
      return [...list, ...gkeNamespaces]
    }

    const accountType = account.Spec.Type
    const l = listMap[accountType].filter((r) => isAccountResource(account, r)) || []

    return l
  }

  const cardProps = useMemoOnce({
    forPolicy: true,
    onAccessClick(rsrc) {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, showIAMRolesSelection: true }))
    },
    showMoreInfoButton: true,
    onMoreInfoClick(rsrc) {
      history.push(
        `/admin/resources/${rsrc.ObjectMeta.Kind.toLowerCase()}/${rsrc.ObjectMeta.Name}?entity=${workload.ObjectMeta.Name}`
      )
    }
  })

  /**
   * Returns accounts for the resource which are there
   * @returns {{label: string, value: string}[]}
   */
  const getDropDownItems = () => {
    const list = []
    const items = []

    getResources().forEach((r) => {
      const k = createRsrcKey(getRsrcAccountRef(r))
      if (!list.includes(k)) {
        list.push(k)
        const account = getObjectRef(reverseRsrcKey(k))
        if (account) {
          items.push({
            label: getResourceName(account),
            value: k
          })
        }
      }
    })

    return items
  }

  useEffect(() => {
    dispatchThunks()
    clearCartItems()
  }, [])

  if (!account) return null

  return (
    <div>
      <SummaryHeader
        actions={<div className='flex gap-4'></div>}
        rsrcName={`${account.ObjectMeta.Kind} (${getResourceName(account)})`}
        breadCrumbsItems={[
          {
            label: 'Workloads',
            link: '/admin/workloads'
          },
          {
            label: getResourceName(workload),
            link: `/admin/workloads/${workload.ObjectMeta.Name}`
          },
          {
            label: 'Resources Catalog'
          }
        ]}
      />

      <ResourcesViewProvider
        filterOptions={{
          hideCloudOptions: true,
          hideAccessStatusFilters: true,
        }}
        dropDownItems={getDropDownItems()}
      >
        <IAMResourceView
          hideCloudTypeSelect
          rsrcs={getResources()}
          activeCloudType=''
          setActiveCloudType={setActiveCloudType}
          cardProps={cardProps}
        />
      </ResourcesViewProvider>

      <CreatePolicyFlow
        enableUserGroupSelection={false}
        serviceAccountType={activeCloudType}
        selectedRsrc={selectedRsrc}
        setSelectedRsrc={setSelectedRsrc}
        views={views}
        setViews={setViews}
      />
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider
    routeKey='workloadName'
    sliceName='workloads'
    loadingMessage='Fetching Workload'
  >
    <AccessCartProvider cartKey={CART_KEY}>
      <WorkloadResourcesView />
    </AccessCartProvider>
  </DataDetailsProvider>
)

export { Wrapped as WorkloadResourcesView }
