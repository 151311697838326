import useAppView from 'Core/Hooks/useAppView'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useRouteParams } from 'Core/Hooks/useRouteParams'
import { useCloudTypes } from 'features/clouds'
import { CreatePolicyFlow, IAMResourceView } from 'features/iamResources'
import {
  AccessCartProvider,
  ResourcesViewProvider,
  createRsrcKey,
  getResourceCloudType,
  getResourceName,
  getRsrcAccountRef,
  reverseRsrcKey
} from 'features/resources'

import React, { useState } from 'react'
import { useHistory } from 'react-router'

const CART_KEY = 'adminResources'

function AdminIAMResources({}) {
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const [activeCloudType, setActiveCloudType] = useState('')

  const { slices, getObjectRef } = useMultiSlice(['awsResources', 'gcpResources', 'azureResources'])

  const { appView } = useAppView()
  const history = useHistory()
  const { cloudTypes } = useCloudTypes()

  useRouteParams({
    key: 'cloudType',
    defaultValue: cloudTypes[0]?.toUpperCase() || 'aws',
    controlValue: activeCloudType.toLowerCase(),
    possibleValues: cloudTypes.map((t) => t.toUpperCase()),
    onControlValueChange(value) {
      setActiveCloudType(value.toUpperCase())
      history.push(`/${appView}/resources/${value}`)
    }
  })

  const [views, setViews] = useState({
    showAccessRequestSubmission: false,
    showCreateIAMRole: false,
    showIAMRolesSelection: false,
    showUserGroupSelection: false
  })

  const cardProps = useMemoOnce({
    forPolicy: true,
    onAccessClick(rsrc) {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, showIAMRolesSelection: true }))
    },
    showMoreInfoButton: true
  })

  /**
   * Returns accounts for the resource which are there
   * @returns {{label: string, value: string}[]}
   */
  const getDropDownItems = () => {
    const list = []
    const items = []
    const rsrcs = [...slices.awsResources, ...slices.gcpResources, ...slices.azureResources]
    rsrcs.forEach((r) => {
      const k = createRsrcKey(getRsrcAccountRef(r))
      if (!list.includes(k)) {
        list.push(k)
        const account = getObjectRef(reverseRsrcKey(k))
        if (account && account.Spec.Type === activeCloudType) {
          items.push({
            label: getResourceName(account),
            value: k
          })
        }
      }
    })

    return items
  }

  const getResources = () => {
    return [...slices.awsResources, ...slices.gcpResources, ...slices.azureResources].filter(
      (e) => getResourceCloudType(e) === activeCloudType
    )
  }

  return (
    <div>
      <AccessCartProvider cartKey={CART_KEY}>
        <ResourcesViewProvider dropDownItems={getDropDownItems()}>
          <IAMResourceView
            rsrcs={getResources()}
            activeCloudType={activeCloudType}
            setActiveCloudType={setActiveCloudType}
            cardProps={cardProps}
          />
        </ResourcesViewProvider>
        <CreatePolicyFlow
          enableUserGroupSelection
          serviceAccountType={activeCloudType}
          selectedRsrc={selectedRsrc}
          setSelectedRsrc={setSelectedRsrc}
          views={views}
          setViews={setViews}
        />
      </AccessCartProvider>
    </div>
  )
}

export { AdminIAMResources }
