import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createGroup, updateGroup } from 'features/groups'
import { Button, Label, TextArea, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function CreateGroupModal({ onCancel, onComplete, group = null }) {
  const [name, setName] = useState(group ? group.ObjectMeta.Name : '')
  const [description, setDescription] = useState(group ? group.Spec.Description : '')

  const isEditMode = !!group

  const [isLoading, setIsLoading] = useState(false)

  const { slices } = useMultiSlice(['groupList'])

  const groupNames = slices.groupList.map((e) => e.ObjectMeta.Name.toLowerCase())

  const handleCreateClick = async () => {
    setIsLoading(true)
    try {
      if (isEditMode) {
        const g = await handleGroupUpdate({ group, description })
        updateSliceData(g)
        enqueueNotification('Group info successfully updated!', 'info')
      } else {
        const g = await handleGroupCreate({ name, description })
        pushToSlice(g)
        enqueueNotification('Group successfully created!', 'info')
      }
      onComplete?.()
    } catch (error) {
      enqueueNotification('Unable to create group!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const getErrors = () => {
    if (!name.length) return 'Group name cannot be empty.'
    if (!isEditMode && groupNames.includes(name.toLowerCase()))
      return 'Group with the name already exists.'
    if (!description.length) return 'Group description cannot be empty.'
  }

  return (
    <div>
      <FullScreenContentModal>
        <div>
          <div className='flex gap-4'>
            <span className='h-8 w-8 flex justify-center items-center rounded-full bg-[#F1F4FB]'>
              <FontAwesomeIcon size='lg' color='#658BD3' icon={faPlus} />
            </span>
            <Typography variant='h4-medium'>{isEditMode ? 'Update' : 'Create'} Group</Typography>
          </div>
          <div className='mt-6'>
            <TextInput
              disabled={isEditMode}
              value={name}
              onChange={(e) => setName(e.target.value.trim())}
              sx={{ width: '100%' }}
              label='Name'
            />
            <div className='mt-6'>
              <TextArea
                label='Description'
                textareaStyle={{ width: '100%' }}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>
          <div className='mt-6 flex gap-4 justify-between'>
            {getErrors() ? <Label text={getErrors()} variant='warning' /> : <div />}
            <div className='flex gap-4'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button disabled={!!getErrors()} onClick={handleCreateClick} variant='grayBlue'>
                Save
              </Button>
            </div>
          </div>
        </div>
      </FullScreenContentModal>
      <LoadingFeedback
        message={`${isEditMode ? 'Updating' : 'Creating'} Group`}
        caption='Please wait..'
        loading={isLoading}
      />
    </div>
  )
}

const handleGroupCreate = async ({ name, description }) => {
  const payload = {
    ObjectMeta: {
      Name: name
    },
    Spec: {
      Description: description
    }
  }

  return await createGroup(payload)
}

const handleGroupUpdate = async ({ description, group }) => {
  const payload = structuredClone(group)
  payload.Spec.Description = description
  return await updateGroup(payload)
}

export { CreateGroupModal }
