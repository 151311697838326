import { faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Button, SimpleDropDown, TagRow, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { TagInfo } from './components/TagInfo'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { TagPolicyCreateModal } from './components/TagPolicyCreateModal'
import { DeleteTagModal } from './components/DeleteTagPolicyModal'

function TagPolicy() {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false)
  const [selectedTag, setSelectedTag] = useState(null)

  const {
    slices: { tagPolicys }
  } = useMultiSlice(['tagPolicys'])

  if (selectedTag === null && tagPolicys.length) setSelectedTag(tagPolicys[0])

  return (
    <div className='flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        <div className='flex justify-between'>
          <Typography variant='h4-regular'>Select Tag</Typography>
          <Button onClick={() => setShowCreateModal(true)} icon={faPlus} variant='primary'>
            Add New
          </Button>
        </div>
        <div className='flex justify-between mt-7 mb-5'>
          <Typography variant='body-regular'>{tagPolicys.length} Tags</Typography>
        </div>
        {tagPolicys.map((e) => {
          const tags = Object.keys(e.KindMap.Map)
          return (
            <div className='mb-4'>
              <TagRow
                key={createRsrcKey(e)}
                customTagsAmount={0}
                icon={<FontAwesomeIcon color='#545B71' icon={faTag} size='lg' />}
                onClick={() => setSelectedTag(e)}
                tags={tags}
                title={getResourceName(e)}
              />
            </div>
          )
        })}
      </div>
      <div className='w-1/2 h-[600px]'>
        {!selectedTag && (
          <div className='bg-[#F9FBFC] h-[100%] flex justify-center items-center flex-col'>
            <FontAwesomeIcon color='#545B71' icon={faTag} size='5x' />
            <Typography className='mt-6' variant='body-regular'>
              Select tag to display information
            </Typography>
          </div>
        )}
        {selectedTag && (
          <TagInfo
            onDelete={() => {
              setShowDeleteTagModal(true)
            }}
            tag={selectedTag}
          />
        )}
        {showCreateModal && (
          <TagPolicyCreateModal
            onCancel={() => setShowCreateModal(false)}
            onSuccess={() => setShowCreateModal(false)}
          />
        )}
        {showDeleteTagModal && (
          <DeleteTagModal
            tag={selectedTag}
            onCancel={() => setShowDeleteTagModal(false)}
            onSuccess={() => {
              setShowDeleteTagModal(false)
              setSelectedTag(null)
            }}
          />
        )}
      </div>
    </div>
  )
}

export { TagPolicy }
