import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { CheckboxFilters } from 'V2Components'
import React, { useMemo } from 'react'
import _ from 'lodash'

function TargetsFilters({
  activeFilters,
  onActiveFilterChange,
  grantedCount = 0,
  unGrantedCount = 0,
  bookmarkCount = 0,
  managedCount = 0,
  unmanagedCount = 0,
  showManagedFilters = true
}) {
  const { slices } = useMultiSlice(['kubeClusters'])

  const checkboxFilterItems = useMemo(() => {
    const items = []
    if (showManagedFilters)
      items.push({
        label: 'Managed',
        filters: [
          {
            label: 'Managed',
            amount: managedCount,
            value: 'managed',
            checked: true
          },
          {
            label: 'Un-Managed',
            amount: unmanagedCount,
            value: 'unmanaged',
            checked: true
          }
        ]
      })
    items.push(
      {
        label: 'Access Status',
        filters: [
          {
            label: 'Granted',
            amount: grantedCount,
            value: 'granted',
            checked: true
          },
          {
            label: 'Ungranted',
            amount: unGrantedCount,
            value: 'ungranted',
            checked: false
          },
          {
            label: 'Bookmarked',
            amount: bookmarkCount,
            value: 'bookmarked',
            checked: true
          }
        ]
      },
      {
        label: 'Targets Types',
        filters: [
          {
            label: 'AppRole',
            value: 'AppRole',
            checked: activeFilters.includes('AppRole')
          },
          {
            label: 'Server',
            value: 'Server',
            checked: activeFilters.includes('Server')
          },
          {
            label: 'Database',
            value: 'Database',
            checked: activeFilters.includes('Database')
          },
          {
            label: 'RDP Server',
            value: 'RDPServer',
            checked: activeFilters.includes('RDPServer')
          },
          {
            label: 'Kafka',
            value: 'Kafka',
            checked: activeFilters.includes('Kafka')
          },
          {
            label: 'Kube Clusters',
            value: 'KubeCluster',
            checked: activeFilters.includes('KubeCluster')
          },
        ]
      }
    )

    return items
  }, [slices, activeFilters, grantedCount, unGrantedCount, bookmarkCount])

  return (
    <CheckboxFilters
      title={''}
      filterItems={checkboxFilterItems}
      activeFilters={activeFilters}
      onActiveFilterChange={onActiveFilterChange}
    />
  )
}

export { TargetsFilters }
