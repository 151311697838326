import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal } from 'V2Components'
import { useApplicationAccess } from 'features/applications'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  WorkSpaceDetailProvider,
  useAppDetailsContext
} from 'features/databricks'
import { AccessCartProvider, createRsrcKey, useAccessCartProvider } from 'features/resources'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { AddCloudStore } from '../../../snowflake/views/AddCloudStore'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

const useSlices = createDataSelectorHook([
  'applicationList',
  'dataBricksAccounts',
  'dataBricksResources',
  'dataBricksWorkSpaces'
])

function WorkSpacedetails({}) {
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const history = useHistory()
  const { appView } = useAppView()
  const { slices } = useSlices()
  const { app } = useAppDetailsContext()
  const signInAllowed = true
  const canGoBack = useCanGoBack()
  const pollingInterval = useRef(null)
  const [showScanning, setShowScanning] = useState(false)
  const { getApplicationRsrcsAccessMap } = useApplicationAccess()
  const accessObject = getApplicationRsrcsAccessMap()
  const [updateState, setUpdateState] = useState(false)
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false)

  useEffect(() => {
    return () => {
      clearInterval(pollingInterval.current)
    }
  }, [])

  useEffect(() => {
    const runCheckScanningStatus = async () => {
      await checkScaningStatus()
    }
    runCheckScanningStatus()
  }, [updateState])

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const getParentApp = () => {
    const currentDBAccount = _.find(slices.dataBricksAccounts, {
      ObjectMeta: { ID: app?.Account?.RefID }
    })

    const appAccount = _.find(slices.applicationList, {
      ObjectMeta: { ID: currentDBAccount?.Application?.RefID }
    })

    return appAccount?.ObjectMeta.Name
  }

  const checkScaningStatus = async () => {
    try {
      if (app) {
        if (app?.Status?.Status?.Status === 'started') {
          setShowScanning(true)

          await reduxApiClient('databricksworkspaces').update(app)
          pollingInterval.current = setInterval(async () => {
            const currentAccount = await reduxApiClient('databricksworkspaces').getAll({})
            currentAccount?.find((obj) => obj.ObjectMeta.ID === app.ObjectMeta.ID)

            if (app?.Status?.Status?.Status === 'success' || app?.Status?.Status?.Status === '') {
              clearInterval(pollingInterval.current)
              await reduxApiClient('applications').getAll({})
              enqueueNotification('Sync updated', 'info')
              setShowScanning(false)
            }
          }, 10000)
        } else {
          setShowScanning(false)
        }
      } else {
        setShowScanning(false)
      }
    } catch (error) {
      setShowScanning(false)
    }
  }

  const getSignInStatus = () => {
    let snApp
    if (appView === 'user') {
      const snowflakeRole = accessObject && Object.keys(accessObject).map((obj) => obj.split('+'))
      if (snowflakeRole) {
        snowflakeRole.find((role) => {
          const roleObj = slices.snowFlakeRoles.find((snRole) => snRole.ObjectMeta.ID === role[1])

          const snAccount =
            roleObj &&
            slices.snowFlakeAccounts.find(
              (snAccount) => snAccount.ObjectMeta.ID === roleObj?.Spec?.SnowflakeAccount?.RefID
            )
          snApp =
            snAccount &&
            slices.applicationList.find(
              (apps) => apps.ObjectMeta.ID === snAccount?.Application?.RefID
            )
        })
      }
      if (createRsrcKey(snApp) === createRsrcKey(app)) {
        return true
      }
    } else {
      return signInAllowed
    }
  }

  const handleSync = async () => {
    setShowScanning(true)
    const currentAccount = await reduxApiClient('databricksworkspaces').getAll({})
    let filteredAccount = currentAccount?.find((obj) => obj.ObjectMeta.ID === app.ObjectMeta.ID)

    if (filteredAccount?.Status?.Status) {
      filteredAccount = {
        ...filteredAccount,
        Status: {
          ...filteredAccount.Status,
          Status: {
            Status: 'started',
            Error: ''
          }
        }
      }

      await reduxApiClient('databricksworkspaces').update(filteredAccount)
      let pollcount = 0
      const checkSyncStatus = async () => {
        const currentAccount = await reduxApiClient('databricksworkspaces').getAll({})
        pollcount = pollcount + 1
        const filteredAccount = currentAccount?.find(
          (obj) => obj.ObjectMeta.ID === app.ObjectMeta.ID
        )
        if (filteredAccount.Status.Status.Status === 'success') {
          clearInterval(pollingInterval.current)
          enqueueNotification('Sync updated', 'info')
          setUpdateState(true)
          setShowScanning(false)
        }
      }

      pollingInterval.current = setInterval(checkSyncStatus, 10000)
    } else {
      setShowScanning(false)
    }
  }

  return (
    <div>
      <Header
        showSignIn={getSignInStatus()}
        app={app}
        onSync={handleSync}
        showScanning={showScanning}
        isSyncDisabled={false}
        parentAppName={getParentApp()}
      />
      <div className='mt-6'>
        <Tabs />
      </div>
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            if (canGoBack) history.goBack()
            else history.push(`/${appView}/datacloud`)
          }}
          onCancel={() => setShowDeleteAppModal(false)}
          app={app}
        />
      )}
      {showEditAppModal && (
        <FullScreenContentModal>
          <AddCloudStore
            type=''
            onComplete={() => setShowEditAppModal(false)}
            app={app}
            onClose={() => setShowEditAppModal(false)}
          />
        </FullScreenContentModal>
      )}

      {showCreateRoleModal && (
        <CreateRoleModal
          showCreateRoleModal={showCreateRoleModal}
          setShowCreateRoleModal={setShowCreateRoleModal}
        />
      )}

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
    </div>
  )
}

const WrappedApplicationDetails = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <WorkSpaceDetailProvider>
      <AccessCartProvider key={cartKey} cartKey={cartKey}>
        <WorkSpacedetails />
      </AccessCartProvider>
    </WorkSpaceDetailProvider>
  )
}

export { WrappedApplicationDetails as WorkSpacedetails }
