import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import classNames from 'classnames'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid ' + theme.palette.grey[300],
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    cursor: 'pointer'
  },
  noBorder: {
    borderBottom: 'none'
  },
  text: {
    fontWeight: 'normal'
  },
  primary: {
    fill: theme.palette.primary.main
  },
  info: {
    fill: theme.palette.info.main
  },
  secondary: {
    fill: theme.palette.primary.light
  },
  error: {
    fill: theme.palette.error.main
  },
  success: {
    fill: theme.palette.success.main
  },
  warning: {
    fill: theme.palette.warning.main
  }
}))

const AlertLogItem = ({ onClick, metaData = null, Icon, Text = null, color = 'default', disableBorder = false, variant = 'secondary' }) => {
  const classes = useStyles()

  const handleClick = () => {
    onClick?.(metaData)
  }

  return (
    <div onClick={handleClick} className={classNames(classes.root, { [classes.noBorder]: disableBorder })}>
      <Grid container alignItems='center'>
        <Grid alignItems='center' xs={11} container>
          {Icon && (
            <Grid xs={1} item>
              <Icon className={classes[variant]} color={color} />
            </Grid>
          )}
          <Grid xs={11} item>
            <CustomTypo className={classes.text} variant='body2'>{Text}</CustomTypo>
          </Grid>
        </Grid>
        <Grid xs={1} item>
          <IconButton>
            <ChevronRight fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default AlertLogItem
