import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faRefresh } from '@fortawesome/pro-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { CopyText, LabelContent } from 'V2Components'
import { getResourceName } from 'features/resources'
import { UpdateWorkloadKeysModal } from 'features/workloads'
import moment from 'moment'
import { Button, IconButton, Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useEffect, useState } from 'react'
import { ManageWorkloadAccounts } from '../ManageWorkloadAccounts'

const OverviewTab = () => {
  const [showUpdateKeysModal, setShowUpdateKeysModal] = useState(false)
  const [workloadCredentials, setWorkloadCredentials] = useState({
    key: '',
    secret: '',
    notAfter: '',
    isLoaded: false
  })
  const workload = useDataDetailsContext()

  const { selectDispatch, getObjectRef } = useMultiSlice(['userList'])

  if (!workloadCredentials.isLoaded && workload) {
    setWorkloadCredentials({
      key: workload.Status.ClientId,
      secret: workload.Status.ClientSecret,
      notAfter: workload.Status.NotAfter,
      isLoaded: true
    })
  }

  const createdBy = getObjectRef(workload.CreatedBy)

  useEffect(() => {
    selectDispatch(['accountList', 'gcpResources', 'projects', 'userList'])
  }, [])

  return (
    <div>
      <div className='flex gap-4'>
        <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
          <Typography className='mb-8' variant='h4-regular'>
            Workload Information
          </Typography>
          <LabelContent title='Name' content={getResourceName(workload)} />
          <LabelContent title='Description' content={workload.Spec.Description} />
          <LabelContent title='Creator' content={getResourceName(createdBy)} showEmptyLabel />
          <LabelContent
            title='Created On'
            content={moment(workload.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')}
            showEmptyLabel
          />

          <Typography className='my-8' variant='h4-regular'>
            For Developers
          </Typography>
          <LabelContent
            title='API Key'
            content={
              <>
                <CopyText
                  showText
                  messageKey='API Key'
                  text={workloadCredentials.key}
                  Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
                />
              </>
            }
          />
          <LabelContent
            title='API Secret'
            content={
              <div className='flex gap-4 justify-between items-center'>
                ********** ********** ********** **********
                <CopyText
                  showText={false}
                  messageKey='API Secrets'
                  text={workloadCredentials.secret}
                  Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
                />
              </div>
            }
          />
          <LabelContent
            content={moment(workloadCredentials.notAfter).format('MMMM Do YYYY, h:mm A')}
            title='Expires'
          />
          <div className='mt-4 flex justify-end'>
            <Button onClick={() => setShowUpdateKeysModal(true)} variant='gray' icon={faRefresh}>
              Update Keys
            </Button>
          </div>
        </div>
        <div className='w-1/2'>
          <ManageWorkloadAccounts workload={workload} />
        </div>
      </div>
      {showUpdateKeysModal && (
        <UpdateWorkloadKeysModal
          workload={workload}
          onCancel={() => setShowUpdateKeysModal(false)}
          cleanUpFn={() => setShowUpdateKeysModal(false)}
        />
      )}
    </div>
  )
}

export { OverviewTab }
