import { cn } from 'Utils/Helpers'
import { Button, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const NameIDFormats = ['TRANSIENT', 'PERSISTENT', 'EMAIL']

function SAMLApplicationForm({ onCancel, onComplete }) {
  const [applicationName, setApplicationName] = useState('')
  const [ssoURL, setSsoURL] = useState('')
  const [ssoMetadata, setSsoMetadata] = useState('')
  const [nameIDFormat, setNameIDFormat] = useState(NameIDFormats[0])

  const getErrors = () => {
    return ''
  }

  const handleSubmit = async () => onComplete?.()

  const formErrors = getErrors()

  return (
    <div>
      <Typography variant='body-regular'>
        Add a SAML Application by providing friendly application name, SSO URL and SSO metadata URL
        or content
      </Typography>
      <div>
        <div className='mt-4'>
          <TextInput
            value={applicationName}
            onChange={(e) => setApplicationName(e.target.value)}
            sx={{ width: '100%' }}
            label='Application Name'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={ssoURL}
            onChange={(e) => setSsoURL(e.target.value)}
            sx={{ width: '100%' }}
            label='SSO URL'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={ssoMetadata}
            onChange={(e) => setSsoMetadata(e.target.value)}
            sx={{ width: '100%' }}
            label='SSO Metadata URL'
          />
        </div>
        <div className='mt-4'>
          <Typography className='mb-1' variant='body-regular'>
            Name ID Format
          </Typography>
          <SelectDropDown
            showHelpText={false}
            className='!w-[200px]'
            menuItems={NameIDFormats.map((e) => ({
              label: `${e[0]}${e.toLowerCase().slice(1)}`,
              value: e
            }))}
            onChange={(e) => setNameIDFormat(e.target.value)}
            value={nameIDFormat}
          />
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            Create
          </Button>
        </div>
      </div>
    </div>
  )
}

export { SAMLApplicationForm }
