import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { AlertLogs } from 'Components/AlertLogs'
import { Apps, Mouse, NewReleasesSharp, Input } from '@material-ui/icons'
import { chipsItems, logItems } from './metaData'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import ResourcesIcon from 'Components/CustomIcons/Resources'
import CONSTANTS from 'Utils/Constants'
import { useSelector } from 'react-redux'
import { isRequestPendingOnUsersEnd, prepareAdminApprovalReqList } from 'Utils/PureHelperFuctions'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const Tasks = ({}) => {
  const user = useSelector((state) => state.user)
  const classes = useStyles()
  const history = useHistory()
  const onLogClick = (item) => {
    console.log('onLogClick', item)
    history.push('/admin/approval-request/' + item.Name)
  }
  const { slices } = useMultiSlice(['approvalReqList', 'appRolesList', 'userList'])
  const [chips, logs] = useMemo(() => {
    const chips = {
      Target: {
        Icon: ResourcesIcon,
        label: 0,
        color: 'default',
        variant: 'error'
      },
      IAMAction: {
        Icon: NewReleasesSharp,
        label: 0,
        color: 'default',
        variant: 'info'
      },
      App: {
        Icon: Apps,
        label: 0,
        color: 'default',
        variant: 'success'
      }
    }
    const formatted = prepareAdminApprovalReqList(
      slices.approvalReqList || [],
      slices.appRolesList || [],
      slices.userList || [],
      user,
      slices.groupList || [],
      slices.serverList || [],
      slices.iamActions || [],
      slices.applicationList || []
    )
    const logs = []
    const formattedTypes = {
      Target: 'Targets',
      IAMAction: 'Iam Roles',
      App: 'Applications'
    }
    formatted.forEach((request) => {
      const chip = chips[request.Type]
      if (chip) chip.label++
      const requestFor = request.collapsable.requestedFor.map((e) => e.name).join(', ')
      logs.push({
        Icon: chip ? chip.Icon : Input,
        Text: `${request.user.name} has requested access to ${request.resources.length} ${
          formattedTypes[request.Type]
        } for ${requestFor}. Go to Request Approvals to approve or reject access.`,
        variant: chip ? chip.variant : 'warning',
        metaData: { Name: request.Name.Name }
      })
    })
    return [Object.values(chips), logs]
  }, [slices.approvalReqList, user, slices.user])
  return (
    <div className={classes.root} id='intro-js-tasks'>
      <AlertLogs Title='Tasks' Icon={Mouse} onLogClick={onLogClick} chips={chips} logs={logs} />
    </div>
  )
}

export default Tasks
