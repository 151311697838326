import { SearchInput } from 'V2Components'
import { createDataSelectorHook } from 'infra/redux'
import { Button, IamRolesCard, IconButton, Label, TabGroup, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  useAppDetailsContext
} from 'features/applications'
import { createRef, useAccessCartProvider } from 'features/resources'
import useAppView from 'Core/Hooks/useAppView'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { getUserInfo } from 'Utils/Helpers'
import { Grid } from '@material-ui/core'

const useSlices = createDataSelectorHook([
  'salesForceUsersList',
  'salesForcePermissionList',
  'salesForceAccountList',
  'salesForceUserPermissionBinding'
])

const SFPermission = () => {
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [searchKey, setSearchKey] = useState('')
  const { appView } = useAppView()
  const user = getUserInfo()
  const [permissionList, setPermissionList] = useState([])
  const [filterPermissionList, setFilteredPermissionList] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [activeButton, setActiveButton] = useState('Profile')
  const {
    addItemsToCart,
    isResourceInCart,
    isCartEmpty,
    removeFromCart,
    clearCartItems,
    cartItems
  } = useAccessCartProvider()
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })

  useEffect(() => {
    getPermissionList(activeButton)
  }, [searchKey])

  useEffect(() => {
    getFilteredUsersList()
    getPermissionList(activeButton)
  }, [slices.salesForcePermissionList, activeButton, slices.salesForceUsersList])

  const getActivePermission = (permission) => {
    switch (permission) {
      case 'Profile':
        return 'PROFILE'
      case 'Set':
        return 'PERMISSION_SET'
      case 'Groups':
        return 'PERMISSION_SET_GROUP'
    }
  }

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const handleRoleActionClick = (permission) => {
    if (activeButton === 'Set' && appView === 'user') {
      if (isResourceInCart(createRef(permission))) {
        return removeFromCart(createRef(permission))
      }
      addItemsToCart({
        resourceRef: createRef(permission),
        principal: '',
        roles: []
      })
      setSelectedPermissions(permission)
    } else {
      if (selectedPermissions === permission) {
        // If the clicked permission is already selected, unselect it
        clearCartItems()
        setSelectedPermissions(null)
      } else {
        // Otherwise, select the clicked permission
        if (isResourceInCart(createRef(permission)) || !isCartEmpty) return
        addItemsToCart({
          resourceRef: createRef(permission),
          principal: '',
          roles: []
        })
        setSelectedPermissions(permission)
      }
    }

    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: false
      })
    } else {
      if (activeButton === 'Set') {
        handleViewsChange({
          accessRequestSubmissionModal: false
        })
      } else {
        handleViewsChange({
          accessRequestSubmissionModal: true
        })
      }
    }
  }

  const getPermissionList = (type) => {
    const data = getFilteredUsersList()
    const permissionList = []
    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account.Application.RefID === app.ObjectMeta.ID
    )
    if (data.length) {
      let list = slices.salesForcePermissionList
        .filter(
          (prlist) => prlist?.SalesforceAccount?.RefID === filterCurrentAccount?.ObjectMeta?.ID
        )
        .filter((permission) => getActivePermission(type) === permission?.Type)

      // in user view show only matching license permissions
      if ((type === 'Profile' || type === 'Set') && appView === 'user') {
        const filterUserPermission = list.find(
          (permission) => permission?.ObjectMeta?.ID === data[0].Permission?.RefID
        )

        const filterMatchingLicensePermission = list.filter(
          (permission) =>
            permission.License.RefID === filterUserPermission?.License?.RefID &&
            permission.ObjectMeta.ID !== filterUserPermission?.ObjectMeta?.ID
        )

        if (type === 'Set') {
          const setPermissionFilter = list.filter(
            (permission) =>
              permission.ObjectMeta.ID !== filterUserPermission?.ObjectMeta?.ID &&
              permission.License.RefKind === ''
          )
          permissionList.push(...setPermissionFilter)
        } else {
          permissionList.push(...filterMatchingLicensePermission)
        }
      } else {
        permissionList.push(...list)
      }

      if (searchKey || searchKey.trim() !== '') {
        const filterSearch = permissionList.filter((permission) =>
          permission?.EntityName.toLowerCase().includes(searchKey.toLocaleLowerCase())
        )
        setPermissionList(filterSearch)
        setFilteredPermissionList(filterSearch)
      } else {
        setPermissionList(permissionList)
        setFilteredPermissionList(permissionList)
      }
    }
  }

  const handleTabClick = (type) => {
    getPermissionList(type)
    setActiveButton(type)
    clearCartItems()
    setSelectedPermissions(null)
  }

  const getProfilePermission = () => {
    const data = getFilteredUsersList()
    let filterAssigneePermission
    let [_, time] = ''

    if (data.length) {
      const filterUserPermission = slices.salesForcePermissionList.find(
        (permission) => permission?.ObjectMeta?.ID === data[0].Permission?.RefID
      )

      if (data[0]?.Assignee?.ExpirationDate !== '') {
        filterAssigneePermission = slices.salesForcePermissionList.find(
          (permission) => permission?.ObjectMeta?.ID === data[0]?.Assignee?.Permission?.RefID
        )

        const utcTime = new Date(data[0].Assignee?.ExpirationDate)
        const convertedTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
        ;[_, time] = convertedTime.split(', ')
      }

      return (
        <div className='gird gap-2'>
          <Label
            style={{ marginBottom: '5px' }}
            variant='grayBlue'
            text={`Default : ${filterUserPermission?.EntityName}`}
          />
          {filterAssigneePermission && (
            <div className='flex gap-1'>
              <Label
                variant='grayBlue'
                text={`Assignee : ${filterAssigneePermission?.EntityName}`}
              />
              <Label text={`Expire : ${time}`} variant='danger' />
            </div>
          )}
        </div>
      )
    }
  }

  const getGroupPermission = () => {
    const data = getFilteredUsersList()
    const SFPermissionList = []

    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
    )

    if (data.length) {
      const filterGroupPermission = slices.salesForceUserPermissionBinding.filter(
        (set) =>
          set.Type === 'PERMISSION_SET_GROUP' &&
          set.User.RefID === data[0].ObjectMeta.ID &&
          set.SalesforceAccount.RefID === filterCurrentAccount.ObjectMeta.ID
      )

      filterGroupPermission.forEach((PerGroupList) => {
        const permissionRefID = PerGroupList.Permission.RefID
        const permissionObject = slices.salesForcePermissionList.find(
          (permission) => permission.ObjectMeta.ID === permissionRefID
        )
        SFPermissionList.push(permissionObject)
      })

      return (
        <div>
          {SFPermissionList.map((permission) => (
            <div
              key={permission.ObjectMeta.ID}
              className='flex gap-1 my-4 justify-between border-b border[#D8DDE4] pb-2'
            >
              <Typography variant='body-semiBold' style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  disabled
                  style={{ marginRight: '5px' }}
                  icon={faEdit}
                  variant={'grayBlue'}
                />
                {permission?.EntityName}
              </Typography>
              {getExpiry(permission)}
            </div>
          ))}
        </div>
      )
    }
  }

  const getSetPermission = () => {
    const data = getFilteredUsersList()
    const SFPermissionList = []

    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
    )

    if (data.length) {
      const filterGroupPermission = slices.salesForceUserPermissionBinding.filter(
        (set) =>
          set.Type === 'PERMISSION_SET' &&
          set.User.RefID === data[0].ObjectMeta.ID &&
          set.SalesforceAccount.RefID === filterCurrentAccount.ObjectMeta.ID
      )

      filterGroupPermission.forEach((PerGroupList) => {
        const permissionRefID = PerGroupList.Permission.RefID
        const permissionObject = slices.salesForcePermissionList.find(
          (permission) => permission.ObjectMeta.ID === permissionRefID
        )
        SFPermissionList.push(permissionObject)
      })

      return (
        <div>
          {SFPermissionList.map((permission) => (
            <div
              key={permission.ObjectMeta.ID}
              className='flex gap-1 my-4 justify-between border-b border[#D8DDE4] pb-2'
            >
              <Typography variant='body-semiBold' style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  disabled
                  style={{ marginRight: '5px' }}
                  icon={faEdit}
                  variant={'grayBlue'}
                />
                {permission?.EntityName}
              </Typography>
              {getExpiry(permission)}
            </div>
          ))}
        </div>
      )
    }
  }

  const getExpiry = (data) => {
    const getReversePermissionBind = slices.salesForceUserPermissionBinding.find(
      (item) => item.Permission.RefID === data.ObjectMeta.ID
    )
    const utcTime = new Date(getReversePermissionBind?.ExpirationDate)
    const convertedTime = utcTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
    const [date, time] = convertedTime.split(', ')

    if (time) {
      return <Label text={`Expire : ${time}`} variant='danger' />
    }

    return ''
  }

  const getFilteredUsersList = () => {
    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account?.Application?.RefID === app?.ObjectMeta?.ID
    )
    const filterCurrentAccountUsers = slices.salesForceUsersList?.filter(
      (user) => user?.SalesforceAccount?.RefID === filterCurrentAccount?.ObjectMeta?.ID
    )

    if (appView === 'user') {
      const filterLogedInUser = filterCurrentAccountUsers.filter(
        (sfuser) => sfuser.UserName === user.name
      )
      return filterLogedInUser
    }
    return filterCurrentAccountUsers
  }

  const getGroupsList = (permission) => {
    const permissionSet = permission?.PermissionSet?.ObjectRef
    const matchingPermissionArray = []
    if (permissionSet.length) {
      permissionSet.forEach((set) => {
        const setRefID = set.RefID
        const matchingPermission = slices.salesForcePermissionList.filter(
          (list) => list.ObjectMeta.ID === setRefID
        )
        matchingPermissionArray.push(...matchingPermission)
      })
    }

    return matchingPermissionArray.map((action) => (
      <Typography style={{ marginBottom: '3px' }} variant='caption-regular'>
        {action.EntityName}
      </Typography>
    ))
  }

  const getSelectedStatus = (permission) => {
    if (isResourceInCart(createRef(permission))) return true
    return false
  }

  return (
    <div className='flex'>
      <div className='w-[800px] border-r border[#D8DDE4] pr-8'>
        <Typography className={'mb-4'} variant='h3'>
          Select Permission
        </Typography>
        <div className='flex justify-between border-b-[1px] pb-2'>
          <div className='flex gap-1'>
            <Button
              variant={activeButton === 'Profile' ? 'primary' : 'gray'}
              size='sm'
              onClick={() => handleTabClick('Profile')}
            >
              Profile
            </Button>
            <Button
              variant={activeButton === 'Groups' ? 'primary' : 'gray'}
              size='sm'
              onClick={() => handleTabClick('Groups')}
            >
              Groups
            </Button>
            <Button
              variant={activeButton === 'Set' ? 'primary' : 'gray'}
              size='sm'
              onClick={() => handleTabClick('Set')}
            >
              Set
            </Button>
          </div>

          <div className='flex gap-2'>
            <SearchInput searchKey={searchKey} sx={{ width: '250px' }} onChange={setSearchKey} />
          </div>
        </div>

        <div className='mt-5 pb-10'>
          {filterPermissionList &&
            filterPermissionList.map((e, index) => (
              <IamRolesCard
                style={{
                  marginBottom: '10px'
                }}
                key={index}
                description={e.Description === '' ? 'Description' : e.Description}
                selected={getSelectedStatus(e)}
                assigned={getSelectedStatus(e)}
                onClickActionButton={() => handleRoleActionClick(e)}
                title={e.EntityName}
                showMoreButton={activeButton === 'Groups'}
              >
                {activeButton === 'Groups' && (
                  <div className={'mt-4'}>
                    <Grid spacing={1} container>
                      <Grid xs={8} item>
                        <Typography variant='body-semiBold' style={{ marginBottom: '3px' }}>
                          Existing permission list
                        </Typography>
                        {getGroupsList(e)}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </IamRolesCard>
            ))}
        </div>
      </div>
      {appView === 'user' && (
        <div className='w-1/2 px-8'>
          <Typography style={{ textAlign: 'left', marginBottom: '15px' }} variant='h3'>
            Existing Permissions
          </Typography>

          <TabGroup
            tabs={[
              {
                label: 'Profile',
                tabContent: getProfilePermission()
              },
              {
                label: 'Group',
                tabContent: getGroupPermission()
              },
              {
                label: 'Set',
                tabContent: getSetPermission()
              }
            ]}
          />
        </div>
      )}
      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
    </div>
  )
}

export { SFPermission }
