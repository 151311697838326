import store from 'infra/redux/store'
import { Spinner } from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { status } from 'infra/redux/reducers'

const Loader = () => {
  const [loading, setLoading] = useState(false)
  const unsubscribe = useRef(null)

  if (!unsubscribe.current) {
    unsubscribe.current = store.subscribe(() => {
      const state = store.getState()
      const data = Object.keys(state).every((key) => {
        const storeStatus = state[key].status
        if (storeStatus === status.LOADING) return false
        return true
      })
      setLoading(!data)
    })
  }

  useEffect(() => {
    return unsubscribe.current
  }, [])

  // return <div className='fixed bottom-10 z-50 right-4'>{loading && <Spinner />}</div>
}

export default Loader
