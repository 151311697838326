import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const ECS = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 40 40' {...props}>
      <svg className='w-6 h-6' height='40' width='40' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient x1='0%' y1='100%' x2='100%' y2='0%' id='Arch_Amazon-Elastic-Container-Service_32_svg__a'>
            <stop stop-color='#C8511B' offset='0%' />
            <stop stop-color='#F90' offset='100%' />
          </linearGradient>
        </defs>
        <g fill='none' fill-rule='evenodd'>
          <path d='M0 0h40v40H0z' fill='url(#Arch_Amazon-Elastic-Container-Service_32_svg__a)' />
          <path
            d='M32 24.25l-4-2.357v-6.068a.492.492 0 00-.287-.444L22 12.736V8.285l10 4.897V24.25zm.722-11.811l-11-5.387a.504.504 0 00-.485.022.49.49 0 00-.237.417v5.557c0 .19.111.363.287.444L27 16.136v6.035c0 .172.091.332.243.42l5 2.947a.501.501 0 00.757-.42v-12.24a.49.49 0 00-.278-.44zM19.995 32.952L9 27.317V13.169l9-4.849v4.442l-4.746 2.636a.488.488 0 00-.254.427v8.842a.49.49 0 00.258.43l6.5 3.515a.508.508 0 00.482.001l6.25-3.371 3.546 2.33-10.041 5.38zm6.799-8.693a.51.51 0 00-.519-.022L20 27.622l-6-3.245v-8.265l4.746-2.637a.489.489 0 00.254-.427V7.49a.489.489 0 00-.245-.422.512.512 0 00-.496-.01l-10 5.388a.49.49 0 00-.259.43v14.737c0 .184.103.35.268.436l11.5 5.895a.52.52 0 00.471-.005l11-5.895a.486.486 0 00.039-.839l-4.484-2.947z'
            fill='#FFF'
          />
        </g>
      </svg>
      <svg className='w-6 h-6' height='40' width='40' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient x1='0%' y1='100%' x2='100%' y2='0%' id='Arch_Amazon-Elastic-Container-Service_32_svg__a'>
            <stop stop-color='#C8511B' offset='0%' />
            <stop stop-color='#F90' offset='100%' />
          </linearGradient>
        </defs>
        <g fill='none' fill-rule='evenodd'>
          <path d='M0 0h40v40H0z' fill='url(#Arch_Amazon-Elastic-Container-Service_32_svg__a)' />
          <path
            d='M32 24.25l-4-2.357v-6.068a.492.492 0 00-.287-.444L22 12.736V8.285l10 4.897V24.25zm.722-11.811l-11-5.387a.504.504 0 00-.485.022.49.49 0 00-.237.417v5.557c0 .19.111.363.287.444L27 16.136v6.035c0 .172.091.332.243.42l5 2.947a.501.501 0 00.757-.42v-12.24a.49.49 0 00-.278-.44zM19.995 32.952L9 27.317V13.169l9-4.849v4.442l-4.746 2.636a.488.488 0 00-.254.427v8.842a.49.49 0 00.258.43l6.5 3.515a.508.508 0 00.482.001l6.25-3.371 3.546 2.33-10.041 5.38zm6.799-8.693a.51.51 0 00-.519-.022L20 27.622l-6-3.245v-8.265l4.746-2.637a.489.489 0 00.254-.427V7.49a.489.489 0 00-.245-.422.512.512 0 00-.496-.01l-10 5.388a.49.49 0 00-.259.43v14.737c0 .184.103.35.268.436l11.5 5.895a.52.52 0 00.471-.005l11-5.895a.486.486 0 00.039-.839l-4.484-2.947z'
            fill='#FFF'
          />
        </g>
      </svg>
    </SvgIcon>
  )
})

export default ECS
