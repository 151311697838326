import { Chip, FormControl, Input, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      //   width: 1
    }
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 100,
    textAlign: 'center'
    // maxWidth: 300
  },
  outlined: {
    height: '2.5rem',
    transform: 'translateY(2px)'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 0
  },
  noLabel: {
    marginTop: theme.spacing(1)
  }
}))
const SingleSelectFilter = ({ filters = [], variant = 'default', filterOptions = false, data, onChange, name, label, filterType }) => {
  const options = filterOptions ||
  (data ? [...new Set(data.map(e => ({ name: e[name], label: e[name] })))] : [])
  const [value, setValue] = useState('-')
  useEffect(() => {
    const defaultValue = filters.find(e => e.name === name)
    if (defaultValue) {
      setValue(defaultValue.value)
    }
  }, [])
  const classes = useStyles()
  const handleChange = (event) => {
    const { value: tempValue } = event.target
    setValue(tempValue)
    onChange(tempValue, name, filterType)
  }
  return (
    <FormControl variant={variant} className={classNames(classes.formControl)}>
      <InputLabel id='demo-mutiple-chip-label'>{label}</InputLabel>
      <Select
        className={classNames({ [classes.outlined]: variant === 'outlined' })}
        labelId='demo-mutiple-chip-label'
        id='demo-mutiple-chip'
        value={value}
        onChange={handleChange}
        input={variant !== 'outlined' && <Input id='select-multiple-chip' />}
        label={label}
        MenuProps={MenuProps}
      >
        <MenuItem value='-'>
          All {label}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  )
}

export default SingleSelectFilter
