import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const AmazonIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 32 32' {...props}>
      <defs>
        <path id='4o9iixbtca' d='M0 0L14.59 0 14.59 17.017 0 17.017z' />
        <path id='zsd9isq33c' d='M0 0L24.817 0 24.817 5.477 0 5.477z' />
        <path id='zbqupxxipe' d='M0 0L5.083 0 5.083 4.898 0 4.898z' />
      </defs>
      <g fill='none' fill-rule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g transform='translate(-629.000000, -262.000000) translate(294.000000, 181.000000) translate(335.000000, 81.000000) translate(2.400000, 4.800000) translate(6.755253, 0.000000)'>
                  <mask id='qhgmcf4qyb' fill='#fff'>
                    <use xlinkHref='#4o9iixbtca' />
                  </mask>
                  <path fill='#070707' d='M8.982 9.943v-.854c0-.19-.046-.235-.233-.223-.263.016-.526.03-.788.05-.607.048-1.21.13-1.778.363-.735.302-1.306.781-1.562 1.557-.207.625-.202 1.267-.005 1.89.214.679.683 1.106 1.403 1.223.548.089 1.042-.072 1.496-.364.612-.394.962-.988 1.207-1.652.237-.643.243-1.317.26-1.99m4.31-1.823c-.006.842.017 1.915-.015 2.99-.027.954.304 1.8.906 2.54.093.115.185.231.266.354.215.328.189.568-.107.826-.416.365-.843.718-1.265 1.075-.293.248-.584.498-.881.741-.405.33-.641.331-1.054.007-.563-.441-.996-.998-1.393-1.586-.146-.217-.16-.219-.351-.034-.577.557-1.173 1.098-1.924 1.41-1.574.656-3.18.786-4.806.198-1.395-.505-2.194-1.525-2.503-2.948-.21-.965-.245-1.933.044-2.89.47-1.552 1.477-2.626 2.955-3.275.859-.377 1.766-.574 2.687-.717.981-.153 1.97-.24 2.958-.332.09-.008.17-.028.168-.136-.008-.503.037-1.009-.047-1.509-.16-.939-.608-1.408-1.54-1.59-.676-.132-1.321-.023-1.92.319-.541.309-.838.798-.992 1.388-.119.455-.286.584-.752.54-.89-.083-1.779-.175-2.667-.275-.442-.05-.605-.301-.514-.732.444-2.102 1.77-3.38 3.773-4.018 1.23-.393 2.493-.551 3.78-.422 1.16.117 2.282.379 3.278 1.017 1.056.676 1.637 1.661 1.825 2.888.099.642.086 1.289.09 1.935.003.668 0 1.336 0 2.236' mask='url(#qhgmcf4qyb)' />
                </g>
                <g transform='translate(-629.000000, -262.000000) translate(294.000000, 181.000000) translate(335.000000, 81.000000) translate(2.400000, 4.800000) translate(0.000000, 16.923442)'>
                  <mask id='uugtbk6bid' fill='#fff'>
                    <use xlinkHref='#zsd9isq33c' />
                  </mask>
                  <path fill='#F5A621' d='M13.846 5.472c-1.15-.014-2.073-.08-2.993-.195-.685-.085-1.364-.2-2.035-.352-.525-.119-1.043-.27-1.558-.432-.76-.238-1.497-.526-2.222-.855-1.226-.556-2.382-1.232-3.47-2.023C1.077 1.259.603.888.162.475.066.385-.05.29.023.127.085-.01.228-.037.4.049c.557.283 1.11.575 1.674.844 1.068.51 2.168.941 3.297 1.3.561.18 1.125.35 1.695.492.656.163 1.319.303 1.986.423.722.129 1.448.237 2.177.304 1.234.114 2.472.16 3.713.098.548-.027 1.095-.06 1.64-.111.6-.056 1.196-.137 1.788-.243.494-.088.987-.184 1.477-.3.53-.128 1.058-.26 1.58-.415.628-.185 1.248-.39 1.858-.625.294-.114.584-.246.886-.332.306-.088.527-.058.628.224.053.148-.012.253-.09.358-.14.188-.35.29-.54.413-.552.358-1.128.675-1.718.964-.723.353-1.47.651-2.232.909-.486.164-.975.315-1.47.439-.544.137-1.094.253-1.646.355-.572.105-1.146.183-1.723.235-.583.053-1.169.112-1.533.091' mask='url(#uugtbk6bid)' />
                </g>
                <g transform='translate(-629.000000, -262.000000) translate(294.000000, 181.000000) translate(335.000000, 81.000000) translate(2.400000, 4.800000) translate(22.117342, 16.410480)'>
                  <mask id='tf8sikmavf' fill='#fff'>
                    <use xlinkHref='#zbqupxxipe' />
                  </mask>
                  <path fill='#F5A621' d='M2.33 1.05c-.682-.018-1.338.088-1.998.142-.111.009-.249.056-.309-.072C-.05.965.065.871.186.792c.44-.289.918-.485 1.431-.607C2.224.041 2.84-.019 3.46.005c.452.019.907.059 1.331.243.197.085.295.235.293.448-.011 1.173-.317 2.256-.992 3.225-.196.281-.417.54-.665.777-.047.044-.1.083-.153.12-.099.071-.21.123-.314.03-.092-.082-.038-.178.002-.28.259-.669.536-1.332.717-2.027.066-.253.13-.51.14-.772.017-.454-.158-.55-.573-.655-.307-.077-.62-.068-.916-.063' mask='url(#tf8sikmavf)' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
})

export default AmazonIcon
