import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { isPolicyIssuedToUser } from 'features/policy'
import { createRef, createRsrcKey, isResourceManaged } from 'features/resources'
import { isAdminUser } from 'features/users'
import _ from 'lodash'
import { useCallback } from 'react'
import { TargetsSliceNames, getTargetsPolicyRules } from '../utils'

export const useTargetAccess = () => {
  const { slices } = useMultiSlice(['policyList', ...TargetsSliceNames, 'groupList'])

  /**
   * Returns all the targets which has policy created. Regardless of to whom the target is granted
   */
  const getAccessGrantedTargetsFromPolicy = useCallback(() => {
    const mapObj = {}
    slices.policyList.forEach((policy) => {
      const rules = _.flatten(Object.values(getTargetsPolicyRules(policy)))
      rules.forEach((rule) => {
        const key = `${rule.ObjectRef.RefKind}+${rule.ObjectRef.RefID}`
        // Don't add any iam actions - iam actions carry iam rsrcs
        if (rule.ObjectRef.RefKind === 'IamAction') return
        if (!mapObj[key]) mapObj[key] = true
      })
    })
    return mapObj
  }, [slices])

  const getAllTargetsMapObj = () => {
    const mapObj = {}
    TargetsSliceNames.forEach((sliceName) => {
      slices[sliceName].forEach((e) => {
        // Only managed resource are given access
        if (isResourceManaged(e)) mapObj[createRsrcKey(e)] = []
      })
    })
    return mapObj
  }

  /**
   * Get all the targets which the `user` has access to.
   *
   * Sample:
   * ```
   *    {
   *      'Server+1234': true
   *      'AppRole+6670': true
   *    }
   * ```
   */
  const getUserAccessTargetsMap = (user) => {
    const mapObj = {}
    const isAdmin = (() => {
      const adminGroupRef = createRef(_.find(slices.groupList, { ObjectMeta: { Name: 'admin' } }))
      return isAdminUser(user, adminGroupRef)
    })()

    /**
     * By default, admin users have access to all targets
     */
    if (isAdmin) return getAllTargetsMapObj()

    slices.policyList.forEach((policy) => {
      // Return if the policy is not issued to this user or if this is a iam resources policy
      if (!isPolicyIssuedToUser(policy, user) || policy.Type === 'IAMAction') return
      const AssumeRoleRules = policy.Spec.ActionMap.AssumeRole?.PolicyRule || []
      const SSHRules = policy.Spec.ActionMap.SSH?.PolicyRule || []
      const DBAccessRules = policy.Spec.ActionMap.DBAccess?.PolicyRule || []
      const KubeAccess = policy.Spec.ActionMap.KubeAccess?.PolicyRule || []
      const all = [...AssumeRoleRules, ...SSHRules, ...DBAccessRules, ...KubeAccess]
      all.forEach((rule) => {
        const key = `${rule.ObjectRef.RefKind}+${rule.ObjectRef.RefID}`
        // Don't add any iam actions - iam actions carry iam rsrcs
        if (rule.ObjectRef.RefKind === 'IamAction') return
        const policyRefs = mapObj[key] || []
        policyRefs.push(createRef(policy))
        mapObj[key] = policyRefs
      })
    })
    return mapObj
  }

  return { getAccessGrantedTargetsFromPolicy, getUserAccessTargetsMap }
}
