import useAppView from 'Core/Hooks/useAppView'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getIAMRoleTargetIconType, isCustomIAMRole, useIAMRoleAccess } from 'features/iamRoles'
import { createRsrcKey, getResourceName } from 'features/resources'
import { createDataSelectorHook, useReduxFetch } from 'infra/redux'

const useSlices = createDataSelectorHook(['iamActions'], { disableReduxFetch: true })

export const useIamRoles = ({ activeCloudType, searchKey, activeFilters }) => {
  const { slices } = useSlices()
  const { getIAMRoleAttachedPolicys, getIAMRoleAttachedRsrcs } = useIAMRoleAccess()
  const { appView } = useAppView()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (iamrole, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const name = getResourceName(iamrole)
      const description = iamrole.Spec.Description

      const all = `${name}${description}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const filteredIAMActions = () => {
    const fils = applySearchQuery(slices.iamActions)
    return fils.filter((role) => {
      if (role.Spec.CloudType !== activeCloudType) return
      if (activeFilters.length !== 2) {
        if (activeFilters.includes('Custom') && !isCustomIAMRole(role)) return
        if (activeFilters.includes('Default') && isCustomIAMRole(role)) return
      }
      return true
    })
  }

  const getCardData = (role) => {
    const data = {
      moreInfoRoute: `/${appView}/iam-roles/${role.ObjectMeta.Name}/overview`,
      key: createRsrcKey(role),
      name: getResourceName(role),
      description: role.Spec.Description,
      cloudType: role.Spec.CloudType,
      actionsCount: role.Spec.Actions.Elems.length,
      custom: isCustomIAMRole(role),
      iconType: getIAMRoleTargetIconType(role),
      policysCount: getIAMRoleAttachedPolicys(role).length,
      rsrcsCount: getIAMRoleAttachedRsrcs(role).length,
      role
    }
    return data
  }
  useReduxFetch(['iamActions'])

  return { getCardData, applySearchQuery, iamActions: filteredIAMActions() }
}
