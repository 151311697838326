import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const KMS = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 40 40' {...props}>
      <svg className='w-6 h-6' height='40' width='40' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <linearGradient x1='0%' y1='100%' x2='100%' y2='0%' id='Arch_AWS-Key-Management-Services_32_svg__a'>
            <stop stop-color='#BD0816' offset='0%' />
            <stop stop-color='#FF5252' offset='100%' />
          </linearGradient>
        </defs>
        <g fill='none' fill-rule='evenodd'>
          <path d='M0 0h40v40H0z' fill='url(#Arch_AWS-Key-Management-Services_32_svg__a)' />
          <path
            d='M26.73 26.497h4v-1h-4v1zm-5 0h4v-1h-4v1zm-5 0h4v-1h-4v1zm6-4a1.001 1.001 0 111 1c-.55 0-1-.449-1-1zm3 0c0-1.103-.896-2-2-2-1.101 0-2 .897-2 2s.899 2 2 2c1.104 0 2-.897 2-2zm-6 0a1.001 1.001 0 01-2 0 1.001 1.001 0 012 0zm-3 0c0 1.103.899 2 2 2 1.104 0 2-.897 2-2s-.896-2-2-2c-1.101 0-2 .897-2 2zm17-6.5v13a.5.5 0 01-.5.5h-16.5v-1h16v-12h-12v-1h12.5a.5.5 0 01.5.5zm-19.684 3.024a.5.5 0 00-.346.476V31.26l-1.304 1.063-1.165-1.963v-1.863a.504.504 0 00-.177-.383l-1.198-1.006 1.233-1.261a.498.498 0 00.142-.35v-1a.494.494 0 00-.147-.354l-1.23-1.223 1.206-1.046a.499.499 0 00.17-.377v-2a.5.5 0 00-.347-.477c-2.742-.868-4.358-3.712-3.758-6.616.428-2.072 2.02-3.733 4.06-4.232 1.73-.427 3.51-.055 4.879 1.017a5.577 5.577 0 012.15 4.414c0 2.32-1.792 4.649-4.168 5.418zm5.168-5.418a6.571 6.571 0 00-2.532-5.2c-1.611-1.263-3.7-1.699-5.736-1.203-2.411.591-4.295 2.554-4.8 5.002-.678 3.282 1.067 6.503 4.085 7.65v1.417l-1.437 1.249a.5.5 0 00-.024.732l1.46 1.455v.588l-1.465 1.501a.498.498 0 00.035.732l1.43 1.204v1.767c0 .09.026.178.072.255l1.53 2.581a.5.5 0 00.747.132l1.938-1.581a.5.5 0 00.183-.387V19.85c2.297-.894 4.514-3.249 4.514-6.247zm-6.608 1.301a1.39 1.39 0 010-2.778c.767 0 1.39.623 1.39 1.39a1.39 1.39 0 01-1.39 1.388zm0-3.778a2.391 2.391 0 00-2.388 2.39 2.391 2.391 0 002.388 2.388 2.392 2.392 0 002.39-2.388 2.393 2.393 0 00-2.39-2.39z'
            fill='#FFF'
          />
        </g>
      </svg>
    </SvgIcon>
  )
})

export default KMS
