import { getAWSResourceIcon } from 'Components/CustomIcons/AWSResources'
import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'
import { getGCPResourceIcon } from 'Components/CustomIcons/GCPResources'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { getAwsResourceTags, getIAMResourceDisplayType } from 'features/iamResources'
import {
  createRef,
  createRsrcKey,
  getResourceName,
  getResourcePolicyTags,
  getRsrcIcon
} from 'features/resources'
import { ptagFilterFn, TAG_DELIMITER, tagFilterFn } from 'features/tags'
import { getServerName } from 'features/targets'

export const rsrcsAdapters = {
  aws({ rsrc, account }) {
    const Icon = getAWSResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.Type.replaceAll('_', ' '),
      resourceName: getResourceName(rsrc),
      type: 'AWS',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kubeNamespaces({ rsrc, account }) {
    const Icon = getRsrcIcon(rsrc)

    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: rsrc.Spec.DisplayName,
      type: rsrc.Spec.Type,
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  azure({ rsrc, account }) {
    const Icon = getAZUREResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'AZURE',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  gcp({ rsrc, account }) {
    const Icon = getGCPResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'GCP',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  appRole({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'AppRole',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  server({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getServerName(rsrc),
      type: 'Server',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  database({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Database',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  rdpServer({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Database',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kafka({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Kafka',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kubeCluster({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: addSpaces(rsrc.ObjectMeta.Kind),
      resourceName: getResourceName(rsrc),
      type: 'KubeCluster',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  }
}

export const resourceAdapterMap = {
  AwsResource: rsrcsAdapters.aws,
  KubeNamespace: rsrcsAdapters.kubeNamespaces,
  GcpResource: rsrcsAdapters.gcp,
  AzureResource: rsrcsAdapters.azure,
  Database: rsrcsAdapters.database,
  RDPServer: rsrcsAdapters.rdpServer,
  AppRole: rsrcsAdapters.appRole,
  Server: rsrcsAdapters.server,
  Kafka: rsrcsAdapters.kafka,
  KubeCluster: rsrcsAdapters.kubeCluster
}
// =================================== // SEARCH FILTERS UTILS/ ===================================//

const getRrscSearchData = (rsrc, account) => {
  const { DisplayType, Region } = rsrc.Spec
  const dName = getIAMResourceDisplayType(rsrc)
  return `${getResourceName(rsrc)}${getResourceName(account)}${dName || ''}${DisplayType}${Region}`
}


export const rsrcSearchDataMatch = (rsrc, queryKey, data = { account: null }) =>
  getRrscSearchData(rsrc, data.account).toLowerCase().includes(queryKey.toLowerCase())
