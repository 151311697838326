import React from 'react'
import { Route } from 'react-router'
import { GroupDetails, GroupList } from '../views'

const GroupsRoutes = () => {
  return (
    <>
      <Route exact path={['/admin/groups']}>
        <GroupList />
      </Route>
      <Route exact path={['/admin/groups/:groupName']}>
        <GroupDetails />
      </Route>
    </>
  )
}

export { GroupsRoutes }
