import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core'
import { ApiProvider } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { LabelContent, LoadingFeedback } from 'V2Components'
import { ErrorLabelContent, getResourceName } from 'features/resources'
import _ from 'lodash'
import { Button, Label, SelectDropDown } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '74%'
  },
  selectOptions: {
    '& .MuiListItem-root': {
      borderTop: '1px solid rgb(3,15,252)',
      borderRadius: 8
    },

    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#f1f4fb',
      margin: '3px 0'
    },
    '& .MuiCheckbox-root': {
      color: '#1976d2'
    },
    '& .MuiCheckbox-colorSecondary': {
      '&.Mui-checked': {
        color: '#1976d2 !important'
      }
    }
  },
  missingPassword: {
    border: '2px solid red'
  }
}))

const KafkaResource = ({ rsrc, account }) => {
  const classes = useStyles()
  // MenuProps.PaperProps.className = classes.selectOptions;
  const [saving, setSaving] = useState('')
  const [dbMeshCluster, setDbMeshCluster] = useState((rsrc && rsrc.Spec.MeshCluster) || '')

  const { slices } = useMultiSlice(['medusaNodes'])
  const { selectDispatch } = useMultiDispatch([])
  const [selectedItems, setSelectedItems] = useState([])

  const { appView } = useAppView()
  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))

  const handleChange = (event) => {
    setSelectedItems(event.target.value)
  }

  const handleUserSave = async () => {
    const updateBuiltInUsers = (array, builtInUsers) => {
      if (!Array.isArray(array) || typeof builtInUsers !== 'object') {
        return builtInUsers // Return the original object if the input is not as expected
      }

      const updatedMap = { ...builtInUsers.Map } // Create a new map

      for (const key in updatedMap) {
        if (updatedMap.hasOwnProperty(key)) {
          // Check if the user is selected (in selectedItems) and set its value accordingly
          updatedMap[key] = array.includes(key)
        }
      }

      const updatedBuiltInUsers = { ...builtInUsers, Map: updatedMap } // Create a new BuiltInUsers object

      return updatedBuiltInUsers
    }

    const updatedBuiltInUsers = updateBuiltInUsers(selectedItems, rsrc.Spec.BuiltInUsers)

    try {
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.BuiltInUsers = updatedBuiltInUsers
      await new ApiProvider('databases').setInstance(dataObj).put()
      enqueueNotification(`Added Procyon Users Successfully!`, 'info')
      selectDispatch(['databases'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  const getPrivateProxyClusterNames = () => {
    if (!rsrc) return []
    const clusterIDs = [dbMeshCluster]
    const MeshClusterNodes = rsrc.Spec.MeshClusterNodes.ObjectRef
    const existingSelectedClusterId =
      rsrc?.Spec?.MeshCluster !== ''
        ? slices.medusaNodes.find((node) => node.ClusterID === rsrc.Spec.MeshCluster)
        : {}

    const existingSelectedClusterRef = {
      RefKind: existingSelectedClusterId?.ObjectMeta?.Kind,
      RefID: existingSelectedClusterId?.ObjectMeta?.ID
    }

    const combinedClusterNodes = [...MeshClusterNodes]
    combinedClusterNodes.push(existingSelectedClusterRef)
    combinedClusterNodes?.forEach((element) => {
      const matchedNodeID = slices.medusaNodes.filter(
        (meta) => element.RefID === meta.ObjectMeta.ID
      )
      if (matchedNodeID.length > 0) {
        // Extract ClusterID values from matchedNodeID
        const clusterIDsFromMatchedNodes = matchedNodeID.map((node) => node)

        // Push the ClusterID values into clusterIDs
        clusterIDs.push(...clusterIDsFromMatchedNodes)
      }
    })

    const filteredArray = clusterIDs.filter((item) => typeof item === 'object')
    const updatedUniqueArray = []
    const uniqueNode = []
    filteredArray.map((item) => {
      if (uniqueNode.includes(item.ObjectMeta.ID)) {
        return
      } else {
        uniqueNode.push(item.ObjectMeta.ID)
        updatedUniqueArray.push(item)
      }
    })

    return [...updatedUniqueArray]
  }

  if (!rsrc) return null

  const getRegionName = (value) => {
    const arnParts = value.split(':')
    const region = arnParts[3]
    return region
  }

  const getHostNameWithPort = (data) => {
    const port = 9190
    let updatedHostName = []
    for (let i = 0; i < data.Spec.Brokers.Elems.length; i++) {
      let increamentPort = i + port
      updatedHostName.push(data.Spec.DNSNames.Elems + ':' + increamentPort)
    }
    return updatedHostName.join(', ')
  }

  useEffect(() => {
    selectDispatch(['accountList'])
  }, [])

  const handleRunCheck = () => {
    try {
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.MeshCluster = ''
      dataObj.Spec.MeshClusterNodes.ObjectRef = []
      new ApiProvider('kafkas').setInstance(dataObj).put()
      enqueueNotification(`Run checked successfully!`, 'info')
      selectDispatch(['kafkas'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  const handleSpecUpdate = async (save) => {
    try {
      const dataObj = structuredClone(rsrc)
      setSaving(save)
      if (save === 'MeshCluster') dataObj.Spec.MeshCluster = dbMeshCluster
      await new ApiProvider('kafkas').setInstance(dataObj).put()
      enqueueNotification(`kafkas ${save} successfully!`, 'info')
      selectDispatch(['kafkas'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  return (
    <>
      <LoadingFeedback
        loading={saving}
        caption='Please wait.'
        message={`Updating Database ${saving}`}
      />
      <ErrorLabelContent rsrc={rsrc} />

      <LabelContent title='Name' content={rsrc.Spec.Name} />
      <LabelContent title='Region' content={getRegionName(rsrc.Spec.IAMRes)} showEmptyLabel />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />

      <LabelContent title='Hostname' content={getHostNameWithPort(rsrc)} />
      <LabelContent title='DNS Names' content={rsrc.Spec.Brokers.Elems.join(', ')} />
      {appView === 'admin' ? (
        <LabelContent
          title='Cluster Name'
          content={
            <div className='flex justify-between items-center'>
              <SelectDropDown
                showHelpText={false}
                className='!min-w-[180px]'
                menuItems={[
                  ...getPrivateProxyClusterNames().map((e) => ({
                    label: e.ClusterID,
                    value: e.ClusterID
                  }))
                ]}
                onChange={(e) => e.target.value && setDbMeshCluster(e.target.value)}
                value={dbMeshCluster}
              />
              <div className='flex gap-3'>
                <Button variant='primary' onClick={() => handleRunCheck()}>
                  Run Check
                </Button>

                <Button onClick={() => handleSpecUpdate('MeshCluster')} variant='primary'>
                  Save
                </Button>
              </div>
            </div>
          }
        />
      ) : (
        <LabelContent
          title='Mesh Cluster'
          content={<Label className='!normal-case' text={dbMeshCluster} />}
        />
      )}
      <LabelContent
        title='PublicAccess'
        content={
          <Label className='!normal-case' text={rsrc.Spec.IsPrivate ? 'Disabled' : 'Enabled'} />
        }
      />

      {/* GCP add user dropdown */}

      {account && account.Spec.Type === 'GCP' && appView === 'admin' && (
        <>
          <div className='flex items-center justify-between border-b-2'>
            <label className='mr-8' style={{ fontWeight: 600, color: '#A6ABB6', width: '160px' }}>
              Procyon Users
            </label>
            <FormControl className={classes.formControl}>
              <Select
                className={classes.selectOptions}
                style={{
                  width: '500px',
                  border: '1px solid #d8dde3',
                  marginRight: '10%',
                  fontSize: '15px',
                  color: '#545b71'
                }}
                multiple
                value={selectedItems}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 250,
                      color: '#545b71',
                      fontSize: '15px'
                    }
                  }
                }}
              >
                {Object.keys(rsrc?.Spec?.BuiltInUsers?.Map).map((key) => (
                  <MenuItem style={{ fontSize: '14px' }} key={key} value={key}>
                    <Checkbox
                      style={{ color: '#447be4', borderWidth: '1px' }}
                      checked={selectedItems.indexOf(key) > -1}
                    />
                    <ListItemText primary={key} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button size='sm' onClick={() => handleUserSave()} variant='primary'>
              Save
            </Button>
          </div>

          <div className='flex items-center border-b-2 mt-3 mb-3 pb-3'>
            <label className='mr-8' style={{ fontWeight: 600, color: '#A6ABB6', width: '190px' }}>
              Enabled Users
            </label>

            <div className='flex gap-2 flex-wrap' style={{ width: '600px' }}>
              {selectedItems.map((item) => {
                return (
                  <div>
                    <Label variant='grayBlue' text={item} />
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { KafkaResource }
