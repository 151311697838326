import { Box } from '@material-ui/core'
import React from 'react'

function ErrorBox ({ touched, error, fortSize }) {
  /**
   * If Field was Focused and has error in validation show error message
   * */
  if (touched && error) return <Box fontSize={fortSize} color='error.dark'>{error}</Box>
  else return <></>
}

export default ErrorBox
