import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback, SearchInput } from 'V2Components'
import { createRef, createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { createWorkload, updateWorkload } from 'features/workloads'
import _ from 'lodash'
import {
  PickListContainer,
  PickListHeading,
  TextInput,
  ToggleCloudControl,
  Typography
} from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { AWSAccountsSelector } from './components/AWSAccountsSelector/AWSAccountsSelector'
import { AZUREAccountSelector } from './components/AZUREAccountSelector'
import { GCPAccountsSelector } from './components/GCPAccountsSelector/GCPAccountsSelector'
import { Header } from './components/Header'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getFriendlyName } from 'Utils/FriendlyName'
import { useUser } from 'Core/Hooks/useUser'
import { useAddWorkloads } from './AddWorkload.utils'

/**
 *
 * @param {{ workload?: any, isEditMode?: boolean }} param0
 * @returns
 */
const AddWorkload = ({ workload, isEditMode }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [selectedCloudType, setSelectedCloudType] = useState('AWS')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [awsAccounts, setAwsAccounts] = useState([])
  const [gcpResources, setGcpResources] = useState([])
  const [azureAccounts, setAzureAccounts] = useState([])
  const [azureAccountsScopeURLMap, setAzureAccountsScopeURLMap] = useState({})

  const isFieldsPopulated = useRef(false)

  const { appView } = useAppView()
  const history = useHistory()

  const { getObjectRef, slices, dispatchThunks } = useMultiSlice([
    'accountList',
    'gcpResources',
    'projects',
    'workloads'
  ])

  const { getWorkloadFields, getAzureWorkloadAccounts } = useAddWorkloads()

  const { user } = useUser()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const { applySearchQuery: gcpFilterFn } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      // We dont filter gcp accounts
      if (a.ObjectMeta.Kind === 'Account') return true
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  if (!isFieldsPopulated.current) {
    const fields = getWorkloadFields(workload)
    if (fields.loaded) {
      isFieldsPopulated.current = true
      const { name, description, awsAccounts, azureAccounts, gcpResources, azureScopeURLMap } =
        fields
      setName(name)
      setDescription(description)
      setAwsAccounts(awsAccounts)
      setGcpResources(gcpResources)
      setAzureAccounts(azureAccounts)
      setAzureAccountsScopeURLMap(azureScopeURLMap)
    }
  }

  const getErrors = () => {
    const workloadNames = slices.workloads.map((e) => e.ObjectMeta.Name.toLowerCase())
    if (name.trim().length < 3) return 'Name must be greater than 3 characters.'
    if (!isEditMode && workloadNames.includes(name.toLowerCase().trim()))
      return `Name (${name.trim()}) already exists.`
    if (name.length >= 25) return 'Name must be less than 25 characters.'
    if (!description.trim().length) return 'Description cannot be empty'
    if (!awsAccounts.length && !azureAccounts.length && !gcpResources.length)
      return 'No accounts or projects selected for workload identity.'
    let azureAccountKey = null
    const azureScopeEmpty = azureAccounts.some((key) => {
      azureAccountKey = key
      if (!azureAccountsScopeURLMap[key]) return true
    })
    if (azureScopeEmpty) {
      const azAccount = getObjectRef(reverseRsrcKey(azureAccountKey))
      return `Azure Scope URL not provided for account (${getResourceName(azAccount)})`
    }
  }

  const handleCreateClick = async () => {
    try {
      setIsLoading(true)
      const selectedProjects = gcpResources.reduce((prev, rsrcKey) => {
        const obj = getObjectRef(reverseRsrcKey(rsrcKey))
        if (obj.Spec.DisplayType === 'Project') {
          const projectID = obj.Spec.Id
          const project = _.find(slices.projects, {
            Spec: {
              ProjectId: projectID
            }
          })
          project && prev.push(createRsrcKey(project))
        }
        return prev
      }, [])
      if (isEditMode) {
        const updtdworkload = await updateWorkloadPayload({
          awsAccounts,
          gcpResources: selectedProjects,
          azureAccounts,
          azureAccountsScopeURLMap,
          Name: name,
          Description: description,
          workload
        })
        updateSliceData(updtdworkload)
        setIsLoading(false)
        enqueueNotification('Successfully Updated Workload Identity', 'info')
        history.push(`/${appView}/workloads/${workload.ObjectMeta.Name}`)
      } else {
        const workload = await createWorkloadPayload({
          awsAccounts,
          gcpResources: selectedProjects,
          azureAccounts,
          azureAccountsScopeURLMap,
          Name: name,
          Description: description,
          createdBy: createRef(user)
        })
        pushToSlice(workload)
        setIsLoading(false)
        enqueueNotification('Successfully Created Workload Identity', 'info')
        history.push(`/${appView}/workloads`)
      }
    } catch (error) {
      enqueueNotification('Failed To create Workload Identity', 'error')
    }
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div>
      <LoadingFeedback
        loading={isLoading}
        caption='Please wait...'
        message={isEditMode ? 'Updating Workload Identity' : 'Create Workload Identity'}
      />
      <Header
        isEditMode={isEditMode}
        workload={workload}
        error={getErrors()}
        onSubmit={handleCreateClick}
      />
      <Typography className='mt-6' variant='h4-regular'>
        Workload Information
      </Typography>
      <div className='py-8 mt-6 flex gap-4'>
        <TextInput
          sx={{ width: '100%' }}
          label='Name'
          placeholder='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextInput
          sx={{ width: '100%' }}
          label='Description'
          placeholder='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <Typography className='mt-6' variant='h4-regular'>
        Select Accounts
      </Typography>
      <div className='pt-4 flex justify-between'>
        <div className='flex gap-4'>
          <ToggleCloudControl
            ariaLabel='cloud-select'
            onChange={(_, v) => v && setSelectedCloudType(v)}
            options={['AWS', 'GCP', 'AZURE']}
            value={selectedCloudType}
          />
        </div>
        <SearchInput searchKey={searchKey} onChange={setSearchKey} />
      </div>

      <div>
        <div className='flex gap-4 my-6'>
          <div className='w-[100%]'>
            <PickListHeading
              dropDownItems={[]}
              onChange={function noRefCheck() {}}
              title={`Resources `}
            />
            <PickListContainer
              style={{
                height: '560px',
                width: '100%',
                display: 'block',
                backgroundColor: 'white'
              }}
            >
              {selectedCloudType === 'AWS' && (
                <AWSAccountsSelector
                  selectedAccounts={awsAccounts}
                  setSelectedAccounts={setAwsAccounts}
                  accounts={applySearchQuery(slices.accountList).filter(
                    (acc) => acc.Spec.Type === 'AWS'
                  )}
                />
              )}
              {selectedCloudType === 'GCP' && (
                <GCPAccountsSelector
                  getVisibleNodesFn={gcpFilterFn}
                  onAdd={(key) => setGcpResources((s) => [key, ...s])}
                  onRemove={(key) => setGcpResources((s) => s.filter((s) => s !== key))}
                  selectedRsrcs={gcpResources}
                  rsrcs={slices.gcpResources.filter((e) =>
                    ['Folder', 'Project', 'Organization'].includes(e.Spec.DisplayType)
                  )}
                />
              )}
              {selectedCloudType === 'AZURE' && (
                <AZUREAccountSelector
                  readonlyAccounts={isEditMode ? getAzureWorkloadAccounts(workload) : []}
                  selectedAccounts={azureAccounts}
                  setSelectedAccounts={setAzureAccounts}
                  accounts={applySearchQuery(slices.accountList).filter(
                    (a) => a.Spec.Type === 'AZURE'
                  )}
                  accountScopeURLMap={azureAccountsScopeURLMap}
                  setAccountScopeURLMap={setAzureAccountsScopeURLMap}
                />
              )}
            </PickListContainer>
          </div>
          <div className='w-[100%]'>
            <PickListHeading
              dropDownItems={[]}
              onChange={function noRefCheck() {}}
              title={`Resources `}
            />
            <PickListContainer
              style={{
                height: '560px',
                width: '100%',
                display: 'block',
                backgroundColor: 'white'
              }}
            >
              {(() => {
                const ARR = [
                  <AWSAccountsSelector
                    selectedAccounts={awsAccounts}
                    setSelectedAccounts={setAwsAccounts}
                    accounts={applySearchQuery(getObjectRef(awsAccounts.map(reverseRsrcKey)))}
                  />,
                  <GCPAccountsSelector
                    getVisibleNodesFn={gcpFilterFn}
                    showOnlySelected
                    onAdd={(key) => setGcpResources((s) => [key, ...s])}
                    onRemove={(key) => setGcpResources((s) => s.filter((s) => s !== key))}
                    selectedRsrcs={gcpResources}
                    rsrcs={slices.gcpResources.filter((e) =>
                      ['Folder', 'Project', 'Organization'].includes(e.Spec.DisplayType)
                    )}
                  />,
                  <AZUREAccountSelector
                    readonlyAccounts={isEditMode ? getAzureWorkloadAccounts(workload) : []}
                    showOnlySelected
                    accounts={applySearchQuery(slices.accountList).filter(
                      (a) => a.Spec.Type === 'AZURE'
                    )}
                    accountScopeURLMap={azureAccountsScopeURLMap}
                    selectedAccounts={azureAccounts}
                    setAccountScopeURLMap={setAzureAccountsScopeURLMap}
                    setSelectedAccounts={setAzureAccounts}
                  />
                ]
                const indexPattern = SELECTOR_COMBINATION_MAP[selectedCloudType]
                return indexPattern.map((index) => ARR[index])
              })()}
            </PickListContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

const INDEXES_MAP = {
  AWS: 0,
  GCP: 1,
  AZURE: 2
}

const SELECTOR_COMBINATION_MAP = {
  AWS: [INDEXES_MAP.AWS, INDEXES_MAP.GCP, INDEXES_MAP.AZURE],
  GCP: [INDEXES_MAP.GCP, INDEXES_MAP.AWS, INDEXES_MAP.AZURE],
  AZURE: [INDEXES_MAP.AZURE, INDEXES_MAP.AWS, INDEXES_MAP.GCP]
}

const createWorkloadPayload = async ({
  Name,
  Description,
  awsAccounts,
  gcpResources,
  azureAccounts,
  azureAccountsScopeURLMap,
  createdBy
}) => {
  const payload = {
    ObjectMeta: {
      Name: getFriendlyName()
    },
    Spec: {
      Name,
      Description,
      Accounts: {
        ObjectRef: [
          ...awsAccounts.map(reverseRsrcKey),
          ...gcpResources.map(reverseRsrcKey),
          ...azureAccounts.map(reverseRsrcKey)
        ]
      },
      AzureScope: {
        AzureScopeMap: {}
      }
    },
    CreatedBy: createdBy
  }

  const accountKeys = Object.keys(azureAccountsScopeURLMap)
  accountKeys.forEach((key) => {
    const ID = key.split('+')[1]
    payload.Spec.AzureScope.AzureScopeMap[ID] = azureAccountsScopeURLMap[key]
  })

  const workload = await createWorkload(payload)
  return workload
}

const updateWorkloadPayload = async ({
  workload,
  Name,
  Description,
  awsAccounts,
  gcpResources,
  azureAccounts,
  azureAccountsScopeURLMap
}) => {
  const payload = {
    Spec: {
      Name,
      Description,
      Accounts: {
        ObjectRef: [
          ...awsAccounts.map(reverseRsrcKey),
          ...gcpResources.map(reverseRsrcKey),
          ...azureAccounts.map(reverseRsrcKey)
        ]
      },
      AzureScope: {
        AzureScopeMap: {}
      }
    }
  }

  const accountKeys = Object.keys(azureAccountsScopeURLMap)
  accountKeys.forEach((key) => {
    const ID = key.split('+')[1]
    payload.Spec.AzureScope.AzureScopeMap[ID] = azureAccountsScopeURLMap[key]
  })

  const clone = structuredClone(workload)

  clone.Spec = { ...clone, ...payload.Spec }
  clone.Spec.EditAccounts = true

  const updtdworkload = await updateWorkload(clone)
  return updtdworkload
}

export { AddWorkload }
