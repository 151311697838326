import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { FullScreenContentModal } from 'V2Components'
import { getResourceName, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

function WorkloadErrorModal({ workload, onClose }) {
  const { getObjectRef } = useMultiSlice(['accountList', 'gcpResources', 'projects'])

  const renderErrors = () => {
    const errorObj = workload.Status.Status?.StatusMap || {}

    return Object.keys(errorObj).map((key) => {
      const accountKey = key.replace(':', '+')
      const obj = getObjectRef(reverseRsrcKey(accountKey))
      const Icon = key === 'Misc' ? null : getRsrcIcon(obj)
      const errorText = errorObj[key].Error
      return (
        <div className='border-b mb-4'>
          <div className='flex gap-2'>
            {Icon && <Icon />}
            <Typography className='!font-semibold' variant='h4-regular'>
              {getResourceName(obj) || key}
            </Typography>
          </div>
          <div className='mb-4 w-[100%]'>
            <div className='bg-red-500 p-4 mt-4 rounded text-white max-h-[60vh] overflow-auto'>
              <code>{errorText}</code>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between'>
        <Typography variant='h4-regular'>
          Errors on <strong className='ml-1'>{getResourceName(workload)}</strong>
        </Typography>
        <Button variant='gray' onClick={onClose}>
          Close
        </Button>
      </div>
      <div className='h-[480px] pt-4 w-[100%] overflow-auto'>
        <div>{renderErrors()}</div>
      </div>
    </FullScreenContentModal>
  )
}

export { WorkloadErrorModal }
