import { CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

import ThemeButton from 'Components/ThemeButton'
import React from 'react'

const useStyle = makeStyles((theme) => ({

  label: {
    color: theme.palette.text.primary
  },
  root: {
    margin: props => props.newDesign ? '0px 16px' : '8px 16px',
    '& span': {
      marginLeft: '8px'
    }
  }
}))
const TableActionButton = ({ dataCy, newDesign = false, loading, variant, Icon, label, onClick = () => { } }) => {
  const classes = useStyle({ newDesign })

  return (
    <ThemeButton data-cy={dataCy} startIcon={loading && <CircularProgress size={16} />} className={classNames(classes.root)} theme={variant} onClick={onClick} disableElevation variant='contained' size='small'>
      {Icon && <Icon />} <span className={classes.label}>{label}</span>
    </ThemeButton>
  )
}

export default TableActionButton
