import { faClose } from '@fortawesome/pro-duotone-svg-icons'
import {
  faCircleX,
  faCircleXmark,
  faHourglassClock,
  faHourglassEnd,
  faLock,
  faShieldCheck
} from '@fortawesome/pro-solid-svg-icons'
import { createRsrcKey } from 'features/resources'
import moment from 'moment'

/**
 * The function gives you all the resources which are attached to this approval request
 * @param {*} req
 * @returns
 */
export const getApprovalRequestResourcesRefs = (req) => {
  const resourcesRefs = []
  const finalResourceRefs = []
  const _tempMap = {}

  req.Spec.Resources.Resource.forEach((e) => {
    /**
     * If the approval request is for IamAction then, the resources are the AwsResource and GcpResource
     * which are in the Services array hence, push the Services array.
     * Else, the approval request is for Server or AppRole hence push the Target object
     */
    if (e.Target.RefKind === 'IamAction') {
      resourcesRefs.push(...e.Services.ObjectRef)
    } else {
      resourcesRefs.push(e.Target)
    }
  })

  resourcesRefs.forEach((e) => {
    const key = `${e.RefKind}+${e.RefID}`
    if (_tempMap[key]) return
    _tempMap[key] = true
    finalResourceRefs.push(e)
  })

  return finalResourceRefs
}

/**
 * The function gives you all the resources which are attached to this approval request
 * @param {*} paapolicy
 * @returns
 */
export const getAutoApprovePolicyResourcesRefs = (paapolicy) => {
  const { Target, Services } = paapolicy.Spec

  if (Target.RefKind === 'IamAction') {
    /**
     * This policy is for iam resources, for iam policies, resources are in Services.ObjectRef
     */
    return Services.ObjectRef
  }

  return [Target]
}

/**
 *
 * @param { string } status
 * @returns {{ variant: 'grayBlue' | 'danger' | 'warning' | 'success', icon: import('@fortawesome/fontawesome-svg-core').IconDefinition }}
 */
export const getApprovalIconAndVariant = (status) => {
  let variant = 'grayBlue'
  let icon = faShieldCheck
  if (status === 'Rejected') {
    variant = 'danger'
    icon = faCircleX
  } else if (status === 'Cancelled') {
    variant = 'danger'
    icon = faCircleX
  } else if (status === 'Expired') {
    variant = 'warning'
    icon = faHourglassEnd
  } else if (status === 'Pending') {
    variant = 'success'
    icon = faHourglassClock
  } else if (status === 'Closed') {
    variant = 'danger'
    icon = faLock
  }
  // @ts-ignore
  return { variant, icon }
}

/**
 * The function returns all the IamActions which are attached to the resources on the approval request
 * Both, Aws and Gcp's are handled.
 * @param {*} approvalRequest
 * @returns
 */
export const getApprovalRequestIAMActions = (approvalRequest) => {
  /**
   * Will hold the key of the resource with array of iam actions Ref object
   * Eg, mapObj =  {
   *    "AwsResource+123": [{ RefKind: "IamAction", RefID: "12589" }]
   * }
   * */
  const mapObj = {}
  // Return {}, if the approval request is not for IAM Resources(for IAM Resources actions are AwsIAM or GcpIAM)
  if (!approvalRequest || !approvalRequest.Spec.Resources.Resource[0].Action.includes('IAM'))
    return {}
  const resources = approvalRequest.Spec.Resources.Resource
  resources.forEach((r) => {
    // Services holds the iam resources to which this IAM Action(IAM Role) is attached
    const services = r.Services.ObjectRef
    services.forEach((s) => {
      // Object key is generated here
      const mapKey = `${s.RefKind}+${s.RefID}`
      const iamActions = mapObj[mapKey]
      if (iamActions) {
        // Check if the IamAction is aready exists in the array, if not then onlt add
        if (!iamActions.find((e) => e.RefKind === r.Target.RefKind && e.RefID === r.Target.RefID))
          mapObj[mapKey].push(r.Target)
      } // Array with that key exists, hence push the iam action ref
      else mapObj[mapKey] = [r.Target]
    })
  })
  return mapObj
}

/**
 * A function which returns an onbject containing the different time formats along with the exact
 * time value forward in time
 */
export const getDateWithOptions = (sd) => {
  const mapObject = { hours: {}, days: {}, months: {}, weeks: {}, custom: {} }
  const hoursRange = { from: 1, to: 12 }
  const daysRange = { from: 1, to: 7 }
  const weeksRange = { from: 1, to: 4 }
  const monthsRange = { from: 1, to: 12 }

  for (let count = hoursRange.from; count <= hoursRange.to; count++) {
    const date = moment(sd)
    mapObject.hours = { [count]: date.add(count, 'hours').toDate(), ...mapObject.hours }
  }
  for (let count = daysRange.from; count <= daysRange.to; count++) {
    const date = moment(sd)
    mapObject.days = { [count]: date.add(count, 'days').toDate(), ...mapObject.days }
  }
  for (let count = weeksRange.from; count <= weeksRange.to; count++) {
    const date = moment(sd)
    mapObject.weeks = { [count]: date.add(count, 'weeks').toDate(), ...mapObject.weeks }
  }
  for (let count = monthsRange.from; count <= monthsRange.to; count++) {
    const date = moment(sd)
    mapObject.months = { [count]: date.add(count, 'months').toDate(), ...mapObject.months }
  }
  return { ...mapObject, date: sd }
}

/**
 * Check if the approval request has expired
 * @param {any} request
 * @returns {boolean}
 */
export const hasApprovalRequestExpired = (request) => {
  return (
    request.Status === APPROVED_STATUS.Expired ||
    new Date(request?.Spec?.NotAfter).getTime() - new Date().getTime() < 0
  )
}

/**
 * Check if an approval request can be edited or not
 * @param {*} request
 * @returns {boolean}
 */
export const isApprovalRequestEditable = (request) => request.Status === APPROVED_STATUS.Pending
/**
 * Check if approval request is cancelled
 * @param {*} req
 * @returns {boolean}
 */
export const isApprovalRequestCancelled = (req) => req.Status === APPROVED_STATUS.Cancelled

/**
 * Check is the user requested the access request.
 * @param {object} user user object
 * @param {object} approvalRequest approval request
 * @returns {boolean} True if the `approvalRequest` was created by the user
 */
export const isApprovalRequestCreatedByUser = (user, approvalRequest) => {
  const requestedBy = createRsrcKey(approvalRequest.Requestor)
  const uKey = createRsrcKey(user)
  return uKey === requestedBy
}

/**
 * Verify if the approval request is for service account creation from user
 * @param {*} approvalRequest 
 * @returns `true` if the approval request is for service account creation
 */
export const isServiceAccountRequest = (approvalRequest) => {
  return approvalRequest.Type === 'ServiceAccount'
}

export const APPROVED_STATUS = {
  New: 'New',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  Expired: 'Expired',
  Close: 'Closed'
}

export * from './constants'
