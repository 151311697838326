import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import {
  AccessCartProvider,
  ResourceDetailsProvider,
  ResourceDetailsView,
  RsrcAccessSummaryView,
  createRef,
  isResourceManaged,
  useAccessCartProvider,
  useResourceDetails
} from 'features/resources'
import { useTagsRsrcAccess } from 'features/tags'
import {
  CreatePolicyFlow,
  HeaderActions,
  PRINCIPAL_OBJECT_KINDS,
  TargetDetails,
  isDynamicAppRole,
  isPrivateServer
} from 'features/targets'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const QUEUE_KEY = 'adminTargets'

function AdminTargetDetails() {
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const { rsrc } = useResourceDetails()
  const { appView } = useAppView()
  const { addItemsToCart, isResourceInCart, updateCartQueueItem, getResourceFromCart } =
    useAccessCartProvider()

  const { getRsrcTagPolicies } = useTagsRsrcAccess()
  const [views, setViews] = useState({
    principalModal: false,
    accessRequestSubmissionModal: false,
    userGroupSelectionModal: false
  })

  if (!selectedRsrc && rsrc) setSelectedRsrc(rsrc)

  const { slices, dispatchThunks, getObjectRef } = useMultiSlice([
    'policyList',
    'userList',
    'groupList',
    'workloads',
    'serviceAccounts',
    'adDomainControllers'
  ])

  /**
   *
   * @returns A list of policy which are attached to this resource
   */
  const getRsrcAttachedPolicy = useCallback(() => {
    const pList = []
    if (!rsrc) return []
    const rsrcRef = { RefKind: rsrc.ObjectMeta.Kind, RefID: rsrc.ObjectMeta.ID }
    slices.policyList.forEach((p) => {
      let { AssumeRole, SSH, DBAccess, RDPServer, KubeAccess } = p.Spec.ActionMap

      AssumeRole = AssumeRole?.PolicyRule || []
      SSH = SSH?.PolicyRule || []
      DBAccess = DBAccess?.PolicyRule || []
      RDPServer = RDPServer?.PolicyRule || []
      KubeAccess = KubeAccess?.PolicyRule || []

      const rules = [...AssumeRole, ...SSH, ...DBAccess, ...RDPServer, ...KubeAccess]
      if (_.find(rules, { ObjectRef: rsrcRef })) pList.push(p)
    })
    const rsrcTagPolicys = getRsrcTagPolicies(rsrc)
    return [...pList, ...rsrcTagPolicys]
  }, [slices.policyList, rsrc])

  /**
   *
   * @returns { { RefKind: string, RefID:string }[]} List of unique entity refs who have aceess to this resource
   */
  const getRsrcAccessEntities = useCallback(() => {
    const pList = getRsrcAttachedPolicy()
    const tempMap = {}
    const refObjects = []
    if (!rsrc) return []
    pList.forEach((p) => {
      p.IssuedTo.ObjectRef.forEach((ref) => {
        const key = `${ref.RefKind}+${ref.RefID}`
        if (!tempMap[key]) {
          const refObject = getObjectRef(ref)
          if (refObject) {
            refObjects.push(refObject)
            tempMap[key] = true
          }
        }
      })
    })
    return refObjects
  }, [getObjectRef, getRsrcAttachedPolicy, rsrc])

  const attachedPolicys = getRsrcAttachedPolicy()
  const rsrcAccessEntities = getRsrcAccessEntities()

  const handleResourceAccessClick = (principal, data) => {
    const rsrcKind = data?.ObjectMeta ? data?.ObjectMeta.Kind : rsrc.ObjectMeta.Kind
    const resource = data ? data : rsrc

    if (principal) {
      if (data.ObjectMeta.Kind === 'KubeNamespace') {
        // Kube is already present
        if (isResourceInCart(createRef(data))) {
          const cartData = getResourceFromCart(createRef(data))
          const Principal = cartData?.Principal || ''
          //If the kube rsrc like pods is already present then ignore
          if (Principal.includes(principal)) return
          // Update the principal with new k8 rsrc
          updateCartQueueItem({
            principal: `${cartData.Principal ? cartData.Principal + ',' : ''}${principal}`,
            resourceRef: createRef(data)
          })
        } else {
          //Add new k8 rsrcs to queue
          addItemsToCart({
            principal,
            resourceRef: createRef(data),
            roles: []
          })
        }
      }
      return
    }

    if (rsrcKind === 'AppRole' || rsrcKind === 'Kafka') {
      if (isResourceInCart({ RefKind: rsrcKind, RefID: rsrc.ObjectMeta.ID })) return
      addItemsToCart({
        principal: '',
        resourceRef: {
          RefKind: rsrcKind,
          RefID: rsrc.ObjectMeta.ID
        },
        roles: []
      })
      return
    }

    if (rsrc.ObjectMeta.Kind === 'KubeCluster' || rsrc.ObjectMeta.Kind === 'KubeNamespace') {
      setSelectedRsrc(resource)
      setViews((s) => ({ ...s, kubeAppRoleSelector: true }))
    }

    if (PRINCIPAL_OBJECT_KINDS.includes(rsrcKind)) {
      setSelectedRsrc(rsrc)
      setViews((s) => ({ ...s, principalModal: true }))
      return
    }
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  const memoizedTabs = useMemo(() => {
    const tabs = []
    return [
      {
        label: 'Access Summary',
        tabContent: (
          <RsrcAccessSummaryView
            attachedPolicys={attachedPolicys}
            rsrcAccessEntities={rsrcAccessEntities}
          />
        )
      },
      ...tabs
    ]
  }, [attachedPolicys, rsrcAccessEntities])

  if (!rsrc) return null

  const disableAccessButton = (() => {
    // Do not disable access button when it's kube cluster
    if (rsrc.ObjectMeta.Kind === 'KubeCluster') return false
    return isResourceInCart({
      RefKind: rsrc.ObjectMeta.Kind,
      RefID: rsrc.ObjectMeta.ID
    })
  })()

  // Hide the request button for dynamic approle and any targets which is already granted other than Server only in
  // user view
  const hideAccessButton = (() => {
    if (!isResourceManaged(rsrc)) return true
    if (isDynamicAppRole(rsrc)) return true
    if (appView === 'admin') return false
    return attachedPolicys.length >= 1 && rsrc.ObjectMeta.Kind !== 'Server'
  })()

  return (
    <div>
      <ResourceDetailsView
        HeaderActions={
          <HeaderActions
            accessButtonPrimaryText={
              rsrc.ObjectMeta.Kind === 'KubeCluster' &&
              isResourceInCart({
                RefKind: rsrc.ObjectMeta.Kind,
                RefID: rsrc.ObjectMeta.ID
              })
                ? 'Edit'
                : 'Create'
            }
            showManageButton={!isPrivateServer(rsrc)}
            hideAccessButton={hideAccessButton}
            disableAccessButton={disableAccessButton}
            onAccessClick={handleResourceAccessClick}
            rsrc={rsrc}
            showSignIn={
              isResourceManaged(rsrc) &&
              rsrc.ObjectMeta.Kind === 'AppRole' &&
              (attachedPolicys.length > 0 || appView === 'admin')
            }
            showPrincipleSignIn={
              isResourceManaged(rsrc) &&
              rsrc.ObjectMeta.Kind === 'RDPServer' &&
              (attachedPolicys.length > 0 || appView === 'admin')
            }
          />
        }
      >
        <TargetDetails tabs={memoizedTabs} onAccessClick={handleResourceAccessClick} />
      </ResourceDetailsView>

      <CreatePolicyFlow
        selectedRsrc={selectedRsrc}
        setSelectedRsrc={setSelectedRsrc}
        setViews={setViews}
        views={views}
      />
    </div>
  )
}

const WrappedAdminDetails = () => {
  const { slices, dispatchThunks, selectDispatch } = useMultiSlice([
    'appRolesList',
    'databases',
    'serverList',
    'rdpServers',
    'kubeClusters',
    'kubeNamespaces',
    'kafkas'
  ])
  const rsrcList = useMemo(
    () => [
      ...slices.appRolesList,
      ...slices.databases,
      ...slices.serverList,
      ...slices.rdpServers,
      ...slices.kafkas,
      ...slices.kubeClusters,
      ...slices.kubeNamespaces
    ],
    [slices]
  )

  useEffect(() => {
    dispatchThunks()
    selectDispatch(['serviceAccounts', 'tenantprofiles'])
  }, [])

  return (
    <div>
      <ResourceDetailsProvider rsrcList={rsrcList} rsrcKindKey='kind' rsrcNameKey='targetName'>
        <AccessCartProvider cartKey={QUEUE_KEY}>
          <AdminTargetDetails />
        </AccessCartProvider>
      </ResourceDetailsProvider>
    </div>
  )
}

export { WrappedAdminDetails as AdminTargetDetails }
