import { ApiProvider } from 'Core'
import { enqueueNotification } from 'Utils/Helpers'
import { IntegrationCard } from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { AllIntegrationData } from '../../../../../const'
import { InfoModal } from '../../Modals'
import { IntegrationsModal } from '../../Modals/Integrations'
import { reduxApiClient } from 'infra'
import { LoadingFeedback } from 'V2Components'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { useHistory } from 'react-router'

const AllIntegration = () => {
  const history = useHistory()
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showIntegrationModal, setShowIntegrationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isRedirectionLoad, setIsRedirectionLoad] = useState(false)
  const timmer = useRef(null)
  const [message, setMessage] = useState('')
  const [slackIntegration, setSlackIntegration] = useState({
    name: '',
    webhookUrl: ''
  })
  const [isSelfHosted, setIsSelfHosted] = useState(false)
  const [jiraIntegration, setJiraIntegration] = useState({
    name: '',
    hostUrl: ''
  })
  const [serviceNowIntegration, setServiceNowIntegration] = useState({
    name: '',
    instanceURL: '',
    clientID: '',
    clientSecret: ''
  })
  const [msTeamIntegrations, setMsTeamIntegrations] = useState({
    name: '',
    webhookurl: ''
  })

  useEffect(() => {
    const servicewNowAllField = Object.values(serviceNowIntegration).every(
      (value) => value.trim() !== ''
    )
    setIsDisabled(!servicewNowAllField)
  }, [serviceNowIntegration])

  const handleInfoModalClick = (type) => {
    setShowInfoModal(true)
    setSelectedType(type)
  }

  const handleIntegrationModalClick = (type) => {
    setShowInfoModal(false)
    setSelectedType(type)
    setShowIntegrationModal(true)
  }

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.Clipboard
    const pastedText = clipboardData.getData('text')
    setSlackIntegration((prevIntegration) => ({
      ...prevIntegration,
      webhookUrl: pastedText.trim()
    }))
  }

  const onInputChange = (value, field) => {
    if (selectedType === 'slack') {
      setSlackIntegration((prevIntegration) => ({
        ...prevIntegration,
        [field]: value.trim()
      }))

      const isNameValid = slackIntegration.name.trim() !== ''
      const isWebhookUrlValid = slackIntegration.webhookUrl.trim() !== ''

      if (isNameValid && isWebhookUrlValid) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }

    if (selectedType === 'jira') {
      if (field === 'name' && value !== '' && !isSelfHosted) {
        setIsDisabled(false)
        setJiraIntegration((prevIntegration) => ({
          ...prevIntegration,
          [field]: value.trim()
        }))
      } else if (isSelfHosted && field === 'hostUrl' && value !== '') {
        setIsDisabled(false)
        setJiraIntegration((prevIntegration) => ({
          ...prevIntegration,
          [field]: value.trim()
        }))
      } else {
        setIsDisabled(true)
      }
    }

    if (selectedType === 'servicenow') {
      setServiceNowIntegration((prevData) => ({
        ...prevData,
        [field]: value.trim()
      }))
    }

    if (selectedType === 'msteam') {
      if (!isSelfHosted) {
        if (field === 'name' && value !== '') {
          setMsTeamIntegrations((prev) => ({
            ...prev,
            [field]: value.trim()
          }))
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }
      } else {
        if (field === 'webhookurl' && value !== '') {
          setMsTeamIntegrations((prev) => ({
            ...prev,
            [field]: value.trim()
          }))
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }
      }
    }
  }

  const pollForAuthURL = async (name, kind) => {
    setIsRedirectionLoad(true)
    setMessage("Redirecting to authorization page, please don't leave this page.")
    clearPolling()
    const poll = async () => {
      const resp = await reduxApiClient(kind).getByDataParams({
        'ObjectMeta.Name': name
      })
      console.log('polling resp', resp[0])
      if (resp[0]?.Status?.AuthURL) {
        window.location.href = resp[0].Status.AuthURL
        setIsRedirectionLoad(false)
        clearPolling()
      }
    }
    timmer.current = setInterval(poll, 3000)
  }
  const clearPolling = () => {
    if (timmer.current) {
      clearInterval(timmer.current)
    }
  }

  const onContinue = () => {
    switch (selectedType) {
      case 'slack':
        handleSlackIntegration()
        break
      case 'jira':
        handleJiraIntegration()
        break
      case 'servicenow':
        handleServicenowIntegration()
        break
      case 'msteam':
        handleMSTeamIntegration()
        break
    }
  }

  const handleMSTeamIntegration = async () => {
    try {
      const payload = {
        ObjectMeta: {
          Kind: 'MsTeamsIntegration',
          Name: msTeamIntegrations.name
        }
      }
      if (isSelfHosted) {
        payload.Spec = { WebhookUrl: msTeamIntegrations.webhookurl }
      }
      const response = await reduxApiClient('msteamsintegrations').create(payload)
      if (response && !isSelfHosted) {
        pollForAuthURL(response.ObjectMeta.Name, 'msteamsintegrations')
      }
      history.push('/admin/integrations')
      enqueueNotification('MS Team Integration created successfully!', 'info')
      setShowIntegrationModal(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleJiraIntegration = async () => {
    try {
      const payload = { ObjectMeta: { Kind: 'JiraIntegration', Name: jiraIntegration.name } }
      if (isSelfHosted) {
        payload.Spec = { IsSelfHosted: true, BaseURL: jiraIntegration.hostUrl }
      }
      const resp = await new ApiProvider('jiraintegrations').setInstance(payload).post()
      console.log('resp', resp)
      if (resp && resp.data) {
        pollForAuthURL(resp.data.ObjectMeta.Name, 'jiraintegrations')
        enqueueNotification('Integration Created', 'info')
      } else {
        console.error('error: integration object not created')
        enqueueNotification('Operation Failed')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleSlackIntegration = async () => {
    try {
      setIsLoading(true)
      const payload = { ObjectMeta: { Kind: 'SlackIntegration', Name: slackIntegration.name } }
      payload.Spec = { WebhookUrl: slackIntegration.webhookUrl }
      const response = await reduxApiClient('slackintegrations').create(payload)
      pushToSlice(response)
      enqueueNotification('Slack Integration created successfully!', 'info')
      setShowIntegrationModal(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleServicenowIntegration = async () => {
    try {
      const payload = {
        ObjectMeta: {
          Kind: 'ServiceNowIntegration',
          Name: serviceNowIntegration.name
        },
        Spec: {
          ClientID: serviceNowIntegration.clientID,
          ClientSecret: serviceNowIntegration.clientSecret,
          DevInstanceURL: serviceNowIntegration.instanceURL
        }
      }
      const resp = await new ApiProvider('servicenowintegrations').setInstance(payload).post()
      console.log('resp', resp)
      if (resp && resp.data) {
        pollForAuthURL(resp.data.ObjectMeta.Name, 'servicenowintegrations')
        // enqueueNotification('Integration Created', 'info')
      } else {
        console.error('error: integration object not created')
        enqueueNotification('Operation Failed')
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <>
      <div className='flex items-center gap-6 flex-wrap'>
        {AllIntegrationData.map((item) => (
          <IntegrationCard
            caption={item.caption}
            iconType={item.icon}
            onClickAuthorizeButton={function noRefCheck() {}}
            onClickInfoButton={() => handleInfoModalClick(item.type.toLowerCase())}
            onClickIntegrateButton={() => handleIntegrationModalClick(item.type.toLowerCase())}
            onClickManageIntegrationButton={function noRefCheck() {}}
            onClickRemoveButton={function noRefCheck() {}}
            showInfoButton
            showIntegrateButton
            subtitle={item.category}
            title={item.name}
            width='450px'
            className='mr-10'
          />
        ))}
      </div>

      {/* slack info modal */}
      <InfoModal
        selectedType={selectedType}
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        handleIntegrationModalClick={handleIntegrationModalClick}
      />

      {/* slack integration modal */}
      <IntegrationsModal
        selectedType={selectedType}
        showIntegrationModal={showIntegrationModal}
        setShowIntegrationModal={setShowIntegrationModal}
        onInputChange={onInputChange}
        isDisabled={isDisabled}
        onContinue={onContinue}
        isSelfHosted={isSelfHosted}
        setIsSelfHosted={setIsSelfHosted}
        message={message}
        handlePaste={handlePaste}
      />
      <LoadingFeedback loading={isLoading} message='Creating Integration' caption='Please wait..' />
      {message && (
        <LoadingFeedback loading={isRedirectionLoad} message={message} caption='Please wait...' />
      )}
    </>
  )
}

export { AllIntegration }
