import { makeStyles } from '@material-ui/core'
import { NavLink } from 'Core'
import { FeatureFlagContext } from 'Core/FeatureFlagContext'
import React, { useContext, useEffect } from 'react'
import './NotFound.css'
const useStyle = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})
function NotFound () {
  const classes = useStyle()
  const { data: { viewMode } } = useContext(FeatureFlagContext)
  const redirectURL = viewMode === 'admin' ? '/admin/' : '/user/'
  useEffect(() => {
    return () => {

    }
  }, [])

  return (
    <div className={classes.root}>
      <h3>
        The Route You Are Looking For Is Not Found.
      </h3>
      <NavLink to={redirectURL}>Go back to Home</NavLink>
    </div>
  )
}

export default NotFound
