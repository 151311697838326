import { makeStyles, TextField } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
const useStyles = makeStyles((theme) => ({
  // TODO Remove this classes after final changes
  searchInput: {
    padding: theme.spacing(0.725),
    height: props => props.height && theme.spacing(props.height),
    boxShadow: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.13)'
  },
  searchBar: {
    width: (props) => props.width && props.width.toString().includes('%') ? props.width : theme.spacing(props.width),
    marginTop: (props) => props.mt && theme.spacing(props.mt),
    marginBottom: (props) => props.mb && theme.spacing(props.mb),
    // TODO change when theme changes
    backgroundColor: 'white'
  }

}))
/**
 * @typedef {import('@material-ui/core').TextFieldProps} TextFieldProps
 * @property {string} width
 * @property {string} height
 * /
/**
 * @param {TextFieldProps} props
 * @returns {React.ReactElement}
 */
const ThemeInput = (props) => {
  const inputProps = props.inputProps || {}
  const className = props.className || ''
  const classes = useStyles({ width: props.width, mt: props.mt, mb: props.mb, height: props.height })
  return (
    <TextField
      {...props}
      className={classNames(className, classes.searchBar)}
      inputProps={{
        ...inputProps,
        className: !props.multiline && classes.searchInput
      }}
    />
  )
}

export default ThemeInput
