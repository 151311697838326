import React from 'react'
import { Route } from 'react-router'
import { ApprovalList, ApprovalsDetails } from '../views'

const ApprovalListRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/access-requests']}>
        <ApprovalList />
      </Route>
      <Route exact path={['/admin/access-requests/:requestName']}>
        <ApprovalsDetails />
      </Route>
      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/access-requests']}>
        <ApprovalList />
      </Route>
      <Route exact path={['/user/access-requests/:requestName']}>
        <ApprovalsDetails />
      </Route>
    </>
  )
}

export { ApprovalListRoutes }
