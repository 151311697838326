import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, QrCodeView } from 'V2Components'
import { getUserDisplayName, getUserOnboardingLink } from 'features/users'
import { Button, LoadingModal, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function OnboardingLinkModal({ user, onClose }) {
  const [isFetching, setIsFetching] = useState(false)
  const [refreshLink, setRefreshLink] = useState(true)
  const [onboardingLink, setOnboardingLink] = useState('')

  const handleLinkRefresh = async () => {
    try {
      setIsFetching(true)
      const data = await getUserOnboardingLink(user)
      setOnboardingLink(data.OnboardTokenLink)
    } catch (error) {
      enqueueNotification('Error getting onboarding link.', 'error')
    } finally {
      setIsFetching(false)
    }
  }

  const handleCopyOnboardLink = () => {
    navigator.clipboard.writeText(onboardingLink)
    enqueueNotification('Onboarding link copied successfully!', 'info')
  }

  if (refreshLink) {
    setRefreshLink(false)
    handleLinkRefresh()
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between'>
        <Typography variant='h4-regular'>
          Onboarding Link for <strong className='ml-1'>{getUserDisplayName(user)}</strong>
        </Typography>
        <Button variant='gray' onClick={onClose}>
          Close
        </Button>
      </div>
      <div className='h-[480px] pt-4 flex justify-center items-center'>
        {!isFetching && (
          <div>
            <div className='flex justify-center items-center flex-col'>
              <QrCodeView text={onboardingLink} />
              <Typography variant='body-regular' className='mb-4 !text-blue-500'>
                Scan to onboard user.
              </Typography>
            </div>
            <div className='p-2 my-2 bg-gray-200 rounded'>
              <Typography className='break-all !leading-5' variant='body-regular'>
                {onboardingLink}
              </Typography>
            </div>
          </div>
        )}
        {isFetching && <LoadingModal shadow={false} caption='Please wait..' message='Fetching onboarding link' />}
      </div>
      <div className='flex justify-between mt-2'>
        <Typography className='!text-blue-500' variant='body-regular'>
          Share this link to onboard the user.
        </Typography>
        <div className='flex gap-4'>
          <Button
            disabled={isFetching || !onboardingLink}
            onClick={() => setRefreshLink(true)}
            variant='grayBlue'
          >
            Refresh Link
          </Button>
          <Button
            onClick={handleCopyOnboardLink}
            disabled={isFetching || !onboardingLink}
            variant='primary'
          >
            Copy Link
          </Button>
        </div>
      </div>
    </FullScreenContentModal>
  )
}

export { OnboardingLinkModal }
