import { Breadcrumbs } from 'V2Components'
import { Typography } from 'procyon-ui'
import React from 'react'

function SummaryHeader({ breadCrumbsItems = [], rsrcName, actions, Icon = null }) {
  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-4 p-4 pb-6'>
      <Breadcrumbs breadCrumbs={breadCrumbsItems} />
      <div className='mt-4 flex justify-between items-center'>
        <Typography className='flex items-center gap-2' variant='h2'>
          {Icon && <Icon className='!rounded w-7 h-7' />}
          {rsrcName}
        </Typography>
        <div className='flex gap-4'>{actions && actions}</div>
      </div>
    </div>
  )
}

export { SummaryHeader }
