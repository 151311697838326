import { Box, Grid, makeStyles } from '@material-ui/core'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import StyledBox from 'Components/StyledBox'
import React from 'react'
import ListData from './Components/ListData'
import Progress from './Components/Progress'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  }
}))
const ProgressWithList = ({ title, progressData, listData, errorData }) => {
  const classes = useStyles()
  return (
    <StyledBox className={classes.root} globalClassName='borderBox' px='1.5rem' pt='1rem' pb='1.625rem'>
      <Box mb={1.438}>
        <CustomTypo variant='h6'>{title}</CustomTypo>
      </Box>
      {/* <Grid container direction='row'>
        <Grid xs={6}>
          <Progress
            errorData={errorData}
            Icon={progressData.Icon}
            iconSize={progressData.iconSize}
            thickness={progressData.thickness}
            size={progressData.size}
            color={progressData.color}
            count={progressData.count}
            currentCount={progressData.currentCount}
            meta={progressData.meta}
            texts={progressData.texts}
          />
        </Grid>
        <Grid xs={6} />
      </Grid> */}
      <ListData listData={listData} />
    </StyledBox>
  )
}

export default ProgressWithList
