import { Box, Grid, makeStyles } from '@material-ui/core'
import DashboardAccountSegment from 'Components/DashboardAccountSegment'
import React, { useEffect } from 'react'
import ActiveUsersGraph from './ActiveUsersGraph'
import DevicesSegment from './DevicesSegment'
import Resources from './Resources'
import SecurityAlerts from './SecurityAlerts'
import Tasks from './Tasks'
import UsersAndMaps from './UsersAndMaps/Index'
import { am4core } from 'Core'
import useIntro from 'Core/Walkthrough/useIntro'
const useStyle = makeStyles((theme) => ({
  root: {},
  logs: {
    marginBottom: theme.spacing()
  }
}))
const Container = ({ handleLogClick, serverList, appRolesList, userList, accountList, databases }) => {
  const classes = useStyle()
  useEffect(() => {
    am4core.system.isPaused = true
    setTimeout(() => {
      am4core.system.isPaused = false
    }, 100)
  }, [])
  const listCount = () => {
    const counts = {
      AWS: 0,
      GCP: 0,
      AZURE: 0
    }
    accountList.forEach(account => {
      counts[account.Spec.Type]++
    })
    counts.serverCount = serverList.length
    counts.roleCount = appRolesList.length
    counts.userCount = userList.length
    // counts.iamActionsCount = iamActions.length
    counts.databasesCount = databases.length
    counts.usersCount = userList.length
    return counts
  }
  useIntro()
  return (
    <Box className={classes.root}>
      <Grid spacing={1} className={classes.logs} container>
        <Grid xs={6} item>
          <SecurityAlerts onLogClick={handleLogClick} />
        </Grid>
        <Grid xs={6} item>
          <Tasks onLogClick={handleLogClick} />
        </Grid>
      </Grid>
      <UsersAndMaps userCount={userList.length} />
      <Grid spacing={1} className={classes.logs} container>
        <Grid xs={6} item>
          <ActiveUsersGraph />
        </Grid>
        <Grid xs={6} item>
          <DevicesSegment />
        </Grid>
      </Grid>
      <DashboardAccountSegment
        counts={listCount()}
      />
      <Resources />
    </Box>
  )
}
export default React.memo(Container)
