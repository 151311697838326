import { useApplicationAccess } from 'features/applications'
import { useIAMRsrcAccess } from 'features/iamResources'
import { useTargetAccess } from 'features/targets'
// !TODO To be removed if deemed un-useful
export const useUserRsrcAccess = () => {
  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const { getUserApplicationAccessMap } = useApplicationAccess()
  const { getUserAccessTargetsMap } = useTargetAccess()

  /**
   * Get list of iam resources which the `user` can sign in
   * @param {*} user
   * @returns Array of IAM Resource's key which the `user` has access to.
   */
  const getUserAccessIAMRsrcs = (user) => {
    const iamRsrcsMap = getPolicySignInURLS(user)
    return Object.keys(iamRsrcsMap)
  }

  /**
   * Get all applications which the user has access to.
   * @param {*} user
   * @returns Array of applications which the `user` has access to.
   */
  const getUserAccessApps = (user) => {
    const map = getUserApplicationAccessMap(user)
    return Object.keys(map)
  }

  /**
   * Get all the targets which the `user` has access.
   * @param {*} user 
   * @returns Array of keys of all targets which the `user` has access to.
   */
  const getUserAccessTargets = (user) => {
    const map = getUserAccessTargetsMap(user)
    return Object.keys(map)
  }

  return { getUserAccessIAMRsrcs, getUserAccessApps, getUserAccessTargets }
}
