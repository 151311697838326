import { faMagnifyingGlass, faSave, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons'
import { cn, enqueueNotification } from 'Utils/Helpers'
import localforage from 'localforage'
import { Button, IconButton, TextInput, Tooltip, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const handleSearchKeySave = async (key, searchKey) => {
  await localforage.setItem(key, searchKey)
}

const getSearchKeySave = async (key) => {
  const searchKey = (await localforage.getItem(key)) || ''
  return searchKey
}
const AdvancedSearch = ({
  searchKey,
  setSearchKey,
  className = '',
  sx = {},
  placeholder = 'Search',
  width = '303px',
  suggestions = [],
  defaultSuggestions = [],
  filterSaveKey = '',
  autoSave = true
}) => {
  const [showSuggesstions, setShowSuggesstions] = useState(false)

  const getActiveTags = () => {
    const activeTags = []
    // Removes all extra whitespaces
    const ws = searchKey.replace(/\s+/g, ' ').trim()
    // Split using space
    const ss = ws.split(' ')
    ss.forEach((s) => {
      // For every spaces split using :
      const splits = s.split('!')
      // If s doen;t have :, return
      if (splits.length === 1) return
      activeTags.push(s)
    })
    return activeTags
  }
  const activeTags = getActiveTags()

  const isTagActive = (tag) => {
    return activeTags.find((e) => tag.template === e)
  }

  const handleSuggestionsClick = (suggs) => {
    if (isTagActive(suggs)) return
    const template = suggs.template
    const s = template.replace('$', searchKey)
    setSearchKey(s)
  }

  const handleSaveClick = () => {
    handleSearchKeySave(filterSaveKey, searchKey)
    enqueueNotification('Search filter saved successfully!', 'info')
  }

  useEffect(() => {
    if (autoSave) handleSearchKeySave(filterSaveKey, searchKey)
  }, [searchKey, autoSave])

  useEffect(() => {
    const init = async () => {
      const key = await getSearchKeySave(filterSaveKey)
      setSearchKey(key)
    }
    init()
  }, [])

  return (
    <div
      onMouseEnter={() => setShowSuggesstions(true)}
      onMouseLeave={() => setShowSuggesstions(false)}
      className='relative'
    >
      <div>
        <TextInput
          value={searchKey}
          className={`${className || ''}  shadow`}
          rows={1}
          icon={searchKey ? faXmarkCircle : faMagnifyingGlass}
          iconStyle={{ cursor: searchKey ? 'pointer' : 'initial' }}
          onClickEndIcon={(e) => {
            setSearchKey('')
            handleSearchKeySave(filterSaveKey, '')
          }}
          iconPosition={searchKey ? 'end' : 'start'}
          onChange={(e) => {
            setSearchKey(e.target.value)
          }}
          placeholder={placeholder}
          sx={{ width, ...sx }}
        />
        {activeTags.length > 0 && !autoSave && (
          <Tooltip title='Save Filter' arrow>
            <IconButton
              onClick={handleSaveClick}
              variant='clear'
              size='sm'
              className='!absolute !text-gray-500 top-[18%] right-8 !bg-transparent !h-6 !w-6'
              icon={faSave}
            />
          </Tooltip>
        )}
        {showSuggesstions && defaultSuggestions.length > 0 && activeTags.length === 0 && (
          <div className='z-50 bg-white absolute shadow rounded top-[35px] w-[100%] text-left max-h-[400px] overflow-auto'>
            {defaultSuggestions.map((e) => (
              <SuggestionButton data={e} handleSuggestionsClick={handleSuggestionsClick} />
            ))}
          </div>
        )}
        {showSuggesstions && suggestions.length > 0 && (
          <div className='z-50 bg-white border absolute shadow rounded top-[35px] w-[100%] text-left max-h-[400px] overflow-auto'>
            {suggestions.map((e) => (
              <SuggestionButton data={e} handleSuggestionsClick={handleSuggestionsClick} />
            ))}
          </div>
        )}
        {showSuggesstions && !suggestions.length && activeTags.length > 0 && (
          <div className=' p-2 z-50 bg-white absolute shadow rounded top-[35px] w-[100%] text-left max-h-[400px] overflow-auto'>
            <Typography variant='body-regular' className='!italic'>
              No suggestions available for this tag.
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

const SuggestionButton = ({ data, handleSuggestionsClick }) => (
  <Button
    onClick={() => handleSuggestionsClick(data)}
    key={data.title}
    className='!w-[100%] !block !justify-start !h-[auto] !rounded-none'
    variant='clear'
  >
    <Typography className='!text-left !normal-case' variant='body-regular'>
      {data.title}
    </Typography>
    {data.caption && (
      <Typography className='!text-left !normal-case mt-1' variant='caption-regular'>
        {data.caption}
      </Typography>
    )}
  </Button>
)

export { AdvancedSearch }
