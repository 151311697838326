import { deepClone, enqueueNotification, errorHandler, getUserInfo } from '../Utils/Helpers'
import ApiProvider from './ApiProvider'
import { deleteRequest, getRequest, postRequest, putRequest } from './NetworkRequest'

export class userApis {
  static async fetchUserPreference (user) {
    try {
      const u = user || getUserInfo()
      const response = await new ApiProvider('userpreferences').setInstance(u).get()
      if (response && response.data) return [response.data]
    } catch (error) {
      errorHandler(error, 'getUserData', 'userApis.js')
    }
  }

  static async createUserPreference (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = await postRequest(apiKey, 'userpreferences', tenant, data, true)
      return response
    } catch (error) {
      errorHandler(error, 'create userpreferences', 'SERVER.js')
    }
  }

  static async updateUserPreference (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await putRequest(apiKey, `userpreferences/${org}`, data.ObjectMeta.Name, tenant, data, 'api')
      return response
    } catch (error) {
      errorHandler(error, 'userpreferences', 'SERVER.js')
    }
  }

  /** Create or update a user preferences */
  static async setUserPreferrences (Name, userpreferences, change = {}) {
    const currentUserPreferences = userpreferences || []
    try {
      /** Find target preference */
      console.log('[boot](curr):', currentUserPreferences)
      const targetPreferences = currentUserPreferences.find((e) => e.ObjectMeta.Name === Name)
      if (targetPreferences) {
        /** Preference exists. Update preference */
        const payload = deepClone(targetPreferences)
        const previousAttributes = payload.Spec.Attributes.Map
        payload.Spec.Attributes.Map = { ...previousAttributes, ...change }
        return await userApis.updateUserPreference(payload)
      } else {
        /** Preference does not exist. Create preference */
        const payload = { ObjectMeta: { Name }, Spec: { Attributes: { Map: change } } }
        return await userApis.createUserPreference(payload)
      }
    } catch (error) {
      errorHandler(error, 'setUserPreferrences', 'userApis.js')
    }
  }

  static async getRoleBasedPermissions (role = 'user') {
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = getRequest(apiKey, `permissions/${role}`, tenant, 'api')
      return response
    } catch (error) {
      errorHandler(error, 'getUserData', 'userApis.js')
    }
  }

  /** fetch all group List */
  static async getGroupList (byPassUser = false) {
    try {
      const user = byPassUser || getUserInfo()
      const { tenant, apiKey } = user
      const response = await getRequest(apiKey, 'groups', tenant)
      return response?.data?.Groups
    } catch (error) {
      errorHandler(error, 'getUserData', 'SERVER.js')
    }
  }

  static async deleteGroup (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await deleteRequest(apiKey, `groups/${org}`, data.ObjectMeta.Name, tenant, 'api')
      enqueueNotification('Group Deleted', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'updateGroup', 'SERVER.js')
    }
  }

  /** all idp Apis */
  static async getIdpList () {
    try {
      const user = getUserInfo()
      const { tenant, apiKey } = user
      const response = getRequest(apiKey, 'idproviders', tenant)

      return response
    } catch (error) {
      errorHandler(error, 'getUserData', 'SERVER.js')
    }
  }

  static async updateIdp (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await putRequest(apiKey, `idproviders/${org}`, data.ObjectMeta.Name, tenant, data, 'api')

      return response
    } catch (error) {
      errorHandler(error, 'updateIdp', 'SERVER.js')
    }
  }

  static async deleteIdp (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await deleteRequest(apiKey, `idproviders/${org}`, data.ObjectMeta.Name, tenant, 'api')
      enqueueNotification('Idp Deleted', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'updateIdp', 'SERVER.js')
    }
  }

  static async updateDevice (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await putRequest(apiKey, `devices/${org}`, data.ObjectMeta.Name, tenant, data, 'api')
      return response
    } catch (error) {
      errorHandler(error, 'updateDevice', 'SERVER.js')
    }
  }

  static async deleteDevice (data) {
    try {
      const user = getUserInfo()
      const { tenant, apiKey, org } = user
      const response = await deleteRequest(apiKey, `devices/${org}`, data.ObjectMeta.Name, tenant, 'api')
      enqueueNotification('Device Deleted', 'success')
      return response
    } catch (error) {
      errorHandler(error, 'updateDevice', 'SERVER.js')
    }
  }
}
