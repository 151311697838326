import React from 'react'
import { IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

function SnackMessage ({ openSnack, setOpenSnack, backgroundColor = 'black', vertical = 'top' }) {
  return (
    <Snackbar
      color='secondary'
      anchorOrigin={{
        vertical,
        horizontal: 'right'
      }}
      ContentProps={{ style: { backgroundColor } }}
      open={openSnack}
      autoHideDuration={3000}
      onClose={() => setOpenSnack(false)}
      message={openSnack}
      action={
        <>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setOpenSnack(false)}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
        }
    />
  )
}

export default SnackMessage
