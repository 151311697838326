import { createRsrcKey, getRsrcAccountRef } from 'features/resources'
import { cloudAccountCheck } from '../api'

/**
 * Check if the account credentials are valid or not.
 * @param {any} accountObj The account object to validate
 * @returns {Promise<boolean>} `true` if the credentials are valid
 */
export const isValidAccountCredentials = async (accountObj) => {
  const accountCheck = await cloudAccountCheck(accountObj)
  return accountCheck?.Status.Status !== 0 && !accountCheck?.Status.Error
}

/**
 * Check if the `account` is a child account.
 * @param {object} account
 */
export const isChildAccount = (account) => {
  if (!account) return false
  const { ParentAccount } = account.Spec
  return ParentAccount.RefKind === 'Account' && ParentAccount.RefID !== '0'
}

export const isAccountResource = (account, rsrc) => {
  const a = createRsrcKey(account)
  const b = createRsrcKey(getRsrcAccountRef(rsrc))
  return a === b
}

export const uiGetAccountTargetIconType = (account) => {
  if (account.Spec.Type === 'AWS') {
    let type = ''
    type = 'AWS_APPLICATION'
    if (account.Spec.AwsSpec.OrgAccount) type = 'AWS_ORG'
    else if (isChildAccount(account)) type = 'AWS_CHILD'
    return type
  }

  if (account.Spec.Type === 'GCP') return 'GOOGLE_APPLICATION'

  return 'AZURE_APPLICATION'
}

//* ====================================/ CLOUD SPECIFIC UTIL FILES EXPORT /====================================//
export * from './aws'
export * from './gcp'
export * from './azure'
