import { TabGroup } from 'procyon-ui'
import React from 'react'
import { CloudStoreList } from '../CloudStoreList'
import { AllCloudStores } from '../AllCloudStores'
import useAppView from 'Core/Hooks/useAppView'
import { createRsrcKey, useResourceDetails } from 'features/resources'

const CloudStore = () => {
  const { rsrc } = useResourceDetails()
  const { viewMode } = useAppView()
  const tabs = [
    {
      label: 'Active Data Cloud',
      tabContent: <CloudStoreList />
    }
  ]

  if (viewMode == 'admin') {
    tabs.push({
      label: 'Add Data Cloud',
      tabContent: <AllCloudStores />
    })
  }

  return (
    <>
      <TabGroup
        activeIndex={0}
        key={createRsrcKey(rsrc)}
        onChangeTab={function noRefCheck() {}}
        tabs={tabs}
      />
    </>
  )
}

export { CloudStore }
