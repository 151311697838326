import { TabGroup } from 'procyon-ui'
import React from 'react'
import { Active } from './components/tabs'
import { AllIntegration } from './components/tabs'
import { Typography } from '@mui/material'

const Integrations = () => {
  return (
    <div>
		<div className='my-6'>
			<Typography variant='h5'>
			Integrations
		</Typography>
		</div>
      
      <TabGroup
        onChangeTab={function noRefCheck() {}}
        tabs={[
          {
            label: 'Active',
            tabContent: <Active />
          },
          {
            label: 'All Integrations',
            tabContent: <AllIntegration />
          }
        ]}
      />
    </div>
  )
}

export { Integrations }
