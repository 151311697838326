import { ActivityLogs } from 'features/activityLogs'
import { useAppDetailsContext } from 'features/applications'
import React from 'react'

const ActivityLogsTab = () => {
  const { app } = useAppDetailsContext()

  return (
    <div>
      <ActivityLogs rsrc={app} />
    </div>
  )
}

export { ActivityLogsTab }
