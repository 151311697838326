import React, { useCallback, useRef, useState } from 'react'

export const ActiveUserContext = React.createContext(null)
const ActiveUserContextProvider = ({ children }) => {
  const [tableData, setTableData] = useState([])
  const [selectedActiveUser, setSelectedActiveUser] = useState(null)
  const selectedUserRef = useRef(null)

  const condition = useCallback((row, userName, i) => userName ? row.userName === userName : i === 0, [])

  const updateTableData = useCallback((data) => {
    const userName = data.length > 0 ? selectedUserRef.current || data[0].userName : null
    const tableData = data.map((e, index) => ({ ...e, selected: condition(e, userName, index) }))
    selectedUserRef.current = userName
    setTableData(tableData)
    setSelectedActiveUser(userName)
  }, [condition])

  const updateSelectedActiveUser = useCallback((userName) => {
    setSelectedActiveUser(userName)
    selectedUserRef.current = userName
    const data = tableData.map((e, index) => ({ ...e, selected: condition(e, userName, index) }))
    setTableData(data)
  }, [tableData, condition])

  return (
    <ActiveUserContext.Provider value={{ tableData, updateSelectedActiveUser, selectedActiveUser, updateTableData }}>
      {children}
    </ActiveUserContext.Provider>
  )
}

export default ActiveUserContextProvider
