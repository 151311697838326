import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { CreateIAMRoleView } from 'features/iamResources'
import React from 'react'
import { useHistory, useParams } from 'react-router'

function CreateIAMRole() {
  const history = useHistory()
  //@ts-ignore
  const { cloudType, roleName } = useParams()
  const canGoBack = useCanGoBack()

  return (
    <div className='-ml-4 -mr-5 -mt-5'>
      <CreateIAMRoleView
        breadCrumbs={[
          {
            label: 'Roles',
            onClick() {
              history.goBack()
            }
          },
          { label: 'Create IAM Role', onClick() {} }
        ]}
        accountType={cloudType?.toUpperCase()}
        //@ts-ignore
        name={roleName}
        onCancel={() => history.goBack()}
        afterSubmit={() => {
          if (canGoBack) history.goBack()
          else history.push(`/admin/iam-roles/${cloudType?.toLowerCase()}`)
        }}
      />
    </div>
  )
}

export { CreateIAMRole }
