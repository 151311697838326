import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createIamActionToRsrcRelation } from 'V2Components'
import { useApplicationAccess } from 'features/applications'
import { IAMResourcesSliceNames, useIAMRsrcAccess } from 'features/iamResources'
import { getPolicyAttachedIAMActions } from 'features/policy'
import { createRef, createRsrcKey, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames, useTargetAccess } from 'features/targets'
import { isAdminUser, useUserDetailsContext } from 'features/users'
import _ from 'lodash'
import { useEffect } from 'react'

export function useAccessGraphTab() {
  const { user } = useUserDetailsContext()
  const { dispatchThunks, getObjectRef, slices } = useMultiSlice([
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames,
    'applicationList',
    'policyList',
    'iamActions',
    'groupList'
  ])

  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const { getUserAccessTargetsMap } = useTargetAccess()
  const { getUserApplicationAccessMap } = useApplicationAccess()

  const iamRsrcsMap = getPolicySignInURLS(user)
  const targetsMap = getUserAccessTargetsMap(user)
  const appsMap = getUserApplicationAccessMap(user)

  const uKey = createRsrcKey(user)

  const isEmpty = Object.keys({ ...iamRsrcsMap, ...targetsMap, ...appsMap }).length === 0

  const adminGroupRef = createRef(_.find(slices.groupList, { ObjectMeta: { Name: 'admin' } }))
  const isAdmin = (() => {
    return isAdminUser(user, adminGroupRef)
  })()

  const getIAMRsrcPolicys = () => {
    const policys = []
    const tempMap = {}
    for (const key in iamRsrcsMap) {
      iamRsrcsMap[key].policys.forEach((e) => {
        const refKey = createRsrcKey(e.ref)
        if (!tempMap[refKey]) {
          policys.push(getObjectRef(e.ref))
          tempMap[refKey] = true
        }
      })
    }

    return policys
  }

  const getTargetsPolicys = () => {
    const nodes = []
    const tempMap = {}
    for (const key in targetsMap) {
      targetsMap[key].forEach((policyRef) => {
        const refKey = createRsrcKey(policyRef)
        if (!tempMap[refKey]) {
          nodes.push(getObjectRef(policyRef))
          tempMap[refKey] = true
        }
      })
    }
    return nodes
  }

  const getAppsPolicys = () => {
    const nodes = []
    const tempMap = {}
    for (const key in appsMap) {
      appsMap[key].forEach((policyRef) => {
        const refKey = createRsrcKey(policyRef)
        if (!tempMap[refKey]) {
          nodes.push(getObjectRef(policyRef))
          tempMap[refKey] = true
        }
      })
    }

    return nodes
  }

  const getRsrcNodes = () => {
    const nodes = []
    const iamRsrcs = getObjectRef(Object.keys(iamRsrcsMap).map(reverseRsrcKey))
    const targets = getObjectRef(Object.keys(targetsMap).map(reverseRsrcKey))
    const apps = getObjectRef(Object.keys(appsMap).map(reverseRsrcKey))
    nodes.push(...iamRsrcs, ...targets, ...apps)
    return nodes
  }

  const getGroupNodes = () => {
    const nodes = []
    // Stores policys which was issued to this user via groups
    const policyIssuedToGroupMap = {}

    const appsPolicy = getAppsPolicys()
    const iamRsrcPolicy = getIAMRsrcPolicys()
    const targetsPolicy = getTargetsPolicys()
    const list = [...iamRsrcPolicy, ...targetsPolicy, ...appsPolicy]
    const userGroupsRefKeys = (user.Spec.Groups.ObjectRef || []).map(createRsrcKey)

    list.forEach((policy) => {
      const IssuedTo = policy.IssuedTo?.ObjectRef || []
      const userIssuedGroups = IssuedTo.filter((e) => {
        const refKey = createRsrcKey(e)
        const isIssuedToGroup = e.RefKind === 'Group' && userGroupsRefKeys.includes(refKey)
        return isIssuedToGroup
      })

      userIssuedGroups.forEach((g) => {
        const pKey = createRsrcKey(policy)
        const groupsRefs = policyIssuedToGroupMap[pKey] || []
        groupsRefs.push(createRsrcKey(g))
        policyIssuedToGroupMap[pKey] = groupsRefs
      })

      nodes.push(
        ...getObjectRef(
          userIssuedGroups.filter((e) => !nodes.find((n) => n.ObjectMeta.ID === e.RefID))
        )
      )
    })

    if (isAdmin) {
      nodes.push(getObjectRef(adminGroupRef))
    }

    return { nodes, policyIssuedToGroupMap }
  }

  const getPolicyNodes = () => {
    const nodes = []
    /**
     * Create Policy nodes of iam resources map
     */
    nodes.push(...getIAMRsrcPolicys())

    /**
     * Create policy nodes of targets map
     */
    nodes.push(...getTargetsPolicys())

    /**
     * Create policy nodes of appsmap
     */
    nodes.push(...getAppsPolicys())

    return nodes
  }

  const getIAMActionsNodes = () => {
    const tempMap = {}
    const iamActionsNodes = []
    // IAM Rsrcs policy have iam actions
    const policys = getIAMRsrcPolicys()
    policys.forEach((p) => {
      // 1. Get iam actions map object attached to the policy
      const actionsObj = getPolicyAttachedIAMActions(p)
      for (const key in actionsObj) {
        // key is the resource and value is the  array of attached iam actions' ref object
        const actions = actionsObj[key]
        actions.forEach((ref) => {
          // 2. Get the actual action object
          const action = getObjectRef(ref)
          const refKey = createRsrcKey(action)
          // 3. If action is not already pushed to the nodes array, then push it
          if (!tempMap[refKey] && action) {
            iamActionsNodes.push(action)
            tempMap[refKey] = true
          }
        })
      }
    })

    return iamActionsNodes
  }

  const getNodes = () => {
    const policyNodes = getPolicyNodes()
    const rsrcNodes = getRsrcNodes()
    const iamActionsNodes = getIAMActionsNodes()
    if (!iamActionsNodes.length) return [[user], getGroupNodes().nodes, policyNodes, rsrcNodes]
    else return [[user], getGroupNodes().nodes, policyNodes, iamActionsNodes, rsrcNodes]
  }

  const getNodesRelationObject = () => {
    const relationObject = {}
    // Relation from user to policy
    const policyNodes = getPolicyNodes()
    /**
     * `policyIssuedToGroupMap`: object whose key is the policy key and value is an array of
     *  group's ref to which the policy is issued to (only groups to which the `user` is in is added)
     */
    const { policyIssuedToGroupMap } = getGroupNodes()
    // Point from user -> [policys] or user -> [groups]-> [policy]
    policyNodes.forEach((policy) => {
      const pKey = createRsrcKey(policy)
      if (policyIssuedToGroupMap[pKey]) {
        const issuedGroups = policyIssuedToGroupMap[pKey]
        issuedGroups.forEach((g) => {
          const userRelations = relationObject[uKey] || []
          const groupRelations = relationObject[g] || []
          // Add respective relations
          userRelations.push(g)
          groupRelations.push(pKey)
          // Point from user -> group
          relationObject[uKey] = userRelations
          // Point from group -> policy
          relationObject[g] = groupRelations
        })
      } else {
        // Since this policy has no groups issued which gives access, hence point directly from user -> policy
        const existingRelations = relationObject[uKey] || []
        existingRelations.push(pKey)
        relationObject[uKey] = existingRelations
      }
    })

    if (isAdmin) {
      // Point from user -> admin group
      const userRelations = relationObject[uKey] || []
      userRelations.push(createRsrcKey(adminGroupRef))
      relationObject[uKey] = userRelations
    }

    for (const targetKey in targetsMap) {
      if (isAdmin) {
        // Point from admin group -> Target(targetKey)
        const aRelations = relationObject[createRsrcKey(adminGroupRef)] || []
        aRelations.push(targetKey)
        relationObject[createRsrcKey(adminGroupRef)] = aRelations
      } else {
        targetsMap[targetKey].forEach((policyRef) => {
          const pKey = createRsrcKey(policyRef)
          // Point from policy(pKey) -> Target(targetKey)
          const pRelations = relationObject[pKey] || []
          pRelations.push(targetKey)
          relationObject[pKey] = pRelations
        })
      }
    }

    // Relation from policy -> iam actions if any and relation from iam action to iam resource
    const policys = getIAMRsrcPolicys()
    const iamRsrcRelations = createIamActionToRsrcRelation(policys)


    // Relation from policy to targets
    for (const targetKey in targetsMap) {
      targetsMap[targetKey] &&
        targetsMap[targetKey].forEach((policyRef) => {
          const pKey = createRsrcKey(policyRef)
          // Point from policy(pKey) -> Target(targetKey)
          const pRelations = relationObject[pKey] || []
          pRelations.push(targetKey)
          relationObject[pKey] = pRelations
        })
    }

    // Relation from policy to apps
    for (const appKey in appsMap) {
      if (isAdmin) {
        // Point from admin group -> App(appKey)
        const aRelations = relationObject[createRsrcKey(adminGroupRef)] || []
        aRelations.push(appKey)
        relationObject[createRsrcKey(adminGroupRef)] = aRelations
      } else {
        appsMap[appKey].forEach((policyRef) => {
          const pKey = createRsrcKey(policyRef)
          // Point from policy(pKey) -> App(appKey)
          const pRelations = relationObject[pKey] || []
          pRelations.push(appKey)
          relationObject[pKey] = pRelations
        })
      }
    }

    return {...relationObject, ...iamRsrcRelations}
  }

  useEffect(() => {
    dispatchThunks({ skipWhenLoaded: true })
  }, [])

  return { getNodes, getNodesRelationObject, isEmpty }
}
