import store from 'infra/redux/store'
import { apiClient } from 'infra/api'
import * as sls from 'infra/redux/reducers/slices/slices'
import { startPolling } from 'infra/utils'
import { delay } from 'Utils/Helpers'

// Constant which holds slice name -> slice endpoint
export const SLICENAME_TO_ENDPOINT_MAP = {}
// Constant which holds slice endpoint -> slice name
export const ENDPOINT_TO_SLICENAME_MAP = {}

/**
 * Parse all thunks and slices names.
 */
for (const property in sls) {
  const { name, endpoint } = sls[property]
  SLICENAME_TO_ENDPOINT_MAP[name] = endpoint
  ENDPOINT_TO_SLICENAME_MAP[endpoint] = name
}

/**
 *
 * @param {import("types").Endpoints} endpoint
 */
export const reduxApiClient = (endpoint) => {
  const client = apiClient(endpoint)
  const dispatch = store.dispatch

  const fetchActions = {
    upsert(data) {
      const sliceName = ENDPOINT_TO_SLICENAME_MAP[endpoint]
      dispatch({
        type: `UPDATE_SLICE_MAP/${sliceName}`,
        payload: data
      })
    },
    updateAll(data) {
      const sliceName = ENDPOINT_TO_SLICENAME_MAP[endpoint]
      dispatch({
        type: `UPDATE_SLICE_MAP/ALL/${sliceName}`,
        payload: data
      })
    },
    delete(data) {
      const sliceName = ENDPOINT_TO_SLICENAME_MAP[endpoint]
      dispatch({
        type: `DELETE/${sliceName}`,
        payload: data
      })
    }
  }

  return {
    /**
     *
     * @param {import('axios').AxiosRequestConfig['params']} params
     */
    async getByDataParams(params) {
      const data = await client.getByParams(params)
      fetchActions.upsert(data)
      return data
    },
    /**
     * @param {import('infra/api').GetAllApiOptions} options
     * @returns {Promise.<any[]>}
     */
    async getAll(options) {
      const data = await client.getAll(options)
      fetchActions.updateAll(data)
      return data
    },
    async create(obj) {
      const data = await client.create(obj)
      fetchActions.upsert(data)
      return data
    },
    async update(obj) {
      const data = await client.update(obj)
      console.log('[pac](upsert):', data)
      fetchActions.upsert(data)
      return data
    },
    async delete(obj) {
      await client.delete(obj)
      fetchActions.delete(obj)
      return obj
    },
    /**
     * The function starts network polling for the specified object resource, until `checkFn` returns true or `stopPollingFn` is called.
     * @param {string|object} object The object/string to poll
     * @param {((any) => boolean)} testFn The function which will be called with response and must return true or false
     * @param {string=} key
     */
    startRsrcObjectPolling(object, testFn, key) {
      if (!object) return
      let objectName = typeof object === 'string' ? object : object.ObjectMeta.Name

      // If key is present then, check if the polling with that key is already running.
      if (key) {
        if (POLLING_KEYS.includes(key)) return
        // Push to polling keys
        POLLING_KEYS.push(key)
      }
      const apiFn = async () => {
        const obj = await client.getByParams({
          'ObjectMeta.Name': objectName
        })
        await delay(3000)
        return obj?.[0]
      }

      const doFn = (object) => {
        // Remove the polling keys once polling is completed
        POLLING_KEYS = POLLING_KEYS.filter((e) => e !== key)
        fetchActions.upsert(object)
      }

      startPolling(apiFn, testFn, doFn)
    }
  }
}

// Variable to store currently running polling
let POLLING_KEYS = []
