import { useURLQuery } from 'Core/Hooks/useURLQuery'
import React, { createContext, useContext } from 'react'

/**
 * @type {{
 * routeTag: string
 * tagKey: string
 * tagValue: string
 * scopeKinds: import('types').ObjectMetaKinds[],
 * tagType: 'tag' | 'ptag'
 * }} TagsURLContextType
 */

const INITIAL_VALUE = {
  routeTag: '',
  tagKey: '',
  tagValue: '',
  scopeKinds: [],
  tagType: 'tag'
}

const TagsURLQueryContext = createContext(INITIAL_VALUE)

/**
 * The context parses the url query.
 *
 * @param {*} param0
 * @returns
 */
const TagsURLQueryProvider = ({ children, scopeRouteKey = 'scope' }) => {
  const search = useURLQuery()

  const getDataFromURLQuery = () => {
    try {
      let tag = ''
      let tagType = ''
      const entries = search.entries()
      for (const element of entries) {
        tagType = element[0]
        tag = element[1]
        break
      }
      const tagScopeKinds = JSON.parse(search.get(scopeRouteKey))
      const split = tag.split('!')
      const tagKey = split[0].trim()
      const tagValue = split[1]?.trim() || ''

      return { tag, tagKey, tagValue, tagScopeKinds, tagType }
    } catch (error) {
      return {}
    }
  }

  const { tag, tagKey, tagValue, tagScopeKinds, tagType } = getDataFromURLQuery()

  return (
    <TagsURLQueryContext.Provider
      value={{
        routeTag: tag,
        tagKey,
        tagValue,
        scopeKinds: tagScopeKinds,
        //@ts-ignore
        tagType
      }}
    >
      {children}
    </TagsURLQueryContext.Provider>
  )
}

export const useTagsURLQueryContext = () => useContext(TagsURLQueryContext)

export { TagsURLQueryProvider }
