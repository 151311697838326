import store from 'infra/redux/store'
import _ from 'lodash'
import { useCallback, useEffect } from 'react'
import useMultiSlice from './useMultiSlice'
import * as sls from 'infra/redux/reducers/slices/slices'
import { MapKindToSliceName } from 'infra/redux/utils/constants'

// This is used to override the slice.name with custom name
const EXCEPTIONS_MAP = {
  credentialTypesList: 'credentialTypeList'
}

const ASYNC_THUNK_MAP = {}

/**
 * Parse all thunks and slices names.
 */
for (const property in sls) {
  const { name: _name, fetchAsyncObjectThunk } = sls[property]
  const name = EXCEPTIONS_MAP[_name] || _name
  ASYNC_THUNK_MAP[name] = fetchAsyncObjectThunk
}

function getRefKindKeys(object = {}, Kind, _path = '', _keys = []) {
  let Keys = _keys
  for (const key in object) {
    const dest = object[key]
    if (_.isObject(dest)) {
      // @ts-ignore
      if (dest.RefKind === Kind) Keys.push(_path ? `${_path}.${key}` : key)
      else Keys = [...Keys, ...getRefKindKeys(dest, Kind, _path ? `${_path}.${key}` : key)]
    }
  }
  return Keys
}

function transformKeysToObject(keys = '', value = {}) {
  const tempObject = {}
  let container = tempObject
  keys.split('.').map((k, i, values) => {
    container = container[k] = i == values.length - 1 ? value : {}
  })
  return tempObject
}

export function findAndMergeObjects(source, Kind, slices) {
  const state = _.isObject(slices) ? slices : store.getState()
  const Spec = source.Spec || {}
  const _Kind = _.isArray(Kind) ? Kind : [Kind]
  let KeysList = []
  _Kind.forEach((kind) => {
    KeysList = [...KeysList, { Kind: kind, keys: getRefKindKeys(Spec, kind) }]
  })
  const clonedSource = _.cloneDeep(source)
  let finalOutputMerge = { ...clonedSource }
  KeysList.forEach((key) => {
    key.keys.forEach((_key) => {
      const RefID = _.get(clonedSource, `Spec.${_key}.RefID`)
      let itemsList = []
      if (_.isArray(state[MapKindToSliceName[key.Kind]]))
        itemsList = state[MapKindToSliceName[key.Kind]]
      else itemsList = state[MapKindToSliceName[key.Kind]]?.data || []
      const refObject = itemsList.find((e) => e.ObjectMeta.ID === RefID) || null
      const pathObject = transformKeysToObject(`Spec.${_key}.RefObject`, refObject)
      finalOutputMerge = _.merge(finalOutputMerge, pathObject)
    })
  })
  return finalOutputMerge
}
/**
 * @deprecated Use useMultiSlice
 * @param {*} sourceObject
 * @param {*} RefKinds
 * @param {*} slices
 * @returns
 */
export function getObjectRef(sourceObject, RefKinds, slices) {
  return findAndMergeObjects(sourceObject, RefKinds, slices)
}

/**
 * @param {{RefID: string, RefKind: string}[]} refs
 * @returns {object[]}
 */
export const getMultiObjecRefFlat = (refs) => {
  const list = []
  if (!_.isArray(refs)) throw new Error('Expected array[refs] got: ' + typeof refs)
  refs.forEach((e) => {
    const refObj = getObjectRefFlat(e)
    if (refObj) list.push(refObj)
  })
  return list
}

/**
 * @param {{RefID: string, RefKind: string} | {RefID: string, RefKind: string}[]} ref
 * @returns {object | undefined}
 */
export const getObjectRefFlat = (ref) => {
  if (!ref) return
  const state = store.getState()
  let list = []

  /**
   * When ref is an array of ref
   */
  if (_.isArray(ref)) {
    /**
     * MAP based search and find for multiple refs
     */
    const items = []
    const notFoundRefs = []

    ref.forEach(({ RefKind, RefID }) => {
      const k = createRsrcKey({ RefID, RefKind })
      const sliceName = MapKindToSliceName[RefKind]
      if (!sliceName) return
      const map = state[sliceName]?.map || {}
      if (map[k]) {
        // object exists hence push
        items.push(map[k])
      } else {
        if (!state[sliceName]?.fetchingMapKeys[createRsrcKey({ RefID, RefKind })]) {
          store.dispatch(ASYNC_THUNK_MAP[sliceName]({ RefID, RefKind }))
        }

        // The ref was not found in the map hence push to not-found
        notFoundRefs.push({ RefKind, RefID })
      }
    })

    // Any not-found refs is sent to find from the slice data[]
    return [...items, ...getMultiObjecRefFlat(notFoundRefs)]
  }

  /**
   * ref is not an array
   */

  if (!MapKindToSliceName[ref.RefKind]) return

  /**
   * MAP based search and find
   */
  const map = state[MapKindToSliceName[ref.RefKind]].map || {}
  if (map[createRsrcKey(ref)]) {
    return map[createRsrcKey(ref)]
  }

  const sliceName = MapKindToSliceName[ref.RefKind]
  if (!state[MapKindToSliceName[ref.RefKind]].fetchingMapKeys[createRsrcKey(ref)]) {
    store.dispatch(ASYNC_THUNK_MAP[sliceName](ref))
  }

  // Was not able to find from map hence find from slice

  list = _.isArray(state[MapKindToSliceName[ref.RefKind]])
    ? state[MapKindToSliceName[ref.RefKind]]
    : state[MapKindToSliceName[ref.RefKind]]?.data

  if (!list) return
  const resource = list.find((e) => e.ObjectMeta.ID === ref.RefID)
  return resource
}

export default function useObjectRelations(sliceNames = []) {
  const { slices, dispatchThunks } = useMultiSlice(sliceNames)
  const populateRef = useCallback(
    (sourceObject, RefKinds) => {
      return getObjectRef(sourceObject, RefKinds, slices)
    },
    [slices]
  )

  useEffect(() => {
    dispatchThunks()
  }, [])

  return { populateRef }
}

export const createRsrcKey = (rsrc) => {
  if (!rsrc) return null
  if (rsrc.RefKind) return `${rsrc.RefKind}+${rsrc.RefID}`
  if (!rsrc.ObjectMeta?.Kind) return null
  return `${rsrc.ObjectMeta.Kind}+${rsrc.ObjectMeta.ID}`
}
