import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { getPolicyIssuedToEntities, getPolicyResourcesRefs } from 'features/policy/utils'
import { createRef, getResourceCloudType } from 'features/resources'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import React, { useRef, useState } from 'react'

const useSlices = createDataSelectorHook([
  'userList',
  'groupList',
  'serviceAccounts',
  'accountList'
])

const EditPolicyIssuedTo = ({ policy, onCancel, onSuccess }) => {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { slices, sliceNames } = useSlices()
  const { getObjectRef } = useObjectRef(sliceNames)

  const isPopulated = useRef(false)

  const getEntities = () => {
    const rsrcRef = getPolicyResourcesRefs(policy)[0]
    const cloudType = getResourceCloudType(getObjectRef(rsrcRef))

    const list = [
      ...slices.userList,
      ...slices.groupList,
      ...slices.serviceAccounts.filter((sa) => sa.Spec.Type === cloudType)
    ]

    return list
  }

  if (!isPopulated.current) {
    const issuedTo = getObjectRef(getPolicyIssuedToEntities(policy))
    if (issuedTo.length) {
      isPopulated.current = true
      setSelectedEntities(issuedTo)
    }
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      await handlePolicyIssuedToUpdate({ policy, entities: selectedEntities })
      enqueueNotification('Policy issued to entities has been updated successfully!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Error updating the entities of the policy.', 'info')
    } finally {
      setIsLoading(false)
    }
  }
  useHTMLBodyScrollbar(true)
  return (
    <FullScreenContentModal width='auto'>
      <EntityPicker
        errorMessage={selectedEntities.length === 0 && 'Select at least one entity.'}
        continueText='Save'
        entities={getEntities()}
        onCancel={onCancel}
        onContinue={handleSave}
        selectedEntities={selectedEntities}
        setSelectedEntities={setSelectedEntities}
        title={`Edit Access of Entities`}
      />
      <LoadingFeedback loading={isLoading} caption='Please wait..' message='Updating Policy' />
    </FullScreenContentModal>
  )
}

const handlePolicyIssuedToUpdate = async ({ policy, entities }) => {
  const refs = entities.map(createRef)
  const clone = structuredClone(policy)

  clone.ObjectMeta.RdOwners.ObjectRef = refs
  clone.IssuedTo.ObjectRef = refs
  clone.Dirty.IssuedTo = true

  await reduxApiClient('pacpolicys').update(clone)
}

export { EditPolicyIssuedTo }
