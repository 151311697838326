import { useUser } from 'Core/Hooks/useUser'
import { AwsRsrcType } from 'features/IdentityAnalyzer'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import JSONbig from 'json-bigint'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { ReactFlowGraph } from 'V2Components'

const useSlices = createDataSelectorHook(['iamUsers'])

const IdaGraph = () => {
  const { accountRefID, kind } = useParams()
  const { slices } = useSlices()
  const { user } = useUser()
  const [responseGraphData, setResponseGraphData] = useState([])
  const [nodesMeta, setNodesMeta] = useState({})
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const nodeSet = useRef(new Map())
  const awsAccountRefIDMap = useRef(new Map())
  const userObj = _.find(slices.iamUsers, { ObjectMeta: { ID: accountRefID } })

  useEffect(() => {
    if (accountRefID) {
      getGraphData()
    }
  }, [accountRefID])

  useEffect(() => {
    buildGraphData()
  }, [responseGraphData, nodesMeta])

  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications' ||
            'ResizeObserver loop limit exceeded'
        )
      ) {
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }
    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  // Generate a unique key based on RefID and RefKind
  const getNodeKey = (refID, refKind) => `${refID}-${refKind}`

  const getGraphData = async () => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_ACCOUNT_GRAPH',
        IdentityGraphSpec: {
          RefID: accountRefID,
          RefKind: kind,
          Level: 4
        }
      }
    }
    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      const parsedData = JSONbig.parse(response?.Spec?.IdentityGraphSpec.Graph)
      setResponseGraphData(parsedData)

      const iamUserNode = parsedData.find(
        (item) => item.FromNode.RefKind === kind || item.ToNode.RefKind === kind
      )

      if (iamUserNode) {
        const nodeId = (
          iamUserNode.FromNode.RefKind === kind
            ? iamUserNode.FromNode.RefID
            : iamUserNode.ToNode.RefID
        ).toString()

        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [nodeId]: {
            ...prevMeta[nodeId],
            highlight: false
          }
        }))
      }
    } catch (error) {
      console.error('Error fetching graph data:', error)
    }
  }

  const getIamUserNextGraphData = async (refID) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_IDENTITY_GRAPH',
        IdentityGraphSpec: {
          RefID: refID,
          RefKind: kind === 'IamFederatedUser' ? 'IamRole' : kind,
          Level: 4
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      return JSONbig.parse(response?.Spec?.IdentityGraphSpec.Graph)
    } catch (error) {
      console.error('Error fetching IamUser graph data:', error)
      return null
    }
  }

  const getExpandableRsrc = async (refID, parentRefID, rsrcName) => {
    const payload = {
      ObjectMeta: {
        Tenant: user.tenant,
        Namespace: user.org,
        Name: userObj?.Spec?.Name
      },
      Spec: {
        Type: 'GET_RESOURCES',
        ResourceSpec: {
          Account: {
            RefID: parentRefID,
            RefKind: 'Account'
          },
          limit: 50,
          offset: 0,
          ResourceType: rsrcName
        }
      }
    }

    try {
      const response = await reduxApiClient('analyze-identity').create(payload)
      const nodes = JSONbig.parse(response?.Spec?.ResourceSpec.Graph)
      return nodes
    } catch (error) {
      console.error('Error fetching resource data:', error)
      return null
    }
  }

  const fetchNextResourcesPath = async (id, awsAccountRefID, rsrcName, kind) => {
    const resourceData = await getExpandableRsrc(id, awsAccountRefID, rsrcName)
    if (resourceData) {
      const newEdges = resourceData.map((node) => ({
        FromNode: {
          RefID: id,
          RefKind: kind,
          Type: rsrcName,
          Name: nodeSet.current.get(id)?.label || `Node ${id}`
        },
        ToNode: {
          ...node,
          Type: rsrcName
        }
      }))

      setResponseGraphData((prevData) => [...prevData, ...newEdges])

      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [id]: {
            ...prevMeta[id],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const fetchNextIamUserPath = async (iamUserId) => {
    const iamUserData = await getIamUserNextGraphData(iamUserId)
    if (iamUserData) {
      setResponseGraphData((prevData) => [...prevData, ...iamUserData])

      requestAnimationFrame(() => {
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(iamUserId, kind)]: {
            ...prevMeta[getNodeKey(iamUserId, kind)],
            isLoading: false,
            isExpanded: true,
            isCollapsed: false
          }
        }))
      })
    }
  }

  const handleExpandNodeCall = async (id, kind, rsrcName) => {
    const nodeMeta = nodesMeta[getNodeKey(id, kind)] || {}
    const isExpanded = nodeMeta.isExpanded || false

    let newMeta = {
      ...nodeMeta,
      isLoading: true
    }

    setNodesMeta((prevMeta) => ({
      ...prevMeta,
      [getNodeKey(id, kind)]: newMeta
    }))

    try {
      if (isExpanded) {
        newMeta = {
          ...nodeMeta,
          isCollapsed: true,
          isExpanded: false,
          isLoading: false
        }
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(id, kind)]: newMeta
        }))
      } else {
        if (
          kind === 'IamUser' ||
          kind === 'IamGroup' ||
          kind === 'IamRole' ||
          kind === 'IamFederatedUser'
        ) {
          await fetchNextIamUserPath(id.toString())
        } else if (kind === 'ExpandableNode') {
          const nodeData = nodeSet.current.get(getNodeKey(id, kind))
          const awsAccountRefID = nodeData.awsAccountRefID
          await fetchNextResourcesPath(id, awsAccountRefID, rsrcName, kind)
        }

        newMeta = {
          ...nodeMeta,
          isLoading: false,
          isExpanded: true,
          isCollapsed: false
        }
        setNodesMeta((prevMeta) => ({
          ...prevMeta,
          [getNodeKey(id, kind)]: newMeta
        }))
      }
    } catch (error) {
      console.error('Error during expand/collapse:', error)
    }
  }

  const getAWSResourceIcon = (type) => {
    if (AwsRsrcType[type]) {
      return 'All_Resource'
    } else {
      return type
    }
  }

  const getIsCollapsibleState = (refkind) => {
    return (
      refkind === 'IamUser' ||
      refkind === 'ExpandableNode' ||
      (refkind === 'IamGroup' && kind === 'IamGroup') ||
      (refkind === 'IamRole' && kind === 'IamRole') ||
      (refkind === 'IamRole' && kind === 'IamFederatedUser')
    )
  }

  const generateUniqueKey = (frefID, frefKind, trefID, trefKind) =>
    `${frefID}-${frefKind}-${trefID}-${trefKind}`

  const buildGraphData = () => {
    setIsLoading(true)

    const graphData = []
    const arrows = []
    nodeSet.current = new Map()
    awsAccountRefIDMap.current = new Map()

    const getNodeType = (refKind, toname, fromname, type) => {
      switch (refKind) {
        case 'Account':
          return 'AWS'
        case 'IamUser':
          return 'USER'
        case 'IamGroup':
          return 'USERGROUPS'
        case 'IamRole':
          return 'ROLE'
        case 'IamFederatedUser':
          return 'FEDERATED_USER'
        case 'AccessKey':
          return 'ACCESS_KEY'
        case 'IamPolicy':
          return 'POLICY'
        case 'ExpandableNode':
          return getAWSResourceIcon(toname)
        case 'AwsResource':
          return type
        default:
          return 'All_Resource'
      }
    }

    const nodeLayers = {
      Account: [],
      IamUser: [],
      IamGroup: [],
      IamFederatedUser: [],
      IamRole: [],
      IamPolicy: [],
      AccessKey: [],
      Resources: [],
      ExpandableNode: [],
      EmptyNode: [],
      AwsResource: []
    }

    const addToLayer = (refKind, nodeData) => {
      nodeLayers[refKind]?.push(nodeData)
    }

    if (kind === 'IamUser' && !nodeSet.current.has(getNodeKey('dummy-user1', kind))) {
      const dummyUserNode = {
        id: getNodeKey('dummy-user1', kind),
        label: userObj?.Spec?.Name?.toString() || 'User',
        key: generateUniqueKey('dummy-user1', kind, '1', '1'),
        type: 'USER',
        collapsible: false,
        description: '',
        data: { meta: nodesMeta[getNodeKey('dummy-user1', kind)] || {} }
      }

      graphData.push([dummyUserNode])
      nodeSet.current.set(getNodeKey('dummy-user1', kind), dummyUserNode)
    }

    const awsAccounts = responseGraphData.filter(
      (item) =>
        item.FromNode.RefKind === 'Account' && item.FromNode.Properties?.ACCOUNT_TYPE === 'AWS'
    )

    if (awsAccounts.length > 1 && !nodeSet.current.has(getNodeKey('dummy-aws', 'AWS'))) {
      const dummyAwsNode = {
        id: getNodeKey('dummy-aws', 'AWS'),
        label: '',
        type: 'AWS',
        key: generateUniqueKey('dummy-aws', 'AWS', '2', '2'),
        collapsible: false,
        description: 'AWS accounts',
        data: { meta: nodesMeta[getNodeKey('dummy-aws', 'AWS')] || {} }
      }

      graphData.push([dummyAwsNode])
      nodeSet.current.set(getNodeKey('dummy-aws', 'AWS'), dummyAwsNode)
      arrows.push({
        startID: getNodeKey('dummy-user1', kind),
        endID: getNodeKey('dummy-aws', 'AWS')
      })

      awsAccounts.forEach((item) => {
        const { FromNode, ToNode } = item
        const fromNodeKey = getNodeKey(FromNode.RefID, FromNode.RefKind)

        if (!nodeSet.current.has(fromNodeKey)) {
          const fromNodeData = {
            id: fromNodeKey,
            key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
            label: FromNode.Name?.toString() || `Node ${FromNode.RefID}`,
            type: getNodeType(FromNode.RefKind),
            collapsible: getIsCollapsibleState(FromNode.RefKind),
            description: FromNode.Name?.toString() || `Description for ${FromNode.RefID}`,
            data: { meta: nodesMeta[fromNodeKey] || {} }
          }

          addToLayer(FromNode.RefKind, fromNodeData)
          nodeSet.current.set(fromNodeKey, fromNodeData)
          arrows.push({ startID: getNodeKey('dummy-aws', 'AWS'), endID: fromNodeKey })
        }
      })
    }

    // Further node processing...
    responseGraphData.forEach((item) => {
      const { FromNode, ToNode } = item
      const fromNodeKey = getNodeKey(FromNode.RefID, FromNode.RefKind)
      const toNodeKey = getNodeKey(ToNode.RefID, ToNode.RefKind)

      let fromNodeAwsAccountRefID = awsAccountRefIDMap.current.get(FromNode.RefID)
      if (!fromNodeAwsAccountRefID) {
        if (FromNode.RefKind === 'Account' && FromNode.Properties?.ACCOUNT_TYPE === 'AWS') {
          fromNodeAwsAccountRefID = FromNode.RefID
        } else {
          fromNodeAwsAccountRefID = awsAccountRefIDMap.current.get(FromNode.RefID) || null
        }
        awsAccountRefIDMap.current.set(FromNode.RefID, fromNodeAwsAccountRefID)
      }

      let toNodeAwsAccountRefID = awsAccountRefIDMap.current.get(ToNode.RefID)
      if (!toNodeAwsAccountRefID) {
        if (ToNode.RefKind === 'Account' && ToNode.Properties?.ACCOUNT_TYPE === 'AWS') {
          toNodeAwsAccountRefID = ToNode.RefID
        } else {
          toNodeAwsAccountRefID = fromNodeAwsAccountRefID
        }
        awsAccountRefIDMap.current.set(ToNode.RefID, toNodeAwsAccountRefID)
      }

      if (!nodeSet.current.has(fromNodeKey)) {
        const fromNodeData = {
          id: fromNodeKey,
          key: generateUniqueKey(FromNode.RefID, FromNode.RefKind, ToNode.RefID, ToNode.RefKind),
          label: FromNode.Name?.toString() || `Node ${FromNode.RefID}`,
          type: getNodeType(FromNode.RefKind),
          awsAccountRefID: toNodeAwsAccountRefID,
          collapsible: getIsCollapsibleState(FromNode.RefKind),
          onExpand: getIsCollapsibleState(FromNode.RefKind)
            ? () => handleExpandNodeCall(FromNode.RefID, FromNode.RefKind, FromNode.Name)
            : undefined,
          description: FromNode.Name?.toString() || `Description for ${FromNode.RefID}`,
          data: { meta: nodesMeta[fromNodeKey] || {} }
        }

        addToLayer(FromNode.RefKind, fromNodeData)
        nodeSet.current.set(fromNodeKey, fromNodeData)
      }

      if (!nodeSet.current.has(toNodeKey)) {
        const toNodeData = {
          id: toNodeKey,
          key: generateUniqueKey(ToNode.RefID, ToNode.RefKind, FromNode.RefID, FromNode.RefKind),
          label: ToNode.RefKind?.toString() || `Node ${ToNode.RefID}`,
          type: getNodeType(ToNode.RefKind, ToNode.Name, FromNode.RefKind, ToNode.Type),
          collapsible: getIsCollapsibleState(ToNode.RefKind),
          properties: ToNode.Properties,
          awsAccountRefID: fromNodeAwsAccountRefID,
          onExpand: getIsCollapsibleState(ToNode.RefKind)
            ? () => handleExpandNodeCall(ToNode.RefID, ToNode.RefKind, ToNode.Name)
            : undefined,
          description: ToNode.Name?.toString() || `Description for ${ToNode.RefID}`,
          data: { meta: nodesMeta[toNodeKey] || {} }
        }

        addToLayer(ToNode.RefKind, toNodeData)
        nodeSet.current.set(toNodeKey, toNodeData)
      }

      arrows.push({ startID: fromNodeKey, endID: toNodeKey })
    })

    graphData.push(...Object.values(nodeLayers))
    const cleanGraphData = graphData.filter((node) => node.length > 0)
    requestAnimationFrame(() => {
      if (cleanGraphData.length > 0) {
        setNodes(cleanGraphData)
      }
      if (arrows.length > 0) {
        setEdges(arrows)
      }
    })
    setIsLoading(false)
  }

  const graphComponents = useMemo(
    () => (
      <ReactFlowGraph
        nodes={nodes.map((layer) =>
          layer.map((node) => ({
            ...node,
            data: {
              ...node.data,
              meta: nodesMeta[node.id] || {}
            }
          }))
        )}
        edges={edges}
        onNodeExpandClick={(node) => {
          node.onExpand && node.onExpand()
        }}
        nodesMeta={nodesMeta}
      />
    ),
    [nodes, edges, nodesMeta, handleExpandNodeCall]
  )

  return <div>{!isLoading ? graphComponents : 'Loading....'}</div>
}

export { IdaGraph }
