import { getRequest } from 'API_LAYER/NetworkRequest'
import { errorHandler, getUserInfo } from './Helpers'

export async function getSAMLServers () {
  try {
    const user = getUserInfo()
    const { apiKey, tenant } = user
    const response = await getRequest(apiKey, 'samlservers', tenant)
    return response.data?.SamlServers
  } catch (error) {
    errorHandler(error, 'SAML Servers Error', 'getSamlServers')
  }
}
