// @ts-nocheck
import { Box, Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CustomTypography } from 'Components/CustomTypography'
import React, { useMemo } from 'react'

const useStyle = makeStyles((theme) => ({
  labelGrid: {
    paddingTop: props => (props.compact ? theme.spacing(1.3) : theme.spacing(1.5)) + 'px !important',
    paddingBottom: props => (props.noBottomPadding && '0 !important'),
    marginTop: props => (props.labelCenter ? theme.spacing(0.2) : 0),
    textAlign: props => props.labelAligment,
    paddingRight: theme.spacing(1) + 'px !important'
  },
  bodyGrid: {
    paddingLeft: theme.spacing(0) + 'px !important',
    paddingTop: props => (props.compact ? theme.spacing(0.89) : theme.spacing(1.5)) + 'px !important',
    paddingBottom: props => (props.noBottomPadding
      ? 0
      : (props.compact ? theme.spacing(0.3) : theme.spacing(0.75))) + 'px !important',
    borderBottomColor: props => (!props.hideBorder) && theme.palette.divider,
    borderBottomStyle: props => (!props.hideBorder) && 'solid',
    borderBottomWidth: props => (!props.hideBorder) && '1px'
  },
  text: {
    minHeight: theme.spacing(1.25)
  }
}))

const defaultClasses = { labelContainer: '', bodyContainer: '', divider: '', root: '' }

const ResourceDetailsListItem = ({ labelSize = 4, spacing = 1, bodySize = 8, label, body, labelAlignment = 'right', classes = defaultClasses, hideBorder = false, labelCenter, compact, noBottomPadding, ...rest }) => {
  const internalClasses = useStyle({ labelAlignment, hideBorder, labelCenter, compact, noBottomPadding })

  const getComponent = (toRender, typographyVariant, alignment) => {
    if (typeof toRender === 'string' || typeof toRender === 'number') return <CustomTypography.Body className={internalClasses.text} align={alignment} variant={typographyVariant}>{toRender}</CustomTypography.Body>
    return toRender
  }

  const labelComponent = useMemo(() => getComponent(label, 'subtitle2', labelAlignment), [label, labelAlignment])
  const bodyComponent = useMemo(() => getComponent(body, 'body2'), [body])

  return (
    <Box {...rest}>
      <Grid container direction='row' spacing={spacing} className={classes.root}>
        <Grid item lg={labelSize} className={classNames(internalClasses.labelGrid, classes.labelContainer)}>
          {labelComponent}
        </Grid>
        <Grid className={classNames(internalClasses.bodyGrid, classes.bodyContainer)} item lg={bodySize}>
          {bodyComponent}

        </Grid>
      </Grid>
    </Box>
  )
}

export default ResourceDetailsListItem
