import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

/**
 *
 * @param {import('@material-ui/core').SvgIconProps} props
 * @returns
 */
const WindowsIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 16 16' {...props}>
      <path
        d='M16 8.506V16l-8.709-1.195V8.506H16zm-9.519 0v6.188L0 13.804V8.506h6.481zm0-7.158v6.246H0v-5.33l.563-.079.203-.029 5.715-.808zM16 0v7.594H7.291V1.233L16 0z'
        fill-rule='evenodd'
        fill-opacity='.87'
      />
    </SvgIcon>
  )
})

export default WindowsIcon
