import useAppView from 'Core/Hooks/useAppView'
import { SummaryHeader, getResourceName, getRsrcIcon } from 'features/resources'
import { Button } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import useMultiSlice from 'Core/Hooks/useMultiSlice'

function MainHeader({ app, showSignIn, onEdit, onDelete, onAccessClick, disableAccessBtn = false, showPolicy, showEdit }) {
  const { appView } = useAppView()
  const {slices} = useMultiSlice(['githubAccount'])
  const [accountName, setAccountName] = useState('')

  useEffect(() => {
    const accountID = app?.Spec.Account.RefID
    const accountLink = _.find(slices.githubAccount, {ObjectMeta: {ID: accountID}})
    setAccountName(accountLink?.ObjectMeta?.Name)
  }, [slices])

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon('Repository')}
        rsrcName={app?.Spec?.RepositorySpec?.Name}
        actions={
          <div className='flex gap-4'>
            {showPolicy && (
              <Button size='md' onClick={onAccessClick} variant='grayBlue'>
              {appView === 'admin' ? 'Policy' : ''}
            </Button>
            )}
              

            { showEdit && (
              <Button onClick={onEdit} size='md' variant='gray'>
                Edit
              </Button>
             )}
            { appView === 'admin' && (
              <Button onClick={onDelete} size='md' variant='grayRed'>
                Delete
              </Button>
            )}
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Resource Catalog',
            link: `/${appView}/applications`
          },
          {
            label: accountName,
            link: `/${appView}/applications/${accountName}`
          },
          {
            label: getResourceName(app),
            link: null
          }
        ]}
      />
    </div>
  )
}

export { MainHeader }
