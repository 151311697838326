import { faDatabase, faHelmetSafety, faServer } from '@fortawesome/pro-light-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { IconInfoRow, SearchInput } from 'V2Components'
import { useCloudDetailsProvider } from 'features/clouds'
import {
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  isResourceManaged,
  reverseRsrcKey
} from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { IamRolesCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const AWSResources = () => {
  const [activeResourceType, setIsActive] = useState('AppRole')
  const [searchKey, setSearchKey] = useState('')

  const { cloud: account } = useCloudDetailsProvider()

  const { slices, getObjectRef } = useMultiSlice(TargetsSliceNames)

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const getResourcesRefs = () => {
    const counts = {
      AppRole: [],
      Server: [],
      Database: [],
      KubeCluster: [],
      KubeNamespace: []
    }
    const accKey = createRsrcKey(account)

    const list = [
      ...slices.databases,
      ...slices.appRolesList,
      ...slices.serverList,
      ...slices.kubeClusters,
      ...slices.kubeNamespaces
    ]

    list.forEach((d) => {
      const Kind = d.ObjectMeta.Kind
      const accountKey = createRsrcKey(getRsrcAccountRef(d))
      if (accKey === accountKey) {
        counts[Kind].push(createRsrcKey(d))
      }
    })

    return counts
  }

  const rsrcRefs = getResourcesRefs()

  const getResourceNodes = () => {
    const rsrcs = getObjectRef(rsrcRefs[activeResourceType].map(reverseRsrcKey))
    return applySearchQuery(rsrcs)
  }

  return (
    <div className='flex gap-4'>
      <div className='w-1/2'>
        <Typography variant='h4-regular' className='mb-4'>
          Select Resource Type
        </Typography>
        <IconInfoRow
          title='AppRoles'
          subtitle={`${rsrcRefs.AppRole.length} Total`}
          faIcon={faHelmetSafety}
          isActive={activeResourceType === 'AppRole'}
          onClick={() => setIsActive('AppRole')}
        />
        <IconInfoRow
          className='mt-4'
          title='Servers'
          subtitle={`${rsrcRefs.Server.length} Total`}
          faIcon={faServer}
          isActive={activeResourceType === 'Server'}
          onClick={() => setIsActive('Server')}
        />
        <IconInfoRow
          className='mt-4'
          title='Database'
          subtitle={`${rsrcRefs.Database.length} Total`}
          faIcon={faDatabase}
          isActive={activeResourceType === 'Database'}
          onClick={() => setIsActive('Database')}
        />
        <IconInfoRow
          className='mt-4'
          title='Kubernetes Cluster'
          subtitle={`${rsrcRefs.KubeCluster.length} Total`}
          Icon={<TargetIcon className='rounded' type='KUBE_CLUSTERS' />}
          isActive={activeResourceType === 'KubeCluster'}
          onClick={() => setIsActive('KubeCluster')}
        />
        <IconInfoRow
          className='mt-4'
          title='Kubernetes Namespace'
          subtitle={`${rsrcRefs.KubeNamespace.length} Total`}
          Icon={<TargetIcon className='rounded' type='KUBE_NAMESPACES' />}
          isActive={activeResourceType === 'KubeNamespace'}
          onClick={() => setIsActive('KubeNamespace')}
        />
      </div>
      <div className='w-1/2'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4-regular'> {activeResourceType} </Typography>
          <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
        </div>
        <div className='flex justify-between items-center mt-4'></div>
        <div className='mt-4'>
          {getResourceNodes().map((rsrc) => {
            return (
              <IamRolesCard
                isCheckedSwitch={isResourceManaged(rsrc)}
                key={createRsrcKey(rsrc)}
                className='mb-4'
                style={{ width: '100%' }}
                expandable={false}
                buttonType='none'
                description={addSpaces(rsrc.ObjectMeta.Kind)}
                icon={<TargetIcon type={IconTypeMap[rsrc.ObjectMeta.Kind]} />}
                title={getResourceName(rsrc)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const IconTypeMap = {
  AppRole: 'APPROLE',
  Server: 'SERVER',
  Database: 'DATABASE',
  KubeCluster: 'KUBE_CLUSTERS',
  KubeNamespace: 'KUBE_NAMESPACES'
}

export { AWSResources }
