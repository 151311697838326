import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { SearchInput } from 'V2Components'
import {
  AccountSelectionItem,
  Button,
  CloudIcon,
  Label,
  PickListContainer,
  PickListHeading,
  TargetIcon,
  ToggleCloudControl,
  Typography,
  UserGroupBar
} from 'procyon-ui'
import React, { useState } from 'react'
import { entityDataAdapters, getFilterDropdownItems } from './../../../../utils'
import _ from 'lodash'
import { createRsrcKey } from 'features/resources'
import { enqueueNotification } from 'Utils/Helpers'

function ManageAccount({
  title,
  entities,
  selectedEntities,
  setSelectedEntities,
  onCancel,
  onContinue,
  errorMessage,
  continueText = 'Update'
}) {
  const [searchKey, setSearchKey] = useState('')

  const [unSelectedEntitiesFilter, setUnSelectedEntitiesFilter] = useState('')
  const [selectedEntitiesFilter, setSelectedEntitiesFilter] = useState('')
  const [permissionValues, setPermissionValues] = useState({
    0: 'read'
  })

  const filterKindMap = {
    GOOGLE_APPLICATION: 'GCP',
    AZURE_APPLICATION: 'AZURE',
    AWS_APPLICATION: 'AWS'
  }

  const applyFilter = ({ data, searchKey, Kind, filterKind, isToggle }) => {
    if (!searchKey && !filterKind) return true
    if (searchKey) return JSON.stringify(data).toLowerCase().includes(searchKey)
    if (filterKind && filterKind === Kind) return true
    if (isToggle) return true
    if (filterKind && filterKind === filterKindMap[data.type]) return true
    return false
  }

  const getResource = (rsrc) => {
    const  Type  = rsrc?.Spec?.Type || rsrc?.type 
    if (entityDataAdapters[Type]) return entityDataAdapters[Type](rsrc)
  }

  const getSelectedEntitiesItems = () => {
    return selectedEntities.reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: selectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind,
        searchKey,
        isToggle: true
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  const getUnSelectedEntities = () => {
    return entities?.filter((e) => {
      const { Kind, ID } = e.ObjectMeta
      return !_.find(selectedEntities, { ObjectMeta: { Kind, ID } })
    })
  }

  const getUnSelectedEntitiesItems = () => {
    return getUnSelectedEntities()?.reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: selectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind,
        searchKey,
        isToggle: false
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  /** ====================================== HANDLERS GO HERE ====================================== */

  const handleSelectEntity = (entity) => {
    if (entity?.Spec?.Members?.Members?.length === 0) {
      enqueueNotification('Please add user under this team', 'error')
    } else {
      const updatedSelectedEntities = [entity, ...selectedEntities].map((entity, index) => {
        return {
          ...entity,
          permission: 'read'
        }
      })
      setSelectedEntities(updatedSelectedEntities)
    }
  }

  const handleDeSelectEntity = (entity) => {
    const entities = selectedEntities.filter((e) => createRsrcKey(entity) !== createRsrcKey(e))
    setSelectedEntities(entities)
  }

  /** ============================================================================================== */

  const handlePermissionChange = (index, value) => {
    setPermissionValues((prevState) => ({
      ...prevState,
      [index]: value
    }))
  }

  const getTypeAlter = (type) => {
    switch (type) {
      case 'GOOGLE_APPLICATION':
        return 'GCP'
      case 'AZURE_APPLICATION':
        return 'AZURE'
      case 'AWS_APPLICATION':
        return 'AWS'
      default:
        return null
    }
  }
 
  const handleUnlinkAll = (type) => {
    const selectedItems = getSelectedEntitiesItems();
    const filteredItems = selectedItems.filter(entity => entity.type !== type);
    const getSelectedEntities = entities.filter(item => filteredItems.some(selected => selected.caption === item.ObjectMeta.Name));
    setSelectedEntities(getSelectedEntities)
};

  
  const generateSelectedRow = () => {
    const selectedItems = getSelectedEntitiesItems();
    const groupedRows = {};
  
    // Group rows by type
    selectedItems.forEach((e, index) => {
      if (!groupedRows[e.type]) {
        groupedRows[e.type] = [];
      }
  
      groupedRows[e.type].push(
        <UserGroupBar
          key={index}
          selected
          assigned
          caption={e.name}
          name={e.caption}
          onChangePermissionsMenu={(e) => handlePermissionChange(index, e.target.value)}
          onClickRemoveButton={() => handleDeSelectEntity(e.refObject)}
          style={{
            width: '100%',
          }}
          type={e.type}
        />
      );
    });
  
    // Render grouped rows with headings and total row count, only if type length is greater than 0
    return Object.entries(groupedRows).map(([type, rows], typeIndex) => {
      const typeCount = rows.length;
      return (
        typeCount > 0 && (
          <div className='w-[100%]' key={typeIndex}>
            <div className='bg-[#e4ebef] flex justify-between p-2'>
              <p className='text-[#545b71] text-xs'>
                {getTypeAlter(type)} ({typeCount} account)
              </p>
              <span className='text-[#545b71] text-xs' onClick={() => handleUnlinkAll(type)}>
                Unlink All
              </span>
            </div>
  
            {rows.map((row, rowIndex) => (
              <div className='w-[100%]' key={rowIndex}>
                {row}
              </div>
            ))}
          </div>
        )
      );
    });
  };
  

  const getCloudCounts = () => {
    const selectedEntities = getSelectedEntitiesItems()
    let awsCount = 0
    let gcpCount = 0
    let azureCount = 0

    selectedEntities.forEach((item) => {
      switch (item.type) {
        case 'AWS_APPLICATION':
          awsCount++
          break
        case 'GOOGLE_APPLICATION':
          gcpCount++
          break
        case 'AZURE_APPLICATION':
          azureCount++
          break
        default:
          break
      }
    })

    return (
      <>
        <span className='flex justify-between items-center bg-[#f0f0f0] p-1 w-12 rounded-md'>
          <CloudIcon type='aws' />
          <span className='text-[blue] text-base py-1 pr-1.5 mb-1.2'>{awsCount}</span>
        </span>

        <span className='flex justify-between items-center bg-[#f0f0f0] p-1 w-12 rounded-md'>
          <CloudIcon type='gcp' />
          <span className='text-[blue] text-base py-1 pr-1.5 mb-1.2'>{gcpCount}</span>
        </span>

        <span className='flex justify-between items-center bg-[#f0f0f0] p-1 w-12 rounded-md'>
          <CloudIcon type='azure' />
          <span className='text-[blue] text-base py-1 pr-1.5 mb-1.2'>{azureCount}</span>
        </span>
      </>
    )
  }

  return (
    <div className='bg-white h-auto rounded-md w-[887px]'>
      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <Typography variant='h2'>{title}</Typography>
          <div className='flex justify-between gap-2'>{getCloudCounts()}</div>
        </div>
        <div className='flex justify-between mt-5'>
          <ToggleCloudControl
            ariaLabel='cloud-select'
            onChange={(_, values) => {
              if (values) {
                setSelectedEntitiesFilter(values)
              }
            }}
            multiSelect={false}
            options={['AWS', 'GCP', 'AZURE']}
            value={selectedEntitiesFilter}
          />
          <SearchInput
            searchKey={searchKey}
            onChange={setSearchKey}
            sx={{ width: '400px' }}
            className='ml-auto'
          />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-8 mt-7'>
        <div>
          <PickListHeading
            dropDownValue={unSelectedEntitiesFilter}
            onChange={(e) => setUnSelectedEntitiesFilter(e.target.value)}
            title={`Existing (${getUnSelectedEntitiesItems()?.length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%'
            }}
          >
            <>
              {getUnSelectedEntitiesItems()?.map((e) => (
                <AccountSelectionItem
                  accountName={e.caption}
                  icon={<TargetIcon type={e.type} />}
                  onClickAddButton={() => handleSelectEntity(e.refObject)}
                  onClickRemoveButton={function noRefCheck() {}}
                  parentAccountName={e.name}
                  width='100%'
                />
              ))}
            </>
          </PickListContainer>
        </div>
        <div>
          <PickListHeading
            onChange={(e) => setSelectedEntitiesFilter(e.target.value)}
            dropDownValue={selectedEntitiesFilter}
            title={`Added (${getSelectedEntitiesItems().length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%'
            }}
          >
            <>{generateSelectedRow()}</>
          </PickListContainer>
        </div>
      </div>
      <div className='flex justify-end gap-4 mt-6'>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
        <Button onClick={onContinue} variant='primary'>
          {continueText}
        </Button>
      </div>
    </div>
  )
}

export { ManageAccount }
