import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { Button, ResourceDropDownItem, TabGroup, Typography, UserGroupBar } from 'procyon-ui'
import React, { useState } from 'react'
import { getServiceAccountDisplayName } from 'Utils/PureHelperFuctions'
import { FullScreenModal } from 'V2Components'

function ResourcesListModal({
  showModal,
  onClose,
  resources = [],
  entities = [],
  title,
  caption,
  onViewDetailsClick,
  hideEntitiesTab = false,
  extendedTabs = [],
  initialTabIndex = null
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex || 0)
  const getEntitiesItems = () => {
    const map = {
      User: 'USER',
      Group: 'USERGROUPS',
      ServiceAccount: 'SERVICEACCOUNT',
      WorkloadIdentity: 'WORKLOAD'
    }

    return entities.map((e) => {
      const { Kind, Name } = e.ObjectMeta
      return {
        name: getResourceName(e),
        caption: Kind === 'ServiceAccount' ? getServiceAccountDisplayName(e) : Name,
        type: map[Kind],
        key: createRsrcKey(e)
      }
    })
  }

  const getResourceItems = () => {
    return resources.map((r) => {
      const Icon = getRsrcIcon(r)
      switch (r.ObjectMeta.Kind) {
        case 'AwsResource':
          return {
            Icon,
            title: getResourceName(r),
            caption: getIAMResourceDisplayType(r)
          }
        case 'KubeNamespace':
          return {
            Icon,
            title: getResourceName(r),
            caption: getIAMResourceDisplayType(r)
          }
        case 'AzureResource':
          return {
            Icon,
            title: getResourceName(r),
            caption: getIAMResourceDisplayType(r)
          }
        case 'GcpResource':
          return {
            Icon,
            title: r.Spec.DisplayName,
            caption: getIAMResourceDisplayType(r)
          }
        case 'RDPServer':
          return {
            Icon,
            title: getResourceName(r)
          }
        default:
          return {
            Icon,
            title: getResourceName(r)
          }
      }
    })
  }

  const getTabs = () => {
    const tabs = [
      {
        label: `Resources (${rsrcsItems.length})`,
        tabContent: (
          <div className='h-[400px]'>
            {rsrcsItems.map(({ Icon, caption, title }) => (
              <ResourceDropDownItem
                key={title + caption}
                icon={<Icon className='rounded h-8 w-8' />}
                onClick={function noRefCheck() {}}
                resourceName={title}
                resourceType={caption}
              />
            ))}
          </div>
        )
      }
    ]

    if (!hideEntitiesTab)
      tabs.push({
        label: `Entities (${entitiesItems.length})`,
        tabContent: (
          <div className='h-[400px]'>
            {entitiesItems.map(({ caption, name, type, key }) => (
              <UserGroupBar endButton={false} key={key} caption={caption} name={name} type={type} />
            ))}
          </div>
        )
      })

    return [...tabs, ...extendedTabs]
  }

  const rsrcsItems = getResourceItems()
  const entitiesItems = getEntitiesItems()

  return (
    <FullScreenModal showModal={showModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='!text-lg'>
              {title}
            </Typography>
            <div className='flex gap-4'>
              <Button onClick={onViewDetailsClick} variant='grayBlue'>
                View Details
              </Button>
              <Button onClick={onClose} variant='gray' icon={faClose} iconPosition='end'>
                Close
              </Button>
            </div>
          </div>
          <Typography variant='caption-regular'>{caption}</Typography>
          <div className='mt-6'>
            <TabGroup
              controllable
              activeIndex={activeTabIndex}
              onChangeTab={(e, v) => setActiveTabIndex(v)}
              tabs={getTabs()}
            />
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { ResourcesListModal }
