import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import React, { useCallback, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

export const BrowserContect = React.createContext(null)

export const ChannelBrowserContext = ({ children }) => {
  const _slices = useMemoOnce([
    'slackIntegrationList',
    'slackChannelList',
    'accountList',
    'jiraIntegrationsList',
    'jiraProjectList',
    'servicenowintegrations',
    'servicenowtables',
    'msTeamsIntegrations',
    'msTeams'
  ])
  const {
    slices: {
      slackIntegrationList,
      slackChannelList,
      jiraIntegrationsList,
      jiraProjectList,
      servicenowintegrations,
      servicenowtables,
      msTeamsIntegrations,
      msTeams
    },
    initiallyLoaded,
    selectDispatch,
    dispatchThunks
  } = useMultiSlice(_slices)

  const { integrationName, kind } = useParams()

  console.log(integrationName)
  const integrationsLists = useMemo(
    () => ({
      SlackIntegration: slackIntegrationList,
      JiraIntegration: jiraIntegrationsList,
      ServiceNowIntegration: servicenowintegrations,
      MsTeamsIntegration: msTeamsIntegrations
    }),
    [slackIntegrationList, jiraIntegrationsList, servicenowintegrations, msTeamsIntegrations]
  )

  const integrationtargetsLists = useMemo(
    () => ({
      SlackIntegration: slackChannelList,
      JiraIntegration: jiraProjectList,
      ServiceNowIntegration: servicenowtables,
      MsTeamsIntegration: msTeams
    }),
    [slackChannelList, jiraProjectList, servicenowtables, msTeamsIntegrations]
  )

  const integration = useMemo(() => {
    const sourceList = integrationsLists[kind]
    try {
      if (integrationName) {
        return sourceList.find((integration) => integration?.ObjectMeta?.Name === integrationName)
      }
      return null
    } catch (error) {
      console.log('error in ChannelBrowserContext', error)
      return null
    }
  }, [integrationName, kind, integrationsLists])

  const linkedChannels = useMemo(() => {
    const sourceList = integrationtargetsLists[kind]
    try {
      if (integration) {
        if (kind === 'SlackIntegration') {
          return sourceList.filter((channel) =>
            channel.Spec.SlackIntegration.ObjectRef.some(
              (ref) => ref.RefID === integration?.ObjectMeta?.ID
            )
          )
        }
        if (kind === 'JiraIntegration') {
          return sourceList.filter(
            (channel) => channel.Spec.JiraIntegration.RefID === integration?.ObjectMeta?.ID
          )
        }
        if (kind === 'ServiceNowIntegration') {
          return sourceList.filter((channel) =>
            channel.Spec.ServiceNowIntegration.ObjectRef.some(
              (ref) => ref.RefID === integration?.ObjectMeta?.ID
            )
          )
        }
        if (kind === 'MsTeamsIntegration') {
          return sourceList.filter((channel) =>
            channel.Spec.MsTeamsIntegration.ObjectRef.some(
              (ref) => ref.RefID === integration.ObjectMeta.ID
            )
          )
        }
      }
      return []
    } catch (error) {
      console.log('error in ChannelBrowserContext', error)
      return []
    }
  }, [integration, integrationtargetsLists, kind])

  const refresh = useCallback(() => {
    selectDispatch([
      'slackIntegrationList',
      'slackChannelList',
      'jiraIntegrationsList',
      'jiraProjectList',
      'notificationSubscriptions',
      'servicenowintegrations',
      'servicenowtables'
    ])
  }, [])

  useEffect(() => {
    refresh()
  }, [integrationName])

  useEffect(() => {
    dispatchThunks()
  }, [])

  const value = useMemo(
    () => ({ refresh, integration, integrationName, initiallyLoaded, linkedChannels }),
    [refresh, integration, integrationName, initiallyLoaded, linkedChannels]
  )
  return <BrowserContect.Provider value={value}>{children}</BrowserContect.Provider>
}
