import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EditableLabelContent, LabelContent } from 'V2Components'
import { useCloudDetailsProvider } from 'features/clouds'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Label, TargetIcon } from 'procyon-ui'
import React, { useState } from 'react'
import { SSHPublicKeyLabelContent } from '../SSHPublickeyLabelContent'
import { AccountNotificationChannels } from '../../../AccountNotificationChannels'

const GCPAccount = ({}) => {
  const { cloud: account } = useCloudDetailsProvider()
  const [description, setDescription] = useState(account.Spec.Description)

  const { slices } = useMultiSlice(['gcpResources', 'kubeClusters', 'kubeNamespaces'])

  const getResourceCounts = () => {
    const counts = {
      Folder: [],
      Project: [],
      KubeCluster: [],
      KubeNamespace: []
    }
    const aKey = createRsrcKey(account)

    // Get the kubeclusters
    slices.kubeClusters.forEach((e) => {
      if (e.Spec.CloudType === 'GCP' && createRsrcKey(e.Spec.Account) === createRsrcKey(account)) {
        counts.KubeCluster.push(createRsrcKey(e))
      }
    })
    // get the namespaces
    slices.kubeNamespaces.forEach((e) => {
      if (e.Spec.CloudType === 'GCP' && createRsrcKey(e.Spec.Account) === createRsrcKey(account)) {
        counts.KubeNamespace.push(createRsrcKey(e))
      }
    })

    slices.gcpResources.forEach((g) => {
      const accKey = createRsrcKey(g.Spec.Account)
      const dType = g.Spec.DisplayType
      if ((dType === 'Project' || dType === 'Folder') && aKey === accKey) {
        counts[dType].push(createRsrcKey(g))
      }
    })

    return counts
  }

  const refs = getResourceCounts()
  const namespace = account.ObjectMeta.Namespace

  return (
    <div>
      <LabelContent content={getResourceName(account)} title='Name' />
      <EditableLabelContent
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        title='Description'
        content={account.Spec.Description}
        editMode={false}
        showEmptyLabel
      />
      <LabelContent
        content={<Label text={namespace} variant={namespace === 'default' ? 'grayBlue' : 'gray'} />}
        title='Org'
      />
      <LabelContent
        title='Notification Channels'
        content={
          <div className='flex gap-2 flex-wrap'>
            <AccountNotificationChannels
              account={account}
              Fallback={<Label text='No Channels' />}
            />
          </div>
        }
      />
      <LabelContent
        content={
          <Label icon={<TargetIcon type='GCP_GOOGLE_PROJECT' />} text={refs.Project.length} />
        }
        title='Project'
      />
      <LabelContent
        content={<Label icon={<TargetIcon type='GCP_GOOGLE_FOLDER' />} text={refs.Folder.length} />}
        title='Folder'
      />
      <LabelContent
        content={
          <Label icon={<TargetIcon type='KUBE_CLUSTERS' />} text={refs.KubeCluster.length} />
        }
        title='Kubernetes Clusters'
      />
      <SSHPublicKeyLabelContent />
    </div>
  )
}

export { GCPAccount }
