import useAppView from 'Core/Hooks/useAppView'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { ErrorLabelContent, getResourceName } from 'features/resources'
import React, { useState } from 'react'
import _ from 'lodash'
import { ApiProvider } from 'Core'
import { enqueueNotification } from 'Utils/Helpers'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LabelContent } from 'V2Components'
import { Button, Label, SelectDropDown } from 'procyon-ui'
import { faSignalBars } from '@fortawesome/pro-solid-svg-icons'

const ServerResource = ({ rsrc, account }) => {
  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))
  const [saving, setSaving] = useState('')
  const { selectDispatch } = useMultiDispatch([])
  const { appView } = useAppView()
  const [dbMeshCluster, setDbMeshCluster] = useState((rsrc && rsrc.Spec.MeshCluster) || '')

  const getPrivateProxyClusterNames = () => {
    if (!rsrc) return []
    const clusterIDs = [dbMeshCluster]
    const MeshClusterNodes = rsrc.Spec.MeshClusterNodes.ObjectRef
    const existingSelectedClusterId =
      rsrc?.Spec?.MeshCluster !== ''
        ? slices.medusaNodes.find((node) => node.ClusterID === rsrc.Spec.MeshCluster)
        : {}

    const existingSelectedClusterRef = {
      RefKind: existingSelectedClusterId?.ObjectMeta?.Kind,
      RefID: existingSelectedClusterId?.ObjectMeta?.ID
    }

    const combinedClusterNodes = [...MeshClusterNodes]
    combinedClusterNodes.push(existingSelectedClusterRef)
    combinedClusterNodes?.forEach((element) => {
      const matchedNodeID = slices.medusaNodes.filter(
        (meta) => element.RefID === meta.ObjectMeta.ID
      )
      if (matchedNodeID.length > 0) {
        // Extract ClusterID values from matchedNodeID
        const clusterIDsFromMatchedNodes = matchedNodeID.map((node) => node)

        // Push the ClusterID values into clusterIDs
        clusterIDs.push(...clusterIDsFromMatchedNodes)
      }
    })

    const filteredArray = clusterIDs.filter((item) => typeof item === 'object')
    const updatedUniqueArray = []
    const uniqueNode = []
    filteredArray.map((item) => {
      if (uniqueNode.includes(item.ObjectMeta.ID)) {
        return
      } else {
        uniqueNode.push(item.ObjectMeta.ID)
        updatedUniqueArray.push(item)
      }
    })

    return [...updatedUniqueArray]
  }

  /**
   *
   * @param {'Password' | 'Username' | 'MeshCluster'} save
   */
  const handleSpecUpdate = async (save) => {
    try {
      const dataObj = structuredClone(rsrc)
      setSaving(save)
      if (save === 'MeshCluster') dataObj.Spec.MeshCluster = dbMeshCluster
      await new ApiProvider('servers').setInstance(dataObj).put()
      enqueueNotification(`Server ${save} successfully!`, 'info')
      selectDispatch(['databases'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  const handleRunCheck = () => {
    try {
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.MeshCluster = ''
      dataObj.Spec.MeshClusterNodes.ObjectRef = []
      new ApiProvider('servers').setInstance(dataObj).put()
      enqueueNotification(`Run checked successfully!`, 'info')
      selectDispatch(['databases'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  const { slices } = useMultiSlice(['medusaNodes'])

  if (!rsrc) return null

  return (
    <>
      <ErrorLabelContent rsrc={rsrc} />
      <LabelContent title='Name' content={getResourceName(rsrc)} />
      <LabelContent title='Region' content={rsrc.Spec.Region} showEmptyLabel />
      <LabelContent
        title='DNS Names'
        content={rsrc.Spec.DNSNames.Elems.join(', ')}
        showEmptyLabel
      />
      <LabelContent title='VPC ID' content={rsrc.Spec.VpcId} showEmptyLabel />
      <LabelContent title='Public IP' content={rsrc.Spec.PublicIP} />
      <LabelContent title='Private IP' content={rsrc.Spec.PrivateIP} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
      {appView === 'admin' ? (
        <LabelContent
          title='Cluster Name'
          content={
            <div className='flex justify-between items-center'>
              <SelectDropDown
                showHelpText={false}
                className='!min-w-[200px]'
                menuItems={[
                  ...getPrivateProxyClusterNames().map((e) => ({
                    label: e.ClusterID,
                    value: e.ClusterID
                  }))
                ]}
                onChange={(e) => e.target.value && setDbMeshCluster(e.target.value)}
                value={dbMeshCluster}
              />

              <div className='flex gap-3'>
                <Button variant='primary' onClick={() => handleRunCheck()}>
                  Run Check
                </Button>

                <Button onClick={() => handleSpecUpdate('MeshCluster')} variant='primary'>
                  Save
                </Button>
              </div>
            </div>
          }
        />
      ) : (
        <LabelContent
          title='Mesh Cluster'
          content={<Label className='!normal-case' text={dbMeshCluster} />}
        />
      )}
    </>
  )
}

export { ServerResource }
