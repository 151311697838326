import { LabelContent } from 'V2Components'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Label, Typography } from 'procyon-ui'
import React from 'react'
import { MeshClusterLabelContent } from '../MeshClusterLabelContent'
import useAppView from 'Core/Hooks/useAppView'

const KubeCluster = ({ rsrc, account }) => {
  const { appView } = useAppView()
  const AccountIcon = getRsrcIcon(account)

  return (
    <div>
      <LabelContent title='Name' content={getResourceName(rsrc)} />
      <LabelContent
        title='Account'
        content={
          <div className='flex gap-2 items-center'>
            {AccountIcon && <AccountIcon className='mr-2' />}
            <Typography variant='body-regular'>{getResourceName(account)}</Typography>
          </div>
        }
      />
      <LabelContent title='Cluster Endpoint' content={rsrc.Spec.ClusterEndpoint} />
      <LabelContent title='IAM Res' content={rsrc.Spec.IAMRes} />
      <LabelContent title='DNS Names' content={rsrc.Spec.DNSNames.Elems.join(', ')} />
      {appView === 'admin' ? (
        <MeshClusterLabelContent rsrc={rsrc} />
      ) : (
        <LabelContent title='Cluster' content={rsrc.Spec.MeshCluster} />
      )}
    </div>
  )
}

export { KubeCluster }
