import { Button, Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useChannelBrowser } from '../../../providers/hooks'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SelectChannel } from '../../Modal'
import { ApiProvider } from 'Core'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { createRsrcKey } from 'features/resources'
import { getFriendlyName } from 'Utils/FriendlyName'
import { useUser } from 'Core/Hooks/useUser'

const ServiceNowNotificationChannel = () => {
  const { linkedChannels, refresh, integration } = useChannelBrowser()
  const { slices, getObjectRef } = useMultiSlice(['notificationSubscriptions', 'servicenowtables'])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationType, setNotificationType] = useState('')
  const { isAgentlessMode } = useUser()

  const getPrivateNS = () => {
    return slices.notificationSubscriptions.find((e) => {
      const { SubscriptionFor, Subscriber } = e.Spec
      if (
        SubscriptionFor.RefID === '0' &&
        SubscriptionFor.RefKind === 'Server' &&
        Subscriber.RefKind === 'ServiceNowTable'
      )
        return true
    })
  }

  const getPrivateServerChannel = () => {
    const privtNotificationChannel = getPrivateNS()
    if (privtNotificationChannel) {
      const { Subscriber } = privtNotificationChannel.Spec
      let subsriberObj = slices.servicenowtables.find((e) => e.ObjectMeta.ID === Subscriber.RefID)
      return subsriberObj
    }
    return
  }

  const getApplicationTb = () => {
    return slices.notificationSubscriptions.find((e) => {
      const { SubscriptionFor } = e.Spec
      if (SubscriptionFor.RefID === '0' && SubscriptionFor.RefKind === 'Application') return true
    })
  }

  const getApplicationTable = () => {
    const ApplicationTable = getApplicationTb()
    if (ApplicationTable) {
      const { Subscriber } = ApplicationTable.Spec
      let subsriberObj = slices.servicenowtables.find((e) => e.ObjectMeta.ID === Subscriber.RefID)
      return subsriberObj
    }
    return
  }

  const privateServerChannel = getPrivateServerChannel()
  const ApplicationTaskTable = getApplicationTable()

  const getApplicationNotificationIcon = () => {
    return ApplicationTaskTable && Object.keys(ApplicationTaskTable).length > 1 ? (
      <TargetIcon type='SERVICENOW' />
    ) : (
      ''
    )
  }

  const getApplicationNotificationVariant = () => {
    return ApplicationTaskTable && Object.keys(ApplicationTaskTable).length > 1
      ? 'grayBlue'
      : 'gray'
  }

  const getApplicationChannelName = () => {
    return (
      <>
        {ApplicationTaskTable ? (
          <strong>{ApplicationTaskTable.Spec.Name}</strong>
        ) : (
          <em>No table selected yet</em>
        )}
      </>
    )
  }

  const getPrivateNotificationIcon = () => {
    return privateServerChannel && Object.keys(privateServerChannel).length > 1 ? (
      <TargetIcon type='SERVICENOW' />
    ) : (
      ''
    )
  }

  const getPrivateNotificationVariant = () => {
    return privateServerChannel && Object.keys(privateServerChannel).length > 1
      ? 'grayBlue'
      : 'gray'
  }

  const getPrivateChannelName = () => {
    return (
      <>
        {privateServerChannel ? (
          <strong>{privateServerChannel.Spec.Name}</strong>
        ) : (
          <em>No table selected yet</em>
        )}
      </>
    )
  }

  const handleModalClick = (type) => {
    setNotificationType(type)
    setShowModal(!showModal)
  }

  const onConfirmChannelChange = (selectedChannel, notificationType) => {
    if (notificationType === 'application') {
      applicationNotificationChannel(selectedChannel)
    } else {
      privateNotificationChannel(selectedChannel)
    }
  }

  const applicationNotificationChannel = async (selectedChannels) => {
    setShowModal(false)
    try {
      setLoading(true)
      if (!Object.keys(selectedChannels).length) {
        // If no channels are selected then, delete any NS if exists
        const privateNS = getApplicationTable()
        if (privateNS) {
          await new ApiProvider('notificationsubscriptions').setInstance(privateNS).delete()
          refresh()
          // onClose()
        }
        return
      }

      const selectedChannelRef = {
        RefKind: 'ServiceNowTable',
        RefID: selectedChannels.ObjectMeta.ID
      }
      const channelKey = createRsrcKey(selectedChannelRef)
      const privateNS = getApplicationTb()

      if (channelKey === createRsrcKey(ApplicationTaskTable)) return setLoading(false)

      if (privateNS) {
        // Delete if PRIVATE notification channel exists
        await new ApiProvider('notificationsubscriptions').setInstance(privateNS).delete()
      }

      const newNS = {
        ObjectMeta: {
          Name: `PRIVATE_${getFriendlyName()}`
        },
        Spec: {
          SubscriptionFor: {
            RefID: 0,
            RefKind: 'Application'
          },
          Subscriber: selectedChannelRef
        }
      }
      // Create NS
      await new ApiProvider('notificationsubscriptions').setInstance(newNS).post()
      enqueueNotification('Application Task Table changed successfully!', 'info')
      setLoading(false)
      refresh()
      // onClose()
    } catch (error) {
      enqueueNotification('Failed to change Notification Channel!', 'error')
      refresh()
    }
  }

  const privateNotificationChannel = async (selectedChannels) => {
    try {
      setLoading(true)
      if (!Object.keys(selectedChannels).length) {
        // If no channels are selected then, delete any NS if exists
        const privateNS = getPrivateNS()
        if (privateNS) {
          await new ApiProvider('notificationsubscriptions').setInstance(privateNS).delete()
          refresh()
          setShowModal(false)
        }
        return
      }
      const selectedChannelRef = {
        RefKind: 'ServiceNowTable',
        RefID: selectedChannels.ObjectMeta.ID
      }
      const channelKey = createRsrcKey(selectedChannelRef)
      const privateNS = getPrivateNS()

      if (channelKey === createRsrcKey(privateServerChannel)) return setLoading(false)

      if (privateNS) {
        // Delete if PRIVATE notification channel exists
        await new ApiProvider('notificationsubscriptions').setInstance(privateNS).delete()
      }

      const newNS = {
        ObjectMeta: {
          Name: `PRIVATE_${getFriendlyName()}`
        },
        Spec: {
          SubscriptionFor: {
            RefID: 0,
            RefKind: 'Server'
          },
          Subscriber: selectedChannelRef
        }
      }
      // Create NS
      await new ApiProvider('notificationsubscriptions').setInstance(newNS).post()
      enqueueNotification('Private Server Notification Channel changed successfully!', 'info')
      setShowModal(false)
      setLoading(false)
      refresh()
    } catch (error) {
      enqueueNotification('Failed to change Notification Channel!', 'error')
      refresh()
    }
  }

  return (
    <div className='mt-8'>
      {!isAgentlessMode && (
        <div className='border-b-2'>
          <Typography variant='h4-medium'>Application Task Label</Typography>
          <div className='flex justify-between items-center my-5'>
            <Label
              size='lg'
              icon={getApplicationNotificationIcon()}
              onClick={function noRefCheck() {}}
              text={getApplicationChannelName()}
              variant={getApplicationNotificationVariant()}
              style={{
                padding: '15px'
              }}
            />
            <Button size='md' variant='gray' onClick={() => handleModalClick('application')}>
              Change Task Table
            </Button>
          </div>
        </div>
      )}

      <div className='border-b-2 mt-10'>
        <Typography variant='h4-medium'>Private Server Task Label</Typography>
        <div className='flex justify-between items-center my-5'>
          <Label
            size='lg'
            icon={getPrivateNotificationIcon()}
            onClick={function noRefCheck() {}}
            text={getPrivateChannelName()}
            variant={getPrivateNotificationVariant()}
            style={{
              padding: '15px'
            }}
          />
          <Button size='md' variant='gray' onClick={() => handleModalClick('private')}>
            Change Task Table
          </Button>
        </div>
      </div>

      <SelectChannel
        showModal={showModal}
        setShowModal={setShowModal}
        onConfirmChannelChange={onConfirmChannelChange}
        notificationType={notificationType}
      />
    </div>
  )
}

export { ServiceNowNotificationChannel }
