import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { isAccountResource } from 'features/clouds'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { getPolicyResourcesRefs, isWorkloadIdentityPolicy } from 'features/policy/utils'
import {
  createRef,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getRsrcIcon
} from 'features/resources'
import { MapKindToSliceName, createDataSelectorHook, useObjectRef } from 'infra/redux'
import { ActionBar } from 'procyon-ui'
import React from 'react'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'

const useSlices = createDataSelectorHook([
  'awsResources',
  'gcpResources',
  'azureResources',
  'accountList'
])
const IAMAction = ({ searchKey }) => {
  const { slices, sliceNames } = useSlices()
  const { policy, selectedResources, setSelectedResources } = useEditPolicyModulesContext()

  const { getObjectRef } = useObjectRef(sliceNames)

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (data, queryKey) => {
      const { title, caption } = data
      const all = `${title}${caption}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const selectedRsrcKeys = selectedResources.map(createRsrcKey)

  const getResources = () => {
    const selectedRsrcsObjects = getObjectRef(selectedResources)
    const allRsrcs = [...selectedRsrcsObjects]
    const oneRsrc = getObjectRef(getPolicyResourcesRefs(policy)[0])
    if (!oneRsrc) return []
    const account = getObjectRef(getRsrcAccountRef(oneRsrc))
    if (!account) return []

    /**
     * Prepares resources list for workload id policy
     */
    if (isWorkloadIdentityPolicy(policy)) {
      let rsrcList = []
      const sliceName = MapKindToSliceName[oneRsrc.ObjectMeta.Kind]
      rsrcList = slices[sliceName]
      const fil = rsrcList.filter((e) => {
        if (selectedRsrcKeys.includes(createRsrcKey(e))) return false
        return isAccountResource(account, e)
      })

      /**
       * * must return from here to prevent overwritng below
       */
      return [...selectedRsrcsObjects, ...fil]
    }

    /**
     * Prepares resource list for IAM Resources policy
     */

    if (policy.OrgSpec.ActionMap.AwsIAM) {
      const fil = slices.awsResources.filter((e) => {
        if (selectedRsrcKeys.includes(createRsrcKey(e))) return false
        return isAccountResource(account, e)
      })

      allRsrcs.push(...fil)
    }

    if (policy.OrgSpec.ActionMap.AzureIAM) {
      const fil = slices.azureResources.filter((e) => {
        if (selectedRsrcKeys.includes(createRsrcKey(e))) return false
        return isAccountResource(account, e)
      })

      allRsrcs.push(...fil)
    }

    if (policy.OrgSpec.ActionMap.GcpIAM) {
      const fil = slices.gcpResources.filter((e) => {
        if (selectedRsrcKeys.includes(createRsrcKey(e))) return false
        return isAccountResource(account, e)
      })

      allRsrcs.push(...fil)
    }

    return allRsrcs
  }

  const getListItems = () => {
    const resources = getResources()

    return applySearchQuery(
      resources.map((r) => {
        const commonProps = {
          Icon: getRsrcIcon(r),
          key: createRsrcKey(r),
          title: getResourceName(r),
          caption: getIAMResourceDisplayType(r),
          selected: selectedRsrcKeys.includes(createRsrcKey(r)),
          ref: createRef(r)
        }
        return commonProps
      })
    )
  }

  const ResourceBar = ({ key, Icon, title, caption, selected, ref }) => (
    <ActionBar
      key={key}
      caption={caption}
      leftIcon={<Icon className='rounded-sm' />}
      name={title}
      onClickAddButton={() => {
        setSelectedResources((s) => [ref, ...s])
      }}
      onClickRemoveButton={() => {
        setSelectedResources((s) => s.filter((e) => createRsrcKey(e) !== key))
      }}
      selected={selected}
      assigned={selected}
    />
  )

  return <div>{getListItems().map(ResourceBar)}</div>
}

export { IAMAction }
