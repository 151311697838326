import { useObjectRef } from 'infra/redux'

export const useSalesForceUtils = () => {
  const { getObjectRef } = useObjectRef([
    'salesForceAccountList',
    'applicationList',
    'salesForcePermissionList'
  ])

  const getCRMEntityApplication = (crme) => {
    let crmeObj = crme
    if (crme?.RefKind) crmeObj = getObjectRef(crme)

    if (!crmeObj) return

    const sfAccount = getObjectRef(crmeObj.SalesforceAccount)
    if (!sfAccount) return

    const sfApp = getObjectRef(sfAccount.Application)
    return sfApp
  }
  return { getCRMEntityApplication }
}
