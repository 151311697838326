import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
const CloudDetailsContext = createContext({ cloud: null })

export const CloudDetailsProvider = ({ children }) => {
  const { slices } = useMultiSlice(['accountList'])

  //@ts-ignore
  const { cloudName } = useParams()

  const [cloudObject, setCloudObject] = useState(() => {
    const c = _.find(slices.accountList, { ObjectMeta: { Name: cloudName } })
    return c || null
  })

  const c = _.find(slices.accountList, { ObjectMeta: { Name: cloudName } })

  if (c && c !== cloudObject) setCloudObject(c)

  useEffect(() => {
    reduxApiClient('accounts').getByDataParams({ 'ObjectMeta.Name': cloudName })
  }, [])

  return (
    <CloudDetailsContext.Provider value={{ cloud: cloudObject }}>
      {!cloudObject && (
        <LoadingFeedback shrinked shadow={false} loading message='Fetching Cloud Object' />
      )}
      {cloudObject && children}
    </CloudDetailsContext.Provider>
  )
}

export const useCloudDetailsProvider = () => useContext(CloudDetailsContext)
