/**
 * @type {import("types").SliceNames[]}
 */
export const TargetsSliceNames = [
  'appRolesList',
  'serverList',
  'databases',
  'rdpServers',
  'kafkas',
  'kubeClusters',
  'kubeNamespaces',
  'rdpServers'
]

// Objects which require principal inputs from user during policy/access request
export const PRINCIPAL_OBJECT_KINDS = ['Server', 'Database', 'RDPServer']

//Objects which have credentials map
export const CREDENTIALS_OBJECT_KIND = ['Database', 'RDPServer']
// Target objects which can have multiple policy to themselves
export const MULTI_POLICY_TARGET_KINDS = ['Server', 'Database', 'RDPServer', 'KubeCluster', 'KubeNamespace']