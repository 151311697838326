import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { useUser } from 'Core/Hooks/useUser'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { getApprovalRequestResourcesRefs } from 'features/approvals'
import {
  CreatePolicyFlow,
  ResourceKindWithAccessLink,
  useIAMRsrcAccess
} from 'features/iamResources'
import { isPolicyIssuedToUser } from 'features/policy'
import {
  createRef,
  createRsrcKey,
  resourceAdapterMap,
  reverseRsrcKey,
  useAccessCartProvider
} from 'features/resources'
import { getAppRoleSignInURL } from 'features/targets'
import _ from 'lodash'
import { ResourceRow, Typography } from 'procyon-ui'
import { DataDetailsProvider } from 'providers/DataDetailsProvider'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ResourceListTreeView } from './ResourceListTreeView'

const ResourcesList = ({ ECSSubTypesRsrc, rsrc }) => {
  const { slices, getObjectRef } = useMultiSlice(['awsResources', 'approvalReqList'])
  const { viewMode } = useAppView()
  const history = useHistory()
  const { user, isAgentlessMode } = useUser()
  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const entityKey = useURLQuery().get('entity')
  const map = getPolicySignInURLS(user, true)
  const [selectedEcs, setSelectedEcs] = useState(ECSSubTypesRsrc[0])
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const signInUrlsMap = getPolicySignInURLS(user)
  const [views, setViews] = useState({
    showAccessRequestSubmission: false,
    showCreateIAMRole: false,
    showIAMRolesSelection: false,
    showUserGroupSelection: false
  })
  const { cartItems, isResourceInCart } = useAccessCartProvider()
  useEffect(() => {
    if (!selectedEcs && ECSSubTypesRsrc?.length) {
      setSelectedEcs(ECSSubTypesRsrc[0])
    }
  }, [])

  const handleRedirect = (rsrc) => {
    const account = getObjectRef(rsrc.Spec.Account)
    const data = resourceAdapterMap[rsrc.ObjectMeta.Kind]({
      rsrc,
      account
    })
    history.push(`/${viewMode}/resources/${data.RefKind.toLowerCase()}/${rsrc.ObjectMeta.Name}`)
  }

  const getResourceAttachedPolicys = () => {
    const policyKeysMap = {}
    if (!selectedEcs) return []

    const signInObj = map[createRsrcKey(selectedEcs)]
    signInObj?.policys?.map((e) => {
      policyKeysMap[createRsrcKey(e.ref)] = true
    })

    return getObjectRef(Object.keys(policyKeysMap).map(reverseRsrcKey))
  }

  const rsrcAttachedPolicys = getResourceAttachedPolicys()

  const getDotmenu = () => {
    const urls = [
      {
        action: () => handleRequest(),
        title: `${viewMode === 'admin' ? 'Create Policy' : 'Request'}`
      }
    ]
    const isRsrcInCart = isResourceInCart(createRef(selectedEcs))
    const handleAppRoleSignIn = (approle) => {
      const signInUrl = getAppRoleSignInURL(approle)
      openURLExternally(signInUrl)
    }

    const userPolicys = rsrcAttachedPolicys.filter((p) => isPolicyIssuedToUser(p, user))

    const isNamespace = selectedEcs?.ObjectMeta?.Kind === 'KubeNamespace'

    if (
      !ResourceKindWithAccessLink[selectedEcs.ObjectMeta.Kind] &&
      !isNamespace &&
      userPolicys.length > 0
    ) {
      userPolicys.forEach((e) => {
        const approle = getObjectRef(e.Spec.ActionMap.AssumeRole.PolicyRule[0]?.ObjectRef)
        urls.push({
          action: () => handleAppRoleSignIn(approle),
          title: e.GivenName
        })
      })
    }

    if (
      (ResourceKindWithAccessLink[selectedEcs.ObjectMeta.Kind] || isNamespace) &&
      userPolicys.length > 0
    ) {
      urls.push()
    }

    const copyProfileName = (ProfileName) => {
      try {
        if (!ProfileName)
          return enqueueNotification('Unable to find ProfileName for this role.', 'error')
        navigator.clipboard.writeText(ProfileName)
        enqueueNotification('Profile Name  copied : ' + ProfileName, 'info')
      } catch (error) {
        console.error(error)
      }
    }

    const copyCMD = (cmd) => {
      try {
        if (!cmd) return
        navigator.clipboard.writeText(cmd)
        enqueueNotification('Command Copied!' + cmd, 'info')
      } catch (error) {
        console.error(error)
      }
    }

    // show copy profile option
    if (selectedEcs.ObjectMeta.Kind === 'AwsResource' && accessState === 'signIn') {
      const rsrcKey = createRsrcKey(createRef(selectedEcs))
      const urlsMap = signInUrlsMap[rsrcKey].policys
      urlsMap.forEach(({ AppRoleID, name }) => {
        const approle = getObjectRef({ RefKind: 'AppRole', RefID: AppRoleID })
        if (approle) {
          urls.push(
            {
              action: () => copyProfileName(approle.ObjectMeta.Name),
              title: `${name} - Copy Profile Name`
            },
            {
              action: () =>
                copyCMD(
                  `${isAgentlessMode ? 'pcli' : 'pcloud'} exec ecs ${approle.ObjectMeta.Name} ${
                    rsrc?.Spec?.Name
                  } "${selectedEcs?.Spec?.Arn}" "${selectedEcs?.Spec?.Region}"`
                ),
              title: `Copy Command - ${name}`
            }
          )
        }
      })
    }

    return urls
  }

  /**
   * Get List of AwsResource and GcpResource refs which have pending approvals
   */
  const pendingRsrcRefs = useMemo(() => {
    const pendingRsrcs = []
    slices.approvalReqList.forEach((e) => {
      const resourcesRefs = getApprovalRequestResourcesRefs(e)
      if (e.Status === 'Pending') pendingRsrcs.push(...resourcesRefs)
    })
    return pendingRsrcs
  }, [slices.approvalReqList])

  const getRsrcAccessState = useCallback(
    (rsrcRef) => {
      let accessState = 'access'
      const rsrcKey = createRsrcKey(rsrcRef)
      /** Checking the approval state of the object */
      if (_.find(pendingRsrcRefs, rsrcRef)) accessState = 'waiting'
      else if (isResourceInCart(rsrcRef)) accessState = 'requested'
      else if (signInUrlsMap[rsrcKey]) accessState = 'signIn'
      return accessState
    },
    [signInUrlsMap, isResourceInCart, pendingRsrcRefs]
  )

  const handleRequest = () => {
    const account = getObjectRef(selectedEcs?.Spec?.Account)
    setSelectedRsrc({ ...createRef(selectedEcs), type: 'AWS' })
    setViews((s) => ({ ...s, showIAMRolesSelection: true }))
  }

  const accessState = getRsrcAccessState(createRef(selectedEcs))

  return (
    <div className='h-[100%] overflow-hidden'>
      <div className='flex gap-4'>
        <div className={cn('border-r w-1/2 border-[#D8DDE4]')}>
          <Typography variant='h3' className='my-5'>
            Select Resource
          </Typography>
          <ResourceListTreeView
            ecsRsrc={ECSSubTypesRsrc}
            onNodeClick={(subType) => {
              setSelectedEcs(subType)
            }}
            accessState={ECSSubTypesRsrc.map((ecsRsrc) => getRsrcAccessState(createRef(ecsRsrc)))}
          />
        </div>

        <div className='w-1/2'>
          <div className='border-b'>
            {selectedEcs && (
              <ResourceRow
                caption={selectedEcs.Spec.Type}
                dropdownMenuItems={[
                  {
                    icon: faEllipsisVertical,
                    //@ts-ignore
                    submenu: getDotmenu(),
                    title: ''
                  }
                ]}
                iconType={selectedEcs.Spec.Type.toUpperCase()}
                isGranted={accessState === 'signIn'}
                isNew
                onClickMoreInfoButton={() => handleRedirect(selectedEcs)}
                onClickRequestButton={() => handleRequest()}
                showMoreInfoButton
                showRequestButton={false}
                title={selectedEcs.Spec.Name}
                width='100%'
              />
            )}
          </div>
        </div>
      </div>
      <DataDetailsProvider sliceName='workloads' routeKey='entity' skip={!entityKey} urlSearchQuery>
        <CreatePolicyFlow
          enableUserGroupSelection={entityKey ? false : true}
          serviceAccountType={'AWS'}
          selectedRsrc={selectedRsrc}
          setSelectedRsrc={setSelectedEcs}
          views={views}
          setViews={setViews}
        />
      </DataDetailsProvider>
    </div>
  )
}

export { ResourcesList }
