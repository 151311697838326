import { ApiProvider } from 'Core'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import moment from 'moment'
import { Typography } from 'procyon-ui'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'

const ExtendExpiryModal = ({ onCancel, tenantApiKeyObj, onSuccess, newTimeDuration }) => {
  const handleSave = async () => {
    try {
      const clone = structuredClone(tenantApiKeyObj)
      clone.Spec.TimeDuration = Number(newTimeDuration)
      const response = await new ApiProvider('tenantapikeys').setInstance(clone).put()
      onSuccess(response.data)
      enqueueNotification('API Secrets expiry extended successfully.', 'info')
    } catch (error) {}
  }

  useHTMLBodyScrollbar(true)

  return (
    <FullScreenAlertModal
      actionButtonText='Save'
      actionButtonVariant='primary'
      alertMessage={`Are you sure you want to extend expiry?`}
      loadingMessage={`Extending Expiry by ${newTimeDuration} days.`}
      onActionClick={handleSave}
      onCancel={onCancel}
      Content={
        <div>
          <Typography className='!text-yellow-500' variant='buttonLabel-regular'>
            The expiry will be extended from <strong>current</strong> time.
          </Typography>
          <Typography className='mt-2' variant='buttonLabel-regular'>
            New expiry will be{' '}
            <strong>{moment().add(newTimeDuration, 'days').format('MMMM Do YYYY, h:mm A ')}</strong>
            i.e. {newTimeDuration} days from today.
          </Typography>
        </div>
      }
      showModal
    />
  )
}

export { ExtendExpiryModal }
