import _ from 'lodash'
const DELIMITER = '!'
/**
 * @typedef {Object.<string, Object.<string, ((rsrc:any, queryKey:string, tagObject:any) => boolean)>>} QueryMapType
 * @typedef {Object.<string, Object.<string, ((rsrc:any, queryKey:string, tagObject:any) => { title: string, template:string }[])>>} SuggestionMapType
 * * SuggesstionsMap follows similar pattern as QueryTypeMap and resolvers in QueryTypeMap and SuggestionMapType should
 * * be there for them to work together.
 *
 * @param {{
 * queryKey: string,
 * queryMap?: QueryMapType,
 * suggesstionsMap?: SuggestionMapType,
 * queryMapKeyExtarctor?: string | ((rsrc:any) => string)
 * defaultQueryFunction: ((iten:any, querykey:string) => boolean)
 * }} param0
 * @returns
 */
export function useSearchQuery({
  queryKey = '',
  queryMap = {},
  defaultQueryFunction,
  queryMapKeyExtarctor = '',
  suggesstionsMap = {}
}) {
  /**
   * Parses the `queryKey` to look for potential tag based search and returns the object with the tag name and the search key
   *
   * Eg:
   * ```
   *
   * queryKey = 'tag:mahant type:instance'
   *
   * {
   *    tag: 'server12',
   *    type: 'instance'
   * }
   * ```
   * @returns {Object.<any, string>}
   */
  const getParsedQueryKeyItems = () => {
    const queryObj = {}
    // Removes all extra whitespaces
    const tagStrings = getTagsFromString(queryKey)
    tagStrings.forEach((str) => {
      const splits = str.split(DELIMITER)
      if (!splits.length) return
      const rootTagName = splits[0]
      queryObj[rootTagName] = splits.slice(1).join(DELIMITER)
    })
    return queryObj
  }

  const getSearchQuery = () => {
    // Removes all extra whitespaces
    const ws = queryKey.replace(/\s+/g, ' ').trim()
    // Split using space
    const ss = ws.split(' ')
    let searchKey = ''

    ss.forEach((s) => {
      if (s.includes(DELIMITER)) return
      searchKey += s
    })

    return searchKey
  }

  /**
   * Extract the key of the `queryMap` which has the query functions that will be used
   * The key returned by this function needs to be present in the `queryMap` object for tag based
   * query search.
   * @param {*} item
   * @returns Key of the query functions in `queryMap`
   */
  const getQueryFunctionKey = (item) => {
    return typeof queryMapKeyExtarctor === 'string'
      ? _.get(item, queryMapKeyExtarctor)
      : queryMapKeyExtarctor(item)
  }

  const isItemMatched = (item, queryKey, queryObj) => {
    const queryKeys = Object.keys(queryObj)
    const queryFnsKey = getQueryFunctionKey(item)
    const queryFunctions = queryMap[queryFnsKey] || {}

    // If no query keys are present or no query functions are defined then, fallback to default query function
    // This also means, if `queryFunctions` are present, then, we dont perform normal search
    if (!queryKeys.length || !Object.keys(queryFunctions).length)
      return defaultQueryFunction?.(item, queryKey)
    /**
     * Only if all the tag filters are true then, we show the rsrc i.e. all query functions
     * for this item should return true
     */
    const allQueryMatch = queryKeys.every((k) => {
      // Return true when query key's value is empty, or, there is no query function defined
      // Doing this we make sure that the rsrc is shown when invalid tag search is performed
      if (!queryObj[k] || !queryFunctions[k]) return true
      return queryFunctions[k](item, queryObj[k])
    })

    const searchKey = getSearchQuery()
    if (searchKey) return defaultQueryFunction?.(item, searchKey) && allQueryMatch

    return allQueryMatch
  }

  /**
   * Get the suggestions data.
   * @param {any[]} data
   * @returns {{
   * title: string
   * template:string
   * }[]}
   */
  const getSuggestionsData = (data) => {
    const suggesstionsItems = []
    const addedTemplatesMap = {}
    if (_.isEmpty(suggesstionsMap)) return []

    data.forEach((e) => {
      const queryObj = getParsedQueryKeyItems()
      const rsrcMap = suggesstionsMap[getQueryFunctionKey(e)]

      for (const key in rsrcMap) {
        if (typeof queryObj[key] === 'undefined') continue
        const itemFn = rsrcMap[key]
        const items = itemFn(e, queryKey).filter((e) => !addedTemplatesMap[e.template])
        // Filter out duplicate templates
        items.forEach((e) => (addedTemplatesMap[e.template] = true))
        if (items) suggesstionsItems.push(...items)
      }
    })

    return suggesstionsItems
  }

  /**
   * Applies the search query filter and returns the data which match the key
   * @param {any[]} data
   * @returns Filtered data after applying search queries
   */
  const applySearchQuery = (data) => {
    const items = []
    const queryObj = getParsedQueryKeyItems()
    data.forEach((e) => {
      const isMatch = isItemMatched(e, queryKey, queryObj)
      isMatch && items.push(e)
    })
    return items
  }

  return { applySearchQuery, getSuggestionsData, getParsedQueryKeyItems }
}

export const getTagsFromString = (str) => {
  // The string to extract from
  // let str = "[tag:endpoint:test] [ptag:test:grp] hello world";

  // Regular expression to match content within square brackets
  let regex = /\[([^\]]+)\]/g

  // Use match method to find all matches
  let matches = str.match(regex)
  if (!matches) return []
  // Remove the square brackets from the matched results
  let contents = matches.map((match) => match.slice(1, -1))
  return contents
}
