import { Box, Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: theme.spacing(2.875)
  },
  box: {
    height: theme.spacing(0.75),
    width: theme.spacing(0.75),
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing()
  },
  info: {
    backgroundColor: theme.palette.info.light
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  warning: {
    backgroundColor: theme.palette.warning.light
  },
  error: {
    backgroundColor: theme.palette.error.light
  },
  light: {
    backgroundColor: theme.palette.grey[200]
  },
  primary_light: {
    backgroundColor: theme.palette.primary.light
  },
  icon: {
    fill: theme.palette.text.disabled
  },
  title: {
    paddingLeft: theme.spacing(0.75)
  },
  text: {
    // paddingRight: theme.spacing(1),

  },
  disableBorder: {
    borderBottom: 'none'
  }
}))

const SegmentRow = ({ Icon, variant = 'light', title = 'Title', text = '10%', disableBorder = false }) => {
  const classes = useStyles()
  return (
    <Grid
      className={classNames(classes.root, { [classes.disableBorder]: disableBorder })}
      alignItems='center'
      container
    >
      <Grid xs={1} alignItems='center' container>
        <div className={classNames(classes.box, classes[variant])} />
      </Grid>
      {Icon && (
        <Grid xs={2} alignItems='center' container>
          <Box display='flex' alignItems='center' pl={1}>
            <Icon fontSize='small' className={classes.icon} />
          </Box>
        </Grid>
      )}
      <Grid xs={Icon ? 7 : 9} alignItems='center' container>
        <CustomTypo className={classes.title} variant='body1'>
          {title}
        </CustomTypo>
      </Grid>
      <Grid xs={2} alignItems='center' justifyContent='flex-end' container>
        <CustomTypo className={classes.text} variant='body2'>
          {text}
        </CustomTypo>
      </Grid>
    </Grid>
  )
}

export default SegmentRow
