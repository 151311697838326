import React, { useEffect, useMemo, useState } from 'react'
import { FullScreenModal, SearchInput } from 'V2Components'
import { createDataSelectorHook } from 'infra/redux'
import { Button, IamRolesCard, Label, TextInput, Typography } from 'procyon-ui'
import { useAppDetailsContext } from 'features/applications'
import { createRef } from 'features/resources'
import { useUser } from 'Core/Hooks/useUser'
import { reduxApiClient } from 'infra'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'

const useSlices = createDataSelectorHook(['salesForcePermissionList', 'salesForceAccountList'])

const CreatePermissionBundleModal = ({ showCreateBundleModal, setShowCreateBundleModal }) => {
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [permissionList, setPermissionList] = useState([])
  const [filterPermissionList, setFilteredPermissionList] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [entityName, setEntityName] = useState('')
  const [bundleDescription, setBundleDescription] = useState('')
  const [sFCurrentAccountRef, setSFCurrentAccountRef] = useState({})
  const { user } = useUser()

  useEffect(() => {
    getPermissionList
  }, [slices])

  useEffect(() => {
    if (!searchKey || searchKey.trim() === '') {
      setFilteredPermissionList(permissionList)
    } else {
      const filterSearch = permissionList?.filter((permission) =>
        permission.EntityName.toLowerCase().includes(searchKey.toLowerCase())
      )
      setFilteredPermissionList(filterSearch)
    }
  }, [searchKey])

  const handleRoleActionClick = (permission) => {
    // Toggle selection
    const isSelected = selectedPermissions.some(
      (selected) => selected.EntityName === permission.EntityName
    )
    if (isSelected) {
      setSelectedPermissions(
        selectedPermissions.filter((selected) => selected.EntityName !== permission.EntityName)
      )
    } else {
      setSelectedPermissions([...selectedPermissions, permission])
    }
  }

  const getPermissionList = useMemo(() => {
    const filterCurrentAccount = slices.salesForceAccountList?.find(
      (account) => account.Application.RefID === app.ObjectMeta.ID
    )

    const list = slices.salesForcePermissionList.filter(
      (prlist) =>
        prlist?.SalesforceAccount.RefID === filterCurrentAccount?.ObjectMeta?.ID &&
        prlist.Type === 'PERMISSION_SET'
    )
    setSFCurrentAccountRef(createRef(filterCurrentAccount))
    setPermissionList(list)
    setFilteredPermissionList(list)
  }, [showCreateBundleModal])

  const handleCreatePermissionBundleClick = async () => {
    const permissionListRef = []
    let objectMeta = {}
    selectedPermissions.forEach((permission, index) => {
      if (index === 1) {
        objectMeta = permission.ObjectMeta
      }
      permissionListRef.push(createRef(permission))
    })

    const payloadObj = {
      ObjectMeta: {
        Kind: objectMeta.Kind,
        Tenant: objectMeta.Tenant,
        Namespace: objectMeta.Namespace
      },
      EntityName: entityName,
      CRM: 'Salesforce',
      Type: 'PERMISSION_SET_GROUP',
      SalesforceAccount: sFCurrentAccountRef,
      Description: bundleDescription,
      PermissionSet: {
        ObjectRef: permissionListRef
      },

      Attributes: {
        Map: {
          label: entityName
        }
      }
    }

    try {
      const response = await reduxApiClient('crm-entities').create(payloadObj)
      if (response.Status.Error === '') {
        setShowCreateBundleModal(false)
        pushToSlice(response)
        enqueueNotification('SuccessFully permisson set group created!', 'info')
      } else {
        const jsonResponse = response.Status.Error.match(/\[.*\]/)[0]
        const parsedResponse = JSON.parse(jsonResponse)
        const messages = parsedResponse.map((item) => item.message)
        const cleanedMessages = messages.map((msg) => msg.replace(/API /g, ''))
        const uniqueMessages = [...new Set(cleanedMessages)]
        console.log(uniqueMessages)
        enqueueNotification(uniqueMessages[0], 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleNameInput = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9_]/g, '')
    setEntityName(filteredValue)
  }

  return (
    <FullScreenModal showModal={showCreateBundleModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between items-center mb-[30px]'>
            <Typography variant='h3' className='text-[20px] m-0'>
              Create Permission Bundle
            </Typography>
          </div>

          <div>
            <TextInput
              label='Label*'
              style={{ width: '560px', marginBottom: '20px' }}
              onChange={(e) => handleNameInput(e.target.value)}
              value={entityName}
            />

            <TextInput
              label='Description'
              style={{ width: '560px' }}
              onChange={(e) => setBundleDescription(e.target.value)}
            />

            {selectedPermissions && (
              <div className='flex flex-wrap mt-2'>
                {selectedPermissions.map((item) => (
                  <Label
                    variant='grayBlue'
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                    text={item.EntityName}
                  ></Label>
                ))}
              </div>
            )}

            <div className='mt-10'>
              <div className='flex items-center justify-between'>
                <Typography variant='body-regular'>Select Permissions</Typography>
                <SearchInput
                  searchKey={searchKey}
                  sx={{ width: '250px' }}
                  onChange={setSearchKey}
                />
              </div>

              <div className='mt-4 h-[400px] overflow-scroll'>
                {filterPermissionList &&
                  filterPermissionList.map((permission) => (
                    <IamRolesCard
                      style={{ marginBottom: '10px' }}
                      key={permission.EntityName}
                      description={
                        permission.Description === '' ? 'Description' : permission.Description
                      }
                      selected={selectedPermissions.some(
                        (selected) => selected.EntityName === permission.EntityName
                      )}
                      assigned={selectedPermissions.some(
                        (selected) => selected.EntityName === permission.EntityName
                      )}
                      onClickActionButton={() => handleRoleActionClick(permission)}
                      title={permission.EntityName}
                      showMoreButton={false}
                    ></IamRolesCard>
                  ))}
              </div>
            </div>
          </div>

          <div className='flex mt-5 flex-row-reverse gap-5'>
            <Button variant='primary' size='md' onClick={() => handleCreatePermissionBundleClick()}>
              Create
            </Button>
            <Button variant='gray' size='md' onClick={() => setShowCreateBundleModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { CreatePermissionBundleModal }
