import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core'

import classNames from 'classnames'
import useAgent from 'Core/Hooks/useAgent'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { enqueueNotification } from 'Utils/Helpers'
import { FileCopy } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    // border: `1px solid ${theme.palette.grey[300]}`,
    // borderRadius: '8px',
    // width: theme.spacing(42.75),
    padding: theme.spacing(2)
  },
  intoBox: {
    backgroundColor: '#EBF6FE',
    padding: theme.spacing(1.25)
  },
  logo: {
    height: theme.spacing(1.875),
    width: theme.spacing(10.875)
  },
  appName: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(1.688)
  },
  subtitle: {
    fontSize: theme.spacing(0.813),
    textAlign: 'center',
    marginTop: theme.spacing(0.438)
  },
  downloadButton: {
    backgroundColor: 'rgb(50, 103, 214)!important',
    color: 'white',
    width: '100%',
    height: theme.spacing(2.313),
    textTransform: 'capitalize'
  },
  inactiveOSButton: {
    height: theme.spacing(3),
    border: '2px solid #d2d2d2',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: theme.spacing(0.875),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  osButton: {
    height: theme.spacing(3),
    border: '2px solid #75799f',
    borderRadius: '4px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[200],
    fontSize: theme.spacing(0.875),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  osIcon: {
    height: theme.spacing(),
    width: theme.spacing(),
    marginRight: theme.spacing(0.5)
  },
  activeIcon: {
    fill: '#75799f'
  }
}))

function InstallAgent() {
  const classes = useStyles()
  const {
    activeApp,
    activeAgentLessApp,
    activeDeviceOS,
    setActiveDeviceOS,
    activeAgentLessDeviceOS,
    setActiveAgentLEssDeviceOS,
    APP_LISTING,
    APP_LISTING_AGENTLESS
  } = useAgent()
  const onCopy = (type) => {
    try {
      navigator.clipboard.writeText(type === 'agent' ? activeApp.link : activeAgentLessApp.link)
      enqueueNotification(
        `${
          type === 'agent' ? activeApp.osName : activeAgentLessApp.osName
        } agent download link to copied`,
        'info'
      )
    } catch (error) {
      console.error('error: ', error)
    }
  }
  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid xs={5} item>
          <Box className={classes.intoBox}>
            <Box display='flex' justifyContent='center' pt={0.75}>
              <img className={classes.logo} alt='Procyon.ai' src='img/full-logo.svg' />
            </Box>
            <Typography className={classes.appName}>{activeApp.name}</Typography>
            <Typography className={classes.subtitle}>{activeApp.osRequirement}</Typography>
            <Box mt={1.375} display='flex' alignItems='center' justifyContent='space-between'>
              <Button
                onClick={() => openURLExternally(activeApp.link)}
                className={classes.downloadButton}
                disableElevation
                variant='contained'
              >
                Download For {activeApp.osName}
              </Button>
              <Tooltip placement='top' title='Copy Download Link'>
                <IconButton onClick={() => onCopy('agent')}>
                  <FileCopy fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid xs={7} item>
          <Box pl={1.438}>
            <Typography>{activeApp.category}</Typography>
            <Box my={0.75}>
              <Divider />
            </Box>
            <Grid spacing={1} container>
              {APP_LISTING.map((item, index) => (
                <Grid key={index} xs={6} item>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => setActiveDeviceOS(item.osName)}
                    className={
                      activeDeviceOS === item.osName ? classes.osButton : classes.inactiveOSButton
                    }
                  >
                    {item.Icon && (
                      <item.Icon
                        className={classNames(classes.osIcon, {
                          [classes.activeIcon]: activeDeviceOS === item.osName
                        })}
                      />
                    )}
                    <Typography variant='body2'>{item.osName}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid xs={5} item>
          <Box className={classes.intoBox}>
            <Box display='flex' justifyContent='center' pt={0.75}>
              <img className={classes.logo} alt='Procyon.ai' src='img/full-logo.svg' />
            </Box>
            <Typography className={classes.appName}>{activeAgentLessApp.name}</Typography>
            <Typography className={classes.subtitle}>{activeAgentLessApp.osRequirement}</Typography>
            <Box mt={1.375} display='flex' alignItems='center' justifyContent='space-between'>
              <Button
                onClick={() => openURLExternally(activeAgentLessApp.link)}
                className={classes.downloadButton}
                disableElevation
                variant='contained'
              >
                Download For {activeAgentLessApp.osName}
              </Button>
              <Tooltip placement='top' title='Copy Download Link'>
                <IconButton onClick={() => onCopy('agentless')}>
                  <FileCopy fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid xs={7} item>
          <Box pl={1.438}>
            <Typography>{activeAgentLessApp.category}</Typography>
            <Box my={0.75}>
              <Divider />
            </Box>
            <Grid spacing={1} container>
              {APP_LISTING_AGENTLESS.map((item, index) => (
                <Grid key={index} xs={6} item>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => setActiveAgentLEssDeviceOS(item.osName)}
                    className={
                      activeAgentLessDeviceOS === item.osName
                        ? classes.osButton
                        : classes.inactiveOSButton
                    }
                  >
                    {item.Icon && (
                      <item.Icon
                        className={classNames(classes.osIcon, {
                          [classes.activeIcon]: activeAgentLessDeviceOS === item.osName
                        })}
                      />
                    )}
                    <Typography variant='body2'>{item.osName}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InstallAgent
