import { Box } from '@material-ui/core'
import { Spinner } from 'procyon-ui'
import React from 'react'

const FallBack = ({ height = '100%', minHeight = '90vh' }) => {
  return (
    <Box height={height} minHeight={minHeight} width='100%' display='flex' alignItems='center' justifyContent='center'>
      <Spinner />
    </Box>
  )
}

export default FallBack
