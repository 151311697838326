import { getDateWithOptions } from 'features/approvals'
import moment from 'moment'
import { SelectDate, SelectDropDown, SelectTime, Typography } from 'procyon-ui'
import React, { useEffect, useMemo, useRef, useState } from 'react'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

function StartEndTimePicker({
  onStartDateChange,
  onEndDateChange,
  onError = null,
  sD = null,
  eD = null,
  hideStartDatePicker = false,
  hideEndDateLabel = false
}) {
  const [startDate, setStartDate] = useState(sD ? moment(sD) : moment())
  const [endDate, setEndDate] = useState(eD ? moment(eD) : null)
  const [endDateFormat, setEndDateFormat] = useState(eD ? 'custom' : 'hours') // 'hours' | 'days' | 'months' | 'weeks' | 'custom'
  const [endDateCountOption, setEndDateCountOption] = useState('hours+1')
  const endDatesOptions = useMemo(() => getDateWithOptions(startDate), [startDate])
  const hasStartDateChanged = useRef(false)
  const [errors, setErrors] = useState({
    startDate: '',
    endDate: ''
  })

  const handleStartDateChange = (e) => {
    hasStartDateChanged.current = true
    const newStartDate = moment(`${e.target.value} ${startDate.format(TIME_FORMAT)}`)
    setStartDate(newStartDate)
    onStartDateChange && onStartDateChange(newStartDate.toDate())
  }

  const handleStartTimeChange = (e) => {
    hasStartDateChanged.current = true
    const newStartDate = moment(` ${startDate.format(DATE_FORMAT)} ${e.target.value}`)
    setStartDate(newStartDate)
    onStartDateChange && onStartDateChange(newStartDate.toDate())
  }

  const handleCustomEndTimeChange = (e) => {
    hasStartDateChanged.current = true
    const newEndDate = moment(` ${endDate.format(DATE_FORMAT)} ${e.target.value}`)
    setEndDate(newEndDate)
    onEndDateChange && onEndDateChange(newEndDate.toDate())
  }

  /**
   * Updates the selectedEndDateOption and endDate, React batching is in effect
   */
  const handleEndDateOptionChange = (e) => {
    if (e.target.value === 'custom') return
    const dates = endDatesOptions[endDateFormat]
    const date = dates[e.target.value.split('+')[1]]
    setEndDateCountOption(e.target.value)
    setEndDate(moment(date))
    onEndDateChange && onEndDateChange(date)
  }
  /**
   * Updates the endDate from custom date picker
   */
  const hanldleEndDateCustom = (e) => {
    setEndDate(moment(e.target.value))
    onEndDateChange && onEndDateChange(moment(e.target.value).toDate())
  }

  /**
   * Updates the selected endDate Option when `endDateFormat` is changed
   */
  const handleEndDateFormatChange = (e) => {
    const value = e.target.value
    setEndDateFormat(value)
    if (value === 'custom') return
    const option = Object.keys(endDatesOptions[value])[0]
    setEndDateCountOption(`${value}+${option}`)
    setEndDate(moment(endDatesOptions[value][option]))
    onEndDateChange && onEndDateChange(endDatesOptions[value][option])
  }

  if (endDate === null) {
    const date = endDatesOptions[endDateFormat][endDateCountOption.split('+')[1]]
    setEndDate(moment(date))
    onEndDateChange && onEndDateChange(date)
  }

  /**
   * Whenever the start date or endDateOptions changes and the end time format is not custom, we must update with new end date format.
   */
  useEffect(() => {
    if (endDateFormat === 'custom') return
    const dates = endDatesOptions[endDateFormat]
    const date = dates[endDateCountOption.split('+')[1]]
    setEndDate(moment(date))
    onEndDateChange && onEndDateChange(date)
  }, [startDate, endDatesOptions])

  useEffect(() => {
    if (hasStartDateChanged.current && startDate.diff(new Date()) < 0) {
      onError && onError({ ...errors, startDate: 'Start Date cannot be less than current date.' })
      setErrors({ ...errors, startDate: 'Start Date cannot be less than current date.' })
    } else if (startDate.diff(endDate) > 0) {
      onError && onError({ ...errors, startDate: 'Start Date cannot be greater than end date.' })
      setErrors({ ...errors, startDate: 'Start Date cannot be greater than end date.' })
    } else {
      onError && onError({ ...errors, startDate: '', endDate: '' })
      setErrors({ ...errors, startDate: '', endDate: '' })
    }
  }, [startDate, endDate])

  return (
    <div>
      {!hideStartDatePicker && (
        <div className='flex gap-8'>
          <div>
            <Typography className='mb-2' variant='body-regular'>
              Start Date
            </Typography>
            <SelectDate onChange={handleStartDateChange} value={startDate.format(DATE_FORMAT)} />
          </div>
          <div>
            <Typography className='mb-2' variant='body-regular'>
              Start Time
            </Typography>
            <SelectTime onChange={handleStartTimeChange} value={startDate.format(TIME_FORMAT)} />
          </div>
        </div>
      )}
      <div className='mt-6 flex gap-8'>
        <div>
          {hideEndDateLabel ? (
            <div className='mb-7'></div>
          ) : (
            <Typography className='mb-2' variant='body-regular'>
              End Date
            </Typography>
          )}

          <SelectDropDown
            showHelpText={false}
            className='w-[147px]'
            menuItems={[
              {
                label: 'Hours',
                selected: true,
                value: 'hours',
                disabled: false
              },
              {
                label: 'Days',
                value: 'days',
                disabled: false
              },
              {
                label: 'Weeks',
                value: 'weeks',
                disabled: false
              },
              {
                label: 'Months',
                value: 'months',
                disabled: false
              },
              {
                label: 'Custom',
                value: 'custom',
                disabled: false
              }
            ]}
            onChange={handleEndDateFormatChange}
            value={endDateFormat}
          />
        </div>
        {endDateFormat === 'custom' && (
          <div className='flex gap-8'>
            <div>
              <Typography className='mb-2 capitalize' variant='body-regular'>
                {endDateFormat}
              </Typography>
              <SelectDate
                onChange={hanldleEndDateCustom}
                value={moment(endDate).format(DATE_FORMAT)}
              />
            </div>
            <div>
              <Typography className='mb-2' variant='body-regular'>
                End Time
              </Typography>
              <SelectTime
                onChange={handleCustomEndTimeChange}
                value={moment(endDate).format(TIME_FORMAT)}
              />
            </div>
          </div>
        )}
        {endDateFormat !== 'custom' && (
          <div>
            <Typography className='mb-2 capitalize' variant='body-regular'>
              {endDateFormat}
            </Typography>
            <SelectDropDown
              showHelpText={false}
              menuItems={Object.keys(endDatesOptions[endDateFormat]).map((e) => ({
                label: e,
                value: `${endDateFormat}+${e}`,
                disabled: false
              }))}
              className='w-[91px]'
              value={endDateCountOption}
              onChange={handleEndDateOptionChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { StartEndTimePicker }
