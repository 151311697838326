import {
  clearAccessData,
  toggleAccessDataEntities,
  toggleAccessDataResources,
  updateAccessDataSpec,
  updateAccessDataResources,
  updateAccessDataEntities,
  updateAccessDataResourceMeta,
  removeAccessDataResources
} from 'infra/redux/reducers/slices/resourceAccess'
import { useDispatch, useSelector } from 'react-redux'

export function useResourceAccess() {
  /**
   * @type {import("infra/redux/reducers/slices/resourceAccess").ResourceAccessState['data']}
   */
  // @ts-ignore
  const data = useSelector((state) => state.resourceAccess.data)
  const dispatch = useDispatch()
  /**
   *
   * @param {import('infra/redux/reducers/slices/resourceAccess').ResourceAccessDataSpec} spec
   */
  const updateSpec = (spec) => dispatch(updateAccessDataSpec(spec))
  const updateResources = (refs) => dispatch(updateAccessDataResources(refs))
  const removeResources = (refs) => dispatch(removeAccessDataResources(refs))
  const toggleResources = (refs) => dispatch(toggleAccessDataResources(refs))
  const updateEntities = (refs) => dispatch(updateAccessDataEntities(refs))
  const toggleEntities = (refs) => dispatch(toggleAccessDataEntities(refs))
  const clearAllAccessData = () => dispatch(clearAccessData())
  const updateRsrcMeta = (obj) => dispatch(updateAccessDataResourceMeta(obj))

  return {
    ...data,
    updateSpec,
    toggleResources,
    toggleEntities,
    clearAllAccessData,
    updateResources,
    updateEntities,
    updateRsrcMeta,
    removeResources
  }
}
