import { useUser } from 'Core/Hooks/useUser'
import { hasApprovalRequestExpired } from 'Utils/PureHelperFuctions'
import {
  ApprovalRequestManager,
  ApprovalsDetailsProvider,
  isApprovalRequestCreatedByUser,
  isServiceAccountRequest,
  useApprovalDetails
} from 'features/approvals'
import { TabGroup } from 'procyon-ui'
import React, { useMemo, useState } from 'react'
import { Header } from './Components/Header'
import { AccessGraphTab, CommentsTab, RequestSummaryTab } from './Components/Tabs'

function ApprovalsDetails() {
  const { approvalRequest, paaPolicy } = useApprovalDetails()
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(false)
  const [showDeleteAutoApprovePolicy, setShowDeleteAutoApprovePolicy] = useState(false)

  const { user } = useUser()

  const memo = useMemo(() => {
    const tabs = []
    if (
      isApprovalRequestCreatedByUser(user, approvalRequest) &&
      approvalRequest.Status === 'Approved' &&
      !hasApprovalRequestExpired(approvalRequest) &&
      !isServiceAccountRequest(approvalRequest)
    ) {
      tabs.push({
        label: 'Access Graph',
        tabContent: <AccessGraphTab />
      })
    }

    return (
      <TabGroup
        tabs={[
          ...tabs,
          {
            label: 'Request Summary',
            tabContent: <RequestSummaryTab />
          },
          {
            label: 'Comments',
            tabContent: <CommentsTab />
          }
        ]}
      />
    )
  }, [approvalRequest])

  const handleModalViewChange = ({
    showApprove,
    showReject,
    showDelete,
    showDeleteAutoApprove
  }) => {
    setShowCloseModal(showDelete)
    setShowApprovalModal(showApprove)
    setShowRejectionModal(showReject)
    setShowDeleteAutoApprovePolicy(showDeleteAutoApprove)
  }

  if (!approvalRequest) return null

  return (
    <div className='min-h-[700px]'>
      <div className='bg-[#F9FBFC] px-12 py-4 -ml-8 -mr-5 -mt-4'>
        <Header
          onPaaPolicyDeleteClick={() => setShowDeleteAutoApprovePolicy(true)}
          onCloseClick={() => setShowCloseModal(true)}
          onRejectClick={() => setShowRejectionModal(true)}
          onApproveClick={() => setShowApprovalModal(true)}
        />
      </div>
      <div className='pt-8 px-4 h-[100%]'>{memo}</div>
      <ApprovalRequestManager
        onModalsViewChange={handleModalViewChange}
        approvalRequest={approvalRequest}
        paaPolicy={paaPolicy}
        views={{
          showDelete: false,
          showApprove: showApprovalModal,
          showClose: showCloseModal,
          showReject: showRejectionModal,
          showDeleteAutoApprove: showDeleteAutoApprovePolicy
        }}
      />
    </div>
  )
}

const Wrapped = () => (
  <ApprovalsDetailsProvider>
    <ApprovalsDetails />
  </ApprovalsDetailsProvider>
)

export { Wrapped as ApprovalsDetails }
