import { combineReducers } from 'redux'
import { NotificationsReducer } from './outdated/NotificationsReducer'
import userReducer from './outdated/UserReducer'
import * as slices from './slices/slices'
import resourcesQueue from './slices/resourcesQueue'
import bookmarksSlice from './slices/bookmarksSlice'
import cartSlice from './slices/cartSlice'
import resourceAccess from './slices/resourceAccess'
import dataStoreReducer from './outdated/DataStoreReducer'
import dashboardSlice from './slices/dashboardSlice'

// Combine all the sub reducers
const rootReducer = combineReducers({
  user: userReducer,
  dataStore: dataStoreReducer,
  notificationStore: NotificationsReducer,
  userList: slices.userListSlice.reducer,
  groupList: slices.groupListSlice.reducer,
  idpList: slices.idpListSlice.reducer,
  googleIdpList: slices.googleIdpListSlice.reducer,
  credentialsList: slices.credentialsListSlice.reducer,
  deviceList: slices.deviceListSlice.reducer,
  serverGroupList: slices.serverGroupListSlice.reducer,
  applicationList: slices.applicationListSlice.reducer,
  accountList: slices.accountListSlice.reducer,
  appRolesList: slices.appRolesListSlice.reducer,
  approvalReqList: slices.approvalReqListSlice.reducer,
  userAccountsList: slices.userAccountsListSlice.reducer,
  recordingList: slices.recordingListSlice.reducer,
  serverList: slices.serverListSlice.reducer,
  vpcList: slices.vpcListSlice.reducer,
  credentialTypeList: slices.credentialTypeSlice.reducer,
  credentialInstanceList: slices.credentialInstanceSlice.reducer,
  policyList: slices.policySlice.reducer,
  paaPolicyList: slices.paaPolicySlice.reducer,
  resourceActions: slices.resourceActionsSlice.reducer,
  resourceKindGrants: slices.resourceKindGrantsSlice.reducer,
  proxyList: slices.proxiesSlice.reducer,
  slackIntegrationList: slices.slackIntegrations.reducer,
  jiraIntegrationsList: slices.jiraIntegrations.reducer,
  slackChannelList: slices.slackChannel.reducer,
  jiraProjectList: slices.jiraProject.reducer,
  userpreferences: slices.userpreferencesSlice.reducer,
  projects: slices.projectsSlice.reducer,
  medusaNodes: slices.medusaNodesSlice.reducer,
  tenantprofiles: slices.tenantprofilesSlice.reducer,
  iamActions: slices.iamActionsSlice.reducer,
  awsResources: slices.awsResourcesSlice.reducer,
  gcpResources: slices.gcpResources.reducer,
  databases: slices.databaseSlice.reducer,
  serviceAccounts: slices.serviceAccountsSlice.reducer,
  kubeNamespaces: slices.kubeNamespacesSlice.reducer,
  azureResources: slices.azureResourcesSlice.reducer,
  resourcesQueue: resourcesQueue,
  bookmarks: bookmarksSlice,
  carts: cartSlice,
  githubResources: slices.githubresourcesSlice.reducer,
  githubAccount: slices.githubAccountSlice.reducer,
  resourceAccess: resourceAccess,
  orgs: slices.orgsSlice.reducer,
  tagPolicys: slices.tagPolicySlice.reducer,
  notificationSubscriptions: slices.notificationSubscriptionSlice.reducer,
  approvers: slices.approversSlice.reducer,
  workloads: slices.workloadsSlice.reducer,
  rdpServers: slices.rdpServersSlice.reducer,
  kafkas: slices.kafkas.reducer,
  servicenowintegrations: slices.servicenowintegrations.reducer,
  servicenowtables: slices.servicenowtables.reducer,
  kubeClusters: slices.kubeClusters.reducer,
  adDomainControllers: slices.adDomainControllers.reducer,
  bundles: slices.bundles.reducer,
  visibilitys: slices.visibilitys.reducer,
  salesForceUsersList: slices.salesForceUsersList.reducer,
  salesForcePermissionList: slices.salesForcePermissionList.reducer,
  salesForceAccountList: slices.salesForceAccountList.reducer,
  salesForceUserPermissionBinding: slices.salesForceUserPermissionBinding.reducer,
  snowFlakeResources: slices.snowFlakeResources.reducer,
  snowFlakeRoles: slices.snowFlakeRoles.reducer,
  snowFlakeUsers: slices.snowFlakeUsers.reducer,
  snowFlakeAccounts: slices.snowFlakeAccounts.reducer,
  adPasswords: slices.adPasswords.reducer,
  msTeamsIntegrations: slices.msTeamsIntegrations.reducer,
  msTeams: slices.msTeams.reducer,
  dashboard: dashboardSlice,
  msTeamsChannels: slices.msTeamsChannels.reducer,
  dataBricksAccounts: slices.dataBricksAccounts.reducer,
  dataBricksIdentitys: slices.dataBricksIdentitys.reducer,
  dataBricksWorkSpaces: slices.dataBricksWorkSpaces.reducer,
  dataBricksResources: slices.dataBricksResources.reducer,
  iamUsers: slices.iamUsers.reducer,
  analyzeIdentity: slices.analyzeIdentity.reducer,
  iamGroups: slices.iamGroups.reducer,
  iamRoles: slices.iamRoles.reducer,
  iamFederatedUsers: slices.iamFederatedUsers.reducer,
  serverControllers: slices.serverControllers.reducer,
  proxyControllers: slices.proxyControllers.reducer
})

export { rootReducer }
