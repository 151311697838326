import { AdvancedSearch, SummaryHeader } from 'V2Components'
import { BUNDLE_TYPE_ICON_TYPE } from 'features/bundles/utils'
import { TabGroup, TargetIcon } from 'procyon-ui'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { GrantedBundles } from './components/Tabs/GrantedBundles'
import { UnGrantedBundles } from './components/Tabs/UnGrantedBundles'
import { InProgressBundles } from './components/Tabs/InProgressBundles'
import { useBundlesUserAccess } from 'features/bundles'
import useAppView from 'Core/Hooks/useAppView'
import { BundlesList } from './components/BundlesList'

const BundleTypeInfo = () => {
  //@ts-ignore
  const { bundleType } = useParams()
  const { countsMap } = useBundlesUserAccess(bundleType.toUpperCase())
  const { appView } = useAppView()

  return (
    <div className='-mt-8'>
      <SummaryHeader
        breadCrumbsItems={[
          {
            label: 'Bundles',
            link: `/${appView}/bundles`
          },
          {
            label: bundleType.toUpperCase()
          }
        ]}
        actions={[]}
        Icon={({ ...props }) => (
          <TargetIcon type={BUNDLE_TYPE_ICON_TYPE[bundleType.toUpperCase()]} {...props} />
        )}
        rsrcName={`${bundleType.toUpperCase()}`}
      />

      <div className='mt-6'>
        {appView === 'admin' && <BundlesList />}
        {appView === 'user' && (
          <TabGroup
            tabs={[
              {
                label: `Un-Granted Bundles(${countsMap.ungrantedCount})`,
                tabContent: <UnGrantedBundles />
              },
              {
                label: `Granted Bundles(${countsMap.grantCount})`,
                tabContent: <GrantedBundles />
              },
              {
                label: `In Progress Bundles(${countsMap.inProgressCount})`,
                tabContent: <InProgressBundles />
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}

export { BundleTypeInfo }
