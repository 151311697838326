// @ts-nocheck
import { Box, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import Distribution from './Distribution'
const useStyle = makeStyles(theme => ({
  linearDistribution: {
    borderRadius: props => theme.spacing(props.height),
    height: props => theme.spacing(props.height),
    width: props => props.widthPercentage ? props.widthPercentage : theme.spacing(props.width),
    overflow: 'hidden'
  }
}))
const LinearDistribution = ({ distributions, height = 0.5, width = 10, widthPercentage = false }) => {
  const classes = useStyle({ height, width, widthPercentage })
  const sum = useMemo(() => distributions.reduce((temp, a) => temp + a.value, 0))
  return (
    <Box display='flex' className={classes.linearDistribution}>
      {distributions.map(({ value, color, name }) =>
        <Distribution sum={sum} key={name} name={name} value={value} color={color} />)}
    </Box>
  )
}

export default LinearDistribution
