import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { Grid, makeStyles } from '@material-ui/core'
import { _ } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import {
  IAMResourcesSliceNames,
  isCloudTypeSupportedIAMAction,
  useIAMRolesJSONMap
} from 'features/iamResources'
import { isCustomIAMRole } from 'features/iamRoles'
import { getResourceCloudType, getResourceName, useAccessCartProvider } from 'features/resources'
import { createRsrcKey } from 'infra/redux/reducers'
import { key } from 'localforage'
import { Button, IamRolesCard, Label, SimpleDropDown, Typography } from 'procyon-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { FloatingDrawer, SearchInput } from 'V2Components'

const useStyles = makeStyles((theme) => ({
  root: {},
  selectRoleWrapper: {
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(4.5),
    position: 'relative'
  },
  selectedRoles: {
    paddingTop: theme.spacing(0.8),
    display: 'flex',
    flexWrap: 'wrap'
  },
  rolesCardContainer: {
    paddingTop: theme.spacing()
  },
  rolesCardWrapper: {
    marginBottom: theme.spacing()
  },
  selectedRoleLabel: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  roleCaption: {
    overflowWrap: 'anywhere'
  },
  roleDetailsContainer: {
    marginTop: theme.spacing()
  },
  searchInput: {
    width: '270px'
  },
  actions: {
    position: 'fixed',
    bottom: '0',
    backgroundColor: 'white',
    width: '671px',
    right: 0,
    height: '74px',
    padding: theme.spacing(1, 2),
    zIndex: 501,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #D0D9EA'
  },
  header: {
    marginBottom: theme.spacing(0.8)
  }
}))

/**
 *
 * selectedRsrc = {
 *  RefKind,
 *  RefID,
 *  type: CloudType
 * }
 * @returns
 */
function IAMRolesSelectionDrawer({
  showDrawer,
  onCloseDrawer,
  selectedRsrc,
  onCreateIAMRoleClick
}) {
  const classes = useStyles()
  const [searchKey, setSearchKey] = useState('')
  const [selectedIamRoles, setSelectedIamRoles] = useState([])
  const [isSelectedIamRolesPopulated, setIsSelectedIamRolesPopulated] = useState(false)
  const { appView } = useAppView()
  const { getResourceFromCart, addItemsToCart, updateCartQueueItem } = useAccessCartProvider()
  const [sortRolesBy, setSortRolesBy] = useState('all')
  const { dispatchThunks, getObjectRef, slices } = useMultiSlice([
    'iamActions',
    ...IAMResourcesSliceNames
  ])
  const rsrcCloudType = getResourceCloudType(getObjectRef(selectedRsrc))
  const isCloudTypeSupportedIAMMap = isCloudTypeSupportedIAMAction(rsrcCloudType)

  const { getSupportedIAMActions } = useIAMRolesJSONMap()

  const isResourceInQueue = getResourceFromCart({
    RefKind: selectedRsrc.RefKind,
    RefID: selectedRsrc.RefID
  })

  const handleDrawerClose = () => {
    setSelectedIamRoles([])
    setIsSelectedIamRolesPopulated(false)
    onCloseDrawer && onCloseDrawer()
  }

  const handleRoleActionClick = (role) => {
    setSelectedIamRoles((s) => {
      if (s.find((r) => r.title === role.title)) return s.filter((e) => e.title !== role.title)
      return [...s, role]
    })
  }

  const handleRemoveRoleClick = (role) =>
    setSelectedIamRoles((s) => s.filter((e) => e.title !== role.title))

  const handleAddToQueue = () => {
    const { RefKind, RefID } = selectedRsrc
    // If resource is already in queue, then update the resource item
    // Else, create a new resource item
    if (isResourceInQueue) {
      updateCartQueueItem({
        resourceRef: {
          RefKind,
          RefID
        },
        roles: selectedIamRoles.map((e) => ({ RefKind: 'IamAction', RefID: e.RefID }))
      })
    } else {
      addItemsToCart({
        resourceRef: {
          RefKind,
          RefID
        },
        principal: '',
        roles: selectedIamRoles.map((e) => ({ RefKind: 'IamAction', RefID: e.RefID }))
      })
    }
    setSelectedIamRoles([])
    handleDrawerClose()
  }

  const isSearchMatch = useCallback(
    (e) => {
      const str = typeof e === 'object' ? JSON.stringify(e) : e
      return str.toLowerCase().includes(searchKey.toLowerCase())
    },
    [searchKey]
  )

  const getIAMActionTypeForResource = (rsrc) => {
    if (rsrc.ObjectMeta.Kind === 'AzureResource') {
      if (rsrc.Spec.DisplayType === 'Microsoft Entra ID') return IAMActionTypeMap.AzureAD
      return IAMActionTypeMap.AzureResource
    }
    if (rsrc.ObjectMeta.Kind === 'KubeNamespace') return rsrc.Spec.Type
    return IAMActionTypeMap[rsrc.ObjectMeta.Kind]
  }

  const getIamActionsList = (rsrcRefObject) => {
    if (!rsrcRefObject) return []
    const supportedIamActions = getSupportedIAMActions(rsrcRefObject.Spec.Type, rsrcCloudType)

    if (sortRolesBy === 'supported') {
      return supportedIamActions
    }

    if (sortRolesBy === 'all') {
      const rsrcType = getIAMActionTypeForResource(rsrcRefObject)
      if (isCloudTypeSupportedIAMAction(rsrcType)) {
        return [...supportedIamActions, ...slices.iamActions.filter(isCustomIAMRole)]
      } else return slices.iamActions
    }
    if (sortRolesBy === 'custom') return slices.iamActions.filter(isCustomIAMRole)
    if (sortRolesBy === 'predefined') return slices.iamActions.filter((e) => !isCustomIAMRole(e))
  }

  const getIamRolesToDisplay = () => {
    const items = []
    const rsrcRefObject = getObjectRef(selectedRsrc)
    if (!rsrcRefObject) return []

    const rsrcType = getIAMActionTypeForResource(rsrcRefObject)

    const iamList = getIamActionsList(rsrcRefObject)

    iamList.forEach((e) => {
      if (rsrcType === e.Spec.CloudType) {
        const data = {
          key: createRsrcKey(e),
          description: e.Spec.Description,
          title: getResourceName(e),
          actions: e.Spec.Actions.Elems,
          RefID: e.ObjectMeta.ID,
          selected: !!selectedIamRoles.find((s) => s.RefID === e.ObjectMeta.ID)
        }
        if (isSearchMatch([e.Spec.RoleName, e.Spec.Actions.Elems, e.ObjectMeta.Name]))
          items.push(data)
      }
    })
    return [...items].slice(0, 99)
  }

  const iamRoles = getIamRolesToDisplay()

  if (selectedRsrc && !isSelectedIamRolesPopulated) {
    const rsrc = getResourceFromCart({ RefID: selectedRsrc.RefID, RefKind: selectedRsrc.RefKind })
    if (rsrc && iamRoles.length && !selectedIamRoles.length) {
      const rolesRefs = rsrc.Roles.ObjectRef
      const rolesData = []
      rolesRefs.forEach((e) => {
        const d = _.find(iamRoles, { RefID: e.RefID })
        if (d) rolesData.push(d)
      })
      setSelectedIamRoles(rolesData)
      setIsSelectedIamRolesPopulated(true)
    }
  }

  const getSortDropdownOptions = () => {
    const customSupportedFilters = [
      {
        label: 'Sort: All',
        value: 'all'
      },
      {
        label: 'Sort: Supported By Resource',
        value: 'supported'
      },
      {
        label: 'Sort: Custom',
        value: 'custom'
      }
    ]

    const options = [
      {
        label: 'Sort: All',
        value: 'all'
      },
      {
        label: 'Sort: Predefined',
        value: 'predefined'
      },
      {
        label: 'Sort: Custom',
        value: 'custom'
      }
    ]

    return isCloudTypeSupportedIAMMap ? customSupportedFilters : options
  }

  useEffect(() => {
    dispatchThunks({ skipWhenLoaded: true })
  }, [])

  return (
    <div className={classes.root}>
      <FloatingDrawer onOverlayClick={handleDrawerClose} showDrawer={showDrawer}>
        <div className={classes.selectRoleWrapper}>
          <Typography className={classes.header} variant='body-regular'>
            Select IAM Roles
          </Typography>
          <div className='flex justify-between'>
            <SearchInput
              searchKey={searchKey}
              sx={{ width: '272px' }}
              className={classes.searchInput}
              onChange={setSearchKey}
            />
            <SimpleDropDown
              menuItems={getSortDropdownOptions()}
              onChange={(e) => setSortRolesBy(e.target.value)}
              value={sortRolesBy}
            />
          </div>
          <div className={classes.selectedRoles}>
            {selectedIamRoles.map((e) => (
              <Label
                className={classes.selectedRoleLabel}
                text={e.title}
                iconButton={faCircleMinus}
                variant='grayBlue'
                onClick={() => handleRemoveRoleClick(e)}
              />
            ))}
          </div>
          {appView === 'admin' && (
            <div
              onClick={onCreateIAMRoleClick}
              className='px-6 py-4 w-[605px] border-solid border rounded-lg border-[#D8DDE4] cursor-pointer hover:opacity-80'
            >
              <p className='font-bold text-[#8D94A1]'>Create Custom Role</p>
            </div>
          )}
          <div className={classes.rolesCardContainer}>
            {iamRoles.map((e) => (
              <IamRolesCard
                className={classes.rolesCardWrapper}
                key={e.key}
                description={e.description}
                selected={e.selected}
                assigned={e.selected}
                onClickActionButton={() => handleRoleActionClick(e)}
                title={e.title}
              >
                <div className={classes.roleDetailsContainer}>
                  <Grid spacing={1} container>
                    <Grid xs={4} item>
                      <Typography className={classes.roleCaption} variant='caption-regular'>
                        {e.title}:
                      </Typography>
                    </Grid>
                    <Grid xs={8} item>
                      {e.actions.map((action) => (
                        <Typography variant='caption-regular'>{action}</Typography>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </IamRolesCard>
            ))}
          </div>
          <div className={classes.actions}>
            <Button onClick={handleDrawerClose} variant='gray'>
              Cancel
            </Button>
            <Button
              disabled={!selectedIamRoles.length}
              onClick={handleAddToQueue}
              variant='primary'
            >
              {isResourceInQueue ? 'Save Changes' : 'Add To Request Queue'}
            </Button>
          </div>
        </div>
      </FloatingDrawer>
    </div>
  )
}

const IAMActionTypeMap = {
  AwsResource: 'AWS',
  GcpResource: 'GCP',
  AzureResource: 'AZURE',
  AzureAD: 'AZURE_AD'
}

export { IAMRolesSelectionDrawer }
