import { ThemeProvider } from '@material-ui/core'
import SITE_CONFIG from 'Core/Config'
import Theme from 'Core/Theme'
import store, { persistor } from 'infra/redux/store'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import './index.css'
import './normalize.css'
import localforage from 'localforage'
const { publicUrl } = SITE_CONFIG

const CURRENT_APP_VERSION = '24.03.11'

const container = document.getElementById('root')
const root = createRoot(container)

const checkAppVersionAndClearStorage = async () => {
  const version = localStorage.getItem('APP_VERSION')
  if (version !== CURRENT_APP_VERSION) {
     await localforage.setItem('persist:root', '')
    localStorage.setItem('APP_VERSION', CURRENT_APP_VERSION)
    window.location.reload()
  }
}

checkAppVersionAndClearStorage()

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router basename={publicUrl}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
)
