import { makeStyles } from '@material-ui/core'
import { Check, Pause, WarningTwoTone } from '@material-ui/icons'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import ReactTabs from 'Components/ReactTabs'
import React, { useEffect, useState } from 'react'
import DeviceList from 'Views/DeviceList'
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex'
  },
  main: {
    flexGrow: 1
  }
}))
const DeviceTabs = ({ tab = 'REGISTERED' }) => {
  const classes = useStyles()
  const TabList = [
    {
      key: 'thisIsAKey1',
      Tab: {
        Icon: Check,
        Title: 'Approved'
      },
      TabPanel: {
        Component: <DeviceList tab='APPROVED' />
      },
      route: {
        path: '/admin/devices/approved'
      }
    },
    {
      key: 'thisIsAKey2',
      Tab: {
        Icon: QueryBuilderIcon,
        Title: 'Unapproved'
      },
      TabPanel: {
        Component: <DeviceList tab='UNAPPROVED' />
      },
      route: {
        path: '/admin/devices/unapproved'
      }
    }
  ]
  const [activeIndex, setActiveIndex] = useState(() => {
    if (tab === 'APPROVED') return 0
    return 1
  })
  useEffect(() => {
    if (tab === 'APPROVED') {
      setActiveIndex(0)
    } else {
      setActiveIndex(1)
    }
  }, [tab])
  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <ReactTabs disableRouteMatch={false} disableRouteCorrection defaultIndex={activeIndex} tabItems={TabList} />
        </div>
      </div>
    </div>
  )
}

export default DeviceTabs
