
import { Box, makeStyles } from '@material-ui/core'
import { Storage } from '@material-ui/icons'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import FallBack from 'Components/Fallback'
import { Theme } from 'Core'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import useMultiQuery from 'Core/Hooks/useMultiQuery'
import React, { useCallback, useEffect, useMemo } from 'react'
import { status } from 'infra/redux/reducers'
import { getUserFromHit, groupByUser, mixSessionCounts } from 'Utils/PureHelperFuctions'
import { useActiveUsers } from 'Views/DashBoard/Components/UsersAndMaps/ActiveUserContext'
import { columns, options } from './TableMeta'

const useStyle = makeStyles(theme => ({
  name: {
    '& > span': { justifyContent: 'flex-start !important' }
  }
}))
const MostActive = () => {
  const { tableData, updateSelectedActiveUser, updateTableData } = useActiveUsers()
  const { data: userList, status: userListStatus } = useMemoSelector('userList')
  const classes = useStyle()
  const headerClassNames = useMemo(() => ({ name: classes.name }), [])
  const seriesMeta = useMemo(() => [
    {
      name: 'servers',
      label: 'Targets',
      color: Theme.palette.warning.light,
      active: true,
      Icon: ({ className }) => <Storage className={className} />
    },
    {
      name: 'appRole',
      label: 'IAM Role',
      color: Theme.palette.info.light,
      active: true,
      Icon: ({ className }) => <img className={className} src='img/icons/iam-role.png' />
    }

  ], [])

  const { hits, loading, getQueryLogs } = useMultiQuery(['event-log', 'proxy-audit-log'])
  const isApiLoading = userListStatus === status.LOADING
  const showLoader = loading || isApiLoading

  useEffect(() => {
    const rolesHits = hits['event-log']
    console.log('🚀 ~ file: index.jsx ~ line 55 ~ .then ~ rolesHits', rolesHits)
    const serverHits = hits['proxy-audit-log']
    const groupedSSHActiveUsers = groupByUser(serverHits, 'servers', getUserFromHit)
    const groupdedSignInActiveUsers = groupByUser(rolesHits, 'appRole', getUserFromHit)

    const activeUsers = [...groupedSSHActiveUsers, ...groupdedSignInActiveUsers]
    const activityDistributionTemplate = { appRole: { name: 'appRole', color: Theme.palette.info.light, label: 'IAM Role' }, servers: { name: 'servers', color: Theme.palette.warning.light, label: 'Targets' } }
    const mixedActiveUsers = mixSessionCounts(activeUsers, userList, 'userName', activityDistributionTemplate)
    console.log('🚀 ~ file: index.jsx ~ line 62 ~ .then ~ mixedActiveUsers', mixedActiveUsers)
    updateTableData(mixedActiveUsers)
  }, [hits, userList])

  useEffect(() => {
    getQueryLogs()
  }, [getQueryLogs])

  const onSelect = useCallback((data) => {
    updateSelectedActiveUser(data[0])
  }, [updateSelectedActiveUser])

  const tableOptions = useMemo(() => options(onSelect), [onSelect])
  const tableColumns = useMemo(() => columns(headerClassNames), [headerClassNames])

  const Table = ({ partialData = false, searchString = '' }) => {
    const data = partialData ? [...tableData].slice(0, 5) : tableData
    return (
      <AnalyticTable
        rowHeight='2.75rem'
        headHeight='2.5rem'
        headerJustify='flex-end'
        vAlign='top'
        searchString={searchString}
        columns={tableColumns}
        options={tableOptions}
        data={data}
      />
    )
  }
  const ModalComponent = ({ closeModal }) => (
    <ModalView
      searchPlaceholder='Enter User Name'
      closeModal={closeModal}
      seriesMeta={seriesMeta}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )
  return (
    <ExpandableContainer
      expandButtonLabel='Expand All Locations'
      seriesMeta={seriesMeta}
      modalTitle='Active Locations'
      // containerHeight='19.5rem'
      ModalComponent={ModalComponent}
    >
      <Box flexGrow={1} minHeight='16rem'>
        {!showLoader && <Table partialData />}
        {showLoader && <FallBack minHeight='25vh' />}
      </Box>
    </ExpandableContainer>
  )
}

export default MostActive
