import { createRef, createRsrcKey } from 'features/resources'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { TargetIcon, TreeItem, TreeView } from 'procyon-ui'
import React from 'react'

const useSlices = createDataSelectorHook([
  'kubeNamespaces',
  'approvalReqList',
  'policyList',
  'kubeClusters'
])

const KubeClusterTreeView = ({ kubeCluster, onNodeClick, accessState = '' }) => {
  const { slices, sliceNames } = useSlices()
  const { getObjectRef } = useObjectRef(sliceNames)

  const handleNodeClick = ({ ref, type, kubeNamespace }) => {
    if (!ref) return
    onNodeClick?.(getObjectRef(ref))
  }

  const getKubeNamespaces = () => {
    return slices.kubeNamespaces.filter((e) => {
      return createRsrcKey(e.Spec.Cluster) === createRsrcKey(kubeCluster)
    })
  }

  const renderKubeNamespaceItems = ({
    Deployments = [],
    StatefulSets = [],
    Pods = [],
    KubeServices = []
  }) => {
    const renderedItems = []
    Pods.forEach((pod) => {
      const label = pod.Name
      const nodeId = `POD/${pod.Name}`
      renderedItems.push(
        <TreeItem labelIcon={<TargetIcon type='KUBE_POD' />} labelText={label} nodeId={nodeId} />
      )
    })

    Deployments.forEach((deploy) => {
      const label = deploy.Name
      const nodeId = `DEPLOYMENT/${deploy.Name}`
      renderedItems.push(
        <TreeItem
          labelIcon={<TargetIcon type='KUBE_DEPLOYMENT' />}
          labelText={label}
          nodeId={nodeId}
        />
      )
    })

    StatefulSets.forEach((state) => {
      const label = state.Name
      const nodeId = `STATEFULLSET/${state.Name}`
      renderedItems.push(
        <TreeItem
          labelIcon={<TargetIcon type='KUBE_STATEFULLSET' />}
          labelText={label}
          nodeId={nodeId}
        />
      )
    })

    KubeServices.forEach((service) => {
      const label = service.Name
      const nodeId = `KUBESERVICES/${service.Name}`
      renderedItems.push(
        <TreeItem
          labelIcon={<TargetIcon type='KUBE_SERVICES' />}
          labelText={label}
          nodeId={nodeId}
        />
      )
    })

    return renderedItems
  }

  const renderKubeNamespace = (kube) => {
    const {
      Deployments: { Deployments },
      Pods: { Pods },
      StatefulSets: { StatefulSets },
      KubeServices: { KubeServices }
    } = kube.Spec

    if ([...Deployments, ...Pods, ...StatefulSets, ...KubeServices].length < 1)
      return (
        <TreeItem
          onClick={() => {
            handleNodeClick({
              ref: createRef(kube),
              type: null,
              kubeNamespace: null
            })
          }}
          labelIcon={<TargetIcon type='KUBE_NAMESPACES' />}
          labelText={kube.Spec.Name}
          nodeId={'KUBENAMESPACE/' + createRsrcKey(kube)}
          isGranted={accessState === 'signIn'}
        />
      )

    return (
      <TreeItem
        onClick={() => {
          handleNodeClick({
            ref: createRef(kube),
            type: null,
            kubeNamespace: null
          })
        }}
        labelIcon={<TargetIcon type='KUBE_NAMESPACES' />}
        labelText={kube.Spec.Name}
        nodeId={'KUBENAMESPACE/' + createRsrcKey(kube)}
        isGranted={accessState === 'signIn'}
      >
        {Pods.length > 1 && (
          <TreeItem
            // * Incase of PODS node click
            // onClick={() => {
            //   handleNodeClick({
            //     ref: null,
            //     type: 'PODS',
            //     kubeNamespace: kube
            //   })
            // }}
            labelIcon={<TargetIcon type='KUBE_POD' />}
            labelText={'Pods'}
            nodeId={'PODS/' + createRsrcKey(kube)}
          >
            {renderKubeNamespaceItems({ Pods })}
          </TreeItem>
        )}
        {StatefulSets.length > 0 && (
          <TreeItem
            labelIcon={<TargetIcon type='KUBE_STATEFULLSET' />}
            labelText={'Stateful Sets'}
            nodeId={'STATEFULLSETS/' + createRsrcKey(kube)}
          >
            {renderKubeNamespaceItems({ StatefulSets })}
          </TreeItem>
        )}
        {Deployments.length > 0 && (
          <TreeItem
            labelIcon={<TargetIcon type='KUBE_DEPLOYMENT' />}
            labelText={'Deployments'}
            nodeId={'DEPLOYMENTS/' + createRsrcKey(kube)}
          >
            {renderKubeNamespaceItems({ Deployments })}
          </TreeItem>
        )}
        {KubeServices.length > 0 && (
          <TreeItem
            labelIcon={<TargetIcon type='KUBE_SERVICES' />}
            labelText={'Services'}
            nodeId={'KUBESERVICES/' + createRsrcKey(kube)}
          >
            {renderKubeNamespaceItems({ KubeServices })}
          </TreeItem>
        )}
      </TreeItem>
    )
  }

  const filterDuplicates = (data) => {
    const uniqueNamespaces = new Set()
    const filteredData = []

    data.forEach((item) => {
      const namespace = item.ObjectMeta.Name
      if (!uniqueNamespaces.has(namespace)) {
        uniqueNamespaces.add(namespace)
        filteredData.push(item)
      }
    })

    return filteredData
  }

  const renderTree = () => {
    const filteredData = filterDuplicates(getKubeNamespaces())

    return filteredData.map((kubeNamespace) => {
      return renderKubeNamespace(kubeNamespace)
    })
  }

  return (
    <TreeView
      height={'100%'}
      maxWidth={`100%`}
      onNodeFocus={() => {}}
      onNodeSelect={(_, nodeId) => {}}
      onNodeToggle={() => {}}
      sx={{
        flexGrow: 1,
        overflowY: 'auto'
      }}
    >
      {renderTree()}
    </TreeView>
  )
}

export { KubeClusterTreeView }
