import { EntitiesView, ResourcesSummaryView } from 'features/resources'
import React from 'react'

function AccessSummaryTab({ entities, resources, resourceAttibutesObject = {} }) {
  return (
    <div className='flex h-[100%]'>
      <div className='basis-2/4  pr-8 py-3 border-r border-[#D0D9EA]'>
        <EntitiesView entities={entities} />
      </div>
      <div className='basis-3/4  pl-8 py-2'>
        <ResourcesSummaryView
          resourceAttibutesObject={resourceAttibutesObject}
          resources={resources}
        />
      </div>
    </div>
  )
}

export { AccessSummaryTab }
