import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { deleteUser } from 'features/users'
import React from 'react'

function DeleteUserModal({ user, onCancel, onSuccess }) {
  const userName = getResourceName(user)

  const handleDelete = async () => {
    try {
      await deleteUser(user)
      deleteSliceData(user)
      onSuccess?.()
      enqueueNotification('User deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete user: ${userName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={user} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteUserModal }
