import React from 'react'
import { Route, Switch } from 'react-router'
import { Users } from '../views/Users'
import { AddUsers } from '../views/AddUsers'
import { UserDetails } from '../views/UserDetails'

const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path={['/admin/users']}>
        <Users />
      </Route>
      <Route exact path="/admin/users/create">
        <AddUsers />
      </Route>
      <Route exact path={['/admin/users/:userName']}>
        <UserDetails />
      </Route>
    </Switch>
  )
}

export { UserRoutes }
