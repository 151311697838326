import { ApiProvider } from 'Core'
import { KindToEndpointMap } from 'Utils/Constants'

export const deleteServer = async (server) => {
  await new ApiProvider('servers').setInstance(server).delete()
  return server
}

export const updateResource = async (rsrc) => {
  const response = await new ApiProvider(KindToEndpointMap[rsrc.ObjectMeta.Kind])
    .setInstance(rsrc)
    .put()
  
  return response.data
}

export const updateRDPServer = async (rdp) => {
  const response = await new ApiProvider('rdpservers').setInstance(rdp).put()
  return response.data
}