import { createRsrcKey, getResourceName } from 'features/resources'
import { useServerControllerWizardContext } from 'features/serverControllers'
import { createDataSelectorHook } from 'infra/redux'
import { SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React from 'react'
const useSlices = createDataSelectorHook(['tagPolicys', 'accountList', 'serverControllers'])

const ConfigStep = () => {
  const {
    controllerName,
    updateConfig,
    selectorType,
    selectedAccountKeys,
    ctag,
    label,
    isEditMode
  } = useServerControllerWizardContext()

  const { slices } = useSlices()

  const getLabelsOptions = () => {
    const menuItems = [{ label: 'Select an option', value: '' }]
    const [labelKey] = label.split(':')

    slices.tagPolicys.forEach((tag) => {
      const labelValue = tag.Tags.Map[labelKey] || ''
      if (!labelValue) return
      const splits = labelValue.split(',')
      splits.map((s) => {
        const str = `${labelKey}:${s}`
        if (menuItems.find((e) => e.value == str)) return
        menuItems.push({
          label: s,
          value: str
        })
      })
    })

    return menuItems
  }

  return (
    <div className=''>
      <TextInput
        disabled={isEditMode}
        sx={{ width: '100%' }}
        label='Controller Name'
        value={controllerName}
        onChange={(e) => updateConfig({ controllerName: e.target.value })}
      />
      <div className='mt-4'>
        <Typography variant='buttonLabel-regular' className='mb-1'>
          Selector Type
        </Typography>
        <div className='flex gap-2 items-center'>
          <SelectDropDown
            sx={{ width: '146px' }}
            menuItems={[
              {
                label: 'Account',
                selected: true,
                value: 'Accounts'
              },
              {
                label: 'Labels',
                value: 'Labels'
              },
              {
                label: 'Cloud Tags',
                value: 'CTags'
              }
            ]}
            onChange={(e) => updateConfig({ selectorType: e.target.value })}
            value={selectorType}
          />
          {selectorType === 'Accounts' && (
            <SelectDropDown
              multiple
              sx={{ minWidth: '146px' }}
              //@ts-ignore
              menuItems={slices.accountList.map((account) => {
                const key = createRsrcKey(account)
                return {
                  label: getResourceName(account),
                  value: key
                }
              })}
              onChange={(e) => {
                updateConfig({ selectedAccountKeys: e.target.value })
              }}
              value={selectedAccountKeys}
            />
          )}
          {selectorType === 'Labels' && (
            <>
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={TAG_KEYS}
                onChange={(e) => {
                  updateConfig({ label: e.target.value })
                }}
                value={label.split(':')[0]}
              />
              <SelectDropDown
                sx={{ width: '150px' }}
                menuItems={getLabelsOptions()}
                onChange={(e) => {
                  if (!e.target.value) return
                  updateConfig({ label: e.target.value })
                }}
                value={label.split(':').length > 1 ? label : ''}
              />
            </>
          )}
          {selectorType === 'CTags' && (
            <>
              <TextInput
                sx={{ width: '150px', marginTop: '-22px' }}
                label='Cloud Tag Key'
                placeholder='Eg, Name'
                onChange={(e) => {
                  const [, value] = ctag.split(':')
                  updateConfig({ ctag: `${e.target.value}:${value || ''}` })
                }}
                value={ctag.split(':')[0] || ''}
              />
              <TextInput
                sx={{ width: '150px', marginTop: '-22px' }}
                label='Cloud Tag Value'
                placeholder='Eg, Procyon'
                onChange={(e) => {
                  const [key] = ctag.split(':')
                  updateConfig({ ctag: `${key || ''}:${e.target.value}` })
                }}
                value={ctag.split(':')[1] || ''}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const TAG_KEYS = [
  {
    label: 'App Group',
    value: 'appgrp'
  },
  {
    label: 'Proxy Group',
    value: 'proxygrp'
  },
  {
    label: 'Policy Group',
    value: 'policygrp'
  },
  {
    label: 'Server Group',
    value: 'servergrp'
  },
  {
    label: 'Read Group',
    value: 'readgrp'
  }
]

export { ConfigStep }
