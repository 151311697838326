import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { FullScreenContentModal } from 'V2Components'
import { TabGroup } from 'procyon-ui'
import React from 'react'
import { Header } from './components/Header'

function InfoTabsModal({
  tabs,
  activeIndex,
  setActiveIndex,
  caption,
  onClose,
  onPrimaryButtonClick,
  title,
  primaryButtonLabel
}) {
  useHTMLBodyScrollbar(true)

  return (
    <FullScreenContentModal>
      <div>
        <Header
          caption={caption}
          onClose={onClose}
          onPrimaryButtonClick={onPrimaryButtonClick}
          title={title}
          primaryButtonLabel={primaryButtonLabel}
        />
        <div className='mt-6'>
          <TabGroup
            activeIndex={activeIndex}
            controllable
            onChangeTab={(_, value) => setActiveIndex(value)}
            tabs={tabs}
          />
        </div>
      </div>
    </FullScreenContentModal>
  )
}


export { InfoTabsModal }
