import store from 'infra/redux/store'
import { MapKindToSliceName } from "infra/redux/utils/constants"

/**
 *
 * @param {'CREATE' | 'UPDATE' | 'DELETE' | 'UPDATE_SLICE_MAP'} action
 * @returns
 */
const createSliceUpdateFn = (action) => (data) => {
  if (typeof data !== 'object') return
  const { Kind } = data.ObjectMeta
  const sliceName = MapKindToSliceName[Kind]
  if (!sliceName) return
  const dispatch = store.dispatch
  dispatch({
    type: `${action}/${sliceName}`,
    payload: data
  })
}

export const updateSliceMap = createSliceUpdateFn('UPDATE_SLICE_MAP')
export const pushToSlice = createSliceUpdateFn('CREATE')
export const updateSliceData = createSliceUpdateFn('UPDATE')
export const deleteSliceData = createSliceUpdateFn('DELETE')
