import { useDashboardDataProvider } from 'features/dashboard'
import { createRsrcKey } from 'features/resources'
import moment from 'moment'
import { SessionActivityCard } from 'procyon-ui'
import React from 'react'

const UsersResourcesActivity = () => {
  const { heatMap } = useDashboardDataProvider()

  const getDateMaps = () => {
    const twlvMthMap = {
      users: {},
      resources: {}
    }

    const thirtyDaysMap = {
      users: {},
      resources: {}
    }

    const twentyFourHoursMap = {
      users: {},
      resources: {}
    }

    const userAndResources = [Object.values(heatMap.users), Object.values(heatMap.resources)]

    userAndResources.forEach((dataItems, index) => {
      let mapKey = index === 0 ? 'users' : 'resources'

      dataItems.forEach((data) => {
        const { user, eventTimes, rsrc } = data
        let rsrcObj = mapKey === 'users' ? user : rsrc

        for (const eventTime of eventTimes) {
          const eventMmt = moment(eventTime)
          const yearFmtText = eventMmt.format(YEAR_DISPLAY_FORMAT)

          if (LAST_12_MONTHS.includes(yearFmtText)) {
            const twlvMthMapDataUserKeys = twlvMthMap[mapKey][yearFmtText]?.dataKeys || []

            twlvMthMap[mapKey][yearFmtText] = {
              count: (twlvMthMap[mapKey][yearFmtText]?.count || 0) + 1,
              dataKeys: [...twlvMthMapDataUserKeys, createRsrcKey(rsrcObj)],
              time: yearFmtText
            }
          }

          const monthFmtText = eventMmt.format(THIRTY_DAYS_DISPLAY_FORMAT)

          if (LAST_30_DAYS.includes(monthFmtText)) {
            const dataKeys = thirtyDaysMap[mapKey][monthFmtText]?.dataKeys || []
            thirtyDaysMap[mapKey][monthFmtText] = {
              count: (thirtyDaysMap[mapKey][monthFmtText]?.count || 0) + 1,
              dataKeys: [...dataKeys, createRsrcKey(rsrcObj)],
              time: monthFmtText
            }
          }
          const sub24HourDateTime = moment().subtract(24, 'hours')
          // check if the event falls under 24 hour from the current time
          if (sub24HourDateTime.diff(eventMmt) < 0) {
            const twentyFourHourFmtText = eventMmt.format(TWENTY_FOUR_HOUR_DISPLAY_FORMAT)
            const dataKeys = twentyFourHoursMap[mapKey][twentyFourHourFmtText]?.dataKeys || []
            twentyFourHoursMap[mapKey][twentyFourHourFmtText] = {
              count: (twentyFourHoursMap[mapKey][twentyFourHourFmtText]?.count || 0) + 1,
              dataKeys: [...dataKeys, createRsrcKey(rsrcObj)],
              time: twentyFourHourFmtText
            }
          }
        }
      })
    })

    return { twlvMthMap, thirtyDaysMap, twentyFourHoursMap }
  }

  const { twlvMthMap, thirtyDaysMap, twentyFourHoursMap } = getDateMaps()

  const getLast12MonthsData = () => {
    const data = []
    LAST_12_MONTHS.forEach((time) => {
      const users = twlvMthMap.users[time]
      const resources = twlvMthMap.resources[time]

      data.push(
        {
          amount: users?.count || 0,
          name: 'Users',
          time
        },
        {
          amount: resources?.count || 0,
          name: 'Resources',
          time
        }
      )
    })

    return data
  }

  const getLast30DaysData = () => {
    const data = []

    LAST_30_DAYS.forEach((time) => {
      const users = thirtyDaysMap.users[time]
      const resources = thirtyDaysMap.resources[time]

      data.push(
        {
          amount: users?.count || 0,
          name: 'Users',
          time
        },
        {
          amount: resources?.count || 0,
          name: 'Resources',
          time
        }
      )
    })

    return data
  }

  const getLast7DaysData = () => {
    const data = []

    LAST_7_DAYS.forEach((time) => {
      const users = thirtyDaysMap.users[time]
      const resources = thirtyDaysMap.resources[time]

      data.push(
        {
          amount: users?.count || 0,
          name: 'Users',
          time
        },
        {
          amount: resources?.count || 0,
          name: 'Resources',
          time
        }
      )
    })

    return data
  }

  const getLast24HoursData = () => {
    const data = []

    LAST_24_HOURS_DATES_GROUPS.forEach((time) => {
      const users = twentyFourHoursMap.users[time]
      const resources = twentyFourHoursMap.resources[time]

      data.push(
        {
          amount: users?.count || 0,
          name: 'Users',
          time
        },
        {
          amount: resources?.count || 0,
          name: 'Resources',
          time
        }
      )
    })

    return data
  }

  const mmData = getLast12MonthsData()
  const last30DaysData = getLast30DaysData()
  const last7DaysData = getLast7DaysData()
  const last24HoursData = getLast24HoursData()

  return (
    <div className='mt-8'>
      <SessionActivityCard
        caption='active users vs resources accessed'
        title='Activity'
        dataSet={{
          '12m': mmData,
          '24h': last24HoursData,
          '30d': last30DaysData,
          '7d': last7DaysData
        }}
      />
    </div>
  )
}

const YEAR_DISPLAY_FORMAT = 'MMM YYYY'
const THIRTY_DAYS_DISPLAY_FORMAT = 'DD MMM'
const SEVEN_DAYS_DISPLAY_FORMAT = 'DD MMM'
const TWENTY_FOUR_HOUR_DISPLAY_FORMAT = 'HH:00 A, DD MMM'

const LAST_12_MONTHS = []

//* LAST 12 MONTHS FORMATTED DATES
let i = 11

while (i >= 0) {
  const mmt = moment().subtract(i, 'months')
  const date = mmt.format(YEAR_DISPLAY_FORMAT)
  LAST_12_MONTHS.push(date)
  i--
}
//* LAST 30 DAYS FORMATED DATES
const LAST_30_DAYS = []
i = 30

while (i >= 0) {
  const mmt = moment().subtract(i, 'days')
  const date = mmt.format(THIRTY_DAYS_DISPLAY_FORMAT)
  LAST_30_DAYS.push(date)
  i--
}

//* LAST 7 DAYS FORMATTED DATES

const LAST_7_DAYS = []
i = 7

while (i >= 0) {
  const mmt = moment().subtract(i, 'days')
  const date = mmt.format(SEVEN_DAYS_DISPLAY_FORMAT)
  LAST_7_DAYS.push(date)
  i--
}

//* LAST 7 DAYS "DATES"

const LAST_24_HOURS_DATES_GROUPS = []
i = 24

while (i >= 0) {
  const mmt = moment().subtract(i, 'hours')
  const date = mmt.format(TWENTY_FOUR_HOUR_DISPLAY_FORMAT)
  LAST_24_HOURS_DATES_GROUPS.push(date)
  i--
}

export { UsersResourcesActivity }
