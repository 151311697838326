import React from 'react'
import { Route, Switch } from 'react-router'
import { Workloads } from '../views/Workloads'
import { AddWorkload } from '../views/AddWorkload'
import { WorkloadDetails } from '../views/WorkloadDetails'
import { WorkloadResourcesView } from '../views/WorkloadResourcesView'
import { EditWorkload } from '../views/EditWorkload'

const WorkloadsRoutes = () => {
  return (
    <Switch>
      <Route exact path={['/admin/workloads']}>
        <Workloads />
      </Route>
      <Route exact path={['/admin/workloads/create']}>
        <AddWorkload />
      </Route>
      <Route exact path={['/admin/workloads/:workloadName/edit']}>
        <EditWorkload />
      </Route>
      <Route exact path={['/admin/workloads/:workloadName']}>
        <WorkloadDetails />
      </Route>
      <Route exact path={['/admin/workloads/:workloadName/create-policy']}>
        <WorkloadResourcesView />
      </Route>
    </Switch>
  )
}

export { WorkloadsRoutes }
