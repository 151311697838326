import { useCloudDetailsProvider } from 'features/clouds'
import { ManageOwnersView } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'
import { AWSAccount } from './components/AWSAccount'
import { AZUREAccount } from './components/AZUREAccount'
import { GCPAccount } from './components/GCPAccount'

const OverviewTab = () => {
  const { cloud } = useCloudDetailsProvider()

  const type = cloud.Spec.Type

  return (
    <div className='flex gap-4'>
      <div className='w-1/2'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4-regular'>Account Information</Typography>
        </div>
        {type === 'AWS' && <AWSAccount />}
        {type === 'GCP' && <GCPAccount />}
        {type === 'AZURE' && <AZUREAccount />}
      </div>
      <div className='w-1/2'>
        <ManageOwnersView rsrc={cloud} />
      </div>
    </div>
  )
}

export { OverviewTab }
