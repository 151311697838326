import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { getResourceName } from 'features/resources'
import { DeleteServerControllerModal } from 'features/serverControllers'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, Label, ResponsiveTable, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import { compareDatesFn } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal, SearchInput } from 'V2Components'
import { CreateServerControllerWizard } from '../CreateServerControllerWizard'

const useSlices = createDataSelectorHook(['serverControllers', 'accountList'])

const ServerControllers = () => {
  const [searchKey, setSearchKey] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedSrvCtrl, setSelectedSrvCtrl] = useState(null)

  const history = useHistory()

  const handleAddClick = () => {
    history.push('/admin/server-controllers/create')
  }

  const { slices } = useSlices()

  const getRowData = () => {
    return slices.serverControllers.map((srvCtrl) => {
      const labelsMap = srvCtrl.Spec.Labels.Map
      const labels = []

      for (const key in labelsMap) {
        labels.push(`${key}:${labelsMap[key]}`)
      }

      const ctagsMap = srvCtrl.Spec.CTags.Map
      const ctags = []

      for (const key in ctagsMap) {
        ctags.push(`${key}:${ctagsMap[key]}`)
      }

      return {
        name: {
          name: getResourceName(srvCtrl),
          onClick() {
            history.push(`/admin/server-controllers/${srvCtrl.ObjectMeta.Name}`)
          }
        },
        users: srvCtrl.Spec.Users.Elems,
        groups: srvCtrl.Spec.Groups.Elems,
        anonymousLoginEnabled: !srvCtrl.Spec.DisableAnonymousLogin,
        osLogin: !srvCtrl.Spec.DisableOsLogin,
        labels,
        createdOn: srvCtrl.ObjectMeta.CreatedAt,
        ctags,
        delete: {
          onClick() {
            setShowDeleteModal(true)
            setSelectedSrvCtrl(srvCtrl)
          }
        }
      }
    })
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h2'>Server Controllers</Typography>
        <div className='flex gap-4 mt-3 items-center'>
          <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
          <Button onClick={handleAddClick} size='md' variant='primary' icon={faPlus}>
            Create
          </Button>
        </div>
      </div>
      <div className='mt-6'>
        <ResponsiveTable scrollX='1200px' data={getRowData()} columns={columns()} />
      </div>
      {/* @ts-ignore */}
      <Route exact path={['/admin/server-controllers/create']}>
        <FullScreenContentModal>
          <CreateServerControllerWizard
            onCancel={() => history.push(`/admin/server-controllers`)}
          />
        </FullScreenContentModal>
      </Route>
      {selectedSrvCtrl && showDeleteModal && (
        <DeleteServerControllerModal
          serverController={selectedSrvCtrl}
          onCancel={() => {
            setShowDeleteModal(false)
            setSelectedSrvCtrl(null)
          }}
          cleanUpFn={() => {
            setShowDeleteModal(false)
            setSelectedSrvCtrl(null)
          }}
        />
      )}
    </div>
  )
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const columns = () => [
  {
    dataIndex: 'name',
    onCell(data) {
      return {
        onClick: () => data.name.onClick()
      }
    },
    title: 'Name',
    fixed: 'left',
    width: 250,
    sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
    render(value) {
      return (
        <Typography className='flex gap-1 items-center' variant='body-regular'>
          {value.name}
        </Typography>
      )
    }
  },
  {
    dataIndex: 'createdOn',
    title: 'Created On',
    fixed: 'left',
    width: 250,
    sorter: (v1, v2) => compareDatesFn({ date: v1.createdOn }, { date: v2.createdOn }),
    render(value) {
      return (
        <Typography className='flex gap-1 items-center' variant='body-regular'>
          {moment(value).format('MMMM Do YYYY, h:mm A')}
        </Typography>
      )
    }
  },
  {
    dataIndex: 'anonymousLoginEnabled',
    onCell(data) {
      return {
        onClick: () => {}
      }
    },
    title: 'Anonymous Login',
    width: 150,
    sorter: (v1, v2) =>
      v1.anonymousLoginEnabled.toString().localeCompare(v2.anonymousLoginEnabled.toString()),
    render(value) {
      if (value) return <Label text='Enabled' variant='success' />
      return <Label text='Disabled' variant='gray' />
    }
  },
  {
    dataIndex: 'osLogin',
    onCell(data) {
      return {
        onClick: () => {}
      }
    },
    title: 'OS Login',
    width: 130,
    sorter: (v1, v2) => v1.osLogin.toString().localeCompare(v2.osLogin.toString()),
    render(value) {
      if (value) return <Label text='Enabled' variant='success' />
      return <Label text='Disabled' variant='gray' />
    }
  },
  {
    dataIndex: 'delete',
    onCell(data) {
      return {
        onClick: () => {}
      }
    },
    title: '',
    width: 130,
    fixed: 'right',
    render(value) {
      return (
        <Button variant='grayRed' onClick={() => value.onClick()}>
          Delete
        </Button>
      )
    }
  }
]

export { ServerControllers }
