
import { Label, TabGroup } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

function ReleaseNotes() {
  const [selectedTab, setSelectedTab] = useState('Web Application')
  const [releaseNoteData, setReleaseNoteData] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [showReleaseNote, setShowReleaseNote] = useState(false)
  const [currentReleaseNoteIndex, setCurrentReleaseNoteIndex] = useState(0)

  useEffect(() => {
    fetch('https://procyon-release-notes.s3.us-west-2.amazonaws.com/release-notes-staging.json')
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`)
        }
        return res.json()
      })
      .then((data) => {
        setReleaseNoteData(data)
        setCurrentReleaseNoteIndex(0)
        setActiveIndex(0)
        setShowReleaseNote(true)
        console.log(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  const handleTabChange = (tab) => {
    if (tab === 'Web Application') {
      setActiveIndex(0)
    } else {
      setActiveIndex(1)
    }
    setSelectedTab(tab)
  }
  const webReleaseNotes = releaseNoteData?.Web || []
  const agentReleaseNotes = releaseNoteData?.Agent || []

  const handleLinkClick = (index) => {
    setShowReleaseNote(false)
    setTimeout(() => {
      setCurrentReleaseNoteIndex(index)
      setShowReleaseNote(true)
    }, 300)
  }

  return (
    <div className='flex'>
      <div className='w-2/12 bg-[#f6fbff]'>
        <h3 className='text-base font-semibold px-3 py-2'>Versions</h3>
        <ul>
          {selectedTab === 'Web Application'
            ? webReleaseNotes.map((note, index) => (
                <li
                  className='text-sm font-medium mt-1 bg-[#fff] py-3 pl-5 cursor-pointer hover:bg-[#d6eafa]'
                  key={`web-${index}`}
                  onClick={() => handleLinkClick(index)}
                >
                  
                    {`${note.Version}`}
                  
                </li>
              ))
            : agentReleaseNotes.map((note, index) => (
                <li
                  className='text-sm font-medium mt-1 bg-[#fff] py-3 pl-5 cursor-pointer hover:bg-[#d6eafa]'
                  key={`agent-${index}`}
                  onClick={() => handleLinkClick(index)}
                >
                  {`${note.Version}`}
                </li>
              ))}
        </ul>
      </div>
      <div className='content w-3/6'>
        {Object.keys(releaseNoteData).length >= 1 && (
          <TabGroup
            controllable
            // tabContentHeight='100vh'
            activeIndex={activeIndex}
            onChangeTab={(e) => handleTabChange(e.target.innerText)}
            tabs={[
              {
                label: 'Web Application',
                tabContent: (
                  <>
                    {webReleaseNotes.length > 0 && webReleaseNotes[currentReleaseNoteIndex] && (
                      <div
                        className={`release-note mb-20 ${showReleaseNote ? 'fade-in' : 'fade-out'}`}
                        style={{
                          opacity: showReleaseNote ? 1 : 0,
                          transition: 'opacity 0.3s ease-in-out'
                        }}
                      >
                        <div className='bg-[#fff] w-full px-5'>
                          <h2 className='text-lg font-bold mb-3 text-[#4e4e4e]'>
                            {webReleaseNotes[currentReleaseNoteIndex].Version}
                          </h2>
                          <div className='flex items-center mb-3'>
                            <Label className='bg-[#f6fbff]' key={'added'} text={'Added'} />
                            <span className='text-small pl-3'>
                              {webReleaseNotes[currentReleaseNoteIndex].ReleaseDate}
                            </span>
                          </div>
                          {Object.keys(webReleaseNotes[currentReleaseNoteIndex].NewFeatures).length >=1 && 
                          <h3 className='flex text-base self-center font-bold my-5 text-[#4e4e4e]'>
                            <span>
                              <img
                                className='w-8'
                                src='https://procyon-release-notes.s3.us-west-2.amazonaws.com/icons/new-features.png'
                                alt='feature'
                              />
                            </span>
                            New Features
                          </h3>
                          }
                          <ul className='list-disc pl-5'>
                            {Object.entries(
                              webReleaseNotes[currentReleaseNoteIndex].NewFeatures
                            ).map(([key, value]) => (
                              <li key={key}>
                                <label className='font-semibold text-[#4e4e4e]'>{key}</label>
                                <ul className='list-disc pl-5 my-3'>
                                  <li className='text-small text-[#545b71]'>{value}</li>
                                </ul>
                              </li>
                            ))}
                          </ul>

                          {Object.keys(webReleaseNotes[currentReleaseNoteIndex].BugFix).length >= 1 &&    
                          <h3 className='flex text-base self-center font-bold my-5 text-[#6a6969]'>
                            <span>
                              <img
                                className='w-8'
                                src='https://procyon-release-notes.s3.us-west-2.amazonaws.com/icons/bug.png'
                                alt='bug icon'
                              />
                            </span>
                            Bug Fixes
                          </h3>
                          }
                          <ul className='list-disc pl-5'>
                            {Object.entries(
                              webReleaseNotes[currentReleaseNoteIndex].BugFix
                            ).map(([key, value]) => (
                              <li key={key}>
                                <label className='font-semibold text-[#4e4e4e]'>{key}</label>
                                <ul className='list-disc pl-5 my-3'>
                                  <li className='text-small text-[#545b71]'>{value}</li>
                                </ul>
                              </li>
                            ))}
                          </ul>

                          {Object.keys(webReleaseNotes[currentReleaseNoteIndex].KnownIssues).length >= 1 &&    
                          <h3 className='flex text-base self-center font-bold my-5 text-[#6a6969]'>
                            Known Issues
                          </h3>
                          }
                         <ul className='list-disc pl-5'>
                            {Object.entries(
                              webReleaseNotes[currentReleaseNoteIndex].KnownIssues
                            ).map(([key, value]) => (
                              <li key={key}>
                                <label className='font-semibold text-[#4e4e4e]'>{key}</label>
                                <ul className='list-disc pl-5 my-3'>
                                  <li className='text-small text-[#545b71]'>{value}</li>
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                )
              },
              {
                label: 'Agent Application',
                tabContent: (
                  <div>
                    <>
                      {agentReleaseNotes.length > 0 && agentReleaseNotes[currentReleaseNoteIndex] && (
                        <div
                          className={`mb-20`}
                          style={{
                            opacity: showReleaseNote ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out'
                          }}
                        >
                          <div className='bg-[#fff] w-full px-5'>
                            <h2 className='text-lg font-bold mb-3 text-[#4e4e4e]'>
                              {agentReleaseNotes[currentReleaseNoteIndex].Version}
                            </h2>
                            <div className='flex items-center mb-3'>
                              <Label className='bg-[#f6fbff]' key={'added'} text={'Added'} />
                              <span className='text-small pl-3'>
                                {agentReleaseNotes[currentReleaseNoteIndex].ReleaseDate}
                              </span>
                            </div>

                            <h2 className='text-base font-medium text-[#4e4e4e]'>Agent Version - {agentReleaseNotes[currentReleaseNoteIndex].AgentVersion}</h2>

                            {Object.keys(agentReleaseNotes[currentReleaseNoteIndex].NewFeatures).length >= 1 && 
                            <h3 className='flex text-base self-center font-bold my-5 text-[#4e4e4e]'>
                              <span>
                                <img
                                  className='w-8'
                                  src='https://procyon-release-notes.s3.us-west-2.amazonaws.com/icons/new-features.png'
                                  alt='feature'
                                />
                              </span>
                              New Features
                            </h3>}
                            <ul className='list-disc pl-5'>
                              {Object.entries(agentReleaseNotes[currentReleaseNoteIndex].NewFeatures).map(([platform, features]) => (
                                <li key={platform}>
                                  <label className='font-semibold text-[#4e4e4e]'>{platform}</label>
                                  <ul className='list-disc pl-5 my-3'>
                                    {features.map((feature, index) => (
                                      <li key={index} className='text-small text-[#545b71]'>{feature}</li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                            {Object.keys(agentReleaseNotes[currentReleaseNoteIndex].BugFix).length >= 1 &&    
                          <h3 className='flex text-base self-center font-bold my-5 text-[#6a6969]'>
                            <span>
                              <img
                                className='w-8'
                                src='https://procyon-release-notes.s3.us-west-2.amazonaws.com/icons/bug.png'
                                alt='bug icon'
                              />
                            </span>
                            Bug Fixes
                          </h3>
                          }
                          <ul className='list-disc pl-5'>
                            {Object.entries(
                              agentReleaseNotes[currentReleaseNoteIndex].BugFix
                            ).map(([key, value]) => (
                              <li key={key}>
                                <label className='font-semibold text-[#4e4e4e]'>{key}</label>
                                <ul className='list-disc pl-5 my-3'>
                                  <li className='text-small text-[#545b71]'>{value}</li>
                                </ul>
                              </li>
                            ))}
                          </ul>

                          {Object.keys(agentReleaseNotes[currentReleaseNoteIndex].KnownIssues).length >= 1 &&    
                          <h3 className='flex text-base self-center font-bold my-5 text-[#6a6969]'>
                            Known Issues
                          </h3>
                          }
                         <ul className='list-disc pl-5'>
                            {Object.entries(
                              agentReleaseNotes[currentReleaseNoteIndex].KnownIssues
                            ).map(([key, value]) => (
                              <li key={key}>
                                <label className='font-semibold text-[#4e4e4e]'>{key}</label>
                                <ul className='list-disc pl-5 my-3'>
                                  <li className='text-small text-[#545b71]'>{value}</li>
                                </ul>
                              </li>
                            ))}
                          </ul>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                )
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}

export { ReleaseNotes }
