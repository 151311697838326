import { Help } from '@material-ui/icons'
import _ from 'lodash'
import { TargetIcon } from 'procyon-ui'

export const AzureDisplayTypeToIconTypeMap = {
  'Virtual Machine': 'AZURE_VM_TYPE',
  'PostgreSQL Flexible Server': 'AZURE_SQL_SERVER_TYPE',
  'MySQL Flexible Server': 'AZURE_SQL_SERVER_TYPE',
  'Storage Table': 'AZURE_STORAGE_TABLE',
  Subscription: 'AZURE_SUBSCRIPTION_TYPE',
  'Managed Cluster': 'AZURE_MANAGED_CLUSTER',
  'SQL Server': 'AZURE_SQL_SERVER_TYPE',
  'Cosmos DB Account': 'AZURE_COSMOS_DB_ACCOUNT',
  'Microsoft Entra ID': 'AZURE_TENANT_TYPE',
  'Storage Blob Service': 'AZURE_BLOB_SERVICE',
  'SQL Server Database': 'AZURE_SQL_SERVER_TYPE',
  'Resource Group': 'AZURE_RESOURCE_GROUP',
  'Load Balancer': 'AZURE_LOAD_BALANCER',
  'Storage Account': 'AZURE_STORAGE',
  'Storage Blob Container': 'AZURE_BLOB_CONTAINER',
  'PostgreSQL Server': 'AZURE_SQL_SERVER_TYPE',
  'Logic App Workflow': 'AZURE_LOGIC_APP_WORKFLOW',
  WebApps: 'AZURE_WEB_APPS',
  'Redis Caches': '',
  'AKS Namespace': 'AZURE_KUBENAMESPACE'
}

/**
 *
 * @param {string|{ Spec:{ Type?:string, DisplayType:? string } }} object
 */
export const getAZUREResourceIcon = (object) => {
  try {
    let displayType = ''
    if (typeof object === 'object') displayType = _.get(object, 'Spec.DisplayType', '')
    if (typeof object === 'string') displayType = object

    return ({ ...props }) => (
      <TargetIcon type={AzureDisplayTypeToIconTypeMap[displayType] || 'AZURE_APPLICATION'} {...props} />
    )
  } catch (error) {
    console.log('error in getting azureicon', error)
    return Help
  }
}
