import useAppView from 'Core/Hooks/useAppView'
import { SummaryHeader } from 'V2Components'
import { IAMRoleDeleteModal } from 'features/iamRoles'
import { isCustomIAMRole } from 'features/iamRoles/utils'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Button } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

const Header = () => {
  const [showDeleteIAMRole, setShowDeleteIAMRole] = useState(false)
  const roleRsrc = useDataDetailsContext()
  const isCustom = isCustomIAMRole(roleRsrc)
  const history = useHistory()
  const { appView } = useAppView()

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-6 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon(roleRsrc)}
        rsrcName={getResourceName(roleRsrc)}
        actions={
          <>
            {isCustom && appView === 'admin' && (
              <>
                <Button
                  onClick={() => {
                    history.push(
                      `/admin/iam-roles/${roleRsrc.Spec.CloudType.toLowerCase()}/${
                        roleRsrc.ObjectMeta.Name
                      }/edit`
                    )
                  }}
                  variant='grayBlue'
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setShowDeleteIAMRole(true)
                  }}
                  variant='grayRed'
                >
                  Delete
                </Button>
              </>
            )}
          </>
        }
        breadCrumbsItems={[
          {
            label: 'IAM Roles',
            link: `/${appView}/iam-roles/aws`
          },
          {
            label: getResourceName(roleRsrc)
          }
        ]}
      />
      {showDeleteIAMRole && (
        <IAMRoleDeleteModal
          iamrole={roleRsrc}
          onCancel={() => {
            setShowDeleteIAMRole(false)
          }}
          onSuccess={() => {
            history.push('/admin/iam-roles/aws')
          }}
        />
      )}
    </div>
  )
}

export { Header }
