import { ActivityLogs } from 'features/activityLogs'
import { TagsAndLabelsView, createRsrcKey, isResourceManaged, useResourceDetails } from 'features/resources'
import { TabGroup } from 'procyon-ui'
import React, { useMemo } from 'react'
import { KubernetResources } from '../KubernetResources'
import { Overview } from './Components/Overview'

function TargetDetails({ tabs = [], onAccessClick }) {
  const { rsrc } = useResourceDetails()

  const memoizedTabs = useMemo(() => {
    const tTabs = [...tabs]

    if (['AppRole', 'Server'].includes(rsrc.ObjectMeta.Kind)) {
      tTabs.push({
        label: 'Activity Logs',
        tabContent: <ActivityLogs rsrc={rsrc} />
      })
    }

    // add resource tab if kind is KubeCluster
    if (['KubeCluster'].includes(rsrc.ObjectMeta.Kind) && isResourceManaged(rsrc)) {
      tTabs.unshift({
        label: 'Resources',
        tabContent: <KubernetResources rsrc={rsrc} onAccessClick={onAccessClick} />
      })
    }

    return [
      {
        label: 'Overview',
        tabContent: <Overview />
      },
      ...tTabs,
      {
        label: 'Tags and Labels',
        tabContent: <TagsAndLabelsView rsrc={rsrc} />
      }
    ]
  }, [tabs, rsrc])

  return <TabGroup key={createRsrcKey(rsrc)} tabs={memoizedTabs} />
}

export { TargetDetails }
