// @ts-nocheck
import { Box, makeStyles } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import React, { useCallback, useMemo } from 'react'
const useStyle = makeStyles(theme => ({
  analyticsTable: {
    boxShadow: 'unset',
    '& thead ': {
      display: (props) => props.hideHeader ? 'none' : 'table-group-header',
      height: props => props.headHeight,
      minHeight: '0px!important',
      '& th': {
        padding: '0px !important',
        height: props => props.headHeight,
        '& > span': {
          justifyContent: props => props.headerJustify
        },
        '& button': {
          padding: theme.spacing(0, 0, 0, 0)
        }
      },
      '& tr': {
        height: props => props.headHeight
      }
    },
    '& tbody tr': {
      height: props => props.rowHeight
    },
    '& td': {
      padding: props => props.padding && theme.spacing(
        props.padding.top,
        props.padding.right,
        props.padding.bottom,
        props.padding.left
      ),
      verticalAlign: props => props.vAlign
    }
  }
}))
const defaultTDPadding = { top: 0.75, right: 1, bottom: 0.5, left: 1 }
const AnalyticTable = ({ searchAll = false, searchString, headHeight = '50px', rowHeight = '50px', headerJustify = 'flex-end', columns, data, options, vAlign = 'inherit', padding = defaultTDPadding }) => {
  const classes = useStyle({ headHeight, rowHeight, headerJustify, vAlign, padding, hideHeader: options.hideHeader })

  const customSearch = useCallback((searchQuery, currentRow, columns) => {
    try {
      let isFound = false
      currentRow.forEach(col => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true
        }
      })
      return isFound
    } catch (error) {
      console.log('error in custom search', error)
      return false
    }
  }, [])
  const customOptions = useMemo(() => {
    const _options = {
      ...options,
      searchText: searchString
    }
    if (searchAll) {
      _options.customSearch = customSearch
    }
    return _options
  }, [options, searchString, searchAll, customSearch])
  return (
    <Box>
      <MUIDataTable
        className={classes.analyticsTable}
        data={data}
        columns={columns}
        options={customOptions}
      />
    </Box>
  )
}

export default AnalyticTable
