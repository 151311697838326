import { Box } from '@material-ui/core'
import React from 'react'

const Footer = ({
  changeFilter,
  currentFilters,
  SeriesFilterComponent,
  DatasetComponent,
  dataSetKeys,
  selectedDataSet,
  handleDataSetChange,
  showDataSet
}) => {
  return (
    <Box px={0.5} display='flex'>
      {(DatasetComponent && showDataSet) && <DatasetComponent selectedDataSet={selectedDataSet} dataSetKeys={dataSetKeys} handleDataSetChange={handleDataSetChange} />}
      {SeriesFilterComponent && <SeriesFilterComponent filters={currentFilters} changeFilter={changeFilter} />}
    </Box>
  )
}

export default Footer
