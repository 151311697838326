import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import {deleteUser } from 'features/github'
import React from 'react'

function DeleteUserModal({ app, cleanUpFn, onCancel = null, name }) {
  const {slices, selectDispatch} = useMultiSlice(['githubAccount'])

  const handleDeleteApplication = async () => {
    const deletedApp = await deleteUser(app)
    selectDispatch(['githubAccount'])
    enqueueNotification('User Deleted Successfully!', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage={`Are you sure you want to delete the user ${name} ?`}
        loadingMessage='Deleting User'
        onActionClick={handleDeleteApplication}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeleteUserModal }
