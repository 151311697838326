import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { LoadingFeedback } from 'V2Components'
import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
const DataDetailsContext = createContext(undefined)

/**
 *
 * @param {{
 * children?: any,
 * routeKey: string
 * sliceName: import('types').SliceNames
 * urlSearchQuery?: boolean
 * loadingMessage?: string
 * skip?: boolean
 * }} param0
 * @returns
 */
export const DataDetailsProvider = ({
  children,
  routeKey,
  sliceName,
  loadingMessage = 'Fetching Data',
  urlSearchQuery, //when true instead of using `routeKey` from route, Search param is used such as: https://<>/?routeKey=value
  skip
}) => {
  const [dataObject, setDataObject] = useState(null)

  //@ts-ignore
  const objectName = useParams()[routeKey]
  const searchQueryName = useURLQuery().get(routeKey)
  const { slices, dispatchThunks } = useMultiSlice([sliceName])

  const sliceList = slices[sliceName] || []

  if (sliceList.length && !skip) {
    const obj = _.find(sliceList, {
      ObjectMeta: { Name: urlSearchQuery ? searchQueryName : objectName }
    })
    if (obj && obj !== dataObject) setDataObject(obj)
  }

  useEffect(() => {
    !skip && dispatchThunks({ skipWhenLoaded: true })
  }, [])

  return (
    <DataDetailsContext.Provider value={dataObject}>
      {!dataObject && !skip && (
        <LoadingFeedback shadow={false} shrinked loading message={loadingMessage} />
      )}
      {skip ? children : dataObject && children}
    </DataDetailsContext.Provider>
  )
}

export const useDataDetailsContext = () => useContext(DataDetailsContext)
