import { Box, Button } from '@material-ui/core'
import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
const ModalView = ({ Table, seriesMeta, closeModal, searchPlaceholder }) => {
  const [searchKey, setSearchkey] = useState('')
  return (
    <Box mt={1} justifyContent='space-between' display='flex' flexDirection='column' height='100%'>
      <Box height='30rem' flexGrow={1} display='flex' flexDirection='column' px={1.5}>

        <Header searchPlaceholder={searchPlaceholder} searchKey={searchKey} setSearchKey={setSearchkey} seriesMeta={seriesMeta} />
        <Box flexGrow={1} overflow='auto' mt={0.25}>
          {Table && <Table searchKey={searchKey} />}
        </Box>
      </Box>
      <Footer closeModal={closeModal} />
    </Box>
  )
}

export default ModalView
