import { FullScreenModal } from 'V2Components'
import { AccountSelectionItem, Button, TargetIcon, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useChannelBrowser } from '../../../providers/hooks'
import { useParams } from 'react-router'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { WebhookChannelSelection } from './components/WebhookChannelSelection'
import { ServiceNowChannelSelection } from './components/ServiceNowChannelSelection'
import { MsChannelSelection } from './components/MsChannelSelection'
import { createDataSelectorHook } from 'infra/redux'

const useSlices = createDataSelectorHook(['msTeamsChannels'])

const SelectChannel = ({ showModal, setShowModal, onConfirmChannelChange, notificationType }) => {
  const { linkedChannels, refresh, integration } = useChannelBrowser()
  const { integrationName, kind } = useParams()
  const { slices } = useSlices()
  const [selectedChannel, setSelectedChannel] = useState({})
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isWebhookChannelSelected, setIsWebhookChannelSelected] = useState(false)

  const handleChannelSelection = (channel, index) => {
    setSelectedChannel(channel)
    setSelectedItemIndex(index)
  }

  const getTitle = () => {
    if (
      kind === 'SlackIntegration' ||
      (kind === 'MsTeamsIntegration' && notificationType === 'getway')
    ) {
      return 'Select Channel To Get Gateway Notification'
    }

    if (
      kind === 'SlackIntegration' ||
      (kind === 'MsTeamsIntegration' && notificationType === 'private')
    ) {
      return 'Select Channel To Get Private Notification'
    }

    if (kind === 'ServiceNowIntegration' && notificationType === 'application') {
      return 'Select Application Task Table '
    }

    if (kind === 'ServiceNowIntegration' && notificationType === 'private') {
      return 'Select Private Task Table'
    }
  }

  const filteredChannel = linkedChannels.filter((item) => {
    const channelName = item?.Spec?.ChannelName
    const name = item?.Spec?.Name
    const projectName = item?.Spec?.ProjectName

    if (
      kind === 'SlackIntegration' &&
      channelName &&
      channelName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    ) {
      return true
    }

    // If ChannelName is not found, check Name and Project
    if (!channelName && kind === 'ServiceNowIntegration') {
      return (
        (name && name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
        (projectName && projectName.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
      )
    }

    if (name && kind === 'MsTeamsIntegration') {
      return name && name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    }

    return false
  })

  const getIcon = (kind) => {
    switch (kind) {
      case 'SlackIntegration':
        return 'SLACK'
      case 'ServiceNowIntegration':
        return 'SERVICENOW'
      case 'MsTeamsIntegration':
        return 'TEAMS'
      default:
        return null
    }
  }

  const handleAccountSelectionCancel = () => {
    setIsWebhookChannelSelected(false)
    setSearchTerm('')
    setShowModal(false)
    setSelectedItemIndex(-1)
  }

  const handleChannelConfirm = (selectedChannel, notificationType) => {
    setSearchTerm('')
    onConfirmChannelChange(selectedChannel, notificationType)
  }

  const getChannelSelectComponent = () => {
    switch (kind) {
      case 'SlackIntegration':
        return (
          <WebhookChannelSelection
            kind={getIcon(integration?.ObjectMeta?.Kind)}
            isWebhookChannelSelected={isWebhookChannelSelected}
            setIsWebhookChannelSelected={setIsWebhookChannelSelected}
          />
        )
      case 'ServiceNowIntegration':
        return (
          <ServiceNowChannelSelection
            filteredChannel={filteredChannel}
            selectedItemIndex={selectedItemIndex}
            handleChannelSelection={handleChannelSelection}
          />
        )
      case 'MsTeamsIntegration':
        if (integration?.Spec?.WebhookUrl === '') {
          return (
            <MsChannelSelection
              filteredChannel={filteredChannel}
              msTeamsChannels={slices.msTeamsChannels}
              handleChannelSelection={handleChannelSelection}
            />
          )
        } else {
          return (
            <WebhookChannelSelection
              kind={getIcon(integration?.ObjectMeta?.Kind)}
              isWebhookChannelSelected={isWebhookChannelSelected}
              setIsWebhookChannelSelected={setIsWebhookChannelSelected}
            />
          )
        }
      default:
        return null
    }
  }

  return (
    <FullScreenModal showModal={showModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between flex-col'>
            <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
              <TargetIcon
                width={'48px'}
                height={'48px'}
                type={getIcon(kind)}
                className='rounded'
                style={{
                  borderRadius: '12px',
                  marginRight: '15px'
                }}
              />
              {getTitle()}
            </Typography>
            <p className='ml-16 font-thin'>{integrationName}</p>
          </div>
          <div className='my-5'>
            <TextInput
              icon={faMagnifyingGlass}
              iconPosition='end'
              onChange={(e) => setSearchTerm(e.target.value)}
              onClickEndIcon={function noRefCheck() {}}
              placeholder='Search'
              style={{
                width: '550px'
              }}
            />
          </div>
          <div className='mt-2 rounded text-black max-h-[40vh] overflow-auto'>
            {getChannelSelectComponent()}
          </div>

          <div className='flex justify-end mt-10 gap-4'>
            <Button size='md' onClick={() => handleAccountSelectionCancel()} variant='gray'>
              Cancel
            </Button>
            <Button
              size='md'
              onClick={() => handleChannelConfirm(selectedChannel, notificationType)}
              variant='primary'
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { SelectChannel }
