import React from 'react'
import { Route } from 'react-router'
import { PolicyDetails, PolicyList } from '../views'

const PolicyRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/policys']}>
        <PolicyList />
      </Route>

      <Route exact path={['/admin/policys/:policyName']}>
        <PolicyDetails />
      </Route>

      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/policys']}>
        <PolicyList />
      </Route>

      <Route exact path={['/user/policys/:policyName']}>
        <PolicyDetails />
      </Route>
    </>
  )
}

export { PolicyRoutes }
