import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { deleteTagPolicy } from 'features/settings'
import React from 'react'

function DeleteTagModal({ tag, onCancel, onSuccess }) {
  const tagName = getResourceName(tag)

  const handleDelete = async () => {
    try {
      await deleteTagPolicy(tag)
      deleteSliceData(tag)
      onSuccess?.()
      enqueueNotification('Tag deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete tag: ${tagName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={tag} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteTagModal }
