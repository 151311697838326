import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { FullScreenModal } from 'V2Components'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

function ErrorModal({
  showModal,
  message,
  onClose,
  onViewDetailsClick = null,
  title,
  hideViewDetails = false
}) {
  return (
    <FullScreenModal showModal={showModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='!text-lg'>
              {title}
            </Typography>
            <div className='flex gap-4'>
              {!hideViewDetails && (
                <Button onClick={onViewDetailsClick} variant='grayBlue'>
                  View Details
                </Button>
              )}
              <Button onClick={onClose} variant='gray' icon={faClose} iconPosition='end'>
                Close
              </Button>
            </div>
          </div>
          <div className='bg-red-500 p-4 mt-8 rounded text-white max-h-[60vh] overflow-auto'>
            <code>{typeof message === 'object' ? JSON.stringify(message) : message}</code>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { ErrorModal }
