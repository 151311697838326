import { createRsrcKey } from 'infra/redux/reducers'
import store from 'infra/redux/store'

const { ApiProvider } = require('Core')

export const bookmarksAPIFn = () => {
  const API = new ApiProvider('bookmarks')

  return {
    async create(Name, userRefID) {
      try {
        const OwnerRef = {
          ObjectRef: [
            {
              RefKind: 'User',
              RefID: userRefID
            }
          ]
        }
        const dataObject = {
          ObjectMeta: {
            Name: `${Name}+${userRefID}`,
            RdOwners: OwnerRef,
            WrOwners: OwnerRef
          },
          Refs: {
            ObjectRef: []
          }
        }
        const response = await API.setInstance(dataObject).post()
        return [response.data, null]
      } catch (error) {
        return [null, error]
      }
    },
    async getAll() {
      try {
        const user = store.getState().user
        const response = await API.setInstance({
          ObjectMeta: { Name: `${user.ObjectMeta.Name}+${user.ObjectMeta.ID}` }
        }).get()
        return [response.data, null]
      } catch (error) {
        return [null, error]
      }
    },
    async updateRefs(refs = []) {
      try {
        const bookmarksObj = store.getState().bookmarks?.data
        const newObj = structuredClone(bookmarksObj)
        newObj.Refs.ObjectRef = refs
        const response = await API.setInstance(newObj).put()
        return [response.data, null]
      } catch (error) {
        return [null, error]
      }
    }
  }
}
