const IAMROLE_ICON_TYPE_MAP = {
  AWS: 'APPROLE_AWS',
  AWS_CUSTOM: 'APPROLE_AWS_CUSTOM',
  GCP: 'APPROLE_GCP',
  GCP_CUSTOM: 'APPROLE_GCP_CUSTOM',
  AZURE: 'APPROLE_AZURE',
  AZURE_CUSTOM: 'APPROLE_AZURE_CUSTOM',
  DEFAULT: 'APPROLE'
}

export const getIAMRoleTargetIconType = (iam) => {
  const { CloudType } = iam.Spec
  const isCustom = isCustomIAMRole(iam)
  // Icon type string
  const iconTypeString = isCustom ? `${CloudType}_CUSTOM` : CloudType
  return IAMROLE_ICON_TYPE_MAP[iconTypeString] || IAMROLE_ICON_TYPE_MAP.DEFAULT
}

export const isCustomIAMRole = (role) => role && role.Spec.State !== 'managed'