import { Box, Chip, makeStyles, Typography } from '@material-ui/core'
import { Person, Warning } from '@material-ui/icons'
import IconChipCount from 'Components/IconChipCount'
import Popup from 'Components/Popup'
import React from 'react'
import InfoTable from './Components/InfoTable'
const useStyle = makeStyles(theme => ({
  title: {
    color: theme.palette.grey[300]
  },
  chip: {
    // FIXME put color in theme
    background: '#fdeceb',
    paddingLeft: theme.spacing(0.2),
    height: theme.spacing(1.75),
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.error.dark,
      fontSize: theme.spacing(1.25)
    }
  }

}))
const InfoSegment = ({ title, current, errorData }) => {
  const classes = useStyle()
  return (
    <Box ml={1} height='100%' display='flex' flexDirection='column' mt={0.813}>
      <Typography variant='body2' color='textSecondary'> {title} </Typography>
      <Box mt={0.625}>

        {errorData &&
          <Popup
            TriggerComponent={<Chip size='medium' className={classes.chip} label={errorData.length} icon={<Warning />} />}
            PopupComponent={<InfoTable tableData={errorData} />}
          />}
      </Box>
      <Box mt={1}>
        <IconChipCount mt={0} Icon={({ className }) => <Person className={className} />} current={current} />
      </Box>
    </Box>
  )
}

export default InfoSegment
