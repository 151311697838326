import React from 'react'
import { Slack } from './Slack'
import { Jira } from './Jira'
import { ServiceNow } from './ServiceNow'
import { MsTeams } from './MsTeams'

const InfoModal = ({
  selectedType,
  showInfoModal,
  setShowInfoModal,
  handleIntegrationModalClick
}) => {
  switch (selectedType) {
    case 'slack':
      return (
        <Slack
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          handleIntegrationModalClick={handleIntegrationModalClick}
        />
      )
    case 'jira':
      return (
        <Jira
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          handleIntegrationModalClick={handleIntegrationModalClick}
        />
      )
    case 'servicenow':
      return (
        <ServiceNow
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          handleIntegrationModalClick={handleIntegrationModalClick}
        />
      )
    case 'msteam':
      return (
        <MsTeams
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          handleIntegrationModalClick={handleIntegrationModalClick}
        />
      )
    default:
      return null // or handle default case as needed
  }
}

export { InfoModal }
