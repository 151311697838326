import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'
import { Header } from './components/Header'
import { TabGroup } from 'procyon-ui'
import { OverviewTab } from './components/OverviewTab'
import { AccessGraphTab } from './components/AccessGraphTab'
import { AccessSummaryTab } from './components/AccessSummaryTab'

const WorkloadDetails = () => {
  const workload = useDataDetailsContext()

  return (
    <div>
      <Header workload={workload} />
      <div className='pt-4'>
        <TabGroup
          onChangeTab={function noRefCheck() {}}
          tabs={[
            {
              label: 'Overview',
              tabContent: <OverviewTab />
            },
            {
              label: 'Access Graph',
              tabContent: <AccessGraphTab />
            },
            {
              label: 'Access Summary',
              tabContent: <AccessSummaryTab />
            }
          ]}
        />
      </div>
    </div>
  )
}

const Wrapped = () => (
  <DataDetailsProvider
    routeKey='workloadName'
    sliceName='workloads'
    loadingMessage='Fetching Workload'
  >
    <WorkloadDetails />
  </DataDetailsProvider>
)

export { Wrapped as WorkloadDetails }
