import store from 'infra/redux/store'
import { _ } from 'Core'

function dump (string = '*') {
  const state = store.getState()
  if (string === '*') return state
  else {
    if (!_.get(state, string)) return `[DEBUG-ERROR]: Store not found: ${string}`
    return _.get(state, string)
  }
}

function dumpToClipboard (string = '*') {
  const state = store.getState()
  if (string === '*') {
    console.log('[graphData](hu):')
    navigator.clipboard.writeText('hu')
  } else {
    if (!_.get(state, string)) console.log(`[DEBUG-ERROR]: Store not found: ${string}`)
    navigator.clipboard.writeText(_.get(state, string))
  }
}

function initDump () {
  try {
    window.dump = dump
    window.dumpToClipboard = dumpToClipboard
  } catch (error) {}
}

export { initDump }
