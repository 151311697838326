import { Paginator, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { PaginationCountText } from './PaginationCountText'

/**
 *
 * @param {{ count: number, page?:number, onPageChange:((page) => void),onIndexChange:((data) => void), itemsPerPage?:number }} param0
 * @returns
 */
function Pagination({ count, page, onPageChange, onIndexChange, itemsPerPage = 20 }) {
  const itemsToShow = itemsPerPage

  const handlePageChange = (value) => {
    const start = (value - 1) * itemsToShow
    const end = value * itemsToShow
    onIndexChange && onIndexChange({ start, end })
    onPageChange && onPageChange(value)
  }

  /**
   * if count % items to show is zero checks if the total count is perfectly divisible without decimals
   * else add + 1 page to show
   */
  const lastPage = count % itemsToShow === 0 ? count / itemsToShow : Math.trunc(count / itemsToShow + 1)

  /**
   * Rest page to one, if page is greater than last page currently
   */
  if (page !== 1 && page > lastPage) {
    handlePageChange(1)
  }

  return (
    <div className='flex justify-between mt-4'>
      <PaginationCountText title='Resources' itemsToShow={itemsToShow} page={page} totalItems={count} />
      <Paginator boundaryCount={1} count={lastPage} onChange={(_, value) => handlePageChange(value)} page={page} />
    </div>
  )
}

export { Pagination }
