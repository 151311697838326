import { RelationAccessGraph } from 'V2Components'
import { useGroupDetailsContext } from 'features/groups'
import { Typography } from 'procyon-ui'
import React from 'react'
import { useAccessGraphTab } from './AccessGraphTab.utils'
import { getResourceName } from 'features/resources'

function AccessGraphTab() {
  const { nodes, relationObject, issuedPolicys } = useAccessGraphTab()
  const { group } = useGroupDetailsContext()

  if (!issuedPolicys.length)
    return (
      <Typography variant='body-regular'>
        No policys for  <strong className='ml-1'>{getResourceName(group)}</strong>
      </Typography>
    )

  return (
    <div>
      <RelationAccessGraph nodeObjects={nodes} relationObject={relationObject} />
    </div>
  )
}

export { AccessGraphTab }
