import { createRsrcKey, getResourceName, getRsrcAccountRef, getRsrcIcon } from 'features/resources'
import { GenericResourceRow, Label } from 'procyon-ui'
import React from 'react'

const Server = ({ rsrc, getObjectRef, rsrcAttributesSpec }) => {
  const Icon = getRsrcIcon(rsrc)
  const account = getObjectRef(getRsrcAccountRef(rsrc))

  const Principal = rsrcAttributesSpec.Server?.Principal

  return (
    <div className='w-[100%] mb-2' key={createRsrcKey(rsrc)}>
      <GenericResourceRow
        children2={<></>}
        caption={getResourceName(account)}
        accountName=''
        className='w-[100%]'
        children1={
          <div className='flex gap-2 w-[100%] justify-end items-center'>
            {Principal && <Label variant='grayBlue' text={`Principal: ${Principal}`} />}
            {!Principal && <Label variant='warning' text='No Principal' />}
          </div>
        }
        region=''
        resourceIcon={<Icon className='rounded' />}
        title={getResourceName(rsrc)}
      />
    </div>
  )
}

export { Server }
