import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0',
    left: '0'
  },
  loader: {
    width: '60px',
    height: '60px',
    zIndex: 9999
  }
}))
const ChartLoader = ({ loading, ...rest }) => {
  const classes = useStyle()
  const Loader = () =>
    <Box className={classes.root} {...rest}>
      <img className={classes.loader} title='' src='img/logo-loader.svg' />
    </Box>
  return (
    loading
      ? <Loader />
      : null
  )
}

export default ChartLoader
