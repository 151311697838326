import { faWarning } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { FullScreenModal } from 'V2Components'
import { createRef, getResourceName, useAccessCartProvider } from 'features/resources'
import { usePrivateKubeNamespaces } from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { Button, RadioInput, TargetIcon, Typography } from 'procyon-ui'
import React, { useRef, useState } from 'react'

const KubeClusterRoleSidebarPicker = ({ onCancel, kubeCluster: kube, onContinue }) => {
  const isSelectedAppRolesUpdated = useRef(false)

  const { getKubeNamespaceAppRolesMap } = usePrivateKubeNamespaces()
  // Array of selected Approle names from kubeCluster.Spec.IAMRoles.Map
  const [selectedRole, setSelectedRole] = useState('')
  const [k8CartIndex, setK8CartIndex] = useState(0)
  const { getObjectRef } = useObjectRef(['kubeClusters', 'kubeNamespaces'])
  const { getResourceFromCart, addItemsToCart, updateCartQueueItem, cartItems } =
    useAccessCartProvider()

  const k8Rrscs = cartItems.filter((e) => e?.Resource.RefKind === 'KubeNamespace' && e?.Principal)

  const getRsrc = () => {
    if (kube) return kube
    if (k8Rrscs.length >= 1) {
      const cartItem = k8Rrscs[k8CartIndex]
      return getObjectRef(cartItem.Resource)
    }
  }

  const rsrc = getRsrc()

  const resourceInQueue = getResourceFromCart(createRef(rsrc))

  const getKubeIAMRolesMap = () => {
    if (!rsrc) return []
    const isNameSpace = rsrc.ObjectMeta.Kind === 'KubeNamespace'
    const map = isNameSpace ? getKubeNamespaceAppRolesMap(rsrc) : kube.Spec.IAMRoles.Map
    return map
  }

  const kubeIAMRolesMap = getKubeIAMRolesMap()
  const iamRoleNames = Object.keys(getKubeIAMRolesMap()).filter(
    (name) => !(rsrc.ObjectMeta.Kind === 'KubeNamespace' && name === 'ProcyonKubectlClusterAdmin')
  )

  const handleAddToQueue = () => {
    // True, when the policy/req is for k8 rsrcs and not for Kubenamespace or Cluster itself
    let isK8RsrcsPolicy = true
    let roles = [
      {
        // GCP use Service account type
        RefKind: rsrc?.Spec.CloudType === 'AWS' ? 'AppRole' : 'ServiceAccount',
        RefID: kubeIAMRolesMap[selectedRole]
      }
    ]

    if (resourceInQueue) {
      updateCartQueueItem({
        resourceRef: createRef(rsrc),
        roles
      })
      if (k8CartIndex < k8Rrscs.length - 1) {
        setK8CartIndex((s) => s + 1)
        return
      }
    } else {
      isK8RsrcsPolicy = false
      addItemsToCart({
        resourceRef: createRef(rsrc),
        roles
      })
    }
    setSelectedRole('')
    onContinue?.(isK8RsrcsPolicy, { roles, k8Ref: createRef(rsrc) })
  }

  /**
   * Populate approle names `selectedAppRoles` if resource is in queue
   */
  if (rsrc && resourceInQueue && !isSelectedAppRolesUpdated.current) {
    // Get all approle ids which are in queue
    const approleRefIDs = resourceInQueue.Roles.ObjectRef?.map((e) => e.RefID) || []
    const appRoleNames = []
    approleRefIDs.forEach((id) => {
      // Get the approle name and id from the kubecluster
      for (const [name, approleID] of Object.entries(getKubeIAMRolesMap())) {
        // If the approle is selected in resource queue, push the approle name
        if (approleID === id) appRoleNames.push(name)
      }
    })
    // Update the approles with the ones in queue
    if (appRoleNames.length === 1) setSelectedRole(appRoleNames[0])
    isSelectedAppRolesUpdated.current = true
  }

  useHTMLBodyScrollbar(true)

  return (
    <FullScreenModal showModal={true}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-4 bg-white'>
          <div className=''>
            <span className='flex'>
              <TargetIcon type='KUBE_POD' className='mr-1' />
              <Typography variant='h4-regular'>
                Create policy for <strong>{getResourceName(rsrc)}</strong>
              </Typography>
            </span>
            <Typography className='mt-2' variant='caption-regular'>
              Select approle for policy.
            </Typography>
            <div className='mt-4'>
              {!iamRoleNames.length && (
                <div className='p-4 rounded bg-red-500 flex gap-4 items-center'>
                  <FontAwesomeIcon color='white' icon={faWarning} />
                  <Typography className='!text-white' variant='body-regular'>
                    AppRoles for this cluster was not found. Please refresh and try.{' '}
                  </Typography>
                </div>
              )}
              {iamRoleNames.map((roleName) => {
                return (
                  <div key={roleName} className='flex items-center'>
                    <RadioInput
                      id={roleName}
                      checked={roleName === selectedRole}
                      name={roleName}
                      onChange={() => setSelectedRole(roleName)}
                      value={roleName}
                      style={{
                        margin: '8px 0px'
                      }}
                    />
                    <label htmlFor={roleName}>{roleName}</label>
                  </div>
                )
              })}

              <div className='flex flex-row-reverse mt-10'>
                <Button onClick={handleAddToQueue} disabled={selectedRole == ''} variant='primary'>
                  {resourceInQueue ? 'Update AppRoles' : 'Add To Queue'}
                </Button>
                <Button onClick={onCancel} variant='gray' className='mr-5'>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { KubeClusterRoleSidebarPicker }
