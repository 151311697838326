import { createRef, createRsrcKey } from 'features/resources'
export const getFilterDropdownItems = (entities = []) => {
  const tempFlagObj = {}
  const filters = [
    {
      label: 'Show: All',
      value: ''
    }
  ]

  entities.forEach((e) => {
    const { Kind } = e.ObjectMeta
    if (!tempFlagObj[Kind]) {
      filters.push({
        label: `Show: ${Kind === 'GithubResource' ? 'Team' : Kind}s`,
        value: Kind
      })
      tempFlagObj[Kind] = true
    }
  })

  return filters
}

export const entityDataAdapters = {
  GCP(u) {
    return {
      caption: u.ObjectMeta.Name,
      name: u.Spec.Description,
      key: createRsrcKey(u),
      ref: createRef(u),
      refObject: u,
      type: 'GOOGLE_APPLICATION'
    }
  },
  AZURE(g) {
    return {
      caption: g.ObjectMeta.Name,
      name: g.Spec.Description,
      key: createRsrcKey(g),
      ref: createRef(g),
      refObject: g,
      type: 'AZURE_APPLICATION'
    }
  },
  AWS(s) {
    return {
      caption: s.ObjectMeta.Name,
      name: s.Spec.Description,
      key: createRsrcKey(s),
      ref: createRef(s),
      refObject: s,
      type: 'AWS_APPLICATION'
    }
  }
}

export const getIntegrationIcon = (kind) => {
  const icons = {
    SlackIntegration: 'SLACK',
    JiraIntegration: 'JIRA',
    ServiceNowIntegration: 'SERVICENOW',
    MsTeamsIntegration: 'TEAMS'
  }
  return icons[kind] || 'SLACK'
}
