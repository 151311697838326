import React, { useEffect, useRef } from 'react'
import { Card, InputAdornment, TextField, makeStyles } from '@material-ui/core'

import { Search } from '@material-ui/icons'
const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: props => props.height
  },
  rootWidth: props => ({
    width: `${props.dynamicWidth ? '100%' : '400px'}`
  }),
  icon: {
    width: '40px'
  },
  textField: {
    background: props => props.background,
    height: '100%',
    flexGrow: 1,
    '& > div': {
      '&::before': {
        borderBottom: 'none'
      },
      // '&::after': {
      //   borderBottom: 'none'
      // },
      height: '100%'
    }
  },
  textFieldInternal: {
    paddingLeft: theme.spacing(1 / 2)
  }
}))
const SearchField = ({
  elavation = 2,
  height = '48.1px',
  dynamicWidth = false,
  searchKey,
  background = 'white',
  setSearchKey,
  placeHolder = 'Search',
  showIcon = true
}) => {
  const classes = useStyle({ dynamicWidth, height, background })
  const inputRef = useRef(null)
  const keyListener = (e) => {
    const dontListenOn = ['INPUT', 'FORM', 'TEXTAREA']
    if (
      /** don't listen on key combinations */
      (!e.ctrlKey) &&
      /** pressed key is `/` */
      e.keyCode === 191 &&
      /** don't listen on form elements */
     (!dontListenOn.includes(e.target.tagName)) &&
      /** Only trigger if search is not already focued */
      inputRef.current !== document.activeElement
    ) {
      /** The `/` key for focus should not be appended to input */
      e.stopPropagation()
      e.preventDefault()
      /** Attempt to focus only if inputRef is connected */
      if (inputRef.current && typeof inputRef.current.focus === 'function') {
        inputRef.current.focus()
      }
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', keyListener)
    return () => document.removeEventListener('keydown', keyListener)
  }, [])
  return (
    <Card className={`${classes.root} ${classes.rootWidth}`} elevation={elavation}>
      <TextField
        inputRef={inputRef}
        onChange={(e) => setSearchKey(e.target.value)}
        value={searchKey}
        role='search'
        InputProps={{
          classes: { root: classes.textFieldInternal },
          startAdornment: (
            showIcon &&
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
          )
        }}
        placeholder={placeHolder ? placeHolder + ', press / to search' : 'press / to search'}
        className={classes.textField}
      />
    </Card>
  )
}

export default SearchField
