import ThemeDropDown from 'Components/ThemeDropDown'
import React from 'react'

const NewThemeSingleSelectFilter = ({ defaultValue, filterOptions = [], onChange }) => {
  return (
    <ThemeDropDown
      height={2.5}
      hidelabel
      labelExtractor={option => option.label}
      valueExtractor={option => option.value}
      labelMarginBottom={0}
      options={filterOptions}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
    />

  )
}

export default NewThemeSingleSelectFilter
