import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { GraphResourceNodeActionsFunctionMap, RelationAccessGraph } from 'V2Components'
import { useAppDetailsContext, useApplicationAccess } from 'features/applications'
import { getPolicyIssuedToEntities, isPolicyIssuedToUser } from 'features/policy'
import { createRsrcKey } from 'features/resources'
import _ from 'lodash'
import { Typography } from 'procyon-ui'
import React from 'react'

function AccessGraphTab() {
  const { app } = useAppDetailsContext()
  const { getApplicationPolicysRef } = useApplicationAccess()

  const { getObjectRef } = useMultiSlice(['policyList', 'userList', 'groupList', 'serviceAccounts', 'githubResources'])

  const policyReducer = (prev, p) => [...prev, ...getPolicyIssuedToEntities(p)]
  const policys = getObjectRef(getApplicationPolicysRef(app))
  // Unique entities
  const entities = getObjectRef(_.uniqBy(policys.reduce(policyReducer, []), createRsrcKey))

  const getGraphRelationsObject = () => {
    // Relation from entities to policy
    const obj = {}
    entities.forEach((e) => {
      policys.forEach((p) => {
        if (isPolicyIssuedToUser(p, e)) {
          const entittyKey = createRsrcKey(e)
          const relationsArr = obj[entittyKey] || []
          relationsArr.push(createRsrcKey(p))
          obj[entittyKey] = relationsArr
        }
      })
    })

    // Relation from policies to resources
    policys.forEach((p) => {
      const entittyKey = createRsrcKey(p)
      const relationsArr = obj[entittyKey] || []
      relationsArr.push(createRsrcKey(app))
      obj[entittyKey] = relationsArr
    })
    return obj
  }

  const getGraphAppsAction = () => {
    const actions = GraphResourceNodeActionsFunctionMap.Application({ rsrc: app })
    return { [createRsrcKey(app)]: actions }
  }

  /**
   * Note: We are not showing the graph when there are no policy attached, this automatically handles the following case:
   * Hiding the sign in action from app for non-admin users who dont have access to the app.
   */

  if (!policys.length)
    return (
      <Typography className='!text-[#545B71]' variant='body-regular'>
        The resource is not accessed by anyone.
      </Typography>
    )

  return (
    <div>
      <RelationAccessGraph
        actionsObj={getGraphAppsAction()}
        relationObject={getGraphRelationsObject()}
        nodeObjects={[entities, policys, [app]]}
      />
    </div>
  )
}

export { AccessGraphTab }
