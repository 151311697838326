import { ApiProvider } from 'Core'

export const createOrganization = async (obj) => {
  const response = await new ApiProvider('orgs').setInstance(obj).post()
  return response.data
}

export const deleteOrganization = async (org) => {
  await new ApiProvider('orgs').setInstance(org).delete()
  return org
}

export const getAPIKeys = async (tenant) => {
  const { data } = await new ApiProvider('tenantapikeys')
    .setInstance({ ObjectMeta: { Name: tenant } })
    .get()
  return data
}

export const createApiKeys = async (tenant) => {
  const response = await new ApiProvider('tenantapikeys')
    .setInstance({ ObjectMeta: { Name: tenant } })
    .post()
  return response.data
}

export const deleteApiKeys = async (tenant) => {
  const response = await new ApiProvider('tenantapikeys')
    .setInstance({ ObjectMeta: { Name: tenant } })
    .delete()
  return response.data
}

export const createTagPolicy = async (data) => {
  const resp = await new ApiProvider('tagpolicys').setInstance(data).post()
  return resp.data
}

export const createApproverPolicy = async (data) => {
  const resp = await new ApiProvider('approvers').setInstance(data).post()
  return resp.data
}

export const deleteApproverPolicy = async (data) => {
  const resp = await new ApiProvider('approvers').setInstance(data).delete()
  return resp.data
}

export const deleteTagPolicy = async (tag) => {
  await new ApiProvider('tagpolicys').setInstance(tag).delete()
  return tag
}

export const updateTenantProfile = async (tp) => {
  const response = await new ApiProvider('tenantprofiles').setInstance(tp).put()
  return response.data
}

export const createReadPolicy = async (data) => {
  const resp = await new ApiProvider('visibilitys').setInstance(data).post()
  return resp.data
}

export const deleteReadPolicy = async (data) => {
  const resp = await new ApiProvider('visibilitys').setInstance(data).delete()
  return resp.data
}
