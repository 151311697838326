import { makeStyles, RadioGroup } from '@material-ui/core'
import React from 'react'
import SelectorItem from './SelectorItem'
const useStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: props => props.fullwidth ? 1 : 'auto',
    justifyContent: 'space-between'
  }
}))
const DatasetSelector = ({
  dataSetKeys,
  selectedDataSet,
  fullwidth = false,
  handleDataSetChange
}) => {
  const classes = useStyle({ fullwidth })
  const handleRadioChange = (e) => {
    console.log('handleDataSetChange', e.target.value)
    handleDataSetChange(e.target.value)
  }
  return (
    <RadioGroup
      className={classes.root}
      value={selectedDataSet}
      onChange={handleRadioChange}
    >
      {dataSetKeys.map(dataset => <SelectorItem key={dataset} value={dataset} />)}
    </RadioGroup>
  )
}

export default DatasetSelector
