import { MapKindToSliceName } from 'infra/redux/utils/constants'
import { serviceAccountMiddlewares } from './functions/serviceAccount'
import { approvalReqMiddlewares } from './functions/approvalReq'

/**
 *
 * @param {{
 * sliceName: import("types").SliceNames,
 * data: any
 * }} param0
 * @returns
 */
export const applyDataMiddleWares = ({ sliceName, data }) => {
  const middleWareFuncs = MIDDLEWARES[sliceName] || []
  return middleWareFuncs.reduce((prev, midFunc) => {
    return midFunc(prev)
  }, data)
}

/**
 *
 */
const MIDDLEWARES = {
  [MapKindToSliceName.ServiceAccount]: serviceAccountMiddlewares,
  [MapKindToSliceName.ApprovalReq]: approvalReqMiddlewares
}
