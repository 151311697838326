import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { SearchInput } from 'V2Components'
import { useCloudTypeRouteProvider, useCloudTypes } from 'features/clouds'
import { Button, ToggleCloudControl } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

function Header({ searchKey, setSearchKey }) {
  const history = useHistory()
  const { cloudType } = useCloudTypeRouteProvider()
  const { cloudTypes } = useCloudTypes()
  const onCloudTypeChange = (v) => history.push(`/admin/clouds/${v.toLowerCase()}`)

  return (
    <div className='flex justify-between mb-4'>
      <ToggleCloudControl
        ariaLabel='cloud-select-control'
        multiSelect={false}
        onChange={(_, v) => v && onCloudTypeChange(v)}
        options={cloudTypes}
        value={cloudType}
      />
      <div className='flex gap-4'>
        <SearchInput sx={{ width: '286px' }} searchKey={searchKey} onChange={setSearchKey} />
        {/* <Button
          onClick={() => history.push(`/admin/clouds/${cloudType.toLowerCase()}/create`)}
          size='md'
          icon={faPlus}
          variant='primary'
        >
          Add Account
        </Button> */}
        <Button
          onClick={() => history.push(`/admin/clouds/${cloudType.toLowerCase()}/wizard`)}
          size='md'
          icon={faPlus}
          variant='primary'
        >
          Add Account Wizard
        </Button>
      </div>
    </div>
  )
}

export { Header }
