import React, { useEffect, useRef, useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import _ from 'lodash'
import {
  Button,
  IconButton,
  Label,
  LabelCheckbox,
  TextInput,
  ToggleCloudControl,
  Typography
} from 'procyon-ui'
import { createDataSelectorHook } from 'infra/redux'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { reduxApiClient } from 'infra'
import {
  AppAccountID,
  AppNameKey,
  AppPrivateKey,
  AppSAML,
  AppSignOnType,
  DatabricksClientSecret,
  DataBrickClientID,
  DatabricksSSOUrl,
  DatabricksAccountID,
  DatabricksSAML,
  DatabricksCloudType
} from 'features/applications/utils'
import { getSAMLServers } from 'Utils/SAMLServersAPI'
import { faCopy, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useUser } from 'Core/Hooks/useUser'

const useSlices = createDataSelectorHook(['applicationList', 'tenantprofiles'])

function DataBricksApplicationForm({ onCancel, onComplete }) {
  const { isEditMode, app } = useApplicationEditContext()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUser()
  const [accountId, setAccountId] = useState(_.get(app, DatabricksAccountID, ''))
  const [applicationName, setApplicationName] = useState(_.get(app, AppNameKey, ''))
  const [isChecked, setIsChecked] = useState(_.get(app, DatabricksSAML, false))
  const [enableSSO, setEnableSSO] = useState(_.get(app, DatabricksSAML, false))
  const [signOnType, setSignOnType] = useState(_.get(app, AppSignOnType, ''))
  const [clientID, setClientID] = useState(_.get(app, DataBrickClientID, ''))
  const [clientSecret, setClientSecret] = useState(_.get(app, DatabricksClientSecret, ''))
  const [activeCloudType, setActiveCloudType] = useState(_.get(app, DatabricksCloudType, ''))
  const [ssoUrl, setSSOUrl] = useState(_.get(app, DatabricksSSOUrl, ''))
  const [showPassword, setShowPassword] = useState(false)
  const [samlEndpoints, setSamlEndpoints] = useState({
    ProcyonSSOUrl: '',
    Certificate: '',
    MetaData: ''
  })

  const { slices } = useSlices()
  const profile = slices.tenantprofiles.find((e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant)
  const isInterOpModeEnabled = profile?.Spec.TunnelConfig.NoDnsProxyMode

  const handleCloudToggle = (v) => setActiveCloudType(v)
  useEffect(() => {
    populateSamlEndpoints()
    setEnableSSO(isChecked ? true : false)
    setSignOnType(isChecked ? 'saml' : '')
  }, [isChecked])

  const populateSamlEndpoints = async () => {
    const [samlServer] = await getSAMLServers()
    if (!samlServer) return
    const Issuer = samlServer.Issuer
    const Certificate = samlServer.IssuerCertificate
    const ProcyonSSOUrl = `https://${Issuer}${isInterOpModeEnabled ? ':8643' : ''}/sso`
    const MetaData = `https://${Issuer}${isInterOpModeEnabled ? ':8643' : ''}/metadata`

    console.log(ProcyonSSOUrl, MetaData)
    setSamlEndpoints({ ProcyonSSOUrl, Certificate, MetaData })
  }

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: applicationName } })) {
      return 'Application name is already taken!'
    }
    if (applicationName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!accountId) return 'Account ID must not be empty.'
    if (!clientID) return 'Client ID must not be empty.'
    if (!clientSecret) return 'Client Secret must not be empty'
    if (isChecked && !ssoUrl) return 'SSO Url mut not be empty'
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const data = {
        applicationName,
        accountId,
        enableSSO,
        signOnType,
        activeCloudType,
        clientSecret,
        ssoUrl
      }
      if (isEditMode) {
        const app = await handleSFApplicationUpdate(data)
        updateSliceData(app)
      } else {
        const app = await handleSFApplicationCreate(data)
        updateSliceData(app)
      }
      reduxApiClient('applications').getAll({})
      enqueueNotification(
        `Successfully ${isEditMode ? 'Updated' : 'Created'} Databricks Application!`,
        'info'
      )
      onComplete?.()
    } catch (error) {
      enqueueNotification(
        `Failed to ${isEditMode ? 'Update' : 'Create'} Databricks Application!`,
        'error'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleSFApplicationCreate = async ({ applicationName, accountId }) => {
    const data = {
      ObjectMeta: {
        Name: applicationName
      },
      Spec: {
        AppType: 'databricks',
        DatabricksAppConfig: {
          AccountID: accountId,
          CloudType: activeCloudType,
          ClientID: clientID,
          ClientSecret: clientSecret,
          EnableSSO: isChecked,
          SSOUrl: isChecked ? ssoUrl : ''
        },
        SignOnType: isChecked ? 'saml' : '',
        EntityID: isChecked ? samlEndpoints.MetaData : '',
        Certificate: isChecked ? samlEndpoints.Certificate : ''
      }
    }
    return await reduxApiClient('applications').create(data)
  }

  const handleSFApplicationUpdate = async (data) => {
    const payload = structuredClone(app)
    _.set(payload, DatabricksAccountID, data.accountId)
    _.set(payload, DataBrickClientID, data.clientID)
    _.set(payload, DatabricksClientSecret, data.clientSecret)
    _.set(payload, DatabricksSAML, data.enableSSO)
    _.set(payload, DatabricksCloudType, data.activeCloudType)
    return await reduxApiClient('applications').update(payload)
  }

  const handleCopy = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        enqueueNotification('Successfully Copied!', 'info')
      })
      .catch((err) => {
        enqueueNotification('Failed to copy: ', 'error')
      })
  }

  const formErrors = getErrors()
  return (
    <div>
      <div className='grid gap-2'>
        <TextInput
          placeholder='Application Name'
          disabled={isEditMode}
          value={applicationName}
          onChange={(e) => setApplicationName(e.target.value)}
          sx={{ width: '100%' }}
          label='Application Name *'
        />

        <TextInput
          value={accountId}
          placeholder='Account ID'
          onChange={(e) => setAccountId(e.target.value)}
          sx={{ width: '100%' }}
          label='Account ID*'
        />

        <TextInput
          value={clientID}
          placeholder='Client ID'
          onChange={(e) => setClientID(e.target.value)}
          sx={{ width: '100%' }}
          label='Client ID *'
        />

        <div className='relative'>
          <TextInput
            value={clientSecret}
            placeholder='Client Secret Key'
            onChange={(e) => setClientSecret(e.target.value)}
            sx={{ width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            label='Client Secret *'
          />
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            variant='clear'
            icon={showPassword ? faEye : faEyeSlash}
            style={{
              marginRight: '10px',
              position: 'absolute',
              top: '26px',
              right: '-9px'
            }}
          />
        </div>

        <div>
          <Typography variant='body-regular'>Select Cloud Type</Typography>
          <ToggleCloudControl
            ariaLabel='cloud-select'
            onChange={(_, v) => handleCloudToggle(v)}
            options={['AWS', 'GCP', 'AZURE']}
            value={activeCloudType}
          />
        </div>

        <div>
          <LabelCheckbox
            checked={isChecked}
            name='Enable SSO'
            onClick={() => setIsChecked(!isChecked)}
            size='medium'
          />

          {isChecked && (
            <div className='grid gap-2'>
              <TextInput
                value={ssoUrl}
                placeholder='SSO Url'
                onChange={(e) => setSSOUrl(e.target.value)}
                sx={{ width: '100%' }}
                label='SSO Url *'
              />

              <div>
                <Typography variant='body-semiBold'>
                  Copy the below informations for SSO configuration
                </Typography>
              </div>
              <TextInput
                value={samlEndpoints.ProcyonSSOUrl}
                disabled
                icon={faCopy}
                iconPosition='end'
                onClickEndIcon={(e) => handleCopy(samlEndpoints.ProcyonSSOUrl)}
                placeholder='Procyon SSO Url'
                onChange={() => {}}
                sx={{ width: '100%' }}
                label='Procyon SSO Url '
              />
              <TextInput
                value={samlEndpoints.MetaData}
                disabled
                icon={faCopy}
                iconPosition='end'
                onClickEndIcon={(e) => handleCopy(samlEndpoints.MetaData)}
                placeholder='Procyon Entity ID'
                onChange={() => {}}
                sx={{ width: '100%' }}
                label='Procyon Entity ID '
              />
              <TextInput
                icon={faCopy}
                iconPosition='end'
                onClickEndIcon={(e) => handleCopy(samlEndpoints.Certificate)}
                value={samlEndpoints.Certificate}
                placeholder='x590 Certificate'
                onChange={() => {}}
                disabled
                sx={{ width: '100%' }}
                label='Certificate '
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback
        caption='Please wait..'
        loading={isLoading}
        message='Creating SnowFlake Application'
      />
    </div>
  )
}

export { DataBricksApplicationForm }
