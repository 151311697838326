import { ApiProvider } from 'Core'

export const createApplication = async (data) => {
  const response = await new ApiProvider('applications').setInstance(data).post()
  return response.data
}

export const updateApplication = async (data) => {
  const response = await new ApiProvider('applications').setInstance(data).put()
  return response.data
}

export const deleteApplication = async (app) => {
  await new ApiProvider('applications').setInstance(app).delete()
  return app
}