import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import CONSTANTS from 'Utils/Constants'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { IAMResourcesSliceNames } from 'features/iamResources/utils'
import { createPacPolicy } from 'features/policy'
import { convertTZ, createRsrcKey } from 'features/resources'
import React, { useCallback } from 'react'
import { IAMResourcesManager } from '../IAMResourcesManager'
import { reduxApiClient } from 'infra'
import _ from 'lodash'
import moment from 'moment'

/**
 *
 * @param {{ views, selectedRsrc, setSelectedRsrc, setViews, serviceAccountType: string, enableUserGroupSelection: boolean }} param0
 * @returns
 */
function CreatePolicyFlow({
  selectedRsrc,
  setSelectedRsrc,
  serviceAccountType,
  views,
  setViews,
  enableUserGroupSelection = true
}) {
  const { slices } = useMultiSlice([
    'userList',
    'groupList',
    'serviceAccounts',
    ...IAMResourcesSliceNames
  ])

  const handleRequestSubmit = useCallback(async (data) => {
    const policy = await handlePolicyCreation({ ...data })
    pushToSlice(policy)
    reduxApiClient('pacpolicys').startRsrcObjectPolling(policy, (obj) => {
      const assumeRole = _.get(obj, 'Spec.ActionMap.AssumeRole')
      return !!assumeRole
    })
  }, [])

  const serviceAccountFilter = (serviceAccount) => {
    const loading =
      !serviceAccount?.Status?.GcpStatus?.Email &&
      !serviceAccount?.Status?.AzureStatus?.DisplayName &&
      !serviceAccount?.Status?.AwsStatus?.UserName
    const hasError = serviceAccount?.Status?.Status?.Status === '0'
    return !loading && !hasError
  }

  const getEntities = () => {
    const list = [...slices.userList, ...slices.groupList]
    list.push(
      ...slices.serviceAccounts.filter(
        (e) => e.Spec.Type === serviceAccountType && serviceAccountFilter(e)
      )
    )
    return list
  }

  return (
    <IAMResourcesManager
      entities={getEntities()}
      setSelectedRsrc={setSelectedRsrc}
      submissionTitle='Policy'
      enableUserGroupSelection={enableUserGroupSelection}
      views={views}
      onViewsChange={setViews}
      selectedRsrc={selectedRsrc}
      onRequestSubmission={handleRequestSubmit}
    />
  )
}

async function handlePolicyCreation(data = {}) {
  const { spec, resources, entities, resourcesMetaObj } = data
  const actionMap = {}
  const reqObject = {
    ObjectMeta: {
      Name: getFriendlyName(),
      RdOwners: {
        ObjectRef: entities
      }
    },
    IssuedTo: {
      ObjectRef: entities
    },
    Spec: {
      Description: spec.comments,
      ActionMap: {}
    },
    NotBefore: moment(convertTZ(spec.startDate, spec.timeZone)).utc(),
    NotAfter: moment(convertTZ(spec.endDate, spec.timeZone)).utc(),
    GivenName: spec.name,
    Type: CONSTANTS.policyTypes.IAMAction,
    Creator: ''
  }

  /** Create the ActionMap object */
  resources.forEach((rsrc) => {
    const resourceObj = getObjectRefFlat(rsrc)
    const Roles = resourcesMetaObj[createRsrcKey(rsrc)]?.Roles || []
    // ActionMap key will be AwsIAM or GcpIAM for AwsResource or GcpResource
    const KubeNampeSpaceActionMap = {
      AWS: 'AwsIAM',
      GCP: 'GcpIAM',
      AZURE: 'AzureIAM'
    }
    const resourceTypeToIAMMap = {
      AwsResource: 'AwsIAM',
      GcpResource: 'GcpIAM',
      AzureResource: 'AzureIAM',
      KubeNamespace: KubeNampeSpaceActionMap[resourceObj?.Spec?.Type]
    }
    const resource = rsrc
    const actionMapKey = resourceTypeToIAMMap[resource.RefKind]
    if (!actionMap[actionMapKey]) {
      actionMap[actionMapKey] = {
        PolicyRule: []
      }
    }
    Roles.forEach((r) => {
      actionMap[actionMapKey].PolicyRule.push({
        ObjectRef: {
          RefID: r.RefID,
          RefKind: 'IamAction'
        },
        Principal: '',
        GcpRoleTarget: null,
        Services: {
          ObjectRef: [
            {
              RefKind: resource.RefKind,
              RefID: resource.RefID
            }
          ]
        }
      })
    })
  })
  reqObject.Spec.ActionMap = actionMap
  const policy = await createPacPolicy(reqObject)
  enqueueNotification('Policy has been successfully created!', 'info')
  return policy
}

export { CreatePolicyFlow }
