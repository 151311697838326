import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { IAMRsrcsTreeView } from 'features/iamResources'
import { createRsrcKey, getResourceName, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { ActionBar } from 'procyon-ui'
import React from 'react'

const GCPAccountsSelector = ({
  onAdd,
  onRemove,
  selectedRsrcs = [],
  rsrcs,
  showOnlySelected = false,
  getVisibleNodesFn = null
}) => {
  const { getObjectRef, slices } = useMultiSlice(['accountList', 'gcpResources'])

  const getVisibleNodesMap = () => {
    if (!getVisibleNodesFn) return {}
    const list = getVisibleNodesFn([...rsrcs, ...slices.accountList.filter(e => e.Spec.Type === 'GCP')])
    return list.reduce((prev, rsrc) => {
      prev[createRsrcKey(rsrc)] = true
      return prev
    }, {})
  }

  const accountsMap = selectedRsrcs.reduce((prev, rsrcKey) => {
    if (rsrcKey.includes('Account')) {
      prev[rsrcKey] = true
      return prev
    }
    const rsrc = getObjectRef(reverseRsrcKey(rsrcKey))
    const accountKey = createRsrcKey(rsrc.Spec.Account)
    prev[accountKey] = true
    return prev
  }, {})

  const visibleNodeMap = getVisibleNodesMap()

  const excludedAccountMap = slices.accountList.reduce((prev, account) => {
    const accountKey = createRsrcKey(account)
    if (!accountsMap[accountKey]) prev[accountKey] = true
    return prev
  }, {})

  const GcpRsrcActionBar = ({ rsrc }) => {
    const Icon = getRsrcIcon(rsrc)
    const displayType = rsrc.Spec.DisplayType
    return (
      <ActionBar
        showActionButton={displayType === 'Project'}
        leftIcon={<Icon />}
        name={getResourceName(rsrc)}
        onClickAddButton={() => onAdd(createRsrcKey(rsrc))}
        assigned={selectedRsrcs.includes(createRsrcKey(rsrc))}
        onClickRemoveButton={() => onRemove(createRsrcKey(rsrc))}
      />
    )
  }

  const renderSelected = (nodeKey, children) => {
    const rsrc = getObjectRef(reverseRsrcKey(nodeKey))
    if (!rsrc) return null

    const isSelected = selectedRsrcs.includes(nodeKey)

    const isVisible = visibleNodeMap[nodeKey]

    if (children.length) {
      return (
        <div className={isSelected ? 'ml-2' : ''} key={createRsrcKey(rsrc)}>
          {rsrc.Spec.DisplayType !== 'Organization' && isSelected && isVisible && (
            <GcpRsrcActionBar rsrc={rsrc} />
          )}
          {children}
        </div>
      )
    }

    if (!isVisible) return null

    if (isSelected)
      return (
        <div className='ml-2'>
          <GcpRsrcActionBar rsrc={rsrc} />
        </div>
      )
    else return null
  }

  const renderUnselected = (nodeKey, children) => {
    const rsrc = getObjectRef(reverseRsrcKey(nodeKey))

    if (!rsrc) return null
    const isVisible = visibleNodeMap[nodeKey]

    if (children.length) {
      return (
        <div className='ml-2' key={createRsrcKey(rsrc)}>
          {rsrc.Spec.DisplayType !== 'Organization' && isVisible && (
            <GcpRsrcActionBar rsrc={rsrc} />
          )}
          {children}
        </div>
      )
    }

    if (!isVisible) return null

    return (
      <div className='ml-2'>
        <GcpRsrcActionBar rsrc={rsrc} />
      </div>
    )
  }

  return (
    <div>
      <IAMRsrcsTreeView
        rsrcs={rsrcs}
        renderNodeFn={showOnlySelected ? renderSelected : renderUnselected}
        excludedAccountKeys={showOnlySelected ? Object.keys(excludedAccountMap) : []}
      />
    </div>
  )
}

export { GCPAccountsSelector }
