import React from 'react'
import { Route, Switch } from 'react-router'
import { Clouds } from '../views'
import { AccountDetails } from '../views/AccountDetails'
import { AddAccountWizard } from '../views/AddAccountWizard'

const CloudsRoutes = () => {
  return (
    <>
      <Route
        exact
        path={[
          '/admin/clouds',
          '/admin/clouds/:cloudType',
          '/admin/clouds/:cloudType/:action',
          '/admin/clouds/:cloudType/:action/:tabName'
        ]}
      >
        <Switch>
          <Route
            exact
            path={['/admin/clouds', '/admin/clouds/:cloudType/create','/admin/clouds/:cloudType/wizard', '/admin/clouds/:cloudType']}
          >
            <Clouds />
            <Route exact path={'/admin/clouds/:cloudType/wizard'}>
              <AddAccountWizard />
            </Route>
          </Route>
          <Route
            exact
            path={[
              '/admin/clouds/:cloudType/:cloudName',
              '/admin/clouds/:cloudType/:cloudName/:tabName'
            ]}
          >
            <AccountDetails />
          </Route>
        </Switch>
      </Route>
    </>
  )
}

export { CloudsRoutes }
