import { useUser } from 'Core/Hooks/useUser'
import { BulkDeleteSelectionModal, FloatingActions } from 'V2Components'
import { useApplicationAccess } from 'features/applications'
import { IAMResourcesSliceNames, useIAMRsrcAccess } from 'features/iamResources'
import { createRef, createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames, useTargetAccess } from 'features/targets'
import {
  BulkAddToGroup,
  DeleteUserModal,
  getUserDisplayName,
  getUserGroupsRefs,
  getUserOwnedDevices,
  getUserSource,
  getUserStatus,
  isAdminUser
} from 'features/users'
import { ResponsiveTable } from 'infra/ui'
import { createDataSelectorHook, useObjectRef, useReduxFetch } from 'infra/redux'
import _ from 'lodash'
import moment from 'moment'
import { SimpleDropDown, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Header } from './components/Header'
import { UserDetailsModal } from './components/UserDetailsModal'
import { responsiveColumns } from './utils'
const useSlices = createDataSelectorHook(['userList', 'groupList', 'deviceList'])

function Users() {
  const [searchKey, setSearchKey] = useState('')
  const [sortFilter, setSortFilter] = useState('name')
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedRowIDs, setSelectedRowIDs] = useState([])
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false)
  const [showBulkDeleteUserModal, setShowBulkDeleteUserModal] = useState(false)
  const [showBulkAddToGroupModal, setShowBulkAddToGroupModal] = useState(false)
  const [activeUserDetailsTab, setActiveUserDetailsTab] = useState('GROUPS') //GROUPS || DEVICES || RSRCS

  const history = useHistory()
  const { user } = useUser()

  const { slices, sliceNames } = useSlices()

  const { getObjectRef } = useObjectRef(sliceNames)

  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const { getUserAccessTargetsMap } = useTargetAccess()
  const { getUserApplicationAccessMap } = useApplicationAccess()

  const handleUserDetailsGoToClick = (name) => {
    history.push(`/admin/users/${name}`)
  }

  const handleAddUsersClick = () => {
    history.push('/admin/users/create')
  }

  const checkIsAdmin = (u) => {
    const adminGroupRef = createRef(_.find(slices.groupList, { ObjectMeta: { Name: 'admin' } }))
    return isAdminUser(u, adminGroupRef)
  }

  /**
   * @param {any} user
   * @param {'GROUPS'|'DEVICES'|'RSRCS'|'APPS'|'TARGETS'} tab
   */
  const handleUserDetailsClick = (user, tab) => {
    setSelectedUser(user)
    setShowUserDetailsModal(true)
    setActiveUserDetailsTab(tab)
  }

  const handleUserDetailsClose = () => {
    setShowUserDetailsModal(false)
    setSelectedUser(null)
  }

  const handleUserDeleteClick = (u) => {
    setSelectedUser(u)
    setShowDeleteUserModal(true)
  }

  const handleViewProfileClick = (u) => history.push(`/admin/users/${u.ObjectMeta.Name}`)

  const getUserDeviceCount = (u) => getUserOwnedDevices(slices.deviceList, u).length

  const getUserRsrcsCount = (u) => {
    const appsCount = Object.keys(getUserApplicationAccessMap(u)).length
    const targetsCount = Object.keys(getUserAccessTargetsMap(u)).length
    const rsrcsCount = Object.keys(getPolicySignInURLS(u)).length
    return {
      appsCount,
      rsrcsCount,
      targetsCount,
      total: appsCount + rsrcsCount + targetsCount
    }
  }

  const getFilteredUsersList = () => {
    const sortedItems = _.sortBy(slices.userList, (u) =>
      SortByFilterMap[sortFilter](u).toLowerCase()
    )
    return sortedItems.filter((e) => {
      if (searchKey) {
        const searchString = `${e.ObjectMeta.Name}${e.Spec.EmailID} ${
          e.Spec.FullName
        } ${getUserSource(e)} ${getUserStatus(e)}`
        return searchString.toLowerCase().includes(searchKey)
      }
      return true
    })
  }

  const tableRowAdapterFn = (u) => {
    return {
      key: createRsrcKey(u),
      objectName: u.ObjectMeta.Name,
      name: {
        // For SCIM users, naming fields might not be there and if it's not there we will show `Empty` label
        name: getUserSource(u) === 'SCIM' ? getUserDisplayName(u, false) : getResourceName(u),
        isLoggedIn: createRsrcKey(user) === createRsrcKey(u)
      },
      email: u.Spec.EmailID || u.ObjectMeta.Name,
      groups: {
        count: getUserGroupsRefs(u).length,
        onClick: () => handleUserDetailsClick?.(u, 'GROUPS')
      },
      devices: {
        count: getUserDeviceCount?.(u),
        onClick: () => handleUserDetailsClick?.(u, 'DEVICES')
      },
      rsrcAccess: {
        count: getUserRsrcsCount?.(u),
        onClick: (tab) => handleUserDetailsClick?.(u, tab)
      },
      source: getUserSource(u),
      status: getUserStatus(u),
      type: checkIsAdmin(u) ? 'Admin' : 'User',
      more: {
        onDeleteClick: () => handleUserDeleteClick?.(u),
        onViewProfileClick: () => handleViewProfileClick?.(u)
      },
      details: {
        onClick: () => handleViewProfileClick?.(u)
      },
      goToUserDetails: () => handleUserDetailsGoToClick(u.ObjectMeta.Name)
    }
  }

  const getTableData = () => {
    return getFilteredUsersList().map(tableRowAdapterFn)
  }

  useReduxFetch([
    'deviceList',
    'groupList',
    'policyList',
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames
  ])

  return (
    <div>
      <Header
        searchKey={searchKey}
        onSearchKeyChange={setSearchKey}
        onAddUsersClick={handleAddUsersClick}
      />
      <div className='mt-7'>
        <div className='flex justify-between'>
          <Typography variant='body-regular'>
            {getFilteredUsersList().length} Total Users
          </Typography>
          <SimpleDropDown
            menuItems={SortMenuItems}
            onChange={(e) => setSortFilter(e.target.value)}
            value={sortFilter}
          />
        </div>
        <div className='mt-4'>
          <ResponsiveTable
            rowSelection={{
              selectedRowKeys: selectedRowIDs,
              type: 'checkbox',
              onChange(selectedRowIDs) {
                setSelectedRowIDs(selectedRowIDs)
              }
            }}
            scrollX='1200px'
            columns={responsiveColumns()}
            data={getTableData()}
          />
        </div>
      </div>
      {showUserDetailsModal && selectedUser && (
        <UserDetailsModal
          tab={activeUserDetailsTab}
          onClose={handleUserDetailsClose}
          user={selectedUser}
        />
      )}
      {showDeleteUserModal && selectedUser && (
        <DeleteUserModal
          user={selectedUser}
          onSuccess={() => setShowDeleteUserModal(false)}
          onCancel={() => setShowDeleteUserModal(false)}
        />
      )}
      {selectedRowIDs.length > 0 && (
        <FloatingActions
          message={`${selectedRowIDs.length} Users Selected`}
          buttons={[
            {
              label: 'Add To Group',
              onClick: () => setShowBulkAddToGroupModal(true),
              variant: 'grayBlue'
            },
            {
              label: 'Delete',
              onClick: () => setShowBulkDeleteUserModal(true),
              variant: 'grayRed'
            }
          ]}
        />
      )}
      {showBulkDeleteUserModal && (
        <BulkDeleteSelectionModal
          items={getObjectRef(selectedRowIDs.map((e) => reverseRsrcKey(e)))}
          onCancel={() => setShowBulkDeleteUserModal(false)}
          onSuccess={() => setShowBulkDeleteUserModal(false)}
          type='User'
        />
      )}
      {showBulkAddToGroupModal && (
        <BulkAddToGroup
          users={getObjectRef(selectedRowIDs.map((e) => reverseRsrcKey(e)))}
          onSuccess={() => setShowBulkAddToGroupModal(false)}
          onCancel={() => setShowBulkAddToGroupModal(false)}
        />
      )}
    </div>
  )
}

/**
 * Constants goes here
 */

const SortByFilterMap = {
  name: (u) => getResourceName(u),
  email: (u) => u.Spec.EmailID,
  created: (u) => moment(u.ObjectMeta.CreatedAt).toDate().getTime().toString()
}

const SortMenuItems = [
  {
    label: 'Sort: by Name',
    value: 'name'
  },
  {
    label: 'Sort: by Email',
    value: 'email'
  },
  {
    label: 'Sort: by Created',
    value: 'created'
  }
]

export { Users }
