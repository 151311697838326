import _ from 'lodash'
import CONSTANTS from 'Utils/Constants'

export const createCustomIAMRolePayload = ({
  Name,
  Description,
  services = [],
  CloudType = CONSTANTS.IAMCloudTypes.AWS,
  mergeObject
}) => {
  let payload = {
    ObjectMeta: {
      Name
    },
    Spec: {
      Description,
      CloudType,
      Actions: {
        Elems: services
      }
    }
  }

  if (mergeObject) {
    payload = _.cloneDeep(mergeObject)
    payload.Spec.Description = Description
    payload.Spec.Actions.Elems = services
  }

  return payload
}
