import { cn } from 'Utils/Helpers'
import React from 'react'

function PrefixSuffixInput({
  prefix = '',
  suffix = '',
  value,
  onTextChange = null,
  prefixAsInput = false,
  prefixWidth = 'auto',
  CustomValueComponent = null
}) {
  return (
    <div>
      <div
        className={cn('border border-[#c2c6cd] h-[46px] rounded flex items-center', {
          'p-4': prefix.length
        })}
      >
        {prefix && !prefixAsInput && <div>{prefix}</div>}

        {prefix && prefixAsInput && (
          <input
            className='overflow-auto whitespace-nowrap bg-transparent'
            style={{ width: prefixWidth }}
            value={prefix}
            disabled
          />
        )}
        <div
          className={cn({
            'w-[100%]': !suffix
          })}
        >
          {CustomValueComponent && (
            <div
              className={cn('outline-none', {
                'px-3 border-x mx-3': prefix.length,
                'pr-3 border-r mr-3 pl-3 border-top rounded-s': !prefix.length,
                'border-r-0 w-[100%] mr-4': !suffix
              })}
            >
              {CustomValueComponent}
            </div>
          )}

          {!CustomValueComponent && (
            <input
              value={value}
              onChange={(e) => onTextChange?.(e.target.value)}
              className={cn('h-[44px] outline-none bg-[#F9FBFC]', {
                'px-3 border-x mx-3': prefix.length,
                'pr-3 border-r mr-3 pl-3 border-top rounded-s': !prefix.length,
                'border-r-0 w-[100%] mr-4': !suffix
              })}
            />
          )}
        </div>
        <div>{suffix}</div>
      </div>
    </div>
  )
}

export { PrefixSuffixInput }
