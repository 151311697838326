import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

/**
 *
 * @param {import('@material-ui/core').SvgIconProps} props
 * @returns
 */
const AppRoleIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 40 40' {...props}>
      <path
        d='M23.071 5.026c3.266.773 5.663 1.969 7.191 3.587 2.292 2.427 3.227 3.14 3.96 5.83.489 1.794.717 2.776.684 2.945.3 1.219.572 1.984.817 2.297.244.312.544.712.898 1.198.451 1 .5 1.745.145 2.236-.354.491-.58.768-.677.829l-1.537 1.451c-1.305.943-2.304 1.574-2.998 1.892-.047.022-.106.05-.176.085l-.156.079c-1.114.569-4.171 2.255-9.173 5.06-2.05.99-3.985 1.485-5.804 1.485-2.729 0-7.95-1.024-9.184-1.68C5.827 31.666 4 30.658 4 28.975s1.418-3.798 1.829-4.341c.41-.544 1.705-2.363 1.968-5.465.176-2.067 1.375-4.986 3.6-8.757 1.771-2.293 3.656-3.794 5.652-4.503 1.997-.708 4.005-1.002 6.022-.882zM34.2 18.751l.105.18c-.406 1.533-2.104 2.621-2.714 2.984-.249.147-.77.42-1.565.82l-.42.21c-.354.177-.752.374-1.194.59l-.34.167-4.318 1.42c-3.272.537-5.578.806-6.919.806-1.34 0-3.313-.33-5.917-.993-.618-.158-.988-.158-1.11 0-.122.158-.122.269 0 .333l.497.177.378.135a1617486.888 1617486.888 0 0 0 .574.203l.177.06c.277.09.71.23 1.224.38l.4.113c.376.105.782.211 1.193.307l4.464.237c1.57-.157 3.208-.435 4.14-.595 1.355-.234 4.293-1.276 5.117-1.587.824-.312 3.077-1.516 4.387-2.374 1.31-.858 1.926-1.784 2.06-1.988.08-.121.126-.447.142-.978l.119.194-.08-.298-.591-.936-.035-.043c.06.183.136.341.226.476zM23.457 6.06l-.108.035c-.854.307-1.865 1.105-3.034 2.395-2.122 2.739-3.256 6.088-3.4 10.048-.194 5.304 2.18 5.63 2.69 5.639h.053l.043-.003c-.51-.993-1.34-4.163-1.145-7.321.194-3.159 2.038-6.442 3.354-8.363 1.17-1.707 2.748-1.99 3.087-2.033l.064.042c-.23-.165-.765-.312-1.604-.438zm9.864 16.356c-.798.362-1.486.739-2.066 1.129.447.182.802.272 1.07.272l.06-.005.108-.069c.895-.58 1.464-.985 1.707-1.216.262-.248-.031-.285-.879-.111zm-3.908-13.32h.018c-3.768 0-5.065 2.36-5.434 2.778-.369.417-1.598 2.402-1.717 5.874-.118 3.471 1.02 4.668 1.02 4.668 0-1.538.414-3.6 1.243-6.186 1.375-2.672 3.005-3.883 4.888-3.632 2.455.327 3.934 2.328 4.28 3.69l.005-.017c-.65-2.286-1.337-3.9-2.057-4.843a15.224 15.224 0 0 0-2.246-2.332z'
        fill-rule='evenodd'
      />
    </SvgIcon>
  )
})

export default AppRoleIcon
