import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getResourceName } from 'features/resources'
import { CloudCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useClouds } from '../../Clouds.utils'
import { useCloudsResources } from 'features/clouds'
import { AccountsSortOptions } from '../../../../components/AccountsSortOptions'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { useHistory } from 'react-router'

function AZUREAccounts({ searchKey }) {
  const [activeOption, setActiveOption] = useState('all')
  const history = useHistory()

  const { accounts: accs, cloudType } = useClouds()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const accounts = applySearchQuery(accs)

  const { getAccountTargets } = useCloudsResources()

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='body-regular'>{accounts.length} Accounts</Typography>
        <AccountsSortOptions activeOption={activeOption} onActiveOptionChange={setActiveOption} />
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {accounts.map((a) => {
          const { _meta } = getAccountTargets(a)
          const namespace = a.ObjectMeta.Namespace
          return (
            <CloudCard
              topRightLabel={namespace}
              topRightLabelVariant={namespace === 'default' ? 'grayBlue' : 'gray'}
              width='100%'
              icon={<TargetIcon height='100%' type='AZURE_APPLICATION' width='100%' />}
              menuItems={[]}
              name={getResourceName(a)}
              onClickMoreInfo={() =>
                history.push(
                  `/admin/clouds/${cloudType.toLowerCase()}/${encodeURIComponent(
                    a.ObjectMeta.Name
                  )}`
                )
              }
              onClickResources={function noRefCheck() {}}
              resourcesAmount={_meta.count}
            />
          )
        })}
      </div>
    </div>
  )
}

export { AZUREAccounts }
