import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { Computer } from '@material-ui/icons'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import { Theme } from 'Core'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import useMultiQuery from 'Core/Hooks/useMultiQuery'
import React, { useEffect, useMemo, useState } from 'react'
import { assumeRoleLogObjectAdapter, sshLogObjectAdapter } from 'Utils/PureHelperFuctions'
import { columns, options } from './TableMeta'

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.text.disabled
  },
  mainText: {
    color: theme.palette.primary.main
  },
  count: {
    marginLeft: -theme.spacing(0.812)
  }
}))

const MostActive = () => {
  const classes = useStyles()
  const columnOptions = { classes }
  const tableOptions = {
    hideHeader: true
  }
  const queryIndexes = useMemoOnce(['event-log', 'proxy-audit-log'])
  const { getQueryLogs, hits, loading } = useMultiQuery(queryIndexes)
  const { data: devices } = useMemoSelector('deviceList')
  const [devicesMap, setDevicesMap] = useState({})
  const [deviceServerMap, setDeviceServerMap] = useState({})
  const [deviceApprolesMap, setDeviceApprolesMap] = useState({})

  const parsedTableData = useMemo(() => {
    const mostActiveDevices = Object.values(devicesMap)
    const data = []
    mostActiveDevices.sort((a, b) => {
      return b.count - a.count
    })
    mostActiveDevices.forEach((item, index) => {
      let serverCount = 0
      let roleCount = 0
      let device = null
      const user = item.user
      if (item.device) {
        if (deviceServerMap[item.device.Attributes.DeviceName]) {
          serverCount = deviceServerMap[item.device.Attributes.DeviceName].count
        }
        if (deviceApprolesMap[item.device.Attributes.DeviceName]) {
          roleCount = deviceApprolesMap[item.device.Attributes.DeviceName].count
        }
        device = item.device.Attributes.DeviceName
      } else {
        if (deviceServerMap[item.deviceName]) {
          serverCount = deviceServerMap[item.deviceName].count
        }
        if (deviceApprolesMap[item.deviceName]) {
          roleCount = deviceApprolesMap[item.deviceName].count
        }
        device = String(item.deviceName)
      }
      if (device !== '0') {
        data.push({
          Icon: Computer,
          deviceInfo: {
            device,
            user
          },
          sessionCount: serverCount + roleCount,
          progress: [
            { name: 'Servers', value: serverCount, color: Theme.palette.warning.light },
            { name: 'IAM Roles', value: roleCount, color: Theme.palette.info.light }
          ]
        })
      }
    })
    return data.map((item, index) => ({ ...item, index: index + 1 }))
  }, [devicesMap, deviceServerMap])

  const Table = ({ partialData = false, searchString = '' }) => {
    const tableData = partialData ? parsedTableData.slice(0, 4) : parsedTableData
    if (loading) {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' height='16rem'>
          <CircularProgress />
        </Box>
      )
    }

    return (
      <Box height='16rem'>
        <AnalyticTable
          rowHeight='2.75rem'
          headHeight='2.5rem'
          headerJustify='flex-end'
          vAlign='top'
          searchString={searchString}
          columns={columns({ ...columnOptions, disableTextWrap: !partialData })}
          options={options(tableOptions)}
          data={tableData}
        />
      </Box>
    )
  }
  const ModalComponent = ({ closeModal }) => (
    <ModalView
      closeModal={closeModal}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )

  useEffect(() => {
    const devicesMap = {}
    const serversMap = {}
    const approlesMap = {}
    const rolesHits = hits['event-log']
    const serverHits = hits['proxy-audit-log']
    serverHits.forEach((hit) => {
      const data = sshLogObjectAdapter(hit, [], devices, [], [], ['account', 'user', 'resource'])
      console.log('[logs hits](server):', data)
      if (data.device) {
        if (!devicesMap[data.device?.Attributes?.DeviceName]) {
          devicesMap[data.device?.Attributes?.DeviceName] = {
            device: data.device,
            count: 1,
            user: hit._source.Data.User,
            deviceName: null
          }
          serversMap[data.device?.Attributes?.DeviceName] = { count: 1 }
        } else {
          devicesMap[data.device?.Attributes?.DeviceName].count++
          if (!serversMap[data.device?.Attributes?.DeviceName])
            {serversMap[data.device?.Attributes?.DeviceName] = { count: 1 }}
          else serversMap[data.device?.Attributes?.DeviceName].count++
        }
      } else {
        if (!devicesMap[hit._source.Data.Device]) {
          devicesMap[hit._source.Data.Device] = {
            device: null,
            count: 1,
            user: hit._source.Data.User,
            deviceName: hit._source.Data.Device
          }
          serversMap[hit._source.Data.Device] = { count: 1 }
        } else {
          devicesMap[hit._source.Data.Device].count++
          if (!serversMap[hit._source.Data.Device]) serversMap[hit._source.Data.Device] = { count: 1 }
          else serversMap[hit._source.Data.Device]
        }
      }
    })
    rolesHits.forEach((hit) => {
      const data = assumeRoleLogObjectAdapter(hit, [], devices, [], [], [], ['account', 'user', 'resource'])
      if (data.device) {
        if (!devicesMap[data.device?.Attributes?.DeviceName]) {
          devicesMap[data.device?.Attributes?.DeviceName] = {
            device: data.device,
            count: 1,
            user: hit._source.User.Name,
            deviceName: null
          }
          approlesMap[data.device?.Attributes?.DeviceName] = { count: 1 }
        } else {
          devicesMap[data.device?.Attributes?.DeviceName].count++
          if (!approlesMap[data.device?.Attributes?.DeviceName])
            {approlesMap[data.device?.Attributes?.DeviceName] = { count: 1 }}
          else approlesMap[data.device?.Attributes?.DeviceName].count++
        }
      } else {
        if (!devicesMap[hit._source.Device.id]) {
          devicesMap[hit._source.Device.id] = {
            device: null,
            count: 1,
            user: hit._source.User.Name,
            deviceName: hit._source.Device.id
          }
          approlesMap[hit._source.Device.id] = { count: 1 }
        } else {
          devicesMap[hit._source.Device.id].count++
          if (!approlesMap[hit._source.Device.id]) approlesMap[hit._source.Device.id] = { count: 1 }
          else approlesMap[hit._source.Device.id].count++
        }
      }
    })
    setDeviceServerMap(serversMap)
    setDeviceApprolesMap(approlesMap)
    setDevicesMap(devicesMap)
  }, [hits, devices])

  useEffect(() => {
    getQueryLogs()
  }, [getQueryLogs])

  return (
    <ExpandableContainer expandButtonLabel='Expand All Devices' modalTitle='Devices' ModalComponent={ModalComponent}>
      <Table partialData />
    </ExpandableContainer>
  )
}

export default MostActive
