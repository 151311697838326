import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import MountOn from 'Components/MountOn'
import PieChart from 'Components/PieChart/PieChart'
import React from 'react'
import Segments from './Segments'
import { columns, options } from './TableMeta'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    height: theme.spacing(19.3),
    width: '100%'
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.grey[200]
  },
  titleHeader: {
    '& > span': {
      justifyContent: 'flex-start !important',
      paddingLeft: theme.spacing(0.5)
    }
  },
  activity: {
    '& > span': {
      paddingRight: theme.spacing()
    }
  },
  spinner: {}
}))

const Segment = ({
  data = [],
  Icon,
  chartData = [],
  modalOptions,
  tableTitleHeader = 'None',
  modalTableData = null,
  loading,
  showFallback,
  fallback
}) => {
  const classes = useStyles()

  const getTableData = () => {
    if (modalTableData?.length) {
      return modalTableData.map((item, index) => ({ index: index + 1, title: item.title, text: item.text }))
    }
    return data.map((item, index) => ({ index: index + 1, title: item.title, text: item.text }))
  }

  const Table = ({ searchString = '' }) => {
    return (
      <AnalyticTable
        rowHeight='2.75rem'
        headHeight='2.5rem'
        headerJustify='flex-end'
        vAlign='top'
        searchString={searchString}
        columns={columns({ classes, tableTitleHeader })}
        options={options()}
        data={getTableData()}
      />
    )
  }

  const ModalComponent = ({ closeModal }) => (
    <ModalView
      searchPlaceholder={modalOptions.searchPlaceholder}
      closeModal={closeModal}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )

  if (loading) {
    return (
      <Box display='flex' height='19.3rem' justifyContent='center' alignItems='center'>
        <CircularProgress className={classes.spinner} />
      </Box>
    )
  }

  return (
    <div className={classes.root}>
      <ExpandableContainer
        hideExpandButton={showFallback}
        modalTitle={modalOptions.modalTitle}
        ModalComponent={ModalComponent}
        expandButtonLabel={modalOptions.expandButtonLabel}
      >
        <Box height='15rem'>
          <MountOn on={!showFallback} fallback={fallback}>
            <Grid justifyContent='space-between' container>
              <Grid xs={6} item>
                <Segments segmentRows={data} />
              </Grid>
              <Grid xs={6} alignItems='flex-start' justifyContent='flex-end' container>
                <Box mt={-1}>
                  <PieChart
                    hideRightSection
                    chartData={chartData}
                    chartStyles={{ height: '15rem', width: '15rem' }}
                    ChartCenter={Icon && <Icon className={classes.icon} fontSize='large' />}
                  />
                </Box>
              </Grid>
            </Grid>
          </MountOn>
        </Box>
      </ExpandableContainer>
    </div>
  )
}

export default Segment
