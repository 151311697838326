import _ from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { Pagination } from 'V2Components'

const PaginationView = ({
  data,
  renderFunction,
  WrapperComponent = null,
  showPaginationAtTop = false,
  showPaginationAtBottom = true,
  itemsCount = 10
}) => {
  const [activePage, setActivePage] = useState(1)
  const prevData = useRef([])
  const [paginationIndex, setPaginationIndex] = useState({
    start: 0,
    end: itemsCount
  })

  const getPaginatedData = useCallback(() => {
    const newData = data.slice(paginationIndex.start, paginationIndex.end)
    if (!_.isEqual(prevData.current, newData)) {
      prevData.current = newData
      return newData
    }
    return prevData.current
  }, [data, paginationIndex])

  const renderComponent = useCallback(() => {
    return getPaginatedData().map(renderFunction)
  }, [renderFunction, getPaginatedData])

  return (
    <>
      {showPaginationAtTop && (
        <Pagination
          page={activePage}
          onPageChange={setActivePage}
          count={data.length}
          onIndexChange={setPaginationIndex}
          itemsPerPage={itemsCount}
        />
      )}
      {WrapperComponent ? (
        <WrapperComponent>{renderComponent()}</WrapperComponent>
      ) : (
        renderComponent()
      )}
      {showPaginationAtBottom && (
        <Pagination
          page={activePage}
          onPageChange={setActivePage}
          count={data.length}
          onIndexChange={setPaginationIndex}
          itemsPerPage={itemsCount}
        />
      )}
    </>
  )
}

export { PaginationView }
