import { Box, makeStyles, Typography } from '@material-ui/core'
import { Laptop } from '@material-ui/icons'
import NewDataTable from 'Components/NewDataTable'
import ThemeButton from 'Components/ThemeButton'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { deviceListSlice } from 'infra/redux/reducers'
import MagicLinkModal from 'Views/UsersAndGroups/UserDetails/Components/Overview/MagicLink/MagicLinkModal'
import SnackMessage from '../../Components/SnackMessage/SnackMessage'
import { prepareDevicesList } from '../../Utils/Helpers'
import { columns, options } from './DevicesTableMeta'

const useStyle = makeStyles((theme) => ({
  root: { padding: theme.spacing(1, 0), paddingTop: 0 },
  date: {
    color: theme.palette.text.secondary
  },
  timerIcon: {
    height: theme.spacing(),
    width: theme.spacing(),
    fill: theme.palette.text.secondary,
    marginRight: theme.spacing(0.375)
  },
  header: {
    fontSize: theme.spacing(1.25)
  },
  you: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(0.1, 0.5),
    borderRadius: '50px',
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(0.8)
  }
}))

const DeviceList = ({ viewMode = 'admin', tab = 'APPROVED' }) => {
  const classes = useStyle()
  const history = useHistory()
  const dispatch = useDispatch()
  // @ts-ignore
  const user = useSelector((state) => state.user)
  const userName = _.get(user, 'ObjectMeta.Name', '')
  // @ts-ignore
  const deviceInfo = useSelector((state) => state.user?.DeviceInfo)
  const [searchKey, setSearchKey] = useState('')
  const [openSnack, setOpenSnack] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const { data: deviceList } = useMemoSelector('deviceList')

  const getAllDevices = async () => {
    dispatch(deviceListSlice.thunk())
  }

  const onInit = () => {
    getAllDevices()
  }

  const onView = (row) => {
    const id = row[row.length - 1]
    history.push(`/${viewMode}/device/${id}`)
  }

  const preparedDeviceList = useMemo(() => {
    let devices = deviceList
    if (viewMode === 'user') devices = deviceList.filter((e) => e.UserName === userName)
    return prepareDevicesList(devices, deviceInfo, tab === 'APPROVED')
  }, [deviceList, deviceInfo, viewMode, userName])

  useEffect(() => {
    onInit()
  }, [])

  const [openModal, setOpenModal] = useState(false)
  const handleMagicLinkModalClose = useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal])

  const tableColumns = useMemo(() => columns({ classes }), [classes])
  const tableOptions = useMemo(() => options(onView), [onView])
  return (
    <div className={classes.root}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography className={classes.header} variant='h3'>
          Onboarded Devices
        </Typography>
        {viewMode === 'user' &&
          <ThemeButton
            theme='primary'
            startIcon={<Laptop />}
            onClick={() => setOpenModal(true)}
          >
            Onboard new device
          </ThemeButton>}
      </Box>
      <NewDataTable
        showFilters={false}
        hideHeader={false}
        searchBarWidth='16'
        showFilterHeader
        hideTimeFilter
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        title='Devices'
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        isLoading={false}
        data={preparedDeviceList}
        columns={tableColumns}
        options={tableOptions}
      />
      <MagicLinkModal selectedUser={user} open={openModal} handleClose={handleMagicLinkModalClose} />
      <SnackMessage backgroundColor='secondary' setOpenSnack={setOpenSnack} openSnack={openSnack} />
    </div>
  )
}

export default DeviceList
