import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deviceListSlice, userListSlice } from 'infra/redux/reducers'
import { constructQuery } from 'Utils/LogsHelpers'
import { groupByDate } from 'Utils/PureHelperFuctions'
import moment from 'moment'

export const LogsContext = createContext(null)
/**
 * Used to summaries active users from sessions.
 * Example
 * const getUserFromHit = (hit) => {
 *  return hit._source.User.Name
 * }
 * @typedef {(hit:object)=>any} getUserFromHit
 */
/**
 * Utitlity context for logs
 * @param {{
 * apiCall:(query:Object)=>Promise,
 * children:Element,
 * chartDefault:Object,
 * chartKey:string,
 * getUserFromHit?:getUserFromHit,
 * }} param0
 * @returns
 */
const LogsProvider = ({
  apiCall,
  children,
  chartDefault = { Sessions: [], 'Active User': [] },
  chartKey = 'data',
  getUserFromHit
}) => {
  const [tableData, setTableData] = useState([])
  const [chartData, setChartData] = useState(chartDefault)
  const [loading, setLoading] = useState(false)
  const transformDataForChart = useCallback((data) => {
    return {
      Sessions: groupByDate(data, chartKey),
      'Active User': groupByDate(data, chartKey, getUserFromHit)
    }
  }, [])

  /**
   * Fetchs data and transform it for table and chart
   * @param {boolean} table should transform data for table
   * @param {boolean} chart should transform data for chart
   * @param {Object} constructedQuery query for elastic search
   */
  const refreshData = useCallback(
    async ({ table = true, chart = true, constructedQuery }) => {
      try {
        setLoading(true)
        /** Get data from api */
        const response = await apiCall(constructedQuery)
        const payload = response.data.hits.hits
        if (table) {
          const tempTableData = payload
          tempTableData.sort(
            (a, b) =>
              moment(b._source['@timestamp']).toDate().getTime() - moment(a._source['@timestamp']).toDate().getTime()
          )
          setTableData(tempTableData)
        }
        if (chart) {
          const tempChartData = transformDataForChart(payload)
          setChartData(tempChartData)
        }
      } catch (error) {
        console.error('error in refreshData', error)
      } finally {
        setLoading(false)
      }
    }, [setTableData, setChartData, constructQuery])
  const dispatch = useDispatch()

  useEffect(() => {
    /**
     * Both role and server activity logs consume userList and device list
    **/
    dispatch(userListSlice.thunk())
    dispatch(deviceListSlice.thunk())
  }, [])
  return (
    <LogsContext.Provider value={{ tableData, chartData, refreshData, loading }}>
      {children}
    </LogsContext.Provider>
  )
}

export default LogsProvider
