import { ApiProvider, _ } from 'Core'
import store from 'infra/redux/store'
import { getActionsOf } from './ActionEnpointsHelper'

const IGNORE_RESOURCEKINDS = ['AwsResource']

const fetchGrantedResources = async (resourceName, action, userName = null) => {
  try {
    const Name = `${resourceName}+${action}${userName ? '+' + userName : ''}`
    const response = await new ApiProvider('authzactioncheck').setInstance({ ObjectMeta: { Name } }).get()
    return response.data
  } catch (error) {
    console.error('error in fetchGrantedResources', error)
    return false
  }
}
const getAuthZEndpoints = (resourceActions, KindArr = []) => {
  // Key with _ in the beginning is ignored, it is taken as a meta info
  const resourceKinds = Object.keys(resourceActions).filter(key => key[0] !== '_' && !IGNORE_RESOURCEKINDS.includes(key))
  const _selectedKinds = KindArr.length ? KindArr : resourceKinds
  const urlsMaps = []
  _selectedKinds.forEach((resourceKind) => {
    const actions = getActionsOf(resourceKind, resourceActions)
    const urlGroup = actions.map(action => ({ resourceKind, action }))
    urlsMaps.push(...urlGroup)
  })
  return urlsMaps
}

const fetchAllResourceGrants = async (urlsMaps, userName = null) => {
  const grantState = store.getState().resourceKindGrants.data
  const actionObjects = _.cloneDeep({ ...grantState })
  await Promise.all(urlsMaps.map(async urlMap => {
    const { resourceKind, action } = urlMap
    const grantedResources = await fetchGrantedResources(resourceKind, action, userName)
    if (resourceKind in actionObjects) {
      actionObjects[resourceKind][action] = grantedResources
    } else {
      actionObjects[resourceKind] = { [action]: grantedResources }
    }
  }))
  return actionObjects
}

export const populateAuthZ = async (resourceActions, userName = null, _resourceKind = []) => {
  try {
    const resourceKind = resourceActions._resourceKind || _resourceKind
    const urlsMaps = getAuthZEndpoints(resourceActions, resourceKind)
    const resourceGrantMap = await fetchAllResourceGrants(urlsMaps, userName)
    return resourceGrantMap
  } catch (error) {
    return {}
  }
}

/**
 *
 * @param {{ResourceKind:{Action:{Resources:Array<{Resource:{RefKind:string,RefID:'string'}}>}}}} resourceKindGrants
 * @param {string} resourceKind ObjectMeta.Kind property of a resource
 * @returns {Array<{Resource:{RefKind:string,RefID:'string'},Action:string}>}
 */
export const getFlatResourceKindGrants = (resourceKindGrants, resourceKind) => {
  if (resourceKind in resourceKindGrants) {
    const flatResourceKindGrants = []
    const specificResourceKindGrants = resourceKindGrants[resourceKind]
    const resourcesActions = Object.keys(specificResourceKindGrants)
    resourcesActions.forEach(action => {
      const grantedResources = specificResourceKindGrants[action]
      flatResourceKindGrants.push(...grantedResources.Resources.map(e => ({ ...e, Action: action })))
    })
    return flatResourceKindGrants
  }
  return []
}
/**
 * Gives all possible actions in the system
 * @param {{[resourceType:String]:{Actions:Array<{Action:String}>}}} resourceActions
 * @returns {Array<String>} actions
 */
export const getAllActions = (resourceActions) => {
  try {
    const actions = []
    Object.values(resourceActions).forEach(e => e.Actions.forEach(action => actions.push(action.Action)))
    return actions
  } catch (error) {
    console.error('error in getAllActions:', error)
    return []
  }
}
