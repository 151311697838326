import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FullScreenModal } from 'V2Components'
import {
  Button,
  Label,
  LoadingSpinner,
  SwitchBox,
  TargetIcon,
  TextInput,
  Typography
} from 'procyon-ui'
import React, { useState } from 'react'

const Jira = ({
  showIntegrationModal,
  setShowIntegrationModal,
  onInputChange,
  onContinue,
  isDisabled,
  isSelfHosted,
  setIsSelfHosted,
  message
}) => {
  return (
    <FullScreenModal showModal={showIntegrationModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
              <TargetIcon
                width={'48px'}
                height={'48px'}
                type={'JIRA'}
                className='rounded'
                style={{
                  borderRadius: '12px',
                  marginRight: '15px'
                }}
              />
              
              Setup Jira Integration
            </Typography>
          </div>
          {!message ? (
            <>
              <div className='p-4 mt-2 rounded text-black overflow-auto'>
                <TextInput
                  label='Integration Name'
                  onChange={(e) => onInputChange(e.target.value, 'name')}
                  placeholder='Please enter name'
                  style={{
                    width: '520px'
                  }}
                />

                <div className='flex justify-between my-3 items-center'>
                  <span>Self Hosted</span>
                  <SwitchBox
                    checked={isSelfHosted}
                    onChange={() => setIsSelfHosted(!isSelfHosted)}
                    variant='primary'
                    width='60px'
                    style={{
                      background: 'none'
                    }}
                  />
                </div>

                {isSelfHosted && (
                  <TextInput
                    label='BaseUrl'
                    onChange={(e) => onInputChange(e.target.value, 'hostUrl')}
                    placeholder='URL'
                    style={{
                      width: '520px'
                    }}
                  />
                )}
              </div>

              <div className='flex justify-end mt-4 gap-5'>
                <Button size='md' onClick={() => setShowIntegrationModal(false)} variant='gray'>
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  size='md'
                  onClick={() => onContinue()}
                  variant='primary'
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <div className='flex items-center bg-[#f2f4fb] p-5 mt-5'>
              <div className='mt-2'>
                <LoadingSpinner size={20} thickness={4} />
              </div>
              <Label text={message} variant='grayBlue' />
            </div>
          )}
        </div>
      </div>
    </FullScreenModal>
  )
}

export { Jira }
