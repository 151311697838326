import { updateSliceData } from 'infra/redux/sliceHandlers'
import { LoadingFeedback } from 'V2Components'
import { SummaryHeader, getResourceName } from 'features/resources'
import {
  DeleteUserModal,
  changeUserStatus,
  getUserStatus,
  useUserDetailsContext
} from 'features/users'
import { Button, SwitchBox } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { OnboardingLinkModal } from './components/OnboardingLinkModal'

function Header() {
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const history = useHistory()
  const { user } = useUserDetailsContext()

  const handleDeleteSuccess = () => history.push('/admin/users')

  const handleUserActiveStatusChange = async () => {
    try {
      setIsStatusChanging(true)
      const newUser = await changeUserStatus(user, !user.Spec.Active)
      updateSliceData(newUser)
    } catch (error) {
    } finally {
      setIsStatusChanging(false)
    }
  }

  return (
    <div>
      <SummaryHeader
        actions={
          <div className='flex gap-4'>
            <SwitchBox
              checked={getUserStatus(user) === 'Active'}
              label={getUserStatus(user)}
              onChange={handleUserActiveStatusChange}
              variant='primary'
            />
            <Button onClick={() => setShowOnboardingModal(true)} size='md' variant='grayBlue'>
              Onboard User
            </Button>
            <Button onClick={() => setShowDeleteUserModal(true)} size='md' variant='grayRed'>
              Delete User
            </Button>
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Users',
            link: '/admin/users'
          },
          {
            label: getResourceName(user)
          }
        ]}
        rsrcName={getResourceName(user)}
      />
      {showOnboardingModal && (
        <OnboardingLinkModal user={user} onClose={() => setShowOnboardingModal(false)} />
      )}
      {showDeleteUserModal && (
        <DeleteUserModal
          user={user}
          onCancel={() => setShowDeleteUserModal(false)}
          onSuccess={handleDeleteSuccess}
        />
      )}
      <LoadingFeedback
        loading={isStatusChanging}
        message={`${getUserStatus(user) === 'Active' ? 'Deactivating' : 'Activating'} User`}
        caption='Please wait..'
      />
    </div>
  )
}

export { Header }
