import { LabelContent } from 'V2Components'
import { getResourceName } from 'features/resources'
import { Button, Label, Typography } from 'procyon-ui'
import React from 'react'

const TagInfo = ({ tag, onDelete }) => {
  const tags = Object.keys(tag.Tags.Map)
  const kinds = Object.keys(tag.KindMap.Map)

  return (
    <div className='px-4'>
      <div className='flex justify-between'>
        <Typography variant='h4-regular'>{getResourceName(tag)}</Typography>
        <span className='flex gap-4'>
          <Button onClick={onDelete} variant='grayRed'>Delete</Button>
          {/* <Button variant='gray'>Edit</Button> */}
        </span>
      </div>
      <div>
        <LabelContent title='RegEx Pattern' content={tag.RegExPattern} />
        <LabelContent
          title='Kinds'
          content={
            <div className='flex gap-2'>
              {kinds.map((k) => (
                <Label key={k} text={k} />
              ))}
            </div>
          }
        />

        {tags.map((t) => {
          const tValue = tag.Tags.Map[t]
          return <LabelContent key={t} title={t} content={tValue} />
        })}
      </div>
    </div>
  )
}

export { TagInfo }
