import { Box } from '@material-ui/core'
import ThemeInput from 'Components/ThemeInput'
import useTimeoutCall from 'Core/Hooks/useTimeoutCall'
import { TextInput } from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'

function SearchBar ({ searchKey, setSearchKey, width = 37, fullWidth, className = '', height = '' }) {
  const inputRef = useRef(null)
  const [text, setText] = useState(searchKey)
  const { updateSeed } = useTimeoutCall({
    delay: 550,
    callback: setText
  })

  const handleTextChange = (event) => {
    updateSeed(event.target?.value)
  }

  useEffect(() => {
    typeof setSearchKey === 'function' && setSearchKey(text)
  }, [text])

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <TextInput
          icon={{
            icon: [
              512,
              512,
              [
                128269,
                'search'
              ],
              'f002',
              'M416 208c0 45.9-14.9 88.3-40 122.7L486.6 441.4 509.3 464 464 509.3l-22.6-22.6L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z'
            ],
            iconName: 'magnifying-glass',
            prefix: 'fass'
          }}
          iconPosition="start"
          width={width}
          inputRef={inputRef}
          onChange={handleTextChange}
          onClickEndIcon={function noRefCheck(){}}
          placeholder="Search"
          style={{
            width: 'auto'
          }}
        />
    </Box>
  )
}

export default SearchBar
