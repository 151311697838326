import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { makeStyles } from '@material-ui/core'
import { Grid } from '@mui/material'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { getDisplayName } from 'Utils/PureHelperFuctions'
import { Breadcrumbs, LoadingFeedback } from 'V2Components'

import { getResourceName, getRsrcIcon, useAccessCartProvider, IANA_TIMEZONES } from 'features/resources'
import { Button, Label, RepositoryRow, SelectDropDown, SummaryResourceCard, TextInput, Typography, UserGroupBar } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { StartEndTimePicker } from './Components/StartEndTimePicker'
import { getIAMResourceDisplayType } from 'features/iamResources'
import useAppView from 'Core/Hooks/useAppView'
import _ from 'lodash'
import { useResourceAccess } from 'Core/Hooks/useResourceAccess'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    height: '100%'
  },
  mainGrid: {
    height: '100%',
    padding: theme.spacing(1, 2.5)
  },
  summaryGrid: {
    borderRight: '1px solid RGB(216, 221, 228)',
    paddingRight: theme.spacing(4),
    height: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'RGB(249, 251, 252)',
    padding: theme.spacing(1, 2.5)
  },
  resourceSummaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  configureRequestGrid: {
    paddingLeft: theme.spacing(4)
  },
  summaryCard: {
    marginBottom: theme.spacing(2)
  },
  rsrcIcon: {
    height: '34px',
    width: '34px',
    borderRadius: '4px'
  },
  requestNameInput: {
    marginTop: theme.spacing(0.5)
  },
  configureReqBody: {
    marginTop: theme.spacing(),
    width: '100%'
  },
  accessTimeHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5)
  },
  label: {
    marginBottom: theme.spacing(0.5)
  },
  commentBox: {
    marginTop: theme.spacing()
  }
}))

function AccessRequestSubmission({
  onCancel,
  onSubmit,
  afterSubmit = null,
  loadingMessage,
  mainTitle = 'Request',
  onRsrcEditClick = null,
  requestForUserGroupsRefs = [],
  onAddMoreResourcesClick,
  onManageUserGroupsClick = null,
  showRequestForUserGroupsSummary = true,
  showAddMoreResourcesBtn = false,
  requestName,
  setRequestName,
  comments,
  setComments,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateTimeError,
  setDateTimeError,
  currentAccountObj
}) {
  const classes = useStyles()
  const { appView } = useAppView()
  const { slices: rsrcs, getObjectRef: getRsrcsRef } = useMultiSlice([
    'awsResources',
    'azureResources',
    'gcpResources',
    'serverList',
    'appRolesList',
    'databases',
    'kubeNamespaces',
    'applicationList'
  ])

  const { slices, selectDispatch, getObjectRef } = useMultiSlice([
    'iamActions',
    'accountList',
    'userList',
    'groupList',
    'serviceAccounts',
    'githubResources'
  ])
  

  const {
    updateSpec,
    spec
  } = useResourceAccess()
  const { userList: users, groupList: groups, serviceAccounts, githubResources } = slices
  const [isLoading, setIsLoading] = useState(false)
  const { cartItems, removeFromCart } = useAccessCartProvider()
  const [allResourcesList, setAllResourcesList] = useState([])
  const [githubRowList, setGithubRowList] = useState([])
  const [permissionValues, setPermissionValues] = useState({0: 'read'});
  const clearAllInputFields = () => {
    setRequestName('')
    setComments('')
    setStartDate(null)
    setEndDate(null)
    setDateTimeError({
      startDate: '',
      endDate: ''
    })
  }

  useEffect(() => {
    selectDispatch(['githubResources'])
    generateGitRepoRow()
  }, [])

  const generateGitRepoRow = async () => {
    let filterGitRow =slices.githubResources?.filter(obj => obj.ObjectMeta.ID === cartItems[0].Resource.RefID)
    setGithubRowList(filterGitRow)
  }

  const getCaption = (item) => {
    if (item.ObjectMeta.Kind === 'User') {
      return item.ObjectMeta.Name
    }
    if (item.ObjectMeta.Kind === 'GithubResource' ) {
      return `${item.Spec?.Members?.Members?.length} Users`
    }
  }
  const getName = (item) => {
    if (item.ObjectMeta.Kind === 'User') {
      return getDisplayName(item)
    }
    if (item.ObjectMeta.Kind === 'GithubResource') {
      return item.Spec?.TeamSpec?.Name
    }
  }

  const requestForUserGroupsItems = useMemo(() => {
    const data = []
    if (!requestForUserGroupsRefs || !requestForUserGroupsRefs.length) return []
    requestForUserGroupsRefs.forEach((e) => {
      const sourceListMap = {
        User: users,
        GithubResource: githubResources
      }
      const TypeMap = {
        user: 'USER',
        githubresource: 'USERGROUPS'
      }

      const list = sourceListMap[e.RefKind]
      const item = _.find(list, { ObjectMeta: { ID: e.RefID } })

      if (!item) return
      data.push({
        caption: getCaption(item),
        name: getName(item),
        type: TypeMap[e.RefKind.toLowerCase()],
        key: `${e.RefKind}+ ${e.RefID}`,
        ref: e,
        permission: e.permission !== undefined ? e.permission : 'read' 
      })
    })
    return data
  }, [requestForUserGroupsRefs, users,])

  const getUserGroupsCount = () => {
    let str = ''
    const counts = { users: 0, groups: 0, serviceAccounts: 0 }
    const countStr = []
    requestForUserGroupsItems.forEach((e) => {
      if (e.type === 'USER') counts.users += 1
      if (e.type === 'USERGROUPS') counts.groups += 1
      if (e.type === 'SERVICEACCOUNT') counts.serviceAccounts += 1
    })

    if (counts.users) countStr.push(`${counts.users} Users`)
    if (counts.groups) countStr.push(`${counts.groups} Groups`)
    if (counts.serviceAccounts) countStr.push(`${counts.serviceAccounts} Service Accounts`)

    str = `${countStr.slice(0, countStr.length > 1 ? countStr.length - 1 : 1).join(', ')} ${
      countStr.length >= 2 ? 'and ' + countStr[countStr.length - 1] : ''
    }`

    return str
  }

  const rsrcsSummary = useMemo(() => {
    const data = []
    cartItems.forEach(({ Resource, Roles, Principal }) => {
      let rsrc = null
      const rsrcAttributes = []
      rsrc = getRsrcsRef(Resource)
      if (!rsrc) return
      Roles.ObjectRef?.forEach((r) => {
        const role = _.find(slices.iamActions, { ObjectMeta: { ID: r.RefID } })
        if (!role) return
        rsrcAttributes.push(role.Spec.RoleName || role.ObjectMeta.Name)
      })
      Principal && rsrcAttributes.push(`Principal: ${Principal}`)
      const account = getObjectRef(rsrc.Spec.Account)
      data.push({
        accountName: _.get(account, 'ObjectMeta.Name', ''),
        region: rsrc.Spec.Region || '',
        resourceName: getResourceName(rsrc),
        resourceType: getIAMResourceDisplayType(rsrc),
        attributes: rsrcAttributes,
        Icon: getRsrcIcon(rsrc),
        disableEdit: Resource.RefKind === 'AppRole' || Resource.RefKind === 'Database',
        principal: Principal,
        rsrc,
        ref: {
          // Resource's Ref
          ...Resource
        }
      })
    })
    return data
  }, [cartItems, rsrcs, slices])

  const handleRsrcEditClick = (rsrc) => {
    onRsrcEditClick && onRsrcEditClick(rsrc.ref, rsrc.rsrc)
  }

  const handleRemoveResourceClick = (ref) => {
    removeFromCart(ref)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const dataObject = {
      selectedRsrcs: cartItems,
      requestName,
      comments,
      startDate,
      endDate,
      requestForUserGroupsRefs,
      currentAccountObj
    }

    try {
      await onSubmit(dataObject)
      clearAllInputFields()
      afterSubmit?.()
    } catch (error) {
      enqueueNotification(`Error Creating ${mainTitle}!`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    clearAllInputFields()
    onCancel?.()
  }

  const getInputErrors = () => {
    if (dateTimeError.endDate || dateTimeError.startDate) return dateTimeError.endDate || dateTimeError.startDate
    if (requestName.length < 3) return 'Request Name must be greater than 3 characters.'
    if (!cartItems.length) return 'No Resources selected. Please go back and select resources.'
  }

  const handlePermissionChange = (index, value) => {
    setPermissionValues(prevState => ({
      ...prevState,
      [index]: value,
    }));
  };



  return (
    <div className={classes.root}>
      <LoadingFeedback loading={isLoading} caption='Please wait..' message={loadingMessage} />
      <div className={classes.header}>
        <div>
          <Breadcrumbs
            breadCrumbs={[
              {
                label: 'Resource Catalog',
                onClick: () => onCancel()
              },
              {
                label: `${mainTitle} Submission`
              }
            ]}
          />
          <Typography className='mt-4' variant='h2'>
            {mainTitle} Submission
          </Typography>
        </div>
        <div className='flex gap-4'>
          {getInputErrors() && <Label iconButton={faTriangleExclamation} text={getInputErrors()} variant='warning' />}
          <Button onClick={handleCancel} variant='gray'>
            Cancel
          </Button>
        </div>
      </div>
      <Grid className={classes.mainGrid} container>
        <Grid className={classes.summaryGrid} xs={6} item>
          <Typography variant='body-regular'>{mainTitle} Summary</Typography>
          
            <div>
              <div className={classes.resourceSummaryHeader}>
                <Typography variant='body-regular'>{getUserGroupsCount()}</Typography>
                
                {appView !== 'user' &&  <Button
                  icon={faPlus}
                  iconPosition='end'
                  onClick={() => onManageUserGroupsClick && onManageUserGroupsClick()}
                  variant='grayBlue'
                >
                  Manage Entities
                </Button>}
                
              </div>
              <div>
                <div className={cn('border-[#EEF4F7] border-b')}>
                  {requestForUserGroupsItems.map((e, index) => (
                    <UserGroupBar 
                      endButton={false} 
                      key={e.key} 
                      caption={e.caption} 
                      name={e.name}   
                      type={e.type}
                      showPermissionsMenu
                      permissionsMenuValue={permissionValues[index] || e.permission}
                      onChangePermissionsMenu={(e) => handlePermissionChange(index, e.target.value)}
                      />
                  ))}
                  
                </div>
              </div>
            </div>
          
          <div>
            <div className={classes.resourceSummaryHeader}>
              <Typography variant='body-regular'>{githubRowList.length} Repository</Typography>
              {showAddMoreResourcesBtn && (
                <Button onClick={onAddMoreResourcesClick} icon={faPlus} iconPosition='end' variant='grayBlue'>
                  Add Resources
                </Button>
              )}
            </div>
            
            <div>
              {githubRowList && githubRowList.map(item => (
                <RepositoryRow
                    githubAccount={item.Spec.RepositorySpec.FullName?.split('/')[0]}
                    onClickMoreInfo={function noRefCheck(){}}
                    onClickSignIn={function noRefCheck(){}}
                    onClickUser={function noRefCheck(){}}
                    onClickUserGroups={function noRefCheck(){}}
                    repositoryName={item.Spec.RepositorySpec.Name}
                    userCount={8}
                    userGroupCount={5}
                    showUserGroupsButton={false}
                    showUserbutton={false}
                    showMoreInfoButton={false}
                    showSignInButton={false}
                />
              ))}
            </div>
                
          </div>
        </Grid>
        <Grid className={classes.configureRequestGrid} xs={6} item>
          <Typography variant='body-semiBold'>Configure {mainTitle}</Typography>
          <div className={classes.configureReqBody}>
            <Typography variant='body-regular'>{mainTitle} Name</Typography>
            <TextInput
              value={requestName}
              onChange={(e) => setRequestName(e.target.value)}
              className={classes.requestNameInput}
              sx={{ width: '100%' }}
            />
            <Typography className={classes.accessTimeHeader} variant='body-semiBold'>
              Setup Access Time
            </Typography>
            <div className='mb-2'>
              <Typography className='mb-2' variant='body-regular'>Select Timezone</Typography>
              <SelectDropDown
                menuItems={IANA_TIMEZONES.map((tz) => ({ label: tz, value: tz }))}
                onChange={(e) => updateSpec({ timeZone: e.target.value })}
                value={spec.timeZone}
              />
            </div>
            <StartEndTimePicker
              onError={setDateTimeError}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
            <div className={classes.commentBox}>
              <Typography className={classes.label} variant='body-regular'>
                Add Comments
              </Typography>
              <TextInput
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                multiline
                rows={3}
                sx={{ width: '100%' }}
              />
            </div>
            <Grid justifyContent='flex-end' container>
              <Grid item>
                <Button disabled={!!getInputErrors()} className='mt-8' onClick={handleSubmit} variant='primary'>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

/**
 * Map of Kinds whose edit is disabled in request summary
 */
const NO_EDIT_RSRC_KINDS = {
  AppRole: true,
  Database: true,
  Application: true
}

export { AccessRequestSubmission }
