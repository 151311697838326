import { useState } from 'react'
import ReactLogo from './logo.svg'
import React from 'react'
import { Button } from 'procyon-ui'
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons'

function ErrorFallback({ error, resetErrorBoundary }) {
  const [copiedErrorMessage, setCopiedErrorMessage] = useState(false)

  return (
    <div className='w-[100vw] h-[100vh] flex justify-center items-center'>
      <div className='text-center'>
        <img className='w-[200px] text-center h-auto m-auto' src={ReactLogo} />
        <h1 className='mt-10 font-bold'> Unknown Error Encountered</h1>
        <div className='bg-red-500 text-white p-4 rounded-md w-[100%] my-8'>
          <code>{error.message}</code>
        </div>
        <p className='text-lg'>Please try again or reload the page.</p>
        <div className='mt-8 flex justify-center items-center'>
          <Button onClick={resetErrorBoundary} variant='primary'>
            Try Again
          </Button>
          <span className='mx-6'>or</span>
          <Button onClick={() => (window.location.href = '/ui')} variant='grayBlue'>
            Reload Application
          </Button>
        </div>
        <hr className='my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-400 to-transparent opacity-10 dark:opacity-100' />
        <div className='mt-8 flex justify-center'>
          <Button
            icon={copiedErrorMessage ? faCheck : faCopy}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(error.message))
              setCopiedErrorMessage(true)
            }}
            variant={copiedErrorMessage ? 'success' : 'primary'}
          >
            {copiedErrorMessage ? 'Copied To Clipboard!' : 'Copy Error Message'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export { ErrorFallback }