import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { ResourcesTreeView, isAccountResource, useCloudDetailsProvider } from 'features/clouds'
import { getIAMResourceDisplayType } from 'features/iamResources'
import { TargetIcon } from 'procyon-ui'
import React, { useEffect } from 'react'

const WHITELISTED_DISPLAY_TYPES = [
  'Folder',
  'Project',
  'SQL Instance',
  'Organization',
  'Compute Instance'
]

const DisplayTypeToIconMap = {
  Folder: 'GCP_GOOGLE_FOLDER',
  Project: 'GCP_GOOGLE_PROJECT_MONO',
  'SQL Instance': 'DATABASE',
  'Compute Instance': 'GCP_COMPUTE_INSTANCE',
  Organization: 'GCP_ORG'
}

const RESOURCES_DISPLAY_TYPES = ['SQL Instance', 'Compute Instance']

/**
 * This config map handles the collection/grouping of various display types into one.
 * 
 * Eg, All SQL Instances, of same parent will be grouped inside a collection name Databases, etc.
 * Only change this config, when adding more groupings.
 */
const RESOURCES_COLLECTION_MAP_CONFIG = {
  DisplayType: {
    'SQL Instance': {
      label: 'Databases'
    },
    'Compute Instance': {
      label: 'Servers'
    }
  },
  more: {
    iconType: {
      // Icon type per label
      Servers: 'SERVER',
      Databases: 'DATABASE'
    }
  }
}

const GCPResources = () => {
  const { cloud: account } = useCloudDetailsProvider()
  const { slices, getObjectRef, dispatchThunks } = useMultiSlice(['gcpResources', 'accountList'])

  const rsrcs = slices.gcpResources.filter((g) => {
    return isAccountResource(account, g) && WHITELISTED_DISPLAY_TYPES.includes(g.Spec.DisplayType)
  })

  const getNodeIcon = (rsrc) => {
    let iconType = ''

    if (typeof rsrc === 'string')
      return <TargetIcon type={RESOURCES_COLLECTION_MAP_CONFIG.more.iconType[rsrc.split('+')[0]]} />

    if (rsrc.ObjectMeta.Kind === 'Account') iconType = 'GOOGLE_APPLICATION'
    else iconType = DisplayTypeToIconMap[rsrc.Spec.DisplayType]

    // @ts-ignore
    return <TargetIcon type={iconType} />
  }

  const disableNodeClick = (rsrc) => {
    if (RESOURCES_DISPLAY_TYPES.includes(rsrc.Spec.DisplayType)) return true
  }

  const getCustomNodeCollection = (rsrc) => {
    const displayType = getIAMResourceDisplayType(rsrc)
    return RESOURCES_COLLECTION_MAP_CONFIG.DisplayType[displayType]
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <ResourcesTreeView
      rsrcs={rsrcs}
      getObjectRef={getObjectRef}
      getNodeIcon={getNodeIcon}
      disableNodeClick={disableNodeClick}
      getCustomNodeCollection={getCustomNodeCollection}
    />
  )
}

export { GCPResources }
