import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'
import { AppAuthToken, AppNameKey, AppSSOURLKey, createApplication, updateApplication } from 'features/applications'
import _ from 'lodash'
import { Button, Label, TextInput } from 'procyon-ui'
import React, { useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import { useHistory } from 'react-router'

function GithubApplicationForm({ onCancel, onComplete }) {
  const { isEditMode, app } = useApplicationEditContext()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [ssoURL, setSsoURL] = useState(_.get(app, AppSSOURLKey, ''))
  const [metaData, setMetadata] = useState('')
  const [authToken, setAuthToken] = useState('')  
  const [applicationName, setApplicationName] = useState(_.get(app, AppNameKey, ''))

  const { slices } = useMultiSlice(['applicationList'])

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: applicationName } })) {
      return 'Application name is already taken!'
    }
    if (applicationName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!ssoURL) return 'SSO URL must not be empty.'
    if (!authToken) return 'Auth Token must not be empty'
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const data = { applicationName, ssoURL, app, authToken }
      let appResp
      if (isEditMode) {
        appResp = await handleGithubApplicationUpdate(data)
        updateSliceData(app)
      } else {
        appResp = await handleGithubApplicationCreate(data)
        pushToSlice(app)
      }
      enqueueNotification(`Successfully ${isEditMode ? 'Updated' : 'Created'} Github Application!`, 'info')
      onComplete?.() 
      window.location.reload()
      // history.push(`/admin/applications/${encodeURIComponent(appResp.ObjectMeta.Name)}`) 
    } catch (error) {
      enqueueNotification(`Failed to ${isEditMode ? 'Update' : 'Create'} Github Application!`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const formErrors = getErrors()

  return (
    <div>
      <div>
        <div className='mt-4'>
          <TextInput
            disabled={isEditMode}
            value={applicationName}
            onChange={(e) => setApplicationName(e.target.value)}
            sx={{ width: '100%' }}
            label='Application Name'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={ssoURL}
            onChange={(e) => setSsoURL(e.target.value)}
            sx={{ width: '100%' }}
            label='SSO URL'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            disabled
            value={ssoURL === '' ? '' : ssoURL+'/metadata'}
            onChange={(e) => setMetadata(e.target.value)}
            sx={{ width: '100%' }}
            label='Metadata'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={authToken}
            onChange={(e) => setAuthToken(e.target.value)}
            sx={{ width: '100%' }}
            type='password'
            label='Auth Token'
          />
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback caption='Please wait..' loading={isLoading} message='Creating Github Application' />
    </div>
  )
}

const handleGithubApplicationCreate = async ({ applicationName, ssoURL, authToken }) => {
  const data = {
    ObjectMeta: {
      Name: applicationName
    },
    Spec: {
      AppType: 'github',
      SignOnType: 'saml',
      SamlServiceProvider: {
        ServiceProviderACSURL: ssoURL,
        ServiceProviderMetadataURL: ssoURL+'/metadata',
        ServiceProviderMetadata: '',
        ServiceProviderSubjectNameIDFormat: 'transient',
        ServiceProviderAttributes: {
          ServiceProviderAttributes: []
        }
      },
      GithubAppConfig: {
        PersonalAccessToken: authToken
      }
    }
  }
  return await createApplication(data)
}


const handleGithubApplicationUpdate = async ({ app, ssoURL, authToken }) => {
  const data = structuredClone(app)
  _.set(data, AppSSOURLKey, ssoURL)
  _.set(data, AppAuthToken, authToken)
  return await updateApplication(data)
}

export { GithubApplicationForm }
