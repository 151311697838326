import { faSignIn } from '@fortawesome/pro-solid-svg-icons'
import { useUser } from 'Core/Hooks/useUser'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { CopyText, FullScreenContentModal } from 'V2Components'
import { copyProfileName } from 'features/iamResources'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { Button, DropDownButton, ElementRow, Tooltip, Typography } from 'procyon-ui'
import React from 'react'

const BundleSignInModal = ({ data, onCancel }) => {
  const { getObjectRef } = useObjectRef([
    'appRolesList',
    'policyList',
    'azureResources',
    'gcpResources',
    'kubeClusters',
    'kubeNamespaces'
  ])
  const { currentUserIsAdmin } = useUser()
  const getRenderData = () => {
    const items = []

    data.forEach(
      ({
        resourceName,
        k8DefaultCmd,
        k8PolicyCmd,
        resourceRef,
        policyName,
        url,
        signInItems,
        profileName
      }) => {
        const rsrc = getObjectRef(resourceRef)

        if (!rsrc) return

        if (['KubeCluster', 'KubeNamespace'].includes(resourceRef.RefKind)) {
          items.push({
            resourceName,
            Icon: getRsrcIcon(rsrc),
            policyName,
            k8DefaultCmd,
            k8PolicyCmd,
            profileName
          })
          return
        }

        items.push({
          resourceName: getResourceName(rsrc),
          Icon: getRsrcIcon(rsrc),
          policyName,
          signInItems,
          url,
          profileName
        })
      }
    )

    return items
  }

  const renderItems = getRenderData()
  return (
    <FullScreenContentModal>
      <div>
        <div className='flex justify-between items-center mb-4'>
          <Typography variant='h4-regular'>Sign In</Typography>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
        </div>
        <div className='h-[60vh] overflow-auto'>
          {renderItems.map(
            ({ Icon, resourceName, url, k8DefaultCmd, k8PolicyCmd, signInItems, profileName }) => {
              if (k8DefaultCmd)
                return (
                  <div className='border-b py-3' key={resourceName}>
                    {currentUserIsAdmin && (
                      <div className='mb-3' key={k8DefaultCmd}>
                        <CopyText
                          Component={
                            <div>
                              <Typography className='mb-3' variant='body-semiBold'>
                                {resourceName} (default)
                              </Typography>
                              <Tooltip title='Click to Copy' arrow>
                                <div className='p-2 rounded bg-gray-100 cursor-copy'>
                                  <Typography className='!break-all' variant='body-regular'>
                                    {k8DefaultCmd}
                                  </Typography>
                                </div>
                              </Tooltip>
                            </div>
                          }
                          messageKey={'Context Command'}
                          text={k8DefaultCmd}
                        />
                      </div>
                    )}
                    <div key={k8PolicyCmd}>
                      <CopyText
                        Component={
                          <div>
                            <Typography className='mb-3' variant='body-semiBold'>
                              {resourceName} (Policy)
                            </Typography>
                            <Tooltip title='Click to Copy' arrow>
                              <div className='p-2 rounded bg-gray-100 cursor-copy'>
                                <Typography className='!break-all' variant='body-regular'>
                                  {k8PolicyCmd}
                                </Typography>
                              </div>
                            </Tooltip>
                          </div>
                        }
                        messageKey={'Context Command'}
                        text={k8PolicyCmd}
                      />
                    </div>
                  </div>
                )

              return (
                <ElementRow
                  Components={
                    <div>
                      {profileName && (
                        <Button
                          onClick={() => {
                            copyProfileName(profileName)
                          }}
                          variant='gray'
                        >
                          Copy Profile Name
                        </Button>
                      )}
                      {signInItems.length > 0 && (
                        <DropDownButton
                          menuItems={[
                            {
                              action: undefined,
                              submenu: signInItems.map((e) => ({
                                title: e.name,
                                action() {
                                  openURLExternally(e.value)
                                }
                              })),
                              title: 'Sign In'
                            }
                          ]}
                          size='sm'
                          variant='gray'
                        />
                      )}
                    </div>
                  }
                  key={resourceName}
                  buttonIcon={faSignIn}
                  icon={<Icon />}
                  showButton={!!url}
                  onClickButton={() => openURLExternally(url)}
                  title={resourceName}
                />
              )
            }
          )}
        </div>
      </div>
    </FullScreenContentModal>
  )
}

export { BundleSignInModal }
