import useAppView from 'Core/Hooks/useAppView'
import { SearchInput } from 'V2Components'
import { TabGroup, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { ApprovedTab, AutoApproveTab, HistoryTab, PendingTab } from './Components/Tabs'


function ApprovalList() {
  const [searchKey, setSearchKey] = useState('')

  const { appView } = useAppView()
  const getTabs = () => {
    const tabs = [
      {
        label: 'Pending',
        tabContent: (
          <div className='mt-6'>
            <PendingTab searchKey={searchKey} />
          </div>
        )
      },
      {
        label: 'Approved',
        tabContent: (
          <div className='mt-6'>
            <ApprovedTab searchKey={searchKey} />
          </div>
        )
      },
      {
        label: 'History',
        tabContent: (
          <div className='mt-6'>
            <HistoryTab searchKey={searchKey} />
          </div>
        )
      }
    ]

    if (appView === 'admin') {
      tabs.push({
        label: 'Auto Approve',
        tabContent: (
          <div className='mt-6'>
            <AutoApproveTab />
          </div>
        )
      })
    }

    return tabs
  }

  return (
    <div>
      <div className='flex justify-between items-center'>
        <Typography className='!font-bold' variant='h2'>
          Access Request Approvals
        </Typography>
        <SearchInput searchKey={searchKey} sx={{ width: '275px' }} onChange={setSearchKey} />
      </div>
      <div className='mt-8'>
        <TabGroup tabs={getTabs()} />
      </div>
    </div>
  )
}

export { ApprovalList }
