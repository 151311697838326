import { ApiProvider } from 'Core'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { useUser } from 'Core/Hooks/useUser'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import CONSTANTS from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import React from 'react'
import { IAMResourcesManager } from '../IAMResourcesManager'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { convertTZ, createRsrcKey } from 'features/resources'
import moment from 'moment'

function CreateRequestFlow({ selectedRsrc, setSelectedRsrc, serviceAccountType, views, setViews }) {
  const { user } = useUser()
  const { slices } = useMultiSlice(['userList', 'groupList', 'serviceAccounts', 'kubeNamespaces'])

  const handleRequestSubmit = async (data) => {
    const approvalreq = await handleApprovalRequestCreation(data, user)
    pushToSlice(approvalreq)
  }

  const serviceAccountFilter = (serviceAccount) => {
    const loading =
      !serviceAccount?.Status?.GcpStatus?.Email &&
      !serviceAccount?.Status?.AzureStatus?.DisplayName &&
      !serviceAccount?.Status?.AwsStatus?.UserName
    const hasError = serviceAccount?.Status?.Status?.Status === '0'
    return !loading && !hasError
  }

  const getServiceAccounts = () =>
    slices.serviceAccounts.filter(
      (e) => e.Spec.Type === serviceAccountType && serviceAccountFilter(e)
    )

  const getEntities = () => {
    return [user, ...getServiceAccounts()]
  }

  return (
    <IAMResourcesManager
      entities={getEntities()}
      setSelectedRsrc={setSelectedRsrc}
      enableUserGroupSelection={getServiceAccounts().length > 0}
      submissionTitle='Access Request'
      views={views}
      onViewsChange={setViews}
      selectedRsrc={selectedRsrc}
      onRequestSubmission={handleRequestSubmit}
    />
  )
}

async function handleApprovalRequestCreation(data = {}, user) {
  const KubeNampeSpaceActionMap = {
    AWS: 'AwsIAM',
    GCP: 'GcpIAM',
    AZURE: 'AzureIAM'
  }

  const { spec, resources, entities, resourcesMetaObj } = data

  const api = new ApiProvider('approvalreqs')
  const reqObject = {
    ObjectMeta: {},
    Spec: {
      Justification: spec.comments,
      Resources: {
        Resource: []
      },
      RequestedFor: {
        ObjectRef: entities.length //If user has selected some refs then use them, else use the currently logged in user
          ? entities
          : [
              {
                RefKind: 'User',
                RefID: user.ObjectMeta.ID
              }
            ]
      },
      NotBefore: moment(convertTZ(spec.startDate, spec.timeZone)).utc(),
      NotAfter: moment(convertTZ(spec.endDate, spec.timeZone)).utc(),
    },
    Status: 'New',
    GivenName: spec.name,
    Type: CONSTANTS.policyTypes.IAMAction
  }

  resources.forEach((rsrc) => {
    const Roles = resourcesMetaObj[createRsrcKey(rsrc)]?.Roles || []
    const resourceObj = getObjectRefFlat(rsrc)
    const resourceToActionMap = {
      AwsResource: 'AwsIAM',
      GcpResource: 'GcpIAM',
      AzureResource: 'AzureIAM',
      KubeNamespace: KubeNampeSpaceActionMap[resourceObj?.Spec?.Type]
    }

    Roles.forEach((roleRef) => {
      reqObject.Spec.Resources.Resource.push({
        Target: {
          RefKind: 'IamAction',
          RefID: roleRef.RefID
        },
        Action: resourceToActionMap[rsrc.RefKind],
        Principal: '',
        Services: {
          ObjectRef: [rsrc]
        }
      })
    })
  })
  const response = await api.setInstance(reqObject).post()
  enqueueNotification('Access Request has been successfully submitted!')
  return response.data
}

export { CreateRequestFlow }
