import {
  faBadgeCheck,
  faChain,
  faClipboardCheck,
  faDownload,
  faFileCheck
} from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { markAsAuditLog, parseLogSessionData } from 'features/sessions'
import moment from 'moment'
import { Button, IconButton, Label, TabGroup, Tooltip, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { CommandsRecordTab } from './components/CommandsRecordTab'
import { SessionInfoTab } from './components/SessionInfoTab'
import { RDPRecordingTab } from './components/RDPRecordingTab'

const LogInfoModal = ({ log, onCancel, onAuditSuccess }) => {
  const [isAuditing, setIsAuditing] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [isLogAudited, setIsLogAudited] = useState(log.Audited)
  const sessionData = parseLogSessionData(log)
  const firstData = sessionData[0]

  const { appView } = useAppView()
  const { user } = useUser()

  const handleLinkCopyClick = () => {
    try {
      const host = location.protocol + '//' + location.host
      navigator.clipboard.writeText(
        `${host}/ui/${appView}/session-logs/${firstData.SessionID?.trim()}?sessionTime=${
          firstData.AuditTime
        }&resourceType=${firstData.Type}`
      )
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1500)
    } catch (error) {}
  }

  const getTabs = () => {
    const tabs = [
      {
        label: 'Session Information',
        tabContent: (
          <div className='h-[500px]'>
            <SessionInfoTab log={log} />
          </div>
        )
      }
    ]

    if (firstData.Type === 'RDP') {
      tabs.push({
        label: 'Recording',
        tabContent: (
          <div>
            <RDPRecordingTab log={log} />
          </div>
        )
      })
    } else {
      tabs.push({
        label: 'Commands Record',
        tabContent: (
          <div>
            <CommandsRecordTab log={log} />
          </div>
        )
      })
    }

    return tabs
  }

  const handleMarkAudit = async () => {
    try {
      setIsAuditing(true)
      await markAsAuditLog([log.SessionKey], user)
      enqueueNotification('Session marked as audited successfully!', 'info')
      setIsLogAudited(true)
      onAuditSuccess(log.SessionKey)
    } catch (error) {
      enqueueNotification('Error while auditing the log.', 'error')
    }
    setIsAuditing(false)
  }

  return (
    <FullScreenContentModal width='800px'>
      {sessionData && (
        <div>
          <div className='flex justify-between items-center'>
            <Typography variant='h4-regular'>
              {moment(firstData.AuditTime).format('MMMM Do YYYY, h:mm A')}
            </Typography>
            <div className='flex mt-4 gap-4'>
              {isLogAudited ? (
                <Label variant='success' iconButton={faBadgeCheck} text='Audited' />
              ) : (
                <Button onClick={handleMarkAudit} variant='grayBlue' icon={faFileCheck}>
                  Mark As Audited
                </Button>
              )}

              <Tooltip title={isCopied ? 'Copied to clipboard!' : 'Click to Copy Log Link'} arrow>
                <IconButton
                  className='!w-[50px]'
                  onClick={handleLinkCopyClick}
                  variant={isCopied ? 'grayBlue' : 'gray'}
                  icon={isCopied ? faClipboardCheck : faChain}
                />
              </Tooltip>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
            </div>
          </div>
          <div>
            <TabGroup tabs={getTabs()} />
          </div>
        </div>
      )}
      <LoadingFeedback
        loading={isAuditing}
        message='Marking session as Audited'
        caption='Please wait..'
      />
    </FullScreenContentModal>
  )
}

export { LogInfoModal }
