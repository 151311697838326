
import { Typography } from '@material-ui/core'
import Count from 'Components/AnalyticTable/components/Count'
import TableHeader from 'Components/AnalyticTable/components/TableHeader'
import LinearDistribution from 'Components/LinearDistribution'

export const options = (onSelect) => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: 'none',
  onRowClick: onSelect,
  rowsPerPageOptions: [5, 10, 15, 100],
  setRowProps: row => {
    if (row[1]) {
      return {
        style: {
          backgroundColor: 'rgba(0,0,0,0.04)'
        }
      }
    }
  }
})

export const columns = (headerClassNames = {}) => [
  {
    name: 'userName',
    label: ' ',
    options: {
      filter: false,
      display: false
    }
  },
  {
    name: 'selected',
    label: ' ',
    options: {
      filter: false,
      display: false
    }
  },
  {
    name: 'index',
    label: ' ',
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => {
        return <Count count={value} />
      }
    }
  },

  {
    name: 'name',
    label: 'Activity Last 30 Days',
    options: {
      filter: false,
      sort: true,
      setCellProps: (value) => ({ style: { paddingLeft: '0.5rem' } }),
      customHeadLabelRender: (columnMeta) => <TableHeader pr={2} pl={0} variant='body2' color='textSecondary' columnMeta={columnMeta} />,
      customBodyRender: (value, tableMeta) => {
        return <Typography variant='body2' color='primary'>{value} </Typography>
      }
    }
  },
  {
    name: 'sessions',
    label: 'Sessions',
    options: {
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      customBodyRender: (value, tableMeta) => {
        return <Count count={value} />
      },
      filter: true,
      sort: true

    }
  },
  {
    name: 'activityDistribution',
    label: 'Activity distribution',
    options: {
      setCellProps: () => ({ style: { verticalAlign: 'middle', textAlign: 'right', paddingRight: 0, paddingLeft: '2rem' } }),
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <LinearDistribution distributions={value} widthPercentage='100%' />
      }
    }
  }
]
