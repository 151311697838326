import React, { useLayoutEffect, useRef } from 'react'
import { randomStringGenerator } from 'Utils/PureHelperFuctions'
import * as am4charts from '@amcharts/amcharts4/charts'
import { am4core, PropTypes, Theme, _ } from 'Core'
const MultiLineChart = ({ data, seriesMeta, xAxis, yAxis, zoomAxis, chartStyles, chartClassName }) => {
  /** Each chart must have a div unique id */
  const divId = useRef(randomStringGenerator(16))
  const chart = useRef(null)
  /** Create category axis */
  const createXAxis = (chart) => {
    const axis = new am4charts.CategoryAxis()
    const categoryAxis = chart.xAxes.push(axis)
    categoryAxis.dataFields.category = xAxis.name
    /** Grid styles */
    categoryAxis.renderer.grid.template.strokeWidth = 0
    /** Label Styles */
    categoryAxis.renderer.labels.template.dy = 10
    categoryAxis.renderer.labels.template.dx = 20
    categoryAxis.tooltip.dx = 20
    categoryAxis.tooltip.dy = 30
    categoryAxis.renderer.labels.template.stroke = '#1f263e'
    categoryAxis.renderer.labels.template.strokeWidth = '0.1'
    categoryAxis.renderer.labels.template.fontSize = '11px'
  }

  /** Create value axis  */
  const createYAxis = (chart) => {
    const axis = new am4charts.ValueAxis()
    const valueAxis = chart.yAxes.push(axis)
    valueAxis.title.text = yAxis.label
    /** Label Styles */
    valueAxis.renderer.inside = true
    // valueAxis.renderer.minLabelPosition = 0.1
    valueAxis.renderer.labels.template.fill = Theme.palette.text.disabled
    valueAxis.renderer.labels.template.fontSize = '12px'
    valueAxis.renderer.labels.template.align = 'left'
    valueAxis.renderer.labels.template.dx = -10
    /** Grid styles */
    valueAxis.renderer.grid.template.dy = 10
    valueAxis.renderer.baseGrid.dy = 10
    valueAxis.renderer.grid.template.stroke = Theme.palette.divider
  }

  /** Pushes new series with bullets to chart */
  const createSeries = (chart, meta) => {
    /** Plot the series only if its active */
    if (meta.active) {
      // Setup Chart Lines
      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = meta.name
      series.dataFields.categoryX = xAxis.name
      series.dx = 20
      series.name = meta.label
      series.stroke = meta.color
      // Setup Chart Bullets
      const bullet = new am4charts.CircleBullet()
      // @ts-ignore
      bullet.fill = Theme.palette.background.paper
      bullet.dx = 20
      series.bullets.push(bullet)
      // Setup tooltip
      series.tooltipText = meta.hoverText
      // Setup hover state
      const hoverState = series.segments.template.states.create('hover')
      hoverState.properties.strokeWidth = 5
      series.segments.template.strokeWidth = 1
    }
  }

  /** Setup Chart Cursor */
  const setuChartCursor = (chart) => {
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.x = 20
    chart.cursor.behavior = zoomAxis
  }

  /**
   * Initate chart on data change
   * Handle creation of axises and serises
  **/
  const initChart = () => {
    console.log('re-renders multiline initiated')
    /** create chart instance */
    chart.current = am4core.create(divId.current, am4charts.XYChart)

    /** Add data to chart */
    chart.current.data = data

    /** setup chart.current Axises */
    createXAxis(chart.current)
    createYAxis(chart.current)

    /** Generate lines & bullets for each series */
    seriesMeta.forEach(meta => createSeries(chart.current, meta))

    setuChartCursor(chart.current)
  }
  /** reset chart on data change */
  useLayoutEffect(() => {
    initChart()
    return () => {
      if (chart.current) { chart.current.dispose() }
    }
  }, [data, seriesMeta, xAxis, yAxis])
  return (
    <div className={chartClassName} style={chartStyles} id={divId.current} />
  )
}
/**  Reusable type for meta and axises props */
const axisType = { name: PropTypes.string, label: PropTypes.string }
const metaType = {
  ...axisType,
  hoverText: PropTypes.string,
  color: PropTypes.string
}
/** Setup propTypes */
MultiLineChart.propTypes = {
  xAxis: PropTypes.shape(axisType),
  yAxis: PropTypes.shape(axisType),
  zoomAxis: PropTypes.string,
  data: PropTypes.array,
  seriesMeta: PropTypes.arrayOf(PropTypes.shape(metaType)),
  chartClassName: PropTypes.string,
  chartStyles: PropTypes.object
}
MultiLineChart.defaultProps = {
  zoomAxis: 'zoomX'
}
export default React.memo(MultiLineChart, _.isEqual)
