import { faCloud } from '@fortawesome/pro-regular-svg-icons'
import {
  faBrowser,
  faGrid,
  faLaptopMobile,
  faLayerGroup,
  faUser,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { TargetsSliceNames } from 'features/targets'
import { createDataSelectorHook, MapSliceNameToKind } from 'infra/redux'
import { CloudIcon, CountersCard } from 'procyon-ui'
import React from 'react'

const useSlices = createDataSelectorHook([
  'accountList',
  'deviceList',
  'applicationList',
  'userList',
  'groupList'
])

const CountCards = () => {
  const { slices } = useSlices()

  const getCounts = () => {
    const counts = {
      gcp: 0,
      aws: 0,
      azure: 0,
      targets: 0,
      resources: 0,
      devices: slices.deviceList.length,
      users: slices.userList.length,
      groups: slices.groupList.length,
      applications: slices.applicationList.length
    }

    slices.accountList.forEach((acc) => {
      const type = acc.Spec.Type.toLowerCase()
      const countsMap = acc.Status.ResourcesInfo.Map

      const targetsCount = TargetsSliceNames.reduce((prev, sname) => {
        const kind = MapSliceNameToKind[sname]
        return prev + (+countsMap[kind] || 0)
      }, 0)

      const resourcesCount = IAMResourcesSliceNames.reduce((prev, sname) => {
        const kind = MapSliceNameToKind[sname]
        return prev + (+countsMap[kind] || 0)
      }, 0)

      counts.targets += targetsCount
      counts.resources += resourcesCount

      counts[type] = counts[type] + 1
    })

    return counts
  }

  const counts = getCounts()

  const getCountData = () => {
    const countElements = [
      {
        title: 'Accounts',
        icon: <FontAwesomeIcon color='#545b71' icon={faCloud} />,
        items: [
          {
            count: counts.aws,
            element: <CloudIcon type='aws' />
          },
          {
            count: counts.gcp,
            element: <CloudIcon type='gcp' />
          },
          {
            count: counts.azure,
            element: <CloudIcon type='azure' />
          }
        ]
      },
      {
        title: 'Users',
        icon: <FontAwesomeIcon color='#545b71' icon={faUsers} />,
        items: [
          {
            count: counts.users,
            element: <FontAwesomeIcon color='#545B71' icon={faUser} />
          },
          {
            count: counts.groups,
            element: <FontAwesomeIcon color='#545B71' icon={faUsers} />
          },
          {
            count: counts.devices,
            element: <FontAwesomeIcon color='#545B71' icon={faLaptopMobile} />
          }
        ]
      },
      {
        title: 'Resources',
        icon: <FontAwesomeIcon color='#545b71' icon={faBrowser} />,
        items: [
          {
            count: counts.targets,
            element: <FontAwesomeIcon color='#545B71' icon={faLayerGroup} />
          },
          {
            count: counts.resources,
            element: <FontAwesomeIcon color='#545B71' icon={faBrowser} />
          },
          {
            count: counts.applications,
            element: <FontAwesomeIcon color='#545B71' icon={faGrid} />
          }
        ]
      }
    ]

    return countElements
  }

  const countsElements = getCountData()

  return (
    <div className='flex gap-8 mb-6'>
      {countsElements.map((e, i) => {
        return (
          <CountersCard
            key={i}
            style={{ width: '33%' }}
            className='!w-1/3'
            counterItems={e.items}
            icon={e.icon}
            onClick={function noRefCheck() {}}
            title={e.title}
            type='simple'
          />
        )
      })}
    </div>
  )
}

export { CountCards }
