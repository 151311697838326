import _ from 'lodash'
import { ResponsiveTable } from 'procyon-ui'
import React from 'react'
import { columns, useSessionLogsTable } from './SessionLogsTable.utils'

const SessionLogsTable = ({ sessions = [], onLogClick, searchKey }) => {
  const { rows } = useSessionLogsTable({ sessions, searchKey })

  return (
    <div>
      <ResponsiveTable
        onRow={(data) => {
          return {
            onClick() {
              const SessionKey = data.id
              const log = _.find(sessions, { SessionKey })
              if (!log) return
              onLogClick(log)
            }
          }
        }}
        columns={columns()}
        data={rows}
        scrollX='1200px'
      />
    </div>
  )
}

export { SessionLogsTable }
