import { useUser } from 'Core/Hooks/useUser'
import { FullScreenContentModal } from 'V2Components'
import { Button, Label, LoadingSpinner, TargetIcon, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const Slack = ({ onInputChange, onContinue, message, handlePaste, onCancel }) => {
  const [webhookUrl, setWebhookUrl] = useState('')
  const { controller } = useUser()

  const handleBotRedirection = () => {
    if (controller === 'staging.proxyon.cloud') {
      window.open(
        'https://slack.com/oauth/v2/authorize?client_id=2969919076628.2964848690821&scope=channels:read,chat:write,commands,groups:read,mpim:read&user_scope=',
        '_blank'
      )
    } else {
      window.open(
        'https://slack.com/oauth/v2/authorize?client_id=2969919076628.3869369921633&scope=channels:read,chat:write,commands,groups:read,mpim:read&user_scope=',
        '_blank'
      )
    }
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center'>
        <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
          <TargetIcon
            width={'48px'}
            height={'48px'}
            type={'SLACK'}
            className='rounded'
            style={{
              borderRadius: '12px',
              marginRight: '15px'
            }}
          />
          Setup Slack Integration
        </Typography>
        <div>
          <Button onClick={() => handleBotRedirection()}>Integrate BOT</Button>
        </div>
      </div>
      {!message ? (
        <>
          <div className='p-4 mt-2 rounded text-black max-h-[60vh] w-[100%]'>
            <TextInput
              label='Integration Name'
              onChange={(e) => onInputChange(e.target.value, 'name')}
              placeholder='Please enter name'
              style={{
                width: '550px'
              }}
            />

            <TextInput
              label='WebHook Url'
              onPaste={(e) => handlePaste(e)}
              onChange={(e) => {
                onInputChange(e.target.value, 'webhookUrl')
                setWebhookUrl(e.target.value)
              }}
              placeholder='URL'
              sx={{ width: '100%', marginTop: '8px' }}
            />
          </div>

          <div className='flex justify-end mt-4 gap-4'>
            <Button size='md' onClick={onCancel} variant='gray'>
              Cancel
            </Button>
            <Button disabled={!webhookUrl.length} size='md' onClick={onContinue} variant='primary'>
              Continue
            </Button>
          </div>
        </>
      ) : (
        <div className='flex items-center bg-[#f2f4fb] p-5 mt-5'>
          <div className='mt-2'>
            <LoadingSpinner size={20} thickness={4} />
          </div>
          <Label text={message} variant='grayBlue' />
        </div>
      )}
    </FullScreenContentModal>
  )
}

export { Slack }
