// @ts-ignore
import { API, FeatureFlagsObject } from 'Core'
import SITE_CONFIG from 'Core/Config'
import { getAppViewPreferenceName, getLastViewForApp } from 'Utils/AuthHelpers'
import { userpreferencesSlice } from 'infra/redux/reducers'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const FeatureFlagContext = React.createContext(null)

export const FeatureFlagProvider = (props) => {
  const [appReady, setAppReady] = useState(false)
  const initiated = useRef(false)
  const { publicUrl } = SITE_CONFIG
  const dispatch = useDispatch()
  const urlPrefix = publicUrl || ''
  // @ts-ignore
  const user = useSelector((state) => state.user)
  // @ts-ignore
  const userpreferences = useSelector((state) => {
    //@ts-ignore
    return Object.values(state.userpreferences.map)
  })

  // @ts-ignore
  const history = useHistory()
  const [contextValue, setContextValue] = useState({
    featureFlags: {},
    viewMode: 'user',
    loading: true
  })
  /**
   * Redirects user to home page of next view
   */
  const redirectToView = (prefferedAppView) => {
    const redirectURL = prefferedAppView === 'admin' ? '/admin/' : '/user/'
    history.push(redirectURL)
  }
  /**
   * Runs when user is logged in
   * Checks prefferedAppView and role to select view mode.
   */
  const setRoleBasedPermissions = async () => {
    if (initiated.current) return
    initiated.current = true
    /** Check if url has desired app view mentioned in it */
    const currentUrl = window.location.pathname.toLowerCase()
    const isAdminRouteActive = currentUrl.startsWith(urlPrefix + '/admin')
    const isUserRouteActive = currentUrl.startsWith(urlPrefix + '/user')

    /** Preffered application view based on last session and role type */
    let prefferedAppView = getLastViewForApp(userpreferences, user)

    /** Override view mode via URL only if user is admin */
    if (user.role === 'admin') {
      if (isUserRouteActive) {
        prefferedAppView = 'user'
      } else if (isAdminRouteActive) {
        prefferedAppView = 'admin'
      } else {
        /**
         * Neighter /admin nor /user is mentioned in the URL
         * Redirect user to 'LastViewForApp' or /admin in absense of 'LastViewForApp'
         */
        redirectToView(prefferedAppView)
      }
      /** Save new app view mode to server */
      const preferenceName = getAppViewPreferenceName(user)
      API.setUserPreferrences(preferenceName, userpreferences, {
        LastViewForApp: prefferedAppView
      })
      /** Fetch and update UserPreferrences in Redux */
      dispatch(userpreferencesSlice.thunk())
    }

    /** Handle user not being an admin and not currently being on a 'userview' route */
    if (user.role === 'user' && !isUserRouteActive) {
      /** Redirect user to '/user' */
      redirectToView(prefferedAppView)
    }

    /** Get new set of permissions based on view mode */
    const featureFlags = FeatureFlagsObject.updatePermissionsFor(prefferedAppView)
    setContextValue((s) => ({ ...s, featureFlags, viewMode: prefferedAppView, loading: false }))
    setAppReady(true)
  }

  /**
   * Updates feature flags dynamically from any component.
   * Redirects user to home page of next view
   */
  const updateContext = async (prefferedAppView) => {
    /** Get new set of permissions based on view mode */
    const newFeatureFlags = FeatureFlagsObject.updatePermissionsFor(prefferedAppView)
    /** Save new app view mode to server */
    const preferenceName = getAppViewPreferenceName(user)
    setContextValue((s) => ({
      ...s,
      featureFlags: newFeatureFlags,
      viewMode: prefferedAppView,
      loading: false
    }))
    API.setUserPreferrences(preferenceName, userpreferences, {
      LastViewForApp: prefferedAppView
    }).then(() => {
      /** Fetch and update UserPreferrences in Redux */
      dispatch(userpreferencesSlice.thunk())
    })
    redirectToView(prefferedAppView)
  }

  if (user && user.loggedIn && !initiated.current) setRoleBasedPermissions()

  return (
    <FeatureFlagContext.Provider value={{ data: contextValue, updateContext, appReady }}>
      {!appReady && props.Fallback}
      {appReady && props.children}
    </FeatureFlagContext.Provider>
  )
}
