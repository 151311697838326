import React, { useCallback } from 'react'
import { FileCopy } from '@material-ui/icons'
import { CustomTypography } from 'Components/CustomTypography'
import { makeStyles } from '@material-ui/core'
import ThemeButton from 'Components/ThemeButton'
import { enqueueNotification } from 'Utils/Helpers'
const useStyle = makeStyles(theme => ({
  icon: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    fontSize: theme.spacing(1),
    objectFit: 'contain',
    marginRight: theme.spacing(0.5)

  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
const CopyButton = ({ copyText, successNotificationText = 'Copied!', label = 'Copy', showIcon = true }) => {
  const classes = useStyle()
  const handleCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(copyText)
      enqueueNotification(successNotificationText, 'info')
    } catch (error) {
      console.error('error in handleCopy: ', error)
    }
  }, [copyText])
  return (
    <ThemeButton disabled={!copyText} className={classes.button} onClick={handleCopy}>
      {showIcon && <FileCopy className={classes.icon} />}
      <CustomTypography.Body> {label}</CustomTypography.Body>
    </ThemeButton>
  )
}

export default CopyButton
