import { Box, Grid, makeStyles } from '@material-ui/core'
import { ExitToAppSharp } from '@material-ui/icons'
import { DetailsContextProvider } from 'Components/DetailsContext'
import useDetails from 'Components/DetailsContext/hook'
import FallBack from 'Components/Fallback'
import ResourceDetailsHeader from 'Components/ResoureDetails/Header'
import ResourceDetailsListItem from 'Components/ResoureDetails/ListItem'
import ThemeButton from 'Components/ThemeButton'
import { ApiProvider } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { iamActionsSlice } from 'infra/redux/reducers'
import CONSTANTS from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import { getIamActionName } from 'Utils/PureHelperFuctions'
import NotFound from 'Views/NotFound'
import ActionCard from './ActionCard'

const useStyles = makeStyles((theme) => ({
  root: {},
  pageContainer: {
    display: 'flex'
  },
  actionsLabel: {
    marginTop: theme.spacing(0.65)
  }
}))

const IAMActionDetails = () => {
  const classes = useStyles()
  const history = useHistory()
  const { viewMode } = useAppView()
  // @ts-ignore
  const { name } = useParams()
  const { entity: selectedIAMAction, fetching } = useDetails()
  // const permissions = usePermissions({ resource: selectedIAMAction })

  const { slices, dispatchThunks } = useMultiSlice(['awsCloudActions', 'gcpCloudActions', 'azureCloudActions'])

  const [deleteLoading, setDeleteLoading] = useState(false)

  const onInit = useCallback(async () => {
    try {
      dispatchThunks()
    } catch (error) {
      console.error(error)
    }
  }, [dispatchThunks])

  useEffect(() => {
    onInit()
  }, [name])

  const backURL = useMemo(() => ({
    title: 'IAM Role',
    link: '/admin/iam-actions/aws'
  }), [])

  const handleDelete = useCallback(async () => {
    try {
      if (selectedIAMAction) {
        setDeleteLoading(true)
        await new ApiProvider('iamactions')
          .setInstance(selectedIAMAction)
          .delete()
        enqueueNotification('IAM Action deleted successfully', 'info')
        setDeleteLoading(false)
        history.push('/admin/iam-actions/aws')
      }
    } catch (error) {
      setDeleteLoading(false)
      enqueueNotification('Operation Failed', 'error')
    }
  }, [selectedIAMAction, setDeleteLoading])

  const loading = (fetching) && (!selectedIAMAction)
  const canShowNotFound = (!fetching) && (!selectedIAMAction)

  const onEdit = () => {
    history.push('/admin/iam-action/' + name + '/edit')
  }
  const handleAccessRequest = () => {
    console.log('on access request')
  }
  const isCustom = selectedIAMAction && selectedIAMAction.Spec.State !== 'managed'
  const ActionButtons = () => (
    <Box display='flex'>
      {viewMode === 'admin' && isCustom &&
        <Box mr={1}>
          <ThemeButton size='small' loading={deleteLoading} theme='error' onClick={handleDelete}>Delete</ThemeButton>
        </Box>}
      {viewMode === 'admin' && isCustom &&
        <Box mr={1}>
          <ThemeButton size='small' onClick={onEdit}>Edit</ThemeButton>
        </Box>}
      {/* <ThemeButton size='small' onClick={handleAccessRequest}><ExitToAppSharp /> New Access Request</ThemeButton> */}
    </Box>
  )
  const actions = useMemo(() => {
    if (!selectedIAMAction) return []
    const serviceActions = {}
    let WhiteListedServiceAction = []
    if (selectedIAMAction.Spec.CloudType === 'GCP') {
      WhiteListedServiceAction = slices.gcpCloudActions
    } if (selectedIAMAction.Spec.CloudType === 'AZURE') {
      WhiteListedServiceAction = slices.azureCloudActions
    } else if (selectedIAMAction.Spec.CloudType === 'AWS') {
      WhiteListedServiceAction = slices.awsCloudActions.filter(e => CONSTANTS.WHITELISTED_CLOUD_SERVICES.includes(e.Service))
    }
    selectedIAMAction.Spec.Actions.Elems.forEach(action => {
      const serviceActionType = WhiteListedServiceAction.find(serviceAction => serviceAction.Actions.Elems.includes(action))
      if (serviceActionType) {
        if (serviceActions[serviceActionType.Service]) {
          serviceActions[serviceActionType.Service].Actions.push(action)
        } else {
          serviceActions[serviceActionType.Service] = {
            Actions: [action],
            Service: serviceActionType.Service
          }
        }
      }
    })
    return Object.values(serviceActions)
  }, [slices, selectedIAMAction])
  return (
    <div className={classes.root}>
      {(loading) && <FallBack />}

      {(selectedIAMAction) && (
        <ResourceDetailsHeader
          switchLabel={false}
          type={selectedIAMAction?.ObjectMeta?.Kind}
          CustomRender={ActionButtons}
          name={getIamActionName(selectedIAMAction)}
          backURL={backURL}
        />
      )}
      {(selectedIAMAction) && (
        <Box mt={1.5}>
          <Grid container>
            <Grid item xs={6}>
              <Box>
                <ResourceDetailsListItem
                  label='Name'
                  classes={{ root: classes.root }}
                  body={getIamActionName(selectedIAMAction)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pl={1}>
                <ResourceDetailsListItem
                  labelSize={3}
                  label='Description'
                  classes={{ root: classes.root }}
                  body={selectedIAMAction?.Spec.Description || 'N/A'}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={1}>
                <ResourceDetailsListItem
                  bodySize={10} labelSize={2} label='Actions'
                  classes={{ root: classes.root, labelContainer: classes.actionsLabel }}
                  body={(
                    <Box>
                      {actions.map(({ Service, Actions }) =>
                        <ActionCard key={Service} service={Service} actions={Actions} />)}
                    </Box>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          {/* {JSON.stringify(selectedIAMAction)} */}
        </Box>
      )}
      {(canShowNotFound) && <NotFound />}

    </div>
  )
}
const IAMActionDetailsWithContext = () => {
  return (
    <DetailsContextProvider thunk={iamActionsSlice.thunk} storeName='iamActions' endpoint='iamactions' pathParameterName='name'>
      <IAMActionDetails />
    </DetailsContextProvider>
  )
}
export default IAMActionDetailsWithContext
