import { faDatabase, faTrash } from '@fortawesome/pro-solid-svg-icons'
import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core'
import { ApiProvider } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'
import { enqueueNotification, getAccountIcon } from 'Utils/Helpers'
import { FullScreenAlertModal, LabelContent, LoadingFeedback } from 'V2Components'
import { ErrorLabelContent, getResourceName } from 'features/resources'
import _ from 'lodash'
import { Button, IconButton, Label, SwitchBox, TextInput } from 'procyon-ui'
import React, { useState } from 'react'
import { MeshClusterLabelContent } from '../MeshClusterLabelContent'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '74%'
  },
  selectOptions: {
    '& .MuiListItem-root': {
      borderTop: '1px solid rgb(3,15,252)',
      borderRadius: 8
    },

    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#f1f4fb',
      margin: '3px 0'
    },
    '& .MuiCheckbox-root': {
      color: '#1976d2'
    },
    '& .MuiCheckbox-colorSecondary': {
      '&.Mui-checked': {
        color: '#1976d2 !important'
      }
    }
  },
  missingPassword: {
    border: '2px solid red'
  }
}))

const Database = ({ rsrc, account }) => {
  const classes = useStyles()
  const [saving, setSaving] = useState('')
  const { selectDispatch } = useMultiDispatch([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRowForDelete, setSelectedRowForDelete] = useState()
  const [selectedItems, setSelectedItems] = useState(
    Object.keys(rsrc?.Spec?.BuiltInUsers?.Map).filter((key) => rsrc?.Spec?.BuiltInUsers?.Map[key])
  )

  const { appView } = useAppView()
  const credentialsData = rsrc?.Spec?.Credentials?.Map
  const iamUsersData = rsrc?.Spec?.IAMUsers?.Elems

  // Create an array of initial rows
  const initialRows = Object.keys(credentialsData).map((username) => ({
    Username: username, // Use the username if available, otherwise an empty string
    Password: credentialsData[username], // Use the password if available, otherwise an empty string
    isAuth: true,
    disableInput: true
  }))

  // If there are no keys in credentialsData, add a default empty row
  if (Object.keys(credentialsData).length === 0 && iamUsersData.length === 0) {
    initialRows.push({
      Username: '', // Default empty username
      Password: '', // Default empty password
      isAuth: true, // Set isAuth to true for the default input fields
      disableInput: false
    })
  }

  // If there is data in IAMUsers, add those users to the initial rows
  if (iamUsersData.length > 0) {
    iamUsersData.forEach((username) => {
      initialRows.push({
        Username: username,
        Password: '',
        isAuth: false,
        disableInput: true
      })
    })
  }

  // Set the initial state
  const [dbRow, setDBrow] = useState(initialRows)
  const [rowData, setRowData] = useState(initialRows)
  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))

  const handleSaveAllRows = async () => {
    const allRowData = rowData.map((row) => {
      return {
        Username: row.Username,
        Password: row.Password,
        isAuth: row.isAuth
      }
    })

    // check if user alredy exist
    const modifiedData = allRowData.map(({ isAuth, Username, Password }) => {
      if (credentialsData.hasOwnProperty(Username) && credentialsData[Username] !== Password) {
        // Password has been updated for an existing user
        return { Username, Password, isAuth, updated: true }
      } else {
        // Either a new user or the password remains unchanged
        return { Username, Password, isAuth, updated: false }
      }
    })

    const iamUsersList = modifiedData
      .filter((item) => !item.isAuth && item.Username !== '')
      .map((user) => user.Username)

    const credentialsArray = modifiedData.filter((item) => item.isAuth && item.Username !== '')

    const credentialsObject = credentialsArray.reduce((acc, item) => {
      acc[item.Username] = item.Password
      return acc
    }, {})

    try {
      const dataObj = structuredClone(rsrc)
      if (iamUsersList.length > 0) {
        dataObj.Spec.IAMUsers.Elems = iamUsersList
      } else {
        dataObj.Spec.IAMUsers.Elems = []
      }
      if (credentialsArray.length > 0) {
        dataObj.Spec.Credentials.Map = credentialsObject
      } else {
        dataObj.Spec.Credentials.Map = {}
      }

      await new ApiProvider('databases').setInstance(dataObj).put()
      enqueueNotification(`Database saved successfully!`, 'info')
      selectDispatch(['databases'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  const handleAddDBRow = () => {
    const newRow = {
      id: Math.floor(Math.random() * 10000),
      Username: '',
      Password: '',
      isAuth: true,
      disableInput: false
    }

    setDBrow((prevRows) => [...prevRows, newRow])

    // Update the rowData to keep track of the new row
    setRowData((prevData) => [...prevData, newRow])
  }

  const handleDbRowInputChange = (fieldName, rowIndex, value) => {
    if (rowIndex >= 0 && rowIndex < rowData.length) {
      const updatedRowData = [...rowData]
      updatedRowData[rowIndex][fieldName] = value
      setRowData(updatedRowData)
    }
  }

  const handleToggleChange = (rowIndex) => {
    if (rowIndex >= 0 && rowIndex < rowData.length) {
      const updatedRowData = [...rowData]
      updatedRowData[rowIndex].isAuth = !updatedRowData[rowIndex]?.isAuth
      setRowData(updatedRowData)
    }
  }

  const handleDeletRow = (rowIndex) => {
    setShowDeleteModal(true)
    setSelectedRowForDelete(rowIndex)
  }

  const handleDeletDBRow = (rowIndex) => {
    // Ensure that rowIndex is within a valid range
    if (rowIndex >= 0 && rowIndex < dbRow.length) {
      const updatedDBRow = dbRow.filter((row, index) => index !== rowIndex)
      setDBrow(updatedDBRow)

      // Update the rowData state to keep it in sync
      const updatedRowData = rowData.filter((row, index) => index !== rowIndex)
      setRowData(updatedRowData)
      setShowDeleteModal(false)
    }
  }

  const handleChange = (event) => {
    setSelectedItems(event.target.value)
  }

  const handleUserSave = async () => {
    const updateBuiltInUsers = (array, builtInUsers) => {
      if (!Array.isArray(array) || typeof builtInUsers !== 'object') {
        return builtInUsers // Return the original object if the input is not as expected
      }

      const updatedMap = { ...builtInUsers.Map } // Create a new map

      for (const key in updatedMap) {
        if (updatedMap.hasOwnProperty(key)) {
          // Check if the user is selected (in selectedItems) and set its value accordingly
          updatedMap[key] = array.includes(key)
        }
      }

      const updatedBuiltInUsers = { ...builtInUsers, Map: updatedMap } // Create a new BuiltInUsers object

      return updatedBuiltInUsers
    }

    const updatedBuiltInUsers = updateBuiltInUsers(selectedItems, rsrc.Spec.BuiltInUsers)

    try {
      const dataObj = structuredClone(rsrc)
      dataObj.Spec.BuiltInUsers = updatedBuiltInUsers
      await new ApiProvider('databases').setInstance(dataObj).put()
      enqueueNotification(`Added Procyon Users Successfully!`, 'info')
      selectDispatch(['databases'])
    } catch (error) {
    } finally {
      setSaving('')
    }
  }

  if (!rsrc) return null

  return (
    <>
      <LoadingFeedback
        loading={saving}
        caption='Please wait.'
        message={`Updating Database ${saving}`}
      />
      <ErrorLabelContent rsrc={rsrc} />

      <LabelContent title='Name' content={getResourceName(rsrc)} />
      <LabelContent
        title='Account'
        content={
          <>
            <AccountIcon className='mr-2' />
            {getResourceName(account)}
          </>
        }
      />
      <LabelContent
        title='Database Type'
        content={<Label iconButton={faDatabase} text={rsrc.Spec.DBType} variant='grayBlue' />}
      />
      <LabelContent title='Hostname' content={rsrc.Spec.Hostname} />
      <LabelContent title='Port' content={rsrc.Spec.Port} />
      <LabelContent title='DNS Names' content={rsrc.Spec.DNSNames.Elems.join(', ')} />
      {appView === 'admin' ? (
        <MeshClusterLabelContent rsrc={rsrc} />
      ) : (
        <LabelContent
          title='Mesh Cluster'
          content={<Label className='!normal-case' text={rsrc && rsrc.Spec.MeshCluster} />}
        />
      )}

      {/* GCP add user dropdown */}

      {account && account.Spec.Type === 'GCP' && appView === 'admin' && (
        <>
          <div className='flex items-center justify-between border-b-2'>
            <label className='mr-8' style={{ fontWeight: 600, color: '#A6ABB6', width: '160px' }}>
              Procyon Users
            </label>
            <FormControl className={classes.formControl}>
              <Select
                className={classes.selectOptions}
                style={{
                  width: '500px',
                  border: '1px solid #d8dde3',
                  marginRight: '10%',
                  fontSize: '15px',
                  color: '#545b71'
                }}
                multiple
                value={selectedItems}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 250,
                      color: '#545b71',
                      fontSize: '15px'
                    }
                  }
                }}
              >
                {Object.keys(rsrc?.Spec?.BuiltInUsers?.Map).map((key) => (
                  <MenuItem style={{ fontSize: '14px' }} key={key} value={key}>
                    <Checkbox
                      style={{ color: '#447be4', borderWidth: '1px' }}
                      checked={selectedItems.indexOf(key) > -1}
                    />
                    <ListItemText primary={key} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button size='md' onClick={() => handleUserSave()} variant='primary'>
              Save
            </Button>
          </div>
          <div className='flex items-center border-b-2 mt-3 mb-3 pb-3'>
            <label className='mr-8' style={{ fontWeight: 600, color: '#A6ABB6', width: '190px' }}>
              Enabled Users
            </label>

            <div className='flex gap-2 flex-wrap' style={{ width: '600px' }}>
              {selectedItems.map((item) => {
                return (
                  <div>
                    <Label variant='grayBlue' text={item} />
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}

      {/*
       * AWS add user or username with password
       */}
      {account &&
        (account.Spec.Type === 'AWS' || account.Spec.Type === 'AZURE') &&
        appView === 'admin' &&
        dbRow.map((e, i) => {
          return (
            <div
              className='flex gap-12 border-b-1 mt-2 mb-2 pb-2'
              style={{ borderBottom: '1px solid #D8DDE4' }}
              key={i}
            >
              <div className='flex gap-5 items-center justify-between'>
                <label
                  className='mr-8'
                  style={{ fontWeight: 600, color: '#A6ABB6', width: '150px' }}
                >
                  Username {rowData[i]?.isAuth ? '/ Password' : ''}
                </label>
                <TextInput
                  required
                  disabled={rowData[i]?.disableInput}
                  value={rowData[i]?.Username}
                  style={{ width: '200px' }}
                  onChange={(e) => handleDbRowInputChange('Username', i, e.target.value)}
                  placeholder='Username'
                />

                <TextInput
                  style={{
                    visibility: rowData[i]?.isAuth ? 'visible' : 'hidden',
                    width: '200px'
                  }}
                  type='password'
                  value={rowData[i]?.Password}
                  onChange={(e) => handleDbRowInputChange('Password', i, e.target.value)}
                  placeholder='Password *'
                />
                {account.Spec.Type !== 'AZURE' && (
                  <SwitchBox
                    checked={!rowData[i]?.isAuth}
                    label='IAMAuth'
                    onChange={() => handleToggleChange(i)}
                    variant='primary'
                  />
                )}

                <div style={{}}>
                  <IconButton onClick={() => handleDeletRow(i)} variant='grayRed' icon={faTrash} />
                </div>
              </div>
            </div>
          )
        })}

      {account &&
        (account.Spec.Type === 'AWS' || account.Spec.Type === 'AZURE') &&
        appView === 'admin' && (
          <div className='flex justify-end mt-5 gap-4'>
            <Button variant='primary' size='md' onClick={handleAddDBRow}>
              Add +
            </Button>
            <Button size='md' onClick={() => handleSaveAllRows()} variant='primary'>
              Save
            </Button>
          </div>
        )}

      {showDeleteModal && (
        <FullScreenAlertModal
          actionButtonText='Delete'
          actionButtonVariant='danger'
          alertMessage={`Are you sure you want to delete?`}
          loadingMessage='Deleting User'
          onActionClick={() => {
            handleDeletDBRow(selectedRowForDelete)
          }}
          onCancel={() => setShowDeleteModal(false)}
          showModal
        />
      )}
    </>
  )
}

export { Database }
