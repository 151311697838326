const { AdminIAMResources, UserIAMResources, AdminIAMResourceDetails } = require('../views')
import React from 'react'
import { Route } from 'react-router'
import { UserIAMResourceDetails } from '../views/UserIAMResourceDetails/index.'

const IAMResourcesRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/resources', '/admin/resources/:cloudType']}>
        <AdminIAMResources />
      </Route>
      <Route exact path='/admin/resources/:kind/:resourceName' component={AdminIAMResourceDetails} />
      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/resources', '/user/resources/:cloudType']}>
        <UserIAMResources />
      </Route>
      <Route exact path='/user/resources/:kind/:resourceName' component={UserIAMResourceDetails} />
    </>
  )
}

export { IAMResourcesRoutes }
