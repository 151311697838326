export const REQUEST_STATUS = {
  Server: { default: 'ssh' },
  Role: { default: 'assumerole' }
}

export const APPROVED_STATUS = {
  New: 'New',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved',
  Cancelled: 'Cancelled'
}
