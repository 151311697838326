import { ErrorOutline, Warning } from '@material-ui/icons'
import { Theme } from 'Core'

export const mostActiveSampleData = [
  {
    message: {
      Icon: <Warning color='error' />,
      link: 'link',
      description: 'alert description'
    }
  },
  {
    message: {
      Icon: <ErrorOutline style={{ color: Theme.palette.warning.main }} />,
      link: 'link',
      description: 'alert description'
    }
  }
]
