import { Route, Switch } from 'react-router'
import { ActivityLogs } from '../views/ActivityLogs'

export const ActivityLogsRoute = () => {
  return (
    <Switch>
      <Route exact path={['/admin/activity-logs']} component={ActivityLogs} />
      <Route exact path={['/user/activity-logs']} component={ActivityLogs} />
    </Switch>
  )
}
