import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const GoogleIcon = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 32 32' {...props}>
      <defs>
        <path id='ds0aky6x0a' d='M0 0L15.214 0 15.214 7.823 0 7.823z' />
        <path id='i34k026plc' d='M0 0L9.378 0 9.378 8.958 0 8.958z' />
        <path id='1ovmyvsvne' d='M0 0L15.269 0 15.269 7.809 0 7.809z' />
        <path id='08wsn2c57g' d='M0 0L4.307 0 4.307 8.499 0 8.499z' />
      </defs>
      <g fill='none' fill-rule='evenodd'>
        <g>
          <g>
            <g>
              <g>
                <g transform='translate(-719.000000, -262.000000) translate(294.000000, 181.000000) translate(425.000000, 81.000000) translate(6.400000, 4.800000) translate(1.007301, 11.377441)'>
                  <mask id='satg1gookb' fill='#fff'>
                    <use xlinkHref='#ds0aky6x0a' />
                  </mask>
                  <path
                    fill='#34A753'
                    d='M8.482 7.823c-.074-.023-.15-.015-.225-.019-.572-.031-1.136-.11-1.694-.237-.93-.21-1.81-.548-2.638-1.011-.731-.409-1.398-.902-1.999-1.48-.78-.748-1.414-1.6-1.902-2.556L0 2.469l1.9-1.423L3.3 0c.182.54.442 1.043.778 1.507.163.226.345.438.541.638.5.512 1.08.916 1.733 1.217.405.186.828.323 1.266.411.18.036.36.062.542.084.35.041.701.045 1.052.027.536-.027 1.064-.107 1.579-.261.234-.07.463-.153.686-.254.195-.088.385-.183.565-.297.013-.007.028-.01.042-.016l.6.454 2.078 1.573.452.346-.04.04c-.242.216-.495.42-.76.608-.777.554-1.624.974-2.537 1.268-.821.264-1.664.415-2.528.46-.069.004-.14-.005-.208.018h-.66z'
                    mask='url(#satg1gookb)'
                  />
                </g>
                <g transform='translate(-719.000000, -262.000000) translate(294.000000, 181.000000) translate(425.000000, 81.000000) translate(6.400000, 4.800000) translate(9.822396, 7.849104)'>
                  <mask id='f4zzgugzpd' fill='#fff'>
                    <use xlinkHref='#i34k026plc' />
                  </mask>
                  <path
                    fill='#4285F3'
                    d='M6.4 8.958l-.453-.347-2.079-1.573-.599-.454c.065-.06.144-.098.215-.149.112-.082.224-.163.329-.254.106-.092.215-.18.31-.283.24-.254.452-.526.627-.826.187-.32.326-.659.43-1.012.034-.112.058-.228.088-.347h-5.2C.014 3.714 0 3.702 0 3.649c.002-1.195.002-2.39 0-3.584C0 .012.012 0 .065 0h9.03c.049 0 .065.015.074.059.046.207.088.416.12.625.024.152.044.305.059.459.008.09.005.18.03.269v.797c-.014.01-.01.025-.01.038-.002.122-.012.244-.02.367-.023.332-.063.662-.116.991-.072.448-.173.89-.305 1.324-.214.705-.506 1.377-.882 2.014-.342.578-.746 1.112-1.214 1.6-.138.144-.277.287-.432.415'
                    mask='url(#f4zzgugzpd)'
                  />
                </g>
                <g transform='translate(-719.000000, -262.000000) translate(294.000000, 181.000000) translate(425.000000, 81.000000) translate(6.400000, 4.800000) translate(1.010840, 0.000000)'>
                  <mask id='495ccu6euf' fill='#fff'>
                    <use xlinkHref='#1ovmyvsvne' />
                  </mask>
                  <path
                    fill='#E94335'
                    d='M0 5.348c.047-.118.112-.227.173-.337.436-.785.973-1.497 1.613-2.135.934-.93 2.016-1.645 3.246-2.145C5.7.459 6.392.264 7.104.143c.485-.082.973-.128 1.466-.14.228-.006.457-.003.685.007.261.012.522.036.782.067.508.06 1.007.162 1.498.302.44.125.87.281 1.288.467.538.24 1.05.523 1.536.85.3.203.59.418.863.652.062.053.062.053.006.108l-2.75 2.685c-.056.056-.055.055-.115.007-.265-.21-.55-.392-.85-.551-.223-.118-.454-.219-.69-.308-.252-.095-.51-.166-.773-.225-.144-.033-.29-.06-.435-.08-.11-.015-.22-.024-.33-.033-.194-.015-.388-.03-.582-.023-.127.004-.255.014-.382.024-.154.011-.307.028-.46.052-.448.07-.88.193-1.3.363-.44.178-.848.409-1.23.685-.199.145-.386.305-.565.474-.295.278-.556.583-.783.915-.274.4-.493.828-.654 1.284l-.033.084c-.248-.184-.497-.367-.744-.552L.907 6.027 0 5.347'
                    mask='url(#495ccu6euf)'
                  />
                </g>
                <g transform='translate(-719.000000, -262.000000) translate(294.000000, 181.000000) translate(425.000000, 81.000000) translate(6.400000, 4.800000) translate(0.000000, 5.347547)'>
                  <mask id='n7dju7iylh' fill='#fff'>
                    <use xlinkHref='#08wsn2c57g' />
                  </mask>
                  <path
                    fill='#FABB05'
                    d='M1.01 0l.908.68 1.645 1.23.743.552c-.024.085-.048.171-.074.257-.07.24-.119.483-.155.73-.044.293-.061.588-.057.883.005.343.04.682.108 1.02.046.23.108.454.179.678l-1.4 1.046-1.9 1.423c-.135-.258-.25-.524-.356-.794C.415 7.106.244 6.49.136 5.858.04 5.292-.007 4.722.001 4.148.02 2.714.34 1.347.98.053.988.034 1 .017 1.01 0'
                    mask='url(#n7dju7iylh)'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
})

export default GoogleIcon
