export const log = (message, tag = 'default', type = 'log') =>
  console[type](`[workload](${tag}):`, message)

/**
 * 
 * @param {*} workload 
 * @returns {any[]}
 */
export const getWorkloadAccounts = (workload) => workload.Spec.Accounts.ObjectRef

export const isWorkloadErrored = (workload) => Object.keys(workload?.Status.Status?.StatusMap || {}).length > 0