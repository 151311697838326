import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FullScreenModal } from 'V2Components'
import { Button, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

const MsTeams = ({ showInfoModal, setShowInfoModal, handleIntegrationModalClick }) => {
  return (
    <FullScreenModal showModal={showInfoModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
              <TargetIcon
                width={'48px'}
                height={'48px'}
                type={'TEAMS'}
                className='rounded'
                style={{
                  borderRadius: '12px',
                  marginRight: '15px'
                }}
              />
              Microsoft Teams
            </Typography>
            <div className='flex gap-4'>
              <Button
                onClick={() => setShowInfoModal(false)}
                variant='gray'
                icon={faClose}
                iconPosition='end'
              >
                Close
              </Button>
            </div>
          </div>
          <div className='p-4 mt-2 rounded text-black max-h-[60vh] overflow-auto'>
            <p className='text-sm font-light'>
              Microsoft Teams (often referred to as MS Teams or simply Teams) is a collaboration
              platform developed by Microsoft as part of the Microsoft 365 suite of products.
            </p>

            <p className='font-regular mt-5'>What is ServiceNow?</p>
            <p className='font-light'>
              Microsoft Teams (MS Teams) is a collaboration platform developed by Microsoft, part of
              the Microsoft 365 suite. It combines workplace chat, video meetings, file storage, and
              application integration to facilitate seamless communication and collaboration within
              organizations. MS Teams allows users to create teams and channels for specific
              projects or departments, conduct virtual meetings, share and collaborate on documents
              in real-time, and integrate with various third-party apps and services. It's widely
              used for remote work, project management, and enhancing productivity through
              centralized communication.
            </p>
          </div>

          <div className='flex justify-end mt-4'>
            <Button
              size='md'
              onClick={() => handleIntegrationModalClick('msteam')}
              variant='primary'
            >
              Integrate
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { MsTeams }
