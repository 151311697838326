import { createRef, createRsrcKey } from 'features/resources'

const { getDisplayName, getServiceAccountName } = require('Utils/PureHelperFuctions')

export const getFilterDropdownItems = (entities = []) => {
  const tempFlagObj = {}
  const filters = [
    {
      label: 'Show: All',
      value: ''
    }
  ]

  entities.forEach((e) => {
    const { Kind } = e.ObjectMeta
    if (!tempFlagObj[Kind]) {
      filters.push({
        label: `Show: ${Kind === 'GithubResource' ? 'Team' : Kind}s`,
        value: Kind 
      })
      tempFlagObj[Kind] = true
    }
  })

  return filters
}

export const entityDataAdapters = {
  User(u) {
    return {
      caption: u.Spec.EmailID || u.ObjectMeta.Name,
      name: getDisplayName(u),
      key: createRsrcKey(u),
      ref: createRef(u),
      refObject: u,
      type: 'USER'
    }
  },
  Group(g) {
    return {
      caption: `${g.Spec.Users.ObjectRef?.length || 0} Users`,
      name: g.ObjectMeta.Name,
      key: createRsrcKey(g),
      ref: createRef(g),
      refObject: g,
      type: 'USERGROUPS'
    }
  },
  ServiceAccount(s) {
    return {
      caption: s.ObjectMeta.Name,
      name: getServiceAccountName(s),
      key: createRsrcKey(s),
      ref: createRef(s),
      refObject: s,
      type: 'SERVICEACCOUNT'
    }
  }, 
  GithubResource(g) {
    return {
      caption:  `${g.Spec?.Members?.Members?.length || 0} Users`,
      name: g.Spec.TeamSpec.Name,
      key: createRsrcKey(g),
      ref: createRef(g),
      refObject: g,
      type: 'USERGROUPS'
    }
  }
}
