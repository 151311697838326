import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { SearchInput } from 'V2Components'
import {
  AccountsSortOptions,
  isChildAccount,
  isOrgAccount,
  useCloudAccounts,
  useCloudDetailsProvider,
  useCloudsResources
} from 'features/clouds'
import { getResourceName } from 'features/resources'
import { CloudCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function ChildAccountsTab({}) {
  const [searchKey, setSearchKey] = useState('')
  const [activeOption, setActiveOption] = useState('all')
  const history = useHistory()
  const { getChildAccounts } = useCloudAccounts()
  const { cloud: account } = useCloudDetailsProvider()
  const { getAccountTargets } = useCloudsResources()
  const { getParentAccount } = useCloudAccounts()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      return `${rName}`.toLowerCase().includes(queryKey)
    }
  })

  const accounts = applySearchQuery(getChildAccounts(account))

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='body-regular'>{accounts.length} Accounts</Typography>
        <div className='flex gap-4'>
          <SearchInput  searchKey={searchKey} onChange={setSearchKey} />
          <AccountsSortOptions activeOption={activeOption} onActiveOptionChange={setActiveOption} />
        </div>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {accounts.map((a) => {
          const { _meta } = getAccountTargets(a)
          const isChild = isChildAccount(a)
          const isOrg = isOrgAccount(a)
          const parentAccount = getParentAccount(a)

          return (
            <CloudCard
              width='100%'
              // @ts-ignore
              caption={
                isOrg ? (
                  'Org Account'
                ) : isChild ? (
                  <>
                    Child of <strong className='ml-1'>{getResourceName(parentAccount)}</strong>
                  </>
                ) : (
                  ''
                )
              }
              icon={
                <TargetIcon
                  height='100%'
                  type={isOrg ? 'AWS_ORG' : isChild ? 'AWS_CHILD' : 'AWS_APPLICATION'}
                  width='100%'
                />
              }
              menuItems={[]}
              name={getResourceName(a)}
              onClickMoreInfo={() =>
                history.push(`/admin/clouds/aws/${encodeURIComponent(a.ObjectMeta.Name)}`)
              }
              onClickResources={function noRefCheck() {}}
              resourcesAmount={_meta.count}
            />
          )
        })}
      </div>
    </div>
  )
}

export { ChildAccountsTab }
