import useAppView from 'Core/Hooks/useAppView'
import { useAppDetailsContext } from 'features/snowflake'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import { IamRolesCard } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

const useSlices = createDataSelectorHook(['dataBricksAccounts', 'dataBricksWorkSpaces'])
const WorkSpaces = () => {
  const history = useHistory()
  const { appView } = useAppView()
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [workSpaceList, setWorkSpaceList] = useState([])

  useEffect(() => {
    initialCall()
  }, [slices])

  const initialCall = async () => {
    await reduxApiClient('databricksaccounts').getAll({})
    await reduxApiClient('databricksworkspaces').getAll({})
  }

  useMemo(() => {
    const filterCurrentAccount = _.find(slices.dataBricksAccounts, {
      Application: { RefID: app?.ObjectMeta?.ID }
    })

    const workSpaces = slices.dataBricksWorkSpaces.filter(
      (workspace) => workspace.Account.RefID === filterCurrentAccount?.ObjectMeta?.ID
    )

    setWorkSpaceList(workSpaces)
  }, [slices])

  const handleMoreInfo = (workspace) => {
    history.push(
      `/${appView}/datacloud/workspacedetails/${encodeURIComponent(workspace.ObjectMeta.Name)}`
    )
  }

  return (
    <div className='flex'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        {workSpaceList &&
          workSpaceList.map((e, index) => (
            <IamRolesCard
              style={{
                marginBottom: '10px',
                alignItems: 'center'
              }}
              showCustomButton={true}
              customButtonVariant='primary'
              customButtonText='More Info'
              onClickCustomButton={() => handleMoreInfo(e)}
              key={index}
              description={e.Spec.CloudType}
              onClickActionButton={() => {}}
              title={e.Spec.WorkspaceName}
              buttonType={'none'}
              showOpenButton={false}
              showMoreButton={false}
            ></IamRolesCard>
          ))}
      </div>
    </div>
  )
}

export { WorkSpaces }
