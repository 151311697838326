import { Box, Typography } from '@material-ui/core'
import React from 'react'

const TableHeader = ({
  columnMeta,
  variant = 'subtitle2',
  color = 'default',
  minWidth = 0,
  textAlign = 'right',
  width = 'auto',
  pl = 1.5,
  pr = 0.2
}) => {
  return (
    <Box pl={pl} pr={pr} width={width}>
      <Typography style={{ textAlign: textAlign, minWidth }} variant={variant} color={color}>{columnMeta.label}</Typography>
    </Box>
  )
}

export default TableHeader
