import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FullScreenModal } from 'V2Components'
import { Button, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

const ServiceNow = ({ showInfoModal, setShowInfoModal, handleIntegrationModalClick }) => {
    return (
        <FullScreenModal showModal={showInfoModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <div className='w-[626px] rounded-lg p-8 bg-white'>
            <div className='flex justify-between'>
              <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
                <TargetIcon
                  width={'48px'}
                  height={'48px'}
                  type={'SERVICENOW'}
                  className='rounded'
                  style={{
                    borderRadius: '12px',
                    marginRight: '15px'
                  }}
                />
                ServiceNow
              </Typography>
              <div className='flex gap-4'>
                <Button onClick={() => setShowInfoModal(false)} variant='gray' icon={faClose} iconPosition='end'>
                  Close
                </Button>
              </div>
            </div>
            <div className='p-4 mt-2 rounded text-black max-h-[60vh] overflow-auto'>
              <p className='text-sm font-light'>
                ServiceNow is a collaboration platform that streamlines team communication, offering
                channels, messaging, and integrations to enhance productivity and collaboration in
                workplaces.
              </p>

              <p className='font-regular mt-5'>What is ServiceNow?</p>
              <p className='font-light'>
                ServiceNow is a collaboration platform designed to facilitate communication and teamwork
                within organizations. It offers a centralized space for team members to communicate,
                share files, and collaborate in real-time. ServiceNow provides channels for organizing
                discussions based on topics, projects, or teams, as well as direct messaging for
                one-on-one conversations. The platform also supports integrations with various
                third-party applications and services, allowing teams to bring their tools into the
                ServiceNow environment. Overall, ServiceNow aims to enhance communication and collaboration,
                making it a popular choice for businesses and teams of all sizes.
              </p>
            </div>

            <div className='flex justify-end mt-4'>
              <Button size='md' onClick={() => handleIntegrationModalClick('servicenow')} variant='primary'>
                Integrate
              </Button>
            </div>
          </div>
        </div>
      </FullScreenModal>
    )
}

export {ServiceNow}