import { createTheme } from '@material-ui/core'

const themeConfig = {
  palette: {
    type: 'light',
    common: {
      white: '#fff'
    },
    other: {
      rating: '#ffb400',
      highEmphasis: '#000000'
    },
    inside: '#cfcfcf',
    chip: {
      // warning: 'rgba(255, 152, 0, 0.3)',
      // info: 'rgba(33, 150, 243, 0.3)',
      // error: 'rgba(244, 67, 54, 0.3)'
      error: '#feedeb',
      info: '#e9f5fe',
      warning: '#fff5e6'
    },
    primary: {
      light: '#75799f',
      main: '#0965df',
      dark: '#2e3955',
      white: '#ffffff',
      paleBlue: '#dcdce5',
      contrastText: '#dcdce5',
      hover: '#06469b'
    },
    secondary: {
      main: '#ff6150',
      light: '#ff4081',
      dark: '#c51162',
      contrastText: '#ffffff',
      pale: {
        main: 'rgba(9, 101, 223, 0.2)',
        yellow: 'rgba(223, 201, 24, 0.2)'
      }
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d',
      alertText: '#663c00',
      contrastText: 'rgba(0,0,0,0.87)'

    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      alertText: '#0d3c61',
      contrastText: '#ffffff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      alertText: '#1e4620',
      contrastText: '#ffffff'
    },
    surface: {
      light: 'rgba(255,255,255,0.50)'
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
      filledInput: 'rgba(0,0,0,0.09)',
      tooltip: 'rgba(97,97,97,0.90)',
      backdrop: 'rgba(0,0,0,0.50)',
      snackbar: '#323232'
    },
    grey: {
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      700: '#616161'
    },
    divider: 'rgba(0,0,0,0.12)',
    customBorder: 'rgba(0, 0, 0, 0.42)',
    listBorder: '#e9e9e9',
    error: {
      main: '#f44336',
      dark: '#d32f2f',
      light: '#e57373',
      alertText: '#611a15',
      contrastText: '#ffffff'
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.26)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
      lowEmphasis: 'rgba(255,255,255,0.50)',
      mediumEmphasis: 'rgba(255,255,255,0.7)',
      highEmphasis: 'rgb(255,255,255)'

    },
    action: {
      disabled: 'rgba(0,0,0,0.26)',
      disabledLowEmphasis: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      selected: 'rgba(0,0,0,0.08)',
      hover: 'rgba(0,0,0,0.04)',
      active: 'rgba(0,0,0,0.54)',
      focus: 'rgba(0,0,0,0.12)'

    }
  },
  spacing: 16,
  typography: {
    subHeading: {
      fontSize: '18px'
    },
    useNextVariants: true
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#06469b'
        }
      }
    },
    MUIDataTable: {
      responsiveStacked: {
        maxHeight: 'none',
        overflowX: 'auto'
      }
    }
  }
}
export { themeConfig }

// @ts-ignore
export default createTheme(themeConfig)
