import useAppView from 'Core/Hooks/useAppView'
import { PaginationView } from 'V2Components'
import { BundleCard } from 'procyon-ui'
import React from 'react'

const BundlesGridView = ({ data }) => {
  const { appView } = useAppView()

  const renderBundleCardFunction = (data) => {
    const {
      name,
      status,
      Icon,
      hideSignInButton,
      hideCopyProfileButton,
      onRequestClick,
      onCopyProfileClick,
      bundleType,
      onMoreInfoClick,
      onSignInClick
    } = data

    return (
      <BundleCard
        width='100%'
        title={name}
        showMoreInfoButton
        isGranted={status === 'granted'}
        onClickSignInButton={onSignInClick}
        onClickRequestButton={onRequestClick}
        isInProgress={status === 'inprogress'}
        showSignInButton={status === 'granted' && !hideSignInButton}
        showRequestButton={status === 'ungranted' && appView === 'user'}
        showCopyProfileButton={status === 'granted' && bundleType === 'AWS' && !hideCopyProfileButton}
        Icon={<Icon className='w-[34px] h-[34px]' />}
        onClickCopyProfileButton={onCopyProfileClick}
        onClickMoreInfoButton={onMoreInfoClick}
      />
    )
  }

  return (
    <PaginationView
      itemsCount={40}
      data={data}
      renderFunction={renderBundleCardFunction}
      WrapperComponent={({ children }) => (
        <div className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 grid gap-8 mt-4'>
          {children}
        </div>
      )}
    />
  )
}

export { BundlesGridView }
