import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cartAPIFn } from '../api'
import {
  addToCart,
  clearCart,
  removeResourceFromCart,
  updateCart,
  updateCartQueue,
  updateCartStatus
} from 'infra/redux/reducers/slices/cartSlice'
import _ from 'lodash'

/**
 *
 * @typedef {'userTargets' | 'adminTargets' |'userResources' | 'adminResources' | 'adminApplications' | 'userApplications' | 'workloadPolicy'} key
 * @param {key} key
 * @deprecated Use AccessCartProvider and hook
 */
export const useCart = (key) => {
  const cartsAPI = useRef(cartAPIFn()).current
  const dispatch = useDispatch()

  // @ts-ignore
  const cartObject = useSelector((s) => s.carts[key]?.data)
  // @ts-ignore
  const cartStatus = useSelector((s) => s.carts[key]?.status)
  const cartItems = cartObject?.CartQueues.CartQueue || []

  const getResourceFromCart = ({ RefKind, RefID }) => {
    return _.find(cartItems, { Resource: { RefKind, RefID } })
  }

  const isResourceInCart = ({ RefKind, RefID }) => {
    const index = _.findIndex(cartItems, { Resource: { RefKind, RefID } })
    return index !== -1
  }

  const addItemsToCart = async ({ resourceRef, roles, principal }) => {
    const data = {
      Resource: resourceRef,
      Roles: { ObjectRef: roles },
      Principal: principal
    }
    dispatch(
      addToCart({
        key,
        data
      })
    )
    const newCartObject = structuredClone(cartObject)
    newCartObject.CartQueues.CartQueue.push(data)
    await cartsAPI.updateCart(newCartObject)
  }

  /**
   *
   * @param {{ resourceRef:object, roles?:any[], principal?:string }} param0
   */
  const updateCartQueueItem = async ({ resourceRef, roles, principal }) => {
    const data = {
      Resource: resourceRef,
      Roles: { ObjectRef: roles },
      Principal: principal
    }
    dispatch(updateCartQueue({ key, data }))
    const index = _.findIndex(cartObject.CartQueues.CartQueue, { Resource: data.Resource })
    const newCartObject = structuredClone(cartObject)
    const queue = newCartObject.CartQueues.CartQueue || []

    if (index !== -1) {
      queue[index] = { ...queue[index], ...data }
      newCartObject.CartQueues.CartQueue = queue
    }
    await cartsAPI.updateCart(newCartObject)
  }

  const clearCartItems = async () => {
    dispatch(clearCart({ key }))
    const newCartObject = structuredClone(cartObject)
    newCartObject.CartQueues.CartQueue = []
    await cartsAPI.updateCart(newCartObject)
  }

  /**
   *
   * @param {{ RefKind:string , RefID: string }} resource
   */
  const removeFromCart = async (resource) => {
    const newCartObject = structuredClone(cartObject)
    const queue = newCartObject.CartQueues.CartQueue || []
    const newQueue = queue.filter((e) => !_.isEqual(e.Resource, resource))
    newCartObject.CartQueues.CartQueue = newQueue
    dispatch(
      removeResourceFromCart({
        key,
        resource
      })
    )
    await cartsAPI.updateCart(newCartObject)
  }

  const initiCarts = async () => {
    let data = null
    try {
      data = await cartsAPI.getCart(key)
    } catch (error) {
      data = await cartsAPI.createCart(key)
    } finally {
      if (data) dispatch(updateCart({ key, data }))
      dispatch(updateCartStatus({ key, status: 'loaded' }))
    }
  }

  if (cartStatus === 'idle') {
    dispatch(updateCartStatus({ key, status: 'loading' }))
    initiCarts()
  }

  return {
    addItemsToCart,
    updateCartQueueItem,
    isResourceInCart,
    getResourceFromCart,
    clearCartItems,
    removeFromCart,
    isCartLoaded: cartStatus === 'loaded',
    cartItems,
    isCartEmpty: cartItems.length === 0
  }
}
