import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { LoadingFeedback } from 'V2Components'
import { useApplicationAccess } from 'features/applications'
import { isPolicyIssuedToUser } from 'features/policy'
import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

const ApplicationDetailsContext = createContext({
  app: undefined,
  policys: [],
  isAllowedAccess: false //True, if the app has more than one policy created - which means access is allowed
})

export const WorkSpaceDetailProvider = ({ children }) => {
  const [app, setApp] = useState()
  const dbWorkSpace = ['workspace', 'workspacedetails']
  //@ts-ignore
  const appName = decodeURIComponent(useParams().workspaceName)
  const { user } = useUser()
  const { slices, selectDispatch, getObjectRef } = useMultiSlice([
    'applicationList',
    'policyList',
    'dataBricksWorkSpaces'
  ])
  const { getApplicationPolicysRef } = useApplicationAccess()

  /**
   * Set the app, by quering with name
   */
  if (appName && slices.dataBricksWorkSpaces.length) {
    const a = _.find(slices.dataBricksWorkSpaces, { ObjectMeta: { Name: appName } })
    if (a !== app) setApp(a)
  }

  /**
   * Set the policys attached with this application
   */

  const refObjs = getObjectRef(getApplicationPolicysRef(app))

  const getAllowedAccess = () => {
    const userPolicys = refObjs.filter((e) => isPolicyIssuedToUser(e, user))
    return userPolicys.length > 0
  }

  useEffect(() => {
    selectDispatch(['applicationList', 'policyList', 'dataBricksWorkSpaces'])
  }, [])

  return (
    <ApplicationDetailsContext.Provider
      value={{ app, policys: refObjs, isAllowedAccess: getAllowedAccess() }}
    >
      {!app && <LoadingFeedback shrinked loading message='Fetching Application' />}
      {app && children}
    </ApplicationDetailsContext.Provider>
  )
}

export const useAppDetailsContext = () => useContext(ApplicationDetailsContext)
