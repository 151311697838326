import { Box, Button } from '@material-ui/core'
import { Fullscreen } from '@material-ui/icons'
import SeriesFilter from 'Components/Charts/ChartContainer/Components/SeriesFilter'
import FlexModal from 'Components/FlexModal'
import MountOn from 'Components/MountOn'
import React, { useState } from 'react'

const ExpandableContainer = ({
  containerHeight,
  children,
  seriesMeta,
  expandButtonLabel,
  modalTitle,
  ModalComponent,
  hideExpandButton
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  return (
    <Box height={containerHeight} px={1} display='flex' flexDirection='column' justifyContent='space-between'>
      {children}
      <Box pt={0.725} pb={0.312} width='100%' display='flex' justifyContent={seriesMeta ? 'space-between' : 'center'}>
        <MountOn on={!hideExpandButton}>
          <Button onClick={openModal} color='primary' startIcon={<Fullscreen />}>
            {expandButtonLabel}
          </Button>
        </MountOn>
        {seriesMeta && <SeriesFilter disabled pb={0} position='right' filters={seriesMeta} />}
      </Box>
      <FlexModal
        Title={modalTitle}
        background='white'
        showModalHeader
        handleClose={closeModal}
        height='41.5rem'
        width='40.5rem'
        isOpen={isOpen}
      >
        {ModalComponent && <ModalComponent closeModal={closeModal} />}
      </FlexModal>
    </Box>
  )
}

export default ExpandableContainer
