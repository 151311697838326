import { BreadCrumbs, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { cn } from 'Utils/Helpers'

/**
 *
 * @param {{
 * maxItems?: number,
  * breadCrumbs: {  
  * className?: import('clsx').ClassValue[],
  * label:string, 
  * onClick?:(() => void), 
  * isActive?: boolean
  * link?: string 
 * }[], 
 * goBack?: boolean,
 * enableLastItemClick?: boolean
 * }} param0
 * @returns
 */
function Breadcrumbs({ breadCrumbs, goBack, maxItems = 2, enableLastItemClick = false }) {
  const history = useHistory()
  const location = useLocation()

  const handleClick = (e) => {
    if (e.onClick) e.onClick()
    else {
      // check if we should go back and it's possible to goBack
      // location.key is truthy, if user routed from somewhere to this page, which means goBack() can be called
      if (goBack && location.key) history.goBack()
      else history.push(e.link || '/')
    }
  }

  return (
    <BreadCrumbs maxItems={maxItems}>
      {breadCrumbs.map((e, i) => (
        <Typography
          className={cn(
            { 'cursor-pointer': i < breadCrumbs.length - 1 || enableLastItemClick },
            { 'cursor-default': i === breadCrumbs.length - 1 && !enableLastItemClick },
            {'!text-blue-500': e.isActive},
            e.className
          )}
          variant='caption-regular'
          key={i}
          onClick={
            breadCrumbs.length == i + 1 && !enableLastItemClick ? undefined : () => handleClick(e)
          }
        >
          {e.label}
        </Typography>
      ))}
    </BreadCrumbs>
  )
}

export { Breadcrumbs }
