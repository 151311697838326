// @ts-nocheck
import { errorHandler } from '../Utils/Helpers'

export class projectApis {
  static async getFavouriteProjects () {
    try {
      const response = { data: { Favorites: [] } }
      const favorites = localStorage.getItem('favouritesProjects')
      if (favorites) {
        const parsedFavorites = JSON.parse(favorites)
        response.data = { Favorites: parsedFavorites }
      }
      return response
    } catch (error) {
      errorHandler(error, 'getFavourite Projects')
    }
  }

  static async setFavouriteProject (id) {
    try {
      let data = []
      const storedFavorites = localStorage.getItem('favouritesProjects')
      if (storedFavorites) {
        data = JSON.parse(storedFavorites)
      }
      if (data.includes(id)) {
        data = data.filter(e => e !== id)
      } else {
        data = [...data, id]
      }
      const stringifiedFavorites = JSON.stringify(data)
      localStorage.setItem('favouritesProjects', stringifiedFavorites)
      return data
    } catch (error) {
      errorHandler(error, 'getFavourite Projects')
    }
  }
}
