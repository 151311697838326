import { faWarning } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { FullScreenModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { Button, RadioInput, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

const K8RolesPicker = ({
  onCancel,
  kubeCluster,
  onContinue,
  selectedRoleName,
  setSelectedRoleName
}) => {
  const { getObjectRef } = useObjectRef(['kubeClusters'])
  // Array of selected Approle names from kubeCluster.Spec.IAMRoles.Map

  const getKubeIAMRolesMap = () => {
    let cluster = kubeCluster

    if (kubeCluster.ObjectMeta.Kind === 'KubeNamespace')
      cluster = getObjectRef(kubeCluster.Spec.Cluster)

    const map = cluster.Spec.IAMRoles.Map
    return map
  }

  const iamRoleNames = () => {
    const list = Object.keys(getKubeIAMRolesMap())
    if (kubeCluster.ObjectMeta.Kind === 'KubeNamespace')
      return list.filter((e) => e !== 'ProcyonKubectlClusterAdmin')
    return list
  }

  const handleAddToQueue = () => {
    onContinue()
  }

  useHTMLBodyScrollbar(true)

  return (
    <FullScreenModal showModal={true}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-4 bg-white'>
          <div className=''>
            <span className='flex'>
              <TargetIcon type='KUBE_POD' className='mr-1' />
              <Typography variant='h4-regular'>
                Create policy for <strong>{getResourceName(kubeCluster)}</strong>
              </Typography>
            </span>
            <Typography className='mt-2' variant='caption-regular'>
              Select approle for policy.
            </Typography>
            <div className='mt-4'>
              {!iamRoleNames().length && (
                <div className='p-4 rounded bg-red-500 flex gap-4 items-center'>
                  <FontAwesomeIcon color='white' icon={faWarning} />
                  <Typography className='!text-white' variant='body-regular'>
                    AppRoles for this cluster was not found. Please refresh and try.{' '}
                  </Typography>
                </div>
              )}
              {iamRoleNames().map((roleName) => {
                return (
                  <div key={roleName} className='flex items-center'>
                    <RadioInput
                      id={roleName}
                      checked={roleName === selectedRoleName}
                      name={roleName}
                      onChange={() => setSelectedRoleName(roleName)}
                      value={roleName}
                      style={{
                        margin: '8px 0px'
                      }}
                    />
                    <label htmlFor={roleName}>{roleName}</label>
                  </div>
                )
              })}

              <div className='flex flex-row-reverse mt-10'>
                <Button
                  onClick={handleAddToQueue}
                  disabled={selectedRoleName == ''}
                  variant='primary'
                >
                  Save
                </Button>
                <Button onClick={onCancel} variant='gray' className='mr-5'>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export { K8RolesPicker }
