import { Box, makeStyles } from '@material-ui/core'
import ThemeLoaderButton from 'Components/ThemeLoaderButton'
import React, { useCallback } from 'react'
import BackUrl from './BackUrl'
import ManageSwitch from './ManageSwitch'
import ResourceIdentifier from './ResourceIdentifier'
const useStyle = makeStyles(() => ({
  root: {}
}))
const ResourceDetailsHeader = ({
  theme = 'default',
  size = 'large',
  CustomRender,
  actionLoading,
  name,
  type,
  backURL,
  switchLabel = 'Managed',
  onSwitch,
  switchActive,
  actionLabel,
  actionClick,
  switchLoading,
  hideActions = false
}) => {
  const classes = useStyle()
  const handleActionClick = useCallback(() => {
    if (typeof actionClick === 'function') {
      actionClick()
    }
  }, [actionClick])
  return (
    <Box className={classes.root}>
      <BackUrl backURL={backURL} />
      <Box display='flex' alignItems='center' justifyContent='space-between' pl={2}>
        <ResourceIdentifier name={name} type={type} />
        {!hideActions && (
          <Box display='flex' alignItems='center'>
            {switchLabel && (
              <ManageSwitch
                loading={switchLoading}
                text={switchLabel}
                onSwitch={onSwitch}
                switchActive={switchActive}
              />
            )}
            {CustomRender && <CustomRender />}
            {actionLabel && (
              <Box ml={1}>
                <ThemeLoaderButton
                  theme={theme}
                  size={size}
                  onClick={handleActionClick}
                  color='default'
                  variant='contained'
                  loading={actionLoading}
                  label={actionLabel}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ResourceDetailsHeader
