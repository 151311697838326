import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { DeleteProxyControllerModal } from 'features/proxyControllers'
import { getResourceName } from 'features/resources'
import { Button } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { SummaryHeader } from 'V2Components'

const Header = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const canGoBackFn = useCanGoBack('/admin/proxy-controllers')
  const proxyCtrl = useDataDetailsContext()
  const history = useHistory()

  return (
    <div>
      <SummaryHeader
        actions={
          <>
            <Button
              onClick={() =>
                history.push(`/admin/proxy-controllers/${proxyCtrl.ObjectMeta.Name}/edit`)
              }
              size='md'
              variant='grayBlue'
            >
              Edit
            </Button>
            <Button onClick={() => setShowDeleteModal(true)} size='md' variant='red'>
              Delete
            </Button>
          </>
        }
        breadCrumbsItems={[
          {
            label: 'Proxy Controllers',
            link: '/admin/proxy-controllers'
          },
          {
            label: getResourceName(proxyCtrl)
          }
        ]}
        rsrcName={getResourceName(proxyCtrl)}
      />
      {showDeleteModal && (
        <DeleteProxyControllerModal
          proxyController={proxyCtrl}
          onCancel={() => {
            setShowDeleteModal(false)
          }}
          cleanUpFn={() => {
            setShowDeleteModal(false)
            canGoBackFn()
          }}
        />
      )}
    </div>
  )
}

export { Header }
