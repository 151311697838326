import { ApiProvider } from 'Core'
import { useUser } from 'Core/Hooks/useUser'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { K8_PRINCIPAL_KEY, convertTZ, createRsrcKey, useAccessCartProvider } from 'features/resources'
import React from 'react'
import { TargetsResourceManager } from '../TargetsResourceManager'
import moment from 'moment'

function CreateRequestFlow({ selectedRsrc, setSelectedRsrc, views, setViews }) {
  const { user } = useUser()
  const { clearCartItems } = useAccessCartProvider()

  const handleRequestCreate = async (data) => {
    const req = await handleRequestSubmission(data, user)
    clearCartItems()
    enqueueNotification('Approval Request created successfully!', 'info')
    pushToSlice(req)
    setViews((s) => ({ ...s, accessRequestSubmissionModal: false }))
  }

  return (
    <TargetsResourceManager
      showRequestForUserGroupsSummary={false}
      mainTitle='Access Request'
      setSelectedRsrc={setSelectedRsrc}
      onRequestSubmission={handleRequestCreate}
      views={views}
      onViewsChange={setViews}
      selectedRsrc={selectedRsrc}
    />
  )
}

const handleRequestSubmission = async ({ spec, resources, resourcesMetaObj }, user) => {
  const data = {
    ObjectMeta: {},
    Spec: {
      Justification: spec.comments,
      Resources: {
        Resource: []
      },
      RequestedFor: {
        ObjectRef: [
          {
            RefKind: 'User',
            RefID: user?.ObjectMeta.ID
          }
        ]
      },
      NotBefore: moment(convertTZ(spec.startDate, spec.timeZone)).utc(),
      NotAfter: moment(convertTZ(spec.endDate, spec.timeZone)).utc(),
    },
    Status: 'New',
    GivenName: spec.name,
    Type: 'Target'
  }

  resources.forEach((rsrc) => {
    let Action = ''
    const { Principal, Roles } = resourcesMetaObj[createRsrcKey(rsrc)] || {}

    if (rsrc.RefKind === 'AppRole') Action = 'AssumeRole'
    else if (rsrc.RefKind === 'Server') Action = 'SSH'
    else if (rsrc.RefKind === 'Database') Action = 'DBAccess'
    else if (rsrc.RefKind === 'Kafka') Action = 'KafkaAccess'
    else if (rsrc.RefKind === 'RDPServer') Action = 'RDPAccess'
    else if (rsrc.RefKind === 'KubeCluster' || rsrc.RefKind === 'KubeNamespace') Action = 'KubeAccess'

    data.Spec.Resources.Resource.push({
      Target: rsrc,
      Action,
      Principal: Principal?.replace(K8_PRINCIPAL_KEY, ''),
      Approved: 'New',
      Services: {
        ObjectRef: Roles || []
      }
    })
  })
  const response = await new ApiProvider('approvalreqs').setInstance(data).post()
  return response.data
}

export { CreateRequestFlow }
