import React, { useState } from 'react'
import { Button, Typography, Divider, Step, StepLabel, StepContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignInAlt,
  faUserShield,
  faUserLock,
  faKey,
  faBan
} from '@fortawesome/free-solid-svg-icons'

const steps = [
  {
    title: 'Sign in to the AWS Management Console',
    description:
      'Go to the AWS Management Console and sign in with your administrator credentials.',
    icon: faSignInAlt
  },
  {
    title: 'Navigate to the IAM Console',
    description:
      "In the AWS Management Console, search for 'IAM' in the top search bar and select it to open the Identity and Access Management console.",
    icon: faUserShield
  },
  {
    title: "Access the User's Account",
    description:
      "In the IAM Dashboard, click on 'Users' in the sidebar to view all IAM users. Select the specific user for whom you want to disable AWS Console access.",
    icon: faUserLock
  },
  {
    title: "Remove the User's Console Access",
    description:
      "On the selected user's Summary page, scroll down to the 'Security Credentials' section. Under 'Console Access', if enabled, click 'Disable'. This will remove the user’s ability to sign in to the AWS Management Console.",
    icon: faKey
  },
  {
    title: 'Verify Console Access Removal',
    description:
      'Confirm the removal of console access. To test, you can attempt to sign in as the user (or confirm with the user) to ensure console access is successfully disabled.',
    icon: faBan
  }
]

const ConsoleAccessRecommendation = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <p className='text-base font-[500]'>Disable AWS Console Access for a User</p>
      <Divider style={{ marginBottom: '20px' }} />

      {steps.map((step, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Step active={activeStep === index} expanded={activeStep === index}>
            <StepLabel>
              <FontAwesomeIcon icon={step.icon} style={{ marginRight: '10px' }} />
              <Typography variant='subtitle2' component='span'>
                {step.title}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <StepContent>
                <p className='mb-3 text-[14px] font-[500] text-[#6e6e6e]'>{step.description}</p>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    style={{ marginRight: '10px' }}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  {index > 0 && <Button onClick={handleBack}>Back</Button>}
                </div>
              </StepContent>
            )}
          </Step>
        </div>
      ))}
    </div>
  )
}

export { ConsoleAccessRecommendation }
