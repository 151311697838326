import useAppView from 'Core/Hooks/useAppView'
import { LabelContent } from 'V2Components'
import { getDeviceType } from 'features/activityLogs/utils'
import { getResourceName } from 'features/resources'
import { DeviceBar, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const IDTYPE_MAP = {
  SAML: 'SAML_APPLICATION',
  OKTA: 'OKTA',
  OIDC: 'OIDC',
  ENTRA: 'Entra',
  AUTH0: 'Auth0',
  ONELOGIN: 'OneLogin',
  PING: 'Ping'
}

const ActivityInfoTab = ({ user, userName, device, deviceName, eventMessage }) => {
  const { appView } = useAppView()
  const history = useHistory()

  let Icon = null

  const idType = deviceName?.split(' ')[0]

  if (idType && IDTYPE_MAP[idType.toUpperCase()]) {
    Icon = <TargetIcon type={IDTYPE_MAP[idType.toUpperCase()]} />
  }

  return (
    <div>
      <LabelContent
        title='User'
        content={
          user && appView === 'admin' ? (
            <Typography variant='body-regular'>
              <Link className='text-[#3267D6]' to={`/admin/users/${user.ObjectMeta.Name}`}>
                {getResourceName(user)}
              </Link>
            </Typography>
          ) : (
            <Typography variant='body-regular'>
              {user ? getResourceName(user) : userName}
            </Typography>
          )
        }
      />
      <LabelContent
        title='User Email'
        content={<Typography variant='body-regular'>{userName}</Typography>}
      />
      {device && (
        <LabelContent
          title='Device'
          content={
            <DeviceBar
              caption={device.Attributes.OperatingSystem?.toUpperCase()}
              name={device.Attributes.DeviceName}
              onClick={() => {
                history.push(`/${appView}/device/${device.ObjectMeta.Name}`)
              }}
              type={getDeviceType(device)}
            />
          }
        />
      )}
      {!device && (
        <LabelContent
          title='Device Name'
          content={
            <div className='flex gap-2 items-center'>
              {Icon && Icon}
              <Typography variant='body-regular'>{deviceName}</Typography>
            </div>
          }
        />
      )}
      <LabelContent title='Message' content={eventMessage} />
    </div>
  )
}

export { ActivityInfoTab }
