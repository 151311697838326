import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getApplicationType } from 'features/applications'
import { isAccountResource } from 'features/clouds'
import {
  getKubeType,
  getPolicyResourcesRefs,
  isPolicyKubenamespaceRsrcs
} from 'features/policy/utils'
import {
  createRef,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getRsrcIcon,
  isResourceManaged
} from 'features/resources'
import {
  TargetsSliceNames,
  getKubeNamespaceRsrcs,
  getTargetsPolicyRules,
  isDynamicAppRole
} from 'features/targets/utils'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { ActionBar, TargetIcon } from 'procyon-ui'
import React from 'react'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'

const useSlices = createDataSelectorHook(['accountList', 'applicationList', ...TargetsSliceNames])

const Target = ({ searchKey }) => {
  const { slices, sliceNames } = useSlices()

  const { getObjectRef } = useObjectRef(sliceNames)

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (data, queryKey) => {
      const { title, caption } = data
      const all = `${title}${caption}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const { policy, selectedResources, setSelectedResources, setAttributesMap, attributesMap } =
    useEditPolicyModulesContext()

  const selectedRsrcKeys = selectedResources.map(createRsrcKey)

  const getResources = () => {
    const selectedRsrcsObjects = getObjectRef(selectedResources)

    const oneRsrc = getObjectRef(getPolicyResourcesRefs(policy)[0])
    if (!oneRsrc) return []

    const isApplication = oneRsrc.ObjectMeta.Kind === 'Application'

    const account = getObjectRef(getRsrcAccountRef(oneRsrc))
    // * Applications dont have accounts so policy does not have account limitations
    if (!isApplication && !account) return []

    let allRsrcObjects = []
    const uniqueKeys = new Set()
    /**
     * For application policy request use apps list
     */
    if (isApplication) {
      allRsrcObjects = slices.applicationList.filter((app) => {
        // Ignore NON-HTTP applications for now
        if (selectedRsrcKeys.includes(createRsrcKey(app)) || getApplicationType(app) !== 'HTTP') {
          return false
        }
        const isUnique = !uniqueKeys.has(createRsrcKey(app))
        if (isUnique) {
          uniqueKeys.add(createRsrcKey(app))
        }
        return isUnique && isAccountResource(account, app)
      })
    } else {
      allRsrcObjects = TargetsSliceNames.reduce((prev, sliceName) => {
        const sliceList = slices[sliceName] || []
        const filteredData = sliceList.filter((rsrc) => {
          if (selectedRsrcKeys.includes(createRsrcKey(rsrc))) return false
          if (rsrc.ObjectMeta.Kind === 'AppRole' && isDynamicAppRole(rsrc)) return false
          let isManaged = isResourceManaged(rsrc)
          if (rsrc.ObjectMeta.Kind === 'KubeNamespace') {
            // Kube namespace is managed/unmanaged through cluster
            const cluster = getObjectRef(rsrc.Spec.Cluster)
            if (!cluster) return false
            isManaged = isResourceManaged(cluster)
          }

          // Check for managed target rsrc and account
          const isUnique = !uniqueKeys.has(createRsrcKey(rsrc))
          if (isUnique) {
            uniqueKeys.add(createRsrcKey(rsrc))
          }

          // Check for managed target rsrc and account
          return isUnique && isAccountResource(account, rsrc) && isManaged
        })
        return [...prev, ...filteredData]
      }, [])
    }
    selectedRsrcsObjects.forEach((rsrc) => {
      const rsrcKey = createRsrcKey(rsrc)
      if (!uniqueKeys.has(rsrcKey)) {
        uniqueKeys.add(rsrcKey)
        allRsrcObjects.push(rsrc)
      }
    })

    return allRsrcObjects
  }

  /**
   * Function which will return array of K8 Resources along with KubeCluster rsrc if it exists.
   *! THIS Function should be called only when isPolicyKubenamespaceRsrcs is true
   * @returns {any[]}
   */
  const getK8RsrcsListItems = () => {
    const allRsrcItems = []
    const selectedRsrcItems = []
    const { KubeAccess } = getTargetsPolicyRules(policy)
    const nsRules = KubeAccess.filter((e) => e.ObjectRef.RefKind === 'KubeNamespace' && e.Principal)

    const nonPrincipalRules = KubeAccess.filter(
      (e) => e.ObjectRef.RefKind === 'KubeNamespace' && !e.Principal
    )

    const clusterRules = KubeAccess.filter((e) => e.ObjectRef.RefKind === 'KubeCluster')
    const nonRules = [...nonPrincipalRules, ...clusterRules]

    nonRules.forEach((rule) => {
      const r = getObjectRef(rule.ObjectRef)
      if (!r) return
      const data = {
        Icon: getRsrcIcon(r),
        key: createRsrcKey(r),
        title: getResourceName(r),
        caption: getApplicationType(r),
        selected: selectedRsrcKeys.includes(createRsrcKey(r)),
        ref: createRef(r),
        onAddClick() {
          setSelectedResources((s) => [createRef(r), ...s])
        },
        onRemoveClick() {
          setSelectedResources((s) => s.filter((e) => createRsrcKey(e) !== createRsrcKey(r)))
        }
      }
      selectedRsrcItems.push(data)
    })

    nsRules.forEach((rule) => {
      const kubeNs = getObjectRef(rule.ObjectRef)
      if (!kubeNs) return []

      const { Deployments, Pods, StatefulSets } = getKubeNamespaceRsrcs(kubeNs)

      const allRsrcs = [] // Includes all the k8 rsrcs belonging to the KNS

      // Custom click functions
      const onAddClick = (key) => {
        setAttributesMap((s) => {
          const props = s[createRsrcKey(kubeNs)] || { K8Rsrcs: [] }
          props.K8Rsrcs.push(key)
          s[createRsrcKey(kubeNs)] = props
          return { ...s }
        })
      }

      const onRemoveClick = (key) => {
        setAttributesMap((s) => {
          const { K8Rsrcs } = s[createRsrcKey(kubeNs)] || { K8Rsrcs: [] }
          s[createRsrcKey(kubeNs)].K8Rsrcs = K8Rsrcs.filter((k) => k !== key)
          return { ...s }
        })
      }

      // The actual selected k8 resources
      const { K8Rsrcs } = attributesMap[createRsrcKey(kubeNs)] || { K8Rsrcs: [] }

      Deployments.map(({ Name }) => {
        const key = `DEPLOY+${Name}`

        // Don push if it is already in selected array
        if (K8Rsrcs.includes(key)) return

        allRsrcs.push({
          Icon: ({ ...props }) => <TargetIcon type='KUBE_DEPLOYMENT' {...props} />,
          key,
          title: `DEPLOY:${Name}`,
          caption: `${getResourceName(kubeNs)}`,
          selected: K8Rsrcs.includes(key),
          ref: rule.ObjectRef,
          onAddClick: () => onAddClick(key),
          onRemoveClick: () => onRemoveClick(key)
        })
      })

      Pods.map(({ Name }) => {
        const key = `POD+${Name}`
        if (K8Rsrcs.includes(key)) return

        allRsrcs.push({
          Icon: ({ ...props }) => <TargetIcon type='KUBE_POD' {...props} />,
          key,
          title: `POD:${Name}`,
          caption: `${getResourceName(kubeNs)}`,
          selected: K8Rsrcs.includes(key),
          ref: rule.ObjectRef,
          onAddClick: () => onAddClick(key),
          onRemoveClick: () => onRemoveClick(key)
        })
      })

      StatefulSets.map(({ Name }) => {
        const key = `STS+${Name}`
        if (K8Rsrcs.includes(key)) return

        allRsrcs.push({
          Icon: ({ ...props }) => <TargetIcon type='KUBE_STATEFULLSET' {...props} />,
          key,
          title: `STS:${Name}`,
          caption: `${getResourceName(kubeNs)}`,
          selected: K8Rsrcs.includes(key),
          ref: rule.ObjectRef,
          onAddClick: () => onAddClick(key),
          onRemoveClick: () => onRemoveClick(key)
        })
      })

      selectedRsrcItems.push(
        ...K8Rsrcs.map((rs) => {
          const [type, name] = rs.split('+')
          return {
            Icon: ({ ...props }) => <TargetIcon {...props} type={getKubeType(type)} />,
            key: rs,
            title: `${type}:${name}`,
            caption: `${getResourceName(kubeNs)}`,
            selected: true,
            ref: rule.ObjectRef,
            onAddClick: () => onAddClick(rs),
            onRemoveClick: () => onRemoveClick(rs)
          }
        })
      )

      allRsrcItems.push(...allRsrcs)
    })

    return [...selectedRsrcItems, ...allRsrcItems]
  }

  const ResourceBar = ({ key, Icon, title, caption, selected, onAddClick, onRemoveClick }) => (
    <ActionBar
      key={key}
      caption={caption}
      leftIcon={<Icon className='rounded-sm' />}
      name={title}
      onClickAddButton={onAddClick}
      onClickRemoveButton={onRemoveClick}
      selected={selected}
      assigned={selected}
    />
  )

  const getListItems = () => {
    if (isPolicyKubenamespaceRsrcs(policy)) return applySearchQuery(getK8RsrcsListItems())
    const resources = getResources()

    return applySearchQuery(
      resources.map((r) => {
        const commonProps = {
          Icon: getRsrcIcon(r),
          key: createRsrcKey(r),
          title: getResourceName(r),
          caption: getApplicationType(r),
          selected: selectedRsrcKeys.includes(createRsrcKey(r)),
          ref: createRef(r),
          onAddClick() {
            setSelectedResources((s) => [createRef(r), ...s])
          },
          onRemoveClick() {
            setSelectedResources((s) => s.filter((e) => createRsrcKey(e) !== createRsrcKey(r)))
          }
        }
        return commonProps
      })
    )
  }

  return <div>{getListItems().map(ResourceBar)}</div>
}

export { Target }
