import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { CreateGroupModal, DeleteGroupModal, useGroupDetailsContext } from 'features/groups'
import { SummaryHeader, getResourceName } from 'features/resources'
import { Button } from 'procyon-ui'
import React, { useState } from 'react'

function Header() {
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const { group } = useGroupDetailsContext()

  const goBackFn = useCanGoBack('/admin/groups')

  const hideDeleteGroup = () => {
    if (group.ObjectMeta.Name === 'admin' || group.ObjectMeta.Name === 'everyone') return true
  }

  return (
    <div>
      <SummaryHeader
        actions={
          <div className='flex gap-4'>
            <Button onClick={() => setShowEditModal(true)} size='md' variant='grayBlue'>
              Edit Group
            </Button>
            {!hideDeleteGroup() && (
              <Button onClick={() => setShowDeleteGroupModal(true)} size='md' variant='grayRed'>
                Delete Group
              </Button>
            )}
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Groups',
            link: '/admin/groups'
          },
          {
            label: getResourceName(group)
          }
        ]}
        rsrcName={getResourceName(group)}
      />
      {showDeleteGroupModal && (
        <DeleteGroupModal
          group={group}
          onCancel={() => setShowDeleteGroupModal(false)}
          onSuccess={() => {
            setShowDeleteGroupModal(false)
            typeof goBackFn === 'function' && goBackFn()
          }}
        />
      )}
      {showEditModal && (
        <CreateGroupModal
          group={group}
          onCancel={() => setShowEditModal(false)}
          onComplete={() => setShowEditModal(false)}
        />
      )}
    </div>
  )
}

export { Header }
