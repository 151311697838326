import { Box, makeStyles } from '@material-ui/core'
import SimpleSearchFilter from 'Components/FilterHeader/FilterComponents/SimpleSearchFilter'
import SingleSelectFilter from 'Components/FilterHeader/FilterComponents/SingleSelectFilter'
import React from 'react'
const useStyle = makeStyles(theme => ({
  root: {
    '& .MuiInput-formControl': {
      marginTop: '0px !important'
    }
  }
}))
const FiltersContainer = ({ filters, onChange }) => {
  const classes = useStyle()
  const chooseFilterComponent = (filter) => {
    switch (filter.filterType) {
      case 'TillDate':
        return <SingleSelectFilter filters={filters} filterOptions={filter.filterOptions} data={false} filterType={filter.filterType} label={filter.label} name={filter.name} onChange={onChange} />
      default:
        return <SimpleSearchFilter filterType={filter.filterType} label={filter.Label} name={filter.Name} onChange={onChange} />
    }
  }
  return (

    <Box className={classes.root} display='flex' alignItems='center'>
      {filters.map(filter => (
        <Box key={filter.name}>
          {chooseFilterComponent(filter)}
        </Box>))}
    </Box>

  )
}

export default FiltersContainer
