import { TabGroup } from 'procyon-ui'
import React from 'react'
import { ApplicationsList } from './components/Tabs/ApplicationsList'
import { AvailableApps } from './components/Tabs/AvailableApps'
import useAppView from 'Core/Hooks/useAppView'
import { createRsrcKey, useResourceDetails } from 'features/resources'

const Applications = () => {
  const { rsrc } = useResourceDetails()
  const { viewMode } = useAppView()

  const tabs = [
    {
      label: 'Active Applications',
      tabContent: <ApplicationsList />
    }
  ]

  if (viewMode == 'admin') {
    tabs.push({
      label: 'Add Application',
      tabContent: <AvailableApps />
    })
  }

  return (
    <TabGroup
      activeIndex={0}
      key={createRsrcKey(rsrc)}
      onChangeTab={function noRefCheck() {}}
      tabs={tabs}
    />
  )
}

export { Applications }
