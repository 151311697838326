import { Box } from '@material-ui/core'
import AmchartsMap from 'Components/AmchartsMap'
import StyledBox from 'Components/StyledBox'
import React, { useMemo, useRef, useCallback } from 'react'
import useMemoSelector from 'Core/Hooks/useMemoSelector'
import { useActiveUsers } from '../ActiveUserContext'
import { getDeviceName } from 'Utils/PureHelperFuctions'

const MapsSegment = () => {
  const { selectedActiveUser } = useActiveUsers()
  const { data: devices } = useMemoSelector('deviceList')
  const usersDevices = useMemo(() => {
    try {
      const temp = []
      devices.forEach(e => {
        if (e?.Attributes?.Labels?.Map?.latitude && e.UserName === selectedActiveUser) {
          temp.push({
            id: e.ObjectMeta.ID,
            tooltip: getDeviceName(e),
            latitude: parseInt(e.Attributes.Labels.Map.latitude, 10),
            longitude: parseInt(e.Attributes.Labels.Map.longitude, 10)
          })
        }
      })
      return temp
    } catch (error) {
      console.error(error)
      return []
    }
  }, [devices, selectedActiveUser])
  const onMarkerClick = useCallback((data) => {
    console.log('onMarkerClick', data)
  }, [])
  const mapDimensions = useRef({ height: '23rem' })
  return (
    <Box p={1}>
      <StyledBox globalClassName='borderBox' height='23.5rem'>
        <AmchartsMap mapDimensions={mapDimensions.current} geoData={usersDevices} onMarkerClick={onMarkerClick} />
      </StyledBox>
    </Box>
  )
}

export default MapsSegment
