import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { DeleteProxyControllerModal } from 'features/proxyControllers'
import { getResourceName } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, ResponsiveTable, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import { compareDatesFn } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal, SearchInput } from 'V2Components'
import { CreateProxyControllerWizard } from '../CreateProxyControllerWizard'

const useSlices = createDataSelectorHook(['proxyControllers', 'accountList'])

const ProxyControllers = () => {
  const [searchKey, setSearchKey] = useState('')
  const [selectedProxyCtrl, setSelectedProxyCtrl] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const history = useHistory()
  const { slices } = useSlices()

  const handleAddClick = () => {
    history.push('/admin/proxy-controllers/create')
  }

  const getRowData = () => {
    return slices.proxyControllers.map((proxyCtrl) => {
      return {
        name: {
          name: getResourceName(proxyCtrl),
          onClick() {
            history.push(`/admin/proxy-controllers/${proxyCtrl.ObjectMeta.Name}`)
          }
        },
        createdOn: proxyCtrl.ObjectMeta.CreatedAt,
        delete: {
          onClick() {
            setShowDeleteModal(true)
            setSelectedProxyCtrl(proxyCtrl)
          }
        }
      }
    })
  }

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h2'>Proxy Controllers</Typography>
        <div className='flex gap-4 mt-3 items-center'>
          <SearchInput sx={{ width: '303px' }} searchKey={searchKey} onChange={setSearchKey} />
          <Button onClick={handleAddClick} size='md' variant='primary' icon={faPlus}>
            Create
          </Button>
        </div>
      </div>
      <div className='mt-6'>
        <ResponsiveTable scrollX='1200px' data={getRowData()} columns={columns()} />
      </div>
      {/* @ts-ignore */}
      <Route exact path={['/admin/proxy-controllers/create']}>
        <FullScreenContentModal>
          <CreateProxyControllerWizard onCancel={() => history.push(`/admin/proxy-controllers`)} />
        </FullScreenContentModal>
      </Route>
      {selectedProxyCtrl && showDeleteModal && (
        <DeleteProxyControllerModal
          proxyController={selectedProxyCtrl}
          onCancel={() => {
            setShowDeleteModal(false)
            setSelectedProxyCtrl(null)
          }}
          cleanUpFn={() => {
            setShowDeleteModal(false)
            setSelectedProxyCtrl(null)
          }}
        />
      )}
    </div>
  )
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
const columns = () => [
  {
    dataIndex: 'name',
    onCell(data) {
      return {
        onClick: () => data.name.onClick()
      }
    },
    title: 'Name',
    fixed: 'left',
    width: 250,
    sorter: (v1, v2) => v1.name.name.localeCompare(v2.name.name),
    render(value) {
      return (
        <Typography className='flex gap-1 items-center' variant='body-regular'>
          {value.name}
        </Typography>
      )
    }
  },
  {
    dataIndex: 'createdOn',
    title: 'Created On',
    fixed: 'left',
    width: 250,
    sorter: (v1, v2) => compareDatesFn({ date: v1.createdOn }, { date: v2.createdOn }),
    render(value) {
      return (
        <Typography className='flex gap-1 items-center' variant='body-regular'>
          {moment(value).format('MMMM Do YYYY, h:mm A')}
        </Typography>
      )
    }
  },

  {
    dataIndex: 'delete',
    onCell(data) {
      return {
        onClick: () => {}
      }
    },
    title: '',
    width: 130,
    fixed: 'right',
    render(value) {
      return (
        <Button variant='grayRed' onClick={() => value.onClick()}>
          Delete
        </Button>
      )
    }
  }
]

export { ProxyControllers }
