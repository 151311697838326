export const parseLogSessionData = (session) => {
  try {
    return session.SessionData.split('\n').map(JSON.parse)
  } catch (error) {
    return null
  }
}


export function convertTimeToMilliseconds(timeString) {
  const regex = /^([\d.]+)(ms|s|m)$/ // Match digits (with or without a decimal point) followed by 'ms', 's', or 'm'
  const match = timeString.match(regex)

  if (!match) {
    return 300
  }

  const value = parseFloat(match[1])
  const unit = match[2]

  if (unit === 'ms') {
    return value
  } else if (unit === 's') {
    return value * 1000
  } else if (unit === 'm') {
    return value * 60000 // 1 minute = 60,000 milliseconds
  } else {
    return 300
  }
}

export const ResourceTypeToKindMap = {
  DB: 'Database',
  SSH: 'Server',
  K8: 'KubeCluster'
}
