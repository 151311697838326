
import { makeStyles } from '@material-ui/core'
import { Storage } from '@material-ui/icons'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import { Theme } from 'Core'
import React from 'react'
import { mostActiveSampleData as data } from './SampleData'
import { options, columns } from './TableMeta'

const useStyle = makeStyles(theme => ({
  location: {
    '& > span': { justifyContent: 'flex-start !important' }
  }
}))

const Locations = () => {
  const classes = useStyle()
  const headerClassNames = { location: classes.location }
  const seriesMeta = [
    {
      name: 'servers',
      label: 'Targets',
      color: Theme.palette.warning.light,
      active: true,
      Icon: ({ className }) => <Storage className={className} />
    },
    {
      name: 'appRole',
      label: 'IAM Role',
      color: Theme.palette.info.light,
      active: true,
      Icon: ({ className }) => <img className={className} src='img/icons/iam-role.png' />
    }

  ]
  const Table = ({ partialData = false, searchString = '' }) => {
    const tableData = partialData ? [...data].slice(0, 5) : data
    return (
      <AnalyticTable
        rowHeight='2.75rem'
        headHeight='2.5rem'
        headerJustify='flex-end'
        vAlign='top'
        searchString={searchString}
        columns={columns(headerClassNames)}
        options={options()}
        data={tableData}
      />
    )
  }
  const ModalComponent = ({ closeModal }) => (
    <ModalView
      searchPlaceholder='Enter Location Name'
      closeModal={closeModal}
      seriesMeta={seriesMeta}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )

  return (
    <ExpandableContainer
      expandButtonLabel='Expand All Locations'
      seriesMeta={seriesMeta}
      modalTitle='Active Locations'
      // containerHeight='19.5rem'
      ModalComponent={ModalComponent}
    >
      <Table partialData />
    </ExpandableContainer>

  )
}

export default Locations
