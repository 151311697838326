import { useCloudDetailsProvider } from 'features/clouds'
import React from 'react'
import { AWSResources } from './components/AWSResources'
import { GCPResources } from './components/GCPResources'
import { AZUREResources } from './components/AZUREResources'

const ResourceTab = () => {
  const { cloud: account } = useCloudDetailsProvider()

  const Type = account.Spec.Type

  return (
    <>
      {Type === 'AWS' && <AWSResources />}
      {Type === 'GCP' && <GCPResources />}
      {Type === 'AZURE' && <AZUREResources />}

    </>
  )
}

export { ResourceTab }
