import {
  faHourglassEnd,
  faHourglassStart,
  faTriangleExclamation
} from '@fortawesome/pro-regular-svg-icons'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAppView from 'Core/Hooks/useAppView'
import { enqueueNotification } from 'Utils/Helpers'
import { Breadcrumbs, FullScreenAlertModal } from 'V2Components'
import { getApplicationType } from 'features/applications'
import {
  EditPolicyIssuedTo,
  EditPolicyResources,
  ExtendPolicyExpiry,
  deletePolicy,
  getPolicyDisplayName,
  getPolicyError,
  getPolicyResourcesRefs,
  getPolicyType,
  isPolicyEditable,
  isWorkloadIdentityPolicy
} from 'features/policy'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { getTargetsPolicyRules } from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import moment from 'moment'
import { Button, DropDownButton, IconButton, Label, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'

function Header({ policy, approvalReq, onErrorClick }) {
  const [showDeletePolicyModal, setShowDeletePolicyModal] = useState(false)
  const [showEditResourcesModal, setShowEditResourcesModal] = useState(false)
  const [showEditIssuedToModal, setShowEditIssuedToModal] = useState(false)
  const [showExtendPolicyExpiryModal, setShowExtendPolicyExpiryModal] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const { appView } = useAppView()

  const { getObjectRef } = useObjectRef(['applicationList', 'bundles'])

  const handleDelete = async () => {
    try {
      await deletePolicy(policy)
      deleteSliceData(policy)
      enqueueNotification('Policy Deleted Successfully!', 'info')
      if (location.key) history.goBack()
      else history.push(`/${appView}/policys`)
    } catch (error) {
      enqueueNotification('Error Deleteing Policy!', 'error')
    }
  }

  const enablePolicyEdit = () => {
    const isEditable = isPolicyEditable(policy)
    if (!isEditable) return false

    if (getPolicyType(policy) === 'Target') {
      const rsrcRefs = getPolicyResourcesRefs(policy)
      const rsrcRefMap = rsrcRefs.reduce((prev, curr) => {
        prev[curr.RefKind] = curr.RefID
        return prev
      }, {})
      // Check and disable policy edit for NON-HTTP policy request
      //@ts-ignore
      if (rsrcRefMap?.Application) {
        const appId = rsrcRefMap['Application']
        const app = getObjectRef({
          RefKind: 'Application',
          RefID: appId
        })
        if (app && getApplicationType(app) !== 'HTTP') return false
        return true
      }
      //Return tru for all targets
      return true
    }

    return true
  }

  const getPolicyEditOptions = () => {
    if (isWorkloadIdentityPolicy(policy))
      return [
        {
          title: 'Resources',
          action() {
            setShowEditResourcesModal(true)
          }
        },
        {
          action: () => setShowExtendPolicyExpiryModal(true),
          title: 'Extend Expiry'
        }
      ]

    return [
      {
        title: 'Resources',
        action() {
          setShowEditResourcesModal(true)
        }
      },
      {
        action: () => setShowEditIssuedToModal(true),
        title: 'Users/Groups'
      },
      {
        action: () => setShowExtendPolicyExpiryModal(true),
        title: 'Extend Expiry'
      }
    ]
  }

  const errors = getPolicyError(policy)
  const bundle = getObjectRef(policy.BundleRef)
  const BundleIcon = getRsrcIcon(bundle)

  return (
    <div className='bg-[#F9FBFC] -m-4 mb-0 -mt-5 px-[32px] py-4'>
      <Breadcrumbs
        breadCrumbs={[
          {
            label: 'Policies',
            link: `/${appView}/policys`
          },
          {
            label: policy.GivenName
          }
        ]}
      />
      <div className='flex justify-between items-center mt-3'>
        <div>
          <Typography variant='h2'>{getPolicyDisplayName(policy)}</Typography>
          <Typography
            className='mt-2 !font-semibold'
            style={{ fontSize: '14px' }}
            variant='caption-regular'
          >
            {policy.ObjectMeta.Name}
          </Typography>
        </div>
        <div className='flex gap-2 items-center'>
          {bundle && (
            <Label
              size='md'
              variant='success'
              icon={<BundleIcon />}
              text={`(${getResourceName(bundle)}) Bundle Policy`}
            />
          )}
          {errors && (
            <IconButton
              icon={faTriangleExclamation}
              onClick={onErrorClick}
              size='sm'
              variant='grayRed'
            />
          )}
          {approvalReq && (
            <Button
              onClick={() =>
                history.push(
                  `/${appView}/access-requests/${encodeURIComponent(approvalReq.ObjectMeta.Name)}`
                )
              }
              variant='grayBlue'
            >
              View Approval Request
            </Button>
          )}
          {appView === 'admin' && (
            <Button onClick={() => setShowDeletePolicyModal(true)} variant='grayRed'>
              Delete Policy
            </Button>
          )}
          {appView === 'admin' && enablePolicyEdit() && (
            <DropDownButton
              menuItems={[
                {
                  action: undefined,
                  submenu: getPolicyEditOptions(),
                  title: 'Edit'
                }
              ]}
              size='sm'
              variant='grayBlue'
            />
          )}
        </div>
      </div>
      <div className='mt-4 flex justify-between items-center'>
        {approvalReq ? (
          <Typography variant='caption-regular'>
            Approved and Created on{' '}
            {moment(policy.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')} by
            <strong> {policy.Creator}</strong>
          </Typography>
        ) : (
          <Typography variant='caption-regular'>
            Created On {moment(policy.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')} by
            <strong> {policy.Creator}</strong>
          </Typography>
        )}
        <div className='flex gap-8 text-[#A6ABB6]'>
          <Typography variant='caption-regular'>
            <FontAwesomeIcon className='mr-3' icon={faHourglassStart} />
            {moment(policy.NotBefore).format('MMM DD, YYYY, h:mm A')}
          </Typography>
          <Typography variant='caption-regular'>
            <FontAwesomeIcon className='mr-3' icon={faHourglassEnd} />
            {moment(policy.NotAfter).format('MMM DD, YYYY, h:mm A')}
          </Typography>
          <Typography className='capitalize' variant='caption-regular'>
            <FontAwesomeIcon className='mr-3' icon={faClock} />
            {moment(policy.NotAfter).fromNow()}
          </Typography>
        </div>
      </div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='grayRed'
        alertMessage='Are you sure you want to delete this policy?'
        loadingMessage='Deleting Policy'
        onActionClick={handleDelete}
        onCancel={() => setShowDeletePolicyModal(false)}
        showModal={showDeletePolicyModal}
      />
      {showEditResourcesModal && (
        <EditPolicyResources
          onCancel={() => setShowEditResourcesModal(false)}
          policy={policy}
          onSuccess={() => setShowEditResourcesModal(false)}
        />
      )}
      {showEditIssuedToModal && (
        <EditPolicyIssuedTo
          policy={policy}
          onCancel={() => setShowEditIssuedToModal(false)}
          onSuccess={() => setShowEditIssuedToModal(false)}
        />
      )}
      {showExtendPolicyExpiryModal && (
        <ExtendPolicyExpiry
          policy={policy}
          onCancel={() => setShowExtendPolicyExpiryModal(false)}
          onSuccess={() => setShowExtendPolicyExpiryModal(false)}
        />
      )}
    </div>
  )
}

export { Header }
