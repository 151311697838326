import { FeatureFlagContext } from 'Core/FeatureFlagContext'
import { useContext, useEffect, useState } from 'react'
/**
 *
 * @returns {{ viewMode: 'admin' | 'user', appView: 'admin' | 'user' }}
 */
const useAppView = () => {
  const context = useContext(FeatureFlagContext)

  const [viewMode, setViewMode] = useState(() => {
    if (context && context.data) return context.data.viewMode
    return 'user'
  })

  if (viewMode !== context?.data?.viewMode) setViewMode(context?.data?.viewMode || 'user')

  return { viewMode, appView: viewMode }
}

export default useAppView
