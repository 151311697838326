import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { deleteAccount } from 'features/clouds'
import { getResourceName } from 'features/resources'
import React from 'react'

const DeleteAccountModal = ({ account, onCancel, onSuccess }) => {
	const groupName = getResourceName(account)

  const handleDelete = async () => {
    try {
      await deleteAccount(account)
      deleteSliceData(account)
      onSuccess?.()
      enqueueNotification('Account deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete account: ${groupName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={account} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteAccountModal }
