import { Container, CssBaseline, makeStyles } from '@material-ui/core'
import { cn } from 'Utils/Helpers'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import '../../assets/layout.css'
import { ErrorFallback } from './components/ErrorFallback'
import { Header } from './components/Header'
import Loader from './components/Loader'
import { Sidemenu } from './components/Sidemenu'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    margin: 'auto',
    padding: 0
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    backgroundColor: '#fff !important'
  }
}))

const LayoutContext = createContext({
  isSideMenuOpen: false
})

const LayoutProvider = ({ children }) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const classes = useStyles()

  return (
    <LayoutContext.Provider value={{ isSideMenuOpen }}>
      <Container className={classes.root}>
        <CssBaseline />
        <Header />
        <Sidemenu onSideMenuStateChange={setIsSideMenuOpen} sideMenuState={isSideMenuOpen} />
        {/* @ts-ignore */}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <main className={cn('bg-white flex-grow pt-0 pr-5 pb-1 pl-8')}>
            <div className={cn('pt-[70px] pl-14')}>
              <div
                className={cn('ml-auto', {
                  'w-[calc(100vw-340px)]': isSideMenuOpen,
                  'w-[calc(100vw-110px)]': !isSideMenuOpen
                })}
              >
                {children}
              </div>
            </div>
          </main>
        </ErrorBoundary>
        <Loader />
      </Container>
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => useContext(LayoutContext)

export { LayoutProvider }
