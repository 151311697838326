import { faCopy, faDatabase, faServer } from '@fortawesome/free-solid-svg-icons'
import { CopyText, EditableLabelContent, LabelContent, SwitchBoxLabelContent } from 'V2Components'
import { useCloudDetailsProvider, useCloudsResources } from 'features/clouds'
import { getResourceName } from 'features/resources'
import { IconButton, Label, TargetIcon } from 'procyon-ui'
import React, { useState } from 'react'
import { SSHPublicKeyLabelContent } from '../SSHPublickeyLabelContent'
import { AccountNotificationChannels } from '../../../AccountNotificationChannels'

const AZUREAccount = ({}) => {
  const { cloud: account } = useCloudDetailsProvider()
  const [description, setDescription] = useState(account.Spec.Description)

  const { getAccountTargets } = useCloudsResources()

  const targets = getAccountTargets(account)

  const namespace = account.ObjectMeta.Namespace
  return (
    <div>
      <LabelContent content={getResourceName(account)} title='Name' />
      <EditableLabelContent
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        title='Description'
        content={account.Spec.Description}
        editMode={false}
        showEmptyLabel
      />
      <LabelContent
        content={<Label text={namespace} variant={namespace === 'default' ? 'grayBlue' : 'gray'} />}
        title='Org'
      />
      <LabelContent
        content={
          <>
            <CopyText
              showText
              messageKey='Tenant ID'
              text={account.Spec.AzureSpec.TenantID}
              Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
            />
          </>
        }
        title='Tenant ID'
      />
      <LabelContent
        content={
          <>
            <CopyText
              showText
              messageKey='Procyon App ID'
              text={account.Spec.AzureSpec.ProcyonAppID}
              Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
            />
          </>
        }
        title='Procyon App ID'
      />

      <LabelContent
        title='Notification Channels'
        content={
          <div className='flex gap-2 flex-wrap'>
            <AccountNotificationChannels
              account={account}
              Fallback={<Label text='No Channels' />}
            />
          </div>
        }
      />
      <LabelContent
        content={<Label iconButton={faServer} text={targets.Server?.length || 0} />}
        title='Server'
      />
      <LabelContent
        content={<Label iconButton={faDatabase} text={targets.Database?.length || 0} />}
        title='Database'
      />

      <LabelContent
        content={
          <Label
            icon={<TargetIcon className='rounded' type='RDP_SERVER' />}
            text={targets.RDPServer?.length || 0}
          />
        }
        title='RDP Server'
      />

      <SSHPublicKeyLabelContent />
    </div>
  )
}

export { AZUREAccount }
