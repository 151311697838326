import { ApiProvider } from "Core";


export const getGithubResources = async() => {
    const response = await new ApiProvider('githubresources').getAll()
    return response.data
  }

export const getGithubTeams = async(app) => {
    const response = await new ApiProvider('github-account').setInstance(app).get()
    return response.data
  }

export const getGithubAllUsers = async() => {
  const response = await new ApiProvider('users').getAll()
  return response.data
}  

export const deleteRepository = async (app) => {
  await new ApiProvider('githubresources').setInstance(app).delete()
  return app
}

export const deletePolicy = async (app) => {
  await new ApiProvider('pacpolicys').setInstance(app).delete()
  return app
}

export const deleteUser = async (app) => {
  const response = await new ApiProvider('github-account').setInstance(app).put()
  return response
}