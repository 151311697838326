import { useMultiOrg } from 'Core/Hooks/useMultiOrg'
import { enqueueNotification, getCurrentOrg } from 'Utils/Helpers'
import { FullScreenAlertModal, FullScreenContentModal, LoadingFeedback, Wizard } from 'V2Components'
import { AddAccountWizardProvider, useAddAccountWizardContext } from 'features/clouds'
import { getResourceName, onlyLettersAndNumbers } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import {
  LabelCheckbox,
  SelectDropDown,
  TargetIcon,
  TextInput,
  ToggleCloudControl,
  Typography
} from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAwsSteps } from './components/AwsSteps'
import { useAZURESteps } from './components/AzureSteps'
import { FooterComponent } from './components/FooterComponent'
import { useGcpSteps } from './components/GcpSteps'
const useSlices = createDataSelectorHook(['accountList'])

const AddAccountWizard = ({ account, onCancel, onComplete }) => {
  //@ts-ignore
  const { cloudType: ct } = useParams()
  const [cloudType, setCloudType] = useState(account?.Spec.Type || ct?.toUpperCase() || 'AWS')
  const [isCurrentOrgSelected, setIsCurrentOrgSelected] = useState(true)
  const [isCredChecking, setIsCredChecking] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const history = useHistory()

  const { slices } = useSlices()
  const { activeOrg, adminOrgs } = useMultiOrg()
  const {
    setName,
    setDescription,
    name,
    description,
    checkAccountCredentials,
    createAccount,
    isEditMode,
    updateAccount,
    org,
    setOrg
  } = useAddAccountWizardContext()

  const awsSteps = useAwsSteps()
  const gcpSteps = useGcpSteps()
  const azureSteps = useAZURESteps()
  const getErrors = () => {
    const accountNamesLowerCase = slices.accountList.map((e) => e.ObjectMeta.Name.toLowerCase())
    if (!description.trim().length) return 'Description cannot be empty'
    if (accountNamesLowerCase.includes(name.trim().toLowerCase()) && !isEditMode)
      return 'Account with the name already exists.'
    if (name.trim().length < 3) return 'Name cannot be less than 3 characters.'
    if (/\s/g.test(name)) return 'Name cannot have spaces.'
    if (!onlyLettersAndNumbers(name)) return 'Only Letters and number are allowed in name.'
    return ''
  }

  const handleVerifyAndAddClick = async () => {
    try {
      setIsCredChecking(true)
      const isValid = await checkAccountCredentials(cloudType)
      if (isValid) {
        setIsCredChecking(false)
        setIsCreating(true)
        if (isEditMode) {
          await updateAccount(cloudType)
          enqueueNotification('Account updated successfully!', 'info')
          onComplete?.()
        } else {
          if (onComplete) onComplete()
          else history.push(`/admin/clouds/${cloudType}`)
          await createAccount(cloudType)
          enqueueNotification('Account added successfully!', 'info')
        }
        setIsCreating(false)
      } else setShowErrorModal(true)
    } catch (error) {
    } finally {
      setIsCredChecking(false)
      setIsCreating(false)
    }
  }

  const getSteps = () => {
    const steps = [
      {
        label: 'Account Info',
        content: (
          <div>
            <TextInput
              className='mb-2'
              label='Name*'
              disabled={isEditMode}
              sx={{ width: '100%' }}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextInput
              multiline
              rows={3}
              sx={{ width: '100%' }}
              label='Description*'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {!isEditMode && activeOrg === 'default' && (
              <div className='my-4'>
                <LabelCheckbox
                  name='Add account on default organization.'
                  checked={isCurrentOrgSelected}
                  onChange={() => {
                    if (!isCurrentOrgSelected) setOrg(activeOrg)
                    setIsCurrentOrgSelected((s) => !s)
                  }}
                />
                {!isCurrentOrgSelected && (
                  <>
                    <Typography className='mb-2' variant='body-regular'>
                      Select an org to add account.
                    </Typography>
                    <SelectDropDown
                      width='200px'
                      menuItems={adminOrgs.map((e) => ({
                        label: e,
                        value: e
                      }))}
                      onChange={(e) => setOrg(e.target.value)}
                      value={org}
                    />
                    <Typography className='mt-2' variant='body-regular'>
                      The account will be added to <strong>{org}</strong> org.
                    </Typography>
                  </>
                )}
              </div>
            )}

            <div className='my-1'>
              {!isEditMode && (
                <ToggleCloudControl
                  ariaLabel='cloud-select'
                  onChange={(_, v) => v && setCloudType(v.toUpperCase())}
                  options={['AWS', 'GCP', 'AZURE']}
                  value={cloudType}
                />
              )}
            </div>
          </div>
        )
      }
    ]
    if (cloudType === 'AWS') return [...steps, ...awsSteps]
    if (cloudType === 'GCP') return [...steps, ...gcpSteps]
    if (cloudType === 'AZURE') return [...steps, ...azureSteps]
    return []
  }

  const getIcon = () => {
    const map = {
      AWS: 'AWS_APPLICATION',
      GCP: 'GOOGLE_APPLICATION',
      AZURE: 'AZURE_APPLICATION'
    }

    return map[cloudType]
  }

  const getLoadingMessage = () => {
    if (isCredChecking) return 'Checking Account Credentials'
    if (isEditMode && isCreating) return `Updating account ${getResourceName(account)}`
    if (isCreating) return 'Adding Account'
  }

  const iconType = getIcon()

  return (
    <FullScreenContentModal>
      <Wizard
        header={
          <div className='flex gap-2 items-center'>
            <TargetIcon type={iconType} /> {isEditMode ? 'Edit' : 'Add'} {cloudType} Account
          </div>
        }
        onCancel={() => {
          if (onCancel) onCancel()
          else history.push(`/admin/clouds/${ct?.toUpperCase()}`)
        }}
        steps={getSteps()}
        FooterComponent={(props) => (
          <FooterComponent
            {...props}
            errors={getErrors()}
            cloudType={cloudType}
            onVerifyAndAddBtnClick={handleVerifyAndAddClick}
          />
        )}
      />
      <LoadingFeedback
        loading={isCredChecking}
        message='Checking Account Credentials'
        caption='Please wait..'
      />
      <LoadingFeedback loading={isCreating} message={getLoadingMessage()} caption='Please wait..' />
      <FullScreenAlertModal
        actionButtonText='Okay'
        actionButtonVariant='primary'
        alertMessage='Error Verifying Account Credentials'
        loadingMessage=''
        onActionClick={() => setShowErrorModal(false)}
        onCancel={() => setShowErrorModal(false)}
        showModal={showErrorModal}
        Content={
          <Typography variant='body-regular'>
            The {cloudType} Account Credentials entered are invalid. Please re-check the credentials
            and try again.
          </Typography>
        }
      />
    </FullScreenContentModal>
  )
}

const WrappedAddAccountWizard = ({
  account = null,
  onCancel = null,
  onComplete = null,
  ...props
}) => (
  <AddAccountWizardProvider account={account}>
    <AddAccountWizard account={account} onCancel={onCancel} onComplete={onComplete} {...props} />
  </AddAccountWizardProvider>
)

export { WrappedAddAccountWizard as AddAccountWizard }
