import { Box, Typography } from '@material-ui/core'
import React from 'react'
import FiltersContainer from '../FiltersContainer'

const Header = ({
  title,
  filters,
  onChange,
  InfoSegment,
  px = 0.5,
  DatasetComponent,
  showDataSet,
  selectedDataSet,
  dataSetKeys,
  handleDataSetChange,
  titleClass,
  headerVerticalAlignment = 'center'
}) => {
  return (
    <Box
      px={px}
      display='flex'
      mb={1}
      justifyContent='space-between'
      alignItems={headerVerticalAlignment}
    >
      <Typography className={titleClass} variant='h6'>{title}</Typography>
      <Box alignItems='center' width='50%' display='flex'>
        {InfoSegment && <InfoSegment />}
        {DatasetComponent && showDataSet && (
          <Box mr={2.375} flexGrow={1}>
            <DatasetComponent
              fullwidth
              selectedDataSet={selectedDataSet}
              dataSetKeys={dataSetKeys}
              handleDataSetChange={handleDataSetChange}
            />
          </Box>
        )}
        <Box ml='auto'>

          <FiltersContainer filters={filters} onChange={onChange} />
        </Box>
      </Box>
    </Box>
  )
}

export default Header
