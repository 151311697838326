import { faCircleMinus, faUser } from '@fortawesome/pro-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityPicker, FullScreenContentModal, SearchInput } from 'V2Components'
import { createRsrcKey } from 'features/resources'
import moment from 'moment'
import { Button, Label, SelectDate, SelectDropDown } from 'procyon-ui'
import React, { useRef, useState } from 'react'

const Filters = ({ searchhKey, setSearchhKey, onApplyClick, filters, onFilterChange }) => {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [showSelectUsers, setShowSelectUsers] = useState(false)

  const _prevUsers = useRef([])

  const { slices } = useMultiSlice(['userList'])

  const handleUserRemove = (key) => {
    setSelectedEntities((s) => s.filter((e) => createRsrcKey(e) !== key))
  }

  const handleFilterApplyClick = () => {
    /**
     * For endTime, if the endTime is the today, then we use the current system time to get
     * the latest logs till the current time.
     */
    onApplyClick({
      ...filters,
      UserFilter: selectedEntities.map((e) => e.ObjectMeta.Name),
      StartTime: new Date(filters.StartTime),
      EndTime:
        filters.EndTime === moment().format('YYYY-MM-DD') ? new Date() : new Date(filters.EndTime)
    })
  }

  return (
    <div>
      <div className='border border-[#D8DDE4] px-4 py-3 rounded-md flex justify-between items-center'>
        <div className='flex items-center gap-4'>
          From
          <SelectDate
            onChange={(e) => onFilterChange({ StartTime: e.target.value })}
            value={filters.StartTime}
          />
          To
          <SelectDate
            onChange={(e) => onFilterChange({ EndTime: e.target.value })}
            value={filters.EndTime}
          />
          <SelectDropDown
            width='150px'
            menuItems={[
              {
                label: 'All Resources',
                value: 'ALL_RESOURCES'
              },
              {
                label: 'RDP Logs',
                value: 'RDP'
              },
              {
                label: 'SSH Logs',
                value: 'SSH'
              },
              {
                label: 'DB Logs',
                value: 'DB'
              },
              {
                label: 'K8 Logs',
                value: 'K8'
              },
              {
                label: 'ECS Logs',
                value: 'ECS'
              }
            ]}
            onChange={(e) => onFilterChange({ ResoureceType: e.target.value })}
            value={filters.ResoureceType}
          />
          <SelectDropDown
            showHelpText={false}
            width='150px'
            menuItems={[
              {
                label: 'All Audit Logs',
                value: 'ALL_AUDITED'
              },
              {
                label: 'Audited',
                value: 'Audited'
              },
              {
                label: 'Non-Audited',
                value: 'NonAudited'
              }
            ]}
            onChange={(e) => onFilterChange({ QueryType: e.target.value })}
            value={filters.QueryType}
          />
          <Button
            onClick={() => {
              // Preserver currently selected users for restoration
              _prevUsers.current = selectedEntities
              setShowSelectUsers(true)
            }}
            size='md'
            variant='grayBlue'
            icon={faUser}
          >
            Select Users
          </Button>
          <Button onClick={handleFilterApplyClick} size='md' variant='primary'>
            Apply
          </Button>
        </div>
        <div>
          <SearchInput sx={{ width: '303px' }} searchKey={searchhKey} onChange={setSearchhKey} />
        </div>
        {showSelectUsers && (
          <FullScreenContentModal width='auto'>
            <EntityPicker
              title='Select Users for Query Filter'
              entities={slices.userList}
              selectedEntities={selectedEntities}
              setSelectedEntities={setSelectedEntities}
              onCancel={() => {
                // Restore previously selected users on cancel
                setSelectedEntities(_prevUsers.current)
                setShowSelectUsers(false)
              }}
              onContinue={() => {
                // No need to restore on continue because we dont want to ;)
                setShowSelectUsers(false)
              }}
            />
          </FullScreenContentModal>
        )}
      </div>
      <div className='flex gap-2 mt-2'>
        {selectedEntities.map((u) => {
          return (
            <Label
              onClick={() => {
                handleUserRemove(createRsrcKey(u))
              }}
              variant='grayBlue'
              iconButton={faCircleMinus}
              text={u.ObjectMeta.Name}
            />
          )
        })}
      </div>
    </div>
  )
}

export { Filters }
