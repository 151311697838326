export const AwsRsrcType = {
  AWS_Glue_Workflow: 'AWS_Glue_Workflow',
  AWS_Cloudwatch_Dashboard: 'AWS_Cloudwatch_Dashboard',
  AWS_Cloudwatch_Metri: 'AWS_Cloudwatch_Metri',
  AWS_SNS_Topic: 'AWS_SNS_Topic',
  AWS_Athena_Data_Cata: 'AWS_Athena_Data_Cata',
  AWS_Cognito_Identity: 'AWS_Cognito_Identity',
  AWS_Glue_Job: 'AWS_Glue_Job',
  AWS_Redshift_Cluster: 'AWS_Redshift_Cluster',
  AWS_GuardDuty_Detect: 'AWS_GuardDuty_Detect',
  AWS_DYNAMO_DB: 'AWS_DYNAMO_DB',
  AWS_Athena_Data_Catalog: 'AWS_Athena_Data_Catalog',
  AWS_Cognito_Identity_Pool: 'AWS_Cognito_Identity_Pool',
  AWS_Cloudwatch_Metric_Stream: 'AWS_Cloudwatch_Metric_Stream',
  AWS_GuardDuty_Detector: 'AWS_GuardDuty_Detector'
}
