import { BUNDLE_TYPE_ICON_TYPE, useBundlesUserAccess } from 'features/bundles'
import { getResourceName, getRsrcIcon } from 'features/resources'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BundlesGridView } from '../../BundlesGridView'
import { Typography } from 'procyon-ui'
import useAppView from 'Core/Hooks/useAppView'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { AdvancedSearch } from 'V2Components'

const InProgressBundles = () => {
  //@ts-ignore
  const { bundleType } = useParams()
  const [searchKey, setSearchKey] = useState('')

  const history = useHistory()
  const { appView } = useAppView()

  const {
    bundleAccessMap: { inProgress }
  } = useBundlesUserAccess(bundleType.toUpperCase())

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const { name } = row

      const all = `${name}`.toLowerCase()
      return all.includes(queryKey)
    }
  })
  const getBundlesData = () => {
    const data = []

    inProgress.forEach((bundle) => {
      data.push({
        name: getResourceName(bundle),
        Icon: getRsrcIcon(bundle),
        status: 'inprogress',
        iconType: BUNDLE_TYPE_ICON_TYPE[bundle.Type],
        onMoreInfoClick() {
          history.push(`/${appView}/bundles/${bundleType.toLowerCase()}/${bundle.ObjectMeta.Name}`)
        }
      })
    })

    return applySearchQuery(data)
  }

  const data = getBundlesData()

  return (
    <div>
      {inProgress.length === 0 && (
        <Typography variant='body-regular'>No bundles in approval process.</Typography>
      )}
      {inProgress.length > 0 && (
        <>
          <AdvancedSearch
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            filterSaveKey='bundles-inprogress'
          />
          <BundlesGridView data={data} />
        </>
      )}
    </div>
  )
}

export { InProgressBundles }
