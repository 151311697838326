import { faCopy } from '@fortawesome/free-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { CopyText, FullScreenContentModal } from 'V2Components'
import { getResourceName, getRsrcIcon, reverseRsrcKey } from 'features/resources'
import { getWorkloadTokens } from 'features/workloads'
import moment from 'moment'
import { Button, IconButton, LoadingModal, Typography } from 'procyon-ui'
import React, { useState } from 'react'

function WorkloadAccessTokenModal({ workload, onClose }) {
  const [isFetching, setIsFetching] = useState(false)
  const [refreshLink, setRefreshLink] = useState(true)
  const { getObjectRef } = useMultiSlice(['accountList', 'gcpResources', 'projects'])
  const [tokens, setTokens] = useState({})

  const handleLinkRefresh = async () => {
    try {
      setIsFetching(true)
      const refMap = {}
      const data = await getWorkloadTokens(workload)
      const {
        WorkloadToken: { AccessTokens }
      } = data
      AccessTokens.forEach((tokenObj) => {
        const { Id } = tokenObj
        const objKeys = Id.split(':')
        const refKey = `${objKeys[0]}+${objKeys[2]}`
        refMap[refKey] = tokenObj
      })
      setTokens(refMap)
    } catch (error) {
      enqueueNotification('Error getting tokens.', 'error')
    } finally {
      setIsFetching(false)
    }
  }

  const renderTokens = () => {
    return Object.keys(tokens).map((key) => {
      const obj = getObjectRef(reverseRsrcKey(key))

      if (!obj) return null

      const { ExpiresAt, Token } = tokens[key]
      const Icon = getRsrcIcon(obj)

      return (
        <div className='border-b mb-4'>
          <div className='flex gap-2'>
            <Icon />
            <Typography className='!font-semibold' variant='h3'>
              {getResourceName(obj)}
            </Typography>
          </div>
          <div className='pl-4 mt-3'>
            <div className='mb-4 w-[100%]'>
              <Typography variant='body-regular'>Expires At</Typography>
              <div className='rounded-sm bg-gray-100 p-2 mt-2 mr-2'>
                <Typography className='break-all' variant='body-regular'>
                  {moment(ExpiresAt).format('MMM DD, YYYY, h:mm A')}
                </Typography>
              </div>
            </div>
            <div className='mb-4 w-[100%]'>
              <Typography variant='body-regular'>Token Decryption Command</Typography>
              <div className='rounded-sm bg-gray-100 p-2 mt-2 mr-2'>
                <CopyText
                  maxCharLen={10000}
                  showText
                  messageKey='Token Decryption Command'
                  text={`echo "${Token}" | base64 -d | openssl aes-256-cbc -d -pbkdf2`}
                  Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
                />
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  if (refreshLink) {
    setRefreshLink(false)
    handleLinkRefresh()
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between'>
        <Typography variant='h4-regular'>
          Access Tokens for <strong className='ml-1'>{getResourceName(workload)}</strong>
        </Typography>
        <Button variant='gray' onClick={onClose}>
          Close
        </Button>
      </div>
      {!isFetching && (
        <div className='h-[480px] pt-4 w-[100%] overflow-auto'>
          <div>{renderTokens()}</div>
        </div>
      )}
      {isFetching && (
        <div className='h-[480px] pt-4 w-[100%] flex justify-center items-center'>
          <LoadingModal shadow={false} caption='Please wait..' message='Fetching Access Tokens' />
        </div>
      )}
      <div className='flex justify-end mt-2'>
        <Button disabled={isFetching} onClick={() => setRefreshLink(true)} variant='grayBlue'>
          Refresh Tokens
        </Button>
      </div>
    </FullScreenContentModal>
  )
}

export { WorkloadAccessTokenModal }
