import React from 'react'
import classNames from 'classnames'
import { Chip, makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  chip: {
    // @ts-ignore
    marginTop: props => theme.spacing(props.mt),
    // @ts-ignore
    marginBottom: props => theme.spacing(props.mb),
    // @ts-ignore
    marginRight: props => theme.spacing(props.mr),
    // @ts-ignore
    marginLeft: props => theme.spacing(props.ml),
    // @ts-ignore
    height: props => theme.spacing(props.height),
    padding: theme.spacing(0.25),
    paddingTop: '0rem',
    paddingBottom: '0rem',
    fontSize: props => props.fontSize || '0.813rem'
  },
  primary: {
    //! FIXME - color not found
    backgroundColor: 'rgba(33, 150, 243, 0.1)'
  },
  secondary: {
    backgroundColor: theme.palette.grey[200]
  },
  error: {
    //! FIXME - color not found
    backgroundColor: 'rgba(244, 67, 54, 0.1)'
  },
  success: {
    //! FIXME - color not found
    backgroundColor: 'rgba(76, 175, 80,0.1)'
  },
  info: {
    //! FIXME - color not found
    backgroundColor: 'rgba(33 ,150, 243,0.1)'
  },
  warning: {
    //! FIXME - color not found
    backgroundColor: 'rgba(255, 152, 0, 0.1)'
  },
  primary_color: {
    color: theme.palette.primary.main
  },
  secondary_color: {
    color: theme.palette.primary.light
  },
  error_color: {
    color: theme.palette.error.main
  },
  success_color: {
    color: theme.palette.success.main
  },
  warning_color: {
    color: theme.palette.warning.main
  },
  info_color: {
    color: theme.palette.info.main
  }
}))
const DataChip = ({ label, color, Icon, variant = 'secondary', fontSize = '', height = '', ml = 0, mr = 0, mt = 0, mb = 0, size = 'medium' }) => {
  const classes = useStyles({ height, ml, mr, mt, mb, fontSize })
  return (
    <Chip
      size={size}
      className={classNames(classes.chip, classes[variant])}
      label={label}
      color={color}
      icon={Icon && <Icon className={classNames(classes[`${variant}_color`])} fontSize='small' />}
    />
  )
}

export default DataChip
