import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createRef, getRsrcIcon } from 'features/resources'
import { ResourceKinds, customTagKeys } from 'features/settings'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { Button, CloudIcon, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { SelectEntity } from '../SelectEntity'

const useSlices = createDataSelectorHook(['accountList', 'userList', 'groupList', 'tagPolicys'])

const ApproverPolicyCreateModal = ({ onCancel, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { getObjectRef } = useObjectRef(['accountList', 'userList', 'groupList'])
  const { slices } = useSlices()

  const [selectedResourceValue, setSelectedResourceValue] = useState([])
  const [showEntityModal, SetShowEntityModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [tagKey, setTagKey] = useState('')
  const [tagValue, setTagValue] = useState('')
  const [policyName, setPolicyName] = useState('')
  const [selectedEntity, setSelectedEntity] = useState([])
  const [selectedEntitiesList, setSelectedEntitiesList] = useState([])
  const user = getUserInfo()

  const userAndGroupEntity = () => {
    return [...slices.userList, ...slices.groupList]
  }

  const getErrors = () => {
    try {
      if (policyName.trim() === '')
        return 'Reader Name is required and special characters are not allowed.'
      if (!tagKey) return 'Policy key is required.'
      if (!tagValue) return 'Policy value is required.'
      if (selectedResourceValue.length === 0) return 'Please Select any one Resources'
      if (selectedEntity.length === 0) return 'Please Select Reader'
    } catch (error) {
      return 'error'
    }
  }

  const errors = getErrors()

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const payload = {
        ObjectMeta: {
          Name: policyName,
          NameSpace: user.org,
          Tenant: user.tenant
        },
        SearchString: tagKey + ':' + tagValue,
        KindMap: getResourcesMapObject(),
        Readers: { ObjectRef: selectedEntity }
      }
      const data = await reduxApiClient('visibilitys').create(payload)
      pushToSlice(data)
      enqueueNotification('Read created successfully!', 'info')
      onSuccess()
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
      console.log(error)
      enqueueNotification('Failed to create Read!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const accountDropdownOptions = [
    {
      label: 'Please Select',
      value: '0',
      cloudType: ''
    },
    ...(slices?.accountList?.map((account, index) => ({
      label: (
        <>
          <CloudIcon
            type={account.Spec.Type?.toLowerCase()}
            style={{ marginRight: '10px', display: 'inline' }}
          />{' '}
          {account.ObjectMeta.Name}
        </>
      ),
      value: (index + 1).toString(), // Adjust the value to start from 1
      cloudType: account.Spec.Type
    })) || [])
  ]

  //Sort the menu items by cloud type in ascending order
  accountDropdownOptions.sort((a, b) => a.cloudType.localeCompare(b.cloudType))

  const getResourcesMapObject = () => {
    const updatedObject = {}
    for (const item of selectedResourceValue) {
      updatedObject[item] = 'true'
    }
    const finalUpdatedObject = {
      Map: updatedObject
    }
    return finalUpdatedObject
  }

  const handleEntityModalContinue = async () => {
    SetShowEntityModal(false)
    const ref_info_array = selectedEntities.map(createRef)
    const selectedEntitesList = getObjectRef(ref_info_array)
    setSelectedEntity(ref_info_array)
    setSelectedEntitiesList(selectedEntitesList)
  }

  const getFilterResources = () => {
    let filteredList = ResourceKinds.filter(
      (item) => item.kind !== 'MedusaNode' && item.kind !== 'Project'
    )

    return filteredList.map((e) => ({ label: e.label, value: e.kind }))
  }

  const getTagPolicyValueOptions = (tagKey = '') => {
    const tagValuesMap = {}

    slices.tagPolicys.forEach((tagPolicy) => {
      const value = tagPolicy.Tags.Map[tagKey]
      if (value) tagValuesMap[value] = true
    })

    const tagValues = Object.keys(tagValuesMap)

    return tagValues.map((val) => ({ label: val, value: val }))
  }

  const handleNameInput = (value) => {
    // Use a regular expression to replace any character that is not a letter or number with an empty string
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
    // Update the form state with the sanitized value
    setPolicyName(sanitizedValue)
  }

  return (
    <>
      <FullScreenContentModal>
        <div>
          <Typography className='mb-4' variant='h4-regular'>
            Create Policy
          </Typography>

          <TextInput
            value={policyName}
            onChange={(e) => handleNameInput(e.target.value)}
            sx={{ width: '100%' }}
            label='Read Policy Name'
          />

          <div className=''>
            <div className='flex gap-4 mt-4 items-center'>
              <div>
                <Typography variant='body-regular' className='mb-1'>
                  Select Key
                </Typography>
                <SelectDropDown
                  sx={{ minWidth: '150px' }}
                  menuItems={customTagKeys.map((e) => ({ label: e.label, value: e.kind }))}
                  onChange={(e) => {
                    setTagKey(e.target.value)
                    setTagValue('')
                  }}
                  value={tagKey}
                />
              </div>

              <div>
                <Typography variant='body-regular' className='mb-1'>
                  Select Value
                </Typography>
                <SelectDropDown
                  sx={{ minWidth: '150px' }}
                  menuItems={getTagPolicyValueOptions(tagKey)}
                  onChange={(e) => setTagValue(e.target.value)}
                  value={tagValue}
                />
              </div>
            </div>
          </div>

          <SelectDropDown
            label='Select Resources Type'
            placeholder='Select Resources Type'
            menuItems={getFilterResources()}
            onChange={(e) => setSelectedResourceValue(e.target.value)}
            value={selectedResourceValue}
            multiple={true}
            style={{
              width: '100%',
              margin: '20px 0 20px 0'
            }}
          />

          <div className='flex gap-2 items-center mt-5 flex-wrap'>
            <Button
              icon={faUsers}
              iconPosition='end'
              onClick={() => SetShowEntityModal(true)}
              size='sm'
              variant='primary'
            >
              Add Readers
            </Button>
            {selectedEntitiesList?.map((user) => {
              const Icon = getRsrcIcon(user)
              return (
                <Label
                  variant='grayBlue'
                  key={user.ObjectMeta.Name}
                  text={user.ObjectMeta.Name}
                  icon={<Icon />}
                />
              )
            })}
          </div>

          <div className='flex justify-between items-center gap-4 mt-6'>
            {errors ? <Label variant='warning' text={errors} /> : <div></div>}
            <div className='flex gap-4'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={!!errors} variant='primary'>
                Create
              </Button>
            </div>
          </div>
          <LoadingFeedback message='Creating Tag' loading={isLoading} caption='Please wait..' />
        </div>
      </FullScreenContentModal>

      {showEntityModal && (
        <FullScreenContentModal width='auto'>
          <SelectEntity
            showPermission={''}
            title={`Select Reader`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={userAndGroupEntity()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              SetShowEntityModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}
    </>
  )
}

export { ApproverPolicyCreateModal }
