import { ApiProvider } from 'Core'
import { APPROVED_STATUS } from '../utils'

/**
 * This function will get the particular approval request
 * @param {string} requestName
 * @returns
 */
export const fetchApprovalRequest = async (requestName) => {
  const response = await new ApiProvider('approvalreqs')
    .setInstance({ ObjectMeta: { Name: requestName } })
    .get()
  return response?.data
}

export const approveApprovalRequest = async (request = {}, comments) => {
  const clone = structuredClone(request)
  if (request.Type === 'ServiceAccount') {
    clone.Status = 'Approved'
  } else {
    const approvedResources = request.Spec.Resources.Resource.map((e) => ({
      ...e,
      Approved: 'Approved'
    }))
    clone.Spec.Resources.Resource = approvedResources
  }

  clone.Spec.ApproverComments = comments

  const response = await new ApiProvider('approvalreqs').setInstance(clone).put()
  return response.data
}

export const rejectApprovalRequest = async (request = {}, comments) => {
  const clone = structuredClone(request)
  if (request.Type === 'ServiceAccount') {
    clone.Status = 'Rejected'
  } else {
    const approvedResources = request.Spec.Resources.Resource.map((e) => ({
      ...e,
      Approved: 'Rejected'
    }))
    clone.Spec.Resources.Resource = approvedResources
  }
  clone.Spec.ApproverComments = comments

  const response = await new ApiProvider('approvalreqs').setInstance(clone).put()
  return response.data
}

export const deleteApprovalRequest = async (request) => {
  await new ApiProvider('approvalreqs').setInstance(request).delete()
  return request
}

export const createAutoApprovePolicy = async (request, notAfter) => {
  if (!request) return
  const { Name } = request.ObjectMeta // Policy name same as approval request name
  // Issued to the user who it was requested for
  const IssuedTo = request.Spec.RequestedFor.ObjectRef[0]
  const { Target, Action, Principal, Services } = request.Spec.Resources.Resource[0]

  const data = {
    ObjectMeta: { Name },
    Spec: {
      IssuedTo,
      Target,
      Action,
      Principal,
      Services
    },
    NotAfter: notAfter,
    GivenName: request.GivenName
  }
  const response = await new ApiProvider('paapolicys').setInstance(data).post()
  return response.data
}

export const deleteAutoApprovePolicy = async (policy) => {
  const response = await new ApiProvider('paapolicys').setInstance(policy).delete()
  return response.data
}

/**
 * This function will update the approval request
 * @param {object} request
 * @returns
 */
export const updateApprovalRequest = async (request) => {
  const response = await new ApiProvider('approvalreqs').setInstance(request).put()
  return response?.data
}

/**
 * This function will move the approval request to cancelled state
 * @param {object} request
 */
export const cancelApprovalRequest = async (request) => {
  const payload = structuredClone(request)
  payload.Status = APPROVED_STATUS.Cancelled
  const response = await new ApiProvider('approvalreqs').setInstance(payload).put()
  return response.data
}

/**
 * This function will move the approval request to Closed state
 * @param {object} request
 */
export const closeApprovalRequest = async (request) => {
  const payload = structuredClone(request)
  payload.Status = APPROVED_STATUS.Close
  const response = await new ApiProvider('approvalreqs').setInstance(payload).put()
  return response.data
}

/**
 * This function will move the approval request to cancelled state
 * @param {object} request
 */
export const reRequestApprovalRequest = async (request) => {
  const payload = structuredClone(request)
  payload.Status = APPROVED_STATUS.Pending
  const response = await new ApiProvider('approvalreqs').setInstance(payload).put()
  return response.data
}