import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodataWorldHigh from '@amcharts/amcharts4-geodata/worldHigh'

import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import _ from 'lodash'
import { am4core } from 'Core'

const AmchartsMap = ({ geoData, onMarkerClick, mapDimensions, mapPosition, zoomOnHit = false }) => {
  const series = useRef(null)
  const activeTarget = useRef(null)

  useLayoutEffect(() => {
    am4core.options.autoDispose = true
    const chart = am4core.create('chartdiv', am4maps.MapChart)

    // Set map definition
    chart.geodata = am4geodataWorldHigh

    // Set projection
    chart.projection = new am4maps.projections.Miller()

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl()

    // Let's add some buttons
    const homeButton = chart.chartContainer.createChild(am4core.Button)
    homeButton.padding(5, 5, 5, 5)
    homeButton.align = 'right'
    homeButton.marginRight = 15
    /** Handle click on home button */
    homeButton.events.on('hit', function () {
      /** If active point is present */
      if (activeTarget.current) {
        /** Zoom to active point */
        activeTarget.current.series.chart.zoomToMapObject(activeTarget.current)
      } else {
        /** Else recenter the map */
        chart.goHome(500)
      }
    })
    homeButton.icon = new am4core.Sprite()
    homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'

    // Create map polygon series
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true
    polygonSeries.exclude = ['AQ']

    // Create image series
    series.current = chart.series.push(new am4maps.MapImageSeries())

    // Create image
    const imageSeriesTemplate = series.current.mapImages.template
    imageSeriesTemplate.properties.fill = am4core.color('#64b5f6')
    const marker = imageSeriesTemplate.createChild(am4core.Sprite)
    marker.path =
    'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0'
    marker.maxWidth = 28
    marker.maxHeight = 28
    marker.nonScaling = true
    marker.tooltipText = '{tooltip}'
    marker.tooltipX = am4core.percent(50)
    marker.tooltipY = am4core.percent(0)
    marker.horizontalCenter = 'middle'
    marker.verticalCenter = 'middle'

    // Set property fields
    imageSeriesTemplate.propertyFields.latitude = 'latitude'
    imageSeriesTemplate.propertyFields.longitude = 'longitude'
    imageSeriesTemplate.propertyFields.isActive = 'active'

    const hoverState = imageSeriesTemplate.states.create('hover')
    hoverState.properties.fill = am4core.color('#2196f3')

    const activeState = imageSeriesTemplate.states.create('active')
    activeState.properties.fill = am4core.color('#1976d2')

    imageSeriesTemplate.events.on('inited', (e) => {
      if (e.target.isActive) {
        activeTarget.current = e.target
        if (zoomOnHit) {
          e.target.series.chart.zoomToMapObject(e.target)
        }
      }
    })

    imageSeriesTemplate.events.on('hit', (e) => {
      onMarkerClick(e.target.dataItem.dataContext)
      if (zoomOnHit) {
        e.target.series.chart.zoomToMapObject(e.target)
      }
    })
  }, [mapDimensions, mapPosition])
  useEffect(() => {
    if (series.current) { series.current.data = geoData }
  }, [geoData])
  return (
    <>
      <div id='chartdiv' style={{ width: mapDimensions.width, height: mapDimensions.height }} />
    </>
  )
}

AmchartsMap.propTypes = {
  geoData: PropTypes.arrayOf(PropTypes.shape({})),
  onMarkerClick: PropTypes.func,
  mapDimensions: PropTypes.shape({}),
  mapPosition: PropTypes.shape({})
}

AmchartsMap.defaultProps = {
  mapDimensions: {
    width: '100%',
    height: '500px'
  },
  mapPosition: {
    homeZoomLevel: 4,
    homeGeoPoint: { longitude: -96.38989283562752, latitude: 40.51983840232243 }
  },
  onMarkerClick: () => {}
}

export default React.memo(AmchartsMap, _.isEqual)
