import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  useAppDetailsContext
} from 'features/github'
import {ApplicationDetailsProvider} from 'features/github'
import { AccessCartProvider, useAccessCartProvider } from 'features/resources'
import { createRef } from 'features/github'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {MainHeader} from 'features/github'
import { Tabs } from 'features/github'


const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

function RepositoriesDetails({}) {
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)

  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const { appView } = useAppView()
  const history = useHistory()
  const { addItemsToCart, isResourceInCart, isCartEmpty } = useAccessCartProvider()
  const { app, isAllowedAccess } = useAppDetailsContext()
  const handleEditClick = () => setShowEditAppModal(true)
  const handleDeleteClick = () => setShowDeleteAppModal(true)

  
  //Reaonly Apps cannot be signed in
  const signInAllowed = true
  const canGoBack = useCanGoBack()

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const handleAccessClick = () => {
    //@ts-ignore
    if (isResourceInCart(createRef(app)) || !isCartEmpty) return
    addItemsToCart({
      resourceRef: createRef(app),
      principal: '',
      roles: [],
    })
    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: true
      })
    } else {
      handleViewsChange({
        accessRequestSubmissionModal: true
      })
    }
  }

  return (
    <div>
      <MainHeader
        onAccessClick={handleAccessClick}
        disableAccessBtn={!isCartEmpty || (appView === 'user' && isAllowedAccess)} //disable when cart is not empty and disable if it is userview and the app is already allowed access
        onDelete={handleDeleteClick}
        onEdit={handleEditClick}
        showSignIn={signInAllowed}
        app={app}
        showPolicy={true}
        showEdit={false}
      />
      <div className='mt-6'>
        <Tabs 
          showAddButton={false}
          isRepo={true}
          app={app}
        />
      </div>
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            if (canGoBack) history.goBack()
            else history.push(`/${appView}/applications/${app.ObjectMeta.Name}`)
          }}
          onCancel={() => setShowDeleteAppModal(false)}
          app={app}
        />
      )}

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow isRepo={true} views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
    </div>
  )
}

const WrappedApplicationDetails = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <ApplicationDetailsProvider>
      <AccessCartProvider key={cartKey} cartKey={cartKey}>
        <RepositoriesDetails />
      </AccessCartProvider>
    </ApplicationDetailsProvider>
  )
}

export { WrappedApplicationDetails as RepositoriesDetails }
