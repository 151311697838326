import { faClock, faHourglassEnd, faHourglassStart } from '@fortawesome/free-solid-svg-icons'
import { faCircleX, faShieldCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import {
  APPROVED_STATUS,
  CancelApprovalRequestModal,
  EditApprovalRequest,
  getApprovalIconAndVariant,
  isApprovalRequestCancelled,
  isApprovalRequestCreatedByUser,
  isApprovalRequestEditable,
  isServiceAccountRequest,
  ReRequestApprovalRequestModal,
  useApprovalDetails
} from 'features/approvals'
import { getPolicyDisplayName } from 'features/policy'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { getUserDisplayName } from 'features/users'
import moment from 'moment'
import { Button, Label, Typography } from 'procyon-ui'
import React, { useMemo, useState } from 'react'
import { hasApprovalRequestExpired } from 'Utils/PureHelperFuctions'
import { Breadcrumbs } from 'V2Components'

function PendingHeaderSection({ onApproveClick, onRejectClick }) {
  return (
    <>
      <Typography variant='h4-regular' className='text-[#545B71]'>
        What do you want to do with this request?
      </Typography>
      <Button onClick={onRejectClick} variant='grayRed'>
        Reject
      </Button>
      <Button onClick={onApproveClick} variant='primary'>
        Approve
      </Button>
    </>
  )
}

function ApprovedHeaderSection({
  approvalRequest,
  onCloseClick,
  onPaaPolicyDeleteClick,
  showDeletePaaPolicyButton
}) {
  const { appView } = useAppView()
  return (
    <>
      <Label
        variant='grayBlue'
        iconButton={faShieldCheck}
        iconPosition='end'
        text={`Approved on ${moment(approvalRequest.ObjectMeta.UpdatedAt).format(
          'MMM DD YYYY, h:mm A'
        )}`}
      />
      <Button onClick={onCloseClick} variant='grayRed'>
        Close
      </Button>
      {appView === 'admin' && (
        <>
          {showDeletePaaPolicyButton && (
            <Button onClick={onPaaPolicyDeleteClick} variant='grayRed'>
              Delete Auto Approve Policy
            </Button>
          )}
        </>
      )}
    </>
  )
}

function RejectedHeaderSection({ approvalRequest }) {
  return (
    <>
      <Label
        variant='danger'
        iconButton={faCircleX}
        iconPosition='end'
        text={`Rejected on ${moment(approvalRequest.ObjectMeta.UpdatedAt).format(
          'MMM DD YYYY, h:mm A'
        )}`}
      />
    </>
  )
}

function Header({ onApproveClick, onRejectClick, onCloseClick, onPaaPolicyDeleteClick }) {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showReRequestModal, setShowReRequestModal] = useState(false)

  const { approvalRequest, requestor, paaPolicy, bundle } = useApprovalDetails()
  const { appView } = useAppView()
  const { user } = useUser()

  const approvalStatus = useMemo(() => {
    if (!approvalRequest) return ''
    if (hasApprovalRequestExpired(approvalRequest)) return 'Expired'
    return approvalRequest.Status
  }, [approvalRequest])

  const isNonRequestorAccessRequest = !isApprovalRequestCreatedByUser(user, approvalRequest)

  const { variant, icon } = getApprovalIconAndVariant(approvalStatus)

  const BundleIcon = getRsrcIcon(bundle)

  return (
    <div>
      <Breadcrumbs
        goBack
        breadCrumbs={[
          {
            label: 'Access Requests',
            link: '/admin/approval-requests-new'
          },
          {
            label: getPolicyDisplayName(approvalRequest)
          }
        ]}
      />
      <div className='flex justify-between mt-8'>
        <div>
          <Typography className='!font-bold' variant='h2'>
            {getPolicyDisplayName(approvalRequest)}
          </Typography>
          <Typography
            className='mt-2 !font-semibold'
            style={{ fontSize: '14px' }}
            variant='caption-regular'
          >
            {approvalRequest.ObjectMeta.Name}
          </Typography>
        </div>

        <div className='flex gap-4 items-center'>
          {bundle && (
            <Label
              size='md'
              variant='success'
              icon={<BundleIcon />}
              text={`(${getResourceName(bundle)}) Bundle Access Request`}
            />
          )}
          {approvalStatus === 'Pending' && (appView === 'admin' || isNonRequestorAccessRequest) && (
            <PendingHeaderSection onRejectClick={onRejectClick} onApproveClick={onApproveClick} />
          )}
          {approvalStatus === 'Approved' && (
            <ApprovedHeaderSection
              showDeletePaaPolicyButton={!!paaPolicy}
              onCloseClick={onCloseClick}
              onPaaPolicyDeleteClick={onPaaPolicyDeleteClick}
              approvalRequest={approvalRequest}
            />
          )}
          {approvalStatus === 'Rejected' && (
            <RejectedHeaderSection approvalRequest={approvalRequest} />
          )}
          {approvalStatus === 'Expired' && (
            <Label
              variant={variant}
              iconButton={icon}
              iconPosition='end'
              text={`Expired on ${moment(approvalRequest.Spec.NotAfter).format(
                'MMM DD YYYY, h:mm A'
              )}`}
            />
          )}
          {approvalStatus === 'Closed' && (
            <Label
              variant={variant}
              iconButton={icon}
              iconPosition='end'
              text={`Approval Request has been closed`}
            />
          )}
          {isApprovalRequestEditable(approvalRequest) &&
            !isServiceAccountRequest(approvalRequest) &&
            !isNonRequestorAccessRequest &&
            appView === 'user' && (
              <Button onClick={() => setShowEditModal(true)} variant='grayBlue'>
                Edit Request
              </Button>
            )}
          {approvalRequest.Status === APPROVED_STATUS.Pending &&
            appView === 'user' &&
            !isNonRequestorAccessRequest && (
              <Button onClick={() => setShowCancelModal(true)} variant='danger'>
                Cancel Request
              </Button>
            )}
          {isApprovalRequestCancelled(approvalRequest) &&
            !isNonRequestorAccessRequest &&
            appView === 'user' && (
              <Button onClick={() => setShowReRequestModal(true)} variant='grayBlue'>
                Re-Request
              </Button>
            )}
        </div>
      </div>
      <div className='flex justify-between mt-8'>
        <Typography className='!text-[#545B71]' variant='body-regular'>
          Requested By:
          {!requestor && <Label className='ml-2' text='Not Found' />}
          <span className='ml-1'>{getUserDisplayName(requestor)}</span>
          <span className='!text-[#8D94A1] ml-1'>{requestor?.Spec?.EmailID}</span>
        </Typography>
        {approvalRequest.Type !== 'ServiceAccount' && (
          <div className='flex gap-8 text-[#A6ABB6]'>
            <Typography variant='caption-regular'>
              <FontAwesomeIcon className='mr-3' icon={faHourglassStart} />
              {moment(approvalRequest.Spec.NotBefore).format('MMM DD, YYYY, h:mm A')}
            </Typography>
            <Typography variant='caption-regular'>
              <FontAwesomeIcon className='mr-3' icon={faHourglassEnd} />
              {moment(approvalRequest.Spec.NotAfter).format('MMM DD, YYYY, h:mm A')}
            </Typography>
            <Typography className='capitalize' variant='caption-regular'>
              <FontAwesomeIcon className='mr-3' icon={faClock} />
              {moment(approvalRequest.Spec.NotAfter).fromNow()}
            </Typography>
          </div>
        )}
      </div>
      <div>
        {showEditModal && (
          <EditApprovalRequest
            approvalReq={approvalRequest}
            onSuccess={() => setShowEditModal(false)}
            onCancel={() => setShowEditModal(false)}
          />
        )}
        {showCancelModal && (
          <CancelApprovalRequestModal
            approvalReq={approvalRequest}
            onCancel={() => setShowCancelModal(false)}
            onSuccess={() => setShowCancelModal(false)}
          />
        )}
        {showReRequestModal && (
          <ReRequestApprovalRequestModal
            approvalReq={approvalRequest}
            onCancel={() => setShowReRequestModal(false)}
            onSuccess={() => setShowReRequestModal(false)}
          />
        )}
      </div>
    </div>
  )
}

export { Header }
