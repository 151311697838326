import { TabGroup } from 'procyon-ui'
import React from 'react'
import { OverviewTab } from './OverviewTab'
import { AccessGraph } from './AccessGraph'
import { AccessSummary } from './AccessSummary'

const Tabs = () => {
  return (
    <div className='mt-8 pr-4'>
      <TabGroup
        tabs={[
          {
            label: 'Overview',
            tabContent: <OverviewTab />
          },
          {
            label: 'Access Summary',
            tabContent: <AccessSummary />
          },
          {
            label: 'Access Graph',
            tabContent: <AccessGraph />
          }
        ]}
      />
    </div>
  )
}

export { Tabs }
