import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { cn } from 'Utils/Helpers'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { LabelContent } from 'V2Components'
import { useAppDetailsContext } from 'features/databricks'
import { getResourceName, getRsrcIcon } from 'features/resources'
import moment from 'moment'
import { CloudIcon, Label, Typography } from 'procyon-ui'
import React from 'react'

function OverviewTab() {
  const { app } = useAppDetailsContext()
  const { appView } = useAppView()
  const { getObjectRef } = useMultiSlice(['accountList'])
  const account = getObjectRef(app?.Account)
  const AccountIcon = getAccountIcon(account?.Spec.Type)

  return (
    <div className='flex gap-8'>
      {/* Hide the border in user view, because the other div which shows owners won't be shown */}
      <div
        className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8', {
          'border-none': appView === 'user'
        })}
      >
        <Typography variant='h4-regular'>Workspace Information</Typography>
        <div className='mt-8'>
          <LabelContent title='Name' content={getResourceName(app)} />

          {account && (
            <LabelContent
              title='Account'
              content={
                <div className='flex gap-2 items-center'>
                  {AccountIcon && <AccountIcon />}
                  {getResourceName(account)}
                </div>
              }
            />
          )}
          <LabelContent
            title={'Cloud Type'}
            content={<CloudIcon type={app.Spec.CloudType.toLowerCase()} />}
          />
          <LabelContent title={'Region'} content={<Label text={app.Spec.Region} />} />
          <LabelContent
            title={'Deployment Name'}
            content={<Label text={app.Spec.DeploymentName} />}
          />
          <LabelContent title={'Workspace ID'} content={<Label text={app.Spec.WorkspaceID} />} />
          <LabelContent
            title='Created On'
            content={moment(app?.ObjectMeta.CreatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
          <LabelContent
            title='Last Updated On'
            content={moment(app?.ObjectMeta.UpdatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
        </div>
      </div>
    </div>
  )
}

export { OverviewTab }
