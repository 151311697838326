import { DataDetailsProvider } from 'providers/DataDetailsProvider'
import React from 'react'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'

const IAMRoleDetails = () => {
  return (
    <div>
      <Header />
      <Tabs />
    </div>
  )
}

const WrappedIAMDetails = () => (
  <DataDetailsProvider
    routeKey='roleName'
    sliceName='iamActions'
    loadingMessage='Fetching IAM Role'
  >
    <IAMRoleDetails />
  </DataDetailsProvider>
)

export { WrappedIAMDetails as IAMRoleDetails }
