import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { updateUser } from 'features/users'
import { Typography } from 'procyon-ui'
import React, { useState } from 'react'

function BulkAddToGroup({ onCancel, onSuccess, users = [] }) {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const { slices } = useMultiSlice(['groupList'])

  const isSingleUser = users.length === 1

  const handleBulkAddToGroup = async () => {
    try {
      setIsUpdating(true)
      const updatedUsers = await handleBulkUserGroupAdd({ users, groups: selectedEntities })
      updatedUsers.forEach(updateSliceData)
      enqueueNotification(`User${isSingleUser ? '' : 's'} has been successfully added to groups!`, 'info')
      onSuccess?.()
    } catch (error) {
      enqueueNotification(`Error adding user${isSingleUser ? '' : 's'} to groups!`, 'error')
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <div>
      <LoadingFeedback
        loading={isUpdating}
        message={`Adding user${isSingleUser ? '' : 's'} to groups.`}
        caption='Please wait..'
      />
      <FullScreenContentModal width='auto'>
        <EntityPicker
          title={isSingleUser ? `Add Groups to ${getResourceName(users[0])}` : 'Add Groups to Users'}
          continueText='Save'
          InfoComponent={
            <Typography variant='caption-regular'>
              {isSingleUser
                ? 'User will be added to these groups.'
                : 'Users will be added to these groups. Exisiting users will be unaffected.'}
            </Typography>
          }
          entities={slices.groupList}
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
          onCancel={onCancel}
          onContinue={handleBulkAddToGroup}
        />
      </FullScreenContentModal>
    </div>
  )
}

const handleBulkUserGroupAdd = async ({ users = [], groups = [] }) => {
  const newUsers = []
  users.forEach((u) => {
    const groupsRefs = u.Spec.Groups?.ObjectRef || []
    // Create a map of already added groups
    const groupsMap = groupsRefs.reduce((prev, ref) => ({ ...prev, [createRsrcKey(ref)]: true }), {})
    // Create a list of new to-be added groups
    const newGroupRefs = groups.filter((g) => !groupsMap[createRsrcKey(g)]).map(createRef)
    // If there are no new groups to be added then, skip this user --> saves unnecessary api call
    if (!newGroupRefs.length) return
    const newUser = structuredClone(u)
    // Combine already in groups and to-be added groups
    newUser.Spec.Groups.ObjectRef = [...groupsRefs, ...newGroupRefs]
    newUsers.push(newUser)
  })

  const apiCalls = newUsers.map(async (u) => await updateUser(u))
  return await Promise.all(apiCalls)
}

export { BulkAddToGroup }
