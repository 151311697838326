import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { deleteGroup } from 'features/groups'
import { getResourceName } from 'features/resources'
import React from 'react'

function DeleteGroupModal({ group, onCancel, onSuccess }) {
  const groupName = getResourceName(group)

  const handleDelete = async () => {
    try {
      await deleteGroup(group)
      deleteSliceData(group)
      onSuccess?.()
      enqueueNotification('Group deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete group: ${groupName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={group} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteGroupModal }
