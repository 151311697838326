import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'
import { AppNameKey, AppSSOURLKey, createApplication, updateApplication } from 'features/applications'
import _ from 'lodash'
import { Button, Label, TextInput } from 'procyon-ui'
import React, { useState } from 'react'
import { useApplicationEditContext } from '../../../providers/ApplicationEditProvider'
import { useHistory } from 'react-router'

function DropboxApplicationForm({ onCancel, onComplete }) {
  const { isEditMode, app } = useApplicationEditContext()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [ssoURL, setSsoURL] = useState(_.get(app, AppSSOURLKey, ''))
  const [applicationName, setApplicationName] = useState(_.get(app, AppNameKey, ''))

  const { slices } = useMultiSlice(['applicationList'])

  const getErrors = () => {
    // Not needed to validate app name when in edit mode
    if (!isEditMode && _.find(slices.applicationList, { ObjectMeta: { Name: applicationName } })) {
      return 'Application name is already taken!'
    }
    if (applicationName.length <= 3) return 'Application Name must be greater than 3 characters.'
    if (!ssoURL) return 'SSO URL must not be empty.'
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const data = { applicationName, ssoURL, app }
      let appResp
      if (isEditMode) {
         appResp = await handleDropboxApplicationUpdate(data)
        updateSliceData(app)
      } else {
         appResp = await handleDropboxApplicationCreate(data)
        pushToSlice(app)
      }
      enqueueNotification(`Successfully ${isEditMode ? 'Updated' : 'Created'} Dropbox Application!`, 'info')
      onComplete?.()
      window.location.reload()
      // history.push(`/admin/applications/${encodeURIComponent(appResp.ObjectMeta.Name)}`)  
    } catch (error) {
      enqueueNotification(`Failed to ${isEditMode ? 'Update' : 'Create'} Dropbox Application!`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const formErrors = getErrors()

  return (
    <div>
      <div>
        <div className='mt-4'>
          <TextInput
            disabled={isEditMode}
            value={applicationName}
            onChange={(e) => setApplicationName(e.target.value)}
            sx={{ width: '100%' }}
            label='Application Name'
          />
        </div>
        <div className='mt-4'>
          <TextInput
            value={ssoURL}
            onChange={(e) => setSsoURL(e.target.value)}
            sx={{ width: '100%' }}
            label='SSO URL'
          />
        </div>
      </div>
      <div
        className={cn('mt-8 flex items-center gap-8', {
          'justify-between': formErrors,
          'justify-end': !formErrors
        })}
      >
        {formErrors && <Label variant='warning' text={formErrors} />}
        <div className='flex gap-4'>
          <Button onClick={onCancel} variant='gray'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!!formErrors} variant='primary'>
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
      <LoadingFeedback caption='Please wait..' loading={isLoading} message='Creating Dropbox Application' />
    </div>
  )
}

const DropboxProviderMetadata = `
<EntityDescriptor xmlns="urn:oasis:names:tc:SAML:2.0:metadata" entityID="Dropbox">
	<SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
  	<NameIDFormat>urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress</NameIDFormat>
  	<AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="https://www.dropbox.com/saml_login" index="0"/>
	</SPSSODescriptor>
</EntityDescriptor>
`

const handleDropboxApplicationCreate = async ({ applicationName, ssoURL }) => {
  const data = {
    ObjectMeta: {
      Name: applicationName
    },
    Spec: {
      AppType: 'Dropbox',
      SignOnType: 'saml',
      SamlServiceProvider: {
        ServiceProviderACSURL: ssoURL,
        ServiceProviderMetadataURL: '',
        ServiceProviderMetadata: DropboxProviderMetadata,
        ServiceProviderSubjectNameIDFormat: '',
        ServiceProviderAttributes: {
          ServiceProviderAttributes: []
        }
      }
    }
  }
  return await createApplication(data)
}

const handleDropboxApplicationUpdate = async ({ app, ssoURL }) => {
  const data = structuredClone(app)
  _.set(data, AppSSOURLKey, ssoURL)
  return await updateApplication(data)
}

export { DropboxApplicationForm }
