import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { getAWSResourceIcon } from 'Components/CustomIcons/AWSResources'
import { getGCPResourceIcon } from 'Components/CustomIcons/GCPResources'
import { ApiProvider, _ } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import CONSTANTS from 'Utils/Constants'
import { enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback, SearchInput, Breadcrumbs } from 'V2Components'

import {
  ActionBar,
  Button,
  Label,
  PickListContainer,
  PickListHeading,
  PickListRowHeading,
  SelectDropDown,
  TextInput,
  ToggleCloudControl,
  Typography
} from 'procyon-ui'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'
import { createCustomIAMRolePayload } from './utils'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import useCloudActions from 'Core/Hooks/useCloudActions'
import { useCloudTypes } from 'features/clouds'

/**
 *
 * @param {{ name?: string, breadCrumbs: { className?: import('clsx').ClassValue[],label:string, onClick?:(() => void) }[], afterSubmit: (() => void), onCancel:(() => void), accountType: 'AWS'|'GCP'|'AZURE' }} param0
 * @returns
 */
function CreateIAMRoleView({ breadCrumbs = [], afterSubmit = null, onCancel, name, accountType }) {
  // This variable also works as unSelected iam actions cloudType
  const [selectedCloudTypeFilter, setSelectedCloudTypeFilter] = useState(accountType || 'AWS') // aws | azure | gcp
  const [searchKey, setSearchKey] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { slices: cloudActions, dispatchThunks } = useMultiSlice(['iamActions'])
  const [unSelectedServiceFilter, setUnSelectedServiceFilter] = useState('')
  const [selectedServiceActions, setSelectedServiceActions] = useState([])
  const [description, setDescription] = useState('')
  const [roleName, setRoleName] = useState(name || '')
  const { awsCloudActions, azureCloudActions, gcpCloudActions } = useCloudActions()
  const { cloudTypes } = useCloudTypes()

  useEffect(() => {
    const iamAction = cloudActions?.iamActions?.find((e) => e.ObjectMeta.Name === name)
    if (iamAction) {
      console.log('iamAction iamAction', iamAction)
      setRoleName(name)
      setDescription(iamAction.Spec.Description)
      setSelectedCloudTypeFilter(iamAction.Spec.CloudType)
      const actionCloudTypeMap = {
        AWS: [],
        GCP: [],
        AZURE: []
      }
      const sourceList = actionCloudTypeMap[iamAction.Spec.CloudType]
      const actions = []
      iamAction.Spec.Actions.Elems.forEach((elem) => {
        const service = sourceList.find((e) => e?.Actions?.Elems?.includes(elem))
        if (!service) return
        const action = actions.find((e) => e.service === service.Service)
        if (action) {
          action.actions.push(elem)
        } else {
          actions.push({
            actions: [elem],
            cloudType: iamAction.Spec.CloudType,
            key: service.Service,
            service: service?.Service
          })
        }
      })
      setSelectedServiceActions(actions)
    }
  }, [name, cloudActions])
  /**
   * An object which holds all the available service actions  of currently selected cloud type
   */
  const iamServicesItemsObject = useMemo(() => {
    const items = {}
    selectedCloudTypeFilter === 'AWS' &&
      awsCloudActions.forEach((action) => {
        if (!CONSTANTS.WHITELISTED_CLOUD_SERVICES.includes(action.Service)) return
        items[action.Service] = {
          service: action.Service,
          key: action.Service,
          actions: action.Actions.Elems,
          cloudType: 'AWS'
        }
      })
    // TODO change the check
    selectedCloudTypeFilter === 'GCP' &&
      gcpCloudActions.forEach((action) => {
        items[action.Service] = {
          service: action.Service,
          key: action.Service,
          actions: action.Actions.Elems,
          cloudType: 'GCP'
        }
      })
    selectedCloudTypeFilter === 'AZURE' &&
      azureCloudActions.forEach((action) => {
        items[action.Service] = {
          service: action.Service,
          key: action.Service,
          actions: action.Actions.Elems,
          cloudType: 'AZURE'
        }
      })
    return items
  }, [awsCloudActions, gcpCloudActions, azureCloudActions, selectedCloudTypeFilter])

  const toggleSelectedServiceAction = ({ action = '', service = '', cloudType = '' }) => {
    setSelectedServiceActions((s) => {
      const serviceObj = s.find((e) => e.service === service)
      if (!serviceObj) {
        // The service of the action doesn;t exist hence we add them along with the action
        return [
          {
            service,
            key: service,
            actions: [action],
            cloudType
          },
          ...s
        ]
      }
      // If the action is already selected, then remove the action from service
      if (serviceObj.actions.includes(action)) {
        serviceObj.actions = serviceObj.actions.filter((e) => e !== action)
        return s.map((e) => {
          if (e.service === service) return serviceObj
          return e
        })
      }
      // The action doesn;t exist hence, we add them here
      serviceObj.actions = [action, ...serviceObj.actions]
      return s.map((e) => {
        if (e.service === service) return serviceObj
        return e
      })
    })
  }

  // The variable holds the array of UNSELECTED "actions" from selected service(dropdown list)
  const filteredUnSelectedActions = useMemo(() => {
    const sKey = searchKey.toLowerCase()
    // List of selected actions
    const selectedActions = selectedServiceActions.reduce((prev, current) => {
      return [...prev, ...current.actions]
    }, [])
    const filteredActions = []
    iamServicesItemsObject[unSelectedServiceFilter]?.actions.forEach((action) => {
      // Don't include the selected actions
      if (selectedActions.includes(action)) return
      if (sKey) {
        // Filter by search key if the search key exists
        if (action.toLowerCase().includes(sKey)) filteredActions.push(action)
      } else {
        filteredActions.push(action)
      }
    })
    return filteredActions
  }, [iamServicesItemsObject, unSelectedServiceFilter, selectedServiceActions, searchKey])

  // FILTERED List of selected services object
  const filteredSelectedServices = useMemo(() => {
    const filtered = []
    const sKey = searchKey.toLowerCase()
    if (!sKey) return selectedServiceActions
    selectedServiceActions.forEach((e) => {
      const { actions } = e
      // Filter the actions based on search key
      const filActions = actions.filter((a) => a.toLowerCase().includes(sKey))
      filtered.push({
        ...e,
        actions: filActions
      })
    })
    return filtered
  }, [selectedServiceActions, searchKey])

  const serviceNames = useMemo(() => Object.keys(iamServicesItemsObject), [iamServicesItemsObject])

  const handleSubmitClick = async () => {
    setIsLoading(true)
    // List of all the selected actions
    const allActions = selectedServiceActions.reduce((prev, curr) => [...prev, ...curr.actions], [])
    if (name) {
      const iamAction = cloudActions?.iamActions?.find((e) => e.ObjectMeta.Name === name)
      const payload = _.cloneDeep(iamAction)
      payload.Spec.Description = description
      payload.Spec.Actions.Elems = allActions
      const response = await new ApiProvider('iamactions').setInstance(payload).put()
      updateSliceData(response.data)
      enqueueNotification('Custom Role has been updated!', 'info')
    } else {
      // Create payload
      const payload = createCustomIAMRolePayload({
        Name: roleName,
        Description: description,
        CloudType: selectedCloudTypeFilter,
        services: allActions,
        mergeObject: null
      })
      const response = await new ApiProvider('iamactions').setInstance(payload).post()
      pushToSlice(response.data)
      enqueueNotification('Custom Role has been created!', 'info')
    }
    setIsLoading(false)
    afterSubmit && afterSubmit()
  }

  const handleAddAllActionsClick = () => {
    // The unselcted service list is just one entry, because of the dropdown filter functionity
    // unSelectedServiceFilter = the service name which is currently on the unselected actions grid(left)
    const serviceObject = iamServicesItemsObject[unSelectedServiceFilter]
    // for checking if there are already some SELECTED actions from THIS SERVICE
    const selectedActions = selectedServiceActions.find(
      (e) => e.service === unSelectedServiceFilter
    )
    // If the actions(some) from this service is already selected then, merge the actions
    if (selectedActions) {
      // List of actions which are not in selectedActions ie not selected
      const actionsToAdd = serviceObject.actions.filter((e) => !selectedActions.actions.includes(e))
      setSelectedServiceActions((s) =>
        s.map((e) => {
          // Merge the already selected actions with other un-selected actions
          if (e.service === serviceObject.service)
            return { ...e, actions: [...e.actions, ...actionsToAdd] }
          return e
        })
      )
    } else {
      // Since the service has not actions selected yet, create an entry
      setSelectedServiceActions((s) => [
        {
          service: serviceObject.service,
          key: serviceObject.service,
          actions: serviceObject.actions
        },
        ...s
      ])
    }
  }

  const handleRemoveAllActionsClick = (service) => {
    setSelectedServiceActions((s) => s.filter((e) => e.service !== service.service))
  }

  const getServiceIconFromText = useCallback(
    (text = '') => {
      const iconFunctionMap = {
        AWS: getAWSResourceIcon,
        GCP: getGCPResourceIcon,
        AZURE: getAZUREResourceIcon
      }
      return iconFunctionMap[selectedCloudTypeFilter](text.split(':')[0], true)
    },
    [selectedCloudTypeFilter]
  )

  const SelectedServices = useMemo(() => {
    return filteredSelectedServices.map((e) => (
      <div key={e.key} className='w-[100%]'>
        <PickListRowHeading
          onClickRemoveAll={() => handleRemoveAllActionsClick(e)}
          button='remove'
          title={e.service.replace('.', ' ').toUpperCase()}
        />
        <div>
          {e.actions.map((action) => {
            const Icon = getServiceIconFromText(
              selectedCloudTypeFilter === 'AWS' ? action : unSelectedServiceFilter
            )
            return (
              <div key={action}>
                <ActionBar
                  assigned
                  style={{ width: '100%' }}
                  leftIcon={<Icon className='rounded-md' />}
                  name={action}
                  onClickRemoveButton={() =>
                    toggleSelectedServiceAction({
                      service: e.service,
                      action,
                      cloudType: e.cloudType
                    })
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    ))
  }, [filteredSelectedServices, getServiceIconFromText])

  const UnselectedServices = useMemo(() => {
    return (
      <div className='w-[100%]'>
        <PickListRowHeading
          onClickAddAll={handleAddAllActionsClick}
          button='add'
          title={unSelectedServiceFilter.replace('.', ' ').toUpperCase()}
        />
        <div>
          {filteredUnSelectedActions.map((action) => {
            const Icon = getServiceIconFromText(
              selectedCloudTypeFilter === 'AWS' ? action : unSelectedServiceFilter
            )
            return (
              <div key={action}>
                <ActionBar
                  style={{ width: '100%' }}
                  onClickAddButton={() =>
                    toggleSelectedServiceAction({
                      service: unSelectedServiceFilter,
                      action,
                      cloudType: selectedCloudTypeFilter
                    })
                  }
                  leftIcon={<Icon className='rounded-md' />}
                  name={action}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }, [filteredUnSelectedActions, getServiceIconFromText, selectedCloudTypeFilter])

  const getFormErrors = () => {
    if (
      !selectedServiceActions.length ||
      selectedServiceActions.find((e) => e.actions.length === 0)
    )
      return 'Please select actions.'
    if (roleName.indexOf(' ') >= 0) return 'Role Name cannot have space in between.'
    if (!roleName) return 'Role Name cannot be empty'
    if (_.find(cloudActions.iamActions, { ObjectMeta: { Name: roleName } }) && !name)
      return 'Role Name already exists'
  }

  /**
   * If there was a change in the serviceNames, check if the `unSelectedServiceFilter` exists in the new serviceNames
   * If not, then overwrite it with new service name filter
   */

  if (serviceNames.length && !serviceNames.includes(unSelectedServiceFilter)) {
    setUnSelectedServiceFilter(serviceNames[0])
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div className='bg-white w-100 h-[100%] overflow-auto'>
      <div className='px-12 py-4 bg-[#F9FBFC]'>
        <Breadcrumbs breadCrumbs={breadCrumbs} />
        <div className='flex justify-between'>
          <h1 className='font-medium mb-0'> {name ? 'Update' : 'Create'} Custom IAM Role</h1>
          <div className='flex gap-4'>
            {getFormErrors() && (
              <Label iconButton={faTriangleExclamation} variant='warning' text={getFormErrors()} />
            )}
            <Button onClick={onCancel} variant='gray'>
              Cancel
            </Button>
            <Button disabled={!!getFormErrors()} onClick={handleSubmitClick} variant='primary'>
              {name ? 'Update' : 'Create'} Role
            </Button>
          </div>
        </div>
      </div>
      <div className='px-12 py-8'>
        <p className='text-lg pb-6'>Role Information</p>
        <div className='flex gap-8'>
          <div className='grow'>
            <Typography className='pb-2' variant='body-regular'>
              Name
            </Typography>
            <TextInput
              disabled={!!name}
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              sx={{ width: '100%' }}
            />
          </div>
          <div className='grow'>
            <Typography className='pb-2' variant='body-regular'>
              Description
            </Typography>
            <TextInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ width: '100%' }}
            />
          </div>
        </div>
        <div className='pt-8'>
          <h4 className='text-lg mb-6'>Select Action</h4>
          <div className='flex justify-between'>
            <div>
              <ToggleCloudControl
                options={cloudTypes}
                value={selectedCloudTypeFilter}
                disabled={!!name}
                ariaLabel='cloud-select'
                onChange={(_, values) => {
                  // if (values === 'AZURE') return
                  setSelectedCloudTypeFilter(values)
                  setSelectedServiceActions([])
                }}
                multiSelect={false}
              />
              <SelectDropDown
                showHelpText={false}
                sx={{ minWidth: '240px' }}
                className='ml-6'
                menuItems={serviceNames.map((e) => ({
                  label: e,
                  selected: true,
                  value: e,
                  disabled: false
                }))}
                value={unSelectedServiceFilter}
                // @ts-ignore
                onChange={(e) => setUnSelectedServiceFilter(e.target.value)}
              />
            </div>
            <div>
              <SearchInput searchKey={searchKey} onChange={setSearchKey} sx={{ width: '313px' }} />
            </div>
          </div>
          <div className='mt-4'>
            <div className='grid grid-cols-2 gap-8'>
              <div>
                <PickListHeading
                  showDropDown={false}
                  dropDownItems={[]}
                  onChange={function noRefCheck() {}}
                  title={`Existing (${filteredUnSelectedActions?.length || 0})`}
                />
                <PickListContainer
                  style={{
                    height: '459px',
                    width: '100%'
                  }}
                >
                  <React.Fragment key='0.'>{UnselectedServices}</React.Fragment>
                </PickListContainer>
              </div>
              <div>
                <PickListHeading
                  showDropDown={false}
                  dropDownItems={[]}
                  onChange={function noRefCheck() {}}
                  title={`Selected (${filteredSelectedServices.reduce(
                    (p, c) => p + c.actions.length,
                    0
                  )})`}
                />
                <PickListContainer
                  style={{
                    height: '459px',
                    width: '100%'
                  }}
                >
                  <React.Fragment key='0.'>{SelectedServices}</React.Fragment>
                </PickListContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingFeedback
        message={`${name ? 'Updating' : 'Creating'} Custom App Role`}
        caption='Please wait..'
        loading={isLoading}
      />
    </div>
  )
}

export { CreateIAMRoleView }
