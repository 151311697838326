import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import store from 'infra/redux/store'
import useMultiDispatch from './useMultiDispatch'
import { getObjectRefFlat } from './useObjectRelations'
import equal from 'fast-deep-equal'

/**
 * Selects all the data from the slices and returns an object
 * @returns
 */
const select = (sliceNames, state) => {
  const selectObject = {}
  sliceNames.forEach((sliceName) => {
    const { map } = state[sliceName]
    selectObject[sliceName] = { map }
  })
  return selectObject
}

/**
 * @param {import('types').SliceNames[]} sliceNames The slice names to take from
 */
function useMultiSlice(sliceNames = []) {
  const state = useSelector((s) => select(sliceNames, s))
  const { dispatchThunks, selectDispatch } = useMultiDispatch(sliceNames)

  const getObjectRef = useCallback((refs) => getObjectRefFlat(refs), [state])
  const [activeState, setActiveState] = useState(state)

  if (!equal(activeState, state)) {
    setActiveState(state)
  }

  /**
   * @type {((sliceNames: import('types').SliceNames[], any?:boolean) => boolean)}
   * @param {boolean} any
   */
  const isFetched = useCallback(
    (sliceNames = [], any = false) => {
      const state = store.getState()
      let flag = true
      sliceNames.some((s) => {
        if (state[s] && any && state[s].initiallyLoaded) return true
        if (state[s] && !state[s].initiallyLoaded) {
          flag = false
          return true
        }
      })
      return flag
    },
    [activeState]
  )

  /**
   *
   * @type {{ [k in import('types').SliceNames]: any[] }}
   */
  //@ts-ignore
  const slices = useMemo(() => {
    const dataMap = {}
    sliceNames.map((e) => {
      dataMap[e] = Object.values(activeState[e].map)
    })
    return dataMap
  }, [activeState])

  const initiallyLoaded = isFetched(sliceNames)

  return {
    slices,
    dispatchThunks,
    selectDispatch,
    getObjectRef,
    isFetched,
    initiallyLoaded
  }
}

export default useMultiSlice
