import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import CopyButton from 'Components/CopyButton'
import InfoIcon from 'Components/TreeView/Components/Icons/InfoIcon'
import React from 'react'

const useStyle = makeStyles(theme => ({
  info: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1),
    width: theme.spacing(1),
    height: theme.spacing(1),
    transform: 'translateY(-1px)',
    marginRight: theme.spacing(0.25)
  },
  statement: {
    display: 'flex', alignItems: 'center'
  }

}))
const Footer = ({ link, userName }) => {
  const classes = useStyle()
  const theme = useTheme()
  return (
    <Box mt={1.50} display='flex' alignItems='center' justifyContent='space-between'>
      <Box display='flex' alignItems='center'>
        <InfoIcon fill={theme.palette.primary.main} className={classes.info} />
        <Typography variant='caption' className={classes.statement} color='primary'>
          Share this link with {userName}. The link expires in 15 minutes
        </Typography>
      </Box>
      {/* Copy Button */}
      <CopyButton label='Copy Link' copyText={link} successNotificationText='Onboarding link copied to clipboard' />
    </Box>
  )
}

export default Footer
