import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { FullScreenContentModal } from 'V2Components'
import { useActivityLogParser } from 'features/activityLogs'
import { getResourceName } from 'features/resources'
import moment from 'moment'
import { Button, TabGroup, Typography } from 'procyon-ui'
import React from 'react'
import { ActivityInfoTab } from './components/ActivityInfoTab'
import { ResourceTab } from './components/ResourcesTab'

const ActivityLogInfoModal = ({ onClose, hit }) => {

  const { getParsedLogData } = useActivityLogParser()

  const [{ deviceName, device, userName, user, eventTime, message, resources }] = getParsedLogData([hit])

  return (
    <FullScreenContentModal>
      <div className='flex items-center justify-between'>
        <div>
          <Typography variant='h4-regular'>
            {moment(eventTime).format('MMMM Do YYYY, h:mm A')}
          </Typography>
          <Typography className='mt-1' variant='caption-regular'>
            {user ? getResourceName(user) : userName}
          </Typography>
        </div>
        <Button onClick={onClose} variant='gray' icon={faClose} iconPosition='end'>
          Close
        </Button>
      </div>
      <div className='mt-6'>
        <TabGroup
          onChangeTab={function noRefCheck() {}}
          tabs={[
            {
              label: 'Event Information',
              tabContent: (
                <ActivityInfoTab
                  user={user}
                  userName={userName}
                  device={device}
                  deviceName={deviceName}
                  eventMessage={message}
                />
              )
            },
            {
              label: 'Resources',
              tabContent: <ResourceTab resource={resources[0]} hit={hit} />
            }
          ]}
        />
      </div>
    </FullScreenContentModal>
  )
}

export { ActivityLogInfoModal }
