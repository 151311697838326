import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { BulkDeleteSelectionModal, FloatingActions } from 'V2Components'
import { reverseRsrcKey } from 'features/resources'
import { ResponsiveTable } from 'infra/ui'
import React, { useState } from 'react'
import { Header } from './components/Header'
import { responsiveCloumns, useWorkloads } from './useWorkloads.utils'

const Workloads = () => {
  const [searchKey, setSearchKey] = useState('')
  const [selectedRowIDs, setSelectedRowIDs] = useState([])
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false)

  const { getObjectRef } = useMultiSlice(['workloads'])

  const { rowDataAdapterFn, workloads } = useWorkloads({ searchKey })

  return (
    <div>
      <Header searchKey={searchKey} setSearchKey={setSearchKey} />
      <div className='mt-8'>
        <ResponsiveTable
          columns={responsiveCloumns()}
          data={workloads.map(rowDataAdapterFn)}
          scrollX='1200px'
          rowSelection={{
            selectedRowKeys: selectedRowIDs,
            type: 'checkbox',
            onChange(selectedRowIDs) {
              setSelectedRowIDs(selectedRowIDs)
            }
          }}
        />
      </div>
      {selectedRowIDs.length > 0 && (
        <FloatingActions
          message={`${selectedRowIDs.length} Workload Identities Selected`}
          buttons={[
            {
              label: 'Delete',
              onClick: () => setShowBulkDeleteModal(true),
              variant: 'grayRed'
            }
          ]}
        />
      )}
      {showBulkDeleteModal && (
        <BulkDeleteSelectionModal
          items={getObjectRef(selectedRowIDs.map((e) => reverseRsrcKey(e)))}
          onCancel={() => setShowBulkDeleteModal(false)}
          onSuccess={() => setShowBulkDeleteModal(false)}
          type='Workload Identity'
        />
      )}
    </div>
  )
}

export { Workloads }
