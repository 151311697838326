// @ts-nocheck
import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles(theme => ({

  icon: {
    width: theme.spacing(1.25),
    fontSize: theme.spacing(1.25),
    marginRight: theme.spacing(1),
    color: theme.palette.text.disabled
  }

}))
const Item = ({ text, Icon, textColor, textVariant = 'body2' }) => {
  const classes = useStyles()
  return (
    <Box mb={0.25} display='flex' alignItems='center'>
      {Icon && <Icon className={classes.icon} />}
      <Typography variant={textVariant} color={textColor}>{text}</Typography>
    </Box>
  )
}

export default Item
