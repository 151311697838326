import { getOutgoers } from '@xyflow/react'
import _ from 'lodash'

/**
 *
 * @param {{ id: string }} node The node to search for
 * @param {{ id: string}[]} nodes The nodes to search for
 * @param {{
 * id       : string
 * startID  : string
 * endID    : string
 * }[]} edges
 * @param {any[]} _all Used for recursion
 * @returns {{ id:string }[]}
 */
export const getReactFlowNodeOutgoers = (node, nodes = [], edges = [], _all = []) => {
  const gNodes = []
  const gEdges = []

  // transform nodes
  nodes.forEach((nodeData) => {
    const { id } = nodeData
    gNodes.push({
      id,
      position: { x: 0, y: 0 },
      data: { label: 'node' }
    })
  })
  //transform edges
  edges.forEach((edge) => {
    const { id, startID, endID } = edge
    gEdges.push({ source: startID, target: endID, id })
  })
  // Get out going nodes
  const outGoers = getOutgoers(node, gNodes, gEdges)

  // map over outgoing nodes
  const allX = outGoers.map((outGoer) => {
    // recursively call the getReactFlowNodeOutgoers fn
    // we pass to all = [..._all, ...outGoers] for storing current call results
    return getReactFlowNodeOutgoers(outGoer, nodes, edges, [..._all, ...outGoers])
  })

  // after successfully reaching the end of that node leaf, return all data
  return [..._all, ..._.flatten(allX)]
}
