import { getApplicationType } from 'features/applications'
import { getKubeType, getRsrcPolicyRule, isPolicyKubenamespaceRsrcs } from 'features/policy/utils'
import {
  createRef,
  createRsrcKey,
  getResourceName,
  getRsrcAccountRef,
  getRsrcIcon
} from 'features/resources'
import {
  CREDENTIALS_OBJECT_KIND,
  PRINCIPAL_OBJECT_KINDS,
  TargetsSliceNames,
  getK8ClusterAppRoleWithID,
  getTargetsPolicyRules
} from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { Label, SummaryResourceCard, TargetIcon, TargetInputModal } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { K8RolesPicker } from '../../../../../K8RolesPicker'
import { useEditPolicyModulesContext } from '../../../../EditPolicyModulesProvider'

const Target = ({}) => {
  const [selectedRsrcRef, setSelectedRsrcRef] = useState(null)
  const [principal, setPrincipal] = useState('')
  const [showPrincipalInput, setShowPrincipalInput] = useState(false)
  const [selectedK8RoleName, setSelectedK8RoleName] = useState('')
  const [showK8RolePicker, setShowK8RolePicker] = useState(false)

  const { getObjectRef } = useObjectRef(['accountList', ...TargetsSliceNames])

  const { policy, selectedResources, removeSelectedResource, attributesMap, setAttributesMap } =
    useEditPolicyModulesContext()

  const getRsrcAttributes = (rsrc) => {
    const rsrcKey = createRsrcKey(rsrc)
    const attributes = attributesMap[rsrcKey]

    // Attribute resolver for PRINIPAL OBJECT rsrcs
    if (PRINCIPAL_OBJECT_KINDS.includes(rsrc.ObjectMeta.Kind)) {
      return [{ title: attributes }]
    }

    // Atrribute resolver for cluster
    const isKubeCluster = rsrc.ObjectMeta.Kind === 'KubeCluster'
    if (isKubeCluster && attributes) {
      const approlesMap = getK8ClusterAppRoleWithID(rsrc)
      if (!approlesMap[attributes.RoleRef?.RefID]) return

      return [
        {
          title: approlesMap[attributes.RoleRef.RefID]
        }
      ]
    }

    //attribute reslover for kube namespace
    const isKubeNamespace = rsrc.ObjectMeta.Kind === 'KubeNamespace'
    if (isKubeNamespace && attributes) {
      const cluster = getObjectRef(rsrc.Spec.Cluster)
      if (cluster) {
        const approlesMap = getK8ClusterAppRoleWithID(cluster)
        if (!approlesMap[attributes.RoleRef?.RefID]) return
        return [
          {
            title: approlesMap[attributes.RoleRef.RefID]
          }
        ]
      }
    }

    return []
  }

  const targetRsrcResolver = (rsrc) => {
    const rsrcKey = createRsrcKey(rsrc)
    const account = getObjectRef(getRsrcAccountRef(rsrc))

    const data = {
      key: rsrcKey,
      rsrcName: getResourceName(rsrc),
      Icon: getRsrcIcon(rsrc),
      attributes: getRsrcAttributes(rsrc),
      accountName: getResourceName(account),
      rsrcRef: createRef(rsrc),
      isEditButtonEnabled: !['AppRole', 'Application', 'Kafka'].includes(rsrc.ObjectMeta.Kind),
      caption: getApplicationType(rsrc)
    }
    return data
  }

  const getAllUserList = () => {
    const substring = ['placeholder']
    const rsrc = getObjectRef(selectedRsrcRef)
    if (!rsrc) return []
    if (CREDENTIALS_OBJECT_KIND.includes(rsrc?.ObjectMeta?.Kind)) {
      const credentialsValue = Object.entries(rsrc?.Spec?.Credentials?.Map)
        .filter(([key]) => substring.every((substring) => !key.includes(substring)))
        .reduce((acc, [key]) => {
          if (key.trim() !== '') {
            acc.push(key)
          }
          return acc
        }, [])
      const iamUsersValues = rsrc.Spec.IAMUsers?.Elems || []
      const combinedValue = [...credentialsValue, ...iamUsersValues]
      return combinedValue
    }
    return []
  }

  const handleTargetPrincipalConfirmClick = () => {
    setAttributesMap((s) => ({ ...s, [createRsrcKey(selectedRsrcRef)]: principal }))
    setPrincipal('')
    setShowPrincipalInput(false)
  }

  const handleDeleteRsrc = (rsrcRef, _meta = {}) => {
    const { isK8Rsrc, k8RsrcKey } = _meta
    const rsrcKey = createRsrcKey(rsrcRef)

    if (isK8Rsrc) {
      // if IS K8 Rsrc remove the rsrc not the ref(namespace)
      const { K8Rsrcs } = attributesMap[rsrcKey]
      setAttributesMap((s) => {
        const data = s[rsrcKey]
        data.K8Rsrcs = K8Rsrcs.filter((e) => e !== k8RsrcKey)
        s[rsrcKey] = data
        return { ...s }
      })
    } else {
      removeSelectedResource(rsrcRef)
    }
  }

  const handleEditRsrc = (rsrcRef) => {
    setSelectedRsrcRef(rsrcRef)
    const key = createRsrcKey(rsrcRef)
    if (rsrcRef.RefKind === 'KubeCluster' || rsrcRef.RefKind === 'KubeNamespace') {
      const getCluster = () => {
        if (rsrcRef.RefKind === 'KubeCluster') return getObjectRef(rsrcRef)
        const kns = getObjectRef(rsrcRef)
        if (!kns) return
        return getObjectRef(kns.Spec.Cluster)
      }

      let cluster = getCluster()
      if (!cluster) return

      const attr = attributesMap[key] || { RoleRef: {}, K8Rsrcs: [] }

      const approlesMap = getK8ClusterAppRoleWithID(cluster)
      const selectedApproleID = attr.RoleRef?.RefID
      setSelectedK8RoleName(approlesMap[selectedApproleID] || '')
      setShowK8RolePicker(true)
    } else {
      const attr = attributesMap[key]
      setPrincipal(attr)
      setShowPrincipalInput(true)
    }
  }

  const handleK8RoleNameSave = () => {
    let k8Cluster = getObjectRef(selectedRsrcRef)

    if (k8Cluster && k8Cluster.ObjectMeta.Kind === 'KubeNamespace') {
      k8Cluster = getObjectRef(k8Cluster.Spec.Cluster)
      if (!k8Cluster) return
    }

    setAttributesMap((s) => {
      const RoleRef = {
        RefKind: k8Cluster.Spec.CloudType === 'AWS' ? 'AppRole' : 'ServiceAccount',
        RefID: k8Cluster.Spec.IAMRoles?.Map[selectedK8RoleName] || '0'
      }

      const data = s[createRsrcKey(selectedRsrcRef)] || { RoleRef: {}, K8Rsrcs: [] }

      data.RoleRef = RoleRef

      return { ...s, [createRsrcKey(selectedRsrcRef)]: data }
    })

    setSelectedK8RoleName('')
    setShowK8RolePicker(false)
  }

  const getK8RoleName = (ref) => {
    const kns = getObjectRef(ref)
    if (!kns) return ''
    const cluster = getObjectRef(kns.Spec.Cluster)
    if (!cluster) return ''
    const map = getK8ClusterAppRoleWithID(cluster)
    const roleRef = attributesMap[createRsrcKey(ref)]?.RoleRef || {}
    return map[roleRef?.RefID] || ''
  }

  const getK8RsrcsItems = () => {
    const items = []

    selectedResources.forEach((ref) => {
      if (ref.RefKind === 'KubeNamespace') {
        const kns = getObjectRef(ref)
        if (!kns) return

        const { rule } = getRsrcPolicyRule(ref, policy)

        // If the rule is for kns not kns resources
        if (rule && !rule.Principal) {
          const data = targetRsrcResolver(kns)
          items.push(data)
          return
        }

        const K8Rsrcs = attributesMap[createRsrcKey(ref)]?.K8Rsrcs || []

        return K8Rsrcs.map((e) => {
          const [type, name] = e.split('+')
          items.push({
            key: e,
            rsrcRef: ref,
            Icon: () => <TargetIcon type={getKubeType(type)} />,
            accountName: '',
            rsrcName: `${type}:${name}`,
            attributes: [{ title: getK8RoleName(ref) }],
            isEditButtonEnabled: true,
            caption: getResourceName(kns),
            _meta: {
              isK8Rsrc: true,
              k8RsrcKey: e
            }
          })
        })
      }
      if (ref.RefKind === 'KubeCluster') {
        if (!getObjectRef(ref)) return
        console.log('[policy](edit):', ref, getObjectRef(ref))
        items.push(targetRsrcResolver(getObjectRef(ref)))
      }
    })

    return items
  }

  const getRsrcItems = () => {
    let items = []

    if (isPolicyKubenamespaceRsrcs(policy)) {
      // K8 Rsrcs policy needs to be handled differently
      items = getK8RsrcsItems()
    } else {
      selectedResources.map((ref) => {
        const rsrc = getObjectRef(ref)
        if (!rsrc) return
        const data = targetRsrcResolver(rsrc)
        return items.push(data)
      })
    }

    return items.map((data) => {
      const {
        key,
        rsrcRef,
        accountName,
        rsrcName,
        attributes = [],
        Icon,
        isEditButtonEnabled,
        caption,
        _meta
      } = data
      return (
        <div className='mb-4'>
          <SummaryResourceCard
            key={key}
            isEditButtonEnabled={isEditButtonEnabled}
            accountName={accountName}
            isDeleteButtonEnabled
            resourceIcon={<Icon />}
            resourceName={rsrcName}
            resourceType={caption}
            region=''
            onClickEditButton={() => handleEditRsrc(rsrcRef)}
            onClickDeleteButton={() => handleDeleteRsrc(rsrcRef, _meta)}
          >
            {attributes.map((r) => (
              <Label key={r.title} text={r.title} variant='grayBlue' />
            ))}
          </SummaryResourceCard>
        </div>
      )
    })
  }

  useEffect(() => {
    const attributesMap = {}
    const { DBAccess, SSH, RDPAccess } = getTargetsPolicyRules(policy)
    const rules = [...DBAccess, ...SSH, ...RDPAccess]
    rules.forEach((rule) => {
      attributesMap[createRsrcKey(rule.ObjectRef)] = rule.Principal
    })
    setAttributesMap((s) => ({ ...s, ...attributesMap }))
  }, [])

  return (
    <div>
      {getRsrcItems()}
      {showPrincipalInput && selectedRsrcRef && (
        <div className='fixed bottom-4 right-4 z-50'>
          <TargetInputModal
            textInputValue={principal}
            confirmButtonLabel={'Save'}
            // icon={RsrcIcon && <RsrcIcon />}
            onChangeTextInput={(value) => setPrincipal(value)}
            autoComplete
            autoCompleteOptions={getAllUserList()}
            onClickCloseButton={() => {
              setPrincipal('')
              setShowPrincipalInput(false)
            }}
            onClickConfirm={() => principal !== '' && handleTargetPrincipalConfirmClick()}
            title={getResourceName(getObjectRef(selectedRsrcRef))}
          />
        </div>
      )}
      {showK8RolePicker && (
        <K8RolesPicker
          kubeCluster={getObjectRef(selectedRsrcRef)}
          selectedRoleName={selectedK8RoleName}
          setSelectedRoleName={setSelectedK8RoleName}
          onCancel={() => setShowK8RolePicker(false)}
          onContinue={handleK8RoleNameSave}
        />
      )}
    </div>
  )
}

export { Target }
