import { faSignalBars, faSignalSlash } from '@fortawesome/pro-solid-svg-icons'
import { ErrorModal } from 'features/policy'
import { getRsrcError } from 'features/resources'
import { Button, Label } from 'procyon-ui'
import React, { useState } from 'react'
import { LabelContent } from 'V2Components'

const ErrorLabelContent = ({ rsrc }) => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const error = getRsrcError(rsrc)

  return (
    <>
      <LabelContent
        title='Status'
        content={
          error ? (
            <Button
              icon={faSignalSlash}
              size='sm'
              variant='danger'
              onClick={() => setShowErrorModal(true)}
            >
              Errored
            </Button>
          ) : (
            <Label iconButton={faSignalBars} text='Normal' variant='grayBlue' />
          )
        }
      />
      {showErrorModal && (
        <ErrorModal
          message={error}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Error'
        />
      )}
    </>
  )
}

export { ErrorLabelContent }
