import { Box } from '@material-ui/core'
import React from 'react'
import Item from './Item'

const ResourceInfo = ({ data, width = 'unset' }) => {
  return (
    <Box width={width}>
      {data.map((item) => <Item {...item} key={item.text} />)}
    </Box>
  )
}

export default ResourceInfo
