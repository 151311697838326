import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { SelectDropDown, SimpleDropDown, TextInput } from 'procyon-ui'
import React from 'react'

const Header = ({ count, setSelectedType, setSearchTerm, selectedType }) => {
  return (
    <div className='flex justify-between items-center mb-7'>
      <p>{count} Active integrations </p>
      <div className='flex items-center'>
        <TextInput
          icon={faSearch}
          iconPosition='end'
          onChange={(e) => setSearchTerm(e.target.value)}
          onClickEndIcon={function noRefCheck() {}}
          placeholder='Search'
          rows={50}
          sx={{
            width: '300px',
            marginRight: '10px',
            marginLeft: '15px'
          }}
        />

        <SelectDropDown
          menuItems={[
            {
              label: 'All Category',
              selected: true,
              value: 'allcategory'
            },
            {
              label: 'Slack',
              value: 'SlackIntegration'
            },
            {
              label: 'Jira',
              value: 'JiraIntegration'
            },
            {
              label: 'ServiceNow',
              value: 'ServiceNowIntegration'
            },
            {
              label: 'MsTeams',
              value: 'MsTeamsIntegration'
            }
          ]}
          onChange={(e) => setSelectedType(e.target.value)}
          value={selectedType}
          sx={{
            width: '300px'
          }}
        />
      </div>
    </div>
  )
}

export { Header }
