import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { makeStyles } from '@material-ui/core'
import { Grid } from '@mui/material'
import { _ } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { Breadcrumbs, LoadingFeedback } from 'V2Components'

import { useResourceAccess } from 'Core/Hooks/useResourceAccess'
import { getIAMResourceDisplayType } from 'features/iamResources'
import {
  IANA_TIMEZONES,
  K8_PRINCIPAL_KEY,
  createRsrcKey,
  getResourceName,
  getRsrcIcon,
  useAccessCartProvider
} from 'features/resources'
import {
  Button,
  Label,
  SelectDropDown,
  SummaryResourceCard,
  TextInput,
  Typography
} from 'procyon-ui'
import React, { useState } from 'react'
import { EntitiesView } from './Components/EntitiesView'
import { StartEndTimePicker } from './Components/StartEndTimePicker'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  mainGrid: {
    padding: theme.spacing(1, 2.5),
    overflow: 'auto',
    height: '100%'
  },
  summaryGrid: {
    borderRight: '1px solid RGB(216, 221, 228)',
    paddingRight: theme.spacing(4),
    overflow: 'auto',
    height: '90%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'RGB(249, 251, 252)',
    padding: theme.spacing(1, 2.5),
    marginTop: 16
  },
  resourceSummaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  configureRequestGrid: {
    paddingLeft: theme.spacing(4)
  },
  summaryCard: {
    marginBottom: theme.spacing(2)
  },
  rsrcIcon: {
    height: '34px',
    width: '34px',
    borderRadius: '4px'
  },
  requestNameInput: {
    marginTop: theme.spacing(0.5)
  },
  configureReqBody: {
    marginTop: theme.spacing(),
    width: '100%'
  },
  accessTimeHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5)
  },
  label: {
    marginBottom: theme.spacing(0.5)
  },
  commentBox: {
    marginTop: theme.spacing()
  }
}))

const MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
}

function AccessRequestSubmissionV2({
  onCancel,
  onSubmit,
  afterSubmit = null,
  loadingMessage,
  mainTitle = 'Request',
  onRsrcEditClick = null,
  onAddMoreResourcesClick,
  onManageUserGroupsClick = null,
  showRequestForUserGroupsSummary = true,
  showAddMoreResourcesBtn = true,
  dateTimeError,
  setDateTimeError,
  headerName = '',
  mode = MODES.CREATE // CREATE | EDIT
}) {
  const classes = useStyles()
  const { getObjectRef: getRsrcsRef } = useMultiSlice([
    'awsResources',
    'azureResources',
    'gcpResources',
    'serverList',
    'appRolesList',
    'databases',
    'kubeNamespaces',
    'applicationList',
    'rdpServers',
    'kubeClusters',
    'kafkas'
  ])
  const { slices, getObjectRef } = useMultiSlice([
    'iamActions',
    'accountList',
    'userList',
    'groupList',
    'serviceAccounts',
    'workloads'
  ])
  const {
    clearAllAccessData,
    updateSpec,
    resources,
    spec,
    resourcesMetaObj,
    entities,
    removeResources,
    updateRsrcMeta
  } = useResourceAccess()
  const [isLoading, setIsLoading] = useState(false)

  const { removeFromCart, getResourceFromCart, updateCartQueueItem } = useAccessCartProvider()

  const clearAllInputFields = () => {
    clearAllAccessData()
    setDateTimeError({
      startDate: '',
      endDate: ''
    })
  }

  const getKubeAttributes = (roles = [], kubeCluster) => {
    if (kubeCluster?.ObjectMeta.Kind !== 'KubeCluster') return []
    const attrs = []
    const ids = roles.map((e) => e.RefID)
    // Get the approle name and id from the kubecluster
    for (const [name, approleID] of Object.entries(kubeCluster.Spec.IAMRoles.Map)) {
      // If the approle is selected in resource queue, push the approle name
      if (ids.includes(approleID) && !attrs.includes(name)) attrs.push(name)
    }
    return attrs
  }

  /**
   * Function which returns display data for rsrcs summary.
   * The function takes care of KubeNamespace and it's related data like pods, sts and deployments.
   */
  const getK8RsrcsData = (ref) => {
    const data = []

    if (ref.RefKind !== 'KubeNamespace') return
    const rsrc = getRsrcsRef(ref)
    const rKey = createRsrcKey(rsrc)
    const meta = resourcesMetaObj[rKey]
    let { Roles, Principal } = meta || {}
    const rsrcAttributes = []
    const account = getObjectRef(rsrc.Spec.Account)
    const cluster = getRsrcsRef(rsrc.Spec.Cluster)
    Principal = Principal?.replace(K8_PRINCIPAL_KEY, '')
    if (Principal) {
      const k8Rsrcs = Principal.split(',')
      cluster && rsrcAttributes.push(...getKubeAttributes(Roles || [], cluster))
      k8Rsrcs.forEach((k8RsrcName) => {
        data.push({
          accountName: _.get(account, 'ObjectMeta.Name', ''),
          region: '',
          resourceName: k8RsrcName,
          resourceType: getIAMResourceDisplayType(rsrc),
          attributes: rsrcAttributes,
          Icon: getRsrcIcon(rsrc),
          disableEdit: false,
          principal: '',
          rsrc,
          ref
        })
      })
    } else {
      cluster && rsrcAttributes.push(...getKubeAttributes(Roles || [], cluster))
      data.push({
        accountName: _.get(account, 'ObjectMeta.Name', ''),
        region: rsrc.Spec.Region || '',
        resourceName: getResourceName(rsrc),
        resourceType: getIAMResourceDisplayType(rsrc),
        attributes: rsrcAttributes,
        Icon: getRsrcIcon(rsrc),
        disableEdit: false,
        principal: Principal,
        rsrc,
        ref
      })
    }
    return data
  }

  const rsrcsSummary = (() => {
    const data = []
    resources.forEach((ref) => {
      let rsrc = null
      const rsrcAttributes = []
      rsrc = getRsrcsRef(ref)
      if (!rsrc) return
      const rKey = createRsrcKey(rsrc)
      const meta = resourcesMetaObj[rKey]
      const { Roles, Principal } = meta || {}

      /**
       * UPDATE ATTRIBUTES
       */
      // Get attributes for KubeNamespace - PRIVATE

      if (rsrc.ObjectMeta.Kind === 'KubeNamespace') {
        const k8NamespaceData = getK8RsrcsData(ref)
        data.push(...k8NamespaceData)
        //* MUST return to prevent duplicate KubeNamespace entry below
        return
      } else if (rsrc.ObjectMeta.Kind === 'KubeCluster') {
        rsrcAttributes.push(...getKubeAttributes(Roles || [], rsrc))
      } else {
        Roles?.forEach((r) => {
          const role = _.find(slices.iamActions, { ObjectMeta: { ID: r.RefID } })
          if (!role) return
          rsrcAttributes.push(role.Spec.RoleName || role.ObjectMeta.Name)
        })
        Principal && rsrcAttributes.push(`Principal: ${Principal}`)
      }

      if (rsrc.ObjectMeta.Kind === 'CRMEntity') {
        data.push({
          accountName: '',
          region: '',
          resourceName: getResourceName(rsrc),
          resourceType: getIAMResourceDisplayType(rsrc),
          attributes: rsrcAttributes,
          Icon: getRsrcIcon(rsrc),
          disableEdit: ref.RefKind === 'AppRole' || ref.RefKind === 'Database',
          principal: Principal,
          rsrc,
          ref
        })
      } else {
        const account = getObjectRef(rsrc?.Spec?.Account)
        data.push({
          accountName: _.get(account, 'ObjectMeta.Name', ''),
          region: rsrc.Spec.Region || '',
          resourceName: getResourceName(rsrc),
          resourceType: getIAMResourceDisplayType(rsrc),
          attributes: rsrcAttributes,
          Icon: getRsrcIcon(rsrc),
          disableEdit: ref.RefKind === 'AppRole' || ref.RefKind === 'Database',
          principal: Principal,
          rsrc,
          ref
        })
      }
    })
    return data
  })()

  const handleRsrcEditClick = (rsrc) => {
    onRsrcEditClick && onRsrcEditClick(rsrc.ref, rsrc.rsrc)
  }

  const handleRemoveResourceClick = (ref, rsrcName) => {
    if (ref.RefKind === 'KubeNamespace') {
      const cartData = getResourceFromCart(ref)
      if (cartData.Principal) {
        const cData = { ...cartData }
        cData.Principal = cData.Principal.replace(K8_PRINCIPAL_KEY, '')
        const newPrincipal = cData.Principal.split(',')
          .filter((e) => e !== rsrcName)
          .join(',')

        if (!newPrincipal.trim().length) {
          // Since this is the last k8 rsrc, remove the kubenamespace itself
          removeResources([ref])
          removeFromCart(ref)
          return
        }

        updateRsrcMeta({
          [createRsrcKey(ref)]: {
            ...resourcesMetaObj[createRsrcKey(ref)],
            Principal: `${K8_PRINCIPAL_KEY}${newPrincipal}`
          }
        })
        updateCartQueueItem({
          resourceRef: ref,
          principal: `${K8_PRINCIPAL_KEY}${newPrincipal}`
        })
        return
      }
    }
    removeResources([ref])
    removeFromCart(ref)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      //!TODO Fix!
      await onSubmit({ resources, spec, entities, resourcesMetaObj })
      clearAllInputFields()
      afterSubmit?.()
    } catch (error) {
      enqueueNotification(`Error Creating ${mainTitle}!`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    clearAllInputFields()
    onCancel?.()
  }

  const getInputErrors = () => {
    if (dateTimeError.endDate || dateTimeError.startDate)
      return dateTimeError.endDate || dateTimeError.startDate
    if (spec.name.length < 3) return 'Request Name must be greater than 3 characters.'
    if (!resources.length) return 'No Resources selected. Please go back and select resources.'
  }

  return (
    <div className={classes.root}>
      <LoadingFeedback loading={isLoading} caption='Please wait..' message={loadingMessage} />
      <div className={classes.header}>
        <div>
          <Breadcrumbs
            breadCrumbs={[
              {
                label: 'Resource Catalog',
                onClick: () => onCancel()
              },
              {
                label: headerName
              }
            ]}
          />
          <Typography className='mt-4' variant='h2'>
            {headerName}
          </Typography>
        </div>
        <div className='flex gap-4 mt-8 justify-end items-center'>
          {mode === MODES.EDIT && (
            <Label
              iconButton={faInfoCircle}
              text='Only start date and end date can be edited.'
              variant='success'
            />
          )}
          {getInputErrors() && (
            <Label iconButton={faTriangleExclamation} text={getInputErrors()} variant='warning' />
          )}
          <Button onClick={handleCancel} variant='gray'>
            Cancel
          </Button>
        </div>
      </div>
      <Grid className={classes.mainGrid} container>
        <Grid className={classes.summaryGrid} xs={6} item>
          <Typography variant='body-regular'>{mainTitle} Summary</Typography>
          {showRequestForUserGroupsSummary && (
            <EntitiesView
              onManageEntitiesClick={onManageUserGroupsClick}
              entities={getObjectRef(entities)}
            />
          )}
          <div>
            <div className={classes.resourceSummaryHeader}>
              <Typography variant='body-regular'>
                {rsrcsSummary.length} Resources Requested
              </Typography>
              {showAddMoreResourcesBtn && (
                <Button
                  onClick={onAddMoreResourcesClick}
                  icon={faPlus}
                  iconPosition='end'
                  variant='grayBlue'
                >
                  Add Resources
                </Button>
              )}
            </div>
            <div className='mb-4'>
              {rsrcsSummary.map((e, index) => (
                <div className='mb-4'>
                  <SummaryResourceCard
                    actionButtons={mode === MODES.CREATE}
                    className={classes.summaryCard}
                    key={index}
                    isEditButtonEnabled={!NO_EDIT_RSRC_KINDS[e.ref.RefKind]}
                    accountName={e.accountName}
                    isDeleteButtonEnabled
                    onClickDeleteButton={() => handleRemoveResourceClick(e.ref, e.resourceName)}
                    onClickEditButton={() => handleRsrcEditClick(e)}
                    region={e.region}
                    resourceIcon={<e.Icon className={classes.rsrcIcon} />}
                    resourceName={e.resourceName}
                    resourceType={e.resourceType}
                  >
                    {e.attributes.map((r) => (
                      <Label key={r} text={r} variant='grayBlue' />
                    ))}
                  </SummaryResourceCard>
                </div>
              ))}
            </div>
          </div>
        </Grid>
        <Grid className={classes.configureRequestGrid} xs={6} item>
          <Typography variant='body-semiBold'>Configure {mainTitle}</Typography>
          <div className={classes.configureReqBody}>
            <Typography variant='body-regular'>{mainTitle} Name</Typography>
            <TextInput
              disabled={mode === MODES.EDIT}
              value={spec.name}
              onChange={(e) => updateSpec({ name: e.target.value })}
              className={classes.requestNameInput}
              sx={{ width: '100%' }}
            />
            <Typography className={classes.accessTimeHeader} variant='body-semiBold'>
              Setup Access Time
            </Typography>
            <div className='mb-2'>
              <Typography className='mb-2' variant='body-regular'>
                Select Timezone
              </Typography>
              <SelectDropDown
                menuItems={IANA_TIMEZONES.map((tz) => ({ label: tz, value: tz }))}
                onChange={(e) => updateSpec({ timeZone: e.target.value })}
                value={spec.timeZone}
              />
            </div>
            <StartEndTimePicker
              sD={spec.startDate}
              eD={spec.endDate}
              onError={setDateTimeError}
              onStartDateChange={(d) => updateSpec({ startDate: d })}
              onEndDateChange={(d) => updateSpec({ endDate: d })}
            />
            <div className={classes.commentBox}>
              <Typography className={classes.label} variant='body-regular'>
                Comments
              </Typography>
              <TextInput
                disabled={mode === MODES.EDIT}
                value={spec.comments}
                onChange={(e) => updateSpec({ comments: e.target.value })}
                multiline
                rows={3}
                sx={{ width: '100%' }}
              />
            </div>
            <div className='mt-8 flex justify-end'>
              <Button disabled={!!getInputErrors()} onClick={handleSubmit} variant='primary'>
                Submit
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

/**
 * Map of Kinds whose edit is disabled in request summary
 */
const NO_EDIT_RSRC_KINDS = {
  AppRole: true,
  Application: true,
  Kafka: true
}
export * from './Components/StartEndTimePicker'
export { AccessRequestSubmissionV2 }
