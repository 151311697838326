import useAppView from 'Core/Hooks/useAppView'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { BUNDLE_REQ_TYPE, BUNDLE_TYPES, useBundlesUserAccess } from 'features/bundles'
import { copyProfileName } from 'features/iamResources'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BundleSignInModal } from '../../BundleSignInModal'
import { BundlesGridView } from '../../BundlesGridView'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { AdvancedSearch } from 'V2Components'

const GrantedBundles = () => {
  const [showSignInModal, setShowSignInModal] = useState(false)
  const [signInData, setSignInData] = useState([])
  const [searchKey, setSearchKey] = useState('')

  //@ts-ignore
  const { bundleType } = useParams()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const { name } = row

      const all = `${name}`.toLowerCase()
      return all.includes(queryKey)
    }
  })
  const {
    bundleAccessMap: { granted },
    getBundleSignInData
  } = useBundlesUserAccess(bundleType.toUpperCase())

  const history = useHistory()
  const { appView } = useAppView()

  const getBundlesData = () => {
    const data = []

    granted.forEach((bundle) => {
      const signInData = []
      const signInMap = getBundleSignInData(bundle)

      for (const key in signInMap) {
        const d = signInMap[key]
        signInData.push(...d)
      }

      data.push({
        name: getResourceName(bundle),
        Icon: getRsrcIcon(bundle),
        status: 'granted',
        bundleType: bundle.Type,
        hideSignInButton:
          !signInData.some((e) => e.url) && !signInData.some((e) => e.signInItems.length),
        hideCopyProfileButton: bundle.ReqType === BUNDLE_REQ_TYPE.Target,
        onSignInClick() {
          // only for aws resources we can have single sign in button because of the approle which gets created
          if (bundle.Type === BUNDLE_TYPES.AWS && bundle.ReqType === BUNDLE_REQ_TYPE.IAMAction) {
            if (signInData[0].url) openURLExternally(signInData[0].url)
            return
          }

          setShowSignInModal(true)
          setSignInData(signInData)
        },
        onCopyProfileClick() {
          if (signInData[0]?.profileName) {
            copyProfileName(signInData[0]?.profileName)
          }
        },
        onMoreInfoClick() {
          history.push(`/${appView}/bundles/${bundleType.toLowerCase()}/${bundle.ObjectMeta.Name}`)
        }
      })
    })

    return applySearchQuery(data)
  }

  const data = getBundlesData()

  return (
    <div>
      {granted.length === 0 && <Typography variant='body-regular'>No granted bundles.</Typography>}
      {granted.length > 0 && (
        <>
          <AdvancedSearch
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            filterSaveKey='bundles-granted'
          />
          <BundlesGridView data={data} />
        </>
      )}

      {showSignInModal && (
        <BundleSignInModal data={signInData} onCancel={() => setShowSignInModal(false)} />
      )}
    </div>
  )
}

const SINGLE_SIGNIN_KIND = ['AppRole', 'AwsResource']

export { GrantedBundles }
