import useAppView from 'Core/Hooks/useAppView'
import { EntityInfoBar, SearchInput } from 'V2Components'
import { createRsrcKey, getResourceName } from 'features/resources'
import _ from 'lodash'
import moment from 'moment'
import { SimpleDropDown, Typography, UserGroupBar } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function RsrcAccessSummaryView({ rsrcAccessEntities = [], attachedPolicys = [] }) {
  const [searchKey, setSearchKey] = useState('')
  const [entitySortFilter, setEntitySortFilter] = useState('')
  const [policySortFilter, setPolicySortFilter] = useState('name')

  const { appView } = useAppView()
  const history = useHistory()

  const getFilteredPolicys = () => {
    if (policySortFilter === 'name')
      return _.sortBy(attachedPolicys, (e) => e.GivenName.toLowerCase())
    if (policySortFilter === 'created')
      return _.sortBy(attachedPolicys, 'ObjectMeta.CreatedAt').reverse()
  }

  const getFilteredAccessEnities = () => {
    return rsrcAccessEntities.filter((e) => {
      const { Name, Kind } = e.ObjectMeta
      const rsrcName = getResourceName(e)
      if (entitySortFilter && entitySortFilter !== Kind) return false
      if (searchKey) {
        if (Name.toLowerCase().includes(searchKey) || rsrcName.toLowerCase().includes(searchKey))
          return true
      } else return true
    })
  }

  const filteredEntities = getFilteredAccessEnities()
  const filteredPolicys = getFilteredPolicys()

  const getEntitySortByItems = () => {
    const list = []

    rsrcAccessEntities.forEach((e) => {
      if (!_.find(list, { value: e.ObjectMeta.Kind })) {
        list.push({
          label: `Sort: By ${e.ObjectMeta.Kind}`,
          value: e.ObjectMeta.Kind
        })
      }
    })

    return list
  }

  if (!attachedPolicys.length) return <Fallback />

  return (
    <div className='flex flex-row gap-4 mt-4'>
      <div className='w-1/2 border-r pr-4 border-[#D8DDE4]'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4-regular'>Entities</Typography>
          <SearchInput
            className='!w-[275px]'
            searchKey={searchKey}
            onChange={setSearchKey}
            placeholder='Search'
          />
        </div>
        <div className='mt-5 flex items-center justify-between'>
          <Typography variant='body-regular'>{filteredEntities.length} Entities</Typography>
          <SimpleDropDown
            menuItems={[
              {
                label: 'Sort: All',
                selected: true,
                value: ''
              },
              ...getEntitySortByItems()
            ]}
            onChange={(e) => setEntitySortFilter(e.target.value)}
            value={entitySortFilter}
          />
        </div>
        <div className='mt-4'>
          {filteredEntities.map((obj) => {
            const key = createRsrcKey(obj)
            return (
              <div key={key} className='border-b border-[#EEF4F7]'>
                <EntityInfoBar
                  endButton={false}
                  caption={obj.ObjectMeta.Name}
                  name={getResourceName(obj)}
                  kind={obj.ObjectMeta.Kind}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className='w-1/2'>
        <Typography variant='h4-regular'>Attached Policy</Typography>
        <div className='flex items-center justify-between'>
          <Typography className='mt-7' variant='body-regular'>
            {filteredPolicys.length} Policys
          </Typography>
          <SimpleDropDown
            menuItems={[
              {
                label: 'Sort: by Name',
                selected: true,
                value: 'name'
              },
              {
                label: 'Sort: by Created',
                value: 'created'
              }
            ]}
            onChange={(e) => setPolicySortFilter(e.target.value)}
            value={policySortFilter}
          />
        </div>

        <div className='mt-5'>
          {filteredPolicys.map((policy) => (
            <div className='border-b border-[#EEF4F7]' key={policy.GivenName}>
              <UserGroupBar
                caption={`Ends ${moment(policy.NotAfter).fromNow()}`}
                menuItems={[
                  {
                    title: 'View Policy',
                    action: () =>
                      history.push(
                        `/${appView}/policys/${encodeURIComponent(policy.ObjectMeta.Name)}`
                      )
                  }
                ]}
                name={policy.GivenName}
                type='POLICY'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function Fallback() {
  return (
    <div className='h-[500px]'>
      <Typography className='!text-[#545B71]' variant='body-regular'>
        The resource is not accessed by anyone.
      </Typography>
    </div>
  )
}

export { RsrcAccessSummaryView }
