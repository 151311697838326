import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { deleteReadPolicy } from 'features/settings'
import React from 'react'

function DeleteApproverModal({ tag, onCancel, onSuccess }) {
  const tagName = getResourceName(tag)
  const { selectDispatch } = useMultiSlice(['approvers'])

  const handleDelete = async () => {
    try {
      await deleteReadPolicy(tag)
      selectDispatch(['visibilitys'])
      deleteSliceData(tag)
      onSuccess?.()
      enqueueNotification('Tag deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete tag: ${tagName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={tag} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteApproverModal }
