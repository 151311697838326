import React from 'react'
export const ShimmerCard = () => (
  <div className='animate-pulse bg-[rgba(249,250,254,0.68)] rounded-lg p-4 w-[500px] h-auto'>
    <div className='flex space-x-4'>
      <div className='flex-1 space-y-4 py-1'>
        <div className='h-4 bg-[#e5efff75] rounded w-3/4'></div>
        <div className='space-y-2'>
          <div className='h-4 bg-[#e5efff75] rounded'></div>
          <div className='h-4 bg-[#e5efff75] rounded w-5/6'></div>
        </div>
      </div>
    </div>
    <div className='mt-6 flex justify-between space-x-4'>
      <div className='w-24 h-8 bg-[#e5efff75] rounded'></div>
      <div className='w-24 h-8 bg-[#e5efff75] rounded'></div>
    </div>
  </div>
)

export const ShimmerRow = () => (
  <div className='animate-pulse bg-[rgba(249,250,254,0.68)] rounded-lg p-4 w-[100vw] h-auto my-2'>
    <div className='flex space-x-4'>
      <div className='flex-1 space-y-4 py-1'>
        <div className='h-4 bg-[#e5efff75] rounded w-3/4'></div>
      </div>
    </div>
  </div>
)

export const ShimmerTableRow = () => (
  <div className='animate-pulse bg-[#dbe6f7] rounded' style={{ width: '100%', height: '30px' }} />
)
