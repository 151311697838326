import React, { useState } from 'react'
import { Button, Typography, Divider, Step, StepLabel, StepContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignInAlt,
  faUserShield,
  faUserLock,
  faTrash,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'

const steps = [
  {
    title: 'Sign in to the AWS Management Console',
    description:
      'Log in to the AWS Management Console using an account with administrative permissions.',
    icon: faSignInAlt
  },
  {
    title: 'Navigate to the IAM Console',
    description:
      "In the AWS Management Console, search for 'IAM' in the top search bar and select it to open the Identity and Access Management console.",
    icon: faUserShield
  },
  {
    title: 'Locate the User with the UnusedIAMUserPassword Finding',
    description:
      "In the IAM Dashboard, select 'Users' from the sidebar to see all IAM users. Choose the user account with the unused password finding.",
    icon: faUserLock
  },
  {
    title: 'Disable or Delete the Console Password',
    description:
      "In the selected user's 'Security Credentials' section, under 'Console Access', click 'Disable' to temporarily disable access. Alternatively, click 'Delete Console Access' to permanently remove the console password.",
    icon: faTrash
  },
  {
    title: 'Verify the Removal of Console Access',
    description:
      "Confirm that the console access is disabled or deleted. Optionally, sign in with the user's credentials to test and ensure access has been removed.",
    icon: faCheckCircle
  }
]

const UnusedIamPasswordRecommendation = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <p className='text-base font-[500]'>Resolve Unused IAM User Password</p>
      <Divider style={{ marginBottom: '20px' }} />

      {steps.map((step, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Step active={activeStep === index} expanded={activeStep === index}>
            <StepLabel>
              <FontAwesomeIcon icon={step.icon} style={{ marginRight: '10px' }} />
              <Typography variant='subtitle2' component='span'>
                {step.title}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <StepContent>
                <p className='mb-3 text-[14px] font-[500] text-[#6e6e6e]'>{step.description}</p>
                <div>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    style={{ marginRight: '10px' }}
                    disabled={activeStep === steps.length - 1}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  {index > 0 && <Button onClick={handleBack}>Back</Button>}
                </div>
              </StepContent>
            )}
          </Step>
        </div>
      ))}
    </div>
  )
}

export { UnusedIamPasswordRecommendation }
