import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const AllResource = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 24 24' {...props}>
      <svg viewBox='-1.6 -1.6 19.20 19.20' xmlns='http://www.w3.org/2000/svg' fill='#fafafa' stroke='#fafafa'>
        <g id='SVGRepo_bgCarrier' stroke-width='0'>
          <rect x='-1.6' y='-1.6' width='19.20' height='19.20' rx='0' fill='#8009e1' strokeWidth='0'></rect>
        </g>
        <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M10.012 2h.976v3.113l2.56-1.557.486.885L11.47 6l2.564 1.559-.485.885-2.561-1.557V10h-.976V6.887l-2.56 1.557-.486-.885L9.53 6 6.966 4.441l.485-.885 2.561 1.557V2zM2 10h4v4H2v-4z'
          ></path>
        </g>
      </svg>
    </SvgIcon>
  )
})

export { AllResource }
