import { SvgIcon } from '@material-ui/core'
import React, { forwardRef } from 'react'

const ElasticIP = forwardRef((props, ref) => {
  return (
    <SvgIcon ref={ref} viewBox='0 0 48 48' {...props}>
      <svg className='w-6 h-6' height='48' width='48' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M43.62 24.058v-.264l.122.132-.122.132zm-32.694 6.794A6.933 6.933 0 014 23.926 6.933 6.933 0 0110.926 17a6.933 6.933 0 016.925 6.926 6.932 6.932 0 01-6.925 6.926zm34.913-7.606l-6.441-6.936-1.465 1.361 4.88 5.255H19.792C19.293 18.474 15.51 15 10.926 15 6.004 15 2 19.004 2 23.926c0 4.922 4.004 8.926 8.926 8.926 4.584 0 8.367-3.474 8.866-7.926h23.021l-4.88 5.255 1.465 1.361 6.441-6.935a1 1 0 000-1.361z'
          fill='#D45B07'
          fill-rule='evenodd'
        />
      </svg>
    </SvgIcon>
  )
})

export default ElasticIP
