import { Chip, FormControl, IconButton, Input, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import { SelectDropDown } from 'procyon-ui'
import React from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    //   width: 1
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    width: 200
    // maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 0
  },
  noLabel: {
    marginTop: theme.spacing(1)
  },
  clearAll: {
    transform: 'translateY(8px)'
  }
}))
const MultiSelectFilter = ({ filterOptions, data, onChange, name, label, filterType }) => {
  const options = filterOptions || data.map(e => e[name])
  const classes = useStyles()
  const [list, setList] = React.useState([])

  const handleClear = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setList([])
    onChange([], name, filterType)
  }
  const handleChange = (e) => {
    const value = e.target.value
    setList(value)
    onChange(value, name, filterType)
  }
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id='demo-mutiple-chip-label'>{label}</InputLabel>
        <Select
          labelId='demo-mutiple-chip-label'
          id='demo-mutiple-chip'
          multiple
          value={list}
          onChange={handleChange}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
          onClear={() => setList([])}
          clea
        >

          {options.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <IconButton className={classes.clearAll} onClick={handleClear}>
        <HighlightOff />
      </IconButton>
    </div>
  )
}

export default MultiSelectFilter
