import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createIamActionToRsrcRelation, createPolicyToTargetsRelation } from 'V2Components'
import { useGroupDetailsContext, useGroupRsrcAccess } from 'features/groups'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { getPolicyAttachedIAMActions, getPolicyResourcesRefs } from 'features/policy'
import { createRsrcKey, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { useEffect } from 'react'

export function useAccessGraphTab() {
  const { getGroupIssuedPolicys } = useGroupRsrcAccess()
  const { group } = useGroupDetailsContext()
  const { getObjectRef, dispatchThunks } = useMultiSlice([
    'policyList',
    'iamActions',
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames
  ])

  // all the policys which are issued to this group
  const issuedPolicys = getGroupIssuedPolicys(group)

  /**
   * a map of object whose key is the policy key and value is an array of key of resources
   * to which the policy is given.
   */
  const policyRsrcRefMap = issuedPolicys.reduce((map, policy) => {
    map[createRsrcKey(policy)] = getPolicyResourcesRefs(policy).map(createRsrcKey)
    return map
  }, {})

  const getIAMActionsNodes = () => {
    const tempMap = {}
    const iamActionsNodes = []
    // IAM Rsrcs policy have iam actions
    const policys = issuedPolicys
    policys.forEach((p) => {
      // 1. Get iam actions map object attached to the policy
      const actionsObj = getPolicyAttachedIAMActions(p)
      for (const key in actionsObj) {
        // key is the resource and value is the  array of attached iam actions' ref object
        const actions = actionsObj[key]
        actions.forEach((ref) => {
          // 2. Get the actual action object
          const action = getObjectRef(ref)
          const refKey = createRsrcKey(action)
          // 3. If action is not already pushed to the nodes array, then push it
          if (!tempMap[refKey] && action) {
            iamActionsNodes.push(action)
            tempMap[refKey] = true
          }
        })
      }
    })

    return iamActionsNodes
  }

  /**
   * Get the policy nodes for the graph
   * > key of object `policyRsrcRefMap` is the policy key which is reversed and object ref is done
   * @returns {any[]}
   */
  const getPolicyNodes = () => getObjectRef(Object.keys(policyRsrcRefMap).map(reverseRsrcKey))

  /**
   * Get all the resource nodes for the graph.
   * 
   * @returns {any[]}
   */
  const getRsrcsNodes = () => {
    // Merge all the rsrc refs[] from the value of `policyRsrcRefMap` object into one array
    const rsrcRefs = Object.values(policyRsrcRefMap).reduce((p, c) => [...p, ...c], [])
    // remove duplicate resource refs
    const uniqRsrcRefs = [...new Set(rsrcRefs)]
    // Reverse the rsrc key string to ref object and perform get object ref
    const rsrcs = getObjectRef(uniqRsrcRefs.map(reverseRsrcKey))
    return rsrcs
  }

  /**
   * Get all the nodes for the graph
   * @returns {any[]}
   */
  const getNodes = () => {
    const policyNodes = getPolicyNodes()
    const rsrcsNodes = getRsrcsNodes()
    return [[group], policyNodes, getIAMActionsNodes(), rsrcsNodes]
  }

  /**
   * Returns the relation object required to draw arrows in the graph
   * @returns {object}
   */

  const getNodesRelationObject = () => {
    const map = {}
    const policyKeys = Object.keys(policyRsrcRefMap)

    policyKeys.forEach((e) => {
      const groupRelations = map[createRsrcKey(group)] || []
      groupRelations.push(e)
      // From group -> policy
      map[createRsrcKey(group)] = groupRelations
    })

    return {
      ...map,
      ...createPolicyToTargetsRelation(issuedPolicys),
      ...createIamActionToRsrcRelation(issuedPolicys)
    }
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return { nodes: getNodes(), relationObject: getNodesRelationObject(), issuedPolicys }
}
