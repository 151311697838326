import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { getResourceName } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { createRsrcKey } from 'infra/redux/reducers'
import { Button, Label, TargetIcon } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

const useSlices = createDataSelectorHook(['adDomainControllers'])
export const useADDomainControllers = ({ setShowDeleteModal, setSelectedADDC }) => {
  const history = useHistory()
  const { appView } = useAppView()
  const {
    slices: { adDomainControllers }
  } = useSlices()

  const rowDataAdapterFn = (addc) => {
    return {
      id: createRsrcKey(addc),
      name: getResourceName(addc),
      username: addc?.Spec?.Username,
      password: addc?.Spec?.Password,
      defaultFor: addc?.Spec?.DefaultFor?.Elems,
      islocal: addc?.Spec?.IsLocal,
      rotatepassword: addc?.Spec?.RotatePasswords,
      actions: {
        onEdit() {
          setSelectedADDC(addc)
          // setShowEditModal(true)
          history.push(
            `/${appView}/addomainControllers/${encodeURIComponent(getResourceName(addc))}`
          )
        },
        onDelete() {
          setSelectedADDC(addc)
          setShowDeleteModal(true)
        }
      }
    }
  }

  const tableData = adDomainControllers.map(rowDataAdapterFn)

  return { tableData, rowDataAdapterFn }
}
/**
 *
 * @returns {import("procyon-ui").DataTableProps['columns']}
 */
export const columns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 250
  },
  {
    field: 'defaultFor',
    headerName: 'Default For',
    width: 250,
    renderCell({ value }) {
      return (
        <div className='flex gap-2'>
          {value.map((e) => (
            <Label key={e} icon={<TargetIcon className='w-[40px] h-[40px]' type={e} />} />
          ))}
          {!value.length && <Label variant='gray' text='None' />}
        </div>
      )
    }
  },
  {
    field: 'rotatepassword',
    headerName: 'Rotate Password',
    width: 250
  },
  {
    field: 'islocal',
    headerName: 'Local User',
    width: 250
  },

  {
    field: 'actions',
    headerName: '',
    width: 200,
    sortable: false,
    renderCell({ value }) {
      return (
        <div className='flex gap-2 items-center'>
          <Button onClick={value.onEdit} variant='grayBlue'>
            Edit
          </Button>
          <Button onClick={value.onDelete} variant='grayRed'>
            Delete
          </Button>
        </div>
      )
    }
  }
]
