import { IAMActionActionMapKeys } from 'features/policy'
import { createRsrcKey } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'

const useSlices = createDataSelectorHook(['policyList'])
/**
 * The hook is specialized in iam role access in regards to policy and approval request.
 */
export const useIAMRoleAccess = () => {
  const { slices } = useSlices()

  const getIAMRolePolicyToResourcesMap = (iamrole) => {
    /**
     * map = {
     *    <Policy+ID>: [<Resource+ID>, <Resource+ID>]
     * }
     * @type {{ [k:string]:string[] }}
     */
    const map = {}

    slices.policyList.forEach((policy) => {
      const ActionMap = policy.Spec.ActionMap
      const actions = Object.keys(ActionMap)
      /**
       * Checking if the policy has the actions map in them,
       * If not then, there are no iam actions to lookup
       */
      if (!actions.some((e) => IAMActionActionMapKeys[e])) return {}
      /**
       * Loop through actions which holds the iam actions attached
       */
      Object.keys(IAMActionActionMapKeys).forEach((action) => {
        const PolicyRule = ActionMap[action]?.PolicyRule
        if (!PolicyRule) return
        return PolicyRule.forEach(({ ObjectRef, Services }) => {
          if (ObjectRef.RefKind !== 'IamAction') return
          const iamKey = createRsrcKey(ObjectRef)
          // Not the IAM Role we are looking for
          if (iamKey !== createRsrcKey(iamrole)) return
          // Push the resource
          const rsrcKeyArr = map[createRsrcKey(policy)] || []
          map[createRsrcKey(policy)] = [...rsrcKeyArr, ...Services.ObjectRef.map(createRsrcKey)]
        })
      })
    })

    return map
  }

  const getIAMRoleAttachedRsrcs = (iamrole) => {
    const plcyMap = getIAMRolePolicyToResourcesMap(iamrole)
    const rsrcs = _.flatten(Object.values(plcyMap))
    return [...new Set(rsrcs)]
  }

  /**
   * Get all the policys which has `iamrole` attached
   * @param {*} iamrole
   * @returns {string[]}
   */
  const getIAMRoleAttachedPolicys = (iamrole) => {
    const plcyMap = getIAMRolePolicyToResourcesMap(iamrole)
    return Object.keys(plcyMap)
  }

  return { getIAMRoleAttachedPolicys, getIAMRoleAttachedRsrcs }
}
