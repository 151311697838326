import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { enqueueNotification } from 'Utils/Helpers'
import { SearchInput } from 'V2Components'
import { createRsrcKey, useAccessCartProvider } from 'features/resources'
import _ from 'lodash'
import {
  Button,
  Label,
  PickListContainer,
  PickListHeading,
  Typography,
  UserGroupBar
} from 'procyon-ui'
import React, { useState } from 'react'
import { entityDataAdapters, getFilterDropdownItems } from '../utils'

function AddTeam({
  title,
  entities,
  selectedEntities,
  setSelectedEntities,
  errorMessage = '',
  InfoComponent = null,
  onCancel,
  onContinue,
  continueText = 'Update',
  showPermission
}) {
  const [searchKey, setSearchKey] = useState('')

  const [unSelectedEntitiesFilter, setUnSelectedEntitiesFilter] = useState('')
  const [selectedEntitiesFilter, setSelectedEntitiesFilter] = useState('')
  const [permissionValues, setPermissionValues] = useState({
    0: 'read'
  })
  const { cartItems } = useAccessCartProvider()

  const applyFilter = ({ data, searchKey, Kind, filterKind }) => {
    if (!searchKey && !filterKind) return true
    if (searchKey) return JSON.stringify(data).toLowerCase().includes(searchKey)
    if (filterKind && filterKind === Kind) return true
    return false
  }

  const getResource = (rsrc) => {
    const { Kind } = rsrc.ObjectMeta
    if (entityDataAdapters[Kind]) return entityDataAdapters[Kind](rsrc)
  }

  const getSelectedEntitiesItems = () => {
    return selectedEntities.reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: selectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind === 'GithubResource' ? 'Teams' : entity.ObjectMeta.Kind,
        searchKey
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  const getUnSelectedEntities = () => {
    return entities.filter((e) => {
      const { Kind, ID } = e.ObjectMeta
      return !_.find(selectedEntities, { ObjectMeta: { Kind, ID } })
    })
  }

  const getUnSelectedEntitiesItems = () => {
    return getUnSelectedEntities().reduce((prev, entity) => {
      const data = getResource(entity)
      if (!data) return prev
      const filterPass = applyFilter({
        data,
        filterKind: unSelectedEntitiesFilter,
        Kind: entity.ObjectMeta.Kind,
        searchKey
      })
      if (filterPass) return [getResource(entity), ...prev]
      return prev
    }, [])
  }

  /** ====================================== HANDLERS GO HERE ====================================== */

  const handleSelectEntity = (entity) => {
    if (entity?.Spec?.Members?.Members?.length === 0) {
      enqueueNotification('Please add user under this team', 'error')
    } else {
      const updatedSelectedEntities = [entity, ...selectedEntities].map((entity, index) => {
        return {
          ...entity,
          permission: 'read'
        }
      })
      setSelectedEntities(updatedSelectedEntities)
    }
  }

  const handleDeSelectEntity = (entity) => {
    const entities = selectedEntities.filter((e) => createRsrcKey(entity) !== createRsrcKey(e))
    setSelectedEntities(entities)
  }

  /** ============================================================================================== */

  const handlePermissionChange = (index, value) => {
    console.log(cartItems)
    setPermissionValues((prevState) => ({
      ...prevState,
      [index]: value
    }))

    // Update the permission value in the selectedEntities array
    const updatedSelectedEntities = selectedEntities.map((entity, idx) => {
      if (idx === index) {
        return {
          ...entity,
          permission: value
        }
      }
      return entity
    })

    setSelectedEntities(updatedSelectedEntities)
  }

  return (
    <div className='bg-white h-auto rounded-md w-[887px]'>
      <div className='grid grid-cols-2 gap-8'>
        <div>
          <Typography variant='h2'>{title}</Typography>
          {InfoComponent && <div className='mt-2'>{InfoComponent}</div>}
        </div>
        <SearchInput
          searchKey={searchKey}
          onChange={setSearchKey}
          sx={{ width: '100%' }}
          className='ml-auto'
        />
      </div>

      <div className='grid grid-cols-2 gap-8 mt-7'>
        <div>
          <PickListHeading
            dropDownValue={unSelectedEntitiesFilter}
            onChange={(e) => setUnSelectedEntitiesFilter(e.target.value)}
            dropDownItems={getFilterDropdownItems(getUnSelectedEntities())}
            title={`Existing (${getUnSelectedEntitiesItems().length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%'
            }}
          >
            <>
              {getUnSelectedEntitiesItems().map((e) => (
                <UserGroupBar
                  key={e.key}
                  onClickAddButton={() => handleSelectEntity(e.refObject)}
                  caption={e.caption}
                  name={e.name}
                  onClickRemoveButton={function noRefCheck() {}}
                  style={{
                    width: '100%'
                  }}
                  type={e.type}
                />
              ))}
            </>
          </PickListContainer>
        </div>
        <div>
          <PickListHeading
            onChange={(e) => setSelectedEntitiesFilter(e.target.value)}
            dropDownValue={selectedEntitiesFilter}
            dropDownItems={getFilterDropdownItems(selectedEntities)}
            title={`Added (${getSelectedEntitiesItems().length})`}
          />
          <PickListContainer
            style={{
              height: '459px',
              width: '100%'
            }}
          >
            <>
              {getSelectedEntitiesItems().map((e, index) => (
                <UserGroupBar
                  key={index}
                  selected
                  assigned
                  caption={e.caption}
                  name={e.name}
                  onChangePermissionsMenu={(e) => handlePermissionChange(index, e.target.value)}
                  onClickRemoveButton={() => handleDeSelectEntity(e.refObject)}
                  style={{
                    width: '100%'
                  }}
                  type={e.type}
                  showPermissionsMenu={showPermission ? true : false}
                  permissionsMenuValue={permissionValues[index] || 'read'}
                />
              ))}
            </>
          </PickListContainer>
        </div>
      </div>
      <div className='flex justify-end gap-4 mt-6'>
        {errorMessage && (
          <Label
            iconButton={faTriangleExclamation}
            style={{ width: '800px' }}
            text={errorMessage}
            variant='warning'
          />
        )}
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
        <Button disabled={!!errorMessage} onClick={onContinue} variant='primary'>
          {continueText}
        </Button>
      </div>
    </div>
  )
}

export { AddTeam }
