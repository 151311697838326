import { CopyText, FullScreenContentModal } from 'V2Components'
import { Button, Tooltip, Typography } from 'procyon-ui'
import React from 'react'

const K8ContextModal = ({ rsrc, onClose }) => {
  return (
    <FullScreenContentModal>
      {rsrc &&
        rsrc.map((e, index) => {
          return (
            <>
              <div className='flex justify-between items-center mb-2'>
                {index === 0 && (
                  <>
                    <Typography variant='h4-regular'>
                      Sign In for <strong>{e.resouceName}</strong>
                    </Typography>
                    <Button variant='gray' onClick={onClose}>
                      Close
                    </Button>
                  </>
                )}
              </div>
              <div className='mb-3' key={e.policyName}>
                <Typography className='mb-3' variant='body-semiBold'>
                  {e.policyName}
                </Typography>
                <CopyText
                  Component={
                    <div>
                      <Tooltip title='Click to Copy' arrow>
                        <div className='p-2 rounded bg-gray-100 cursor-copy'>
                          <Typography variant='body-regular'>{e.command}</Typography>
                        </div>
                      </Tooltip>
                    </div>
                  }
                  messageKey={'Context Command'}
                  text={e.command}
                />
              </div>
            </>
          )
        })}
    </FullScreenContentModal>
  )
}

export { K8ContextModal }
