import { useContext } from 'react'
import { BrowserContect } from './BrowserContext'

export const useChannelBrowser = () => {
  const {
    refresh, integration, integrationName, linkedChannels, initiallyLoaded
  } = useContext(BrowserContect)
  return {
    /** @type {object| null} integration */
    integration,
    /** @type {String} url parameter for intgration name */
    integrationName,
    /** @type {Array<{ObjectMeta:Object,Spec:Object,Status:Object}>} channels that are connected to currently selected integration */
    linkedChannels,
    /** @type {Boolean} are apis initially loaded */
    initiallyLoaded,
    /** @type {() => void} fetches latest list of accounts */
    refresh
  }
}
