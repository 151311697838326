import _ from 'lodash'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bookmarksAPIFn } from '../api'
import { updateBookmarks, updateBookmarksRefs } from 'infra/redux/reducers/slices/bookmarksSlice'

/**
 *
 * @returns
 */
export function useBookmarks() {
  const [bookmarksLoaded, setBookmarksLoaded] = useState(false)
  //@ts-ignore
  const bookmarks = useSelector((s) => {
    // @ts-ignore
    if (s.bookmarks.data) {
      // @ts-ignore
      return s.bookmarks.data.Refs.ObjectRef
    }
    return null
  })

  //@ts-ignore
  const initiallyLoaded = useSelector((s) => s.bookmarks?.loaded)

  const bookmarksAPI = useRef(bookmarksAPIFn()).current
  //@ts-ignore
  const user = useSelector((s) => s.user)
  const dispatch = useDispatch()

  const initialiseBookmarks = async () => {
    try {
      const [data, err] = await bookmarksAPI.getAll()
      if (data) dispatch(updateBookmarks(data))
      if (err && err.response?.data?.error === 'record not found') {
        // If record not found - create
        const [data] = await bookmarksAPI.create(user.ObjectMeta.Name, user.ObjectMeta.ID)
        if (data) dispatch(updateBookmarks(data))
      }
    } catch (error) {
    } finally {
      setBookmarksLoaded(true)
    }
  }

  const toggleBookmark = async (key) => {
    if (!bookmarks) return
    const [RefKind, RefID] = key.split('+')
    if (_.find(bookmarks, { RefKind, RefID })) {
      const newState = bookmarks.filter((e) => {
        return !(RefKind === e.RefKind && RefID === e.RefID)
      })
      dispatch(updateBookmarksRefs(newState))
      await bookmarksAPI.updateRefs(newState)
    } else {
      // TODO! Update the refs from slice
      dispatch(updateBookmarksRefs([...bookmarks, { RefKind, RefID }]))
      await bookmarksAPI.updateRefs([...bookmarks, { RefKind, RefID }])
    }
  }

  const isBookmarked = (key) => {
    if (!bookmarks) return
    const [RefKind, RefID] = key.split('+')
    return _.find(bookmarks, { RefKind, RefID })
  }

  if (!bookmarksLoaded && !initiallyLoaded && user) {
    setBookmarksLoaded(true)
    initialiseBookmarks()
  }

  return { toggleBookmark, isBookmarked }
}
