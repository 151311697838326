import { ApiProvider } from 'Core'

export const createWorkload = async (payload) => {
  const response = await new ApiProvider('workloadidentitys').setInstance(payload).post()
  return response.data
}

export const updateWorkload = async (payload) => {
  const response = await new ApiProvider('workloadidentitys').setInstance(payload).put()
  return response.data
}


export const refreshWorkloadCredentials = async (workload) => {
  const response = await new ApiProvider('workloadidentitys')
    .setInstance(workload)
    .updateInstance({
      Spec: {
        RefreshCreds: true
      }
    })
    .put()
  return response.data
}

export const deleteWorkload = async (workload) => {
  await new ApiProvider('workloadidentitys').setInstance(workload).delete()
  return workload
}

export const getWorkloadTokens = async (workload) => {
  const { ClientId } = workload.Status

  const payload = {
    ObjectMeta: {
      Name: ClientId
    }
  }

  const response = await new ApiProvider('workloadtokens').setInstance(payload).post()
  return response.data
}
