import { Box } from '@material-ui/core'
import { Cloud, ErrorOutline, NewReleases, Person, Storage } from '@material-ui/icons'
import AWSIcon from 'Components/CustomIcons/AWS'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import ProgressWithList from 'Components/ProgressWithList'
import { Theme } from 'Core'
import React from 'react'
import { getAccountImage } from 'Utils/PureHelperFuctions'

const DashboardAccountSegment = ({ counts }) => {
  /**  Data for circular progress graph. */
  console.log('RenderTracker ran useffect of DashboardAccountSegment')
  // const currentCount = 97; const totalcount = 100
  // const errorCount = 2
  // const percentage = currentCount / totalcount * 100
  // const currentltyActive = userCount
  // Text inside the circular progress.
  // const progressTexts = [
  //   { text: 'Health Status', variant: 'subtitle2', color: Theme.palette.text.disabled },
  //   { text: `${percentage}%`, variant: 'h5' }
  // ]
  // const progressData = {
  //   Icon: ({ className }) => <img className={className} src='img/color/aws.svg' />,
  //   iconSize: 2.5,
  //   size: '9.25rem',
  //   thickness: 2.5,
  //   count: totalcount,
  //   currentCount: currentCount,
  //   color: Theme.palette.success.main,
  //   texts: progressTexts,
  //   meta: {
  //     title: 'AWS Highlights',
  //     current: currentltyActive,
  //     errorCount
  //   }
  // }
  /**  Data for list. */
  const listData = [
    {
      title: 'AWS Accounts',
      current: counts.AWS,
      total: counts.AWS,
      error: false,
      Icon: ({ className }) => <img src={getAccountImage('AWS')} className={className} />
    },
    {
      title: 'GCP Accounts',
      current: counts.GCP,
      total: counts.GCP,
      error: false,
      Icon: ({ className }) => <img src={getAccountImage('GCP')} className={className} />
    },
    {
      title: 'Targets ( servers, approles and databases)',
      current: counts.serverCount + counts.roleCount + counts.databasesCount,
      total: counts.serverCount + counts.roleCount + counts.databasesCount,
      error: false,
      Icon: ({ className }) => <Storage className={className} />
    },
    {
      title: 'AZURE Accounts',
      current: counts.AZURE,
      total: counts.AZURE,
      error: false,
      Icon: ({ className }) => <img src={getAccountImage('AZURE')} className={className} />
    },
    {
      title: 'Users',
      current: counts.usersCount,
      total: counts.usersCount,
      error: false,
      Icon: ({ className }) => <Person className={className} />
    }
  ]

  const errorData = [
    // {
    //   InfoIcon: ErrorOutline,
    //   MainIcons: {
    //     Icon: Storage,
    //     Brand: (props) => <img src='img/color/aws.svg' alt='amazon brand icon' {...props} />
    //   },
    //   Text: {
    //     Server: 'Server 1',
    //     BrandTitle: 'AWS Account 1'
    //   }
    // },
    // {
    //   InfoIcon: ErrorOutline,
    //   MainIcons: {
    //     Icon: Storage,
    //     Brand: (props) => <img src='img/color/aws.svg' alt='amazon brand icon' {...props} />
    //   },
    //   Text: {
    //     Server: 'Server 1',
    //     BrandTitle: 'AWS Account 1'
    //   }
    // }
  ]

  return (
    <Box id='intro-js-accounts'>
      <CustomTypo variant='h4'>Accounts</CustomTypo>
      <Box mt={1}>
        <ProgressWithList
          listData={listData}
          // progressData={progressData}
          errorData={errorData}
          title='Accounts'
        />
      </Box>
    </Box>
  )
}

export default React.memo(DashboardAccountSegment)
