import { getResourceName, getRsrcIcon } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { BundlesGridView } from '../BundlesGridView'
import useAppView from 'Core/Hooks/useAppView'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { AdvancedSearch } from 'V2Components'

const useSlices = createDataSelectorHook(['bundles'])

const BundlesList = () => {
  const [searchKey, setSearchKey] = useState('')

  //@ts-ignore
  const { bundleType } = useParams()
  const { slices } = useSlices()
  const history = useHistory()

  const { appView } = useAppView()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const { name } = row

      const all = `${name}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const getBundlesData = () => {
    const data = []

    slices.bundles.forEach((bundle) => {
      if (bundle.Type === bundleType.toUpperCase())
        data.push({
          name: getResourceName(bundle),
          Icon: getRsrcIcon(bundle),
          status: 'ungranted',
          bundleType: bundle.Type,
          onCopyProfileClick() {},
          onMoreInfoClick() {
            history.push(
              `/${appView}/bundles/${bundleType.toLowerCase()}/${bundle.ObjectMeta.Name}`
            )
          }
        })
    })

    return applySearchQuery(data)
  }

  const data = getBundlesData()

  return (
    <div>
      {slices.bundles.length > 0 && (
        <>
          <AdvancedSearch
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            filterSaveKey='bundles-admin'
          />
          <BundlesGridView data={data} />
        </>
      )}
    </div>
  )
}

export { BundlesList }
