import React from 'react';
import { Button, ToggleViewControl, Typography, RepositoryCard } from 'procyon-ui'
import { SearchInput } from 'V2Components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useAppView from 'Core/Hooks/useAppView';

function Headers ({
  handleAddApplicationClick, 
  searchKey, 
  setSearchKey, 
  showAddButton,
  buttonLabel,
  count,
  showToggle,
  title})  {
    const { appView } = useAppView()
    return (
      <div className='flex justify-between items-center'>
      <Typography variant='body-regular'>{count} {title}</Typography>
      <div className='flex gap-6'>
        <SearchInput className='!w-[303px]' searchKey={searchKey} onChange={setSearchKey} />
        {showToggle && 
        <ToggleViewControl ariaLabel='view control' options={['grid', 'table']} value={['grid']} />
        } 
        {appView === 'admin' && showAddButton && (
          <Button icon={faPlus} onClick={() => handleAddApplicationClick()} size='md' variant='primary'>
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
    )
} 

export {Headers};