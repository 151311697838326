import { useActivityLogParser, useMultiQuery } from 'features/activityLogs'
import { createRsrcKey } from 'features/resources'
import moment from 'moment'
import React, { createContext, useContext, useEffect, useState } from 'react'

const DashboardDataContext = createContext({
  heatMap: {
    users: {},
    devices: {},
    resources: {}
  },
  startDays: '1',
  /**
   *
   * @param {string} a String number of days
   */
  setSetStartDays(a) {}
})

export const DashboardDataProvider = ({ children }) => {
  const [startDays, setSetStartDays] = useState('1')
  const startDateTime = moment().subtract(startDays, 'days')
  const endDateTime = new Date()

  const { getQueryLogs, hits } = useMultiQuery({
    indexes: ['audit-log', 'proxy-audit-log'],
    startDateTime: startDateTime.toDate(),
    endDateTime: endDateTime
  })

  const { getParsedLogData } = useActivityLogParser()

  const getLogsHeatMap = () => {
    const map = {
      users: {},
      devices: {},
      resources: {}
    }

    const parsedHits = getParsedLogData(hits)
    for (const hit of parsedHits) {
      const { user, device, resources, eventTime } = hit

      if (device) {
        const eventTimes = map.devices[createRsrcKey(device)]?.eventTimes || []

        map.devices[createRsrcKey(device)] = {
          count: (map.devices[createRsrcKey(device)]?.count || 0) + 1,
          device,
          eventTimes: [eventTime, ...eventTimes],
          owner: user
        }
      }

      if (user) {
        const eventTimes = map.users[createRsrcKey(user)]?.eventTimes || []
        map.users[createRsrcKey(user)] = {
          count: (map.users[createRsrcKey(user)]?.count || 0) + 1,
          user,
          eventTimes: [eventTime, ...eventTimes]
        }
      }

      if (resources?.length) {
        resources.forEach(({ key, rsrc }) => {
          if (!key || !rsrc) return
          const eventTimes = map.resources[key]?.eventTimes || []

          map.resources[key] = {
            count: (map.resources[key]?.count || 0) + 1,
            rsrc,
            eventTimes: [eventTime, ...eventTimes]
          }
        })
      }
    }
    return map
  }

  const heatMap = getLogsHeatMap()

  useEffect(() => {
    getQueryLogs()
  }, [startDays])

  return (
    <DashboardDataContext.Provider value={{ heatMap, setSetStartDays, startDays }}>
      {children}
    </DashboardDataContext.Provider>
  )
}

export const useDashboardDataProvider = () => useContext(DashboardDataContext)
