import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { isPolicyIssuedToUser } from 'features/policy'
import { getRDPServerAccessLinksMap, getTargetsPolicyRules } from '../utils'
import { useUser } from 'Core/Hooks/useUser'

export const useRDPAccess = () => {
  const { slices } = useMultiSlice(['policyList'])
  const { rdpurl } = useUser()
  /**
   * Get all the `RDP Server` Sign In links belonging to the `user`
   * @param {*} user The user whose rdp links are needed
   * @param {*} rdp The RDP Server
   */
  const getUserRDPAccessLinks = (user, rdp) => {
    const linkMap = {}
    const accessLinksMap = getRDPServerAccessLinksMap(rdp)

    slices.policyList.forEach((policy) => {
      if (isPolicyIssuedToUser(policy, user)) {
        const { RDPAccess } = getTargetsPolicyRules(policy)
        RDPAccess.forEach(({ ObjectRef, Principal }) => {
          // The rdp rule belongs to the object
          if (ObjectRef.RefID === rdp.ObjectMeta.ID) {
            // Get RDP Access Link for the principal with which the policy was created
            linkMap[Principal] = accessLinksMap[Principal]
          }
        })
      }
    })
    return linkMap
  }

  return { getUserRDPAccessLinks }
}
