import { createRsrcKey } from 'features/resources'
import store from 'infra/redux/store'
import { MapKindToSliceName } from './constants'
import * as sls from 'infra/redux/reducers/slices/slices'
import _ from 'lodash'
import { LOADING_STATUS } from '../reducers'

// This is used to override the slice.name with custom name
const EXCEPTIONS_MAP = {
  credentialTypesList: 'credentialTypeList'
}

const FETCH_ASYNC_OBJECT_THUNK_MAP = {}
const FETCH_ALL_THUNK = {}
/**
 * Parse all thunks and slices names.
 */
for (const property in sls) {
  const { name: _name, fetchAsyncObjectThunk, thunk } = sls[property]
  const name = EXCEPTIONS_MAP[_name] || _name
  FETCH_ASYNC_OBJECT_THUNK_MAP[name] = fetchAsyncObjectThunk
  FETCH_ALL_THUNK[name] = thunk
}

/**
 * The function accepts refrence of an object. And returns the source object from Redux state.
 * In case the object is not present, a fetch request is send to receive the object instead
 * @param {{RefID: string, RefKind: string} | {RefID: string, RefKind: string}[]} ref
 */
export const getObjectFromRedux = (ref) => {
  if (!ref) return
  const state = store.getState()
  // * 1. Convert ref into array if necessary
  const refArr = _.isArray(ref) ? ref : [ref]
  const items = [] // For storing the objects
  refArr.forEach(({ RefKind, RefID }) => {
    const k = createRsrcKey({ RefID, RefKind })
    // * 2. Get the map from the slice
    const sliceName = MapKindToSliceName[RefKind]
    if (!sliceName) return
    const map = state[sliceName]?.map || {}
    const status = state[sliceName]?.status
    const initiallyLoaded = state[sliceName]?.initiallyLoaded
    const thunk = FETCH_ALL_THUNK[sliceName]

    // * 3a. If the object ref exists in the slice push the 'o'bject into items array
    if (map[k]) {
      items.push(map[k])
    } else {
      // * 3b. Object was not found in the slice so we need to dispatch fetch for the ref
      // const fetchingMapKeyMap = state[sliceName].fetchingMapKeys
      // const notFoundKeysMap = state[sliceName].notFoundMapKeys
      // const isObjectBeingFetched = fetchingMapKeyMap[createRsrcKey({ RefID, RefKind })]
      // const isObjectNotFound = notFoundKeysMap[createRsrcKey({ RefID, RefKind })]
      if (status === LOADING_STATUS.IDLE && !initiallyLoaded) {
        // * 5. Since it is not being fetch, disptach the fetch action
        store.dispatch(thunk())
      }
    }
  })

  /**
   * If the `ref` was an array then, we return an array.
   * Else, we return the object which was found or undefined
   */
  if (_.isArray(ref)) return items
  return items[0]
}
