import { cn } from 'Utils/Helpers'
import { Label, Typography } from 'procyon-ui'
import React from 'react'

function LabelContent({
  title,
  content,
  showEmptyLabel = false,
  className = '',
  fullWidth = false,
  titleBreakAll = true
}) {
  return (
    <div className={cn('flex items-center border-[#D8DDE4] border-b py-2 min-h-[58px]', className)}>
      <Typography
        className={cn('!text-[#A6ABB6] w-[200px] !font-semibold mr-2', {
          '!break-all': titleBreakAll
        })}
        variant='body-semiBold'
      >
        {title}
      </Typography>
      {!content && showEmptyLabel && (
        <Label text={<em className='text-[#8d94a1]'>Empty</em>} variant='gray' />
      )}
      {typeof content === 'string' ? (
        <Typography className='!break-all' variant='body-regular'>
          {content}
        </Typography>
      ) : (
        <div
          className={cn('w-[auto]', {
            '!w-[100%] pl-11': fullWidth
          })}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export { LabelContent }
