import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { refreshWorkloadCredentials } from 'features/workloads'
import React from 'react'

function UpdateWorkloadKeysModal({ workload, cleanUpFn, onCancel = null }) {
  const handleUpdate = async () => {
    const updtWorkload = await refreshWorkloadCredentials(workload)
    updateSliceData(updtWorkload)
    enqueueNotification('Workload Access Keys updated successfully', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Update Keys'
        actionButtonVariant='primary'
        alertMessage='Are you sure you want to update keys?'
        loadingMessage='Updating Workload Access Keys'
        onActionClick={handleUpdate}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { UpdateWorkloadKeysModal }
