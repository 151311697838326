import { ApiProvider } from 'Core'
import { getUserInfo } from 'Utils/Helpers'

export const cartAPIFn = () => ({
  /**
   *
   * @param {import('infra/redux/reducers/slices/cartSlice').key} key
   */
  async createCart(key) {
    const api = new ApiProvider('carts')
    const user = getUserInfo()
    if (!user) return
    const OwnerRef = {
      ObjectRef: [
        {
          RefKind: 'User',
          RefID: user.ObjectMeta.ID
        }
      ]
    }
    const data = {
      ObjectMeta: {
        Name: `${user.ObjectMeta.Name}+${user.ObjectMeta.ID}+${key}`,
        WrOwners: OwnerRef,
        RdOwners: OwnerRef
      },
      CartQueues: {
        CartQueue: []
      }
    }
    const response = await api.setInstance(data).post()
    return response.data
  },
  /**
   *
   * @param {import('infra/redux/reducers/slices/cartSlice').key} key
   */
  async getCart(key) {
    const api = new ApiProvider('carts')
    const user = getUserInfo()
    if (!user) return
    const response = await api.setInstance({ ObjectMeta: { Name: `${user.ObjectMeta.Name}+${user.ObjectMeta.ID}+${key}` } }).get()
    return response.data
  },
  /**
   *
   * @param {any} data
   */
  async updateCart(data) {
    const api = new ApiProvider('carts')
    const response = await api.setInstance(data).put()
    return response.data
  }
})
