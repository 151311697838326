import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.50, 0.75),
    borderRadius: theme.spacing(0.25),
    display: 'flex',
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[400]}`
  },
  service: {
    // minWidth: '220px',
    marginRight: theme.spacing(0.5),
    textAlign: 'right'
  },
  servicetext: {
    // fontSize: '0.875rem',
    fontWeight: 'normal',
    color: theme.palette.text.primary
  },
  actions: {
    // marginTop: '-2.5px',
    display: 'flex',
    flexDirection: 'column',
    // marginBottom: ' 0.125rem',
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))
const ActionCard = ({ service, actions }) => {
  const classes = useStyle()
  return (
    <Box className={classes.root}>
      <Box className={classes.service}>
        <Typography variant='body2' className={classes.servicetext}>{service}:</Typography>
      </Box>
      <Box className={classes.actions}>
        {actions.map(action => (
          <Typography variant='body2' key={action}>{action}</Typography>
        ))}
      </Box>
    </Box>
  )
}

export default ActionCard
