import {
  faBadgeCheck,
  faDatabase,
  faHourglassEmpty,
  faServer
} from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getDeviceName } from 'Utils/PureHelperFuctions'
import { LabelContent } from 'V2Components'
import { getResourceName, getRsrcURL } from 'features/resources'
import { parseLogSessionData } from 'features/sessions'
import _ from 'lodash'
import moment from 'moment'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const SessionInfoTab = ({ log }) => {

  const { slices, getObjectRef } = useMultiSlice([
    'userList',
    'deviceList',
    'serverList',
    'kubeClusters',
    'databases'
  ])
  const { appView } = useAppView()
  const sessionData = parseLogSessionData(log)

  if (!sessionData) return null
  const firstData = sessionData[0]

  const ref = firstData.Ref || {}
  const rsrc = ref ? getObjectRef({ ...ref, RefID: ref.RefID?.toString() }) : null

  const isProcyonUser = firstData.User === 'procyon'
  const isProcyonDevice = firstData.Device === 'procyon'

  const user = _.find(slices.userList, { ObjectMeta: { Name: firstData.User } })
  const device = _.find(slices.deviceList, { ObjectMeta: { Name: firstData.Device } })

  const auditorName = (() => {
    if (!log.Audited) return
    const u = getObjectRef(log.Auditor)
    return u ? getResourceName(u) : log.AuditorName
  })()

  return (
    <div>
      <LabelContent
        title='User'
        showEmptyLabel
        content={!isProcyonUser ? getResourceName(user) : 'procyon'}
      />
      <LabelContent title='User Email' content={firstData.User} />
      <LabelContent
        title='Device'
        showEmptyLabel
        content={!isProcyonDevice ? getDeviceName(device) : 'procyon'}
      />
      <LabelContent title='Tenant' content={firstData.Tenant} />
      <LabelContent title='Organization' content={firstData.Org} />
      <LabelContent
        title='Resource'
        content={
          rsrc ? (
            <Link to={getRsrcURL(rsrc, appView)}>
              <Typography className='!text-blue-500' variant='body-regular'>
                {getResourceName(rsrc)}
              </Typography>
            </Link>
          ) : (
            <Label width='90px' variant='gray' text='Not Found' />
          )
        }
      />
      <LabelContent
        title='Resource Type'
        content={(() => {
          switch (log.ResoureceType) {
            case 'SSH':
              return <Label width='90px' variant='success' iconButton={faServer} text='Server' />
            case 'DB':
              return (
                <Label width='90px' variant='grayBlue' iconButton={faDatabase} text='Database' />
              )
            case 'K8':
              return (
                <Label
                  width='90px'
                  variant='grayBlue'
                  icon={<TargetIcon type='KUBE_CLUSTERS' />}
                  text='K8'
                />
              )
            case 'RDP':
              return (
                <div className='w-[100%] flex gap-8 justify-between items-center'>
                  <Label
                    width='90px'
                    variant='grayBlue'
                    icon={<TargetIcon className='rounded' type='RDP_SERVER' />}
                    text='RDP'
                  />
                </div>
              )
            default:
              return (
                <Label text={log.ResoureceType} iconButton={faHourglassEmpty} variant='warning' />
              )
          }
        })()}
      />
      {log.ResoureceType === 'DB' && <LabelContent title='DB Name' content={firstData.DBName} />}
      {log.ResoureceType === 'SSH' ||
        (log.ResoureceType === 'RDP' && (
          <LabelContent title='Principal' content={firstData.Principal} />
        ))}
      {log.Audited && <LabelContent title='Auditor' content={auditorName} />}
      {log.Audited && (
        <LabelContent
          title='Audit Time'
          content={moment(log.AuditTime).format('MMMM Do YYYY, h:mm A')}
        />
      )}

      <LabelContent
        title='Status'
        content={
          log.Audited ? (
            <Label variant='success' iconButton={faBadgeCheck} text='Audited' />
          ) : (
            <Label text='Non Audited' variant='warning' />
          )
        }
      />
    </div>
  )
}
