import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityInfoBar, InfoTabsModal } from 'V2Components'
import { getUserRefsFromGroup } from 'features/groups'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function GroupsUsersModal({ group, onClose }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const { getObjectRef } = useMultiSlice(['userList'])

  const history = useHistory()

  const description = group.Spec.Description

  const handleViewDetailsClick = () => {
    history.push(`/admin/groups/${group.ObjectMeta.Name}`)
  }

  const users = getObjectRef(getUserRefsFromGroup(group))

  return (
    <div>
      <InfoTabsModal
        activeIndex={activeIndex}
        caption={description}
        onClose={onClose}
        onPrimaryButtonClick={handleViewDetailsClick}
        primaryButtonLabel='View Details'
        setActiveIndex={setActiveIndex}
        title={getResourceName(group)}
        tabs={[
          {
            label: 'Users',
            tabContent: (
              <div className='h-[500px] overflow-y-auto'>
                <Typography variant='body-regular' className='mb-6'>
                  {users.length} Users
                </Typography>
                {users.map((u) => (
                  <EntityInfoBar
                    endButton={false}
                    key={createRsrcKey(u)}
                    kind='User'
                    caption={u.Spec.EmailID}
                    name={getResourceName(u)}
                  />
                ))}
              </div>
            )
          }
        ]}
      />
    </div>
  )
}

export { GroupsUsersModal }
