import { AccountSelectionItem, TargetIcon, TreeItem, TreeView } from 'procyon-ui'
import React, { useState } from 'react'

const MsChannelSelection = ({ filteredChannel, msTeamsChannels, handleChannelSelection }) => {
  const handleclick = (data) => {
    handleChannelSelection(data)
  }
  const renderChannelTree = (channel) => {
    const filterTeamChannels = msTeamsChannels.filter((channels) => {
      const msTeamObjectRef = channels.Spec.MsTeamsTeam.ObjectRef[0]
      if (msTeamObjectRef.RefID === channel.ObjectMeta.ID) {
        return channels
      }
    })

    return filterTeamChannels.map((item, index) => (
      <TreeItem
        onClick={() => handleclick(item)}
        labelIcon={<TargetIcon type='TEAMS_CHANNEL' />}
        labelText={item.Spec.ChannelName}
        nodeId={item.Spec.ChannelID}
      />
    ))
  }

  return (
    <div>
      <TreeView
        defaultExpanded={['0']}
        height={'100%'}
        maxWidth={'100%'}
        onNodeFocus={function noRefCheck() {}}
        onNodeSelect={() => {}}
        onNodeToggle={function noRefCheck() {}}
        sx={{
          flexGrow: 1,
          overflowY: 'auto'
        }}
      >
        {filteredChannel &&
          filteredChannel.map((item, index) => (
            <TreeItem
              labelIcon={<TargetIcon type='TEAMS' />}
              labelText={item.Spec.Name}
              nodeId={index}
              key={index}
              disabled={!renderChannelTree(item).length}
            >
              {renderChannelTree(item)}
            </TreeItem>
          ))}
      </TreeView>
    </div>
  )
}

export { MsChannelSelection }
