
import { Box } from '@material-ui/core'
import classNames from 'classnames'
import TableHeader from 'Components/AnalyticTable/components/TableHeader'
import LinearDistribution from 'Components/LinearDistribution'

export const options = () => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: false,
  rowsPerPageOptions: [5, 10, 15, 100]
})

export const columns = (headerClassNames = {}) => [
  {
    name: 'index',
    label: ' ',
    options: {
      filter: false
    }
  },

  {
    name: 'location',
    label: 'Location',
    options: {
      filter: false,
      sort: true,
      setCellHeaderProps: (value) => ({ className: headerClassNames.location }),
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />

    }
  },
  {
    name: 'activeUser',
    label: 'Active User',
    options: {
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      customBodyRender: (value, tableMeta) => {
        return <Box style={{ textAlign: 'right' }}>{value}</Box>
      },
      filter: true,
      sort: true

    }
  },
  {
    name: 'activityDistribution',
    label: 'Activity distribution',
    options: {
      setCellProps: () => ({ style: { verticalAlign: 'middle', textAlign: 'right', paddingRight: 0 } }),
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return <LinearDistribution distributions={value} />
      }
    }
  }
]
