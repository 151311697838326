export const approvalReqMiddlewares = [
  (data) => {
    const newData = data.map((e) => {
      //Remove the debug labels. These fields change on every request so, deep equality check will fail everytime
      delete e.ObjectMeta.Labels?.Map.DbReadDiff
      delete e.ObjectMeta.Labels?.Map.endTime
      delete e.ObjectMeta.Labels?.Map.startTime
      return e
    })

    return newData
  }
]
