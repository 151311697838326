import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { useUser } from 'Core/Hooks/useUser'
import {
  IANA_TIMEZONES,
  StartEndTimePicker,
  convertTZ,
  createRef,
  createRsrcKey,
  getResourceName
} from 'features/resources'
import { useTagsCreatePolicyContext, useTagsURLQueryContext } from 'features/tags'
import { reduxApiClient } from 'infra'
import moment from 'moment'
import { Button, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { LoadingFeedback } from 'V2Components'

const Config = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [dateTimeError, setDateTimeError] = useState({ startDate: '', endDate: '' })
  const { user } = useUser()
  const { config, updateConfig, resources, rsrcAttributesSpec, entities } =
    useTagsCreatePolicyContext()
  const { appView } = useAppView()

  const { tagType, tagValue, tagKey } = useTagsURLQueryContext()
  const canGoBackFn = useCanGoBack(`/${appView}/resources`)

  const handleCreatePolicy = async () => {
    try {
      setIsLoading(true)
      const data = {
        ObjectMeta: {
          Kind: 'PacPolicy',
          Name: getFriendlyName(),
          RdOwners: {
            ObjectRef: entities.map(createRef)
          }
        },
        Spec: {
          Description: config.comments,
          ActionMap: {}
        },
        IssuedTo: {
          ObjectRef: entities.map(createRef)
        },
        NotBefore: moment(convertTZ(config.startDate, timeZone)).utc(),
        NotAfter: moment(convertTZ(config.endDate, timeZone)).utc(),
        GivenName: config.name,
        Type: 'Target',
        Creator: getResourceName(user)
      }
      if (resources.find((e) => e.ObjectMeta.Kind === 'Server')) {
        const SSHRule = data.Spec.ActionMap.SSH || { PolicyRule: [] }

        SSHRule.PolicyRule.push({
          ObjectRef: {},
          Principal: rsrcAttributesSpec.Server?.Principal || 'none',
          Attributes: {
            Map: {
              [`server:${
                tagType === 'tag' ? 'ctag' : 'label'
              }:${tagKey.toLowerCase()}:${tagValue.toLowerCase()}`]: ''
            }
          }
        })

        data.Spec.ActionMap.SSH = SSHRule
      }

      if (resources.find((e) => e.ObjectMeta.Kind === 'Application')) {
        const HTTPRule = data.Spec.ActionMap.HTTP || { PolicyRule: [] }

        HTTPRule.PolicyRule.push({
          ObjectRef: {},
          Principal: '',
          Attributes: {
            Map: {
              [`application:${
                tagType === 'tag' ? 'ctag' : 'label'
              }:${tagKey.toLowerCase()}:${tagValue.toLowerCase()}`]: ''
            }
          }
        })

        data.Spec.ActionMap.HTTP = HTTPRule
      }

      await reduxApiClient('pacpolicys').create(data)
      enqueueNotification('Tag Policy successfully created!', 'info')
      //@ts-ignore
      canGoBackFn()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const getErrors = () => {
    let errors = ''

    if (!config.name) return 'Name is required.'

    resources.some((rsrc) => {
      const { Kind } = rsrc.ObjectMeta
      const rsrcKey = createRsrcKey(rsrc)
      const attributesMap = rsrcAttributesSpec[rsrcKey]
      if (['AwsResource', 'GcpResource', 'AzureResource'].includes(Kind)) {
        if (!attributesMap || !attributesMap.IamRoles?.length) {
          errors = 'Select iam roles for all of the resources.'
          return true
        }
      }
      if (['Server'].includes(Kind)) {
        if (!rsrcAttributesSpec.Server?.Principal.trim()) {
          errors = 'Principal cannot be empty.'
          return true
        }
      }
    })
    if (!entities.length && !errors) errors = 'Entities cannot be empty.'
    if (dateTimeError.endDate || dateTimeError.startDate) {
      errors = dateTimeError.endDate || dateTimeError.startDate
    }
    return errors
  }

  const errors = getErrors()

  return (
    <div>
      <Typography variant='h4-regular'>Configure Policy</Typography>
      <div className='mt-6'>
        <TextInput
          sx={{
            width: '100%'
          }}
          value={config.name}
          onChange={(e) => updateConfig({ name: e.target.value })}
          label='Policy Name*'
        />
        <Typography className='my-4' variant='body-semiBold'>
          Setup Access Time
        </Typography>
        <div className='mb-2'>
          <Typography className='mb-2' variant='body-regular'>
            Select Timezone
          </Typography>
          <SelectDropDown
            menuItems={IANA_TIMEZONES.map((tz) => ({ label: tz, value: tz }))}
            onChange={(e) => setTimeZone(e.target.value)}
            value={timeZone}
          />
        </div>
        <StartEndTimePicker
          sD={config.startDate}
          eD={config.endDate}
          onError={setDateTimeError}
          onStartDateChange={(d) => updateConfig({ startDate: d })}
          onEndDateChange={(d) => updateConfig({ endDate: d })}
        />
        <TextInput
          placeholder='Comments'
          className='mt-4'
          value={config.comments}
          onChange={(e) => updateConfig({ comments: e.target.value })}
          multiline
          rows={3}
          sx={{ width: '100%' }}
        />
        <div className='flex justify-end mt-4 gap-2'>
          {errors && <Label text={errors} variant='warning' />}
          <Button onClick={handleCreatePolicy} disabled={!!errors} variant='primary'>
            Create
          </Button>
        </div>
      </div>
      <LoadingFeedback message='Creating Policy' caption='Please wait...' loading={isLoading} />
    </div>
  )
}

export { Config }
