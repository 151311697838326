import { Box, Chip, Grid, makeStyles } from '@material-ui/core'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import React from 'react'

const useStyle = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.disabled,
    width: theme.spacing(1.125),
    height: theme.spacing(1.125)
  },
  chip: {
    background: '#e9f4fd',
    height: theme.spacing(1.125),
    fontSize: theme.typography.caption.fontSize
  }
}))

const IconChipCount = ({ Icon, total = false, current, mt = 0, totalVariant = 'caption', currentVariant = 'h5' }) => {
  const classes = useStyle()
  return (
    <Box width='100%' mt={mt} alignItems='center' display='flex'>
      {Icon && <Icon className={classes.icon} />}
      <Box mx={0.5}>
        <Chip size='small' className={classes.chip} label='Active' />
      </Box>
      <Grid alignItems='flex-end' container>
        <Grid item>
          <CustomTypo variant={currentVariant}>{current}</CustomTypo>
        </Grid>
        <Grid item>
          {total && (
            <Box>
              <CustomTypo variant={totalVariant} emphasis='medium'>
                &nbsp; / {total}
              </CustomTypo>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default IconChipCount
