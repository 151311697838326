import { faEye, faEyeSlash, faTrash, faUndo, faUser } from '@fortawesome/free-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { enqueueNotification } from 'Utils/Helpers'
import {
  FullScreenAlertModal,
  FullScreenContentModal,
  LabelContent,
  LoadingFeedback,
  SummaryHeader,
  SwitchBoxLabelContent
} from 'V2Components'
import { useCloudTypes } from 'features/clouds'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import { EntityPicker } from './components/EntityPicker'
import { SelectDropDown, SelectTime, Tooltip } from 'procyon-ui'
import _ from 'lodash'
import {
  Button,
  IconButton,
  Label,
  LabelCheckbox,
  TargetIcon,
  TextInput,
  Typography
} from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { IANA_TIMEZONES } from 'features/resources'
import { useResourceAccess } from 'Core/Hooks/useResourceAccess'
import moment from 'moment'

const TIME_FORMAT = 'HH:mm:ss'
const useSlices = createDataSelectorHook(['adDomainControllers', 'userList'])
const CreateADD = () => {
  const { controllername } = useParams()
  const {
    slices: { adDomainControllers, userList }
  } = useSlices()
  const addc = _.find(adDomainControllers, { ObjectMeta: { Name: controllername } })
  const { appView } = useAppView()
  const [name, setName] = useState(_.get(addc, 'ObjectMeta.Name', ''))
  const [username, setUsername] = useState(_.get(addc, 'Spec.Username', ''))
  const [password, setPassword] = useState(_.get(addc, 'Spec.Password', ''))
  const [selectedCloudTypes, setSelectedCloudTypes] = useState(
    _.get(addc, 'Spec.DefaultFor.Elems', [])
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRowForDelete, setSelectedRowForDelete] = useState()
  const [savingDisable, setSavingDisable] = useState(false)
  const [isRotatingPassword, setIsRotatingPassword] = useState(false)
  const [userSelectionModal, setUserSelectionModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [userRef, setUserRef] = useState(_.get(addc, 'Spec.UserMap.Map', {}))
  const [rotatePasswords, setRotatePassword] = useState(_.get(addc, 'Spec.RotatePasswords', false))
  const [isLocalUser, setIsLocalUser] = useState(_.get(addc, 'Spec.IsLocal', false))
  const addcNames = adDomainControllers.map((e) => e?.ObjectMeta?.Name)
  const [currentRowSelectData, setCurrentRowSelectData] = useState({})
  const [currentSelectedRowIndex, setCurrentSelectedRowIndex] = useState()
  const [dbRow, setDBrow] = useState([])
  const credentialsData = addc?.Spec?.Credentials?.Map || {}
  const [isDataFetched, setIsDataFetched] = useState(true)
  const [isUserRemovedState, setIsUserRemovedState] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showUserPasswords, setShowUserPasswords] = useState(dbRow.map(() => false))
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const { updateSpec, spec } = useResourceAccess()
  const [timeZone, setTimeZone] = useState(
    _.get(addc, 'Spec.AutoApprovalHours.TimeZone', spec.timeZone)
  )

  const [times, setTimes] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day.toLowerCase()] = { StartTime: '', EndTime: '' }
      return acc
    }, {})
  )

  useEffect(() => {
    if (isEditMode && addc) {
      setName(_.get(addc, 'ObjectMeta.Name', ''))
      setIsLocalUser(_.get(addc, 'Spec.IsLocal', false))
      setUsername(_.get(addc, 'Spec.Username', ''))
      setPassword(_.get(addc, 'Spec.Password', ''))
      setSelectedCloudTypes(_.get(addc, 'Spec.DefaultFor.Elems', []))
      setRotatePassword(_.get(addc, 'Spec.RotatePasswords', false))
      setUserRef(_.get(addc, 'Spec.UserMap.Map', {}))
      setTimeZone(_.get(addc, 'Spec.AutoApprovalHours.TimeZone'))
      initializeDBRows()
      setIsDataFetched(false)
    } else {
      setIsDataFetched(false)
      initializeDBRows()
      setTimeZone(spec.timeZone)
    }
  }, [addc])

  useEffect(() => {
    if (isEditMode && addc) {
      setTimes((prevTimes) => ({
        ...prevTimes,
        ...addc.Spec.AutoApprovalHours.HourMap
      }))
    }
  }, [addc])

  const initializeDBRows = () => {
    let initialRows = Object.keys(credentialsData).map((username) => ({
      Username: username,
      Password: credentialsData[username],
      mandatory: false,
      error: false,
      disableInput: true
    }))

    if (Object.keys(credentialsData).length === 0) {
      initialRows.push({
        Username: '',
        Password: '',
        mandatory: true,
        error: false,
        disableInput: false
      })
    }

    setDBrow(initialRows)
  }

  /**
   * Function returns all the cloud types which already have a ADDC associated.
   * Since, having multiple ADDC with default cloud types is disallowed.
   */
  const getExistingADDCCloudTypes = () => {
    const types = {}
    adDomainControllers.forEach((addc) => {
      const defaultFor = _.get(addc, 'Spec.DefaultFor.Elems', [])
      defaultFor.forEach((e) => {
        types[e] = true
      })
    })

    return Object.keys(types)
  }

  const existingADDCCloudTypes = getExistingADDCCloudTypes()
  const isEditMode = !!addc

  const { cloudTypes } = useCloudTypes()

  const handleCloudTypeToggle = (cloudType) => {
    setSelectedCloudTypes((s) => {
      let newS = [...s]
      if (s.includes(cloudType)) newS = s.filter((e) => e !== cloudType)
      else newS.push(cloudType)
      return newS
    })
  }

  const getErrors = () => {
    if (name.trim().length < 3) return 'Name must be greater than 3 characters.'
    if (addcNames.includes(name.trim()) && !isEditMode) return 'Name is already in use.'
    if (!username.trim().length && !isLocalUser) return 'Username is required.'
    if (!isEditMode && !password.trim().length && !isLocalUser) return 'Password is required.'
  }

  const handleCreate = async () => {
    setIsLoading(true)
    let setSuccess
    const specialCharacters = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
    const updatedRows = dbRow.map((row) => {
      if (row?.Username !== '' && specialCharacters.test(row?.Username)) {
        enqueueNotification('Only Special Cahracters are not allowed', 'error')
        delete dbRow[row?.Username]
        return
      }

      if (row.mandatory && !row?.Password.trim() && row?.Username !== '') {
        return {
          ...row,
          error: true
        }
      } else if (row?.Password !== '' && row?.Username === '') {
        return {
          ...row,
          error: true
        }
      } else {
        return {
          ...row,
          error: false
        }
      }
    })

    const hasEmptyPassword = updatedRows?.some((row) => row?.error)
    if (hasEmptyPassword) {
      setDBrow(updatedRows)
      setIsLoading(false)
      enqueueNotification('User name / User password cannot be empty', 'error')
      return
    } else {
      const allRowData = dbRow.map((row) => {
        return {
          Username: row.Username,
          Password: row.Password === undefined ? '' : row.Password
        }
      })

      // check if user alredy exist
      const modifiedData = allRowData.map(({ Username, Password }) => {
        if (credentialsData.hasOwnProperty(Username) && credentialsData[Username] !== Password) {
          // Password has been updated for an existing user
          return { Username, Password, updated: true }
        } else {
          // Either a new user or the password remains unchanged
          return { Username, Password, updated: false }
        }
      })

      const credentialsArray = modifiedData.filter((item) => item.Username !== '')
      const credentialsObject = credentialsArray.reduce((acc, { Username, Password }) => {
        if (!acc.hasOwnProperty(Username)) {
          acc[Username] = Password
        } else if (Password !== '') {
          acc[Username] = Password
        }
        return acc
      }, {})

      // default placholder entery
      const defaultCredentailObj = {
        'placeholder-652c5b8a-2978-46f5-a9f0-6f20001ff230': ''
      }
      // approver access time object
      const filteredTimes = Object.fromEntries(
        Object.entries(times).filter(([, value]) => value.StartTime && value.EndTime)
      )

      try {
        if (isEditMode) {
          const clone = structuredClone(addc)
          if (credentialsArray.length > 0) {
            clone.Spec.Credentials.Map = { ...credentialsObject, ...defaultCredentailObj }
          } else {
            clone.Spec.Credentials.Map = {}
          }
          clone.Spec.Username = username.trim()
          clone.Spec.Password = password
          clone.Spec.DefaultFor.Elems = selectedCloudTypes
          clone.Spec.RotatePasswords = rotatePasswords
          clone.Spec.IsLocal = isLocalUser
          clone.Spec.UserMap.Map = { ...userRef }
          clone.Spec.AutoApprovalHours.HourMap = { ...filteredTimes }
          clone.Spec.AutoApprovalHours.TimeZone = timeZone
          await reduxApiClient('addomaincontrollers').update(clone)
          enqueueNotification('AD Domain Controller updated successfully!', 'info')
          return
        }
        const payload = {
          ObjectMeta: {
            Name: name.trim()
          },
          Spec: {
            Username: username.trim(),
            Password: password,
            Credentials: {
              Map: {
                ...credentialsObject
              }
            },
            RotatePasswords: rotatePasswords,
            IsLocal: isLocalUser,
            AutoApprovalHours: {
              HourMap: filteredTimes,
              TimeZone: timeZone
            },
            UserMap: {
              Map: {
                ...userRef
              }
            },
            DefaultFor: {
              Elems: selectedCloudTypes
            }
          }
        }
        await reduxApiClient('addomaincontrollers').create(payload)
        enqueueNotification('AD Domain Controller created successfully!', 'info')
      } catch (error) {
        enqueueNotification('Error creating AD Domain Controller', 'error')
      } finally {
        setIsLoading(false)
      }
    }
  }
  const handleDeletRow = (rowIndex) => {
    setShowDeleteModal(true)
    setSelectedRowForDelete(rowIndex)
  }

  const handleDbRowInputChange = (fieldName, rowIndex, value) => {
    if (rowIndex >= 0 && rowIndex < dbRow.length) {
      const updatedRowData = [...dbRow]
      const updatedUsername = value.trim()
      updatedRowData[rowIndex][fieldName] = value

      if (fieldName === 'Username' && value !== '') {
        const lowerCaseUsername = updatedUsername.toLowerCase()
        const existingUserIndex = updatedRowData.findIndex(
          (row, index) => index !== rowIndex && row.Username.toLowerCase() === lowerCaseUsername
        )

        if (existingUserIndex !== -1) {
          setSavingDisable(true)
          enqueueNotification(`${value} user already exists`, 'error')
        } else {
          setSavingDisable(false)
        }
      }

      if (fieldName === 'Password' && value !== '') {
        updatedRowData[rowIndex]['error'] = false
      }

      setDBrow(updatedRowData)
    }
  }

  const handleToggleRotatePasswords = () => {
    if (!addc && !rotatePasswords) {
      setRotatePassword(true)
    } else {
      setRotatePassword(!rotatePasswords)
    }
  }

  const handleLocalUserToggle = () => {
    if (!addc && !isLocalUser) {
      setIsLocalUser(true)
    } else {
      setIsLocalUser(!isLocalUser)
    }
  }

  const handleAddDBRow = () => {
    const newRow = {
      id: Math.floor(Math.random() * 10000),
      Username: '',
      Password: '',
      mandatory: true,
      error: false,
      disableInput: false
    }
    setDBrow((prevRows) => [...prevRows, newRow])
  }

  const handleDeletDBRow = async (rowIndex) => {
    // Ensure that rowIndex is within a valid range
    setShowDeleteModal(false)
    if (rowIndex >= 0 && rowIndex < dbRow.length) {
      const updatedDBRow = dbRow.filter((row, index) => index !== rowIndex)
      setDBrow(updatedDBRow)
      const usernamesToMatch = updatedDBRow.map((item) => item.Username)

      // Filter the userObject based on the usernames
      const matchedUserData = Object.keys(userRef)
        .filter((username) => usernamesToMatch.includes(username))
        .reduce((acc, username) => {
          acc[username] = userRef[username]
          return acc
        }, {})
      setUserRef(matchedUserData)
      enqueueNotification('Deleted User Successfully!', 'info')
    }
  }

  const getEntities = () => {
    return [...userList]
  }

  const handleContinue = () => {
    setUserSelectionModal(!userSelectionModal)
    if (selectedEntities.length > 0) {
      const username = currentRowSelectData?.Username

      if (username !== '') {
        setUserRef((prevState) => ({
          ...prevState,
          [username]: {
            RefKind: selectedEntities[0].ObjectMeta?.Kind,
            RefID: selectedEntities[0]?.ObjectMeta?.ID
          }
        }))
        getSelectedUserInfo(currentSelectedRowIndex)
      }
    } else {
      const username = currentRowSelectData?.Username
      const cleanedObj = Object.keys(userRef)
        .filter((key) => key !== '' && !username.includes(key))
        .reduce((acc, key) => {
          acc[key] = userRef[key]
          return acc
        }, {})
      setUserRef(cleanedObj)
      setIsUserRemovedState(true)
      getSelectedUserInfo(currentSelectedRowIndex)
    }
    setSelectedEntities([])
  }

  const handleUserSelection = (rowIndex) => {
    const rowData = dbRow.find((row, index) => index === rowIndex)
    if (rowData) {
      const combinedUserMap = { ...addc?.Spec?.UserMap?.Map, ...userRef }
      const matchingKey = Object.keys(combinedUserMap || {}).find(
        (item) => item === rowData.Username
      )

      if (matchingKey) {
        const matchingData = combinedUserMap[matchingKey]
        const user = userList.filter((ul) => ul.ObjectMeta.ID === matchingData.RefID)
        setSelectedEntities(user)
      }
    }
    setCurrentRowSelectData(rowData)
    setCurrentSelectedRowIndex(rowIndex)
    setUserSelectionModal(!userSelectionModal)
  }

  const getSelectedUserInfo = (rowIndex) => {
    const rowData = dbRow.find((row, index) => index === rowIndex)
    let user = {}
    if (rowData) {
      const combinedUserMap = { ...addc?.Spec?.UserMap?.Map, ...userRef }
      const matchingKey = Object.keys(isUserRemovedState ? userRef : combinedUserMap || {}).find(
        (item) => item === rowData.Username
      )

      if (matchingKey) {
        const matchingData = combinedUserMap[matchingKey]
        user = userList.find((ul) => ul.ObjectMeta.ID === matchingData.RefID)
      }

      if (user) {
        return user?.Spec?.EmailID
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const togglePasswordVisibility = (index) => {
    setShowUserPasswords((prevState) => {
      const newState = [...prevState]
      newState[index] = !newState[index]
      return newState
    })
  }

  const handleUserSelectionCancel = () => {
    setSelectedEntities([])
    setUserSelectionModal(false)
  }

  const errors = getErrors()

  const handleTimeChange = (day, type, value) => {
    const timeIn24HourFormat = moment(value, ['h:mm A']).format(TIME_FORMAT)

    setTimes({
      ...times,
      [day.toLowerCase()]: {
        ...times[day.toLowerCase()],
        [type]: timeIn24HourFormat
      }
    })
  }

  const renderTimeInputs = () =>
    daysOfWeek.map((day, index) => (
      <div key={day} style={{ marginBottom: '10px' }}>
        <div className='flex items-center mb-2'>
          {index === 0 && (
            <span className='w-28'>
              <Typography variant='body-regular'>Days</Typography>
            </span>
          )}
          <div className='flex justify-between items-center w-[145px]'>
            <label className='ml-1 text-[#545b71]'>{day}</label>
            <IconButton
              onClick={() => handleResetDayTimes(day)}
              variant='clear'
              size='sm'
              icon={faUndo}
            />
          </div>
        </div>

        <div className='flex items-center mb-2'>
          {index === 0 && (
            <span className='w-28'>
              <Typography variant='body-regular'>Start Time</Typography>
            </span>
          )}

          <SelectTime
            style={{
              marginRight: '15px',
              width: '140px'
            }}
            onChange={(e) => handleTimeChange(day, 'StartTime', e.target.value)}
            value={times[day.toLowerCase()].StartTime}
          />
        </div>

        <div className='flex items-center'>
          {index === 0 && (
            <span className='w-28'>
              <Typography variant='body-regular'>End Time</Typography>
            </span>
          )}
          <SelectTime
            width='140px'
            onChange={(e) => handleTimeChange(day, 'EndTime', e.target.value)}
            value={times[day.toLowerCase()].EndTime}
          />
        </div>
      </div>
    ))

  const handleTimeZoneChange = (value) => {
    updateSpec({ timeZone: value })
    setTimeZone(value)
  }

  const handleResetDayTimes = (day) => {
    setTimes({
      ...times,
      [day.toLowerCase()]: { StartTime: '', EndTime: '' }
    })
  }

  const handleResetAllTimes = () => {
    const clearedTime = daysOfWeek.reduce((acc, day) => {
      acc[day.toLocaleLowerCase()] = { StartTime: '', EndTime: '' }
      return acc
    }, {})

    setTimes(clearedTime)
  }

  return (
    <div>
      <div>
        <SummaryHeader
          rsrcName={controllername !== 'create' ? controllername : 'Create Ad Domain Controller'}
          Icon={({ ...props }) => <TargetIcon type='AD_DOMAIN_CONTROLER' {...props} />}
          actions={<></>}
          breadCrumbsItems={[
            {
              label: 'AD Domain Controllers',
              link: `/${appView}/addomainControllers`
            },
            {
              label: controllername !== 'create' ? controllername : 'Create Ad Domain Controller',
              link: null
            }
          ]}
        />
      </div>

      <div className={'border-r w-[1200px] grid-col-4 border-[#D8DDE4] pr-2'}>
        <div className='mt-4'>
          <LabelContent
            title={'Name*'}
            content={
              <TextInput
                disabled={isEditMode}
                value={name}
                onChange={(e) => setName(e.target.value.trim())}
                sx={{ width: '300px' }}
              />
            }
          />
        </div>

        <SwitchBoxLabelContent
          value={isLocalUser}
          label='Local User'
          editMode
          onChange={handleLocalUserToggle}
        />

        <div>
          {!isLocalUser && (
            <div>
              <LabelContent
                title={'Admin Username*'}
                content={
                  <TextInput
                    autoComplete='off'
                    value={username}
                    onChange={(e) => setUsername(e.target.value.trim())}
                    sx={{ width: '300px' }}
                  />
                }
              />
              <LabelContent
                title={'Admin Password*'}
                content={
                  <div className='relative'>
                    <TextInput
                      autoComplete='new-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value.trim())}
                      sx={{ width: '300px' }}
                      type={showPassword ? 'text' : 'password'}
                    />
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      variant='clear'
                      icon={showPassword ? faEye : faEyeSlash}
                      style={{
                        marginRight: '10px',
                        position: 'absolute',
                        top: '2px',
                        right: '-8px'
                      }}
                    />
                  </div>
                }
              />
            </div>
          )}
          <div>
            <SwitchBoxLabelContent
              value={rotatePasswords}
              label='Rotate Passwords'
              editMode
              onChange={handleToggleRotatePasswords}
            />

            <LoadingFeedback
              message={`${rotatePasswords ? 'Disabling' : 'Enabling'} password rotation`}
              loading={isRotatingPassword}
              caption='Please wait...'
            />
          </div>
        </div>
        <div>
          {!isLocalUser && (
            <Typography className='mt-4 mb-4 !text-gray-500' variant='body-regular'>
              Use <strong>user@domain</strong> for domain control.
            </Typography>
          )}

          {dbRow &&
            dbRow.map((e, i) => {
              return (
                <>
                  {!dbRow[i]?.Username.includes('placeholder') && (
                    <div
                      className='flex gap-12 border-b-1 mt-2 mb-2 pb-2'
                      style={{ borderBottom: '1px solid #D8DDE4' }}
                      key={i}
                    >
                      <div className='flex gap-5 items-center justify-between'>
                        <label
                          className='mr-9'
                          style={{ fontWeight: 600, color: '#A6ABB6', width: '150px' }}
                        >
                          Username / Password
                        </label>
                        <TextInput
                          autoComplete='off'
                          disabled={dbRow[i]?.disableInput}
                          value={dbRow[i]?.Username}
                          onChange={(e) =>
                            handleDbRowInputChange('Username', i, e.target.value.trim())
                          }
                          placeholder='Username'
                        />

                        <div className='relative'>
                          <TextInput
                            aria-required
                            autoComplete='new-password'
                            sx={{
                              width: '250px'
                            }}
                            style={{ border: dbRow[i]?.error ? '1px solid red' : '' }}
                            type={showUserPasswords[i] ? 'text' : 'password'}
                            value={dbRow[i]?.Password}
                            onChange={(e) =>
                              handleDbRowInputChange('Password', i, e.target.value.trim())
                            }
                            placeholder='Password *'
                          />
                          <IconButton
                            key={dbRow[i]}
                            onClick={() => togglePasswordVisibility(i)}
                            variant='clear'
                            icon={showUserPasswords[i] ? faEye : faEyeSlash}
                            style={{
                              marginRight: '10px',
                              position: 'absolute',
                              top: '2px',
                              right: '-8px'
                            }}
                          />
                        </div>

                        <div className='flex'>
                          <Tooltip
                            onClose={function noRefCheck() {}}
                            onOpen={function noRefCheck() {}}
                            title='Associate User'
                            placement='top'
                          >
                            <IconButton
                              onClick={() => handleUserSelection(i)}
                              variant='grayBlue'
                              icon={faUser}
                              style={{
                                marginRight: '10px'
                              }}
                            />
                          </Tooltip>

                          {getSelectedUserInfo(i) && (
                            <Label variant='grayBlue' text={getSelectedUserInfo(i)} />
                          )}

                          <IconButton
                            onClick={() => handleDeletRow(i)}
                            variant='grayRed'
                            icon={faTrash}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
        </div>
        {!isLocalUser && (
          <>
            <Typography className='mt-4' variant='body-semiBold'>
              Select Cloud Type
            </Typography>
            <Typography variant='caption-regular'>
              The AD Domain Controller will be used as default for selected cloud types.
            </Typography>
            <div className='flex justify-between items-center w-[600px]'>
              {cloudTypes.map((e) => (
                <LabelCheckbox
                  //@ts-ignore
                  disabled={existingADDCCloudTypes.includes(e)}
                  key={e}
                  checked={selectedCloudTypes.includes(e)}
                  name={e}
                  onClick={() => {
                    //disable selection when existing ADDC for this  CloudType is present
                    if (!existingADDCCloudTypes.includes(e)) handleCloudTypeToggle(e)
                  }}
                />
              ))}
            </div>
            {existingADDCCloudTypes.length >= 1 && (
              <Typography variant='caption-regular'>
                The default AD Domain Controller exists for {existingADDCCloudTypes.join()}. Hence,
                these cloud type cannot be selected.
              </Typography>
            )}
          </>
        )}

        <div>
          <Typography className='mt-6' variant='body-semiBold'>
            Auto Approval Time
          </Typography>

          <div className='mb-2 flex mt-3 items-center'>
            <Typography className='mb-2 mr-3' variant='body-regular'>
              Select Timezone
            </Typography>
            <SelectDropDown
              menuItems={IANA_TIMEZONES.map((tz) => ({ label: tz, value: tz }))}
              onChange={(e) => handleTimeZoneChange(e.target.value)}
              value={timeZone}
            />
          </div>

          <div className='flex items-center mt-3'>{renderTimeInputs()}</div>
        </div>

        <div className='flex justify-end mt-4 items-center gap-3 pb-16'>
          {errors && <Label variant='warning' text={errors} />}
          <Button
            icon={faUndo}
            iconColor='white'
            iconPosition='start'
            onClick={() => handleResetAllTimes()}
            size='sm'
            variant='primary'
          >
            Reset All Times
          </Button>
          <Button variant='primary' onClick={handleAddDBRow}>
            Add User +
          </Button>
          <Button onClick={handleCreate} disabled={!!errors} variant='primary'>
            {isEditMode ? 'Save' : 'Create'}
          </Button>
        </div>
        <LoadingFeedback
          loading={isLoading}
          message={isEditMode ? 'Updating AD Domain Controller' : 'Creating AD Domain Controller'}
          caption='Please wait...'
        />
      </div>
      {showDeleteModal && (
        <FullScreenAlertModal
          actionButtonText='Delete'
          actionButtonVariant='danger'
          alertMessage={`Are you sure you want to delete?`}
          loadingMessage='Deleting User'
          onActionClick={() => {
            handleDeletDBRow(selectedRowForDelete)
          }}
          onCancel={() => setShowDeleteModal(false)}
          showModal
        />
      )}

      {userSelectionModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title='Select User'
            entities={getEntities()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onContinue={handleContinue}
            onCancel={handleUserSelectionCancel}
          />
        </FullScreenContentModal>
      )}

      <LoadingFeedback
        message={'Fetching the Data'}
        loading={isDataFetched}
        caption='Please wait...'
      />
    </div>
  )
}

export { CreateADD }
