import { faRightToBracket } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { useResourceAccess } from 'Core/Hooks/useResourceAccess'
import { EntityPicker, FullScreenContentModal, FullScreenModal } from 'V2Components'
import {
  AccessRequestSubmissionV2,
  createRef,
  createRsrcKey,
  useAccessCartProvider
} from 'features/resources'
import { SnackBar } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useState } from 'react'
import { CreateIAMRoleView } from '../CreateIAMRole'
import { IAMRolesSelectionDrawer } from '../IAMRolesSelectionDrawer'
import { BundleCreateModal } from 'features/bundles'

/**
 * @typedef {{ showAccessRequestSubmission:boolean,  showIAMRolesSelection: boolean, showUserGroupSelection: boolean, showCreateIAMRole: boolean }} IAMManagerViews
 * @param {{setSelectedRsrc:((rsrc) => void), entities?:any[], submissionTitle: string,enableUserGroupSelection?:boolean,selectedRsrc: any, views: IAMManagerViews, onViewsChange: ((views:IAMManagerViews) => void), onRequestSubmission:((data:any, selectedUserGroups: any[]) => void) }} param0
 * @returns
 */
function IAMResourcesManager({
  selectedRsrc,
  setSelectedRsrc,
  views,
  onViewsChange,
  onRequestSubmission,
  enableUserGroupSelection,
  submissionTitle,
  entities
}) {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [showBundleCreateModal, setShowBundleCreateModal] = useState(false)
  const { appView } = useAppView()
  const { clearCartItems, cartItems } = useAccessCartProvider()
  const { updateRsrcMeta, updateResources, updateEntities, updateSpec } = useResourceAccess()

  const entityData = useDataDetailsContext()
  const handleDismissResourcesQueue = () => {
    clearCartItems()
    submissionCleanUp()
  }

  const handleViewsChange = (v) => {
    onViewsChange &&
      onViewsChange({
        ...views,
        ...v
      })
  }

  /**
   * Handle the resource iam roles edit click
   * @param {{ RefKind: 'AwsResource' | 'GcpResource' | 'AzureResource' | 'KubeNamespace', RefID:string }} rsrcRef Ref of the resource clicked
   */
  const handleRsrcEditClick = (rsrcRef) => {
    setSelectedRsrc(rsrcRef)

    handleViewsChange({
      ...views,
      showAccessRequestSubmission: false,
      showUserGroupSelection: false,
      showIAMRolesSelection: true
    })
  }

  const handleAfterRequestSubmission = () => {
    handleViewsChange({
      showAccessRequestSubmission: false,
      showCreateIAMRole: false,
      showIAMRolesSelection: false,
      showUserGroupSelection: false
    })
    setSelectedEntities([])
    setSelectedRsrc(null)
    clearCartItems()
    submissionCleanUp()
  }

  const createEntitiesResourceMetaObj = () => {
    const obj = {}
    cartItems.forEach(({ Resource, Roles: { ObjectRef } }) => {
      obj[createRsrcKey(Resource)] = { Roles: ObjectRef }
    })
    return obj
  }

  const updateResourcesAccessSlice = () => {
    //!TODO startDate should not be updated from here
    updateSpec({ startDate: new Date() })
    updateResources(cartItems.map((e) => e.Resource))
    if (entityData) updateEntities([...selectedEntities.map(createRef), createRef(entityData)])
    else updateEntities(selectedEntities.map(createRef))
    updateRsrcMeta(createEntitiesResourceMetaObj())
  }

  const onUserGroupContinue = () => {
    updateResourcesAccessSlice()
    handleViewsChange({ showUserGroupSelection: false, showAccessRequestSubmission: true })
  }

  const onSubmissionCancel = () => {
    handleViewsChange({ showAccessRequestSubmission: false })
    setSelectedEntities([])
    submissionCleanUp()
  }

  const submissionCleanUp = () => {
    setDateTimeError({
      startDate: '',
      endDate: ''
    })
  }

  const handleBundleCreateClick = () => {
    setShowBundleCreateModal(true)
  }

  const [dateTimeError, setDateTimeError] = useState({
    startDate: '',
    endDate: ''
  })
  useHTMLBodyScrollbar(Object.values(views).some((e) => e))

  return (
    <div>
      {selectedRsrc && views.showIAMRolesSelection && (
        <IAMRolesSelectionDrawer
          onCreateIAMRoleClick={() => handleViewsChange({ ...views, showCreateIAMRole: true })}
          onCloseDrawer={() => {
            setSelectedRsrc(null)
            handleViewsChange({ showIAMRolesSelection: false })
          }}
          selectedRsrc={selectedRsrc}
          showDrawer={views.showIAMRolesSelection}
        />
      )}

      <FullScreenModal showModal={views.showCreateIAMRole}>
        {views.showCreateIAMRole && (
          <CreateIAMRoleView
            accountType={selectedRsrc?.AccountType}
            onCancel={() => handleViewsChange({ showCreateIAMRole: false })}
            afterSubmit={() => {
              handleViewsChange({ showCreateIAMRole: false })
            }}
            breadCrumbs={[
              {
                label: 'Resource Catalog',
                onClick: () => handleViewsChange({ showCreateIAMRole: false })
              },
              { label: 'Create IAM Role' }
            ]}
          />
        )}
      </FullScreenModal>
      <FullScreenModal showModal={views.showAccessRequestSubmission}>
        <div className='h-[100%] bg-white'>
          {views.showAccessRequestSubmission && (
            <AccessRequestSubmissionV2
              showRequestForUserGroupsSummary={enableUserGroupSelection}
              onAddMoreResourcesClick={() => {
                handleViewsChange({
                  ...views,
                  showAccessRequestSubmission: false,
                  showUserGroupSelection: false
                })
              }}
              onRsrcEditClick={handleRsrcEditClick}
              headerName={appView === 'admin' ? 'Create Policy' : 'Access Request Submission'}
              loadingMessage={`Creating ${submissionTitle}`}
              mainTitle={submissionTitle}
              onSubmit={onRequestSubmission}
              afterSubmit={handleAfterRequestSubmission}
              onCancel={onSubmissionCancel}
              onManageUserGroupsClick={() => handleViewsChange({ showUserGroupSelection: true })}
              dateTimeError={dateTimeError}
              setDateTimeError={setDateTimeError}
            />
          )}
        </div>
      </FullScreenModal>
      <FullScreenModal showModal={views.showUserGroupSelection}>
        {views.showUserGroupSelection && (
          <FullScreenContentModal width='auto'>
            <EntityPicker
              title={`Entities for ${submissionTitle}`}
              errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
              entities={entities}
              selectedEntities={selectedEntities}
              setSelectedEntities={setSelectedEntities}
              onCancel={() => handleViewsChange({ showUserGroupSelection: false })}
              onContinue={onUserGroupContinue}
            />
          </FullScreenContentModal>
        )}
      </FullScreenModal>
      {cartItems?.length > 0 && (
        <div className='fixed bottom-4 right-9'>
          <SnackBar
            showDropDownButton
            onClickContinueButton={() => {
              if (enableUserGroupSelection) handleViewsChange({ showUserGroupSelection: true })
              else {
                updateResourcesAccessSlice()
                handleViewsChange({ showAccessRequestSubmission: true })
              }
            }}
            leftIcon={faRightToBracket}
            menuItems={[
              appView === 'admin' && {
                label: 'Create Bundle',
                onClick: handleBundleCreateClick
              },
              {
                label: 'Dismiss',
                onClick: handleDismissResourcesQueue
              }
            ].filter((e) => !!e)}
            textMessage={`${cartItems?.length} Resources on Queue`}
            variant='primary'
          />
        </div>
      )}
      {showBundleCreateModal && (
        <BundleCreateModal
          reqType='IAMAction'
          resoucesWithRoles={cartItems}
          onCancel={() => setShowBundleCreateModal(false)}
          onSuccess={() => {
            clearCartItems()
            setShowBundleCreateModal(false)
          }}
        />
      )}
    </div>
  )
}

export { IAMResourcesManager }
