import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey } from 'features/resources'
import _ from 'lodash'

export const useAddWorkloads = () => {
  const { getObjectRef, slices, initiallyLoaded } = useMultiSlice([
    'accountList',
    'projects',
    'gcpResources'
  ])

  const getAzureWorkloadAccounts = (workload) => {
    if (!workload) return []
    const list = []
    const {
      Accounts: { ObjectRef }
    } = workload.Spec

    ObjectRef.forEach((ref) => {
      const obj = getObjectRef(ref)
      if (obj && obj.ObjectMeta.Kind === 'Account') list.push(createRsrcKey(ref))
    })

    return list
  }

  const getWorkloadFields = (workload) => {
    const fields = {
      name: '',
      description: '',
      awsAccounts: [],
      azureAccounts: [],
      gcpResources: [],
      azureScopeURLMap: {},
      loaded: false
    }

    if (!workload || !initiallyLoaded) return fields

    const {
      Name,
      Description,
      Accounts: { ObjectRef },
      AzureScope: { AzureScopeMap }
    } = workload.Spec

    ObjectRef.map((ref) => {
      const obj = getObjectRef(ref)
      const rsrcKey = createRsrcKey(ref)
      if (obj && obj.ObjectMeta.Kind === 'Account') {
        const type = obj.Spec.Type
        if (type === 'AWS') fields.awsAccounts.push(rsrcKey)
        if (type === 'AZURE') {
          const scopeURL = AzureScopeMap[obj.ObjectMeta.ID]
          fields.azureScopeURLMap[rsrcKey] = scopeURL
          fields.azureAccounts.push(rsrcKey)
        }
      } else if (obj && obj.ObjectMeta.Kind === 'Project') {
        const gcpProject = _.find(slices.gcpResources, {
          Spec: {
            Id: obj.Spec.ProjectId
          }
        })
        if (gcpProject) fields.gcpResources.push(createRsrcKey(gcpProject))
      }
    })

    return { ...fields, name: Name, description: Description, loaded: true }
  }

  return { getWorkloadFields, getAzureWorkloadAccounts }
}
