import { EntityInfoBar } from 'V2Components'
import { ResourcesSummaryView, createRsrcKey, getResourceName } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'
import { useAccessSummaryTab } from './AccessSummaryTab.utils'
import { useUser } from 'Core/Hooks/useUser'

function AccessSummaryTab() {
  const history = useHistory()
  const {isAgentlessMode} = useUser()
  const { rsrcs, policys, resourceActionsObj } = useAccessSummaryTab()
  const handlePolicyView = (p) => history.push(`/admin/policys/${p.ObjectMeta.Name}`)

  // filtering application resource if user in agentless mode
  const FilteredResources = isAgentlessMode ? rsrcs?.filter(e => e.ObjectMeta.Kind !== 'Application') : rsrcs

  if (!rsrcs.length)
    return <Typography variant='body-regular'>User has access to 0 resources.</Typography>

  return (
    <div className='flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        {policys.map((e) => (
          <div className='border-b border[#D8DDE4]' key={createRsrcKey(e)}>
            <EntityInfoBar
              kind='PacPolicy'
              name={getResourceName(e)}
              caption={e.ObjectMeta.Name}
              menuItems={[
                {
                  title: 'View',
                  action: () => handlePolicyView(e)
                }
              ]}
            />
          </div>
        ))}
      </div>
      <div className='w-1/2 px-8'>
        <ResourcesSummaryView resources={FilteredResources} resourceAttibutesObject={resourceActionsObj} />
      </div>
    </div>
  )
}

export { AccessSummaryTab }
