import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import _ from 'lodash'
import React, { createContext, useContext, useState } from 'react'
import { useParams } from 'react-router'

const GroupDetailsContext = createContext({
  group: null
})

const findGroup = (list, groupName) => {
  return _.find(list, { ObjectMeta: { Name: groupName } })
}

export const GroupDetailsProvider = ({ children }) => {
  const { slices, initiallyLoaded, selectDispatch } = useMultiSlice(['groupList'])
  //@ts-ignore
  const { groupName } = useParams()

  const [group, setGroup] = useState(() => {
    return findGroup(slices.groupList, groupName)
  })

  if (!initiallyLoaded) selectDispatch(['groupList'])
  else if (initiallyLoaded) {
    const u = findGroup(slices.groupList, groupName)
    if (u && u !== group) setGroup(u)
  }

  return (
    <GroupDetailsContext.Provider value={{ group }}>
      {!group && <LoadingFeedback shadow={false} shrinked loading message='Fetching Group' />}
      {group && children}
    </GroupDetailsContext.Provider>
  )
}

export const useGroupDetailsContext = () => useContext(GroupDetailsContext)
