import { Box, makeStyles, Popover } from '@material-ui/core'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { randomStringGenerator } from 'Utils/PureHelperFuctions'

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {},
  popover: {
    overflowY: 'visible',
    overflowX: 'visible'
  },
  popperContainer: (props) => ({
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: props.disablePadding ? 0 : theme.spacing(1),
    boxShadow: '1rem 21px 1rem -20px rgba(0,0,0,0.58)',
    backgroundColor: theme.palette.primary.white,
    '&::before': (props) => {
      if (props.disableArrow) return undefined
      return ({
        content: '""',
        display: 'block',
        backgroundColor: 'white',
        width: '1rem',
        height: '1rem',
        position: 'absolute',
        left: '0.5rem',
        top: '-0.5rem',
        transform: 'rotate(45deg)',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderLeft: `1px solid ${theme.palette.grey[300]}`
      })
    }
  }),
  trigger: {
    userSelect: 'none',
    position: 'relative',
    cursor: 'pointer'
  },
  arrowUpIcon: {}
}))

const Popup = ({
  TriggerComponent,
  PopupComponent,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left'
  },
  disablePadding = false,
  disableArrow = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles({ disablePadding, disableArrow })

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = useRef(randomStringGenerator(16))

  return (
    <Box className={classes.root}>
      <Box className={classes.trigger} display='inline-block' onClick={handleClick}>
        {TriggerComponent}
      </Box>
      <Popover
        classes={{ paper: classes.popover }}
        id={id.current}
        onClose={() => setAnchorEl(null)}
        open={open}
        anchorEl={anchorEl}
        // @ts-ignore
        anchorOrigin={anchorOrigin}
        // @ts-ignore
        transformOrigin={transformOrigin}
      >
        <Box id='popup-component' className={classNames(classes.popperContainer, 'popup-component')}>
          {PopupComponent}
        </Box>
      </Popover>
    </Box>
  )
}

export default Popup
