import _ from 'lodash'
import { TargetIcon } from 'procyon-ui'
import React from 'react'

export const GCPDisplayTypeToIconTypeMap = {
  'AlloyDB Instance': 'GCP_ALLOYDB',
  'BigQuery Table': 'GCP_BIG_QUERY_TABLE',
  Bigtable: 'GCP_BIG_TABLE',
  Bucket: 'GCP_BUCKET',
  CloudFunction: 'GCP_CLOUD_FUNCTION',
  'Compute Instance': 'GCP_COMPUTE_INSTANCE',
  Folder: 'GCP_GOOGLE_FOLDER',
  Project: 'GCP_GOOGLE_PROJECT',
  'PubSub Subscription': 'GCP_PUBSUB_SUSCRIPTION',
  'PubSub Topic': 'GCP_PUBSUB_TOPIC',
  'Service Account': 'SERVICEACCOUNT',
  Organization: 'GCP_ORG',
  GCP_KubeNamespace: 'GCP_KUBENAMESPACE',
  'SQL Instance': 'GCP_SQL_INSTANCE',
  'GKE Cluster': 'GCP_GKE_CLUSTER'
}

const DisplayTypePatterns = {
  'BigQuery Table': ['bigquery.tables'],
  Bigtable: ['bigtable.instances'],
  Bucket: ['storage.buckets'],
  CloudFunction: ['cloudfunctions.fuctions'],
  'Compute Instance': ['compute.instances'],
  Folder: ['resourcemanager.folders'],
  Project: ['resourcemanager.projects'],
  'PubSub Subscription': ['pubsub.subscriptions'],
  'PubSub Topic': ['pubsub.topics'],
  'Service Account': ['iam.serviceAccounts', 'iam.serviceAccountKeys'],
  Organization: ['resourcemanager.organizations']
}

/**
 *
 * @param {string|{ Spec:{ DisplayType:string } }} rsrc
 */
export const getGCPResourceIcon = (rsrc, matchPattern = false) => {
  let displayType = ''

  if (typeof rsrc === 'object') displayType = _.get(rsrc, 'Spec.DisplayType', '')
  if (typeof rsrc === 'string') displayType = rsrc

  if (matchPattern && typeof rsrc === 'string') {
    const displayTypeKeys = Object.keys(DisplayTypePatterns)
    const firstMatch = displayTypeKeys.find((e) => {
      const matchPatterns = DisplayTypePatterns[e]
      const isMatch = matchPatterns.find((p) => {
        if (p.includes(rsrc.toLowerCase())) return true
      })
      return isMatch
    })
    if (firstMatch) displayType = firstMatch
  }

  return ({ ...props }) => <TargetIcon type={GCPDisplayTypeToIconTypeMap[displayType] || 'GOOGLE_APPLICATION'} {...props} />
}
