import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FullScreenModal } from 'V2Components'
import {
  Button,
  Label,
  LoadingSpinner,
  PasswordInput,
  TargetIcon,
  TextInput,
  Typography
} from 'procyon-ui'
import React from 'react'

const ServiceNow = ({
  showIntegrationModal,
  setShowIntegrationModal,
  onInputChange,
  onContinue,
  isDisabled,
  message
}) => {
  return (
    <FullScreenModal showModal={showIntegrationModal}>
      <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
        <div className='w-[626px] rounded-lg p-8 bg-white'>
          <div className='flex justify-between'>
            <Typography variant='title-regular' className='flex text-2xl items-center font-bold'>
              <TargetIcon
                width={'48px'}
                height={'48px'}
                type={'SERVICENOW'}
                className='rounded'
                style={{
                  borderRadius: '12px',
                  marginRight: '15px'
                }}
              />
              Setup ServiceNow Integration
            </Typography>
          </div>
          {!message ? (
            <>
              <div className='p-4 mt-2 rounded text-black max-h-[60vh] overflow-auto'>
                <TextInput
                  label='Integration Name'
                  onChange={(e) => onInputChange(e.target.value, 'name')}
                  placeholder='Name'
                  style={{
                    width: '530px'
                  }}
                />

                <div className='border my-7'></div>

                <TextInput
                  label='Instance URL'
                  onChange={(e) => onInputChange(e.target.value, 'instanceURL')}
                  placeholder='URL'
                  className='mb-5'
                  style={{
                    width: '530px'
                  }}
                />

                <TextInput
                  label='Client ID'
                  onChange={(e) => onInputChange(e.target.value, 'clientID')}
                  placeholder='Client'
                  className='mb-5'
                  style={{
                    width: '530px'
                  }}
                />

                <PasswordInput
                  label='Client Secret'
                  onChange={(e) => onInputChange(e.target.value, 'clientSecret')}
                  placeholder='*********'
                  style={{
                    width: '530px'
                  }}
                />
              </div>

              <div className='flex justify-end mt-4 gap-5'>
                <Button size='md' onClick={() => setShowIntegrationModal(false)} variant='gray'>
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  size='md'
                  onClick={() => onContinue()}
                  variant='primary'
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <div className='flex items-center bg-[#f2f4fb] p-5 mt-5'>
              <div className='mt-2'>
                <LoadingSpinner size={20} thickness={4} />
              </div>
              <Label text={message} variant='grayBlue' />
            </div>
          )}
        </div>
      </div>
    </FullScreenModal>
  )
}

export { ServiceNow }
