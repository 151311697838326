import { DataDetailsProvider, useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'
import { AddWorkload } from '../AddWorkload'

const EditWorkload = () => {
  const workload = useDataDetailsContext()

  return <AddWorkload workload={workload} isEditMode />
}

const Wrapped = () => (
  <DataDetailsProvider
    routeKey='workloadName'
    sliceName='workloads'
    loadingMessage='Fetching Workload'
  >
    <EditWorkload />
  </DataDetailsProvider>
)

export { Wrapped as EditWorkload }
