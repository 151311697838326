import { faCloud, faFileCertificate } from '@fortawesome/pro-solid-svg-icons'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { cn } from 'Utils/Helpers'
import { isPolicyIssuedToEntity } from 'features/policy'
import { createRsrcKey, dateSortComparator, getResourceName } from 'features/resources'
import { getWorkloadAccounts, isWorkloadErrored } from 'features/workloads'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import moment from 'moment'
import { Label, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

const useSlices = createDataSelectorHook(['policyList', 'workloads'])

export const useWorkloads = ({ searchKey }) => {
  const { getObjectRef } = useObjectRef(['accountList', 'workloads'])
  const history = useHistory()

  const { slices } = useSlices()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (a, queryKey) => {
      const rName = getResourceName(a)
      const nameSpec = a.Spec.Name
      const desc = a.Spec.Description
      return `${rName}${desc}${nameSpec}`.toLowerCase().includes(queryKey)
    }
  })

  const rowDataAdapterFn = (workload) => {
    const policiesCount = slices.policyList.filter((p) =>
      isPolicyIssuedToEntity(p, workload)
    ).length
    const createdBy = getObjectRef(workload.CreatedBy)
    return {
      objectName: workload.ObjectMeta.Name,
      key: createRsrcKey(workload),
      name: {
        text: getResourceName(workload),
        isErrored: isWorkloadErrored(workload)
      },
      description: workload.Spec.Description,
      resourcesCount: getObjectRef(getWorkloadAccounts(workload)).length,
      policiesCount: policiesCount,
      createdBy: getResourceName(createdBy),
      createdOn: workload.ObjectMeta.CreatedAt,
      goToDetails() {
        history.push(`/admin/workloads/${workload.ObjectMeta.Name}`)
      }
    }
  }

  return { rowDataAdapterFn, workloads: applySearchQuery(slices.workloads) }
}

/**
 *
 * @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const responsiveCloumns = () => [
  {
    dataIndex: 'name',
    title: 'Name',
    width: 250,
    fixed: 'left',
    align: 'left',
    sorter: (v1, v2) => v1.name.text.localeCompare(v2.name.text),
    onCell(data) {
      return {
        onClick: data.goToDetails
      }
    },
    render(value) {
      return (
        <div className='flex cursor-pointer'>
          {value && (
            <Typography className={cn({ '!text-red-500': value.isErrored })} variant='body-regular'>
              {value.text.substring(0, 30)}
            </Typography>
          )}
        </div>
      )
    }
  },
  {
    dataIndex: 'description',
    title: 'Description',
    align: 'left',
    width: 400,
    sorter: (v1, v2) => v1.description.localeCompare(v2.description)
  },
  {
    dataIndex: 'resourcesCount',
    title: 'Accounts',
    width: 200,
    align: 'left',
    sorter: (v1, v2) => v1.resourcesCount - v2.resourcesCount,
    render(value) {
      return <Label iconButton={faCloud} text={value} />
    }
  },
  {
    dataIndex: 'policiesCount',
    title: 'Policies',
    width: 200,
    align: 'left',
    sorter: (v1, v2) => v1.policiesCount - v2.policiesCount,
    render(value) {
      return <Label iconButton={faFileCertificate} text={value} />
    }
  },
  {
    dataIndex: 'createdBy',
    title: 'Created By',
    align: 'left',
    width: 250,
    sorter: (v1, v2) => v1.createdBy.localeCompare(v2.createdBy)
  },
  {
    dataIndex: 'createdOn',
    title: 'Created On',
    align: 'left',
    width: 250,
    sorter: (v1, v2) => dateSortComparator(v1.createdOn, v2.createdOn),
    render(value) {
      return moment(value).format('MMMM Do YYYY, h:mm A')
    }
  }
]
