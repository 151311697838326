import { Button, IconButton, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { cn, enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { faCog, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useParams } from 'react-router'
import { ApiProvider } from 'Core'
import { useChannelBrowser } from '../../providers/hooks'

const JiraConfigurationEdit = ({ isJiraConfigEditable, setIsJiraConfigEditable, integration }) => {
  const { refresh } = useChannelBrowser()
  const { kind, integrationName } = useParams()
  const [rows, setRows] = useState([{ id: 1, fieldName: '', resourceName: '' }])
  const [tRows, setTRows] = useState([
    { id: 1, startProcessName: '', endProcessName: '', stateInputs: [''] }
  ])
  const [issueType, setIssueType] = useState('')
  const [stateMapping, setStateMapping] = useState({
    Pending: '',
    Approved: '',
    Rejected: '',
    Cancelled: '',
    Expired: '',
    Closed: ''
  })

  const { slices } = useMultiSlice(['jiraIntegrationsList'])

  useEffect(() => {
    if (integration?.Spec?.JiraIssueType) {
      setIssueType(integration.Spec.JiraIssueType)
    }
    if (Object.keys(integration.Spec.AttributeMapping.Map).length >= 1) {
      const newRows = Object.entries(integration.Spec.AttributeMapping.Map).map(
        ([fieldName, resourceValue], index) => ({
          id: index + 1, // Assuming IDs start from 1 and increment by 1
          fieldName: fieldName,
          resourceName: resourceValue.replace('{{', '').replace('}}', '') // Remove the curly braces from the resource value
        })
      )
      setRows(newRows)
    }

    if (Object.keys(integration.Spec.Transitions.Map).length >= 1) {
      const transitionMap = integration.Spec.Transitions.Map
      const newTRows = Object.entries(transitionMap)
        .map(([key, value], index) => {
          // Split the key to get process names
          const processName = key.split(':')
          // Split the value to get steps
          const steps = value.split(', ')

          // Check if both process names and steps are not empty
          if (processName[0] && processName[1] && steps.length > 0) {
            return {
              id: index + 1,
              startProcessName: processName[0],
              endProcessName: processName[1],
              stateInputs: steps
            }
          }
          // If any of the values is empty, return null
          return null
        })
        .filter((row) => row !== null) // Filter out null rows

      setTRows(newTRows)
    }

    if (Object.keys(integration.Spec.StatusMapping.Map).length >= 1) {
      let statusMappingData = integration.Spec.StatusMapping.Map
      setStateMapping((prevState) => {
        const newStateMapping = { ...prevState }
        Object.keys(statusMappingData).forEach((key) => {
          if (newStateMapping.hasOwnProperty(key)) {
            newStateMapping[key] = statusMappingData[key]
          }
        })
        return newStateMapping
      })
    }
  }, [])


  const addTRow = () => {
    setTRows((prevTRows) => {
      // Check if the new ID already exists in the previous rows
      const newId = prevTRows.some(row => row.id === prevTRows.length + 1) 
                 ? 1 // If the ID exists, reinitialize to 1
                 : prevTRows.length + 1; // Otherwise, use the next available ID
  
      return [
        ...prevTRows,
        { id: newId, startProcessName: '', endProcessName: '', stateInputs: [''] }
      ];
    });
  };
  

  const removeTRow = (id) => {
    setTRows((prevTRows) => prevTRows.filter((row) => row.id !== id))
  }

  const addRow = () => {
    setRows((prevRows) => {
      const newId = prevRows.some(row => row.id === prevRows.length + 1) 
      ? 1 // If the ID exists, reinitialize to 1
      : prevRows.length + 1; // Otherwise, use the next available ID

     return [
        ...prevRows,
        { id: newId, fieldName: '', resourceName: '' }
      ]
    }) 
  }

  const removeRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id))
  }

  const addStateInput = (rowId) => {
    setTRows((prevTRows) => {
      return prevTRows.map((row) => {
        if (row.id === rowId) {
          const updatedStateInputs = [...row.stateInputs, '']
          return { ...row, stateInputs: updatedStateInputs }
        }
        return row
      })
    })
  }

  const removeStateInput = (rowId, inputIndex) => {
    setTRows((prevTRows) => {
      return prevTRows.map((row) => {
        if (row.id === rowId) {
          const updatedStateInputs = row.stateInputs.filter((_, index) => index !== inputIndex)
          return { ...row, stateInputs: updatedStateInputs }
        }
        return row
      })
    })
  }

  const handleChange = (id, field, value) => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      })
    })
  }

  const handleTransitionChange = (id, field, value) => {
    setTRows((prevTRows) => {
      return prevTRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      })
    })
    console.log(tRows)
  }

  const handleStateInputChange = (rowId, index, value) => {
    setTRows((prevTRows) => {
      return prevTRows.map((row) => {
        if (row.id === rowId) {
          const newStateInputs = [...row.stateInputs]
          newStateInputs[index] = value
          return { ...row, stateInputs: newStateInputs }
        }
        return row
      })
    })
    console.log(tRows)
  }

  const startEndStateMapping = tRows.reduce((acc, row) => {
    const startEndMapping = `${row.startProcessName}:${row.endProcessName}`
    const stateValues = row.stateInputs.filter((state) => state !== '').join(', ')
    acc[startEndMapping] = stateValues
    return acc
  }, {})

  const handleOnSave = async () => {
    const filterCurrentJira = slices.jiraIntegrationsList.filter(
      (jira) => jira.ObjectMeta.Name === integrationName
    )
    try {
      const payload = _.cloneDeep(filterCurrentJira)
      payload[0].Spec.JiraIssueType = issueType

      if (rows.length >= 1) {
        payload[0].Spec.AttributeMapping.Map = {} // Reset the map first
        rows?.forEach((item) => {
          payload[0].Spec.AttributeMapping.Map[item.fieldName] = `{{${item.resourceName}}}`
        })
      } else {
        payload[0].Spec.AttributeMapping.Map = {}
      }

      payload[0].Spec.Transitions.Map = startEndStateMapping
      payload[0].Spec.StatusMapping.Map = stateMapping

      await new ApiProvider('jiraintegrations').setInstance(payload[0]).put()
      refresh()
      setIsJiraConfigEditable(!isJiraConfigEditable)
      enqueueNotification('Jira config updated successfully!', 'info')
    } catch (error) {
      enqueueNotification('Error in Updating config', 'error')
      console.log(error)
    }
  }

  const handleStatusInputChange = (label, value) => {
    setStateMapping((prevValues) => ({
      ...prevValues,
      [label]: value
    }))
  }

  return (
    <div className='flex gap-8'>
      <div className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8')}>
        <div className='flex justify-between items-center'>
          <Typography variant='h3' className='mt-5'>
            Jira Issue Type
          </Typography>
          <div
            onClick={() => setIsJiraConfigEditable(!isJiraConfigEditable)}
            className='bg-[#f6f6f6] px-3.5 py-2 rounded-md flex items-center mt-5 cursor-pointer'
          >
            <span className=' font-normal pr-2 text-base text-[#545b71]'>Setting</span>
            <FontAwesomeIcon icon={faCog} />
          </div>
        </div>

        <div className='mt-8 w-[100%]'>
          <TextInput
            label='Issue Type'
            onChange={(e) => setIssueType(e.target.value)}
            value={issueType}
            placeholder='Enter issue type'
            style={{
              width: '42vw',
              marginBottom: '15px'
            }}
          />
          <div className=''>
            {rows && rows.map((row, index) => (
              <div className='flex items-center my-3 mb-5' key={index + 1}>
                <div>
                  <TextInput
                    label='Jira field name'
                    onChange={(e) => handleChange(row.id, 'fieldName', e.target.value)}
                    placeholder='Enter field name'
                    value={row.fieldName !== '' ? row.fieldName : ''}
                    style={{
                      width: '17.5vw',
                      marginRight: '20px'
                    }}
                  />
                  <TextInput
                    label='Jira resources'
                    onChange={(e) => handleChange(row.id, 'resourceName', e.target.value)}
                    placeholder='{{resource}}'
                    value={row.resourceName !== '' ? row.resourceName : ''}
                    style={{
                      width: '17.5vw',
                      marginRight: '20px'
                    }}
                  />
                </div>
                <div className='flex gap-2 mt-6'>
                  <IconButton onClick={() => addRow()} variant='gray' icon={faCirclePlus} />
                  {rows.length >= 2 && (
                    <IconButton onClick={() => removeRow(row.id)} variant='gray' icon={faTrash} />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className='mt-10 mb-20'>
            <span className='flex justify-between'>
            <Typography variant='h3'>Transitions</Typography>
            <Button variant='gray' size='md' onClick={addTRow}>
              Add Row +
            </Button>
            </span>
            

            <div className='flex flex-col'>
              {tRows &&
                tRows.map((row, index) => (
                  <div className='flex mt-4 gap-5' key={index + 1}>
                    <TextInput
                      label={'Start process name'}
                      onChange={(e) =>
                        handleTransitionChange(row.id, 'startProcessName', e.target.value)
                      }
                      placeholder='Enter process name'
                      value={row.startProcessName}
                      style={{
                        width: '170px'
                      }}
                    />
                    <TextInput
                      label={'End process name'}
                      onChange={(e) =>
                        handleTransitionChange(row.id, 'endProcessName', e.target.value)
                      }
                      placeholder={'Enter session name'}
                      value={row.endProcessName}
                      style={{
                        width: '170px'
                      }}
                    />
                    <div className='flex flex-col'>
                      {row.stateInputs.map((input, index) => (
                        <TextInput
                          label={index === 0 ? 'In between process name' : `Step ${index + 1}`}
                          icon={faWindowClose}
                          iconPosition='end'
                          onChange={(e) => handleStateInputChange(row.id, index, e.target.value)}
                          onClickEndIcon={() => removeStateInput(row.id, index)}
                          placeholder={'Enter process name'}
                          value={input}
                          style={{
                            width: '170px'
                          }}
                          key={index}
                        />
                      ))}
                    </div>
                    <div className='flex mt-6 gap-2'>
                      <Button size='sm' variant='gray' onClick={() => addStateInput(row.id)}>
                        Add state
                      </Button>
                      <IconButton onClick={addTRow} variant='gray' icon={faCirclePlus} />

                      {tRows.length >= 2 && (
                        <IconButton
                          onClick={() => removeTRow(row.id)}
                          variant='gray'
                          icon={faTrash}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='w-1/2'>
        <div className='flex justify-between mt-5'>
          <Typography variant='h3'>Status Mapping</Typography>
        </div>
        <div className='mt-5'>
          {Object.entries(stateMapping).map(([label, value], index) => (
            <div
              className='flex justify-between items-center border-[#D8DDE4] border-b py-4 min-h-[58px]'
              key={label}
            >
              <div className='flex flex-col'>
               {index === 0 && 
                <Typography variant='h4-regular' className='mb-8'>Approval request status</Typography>
                }
              <Typography
                className='!text-[#A6ABB6] min-w-[193px] !font-semibold mr-2'
                variant='body-semiBold'
              >
                {label}
              </Typography>
              </div>
              
              <div className='flex flex-col'>
              {index === 0 && 
              <Typography variant='h4-regular' className='mb-8'>Jira ticket state</Typography>
              }
              <TextInput
                placeholder='Input'
                style={{
                  width: '300px'
                }}
                value={value}
                onChange={(e) => handleStatusInputChange(label, e.target.value)}
              />
              </div>
              
            </div>
          ))}
        </div>
        <div className='flex mt-10 flex-row-reverse gap-5'>
          <Button size='lg' variant='primary' onClick={handleOnSave}>
            Save
          </Button>
          <Button size='lg' variant='gray' onClick={() => setIsJiraConfigEditable(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export { JiraConfigurationEdit }
