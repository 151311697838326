import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LoadingFeedback } from 'V2Components'
import { useApplicationAccess } from 'features/applications'
import { getGithubResources } from 'features/github'
import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

const ApplicationDetailsContext = createContext({
  app: undefined,
  policys: [],
  isAllowedAccess: false    //True, if the app has more than one policy created - which means access is allowed
})

export const ApplicationDetailsProvider = ({ children }) => {
  const [app, setApp] = useState()
  //@ts-ignore
  const appName = decodeURIComponent(useParams().appName)
  const { slices, selectDispatch, getObjectRef } = useMultiSlice(['policyList'])
  const { getApplicationPolicysRef } = useApplicationAccess()

  useEffect(() => {
    callGithubResources()
  }, [])

  const callGithubResources = async () => {
    let response = await getGithubResources()

    /**
   * Set the app, by quering with name
   */
  if (appName && response.GithubResources.length) {
    const a = _.find(response.GithubResources, { ObjectMeta: { Name: appName } })
    if (a !== app) setApp(a)
  }
  }

  

  /**
   * Set the policys attached with this application
   */
  const refObjs = getObjectRef(getApplicationPolicysRef(app))

  useEffect(() => {
    selectDispatch(['policyList'])
  }, [])

  return (
    <ApplicationDetailsContext.Provider value={{ app, policys: refObjs, isAllowedAccess: refObjs.length > 0 }}>
      {!app && <LoadingFeedback shrinked loading message='Fetching Application' />}
      {app && children}
    </ApplicationDetailsContext.Provider>
  )
}

export const useAppDetailsContext = () => useContext(ApplicationDetailsContext)
