import { Box, makeStyles } from '@material-ui/core'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import React from 'react'
import { alertsData as data } from './MetaData'
import { columns, options } from './TableMeta'

const useStyles = makeStyles((theme) => ({
  root: {},
  mainIcon: {
    fill: theme.palette.text.disabled
  },
  chevronRight: {
    fill: theme.palette.text.dark
  }
}))

const Alerts = () => {
  const classes = useStyles()

  const Table = ({ partialData = false, searchString = '' }) => {
    const tableOptions = { hideHeader: true }
    const columnProps = { classes }

    const tableData = partialData ? [...data()].slice(0, 5) : data()

    return (
      <Box height='16rem'>
        <AnalyticTable
          rowHeight='2.75rem'
          headHeight='2.5rem'
          headerJustify='flex-end'
          vAlign='top'
          searchString={searchString}
          columns={columns(columnProps)}
          options={options(tableOptions)}
          data={tableData}
        />
      </Box>
    )
  }

  const ModalComponent = ({ closeModal }) => (
    <ModalView
      searchPlaceholder='Enter User Name'
      closeModal={closeModal}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )

  return (
    <ExpandableContainer expandButtonLabel='Expand All Alerts' modalTitle='Alerts' ModalComponent={ModalComponent}>
      <Table partialData />
    </ExpandableContainer>
  )
}

export default Alerts
