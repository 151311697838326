import { Warning, Computer } from '@material-ui/icons'

export const alertsData = () => [
  {
    InfoIcon: Warning,
    MainIcon: Computer,
    Text: 'Luke Skywalker MacBook Pro trust score decreased from 93% to 62%'
  },
  {
    InfoIcon: Warning,
    MainIcon: Computer,
    Text: 'Luke Skywalker MacBook Pro trust score decreased from 93% to 62%'
  }
]
