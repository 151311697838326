import { deleteSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenAlertModal } from 'V2Components'
import { deleteRepository } from 'features/github'
import React from 'react'

function DeleteApplicationModal({ app, cleanUpFn, onCancel = null }) {
  const handleDeleteApplication = async () => {
    const deletedApp = await deleteRepository(app)
    deleteSliceData(deletedApp)
    enqueueNotification('Application Deleted Successfully!', 'info')
    cleanUpFn?.()
  }

  const handleCancel = () => {
    if (onCancel) onCancel()
    else cleanUpFn?.()
  }

  return (
    <div>
      <FullScreenAlertModal
        actionButtonText='Delete'
        actionButtonVariant='danger'
        alertMessage='Are you sure you want to delete this repository?'
        loadingMessage='Deleting Repository'
        onActionClick={handleDeleteApplication}
        onCancel={handleCancel}
        showModal
      />
    </div>
  )
}

export { DeleteApplicationModal }
