import { createAccount, updateAccount } from '../api'
import { getAccountIDFromARN, isValidAccountCredentials } from '../utils'

export function useAWSForm() {
  const checkAWSAccountCredentials = async ({ arn, externalID, ouID, orgAccount }) => {
    const AccountID = getAccountIDFromARN(arn)
    const AwsSpec = {
      AssumeRoleARN: arn.trim(),
      ExternalID: externalID.trim(),
      OuID: ouID.trim(),
      OrgAccount: orgAccount
    }

    const payload = {
      ObjectMeta: {
        Name: 'ACCOUNT_CHECK'
      },
      Spec: {
        Automate: false,
        AwsSpec,
        Description: 'aws',
        Discovery: false,
        RemoteProxy: false,
        AccountType: 'AWS_CROSS',
        AccountID
      }
    }
    const isValidCredentials = await isValidAccountCredentials(payload)
    return isValidCredentials
  }

  const getAwsSpecFromForm = ({ arn, externalID, ouID, orgAccount }) => {
    const AwsSpec = {
      AssumeRoleARN: arn.trim(),
      ExternalID: externalID.trim(),
      OuID: ouID.trim(),
      OrgAccount: orgAccount
    }
    return AwsSpec
  }

  const updateAWSAccount = async ({
    arn,
    account,
    description,
    orgAccount,
    externalID,
    ouID
  }) => {
    const AwsSpec = getAwsSpecFromForm({ arn, orgAccount, externalID, ouID })
    const AccountID = getAccountIDFromARN(arn)
    const acc = structuredClone(account)
    acc.Spec.AwsSpec = AwsSpec
    acc.Spec.AccountID = AccountID
    acc.Spec.Description = description
    return await updateAccount(acc)
  }

  const createAWSAccount = async ({ arn, name, description, orgAccount, externalID, ouID, org }) => {
    const AccountID = getAccountIDFromARN(arn)
    const AwsSpec = getAwsSpecFromForm({ arn, orgAccount, externalID, ouID })
    const payload = {
      ObjectMeta: {
        Kind: 'Account',
        Name: name.trim(),
        Namespace: org
      },
      Spec: {
        AwsSpec,
        AccountID,
        Description: description,
        DisplayName: name.trim(),
        Type: 'AWS'
      }
    }
    return await createAccount(payload)
  }

  return { checkAWSAccountCredentials, createAWSAccount, updateAWSAccount }
}
