import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { PropTypes } from 'Core'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  h3: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.25),
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px'
  }
}))
const H3 = ({ color = 'initial', className = '', children, height = '16px', ...rest }) => {
  const internalClasses = useStyle({ height })
  return (
    <Typography color={color} className={classNames(internalClasses.h3, className)} {...rest}>{children}</Typography>
  )
}
H3.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'textPrimary', 'textSecondary', 'error', 'initial', 'inherit'])
}
export default H3
