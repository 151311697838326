import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
import {
  faArrowRight,
  faRightToBracket,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { ErrorModal } from 'features/policy'
import { createRsrcKey, isResourceManaged } from 'features/resources'
import {
  getAppRoleSignInURL,
  getRDPServerAccessLinksMap,
  updateResource,
  usePrivateKubeAccess,
  useRDPAccess
} from 'features/targets'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { Button, DropDownButton, IconButton, Label, SwitchBox } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { LoadingFeedback } from 'V2Components'
import { K8ContextCommandsModal } from '../K8ContextCommandsModal'

function HeaderActions({
  rsrc,
  showSignIn,
  disableAccessButton,
  onAccessClick,
  hideAccessButton = false,
  showManageButton = true,
  showPrincipleSignIn,
  accessButtonPrimaryText = 'Create'
}) {
  const [showK8SignInModal, setShowK8SignInModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [isManaging, setIsManaging] = useState(false)
  const { appView } = useAppView()
  const { getObjectRef } = useMultiSlice(['policyList', 'kubeClusters'])
  const { user } = useUser()
  const history = useHistory()

  const { getUserRDPAccessLinks } = useRDPAccess()
  const { getKubeAccessMap } = usePrivateKubeAccess()

  const kubeCluster =
    rsrc.ObjectMeta.Kind === 'KubeNamespace' ? getObjectRef(rsrc.Spec.Cluster) : rsrc

  const getKubeSignInURLS = () => {
    const urls = []
    if (rsrc.ObjectMeta.Kind !== 'KubeCluster' && rsrc.ObjectMeta.Kind !== 'KubeNamespace') {
      return []
    }
    // get the cluster if it's namespace
    const rsrcPolicys = getKubeAccessMap()[createRsrcKey(rsrc)] || []

    return rsrcPolicys
  }

  const toggleManaged = async () => {
    setIsManaging(true)
    try {
      const data = structuredClone(rsrc)
      data.Spec.State = data.Spec.State === 'managed' ? 'unmanaged' : 'managed'
      const updt = await updateResource(data)
      updateSliceData(updt)
    } catch (error) {
    } finally {
      setIsManaging(false)
    }
  }

  const handleAppRoleSignIn = () => {
    const signInUrl = getAppRoleSignInURL(rsrc)
    openURLExternally(signInUrl)
  }

  const getDropdownMenuItems = () => {
    let accessLinksMap = {}
    if (appView === 'admin') accessLinksMap = getRDPServerAccessLinksMap(rsrc)
    else accessLinksMap = getUserRDPAccessLinks(user, rsrc)

    return [
      {
        submenu: Object.entries(accessLinksMap).map(([key, value]) => ({
          title: key,
          action: () => openURLExternally(value)
        })),
        title: 'Sign In'
      }
    ]
  }

  const getKubeCtlCtx = () => {
    // default show if appview is admin and route is cluster and resource is managed
    const isKubeClusterRoute = window.location.href.includes('kubecluster') ? true : false
    if (isResourceManaged(kubeCluster) && appView === 'admin' && isKubeClusterRoute) return true

    if (!isKubeClusterRoute && isResourceManaged(kubeCluster) && getKubeSignInURLS().length > 0)
      return true

    // for userview check iscluster managed and policy exist
    if (getKubeSignInURLS().length && appView === 'user') return true
  }

  const errors = rsrc.Status.Status.Error

  return (
    <div className='flex items-center gap-4'>
      {isManaging && (
        <LoadingFeedback loading caption='Please Wait' message='Changing the managed state.' />
      )}
      {rsrc.ObjectMeta.Kind === 'KubeNamespace' && (
        <Label
          size='md'
          variant='success'
          iconButton={faInfoCircle}
          text='Kubernetes Namespace can be managed by managing the cluster'
        />
      )}
      {errors && (
        <IconButton
          variant='grayRed'
          icon={faTriangleExclamation}
          onClick={() => setShowErrorModal(true)}
        />
      )}
      {appView === 'admin' && rsrc.ObjectMeta.Kind !== 'KubeNamespace' && showManageButton && (
        <SwitchBox
          checked={rsrc.Spec.State === 'managed'}
          label='Managed'
          onChange={toggleManaged}
          variant='primary'
        />
      )}
      {showPrincipleSignIn && (
        <DropDownButton
          menuItems={getDropdownMenuItems()}
          size='md'
          variant='grayBlue'
          textTransform='none'
        />
      )}

      {rsrc.ObjectMeta.Kind === 'RDPServer' && appView === 'admin' && (
        <Button
          iconPosition='end'
          icon={faArrowRight}
          size='md'
          onClick={() => history.push(`/${appView}/addomaincontrollers`)}
          key='signin'
          variant='grayBlue'
        >
          Configure AD Domain Controllers
        </Button>
      )}

      {showSignIn && (
        <Button size='md' onClick={handleAppRoleSignIn} key='signin' variant='grayBlue'>
          Sign In
        </Button>
      )}
      {/* Show signin button when the cluster is managed - for kubenamespace and cluster */}

      {getKubeCtlCtx() &&
        (rsrc.ObjectMeta.Kind === 'KubeCluster' || rsrc.ObjectMeta.Kind === 'KubeNamespace') && (
          <Button
            icon={faRightToBracket}
            variant='primary'
            onClick={() => setShowK8SignInModal(true)}
          >
            Kubectl Ctx
          </Button>
        )}

      {!hideAccessButton && (
        <Button
          disabled={disableAccessButton}
          onClick={() => onAccessClick()}
          size='md'
          variant='grayBlue'
        >
          {appView === 'admin'
            ? `${accessButtonPrimaryText} Policy`
            : `${accessButtonPrimaryText} Request`}
        </Button>
      )}

      {showK8SignInModal && (
        <K8ContextCommandsModal
          principalName={'default'}
          onClose={() => setShowK8SignInModal(false)}
          rsrc={rsrc}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          message={errors}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Policy Error'
        />
      )}
    </div>
  )
}

export { HeaderActions }
