import React, { useEffect, useState } from 'react'
import { Header } from './components/Header'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { ChannelRow, Typography } from 'procyon-ui'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useHistory, useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useChannelBrowser } from './providers/hooks'
import { ChannelBrowserContext } from './providers/BrowserContext'
import { SlackNotificationChannel } from './components/Notifications/SlackWebhook'
import { createRef, createRsrcKey, reverseRsrcKey } from 'features/resources'
import { FullScreenModal, SearchInput } from 'V2Components'
import { ManageAccount } from './components/ManageAccounts'
import { getFriendlyName } from 'Utils/FriendlyName'
import { ServiceNowNotificationChannel } from './components/Notifications/ServiceNow'
import { DeleteIntegrationModal } from 'features/integrations'
import { Endpoints } from '../../const'
import { pushToSlice } from 'infra/redux/sliceHandlers'

import { faCog, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { JiraConfiguration } from './components/JiraConfiguration'
import { JiraConfigurationEdit } from './components/JiraConfigurationEdit'
import { createDataSelectorHook } from 'infra/redux'
import { reduxApiClient } from 'infra'
import { MSTeam } from './components/Notifications/MSTeam/MSTeam'
import { MsTeamNotificationWebhook } from './components/Notifications/MsTeamNotificationWebhook'
import { SlackChannels } from './components/ChannelsList/SlackChannels'
import { ServiceNowChannels } from './components/ChannelsList/ServiceNowChannels'
import { MsChannels } from './components/ChannelsList/MsChannels'
import { MsWebhookChannels } from './components/ChannelsList/MsWebhookChannels'

const ManageIntegration = () => {
  const history = useHistory()
  const { linkedChannels, refresh, integration } = useChannelBrowser()
  const { kind } = useParams()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { getObjectRef } = useMultiSlice([
    'accountList',
    'notificationSubscriptions',
    'servicenowtables',
    'msTeamsChannels'
  ])

  const useSlices = createDataSelectorHook([
    'accountList',
    'notificationSubscriptions',
    'servicenowtables',
    'msTeamsChannels'
  ])
  const [showLinkAccountModal, setShowLinkAccountModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [currentSelectedChannel, setCurrentSelectedChannel] = useState({})
  const [currentChannelAccounts, setCurrentChannelAccounts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showJiraConfig, setShowJiraConfig] = useState(true)
  const [isJiraConfigEditable, setIsJiraConfigEditable] = useState(false)
  const { slices } = useSlices()

  const getIntegrationType = () => {
    switch (kind) {
      case 'SlackIntegration':
        return 'SLACK'
      case 'JiraIntegration':
        return 'JIRA'
      case 'ServiceNowIntegration':
        return 'SERVICENOW'
      case 'MsTeamsIntegration':
        return 'TEAMS'
      default:
        return null
    }
  }

  useEffect(() => {
    refresh()
  }, [isJiraConfigEditable])

  const getChannelAccounts = (channel) => {
    const channelKey = createRsrcKey(channel)
    const accountsKey = []
    slices.notificationSubscriptions.forEach((notySub) => {
      const subKey = createRsrcKey(notySub?.Spec?.Subscriber)
      if (channelKey === subKey) accountsKey.push(createRsrcKey(notySub?.Spec?.SubscriptionFor))
    })

    return getObjectRef([...new Set(accountsKey)].map(reverseRsrcKey))
  }

  const getTotalLinkedAccount = (channel) => {
    const accounts = getChannelAccounts(channel)
    return accounts
  }

  const getCloudsAccountCount = (channel, type) => {
    const accounts = getChannelAccounts(channel)
    const filterGCP = accounts.filter((account) => account?.Spec?.Type === type)
    return filterGCP
  }

  const handleEntityModalContinue = async () => {
    try {
      setShowLinkAccountModal(false)
      // setLoading(true)
      const currentChannelIDs = currentChannelAccounts?.map((e) => e?.ObjectMeta?.ID)
      const newChannelIDs = selectedEntities.map((e) => e?.ObjectMeta?.ID)
      const integrationTargetsToRemove = currentChannelIDs.filter(
        (targetId) => !newChannelIDs.includes(targetId)
      )
      const integrationTargetsToAdd = newChannelIDs.filter(
        (targetId) => !currentChannelIDs.includes(targetId)
      )
      const payloads = []
      const toRemoveNotificationChannels = []
      const toCreateNotificationChannels = []

      integrationTargetsToRemove.forEach((accountID) => {
        const slackKey = createRsrcKey(currentSelectedChannel)
        const thisAccKey = `Account+${accountID}`
        const notifObject = slices.notificationSubscriptions.find((ns) => {
          const sKey = createRsrcKey(ns?.Spec?.Subscriber)
          const accKey = createRsrcKey(ns?.Spec?.SubscriptionFor)
          if (sKey === slackKey && accKey === thisAccKey) return true
        })
        if (notifObject) toRemoveNotificationChannels.push(notifObject)
      })

      integrationTargetsToAdd.forEach((accountID) => {
        const obj = {
          SubscriptionFor: {
            RefKind: 'Account',
            RefID: accountID
          },
          Subscriber: createRef(currentSelectedChannel)
        }
        toCreateNotificationChannels.push(obj)
      })

      toCreateNotificationChannels.map(async (Spec) => {
        const payload = {
          ObjectMeta: {
            Name: getFriendlyName()
          },
          Spec
        }
        await reduxApiClient('notificationsubscriptions').create(payload)
      })

      toRemoveNotificationChannels.map(async (payload) => {
        await reduxApiClient('notificationsubscriptions').delete(payload)
      })
      pushToSlice()
      // setLoading(true)
      enqueueNotification('Updated channel account linking successfully', 'info')
    } catch (error) {
      console.error('Error in saving owners', error)
      enqueueNotification('Operation Failed', 'error')
    } finally {
      // setLoading(false)
      setTimeout(() => {
        setShowLinkAccountModal(false)
      }, 1500)
    }
  }

  const handleManageAccount = (channel) => {
    const accounts = getChannelAccounts(channel)
    setSelectedEntities(accounts)
    setShowLinkAccountModal(true)
    setCurrentSelectedChannel(channel)
    setCurrentChannelAccounts(accounts)
  }

  const handleUnlinkAllAccount = async (channel) => {
    const accounts = getChannelAccounts(channel)
    const currentChannelIDs = accounts?.map((e) => e?.ObjectMeta?.ID)
    const toRemoveNotificationChannels = []

    try {
      currentChannelIDs.forEach((accountID) => {
        const slackKey = createRsrcKey(channel)
        const thisAccKey = `Account+${accountID}`
        const notifObject = slices.notificationSubscriptions.find((ns) => {
          const sKey = createRsrcKey(ns?.Spec?.Subscriber)
          const accKey = createRsrcKey(ns?.Spec?.SubscriptionFor)
          if (sKey === slackKey && accKey === thisAccKey) return true
        })
        if (notifObject) toRemoveNotificationChannels.push(notifObject)
      })

      toRemoveNotificationChannels.map(async (payload) => {
        await reduxApiClient('notificationsubscriptions').delete(payload)
      })

      refresh()
      console.error('removed all linked channel')
      enqueueNotification('Removed all linked channel successfully', 'info')
    } catch (error) {
      console.error('Error in removing all linked channel', error)
      enqueueNotification('Operation Failed', 'error')
      console.log(error)
    }
  }

  const filteredChannel = linkedChannels.filter((item) => {
    const channelName = item?.Spec?.ChannelName
    const name = item?.Spec?.Name
    const projectName = item?.Spec?.ProjectName

    // Check if ChannelName contains the searchTerm
    if (channelName && channelName.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return true
    }

    // If ChannelName is not found, check Name and Project
    if (!channelName) {
      return (
        (name && name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
        (projectName && projectName.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
      )
    }
    return false
  })

  const getChannelName = () => {
    switch (kind) {
      case 'SlackIntegration':
        return integration?.Spec?.WebhookUrl === ''
          ? currentSelectedChannel?.Spec?.ChannelName
          : 'Webhook Channel  '
      case 'JiraIntegration':
        return currentSelectedChannel?.Spec?.ProjectName
      case 'ServiceNowIntegration':
        return currentSelectedChannel?.Spec?.Name
      default:
        return ''
    }
  }

  const handleDeleteIntegrationClick = async () => {
    setShowDeleteModal(false)
    try {
      await reduxApiClient(Endpoints[integration?.ObjectMeta?.Kind]).delete(integration)
      enqueueNotification(
        `${integration?.ObjectMeta?.Name} integration removed successfully`,
        'info'
      )
      history.push('/ui/admin/integrations')
    } catch (error) {
      enqueueNotification('Operation failed', 'error')
      console.log(error, 'integration delete error')
    }
  }

  const getHeading = () => {
    switch (kind) {
      case 'SlackIntegration':
        return 'Channel-Account linking'
      case 'JiraIntegration':
        return 'Project-Account linking'
      case 'ServiceNowIntegration':
        return 'Table-Acccount linking'
    }
  }

  const getKindNotificationComponent = () => {
    switch (kind) {
      case 'SlackIntegration':
        return <SlackNotificationChannel />
      case 'MsTeamsIntegration':
        if (integration?.Spec?.WebhookUrl === '') {
          return <MSTeam />
        } else {
          return <MsTeamNotificationWebhook />
        }
      case 'ServiceNowIntegration':
        return <ServiceNowNotificationChannel />
      default:
        return null // or some default component
    }
  }

  const getKindChannelsComponent = () => {
    switch (integration?.ObjectMeta?.Kind) {
      case 'SlackIntegration':
        return (
          <SlackChannels
            getCloudsAccountCount={getCloudsAccountCount}
            handleManageAccount={handleManageAccount}
            handleUnlinkAllAccount={handleUnlinkAllAccount}
            getTotalLinkedAccount={getTotalLinkedAccount}
            getIntegrationType={getIntegrationType}
          />
        )
      case 'ServiceNowIntegration':
        return (
          <ServiceNowChannels
            getCloudsAccountCount={getCloudsAccountCount}
            handleManageAccount={handleManageAccount}
            handleUnlinkAllAccount={handleUnlinkAllAccount}
            getTotalLinkedAccount={getTotalLinkedAccount}
            getIntegrationType={getIntegrationType}
            filteredChannel={filteredChannel}
          />
        )
      case 'JiraIntegration':
        return (
          <ServiceNowChannels
            getCloudsAccountCount={getCloudsAccountCount}
            handleManageAccount={handleManageAccount}
            handleUnlinkAllAccount={handleUnlinkAllAccount}
            getTotalLinkedAccount={getTotalLinkedAccount}
            getIntegrationType={getIntegrationType}
            filteredChannel={filteredChannel}
          />
        )
      case 'MsTeamsIntegration':
        if (integration?.Spec?.WebhookUrl === '') {
          return (
            <MsChannels
              getCloudsAccountCount={getCloudsAccountCount}
              handleManageAccount={handleManageAccount}
              handleUnlinkAllAccount={handleUnlinkAllAccount}
              getTotalLinkedAccount={getTotalLinkedAccount}
              filteredChannel={filteredChannel}
              msTeamsChannels={slices.msTeamsChannels}
            />
          )
        } else {
          return (
            <MsWebhookChannels
              getCloudsAccountCount={getCloudsAccountCount}
              handleManageAccount={handleManageAccount}
              handleUnlinkAllAccount={handleUnlinkAllAccount}
              getTotalLinkedAccount={getTotalLinkedAccount}
            />
          )
        }

      default:
        return null
    }
  }

  return (
    <>
      <Header setShowDeleteModal={setShowDeleteModal} isJiraConfigEditable={isJiraConfigEditable} />
      {isJiraConfigEditable ? (
        <JiraConfigurationEdit
          integration={integration}
          isJiraConfigEditable={isJiraConfigEditable}
          setIsJiraConfigEditable={setIsJiraConfigEditable}
        />
      ) : (
        <div className='flex gap-8'>
          <div className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8')}>
            {kind === 'JiraIntegration' ? (
              <>
                <div className='flex justify-between items-center'>
                  <Typography variant='h3' className='mt-5'>
                    Jira Issue Type
                  </Typography>
                  <div
                    onClick={() => setIsJiraConfigEditable(!isJiraConfigEditable)}
                    className='bg-[#f6f6f6] px-3.5 py-2 rounded-md flex items-center mt-5 cursor-pointer'
                  >
                    <span className=' font-normal pr-2 text-base text-[#545b71]'>Setting</span>
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                </div>

                {integration?.Spec?.JiraIssueType === '' && (
                  <Typography className='mt-5' variant='body-regular'>
                    Setting up Jira configuration involves customizing various aspects like issue
                    types, custom fields, workflows, and user permissions to align with your team's
                    workflow and project requirements.
                  </Typography>
                )}

                {!isJiraConfigEditable &&
                  showJiraConfig &&
                  integration?.Spec?.JiraIssueType !== '' && (
                    <JiraConfiguration integration={integration} />
                  )}
              </>
            ) : (
              <>
                <Typography variant='h3' className='mt-5'>
                  Notification
                </Typography>
                {getKindNotificationComponent()}
              </>
            )}
          </div>

          <div className='w-1/2'>
            <div>
              <div className='flex justify-between mt-5'>
                <Typography variant='h3'>{getHeading()}</Typography>
                <SearchInput searchKey={searchTerm} onChange={setSearchTerm} />
              </div>
              <div className='mt-8 h-[71vh] overflow-auto'>{getKindChannelsComponent()}</div>
            </div>
          </div>
        </div>
      )}
      {showLinkAccountModal && (
        <FullScreenModal showModal={showLinkAccountModal}>
          <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
            <div className='rounded-lg p-8 bg-white'>
              <ManageAccount
                title={`Link accounts to ${getChannelName()} channel`}
                errorMessage=''
                entities={slices.accountList}
                selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities}
                onCancel={() => {
                  setShowLinkAccountModal(false)
                }}
                onContinue={handleEntityModalContinue}
              />
            </div>
          </div>
        </FullScreenModal>
      )}
      {showDeleteModal && (
        <DeleteIntegrationModal
          integrationName={integration}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          handleDeleteIntegrationClick={handleDeleteIntegrationClick}
        />
      )}
    </>
  )
}

const ManageIntegratonsWrapper = () => {
  return (
    <ChannelBrowserContext>
      <ManageIntegration />
    </ChannelBrowserContext>
  )
}

export default ManageIntegratonsWrapper
