import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

const Fallback = ({ onAddAccount }) => {
  return (
    <>
      <div className='flex w-[100%] justify-center items-center p-7 bg-[#F9FBFC] -m-4 mb-0'>
        <Typography variant='h2'>Connect your Cloud Service Accounts to Procyon</Typography>
      </div>
      <div className='flex justify-center items-center flex-col'>
        <div className='w-[642px] '>
          <div className='flex justify-center gap-4 mt-[83px]'>
            <TargetIcon width='100px' type='AWS_APPLICATION' />
            <TargetIcon width='100px' type='GOOGLE_APPLICATION' />
            <TargetIcon width='100px' type='AZURE_APPLICATION' />
          </div>
          <Typography variant='body-regular' className='!text-center !block mt-[50px]'>
            Enable users passwordless access to cloud resources and workloads in the cloud
          </Typography>
          <Typography
            variant='body-regular'
            className='!text-center !block !text-[#545B71] mt-3 m-auto'
          >
            This approach eliminates the need for traditional passwords and enhances security while
            ensuring efficient utilization of cloud services such as servers, databases and roles.
          </Typography>
          <Button icon={faPlus} onClick={onAddAccount} size='lg' variant='primary' className='m-auto my-8'>
            Add Account
          </Button>
          <hr className='w-[] mb-8' />
          <div className='flex gap-8 justify-between'>
            <div>
              <Typography className='!text-[#8D94A1]' variant='h4-regular'>
                Access Capabilities
              </Typography>
              <div className='flex flex-col gap-2'>
                <Typography className='mt-8' variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  AWS, GCP and AZURE resource easy access.
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  AWS CLI
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  GCLOUD CLI
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Assume AppRole
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Kubernetes
                </Typography>
              </div>
            </div>
            <div>
              <Typography className='!text-[#8D94A1]' variant='h4-regular'>
                Provisioning
              </Typography>
              <div className='flex flex-col gap-2'>
                <Typography className='mt-8' variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Manage Servers, Databases and AppRoles
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Onboard and Manage Users
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Grant User Access to Resources
                </Typography>
                <Typography variant='body-regular'>
                  <FontAwesomeIcon className='mr-2' icon={faCheck} />
                  Manage Resource Access
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Fallback }
