import useAppView from 'Core/Hooks/useAppView'
import { PaginationView, SearchInput } from 'V2Components'
import { useBundlesUserAccess } from 'features/bundles'
import { getRsrcIcon } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import { BundleCard, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

const useSlices = createDataSelectorHook(['bundles'])

const Bundles = () => {
  const [searchKey, setSearchKey] = useState('')

  const history = useHistory()
  const { appView } = useAppView()
  const { slices } = useSlices()
  const { getBundleAccessMap } = useBundlesUserAccess()

  const getBundlesData = () => {
    const typesMap = {}

    slices.bundles.forEach((bundle) => {
      const maps = getBundleAccessMap(bundle.Type)
      typesMap[bundle.Type] = {
        type: bundle.Type,
        Icon: getRsrcIcon(bundle),
        granted: maps.granted.length,
        ungranted: maps.ungranted.length,
        inProgress: maps.inProgress.length
      }
    })

    return Object.values(typesMap)
  }

  const renderBundleCardFunction = (data) => {
    const { type, Icon, granted, ungranted, inProgress } = data

    return (
      <BundleCard
        Icon={<Icon width='34px' height='34px' className='w-[34px] h-[34px]' />}
        onClickMoreInfoButton={() => history.push(`/${appView}/bundles/${type.toLowerCase()}`)}
        title={type}
        grantedCount={granted}
        ungrantedCount={ungranted}
        progressCount={inProgress}
        showGrantedCountButton
        showUngrantedCountButton
        showProgressCountButton
      />
    )
  }

  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <Typography variant='h2'>Bundles</Typography>
        <SearchInput searchKey={searchKey} onChange={setSearchKey} />
      </div>
      <div>
        <PaginationView
          itemsCount={40}
          data={getBundlesData()}
          renderFunction={renderBundleCardFunction}
          WrapperComponent={({ children }) => (
            <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid gap-8 mt-4'>
              {children}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export { Bundles }
