import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { EntityInfoBar } from 'V2Components'
import { IAMResourcesSliceNames } from 'features/iamResources'
import {
  getPolicyAttachedIAMActions,
  getPolicyResourcesRefs,
  isPolicyIssuedToEntity
} from 'features/policy'
import {
  ResourcesSummaryView,
  createRsrcKey,
  getResourceName,
  reverseRsrcKey
} from 'features/resources'
import { Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

const AccessSummaryTab = () => {
  const workload = useDataDetailsContext()
  const history = useHistory()
  const { slices, getObjectRef, dispatchThunks } = useMultiSlice([
    'policyList',
    'iamActions',
    'projects',
    ...IAMResourcesSliceNames
  ])

  const handlePolicyView = (p) => history.push(`/admin/policys/${p.ObjectMeta.Name}`)

  const issuedPolicys = slices.policyList.filter((p) => isPolicyIssuedToEntity(p, workload))

  const getRsrcToIAMActionMap = () => {
    const rsrcToIAMActions = {}

    issuedPolicys.forEach((policy) => {
      const rsrcs = getPolicyResourcesRefs(policy)
      const iamActions = getPolicyAttachedIAMActions(policy)
      rsrcs.forEach((rsrcRef) => {
        const rsrcKey = createRsrcKey(rsrcRef)
        const rsrcIAMActions = rsrcToIAMActions[rsrcKey] || []
        const rsrcAttachedIAMActions = iamActions[rsrcKey] || []

        rsrcIAMActions.push(...rsrcAttachedIAMActions.map(createRsrcKey))
        rsrcToIAMActions[rsrcKey] = [...new Set(rsrcIAMActions)]
      })
    })

    return rsrcToIAMActions
  }

  const rsrcToIAMActionMap = getRsrcToIAMActionMap()

  const getResources = () => {
    return getObjectRef(Object.keys(rsrcToIAMActionMap).map(reverseRsrcKey))
  }

  const getAttributesObject = () => {
    const map = {}

    for (const rsrcKey in rsrcToIAMActionMap) {
      const iamActionKeys = rsrcToIAMActionMap[rsrcKey]
      const iamActions = getObjectRef(iamActionKeys.map(reverseRsrcKey))
      map[rsrcKey] = iamActions.map(getResourceName)
    }

    return map
  }
  useEffect(() => {
    dispatchThunks()
  }, [])
  
  if (!issuedPolicys.length)
    return (
      <Typography variant='body-regular'>
        Workload Identity doesn't have any resource access.
      </Typography>
    )

  return (
    <div className='flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        {issuedPolicys.map((e) => (
          <div className='border-b border[#D8DDE4]' key={createRsrcKey(e)}>
            <EntityInfoBar
              kind='PacPolicy'
              name={getResourceName(e)}
              caption={e.ObjectMeta.Name}
              menuItems={[
                {
                  title: 'View',
                  action: () => handlePolicyView(e)
                }
              ]}
            />
          </div>
        ))}
      </div>
      <div className='w-1/2 px-8'>
        <ResourcesSummaryView
          resources={getResources()}
          resourceAttibutesObject={getAttributesObject()}
        />
      </div>
    </div>
  )
}

export { AccessSummaryTab }
