import { createSelector } from '@reduxjs/toolkit'
import * as sls from 'infra/redux/reducers/slices/slices'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createDataSelectorHook, MapSliceNameToKind } from '../utils'

const SlicePaginatedThunks = {}
const SliceNames = []

// This is used to override the slice.name with custom name
const EXCEPTIONS_MAP = {
  credentialTypesList: 'credentialTypeList'
}

/**
 * Parse all thunks and slices names.
 */
for (const property in sls) {
  const { name: _name, paginatedFetchThunk } = sls[property]
  const name = EXCEPTIONS_MAP[_name] || _name
  SliceNames.push(name)
  SlicePaginatedThunks[name] = paginatedFetchThunk
}

/**
 * Function which returns a selector function for data of `sliceNames`
 * @param {import('types').SliceNames[]} sliceNames
 * @returns
 */
export const lastIndexSelector = (sliceNames) => {
  return createSelector(
    sliceNames.map((e) => (s) => {
      return s[e].pageLastIndex
    }),
    (...s) => {
      const map = {}
      sliceNames.forEach((sName, index) => {
        const lastIndex = s[index]
        map[sName] = lastIndex
      })
      return map
    }
  )
}

/**
 * Function which returns a selector function for data of `sliceNames`
 * @param {import('types').SliceNames[]} sliceNames
 * @returns
 */
export const pageEndReachedSelector = (sliceNames) => {
  return createSelector(
    sliceNames.map((e) => (s) => {
      return s[e].lastPageReached
    }),
    (...s) => {
      const map = {}
      sliceNames.forEach((sName, index) => {
        const lastIndex = s[index]
        map[sName] = lastIndex
      })
      return map
    }
  )
}

const POLLING_END_MAP = {}
const POLLING_STARTED_MAP = {}

const useSlices = createDataSelectorHook(['accountList'])
/**
 *
 * @param {{
 * sliceNames:import('types').SliceNames[]
 * }} param0
 */
export const usePaginatedReduxFetch = ({ sliceNames }) => {
  const lastIndexMap = useSelector(lastIndexSelector(sliceNames))
  const init = useRef(null)
  const dispatch = useDispatch()

  const { slices } = useSlices()

  useEffect(() => {
    if (!slices.accountList.length || init.current) return

    sliceNames.forEach(async (sliceName) => {
      const thunk = SlicePaginatedThunks[sliceName]
      const lastIndex = lastIndexMap[sliceName]

      const kind = MapSliceNameToKind[sliceName]

      const totalCount = slices.accountList.reduce((prev, curr) => {
        const count = curr.Status?.ResourcesInfo?.Map[kind] || 0
        return prev + +count
      }, 0)

      let startIndex = lastIndex
      let count = 1
      if (!thunk) return
      if (POLLING_END_MAP[sliceName]) return
      if (POLLING_STARTED_MAP[sliceName]) return
      while (true) {
        POLLING_STARTED_MAP[sliceName] = true
        let endIndex = lastIndex + 1000 * count
        //endIndex is limit, and limit is always 1000
        const { data } = await dispatch(thunk({ startIndex, endIndex:1000 })).unwrap()
        if (!data) {
          //There was an error hence sleep
          await new Promise((r) => setTimeout(r, 5000))
        }
        //polling end logic
        if (endIndex >= totalCount) {
          POLLING_END_MAP[sliceName] = true
          break
        }
        startIndex = endIndex
        count += 1
      }
    })
    init.current = true
  }, [slices.accountList])
}
