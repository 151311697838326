const { createSlice } = require('@reduxjs/toolkit')

/**
 * @typedef {'iamResourcesUser' | 'iamResourcesAdmin' | 'targetsUser' | 'targetsAdmin'} QueueAvailableKey
 * @typedef {{ Account:{ RefID: string, Type: string }, Principal?: string, RefKind: string, RefID: string, IamRoles?:{ RefKind: 'IamAction', RefID: string }[] }} ResourceItem
 * @typedef {{ key:QueueAvailableKey, data:  ResourceItem }} QueuePayload
 */

/**
 * @typedef {{ iamResourcesUser:ResourceItem[], iamResourcesAdmin:ResourceItem[], targetsUser: ResourceItem[], targetsAdmin: ResourceItem[] }} QueueStateType
 * @type {QueueStateType}
 */
const initialState = {
  iamResourcesUser: [],
  iamResourcesAdmin: [],
  targetsUser: [],
  targetsAdmin: []
}

const resourcesQueueSlice = createSlice({
  name: 'resourcesQueue',
  initialState,
  reducers: {
    /**
     *
     * @param {{ type: string, payload: QueuePayload}} action
     */
    addToQueue(state, action) {
      if (state[action.payload.key]) {
        state[action.payload.key].push(action.payload.data)
        return state
      }
      state[action.payload.key] = [action.payload.data]
      return state
    },
    /**
     *
     * @param {{ type:string, payload: { key: QueueAvailableKey, RefID: string, RefKind: string } }} action
     */
    removeResourceFromQueue(state, action) {
      if (state[action.payload.key]) {
        state[action.payload.key] = state[action.payload.key].filter((s) => {
          if (s.RefKind === action.payload.RefKind && s.RefID === action.payload.RefID) return false
          return true
        })
      }
    },
    /**
     *
     * @param {{ type: string, payload: QueuePayload}} action
     */
    updateQueueResource(state, action) {
      if (state[action.payload.key]) {
        state[action.payload.key] = state[action.payload.key].map((s) => {
          if (s.RefKind === action.payload.data.RefKind && s.RefID === action.payload.data.RefID)
            return {
              ...s,
              ...action.payload.data
            }
          return s
        })
      }
    },
    /**
     *
     * @param {{ type:string, payload: { key: QueueAvailableKey } }} action
     */
    clearResourcesQueue(state, action) {
      state[action.payload.key] = []
    }
  }
})

export const { addToQueue, removeResourceFromQueue, clearResourcesQueue, updateQueueResource } =
  resourcesQueueSlice.actions
export default resourcesQueueSlice.reducer
