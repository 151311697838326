import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { cn } from 'Utils/Helpers'
import { getAccountIcon } from 'Utils/PureHelperFuctions'
import { LabelContent } from 'V2Components'
import { AppFrontendURLKey, getApplicationType, useAppDetailsContext } from 'features/snowflake'
import { ManageOwnersView, getResourceName, getRsrcIcon } from 'features/resources'

import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import moment from 'moment'
import { InformationRow, Label, RiskIndicator, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
const useSlices = createDataSelectorHook([
  'githubAccount',
  'githubResources',
  'salesForceAccountList'
])

function OverviewTab() {
  const { app } = useAppDetailsContext()
  const { user, rdpurl, isAgentlessMode } = useUser()
  const { appView } = useAppView()
  const appType = getApplicationType(app)
  const { getObjectRef } = useMultiSlice(['accountList'])
  const Icon = getRsrcIcon(app)
  const account = getObjectRef(app?.Spec.Account)
  const AccountIcon = getAccountIcon(account?.Spec.Type)

  const getActiveStatus = (app) => {
    if (app.Spec.AppType === 'salesforce') {
      return app.Spec.SalesforceAppConfig.Status.Status === 'Fail' ? 'offline' : 'online'
    }

    if (app.Spec.AppType === 'snowflake') {
      return app.Spec.SnowflakeAppConfig.Status.Status === 'Fail' ? 'offline' : 'online'
    }
  }

  return (
    <div className='flex gap-8'>
      {/* Hide the border in user view, because the other div which shows owners won't be shown */}
      <div
        className={cn('w-1/2 ', 'border-r border[#D8DDE4] pr-8', {
          'border-none': appView === 'user'
        })}
      >
        <Typography variant='h4-regular'>Resource Information</Typography>
        <div className='mt-8'>
          {
            <LabelContent
              title='Status'
              content={
                <>
                  <RiskIndicator variant={getActiveStatus(app)} />
                </>
              }
            />
          }
          <LabelContent title='Application Name' content={getResourceName(app)} />
          <LabelContent
            title='Application Type'
            content={
              <Label
                style={{ marginLeft: '0px' }}
                //@ts-ignore
                text={
                  <div className='flex gap-2 items-center'>
                    {Icon && <Icon />}
                    <strong>{appType}</strong>
                  </div>
                }
              />
            }
          />
          {account && (
            <LabelContent
              title='Account'
              content={
                <div className='flex gap-2 items-center'>
                  {AccountIcon && <AccountIcon />}
                  {getResourceName(account)}
                </div>
              }
            />
          )}
          <LabelContent
            title='Created On'
            content={moment(app?.ObjectMeta.CreatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
          <LabelContent
            title='Last Updated On'
            content={moment(app?.ObjectMeta.UpdatedAt).format('DD MMMM, YYYY HH:mm:ss A')}
          />
        </div>
      </div>
      {/* 
          Hide owners on user view
      */}
      {appView === 'admin' && appType !== 'GITHUB' && (
        <div className='w-1/2'>
          <ManageOwnersView rsrc={app} />
        </div>
      )}
    </div>
  )
}

export { OverviewTab }
