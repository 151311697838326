import { EntityInfoBar } from 'V2Components'
import { ResourcesSummaryView, createRsrcKey } from 'features/resources'
import { Typography } from 'procyon-ui'
import React from 'react'
import { useAccessSummaryTab } from './AccessSummaryTab.utils'
import { useHistory } from 'react-router'

function AccessSummaryTab() {
  const history = useHistory()

  const { attributesObject, rsrcs, policys } = useAccessSummaryTab()
  if (!rsrcs.length)
    return <Typography variant='body-regular'>Group has access to 0 resources.</Typography>

  return (
    <div className='flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        <Typography className='mt-2' variant='h4-regular'>Policies Attached</Typography>
        <div className='mt-4'>
          {policys.map((e) => (
            <div className='border-b border[#D8DDE4]' key={createRsrcKey(e)}>
              <EntityInfoBar
                kind='PacPolicy'
                name={e.GivenName}
                caption={e.ObjectMeta.Name}
                menuItems={[
                  {
                    title: 'View',
                    action: () => history.push(`/admin/policys/${e.ObjectMeta.Name}`)
                  }
                ]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='w-1/2 px-8'>
        <ResourcesSummaryView resources={rsrcs} resourceAttibutesObject={attributesObject} />
      </div>
    </div>
  )
}

export { AccessSummaryTab }
