import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { cn } from 'Utils/Helpers'
import { getServiceAccountDisplayName } from 'Utils/PureHelperFuctions'
import { EntityInfoBar } from 'V2Components'
import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { Button, Typography } from 'procyon-ui'
import React from 'react'

function EntitiesView({ entities = [], onManageEntitiesClick }) {
  const getUserGroupsCount = () => {
    let str = ''
    const counts = { User: 0, Group: 0, ServiceAccount: 0 }
    const countStr = []
    entities.forEach((e) => {
      counts[e.ObjectMeta.Kind] += 1
    })

    if (counts.User) countStr.push(`${counts.User} Users`)
    if (counts.Group) countStr.push(`${counts.Group} Groups`)
    if (counts.ServiceAccount) countStr.push(`${counts.ServiceAccount} Service Accounts`)

    str = `${countStr.slice(0, countStr.length > 1 ? countStr.length - 1 : 1).join(', ')} ${
      countStr.length >= 2 ? 'and ' + countStr[countStr.length - 1] : ''
    }`

    return str
  }

  const getCaption = (item) => {
    const { Kind } = item.ObjectMeta
    if (Kind === 'WorkloadIdentity') return `${item.Spec.Accounts.ObjectRef.length} Accounts`
    if (Kind === 'User') return item.Spec.EmailID
    if (Kind === 'Group') return `${item.Spec.Users.ObjectRef?.length} Users`
    if (Kind === 'ServiceAccount') return getServiceAccountDisplayName(item)
  }

  const getEntitiesRenderItems = () => {
    const data = []

    if (!entities || !entities.length) return []

    entities.forEach((e) => {
      data.push({
        caption: getCaption(e),
        name: getResourceName(e),
        kind: e.ObjectMeta.Kind,
        key: createRsrcKey(e),
        ref: createRef(e)
      })
    })
    return data
  }

  return (
    <div>
      <div className='flex justify-between items-center mt-2 mb-2'>
        <Typography variant='body-regular'>{getUserGroupsCount()}</Typography>
        <Button
          icon={faPlus}
          iconPosition='end'
          onClick={() => onManageEntitiesClick && onManageEntitiesClick()}
          variant='grayBlue'
        >
          Manage Entities
        </Button>
      </div>
      <div>
        <div className={cn('border-[#EEF4F7] border-b')}>
          {getEntitiesRenderItems().map((e) => (
            <EntityInfoBar
              kind={e.kind}
              endButton={false}
              key={e.key}
              caption={e.caption}
              name={e.name}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export { EntitiesView }
