import {
  AccountsSortOptions,
  isChildAccount,
  isOrgAccount,
  useCloudAccounts,
  useCloudsResources
} from 'features/clouds'
import { getResourceName } from 'features/resources'
import { CloudCard, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useClouds } from '../../Clouds.utils'
import { useAWSAccounts } from './AWSAccounts.utils'

function AWSAccounts({ searchKey }) {
  const [activeOption, setActiveOption] = useState('all')

  const history = useHistory()

  const { cloudType } = useClouds()
  const { getAccountTargets } = useCloudsResources()
  const { getParentAccount } = useCloudAccounts()
  const { sortOptions, filteredAccounts } = useAWSAccounts({ searchKey, sortOption: activeOption })

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='body-regular'>{filteredAccounts.length} Accounts</Typography>
        <AccountsSortOptions
          options={sortOptions}
          activeOption={activeOption}
          onActiveOptionChange={setActiveOption}
        />
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8 mt-4'>
        {filteredAccounts.map((a) => {
          const { _meta } = getAccountTargets(a)
          const isChild = isChildAccount(a)
          const isOrg = isOrgAccount(a)
          const parentAccount = getParentAccount(a)
          const namespace = a.ObjectMeta.Namespace
          return (
            <CloudCard
              topRightLabel={namespace}
              topRightLabelVariant={namespace === 'default' ? 'grayBlue' : 'gray'}
              width='100%'
              // @ts-ignore
              caption={
                isOrg ? (
                  'Org Account'
                ) : isChild ? (
                  <>
                    Child of <strong className='ml-1'>{getResourceName(parentAccount)}</strong>
                  </>
                ) : (
                  ''
                )
              }
              icon={
                <TargetIcon
                  height='100%'
                  type={isOrg ? 'AWS_ORG' : isChild ? 'AWS_CHILD' : 'AWS_APPLICATION'}
                  width='100%'
                />
              }
              menuItems={[]}
              showResourcesButton={!isOrg}
              name={getResourceName(a)}
              onClickMoreInfo={() =>
                history.push(
                  `/admin/clouds/${cloudType.toLowerCase()}/${encodeURIComponent(
                    a.ObjectMeta.Name
                  )}`
                )
              }
              onClickResources={function noRefCheck() {}}
              resourcesAmount={_meta.count}
            />
          )
        })}
      </div>
    </div>
  )
}

export { AWSAccounts }
