import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import { CopyText, LabelContent } from 'V2Components'
import { getSSHCA } from 'features/clouds'
import { IconButton, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const SSHPublicKeyLabelContent = () => {
  const [sshCA, setSshCA] = useState({
    PublicKey: '',
    SSHPublicKey: '',
    loaded: false
  })

  const fetchAndPopulateSSHCA = async () => {
    const ca = await getSSHCA()
    setSshCA({
      loaded: true,
      SSHPublicKey: ca.SSHPublicKey,
      PublicKey: ca.PublicKey
    })
  }

  if (!sshCA.loaded) fetchAndPopulateSSHCA()

  return (
    <LabelContent
      content={
        <>
          {!sshCA.loaded && 'Loading'}
          {sshCA.loaded && (
            <div className='flex gap-2'>
              <Typography className='break-all' variant='body-regular'>
                {sshCA.SSHPublicKey}
              </Typography>
              <CopyText
                messageKey='SSH Public Key'
                text={sshCA.SSHPublicKey}
                Component={<IconButton size='sm' icon={faCopy} variant='gray' />}
              />
            </div>
          )}
        </>
      }
      title='SSH Public Key'
    />
  )
}

export { SSHPublicKeyLabelContent }
