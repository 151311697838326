import { createRsrcKey, getResourceName } from 'features/resources'
import _ from 'lodash'
import { ElementRow, SimpleDropDown, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'

const PolicysView = ({ policys, activePolicyKey = '', onPolicyClick }) => {
  const [policySortFilter, setPolicySortFilter] = useState('')

  const getFilteredPolicys = () => {
    if (policySortFilter === '') return policys
    if (policySortFilter === 'name') return _.sortBy(policys, (e) => e.GivenName.toLowerCase())
    if (policySortFilter === 'created') return _.sortBy(policys, 'ObjectMeta.CreatedAt').reverse()
  }

  const filteredPolicys = getFilteredPolicys()

  return (
    <div>
      <Typography variant='h4-regular'>Attached Policy</Typography>
      <div className='flex items-center justify-between'>
        <Typography className='mt-7' variant='body-regular'>
          {filteredPolicys.length} Policys
        </Typography>
        <SimpleDropDown
          menuItems={[
            {
              label: 'Sort: by Default',
              selected: true,
              value: ''
            },
            {
              label: 'Sort: by Name',
              selected: true,
              value: 'name'
            },
            {
              label: 'Sort: by Created',
              value: 'created'
            }
          ]}
          onChange={(e) => setPolicySortFilter(e.target.value)}
          value={policySortFilter}
        />
      </div>

      <div className='mt-5'>
        {filteredPolicys.map((policy) => (
          <div className='border-b border-[#EEF4F7]' key={policy.GivenName}>
            <ElementRow
              active={activePolicyKey === createRsrcKey(policy)}
              onClick={() => onPolicyClick(createRsrcKey(policy))}
              key={createRsrcKey(policy)}
              title={getResourceName(policy)}
              showButton={false}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export { PolicysView }
