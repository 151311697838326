import { createRef, createRsrcKey, getResourceName } from 'features/resources'
import { getUserDisplayName } from 'features/users'

const { getServiceAccountName, getServiceAccountDisplayName } = require('Utils/PureHelperFuctions')

export const getFilterDropdownItems = (entities = []) => {
  const tempFlagObj = {}
  const filters = [
    {
      label: 'Show: All',
      value: ''
    }
  ]

  entities.forEach((e) => {
    const { Kind } = e.ObjectMeta
    if (!tempFlagObj[Kind]) {
      filters.push({
        label: `Show: ${Kind === 'GithubResource' ? 'Team' : Kind}s`,
        value: Kind
      })
      tempFlagObj[Kind] = true
    }
  })

  return filters
}

export const entityDataAdapters = {
  User(u) {
    return {
      caption: u.Spec.EmailID || u.ObjectMeta.Name,
      name: getUserDisplayName(u),
      key: createRsrcKey(u),
      ref: createRef(u),
      refObject: u,
      type: 'USER'
    }
  },
  Group(g) {
    return {
      caption: `${g.Spec.Users.ObjectRef?.length || 0} Users`,
      name: g.ObjectMeta.Name,
      key: createRsrcKey(g),
      ref: createRef(g),
      refObject: g,
      type: 'USERGROUPS'
    }
  },
  ServiceAccount(s) {
    const dName = getServiceAccountDisplayName(s)
    let str = dName
    if (dName.length > 38) str = `${dName.substring(0, 15)}...${dName.slice(-15)}`
    return {
      caption: str,
      name: getServiceAccountName(s),
      key: createRsrcKey(s),
      ref: createRef(s),
      refObject: s,
      type: 'SERVICEACCOUNT',
      email: dName
    }
  },
  GithubResource(g) {
    return {
      caption: `${g.Spec?.Members?.Members?.length || 0} Users`,
      name: g.Spec.TeamSpec.Name,
      key: createRsrcKey(g),
      ref: createRef(g),
      refObject: g,
      type: 'USERGROUPS'
    }
  },
  WorkloadIdentity(w) {
    return {
      caption: `${w.Spec.Accounts.ObjectRef.length} Accounts`,
      name: getResourceName(w),
      key: createRsrcKey(w),
      ref: createRef(w),
      refObject: w,
      type: 'WORKLOAD'
    }
  }
}
