import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import {
  APPROVED_STATUS,
  CancelApprovalRequestModal,
  EditApprovalRequest,
  isApprovalRequestCreatedByUser,
  isServiceAccountRequest
} from 'features/approvals'
import React, { useEffect, useMemo, useState } from 'react'
import { ApprovalRequestManager } from '../../../../../components/ApprovalRequestManager'
import { ResourcesListModal } from '../../ResourcesListModal'
import { Table } from '../../Table'
import { useTabs } from '../Tabs.utils'
import { ViewColumnsMap, pendingTableColumns } from '../table.utils'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'

const useSlices = createDataSelectorHook(['approvalReqList'], { disableReduxFetch: true })

function PendingTab({ searchKey = '' }) {
  const [selectedApprovalRequest, setSelectedApprovalRequest] = useState(null)
  const [showRsrcsListModal, setShowRsrcsListModal] = useState(false)

  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [showEditReqModal, setShowEditReqModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const { user } = useUser()
  const { appView } = useAppView()
  const { getCommonTableRowData } = useTabs()
  const { slices } = useSlices()

  const pendingReqs = useMemo(() => {
    return [...slices.approvalReqList].reverse().filter(e => e.Status === 'Pending')
  }, [slices.approvalReqList])

  const getColumns = () => {
    const visibleColumns = ViewColumnsMap[appView].pending
    //@ts-ignore
    return pendingTableColumns().filter((e) => visibleColumns.includes(e.dataIndex))
  }

  const getAdminViewOptions = (r, noDelete = false) => {
    return [
      {
        title: 'Approve',
        twColor: '!text-blue-500',
        action() {
          setSelectedApprovalRequest(r)
          setShowApprovalModal(true)
        }
      },
      {
        title: 'Reject',
        twColor: '!text-red-500',
        action() {
          setSelectedApprovalRequest(r)
          setShowRejectionModal(true)
        }
      }
    ]
  }

  const getUserViewRowOptions = (r) => {
    const options = []
    if (!isApprovalRequestCreatedByUser(user, r)) return getAdminViewOptions(r, true)
    if (!isServiceAccountRequest(r))
      options.push({
        title: 'Edit',
        twColor: '!text-blue-500',
        action() {
          setSelectedApprovalRequest(r)
          setShowEditReqModal(true)
        }
      })
    return [
      ...options,
      {
        title: 'Cancel',
        twColor: '!text-red-500',
        action() {
          setSelectedApprovalRequest(r)
          setShowCancelModal(true)
        }
      }
    ]
  }

  const rowDataAdapterFn = (r) => {
    const data = getCommonTableRowData(r)

    data.resources.onClick = () => {
      setSelectedApprovalRequest(r)
      setShowRsrcsListModal(true)
    }

    return {
      ...data,
      moreOptions: appView === 'admin' ? getAdminViewOptions(r) : getUserViewRowOptions(r)
    }
  }

  const handleModalViewChange = ({ showApprove, showReject }) => {
    setShowApprovalModal(showApprove)
    setShowRejectionModal(showReject)
  }

  useEffect(() => {
    reduxApiClient('approvalreqs').getByDataParams({ Status: APPROVED_STATUS.Pending })
  }, [])

  return (
    <div>
      <ApprovalRequestManager
        onModalsViewChange={handleModalViewChange}
        approvalRequest={selectedApprovalRequest}
        views={{
          showDelete: false,
          showDeleteAutoApprove: false,
          showApprove: showApprovalModal,
          showReject: showRejectionModal,
          showClose: false
        }}
      />
      <Table
        getColumns={getColumns}
        data={pendingReqs}
        rowDataAdapterFn={rowDataAdapterFn}
        searchKey={searchKey}
      />
      {selectedApprovalRequest && (
        <ResourcesListModal
          // @ts-ignore
          approvalReq={selectedApprovalRequest}
          onClose={() => setShowRsrcsListModal(false)}
          showModal={showRsrcsListModal}
        />
      )}
      {selectedApprovalRequest && showEditReqModal && (
        <EditApprovalRequest
          approvalReq={selectedApprovalRequest}
          onCancel={() => setShowEditReqModal(false)}
          onSuccess={() => {
            setShowEditReqModal(false)
            enqueueNotification('Access Request has been updated successfully!', 'info')
          }}
        />
      )}
      {selectedApprovalRequest && showCancelModal && (
        <CancelApprovalRequestModal
          approvalReq={selectedApprovalRequest}
          onCancel={() => setShowCancelModal(false)}
          onSuccess={() => setShowCancelModal(false)}
        />
      )}
    </div>
  )
}

export { PendingTab }
