import { AWSDisplayTypeToIconTypeMap } from 'Components/CustomIcons/AWSResources'
import { AzureDisplayTypeToIconTypeMap } from 'Components/CustomIcons/AzureResources'
import { GCPDisplayTypeToIconTypeMap } from 'Components/CustomIcons/GCPResources'
import { _ } from 'Core'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import { SALESFORCE_TYPE_ICON_NAME, UIApplicationTypeIconMap, getApplicationType } from 'features/applications'
import { BUNDLE_TYPE_ICON_TYPE } from 'features/bundles'
import { uiGetAccountTargetIconType } from 'features/clouds'
import { createRsrcKey, getResourceName } from 'features/resources'
import { isDynamicAppRole, isPrivateServer } from 'features/targets'
import { Diagram } from 'procyon-ui'
import React from 'react'

/**
 *
 * @typedef {{ label:string, id:string, onClick:(() => void) }} NodeAction
 * @typedef {{ label:'', description: '' }} NodeAttributes
 * @param {{
 * preDataNodeObjects?: {
 *  id: string
 *  key: string
 *  type: import('procyon-ui').TargetIconProps['type']
 *  label: string
 *  description: string
 * }[][]  //* This objects are in pre-seeded format to directly push into the nodes of the graph
 * nodeObjects     :    any[][],
 * getKey          ?: ((rsrc:any, rowIndex: number) => string | null)
 * relationObject  ?:   Object<any, string[]>,
 * actionsObj      ?:   Object<any, NodeAction[]>           // Actions for every nodes on graph - "object key" is the rsrc key
 * nodeAttributesObject?: Object<any, NodeAttributes>       // Used to override the properties of nodes
 * }} param0
 * @returns
 */
function RelationAccessGraph({
  preDataNodeObjects,
  nodeObjects,
  relationObject,
  actionsObj = {},
  nodeAttributesObject = {},
  getKey
}) {
  const getRsrcActions = (key) => {
    return actionsObj[key] ?? []
  }

  const getNodes = () => {
    const nodes = []
    nodeObjects &&
      nodeObjects.forEach((objects, index) => {
        const nodeColumnsData = []
        objects.forEach((nodeRsrc, i) => {
          const data = getNodeData(nodeRsrc)
          if (typeof getKey === 'function') {
            const k = getKey(nodeRsrc, index)
            data.id = k === null ? data.id : k
            data.key = data.id
          }
          const overridingProps = nodeAttributesObject[data.key] || {}
          nodeColumnsData.push({
            ...data,
            // * override values
            ...overridingProps,
            actions: getRsrcActions(createRsrcKey(nodeRsrc))
          })
        })

        if (preDataNodeObjects && preDataNodeObjects[index]?.length)
          nodeColumnsData.push(...preDataNodeObjects[index])

        nodes.push(nodeColumnsData)
      })

    return nodes
  }
  /**
   *
   * @returns A map object with key of all the nodes on the graph
   */
  const getNodesKeyMap = () => {
    const map = {}
    const nodes = getNodes()
    nodes.forEach((n) => n.forEach((e) => (map[e.key] = true)))
    return map
  }

  /**
   * Get the arrows data pointed from <key> to multiple keys[] - one-to-many
   * @returns
   */
  const getArrows = () => {
    const arrows = []
    const nodesKeyMap = getNodesKeyMap()
    if (!relationObject) return []
    for (const key in relationObject) {
      const linkKeys = relationObject[key]
      linkKeys.forEach((linkKey) => {
        /**
         * If node doesn't exist for arrow to point then, dont create arrow
         */
        if (!nodesKeyMap[linkKey] || !nodesKeyMap[key]) return
        // Arrow from `key` to many keys
        arrows.push({
          startID: key,
          endID: linkKey
        })
      })
    }
    return arrows
  }

  const style = useMemoOnce({
    height: '100vh',
    minHeight: '600px',
    overflow: 'auto',
    maxWidth: '100%'
  })

  return (
    <div>
      <Diagram containerStyle={style} arrows={getArrows()} data={getNodes()} />
    </div>
  )
}

const getNodeData = (node) => {
  const common = {
    id: createRsrcKey(node),
    value: createRsrcKey(node),
    key: createRsrcKey(node)
  }
  switch (node.ObjectMeta.Kind) {
    case 'Application':
      return {
        description: getApplicationType(node),
        label: getResourceName(node),
        type: UIApplicationTypeIconMap[getApplicationType(node)],
        ...common
      }
    case 'AwsResource':
      return {
        description: node.Spec.DisplayType,
        label: getResourceName(node),
        type: AWSDisplayTypeToIconTypeMap[node.Spec.Type] || 'AWS_APPLICATION',
        ...common
      }
    case 'AzureResource':
      return {
        description: node.Spec.DisplayType,
        label: getResourceName(node),
        type: AzureDisplayTypeToIconTypeMap[node.Spec.DisplayType] || 'AZURE_APPLICATION',
        ...common
      }
    case 'GcpResource':
      return {
        description: node.Spec.DisplayType,
        label: getResourceName(node),
        type: GCPDisplayTypeToIconTypeMap[node.Spec.DisplayType] || 'GCP',
        ...common
      }
    case 'Server':
      return {
        description: getResourceName(node),
        label: getResourceName(node),
        type: isPrivateServer(node) ? 'SERVER_PRIVATE' : 'SERVER',
        ...common
      }
    case 'AppRole':
      return {
        description: getResourceName(node),
        label: getResourceName(node),
        type: isDynamicAppRole(node) ? 'APPROLE_DYNAMIC' : 'APPROLE',
        ...common
      }
    case 'Database':
      return {
        description: getResourceName(node),
        label: getResourceName(node),
        type: 'DATABASE',
        ...common
      }
    case 'KubeNamespace':
      return {
        description: getResourceName(node),
        label: getResourceName(node),
        type: `KUBE_NAMESPACES`,
        ...common
      }
    case 'IamAction':
      return {
        label: getResourceName(node),
        description: node.Spec.Description?.substring(0, 40) || '',
        type: 'ROLE',
        ...common
      }
    case 'PacPolicy':
      return {
        description: node.ObjectMeta.Name,
        label: node.GivenName,
        type: 'POLICY',
        ...common
      }
    case 'User':
      return {
        description: node.ObjectMeta.Name,
        label: getResourceName(node),
        type: 'USER',
        ...common
      }
    case 'Group':
      return {
        description: node.ObjectMeta.Name,
        label: getResourceName(node),
        type: 'USERGROUPS',
        ...common
      }
    case 'ServiceAccount':
      return {
        description: node.ObjectMeta.Name,
        label: getResourceName(node),
        type: 'SERVICEACCOUNT',
        ...common
      }
    case 'WorkloadIdentity':
      return {
        description: node.Spec.Description,
        label: getResourceName(node),
        type: 'WORKLOAD',
        ...common
      }
    case 'GithubResource':
      return {
        description: node.ObjectMeta.Name,
        label: getResourceName(node),
        type: 'GITHUB_REPOSITORIES_MONO',
        ...common
      }
    case 'RDPServer':
      return {
        description: '',
        label: getResourceName(node),
        type: 'RDP_SERVER',
        ...common
      }
    case 'Account':
      return {
        description: node.Spec.Description,
        label: getResourceName(node),
        type: uiGetAccountTargetIconType(node),
        ...common
      }
    case 'ReqBundle':
      return {
        description: node.Spec.Description,
        label: getResourceName(node),
        type: BUNDLE_TYPE_ICON_TYPE[node.Type],
        ...common
      }
    case 'KubeCluster':
      return {
        description: '',
        label: getResourceName(node),
        type: 'KUBE_CLUSTERS',
        ...common
      }
    case 'Kafka':
      return {
        description: '',
        label: getResourceName(node),
        type: 'APACHE_KAFKA',
        ...common
      }
    case 'CRMEntity':
      const type = node.Type
      const iconType = SALESFORCE_TYPE_ICON_NAME[type] || 'SALESFORCE'
      return {
        description: node.Type,
        label: getResourceName(node),
        type: iconType,
        ...common
      }
    default:
      return {
        description: '',
        label: getResourceName(node),
        type: 'APACHE_KAFKA',
        ...common
      }
  }
}

export { RelationAccessGraph }
