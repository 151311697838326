import React from 'react'
import { useHistory } from 'react-router'
import { ApproveRequestModal } from './Components/ApproveRequestModal'
import { DeleteAutoApprovePolicyModal } from './Components/DeletePAAPolicy'
import { DeleteRequestModal } from './Components/DeleteRequestModal'
import { RejectRequestModal } from './Components/RejectRequestModal'
import useAppView from 'Core/Hooks/useAppView'
import { CloseRequestModal } from './Components/CloseApprovalRequest'

/**
 *
 * @param {{paaPolicy?:any, approvalRequest?: any, views: {
 * showApprove: boolean,
 * showReject: boolean,
 * showDelete: boolean,
 * showClose: boolean,
 * showDeleteAutoApprove:boolean },
 * onModalsViewChange: ((data: {
 * showApprove: boolean,
 * showReject: boolean,
 * showDelete: boolean,
 * showClose: boolean,
 * showDeleteAutoApprove:boolean
 * }) => void) }} param0
 * @returns
 */
function ApprovalRequestManager({ approvalRequest, views, onModalsViewChange, paaPolicy }) {
  const history = useHistory()
  const { appView } = useAppView()

  const handleModalViewChange = ({
    showDelete,
    showApprove,
    showReject,
    showClose,
    showDeleteAutoApprove
  }) => {
    onModalsViewChange?.({ showDelete, showApprove, showReject, showDeleteAutoApprove, showClose })
  }

  return (
    <div>
      {approvalRequest && views.showApprove && (
        <ApproveRequestModal
          handleCancel={() => handleModalViewChange({ ...views, showApprove: false })}
          showApprovalModal={views.showApprove}
          approvalRequest={approvalRequest}
          afterApproval={() => handleModalViewChange({ ...views, showApprove: false })}
        />
      )}

      {approvalRequest && views.showReject && (
        <RejectRequestModal
          onCancel={() => handleModalViewChange({ ...views, showReject: false })}
          showModal={views.showReject}
          approvalRequest={approvalRequest}
          afterRejection={() => {
            handleModalViewChange({ ...views, showReject: false })
          }}
        />
      )}

      {approvalRequest && views.showDelete && (
        <DeleteRequestModal
          onCancel={() => handleModalViewChange({ ...views, showDelete: false })}
          showModal={views.showDelete}
          approvalRequest={approvalRequest}
          afterDelete={() => {
            handleModalViewChange({ ...views, showDelete: false })
            history.push(`/${appView}/access-requests`)
          }}
        />
      )}

      {approvalRequest && views.showClose && (
        <CloseRequestModal
          onCancel={() => handleModalViewChange({ ...views, showClose: false })}
          approvalRequest={approvalRequest}
          afterClose={() => {
            handleModalViewChange({ ...views, showClose: false })
          }}
        />
      )}

      {paaPolicy && views.showDeleteAutoApprove && (
        <DeleteAutoApprovePolicyModal
          onCancel={() => handleModalViewChange({ ...views, showDeleteAutoApprove: false })}
          showModal={views.showDeleteAutoApprove}
          paaPolicy={paaPolicy}
          afterDelete={() => {
            handleModalViewChange({ ...views, showDeleteAutoApprove: false })
          }}
        />
      )}
    </div>
  )
}

export { ApprovalRequestManager }
