import { TabGroup, TextInput } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { UsersTable, GroupsTable, RolesTable } from './Components/Tabs'
import { FederatedUserTable } from './Components/Tabs/FederatedUserTable'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const Remediations = () => {
  const [searchQuery, setSearchQuery] = useState('')

  // Initialize activeTab with localStorage value or default to 0 if not found
  const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTab')) || 0)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    const storedTabIndex = parseInt(localStorage.getItem('activeTab'))
    if (!isNaN(storedTabIndex)) {
      setActiveTab(storedTabIndex)
    }
  }, [])

  const TAB_NAME_MAP = {
    0: 'users',
    1: 'groups',
    2: 'roles',
    3: 'federated-user'
  }

  const TAB_INDEX_MAP = {
    users: 0,
    groups: 1,
    roles: 2,
    'federated-user': 3
  }

  const handleTabChange = (v) => {
    setSearchQuery('')
    setActiveTab(v)
    localStorage.setItem('activeTab', v)
  }

  return (
    <>
      <div className=''>
        <div className='absolute right-[20px] w-[280px] z-10'>
          <TextInput
            style={{
              width: '280px'
            }}
            icon={faSearch}
            iconPosition='start'
            value={searchQuery}
            onChange={handleSearchChange}
            onClickEndIcon={function noRefCheck() {}}
            placeholder='Search'
          />
        </div>
        <TabGroup
          tabs={[
            {
              label: 'Users',
              tabContent: <UsersTable searchQuery={searchQuery} />
            },
            {
              label: 'Groups',
              tabContent: <GroupsTable searchQuery={searchQuery} />
            },
            {
              label: 'Roles',
              tabContent: <RolesTable searchQuery={searchQuery} />
            },
            {
              label: 'Federated User',
              tabContent: <FederatedUserTable searchQuery={searchQuery} />
            }
          ]}
          controllable={true}
          activeIndex={activeTab}
          onChangeTab={(_, v) => {
            handleTabChange(v)
          }}
        />
      </div>
    </>
  )
}

export { Remediations }
