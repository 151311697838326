import { enqueueNotification } from 'Utils/Helpers'
import { Typography } from 'procyon-ui'
import React from 'react'

const CopyText = ({
  text,
  messageKey,
  Component,
  showText = false,
  maxCharLen = 1024,
  maskText = false,
  withoutWrapperComponent = false
}) => {
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(text)
      enqueueNotification(`${messageKey} copied to clipboard!`, 'info')
    } catch (error) {
      console.error('error in handleCopy: ', error)
    }
  }


  if(withoutWrapperComponent) return React.cloneElement(Component, { onClick: handleCopy })

  return (
    <div className='flex justify-between gap-4 items-center'>
      {showText && (
        <Typography className='break-all' variant='body-regular'>
          {maskText ? '**************************************' : text.slice(0, maxCharLen)}
        </Typography>
      )}
      <div className='self-start'>{React.cloneElement(Component, { onClick: handleCopy })}</div>
    </div>
  )
}

export { CopyText }
