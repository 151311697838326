import { errorHandler } from '../Utils/Helpers'

export default class serverApis {
  static async getFavoriteServerList () {
    try {
      const response = { data: { Favorites: [] } }
      const favorites = localStorage.getItem('favoritesIAM')
      if (favorites) {
        const parsedFavorites = JSON.parse(favorites)
        response.data = { Favorites: parsedFavorites }
      }
      return response
    } catch (error) {
      errorHandler(error, 'getapprolessList', 'approlesApis.js')
    }
  }

  static async setFavoriteServerList (id) {
    try {
      let data = []
      const storedFavorites = localStorage.getItem('favoritesIAM')
      if (storedFavorites) {
        data = JSON.parse(storedFavorites)
      }
      if (data.includes(id)) {
        data = data.filter((e) => e !== id)
      } else {
        data = [...data, id]
      }
      const stringifiedFavorites = JSON.stringify(data)
      localStorage.setItem('favoritesIAM', stringifiedFavorites)
      return data
    } catch (error) {
      errorHandler(error, 'getapprolessList', 'approlesApis.js')
    }
  }
}
