import React from 'react'
import { LabelContent } from './LabelContent'
import { Label, SwitchBox } from 'procyon-ui'

const SwitchBoxLabelContent = ({ editMode, value = false, label, onChange }) => {
  return (
    <LabelContent
      content={
        <div>
          {editMode && (
            <SwitchBox
              label={value ? 'Enabled' : 'Disabled'}
              onChange={(_, c) => onChange(c)}
              checked={value}
            />
          )}
          {!editMode &&
            (value ? (
              <Label text='Enabled' variant='success' />
            ) : (
              <Label text='Disabled' variant='gray' />
            ))}
        </div>
      }
      title={label}
    />
  )
}

export { SwitchBoxLabelContent }
