import useAppView from 'Core/Hooks/useAppView'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { ResourcesListModal } from 'V2Components'
import { useIAMRoleAccess } from 'features/iamRoles'
import { createRsrcKey, getResourceName, getRsrcURL, reverseRsrcKey } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import { ElementRow, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'
import { useHistory } from 'react-router'

const IAMRoleSummaryModal = ({ onClose, iamrole, tabIndex = 0 }) => {
  const history = useHistory()
  const { appView } = useAppView()
  const { getIAMRoleAttachedPolicys, getIAMRoleAttachedRsrcs } = useIAMRoleAccess()
  const { getObjectRef } = useObjectRef([
    'gcpResources',
    'azureResources',
    'awsResources',
    'policyList'
  ])

  const getPolicys = () => {
    const policyRefs = getIAMRoleAttachedPolicys(iamrole).map(reverseRsrcKey)
    return getObjectRef(policyRefs)
  }
  
  const getResources = () => {
    const rsrcRefs = getIAMRoleAttachedRsrcs(iamrole).map(reverseRsrcKey)
    return getObjectRef(rsrcRefs)
  }

  useHTMLBodyScrollbar(true)

  return (
    <div>
      <ResourcesListModal
        initialTabIndex={tabIndex}
        onViewDetailsClick={() => {
          history.push(getRsrcURL(iamrole, appView))
        }}
        showModal
        title={getResourceName(iamrole)}
        caption={''}
        onClose={onClose}
        resources={getResources()}
        hideEntitiesTab
        extendedTabs={[
          {
            label: 'Actions',
            tabContent: (
              <div className='h-[400px] overflow-auto'>
                {iamrole.Spec.Actions.Elems.map((e) => (
                  <Typography variant='body-regular' className='!text-[#8D94A1]' key={e}>
                    {e}
                  </Typography>
                ))}
              </div>
            )
          },
          {
            label: 'Policies',
            tabContent: (
              <div className='h-[400px] overflow-auto'>
                {getPolicys().map((e) => (
                  <ElementRow
                    style={{ padding: 4, paddingLeft: 8 }}
                    icon={<TargetIcon type='POLICY' />}
                    key={createRsrcKey(e)}
                    title={getResourceName(e)}
                    showButton={false}
                  />
                ))}
              </div>
            )
          }
        ]}
      />
    </div>
  )
}

export { IAMRoleSummaryModal }
