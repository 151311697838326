import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { TreeViewExtended } from 'V2Components'
import { isAccountResource } from 'features/clouds'
import { IAMResourcesSliceNames } from 'features/iamResources/utils'
import { createRsrcKey, getRsrcAccountRef, reverseRsrcKey } from 'features/resources'
import React from 'react'

const IAMRsrcsTreeView = ({ rsrcs, renderNodeFn, excludedAccountKeys = [] }) => {
  const { getObjectRef } = useMultiSlice(['accountList', ...IAMResourcesSliceNames])

  const accounts = (() => {
    const list = []

    rsrcs.forEach((r) => {
      const accRef = getRsrcAccountRef(r)
      if (accRef && getObjectRef(accRef)) list.push(createRsrcKey(accRef))
    })

    return [...new Set(list)].map(reverseRsrcKey).map(getObjectRef)
  })()

  const getAccountRsrcs = (acc) => rsrcs.filter((g) => isAccountResource(acc, g))

  const createParentToChildrenMap = ({ account, rsrcs }) => {
    const map = {}
    const accountKey = createRsrcKey(account)

    rsrcs.forEach((rsrc) => {
      const key = createRsrcKey(rsrc)
      const parentKey = createRsrcKey(rsrc.Spec.Parent)
      /**
       * Instead of trying to find the childrens of each resource,
       * we update the parent with the current resource .
       *
       * In short, every resource has a parent which gets updated with the current resource as children.
       */
      if (parentKey === null) {
        const parentChildrens = map[accountKey] || []
        parentChildrens.push(key)
        map[accountKey] = parentChildrens
        return
      } else {
        const parentChildrens = map[parentKey] || []
        parentChildrens.push(key)
        map[parentKey] = parentChildrens
      }
    })

    return map
  }

  return (
    <>
      {accounts.map((acc, i) => {
        const accountKey = createRsrcKey(acc)
        if (excludedAccountKeys.includes(accountKey)) return null
        const rsrcs = getAccountRsrcs(acc)
        const tlr = rsrcs.filter((r) => r.Spec.Parent.RefKind === '' && r.Spec.Parent.RefID === '0')
        const parentChildrenMap = createParentToChildrenMap({ account: acc, rsrcs })
        const getChildrenNodes = (nodeKey) => parentChildrenMap[nodeKey] || []
        return (
          <div key={createRsrcKey(acc)} className='-ml-3 border-b py-2'>
            <TreeViewExtended
              renderRawUI
              selectedNodeKey=''
              rootNode={[createRsrcKey(acc), []]}
              rootChildrens={tlr.map(createRsrcKey)}
              getChildrenNodes={getChildrenNodes}
              renderNodeFn={renderNodeFn}
              onNodeClick={() => {}}
            />
          </div>
        )
      })}
    </>
  )
}

export { IAMRsrcsTreeView }
