import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback, ProcyonLogoIcon } from 'V2Components'
import { AWS_CLOUD_REGIONS } from 'features/clouds'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import { Button, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'

import React, { useRef, useState } from 'react'

const useSlices = createDataSelectorHook(['tenantprofiles'])

const RDPStorageConfigModal = ({ onCancel }) => {
  const [configType, setConfigType] = useState('Default') // "AWS", "Azure", "GCP", "Default" - now only "Default" and "AWS" is supported
  const [awsUploadConfig, setAwsUploadConfig] = useState({
    CredsType: 'Static', // "Static", "Role"
    StaticCredentials: {
      AccessKey: '',
      SecretKey: ''
    },
    RoleCredentials: {
      RoleARN: '',
      ExternalID: ''
    },
    S3Bucket: '',
    Region: ''
  })

  const [isLoading, setIsLoading] = useState(false)

  const { slices } = useSlices()
  const rdpConfigInitialized = useRef(false)

  const { user } = useUser()

  const currentTenantProfile = slices.tenantprofiles.find(
    (e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant
  )

  if (!rdpConfigInitialized.current && currentTenantProfile) {
    rdpConfigInitialized.current = true
    const config = currentTenantProfile.Spec.RDPUploadConfig
    //Update config type
    if (config.Type === 'Invalid') setConfigType('Default')
    else setConfigType(config.Type)

    if (config.Type === 'AWS') {
      const awsConfig = config.AWSUploadConfig
      if (awsConfig.CredsType !== 'Invalid') setAwsUploadConfig(awsConfig)
    }
  }

  const handleConfigSave = async () => {
    setIsLoading(true)
    try {
      const tenantProfileClone = structuredClone(currentTenantProfile)

      tenantProfileClone.Spec.RDPUploadConfig.Type = configType

      // Only update the fields which are selected in ui in order to prevent unwanted fields update
      if (configType === 'AWS') {
        tenantProfileClone.Spec.RDPUploadConfig.AWSUploadConfig.CredsType =
          awsUploadConfig.CredsType

        tenantProfileClone.Spec.RDPUploadConfig.AWSUploadConfig.Region = awsUploadConfig.Region
        tenantProfileClone.Spec.RDPUploadConfig.AWSUploadConfig.S3Bucket = awsUploadConfig.S3Bucket

        if (awsUploadConfig.CredsType === 'Static') {
          tenantProfileClone.Spec.RDPUploadConfig.AWSUploadConfig.StaticCredentials =
            awsUploadConfig.StaticCredentials
        } else if (awsUploadConfig.CredsType === 'Role') {
          tenantProfileClone.Spec.RDPUploadConfig.AWSUploadConfig.RoleCredentials =
            awsUploadConfig.RoleCredentials
        }
      }

      await reduxApiClient('tenantprofiles').update(tenantProfileClone)
      enqueueNotification('RDP Config Successfully Updated', 'info')
    } catch (error) {
      enqueueNotification('Unable to update RDP Config.', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const getErrors = () => {
    if (configType === 'AWS') {
      if (awsUploadConfig.CredsType === 'Static') {
        if (!awsUploadConfig.StaticCredentials.AccessKey.trim()) return 'Access Key is required!'
        if (!awsUploadConfig.StaticCredentials.SecretKey.trim()) return 'Secret Key is required!'
      }
      if (awsUploadConfig.CredsType === 'Role') {
        if (!awsUploadConfig.RoleCredentials.RoleARN.trim()) return 'Role ARN is required!'
        if (!awsUploadConfig.RoleCredentials.ExternalID.trim()) return 'External ID is required!'
      }
    }
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>Configure RDP Storage</Typography>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
      </div>
      <div className='mt-4'>
        <Typography variant='buttonLabel-regular'>Cloud Storage</Typography>
        <SelectDropDown
          //change disabled until the config is loaded
          disabled={!rdpConfigInitialized.current}
          sx={{ width: '200px', marginTop: '6px' }}
          menuItems={[
            {
              label: 'Procyon Storage Bucket',
              value: 'Default'
            },
            {
              label: 'AWS',
              value: 'AWS'
            }
          ]}
          onChange={(e) => {
            setConfigType(e.target.value)
          }}
          value={configType}
        />
        {configType === 'Default' && (
          <div className='flex items-center mt-6'>
            <Typography variant='body-regular' className='flex items-center'>
              The RDP Recordings are maintained and stored by
              <ProcyonLogoIcon className='w-4 h-4 mx-1' /> Procyon
            </Typography>
          </div>
        )}
        {configType === 'AWS' && (
          <div>
            <div className='flex justify-between items-end'>
              <div>
                <Typography className='mt-4' variant='buttonLabel-regular'>
                  AWS Region
                </Typography>
                <SelectDropDown
                  sx={{ width: '280px', marginTop: '6px' }}
                  menuItems={AWS_CLOUD_REGIONS.map((e) => ({ label: e, value: e }))}
                  onChange={(e) => {
                    setAwsUploadConfig((s) => ({ ...s, Region: e.target.value }))
                  }}
                  value={awsUploadConfig.Region}
                />
              </div>
              <div>
                <Typography variant='buttonLabel-regular'>AWS Credential Type</Typography>
                <SelectDropDown
                  sx={{ width: '280px', marginTop: '6px' }}
                  menuItems={[
                    {
                      label: 'Static Credentials',
                      value: 'Static'
                    },
                    {
                      label: 'Role',
                      value: 'Role'
                    }
                  ]}
                  onChange={(e) => {
                    setAwsUploadConfig((s) => ({ ...s, CredsType: e.target.value }))
                  }}
                  value={awsUploadConfig.CredsType}
                />
              </div>
            </div>
            {awsUploadConfig.CredsType === 'Static' && (
              <div className='mt-4 flex flex-col'>
                <TextInput
                  value={awsUploadConfig.StaticCredentials.AccessKey}
                  onChange={(e) => {
                    const text = e.target.value
                    setAwsUploadConfig((s) => {
                      const newS = structuredClone(s)
                      newS.StaticCredentials.AccessKey = text
                      return newS
                    })
                  }}
                  type='password'
                  className='mb-2'
                  label='Access Key*'
                />
                <TextInput
                  value={awsUploadConfig.StaticCredentials.SecretKey}
                  onChange={(e) => {
                    const text = e.target.value
                    setAwsUploadConfig((s) => {
                      const newS = structuredClone(s)
                      newS.StaticCredentials.SecretKey = text
                      return newS
                    })
                  }}
                  type='password'
                  label='Secret Key*'
                />
              </div>
            )}
            {awsUploadConfig.CredsType === 'Role' && (
              <div className='mt-4 flex flex-col'>
                <TextInput
                  value={awsUploadConfig.RoleCredentials.RoleARN}
                  onChange={(e) => {
                    const text = e.target.value
                    setAwsUploadConfig((s) => {
                      const newS = structuredClone(s)
                      newS.RoleCredentials.RoleARN = text
                      return newS
                    })
                  }}
                  className='mb-2'
                  label='Role ARN*'
                />
                <TextInput
                  value={awsUploadConfig.RoleCredentials.ExternalID}
                  onChange={(e) => {
                    const text = e.target.value
                    setAwsUploadConfig((s) => {
                      const newS = structuredClone(s)
                      newS.RoleCredentials.ExternalID = text
                      return newS
                    })
                  }}
                  label='External ID*'
                />
              </div>
            )}
          </div>
        )}
        <div className='flex gap-2 justify-end items-center mt-4'>
          {getErrors() && <Label text={getErrors()} variant='warning' />}
          <Button disabled={!!getErrors()} onClick={handleConfigSave} variant='primary'>
            Save
          </Button>
        </div>
        <LoadingFeedback loading={isLoading} message='Updating RDP Upload Configuration' />
      </div>
    </FullScreenContentModal>
  )
}

export { RDPStorageConfigModal }
