import { FilterBarCategory, FilterBarItem, FilterBarResources } from 'procyon-ui'
import React from 'react'

function CheckboxFilters({ filterItems = [], activeFilters = [], onActiveFilterChange, title }) {
  const toggleResourceTypeFilter = (filter) => {
    let newFilters = []
    if (activeFilters.includes(filter)) newFilters = activeFilters.filter((e) => e !== filter)
    else newFilters = [filter, ...activeFilters]
    onActiveFilterChange && onActiveFilterChange(newFilters)
  }

  return (
    <FilterBarResources title={title}>
      {filterItems.map((e) => (
        <>
          <FilterBarCategory key={e.label} name={e.label}>
            {e.filters.map((filter) => (
              <FilterBarItem
                key={filter.value}
                amount={filter.amount?.toString()}
                name={filter.label}
                checked={activeFilters.includes(filter.value)}
                onClick={() => toggleResourceTypeFilter(filter.value)}
              />
            ))}
          </FilterBarCategory>
        </>
      ))}
    </FilterBarResources>
  )
}

export { CheckboxFilters }
