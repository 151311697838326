import { faBrowser, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { getPolicyIssuedToEntities, getPolicyResourcesRefs } from 'features/policy'
import { createRsrcKey, dateSortComparator } from 'features/resources'
import moment from 'moment'
import { Button } from 'procyon-ui'
import React from 'react'

export const tableDataAdapter = ({ policys = [], handleResourcesClick }) => {
  return policys.map((policy) => {
    return {
      id: createRsrcKey(policy),
      name: policy.GivenName,
      resources: {
        count: getPolicyResourcesRefs(policy).length,
        onClick: () => handleResourcesClick?.(policy)
      },
      entities: {
        count: getPolicyIssuedToEntities(policy).length,
        onClick: () => handleResourcesClick?.(policy)
      },
      submittedOn: moment(policy.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A'),
      expiresOn: policy.NotAfter,
      createdBy: policy.Creator
    }
  })
}

export const tableColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 250
  },
  {
    field: 'resources',
    headerName: 'Resources',
    width: 130,
    sortComparator: (v1, v2) => v2.count - v1.count,
    renderCell({ value }) {
      return (
        <Button onClick={value.onClick} icon={faBrowser} variant='grayBlue'>
          {value.count}
        </Button>
      )
    }
  },
  {
    field: 'entities',
    headerName: 'Entities',
    sortComparator: (v1, v2) => v2.count - v1.count,
    renderCell({ value }) {
      return (
        <Button onClick={value.onClick} icon={faUsers} variant='grayBlue'>
          {value.count}
        </Button>
      )
    },
    width: 130
  },
  {
    field: 'submittedOn',
    headerName: 'Submitted on',
    width: 240
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 240
  },
  {
    field: 'expiresOn',
    headerName: 'Expires In',
    width: 240,
    sortComparator: dateSortComparator,
    renderCell({ value }) {
      return moment(value).fromNow()
    }
  }
]
