//* ====================================/ AZURE Specific Utils /====================================//

import { isValidAccountCredentials } from './utils'

// * AZURE Cloud Account's Service Account Permissions list

// Common permissions which are must
export const AZURE_MUST_SA_PERMISSIONS = [
  'Directory.Read.All',
  'Application.ReadWrite.OwnedBy',
  'RoleManagement.ReadWrite.Directory'
]
// Permissions which are required when Procyon is managing the ID Provider
export const AZURE_AUTO_ID_PROVIDER_SA_PERMISSIONS = [
  'IdentityProvider.ReadWrite.All',
  'Domain.ReadWrite.All'
]

//Permissions which are required when the user wants to manually manage the ID Provider
export const AZURE_MANUAL_ID_PROVIDER_SA_PERMISSIONS = [
  'IdentityProvider.Read.All',
  'Domain.Read.All'
]

// Permissions required for managing users automatically by Procyon
export const AZURE_AUTO_USERS_PERMISSIONS = ['User.ReadWrite.All']

// Permission required when users wants to managed the users
export const AZURE_MANUAL_USERS_PERMISSIONS = ['User.Invite.All']

/**
 *
 * @param {{
 * tenantID?:string
 * appID?:string
 * appKey?:string }} param0
 * @returns
 */
export const checkAZUREAccountCredentials = async ({ tenantID, appID, appKey }) => {
  const payload = {
    ObjectMeta: {
      Name: 'azure-test'
    },
    Spec: {
      Automate: false,
      AzureSpec: {
        TenantID: tenantID.trim(),
        ProcyonAppID: appID.trim(),
        ProcyonAppKey: appKey.trim()
      },
      Description: '',
      Discovery: true,
      RemoteProxy: false,
      AccountType: 'AZURE'
    }
  }
  return await isValidAccountCredentials(payload)
}
