import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useApplicationAccess } from 'features/applications'
import { IAMResourcesSliceNames, useIAMRsrcAccess } from 'features/iamResources'
import { getPolicyAttachedIAMActions } from 'features/policy'
import { createRsrcKey, getResourceName, reverseRsrcKey } from 'features/resources'
import { TargetsSliceNames, useTargetAccess } from 'features/targets'
import { useUserDetailsContext } from 'features/users'

export const useAccessSummaryTab = () => {
  const { getObjectRef } = useMultiSlice([
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames,
    'iamActions',
    'applicationList',
    'policyList'
  ])

  const { user } = useUserDetailsContext()

  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const { getUserAccessTargetsMap } = useTargetAccess()
  const { getUserApplicationAccessMap } = useApplicationAccess()

  const iamAccessMap = getPolicySignInURLS(user)
  const targetsAccessMap = getUserAccessTargetsMap(user)
  const appsAccessMap = getUserApplicationAccessMap(user)

  const rsrcs = (() => {
    const resourceRefs = Object.keys({
      ...iamAccessMap,
      ...targetsAccessMap,
      ...appsAccessMap
    }).map(reverseRsrcKey)

    const resources = getObjectRef(resourceRefs)

    return resources
  })()

  const getIAMRsrcAttributesFromPolicys = (policys = []) => {
    // Creates a map of resource with actions
    const actions = policys.reduce((prev, policy) => {
      const iamActionsMap = getPolicyAttachedIAMActions(policy)
      for (const rsrcKey in iamActionsMap) {
        const addedActions = prev[rsrcKey] || []
        const acts = iamActionsMap[rsrcKey]
        addedActions.push(...acts)
        prev[rsrcKey] = addedActions
      }
      return prev
    }, {})

    // Remove any duplicate actions from the resource
    for (const rsrcKey in actions) {
      const map = {}
      const acts = actions[rsrcKey]
      actions[rsrcKey] = acts.filter((a) => {
        const key = createRsrcKey(a)
        if (!map[key]) {
          map[key] = true
          return true
        }
      })
    }

    return actions
  }

  // TODO! Simplyfiy this whole attribute flow - high complexity
  const getResourcesAttributes = () => {
    const iamPolicysRefs = Object.values(iamAccessMap).reduce((prev, current) => {
      const policyRefs = current.policys.map((e) => e.ref)
      return [...policyRefs, ...prev]
    }, [])
    const rsrcAttachedIAMActions = getIAMRsrcAttributesFromPolicys(getObjectRef(iamPolicysRefs))
    const rsrcAttributesObj = {}
    for (const rsrcKey in rsrcAttachedIAMActions) {
      const actionRefs = rsrcAttachedIAMActions[rsrcKey]
      const actionsObjects = getObjectRef(actionRefs)
      actionsObjects.forEach((action) => {
        const attrs = rsrcAttributesObj[rsrcKey] || []
        attrs.push(getResourceName(action))
        rsrcAttributesObj[rsrcKey] = attrs
      })
    }
    return rsrcAttributesObj
  }

  const policys = (() => {
    const policyMap = {}
    /**
     * Get all user's policys
     */
    const iamPolicysRefs = Object.values(iamAccessMap).reduce((prev, current) => {
      const policyRefs = current.policys.map((e) => e.ref)
      return [...policyRefs, ...prev]
    }, [])
    const targetPolicyRefs = Object.values(targetsAccessMap).reduce((p, c) => [...c, ...p], [])
    const appsPolicyRefs = Object.values(appsAccessMap).reduce((p, c) => [...c, ...p], [])
    /**
     * Filter out duplicate policy refs
     */
    const policyRefs = [...iamPolicysRefs, ...targetPolicyRefs, ...appsPolicyRefs].filter((ref) => {
      const pKey = createRsrcKey(ref)
      if (!policyMap[pKey]) {
        policyMap[pKey] = true
        return true
      }
    })
    return getObjectRef(policyRefs)
  })()

  return { rsrcs, policys, resourceActionsObj: getResourcesAttributes() }
}
