import {
  faCircleDot,
  faGrid,
  faHelmetSafety,
  faServer
} from '@fortawesome/pro-solid-svg-icons'
import { useDashboardDataProvider } from 'features/dashboard'
import { ResponsiveDonutChartCard } from 'procyon-ui'
import React from 'react'

const AccessDistribution = () => {
  const { heatMap, startDays } = useDashboardDataProvider()

  const getCountMap = () => {
    const map = {
      AppRole: 0,
      Server: 0,
      Application: 0,
      Others: 0,
      total: 0
    }
    const resources = Object.values(heatMap.resources)

    resources.forEach((data) => {
      const kind = data.rsrc && data.rsrc.ObjectMeta.Kind
      if (map[kind] !== undefined) map[kind] = map[kind] + 1
      else map.Others = map.Others + 1
      map.total += 1
    })

    return map
  }

  const getCountData = () => {
    const countsMap = getCountMap()
    const data = [
      {
        caption: 'App Roles',
        color: '#658bd3',
        icon: faHelmetSafety,
        percent: Math.round((countsMap.AppRole / countsMap.total) * 100) || 0,
        type: 'Data Cloud',
        value: countsMap.AppRole
      },
      {
        caption: 'Applications',
        color: '#94bd91',
        icon: faGrid,
        percent: Math.round((countsMap.Application / countsMap.total) * 100) || 0,
        type: 'Targets',
        value: countsMap.Application
      },
      {
        caption: 'Servers',
        color: '#e4b761',
        icon: faServer,
        percent: Math.round((countsMap.Server / countsMap.total) * 100) || 0,
        type: 'Resources',
        value: countsMap.Server
      },
      {
        caption: 'Others',
        color: '#a6abb6',
        icon: faCircleDot,
        percent: Math.round((countsMap.Others / countsMap.total) * 100) || 0,
        type: 'Applications',
        value: countsMap.Others
      }
    ]

    return data.sort((a, b) => b.value - a.value)
  }

  const data = getCountData()

  return (
    <div>
      <ResponsiveDonutChartCard
        caption={`by resource type last ${startDays} days`}
        items={data}
        title='Access Distribution'
        width='100%'
      />
    </div>
  )
}

export { AccessDistribution }
