import React from 'react'

/**
 *
 * @param {{on: boolean, children?:any, fallback?: any}} param0
 * @returns
 */
const MountOn = ({ on, children, fallback }) => {
  /*
   * IMPORTANT: The `children` is rendered/evaluated nonetheless, so passing a function call as a children,
   * Ex, children = <>{renderMyComponent()}</> would be invoked always before mounting. Make very sure of it to prevent crashing becuase of undfined values.
   * To overcome this, use conditional rendering not this component.
   */
  if (on) {
    return <>{children}</>
  }
  if (fallback) return <> {fallback}</>
  return null
}

export default MountOn
