import { getPolicyAttachedIAMActions, getPolicyResourcesRefs } from 'features/policy'
import { createRsrcKey } from 'features/resources'

/**
 * Create a map object which points from iam action to resource
 * @param {any[]} policys
 * @returns A map object which points from: policy -> iam actions -> respective associated iam resources
 */
export const createIamActionToRsrcRelation = (policys = []) => {
  const map = {}
  policys.forEach((policy) => {
    const pKey = createRsrcKey(policy)
    //* Pointer from policy to actions
    const policyToActions = map[pKey] || []
    const actionsObj = getPolicyAttachedIAMActions(policy)
    // Adding policy pointer to every actions node
    for (const key in actionsObj) {
      const actions = actionsObj[key]
      // Adding actions pointer to resource node
      actions.forEach((act) => {
        const aKey = createRsrcKey(act)
        //* Pointer from actions to resources
        const rsrcRefs = map[aKey] || []
        // Add rsrc if not in the action's pointer array
        if (!rsrcRefs.includes(key)) rsrcRefs.push(key)
        // Add action if not in the policy's pointer array
        if (!policyToActions.includes(aKey)) policyToActions.push(aKey)
        //* add pointer from: action -> resource node
        map[aKey] = rsrcRefs
      })
      //* Add pointer from: policy -> iam actions
      map[pKey] = policyToActions
    }
  })
  return map
}

/**
 * Create a map of relation pointing from policy to target resources
 * @param {any[]} policys
 *
 */
export const createPolicyToTargetsRelation = (policys = []) => {
  const map = {}

  policys.forEach((policy) => {
    const pKey = createRsrcKey(policy)
    if (policy.Type === 'IAMAction') return
    const rsrcs = getPolicyResourcesRefs(policy)
    //* pointer from policy to targets
    const rsrcRefs = map[pKey] || []
    rsrcs.forEach((r) => {
      if (!rsrcRefs.includes(createRsrcKey(r))) rsrcRefs.push(createRsrcKey(r))
    })
    //* Add pointer from policy -> targets
    map[pKey] = rsrcRefs
  })

  return map
}
