import { GroupDetailsProvider, useGroupDetailsContext } from 'features/groups'
import React, { useEffect } from 'react'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'
import useMultiDispatch from 'Core/Hooks/useMultiDispatch'

function GroupDetails() {
  const { group } = useGroupDetailsContext()
  const { selectDispatch } = useMultiDispatch([])

  useEffect(() => {
    selectDispatch(['userList'])
  }, [])

  return (
    <div>
      <Header />
      <div>
        <Tabs />
      </div>
    </div>
  )
}

const WrappedGroupDetails = () => (
  <GroupDetailsProvider>
    <GroupDetails />
  </GroupDetailsProvider>
)

export { WrappedGroupDetails as GroupDetails }
