import { faCubes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFriendlyName } from 'Utils/FriendlyName'
import { enqueueNotification } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { BUNDLE_REQ_TYPE, BUNDLE_TYPES } from 'features/bundles'
import { K8_PRINCIPAL_KEY } from 'features/resources'
import { getResourceCloudType } from 'features/resources/utils'
import { isPrivateServer } from 'features/targets'
import { reduxApiClient } from 'infra'
import { getObjectFromRedux } from 'infra/redux'
import { createRsrcKey } from 'infra/redux/reducers'
import { Button, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'

/**
 *
 * @param {{
 *  onCancel:(() => void),
 *  resoucesWithRoles: any,
 *  onSuccess:(() => void),
 *  reqType: 'IAMAction' | 'Target' | 'Salesforce'
 *  }} param0
 * @returns
 */
const BundleCreateModal = ({ onCancel, resoucesWithRoles, onSuccess, reqType = 'IAMAction' }) => {
  const [bundleName, setBundleName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [description, setDescription] = useState('')

  const handleCreateClick = async () => {
    try {
      setIsLoading(true)
      const metaObj = {}
      const resources = []

      resoucesWithRoles.forEach(({ Resource, Roles, Principal }) => {
        metaObj[createRsrcKey(Resource)] = { Roles: Roles.ObjectRef, Principal }
        resources.push(Resource)
      })

      await handleBundleCreate({
        resources,
        resourcesMetaObj: metaObj,
        name: bundleName,
        description,
        reqType
      })
      enqueueNotification('Bundle created successfully!', 'info')
      onSuccess()
    } catch (error) {
      enqueueNotification('Error creating bundle', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center mb-4'>
        <Typography variant='h4-regular'>
          <FontAwesomeIcon className='mr-2' icon={faCubes} />
          Create Bundle
        </Typography>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
      </div>
      <div className='mb-4'>
        <TextInput
          sx={{
            width: '100%'
          }}
          label='Bundle Name(*)'
          value={bundleName}
          onChange={(e) => setBundleName(e.target.value)}
        />
        <TextInput
          label='Description'
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={3}
          sx={{ width: '100%', marginTop: '8px' }}
        />
      </div>
      <div className='flex justify-end'>
        <Button onClick={handleCreateClick} disabled={!bundleName} variant='primary'>
          Submit
        </Button>
      </div>
      <LoadingFeedback loading={isLoading} message='Creating Bundle' caption='Please wait..' />
    </FullScreenContentModal>
  )
}

const handleBundleCreate = async ({ resources, resourcesMetaObj, name, description, reqType }) => {
  const reqObject = {
    ObjectMeta: {
      Name: getFriendlyName()
    },
    Spec: {
      Description: description,
      ActionMap: {}
    },
    Type: '',
    ReqType: reqType,
    GivenName: name
  }

  if (reqType === BUNDLE_REQ_TYPE.IAMAction) {
    const { actionMap, type } = iamResourcesPolicySpecCreate({ resources, resourcesMetaObj })
    reqObject.Spec.ActionMap = actionMap
    reqObject.Type = type
  } else if (reqType === 'Target') {
    const { actionMap, type } = targetPolicySpecCreate({ resources, resourcesMetaObj })
    reqObject.Spec.ActionMap = actionMap
    reqObject.Type = type
  } else if (reqType === 'Salesforce') {
    const { actionMap, type } = salesforcePolicySpecCreate({ resources })
    reqObject.Spec.ActionMap = actionMap
    reqObject.Type = type
  }
  await reduxApiClient('reqbundles').create(reqObject)
}

function salesforcePolicySpecCreate({ resources }) {
  const actionMap = { Salesforce: { PolicyRule: [] } }
  resources.forEach((refObj) => {
    actionMap.Salesforce.PolicyRule.push({
      ObjectRef: {
        RefKind: refObj.RefKind,
        RefID: refObj.RefID
      }
    })
  })

  return { actionMap, type: BUNDLE_TYPES.SALESFORCE_APP }
}

function iamResourcesPolicySpecCreate({ resources, resourcesMetaObj }) {
  const actionMap = {}
  let type = ''
  /** Create the ActionMap object */
  resources.forEach((rsrc) => {
    const Roles = resourcesMetaObj[createRsrcKey(rsrc)]?.Roles || []

    const KIND_TYPE_MAP = { AwsResource: 'AWS', GcpResource: 'GCP', AzureResource: 'AZURE' }
    type = KIND_TYPE_MAP[rsrc.RefKind]

    const resourceTypeToIAMMap = {
      AwsResource: 'AwsIAM',
      GcpResource: 'GcpIAM',
      AzureResource: 'AzureIAM'
    }

    const resource = rsrc
    const actionMapKey = resourceTypeToIAMMap[resource.RefKind]
    if (!actionMap[actionMapKey]) {
      actionMap[actionMapKey] = {
        PolicyRule: []
      }
    }
    Roles.forEach((r) => {
      actionMap[actionMapKey].PolicyRule.push({
        ObjectRef: {
          RefID: r.RefID,
          RefKind: 'IamAction'
        },
        Principal: '',
        GcpRoleTarget: null,
        Services: {
          ObjectRef: [
            {
              RefKind: resource.RefKind,
              RefID: resource.RefID
            }
          ]
        }
      })
    })
  })

  return { actionMap, type }
}

function targetPolicySpecCreate({ resources, resourcesMetaObj }) {
  const actionMap = {}
  let type = ''
  const rsrc = getObjectFromRedux(resources[0])
  type = getResourceCloudType(rsrc)
  resources.forEach((rsrc) => {
    const { Principal, Roles } = resourcesMetaObj[createRsrcKey(rsrc)] || {}
    if (rsrc.RefKind === 'Server') {
      const SSHRule = actionMap.SSH || { PolicyRule: [] }
      const srv = getObjectFromRedux(rsrc)
      const isPrivateSrv = srv ? isPrivateServer(srv) : false

      if (isPrivateSrv) {
        type = BUNDLE_TYPES.PRIVATE_SERVER
      }

      SSHRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Server',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      actionMap.SSH = SSHRule
    } else if (rsrc.RefKind === 'AppRole') {
      const AssumeRole = actionMap.AssumeRole || { PolicyRule: [] }
      AssumeRole.PolicyRule.push({
        ObjectRef: {
          RefKind: 'AppRole',
          RefID: rsrc.RefID
        }
      })
      actionMap.AssumeRole = AssumeRole
    } else if (rsrc.RefKind === 'Database') {
      const DBAccess = actionMap.DBAccess || { PolicyRule: [] }
      DBAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Database',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      actionMap.DBAccess = DBAccess
    } else if (rsrc.RefKind === 'RDPServer') {
      const RDPAccessRule = actionMap.RDPAccess || { PolicyRule: [] }
      RDPAccessRule.PolicyRule.push({
        ObjectRef: {
          RefKind: 'RDPServer',
          RefID: rsrc.RefID
        },
        Principal: Principal
      })
      actionMap.RDPAccess = RDPAccessRule
    } else if (rsrc.RefKind === 'Kafka') {
      const KafkaAccess = actionMap.KafkaAccess || { PolicyRule: [] }
      KafkaAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Kafka',
          RefID: rsrc.RefID
        }
      })
      actionMap.KafkaAccess = KafkaAccess
    } else if (rsrc.RefKind === 'KubeCluster') {
      const KubeAccess = actionMap.KubeAccess || { PolicyRule: [] }
      KubeAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'KubeCluster',
          RefID: rsrc.RefID
        },
        Services: {
          ObjectRef: Roles
        }
      })
      actionMap.KubeAccess = KubeAccess
    } else if (rsrc.RefKind === 'KubeNamespace') {
      const KubeAccess = actionMap.KubeAccess || { PolicyRule: [] }
      KubeAccess.PolicyRule.push({
        ObjectRef: {
          RefKind: 'KubeNamespace',
          RefID: rsrc.RefID
        },
        Services: {
          ObjectRef: Roles
        },
        Principal: Principal?.replace(K8_PRINCIPAL_KEY, '')
      })
      actionMap.KubeAccess = KubeAccess
    } else if (rsrc.RefKind === 'Application') {
      const HTTP = actionMap.HTTP || { PolicyRule: [] }
      HTTP.PolicyRule.push({
        ObjectRef: {
          RefKind: 'Application',
          RefID: rsrc.RefID
        },
        Services: {
          ObjectRef: []
        },
        Principal: ''
      })
      actionMap.HTTP = HTTP
      type = BUNDLE_TYPES.HTTP_APP
    }
  })

  return { actionMap, type }
}

export { BundleCreateModal }
