import { SummaryHeader } from 'V2Components'
import { getResourceName, getRsrcIcon } from 'features/resources'
import { Button, TargetIcon } from 'procyon-ui'
import React from 'react'
import { useParams } from 'react-router'

const Header = ({setShowDeleteModal, isJiraConfigEditable}) =>  {
    const {integrationName, kind} = useParams()
    
    return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon(kind)}
        rsrcName={integrationName}
        actions={
          <div className='flex gap-4'>
              <Button size='md' variant='grayRed' onClick={() => setShowDeleteModal(true)}>
                  Remove
              </Button>
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Integrations',
            link: '/admin/integrations'
          },
          kind === 'JiraIntegration'
            ? {
                label: integrationName,
                link: `/admin/integrations`
              }
            : {
                label: integrationName,
                link: '/admin/integrations'
              },
          isJiraConfigEditable && {
            label: 'Jira Config'
          }
        ]}
      />
    </div>
    )
}

export {Header}