export const KindToQueryIndexMap = {
  AppRole: ['event-log'],
  Server: ['proxy-audit-log'],
  Application: ['proxy-audit-log'],
  _ALL: ['event-log', 'audit-log', 'proxy-event-log', 'proxy-audit-log']
}

export const ElasticLogIndexDataMap = {
  'event-log': {
    UserNameMatchKey: 'User.Name',
    TenantMatchKey: 'User.Tenant',
    DeviceMatchKey: 'Device.Name',
    ResourceMatchKey: 'Resources',
    ResourceKind: 'AppRole'
  },
  'proxy-audit-log': {
    UserNameMatchKey: 'Data.User',
    TenantMatchKey: 'Data.Tenant',
    DeviceMatchKey: 'Data.Device',
    ResourceMatchKey: 'Data.ServerName',
    ResourceKind: 'Server'
  },
  'proxy-event-log': {
    UserNameMatchKey: 'Data.User.Name',
    TenantMatchKey: 'Data.User.Tenant',
    DeviceMatchKey: 'Data.Device.Name',
    ResourceMatchKey: null, // Resource doesnt exist for these logs,
    ResourceKind: ''
  },
  'audit-log': {
    UserNameMatchKey: 'User.Name',
    TenantMatchKey: 'User.Tenant',
    DeviceMatchKey: 'Device.Name',
    ResourceMatchKey: null, // Resource doesnt exist for these logs
    ResourceKind: ''
  }
}