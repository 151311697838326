import { Apps, FeedbackOutlined, Folder } from '@material-ui/icons'
import DnsIcon from '@material-ui/icons/Dns'
import AppRoleIcon from 'Components/CustomIcons/AppRoleIcon'
import DatabaseIcon from 'Components/CustomIcons/Database'
import { getServiceAccountName } from 'Utils/PureHelperFuctions'
import { getIamActionName, getKubeNamespaceName } from 'features/iamResources'
import { getServerName, isPrivateServer } from 'features/targets'
import _ from 'lodash'
import React from 'react'

import { faKey, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAWSResourceIcon } from 'Components/CustomIcons/AWSResources'
import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'
import { getGCPResourceIcon } from 'Components/CustomIcons/GCPResources'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { SALESFORCE_TYPE_ICON_NAME, getApplicationIcon } from 'features/applications'
import { BUNDLE_TYPE_ICON_TYPE } from 'features/bundles'
import { getIAMRoleTargetIconType } from 'features/iamRoles'
import { getPolicyDisplayName } from 'features/policy'
import { getUserDisplayName } from 'features/users'
import { TargetIcon } from 'procyon-ui'
import { msTeamsIntegrations } from 'infra/redux/reducers'

/*** ============================== Required constants goes in here ================================= */

const KindToIconMap = {
  AppRole: 'APPROLE',
  ADDomainController: 'AD_DOMAIN_CONTROLER',
  User: 'USER',
  Group: 'USERGROUPS',
  Server: 'SERVER',
  ServiceAccount: 'SERVICEACCOUNT',
  PrivateServer: 'SERVER_PRIVATE',
  DynamicAppRole: 'APPROLE_DYNAMIC',
  PacPolicy: 'POLICY',
  Repository: 'GITHUB_REPOSITORIES_MONO',
  GithubResource: 'GITHUB_REPOSITORIES_MONO',
  Database: 'DATABASE',
  RDPServer: 'RDP_SERVER',
  Kafka: 'APACHE_KAFKA',
  KubeCluster: 'KUBE_CLUSTERS',
  Application: 'GENERIC_APPLICATION',
  Slack: 'SLACK',
  JiraIntegration: 'JIRA',
  SlackIntegration: 'SLACK',
  ServiceNowIntegration: 'SERVICENOW',
  SlackChannel: 'SLACK',
  ServiceNowTable: 'SERVICENOW',
  KubeNamespace: 'KUBE_NAMESPACES',
  Salesforce: 'SALESFORCE',
  Snowflake: 'SNOWFLAKE',
  MsTeamsIntegration: 'TEAMS',
  DatabricksWorkspace: 'DATABRICKS',
  DatabricksResource: 'DATABRICKS'
}

const AccountTypeToIconNameMap = {
  AWS: 'AWS_APPLICATION',
  GCP: 'GOOGLE_APPLICATION',
  AZURE: 'AZURE_APPLICATION'
}

const IconsKindResolversMap = {
  AwsResource: getAWSResourceIcon,
  GcpResource: getGCPResourceIcon,
  AzureResource: getAZUREResourceIcon,
  Application: getApplicationIcon, //*<=================== resolver
  IamAction: (iam) => {
    return ({ ...props }) => <TargetIcon type={getIAMRoleTargetIconType(iam)} {...props} />
  },
  SlackIntegration: 'SLACK',
  SnowflakeRole: () => {
    return ({ ...props }) => <TargetIcon type='SNOWFLAKE' />
  },
  Account: (acc) => {
    const { Type } = acc.Spec
    return ({ ...props }) => <TargetIcon type={AccountTypeToIconNameMap[Type]} {...props} />
  },
  ReqBundle(bundle) {
    const iconType = BUNDLE_TYPE_ICON_TYPE[bundle.Type]
    return ({ ...props }) => <TargetIcon type={iconType} {...props} />
  },
  CRMEntity(entity) {
    const type = entity.Type
    const iconType = SALESFORCE_TYPE_ICON_NAME[type] || 'SALESFORCE'
    return ({ ...props }) => <TargetIcon type={iconType} {...props} />
  }
}

const EntitiesFAIconMap = {
  User: faUser,
  Group: faUserGroup,
  ServiceAccount: faKey
}

const ServiceAccountAccTypeKeyMap = {
  AWS: 'Spec.AwsSpec.Account',
  AZURE: 'Spec.AzureSpec.Account'
}

const RsrcAccountRefKeyMap = {
  ServiceAccount: (sa) => {
    const { Type } = sa.Spec
    return ServiceAccountAccTypeKeyMap[Type] || ''
  },
  Default: 'Spec.Account'
}

/** ================================================================================================= */

export const getIAMBookmarksCount = ({ list, isBookmarked, accountType }) => {
  let count = 0
  if (!list || !isBookmarked) return 0
  const accountTypeResourceMap = {
    AWS: 'AwsResource',
    GCP: 'GcpResource',
    AZURE: 'AzureResource'
  }
  list.forEach((e) => {
    const kind = e.ObjectMeta.Kind
    const key = createRsrcKey(e)
    if (!accountType) {
      if (isBookmarked(key)) count += 1
    } else {
      const isAccountTypeResource = kind === accountTypeResourceMap[accountType]
      const isAccountTypeNameSpace = kind === 'KubeNamespace' && e.Spec.Type === accountType
      if (isAccountTypeResource || isAccountTypeNameSpace) {
        if (isBookmarked(key)) count += 1
      }
    }
  })
  return count
}

export const getTargetBookmarksCount = ({ list, isBookmarked }) => {
  let count = 0
  if (!list || !isBookmarked) return 0
  list.forEach((e) => {
    const kind = e.ObjectMeta.Kind
    const key = `${kind}+${e.ObjectMeta.ID}`
    if (isBookmarked(key)) count += 1
  })
  return count
}

export const getDeviceName = (device) => {
  try {
    return device?.Attributes?.DeviceName || device.ObjectMeta.Name
  } catch (error) {
    console.error('error in getting device name', error)
  }
}

export const getAccountName = (account) => {
  const { DisplayName } = account.Spec
  if (!account) return ''
  return DisplayName || account.ObjectMeta.Name
}

/**
 * Gives Display name for resource
 * @param {object} rsrc
 */
export const getResourceName = (rsrc) => {
  if (!rsrc) return ''
  switch (rsrc.ObjectMeta.Kind) {
    case 'ADDomainController':
      return rsrc.ObjectMeta.Name
    case 'AppRole':
      return rsrc.GivenName || rsrc.Spec.RoleName
    case 'Project':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'GcpResource':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'AwsResource':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'KubeNamespace':
      return getKubeNamespaceName(rsrc)
    case 'AzureResource':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'IamAction':
      return getIamActionName(rsrc)
    case 'Server':
      return getServerName(rsrc)
    case 'Database':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'User':
      return getUserDisplayName(rsrc)
    case 'ServiceAccount':
      return getServiceAccountName(rsrc)
    case 'ApprovalReq':
      return rsrc.GivenName
    case 'TagPolicy':
      return rsrc.ObjectMeta.Name
    case 'SlackChannel':
      return rsrc.Spec.ChannelName
    case 'WorkloadIdentity':
      return rsrc.Spec.Name
    case 'JiraProject':
      return rsrc.Spec.ProjectName
    case 'RDPServer':
      return rsrc.Spec.DisplayName || rsrc.Spec.Name
    case 'Kafka':
      return rsrc.Spec.Name
    case 'ServiceNowTable':
      return rsrc.Spec.Name
    case 'Account':
      return getAccountName(rsrc)
    case 'Device':
      return getDeviceName(rsrc)
    case 'SlackIntegration':
      return rsrc.Spec.Name
    case 'KubeCluster':
      return rsrc.Spec.DisplayName
    case 'PacPolicy':
      return getPolicyDisplayName(rsrc)
    case 'ReqBundle':
      return rsrc.GivenName || rsrc.ObjectMeta.Name
    case 'CRMEntity':
      return rsrc.EntityName
    case 'SnowflakeRole':
      return rsrc.Spec.Name
    case 'DatabricksResource':
      return rsrc.Spec.Name
    case 'DatabricksWorkspace':
      return rsrc.Spec.WorkspaceName
  }
  return rsrc.ObjectMeta.Name
}
/**
 * @deprecated
 */
export const getResourceIcon = (type) => {
  switch (type) {
    case 'Role':
      return ({ className }) => <img className={className} src='img/iam-role.png' />
    case 'AppRole':
      return AppRoleIcon
    case 'Server':
      return DnsIcon
    case 'Project':
      return Folder
    case 'GcpResource':
      return Folder
    case 'Application':
      return Apps
    case 'Database':
      return DatabaseIcon
    default:
      return FeedbackOutlined
  }
}

export const isPrivateKubeNamespaceManaged = (kube) => {
  if (kube?.ObjectMeta.Kind !== 'KubeNamespace') return false
  const cluster = getObjectRefFlat(kube.Spec.Cluster)
  return _.get(cluster, 'Spec.State', 'unmanaged') === 'managed'
}

/**
 *
 * @param {object} resource Resource which needs to be checked for managed state
 * @returns {boolean} True for managed resource, false for unmanaged resource
 */
export const isResourceManaged = (resource) => {
  // Private KNamespaces are always managed
  if (isPrivateKubeNamespaces(resource)) return isPrivateKubeNamespaceManaged(resource)
  // Private Servers are always managed
  if (isPrivateServer(resource)) return true
  if (_.get(resource, 'ObjectMeta.Kind') === 'Project') {
    return _.get(resource, 'Status.Status.State', 'unmanaged') === 'managed'
  }
  return _.get(resource, 'Spec.State', 'unmanaged') === 'managed'
}

export const createRsrcKey = (rsrc) => {
  if (!rsrc) return null
  if (rsrc.RefKind) return `${rsrc.RefKind}+${rsrc.RefID}`
  if (!rsrc.ObjectMeta?.Kind) return null
  return `${rsrc.ObjectMeta.Kind}+${rsrc.ObjectMeta.ID}`
}

/**
 * Get ref object from a key created using `createRsrcKey()`
 * @param {string} key
 * @returns {import('types').ObjectRef | undefined}
 */
export const reverseRsrcKey = (key) => {
  if (typeof key !== 'string') return
  return {
    //@ts-ignore
    RefKind: key.split('+')[0],
    RefID: key.split('+')[1]
  }
}

/**
 * The function returns a Icon component when a string or resource object is passed
 * @param {string|object} rsrc
 * @returns
 */
export function getRsrcIcon(rsrc) {
  let Icon = null
  if (!rsrc) return null
  if (typeof rsrc === 'object') {
    const Kind = rsrc.ObjectMeta.Kind
    if (IconsKindResolversMap[Kind]) {
      Icon = IconsKindResolversMap[Kind](rsrc)
    } else if (KindToIconMap[Kind]) {
      Icon = ({ ...rest }) => <TargetIcon type={KindToIconMap[Kind]} {...rest} />
    } else Icon = getResourceIcon(Kind)
  } else if (typeof rsrc === 'string' && KindToIconMap[rsrc]) {
    Icon = ({ ...rest }) => <TargetIcon type={KindToIconMap[rsrc]} {...rest} />
  } else {
    Icon = ({ ...rest }) => (
      <div className='p-2 rounded flex justify-center items-center bg-gray-300'>
        <FontAwesomeIcon size='lg' icon={faBoxArchive} {...rest} />
      </div>
    )
  }

  return Icon
}

/**
 *
 * @returns An object ref of the passed object
 */
export const createRef = (obj) => {
  if (!obj) return {}
  return { RefKind: obj.ObjectMeta.Kind, RefID: obj.ObjectMeta.ID }
}

/**
 * Get the type of resource, used exclisively for Iam resources
 * @param {*} rsrc
 * @returns Type of resource
 */
export const getResourceType = (rsrc) => {
  if (!rsrc) return ''
  switch (rsrc.ObjectMeta.Kind) {
    case 'AwsResource':
      return rsrc.Spec.Type
    case 'KubeNamespace':
      return rsrc.Spec.DisplayType
    case 'AzureResource':
      return rsrc.Spec.Type
    case 'GcpResource':
      return rsrc.Spec.DisplayType
    default:
      return rsrc.ObjectMeta.Kind
  }
}

/**
 *
 * @param {string | object } e The entity object
 * @returns Icon
 */
export const getEntitiyFAIcon = (e) => {
  const Kind = typeof e === 'string' ? e : e.ObjectMeta.Kind
  return EntitiesFAIconMap[Kind] || EntitiesFAIconMap.User
}

/**
 *
 * @param {object} rsrc Resource Object
 * @returns {import('types').ObjectRef}
 */
export const getRsrcAccountRef = (rsrc) => {
  if (!rsrc) return null
  let refKey = RsrcAccountRefKeyMap[rsrc.ObjectMeta.Kind] || RsrcAccountRefKeyMap.Default
  if (typeof refKey === 'function') refKey = refKey(rsrc)
  return _.get(rsrc, refKey)
}

/**
 * Check if `rsrc` belongs to account `acc`
 * @param {*} rsrc
 * @param {*} acc
 * @returns {boolean}
 */
export const isResourceOfAccount = (rsrc, acc) => {
  const rsrcAccountKey = createRsrcKey(getRsrcAccountRef(rsrc))
  const accKey = createRsrcKey(acc)

  return accKey === rsrcAccountKey
}

export const getResourcePolicyTags = (rsrc) => {
  let tags = {}
  tags = rsrc?.ObjectMeta?.Labels?.Map
  return tags || {}
}

export function onlyLettersAndNumbers(str) {
  return /([A-Za-z0-9_]+)/.test(str)
}

export function isAlphanumeric(str) {
  return /^[a-zA-Z0-9]+$/.test(str);
}

export function keysToLowerCase(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    newObj[key.toLowerCase()] = obj[key]
    return newObj
  }, {})
}

/**
 *
 * @param {*} rsrc The resource whose type needs to be determined
 * @returns {"AWS"|"GCP"|"AZURE"}
 */
export const getResourceCloudType = (rsrc) => {
  if (!rsrc) return 'AWS'
  const type = KIND_TO_CLOUD_TYPE_FUNCTION_MAP[rsrc.ObjectMeta.Kind]
  if (typeof type === 'function') return type(rsrc)
  return type
}

const KIND_TO_CLOUD_TYPE_FUNCTION_MAP = {
  AwsResource: 'AWS',
  GcpResource: 'GCP',
  AzureResource: 'AZURE',
  AppRole: 'AWS',
  Account(acc) {
    return acc.Spec.Type
  },
  KubeNamespace(kube) {
    if (kube.Spec.Type === 'PRIVATE') return kube.Spec.CloudType
    return kube.Spec.Type
  },
  Database(db) {
    return db?.Spec?.CloudType
  },
  Server(server) {
    if (isPrivateServer(server)) return ''
    const account = getObjectRefFlat(getRsrcAccountRef(server))
    return account?.Spec.Type || ''
  },
  Project: 'GCP',
  Kafka: 'AWS',
  RDPServer: (rdp) => {
    return rdp.Spec.Type || 'AZURE'
  },
  KubeCluster(kc) {
    return kc.Spec.CloudType
  }
}

const RSRC_URL_MAP = {
  IamAction: '/<VIEW>/iam-roles/<NAME>/overview',
  Application: '/<VIEW>/applications/<NAME>',
  AppRole: '/<VIEW>/targets/<KIND>/<NAME>',
  Server: '/<VIEW>/targets/<KIND>/<NAME>',
  Database: '/<VIEW>/targets/<KIND>/<NAME>',
  KubeNamespace: '/<VIEW>/targets/<KIND>/<NAME>',
  KubeCluster: '/<VIEW>/targets/<KIND>/<NAME>',
  AwsResource: '/<VIEW>/resources/<KIND>/<NAME>',
  RDPServer: '/<VIEW>/targets/<KIND>/<NAME>'
}

/**
 * Generate the details page url for `rsrc`
 * @param {*} rsrc
 * @param {'admin'|'user'} view
 * @returns {string}
 */
export const getRsrcURL = (rsrc, view) => {
  if (!rsrc) return ''
  const { Name, Kind } = rsrc.ObjectMeta
  const urlGenFn = RSRC_URL_MAP[Kind]
  if (!urlGenFn) return ''
  const url = urlGenFn
    .replace('<VIEW>', view.toLowerCase())
    .replace('<NAME>', Name)
    .replace('<KIND>', Kind.toLowerCase())
  return url
}

export const isPrivateKubeNamespaces = (kube) =>
  kube?.ObjectMeta.Kind === 'KubeNamespace' && kube.Spec.Type === 'PRIVATE'

export const dateSortComparator = (v1, v2) => {
  if (!v1) return -1
  if (!v2) return 1
  return new Date(v1).getTime() - new Date(v2).getTime()
}

/**
 * Get error from rsrc
 * @param {*} rsrc
 * @returns {string} The Error message if not empty
 */
export const getRsrcError = (rsrc) => {
  if (!rsrc) return ''
  const error = rsrc.Status.Status.Error === '{}' ? '' : rsrc.Status.Status.Error
  return error
}

export const K8_PRINCIPAL_KEY = 'k8Rrsc=true;'

export * from './ianaTimezones'
