import { Box, Tooltip, Typography } from '@material-ui/core'
import LinearDistribution from 'Components/LinearDistribution'
import MountOn from 'Components/MountOn'
import React from 'react'

export const options = (extraOptions = {}) => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: false,
  rowsPerPageOptions: [5, 10, 15, 100],
  ...extraOptions
})

export const columns = ({ classes, disableTextWrap }) => [
  {
    name: 'index',
    label: 'Index',
    options: {
      setCellProps: () => ({ style: { paddingRight: '0.5rem', width: '0px' } }),
      customBodyRender: (index) => (
        <Typography className={classes.count} variant='body2'>
          {index}
        </Typography>
      ),
      filter: true,
      sort: true
    }
  },
  {
    name: 'Icon',
    label: 'Icon',
    options: {
      setCellProps: () => ({ style: { width: '0px' } }), // Need width as 0px to remove extra unused space
      customBodyRender: (Icon) => <Icon fontSize='small' className={classes.icon} />,
      filter: true,
      sort: true
    }
  },
  {
    name: 'deviceInfo',
    label: 'Device Info',
    options: {
      setCellProps: () => ({ style: { paddingLeft: '0px' } }),
      customBodyRender: (deviceInfo) => {
        return (
          <Box>
            <Typography variant='body2' className={classes.mainText}>
              <MountOn fallback={deviceInfo.device} on={deviceInfo.device.length > 25 && !disableTextWrap}>
                <Tooltip title={deviceInfo.device}>
                  <span>{deviceInfo.device.substring(0, 25)}...</span>
                </Tooltip>
              </MountOn>
            </Typography>
            <Box pt='0.2rem'>
              <Typography variant='body2'>{deviceInfo.user}</Typography>
            </Box>
          </Box>
        )
      },
      filter: true,
      sort: true
    }
  },
  {
    name: 'sessionCount',
    label: ' ',
    options: {
      setCellProps: () => ({ style: { paddingLeft: '0px' } }),
      customBodyRender: (value) => {
        return (
          <Box>
            <Tooltip title={`Sessions: ${value}`}>
              <Typography>{value}</Typography>
            </Tooltip>
          </Box>
        )
      },
      filter: true,
      sort: true
    }
  },
  {
    name: 'progress',
    label: 'Progress',
    options: {
      customBodyRender: (progress) => (
        <Box pt='0.3rem' mr='-1rem' display='flex' justifyContent='flex-end'>
          <LinearDistribution width={7.313} distributions={progress} />
        </Box>
      ),
      filter: true,
      sort: true
    }
  }
]
