import { RelationAccessGraph } from 'V2Components'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { useIAMRoleAccess } from 'features/iamRoles'
import { getPolicyIssuedToEntities } from 'features/policy'
import { createRsrcKey, reverseRsrcKey } from 'features/resources'
import { useObjectRef } from 'infra/redux'
import _ from 'lodash'
import { Typography } from 'procyon-ui'
import { useDataDetailsContext } from 'providers/DataDetailsProvider'
import React from 'react'

const AccessGraph = () => {
  const { getObjectRef } = useObjectRef([
    'policyList',
    'userList',
    'groupList',
    'serviceAccounts',
    ...IAMResourcesSliceNames
  ])
  const iamrole = useDataDetailsContext()

  const { getIAMRoleAttachedPolicys, getIAMRoleAttachedRsrcs } = useIAMRoleAccess()

  const getPolicysToEntitiyMap = () => {
    const map = {}
    const policysRef = getIAMRoleAttachedPolicys(iamrole)
    const policys = getObjectRef(policysRef.map(reverseRsrcKey))
    policys.forEach((policy) => {
      const entities = getPolicyIssuedToEntities(policy)
      map[createRsrcKey(policy)] = entities
    })

    return map
  }

  const policyToEntityMap = getPolicysToEntitiyMap()

  const getPolicys = () => {
    const keys = Object.keys(policyToEntityMap)
    return getObjectRef(keys.map(reverseRsrcKey))
  }

  const getEntities = () => {
    const keys = _.flatten(Object.values(policyToEntityMap)).map(createRsrcKey)
    return getObjectRef([...new Set(keys)].map(reverseRsrcKey))
  }

  const getResources = () => {
    const rsrcs = getIAMRoleAttachedRsrcs(iamrole)
    return getObjectRef(rsrcs.map(reverseRsrcKey))
  }

  const policys = getPolicys()
  const entities = getEntities()
  const resources = getResources()

  const getGraphRelationsObject = () => {
    const obj = {}
    // Entity to policy
    for (const policyKey in policyToEntityMap) {
      // Entity in the policy key - policyKey
      const entityRefObjects = policyToEntityMap[policyKey]
      // For evry entity obj map to policy
      entityRefObjects.forEach((entityRefObj) => {
        const keys = obj[createRsrcKey(entityRefObj)] || []
        keys.push(policyKey)
        // here: entity -> policy/s
        obj[createRsrcKey(entityRefObj)] = keys
      })
    }

    //Policys to iam actions

    policys.forEach((policy) => {
      obj[createRsrcKey(policy)] = [createRsrcKey(iamrole)]
    })

    // IAMROLE to Resources
    resources.forEach((rsrc) => {
      const iamArrowKeys = obj[createRsrcKey(iamrole)] || []
      iamArrowKeys.push(createRsrcKey(rsrc))
      obj[createRsrcKey(iamrole)] = iamArrowKeys
    })

    return obj
  }

  return (
    <div>
      {policys.length ? (
        <RelationAccessGraph
          relationObject={getGraphRelationsObject()}
          nodeObjects={[entities, policys, [iamrole], resources]}
        />
      ) : (
        <Typography variant='body-regular'>IAM Role is not used for access.</Typography>
      )}
    </div>
  )
}

export { AccessGraph }
