
import { axios } from 'Core'
import { debug } from 'Utils/DebugHelper'
import { errorHandler } from '../Utils/Helpers'
// import { getRequest } from './NetworkRequest'

export default class authApis {
  static async checkAuth () {
    try {
      const apiKey = debug.getApiToken()
      const requestObject = {
        method: 'GET',
        url: '/whoami'
      }
      /** Add jwt if developing off tunnel */
      if (apiKey) {
        requestObject.headers = { Authorization: `Bearer ${apiKey}` }
      }
      const response = await axios(requestObject)
      return response
    } catch (error) {
      errorHandler(error, '', 'authApis.js')
    }
  }
}
