import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { CloudTypeRouteProvider } from 'features/clouds'
import { useCloudTypes } from 'features/clouds/'
import { useReduxFetch } from 'infra/redux'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useClouds } from './Clouds.utils'
import { AWSAccounts } from './components/AWSAccounts'
import { AZUREAccounts } from './components/AZUREAccounts'
import { Fallback } from './components/Fallback'
import { GCPAccounts } from './components/GCPAccounts'
import { Header } from './components/Header'

function Clouds() {
  const [searchKey, setSearchKey] = useState('')
  const { initiallyLoaded, slices } = useMultiSlice(['accountList'])
  //@ts-ignore
  const { cloudType: ct } = useParams()
  const { cloudType } = useClouds()
  const history = useHistory()
  const { cloudTypes } = useCloudTypes()

  // Redirect user to valid cloud type
  if (!ct || !cloudTypes.includes(ct.toUpperCase())) {
    history.push(`/admin/clouds/${cloudTypes[0]?.toLowerCase() || 'aws'}`)
  }

  if (initiallyLoaded && !slices.accountList.length)
    return (
      <Fallback
        onAddAccount={() => history.push(`/admin/clouds/${cloudType.toLowerCase()}/wizard`)}
      />
    )

  useReduxFetch(['accountList'])

  return (
    <div>
      <Header searchKey={searchKey} setSearchKey={setSearchKey} />
      {cloudType === 'AWS' && <AWSAccounts searchKey={searchKey} />}
      {cloudType === 'GCP' && <GCPAccounts searchKey={searchKey} />}
      {cloudType === 'AZURE' && <AZUREAccounts searchKey={searchKey} />}
    </div>
  )
}

const WrappedClouds = () => (
  <CloudTypeRouteProvider routeKey='cloudType'>
    <Clouds />
  </CloudTypeRouteProvider>
)

export { WrappedClouds as Clouds }
