import { Box, CircularProgress, makeStyles, Switch } from '@material-ui/core'
import { CustomTypography } from 'Components/CustomTypography'
import React, { useCallback } from 'react'
const useStyle = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.inside}`,
    borderRadius: theme.spacing(0.25),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1.3),
    paddingBottom: theme.spacing(1.3)
  },
  switch: {
    marginLeft: theme.spacing(2.25)
  }
}))
const ManageSwitch = ({ text = 'Managed', onSwitch, switchActive, loading = false, disabled }) => {
  const classes = useStyle()
  const handleSwitch = useCallback(() => {
    if (typeof onSwitch === 'function') {
      onSwitch()
    }
  }, [onSwitch])
  return (
    <Box display='flex' height='2.375rem' alignItems='center' justifyContent='space-between' className={classes.root} px={0.75}>
      <CustomTypography.Body color='textSecondary'>{text}</CustomTypography.Body>
      {loading && <CircularProgress className={classes.switch} size={24} />}
      {!loading && <Switch disabled={disabled} className={classes.switch} checked={switchActive} color='primary' onClick={handleSwitch} />}
    </Box>
  )
}

export default ManageSwitch
