import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1002,
    paddingTop: theme.spacing(2),
  }
}))

function FullScreenModal({ children, showModal }) {
  const classes = useStyles()
  if (!showModal) return null
  return <div className={classNames(classes.root, 'glass-morph')}>{children}</div>
}

export { FullScreenModal }

