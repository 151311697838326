import { useContext } from 'react'
import { DetailsContext } from '.'

const useDetails = () => {
  const {
    entity,
    error,
    fetching,
    managing,
    updateEntity,
    handleManageSwitch,
    fetch
  } = useContext(DetailsContext)
  return (
    {
      /**
       * * @typedef {Array<{RefKind:String, RefID: String}>} ObjectRef
       * @typedef {{ RdOwners:{ObjectRef:ObjectRef }, WrOwners:{ObjectRef:ObjectRef}, Kind:string  }} ObjectMeta
       */
      /** @type {{ObjectMeta:ObjectMeta,Spec:any}} BD Object of entity fetched from the url params */
      entity,
      /** @type {String} any error that occured while fetching, updating or deleting */
      error,
      /** @type {Boolean} Loading state */
      fetching,
      /** @type {Boolean} Managed state updating */
      managing,
      /** @type {Function} Toggles Managed state of the entity */
      handleManageSwitch,
      /**
       * @type {Function} To update state without calling find or fetch
       * Can  be used if an update api returns new state inside the component
       **/
      updateEntity,
      /**
       * @type {Function}
       * Fetches DB object of entity, can be used as refresh
       **/
      fetch
    }
  )
}

export default useDetails
