import { Box, Grid, makeStyles, TextField } from '@material-ui/core'
import ThemeButton from 'Components/ThemeButton'
import moment from 'moment'
import { Button, SelectDate, SelectTime } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {},
  // TODO Remove this class after final changes
  shadow: {
    boxShadow: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.13)',
    // TODO change when theme changes
    backgroundColor: 'white'
  },
  input: {
    padding: theme.spacing(0.75)
  },
  advancedFilterLabel: {
    fontWeight: 500
  },
  filterText: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.25)
  },
  expandIcon: {
    marginLeft: theme.spacing(0.25),
    fontSize: theme.spacing(0.875)
  },
  timeInput: {
    marginLeft: theme.spacing(0.75),
    // TODO Remove this style after final changes
    boxShadow: 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.13)',
    // TODO change when theme changes
    backgroundColor: 'white'
  },
  applyButton: {
    height: theme.spacing(2.7),
    width: theme.spacing(5)
  }
}))

function TimeFilter ({ onTimeChange = null, initialTime = { start: null, end: null }, onApplyClick = null }) {
  const classes = useStyles()
  const [duration, setDuration] = useState(() => {
    const obj = { start: moment(), end: moment().add(1, 'hours') }
    if (initialTime?.start) {
      obj.start = moment(initialTime.start)
    }
    if (initialTime?.end) {
      obj.end = moment(initialTime.end)
    }
    return obj
  })

  const handleTimeChange = (e, key = '') => {
    const newTime = e.target?.value
    if (!newTime) return
    const date = duration[key].format('YYYY-MM-DD')
    setDuration((state) => {
      return { ...state, [key]: moment(date + ' ' + newTime) }
    })
  }

  const handleDateChange = (e, key = '') => {
    const newDate = e.target?.value
    if (!newDate) return
    const time = duration[key].format('HH:mm:ss')
    setDuration((state) => {
      return { ...state, [key]: moment(newDate + ' ' + time) }
    })
  }

  const handleApplyClick = () => {
    onApplyClick && onApplyClick(duration.start.toDate(), duration.end.toDate())
  }

  useEffect(() => {
    onTimeChange && onTimeChange(duration.start.toDate(), duration.end.toDate())
  }, [duration])

  return (
    <Box className={classes.root}>
      <Grid alignItems='center' justifyContent='space-between' container>
        <Grid item>
          <Box display='flex'>
            <Box mr={1.5}>
            <SelectDate
              onChange={(e) => handleDateChange(e, 'start')}
              value={duration.start.format('YYYY-MM-DD')}
            />
            <SelectTime
              onChange={(e) => handleTimeChange(e, 'start')}
              value={duration.start.format('HH:mm:ss')}
            />
            </Box>
            <Box>
            <SelectDate
              onChange={(e) => handleDateChange(e, 'end')}
              value={duration.end.format('YYYY-MM-DD')}
            />
            <SelectTime
              defaultValue="10:10"
              value={duration.end.format('HH:mm:ss')}
            />
            </Box>
            <Box ml={1}>
            <Button
              onClick={handleApplyClick}
              size="lg"
              variant="primary"
              style={{
                height: '38px'
              }}
            >
              Apply
            </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TimeFilter
