import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { ErrorModal, PolicyDetailsProvider, usePolicyDetailsContext } from 'features/policy'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Header } from './Components/Header'
import { Tabs } from './Components/Tabs'

function PolicyDetails({}) {
  const [approvalReq, setApprovalReq] = useState()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const { policy, error } = usePolicyDetailsContext()
  const { slices, dispatchThunks } = useMultiSlice(['approvalReqList'])

  if (policy && !approvalReq) {
    const req = _.find(slices.approvalReqList, { ObjectMeta: { Name: policy.ObjectMeta.Name } })
    if (req) setApprovalReq(req)
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div>
      <Header onErrorClick={() => setShowErrorModal(true)} approvalReq={approvalReq} policy={policy} />
      <Tabs />
      {showErrorModal && policy && (
        <ErrorModal
          message={error}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Policy Error'
        />
      )}
    </div>
  )
}

const ContxtWrapped = () => (
  <PolicyDetailsProvider>
    <PolicyDetails />
  </PolicyDetailsProvider>
)

export { ContxtWrapped as PolicyDetails }

