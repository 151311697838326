import React, { useEffect, useRef } from 'react'
import { getRandomInt } from 'Utils/Helpers'
import QRCode from 'qrcode'

const QrCodeView = ({ text }) => {
  const canvasRef = useRef(getRandomInt() + '')

  useEffect(() => {
    QRCode.toCanvas(document.getElementById(canvasRef.current), text, function (error) {})
  }, [text])

  return <canvas id={canvasRef.current} />
}

export { QrCodeView }
