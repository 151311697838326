import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification } from 'Utils/Helpers'
import { CopyText, FullScreenContentModal } from 'V2Components'
import { createRsrcKey, getResourceName } from 'features/resources'
import { usePrivateKubeAccess } from 'features/targets'
import { Button, Tooltip, Typography } from 'procyon-ui'
import React from 'react'

const K8ContextCommandsModal = ({ rsrc, onClose, principalName }) => {
  const { getKubeAccessMap } = usePrivateKubeAccess()
  const { appView } = useAppView()

  const { getObjectRef } = useMultiSlice(['policyList', 'kubeClusters'])

  const kubeCluster =
    rsrc.ObjectMeta.Kind === 'KubeNamespace' ? getObjectRef(rsrc.Spec.Cluster) : rsrc

  const getKubeSignInURLS = () => {
    const urls = []
    if (rsrc.ObjectMeta.Kind !== 'KubeCluster' && rsrc.ObjectMeta.Kind !== 'KubeNamespace') {
      return []
    }
    // get the cluster if it's namespace
    const rsrcPolicys = getKubeAccessMap()[createRsrcKey(rsrc)] || []
    const policys = getObjectRef(rsrcPolicys)

    // show default contextcommand for cluster level

    if (rsrc.ObjectMeta.Kind === 'KubeCluster' && appView === 'admin') {
      urls.push({
        policyName: 'Default',
        command: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon`,
        action() {
          navigator.clipboard.writeText(`${kubeCluster.Spec.IAMRes}:procyon`)
          enqueueNotification(`Context Name  copied : ${getResourceName(rsrc)}`, 'info')
        }
      })
    }

    const addedPolicyNames = new Set() // Set to store added policy names

    policys.forEach((policy) => {
      const principalKey = policy.Spec.ActionMap.KubeAccess.PolicyRule[0].Principal.split(',')

      principalKey?.forEach((item) => {
        // Using forEach instead of map since we don't need to return anything
        if (principalName === item || principalName === 'default') {
          const policyName = getResourceName(policy)
          if (!addedPolicyNames.has(policyName)) {
            // Check if policyName is not already added
            urls.push({
              policyName: policyName,
              command: `kubectl config use-context ${kubeCluster.Spec.IAMRes}:procyon:${policy.ObjectMeta.Name}`,
              action() {
                navigator.clipboard.writeText(
                  `${kubeCluster.Spec.IAMRes}:procyon:` + getResourceName(policy)
                )
                enqueueNotification(`Context Name  copied : ${getResourceName(policy)}`, 'info')
              }
            })
            addedPolicyNames.add(policyName) // Add policyName to the set
          }
        }
      })
    })

    return urls
  }

  return (
    <FullScreenContentModal>
      <div className='flex justify-between items-center mb-2'>
        <Typography variant='h4-regular'>
          Sign In for <strong>{getResourceName(rsrc)}</strong>
        </Typography>
        <Button variant='gray' onClick={onClose}>
          Close
        </Button>
      </div>
      {getKubeSignInURLS().map((e) => {
        return (
          <div className='mb-3' key={e.policyName}>
            <Typography className='mb-3' variant='body-semiBold'>
              {e.policyName}
            </Typography>
            <CopyText
              Component={
                <div>
                  <Tooltip title='Click to Copy' arrow>
                    <div className='p-2 rounded bg-gray-100 cursor-copy'>
                      <Typography variant='body-regular'>{e.command}</Typography>
                    </div>
                  </Tooltip>
                </div>
              }
              messageKey={'Context Command'}
              text={e.command}
            />
          </div>
        )
      })}
    </FullScreenContentModal>
  )
}

export { K8ContextCommandsModal }
