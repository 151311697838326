import { SvgIcon } from '@material-ui/core'
import React from 'react'

/**
 *
 * @param {import('@material-ui/core').SvgIconProps} props
 * @returns
 */
const InfoIcon = ({ fill = '#57565B', ...props }) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <path d='M14.4 8A6.4 6.4 0 1 1 1.6 8a6.4 6.4 0 0 1 12.8 0zM8.8 4.8a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0zM7.2 7.2a.8.8 0 0 0 0 1.6v2.4a.8.8 0 0 0 .8.8h.8a.8.8 0 1 0 0-1.6V8a.8.8 0 0 0-.8-.8h-.8z' fill={fill} fill-rule='evenodd' />
    </SvgIcon>
  )
}

export default InfoIcon
