import { useProxyControllerWizardContext } from 'features/proxyControllers'
import { LabelCheckbox, SelectDropDown, Typography } from 'procyon-ui'
import React from 'react'
import { PrefixSuffixInput } from 'V2Components'

const TargetProxyImage = () => {
  const { targetProxyImages, updateConfig, rdpEnabled, targetProxyImagesOptions } =
    useProxyControllerWizardContext()

  return (
    <div className=''>
      <div className='mt-4'>
        <Typography className='mb-1' variant='body-regular'>
          Procyon Proxy Image
        </Typography>
        <PrefixSuffixInput
          prefixAsInput
          prefixWidth='200%'
          prefix={`procyon-proxy:${targetProxyImages['procyon-proxy'].split(':')[0]}`}
          CustomValueComponent={
            <SelectDropDown
              sx={{ width: '100%' }}
              menuItems={targetProxyImagesOptions['procyon-proxy'].map((e) => ({
                label: e,
                value: e
              }))}
              onChange={(e) => {
                const val = e.target.value
                updateConfig({
                  targetProxyImages: {
                    ...targetProxyImages,
                    'procyon-proxy': `${targetProxyImages['procyon-proxy'].split(':')[0]}:${val}`
                  }
                })
              }}
              value={targetProxyImages['procyon-proxy'].split(':')[1]}
            />
          }
        />
      </div>
      <LabelCheckbox
        checked={rdpEnabled}
        name='RDP Enabled'
        onClick={() => updateConfig({ rdpEnabled: !rdpEnabled })}
      />
      {rdpEnabled && (
        <div>
          <div className=''>
            <Typography className='mb-1' variant='body-regular'>
              Guacamole Client Image
            </Typography>
            <PrefixSuffixInput
              prefixAsInput
              prefixWidth='200%'
              prefix={`guacamole-client:${targetProxyImages['guacamole-client'].split(':')[0]}`}
              value={targetProxyImages['guacamole-client'].split(':')[1]}
              CustomValueComponent={
                <SelectDropDown
                  sx={{ width: '100%' }}
                  menuItems={targetProxyImagesOptions['guacamole-client'].map((e) => ({
                    label: e,
                    value: e
                  }))}
                  onChange={(e) => {
                    const val = e.target.value
                    updateConfig({
                      targetProxyImages: {
                        ...targetProxyImages,
                        'guacamole-client': `${
                          targetProxyImages['guacamole-client'].split(':')[0]
                        }:${val}`
                      }
                    })
                  }}
                  value={targetProxyImages['guacamole-client'].split(':')[1]}
                />
              }
            />
          </div>
          <div className='mt-4'>
            <Typography className='mb-1' variant='body-regular'>
              Guacamole Server Image
            </Typography>
            <PrefixSuffixInput
              prefixAsInput
              prefixWidth='200%'
              prefix={`guacamole-server:${targetProxyImages['guacamole-server'].split(':')[0]}`}
              value={targetProxyImages['guacamole-server'].split(':')[1]}
              CustomValueComponent={
                <SelectDropDown
                  sx={{ width: '100%' }}
                  menuItems={targetProxyImagesOptions['guacamole-server'].map((e) => ({
                    label: e,
                    value: e
                  }))}
                  onChange={(e) => {
                    const val = e.target.value
                    updateConfig({
                      targetProxyImages: {
                        ...targetProxyImages,
                        'guacamole-server': `${
                          targetProxyImages['guacamole-server'].split(':')[0]
                        }:${val}`
                      }
                    })
                  }}
                  value={targetProxyImages['guacamole-server'].split(':')[1]}
                />
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export { TargetProxyImage }
