import { faRadar, faSync, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { SummaryHeader } from 'V2Components'
import {
  DeleteAccountModal,
  isChildAccount,
  useCloudAccounts,
  useCloudDetailsProvider,
  useCloudTypeRouteProvider,
  useRescan
} from 'features/clouds'
import { ErrorModal } from 'features/policy'
import { getResourceName, getRsrcError, getRsrcIcon } from 'features/resources'
import moment from 'moment'
import { Button, IconButton, Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { AddAccountWizard } from 'features/clouds'

const Header = () => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showEditAccount, setShowEditAccount] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)

  const { getObjectRef } = useMultiSlice(['accountList'])
  const { cloud } = useCloudDetailsProvider()
  const { uiGetAccountTargetIconType } = useCloudAccounts()
  const { cloudType } = useCloudTypeRouteProvider()
  const { triggerRescan, scanning } = useRescan()

  const history = useHistory()

  const error = getRsrcError(cloud)
  const cloudName = getResourceName(cloud)

  const getBreadcrumbs = () => {
    const parentAccount = getObjectRef(cloud.Spec.ParentAccount)
    if (parentAccount)
      return [
        {
          label: 'Clouds',
          link: `/admin/clouds/${cloudType.toLowerCase()}`
        },
        {
          label: getResourceName(parentAccount),
          link: `/admin/clouds/${cloudType.toLowerCase()}/${parentAccount.ObjectMeta.Name}/overview`
        },
        {
          label: cloudName,
          link: null
        }
      ]
    return [
      {
        label: 'Clouds',
        link: `/admin/clouds/${cloudType.toLowerCase()}`
      },
      {
        label: cloudName,
        link: null
      }
    ]
  }

  return (
    <div className=''>
      <SummaryHeader
        Icon={
          cloud.ObjectMeta.Kind === 'Account'
            ? (props) => <TargetIcon type={uiGetAccountTargetIconType(cloud)} {...props} />
            : getRsrcIcon(cloud)
        }
        rsrcName={cloudName}
        actions={
          <>
            <div className='flex gap-4 items-center'>
              {error && (
                <IconButton
                  icon={faTriangleExclamation}
                  onClick={() => setShowErrorModal(true)}
                  size='sm'
                  variant='grayRed'
                />
              )}

              {!isChildAccount(cloud) && (
                <Button onClick={() => setShowEditAccount(true)} size='md' variant='grayBlue'>
                  Edit
                </Button>
              )}

              {!scanning && (
                <Button onClick={triggerRescan} size='md' variant='grayBlue'>
                  Rescan
                </Button>
              )}

              {scanning && (
                <Label iconButton={faRadar} variant='success' size='lg' text='Scan In Progress' />
              )}

              <Button onClick={() => setShowDeleteAccount(true)} size='md' variant='grayRed'>
                Delete
              </Button>
            </div>
          </>
        }
        breadCrumbsItems={getBreadcrumbs()}
      />
      <Typography className='text-right' variant='caption-regular'>
        <FontAwesomeIcon icon={faSync} className='mr-2' /> Last Sync:
        {cloud.Status.CrawlerEndedAt &&
          moment(cloud.Status.CrawlerEndedAt).format('DD MMMM YYYY HH:mm:ss A')}
        {!cloud.Status.CrawlerEndedAt && ' Scan In Progress'}
      </Typography>

      {showErrorModal && (
        <ErrorModal
          message={error}
          onClose={() => setShowErrorModal(false)}
          hideViewDetails
          showModal
          title='Account Error'
        />
      )}
      {showEditAccount && cloud && (
        <AddAccountWizard account={cloud} onComplete={() => setShowEditAccount(false)} onCancel={() => setShowEditAccount(false)} />
      )}
      {showDeleteAccount && (
        <DeleteAccountModal
          account={cloud}
          onCancel={() => setShowDeleteAccount(false)}
          onSuccess={() => {
            history.push(`/admin/clouds/${cloudType.toLowerCase()}`)
          }}
        />
      )}
    </div>
  )
}

export { Header }
