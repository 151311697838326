import {
  PolicyAccessGraphView,
  usePolicyDetailsContext
} from 'features/policy'
import React from 'react'

function AccessGraphTab() {
  const { policy } = usePolicyDetailsContext()

  return (
    <div>
      <PolicyAccessGraphView policy={policy} />
    </div>
  )
}

export { AccessGraphTab }
