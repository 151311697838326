import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import { EntityPicker, FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { updateGroup } from 'features/groups'
import { createRef, getResourceName } from 'features/resources'
import React, { useState } from 'react'

function ManageGroupUsers({ onCancel, onSuccess, group }) {
  const [isUpdating, setIsUpdating] = useState(false)
  const { slices, getObjectRef } = useMultiSlice(['userList'])

  const [selectedEntities, setSelectedEntities] = useState(() =>
    getObjectRef(group.Spec.Users?.ObjectRef || [])
  )

  const groupName = getResourceName(group)

  const handleManageUsers = async () => {
    try {
      setIsUpdating(true)
      const updatedGroup = structuredClone(group)
      const userRefs = selectedEntities.map(createRef)
      // Copy new user refs to the group
      updatedGroup.Spec.Users.ObjectRef = userRefs
      const updtGroup = await updateGroup(updatedGroup)
      updateSliceData(updtGroup)
      enqueueNotification(`Successfully updated ${groupName}'s users.`, 'info')
      onSuccess?.()
    } catch (error) {
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <div>
      <div>
        <LoadingFeedback
          loading={isUpdating}
          message={`Updating ${groupName}'s users.`}
          caption='Please wait..'
        />
        <FullScreenContentModal width='auto'>
          <EntityPicker
            preventDeselect={group.ObjectMeta.Name === 'everyone'}
            title={
              <span>
                Add Users to <strong>{groupName}</strong>
              </span>
            }
            continueText='Save'
            entities={slices.userList}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={onCancel}
            onContinue={handleManageUsers}
          />
        </FullScreenContentModal>
      </div>
    </div>
  )
}

export { ManageGroupUsers }
