import { faCopy, faShare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueNotification } from 'Utils/Helpers'
import { LabelContent } from 'V2Components'
import { useAddAccountWizardContext } from 'features/clouds'
import { Button, IconButton, Label, RadioInput, TextInput, Typography } from 'procyon-ui'
import React from 'react'

const OrgStep = ({}) => {
  const { awsSpec, setAwsSpec } = useAddAccountWizardContext()

  return (
    <>
      <Typography variant='body-regular'>Is this an Org Account?</Typography>
      <div className='flex flex-col gap-2 mt-4'>
        <RadioInput
          checked={awsSpec.orgAccount}
          label='Yes'
          onChange={() => setAwsSpec({ orgAccount: true })}
          value='yes'
        />
        <RadioInput
          checked={!awsSpec.orgAccount}
          label='No'
          onChange={() => setAwsSpec({ orgAccount: false })}
          value='no'
        />
      </div>
    </>
  )
}

const CFTemplate = ({}) => {
  const { awsSpec, setAwsSpec } = useAddAccountWizardContext()
  const { externalID } = awsSpec
  const CT = awsSpec.orgAccount
    ? 'https://procyon-cf.s3.us-west-2.amazonaws.com/procyon-aws-standard-org.json'
    : 'https://procyon-cf.s3.us-west-2.amazonaws.com/procyon-aws-standard.json'

  const handleOpen = () => {
    const createLink = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${CT}&param_ExternalId=${externalID}&param_AutoProxyDeploy=Enabled`
    window.open(createLink, '_blank').focus()
  }

  const handleCopyTemplate = () => {
    navigator.clipboard.writeText(CT)
    enqueueNotification('Cloud Formation Template copied to clipboard!', 'info')
  }

  return (
    <div className='my-4'>
      <Typography variant='body-regular'>
        Use the below cloud formation template and ExternalID details to create a role on AWS
        console or click on the{' '}
        <strong>
          {' '}
          <FontAwesomeIcon icon={faShare} /> Open CF Template
        </strong>{' '}
        button below to navigate to AWS console directly.
      </Typography>
      <div className='mt-2 flex gap-2 items-center'>
        <TextInput onClick={handleCopyTemplate} sx={{ width: '100%' }} readOnly value={CT} />
        <IconButton onClick={handleCopyTemplate} variant='clear' icon={faCopy} />
      </div>
      <div className='mt-2'>
        <TextInput
          value={externalID}
          onChange={(e) => setAwsSpec({ externalID: e.target.value })}
          sx={{ width: '100%' }}
          label='Use the following External ID to create role.'
        />
      </div>
      <Typography className='my-2 text-center' variant='caption-regular'>
        Or
      </Typography>
      <Button onClick={handleOpen} className='mx-auto' variant='primary' icon={faShare}>
        Open CF Template
      </Button>
    </div>
  )
}

const ARNOuIDStep = ({}) => {
  const { awsSpec, setAwsSpec } = useAddAccountWizardContext()
  const { arn, orgAccount, ouID } = awsSpec
  return (
    <>
      <Typography variant='body-regular'>
        {awsSpec.orgAccount ? (
          <>
            Please retrieve the <strong>Amazon Resource Name (ARN)</strong> from the 'Output' tab
            and the <strong>Organizational Unit Identifier (OUID)</strong> from the 'Parameters' tab
            of the CloudFormation stack that was previously created on AWS and enter them below.
          </>
        ) : (
          <>
            Please retrieve the <strong>Amazon Resource Name (ARN)</strong> from the 'Output' tab of
            the CloudFormation stack that was previously created on AWS and enter them below.
          </>
        )}
      </Typography>
      <div className='mt-4'>
        <TextInput
          value={arn}
          onChange={(e) => setAwsSpec({ arn: e.target.value })}
          sx={{ width: '100%' }}
          label='Cross Account Role ARN*'
        />
      </div>
      {orgAccount && (
        <div className='mt-2'>
          <TextInput
            value={ouID}
            onChange={(e) => setAwsSpec({ ouID: e.target.value })}
            sx={{ width: '100%' }}
            label='Organization Unit (OU) ID*'
          />
        </div>
      )}
    </>
  )
}

const AccountSummary = ({}) => {
  const { awsSpec, name, description, org, isEditMode } = useAddAccountWizardContext()
  return (
    <div>
      <Typography variant='body-regular'>Summary of Account</Typography>
      <LabelContent title='Account Name' content={name} />
      <LabelContent title='Description' content={description} />
      <LabelContent
        title='Org'
        content={
          <div>
            <Typography variant='body-regular'>
              <strong>{org}</strong>
            </Typography>
            {!isEditMode && (
              <Typography variant='caption-regular'>
                The account will be added on this org.
              </Typography>
            )}
          </div>
        }
      />
      <LabelContent
        title='Is Org Account'
        content={
          <Label
            variant={awsSpec.orgAccount ? 'grayBlue' : 'gray'}
            text={awsSpec.orgAccount ? 'Yes' : 'No'}
          />
        }
      />
      <LabelContent title='ARN' content={awsSpec.arn} />
      <LabelContent title='External ID' content={awsSpec.externalID} />
      {awsSpec.orgAccount && (
        <LabelContent title='Organization Unit (OU) ID' content={awsSpec.ouID} />
      )}
    </div>
  )
}

export const useAwsSteps = () => {
  const { isEditMode } = useAddAccountWizardContext()

  return [
    !isEditMode
      ? {
          label: 'Org Setup',
          content: <OrgStep />
        }
      : null,
    {
      label: 'CF Setup',
      content: <CFTemplate />
    },
    {
      label: 'ARN, External ID and OuID Setup',
      content: <ARNOuIDStep />
    },
    {
      label: 'Summary',
      content: <AccountSummary />
    }
  ].filter((e) => e)
}
