import { faRightToBracket } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useResourceAccess } from 'Core/Hooks/useResourceAccess'
import { EntityPicker, FullScreenContentModal, FullScreenModal } from 'V2Components'
import {
  AccessRequestSubmissionV2,
  createRef,
  createRsrcKey,
  getResourceName,
  getRsrcIcon,
  useAccessCartProvider
} from 'features/resources'
import { CREDENTIALS_OBJECT_KIND, PRINCIPAL_OBJECT_KINDS } from 'features/targets/utils'
import { SnackBar, TargetInputModal } from 'procyon-ui'
import React, { useState } from 'react'
import { KubeClusterRoleSidebarPicker } from '../KubeClusterRoleSidebarPicker'
import { BundleCreateModal } from 'features/bundles'
import { useUser } from 'Core/Hooks/useUser'

/**
 * @typedef {{
 * principalModal                    : boolean,
 * userGroupSelectionModal           : boolean
 * accessRequestSubmissionModal      : boolean,
 * kubeAppRoleSelector               : boolean
 * kubeAppRoleSelectorCartItems      : boolean
 * }} Views
 *
 * @param {{
 * views                            : Views,
 * mode?                            : 'EDIT' | 'CREATE',
 * mainTitle                        : string,
 * selectedRsrc?                    : any,
 * onViewsChange                    : ((views:Views) => void),
 * setSelectedRsrc                  : ((rsrc) => void),
 * onRequestSubmission              : ((data) => void)
 * userGroupSelectionPrompt?        : boolean,
 * showRequestForUserGroupsSummary? : boolean,
 * }} param0
 * @returns
 */
function TargetsResourceManager({
  views,
  mainTitle,
  selectedRsrc,
  onViewsChange,
  setSelectedRsrc,
  onRequestSubmission,
  userGroupSelectionPrompt,
  showRequestForUserGroupsSummary
}) {
  const [selectedEntities, setSelectedEntities] = useState([])
  const [showBundleCreateModal, setShowBundleCreateModal] = useState(false)
  const [bundleK8RolesPick, setBundleK8RolesPick] = useState(false)
  const selectedRsrcKind = selectedRsrc?.ObjectMeta.Kind
  const { appView } = useAppView()
  const { user } = useUser()
  const { updateRsrcMeta, updateResources, updateEntities, updateSpec } = useResourceAccess()
  const { slices } = useMultiSlice(['userList', 'groupList'])
  const { addItemsToCart, getResourceFromCart, cartItems, updateCartQueueItem, clearCartItems } =
    useAccessCartProvider()

  const selectedRsrcInQueue = getResourceFromCart(createRef(selectedRsrc))

  const [principal, setPrincipal] = useState(selectedRsrcInQueue?.Principal || '')

  const getEntities = () => {
    const list = [...slices.userList, ...slices.groupList]
    return list
  }

  const handleTargetPrincipalConfirmClick = (e) => {
    if (selectedRsrcInQueue) {
      updateCartQueueItem({
        resourceRef: createRef(selectedRsrc),
        principal
      })
    } else {
      addItemsToCart({
        resourceRef: createRef(selectedRsrc),
        principal,
        roles: []
      })
    }
    setPrincipal('')
    onViewsChange && onViewsChange({ ...views, principalModal: false })
  }

  const handleViewsChange = (e = {}) => {
    onViewsChange && onViewsChange({ ...views, ...e })
  }

  const handleRsrcEditClick = (ref, rsrc) => {
    if (ref.RefKind === 'KubeCluster' || ref.RefKind === 'KubeNamespace') {
      setSelectedRsrc(rsrc)
      updateRsrcMeta(createEntitiesResourceMetaObj({ k8Roles: [], k8Ref: null }))
      handleViewsChange({ accessRequestSubmissionModal: false, kubeAppRoleSelector: true })
      return
    }
    if (!PRINCIPAL_OBJECT_KINDS.includes(ref.RefKind)) return
    const rsrcFromQueue = getResourceFromCart({ ...ref })
    setPrincipal(rsrcFromQueue?.Principal)
    setSelectedRsrc(rsrc)
    handleViewsChange({ principalModal: true, accessRequestSubmissionModal: false })
  }

  const handleDismissResourcesQueue = () => {
    clearCartItems()
    submissionCleanUp()
  }

  const createEntitiesResourceMetaObj = ({ k8Roles, k8Ref }) => {
    const obj = {}
    cartItems.forEach(({ Principal, Resource, Roles }) => {
      const temp = {}
      if (Principal) temp.Principal = Principal
      if (k8Ref && k8Roles && createRsrcKey(k8Ref) === createRsrcKey(Resource) && k8Roles?.length) {
        temp.Roles = k8Roles
      } else {
        if (Roles?.ObjectRef?.length) temp.Roles = Roles.ObjectRef
      }
      obj[createRsrcKey(Resource)] = temp
    })
    return obj
  }

  const updateResourcesAccessSlice = ({ k8Roles = [], k8Ref = null } = {}) => {
    //!TODO startDate should not be updated from here
    updateSpec({ startDate: new Date() })
    updateResources(cartItems.map((e) => e.Resource))
    updateEntities(selectedEntities.map(createRef))
    updateRsrcMeta(createEntitiesResourceMetaObj({ k8Roles, k8Ref }))
  }

  const onUserGroupContinue = () => {
    updateResourcesAccessSlice({ k8Roles: [], k8Ref: null })
    handleViewsChange({ userGroupSelectionModal: false, accessRequestSubmissionModal: true })
  }

  const afterSubmit = () => {
    handleViewsChange({
      principalModal: false,
      accessRequestSubmissionModal: false,
      userGroupSelectionModal: false
    })
    handleDismissResourcesQueue()
    setSelectedEntities([])
    setPrincipal('')
  }

  const onSubmissionCancel = () => {
    handleViewsChange({ accessRequestSubmissionModal: false })
    setSelectedEntities([])
    setPrincipal('')
    submissionCleanUp()
  }
  const submissionCleanUp = () => {
    setDateTimeError({
      startDate: '',
      endDate: ''
    })
  }

  const [dateTimeError, setDateTimeError] = useState({
    startDate: '',
    endDate: ''
  })

  const getAllUserList = () => {
    if (CREDENTIALS_OBJECT_KIND.includes(selectedRsrcKind)) {
      /**
       * show only those principle which principal is associated with logedin user and non associated principles
       */
      const userMap = selectedRsrc?.Spec?.UserMap?.Map || {}
      const credentialsMap = selectedRsrc?.Spec?.Credentials?.Map || {}
      const matchedUser = Object.entries(userMap).filter(
        ([key, value]) => value.RefID === user.ObjectMeta.ID
      )
      const matchedKeys = new Set(matchedUser.map((item) => item[0]))
      const associatedCreds = Object.keys(credentialsMap).filter((key) =>
        userMap.hasOwnProperty(key)
      )
      const nonAssociatedCreds = Object.keys(credentialsMap).filter(
        (key) => !userMap.hasOwnProperty(key)
      )
      const matchedCredentials = associatedCreds.filter((key) => matchedKeys.has(key))

      if (appView === 'admin') {
        return [...associatedCreds, ...nonAssociatedCreds].filter(
          (value) => !value.startsWith('placeholder')
        )
      } else {
        return [...matchedCredentials, ...nonAssociatedCreds].filter(
          (value) => !value.startsWith('placeholder')
        )
      }
    }
    return []
  }

  const handleContinueClick = () => {
    const k8Rrscs = cartItems.filter((e) => e.Resource.RefKind === 'KubeNamespace' && e.Principal)

    if (k8Rrscs.length >= 1) {
      // Must be null for KubeClusterRoleSidebarPicker to use the cart items
      setSelectedRsrc(null)
      onViewsChange({ ...views, kubeAppRoleSelectorCartItems: true })
      return
    }

    if (userGroupSelectionPrompt) onViewsChange({ ...views, userGroupSelectionModal: true })
    else {
      updateResourcesAccessSlice()
      onViewsChange({ ...views, accessRequestSubmissionModal: true })
    }
  }

  const handleBundleCreateClick = () => {
    const k8Rrscs = cartItems.filter((e) => e.Resource.RefKind === 'KubeNamespace' && e.Principal)

    if (k8Rrscs.length >= 1) {
      // Must be null for KubeClusterRoleSidebarPicker to use the cart items
      setSelectedRsrc(null)
      onViewsChange({ ...views, kubeAppRoleSelectorCartItems: true })
      setBundleK8RolesPick(true)
      return
    }

    setShowBundleCreateModal(true)
  }

  useHTMLBodyScrollbar(Object.values(views).some((e) => e))

  const RsrcIcon = getRsrcIcon(selectedRsrc)

  const getQueueItemCount = () => {
    let count = 0
    cartItems.forEach((item) => {
      if (item.Resource.RefKind === 'KubeNamespace' && item.Principal) {
        count = count + item.Principal.split(',').length
      } else {
        count += 1
      }
    })
    return count
  }

  return (
    <div className='relative'>
      {views.kubeAppRoleSelector && (
        <KubeClusterRoleSidebarPicker
          kubeCluster={selectedRsrc}
          onContinue={(isK8RsrcsPolicy, { roles, k8Ref }) => {
            // If the policy is not for k8 rsrcs rather than k8 itself return
            if (!isK8RsrcsPolicy) {
              onViewsChange({ ...views, kubeAppRoleSelector: false })
              return
            }
            if (userGroupSelectionPrompt)
              onViewsChange({ ...views, userGroupSelectionModal: true, kubeAppRoleSelector: false })
            else {
              // Created/Added role for k8 rsrcs
              updateResourcesAccessSlice({ k8Roles: roles, k8Ref })
              onViewsChange({
                ...views,
                accessRequestSubmissionModal: true,
                kubeAppRoleSelector: false
              })
            }
          }}
          onCancel={() => {
            handleViewsChange({ kubeAppRoleSelector: false })
          }}
        />
      )}
      {views.kubeAppRoleSelectorCartItems && (
        <KubeClusterRoleSidebarPicker
          kubeCluster={null}
          onContinue={(isK8RsrcsPolicy, { roles, k8Ref }) => {
            if (bundleK8RolesPick) {
              setShowBundleCreateModal(true)
              setBundleK8RolesPick(false)
              onViewsChange({ ...views, kubeAppRoleSelectorCartItems: false })
              return
            }
            // If the policy is not for k8 rsrcs rather than k8 itself return
            if (!isK8RsrcsPolicy) {
              onViewsChange({ ...views, kubeAppRoleSelectorCartItems: false })
              return
            }
            if (userGroupSelectionPrompt)
              onViewsChange({
                ...views,
                userGroupSelectionModal: true,
                kubeAppRoleSelectorCartItems: false
              })
            else {
              // Created/Added role for k8 rsrcs
              updateResourcesAccessSlice({ k8Roles: roles, k8Ref })
              onViewsChange({
                ...views,
                accessRequestSubmissionModal: true,
                kubeAppRoleSelectorCartItems: false
              })
            }
          }}
          onCancel={() => {
            handleViewsChange({ kubeAppRoleSelectorCartItems: false })
          }}
        />
      )}
      {views.principalModal &&
        selectedRsrc &&
        PRINCIPAL_OBJECT_KINDS.includes(selectedRsrcKind) && (
          <div className='fixed bottom-4 right-4 z-50'>
            <TargetInputModal
              textInputValue={principal}
              confirmButtonLabel={selectedRsrcInQueue ? 'Update Principal' : 'Add To Queue'}
              icon={RsrcIcon && <RsrcIcon />}
              onChangeTextInput={(value) => setPrincipal(value)}
              autoComplete
              autoCompleteOptions={getAllUserList()}
              onClickCloseButton={() => {
                handleViewsChange({ principalModal: false })
                setPrincipal('')
              }}
              onClickConfirm={() => principal !== '' && handleTargetPrincipalConfirmClick()}
              title={getResourceName(selectedRsrc)}
            />
          </div>
        )}
      <FullScreenModal showModal={views.accessRequestSubmissionModal}>
        {views.accessRequestSubmissionModal && (
          <div className='h-[100%] bg-white'>
            <AccessRequestSubmissionV2
              onAddMoreResourcesClick={() =>
                handleViewsChange({ accessRequestSubmissionModal: false })
              }
              onRsrcEditClick={handleRsrcEditClick}
              loadingMessage={`Creating ${mainTitle}`}
              headerName={appView === 'admin' ? 'Create Policy' : 'Access Request Submission'}
              showRequestForUserGroupsSummary={showRequestForUserGroupsSummary}
              mainTitle={mainTitle}
              onSubmit={onRequestSubmission}
              afterSubmit={afterSubmit}
              onCancel={onSubmissionCancel}
              onManageUserGroupsClick={() => handleViewsChange({ userGroupSelectionModal: true })}
              dateTimeError={dateTimeError}
              setDateTimeError={setDateTimeError}
            />
          </div>
        )}
      </FullScreenModal>

      {views.userGroupSelectionModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title='Users and Groups in this Policy'
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={getEntities()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => handleViewsChange({ userGroupSelectionModal: false })}
            onContinue={onUserGroupContinue}
          />
        </FullScreenContentModal>
      )}

      {getQueueItemCount() > 0 && (
        <div className='fixed bottom-4 right-9'>
          <SnackBar
            showDropDownButton
            onClickContinueButton={handleContinueClick}
            leftIcon={faRightToBracket}
            menuItems={[
              appView === 'admin' && {
                label: 'Create Bundle',
                onClick: handleBundleCreateClick
              },
              {
                label: 'Dismiss',
                onClick: handleDismissResourcesQueue
              }
            ].filter((e) => !!e)}
            textMessage={`${getQueueItemCount()} Resources on Queue`}
            variant='primary'
          />
        </div>
      )}
      {showBundleCreateModal && (
        <BundleCreateModal
          reqType='Target'
          resoucesWithRoles={cartItems}
          onCancel={() => setShowBundleCreateModal(false)}
          onSuccess={() => {
            clearCartItems()
            setShowBundleCreateModal(false)
          }}
        />
      )}
    </div>
  )
}

export { TargetsResourceManager }
