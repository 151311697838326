
import { Box } from '@material-ui/core'
import AnalyticTable from 'Components/AnalyticTable/AnalyticTable'
import ExpandableContainer from 'Components/ExpandableContainer'
import ModalView from 'Components/ExpandableContainer/components/ModalView'
import FallBack from 'Components/Fallback'
import React from 'react'

import { options, columns } from './TableMeta'
const ActivityTable = ({ data, loading }) => {
  const Table = ({ partialData = false, searchString = '' }) => {
    const tableData = partialData ? [...data].slice(0, 5) : data
    return <AnalyticTable searchAll searchString={searchString} vAlign='top' columns={columns()} options={options()} data={tableData} />
  }
  const ModalComponent = ({ closeModal }) => (
    <ModalView
      searchPlaceholder='Enter Resource Name'
      closeModal={closeModal}
      Table={({ searchKey }) => <Table partialData={false} searchString={searchKey} />}
    />
  )
  return (
    <ExpandableContainer
      expandButtonLabel='Expand All'
      modalTitle='Resource Activity'
    // containerHeight='19.5rem'
      ModalComponent={ModalComponent}
    >
      <Box px={1} minHeight='16rem'>
        {!loading && <Table partialData />}
        {loading && <FallBack minHeight='25vh' />}
      </Box>
    </ExpandableContainer>
  )
}

export default ActivityTable
