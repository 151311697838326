import { Link } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LabelContent } from 'V2Components'
import { getResourceName, getRsrcAccountRef, getRsrcIcon, getRsrcURL } from 'features/resources'
import _ from 'lodash'
import { Typography } from 'procyon-ui'
import React from 'react'

const RESOURCE_KIND_INFO_MAP = {
  AppRole: {
    Name: (r, _hit) => r?.Spec.RoleName,
    ARN: (r) => r.Spec.Attributes.Map['aws-role-arn']
  },
  Application: {
    Host: (r, hit) => hit._source.Data.Host,
    Method: (_r, hit) => hit._source.Data.Method,
    Path: (_r, hit) => hit._source.Data.Path
  }
}

const ResourceTab = ({ resource, hit }) => {
  const { getObjectRef } = useMultiSlice(['accountList'])
  const { appView } = useAppView()

  if (!resource)
    return <Typography variant='body-regular'>Resource does not exist for this log.</Typography>

  const rsrcObject = resource.rsrc

  const account = rsrcObject ? getObjectRef(getRsrcAccountRef(rsrcObject)) : null

  const RsrcIcon = getRsrcIcon(resource.rsrc ? resource.rsrc : resource.Kind)
  const AccountIcon = account && getRsrcIcon(account)

  const getLabelContents = () => {
    if (!rsrcObject) return []

    const renderList = []
    const namesMap = RESOURCE_KIND_INFO_MAP[resource.Kind] || {}

    for (const key in namesMap) {
      const dataNameFn = namesMap[key]
      renderList.push(<LabelContent title={key} content={dataNameFn(rsrcObject, hit)} />)
    }
    return renderList
  }

  return (
    <div>
      <LabelContent
        title='Resource Name'
        content={
          <div className='flex gap-2 items-center'>
            <RsrcIcon />
            <Typography className='!text-[#658BD3]' variant='body-regular'>
              {rsrcObject ? (
                <Link to={getRsrcURL(rsrcObject, appView)}>{getResourceName(rsrcObject)}</Link>
              ) : (
                resource.Name
              )}
            </Typography>
          </div>
        }
      />
      {getLabelContents()}
      {account && (
        <LabelContent
          title='Account'
          content={
            <div className='flex gap-2 items-center'>
              <AccountIcon className='mr-2' />
              {appView === 'admin' ? (
                <Typography className='!text-[#658BD3]' variant='body-regular'>
                  <Link
                    to={`/admin/clouds/${account.Spec.Type.toLowerCase()}/${
                      account.ObjectMeta.Name
                    }/overview`}
                  >
                    {getResourceName(account)}
                  </Link>
                </Typography>
              ) : (
                <Typography variant='body-regular'>{getResourceName(account)}</Typography>
              )}
            </div>
          }
        />
      )}
    </div>
  )
}

export { ResourceTab }
