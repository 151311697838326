import { makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { PropTypes } from 'Core'
import React from 'react'
const useStyle = makeStyles((theme) => ({
  font: {
    height: theme.spacing(1.5),
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.12px'
  }
}))
const Title = ({ color = 'initial', className = '', children, ...rest }) => {
  const internalClasses = useStyle()
  return (
    <Typography color={color} className={classNames(internalClasses.font, className)} {...rest}>{children}</Typography>
  )
}
Title.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'textPrimary', 'textSecondary', 'error', 'initial', 'inherit'])
}
export default Title
