import { faSignalBars } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { LabelContent } from 'V2Components'
import { ErrorLabelContent, getResourceName, getRsrcIcon, getRsrcURL } from 'features/resources'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const KubeNamespace = ({ rsrc, account }) => {
  const { getObjectRef, selectDispatch } = useMultiSlice(['kubeClusters'])
  const { appView } = useAppView()

  const cluster = getObjectRef(rsrc.Spec.Cluster)
  const AccountIcon = getRsrcIcon(account)

  useEffect(() => {
    selectDispatch(['kubeClusters'])
  }, [])

  return (
    <>
      <ErrorLabelContent rsrc={rsrc} />

      <LabelContent title='Name' content={getResourceName(rsrc)} />
      <LabelContent
        title='Account'
        content={
          <div className='flex gap-2 items-center'>
            {AccountIcon && <AccountIcon className='mr-2' />}
            <Typography variant='body-regular'>{getResourceName(account)}</Typography>
          </div>
        }
      />
      <LabelContent
        title='Cluster'
        content={
          cluster ? (
            <Link to={getRsrcURL(cluster, appView)}>
              <Label
                icon={<TargetIcon type='KUBE_CLUSTERS' />}
                text={getResourceName(cluster)}
                variant='grayBlue'
              />
            </Link>
          ) : (
            <Label text={`Cluster Not Found: ${rsrc.Spec.Cluster.RefID}`} />
          )
        }
      />
      <LabelContent title='Region' content={rsrc.Spec.Region} />
    </>
  )
}

export { KubeNamespace }
