import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  loaded: false
}

const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    /**
     *
     * @param {{ type: string, payload: any}} action
     */
    updateBookmarks(state, action) {
      state.data = action.payload
      state.loaded = true
      return state
    },
    /**
     *
     * @param {{ type: string, payload: any[]}} action
     */
    updateBookmarksRefs(state, action) {
      if (state.data.Refs.ObjectRef) {
        state.data.Refs.ObjectRef = action.payload
      }
      return state
    }
  }
})

export const { updateBookmarks, updateBookmarksRefs } = bookmarksSlice.actions
export default bookmarksSlice.reducer
