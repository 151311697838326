import { ResponsiveTable, Typography } from 'procyon-ui'
import React from 'react'
import { columns, useTopResources } from './TopResources.utils'

export const TopResources = () => {
  const { rowData, startDays } = useTopResources()
  return (
    <div className='border rounded p-5 w-[100%] h-[480px] overflow-auto'>
      <Typography variant='h2'>Top Resources</Typography>
      <Typography className='!text-gray-400' variant='title-regular'>
        sessions last {startDays} days
      </Typography>
      <div className='mt-6'>
        <ResponsiveTable
          // onRow={(data) => {
          //   return {
          //     onClick() {
          //       const SessionKey = data.id
          //       const log = _.find(sessions, { SessionKey })
          //       if (!log) return
          //       onLogClick(log)
          //     }
          //   }
          // }}
          columns={columns()}
          data={rowData}
          pagination={{
            pageSize: 5
          }}
        />
      </div>
    </div>
  )
}

const ResourceTypeMap = {
  AppRole: 'targets',
  Server: 'targets',
  Application: 'applications'
}
