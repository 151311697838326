import { Box, IconButton, Tooltip } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { CustomTypography } from 'Components/CustomTypography'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

const BackUrl = ({ backURL }) => {
  const history = useHistory()

  const onBackClick = useCallback(() => {
    if (!backURL.link) {
      if (history.action !== 'POP') {
        // The user can go back
        history.goBack()
      } else {
        // the user can't go back hence, fallback
        history.push(backURL.fallback || '/')
      }
    } else history.push(backURL.link)
  }, [backURL])

  return (
    <Box display='flex' alignItems='center'>
      <Tooltip placement='top' title={backURL?.title}>
        <Box display='flex' alignItems='center'>
          <IconButton onClick={onBackClick} size='small'>
            <ChevronLeft color='primary' />
          </IconButton>
          <Box ml={0.3}>
            <CustomTypography.Body classNaw>{backURL?.title}</CustomTypography.Body>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default BackUrl
