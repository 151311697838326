import {
  faExclamation,
  faGear,
  faTriangleExclamation,
  faUser
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createRsrcKey, getResourceName } from 'features/resources'
import { useServerControllerWizardContext } from 'features/serverControllers'
import { getUserServerName } from 'features/users'
import { createDataSelectorHook } from 'infra/redux'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { EntityInfoBar, EntityPicker, FullScreenContentModal } from 'V2Components'

const useSlices = createDataSelectorHook(['userList', 'groupList', 'tagPolicys', 'accountList'])

const SelectUsersStep = () => {
  const [showUsersModal, setShowUsersModal] = useState(false)
  const { users, updateConfig } = useServerControllerWizardContext()

  const { slices } = useSlices()

  const getUsers = () => {
    return slices.userList.filter((usr) => !!getUserServerName(usr))
  }

  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <div>
          <Typography variant='h4-regular'>Users</Typography>
          <Typography className='mt-1' variant='caption-regular'>
            {users.length} Users Selected
          </Typography>
        </div>
        <Button variant='grayBlue' icon={faGear} onClick={() => setShowUsersModal(true)}>
          Configure Users
        </Button>
      </div>

      <div className='max-h-[400px] min-h-[200px] overflow-auto'>
        {users.map((entity) => {
          const serverName = getUserServerName(entity)
          return (
            <div className='-ml-3'>
              <EntityInfoBar
                width='100%'
                endButton={false}
                key={createRsrcKey(entity)}
                kind={entity.ObjectMeta.Kind}
                name={getResourceName(entity)}
                //@ts-ignore
                caption={serverName ? `User Name: ${serverName}` : <em>No User Name</em>}
              />
            </div>
          )
        })}

        {users.length === 0 && (
          <div className='h-[200px] flex items-center justify-center '>
            <Typography
              onClick={() => setShowUsersModal(true)}
              variant='caption-regular'
              className='flex flex-col gap-2 items-center justify-center cursor-pointer hover:opacity-80'
            >
              <FontAwesomeIcon icon={faUser} size='2x' />
              Select Users
            </Typography>
          </div>
        )}
      </div>

      {showUsersModal && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            entities={getUsers()}
            selectedEntities={users}
            setSelectedEntities={(us) => updateConfig({ users: us })}
            onCancel={() => setShowUsersModal(false)}
            onContinue={() => setShowUsersModal(false)}
            title='Select Users'
          />
        </FullScreenContentModal>
      )}
    </div>
  )
}

export { SelectUsersStep }
