import { DataTable } from 'procyon-ui'
import React from 'react'
import { tableColumns } from './utils'
import useMemoOnce from 'Core/Hooks/useMemoOnce'

function ListTable({ data }) {

  const columns = useMemoOnce(tableColumns())

  return (
    <div className='w-[100%]'>
      <DataTable
        width='100%'
        autoHeight
        className='w-[100%]'
        columns={columns}
        rows={data}
      />
    </div>
  )
}

export { ListTable }