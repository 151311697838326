import { Box, Checkbox, makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
const useStyles = makeStyles(theme => ({
  colorBox: {
    // @ts-ignore
    background: props => props.color,
    width: '12px',
    height: '12px'
  },
  icon: {
    width: '20px',
    height: '20px',
    fontSize: '20px',
    fill: theme.palette.text.disabled
  }
}))
const FilterItem = ({ changeFilter, chacked, name, label, color, Icon, disabled = false }) => {
  const classes = useStyles({ color })
  return (
    <Tooltip title={label}>
      <Box ml={1} display='flex' alignItems='center'>
        {!disabled && <Checkbox disabled={disabled} color='primary' size='small' onClick={() => changeFilter(name)} checked={chacked} />}
        <Box mr={0.25} ml={0.3} className={classes.colorBox} />
        {Icon && <Icon className={classes.icon} />}
      </Box>
    </Tooltip>
  )
}

export default FilterItem
