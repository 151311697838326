import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShimmerRow } from 'Utils/ShimmerEffect'
import { ChannelRow, TargetIcon, TreeItem, TreeView } from 'procyon-ui'
import React, { useEffect, useState } from 'react'

const MsChannels = ({
  handleManageAccount,
  handleUnlinkAllAccount,
  filteredChannel,
  getCloudsAccountCount,
  getTotalLinkedAccount,
  msTeamsChannels
}) => {
  const [showShimmer, setShowShimmer] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowShimmer(false)
    }, 2000)
  }, [filteredChannel])

  const renderChannelTree = (channel) => {
    const filterTeamChannels = msTeamsChannels.filter((channels) => {
      const msTeamObjectRef = channels.Spec.MsTeamsTeam.ObjectRef[0]
      if (msTeamObjectRef.RefID === channel.ObjectMeta.ID) {
        return channels
      }
    })

    return filterTeamChannels.map((item) => (
      <ChannelRow
        key={item.id}
        awsButtonNumber={getCloudsAccountCount(item, 'AWS').length}
        azureButtonNumber={getCloudsAccountCount(item, 'AZURE').length}
        channelName={item.Spec.ChannelName}
        dropdownMenuItems={[
          {
            submenu: [
              {
                action: () => handleManageAccount(item),
                title: 'Manage'
              },
              {
                action: () => handleUnlinkAllAccount(item),
                title: 'Unlink All'
              }
            ],
            title: <FontAwesomeIcon icon={faEllipsisVertical} />
          }
        ]}
        googleButtonNumber={getCloudsAccountCount(item, 'GCP').length}
        onClickAwsButton={function noRefCheck() {}}
        onClickAzureButton={function noRefCheck() {}}
        onClickGoogleButton={function noRefCheck() {}}
        onClickRepositories={function noRefCheck() {}}
        onClickUserGroups={function noRefCheck() {}}
        showAwsButton
        showAzureButton
        showDropdownButton
        showGoogleButton
        totalAccounts={`Total ${getTotalLinkedAccount(item).length}`}
        type={'TEAMS_CHANNEL'}
      />
    ))
  }

  return (
    <div>
      {showShimmer ? (
        <div className='flex flex-col w-100% '>
          <ShimmerRow />
          <ShimmerRow />
          <ShimmerRow />
          <ShimmerRow />
        </div>
      ) : (
        <TreeView
          defaultExpanded={['0']}
          height={'100%'}
          maxWidth={'100%'}
          onNodeFocus={function noRefCheck() {}}
          onNodeSelect={function noRefCheck() {}}
          onNodeToggle={function noRefCheck() {}}
          sx={{
            flexGrow: 1,
            overflowY: 'auto'
          }}
        >
          {filteredChannel &&
            filteredChannel.map((item, index) => (
              <TreeItem
                labelIcon={<TargetIcon type='TEAMS' />}
                labelText={item.Spec.Name}
                nodeId={index}
                key={index}
              >
                {renderChannelTree(item)}
              </TreeItem>
            ))}
        </TreeView>
      )}
    </div>
  )
}

export { MsChannels }
