import React from 'react'
import { Route } from 'react-router'
import { CreateTagPolicy } from '../views/CreateTagPolicy'

const TagsRoutes = () => {
  return (
    <>
      {/* @ts-ignore*/}
      <Route exact path={['/admin/tags/policy/create']} component={CreateTagPolicy} />
    </>
  )
}

export { TagsRoutes }
