import ResourceInfo from 'Components/AnalyticTable/components/ResourceInfo'
import TableHeader from 'Components/AnalyticTable/components/TableHeader'
import Count from 'Components/AnalyticTable/components/Count'
export const options = () => ({
  filterType: 'checkbox',
  rowsPerPage: 10,
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  pagination: false,
  selectableRows: false,
  rowsPerPageOptions: [5, 10, 15, 100]
})

export const columns = () => [
  {
    name: 'index',
    label: ' ',
    options: {
      filter: false
    }
  },

  {
    name: 'resourceInfo',
    label: 'Resource/Account',
    options: {
      filter: false,
      sort: true,
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      customBodyRender: (value, tableMeta) => {
        return <ResourceInfo width='9rem' data={value} />
      }
    }
  },
  {
    name: 'dailyAverage',
    label: 'Avg daily users',
    options: {
      customHeadLabelRender: (columnMeta) => <TableHeader columnMeta={columnMeta} />,
      setCellProps: () => ({ style: { width: '12rem' } }),
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return <Count count={value} />
      }

    }
  },
  {
    name: 'resourceName',
    label: ' ',
    options: {
      searchable: true,
      display: false
    }
  }
]
