import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(4.6),
    position: 'relative'
  },
  content: {
    backgroundColor: 'white',
    width: '671px',
    position: 'fixed',
    height: '100vh',
    overflowY: 'auto',
    maxHeight: '100%',
    top: 0,
    zIndex: 500,
    paddingTop: theme.spacing(4)
  },
  right: {
    right: 0
  },
  left: {
    left: 0
  },
  'overlay-right': {
    position: 'fixed',
    top: 0,
    height: '100%',
    width: 'calc(100% - 671px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
    zIndex: 1001,
    left: 0
  },
  'overlay-left': {
    position: 'fixed',
    top: 0,
    height: '100%',
    width: 'calc(100% - 671px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
    zIndex: 1001,
    right: 0
  }
}))

function FloatingDrawer({ showDrawer, children, floatDirection = 'right', onOverlayClick }) {
  const classes = useStyles()
  if (!showDrawer) return null
  return (
    <div className={classes.root}>
      <div className={classNames(classes[`overlay-${floatDirection}`], 'glass-morph')} onClick={onOverlayClick}></div>
      <div className={classNames(classes.content, { [classes[floatDirection]]: true })}>{children}</div>
    </div>
  )
}

export { FloatingDrawer }

