import { CopyText, LabelContent } from 'V2Components'
import { useAddAccountWizardContext, useSAMLServers } from 'features/clouds'
import {
  AZURE_AUTO_ID_PROVIDER_SA_PERMISSIONS,
  AZURE_AUTO_USERS_PERMISSIONS,
  AZURE_MANUAL_ID_PROVIDER_SA_PERMISSIONS,
  AZURE_MANUAL_USERS_PERMISSIONS,
  AZURE_MUST_SA_PERMISSIONS
} from 'features/clouds/utils/azure'
import { Label, LabelCheckbox, TextInput, Tooltip, Typography } from 'procyon-ui'
import React, { useMemo } from 'react'

const AccountConfig = () => {
  const { azureSpec, setAzureSpec } = useAddAccountWizardContext()

  return (
    <div>
      <Typography variant='body-regular'>Select the following options</Typography>
      <div>
        <div className='mt-2'>
          <LabelCheckbox
            checked={azureSpec.isSAMLConfigurationAllowed}
            name='Allow Procyon to Configure SAML ID'
            onClick={() =>
              setAzureSpec({ isSAMLConfigurationAllowed: !azureSpec.isSAMLConfigurationAllowed })
            }
          />
          <LabelCheckbox
            checked={azureSpec.isGuestUserDeleteAllowed}
            name='Allow Procyon to delete guest users'
            onClick={() =>
              setAzureSpec({ isGuestUserDeleteAllowed: !azureSpec.isGuestUserDeleteAllowed })
            }
          />
        </div>
      </div>
    </div>
  )
}

const SAMLConfigurationStep = () => {
  const { domain, issuerURI, metadataURI, samlCertficate, ssoURI } = useSAMLServers()

  const steps = [
    <>
      Go to the <strong>External Identities</strong> page in the Azure console.
    </>,
    <>
      Select <strong>All identity providers</strong> and <strong>New SAML/WS-Fed IdP.</strong>
    </>,
    <>
      Fill in the the fields with the following values (values from the SAML server metadata):
      <div>
        <div className='flex gap-2'>
          <CopyText
            withoutWrapperComponent
            Component={
              <div className='mt-2 w-1/2 items-center'>
                <Tooltip title='Click to Copy' arrow>
                  <TextInput
                    label='Domain Name'
                    sx={{ width: '100%' }}
                    readOnly
                    value={domain || 'Loading...'}
                  />
                </Tooltip>
              </div>
            }
            messageKey='Domain Name'
            text={domain}
          />
          <CopyText
            withoutWrapperComponent
            Component={
              <div className='mt-2 w-1/2 items-center'>
                <Tooltip title='Click to Copy' arrow>
                  <TextInput
                    label='Issuer URI'
                    sx={{ width: '100%' }}
                    readOnly
                    value={issuerURI || 'Loading...'}
                  />
                </Tooltip>
              </div>
            }
            messageKey='Issuer URI'
            text={issuerURI}
          />
        </div>
        <div className='flex gap-2'>
          <CopyText
            withoutWrapperComponent
            Component={
              <div className='mt-2 w-1/2  items-center'>
                <Tooltip title='Click to Copy' arrow>
                  <TextInput
                    label='SSO URI'
                    sx={{ width: '100%' }}
                    readOnly
                    value={ssoURI || 'Loading...'}
                  />
                </Tooltip>
              </div>
            }
            messageKey='SSO URI'
            text={ssoURI}
          />
          <CopyText
            withoutWrapperComponent
            Component={
              <div className='mt-2 w-1/2  items-center'>
                <Tooltip title='Click to Copy' arrow>
                  <TextInput
                    label='Metadata URI'
                    sx={{ width: '100%' }}
                    readOnly
                    value={metadataURI || 'Loading...'}
                  />
                </Tooltip>
              </div>
            }
            messageKey='Metadata URI'
            text={metadataURI}
          />
        </div>
        <CopyText
          withoutWrapperComponent
          Component={
            <div className='mt-2 items-center'>
              <Tooltip title='Click to Copy' arrow>
                <TextInput
                  label='SAML Certificate'
                  sx={{ width: '100%' }}
                  readOnly
                  value={samlCertficate || 'Loading...'}
                />
              </Tooltip>
            </div>
          }
          messageKey='SAML Certificate'
          text={samlCertficate}
        />
      </div>
    </>
  ]

  return (
    <div>
      <Typography className='bg-gray-100 p-1 italic !text-[13px] mb-2' variant='body-regular'>
        You can create the External ID Provider manually to avoid giving the Procyon App the
        <strong className='underline'> IdentityProvider.ReadWrite.All</strong> and{' '}
        <strong className='underline'>Domain.ReadWrite.All</strong> roles and to substitute them
        with <strong className='underline'>IdentityProvider.Read.All</strong> and
        <strong className='underline'> Domain.Read.All</strong> instead.
      </Typography>
      <Typography variant='body-regular'>Steps to configure SAML ID:</Typography>

      {steps.map((step, index) => (
        <Typography key={index} className='mt-2' variant='body-regular'>
          {index + 1}. {step}
        </Typography>
      ))}
    </div>
  )
}

const ServiceAccountCreationStep = () => {
  const { azureSpec } = useAddAccountWizardContext()

  const getPermissions = () => {
    const list = [...AZURE_MUST_SA_PERMISSIONS]
    if (azureSpec.isSAMLConfigurationAllowed) list.push(...AZURE_AUTO_ID_PROVIDER_SA_PERMISSIONS)
    else list.push(...AZURE_MANUAL_ID_PROVIDER_SA_PERMISSIONS)
    if (azureSpec.isGuestUserDeleteAllowed) list.push(...AZURE_AUTO_USERS_PERMISSIONS)
    else list.push(...AZURE_MANUAL_USERS_PERMISSIONS)
    return list
  }

  return (
    <div>
      <Typography variant='body-regular'>
        Use the following permission to create a new service account:
      </Typography>
      <div className='p-1 bg-gray-200 rounded my-2'>
        {getPermissions().map((p, index) => (
          <Typography key={index} variant='bodySmall-regular'>
            {p}
            <br />
          </Typography>
        ))}
      </div>
    </div>
  )
}

const ServiceAccountDetailsSetup = () => {
  const { azureSpec, setAzureSpec } = useAddAccountWizardContext()

  return (
    <div>
      <Typography variant='body-regular'>
        From the Service Account created on <strong>previous step</strong> enter the following
        fields.
      </Typography>
      <div className='mt-2'>
        <TextInput
          sx={{ width: '100%' }}
          label='Tenant ID*'
          value={azureSpec.tenantID}
          onChange={(e) => setAzureSpec({ tenantID: e.target.value })}
        />
      </div>
      <div className='mt-2'>
        <TextInput
          sx={{ width: '100%' }}
          label='Procyon App ID*'
          value={azureSpec.appID}
          onChange={(e) => setAzureSpec({ appID: e.target.value })}
        />
      </div>
      <div className='mt-2 mb-4'>
        <TextInput
          sx={{ width: '100%' }}
          label='Procyon App Key*'
          value={azureSpec.appKey}
          onChange={(e) => setAzureSpec({ appKey: e.target.value })}
        />
      </div>
    </div>
  )
}

const AccountSummary = ({}) => {
  const { name, description, azureSpec, org, isEditMode } = useAddAccountWizardContext()
  return (
    <div>
      <Typography variant='body-regular'>Summary of Account</Typography>
      <LabelContent title='Account Name' content={name} />
      <LabelContent title='Description' content={description} />
      <LabelContent
        title='Org'
        content={
          <div>
            <Typography variant='body-regular'>
              <strong>{org}</strong>
            </Typography>
            {!isEditMode && (
              <Typography variant='caption-regular'>
                The account will be added on this org.
              </Typography>
            )}
          </div>
        }
      />
      <LabelContent
        title='Allow Procyon to Configure SAML ID?'
        content={
          <Label variant='grayBlue' text={azureSpec.isSAMLConfigurationAllowed ? 'Yes' : 'No'} />
        }
      />
      <LabelContent
        title='Allow Procyon to delete guest users?'
        content={
          <Label variant='grayBlue' text={azureSpec.isGuestUserDeleteAllowed ? 'Yes' : 'No'} />
        }
      />
      <LabelContent title='Tenant ID' content={azureSpec.tenantID} />
      <LabelContent title='Procyon App ID' content={azureSpec.appID} />
      <LabelContent title='Procyon App Key' content={azureSpec.appKey} />
    </div>
  )
}

export const useAZURESteps = () => {
  const { azureSpec } = useAddAccountWizardContext()

  const steps = useMemo(() => {
    const sts = [
      {
        label: 'Account Configuration',
        content: <AccountConfig />
      }
    ]

    if (!azureSpec.isSAMLConfigurationAllowed) {
      sts.push({
        label: 'Saml Configuration',
        content: <SAMLConfigurationStep />
      })
    }
    return [
      ...sts,
      {
        label: 'SA Create',
        content: <ServiceAccountCreationStep />
      },
      {
        label: 'SA Credentials',
        content: <ServiceAccountDetailsSetup />
      },
      {
        label: 'Account Summary',
        content: <AccountSummary />
      }
    ]
  }, [azureSpec.isSAMLConfigurationAllowed])

  return steps
}
