import { enqueueNotification } from 'Utils/Helpers'
import { DeleteConfirmationModal } from 'V2Components'
import { getResourceName } from 'features/resources'
import { reduxApiClient } from 'infra'
import React from 'react'

function DeleteBundleModal({ bundle, onCancel, onSuccess }) {
  const bundleName = getResourceName(bundle)

  const handleDelete = async () => {
    try {
      await reduxApiClient('reqbundles').delete(bundle)
      onSuccess?.()
      enqueueNotification('Bundle deleted successfully!', 'info')
    } catch (error) {
      enqueueNotification(`Failed to delete bundle: ${bundleName}!`, 'error')
    }
  }

  return <DeleteConfirmationModal item={bundle} onCancel={onCancel} onDelete={handleDelete} />
}

export { DeleteBundleModal }
