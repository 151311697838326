import NotFound from 'Views/NotFound'
import { React } from 'Core'
import { FeatureFlagContext } from 'Core/FeatureFlagContext'

const CheckFeatureFlag = ({ children, isRoute, permission, fallback = false }) => {
  const { data: { featureFlags } } = React.useContext(FeatureFlagContext)
  if (featureFlags && featureFlags[permission]) {
    return children
  } else {
    if (fallback) {
      return fallback
    }
    /** A Route is accessed without permission */
    if (isRoute) {
      return <NotFound />
    }
    return null
  }
}

export default CheckFeatureFlag
