import { isValidEmail } from 'Utils/PureHelperFuctions'
import { useState } from 'react'

// Keys from user.Spec
// prettier-ignore
const EditableFields = [
  'City'          ,
  'State'         ,
  'ZipCode'       ,
  'EmailID'       ,
  'FullName'      ,
  'NickName'      ,
  'LastName'      ,
  'FirstName'     ,
  'MiddleName'    ,
  'MobilePhone'   ,
  'CountryCode'   ,
  'PrimaryPhone'  ,
  'StreetAddress' ,
]

export const useOverviewDetails = (user) => {
  /**
   * @typedef { { EmailID: string, City: string, State: string, ZipCode: string, FullName: string, NickName: string, LastName: string, FirstName: string, MiddleName: string, MobilePhone: string, CountryCode: string, PrimaryPhone: string, StreetAddress: string } } FormType
   * @type {[FormType, (s) => FormType]}
   *
   */
  // @ts-ignore
  const [formFields, setFormFields] = useState(() => {
    return EditableFields.reduce((p, c) => ({ ...p, [c]: user.Spec[c] }), {})
  })

  const handleFormFieldChange = (e) => {
    const { name, value } = e.target
    if (EditableFields.includes(name)) {
      setFormFields((s) => ({ ...s, [name]: value }))
    }
  }

  const getErrors = () => {
    const errorObj = {}
    if (formFields.FirstName.length <= 2)
      errorObj.FirstName = 'First Name must be greater than 2 characters.'
    if (formFields.LastName.length <= 2)
      errorObj.LastName = 'Last Name must be greater than 2 characters.'
    if (!isValidEmail(formFields.EmailID)) errorObj.EmailID = 'Invalid email.'
    return errorObj
  }

  return { formFields, handleFormFieldChange, EditableFields, getErrors }
}
