const { createSlice } = require('@reduxjs/toolkit')

const createRsrcKey = (rsrc) => {
  if (!rsrc) return null
  if (rsrc.RefKind) return `${rsrc.RefKind}+${rsrc.RefID}`
  return `${rsrc.ObjectMeta.Kind}+${rsrc.ObjectMeta.ID}`
}

/**
 * Get ref object from a key created using `createRsrcKey()`
 * @param {string} key
 * @returns {import('types').ObjectRef | undefined}
 */
const reverseRsrcKey = (key) => {
  if (typeof key !== 'string') return
  return {
    //@ts-ignore
    RefKind: key.split('+')[0],
    RefID: key.split('+')[1]
  }
}

/**
 * @typedef { {
 * name?:string,
 * comments?: string,
 * endDate?: Date,
 * startDate?: Date,
 * timeZone?: string
 * } } ResourceAccessDataSpec
 *
 * @type { {
 *   data:{
 *    spec: ResourceAccessDataSpec,
 *    entities: import('types').EntityObjectRef[],
 *    resources: import('types').ObjectRef[],
 *    resourcesMetaObj: Object.<string, any>,
 *    cloudType: 'AWS' | 'GCP' | 'AZURE' | null
 * },
 *  } }
 */

const INITIAL_STATE = {
  data: {
    spec: {
      name: '',
      comments: '',
      endDate: null,
      startDate: null,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    entities: [],
    resources: [],
    resourcesMetaObj: {},
    cloudType: null
  }
}

/**
 * @typedef {typeof INITIAL_STATE} ResourceAccessState
 */

const resourceAccessSlice = createSlice({
  name: 'resourceAccess',
  initialState: INITIAL_STATE,
  reducers: {
    /**
     * @param {{ type: string, payload: ResourceAccessDataSpec}} action
     */
    updateAccessDataSpec(state, action) {
      state.data.spec = { ...state.data.spec, ...action.payload }
    },
    /**
     *
     * @param {{ type:string, payload:import('types').EntityObjectRef[] }} action
     */
    updateAccessDataEntities(state, action) {
      state.data.entities = action.payload
      return state
    },
    /**
     *
     * @param {{ type:string, payload:import('types').ObjectRef[] }} action
     */
    toggleAccessDataEntities(state, action) {
      const n = []

      const entities = state.data.entities.map(createRsrcKey)
      const nEntities = action.payload.map(createRsrcKey)
      // Create a count map
      const countMap = [...entities, ...nEntities].reduce((prev, curr) => {
        let c = prev[curr] || 0
        c += 1
        prev[curr] = c
        return prev
      }, {})
      // All keys whose count is 1 is pushed
      for (const key in countMap) if (countMap[key] === 1) n.push(key)
      // Update entites after reversing the string to ref object
      // @ts-ignore
      state.data.entities = n.map(reverseRsrcKey)
      return state
    },
    /**
     *
     * @param {{ type:string, payload:import('types').ObjectRef[] }} action
     */
    updateAccessDataResources(state, action) {
      state.data.resources = action.payload
      return state
    },
    /**
     *
     * @param {{ type:string, payload:import('types').ObjectRef[] }} action
     */
    removeAccessDataResources(state, action) {
      const newResources = []
      const keys = action.payload.map(createRsrcKey)
      state.data.resources.forEach((r) => {
        if (!keys.includes(createRsrcKey(r))) newResources.push(r)
      })

      state.data.resources = newResources
      return state
    },
    /**
     *
     * @param {{ type:string, payload:import('types').ObjectRef[] }} action
     */
    toggleAccessDataResources(state, action) {
      const n = []
      const rsrcs = state.data.resources.map(createRsrcKey)
      const nRsrcs = action.payload.map(createRsrcKey)
      // Create a count map
      const countMap = [...rsrcs, ...nRsrcs].reduce((prev, curr) => {
        let c = prev[curr] || 0
        c += 1
        prev[curr] = c
        return prev
      }, {})
      // All keys whose count is 1 is pushed
      for (const key in countMap) if (countMap[key] === 1) n.push(key)
      // Update entites after reversing the string to ref object
      // @ts-ignore
      state.data.entities = n.map(reverseRsrcKey)
      return state
    },
    /**
     *
     * @param {{ type: string, payload: object }} action
     */
    updateAccessDataResourceMeta(state, action) {
      state.data.resourcesMetaObj = { ...state.data.resourcesMetaObj, ...action.payload }
    },
    clearAccessData() {
      return INITIAL_STATE
    }
  }
})

export const {
  toggleAccessDataEntities,
  toggleAccessDataResources,
  updateAccessDataResourceMeta,
  updateAccessDataSpec,
  clearAccessData,
  updateAccessDataResources,
  updateAccessDataEntities,
  removeAccessDataResources
} = resourceAccessSlice.actions

export default resourceAccessSlice.reducer
