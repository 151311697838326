import { useEffect } from 'react'

/**
 * IMPORTANT! DO NOT USE THIS inside multiple children of the same components,
 * you might encounter bugs.
 * The hook provides a way to hide the scrollbar from body tag, used by modals and drawers for fullscreen and preven
 * scrolling of body
 * @param {boolean} hideScrollbar Hide scrollbar from body or not
 */
const useHTMLBodyScrollbar = (hideScrollbar) => {
  useEffect(() => {
    const body = document.querySelector('body')
    if (hideScrollbar && body.style.overflow === 'hidden') return
    if (hideScrollbar) {
      body.style.overflow = 'hidden'
      return () => {
        body.style.overflow = 'auto'
      }
    }
    body.style.overflow = 'auto'
  }, [hideScrollbar])
}

export default useHTMLBodyScrollbar
