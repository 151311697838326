import { getDateWithOptions } from 'features/approvals'
import moment from 'moment'
import { SelectDate, SelectDropDown, SelectTime, Typography } from 'procyon-ui'
import React, { useEffect, useMemo, useRef, useState } from 'react'

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'

function StartEndTimePicker({ onStartDateChange, onEndDateChange, onError = null }) {
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(null)
  const [endDateFormat, setEndDateFormat] = useState('hours') // 'hours' | 'days' | 'months' | 'weeks' | 'custom'
  const [endDateCountOption, setEndDateCountOption] = useState('1')
  const endDatesOptions = useMemo(() => getDateWithOptions(startDate), [startDate])
  const hasStartDateChanged = useRef(false)
  const [errors, setErrors] = useState({
    startDate: '',
    endDate: ''
  })

  const handleStartDateChange = (e) => {
    hasStartDateChanged.current = true
    const newStartDate = moment(`${e.target.value} ${startDate.format(TIME_FORMAT)}`)
    setStartDate(newStartDate)
  }

  const handleStartTimeChange = (e) => {
    hasStartDateChanged.current = true
    const newStartDate = moment(` ${startDate.format(DATE_FORMAT)} ${e.target.value}`)
    setStartDate(newStartDate)
  }

  /**
   * Updates the selectedEndDateOption and endDate, React batching is in effect
   */
  const handleEndDateOptionChange = (e) => {
    if (e.target.value === 'custom') return
    const date = endDatesOptions[endDateFormat][e.target.value.split('+')[1]]
    setEndDateCountOption(e.target.value)
    setEndDate(date)
  }

  /**
   * Updates the endDate from custom date picker
   */
  const hanldleEndDateCustom = (e) => {
    setEndDate(moment(e.target.value))
  }

  /**
   * Updates the selected endDate Option when `endDateFormat` is changed
   */
  useEffect(() => {
    if (endDateFormat === 'custom') return
    const option = Object.keys(endDatesOptions[endDateFormat])[0]
    setEndDateCountOption(`${endDateFormat}+${option}`)
    setEndDate(endDatesOptions[endDateFormat][option])
  }, [endDatesOptions, endDateFormat])

  /**
   * Updates endDate when it's initial value is null
   */
  useEffect(() => {
    if (endDate) return
    const date = endDatesOptions[endDateFormat][endDateCountOption.split('+')[1]]
    setEndDate(date)
  }, [endDateCountOption, endDateFormat, endDatesOptions])

  useEffect(() => {
    onStartDateChange && onStartDateChange(startDate.toDate())
    onEndDateChange && onEndDateChange(endDate)
  }, [startDate, endDate, onEndDateChange, onStartDateChange])
  useEffect(() => {
    if (hasStartDateChanged.current && startDate.diff(new Date()) < 0) {
      onError && onError({ ...errors, startDate: 'Start Date cannot be less than current date.' })
      setErrors({ ...errors, startDate: 'Start Date cannot be less than current date.' })
    } else if (startDate.diff(endDate) > 0) {
      onError && onError({ ...errors, startDate: 'Start Date cannot be greater than end date.' })
      setErrors({ ...errors, startDate: 'Start Date cannot be greater than end date.' })
    } else {
      onError && onError({ ...errors, startDate: '', endDate: '' })
      setErrors({ ...errors, startDate: '', endDate: '' })
    }
  }, [startDate, endDate])

  return (
    <div>
      <div className='flex gap-8'>
        <div>
          <Typography className='mb-2' variant='body-regular'>
            Start Date
          </Typography>
          <SelectDate onChange={handleStartDateChange} value={startDate.format(DATE_FORMAT)} />
        </div>
        <div>
          <Typography className='mb-2' variant='body-regular'>
            Start Time
          </Typography>
          <SelectTime onChange={handleStartTimeChange} value={startDate.format(TIME_FORMAT)} />
        </div>
      </div>
      <div className='mt-6 flex gap-8'>
        <div>
          <Typography className='mb-2' variant='body-regular'>
            End Date
          </Typography>
          <SelectDropDown
            showHelpText={false}
            className='w-[147px]'
            menuItems={[
              {
                label: 'Hours',
                selected: true,
                value: 'hours',
                disabled: false
              },
              {
                label: 'Days',
                value: 'days',
                disabled: false
              },
              {
                label: 'Weeks',
                value: 'weeks',
                disabled: false
              },
              {
                label: 'Months',
                value: 'months',
                disabled: false
              },
              {
                label: 'Custom',
                value: 'custom',
                disabled: false
              }
            ]}
            // @ts-ignore
            onChange={(e) => e.target.value && setEndDateFormat(e.target.value)}
            value={endDateFormat}
          />
        </div>
        {endDateFormat === 'custom' && (
          <div>
            <Typography className='mb-2 capitalize' variant='body-regular'>
              {endDateFormat}
            </Typography>
            <SelectDate
              onChange={hanldleEndDateCustom}
              value={moment(endDate).format(DATE_FORMAT)}
            />
          </div>
        )}
        {endDateFormat !== 'custom' && (
          <div>
            <Typography className='mb-2 capitalize' variant='body-regular'>
              {endDateFormat}
            </Typography>
            {/* <SelectDropDown
              showHelpText={false}
              menuItems={Object.keys(endDatesOptions[endDateFormat]).map((e) => ({
                label: e,
                value: `${endDateFormat}+${e}`,
                disabled: false
              }))}
              className='w-[91px]'
              value={endDateCountOption}
              onChange={handleEndDateOptionChange}
            /> */}
          </div>
        )}
      </div>
    </div>
  )
}

export { StartEndTimePicker }
