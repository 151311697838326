// @ts-nocheck
import { errorHandler, getUserInfo } from '../Utils/Helpers'
import ApiProvider from './ApiProvider'

export class TenantProfilesApi {
  /** fetch all credentials List */
  static async getTenantprofiles () {
    try {
      const user = getUserInfo()
      const { tenant } = user
      const response = await new ApiProvider('tenantprofiles').setInstance({ ObjectMeta: { Name: tenant } }).get()
      // await getRequest(apiKey, `tenantprofiles/${}`, tenant)
      if (response && response.data) {
        return [response.data]
      }
    } catch (error) {
      errorHandler(error, 'tenantprofiles', 'TenantProfilesApi.js')
    }
  }
}
