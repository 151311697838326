import { DashboardDataProvider, useDashboardDataProvider } from 'features/dashboard'
import { SelectDropDown, Typography } from 'procyon-ui'
import React from 'react'
import { AccessDistribution } from './components/AccessDistribution'
import { CountCards } from './components/CountCards'
import { TopDevices } from './components/TopDevices'
import { TopResources } from './components/TopResources'
import { TopUsersTable } from './components/TopUsersTable'
import { UsersResourcesActivity } from './components/UsersResourcesActivity'

const Dashboard = () => {
  const { setSetStartDays, startDays } = useDashboardDataProvider()

  return (
    <div className='w-[100%]'>
      <div className='flex justify-between items-center mb-6'>
        <Typography variant='h2'>Dashboard</Typography>
        <SelectDropDown
          sx={{ width: '150px' }}
          menuItems={[
            {
              label: 'Last 24 Hours',
              selected: true,
              value: '1'
            },
            {
              label: 'Last 7 Days',
              value: '7'
            },
            {
              label: 'Last 30 Days',
              value: '30'
            },
            {
              label: 'Last 60 Days',
              value: '60'
            },
            {
              label: 'Last 365 Days',
              value: '365'
            }
          ]}
          onChange={(e) => {
            setSetStartDays(e.target.value)
          }}
          value={startDays}
        />
      </div>
      <CountCards />
      <div className='flex gap-8'>
        <div className='w-1/2'>
          <TopUsersTable />
          <div className='mt-8'>
            <TopDevices />
          </div>
        </div>
        <div className='w-1/2'>
          <TopResources />
          <div className='mt-8'>
            <AccessDistribution />
          </div>
        </div>
      </div>
      <UsersResourcesActivity />
    </div>
  )
}

const WrappedDashboard = () => (
  <DashboardDataProvider>
    <Dashboard />
  </DashboardDataProvider>
)

export { WrappedDashboard as Dashboard }
