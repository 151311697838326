import { getUserInfo } from 'Utils/Helpers'

export const getRoleLogQuery = (roleName, user, deviceID) => {
  const query = [
    {
      match_phrase: {
        'User.Tenant': getUserInfo().tenant
      }
    }, {
      bool: {
        should: [
          {
            match_phrase: {
              Message: 'assumed role'
            }
          }
        ],
        minimum_should_match: 1
      }
    }]
  if (roleName) {
    query.push({
      match_phrase: {
        'Resources.Name': roleName
      }
    })
  }
  if (user) {
    query.push({
      match_phrase: {
        'User.Name': user
      }
    })
  }
  if (deviceID) {
    query.push({
      match_phrase: {
        'Device.id': deviceID
      }
    })
  }
  return query
}
export const getServerLogQuery = (serverName, user, deviceName) => {
  const query = [
    {
      match_phrase: {
        'Data.Tenant': getUserInfo().tenant
      }
    },
    {
      match_phrase: {
        Message: 'SSH'
      }
    }]
  if (serverName) {
    query.push({
      match_phrase: {
        'Data.ServerName': serverName
      }
    })
  }
  if (user) {
    query.push({
      match_phrase: {
        'Data.User': user
      }
    })
  }
  if (deviceName) {
    query.push({
      match_phrase: {
        'Data.Device': deviceName
      }
    })
  }
  return query
}
