import React, { useLayoutEffect, useRef } from 'react'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'
import { Grid, makeStyles } from '@material-ui/core'
import { randomStringGenerator } from 'Utils/PureHelperFuctions'
import { am4core } from 'Core'

am4core.useTheme(am4themesAnimated)

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  chartCenter: {
    // For absolute center-ing an element
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  chartContainer: {
    position: 'relative'
  },
  titleContainer: {
    paddingLeft: theme.spacing(2)
  },
  rightSection: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  chart: {
    width: '100%',
    height: '100%'
  },
  label: {
    color: theme.palette.text.secondary
  }
}))

const PieChart = ({
  Title = '',
  chartStyles = { height: '10rem', width: '10rem', innerRadiusPercent: 60 },
  RightSection,
  ChartCenter,
  hideRightSection = false,
  LabelRightTop = 'Managed',
  ValueRightTop,
  LabelRightBottom = 'Offline',
  ValueRightBottom,
  rootStyles = {},
  chartData = []
}) => {
  const classes = useStyles()
  const id = useRef(randomStringGenerator((16)))
  useLayoutEffect(() => {
    const chart = am4core.create(id.current, am4charts.PieChart)
    chart.data = chartData.map((data) => ({ ...data, color: am4core.color(data.color) }))
    chart.innerRadius = am4core.percent(chartStyles.innerRadiusPercent || 60)
    const pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.slices.template.propertyFields.fill = 'color'
    pieSeries.labels.template.disabled = true
    pieSeries.ticks.template.disabled = false
    pieSeries.slices.template.tooltipText = '{title} - {value}%'
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'total'

    return () => {
      chart.dispose()
    }
  }, [])

  return (
    <div style={rootStyles} className={classes.root}>
      <Grid direction='column' container>
        <Grid className={classes.titleContainer} item>
          {Title}
        </Grid>
        <Grid container>
          <Grid
            style={{
              width: chartStyles.width || '10rem',
              height: chartStyles.height || '10rem'
            }}
            className={classes.chartContainer}
            item
          >
            <div id={id.current} className={classes.chart} />
            <div className={classes.chartCenter}>{ChartCenter}</div>
          </Grid>
          {!hideRightSection && (
            <Grid className={classes.rightSection} item>
              {RightSection || (
                <Grid justifyContent='space-around' direction='column' container>
                  <Grid justifyContent='space-between' alignItems='center' container>
                    <Grid className={classes.label} item>{LabelRightTop}</Grid>
                    <Grid item>{ValueRightTop}</Grid>
                  </Grid>
                  <Grid justifyContent='space-between' alignItems='center' container>
                    <Grid className={classes.label} item>{LabelRightBottom}</Grid>
                    <Grid item>{ValueRightBottom}</Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default PieChart
