import { Grid, makeStyles } from '@material-ui/core'
import CustomTypo from 'Components/CustomTypography/CustomTypo'
import IconChipCount from 'Components/IconChipCount'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500
  }
}))

const ChipCountHeader = ({ title, current, total, Icon, headerVariant = 'h6' }) => {
  const classes = useStyles()

  return (
    <Grid justifyContent='space-between' alignItems='flex-end' container>
      <Grid item>
        <CustomTypo className={classes.title} variant={headerVariant}>
          {title}
        </CustomTypo>
      </Grid>
      <Grid item>
        <IconChipCount Icon={Icon} current={current} total={total} />
      </Grid>
    </Grid>
  )
}

export default ChipCountHeader
