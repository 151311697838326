import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { LabelContent } from 'V2Components'
import { useAppDetailsContext } from 'features/databricks'
import { createDataSelectorHook } from 'infra/redux'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'

const useSlices = createDataSelectorHook([
  'policyList',
  'userList',
  'dataBricksIdentitys',
  'dataBricksResources'
])

const UsersTab = () => {
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [usersList, setUsersList] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [updateState, setUpdateState] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useMemo(() => {
    const usersList = []

    const identityUsers = app.Status.Users.ObjectRef.map((userRef) => {
      const userRefID = userRef?.RefID
      const matchingIdentityUser = slices.dataBricksIdentitys.find(
        (identityUser) => identityUser.ObjectMeta.ID === userRefID
      )

      return matchingIdentityUser
    }).filter(Boolean)

    identityUsers.forEach((user) => {
      const userList = slices.userList.filter((puser) => puser.Spec.EmailID === user.Spec.Email)
      usersList.push(...userList)
    })
    setSelectedUser(usersList[0])
    setUsersList(usersList)
  }, [])

  useEffect(() => {
    getSelectedUserGrantedRole()
  }, [selectedUser, updateState])

  useEffect(() => {
    setTimeout(() => {
      setUpdateState(true)
    }, 2000)
  }, [])

  const handleUserClick = (user) => {
    setSelectedUser(user)
  }

  const getPolicyList = () =>
    slices.policyList.filter((policy) => {
      return policy?.Spec?.ActionMap?.Databricks?.PolicyRule
    })

  const getExpiryFormatedTime = (time) => {
    const date = new Date(time)

    const options = { timeZone: 'Asia/Kolkata', hour12: false }
    const istDate = date.toLocaleString('en-US', options).replace(',', '')

    // Split the date and time for custom formatting
    const [datePart, timePart] = istDate.split(' ')
    const formattedDate = datePart.split('/').reverse().join('-') + ' ' + timePart

    return formattedDate
  }

  const getSelectedUserGrantedRole = async () => {
    setIsLoading(true)
    try {
      getGrantedPermissions()
    } catch (error) {
      console.error('Error fetching granted roles:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getGrantedPermissions = () => {
    const policyList = getPolicyList()
    return policyList.reduce((acc, policy) => {
      const policyRule = policy?.Spec?.ActionMap?.Databricks?.PolicyRule?.[0]
      const policyError = JSON.parse(policy?.Status?.Error)

      const policyHasError = Object.values(policyError).some(
        (value) => typeof value === 'string' && value.length > 0
      )
      const policyIssuedTo =
        policy?.IssuedTo?.ObjectRef?.[0]?.RefID === selectedUser?.ObjectMeta?.ID

      if (!policyHasError && policyRule && policyIssuedTo) {
        const { ObjectRef, Permissions } = policyRule
        const rsrcObj = getObjectRefFlat(ObjectRef)
        if (rsrcObj?.Spec?.DisplayName && Permissions) {
          acc.push({
            rsrcName: rsrcObj.Spec.DisplayName,
            permission: Permissions,
            expiry: getExpiryFormatedTime(policy?.NotAfter)
          })
        }
      }

      return acc
    }, [])
  }

  return (
    <>
      <div className='flex'>
        <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
          {usersList &&
            usersList.map((user) => (
              <div className='my-5'>
                <div
                  className='flex justify-between items-center border p-2 cursor-pointer'
                  onClick={() => handleUserClick(user)}
                >
                  <div className='flex gap-2'>
                    <TargetIcon type={'USER'} />
                    <span className='' style={{ width: '250px', wordBreak: 'break-word' }}>
                      <Typography variant='body-regular'>{user.Spec.EmailID}</Typography>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {usersList.length > 0 && (
          <div className='w-1/2 pr-8'>
            <Typography variant='h3' style={{ padding: '20px 30px' }}>
              Access Details of <span className='font-semibold'>{selectedUser?.Spec?.Email}</span>
            </Typography>
            <div>
              {isLoading ? (
                <Typography variant='h4-regular'>Resources and Permissions loading...</Typography>
              ) : (
                getGrantedPermissions().map((item, index) => (
                  <LabelContent
                    key={index}
                    title={item.rsrcName}
                    className='pl-7'
                    content={
                      <div className='flex items-center'>
                        {item.permission.map((perm) => (
                          <Label variant='grayBlue' className='mr-2' text={perm} />
                        ))}
                        <span className='text-[red] font-medium ml-1'>Expiry - {item.expiry}</span>
                      </div>
                    }
                  />
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export { UsersTab }
