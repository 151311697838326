import { isAlphanumeric, reverseRsrcKey } from 'features/resources'
import {
  ServerControllerWizardProvider,
  useServerControllerWizardContext
} from 'features/serverControllers'
import { getUserServerName } from 'features/users'
import { reduxApiClient } from 'infra'
import { createDataSelectorHook } from 'infra/redux'
import _ from 'lodash'
import React from 'react'
import { enqueueNotification } from 'Utils/Helpers'
import { Wizard } from 'V2Components'
import { ConfigStep } from './components/ConfigStep'
import { FooterComponent } from './components/FooterComponent'
import { SelectGroupsStep } from './components/SelectGroupsStep'
import { SelectUsersStep } from './components/SelectUsersStep'
import { SudoersPolicys } from './components/SudoersPolicys'
import { SummaryStep } from './components/SummaryStep'

const useSlices = createDataSelectorHook(['serverControllers'])

const CreateServerControllerWizard = ({ onCancel }) => {
  const contextData = useServerControllerWizardContext()
  const { slices } = useSlices()

  const getSteps = () => {
    return [
      {
        label: 'Config',
        content: <ConfigStep />,
        getErrors() {
          const { controllerName, selectorType, label, selectedAccountKeys, ctag } = contextData
          if (controllerName.trim().length <= 3)
            return 'Controller Name must be greater than 3 characters.'
          if (
            !contextData.isEditMode &&
            _.find(slices.serverControllers, { ObjectMeta: { Name: controllerName.trim() } })
          ) {
            return 'Controller with the name already exists.'
          }
          if (/\s/g.test(controllerName)) return 'Name cannot have spaces.'
          if (!isAlphanumeric(controllerName)) return 'Only Letters and number are allowed in name.'
          switch (selectorType) {
            case 'Accounts':
              if (selectedAccountKeys.length === 0) return 'Select at least one account.'
              break
            case 'CTags':
              const [key, name] = ctag.split(':')
              if (!key || !name) return 'Tag key and value is required.'
              break
            case 'Labels':
              const [lKey, lName] = label.split(':')
              if (!lKey || !lName) return 'Tag key and value is required.'
              break
          }
        }
      },
      {
        label: 'Server Users',
        content: <SelectUsersStep />,
        getErrors() {
          if (!contextData.users.length && !contextData.isEditMode)
            return 'Select at least one user.'
        }
      },
      {
        label: 'Server Groups',
        content: <SelectGroupsStep />,
        getErrors() {
          const emptyGroup = contextData.groups.find((e) => !contextData.groupsMap[e]?.length)
          if (emptyGroup) return `Group (${emptyGroup}) has 0 users assigned.`
        }
      },
      {
        label: 'Sudoers Policies',
        content: <SudoersPolicys />
        // getErrors() {

        // }
      },
      {
        label: 'Summary',
        content: <SummaryStep />
      }
    ]
  }

  const steps = getSteps()

  const getIndexErrors = () => {
    const indexedErrors = []

    steps.forEach((step, i) => {
      const { getErrors } = step
      let errors = ''
      if (getErrors) {
        errors = getErrors()
      }
      indexedErrors[i] = errors
    })

    return indexedErrors
  }

  const handleServerControllerCreate = async () => {
    try {
      //@ts-ignore
      await handleCreate(contextData)
      enqueueNotification(
        `Server Controller Successfully ${contextData.isEditMode ? 'Updated!' : 'Created!'}.`,
        'info'
      )
      onCancel()
    } catch (error) {
      enqueueNotification(
        `Error ${contextData.isEditMode ? 'Updating' : 'Creating'} server controller!`,
        'error'
      )
    }
  }

  return (
    <Wizard
      header={
        <div className='flex gap-2 items-center'>
          {contextData.isEditMode ? 'Edit' : 'Create'} Server Controller
        </div>
      }
      onCancel={onCancel}
      steps={steps}
      //@ts-ignore
      FooterComponent={(props) => (
        <FooterComponent
          isEditMode={contextData.isEditMode}
          indexedErrors={getIndexErrors()}
          onSubmit={handleServerControllerCreate}
          {...props}
        />
      )}
    />
  )
}

const Wrapped = ({ onCancel, srvCtrl = null }) => (
  <ServerControllerWizardProvider srvCtrl={srvCtrl}>
    <CreateServerControllerWizard onCancel={onCancel} />
  </ServerControllerWizardProvider>
)

const handleCreate = async ({
  srvCtrl,
  users,
  groups,
  groupsMap,
  sudoers,
  controllerName,
  selectedAccountKeys,
  selectorType,
  label,
  ctag,
  disableAnonymousLogin,
  disableOSLogin
}) => {
  try {
    const payload = {
      ObjectMeta: {
        Name: controllerName
      },
      Spec: {
        Users: {
          Elems: []
        },
        Sudoers: {
          Elems: []
        },
        Groups: {
          Elems: groups.filter((g) => !!g)
        },
        GroupMap: {
          Map: {}
        },
        Accounts: {
          ObjectRef: []
        },
        Labels: {
          Map: {}
        },
        CTags: {
          Map: {}
        },
        DisableAnonymousLogin: disableAnonymousLogin,
        DisableOsLogin: disableOSLogin
      }
    }

    if (srvCtrl) payload.ObjectMeta = { ...srvCtrl.ObjectMeta }

    payload.Spec.Users.Elems = users.reduce((prev, curr) => {
      const sName = getUserServerName(curr)
      if (!sName) return prev
      return [...prev, sName]
    }, [])

    payload.Spec.Sudoers.Elems = sudoers.split(/\r\n|\r|\n/)

    const gMap = {}
    for (const groupName in groupsMap) {
      const users = groupsMap[groupName] || []

      const filtered = users.reduce((prev, curr) => {
        const sName = curr.split('+')[1]
        if (!sName) return prev
        return [...prev, sName]
      }, [])

      gMap[groupName] = {
        Elems: filtered
      }
    }

    payload.Spec.GroupMap.Map = gMap

    switch (selectorType) {
      case 'Accounts':
        payload.Spec.Accounts.ObjectRef = selectedAccountKeys.map(reverseRsrcKey)
        break
      case 'Labels':
        const [labelKey, labelValue] = label.split(':')
        payload.Spec.Labels.Map[labelKey] = labelValue
        break
      case 'CTags':
        const [cTagKey, cTagValue] = ctag.split(':')
        payload.Spec.CTags.Map[cTagKey] = cTagValue
        break
    }
    if (srvCtrl) await reduxApiClient('servercontrollers').update(payload)
    else await reduxApiClient('servercontrollers').create(payload)
  } catch (error) {}
}

export { Wrapped as CreateServerControllerWizard }
