import useHTMLBodyScrollbar from 'Core/Hooks/useHTMLBodyScrollbar'
import { SearchInput } from 'V2Components'
import { getPolicyType } from 'features/policy/utils'
import React, { useState } from 'react'
import { useEditPolicyModulesContext } from '../../EditPolicyModulesProvider'
import { IAMAction } from './components/IAMAction'
import { Target } from './components/Target'

const SelectResources = ({}) => {
  const [searchKey, setSearchKey] = useState('')

  const { policy } = useEditPolicyModulesContext()

  useHTMLBodyScrollbar(true)

  const policyType = getPolicyType(policy)

  return (
    <div className='relative'>
      <div className='pb-2 border-b mb-2 px-5 -mx-5 fixed w-[626px] bg-white z-[580]'>
        <SearchInput onChange={setSearchKey} searchKey={searchKey} />
      </div>
      <div className='pt-14'>
        {policyType === 'IAMAction' && <IAMAction searchKey={searchKey} />}
        {policyType === 'Target' && <Target searchKey={searchKey} />}
      </div>
    </div>
  )
}

export { SelectResources }
