import useAppView from 'Core/Hooks/useAppView'
import { getApprovalRequestResourcesRefs } from 'features/approvals'
import { IAMResourcesSliceNames } from 'features/iamResources'
import { createRsrcKey, getResourceName, getRsrcIcon } from 'features/resources'
import { TargetsSliceNames } from 'features/targets'
import { useObjectRef } from 'infra/redux'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

export function useTabs() {
  const { getObjectRef } = useObjectRef([
    'userList',
    'groupList',
    'serviceAccounts',
    'kubeClusters',
    'kubeNamespaces',
    'bundles',
    ...TargetsSliceNames,
    ...IAMResourcesSliceNames
  ])

  const { appView } = useAppView()
  const history = useHistory()

  const handleTableCellClick = (p) => {
    if (p.field === 'name') history.push(`/${appView}/access-requests/${p.row.objectName}`)
  }

  const getCommonTableRowData = useCallback(
    (request) => {
      const type = request.Type
      const requestor = getObjectRef(request.Requestor)
      const requestedFor = request.Spec.RequestedFor.ObjectRef.slice(0, 2).reduce((prev, e) => {
        const obj = getObjectRef(e)
        if (obj)
          prev.push({
            RefKind: e.RefKind,
            name: getResourceName(obj)
          })
        return prev
      }, [])

      const resourcesRefs = getObjectRef(getApprovalRequestResourcesRefs(request))
      const resouceCount = type === 'ServiceAccount' ? 1 : resourcesRefs.length
      const bundle = getObjectRef(request.BundleRef)
      // Search key pattern will be applied in this data of the table
      const searchData = {
        id: createRsrcKey(request),
        objectName: request.ObjectMeta.Name,
        name: {
          name: bundle ? getResourceName(bundle): request.GivenName,
          isBundleReq: !!bundle,
          Icon: getRsrcIcon(bundle)
        },
        requestedBy: getResourceName(requestor),
        requestedFor,
        resources: {
          count: resouceCount,
          onClick() {}
        },
        submittedOn: request.ObjectMeta.CreatedAt,
        expiresOn: type === 'ServiceAccount' ? null : request.Spec.NotAfter,
        details: `/${appView}/access-requests/${request.ObjectMeta.Name}`,
        goToDetails() {
          history.push(`/${appView}/access-requests/${request.ObjectMeta.Name}`)
        }
      }

      return searchData
    },
    [getObjectRef]
  )

  return { getCommonTableRowData, handleTableCellClick }
}
