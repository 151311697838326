import moment from 'moment'
import { ElasticLogIndexDataMap } from './constants'
import _ from 'lodash'

export const constructTillDateRange = (value) => {
  const [amount, unit] = value.split(' ')
  const lt = moment().format()
  const gte = moment().subtract(amount, unit).format()
  const range = { range: { '@timestamp': { gte, lt } } }
  return range
}

export const constructDateRange = (startTime, endTime) => {
  const lt = moment(endTime).format()
  const gte = moment(startTime).format()
  const range = { range: { '@timestamp': { gte, lt } } }
  return range
}
/**
 * Generates query object for elastic search
 * @param {string | boolean} tillDate till which date data should be fetched
 * @param {Array<Object>} musts must conditions for elastic search query
 * @param {{start:Date,end:Date}|false} timeRange
 * @returns {Object} elastic search query
 */
export const constructQuery = (tillDate = false, musts = [], timeRange = false) => {
  const query = {
    size: 10000,
    query: {
      bool: {
        must: [...musts]
      }
    }
  }
  if (tillDate && tillDate !== '-') query.query.bool.must.push(constructTillDateRange(tillDate))
  if (timeRange) query.query.bool.must.push(constructDateRange(timeRange.start, timeRange.end))

  return query
}

/**
 * Generates query object for elastic search
 * @param {string | boolean} tillDate till which date data should be fetched
 * @param {{start:Date,end:Date}|false} timeRange
 * @returns {Object} elastic search query
 */

export const constructTimeAndDateRange = (tillDate = false, timeRange = false) => {
  const dateTimeArray = []

  if (tillDate && tillDate !== '-') dateTimeArray.push(constructTillDateRange(tillDate))
  if (timeRange) dateTimeArray.push(constructDateRange(timeRange.start, timeRange.end))

  return dateTimeArray
}

export const getActivityLogResourceKind = (hit) => {
  const {
    _source: { Data, Resources }
  } = hit
  if (Data.ServerName) return 'Server'
  if (isApplicationLog(hit)) return 'Application'
  if (_.isArray(Resources) && Resources.length && Resources[0].Kind === 'AppRole') return 'AppRole'
}

/**
 *
 * @param {*} hit ES Log
 * @returns {{
 * userName: string,
 * tenantName: string,
 * deviceName: string,
 * resources: string | any[] ,
 * resourceKind: string
 * }}
 */
export const getDataNamesFromESLog = (hit) => {
  const { _index, _source } = hit
  const DataMap = ElasticLogIndexDataMap[_index]

  const { UserNameMatchKey, TenantMatchKey, DeviceMatchKey, ResourceMatchKey } = DataMap

  const tenantName = TenantMatchKey ? _.get(_source, TenantMatchKey) : ''
  const deviceName = DeviceMatchKey ? _.get(_source, DeviceMatchKey) : ''
  const userName = UserNameMatchKey ? _.get(_source, UserNameMatchKey) : ''
  const resources = ResourceMatchKey ? _.get(_source, ResourceMatchKey) : ''

  return { userName, tenantName, deviceName, resources, resourceKind: getActivityLogResourceKind(hit) }
}

export const findActivityLogById = (hits, id) => {
  return _.find(hits, { _id: id })
}

//prettier-ignore
const DeviceTypeMap = {
	WINDOWS	: 	'DESKTOP'	,
	MAC			: 	'DESKTOP'	,
	IOS			:	 	'MOBILE'	,
  ANDROID :   'MOBILE'  ,
  Others  :   'DESKTOP' ,
}

export const getDeviceType = (device) => {
  return DeviceTypeMap[device.Attributes.DeviceType.toUpperCase()] || 'OTHERS'
}

/**
 *
 * @param {*} log Log to check for
 * @returns Returns true if this log is generated when user is opening application
 */
export const isApplicationLog = (log) => {
  const { _index, _source } = log
  return _index === 'proxy-audit-log' && _source.Message?.toLowerCase() === 'received http request'
}
