import { TabGroup } from 'procyon-ui'
import React, { useMemo } from 'react'
import { ActivePolicies, TeamsandGroups } from 'features/github'

function Tabs(showAddButton, isRepo, app) {
  
  const tabs = useMemo(() => {
    const list = []
    
    if(isRepo) {
      list.push(
        {
        label: 'Active Policies',
        tabContent: <ActivePolicies isRepo={isRepo} showAddButton={showAddButton} />
      })
      }else {
      list.push(
        {
          label: 'Teams & Users',
          tabContent: <TeamsandGroups isRepo={isRepo} showAddButton={showAddButton} />
        },
        {
          label: 'Active Policies',
          tabContent: <ActivePolicies isRepo={false} showAddButton={showAddButton} />
        }
      )
    }

   
    return list
  }, [])

  return (
    <div>
      <TabGroup tabs={tabs} />
    </div>
  )
}

export { Tabs }
