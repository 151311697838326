import { faCircleMinus, faUser } from '@fortawesome/pro-solid-svg-icons'
import {
  DateTimeComposedFilter,
  EntityPicker,
  FullScreenContentModal
} from 'V2Components'
import { createRsrcKey, getResourceName } from 'features/resources'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import { Button, Label, ResponsiveTable } from 'procyon-ui'
import React, { useCallback, useRef, useState } from 'react'
import { useTabs } from '../Tabs/Tabs.utils'

const getStatusLabel = (request) => {
  if (!request) return
  const labelMap = {
    Cancelled: 'Cancelled',
    Rejected: 'Rejected',
    Closed: 'Closed'
  }
  return labelMap[request.Status] || 'Expired'
}

const useSlices = createDataSelectorHook(['userList'])

const Table = ({ data, getColumns, rowDataAdapterFn, searchKey }) => {
  const { getCommonTableRowData, handleTableCellClick } = useTabs()
  const [selectedEntities, setSelectedEntities] = useState([])
  const [showSelectUsers, setShowSelectUsers] = useState(false)

  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))
  const [endDate, setEndDate] = useState(moment())

  const { slices } = useSlices()

  const _prevUsers = useRef([])

  const getData = useCallback(() => {
    const items = []
    const startDateMS = startDate.toDate().getTime()
    const endDateMS = endDate.toDate().getTime()
    data.forEach((e) => {
      const data = getCommonTableRowData(e)
      const { submittedOn, requestedBy } = data
      const submittedOnMS = new Date(submittedOn).getTime()

      if (!(submittedOnMS >= startDateMS && submittedOnMS <= endDateMS)) return
      const searchData = [getStatusLabel(e), requestedBy, data.name]
      if (selectedEntities.length) {
        const names = selectedEntities.map(getResourceName)
        if (!names.includes(requestedBy)) return
      }
      // Return if search pattern doesn't macth
      if (searchKey) {
        if (JSON.stringify(Object.values(searchData)).toLowerCase().includes(searchKey)) {
          items.push(e)
        }
      } else items.push(e)
    })
    return items
  }, [data, searchKey, startDate, endDate, selectedEntities])

  const handleUserRemove = (key) => {
    setSelectedEntities((s) => s.filter((e) => createRsrcKey(e) !== key))
  }

  return (
    <div className='w-[100%]'>
      <DateTimeComposedFilter
        hideSearch
        initialEndDate={endDate}
        initialStartDate={startDate}
        onFilterChange={({ startDate, endDate }) => {
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        CustomFilters={
          <div>
            <Button
              onClick={() => {
                // Preserver currently selected users for restoration
                _prevUsers.current = selectedEntities
                setShowSelectUsers(true)
              }}
              size='md'
              variant='grayBlue'
              icon={faUser}
            >
              Select Users
            </Button>
          </div>
        }
      />
      <div className='flex gap-2 my-2'>
        {selectedEntities.map((u) => {
          return (
            <Label
              onClick={() => {
                handleUserRemove(createRsrcKey(u))
              }}
              variant='grayBlue'
              iconButton={faCircleMinus}
              text={u.ObjectMeta.Name}
            />
          )
        })}
      </div>
      <ResponsiveTable scrollX='1200px' data={getData().map(rowDataAdapterFn)} columns={getColumns()} />

      {showSelectUsers && (
        <FullScreenContentModal width='auto'>
          <EntityPicker
            title='Select Users for Query Filter'
            entities={slices.userList}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              // Restore previously selected users on cancel
              setSelectedEntities(_prevUsers.current)
              setShowSelectUsers(false)
            }}
            onContinue={() => {
              // No need to restore on continue because we dont want to ;)
              setShowSelectUsers(false)
            }}
          />
        </FullScreenContentModal>
      )}
    </div>
  )
}

export { Table }
